function ascend(str1, str2) {
  return str1.toLowerCase().localeCompare(str2.toLowerCase());
}

function descend(str1, str2) {
  return str2.toLowerCase().localeCompare(str1.toLowerCase());
}

function sortBy(str1, str2, attribute, orderFunc) {
  const value1 = `${str1.attributes[attribute] || ''}`.trim();
  const value2 = `${str2.attributes[attribute] || ''}`.trim();
  return orderFunc(value1, value2);
}

export function ascendBy(attribute) {
  return (str1, str2) => sortBy(str1, str2, attribute, ascend);
}

export function descendBy(attribute) {
  return (str1, str2) => sortBy(str1, str2, attribute, descend);
}

function getPlaceX(el) {
  const boundingClientRect = el.getBoundingClientRect();
  const diff = window.innerWidth - boundingClientRect.x;

  if (diff < 380) return 'right';
  if (boundingClientRect.x > 160) return 'center';
  return 'left';
}

function getPlaceY(el) {
  const boundingClientRect = el.getBoundingClientRect();

  if (boundingClientRect.top < 480) return 'bottom';
  return 'top';
}

export function getPositions(el) {
  let bottom = null;
  let left = null;
  let marginLeft = null;
  let right = null;
  let top = null;

  if (!el) {
    return { bottom, left, marginLeft, right, top };
  }

  const placeX = getPlaceX(el);
  const placeY = getPlaceY(el);

  if (placeX === 'right') {
    right = '16px';
  } else if (placeX === 'center') {
    left = '-50%';
    marginLeft = '-50%';
  } else {
    left = '16px';
  }

  if (placeY === 'top') {
    bottom = placeX === 'center' ? '16px' : '0';
  } else {
    top = placeX === 'center' ? '16px' : '0';
  }

  return { bottom, left, marginLeft, right, top };
}
