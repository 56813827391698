import * as React from 'react';
import PropTypes from 'prop-types';

function FindGlassIcon({ fillOpacity, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.68 10.346c0 1.254-.367 2.42-.992 3.404l-.209.296L20 18.57 18.596 20l-4.525-4.525-.288.2A6.342 6.342 0 014 10.345 6.343 6.343 0 0110.342 4c3.5 0 6.337 2.842 6.337 6.346zm-1.322-.004c0-1.338-.52-2.596-1.466-3.542a4.977 4.977 0 00-3.542-1.467c-1.338 0-2.596.521-3.542 1.467a4.977 4.977 0 00-1.466 3.542c0 1.337.52 2.595 1.466 3.541a4.976 4.976 0 003.542 1.467c1.338 0 2.596-.52 3.542-1.467a4.976 4.976 0 001.466-3.541z"
        fill="currentColor"
        fillOpacity={fillOpacity}
      />
    </svg>
  );
}

FindGlassIcon.propTypes = {
  fillOpacity: PropTypes.number,
};

FindGlassIcon.defaultProps = {
  fillOpacity: 1,
};

export default FindGlassIcon;
