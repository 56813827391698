import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '../../mc-ui';
import Email from '../Email';

import background from './emailsBackground.png';

const getPosition = index => {
  if (index === 0) return '0';
  if (index === 1) return '56px';
  if (index === 2) return '112px';
  return null;
};

const EmailsBackground = ({ brand }) => {
  const emails = brand.relationships.emails.data;

  return (
    <Box
      left="0"
      height="56px"
      overflow="hidden"
      position="absolute"
      top="0"
      width="245px"
      css={{
        background: 'linear-gradient(360deg, rgba(0, 0, 0, 0.2) -1.46%, rgba(0, 0, 0, 0) 100%)',
      }}
    >
      <Box
        height="56px"
        overflow="hidden"
        position="relative"
        width="245px"
        css={{ backgroundImage: `url(${background})` }}
      >
        {emails.map((email, index) => (
          <Box
            key={email.id}
            borderRadius="4px"
            height="117px"
            left={getPosition(index)}
            position="absolute"
            right={index === 3 ? '0' : null}
            top="0"
            width="80px"
          >
            <Email
              email={email}
              height="100%"
              width="100%"
              css={{ border: 'none', height: '100%', margin: '0', padding: '0', width: '100%' }}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

EmailsBackground.propTypes = {
  brand: PropTypes.shape({}).isRequired,
};

export default EmailsBackground;
