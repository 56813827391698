import React from 'react';

import Carousel from '../ui/Carousel';

import { fetchFeaturedEmailLists } from './utils/api';

class FeaturedEmailLists extends React.PureComponent {
  state = {
    loading: true,
    lists: [],
  };

  componentDidMount() {
    this.request();
  }

  request = async () => {
    const lists = await fetchFeaturedEmailLists();

    this.setState(() => ({
      lists,
      loading: false,
    }));
  };

  render() {
    const { loading, lists } = this.state;

    return <Carousel collection={lists} loading={loading} type="emailLists" />;
  }
}

export default FeaturedEmailLists;
