import React from 'react';
import PropTypes from 'prop-types';

import ReactSelect from '../../../../mc-ui/overrides/ReactSelect';

import Button from './Button';
import DropdownIndicator from './DropdownIndicator';
import Menu from './Menu';
import Overlay from './Overlay';
import Wrapper from './Wrapper';

const selectStyles = {
  control: provided => ({
    ...provided,
    border: 'none !important',
    borderColor: 'transparent !important',
    boxShadow: null,
    minWidth: 240,
    margin: 8,
  }),
};

class Dropdown extends React.Component {
  state = {
    isOpen: this.props.open,
    value: undefined,
  };

  onSelectChange = value => {
    this.toggleOpen();
    this.setState(() => ({ value }));
    this.props.onSelect(value);
  };

  toggleOpen = () => {
    this.setState(currentState => ({ isOpen: !currentState.isOpen }));
  };

  render() {
    const { isOpen, value } = this.state;
    const { options } = this.props;

    return (
      <Wrapper>
        <Button onClick={this.toggleOpen} open={isOpen} />
        {isOpen && (
          <Menu>
            <ReactSelect
              autoFocus
              backspaceRemovesValue={false}
              components={{
                ClearIndicator: null,
                IndicatorSeparator: null,
                MultiValueRemove: null,
                DropdownIndicator,
              }}
              controlShouldRenderValue={false}
              hideSelectedOptions={false}
              isClearable={false}
              menuIsOpen
              onChange={this.onSelectChange}
              options={options}
              placeholder="Search for brand"
              styles={selectStyles}
              tabSelectsValue={false}
              value={value}
            />
          </Menu>
        )}
        {isOpen && <Overlay onClick={this.toggleOpen} />}
      </Wrapper>
    );
  }
}

Dropdown.defaultProps = {
  open: false,
};

Dropdown.propTypes = {
  open: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default Dropdown;
