import styled from "@emotion/styled";

const FooterActions = styled("div")({
  alignItems: 'center',
  boxShadow: '0px -1px 0px #D4D9DD',
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '12px 32px',
});

export default FooterActions;
