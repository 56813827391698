import * as React from 'react';

const NoComments = props => (
  <svg width={162} height={202} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="m25.201 11.813 10.46 18.117H14.74l10.46-18.117Z" fill="#78C854" />
    <path
      d="m72.674 3.643-7.737 4.343 4.034 6.795-6.891 3.867"
      stroke="#0083F1"
      strokeWidth={6}
      strokeLinecap="round"
    />
    <path
      d="m139.942 91.075 7.845 4.53v9.058l-7.845 4.529-7.845-4.529v-9.059l7.845-4.529Z"
      fill="#48DDCF"
    />
    <circle
      cx={45.23}
      cy={62.141}
      r={9.525}
      transform="rotate(-7.312 45.23 62.141)"
      fill="#9E6ACC"
    />
    <rect
      x={0.921}
      y={51.822}
      width={64.86}
      height={64.86}
      rx={8}
      transform="rotate(-7.312 .921 51.822)"
      stroke="#003872"
      strokeWidth={3}
      strokeLinecap="round"
    />
    <path
      d="M10.76 67.91c3.102-1.69 7.01-4.732 10.614-4.981.895-.062 1.191 2.222 1.622 2.894 2.809 4.386 12.753-2.328 15.583-3.752 2.76-1.389 6.953-1.318 9.933-1.188 1.597.07 9.497.74 10.123-1.185M16.234 82.062c1.934-.248 6.3-2.186 7.76.113 1.173 1.847 8.655-.702 10.349-1.39 5.082-2.061 9.302-3.35 14.592-4.029M17.168 100.038c4.91-1.811 9.826-3.9 14.854-5.356.65-.188 2.023-.637 2.745-.409 1.825.576.88 2.885 3.533 2.545l13.445-1.725"
      stroke="#003872"
      strokeWidth={3}
      strokeLinecap="round"
    />
    <path
      d="M62.019 128.516c3.683-1.703 7.789-3.65 11.813-4.227l17.304 13.552c2.954 1.481 4.856 3.718 7.283 5.97 2.452 2.276 5.029 4.396 7.573 6.565.39.333.887.663 1.211 1.07.168.211.15.6.343.872.649.916 1.174 1.983 1.619 3.011 1.312 3.027 2.964 5.366 3.424 8.691.861 6.212-1.411 14.938-6.94 18.307-12.715 7.749-28.09 5.196-42.274 6.525-4.904.459-9.883.343-14.802.343-5.247 0-10.422.077-15.606-.845-3.811-.678-7.67-1.673-10.786-4.108-2.63-2.055-4.115-4.84-5.242-7.912-1.889-5.15-2.25-11.952.027-17.091 2.54-5.73 7.15-9.789 12.168-13.367 3.16-2.253 6.525-4 10.036-5.627 3.668-1.699 7.07-3.576 10.535-5.666 3.88-2.341 8.201-4.162 12.314-6.063Z"
      fill="#FFD336"
    />
    <path
      d="M74.193 136.455c-1.256-3.685-1.237-7.388-2.682-11.012l-1.772-7.193c4.592 1.554 9.196 1.733 14.025 1.799 1.67.023 3.184-.368 4.798-.45.507-.025.222.166.222.675 0 3.603.302 7.337.885 10.887.297 1.805.495 3.588.443 5.419-.013.478-.53 4.9-.886 4.9-1.705 0-3.635-.011-5.265.5a97.64 97.64 0 0 0-5.585 1.923c-.417.158-.1-1.1-2.001-2.254-1.902-1.155-2.053-4.816-2.182-5.194Z"
      fill="#fff"
    />
    <path
      d="M62.604 103.656c2.312-5.44 3.663-10.81 4.505-16.481 3.313-3.516 12.075-9.471 20.622-5.162 8.547 4.31 12.2 14.009 12.957 18.32-3.01 3.297-3.162 11.657-6.007 14.922-3.177 3.646-6.32 5.826-12.011 7.673-5.211 1.691-16.705-.374-20.02-3.456-3.509-3.261-1.56-12.254-.046-15.816Z"
      fill="#fff"
      stroke="#003872"
      strokeWidth={3}
      strokeLinecap="round"
    />
    <path
      d="M77.764 85.574c-.688-.552-2.273-.167-3.042-.233l-1.603.051c-.843-.093-2.043 1.48-2.516 2.13-.877 1.207-2.716 1.959-4.105 1.133-2.294-1.364-1.689-6.566-.668-8.54.534-1.034.926-2.471 2.093-3.002.982-.448 2.47-.235 3.511-.12 1.667.183 3.484.047 4.996-.705 1.699-.845 2.98-2.355 4.662-3.2 2.067-1.037 4.421-1.403 6.68-1.722 6.852-.97 14.748.011 20.846 3.489 3.429 1.955 6.421 5.116 7.804 8.782 1.183 3.137.92 5.821-.115 8.974-.786 2.394-2.183 4.519-3.673 6.529-1.538 2.075-2.981 4.313-4.671 6.266-1.432 1.656-3.334 3.765-5.672 3.507-1.896-.209-3.604-1.278-5.51-1.52-2.25-.285-3.737-.634-5.568-1.932-1.523-1.08-2.249-1.984-2.527-3.979-.152-1.089.09-3.543-.969-1.451-.72 1.425-.536 3.141-2.436 2.9-1.731-.219-4.352-1.214-5.196-2.879-1.304-2.572-1.276-5.588-1.498-8.379-.123-1.545-.333-3.095-.388-4.644-.018-.513.01-1.097-.435-1.455Z"
      fill="#E99635"
    />
    <path
      d="M94.575 120.445c5.821-.113 11.766-1.197 17.591-.386 8.215 1.143 16.782 4.517 23.861 8.948 5.415 3.388 9.487 8.252 13.089 13.556 2.427 3.573 5.127 6.792 7.091 10.673 4.018 7.938 6.811 18.502 3.208 27.053-2.774 6.582-11.569 9.123-17.807 9.84-8.191.94-17.063 2.409-25.17.148-5.104-1.423-10.574-2.957-15.39-5.247-7.912-3.761-11.976-11.736-16.412-19.071v-.001c-1.745-2.885-3.596-5.945-4.271-9.318-.774-3.863-.896-7.871-1.266-11.788-.267-2.825-.69-5.781.46-8.457.499-1.16 1.796-1.995 2.906-2.364 1.334-.443 3.3-.203 4.473-.877.209-.12.447-.162.696-.177l6.941-12.532Z"
      fill="#FFD336"
    />
    <path
      d="M98.552 128.555c-2.117.35-3.891.936-6.103.972l-2.353 1.137 2.353 4.96-11.127 3.008c-1.678-4.913-4.439-16.84 4.387-17.159 1.693-.061 3.763-.029 4.387-1.77a90.879 90.879 0 0 0 1.655-5.039c.267-.909 1.54 1.702 2.054-.117.18-.637-.058-5.145 1.157-4.552 1.212.591 2.85 3.36 3.21 4.591.525 1.791-.697-3.682-.697-5.544 0-1.685-.079-5.919 1.077-7.179.782-.853 2.846 1.016 3.389 1.556 4.951 4.92 8.77 15.611 4.348 21.887-1.197 1.697-1.83 1.891-3.869 2.626-1.357.489-2.48.393-3.868.623Z"
      fill="#fff"
      stroke="#003872"
      strokeWidth={3}
      strokeLinecap="round"
    />
    <path
      d="M114.754 139.517c10.438 9.424 29.119 16.016 34.523 29.254l2.552 3.291c1.579.802 1.701 4.247 1.701 5.951 0 1.65-1.382 4.884-2.552 6.142-1.637 1.762-2.966 4.745-5.102 5.951-3.463 1.954-19.091 4.264-22.429 3.472-3.477-.825-6.819-1.22-10.253-2.256-3.466-1.045-6.429-2.843-9.638-4.559-2.922-1.561-5.952-2.47-8.694-4.415-2.325-1.649-4.412-3.422-6.568-5.279-8.505-7.324-9.902-19.644-9.41-30.906.286-6.534-.114-16.084 7.631-18.332 5.316-1.543 6.246.616 10.993 2.807 5.779 2.669 12.383 4.489 17.246 8.879Z"
      fill="#FFD336"
    />
    <path
      d="M142.961 170.337c-2.02-.439-3.97-.755-6.039-.755M142.962 170.337c-1.978-3.377-8.87-5.134-10.989-8.422-1.517-2.354-7.874-5.919-9.084-8.451-1.754-3.673-4.23-7.144-7.28-9.858-2.564-2.282-4.903-4.703-7.923-6.404-1.087-.612-2.193-1.315-3.366-1.748-.691-.255-1.663-.419-2.105-1.074-.858-1.275-2.108-2.445-3.219-3.518-.74-.715-1.668-1.803-2.756-1.854-1.649-.076-3.296-.189-4.966-.189-2.95 0-5.666.659-8.449 1.516-.955.294-1.955.676-2.578 1.433-.187.227-.422.296-.673.421-.391.196-.29 1.141-.347 1.485-.17 1.041-.396 2.313-.79 3.297-.6 1.504.283 3.546-.378 5.118-.112.266.379 6.761.379 7.043M31.974 144.671c-8.856 3.249-16.054 14.437-16.454 19.303-.462 5.611.04 14.631 5.587 18.924 4.367 3.38 11.181 4.189 16.847 4.987 6.718.947 13.956 1.161 20.78 1.161 4.268 0 8.406.354 12.635.017 2.946-.234 5.88.09 8.832-.256 2.322-.272 4.716-.628 7.048-.683.734-.017 2.817-.382 3.073-1.093.206-.575.997-2.406.215-2.903-1.185-.754-1.17-2.412-2.042-3.382-1.604-1.785-2.426-3.986-3.954-5.841-2.442-2.966-4.853-4.849-8.896-6.456-6.2-2.464-16.834-11.397-23.608-11.995-5.233-.462-5.721-5.119-10.817-3.769"
      stroke="#003872"
      strokeWidth={3}
      strokeLinecap="round"
    />
    <path
      d="M78.797 148.445c1.974 8.36 1.326 21.863 6.509 28.86 2.059 2.78-.463 1.005 2.106 3.36 1.648 1.51 2.935 3.296 4.563 4.836 1.396 1.319 3.306 1.164 4.94 2.198"
      stroke="#003872"
      strokeWidth={3}
      strokeLinecap="round"
    />
    <path
      d="M96.607 171.241c-1.909.892-9.079 5.312-12.028 6.503l-.364 9.679c1.4-.895 8.965-.698 10.467.394 1.412 1.027 3.053-2.387 4.39-1.29.736.603.268-2.268 1.724-1.051.511.427 2.899 4.288 3.54 3.12.639-1.166 1.735-4.32 1.322-5.54-.602-1.773 1.596 2.076 2.662 3.616.965 1.394 1.144 2.588 2.798 2.985 1.12.268 1.663-1.071 1.793-1.821 1.178-6.834-1.04-15.509-8.201-18.23-1.937-.736-2.559-.542-4.625-.011-1.375.353-2.227 1.061-3.478 1.646Z"
      fill="#fff"
      stroke="#003872"
      strokeWidth={3}
      strokeLinecap="round"
    />
    <path
      d="M118.726 17.64c.144-.95 1.416-1.17 1.87-.322l4.041 7.551a1 1 0 0 0 1.475.334l6.896-5.078c.775-.57 1.829.174 1.55 1.095l-2.482 8.197a1 1 0 0 0 .807 1.278l8.468 1.286c.95.144 1.169 1.416.321 1.87l-7.551 4.041a1 1 0 0 0-.333 1.475l5.078 6.896c.57.775-.175 1.829-1.095 1.55l-8.197-2.482a1 1 0 0 0-1.278.807l-1.286 8.468c-.144.95-1.417 1.17-1.87.321l-4.041-7.55a1 1 0 0 0-1.475-.334l-6.897 5.078c-.774.57-1.828-.175-1.55-1.095l2.482-8.197a1 1 0 0 0-.807-1.278l-8.467-1.286c-.951-.144-1.17-1.417-.322-1.87l7.551-4.041a1 1 0 0 0 .334-1.475l-5.078-6.896c-.571-.775.174-1.83 1.095-1.55l8.197 2.482a1 1 0 0 0 1.278-.807l1.286-8.468Z"
      fill="#E2F2FF"
    />
    <rect
      x={93.864}
      y={5.776}
      width={60.778}
      height={60.778}
      rx={8}
      transform="rotate(5.24 93.864 5.776)"
      stroke="#003872"
      strokeWidth={3}
      strokeLinecap="round"
    />
    <path
      d="M103.076 26.085c3.879-2.15 8.893-7.631 11.747-2.146 2.066 3.969 7.141 2.628 10.755 1.523 6.458-1.976 15.287.328 22.059.949M99.338 37.55c2.913-.224 9.508-1.531 12.104.155.685.445-.119 3.904-.189 4.668-.538 5.863 12.314-.24 14.504-.968 1.559-.518 3.583-.913 5.229-.386 1.067.342 1.051 1.54 2.479 1.929 3.573.974 6.832.507 10.43.837M100.707 51.924c.772.07 7.338-.13 7.851.959.228.483.17 1.716.776 1.951 3.705 1.436 9.371.72 13.027-.148 5.354-1.273 8.345.526 13.459.995"
      stroke="#003872"
      strokeWidth={3}
      strokeLinecap="round"
    />
  </svg>
);

export default NoComments;
