import React from 'react';
import PropTypes from 'prop-types';

import { Box, Flex, Loader } from '../../mc-ui';

import { fetchGroupOverview } from '../utils/api';

import Brands from './Brands';
import Emails from './Emails';
import Header from './Header';
import Journeys from './Journeys';
import Stats from './Stats';

const PAGE_SIZE = 15;

class GroupViews extends React.PureComponent {
  state = {
    loading: true,
    overview: {},
  };

  componentDidMount() {
    this.request();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.group.id !== this.props.group.id) {
      this.request();
    }
  }

  request = () => {
    const { group } = this.props;

    this.setState(
      () => ({ loading: true }),
      async () => {
        const overview = await fetchGroupOverview(group.id);
        this.setState(() => ({ loading: false, overview }));
      },
    );
  };

  renderView = () => {
    const { group } = this.props;
    const { type } = this.props;
    let view = null;

    if (type === 'companies') {
      view = <Brands group={group} pageSize={PAGE_SIZE} />;
    } else if (type === 'emails') {
      view = <Emails group={group} pageSize={PAGE_SIZE} />;
    } else if (type === 'journeys') {
      view = <Journeys group={group} pageSize={3} />;
    }

    return view;
  };

  render() {
    const { group, type, } = this.props;
    const { loading, overview } = this.state;

    if (loading) {
      return (
        <Flex alignItems="center" height="652px" justifyContent="center" width="824px">
          <Loader />
        </Flex>
      );
    }

    return (
      <Box>
        <Header group={group} />
        {type !== 'journeys' && <Stats overview={overview} />}

        {this.renderView()}
      </Box>
    );
  }
}

GroupViews.propTypes = {
  group: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  type: PropTypes.string.isRequired,
};

export default GroupViews;
