/** @jsx jsx */
import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { jsx, withTheme } from '@emotion/react';

import { subjectLinesApi as api } from '../../../../modules/newReports/subjectLines';

import { shallowEqual } from '../../../../lib/utils';
import { Flex, Loader, Pagination, Table, Tag } from '../../../mc-ui';

class GroupTab extends React.Component {
  state = {
    loading: true,
    popularTerms: [],
  };

  componentDidMount() {
    if (this.props.model.id) {
      this.request(this.props);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.model.id) return;
    const nextModel = { id: nextProps.model.id, type: nextProps.model.type };
    const model = { id: this.props.model.id, type: this.props.model.type };
    const nextDates = { endDate: nextProps.endDate, startDate: nextProps.startDate };
    const dates = { endDate: this.props.endDate, startDate: this.props.startDate };

    if (!shallowEqual(nextModel, model) || !shallowEqual(nextDates, dates)) {
      this.setState(
        () => ({ loading: true }),
        () => {
          this.request(nextProps);
        },
      );
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const nextModel = { id: nextProps.model.id, type: nextProps.model.type };
    const model = { id: this.props.model.id, type: this.props.model.type };
    const nextDates = { endDate: nextProps.endDate, startDate: nextProps.startDate };
    const dates = { endDate: this.props.endDate, startDate: this.props.startDate };

    return (
      !shallowEqual(nextModel, model) ||
      !shallowEqual(nextDates, dates) ||
      !shallowEqual(nextState, this.state)
    );
  }

  request = async ({ model, endDate, startDate }) => {
    if (!endDate || !startDate) return;

    const response = await api.fetchPopularTerms(model.id, model.type, startDate, endDate);

    const popularTerms = response.meta.stats.popular_terms.buckets.map(bucket => ({
      key: `${bucket.key}-${bucket.doc_count}`,
      docCount: bucket.doc_count,
      term: bucket.key,
      word: bucket.word,
      email: bucket.emails[0],
    }));

    this.setState(() => ({ loading: false, popularTerms }));
  };

  render() {
    const { endDate, startDate, theme } = this.props;
    const { router } = this.context;
    const { loading, popularTerms } = this.state;

    if (loading) {
      return (
        <Flex alignItems="center" justifyContent="center" css={{ minHeight: '481px' }}>
          <Loader />
        </Flex>
      );
    }

    return (
      <Pagination collection={popularTerms} pageSize={5}>
        {({ collection, page, pageSize }) => (
          <Table>
            <Table.Head>
              <Table.Row>
                <Table.Cell colSpan={2} width="585px">
                  Popular Terms
                </Table.Cell>
                <Table.Cell align="center" width="105px">
                  Total Emails
                </Table.Cell>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {collection.map((row, index) => (
                <Table.Row key={row.key}>
                  <Table.IndexCell
                    verticalAlign="top"
                    index={index}
                    page={page}
                    pageSize={pageSize}
                  />
                  <Table.Cell width="585px">
                    <Link
                      to={{
                        pathname: router.location.pathname.replace('/subject-lines', '/emails'),
                        query: {
                          q: row.word,
                          filter: 'subject',
                          end_date: endDate,
                          start_date: startDate,
                        },
                      }}
                    >
                      <Tag color="dark08" css={{ marginBottom: '8px' }}>{row.word}</Tag>
                    </Link>
                    <br />
                    <Link
                      to={{
                        pathname: `/emails/${row.email.attributes.guid}`,
                        state: { modal: true, returnTo: router.location },
                      }}
                      css={{
                        color: `${theme.colors.dark1} !important`,
                        display: 'block',
                        overflow: 'hidden',
                        textDecoration: 'none',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        width: '505px',
                      }}
                    >
                      <strong
                        css={{
                          fontWeight: 'bold !important',
                          color: `${theme.colors.dark04} !important`,
                        }}
                      >
                        Example:
                      </strong>{' '}
                      {row.email.attributes.subject}
                    </Link>
                  </Table.Cell>
                  <Table.Cell align="center" width="105px">
                    <Link
                      to={{
                        pathname: router.location.pathname.replace('/subject-lines', '/emails'),
                        query: {
                          q: row.word,
                          filter: 'subject',
                          end_date: endDate,
                          start_date: startDate,
                        },
                      }}
                    >
                      View {row.docCount} {pluralize('email', row.docCount)}
                    </Link>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
      </Pagination>
    );
  }
}

GroupTab.contextTypes = {
  router: PropTypes.shape({}).isRequired,
};

GroupTab.defaultProps = {
  endDate: null,
  startDate: null,
};

GroupTab.propTypes = {
  model: PropTypes.shape({ id: PropTypes.string, type: PropTypes.string }).isRequired,
  endDate: PropTypes.string,
  startDate: PropTypes.string,
  theme: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => ({
  endDate: state.newReports.dates.endDate,
  startDate: state.newReports.dates.startDate,
});

export default connect(mapStateToProps)(withTheme(GroupTab));
