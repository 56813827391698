import React from 'react';
import PropTypes from 'prop-types';

import { Tab } from '../../../../mc-ui';

import EmailsList from './EmailsList';

const Emails = ({ buyOneGetOneFree, dollarsOff, freeShipping, percentOff, onEmailChange }) => (
  <Tab defaultActive="percentOff" css={{ marginTop: '40px' }} onTabChange={onEmailChange}>
    <Tab.Header>
      <Tab.Item name="percentOff">Percent-Off</Tab.Item>
      <Tab.Item name="dollarsOff">Dollars-Off</Tab.Item>
      <Tab.Item name="freeShipping">Free Shipping</Tab.Item>
      <Tab.Item name="buyOneGetOneFree">Buy One, Get One Free</Tab.Item>
    </Tab.Header>
    <Tab.Body>
      {({ active }) => {
        if (active === 'percentOff') return <EmailsList data={percentOff} />;
        if (active === 'dollarsOff') return <EmailsList data={dollarsOff} />;
        if (active === 'freeShipping') return <EmailsList data={freeShipping} />;
        return <EmailsList data={buyOneGetOneFree} />;
      }}
    </Tab.Body>
  </Tab>
);

Emails.defaultProps = {
  onEmailChange: () => {},
};

Emails.propTypes = {
  buyOneGetOneFree: PropTypes.shape({}).isRequired,
  dollarsOff: PropTypes.shape({}).isRequired,
  freeShipping: PropTypes.shape({}).isRequired,
  percentOff: PropTypes.shape({}).isRequired,
  onEmailChange: PropTypes.func,
};

export default Emails;
