import React from 'react';

function SvgClassicViewIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" fill="none" {...props}>
      <path d="M15.5 5.526L24 11l14-8" stroke="#A5ADBA" />
      <circle cx={39} cy={2} r={2} fill="#A5ADBA" />
      <circle cx={15} cy={5} r={2} fill="#A5ADBA" />
      <path d="M4.5 48l.4-6h5.6l-4 6h-2zM43.5 48l-.4-6h-5.6l4 6h2z" fill="#A5ADBA" />
      <rect y={10} width={48} height={32} rx={4} fill="#5E6C84" />
      <rect x={5} y={15} width={30} height={22} rx={2} fill="#F3F5F7" />
      <circle cx={41} cy={22} r={3} fill="#F3F5F7" />
      <circle cx={41} cy={31} r={2} fill="#F3F5F7" />
    </svg>
  );
}

export default SvgClassicViewIcon;
