import styled from "@emotion/styled";

const Body = styled("div")(({
  customScrollbar = false,
  height = '705px',
  maxHeight = '705px',
  padding = '12px 32px',
  theme,
  ...props
}) => {
  const headerHeight = 79;
  const footerHeight = 64;
  const wrapperHeight = Number((maxHeight || height).replace('px', ''));
  const bodyHeight = wrapperHeight - (headerHeight + footerHeight);
  let customScrollbarCss = {};

  if (customScrollbar) {
    customScrollbarCss = {
      marginBottom: '12px',
      marginRight: '10px',
      overflowY: 'scroll',
      padding: '12px 22px 12px 32px',
      scrollbarWidth: 'thin',
      '&::-webkit-scrollbar': {
        WebkitAppearance: 'none',
        width: '6px',
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '4px',
        backgroundColor: theme.colors.dark01,
      },
      ...props.customScrollbarCss,
    };
  }

  return {
    boxSizing: 'border-box',
    height: bodyHeight,
    overflowY: 'auto',
    padding,
    '& h4': {
      margin: 0,
      marginBottom: '16px',
      marginTop: '32px',
      padding: 0,
    },
    '& fieldset': {
      margin: 0,
      padding: 0,
      '& h5': {
        margin: 0,
        marginBottom: '5px',
        padding: 0,
      },
    },
    ...customScrollbarCss,
  };
});

export default Body;
