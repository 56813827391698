import React from 'react'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import api from '../lib/api'

import Container from './Container'

class Confirm extends React.Component {
  state = {
    loading: true,
    user: { attributes: {} },
  }
  handleSubmit = (event) => {
    event.preventDefault()
    let anonymousId = undefined
    const options = { ...this.state.user.attributes }
    api.post('/v2/user', options)
      .then(r => {
        const user = { ...r.data }
        window.analytics.alias(user.id)
        window.analytics.identify(user.id)
        window.analytics.track('Account created')
        this.props.router.replace('/')
      })
      .catch(err => this.props.dispatch({ type: 'INVALID_USER', error: err }))
  }
  handleChange = (event) => {
    event.preventDefault()
    const user = { ...this.state.user };
    user.attributes[event.target.name] = event.target.value
    this.setState({ user })
  }
  componentDidMount() {
    let { email, token } = this.props.router.location.query
    api.get(`/v2/user/confirm`, { params: { token, email } })
      .then(r => {
        let user = r.data.data.reduce((m, u) => u, {})
        user.attributes.token = token
        let loading = false
        this.setState({ user, loading })
      })
      .catch(err => {
        console.error(err)
      })
  }
  render() {
    return (
      <div style={{}}>
      <div className='pure-g'>
        <div
          style={{
            background: `url('/signup.svg')`,
            backgroundSize: 'cover',
            padding: '100px 0',
            textAlign: 'center',
          }}
          className='pure-u-1'
        >
          <h1
            style={{
              color: '#ffffff',
            }}
          >
            Just one more step: Select your password
          </h1>
          <h2
            style={{
              color: '#ffffff',
            }}
          >
            And verify that the below information is correct.
          </h2>
        </div>
        <div
          className='pure-g'
          style={{
            width: '100%',
            margin: '0 auto',
          }}
        >
          <div
            className='pure-u-1'
            style={{
              textAlign: 'center',
              marginTop: -75,
              padding: '0 2%',
            }}
          >
            <Container
              style={{ margin: '0 auto', maxWidth: 400, padding: 50, }}
            >
          {this.state.loading ?
          <div className='pure-u-1'>
            <h2>Loading your account...</h2>
          </div>
          :
          <div className='pure-u-1'>
            {this.props.user.error ? <p>Error</p> : null}
            <form className='pure-form' onSubmit={this.handleSubmit}>
              <fieldset className='pure-group'>
                <input
                  type='text'
                  name='first_name'
                  style={{ textAlign: 'center' }}
                  className='pure-u-1'
                  placeholder='First name'
                  value={this.state.user.attributes.first_name}
                  onChange={this.handleChange}
                />
                <input
                  type='text'
                  name='last_name'
                  style={{ textAlign: 'center' }}
                  value={this.state.user.attributes.last_name}
                  onChange={this.handleChange}
                  className='pure-u-1'
                  placeholder='Last name'
                />
              </fieldset>
              <fieldset className='pure-group'>
                <input
                  type='text'
                  name='company_name'
                  value={this.state.user.attributes.company_name}
                  onChange={this.handleChange}
                  style={{ textAlign: 'center' }}
                  className='pure-u-1'
                  placeholder='Company name'
                />
                <input
                  type='text'
                  name='website'
                  value={this.state.user.attributes.website}
                  onChange={this.handleChange}
                  style={{ textAlign: 'center' }}
                  className='pure-u-1'
                  placeholder='Website'
                />
              </fieldset>
              <fieldset className='pure-group'>
                <input
                  type='email'
                  name='email'
                  disabled
                  value={this.state.user.attributes.email}
                  onChange={this.handleChange}
                  style={{ textAlign: 'center' }}
                  className='pure-u-1'
                  placeholder='Email address'
                />
                <input
                  required
                  name='password'
                  type='password'
                  style={{ textAlign: 'center' }}
                  className='pure-u-1'
                  placeholder='Password'
                  onChange={this.handleChange}
                />
              </fieldset>
              <div
                style={{ fontSize: '12px', textAlign: 'left', color: 'rgba(9,30,66,0.8)' }}
              >
                <span> Passwords must be between 8 and 100 characters and include </span>
                <ul>
                  <li>a lowercase character</li>
                  <li>an uppercase character</li>
                  <li>a number</li>
                  <li>a special character</li>
                </ul>
              </div>
              {this.props.user.error ? <p>Please try again</p> : null}
              <input type='submit' className='pure-button pure-button-primary pure-u-1' value='Log in' />
              <div className='pure-u-1'>
                <p>
                  <Link
                    style={{ color: '#3285ce' }}
                    to={{ pathname: '/signin', query: { email: this.state.user.attributes.email } }}
                  >
                    Already have an account? Sign in.
                  </Link>
                </p>
              </div>
            </form>
          </div>}
            </Container>
          </div>
        </div>
      </div>
        <div className='pure-g'>
        </div>
      </div>
    )
  }
}

const s = (state) => ({ user: state.user })
export default connect(s)(Confirm)
