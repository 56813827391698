import React from 'react'
import { Link } from 'react-router'
import { connect } from 'react-redux'

import GroupsCompaniesRow from './groups/GroupsCompaniesRow'
import axios from 'axios'

import url from 'url'
import querystring from 'querystring'

import api from '../lib/api'

// IF YOURE LOOKING A COMPANY, YOU NEED TO SELECT THE GROUP
// IF YOURE LOOKING AT A GROUP, YOU NEED TO SELECT THE COMPANY

const PARAMS = {
  owner: true,
  limit: 5
}

class GroupsAddCompany extends React.Component {
  state = {
    loading: true,
    company: null,
    group: null,
    q: '',
  }
  addOrRemove = (options) => {
    let company = this.props.companies.data.reduce((m, c) => c, null)
    let params = { company_id: company.id }
    let req = (options.included ?
     api.delete(`/v2/groups/${options.group.id}/companies`, { params })
     :
     api.post(`/v2/groups/${options.group.id}/companies`, params)
    )
    req.then(r => {
      // this.props.dispatch({ type: 'REQUEST_GROUPS', params })
    })
  }
  paginate = (event) => {
    event.preventDefault()
    let href = event.target.href
    let obj = url.parse(href)
    let params = querystring.parse(obj.search.replace('?', ''))
    let query = { ...this.props.router.location.query, page: params.page  }
    this.props.router.push({
      pathname: this.props.router.location.pathname,
      query
    })
    // this.props.dispatch({ type: 'REQUEST_GROUPS', query })
  }
  componentDidMount() {
    let params = { ...PARAMS, ...this.props.router.location.query }
    if (params.company_id && this.props.companies.data.length == 0)
      this.props.dispatch({ type: 'REQUEST_COMPANIES', ...params })
  }
  suggest = (event) => {
    event.preventDefault()
    this.setState({ q: event.target.value })
    if (this.state.q.length < 1) return
    if (this.timeout) clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      clearTimeout(this.timeout)
      let params = { ...PARAMS, filter: this.state.q }
      this.props.dispatch({ type: 'REQUEST_GROUPS', params })
    }, 200)
  }
  render() {
    let company = this.props.companies.data.reduce((m, c) => c, null)
    let groups = this.props.user.included.filter(i => i.type == 'groups')
    return this.props.companies.loading ? null : (
        <div className='pure-u-1'>
          <h3>Add {company ? company.attributes.name : 'company' } to groups</h3>
          {groups.length == 0 && this.state.q == '' ?
            null
            :
            <form className='pure-form' onSubmit={(e) => e.preventDefault()}>
              <label htmlFor='q'>
                <h5>Find groups</h5>
              </label>
              <input
                className='pure-u-1'
                type='search'
                autoComplete='off'
                placeholder='Find'
                id='q'
                name='q'
                value={this.state.q}
                onChange={this.suggest}
              />
            </form>
          }
          {groups.length ?
          <div className='pure-u-1'>
            <h5>Your groups</h5>
            {groups.map(m => {
              return (<div className='pure-u-1' key={m.id} style={{ marginBottom: 10 }}>
                <GroupsCompaniesRow
                  handleClick={this.addOrRemove}
                  company_id={company.id}
                  group={m}
                />
              </div>)
            })}
            {this.props.groups.links.next || this.props.groups.links.prev ?
            <div className='pure-u-1'>
              <div className='pure-u-1-2'>
                {this.props.groups.links.prev ?
                  <p style={{ margin: 0, fontSize: `${13/16}em` }}>
                    <a
                      href={this.props.groups.links.prev}
                      onClick={this.paginate}
                    >
                      Previous
                    </a>
                  </p>
                : null}
              </div>
              <div className='pure-u-1-2' style={{ textAlign: 'right' }}>
                {this.props.groups.links.next ?
                  <p style={{ margin: 0, fontSize: `${13/16}em` }}>
                    <a
                      href={this.props.groups.links.next}
                      onClick={this.paginate}
                    >
                      Next
                    </a>
                  </p>
                : null}
              </div>
            </div> : null}
          </div>
          :
          <div className='pure-u-1' style={{ textAlign: 'center', margin: '0 auto' }}>
            <img src='/empty-groups.png' width='222' style={{ marginTop: '1.67em' }}/>
            {this.state.q ?
            <h3>None of your groups match <em>{this.state.q}</em></h3>
            :
            <h3>No groups here yet</h3>
            }
            <p>You can create new group right away. Click the magic blue button!</p>
          </div>
          }
          <div className='pure-u-1'>
            <div
              style={{
                background: '#F3F5F7',
                padding: 25,
              }}
            >
              <div className='pure-u-1-2'>
                <p>
                  <Link
                    to='#'
                    className='pure-button pure-button-primary'
                  >
                    Create new group
                  </Link>
                </p>
              </div>
              {(this.props.router.location.state || {}).returnTo ?
              <div className='pure-u-1-2' style={{ textAlign: 'right' }}>
                <p>
                  <Link
                    to={this.props.router.location.state.returnTo}
                    className='pure-button'
                  >
                    Done
                  </Link>
                </p>
              </div> : null}
            </div>
          </div>
        </div>
    )
  }
}

const s = (state) => ({ user: state.user, companies: state.companies, groups: state.groups })
export default connect(s)(GroupsAddCompany)
