const INITIAL_STATE = { loading: true, links: {}, meta: {}, data: [], included: [] }
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'REQUEST_GROUPS': return {...state, loading: true }
    case 'CATCH_ERROR': return { ...action.error, loading: false};
    case 'RECEIVE_GROUPS': return { ...action.data, loading: false }
    case 'REQUEST_GROUPS_PAGINATION': return { ...state, paginating: true }
    case 'GROUPS_ERROR': return { ...INITIAL_STATE, loading: false }
    case 'FLUSH': return { ...INITIAL_STATE }
    case 'RECEIVE_GROUPS_PAGINATION':
      let data = [].concat(state.data || [], action.groups.data)
      return { ...state, data, paginating: false, links: action.groups.links }
    default: return state
  }
}
