import { call, put, select } from 'redux-saga/effects';
import { camelizeKeys } from 'humps';

import * as actions from './actions';
import * as api from './api';
import * as selectors from './selectors';

const isModelPresent = model => model && model.id;
const isModelValid = (model, currentModel) =>
  isModelPresent(model) && (model.id !== currentModel.id || model.type !== currentModel.type);

export function* fetchData({ model }) {
  try {
    const currentModel = yield select(selectors.getModel);

    if (isModelValid(model, currentModel)) {
      const response = yield call(api.fetchData, model);
      const [company] = response.included.filter(i => i.type === 'companies');
      const latestEmails = response.included.filter(i => i.type == 'emails');
      const { stats } = response.meta;

      const benchmarks = {
        frequency: stats.benchmarks_frequency,
        promotional: stats.benchmarks_promotional,
      };

      const payload = {
        benchmarks,
        brand: camelizeKeys(company),
        current: stats.current,
        days: stats.days,
        frequency: stats.frequency,
        latestEmails,
        mailchartsScore: stats.mailcharts_score,
        meta: camelizeKeys(response.meta),
        mobileOptimized: stats.mobile_optimized,
        mostPopularEmojis: stats.most_popular_emojis,
        popularTerms: stats.popular_terms,
        previous: stats.previous,
        promotionTypes: stats.promotion_types,
        promotional: stats.promotional,
        sentAtDaysOfWeek: stats.sent_at_days_of_week,
        sentAtHoursOfDay: stats.sent_at_hours_of_day,
        subjectLineDistribution: stats.subject_line_distribution,
        subjectLineEmoji: stats.subject_line_emoji,
        subjectLineLength: stats.subject_line_length,
        totalEmails: stats.total_emails,
        weeks: stats.weeks,
      };

      yield put(actions.fetchDataSuccess(payload));
      yield put(actions.setModel(model));
    } else if (isModelPresent(model)) {
      yield put(actions.toggleDataLoading());
    }
  } catch (error) {
    console.log('error', error);
    yield put(actions.fetchDataFailure(error));
  }
}

// **********  JOURNEYS **********

export function* getJourneys({ model }) {
  try {
    const currentModel = yield select(selectors.getModel);

    if (isModelValid(model, currentModel)) {
      const { data } = yield call(api.getJourneys, model);

      yield put(actions.getJourneysSuccess(data));
      yield put(actions.setModel(model));
    } else if (isModelPresent(model)) {
      yield put(actions.toggleJourneysLoading());
    }
  } catch (error) {
    console.log('error', error);
    yield put(actions.getJourneysFailure(error));
  }
}
