import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { teamsActions, teamsSelectors } from '../../../../modules/teams';

import { Button, Flex, Loader, Table, theme } from '../../../mc-ui';

import MemberStatusBadge from './MemberStatusBadge';
import RoleSelect from './RoleSelect';
import RemoveConfirmationModal from './RemoveConfirmationModal';
import ToggleMemberStatusConfirmationModal from './ToggleMemberStatusConfirmationModal';

const MODALS = {
  removeMember: 'remove-member',
  toggleMemberStatus: 'toggle-member-status',
};

const cellCSS = {
  fontSize: '12px',
  fontWeight: '500',
  letterSpacing: '0.02em',
  lineHeight: '1.25em',
};

function isRoleSelectEnabled(isAdmin, isOwner, member, user) {
  if (isOwner) {
    return member.id !== user.id;
  }

  if (isAdmin) {
    if (member.id === user.id || member.attributes.role !== 'owner') {
      return true;
    }

    return false;
  }

  return false;
}

class Members extends React.Component {
  state = {
    modal: null,
    selectedMemberEmail: '',
    selectedMemberId: null,
    toggleMemberActionName: null,
  };

  componentDidMount() {
    this.props.requestTeam();
  }

  handleCancelInvitation = event => {
    event.preventDefault();
    const { invitationId } = event.target.dataset;
    this.props.cancelInvitation(invitationId);
  };

  handleArchiveMember = event => {
    event.preventDefault();

    const { memberId, memberEmail } = event.target.dataset;
    const { archiveMember, isNewPlan, isSubscriptionAutomaticallyCharged, planExpired, } = this.props;

    if (isNewPlan && isSubscriptionAutomaticallyCharged && !planExpired) {
      this.setState({
        modal: MODALS.toggleMemberStatus,
        selectedMemberId: memberId,
        selectedMemberEmail: memberEmail,
        toggleMemberActionName: 'archive',
      });
    } else {
      archiveMember(memberId);
    }
  };

  handleRemoveMember = event => {
    event.preventDefault();
    const { memberId, memberEmail } = event.target.dataset;
    this.setState({
      modal: MODALS.removeMember,
      selectedMemberId: memberId,
      selectedMemberEmail: memberEmail,
    });
  };

  handleResendInvitation = event => {
    event.preventDefault();
    const { invitationId } = event.target.dataset;
    this.props.resendInvitation(invitationId);
  };

  handleActivateMember = event => {
    event.preventDefault();

    const { memberId, memberEmail } = event.target.dataset;
    const { isNewPlan, isSubscriptionAutomaticallyCharged, team, activateMember } = this.props;

    if (isNewPlan && isSubscriptionAutomaticallyCharged) {
      this.setState({
        modal: MODALS.toggleMemberStatus,
        selectedMemberId: memberId,
        selectedMemberEmail: memberEmail,
        toggleMemberActionName: 'activate',
      });
    } else {
      activateMember(team.id, memberId, { status: 'active' });
    }
  };

  closeModal = () => {
    this.setState({ modal: null });
  };

  render() {
    const { isAdmin, isOwner, loading, members, planExpired, team, updateMember } = this.props;
    const { modal, selectedMemberId, selectedMemberEmail, toggleMemberActionName } = this.state;

    if (loading) {
      return (
        <Flex alignItems="center" height="87px" justifyContent="center">
          <Loader />
        </Flex>
      );
    }

    const [user] = this.props.user.data;
    const ownersTotal = members.filter(member => member.attributes.role === 'owner').length;

    return (
      <div>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Cell>Member</Table.Cell>
              <Table.Cell>Email</Table.Cell>
              <Table.Cell>Status</Table.Cell>
              <Table.Cell>Role</Table.Cell>
              <Table.Cell />
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {members.map(member => (
              <Table.Row key={`TeamMember${member.id}`}>
                <Table.Cell color="dark08" css={cellCSS} width="176px">
                  {member.attributes.name}
                </Table.Cell>
                <Table.Cell color="dark08" css={cellCSS} width="210px">
                  {member.attributes.email}
                </Table.Cell>
                <Table.Cell color="dark08" css={cellCSS} width="80px">
                  <MemberStatusBadge
                    status={member.id === user.id ? 'you' : member.attributes.status}
                  />
                </Table.Cell>
                <Table.Cell color="dark08" css={cellCSS} width="128px">
                  <RoleSelect
                    enabled={isRoleSelectEnabled(isAdmin, isOwner, member, user)}
                    memberId={member.id}
                    onUpdate={updateMember}
                    role={member.attributes.role}
                    teamId={member.attributes.team_id}
                  />
                </Table.Cell>
                <Table.Cell align="right">
                  {member.attributes.status === 'active' &&
                    ((isOwner && member.id !== user.id) ||
                      (isAdmin && member.id !== user.id && member.attributes.role !== 'owner')) && (
                      <Button
                        css={{ margin: 0, padding: '4px 8px' }}
                        data-member-id={member.id}
                        data-member-email={member.attributes.email}
                        onClick={this.handleArchiveMember}
                        variant="secondary"
                      >
                        Archive
                      </Button>
                    )}
                  {(isAdmin || isOwner) &&
                    member.type === 'invitations' &&
                    member.attributes.status === 'pending' && (
                      <Button
                        css={{ margin: '0 8px 0 0', padding: '4px 8px' }}
                        data-invitation-id={member.id}
                        disabled={planExpired}
                        onClick={this.handleResendInvitation}
                        variant="secondary"
                      >
                        Resend
                      </Button>
                    )}
                  {(isAdmin || isOwner) &&
                    member.type === 'invitations' &&
                    member.attributes.status === 'pending' && (
                      <Button
                        css={{ color: theme.colors.red1, margin: 0, padding: '4px 8px' }}
                        data-invitation-id={member.id}
                        onClick={this.handleCancelInvitation}
                        variant="secondary"
                      >
                        Cancel
                      </Button>
                    )}
                  {((isOwner && member.id === user.id && ownersTotal > 1) ||
                    (!isOwner && member.id === user.id)) && (
                    <Button
                      css={{ margin: 0, padding: '4px 8px' }}
                      data-member-id={member.id}
                      onClick={this.handleRemoveMember}
                      variant="secondary"
                    >
                      Leave
                    </Button>
                  )}
                  {(isAdmin || isOwner) &&
                    member.attributes.status === 'archived' && (
                      <Button
                        css={{ margin: '0 8px 0 0', padding: '4px 8px' }}
                        data-member-id={member.id}
                        data-member-email={member.attributes.email}
                        onClick={this.handleActivateMember}
                        disabled={planExpired}
                        variant="secondary"
                      >
                        Activate
                      </Button>
                    )}
                  {(isAdmin || isOwner) &&
                    member.attributes.status === 'archived' && (
                      <Button
                        css={{ color: theme.colors.red1, margin: 0, padding: '4px 8px' }}
                        data-member-id={member.id}
                        data-member-email={member.attributes.email}
                        onClick={this.handleRemoveMember}
                        variant="secondary"
                      >
                        Remove
                      </Button>
                    )}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        {modal === MODALS.removeMember &&
          selectedMemberId && (
            <RemoveConfirmationModal
              closeModal={this.closeModal}
              memberEmail={selectedMemberEmail}
              memberId={selectedMemberId}
              isAdminOrOwner={isAdmin || isOwner}
              isSelf={user.id === selectedMemberId}
              teamId={team.id}
            />
          )}

        {modal === MODALS.toggleMemberStatus &&
          toggleMemberActionName &&
          selectedMemberId && (
            <ToggleMemberStatusConfirmationModal
              actionName={toggleMemberActionName}
              closeModal={this.closeModal}
              memberEmail={selectedMemberEmail}
              memberId={selectedMemberId}
              teamId={team.id}
            />
          )}
      </div>
    );
  }
}

Members.defaultProps = {
  team: {
    attributes: {
      admins: [],
    },
  },
};

Members.propTypes = {
  archiveMember: PropTypes.func.isRequired,
  cancelInvitation: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isNewPlan: PropTypes.bool.isRequired,
  isOwner: PropTypes.bool.isRequired,
  isSubscriptionAutomaticallyCharged: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  members: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      attributes: PropTypes.shape({
        email: PropTypes.string,
        name: PropTypes.string,
        role: PropTypes.string,
        status: PropTypes.string,
      }),
    }),
  ).isRequired,
  planExpired: PropTypes.bool.isRequired,
  requestTeam: PropTypes.func.isRequired,
  resendInvitation: PropTypes.func.isRequired,
  team: PropTypes.shape({
    attributes: PropTypes.shape({
      admins: PropTypes.arrayOf(PropTypes.string),
    }),
  }),
  activateMember: PropTypes.func.isRequired,
  updateMember: PropTypes.func.isRequired,
  user: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      }),
    ),
  }).isRequired,
};

const mapStateToProps = state => ({
  isAdmin: teamsSelectors.isAdminSelector(state),
  isNewPlan: teamsSelectors.isNewPlanSelector(state),
  isOwner: teamsSelectors.isOwnerSelector(state),
  isSubscriptionAutomaticallyCharged: teamsSelectors.isSubscriptionAutomaticallyChargedSelector(
    state,
  ),
  loading: teamsSelectors.loadingSelector(state),
  members: teamsSelectors.membersSelector(state),
  planExpired: teamsSelectors.planExpiredSelector(state),
  team: teamsSelectors.teamDataSelector(state),
  user: state.user,
});

const mapDispatchToProps = (dispatch, { teamId }) => ({
  archiveMember: id => dispatch(teamsActions.archiveMemberRequest(teamId, id)),
  cancelInvitation: id => dispatch(teamsActions.cancelInvitationRequest(teamId, id)),
  requestTeam: () => dispatch(teamsActions.fetchTeamRequest(teamId)),
  resendInvitation: id => dispatch(teamsActions.resendInvitationRequest(teamId, id)),
  updateMember: (...args) => dispatch(teamsActions.updateMemberRequest(...args)),
  activateMember: (...args) => dispatch(teamsActions.activateMemberRequest(...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Members);
