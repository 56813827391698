import api from '../../lib/api';

export const createList = async data => {
  const response = await api.post('/v2/lists', data);
  return response;
};

export const deleteList = async id => {
  await api.delete(`/v2/lists/${id}`);
};

export const updateList = async (id, data) => {
  const response = await api.patch(`/v2/lists/${id}`, data);
  return response;
};

export const fetchUserLists = async (params = {}) => {
  const response = await api.get(`/v3/user/lists`, { params });
  return response;
};

export const fetchTeamLists = async (teamId, params) => {
  const response = await api.get(`/v3/teams/${teamId}/lists`, { params });
  return response;
};

export const shareWithTeam = async (teamId, listId) => {
  const response = await api.post(`/v3/teams/${teamId}/lists`, { list_id: listId });
  return response;
};

export const deleteTeamList = async (teamId, listId) => {
  const response = await api.delete(`/v3/teams/${teamId}/lists/${listId}`);
  return response;
};

export const updateTeamList = async (teamId, id, data) => {
  const response = await api.patch(`/v3/teams/${teamId}/lists/${id}`, data);
  return response;
};

export const addItemsToList = async (listId, data) => {
  const response = await api.post(`/v3/lists/${listId}/add`, { data });
  return response;
};

export const removeItemsFromList = async (listId, data) => {
  const response = await api({
    method: 'delete',
    url: `/v3/lists/${listId}/remove`,
    data: { data },
  });
  return response;
};
