import styled from "@emotion/styled";

const ListName = styled("div")({
  color: '#242424',
  flex: '1',
  fontSize: '14px',
  lineHeight: '20px',
  '& small': {
    color: '#a0afba',
    display: 'block',
    fontSize: '14px',
    lineHeight: '20px'
  }
})

export default ListName
