const INITIAL_STATE = { loading: true, links: {}, meta: {}, data: [], included: [] }
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'FLUSH': return { ...INITIAL_STATE }
    case 'REQUEST_ADDRESS': return {...state, loading: true }
    case 'RECEIVE_ADDRESS': return { ...action.data, loading: false }
    default: return state
  }
}

