import React from 'react';

import NavigationDropdown from '../NavigationDropdown';

import NavigationItem from './NavigationItem';

const Emails = () => (
  <NavigationItem label="Emails" to="/emails">
    <NavigationDropdown>
      <NavigationDropdown.Item to="/emails">Emails Home</NavigationDropdown.Item>
      <NavigationDropdown.Item to="/browse/technologies">
        ESPs and Technology
      </NavigationDropdown.Item>
      <NavigationDropdown.Divider />
      <NavigationDropdown.Item to="/lists/681">Animated GIFs</NavigationDropdown.Item>
      <NavigationDropdown.Item to="/lists/1529">Back in Stock</NavigationDropdown.Item>
      <NavigationDropdown.Item to="/lists/677">Black Friday</NavigationDropdown.Item>
      <NavigationDropdown.Item to="/lists/671">Cart Abandonment</NavigationDropdown.Item>
      <NavigationDropdown.Item to="/lists/1898">Christmas</NavigationDropdown.Item>
      <NavigationDropdown.Item to="/lists/678">Cyber Monday</NavigationDropdown.Item>
      <NavigationDropdown.Item to="/lists/1687">Gift Guides</NavigationDropdown.Item>
      <NavigationDropdown.Item to="/lists/2657">Introducing New Collection</NavigationDropdown.Item>
      <NavigationDropdown.Item to="/lists/1235">Mother&rsquo;s Day</NavigationDropdown.Item>
      <NavigationDropdown.Item to="/lists/690">Onboarding Drips</NavigationDropdown.Item>
      <NavigationDropdown.Item to="/lists/57">Order Confirmation</NavigationDropdown.Item>
      <NavigationDropdown.Item to="/lists/2655">Sales and Discounts</NavigationDropdown.Item>
      <NavigationDropdown.Item to="/lists/687">Shipping Confirmation</NavigationDropdown.Item>
      <NavigationDropdown.Item to="/lists/2096">Valentine&rsquo;s Day</NavigationDropdown.Item>
      <NavigationDropdown.Item to="/lists/9">Winback</NavigationDropdown.Item>
      <NavigationDropdown.Item to="/emails">View Emails Home</NavigationDropdown.Item>
    </NavigationDropdown>
  </NavigationItem>
);

export default Emails;
