import PropTypes from 'prop-types';
import { isEmpty, isNil } from 'ramda';
import React from 'react';
import MenuHeader from './subMenu/MenuHeader';
import MenuLink from './subMenu/MenuLink';

const isPresent = val => !isEmpty(val) && !isNil(val);

class SubMenu extends React.Component {
  static Header = MenuHeader;
  static Link = ({ activePathname, activePathnames, to, as, ...props }) => {
    if (as) {
      const LinkComponent = as ? MenuLink.withComponent(as) : MenuLink;
      return <LinkComponent {...props} />;
    }

    const toPathname = (to.pathname || to)
      .split('/')
      .pop()
      .split('?')[0];
    const predictions = isPresent(activePathnames) ? activePathnames : [toPathname];
    const active = predictions.includes(activePathname);

    return <MenuLink to={to} data-active={active} {...props} />;
  };

  render() {
    const activePathname = this.context.router.location.pathname.split('/').pop();
    const children = React.Children.map(
      this.props.children,
      child => (child ? React.cloneElement(child, { activePathname }) : null),
    );

    return <nav>{children}</nav>;
  }
}

SubMenu.contextTypes = {
  router: PropTypes.object,
};

SubMenu.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SubMenu;
