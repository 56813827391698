import PropTypes from 'prop-types';
import React from 'react';
import api from '../../../lib/api';
import { Box, Flex, Spinner } from '../../mc-ui';
import Section from '../../Section';
import Message from './Message';
import Panels from './Panels';
import ContextNavigation from './ContextNavigation';

export default class TextMessageViewPage extends React.PureComponent {
  state = {
    data: null,
    loading: true,
    retried: 0,
  };

  componentDidMount() {
    this.fetch();
  }

  fetch = async () => {
    const { router } = this.props;
    const { guid } = router.params;
    const { list_id: listId } = router.location.query;
    const { retried } = this.state;

    if (retried === 3) return;

    try {
      const params = {};
      if (listId) {
        params.list_id = listId;
      }
      const response = await api.get(`/v3/text-messages/${guid}`, { params });
      this.setState({ data: response.data, loading: false });
    } catch (error) {
      this.setState({ retried: retried + 1 }, this.fetch);
      console.log('error', error);
    }
  };

  render() {
    const { router } = this.props;
    const { data, loading } = this.state;
    const [textMessage] = loading ? [] : data.data;

    return (
      <Box>
        <ContextNavigation loading={loading} router={router} textMessage={textMessage} />

        <Box minHeight="calc(100vh - 60px)" paddingTop="40px">
          {loading ? (
            <Flex justifyContent="center">
              <Spinner color="dark4" size="64px" />
            </Flex>
          ) : (
            <Section outerBackground="#fff">
              <Flex justifyContent="space-between">
                <Message data={textMessage} />
                <Panels data={textMessage} router={router} />
              </Flex>
            </Section>
          )}
        </Box>
      </Box>
    );
  }
}

TextMessageViewPage.propTypes = {
  router: PropTypes.shape({
    params: PropTypes.shape({
      guid: PropTypes.string,
      list_id: PropTypes.string,
    }),
    location: PropTypes.shape({
      query: PropTypes.shape({
        list_id: PropTypes.string,
      }),
    }),
  }).isRequired,
};
