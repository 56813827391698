import React from 'react';
import api from '../lib/api';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import { browserHistory } from 'react-router';
import Welcome from './Welcome';
import Splash from './Splash';
import Loader from './Loader';
import { www } from '../lib/utils';

function SigningIn() {
  const dispatch = useDispatch();
  const { user: auth0User, isAuthenticated, isLoading, logout } = useAuth0();

  if (isAuthenticated) {

    let options = { user: auth0User, isAuthenticated };
    api.post('/auth/create', options)
      .then(r =>  {
          return api.get('/v2/user')
      })
      .then(r => {
        dispatch({ type: 'RECEIVED_USER', data: r.data })
      })
      .then(r => {
        browserHistory.push('/');
      })
      .catch(async err => {
        const redirectURI = www('/');
        await api.post('/auth/destroy').catch(console.error);
        logout({ returnTo: redirectURI });
      });
  }
  return (
    <div>
      <Splash>
        <Loader loading={true} />
        <div style={{ position: 'relative' }}></div>
        <div style={{
          marginTop: '50px',
          padding: '0px 50px'
        }}
        >
        </div>
        <Welcome></Welcome>
      </Splash>
    </div>
  );
}

export default SigningIn;