/** @jsx jsx */
import { jsx } from '@emotion/react';
import bytes from 'bytes';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import { Link as RRLink } from 'react-router';
import { Box, Button, Flex, Heading, Link, Text, theme, Tooltip } from '../../../mc-ui';
import CharacterIcon from '../../../mc-ui/icons/CharacterIcon';
import PromoIcon from '../../../mc-ui/icons/PromoIcon';
import WarningCircleIcon from '../../../mc-ui/icons/WarningCircleIcon';
import EmailScoreBar from './EmailScoreBar';
import TechnologyLogo from './TechnologyLogo';

export default function Details({ email }) {
  if (email.loading) return null;
  const [model] = email.data;
  const [brand] = (model.relationships.companies || { data: [] }).data;
  const technologies = (model.relationships.technologies || { data: [] }).data;

  if (!brand) return null;

  const {
    body_bytes: bodyBytes,
    dkim_result: dkimResult,
    from,
    image_bytes: imageBytes,
    mobile_optimized: mobileOptimized,
    no_reply: noReply,
    preheader_optimized: preheaderOptimized,
    promotional,
    reply_to: replyTo,
    spam_flag: spamFlag,
    spf_result: spfResult,
    subject_line_length: subjectLineLength,
  } = model.attributes;

  const replyable = !!replyTo || !noReply || from.length > 0;
  const DKIM_status = (dkimResult?dkimResult:'').toLowerCase() === 'pass' ? 'Yes' : 'No';
  const SPF_status = (spfResult?spfResult:'').toLowerCase() === 'pass' ? 'Yes' : (spfResult?spfResult:'').toLowerCase() === 'neutral' ? 'Neutral' : 'No';

  return (
    <div>
      <Flex justifyContent="space-between" marginBottom="34px">
        <Box width="48%">
          <Heading
            as="h5"
            color="dark08"
            fontWeight="bold"
            lineHeight="1.56em"
            css={{ fontSize: '10px', letterSpacing: '0.12em', marginBottom: '8px' }}
          >
            Subject Line Length
          </Heading>

          <Flex alignItems="center" color="dark08" marginBottom="8px">
            <CharacterIcon height="20px" width="20px" />

            <Text color="dark1" fontWeight="500" lineHeight="20px" margin="0 0 0 8px">
              {subjectLineLength} {pluralize('character', subjectLineLength)}
            </Text>
          </Flex>

          <Text color="dark08" css={{ '& a': { border: 'none', fontWeight: 'bold' } }}>
            Subject lines from{' '}
            <Link to={`/companies/${brand.id}/subject-lines`}>{brand.attributes.name}</Link> average{' '}
            <strong>{Math.round(brand.attributes.subject_line_length)} characters</strong> in
            length.
          </Text>
        </Box>

        <Box width="48%">
          <Heading
            as="h5"
            color="dark08"
            fontWeight="bold"
            lineHeight="1.56em"
            css={{ fontSize: '10px', letterSpacing: '0.12em', marginBottom: '8px' }}
          >
            Promotional
          </Heading>

          <Flex alignItems="center" color="dark08" marginBottom="8px">
            <PromoIcon fillOpacity={1} height="20px" width="20px" />

            <Text color="dark1" fontWeight="500" lineHeight="20px" margin="0 0 0 8px">
              {promotional ? 'YES' : 'NO'}
            </Text>
          </Flex>

          <Text color="dark08" css={{ '& a': { border: 'none', fontWeight: 'bold' } }}>
            <strong>{Math.round(brand.attributes.promotional || 0)}%</strong> of emails sent by{' '}
            <Link to={`/companies/${brand.id}/promotions`}>{brand.attributes.name}</Link> are
            promotional.
          </Text>
        </Box>
      </Flex>

      <Box marginBottom="32px">
        <Box marginBottom="16px">
          <Flex alignItems="center" marginBottom="8px">
            <Heading
              as="h3"
              color="dark08"
              fontWeight="500"
              lineHeight="1.25em"
              css={{ fontSize: '16px', letterSpacing: '0.02em' }}
            >
              Email Score
            </Heading>

            <Tooltip
              element={<WarningCircleIcon fillOpacity={0.6} height="22px" width="22px" />}
              id="emailDetailsEmailScoreTooltip"
              css={{
                fontSize: '22px',
                height: '22px',
                marginLeft: '4px',
                width: '22px',
                '& > span': {
                  display: 'inline-block',
                  height: '22px',
                  margin: '0',
                  width: '22px',
                },
                '& [data-id="tooltip"]': {
                  marginLeft: '0 !important',
                },
              }}
              dark
            >
              <h4
                css={{
                  fontWeight: '500',
                  lineHeight: '20px',
                  marginBottom: '8px',
                }}
              >
                Email Score
              </h4>
              <p
                css={{ margin: '0 0 8px 0 !important' }}
              >
                Identify easy wins to optimize your campaigns with best practices using Email Score.{' '}
              </p>
              <a
                href="https://knowledge.validity.com/s/articles/What-information-does-the-Email-Score-provide-me?language=en_US"
                target="_blank"
                rel="noopener noreferrer"
                css={{
                  color: `${theme.colors.blue} !important`,
                }}
              >
                Learn more
              </a>
            </Tooltip>
          </Flex>

          <EmailScoreBar email={model} />
        </Box>

        <Flex justifyContent="space-between" wrap="wrap">
          <Text color="dark08" margin="0 0 8px 0" width="48%">
            Mobile optimized: <strong>{mobileOptimized ? 'Yes' : 'No'}</strong>
          </Text>

          <Text color="dark08" margin="0 0 8px 0" width="48%">
            Subject line length: <strong>{subjectLineLength} chars</strong>
          </Text>

          <Text color="dark08" margin="0 0 8px 0" width="48%">
            HTML weight: <strong>{bytes.format(bodyBytes)}</strong>
          </Text>

          <Text color="dark08" margin="0 0 8px 0" width="48%">
            Preheader optimized: <strong>{preheaderOptimized ? 'Yes' : 'No'}</strong>
          </Text>

          <Text color="dark08" margin="0 0 8px 0" width="48%">
            Replyable email address: <strong>{replyable ? 'Yes' : 'No'}</strong>
          </Text>

          {(dkimResult || spfResult) && (
            <Text color="dark08" margin="0 0 8px 0" width="48%">
              DKIM / SPF pass: <strong>{DKIM_status} / {SPF_status}</strong>
            </Text>
          )}

          <Text color="dark08" width="48%">
            Image weight: <strong>{bytes.format(imageBytes)}</strong>
          </Text>

          {spamFlag && (
            <Text color="dark08" width="48%">
              Spam check: <strong>{(spamFlag?spamFlag:'').toLowerCase() === 'no' ? 'OK' : 'NOT OK'}</strong>
            </Text>
          )}
        </Flex>
      </Box>

      {technologies.length > 0 && (
        <Box marginBottom="32px">
          <Heading
            as="h5"
            color="dark08"
            fontWeight="bold"
            lineHeight="1.56em"
            css={{ fontSize: '10px', letterSpacing: '0.12em', marginBottom: '8px' }}
          >
            Email Technology
          </Heading>

          {technologies.map(technology => (
            <Flex
              key={`EmailDetails-Technology${technology.id}`}
              alignItems="center"
              marginBottom="8px"
            >
              <RRLink
                to={`/technologies/${technology.id}`}
                css={{
                  display: 'block',
                  height: '24px',
                }}
              >
                <TechnologyLogo
                  alt={technology.attributes.name}
                  src={`https://cache.mailcharts.com/logos/technologies/${technology.id}.png`}
                />
              </RRLink>
              <Link to={`/technologies/${technology.id}`}>{technology.attributes.name}</Link>
            </Flex>
          ))}
        </Box>
      )}

      <Button
        as={RRLink}
        to={`/companies/${brand.id}`}
        variant="secondary"
        css={{ height: '32px', lineHeight: '32px', margin: '0', padding: '0', width: '100%' }}
        style={{
          '@media screen and (max-width: 1280px)': {
            whiteSpace: 'break-spaces'
          }
        }}
      >
        View more data and insights on our {brand.attributes.name} Overview
      </Button>
    </div>
  );
}

Details.propTypes = {
  email: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        attributes: PropTypes.shape({
          animated: PropTypes.bool,
          body_bytes: PropTypes.number,
          mobile_optimized: PropTypes.bool,
          preheader_optimized: PropTypes.bool,
          promotional: PropTypes.bool,
          subject_line_length: PropTypes.number,
        }),
        relationships: PropTypes.shape({
          companies: PropTypes.shape({
            data: PropTypes.arrayOf(
              PropTypes.shape({
                id: PropTypes.string,
                attributes: PropTypes.shape({
                  name: PropTypes.string,
                  promotional: PropTypes.number,
                  subject_line_length: PropTypes.number,
                }),
              }),
            ),
          }),
          technologies: PropTypes.shape({
            data: PropTypes.arrayOf(
              PropTypes.shape({
                id: PropTypes.string,
                attributes: PropTypes.shape({
                  name: PropTypes.string,
                }),
              }),
            ),
          }),
        }),
      }),
    ),
    loading: PropTypes.bool,
  }).isRequired,
};
