import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import pluralize from 'pluralize';
import qs from 'qs';
import { connect } from 'react-redux';
import { isEmpty } from 'ramda';

import { formatNumber } from '../../../lib/utils';

import DateRangePickerWrapper from '../../DateRangePickerWrapper';
import AdvancedOptions, { AdvancedOptionsButton } from '../../AdvancedOptions';

import Wrapper from './ui/Wrapper';
import CustomIntervalWrapper from './ui/SingleHeader/CustomIntervalWrapper';
import IntervalWrapper from './ui/SingleHeader/IntervalWrapper';

import ComparisonSelect from './ComparisonSelect';
import ExportOptions from './ExportOptions';
import ModelToCompare from './ModelToCompare';

const modelTypes = {
  companies: 'Company',
  groups: 'Group',
  technologies: 'Technology',
};

const INITIAL_STATE = {
  advancedOptionsOpen: false,
  interval: '90_days',
  intervalEndDate: moment().endOf('month'),
  intervalStartDate: moment().startOf('month'),
};

class SingleHeader extends Component {
  state = INITIAL_STATE;

  componentDidMount() {
    this.readItervalFromRouterQuery();
  }

  onDatesChange = (startDate, endDate) => {
    const { queryScope, setQuery } = this.props;
    const { router } = this.context;

    let query = qs.parse((router.location.search || '').replace('?', ''));
    if (queryScope && query[queryScope]) {
      query = query[queryScope];
    }

    if (startDate && endDate) {
      query.start_date = moment(startDate);
      query.end_date = moment(endDate);
    } else {
      delete query.start_date;
      delete query.end_date;
    }

    this.setState({ intervalStartDate: startDate, intervalEndDate: endDate }, () => {
      setQuery(query, queryScope, true);
    });
  };

  getModelFromUser = (id, type) => {
    const { user } = this.props;
    const model = user.included.find(i => i.type === type && i.id === id);
    return model.attributes.name;
  };

  readItervalFromRouterQuery = () => {
    const { queryScope, user } = this.props;
    const { router } = this.context;

    let query = qs.parse((router.location.search || '').replace('?', ''));
    if (queryScope) {
      query = query[queryScope] || {};
    }

    const { end_date: endDate, start_date: startDate } = query;

    let interval;
    if (endDate && startDate) {
      const customInterval = { value: 'custom', end_date: endDate, start_date: startDate };
      interval = user.meta.intervals.reduce(
        (m, i) => (i.end_date === endDate && i.start_date === startDate ? i : m),
        customInterval,
      );
    } else {
      interval = user.meta.intervals.find(i => i.value === this.state.interval);
    }

    this.setState({
      interval: interval.value,
      intervalEndDate: moment(interval.end_date),
      intervalStartDate: moment(interval.start_date),
    });
  };

  toggleAdvancedOptions = () => {
    this.setState(prevState => ({ advancedOptionsOpen: !prevState.advancedOptionsOpen }));
  };

  updateInterval = (event) => {
    const { intervalEndDate, intervalStartDate } = this.state;
    const { queryScope, setQuery, user } = this.props;
    const { router } = this.context;

    if (event) {
      event.preventDefault();
    }

    let interval;

    if (isEmpty(event.target.value)) {
      interval = user.meta.intervals.find(i => i.value === INITIAL_STATE.interval);
    } else {
      interval = user.meta.intervals.reduce((m, i) => (i.value === event.target.value ? i : m), {
        end_date: intervalEndDate,
        label: 'Custom',
        start_date: intervalStartDate,
        value: 'custom',
      });
    }

    let query = qs.parse((router.location.search || '').replace('?', ''));
    if (queryScope && query[queryScope]) {
      query = query[queryScope];
    }

    if (interval.start_date && interval.end_date) {
      query.start_date = moment(interval.start_date);
      query.end_date = moment(interval.end_date);
    }

    this.setState({ interval: interval.value }, () => {
      setQuery(query, queryScope, true);
    });
  };

  renderCurrentModel = () => {
    const {
      comparisonMode, model, queryScope, reports,
    } = this.props;

    if (comparisonMode) {
      if (queryScope) {
        return (
          <div style={{ display: 'inline-flex' }}>
            <ModelToCompare {...this.props} />
            {!reports.loading && (
              <h4
                style={{
                  lineHeight: `${32 / 18}em`,
                  margin: 0,
                  padding: 0,
                  width: comparisonMode ? '100%' : null,
                }}
              >
                &nbsp;({formatNumber(reports.export.comparisonTotal)}{' '}
                {pluralize('email', reports.export.comparisonTotal)})
              </h4>
            )}
          </div>
        );
      }

      return (
        <h4
          style={{
            lineHeight: `${32 / 18}em`,
            margin: 0,
            padding: 0,
            width: comparisonMode ? '100%' : null,
          }}
        >
          {modelTypes[model.type]} - {model.attributes.name}{' '}
          {!reports.loading &&
            `(${formatNumber(reports.export.total)} ${pluralize('emails', reports.export.total)})`}
        </h4>
      );
    }

    return null;
  };

  render() {
    const {
      comparisonMode,
      currentQuery,
      model,
      queryScope,
      setQuery,
      showExport,
      toggleComparisonMode,
      updateRouterQuery,
      user,
    } = this.props;

    const {
      advancedOptionsOpen, interval, intervalEndDate, intervalStartDate,
    } = this.state;

    return (
      <Wrapper comparisonMode={comparisonMode}>
        <div>
          {comparisonMode &&
            queryScope && (
              <a href="#close" onClick={toggleComparisonMode}>
                <img src="/close.png" width="18" alt="Close" />
              </a>
            )}

          {this.renderCurrentModel()}

          <IntervalWrapper comparisonMode={comparisonMode}>
            <form className="pure-form">
              <label htmlFor="interval">
                <select id="interval" onChange={this.updateInterval} value={interval || ''}>
                  <option value="">Reset</option>
                  {user.meta.intervals.map((i, idx) => {
                    const iStartDate = moment(i.start_date);
                    const firstEmailSentAt = model.attributes.first_email_sent_at || new Date();
                    const disabled =
                      model.type === 'companies' && moment(firstEmailSentAt).isAfter(iStartDate);

                    return (
                      <option
                        key={idx}
                        value={i.value}
                        disabled={disabled}
                      >
                        {i.label}
                      </option>
                    );
                  })}
                  <option value="custom">Custom</option>
                </select>
              </label>
            </form>

            {interval === 'custom' && (
              <CustomIntervalWrapper>
                <DateRangePickerWrapper
                  startDate={intervalStartDate}
                  endDate={intervalEndDate}
                  startDatePlaceholderText="Start"
                  endDatePlaceholderText="End"
                  onDatesChange={this.onDatesChange}
                  fixedSize
                />
              </CustomIntervalWrapper>
            )}
          </IntervalWrapper>

          <AdvancedOptionsButton
            comparisonMode={comparisonMode}
            onClick={this.toggleAdvancedOptions}
          />

          <div style={{ flex: 1 }} />

          {!comparisonMode && (
            <ComparisonSelect
              currentPeriod={interval}
              model={model}
              setQuery={setQuery}
              toggleComparisonMode={toggleComparisonMode}
            />
          )}

          <ExportOptions model={model} queryScope={queryScope} showExport={showExport} />
        </div>

        <AdvancedOptions
          currentQuery={currentQuery}
          endDate={intervalEndDate}
          queryScope={queryScope}
          setQuery={setQuery}
          showFilter={advancedOptionsOpen}
          startDate={intervalStartDate}
          toggleAdvancedOptions={this.toggleAdvancedOptions}
          updateRouterQuery={updateRouterQuery}
        />
      </Wrapper>
    );
  }
}

SingleHeader.contextTypes = {
  router: PropTypes.object,
};

SingleHeader.propTypes = {
  model: PropTypes.shape({}).isRequired,
  reports: PropTypes.shape({}).isRequired,
  setQuery: PropTypes.func.isRequired,
  toggleComparisonMode: PropTypes.func.isRequired,
  updateRouterQuery: PropTypes.func.isRequired,
  user: PropTypes.shape({}).isRequired,
  comparisonMode: PropTypes.bool,
  currentQuery: PropTypes.shape({}),
  queryScope: PropTypes.string,
  showExport: PropTypes.bool,
};

SingleHeader.defaultProps = {
  comparisonMode: false,
  currentQuery: {},
  queryScope: null,
  showExport: false,
};

const mapStateToProps = state => ({
  reports: state.reports,
  user: state.user,
});

export default connect(mapStateToProps)(SingleHeader);
