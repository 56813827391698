import { call, put, select } from 'redux-saga/effects';
import { camelizeKeys } from 'humps';

import * as actions from './actions';
import * as api from './api';
import * as selectors from './selectors';

export function* fetchBrands({ params, url }) {
  try {
    const response = yield call(api.fetchBrands, params, url);
    yield put(actions.receive(camelizeKeys(response.data)));
  } catch (error) {
    console.log('modules/brands › fetchBrands › error', error);
  }
}

export function* paginateBrands() {
  try {
    const { next } = yield select(selectors.linksSelector);
    const response = yield call(api.paginateBrands, next);
    yield put(actions.receivePagination(camelizeKeys(response.data)));
  } catch (error) {
    console.log('modules/brands › paginateBrands › error', error);
  }
}
