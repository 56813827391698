import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { teamsSelectors } from '../../modules/teams';
import { Box, Flex, Heading, Link, Text, theme } from '../mc-ui';
import WarningCircleIcon from '../mc-ui/icons/WarningCircleIcon';

// eslint-disable-next-line react/prop-types
function Paragraph({ accountStatus, fromTeamManagement, isOwner, enterprise }) {
  if (!isOwner) {
    if (!fromTeamManagement) {
      return (
        <Text>
          <Link to="/manage/teams">Contact your team owner to reactivate the team account.</Link>
        </Text>
      );
    }

    return <Text>Contact your team owner to reactivate the team account.</Text>;
  }

  if (accountStatus === 'trialing') {
    if (fromTeamManagement) {
      return (
        <Text>
          <Link to="/manage/plan">Upgrade your team to MailCharts Pro.</Link>
        </Text>
      );
    }

    return <Text>Upgrade your team to MailCharts Pro.</Text>;
  }

  if (fromTeamManagement) {
    return (
      <Text>
        Your team's MailCharts access has been downgraded.{' '}
        <Link to="/manage/plan">Update payment details to reactivate your subscription.</Link>
      </Text>
    );
  }

  return enterprise ? (
    <Text>
      Your MailCharts access has been downgraded. Please contact hi@mailcharts.com to update your
      subscription.
    </Text>
  ) : (
    <Text>
      Your MailCharts access has been downgraded. Update payment details below to reactivate your
      subscription.
    </Text>
  );
}

function PlanExpiredNotice({
  fromTeamManagement,
  isCanceled,
  isOwner,
  planExpired,
  team,
  enterprise,
}) {
  // if (!team || !planExpired) return null;
  if (!((team && planExpired) || isCanceled)) return null;

  const { account_status: accountStatus, status } = team ? team.attributes : {};

  if (accountStatus !== 'trialing' && status !== 'active') return null;

  const planName = accountStatus === 'trialing' ? 'trial' : 'subscription';

  return (
    <Flex
      alignItems="flex-start"
      backgroundColor="white"
      border="1px solid"
      borderColor="yellowMedium"
      borderRadius="4px"
      padding="24px"
    >
      <WarningCircleIcon color={theme.colors.yellowDark} />

      <Box flex="1" margin={isOwner ? '0 16px' : '0 0 0 16px'} width="unset">
        <Heading as="h3" lineHeight={`${24 / 18}em`} css={{ marginBottom: '8px' }}>
          Your {planName} has expired
        </Heading>

        <Paragraph
          accountStatus={accountStatus}
          fromTeamManagement={fromTeamManagement}
          enterprise={enterprise}
          isOwner={isOwner}
        />
      </Box>
    </Flex>
  );
}

PlanExpiredNotice.defaultProps = {
  fromTeamManagement: false,
  enterprise: false,
  team: null,
};

PlanExpiredNotice.propTypes = {
  fromTeamManagement: PropTypes.bool,
  isCanceled: PropTypes.bool.isRequired,
  isOwner: PropTypes.bool.isRequired,
  planExpired: PropTypes.bool.isRequired,
  enterprise: PropTypes.bool,
  team: PropTypes.shape({
    attributes: PropTypes.shape({
      account_status: PropTypes.string,
    }),
  }),
};

const mapStateToProps = state => ({
  isCanceled: teamsSelectors.isCanceledSelector(state),
  isOwner: teamsSelectors.isOwnerSelector(state),
  planExpired: teamsSelectors.planExpiredSelector(state),
  team: teamsSelectors.teamSelector(state),
});

export default connect(mapStateToProps)(PlanExpiredNotice);
