import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';

import api from '../../../../lib/api';
import { dollar } from '../../../../lib/utils';
import { teamsActions, teamsSelectors } from '../../../../modules/teams';

import { Button, Heading, Loader, Modal, Text } from '../../../mc-ui';

import * as S from './styles';

class IntervalUpdateConfirmationModal extends React.Component {
  state = {
    data: {},
    loading: true,
    updating: false,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.request();
    }
  }

  request = async () => {
    const { isOpen, membersMeta, newInterval, subscription } = this.props;

    if (!isOpen) return;

    const quantity = membersMeta.counts.active;

    const response = await api.get(`/v3/subscriptions/${subscription.id}/calculate`, {
      params: { interval: newInterval, quantity },
    });

    this.setState(() => ({
      data: response.data.data[0],
      loading: false,
    }));
  };

  amountToChargeNow = () => {
    const { data } = this.state;
    if (data.attributes) {
      return data.attributes.updated_invoice_amount < 0
        ? (data.attributes.next_invoice_amount + data.attributes.updated_invoice_amount) / 100
        : (data.attributes.next_invoice_amount - data.attributes.updated_invoice_amount) / 100;
    }
    return 0;
  };

  confirm = event => {
    if (event) event.preventDefault();
    const { closeModal, newInterval, updateSubscription } = this.props;

    this.setState(
      () => ({ updating: true }),
      () => {
        updateSubscription({ interval: newInterval }, closeModal);
      },
    );
  };

  render() {
    const { closeModal, isOpen, newInterval } = this.props;
    const { data, loading, updating } = this.state;

    const amount = this.amountToChargeNow();

    const previousInterval = newInterval === 'month' ? 'year' : 'month';

    return (
      <Modal isOpen={isOpen} onClose={closeModal}>
        <Modal.Body maxHeight="344px">
          {loading ? (
            <Loader />
          ) : (
            <div>
              <Heading
                as="h2"
                fontSize="24px"
                fontWeight="300"
                lineHeight="1.32em"
                css={{ marginBottom: '8px' }}
              >
                You are about to update your plan
              </Heading>

              <Text fontWeight="bold" css={{ marginBottom: '4px' }}>
                Switching from <u>{previousInterval}</u> to <u>{newInterval}</u> billing.
              </Text>

              <S.UL>
                {amount > 0 ? (
                  <S.LI>
                    You will be charged <strong>{dollar(this.amountToChargeNow())}</strong>{' '}
                    immediately.
                  </S.LI>
                ) : (
                  <S.LI>
                    We will credit <strong>{dollar(this.amountToChargeNow())}</strong> for unused
                    time on the previous plan.
                  </S.LI>
                )}
                {newInterval === 'month' && (
                  <S.LI>
                    You will be charged{' '}
                    <strong>
                      {dollar(data.attributes.next_invoice_amount, { fromCents: true })}
                    </strong>{' '}
                    immediately.
                  </S.LI>
                )}
                {newInterval === 'month' ? (
                  <S.LI>
                    <em>
                      Your next invoice will be on{' '}
                      {moment(data.attributes.next_invoice_date)
                        .add(1, 'month')
                        .format('MMM DD, YYYY')}{' '}
                      for{' '}
                      <strong>
                        {dollar(data.attributes.next_invoice_amount, { fromCents: true })}
                      </strong>
                      .
                    </em>
                  </S.LI>
                ) : (
                  <S.LI>
                    <em>
                      Your next invoice will be on{' '}
                      {moment(data.attributes.next_invoice_date)
                        .add(1, 'year')
                        .format('MMM DD, YYYY')}{' '}
                      for{' '}
                      <strong>
                        {dollar(data.attributes.next_invoice_amount, { fromCents: true })}
                      </strong>
                      .
                    </em>
                  </S.LI>
                )}
              </S.UL>
            </div>
          )}
        </Modal.Body>

        {!loading && (
          <Modal.Footer justifyContent="center">
            <Button
              css={{
                height: '40px',
                lineHeight: '40px',
                marginRight: '8px',
                padding: 0,
                width: '88px',
              }}
              disabled={updating}
              onClick={this.confirm}
              variant="primary"
            >
              Confirm
            </Button>

            <Button
              css={{ height: '40px', lineHeight: '40px', padding: 0, width: '80px' }}
              disabled={updating}
              onClick={closeModal}
              variant="secondary"
            >
              Cancel
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    );
  }
}

IntervalUpdateConfirmationModal.defaultProps = {
  newInterval: null,
};

IntervalUpdateConfirmationModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  membersMeta: PropTypes.shape({
    counts: PropTypes.shape({
      active: PropTypes.number,
    }),
  }).isRequired,
  newInterval: PropTypes.string,
  subscription: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  updateSubscription: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  membersMeta: teamsSelectors.membersMetaSelector(state),
  subscription: teamsSelectors.subscriptionSelector(state),
});

const mapDispatchToProps = dispatch => ({
  updateSubscription: (payload, resolve) =>
    dispatch(teamsActions.subscriptionUpdateRequest(payload, resolve)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IntervalUpdateConfirmationModal);
