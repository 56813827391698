import React from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import moment from 'moment';
import EmailThumbnail from './EmailThumbnail';

import Emails from './Emails';
import Container from './Container';

class GroupsEmails extends React.Component {
  render() {
    const user = this.props.user.data.reduce((m, u) => u, {});
    const [group = { attributes: {} }, ..._] = this.props.groups.data;
    return (
      <div className="pure-u-1">
        <Emails {...this.props} model={group} />
      </div>
    );
  }
}

const s = state => ({ user: state.user, groups: state.groups });
export default connect(s)(GroupsEmails);
