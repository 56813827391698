import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import qs from 'qs';
import { connect } from 'react-redux';

import axios, { CancelToken } from 'axios';
import api from '../../lib/api';
import { reportsActions } from '../../modules/reports';

import Loader from '../Loader';
import PromotionFrequency from '../charts/PromotionFrequency';
import PromotionValues from '../charts/PromotionValues';
import Restricted from '../Restricted';
import Tooltip from '../ui/Tooltip';

import IntervalFilter from './IntervalFilter';

class PromotionsReport extends Component {
  state = {
    interval: 'week',
    loading: true,
    promotionType: 'discount_percentage',
    report: null,
    searchString: '',
  };

  UNSAFE_componentWillMount() {
    const { router } = this.props;

    this.setState({ searchString: router.location.search }, () => {
      this.fetch(this.props);
    });
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { router } = props;

    if (router.location.search !== this.state.searchString) {
      this.setState({ searchString: router.location.search }, () => {
        this.fetch(props);
      });
    }
  }

  setTotal = (report) => {
    let total;
    let comparisonTotal;
    report.meta.stats.forEach((stat) => {
      if (stat.name === 'Comparison') {
        comparisonTotal = stat.total;
      } else {
        total = stat.total; // eslint-disable-line
      }
    });

    this.props.receiveExportTotal({ total, comparisonTotal });
  };

  cancelTokenSource = null;

  cancelFetch = () => {
    if (this.cancelTokenSource) {
      this.cancelTokenSource.cancel();
    }

    this.cancelTokenSource = CancelToken.source();
  };

  fetch(props) {
    this.cancelFetch();

    const { router } = props;
    const { model, startReportLoading } = this.props;
    const { interval } = this.state;

    startReportLoading();

    this.setState({ loading: true, error: null }, () => {
      const query = qs.parse((router.location.search || '').replace('?', ''));
      const params = {
        ...query,
        interval,
      };

      api
        .get(`/v2/${model.type}/${model.id}/promotions?${qs.stringify(params)}`, {
          cancelToken: this.cancelTokenSource.token,
        })
        .then((r) => {
          const report = r.data;
          this.setTotal(report);
          this.setState({ report });

          return api.get('/v2/searches', {
            params: {
              ...params,
              ...router.params,
              limit: 6,
              promotional: true,
            },
          });
        })
        .then(() => {
          this.setState({ loading: false, restricted: null });
        })
        .catch((err) => {
          this.setState({ error: true, restricted: /403/.test(err.toString()), report: {} });
        });
    });
  }

  togglePromotionType = (event) => {
    event.preventDefault();
    const promotionType = event.target.value;
    this.setState({ promotionType });
  };

  updateInterval = (interval) => {
    this.setState({ interval }, () => this.fetch(this.props));
  };

  renderReport = ({
    model, promotionType, report, IntervalFilterNav,
  }) => (
    <div>
      <div style={{ position: 'relative ' }}>
        <h4 style={{ display: 'inline-block', marginRight: 5 }}>Promotions</h4>
        <Tooltip style={{ display: 'inline-block' }}>
          <h5>How is this calculated?</h5>
          <p style={{ fontSize: `${14 / 16}em` }}>
            Promotional rate for {model.attributes.name} is determined by the number of emails
            containing a promotion.
          </p>
          <p style={{ fontSize: `${14 / 16}em` }}>
            <a
              href='https://knowledge.validity.com/hc/en-us/articles/10474155356827-Getting-started-with-MailCharts-Email-Reports'
              target='_blank'
              className='pure-button'
            >
              Learn more
            </a>

          </p>
        </Tooltip>
        <IntervalFilterNav />
      </div>
      <PromotionFrequency
        height={100}
        names={report.meta.stats.map(s => s.name)}
        buckets={report.meta.stats.map(s => s.interval.buckets)}
      />
      <div
        style={{
          borderTop: '1px solid #F3F5F7',
          marginTop: 20,
          paddingTop: 10,
        }}
      >
        <div>
          <h5 style={{ display: 'inline-block', marginRight: 5 }}>Promotion breakdown</h5>
          <Tooltip style={{ display: 'inline-block' }}>
            <h5>Emails can have many &quot;types&quot;</h5>
            <p style={{ fontSize: `${14 / 16}em` }}>
              Emails can contain several types of promotions all at once, so the sum of the types of
              promotions may be &quot;higher&quot; than 100%.
            </p>
          </Tooltip>
        </div>
        <table className="pure-table pure-table-striped" style={{ width: '100%' }}>
          <thead>
            <tr>
              <th>Type</th>
              {report.meta.stats.map((s, idx) => {
                const { name } = report.meta.stats[idx];
                const key = idx;
                return <th key={key}>{name}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {['discount_percentage', 'discount_absolute', 'free_shipping', 'bogof'].map((type, idx) => {
                const key = type.toLowerCase();
                const rowKey = `${idx}-${key}`;
                let label = key;
                if (key === 'discount_percentage') label = 'Percent off';
                else if (key === 'discount_absolute') label = 'Dollars off';
                else if (key === 'free_shipping') label = 'Free shipping';
                else if (key === 'bogof') label = 'Buy one, get one';
                return (
                  <tr key={rowKey}>
                    <td>{label}</td>
                    {report.meta.stats.map((s, index) => {
                      const value =
                        s.promotion_types.buckets.reduce((m, b) => {
                          if (b.key === key) {
                            return b;
                          }
                          return m;
                        }, {}).company_avg.value || 0;

                      const total = s.company_avg.value || 1;
                      let percentage = `${Math.round((value / total) * 100)}%`;

                      if (value / total >= 1) percentage = '~100%';

                      const cellKey = index;
                      return <td key={cellKey}>{percentage}</td>;
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
        <h5 style={{ display: 'inline-block', marginRight: 5 }}>Distribution of promotions</h5>
        <form style={{ display: 'inline-block' }} className="pure-form">
          <fieldset>
            <select
              style={{ minWidth: 100, fontSize: `${12 / 16}em` }}
              onChange={this.togglePromotionType}
              value={promotionType}
            >
              <option value="discount_percentage">Percent off</option>
              <option value="discount_absolute">Dollars off</option>
            </select>
          </fieldset>
        </form>
        <PromotionValues
          height={100}
          type={promotionType}
          names={report.meta.stats.map(s => s.name)}
          buckets={report.meta.stats.map(s =>
            s.promotion_value.buckets.reduce((m, b) => {
              if (b.key === promotionType) {
                return b.value.buckets;
              }
              return m;
            }, []))}
        />
      </div>
    </div>
  );

  render() {
    return (
      <Loader loading={this.state.loading} error={this.state.error}>
        {this.state.report ? (
          <div
            ref={(i) => {
              this.div = i;
            }}
            className="pure-u-1"
          >
            {this.state.restricted ? (
              <Restricted />
            ) : (
              <IntervalFilter onUpdate={this.updateInterval} {...this.props} {...this.state}>
                {this.renderReport}
              </IntervalFilter>
            )}
          </div>
        ) : null}
      </Loader>
    );
  }
}

PromotionsReport.propTypes = {
  model: PropTypes.shape({}).isRequired,
  router: PropTypes.shape({}).isRequired,
  receiveExportTotal: PropTypes.func.isRequired,
  startReportLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  companies: state.companies,
  groups: state.groups,
});

const mapDispatchToProps = dispatch => ({
  startReportLoading: () => dispatch(reportsActions.startLoading()),
  receiveExportTotal: (...args) => dispatch(reportsActions.receiveExportTotalAmount(...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PromotionsReport);
