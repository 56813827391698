import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '../../../mc-ui';

import BrandInfo from './BrandInfo';
import GroupInfo from './GroupInfo';
import FeaturedJourneyEmailsInfo from './FeaturedJourneyEmailsInfo';
import HolidayInfo from './HolidayInfo';
import JourneyInfo from './JourneyInfo';
import ListInfo from './ListInfo';
import FeaturedSMSListsInfo from './FeaturedSMSListsInfo';

const components = {
  emailLists: ListInfo,
  featuredBrands: BrandInfo,
  featuredGroups: GroupInfo,
  featuredHolidays: HolidayInfo,
  featuredJourneyEmails: FeaturedJourneyEmailsInfo,
  featuredJourneys: JourneyInfo,
  featuredSMSLists: FeaturedSMSListsInfo,
};

const Info = ({ model, type }) => {
  const Component = components[type];

  return (
    <Box height="407px" paddingTop="40px">
      <Component model={model} />
    </Box>
  );
};

Info.propTypes = {
  model: PropTypes.shape({}).isRequired,
  type: PropTypes.string.isRequired,
};

export default Info;
