import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { take } from 'ramda';

import { appURL, www } from '../../../lib/utils';

import { flashMessageActions } from '../../../modules/flashMessage';
import {
  shareButtonActions as actions,
  shareButtonSelectors as selectors,
} from '../../../modules/shareButton';
import { TYPES } from '../../../modules/shareButton/constants';
import { userSelectors } from '../../../modules/user';

import { Box, Button } from '../../mc-ui';
import ShareIcon from '../../mc-ui/icons/ShareIcon';

import ShareForm from './ShareForm';

function injectQueryString(url) {
  const { search } = window.location;
  if (url.match(/\?/)) {
    return `${url}${search.replace('?', '&')}`;
  }

  return `${url}${search}`;
}

class ShareButton extends React.Component {
  state = {
    copied: false,
  };

  componentWillUnmount() {
    this.props.close();
  }

  handleCopyURL = event => {
    event.preventDefault();

    const { close, model, type } = this.props;
    let url = null;

    switch (type) {
      case TYPES.email: {
        url = www(`/emails/${model.attributes.guid}`);
        break;
      }
      case TYPES.journey: {
        const [brand] = (model.relationships.companies || { data: [] }).data;
        url = appURL(`/companies/${brand.id}/journeys/${model.id}`);
        break;
      }
      case TYPES.textMessage: {
        url = appURL(`/text-messages/${model.attributes.guid}`);
        break;
      }
      default: {
        url = window.location.href;
        break;
      }
    }

    this.setState({ copied: true }, () => {
      window.navigator.clipboard.writeText(url);

      window.setTimeout(() => {
        this.setState(() => ({ copied: false }), close);
      }, 2000);
    });
  };

  trackEvent = (event, message) => {
    const { close, showFlashMessage } = this.props;

    window.analytics.track('Share in-app content', event);

    close();
    showFlashMessage({ title: message });
  };

  sendBrandOverview = values => {
    const { type, model, user } = this.props;
    const { message, to: recipient } = values;
    const event = {
      message: (message || '').trim().length > 0 ? message.trim() : '',
      model: {
        type: model.type,
        id: model.id,
        attributes: { name: model.attributes.name },
      },
      recipient,
      type,
      url: appURL(`/companies/${model.id}`),
      username: user.attributes.name,
    };

    this.trackEvent(event, 'This brand was shared successfully!');
  };

  sendEmail = values => {
    const { type, model, user } = this.props;
    const { message, to: recipient } = values;
    const [brand] = (model.relationships.companies || { data: [] }).data;

    const event = {
      brand,
      message: (message || '').trim().length > 0 ? message.trim() : '',
      model: {
        type: model.type,
        id: model.id,
        attributes: {
          guid: model.attributes.guid,
          subject: model.attributes.subject,
        },
      },
      recipient,
      type,
      url: www(`/emails/${model.attributes.guid}`),
      username: user.attributes.name,
    };

    this.trackEvent(event, 'This email was shared successfully!');
  };

  sendEmailList = values => {
    const { type, model, user } = this.props;
    const { message, to: recipient } = values;

    const event = {
      message: (message || '').trim().length > 0 ? message.trim() : '',
      model: {
        type: model.type,
        id: Number(model.id),
        attributes: {
          name: model.attributes.name,
        },
      },
      recipient,
      type,
      url: appURL(`/lists/${model.id}`),
      username: user.attributes.name,
    };

    this.trackEvent(event, 'This list was shared successfully!');
  };

  sendIndustryOverview = values => {
    const { type, model, user } = this.props;
    const relatedCompanies = model.relationships
      ? (model.relationships || { companies: { data: [] } }).companies.data
      : model.attributes.related_companies;
    const companies = take(4, relatedCompanies).map(c => ({
      id: c.id,
      name: c.attributes.name,
    }));
    const { message, to: recipient } = values;
    const event = {
      companies,
      message: (message || '').trim().length > 0 ? message.trim() : '',
      model: {
        type: model.type,
        id: model.id,
        attributes: { name: model.attributes.name },
      },
      recipient,
      type,
      url: appURL(`/groups/${model.id}`),
      username: user.attributes.name,
    };

    this.trackEvent(event, 'This group was shared successfully!');
  };

  sendJourney = values => {
    const { type, model, user } = this.props;
    const [brand] = (model.relationships.companies || { data: [] }).data;
    const { message, to: recipient } = values;
    const event = {
      brand,
      message: (message || '').trim().length > 0 ? message.trim() : '',
      model: {
        type: model.type,
        id: model.id,
      },
      recipient,
      type,
      url: injectQueryString(appURL(`/companies/${brand.id}/journeys/${model.id}`)),
      username: user.attributes.name,
    };

    this.trackEvent(event, 'This journey was shared successfully!');
  };

  sendTextMessage = values => {
    const { type, model, user } = this.props;
    const { message, to: recipient } = values;
    const [brand] = (model.relationships.companies || { data: [] }).data;

    const event = {
      brand,
      message: (message || '').trim().length > 0 ? message.trim() : '',
      model: {
        type: model.type,
        id: model.id,
        attributes: {
          guid: model.attributes.guid,
        },
      },
      recipient,
      type,
      url: injectQueryString(appURL(`/text-messages/${model.attributes.guid}`)),
      username: user.attributes.name,
    };

    this.trackEvent(event, 'This text message was shared successfully!');
  };

  handleShareToPinterest = event => {
    event.preventDefault();

    const { close, model } = this.props;
    const publicURL = `https://www.mailcharts.com/emails/${model.attributes.guid}`;
    const pinterestURL = `https://pinterest.com/pin/create/button/?url=${publicURL}`;

    window.analytics.track('Shared email to Pinterest');
    window.open(pinterestURL, '_blank');
    close();
  };

  handleSubmit = values => {
    const { type } = this.props;

    if (type === TYPES.brandOverview) {
      this.sendBrandOverview(values);
    } else if (type === TYPES.email) {
      this.sendEmail(values);
    } else if (type === TYPES.emailList) {
      this.sendEmailList(values);
    } else if (type === TYPES.industryOverview) {
      this.sendIndustryOverview(values);
    } else if (type === TYPES.textMessage) {
      this.sendTextMessage(values);
    } else {
      this.sendJourney(values);
    }
  };

  toggle = event => {
    if (event) event.preventDefault();

    const { close, isOpen, open } = this.props;
    if (isOpen) {
      close();
    } else {
      open();
    }
  };

  render() {
    const { children, css, includePinterest, isOpen, model, small, type, variant } = this.props;
    const { copied } = this.state;

    if (small) {
      css.fontSize = '12px';
      css.height = '24px';
      css.width = '48px';
    }

    return (
      <Box inline position="relative" width="unset">
        <Button
          onClick={this.toggle}
          variant={variant}
          css={{
            alignItems: 'center',
            display: 'inline-flex',
            height: '32px',
            justifyContent: 'center',
            margin: '0',
            padding: '0',
            width: '88px',
            '& > svg':
              !small && !children
                ? {
                    marginRight: '8px',
                  }
                : null,
            ...css,
          }}
        >
          {!small && !children && <ShareIcon height="20px" width="20px" />}
          {children || 'Share'}
        </Button>

        {isOpen && (
          <ShareForm
            copied={copied}
            handleCopyURL={this.handleCopyURL}
            handleShareToPinterest={this.handleShareToPinterest}
            includePinterest={includePinterest}
            model={model}
            onSubmit={this.handleSubmit}
            small={small}
            toggle={this.toggle}
            type={type}
          />
        )}
      </Box>
    );
  }
}

ShareButton.defaultProps = {
  children: null,
  css: {},
  includePinterest: false,
  small: false,
  variant: 'primary',
};

ShareButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
  close: PropTypes.func.isRequired,
  css: PropTypes.shape({}),
  includePinterest: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  model: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    attributes: PropTypes.shape({
      guid: PropTypes.string,
      name: PropTypes.string,
      related_companies: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          attributes: PropTypes.shape({
            name: PropTypes.string,
          }),
        }),
      ),
    }),
    relationships: PropTypes.shape({
      companies: PropTypes.shape({
        data: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            attributes: PropTypes.shape({
              name: PropTypes.string,
            }),
          }),
        ),
      }),
    }),
  }).isRequired,
  open: PropTypes.func.isRequired,
  showFlashMessage: PropTypes.func.isRequired,
  small: PropTypes.bool,
  type: PropTypes.string.isRequired,
  user: PropTypes.shape({
    attributes: PropTypes.shape({
      email: PropTypes.string,
      name: PropTypes.string,
    }),
  }).isRequired,
  variant: PropTypes.string,
};

const mapStateToProps = (state, { model, type }) => ({
  isOpen: selectors.isOpenSelector(type, model)(state),
  user: userSelectors.getUser(state),
});

const mapDispatchToProps = (dispatch, { model, type }) => ({
  close: () => dispatch(actions.close(type, model)),
  open: () => dispatch(actions.open(type, model)),
  showFlashMessage: options => dispatch(flashMessageActions.showSuccess(options)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShareButton);
