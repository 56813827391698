import React from 'react'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import api from '../lib/api'

class GroupsEdit extends React.Component {
  state = {
    loading: true,
    group: {},
    companies: []
  }
  componentDidMount() {
    let loading = true
    let group = {}
    let companies = []
    this.setState({ loading })
    api.get(`/v2/groups/${this.props.router.params.group_id}`)
      .then(resp => {
        group = resp.data
        return api.get(group.relationships.companies.links.self)
      })
      .then(resp => {
        companies = resp.data
        loading = false
        this.setState({ group, loading, companies })
      })
  }
  handlePagination = (event) => {
    event.preventDefault()
    api.get(event.target.href)
      .then(r => {
        this.setState({ companies: r.data, loading: false })
      })
  }
  render() {
    return (
      <div className='pure-u-1'>
        {this.state.loading ?
          <p>Loading</p>

        :
        <div>
          <h2>{this.state.group.data.attributes.name}</h2>
          <div className='pure-u-1'>
            <div className='pure-u-1-2'>
              {this.state.companies.links.prev ?
              <Link
                className='pure-button'
                onClick={this.handlePagination}
                to={this.state.companies.links.prev}>
                Prev
              </Link>
              : null}
            </div>
            <div className='pure-u-1-2' style={{ textAlign: 'right' }}>
              {this.state.companies.links.next ?
              <Link
                className='pure-button'
                onClick={this.handlePagination}
                to={this.state.companies.links.next}>
                Next
              </Link>
              : null}
            </div>
          </div>
          <table style={{ width: '100%', minWidth: 600 }} className='pure-table pure-table-striped'>
            <thead>
              <tr>
                <th>Name</th>
              </tr>
            </thead>
            <tbody>
              {this.state.companies.included.filter(i => i.type == 'companies').map(m => {
                return (<tr key={m.id}>
                  <td>
                    <Link to={`/${m.type}/${m.id}`}>{m.attributes.name}</Link>
                  </td>
                </tr>)
              })}
            </tbody>
          </table>

            <p>
              <Link to={{
                pathname: `/manage/productboard`,
                state: { modal: true, returnTo: this.props.router.location }
                }}
              >
                Can't find your company?
              </Link>
            </p>
          </div>
        }
      </div>
    )
  }
}

const s = (state) => ({})
export default connect(s)(GroupsEdit)
