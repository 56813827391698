import React from 'react';
import { PropTypes } from 'prop-types';

import Body from './Body';
import Dropdown from './Dropdown';
import Header from './Header';
import Item from './Item';

class Tab extends React.Component {
  static Body = props => <Body {...props} />;
  static Dropdown = props => <Dropdown {...props} />;
  static Header = props => <Header {...props} />;
  static Item = props => <Item {...props} />;

  state = {
    active: this.props.defaultActive,
  };

  setActive = name => this.setState({ active: name });

  render() {
    return (
      <div>
        {React.Children.map(this.props.children, child =>
          React.cloneElement(child, { active: this.state.active, setActive: this.setActive }),
        )}
      </div>
    );
  }
}

Tab.defaultProps = {
  defaultActive: '',
};

Tab.propTypes = {
  children: PropTypes.node.isRequired,
  defaultActive: PropTypes.string,
};

export default Tab;
