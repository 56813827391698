import styled from "@emotion/styled";

const Label = styled("em")({
  backgroundColor: '#9e6acc',
  borderRadius: '3px',
  color: '#fff',
  fontSize: `${13 / 16}em`,
  fontStyle: 'normal',
  lineHeight: `${16 / 13}em`,
  padding: `${4 / 13}em ${6 / 13}em`,
  textTransform: 'uppercase',
});

export default Label;
