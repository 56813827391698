import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'ramda';

import { customListsActions, customListsSelectors } from '../../../modules/customLists';

import CheckSmallIcon from '../../mc-ui/icons/CheckSmallIcon';
import CrossSmallIcon from '../../mc-ui/icons/CrossSmallIcon';
import PlusIcon from '../../mc-ui/icons/PlusIcon';
import { Box, Button, Flex, Heading, Loader, Modal, theme } from '../../mc-ui';

import * as S from '../styles';
import { ascendBy, descendBy, getPositions } from '../helpers';

import ListSelector from './ListSelector';
import NoLists from './NoLists';
import SortSelect from './SortSelect';

class Lists extends React.Component {
  constructor(props) {
    super(props);

    const { parentEl } = props;
    const { bottom, left, marginLeft, right, top } = getPositions(parentEl);

    this.state = {
      bottom,
      left,
      marginLeft,
      sortedBy: 'updated_at',
      sortedLists: [],
      right,
      top,
    };
  }

  componentDidMount() {
    const { fetchUserLists, fetchTeamLists } = this.props;

    fetchUserLists();
    fetchTeamLists();

    const lists = this.props.userLists;
    this.setSortedLists(lists);
  }

  componentDidUpdate(prevProps) {
    const { listsOwner } = this.props;

    if (listsOwner === 'me' && prevProps.userListsLoading && !this.props.userListsLoading) {
      const lists = this.props.userLists;
      this.setSortedLists(lists);
    } else if (
      listsOwner === 'team' &&
      prevProps.teamListsLoading &&
      !this.props.teamListsLoading
    ) {
      const lists = this.props.teamLists;
      this.setSortedLists(lists);
    }
  }

  setSortedLists = lists => {
    const sortedLists = lists.sort(descendBy('updated_at'));
    this.setState({ sortedLists, sortedBy: 'updated_at' });
  };

  handleSort = sortedBy => {
    let { sortedLists } = this.state;

    switch (sortedBy) {
      case 'asc':
        sortedLists = sortedLists.sort(ascendBy('name'));
        break;
      case 'desc':
        sortedLists = sortedLists.sort(descendBy('name'));
        break;
      case 'updated_at':
        sortedLists = sortedLists.sort(descendBy('updated_at'));
        break;
      default:
        sortedLists = sortedLists.sort(ascendBy('name'));
        break;
    }
    this.setState({ sortedLists, sortedBy });
  };

  showUserLists = event => {
    event.preventDefault();
    this.props.fetchUserLists();
    this.props.onUpdateOwner('me');
  };

  showTeamLists = event => {
    event.preventDefault();
    this.props.fetchTeamLists();
    this.props.onUpdateOwner('team');
  };

  renderLists = () => {
    const {
      emails,
      isValidTeam,
      listType,
      listsOwner,
      onClose,
      onCloseListsForm,
      onOpenCreateForm,
      teamListsLoading,
      textMessages,
      userListsLoading,
    } = this.props;
    const { sortedBy, sortedLists } = this.state;
    const loading =
      (listsOwner === 'me' && userListsLoading) || (listsOwner === 'team' && teamListsLoading);

    return (
      <div>
        <Flex
          borderBottom={`1px solid ${theme.colors.dark01}`}
          justifyContent={isValidTeam ? 'space-between' : 'flex-end'}
          marginBottom="16px"
          padding="0 24px 16px 24px"
        >
          {isValidTeam && (
            <S.ButtonGroup>
              <S.Button active={listsOwner === 'me'} onClick={this.showUserLists}>
                Mine
              </S.Button>
              <S.Button active={listsOwner === 'team'} onClick={this.showTeamLists}>
                Team
              </S.Button>
            </S.ButtonGroup>
          )}
          <SortSelect handleChange={this.handleSort} value={sortedBy} />
        </Flex>

        <Box padding="0 24px">
          <Heading
            as="h5"
            color="dark06"
            fontSize="12px"
            fontWeight="bold"
            letterSpacing="0.08em"
            lineHeight="2em"
            css={{ marginBottom: '16px' }}
          >
            Select Lists to Update
          </Heading>

          {loading ? (
            <Flex alignItems="center" justifyContent="center" height="248px">
              <Loader />
            </Flex>
          ) : (
            <Box height="248px" css={{ overflowY: 'auto' }}>
              {isEmpty(sortedLists) ? (
                <NoLists />
              ) : (
                sortedLists.map(list => (
                  <ListSelector
                    key={`AddToListButton-ListSelector-${list.id}`}
                    emails={emails}
                    list={list}
                    listType={listType}
                    textMessages={textMessages}
                  />
                ))
              )}
            </Box>
          )}

          <Flex justifyContent="space-between" paddingTop="8px">
            <Button
              variant="secondary"
              css={{
                alignItems: 'center',
                display: 'flex',
                height: '32px',
                justifyContent: 'center',
                margin: '0',
                padding: '0 12px',
              }}
              onClick={() => {
                onCloseListsForm();
                onOpenCreateForm();
              }}
            >
              <PlusIcon />
              Create new list
            </Button>

            <Button
              variant="primary"
              css={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                height: '32px',
                margin: '0',
                padding: '0 12px',
              }}
              onClick={() => {
                onClose();
                onCloseListsForm();
              }}
            >
              <CheckSmallIcon />
              Done
            </Button>
          </Flex>
        </Box>
      </div>
    );
  };

  render() {
    const { asModal, listType, onClose, onCloseListsForm } = this.props;
    const { bottom, left, marginLeft, right, top } = this.state;

    if (asModal) {
      const onModalClose = () => {
        onClose();
        onCloseListsForm();
      };

      return (
        <Modal isOpen onClose={onModalClose} padding="24px 0" width="384px">
          <Modal.Header
            onClose={onModalClose}
            wrapperCss={{ paddingLeft: '24px', paddingRight: '24px' }}
          >
            {listType === 'text-messages' ? 'Add this SMS to list' : 'Add this email to list'}
          </Modal.Header>
          <Modal.Body>{this.renderLists()}</Modal.Body>
        </Modal>
      );
    }

    return (
      <Box
        backgroundColor="white"
        borderRadius="4px"
        bottom={bottom}
        boxShadow={`0px 2px 8px ${theme.colors.dark02}`}
        left={left}
        marginLeft={marginLeft}
        padding="24px 0"
        position="absolute"
        right={right}
        top={top}
        width="384px"
        zIndex={theme.zIndices.dropdown}
      >
        <Box padding="0 24px" position="relative">
          <Heading
            as="h3"
            fontSize="18px"
            fontWeight="500"
            lineHeight="24px"
            css={{ marginBottom: '16px' }}
          >
            Add this email to list
          </Heading>

          <Button
            onClick={() => {
              onClose();
              onCloseListsForm();
            }}
            variant="none"
            css={{
              margin: '0',
              padding: '0',
              position: 'absolute',
              right: '24px',
              top: '0',
            }}
          >
            <CrossSmallIcon />
          </Button>
        </Box>

        {this.renderLists()}
      </Box>
    );
  }
}

Lists.defaultProps = {
  asModal: false,
  listType: 'emails',
  parentEl: null,
};

Lists.propTypes = {
  asModal: PropTypes.bool,
  emails: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fetchTeamLists: PropTypes.func.isRequired,
  fetchUserLists: PropTypes.func.isRequired,
  isValidTeam: PropTypes.bool,
  listType: PropTypes.string,
  listsOwner: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onCloseListsForm: PropTypes.func.isRequired,
  onOpenCreateForm: PropTypes.func.isRequired,
  onUpdateOwner: PropTypes.func.isRequired,
  parentEl: PropTypes.shape({}),
  teamLists: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  teamListsLoading: PropTypes.bool.isRequired,
  textMessages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  userLists: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  userListsLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  isValidTeam: customListsSelectors.isValidTeamSelector(state),
  teamLists: customListsSelectors.teamListsDataSelector(state),
  teamListsLoading: customListsSelectors.isTeamListsLoadingSelector(state),
  userLists: customListsSelectors.userListsDataSelector(state),
  userListsLoading: customListsSelectors.isUserListsLoadingSelector(state),
});

const mapDispatchToProps = (dispatch, { listType }) => ({
  fetchTeamLists: () => dispatch(customListsActions.fetchTeamListsRequest({ list_type: listType })),
  fetchUserLists: () => dispatch(customListsActions.fetchUserListsRequest({ list_type: listType })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Lists);
