import React, { Component } from 'react';
import humanize from 'humanize-number';
import moment from 'moment';

import api from '../../lib/api';

import { Loader, Table } from '../mc-ui';

import * as S from './styles';

const cellCSS = {
  fontSize: '12px',
  fontWeight: '500',
  letterSpacing: '0.02em',
  lineHeight: '1.25em',
  padding: '16px 0',
};

class PaymentHistory extends Component {
  state = {
    invoices: [],
    loading: true,
  };

  componentDidMount() {
    this.request();
  }

  request = () => {
    const { subscription, } = this.props
    this.setState(
      () => ({ loading: true }),
      async () => {
        const response = await api.get(`/v3/subscriptions/${subscription.id}/invoices`);

        this.setState(() => ({
          invoices: response.data.data,
          loading: false,
        }));
      },
    );
  };

  getInvoiceAfterClick = event => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    const { invoiceId } = event.currentTarget.dataset;
    return this.state.invoices.find(i => i.id === invoiceId);
  };

  download = event => {
    const invoice = this.getInvoiceAfterClick(event);
    window.open(invoice.links.pdf, '_blank');
  };

  open = event => {
    const invoice = this.getInvoiceAfterClick(event);
    window.open(invoice.links.self, '_blank');
  };

  render() {
    const { invoices, loading } = this.state;

    if (loading) return <Loader />;

    return (
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Cell>Date</Table.Cell>
            <Table.Cell>Amount Due</Table.Cell>
            <Table.Cell>Amount Paid</Table.Cell>
            <Table.Cell>Status</Table.Cell>
            <Table.Cell />
          </Table.Row>
        </Table.Head>

        <Table.Body>
          {invoices.map(invoice => (
            <Table.Row
              key={invoice.id}
              data-invoice-id={invoice.id}
              onClick={this.open}
              css={{ cursor: 'pointer' }}
            >
              <Table.Cell color="dark08" css={cellCSS}>
                {moment(invoice.attributes.date).format('DD MMM YYYY')}
              </Table.Cell>
              <Table.Cell color="dark08" css={cellCSS}>
                ${humanize(invoice.attributes.amount_due / 100)}
              </Table.Cell>
              <Table.Cell color="dark08" css={cellCSS}>
                ${humanize(invoice.attributes.amount_paid / 100)}
              </Table.Cell>
              <Table.Cell color="dark08" css={cellCSS}>
                {invoice.attributes.closed ? <S.PaidBadge /> : <S.UnpaidBadge />}
              </Table.Cell>
              <Table.Cell>
                {!invoice.attributes.closed && (
                  <S.DownloadButton data-invoice-id={invoice.id} onClick={this.download} />
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  }
}

export default PaymentHistory;
