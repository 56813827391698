import React from 'react'
import { Link } from 'react-router'
import { connect } from 'react-redux'

import Container from './Container'
import ManageAccountInfo from './ManageAccountInfo'

class ManageAccount extends React.Component {
  render() {
    return (
      <div>
        <ManageAccountInfo />
        <Container>
          <p>Want to update your plan? <Link to='/manage/plan'>Click here to manage your plan.</Link></p>
        </Container>
      </div>
    )
  }
}

const s = (state) => ({ user: state.user })
export default connect(s)(ManageAccount)

