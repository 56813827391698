import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

import BrandLogo from '../../ui/BrandLogo';

const Logo = ({ brand }) => (
  <Link to={`/companies/${brand.id}`}>
    <BrandLogo brand={brand} css={{ height: '32px', width: '32px' }} />
  </Link>
);

Logo.propTypes = {
  brand: PropTypes.shape({}).isRequired,
};

export default Logo;
