import { isNil } from 'ramda';
import { createSelector } from 'reselect';

const getModelType = type => (type === 'companies' ? 'brands' : type);

const getState = state => state.newReports.timeSeries || {};

export const getTimeSeries = (modelId, modelType, chart, interval, startDate, endDate) =>
  createSelector(getState, state => {
    const dateKey = `${startDate}-${endDate}`;
    const dataObj = state[getModelType(modelType)][modelId] || {};
    const chartObj = dataObj[chart] || {};
    const intervalObj = chartObj[interval] || {};
    return intervalObj[dateKey] || {};
  });

export const getTimeSeriesData = (modelId, modelType, chart, interval, startDate, endDate) =>
  createSelector(
    getTimeSeries(modelId, modelType, chart, interval, startDate, endDate),
    timeSeries => timeSeries.data || {},
  );

export const getTimeSeriesLoading = (modelId, modelType, chart, interval, startDate, endDate) =>
  createSelector(
    getTimeSeries(modelId, modelType, chart, interval, startDate, endDate),
    timeSeries => (isNil(timeSeries.loading) ? true : timeSeries.loading),
  );

export const getTimeSeriesHistoricalTrendData = (
  modelId,
  modelType,
  chart,
  interval,
  startDate,
  endDate,
) =>
  createSelector(
    getTimeSeries(modelId, modelType, chart, interval, startDate, endDate),
    timeSeries => {
      const historicalTrendObj = timeSeries.historicalTrend || {};
      return historicalTrendObj.data || {};
    },
  );

export const getTimeSeriesHistoricalTrendLoading = (
  modelId,
  modelType,
  chart,
  interval,
  startDate,
  endDate,
) =>
  createSelector(
    getTimeSeries(modelId, modelType, chart, interval, startDate, endDate),
    timeSeries => {
      const historicalTrendObj = timeSeries.historicalTrend || {};
      return isNil(historicalTrendObj.loading) ? true : historicalTrendObj.loading;
    },
  );

export const getTimeSeriesComparisonData = (
  modelId,
  modelType,
  chart,
  interval,
  startDate,
  endDate,
  comparisonStartDate,
  comparisonEndDate,
) =>
  createSelector(
    getTimeSeries(modelId, modelType, chart, interval, startDate, endDate),
    timeSeries => {
      const dateKey = `${comparisonStartDate}-${comparisonEndDate}`;
      const comparisonObj = timeSeries.comparison || {};
      const dateObj = comparisonObj[dateKey] || {};
      return dateObj.data || {};
    },
  );

export const getTimeSeriesComparisonLoading = (
  modelId,
  modelType,
  chart,
  interval,
  startDate,
  endDate,
  comparisonStartDate,
  comparisonEndDate,
) =>
  createSelector(
    getTimeSeries(modelId, modelType, chart, interval, startDate, endDate),
    timeSeries => {
      const dateKey = `${comparisonStartDate}-${comparisonEndDate}`;
      const comparisonObj = timeSeries.comparison || {};
      const dateObj = comparisonObj[dateKey] || {};
      return isNil(dateObj.loading) ? true : dateObj.loading;
    },
  );

export const getTimeSeriesBrandsData = (modelId, modelType, chart, interval, startDate, endDate) =>
  createSelector(
    getTimeSeries(modelId, modelType, chart, interval, startDate, endDate),
    timeSeries => {
      const brandsObj = timeSeries.brands || {};
      return brandsObj.data || [];
    },
  );

export const getTimeSeriesBrandsLoading = (
  modelId,
  modelType,
  chart,
  interval,
  startDate,
  endDate,
) =>
  createSelector(
    getTimeSeries(modelId, modelType, chart, interval, startDate, endDate),
    timeSeries => {
      const brandsObj = timeSeries.brands || {};
      return isNil(brandsObj.loading) ? true : brandsObj.loading;
    },
  );

export const getTimeSeriesBrandData = (
  modelId,
  modelType,
  chart,
  interval,
  startDate,
  endDate,
  brandId,
) =>
  createSelector(
    getTimeSeriesBrandsData(modelId, modelType, chart, interval, startDate, endDate),
    brandsData => brandsData.find(d => Number(d.brand.id) === Number(brandId)) || {},
  );
