import * as React from 'react';
import PropTypes from 'prop-types';

function LinkIcon({ fillOpacity, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M12.098 7.576l-.95.952a.798.798 0 101.132 1.128l.948-.95a1.46 1.46 0 112.065 2.065l-.95.95a.8.8 0 101.13 1.13l.95-.95a3.059 3.059 0 10-4.325-4.325zM11.748 14.318l-1.005 1.004a1.46 1.46 0 11-2.065-2.066l1.004-1.004a.799.799 0 00-1.13-1.13L7.55 12.127a3.058 3.058 0 004.324 4.324l1.004-1.004a.799.799 0 00-1.13-1.13z"
        fill="currentColor"
        fillOpacity={fillOpacity}
      />
      <path
        d="M13.876 10.124a.799.799 0 00-1.13 0L9.922 12.95a.8.8 0 101.13 1.13l2.824-2.824a.798.798 0 000-1.133z"
        fill="currentColor"
        fillOpacity={fillOpacity}
      />
    </svg>
  );
}

LinkIcon.propTypes = {
  fillOpacity: PropTypes.number,
};

LinkIcon.defaultProps = {
  fillOpacity: 1,
};

export default LinkIcon;
