import styled from "@emotion/styled";

const CustomIntervalWrapper = styled("div")(() => ({
  display: 'inline-block',
  marginLeft: '18px',
  '& .DateRangePickerWrapper.fixedSize': {
    width: '196px',
    '& .DateInput_input': {
      fontSize: '1.35416em !important',
      padding: '.39em .6em !important',
      width: '73px',
    },
  },
}));

export default CustomIntervalWrapper;
