import React from 'react';
import PropTypes from 'prop-types';
import styled from "@emotion/styled";

import { Flex, Text } from '../../../../../mc-ui';

import Chart from './Chart';
import DayModal from './DayModal';
import HolidayTag from './HolidayTag';
import TBD from './TBD';

const StyledFlex = styled(Flex)(
  ({
    disableClick,
    firstRow,
    isCurrentMonth,
    isFutureDate,
    isLastMonth,
    isNextMonth,
    lastDayOfCurrentMonth,
    lastDayOfLastMonth,
    lastRow,
    selected,
    theme,
  }) => {
    const hover =
      isFutureDate || disableClick
        ? {}
        : {
            border: '2px solid #0072D3',
            padding: '6px',
            backgroundColor: selected ? theme.colors.blueLight : theme.colors.tealLight,
          };

    const borders = {};
    if (lastDayOfLastMonth) {
      borders.borderRight = `1px solid ${theme.colors.dark04}`;
    } else {
      borders.borderRight = `1px solid ${theme.colors.dark5}`;
    }

    if (isLastMonth) {
      borders.borderBottom = `1px solid ${theme.colors.dark04}`;
    }

    if (firstRow && isCurrentMonth) {
      borders.borderTop = `1px solid ${theme.colors.dark04}`;
    }

    if (lastRow) {
      if (isCurrentMonth) {
        borders.borderBottom = `1px solid ${theme.colors.dark04}`;
      } else if (isNextMonth) {
        borders.borderTop = `1px solid ${theme.colors.dark04}`;
      }
    }

    if (lastDayOfCurrentMonth) {
      borders.borderRight = `1px solid ${theme.colors.dark04}`;
    }

    return {
      backgroundColor: selected ? theme.colors.blueLight : null,
      cursor: isFutureDate || disableClick ? 'default' : 'pointer',
      height: '120px',
      padding: '8px',
      position: 'relative',
      width: '84px',
      '&:hover': hover,
      ...borders,
    };
  },
);

const StyledText = styled(Text)(({ isCurrentMonth, isToday, theme }) => {
  let textColor = theme.colors.dark08;
  if (isToday) {
    textColor = theme.colors.white;
  } else if (!isCurrentMonth) {
    textColor = theme.colors.dark04;
  }

  return {
    backgroundColor: isToday ? theme.colors.dark08 : null,
    borderRadius: isCurrentMonth ? '50%' : null,
    color: textColor,
    fontFamily: theme.font.family,
    fontSize: '12px',
    fontWeight: '500',
    height: isCurrentMonth ? '24px' : null,
    letterSpacing: '0.12em',
    lineHeight: '24px',
    textAlign: 'center',
    textTransform: 'uppercase',
    width: isCurrentMonth ? '24px' : null,
  };
});

class Day extends React.Component {
  state = {
    isModalOpen: false,
  };

  toggleSelected = () => {
    const { disableClick, isFutureDate } = this.props;
    if (isFutureDate || disableClick) return;
    this.setState(currentState => ({ isModalOpen: !currentState.isModalOpen }));
  };

  renderData = () => {
    const { data, isFutureDate, isToday, model, todayIsNotDefined } = this.props;

    if (todayIsNotDefined && isToday) return <TBD />;
    if (isFutureDate) return <TBD />;

    return <Chart data={data} model={model} />;
  };

  render() {
    const {
      date,
      disableClick,
      firstRow,
      isCurrentMonth,
      isLastMonth,
      isNextMonth,
      isFutureDate,
      isToday,
      lastDayOfCurrentMonth,
      lastDayOfLastMonth,
      lastRow,
    } = this.props;
    const { isModalOpen } = this.state;
    const formatStr = isCurrentMonth ? 'D' : 'M/D';

    return (
      <div>
        <StyledFlex
          alignItems="center"
          direction="column"
          disableClick={disableClick}
          firstRow={firstRow}
          isCurrentMonth={isCurrentMonth}
          isFutureDate={isFutureDate}
          isLastMonth={isLastMonth}
          isNextMonth={isNextMonth}
          lastDayOfCurrentMonth={lastDayOfCurrentMonth}
          lastDayOfLastMonth={lastDayOfLastMonth}
          lastRow={lastRow}
          onClick={this.toggleSelected}
          selected={isModalOpen}
        >
          <StyledText isCurrentMonth={isCurrentMonth} isToday={isToday}>
            {date.format(formatStr)}
          </StyledText>
          {this.renderData()}
          <HolidayTag date={date} isToday={isToday} />
        </StyledFlex>

        {isModalOpen && <DayModal {...this.props} closeModal={this.toggleSelected} />}
      </div>
    );
  }
}

Day.defaultProps = {
  disableClick: false,
  todayIsNotDefined: false,
};

Day.propTypes = {
  data: PropTypes.shape({
    perentageBrands: PropTypes.number,
    promotionRate: PropTypes.number,
  }).isRequired,
  date: PropTypes.shape({}).isRequired,
  disableClick: PropTypes.bool,
  firstRow: PropTypes.bool.isRequired,
  isCurrentMonth: PropTypes.bool.isRequired,
  isFutureDate: PropTypes.bool.isRequired,
  isNextMonth: PropTypes.bool.isRequired,
  isToday: PropTypes.bool.isRequired,
  isLastMonth: PropTypes.bool.isRequired,
  lastDayOfCurrentMonth: PropTypes.bool.isRequired,
  lastDayOfLastMonth: PropTypes.bool.isRequired,
  lastRow: PropTypes.bool.isRequired,
  model: PropTypes.shape({ id: PropTypes.string, type: PropTypes.string }).isRequired,
  todayIsNotDefined: PropTypes.bool,
};

export default Day;
