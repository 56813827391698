import React from 'react';
import PropTypes from 'prop-types';

import CheckboxDefaultIcon from '../mc-ui/icons/CheckboxDefaultIcon';
import CheckboxDisabledIcon from '../mc-ui/icons/CheckboxDisabledIcon';
import CheckboxExcludeIcon from '../mc-ui/icons/CheckboxExcludeIcon';
import CheckboxHoverIcon from '../mc-ui/icons/CheckboxHoverIcon';
import CheckboxSelectedIcon from '../mc-ui/icons/CheckboxSelectedIcon';

export default function Checkbox({ checked, disabled, exclude, hover }) {
  if (checked) return <CheckboxSelectedIcon />;
  if (disabled) return <CheckboxDisabledIcon />;
  if (exclude) return <CheckboxExcludeIcon />;
  if (hover) return <CheckboxHoverIcon />;
  return <CheckboxDefaultIcon />;
}

Checkbox.defaultProps = {
  checked: false,
  disabled: false,
  exclude: false,
  hover: false,
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  exclude: PropTypes.bool,
  hover: PropTypes.bool,
};
