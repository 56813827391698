import * as React from 'react';
import PropTypes from 'prop-types';

function AdjustIcon({ fillOpacity, ...props }) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.028 12.833a2.084 2.084 0 01-3.889 0h-.972a.833.833 0 110-1.666h1.045a2.083 2.083 0 013.743 0h6.878a.833.833 0 110 1.666h-6.805zm-2.945 0a1.248 1.248 0 002 0 1.247 1.247 0 00-1-2c-.328 0-.626.127-.85.334a1.247 1.247 0 00-.15 1.666zM13.917 7a1.247 1.247 0 01.15-1.667h-7.9a.833.833 0 000 1.667h7.75zm2 0h1.916a.833.833 0 100-1.667h-2.066A1.247 1.247 0 0115.917 7z"
        fill="currentColor"
        fillOpacity={fillOpacity}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.917 8.333a2.083 2.083 0 110-4.166 2.083 2.083 0 010 4.166zm0-.833a1.25 1.25 0 110-2.5 1.25 1.25 0 010 2.5zM13.917 18.667a1.247 1.247 0 01.15-1.667h-7.9a.833.833 0 100 1.667h7.75zm2 0h1.916a.833.833 0 100-1.667h-2.066a1.247 1.247 0 01.15 1.667z"
        fill="currentColor"
        fillOpacity={fillOpacity}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.917 20a2.083 2.083 0 110-4.167 2.083 2.083 0 010 4.167zm0-.833a1.25 1.25 0 110-2.5 1.25 1.25 0 010 2.5z"
        fill="currentColor"
        fillOpacity={fillOpacity}
      />
    </svg>
  );
}

AdjustIcon.propTypes = {
  fillOpacity: PropTypes.number,
};

AdjustIcon.defaultProps = {
  fillOpacity: 1,
};

export default AdjustIcon;
