import { createSelector } from 'reselect';

import { teamsSelectors } from '../teams';

export const { isMembershipActiveSelector, teamSelector } = teamsSelectors;

export const getState = state => state.savedSearches;

export const isTeamFiltersLoadingSelector = createSelector(
  getState,
  state => !!state.teamFilters.loading,
);

export const teamFiltersDataSelector = createSelector(
  getState,
  state => state.teamFilters.data || [],
);
export const teamFilterIdsSelector = createSelector(getState, state => state.teamFilterIds || []);

export const isUserFiltersLoadingSelector = createSelector(
  getState,
  state => !!state.userFilters.loading,
);

export const userFiltersDataSelector = createSelector(
  getState,
  state => state.userFilters.data || [],
);
export const userFilterIdsSelector = createSelector(getState, state => state.userFilterIds || []);

export const isValidTeamSelector = createSelector(
  teamSelector,
  isMembershipActiveSelector,
  (team, isMembershipActive) => team && isMembershipActive,
);
