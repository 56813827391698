import React from 'react'

import hoc from './hoc'
import AddToListEmailsWrapper from './ui/AddToListEmailsWrapper'
import ImageWrapper from './ui/ImageWrapper'

const AddToListEmails = ({
  model,
  selectedEmails,
  selectedEmailsCount
}) => {
  const size = '300x400'
  if (model && model.attributes && model.attributes.guid) {
    selectedEmailsCount = 1
    selectedEmails = [].concat(model)
  }

  return (
    <AddToListEmailsWrapper single={selectedEmailsCount === 1}>
      {selectedEmails.map((email) => (
        <ImageWrapper key={email.id}>
          <img
            className='pure-img'
            src={`//assets.mailcharts.com/emails/${email.attributes.guid}/${size}.png`}
          />
        </ImageWrapper>
      ))}
    </AddToListEmailsWrapper>
  )
}

const enhance = hoc('AddToListEmails')

export default enhance(AddToListEmails)
