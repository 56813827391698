/** @jsx jsx */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import pluralize from 'pluralize';
import { Link } from 'react-router';
import { take } from 'ramda';
import { jsx, withTheme } from '@emotion/react';

import { Box, Flex, Heading, ScrollableBox, Text } from '../../../mc-ui';
import Email from '../../../ui/Email';

const ListsList = ({ collection: lists, theme }) => (
  <ScrollableBox height="346px" scrollBoxHeight="328px">
    {lists.map(list => (
      <Link
        key={list.id}
        to={`/lists/${list.id}`}
        css={{
          textDecoration: 'none',
          '&:hover': { '& h5': { color: `${theme.colors.blue} !important` } },
        }}
      >
        <Box marginBottom="24px">
          <Flex justifyContent="space-between" marginBottom="8px">
            {take(4, list.relationships.emails.data).map(email => (
              <Box key={email.id} height="64px" width="64px">
                <Email
                  email={email}
                  height="100%"
                  width="100%"
                  cssStyle={{
                    cursor: 'pointer',
                    height: '100%',
                    margin: '0',
                    padding: '0',
                    width: '100%',
                  }}
                />
              </Box>
            ))}
          </Flex>

          <Heading
            as="h5"
            css={{
              fontSize: '14px',
              letterSpacing: '0.02em',
              lineHeight: '1.25em',
              textTransform: 'capitalize',
            }}
          >
            {list.attributes.name}
          </Heading>

          <Text fontSize="12px">
            {list.attributes.approximateEmails}{' '}
            {pluralize('email', list.attributes.approximateEmails)} .{' '}
            {moment.duration(moment().diff(moment(list.attributes.updatedAt))).humanize()}
          </Text>
        </Box>
      </Link>
    ))}
  </ScrollableBox>
);

ListsList.propTypes = {
  collection: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.string,
      attributes: PropTypes.shape({
        approximateEmails: PropTypes.number,
        name: PropTypes.string,
        updatedAt: PropTypes.string,
      }),
      relationships: PropTypes.shape({
        emails: PropTypes.shape({
          data: PropTypes.arrayOf(PropTypes.shape({})),
        }),
      }),
    }),
  ).isRequired,
  theme: PropTypes.shape({}).isRequired,
};

export default withTheme(ListsList);
