import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@emotion/react';

import componentFactory from './componentFactory';

const Heading = ({
  children,
  color,
  css,
  padding,
  fontSize,
  fontWeight,
  letterSpacing,
  lineHeight,
  theme,
  ...props
}) => {
  const componentStyle = {
    color: theme.colors[color],
    fontFamily: theme.font.family,
    letterSpacing,
    fontSize,
    fontWeight,
    lineHeight,
    margin: 0,
    padding: 0,
  };

  const Component = componentFactory({ componentStyle, css, ...props });
  return <Component>{children}</Component>;
};

Heading.defaultProps = {
  as: 'h1',
  css: {},
  color: 'dark1',
  fontSize: null,
  fontWeight: 500,
  letterSpacing: '0.02em',
  lineHeight: null,
};

Heading.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  css: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  fontSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fontWeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  letterSpacing: PropTypes.string,
  lineHeight: PropTypes.string,
  theme: PropTypes.shape({}).isRequired,
};

export default withTheme(Heading);
