import * as React from 'react';
import PropTypes from 'prop-types';

const CommentIcon = ({ fillOpacity, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 3a2 2 0 0 0-2 2v9.333a2 2 0 0 0 2 2h1.6c.22 0 .4.18.4.4v3.555a.4.4 0 0 0 .608.342l6.82-4.15a1 1 0 0 1 .52-.147H20a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2H4Zm3.6 8a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm5.6-1a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm3.4 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
      fill="currentColor"
      fillOpacity={fillOpacity}
    />
  </svg>
);

CommentIcon.propTypes = {
  fillOpacity: PropTypes.number,
};

CommentIcon.defaultProps = {
  fillOpacity: 1,
};

export default CommentIcon;
