import colors from '../../theme/colors';

const multiValueStyles = base => ({
  ...base,
  backgroundColor: 'transparent',
  border: `1px solid ${colors.dark5}`,
  borderRadius: 4,
  height: 16,
  margin: 0,
  marginRight: 5,
  padding: 0,
  '&:hover': {
    borderColor: '#091E42',
  },
});

export default multiValueStyles;
