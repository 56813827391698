import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';

import { Box, Select } from '../../mc-ui';

const options = [
  { value: 'created_at:desc', label: 'Newest Filters' },
  { value: 'created_at:asc', label: 'Oldest Filters' },
  { value: 'name:asc', label: 'A-Z' },
  { value: 'name:desc', label: 'Z-A' },
];

export default function SortSelect({ disabled, handleChange, value }) {
  const initialValues = { sort: value };

  return (
    <Box
      width="200px"
      css={{
        fontSize: '14px',
        height: '32px',
      }}
    >
      <Form initialValues={initialValues} onSubmit={() => {}}>
        {() => (
          <form>
            <Field
              component={Select}
              id="sort"
              isDisabled={disabled}
              name="sort"
              options={options}
              onChange={handleChange}
            />
          </form>
        )}
      </Form>
    </Box>
  );
}

SortSelect.defaultProps = {
  disabled: false,
};

SortSelect.propTypes = {
  disabled: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.oneOf(options.map(o => o.value)).isRequired,
};
