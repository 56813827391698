import React from 'react'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import EmailThumbnail from './EmailThumbnail'
import EmailDate from './EmailDate'
import moment from 'moment'
import styles from './styles.css'

class Event extends React.Component {
  render () {
    return (
      <div style={{ padding: 5, background: '#a767bf', borderRadius: 2, color: 'white' }} className='pure-u-1'>
        <div className='pure-u-3-4'>
          <p style={{ padding: 0, margin: 0 }}>{this.props.model.attributes.label}</p>
        </div>
        <div className='pure-u-1-4'>
          <EmailDate style={{ padading: 0, margin: 0 }} date={this.props.model.attributes.occurred_at} />
        </div>
      </div>
    )
  }
}
class Email extends React.Component {
  render () {
    return (
      <div style={{ padding: 5 }} className='pure-u-1'>
        <EmailThumbnail email={this.props.model} />
      </div>
    )
  }
}

const addressDate = (address) => {
  let event = address.models.filter(m => m.type === 'events').shift()
  let created = moment(event ? event.attributes.occurred_at : address.attributes.created_at)
  let now = moment()
  let date = created.format('M/D/YY')
  if (now.diff(created, 'day') < 1) {
    date = created.format('hh:mm a')
  } else if (now.diff(created, 'year') < 1) {
    date = created.format('MMM D')
  }
  return `Created ${created.fromNow()} (${date})`
}

const dormant = ({ idx, view, model }) => {
  const SIX_MONTHS_DIFF = 1 - (3000000 * 6)
  let timeDiff = moment(model.attributes.date).diff(moment(), 'seconds')
  if (idx === 0) console.log(timeDiff, SIX_MONTHS_DIFF)
  return timeDiff < SIX_MONTHS_DIFF
}

class Journeys extends React.Component {
  state = {
    view: 'welcome',
    showEvents: false
  }

  toggleEvents = (event) => {
    event.preventDefault()
    this.setState({ showEvents: !this.state.showEvents })
  }

  toggleView = (event) => {
    event.preventDefault()
    this.setState({ view: event.target.id })
  }

  componentDidMount () {
    this.props.dispatch({ type: 'REQUEST_JOURNEYS', ...this.props.params })
  }

  componentWillUnmount () {
    this.props.dispatch({ type: 'FLUSH' })
  }

  render() {
    return (
      <div className='pure-u-1'>
        {this.props.journeys.loading
          ? <p>Loading</p>
          : <div>
            <div className='pure-u-11-12'>
              <div className='pure-menu pure-menu-horizontal'>
                <ul className='pure-menu-list'>
                  <li className='pure-menu-item'><a onClick={this.toggleView} id='welcome' href='#' className='pure-menu-link'>Welcome journey</a></li>
                  <li className='pure-menu-item'><a onClick={this.toggleView} id='recent' href='#' className='pure-menu-link'>Recent emails</a></li>
                </ul>
              </div>
            </div>
            <div className='pure-u-1-12'>
              <a onClick={this.toggleEvents} id='thumbnail' href='#' style={{ fontSize: '.8em' }} className='pure-button'>Show events</a>
            </div>
            {this.props.journeys.data.addresses.map((address, index, addresses) => {
              return (
                <div key={index} className='pure-u-1-5'>
                  <div style={{ padding: '0 10px' }}>
                    <h2>{address.attributes.description}</h2>
                    <h5>{addressDate(address)}</h5>
                    {dormant({ model: address.models[address.models.length - 1] })
                      ? <div className={styles['journey-elapsed']}>
                        <a href='#' className='pure-button'>This address looks dormant<br />Request a refresh</a>
                      </div>
                      : null
                    }
                    {address.models
                      .map((model, index, models) => {
                        if (this.state.view === 'recent') { // reverses the array
                          return models[models.length - (1 + index)]
                        }
                        return model
                      })
                      .filter((model, index) => index < 25) // limit to 25 emails
                      .filter((model, index) => {
                        return this.state.showEvents || model.type === 'emails'
                      })
                      .map((model, idx, models) => {
                        model.attributes.address_description = address.attributes.description
                        let previous = models[idx - 1]
                        let elapsed = previous
                          ? moment.duration(
                            moment(model.attributes.sent_at || model.attributes.occurred_at)
                              .diff(moment(previous.attributes.sent_at || previous.attributes.occurred_at))
                          ).humanize()
                          : null

                        return (
                          <div key={idx}>
                            {idx === 0 && <div>
                              <div style={{ borderBottom: '1px solid #eee' }} className='pure-u-1'>
                                <p>Showing {models.filter(m => m.type === 'emails').length} emails&nbsp;
                                (<Link to={`/personas/${address.id}`}>See all {address.models.filter(m => m.type === 'emails').length}</Link>)
                                </p>
                              </div>
                            </div>}

                            <div className={styles['journey-model']}>
                              {elapsed &&
                                <div className={styles['journey-elapsed']}>
                                  <p>{elapsed} {this.state.view === 'welcome' ? 'later' : 'earlier'}</p>
                                </div>
                              }
                              {model.type === 'emails' ? <Email model={model} /> : <Event model={model} />}
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              )
            })}
          </div>
        }
      </div>
    )
  }
}
const s = (state) => ({ journeys: state.journeys })
export default connect(s)(Journeys)
