import api from '../lib/api'

export default store => next => action => {
  next(action)
  switch (action.type) {
    case 'REQUEST_SUGGESTIONS':
      (action.query.q ?
        api({ method: 'get', url: action.url || '/v2/suggest/all', params: action.query })
        :
        Promise.resolve()
      )
      .then((r = {}) => {
        const { data = {} } = r
        store.dispatch({ type: 'RECEIVE_SUGGESTIONS', data })
      })
    break
  }
}
