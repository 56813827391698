/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import { withTheme } from '@emotion/react';

import { Flex, Text } from '../../mc-ui';

const Legend = ({ model, theme }) => (
  <Flex css={{ marginBottom: '16px' }}>
    {model.type === 'groups' && (
      <Flex alignItems="center" css={{ width: '234px' }}>
        <span
          css={{
            backgroundColor: theme.colors.blue,
            borderRadius: '50%',
            display: 'inline-block',
            height: '16px',
            marginRight: '8px',
            width: '16px',
          }}
        />
        <Text
          color="dark1"
          fontSize="12px"
          fontWeight="500"
          letterSpacing="0.02em"
          lineHeight="1.25em"
        >
          % brands that sent email
        </Text>
      </Flex>
    )}

    <Flex alignItems="center" css={{ width: '160px' }}>
      <span
        css={{
          backgroundColor: theme.colors.tealMedium,
          borderRadius: '50%',
          display: 'inline-block',
          height: '16px',
          marginRight: '8px',
          width: '16px',
        }}
      />
      <Text
        color="dark1"
        fontSize="12px"
        fontWeight="500"
        letterSpacing="0.02em"
        lineHeight="1.25em"
      >
        Average promotion rate
      </Text>
    </Flex>
  </Flex>
);

Legend.propTypes = {
  model: PropTypes.shape({ id: PropTypes.string, type: PropTypes.string }).isRequired,
  theme: PropTypes.shape().isRequired,
};

export default withTheme(Legend);
