import React from 'react';
import PropTypes from 'prop-types';

function ArrowRightIcon({ fillOpacity, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 9v2h9.117l-3.375 3.375a.86.86 0 001.217 1.216l4.643-4.643a1.288 1.288 0 000-1.825l-4.643-4.642a.86.86 0 00-1.217 1.216L13.045 9H4z"
        fill="currentColor"
        fillOpacity={fillOpacity}
      />
    </svg>
  );
}

ArrowRightIcon.propTypes = {
  fillOpacity: PropTypes.number,
};

ArrowRightIcon.defaultProps = {
  fillOpacity: 0.6,
};

export default ArrowRightIcon;
