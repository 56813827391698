import styled from "@emotion/styled";

const Overlay = styled("div")({
  background: 'rgba(0, 0, 0, .33)',
  boxSizing: 'border-box',
  height: '100%',
  left: '0',
  overflowY: 'scroll',
  position: 'fixed',
  top: '0',
  width: '100%',
  zIndex: '9996',
});

export default Overlay;
