import React from 'react';

const JourneysIcon = props => (
  <svg viewBox="0 0 64 64" width="1em" height="1em" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle fill="#0379D5" cx={32} cy={32} r={32} />
      <path
        d="M41 31h12M16 18h6M18 46h4"
        stroke="#FFF"
        strokeWidth={1.6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle fill="#FFF" cx={31} cy={45} r={5} />
      <circle fill="#FFF" cx={31} cy={31} r={5} />
      <circle fill="#FFF" cx={31} cy={17} r={5} />
      <path
        d="M31 17v28.636"
        stroke="#FFF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default JourneysIcon;
