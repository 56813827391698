import addresses from './addresses-middleware';
import companies from './companies-middleware';
import dashboard from './dashboard-middleware';
import demo from './demo-middleware';
import email from './email-middleware';
import groups from './groups-middleware';
import journeys from './journeys-middleware';
import lists from './lists-middleware';
import navigation from './navigation-middleware';
import requested from './requested-middleware';
import searches from './searches-middleware';
import suggestions from './suggestions-middleware';
import technologies from './technologies-middleware';
import trends from './trends-middleware';
import user from './user-middleware';

export default [
  addresses,
  companies,
  dashboard,
  demo,
  email,
  groups,
  journeys,
  lists,
  navigation,
  requested,
  searches,
  suggestions,
  technologies,
  trends,
  user,
];
