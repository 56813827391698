import React from 'react';
import PropTypes from 'prop-types';

import { Box, Flex, Text } from '../../../../../mc-ui';
import BetweenEmailsIcon from '../../../../../mc-ui/icons/BetweenEmailsIcon';
import CalendarStartIcon from '../../../../../mc-ui/icons/CalendarStartIcon';
import DurationIcon from '../../../../../mc-ui/icons/DurationIcon';
import EmailIcon from '../../../../../mc-ui/icons/EmailIcon';

import DateDataPoint from './DateDataPoint';
import NumberDataPoint from './NumberDataPoint';

const DataPoints = ({ durationDays, endDate, intervalDays, startDate, totalEmails, triggeredEmails, }) => (
  <Box>
    <Flex marginBottom="3px">
      <Text color="dark06" lineHeight="1em" css={{ marginRight: '10px' }}>
        <CalendarStartIcon height="20px" width="20px" />
      </Text>

      <DateDataPoint endDate={endDate} startDate={startDate} />
    </Flex>

    <Flex marginBottom="3px">
      <Text color="dark06" lineHeight="1em" css={{ marginRight: '10px' }}>
        <DurationIcon height="20px" width="20px" />
      </Text>

      <NumberDataPoint label="Duration" pluralLabel="day" value={durationDays} />
    </Flex>

    <Flex marginBottom="3px">
      <Text color="dark06" lineHeight="1em" css={{ marginRight: '10px' }}>
        <EmailIcon fillOpacity={1} height="20px" width="20px" />
      </Text>

      <NumberDataPoint label="Triggered emails" value={triggeredEmails} />
    </Flex>

    <Flex>
      <Text color="dark06" lineHeight="1em" css={{ marginRight: '10px' }}>
        <BetweenEmailsIcon height="20px" width="20px" />
      </Text>

      <NumberDataPoint label="Time between emails" pluralLabel="day" value={intervalDays} />
    </Flex>
  </Box>
);

DataPoints.defaultProps = {
  durationDays: null,
  endDate: null,
  intervalDays: null,
  startDate: null,
  totalEmails: null,
};

DataPoints.propTypes = {
  durationDays: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  endDate: PropTypes.string,
  intervalDays: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  startDate: PropTypes.string,
  totalEmails: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default DataPoints;
