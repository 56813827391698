import React from 'react';
import { PropTypes } from 'prop-types';
import { withTheme } from '@emotion/react';

import { Flex } from '../../../mc-ui';

const Body = ({ active, children, theme }) => (
  <Flex
    direction="column"
    css={{
      border: `1px solid ${theme.colors.dark02}`,
      borderRadius: active === 'tabOne' ? '0 4px 4px 4px' : '4px 0 4px 4px',
      padding: '24px 24px 12px 24px',
    }}
  >
    {children({ active })}
  </Flex>
);

Body.propTypes = {
  active: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
  theme: PropTypes.shape({}).isRequired,
};

export default withTheme(Body);
