import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';

import api from '../../../../lib/api';
import { dollar } from '../../../../lib/utils';
import { teamsActions, teamsSelectors } from '../../../../modules/teams';

import { Button, Flex, Heading, Loader, Modal, Text } from '../../../mc-ui';

import * as S from './styles';

class ToggleMemberStatusConfirmationModal extends React.Component {
  state = {
    data: {},
    loading: true,
  };

  componentDidMount() {
    this.request();
  }

  request = async () => {
    const { actionName, membersMeta, subscription } = this.props;
    const activeMembers = membersMeta.counts.active;
    const quantity = actionName === 'archive' ? activeMembers - 1 : activeMembers + 1;
    const params = { quantity };

    const response = await api.get(`/v3/subscriptions/${subscription.id}/calculate`, { params });

    this.setState(() => ({
      data: response.data.data[0],
      loading: false,
    }));
  };

  confirm = event => {
    if (event) event.preventDefault();

    const { actionName, archiveMember, closeModal, activateMember } = this.props;

    if (actionName === 'archive') {
      archiveMember();
    } else {
      activateMember();
    }

    closeModal();
  };

  render() {
    const { actionName, closeModal, memberEmail } = this.props;
    const { data, loading } = this.state;

    return (
      <Modal isOpen onClose={closeModal}>
        <Modal.Body maxHeight="344px">
          {loading ? (
            <Loader />
          ) : (
            <Flex alignItems="center" direction="column" justifyContent="center" width="696px">
              <img src="/images/alert.png" alt="Alert" />

              <Heading
                as="h2"
                fontSize="24px"
                fontWeight="300"
                lineHeight="1.32em"
                css={{ margin: '40px 0 24px 0' }}
              >
                Are you sure?
              </Heading>

              <Text margin="0 0 24px 0">
                You are about to {actionName} <strong>{memberEmail}</strong>
              </Text>

              <S.UL>
                {actionName === 'activate' && (
                  <S.LI>
                    You will be charged{' '}
                    <strong>
                      {dollar(data.attributes.updated_invoice_amount, { fromCents: true })}
                    </strong>{' '}
                    immediately.
                  </S.LI>
                )}
                <S.LI>
                  <em>
                    Your next bill on{' '}
                    {moment(data.attributes.next_invoice_date).format('ddd, MMM. D, YYYY')} will
                    total {dollar(data.attributes.next_invoice_amount, { fromCents: true })} for
                    your entire team.
                  </em>
                </S.LI>
              </S.UL>

              <Flex justifyContent="space-between" marginTop="24px" width="220px">
                <Button
                  css={{
                    height: '40px',
                    lineHeight: '40px',
                    margin: 0,
                    padding: 0,
                    textTransform: 'capitalize',
                    width: '100px',
                  }}
                  onClick={this.confirm}
                  variant="primary"
                >
                  {actionName}
                </Button>

                <Button
                  css={{
                    height: '40px',
                    lineHeight: '40px',
                    margin: 0,
                    padding: 0,
                    width: '100px',
                  }}
                  onClick={closeModal}
                  variant="secondary"
                >
                  Nevermind
                </Button>
              </Flex>
            </Flex>
          )}
        </Modal.Body>
      </Modal>
    );
  }
}

ToggleMemberStatusConfirmationModal.propTypes = {
  actionName: PropTypes.oneOf(['archive', 'activate']).isRequired,
  archiveMember: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  memberEmail: PropTypes.string.isRequired,
  membersMeta: PropTypes.shape({
    counts: PropTypes.shape({
      active: PropTypes.number,
    }),
  }).isRequired,
  subscription: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.shape({
      plan_interval: PropTypes.string,
    }),
  }).isRequired,
  activateMember: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  membersMeta: teamsSelectors.membersMetaSelector(state),
  subscription: teamsSelectors.subscriptionSelector(state),
});

const mapDispatchToProps = (dispatch, { memberId, teamId }) => ({
  archiveMember: () => dispatch(teamsActions.archiveMemberRequest(teamId, memberId)),
  activateMember: (...args) =>
    dispatch(teamsActions.activateMemberRequest(...[teamId, memberId, ...args])),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ToggleMemberStatusConfirmationModal);
