import * as React from 'react';
import PropTypes from 'prop-types';

function CheckboxMultiSelectIcon({ fillOpacity, ...props }) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 8H6a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-8a2 2 0 00-2-2zM6 6a4 4 0 00-4 4v8a4 4 0 004 4h8a4 4 0 004-4v-8a4 4 0 00-4-4H6z"
        fill="currentColor"
        fillOpacity={fillOpacity}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 5h8a2 2 0 012 2v8a2 2 0 01-1.075 1.774A3.996 3.996 0 0116.645 19H17a4 4 0 004-4V7a4 4 0 00-4-4H9a4 4 0 00-4 4v.354a3.996 3.996 0 012.226-1.28A2 2 0 019 5z"
        fill="currentColor"
        fillOpacity={fillOpacity}
      />
      <path
        d="M7 13.583L9.216 16 13 12"
        stroke="currentColor"
        strokeWidth={1.8}
        fillOpacity={fillOpacity}
      />
    </svg>
  );
}

CheckboxMultiSelectIcon.propTypes = {
  fillOpacity: PropTypes.number,
};

CheckboxMultiSelectIcon.defaultProps = {
  fillOpacity: 1,
};

export default CheckboxMultiSelectIcon;
