import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@emotion/react';

import { Flex, Text, Tooltip } from '../../mc-ui';

import Bar from './progressBar/Bar';

const ProgressBar = ({ id, label, size, theme, tooltip }) => (
  <Flex direction="column" justifyContent="flex-end" grow="1" css={{ padding: '0 24px' }}>
    <Text
      css={{
        color: theme.colors.dark1,
        fontSize: '14px',
        lineHeight: '1.56em',
        margin: '0 0 2px 0',
        padding: '0',
      }}
    >
      {label}
    </Text>
    <Tooltip
      id={`progress-bar-${id}`}
      element={<Bar size={100} />}
      wrapperWidth={`${size}%`}
      effect="float"
      dark
    >
      {tooltip}
    </Tooltip>
  </Flex>
);

ProgressBar.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  label: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  theme: PropTypes.shape({}).isRequired,
  tooltip: PropTypes.node.isRequired,
};

export default withTheme(ProgressBar);
