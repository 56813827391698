import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@emotion/react';
import { connect } from 'react-redux';

import { userSelectors } from '../../../modules/user';
import { Badge, Box, Flex, Loader } from '../../mc-ui';
import LeftChevron from '../../mc-ui/icons/LeftChevron';
import RightChevron from '../../mc-ui/icons/RightChevron';

import Controls from './Controls';
import Highlights from './Highlights';
import Info from './Info';
import NavigationButton from './NavigationButton';

import api from '../../../lib/api';

const badgeTitles = {
  unlocked: {
    emailLists: 'Unlocked email campaigns',
    featuredBrands: 'Unlocked Brands',
    featuredGroups: 'Unlocked Industries',
    featuredHolidays: 'Unlocked Holiday emails',
    featuredJourneyEmails: 'Unlocked Lifecycle Emails',
    featuredJourneys: 'Unlocked Journeys',
    featuredSMSLists: 'Unlocked SMS Lists',
  },
  featured: {
    emailLists: 'Featured email campaigns',
    featuredBrands: 'Featured Brands',
    featuredGroups: 'Featured Industries',
    featuredHolidays: 'Featured Holiday emails',
    featuredJourneyEmails: 'Featured Lifecycle Emails',
    featuredJourneys: 'Featured Journeys',
    featuredSMSLists: 'Featured SMS Lists',
  },
};

class Carousel extends React.Component {
  state = {
    index: 0,
    collection: [],
    loading: true,
  };

  UNSAFE_componentWillReceiveProps(props) {
    const { loading, collection } = props;
    if (!loading) this.fetchModel(collection);
  }

  selectIndex = event => {
    event.preventDefault();

    const { index } = event.target.dataset;
    const { collection } = this.props;
    this.fetchModel(collection, Number(index));
  };

  nextIndex = event => {
    event.preventDefault();

    const { collection } = this.props;
    const { index } = this.state;
    let newIndex = index + 1;
    if (!collection[newIndex]) {
      newIndex = 0;
    }
    this.fetchModel(collection, newIndex);
  };

  prevIndex = event => {
    event.preventDefault();

    const { collection } = this.props;
    const { index } = this.state;
    let newIndex = index - 1;
    if (newIndex < 0) {
      newIndex = collection.length - 1;
    }
    this.fetchModel(collection, newIndex);
  };

  fetchModel(collection, index = 0) {
    this.setState({ loading: true });
    let model = collection[index];
    if (this.props.type === 'featuredGroups') {
      api.get(`/v3/reports/overview`, { params: { group_id: model.id } }).then(resp => {
        [model] = resp.data.data;
        model.attributes.approximateCompanies = resp.data.meta.total_companies;
        model.attributes.frequency = resp.data.meta.stats.current.frequency.value;
        model.attributes.promotional = resp.data.meta.stats.current.promotional.value;
        model.relationships = {
          emails: { data: resp.data.included.filter(i => i.type === 'emails') },
          companies: { data: resp.data.included.filter(i => i.type === 'companies') },
        };
        this.setState({ index, model, collection, loading: false });
      });
    } else {
      this.setState({ index, model, collection, loading: false });
    }
  }

  render() {
    const { theme, type, isUserRestricted } = this.props;
    const { model, index, loading, collection } = this.state;

    const boxWrapperWidth = type !== 'featuredSMSLists' ? '702px' : '100%';
    const boxHighlightMessage = type !== 'featuredSMSLists' ? { justifyContent: 'space-between' } : {};

    return (
      <Box backgroundColor="white" borderRadius="4px" height="512px" width={boxWrapperWidth}>
        {loading ? (
          <Flex alignItems="center" height="100%" justifyContent="center">
            <Loader />
          </Flex>
        ) : (
          <Flex height="100%" { ...boxHighlightMessage } position="relative">
            <Box height="100%" padding="32px 0 32px 32px" width="283px">
              <Badge
                backgroundColor="yellowMedium"
                boxShadow={`1px 2px 4px ${theme.colors.dark5}`}
                color="dark1"
                fontSize="12px"
                letterSpacing="0.08em"
                lineHeight="1.56em"
                padding="4px 7.625px"
              >
                { !isUserRestricted ? (
                  badgeTitles['featured'][type]
                  ) : (
                  badgeTitles['unlocked'][type])}
              </Badge>

              <Flex direction="column" justifyContent="space-between">
                <Info model={model} type={type} />
                <Controls index={index} onClick={this.selectIndex} size={collection.length} />
              </Flex>
            </Box>

            <Highlights model={model} type={type} />

            <NavigationButton onClick={this.prevIndex} left>
              <LeftChevron />
            </NavigationButton>
            <NavigationButton onClick={this.nextIndex} right>
              <RightChevron />
            </NavigationButton>
          </Flex>
        )}
      </Box>
    );
  }
}

Carousel.defaultProps = {
  collection: [],
  loading: false,
  isUserRestricted: false,
};

Carousel.propTypes = {
  collection: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ),
  loading: PropTypes.bool,
  theme: PropTypes.shape({
    colors: PropTypes.shape({}),
  }).isRequired,
  type: PropTypes.string.isRequired,
  isUserRestricted: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  isUserRestricted: userSelectors.isUserRestrictedSelector(state)
});

export default connect(mapStateToProps)(withTheme(Carousel));
