import styled from "@emotion/styled";

const List = styled("div")({
  alignItems: 'center',
  display: 'flex',
  margin: '0 auto',
  marginBottom: '24px',
  maxWidth: '360px',
  width: '100%'
})

export default List
