import { flatten } from 'ramda';

import generateDefaultTooltipHTML from './generateDefaultTooltipHTML';

const comparisonPeriodRegex = /\{COMPARISON_PERIOD\}/;

const generateCompareDatesTooltipHTML = () => (getBody, tooltipModel) => {
  if (!tooltipModel.body) return null;

  const bodyLines = flatten(tooltipModel.body.map(getBody));
  let tooltipHTML = generateDefaultTooltipHTML()(getBody, tooltipModel);

  bodyLines.forEach((bodyLine, index) => {
    const color = tooltipModel.labelColors[index];
    if (comparisonPeriodRegex.test(bodyLine)) {
      tooltipHTML += '<div class="title">Comparison Period</div>';
      tooltipHTML += `<div class="line"><div class="bullet" style="background-color: ${
        color.borderColor === '#FFF' ? color.backgroundColor : color.borderColor
      }"></div> <div class="content">${bodyLine.replace(comparisonPeriodRegex, '')}</div></div>`;
    }
  });

  return tooltipHTML;
};

export default generateCompareDatesTooltipHTML;
