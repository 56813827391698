import colors from '../../theme/colors';

const disabledOptionStyles = base => ({
  ...base,
  color: `${colors.dark04} !important`,
  cursor: 'not-allowed !important',
  '&:active, &:hover, &:focus': {
    color: `${colors.dark04} !important`,
    cursor: 'not-allowed !important',
  },
});

export default disabledOptionStyles;
