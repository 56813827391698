import React from 'react'
import Tooltip from './ui/Tooltip'

class AverageExplanation extends React.Component {
  render() {
    let length = this.props.model.type == 'companies' ?
      this.props.model.relationships.journeys.data.length : this.props.model.attributes.approximate_companies
    let filter = this.props.model.type == 'companies' ? 'journeys' : 'companies'
    return length > 0 ? (
      <Tooltip
        style={{ display: 'inline-block' }}
      >
        <h5>How we calculate averages</h5>
        <p
          style={{ fontSize: `${12/16}em`, marginTop: 0 }}
        >
          Averages are calculated by the average of all {length} {filter} for {this.props.model.attributes.name}.
        </p>
      </Tooltip>
    ) : null
  }
}

export default AverageExplanation
