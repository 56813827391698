import React from 'react';
import PropTypes from 'prop-types';

import BarsChart from '../../../newReports/Benchmarks/BarsChart';

import { Box, Flex } from '../../../mc-ui';

import Heading from '../Heading';

const Highlights = ({ data }) => (
  <Flex justifyContent="space-between">
    <Box width="344px">
      <Heading css={{ marginBottom: '16px' }}>Emails sent / week</Heading>
      <BarsChart data={data.benchmarks.frequency} loading={false} width="100%" />
    </Box>

    <Box width="344px">
      <Heading css={{ marginBottom: '16px' }}>Promotion rate</Heading>
      <BarsChart
        data={data.benchmarks.promotional}
        adjustment={Math.round}
        append=" %"
        loading={false}
        width="100%"
      />
    </Box>
  </Flex>
);

Highlights.propTypes = {
  data: PropTypes.shape({
    benchmarks: PropTypes.shape({
      frequency: PropTypes.shape({
        buckets: PropTypes.arrayOf(PropTypes.shape({})),
      }),
      promotional: PropTypes.shape({
        buckets: PropTypes.arrayOf(PropTypes.shape({})),
      }),
    }),
  }).isRequired,
};

export default Highlights;
