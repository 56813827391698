import React from 'react';

import Section from '../Section';
import { Box, Flex, Heading } from '../mc-ui';

import BrowseByIndustries from '../BrowseByIndustries';

import FeaturedBrands from './FeaturedBrands';
import MCIndex from './MCIndex';
import MostTrackedBrands from './MostTrackedBrands';
import QuickSearch from './QuickSearch';
import SearchBar from './SearchBar';

const BrandsIndex = props => (
  <div>
    <Section>
      <Box paddingBottom="40px" paddingTop="25px">
        <Heading as="h3" css={{ fontWeight: '300', marginBottom: '20px' }}>
          Which <strong>brand</strong> are you looking for?
        </Heading>
        <SearchBar {...props} />
        <QuickSearch {...props} />
        <MCIndex {...props} />
        <Flex alignItems="flex-start" justifyContent="space-between" marginBottom="16px">
          <FeaturedBrands />
          <MostTrackedBrands />
        </Flex>
      </Box>
    </Section>

    <Section outerBackground="#FFF">
      <BrowseByIndustries type="companies" />
    </Section>
  </div>
);

export default BrandsIndex;
