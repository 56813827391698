import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';
import moment from 'moment';

import * as validators from '../../../lib/forms/validators';
import * as normalizers from '../../../lib/forms/normalizers';
import api from '../../../lib/api';
import { teamsActions, teamsSelectors } from '../../../modules/teams';

import { Button, Flex, Label, Modal, Select, TextareaField, Text, TextField } from '../../mc-ui';

import { SELECTABLE_ROLES } from '../Team/Members/RoleSelect';
import Box from '../../mc-ui/forms/checkbox/Box';

const splitEmails = value =>
  value
    .split(/\s|,/)
    .map(e => e.trim())
    .filter(e => e.length > 0);
const emailsValidator = (activeMembersCount, pendingMembersCount, limit) => value => {
  if (validators.required(value)) return validators.required(value);
  if (validators.email(value)) return validators.email(value);

  const emails = splitEmails(value);

  if (emails.length + activeMembersCount + pendingMembersCount > limit) {
    return 'Maximum members seats exceeded.';
  }

  return undefined;
};

class InviteFormModal extends React.Component {
  state = {
    quantity: (this.props.membersMeta.counts || { active: 1 }).active + 1,
    subscriptionCalculated: this.props.subscriptionCalculated,
  };

  handleSubmit = values => {
    const { inviteMember, toggleModal } = this.props;
    const { email, message, role } = values;
    // const emails = splitEmails(values.emails)

    // emails.forEach(email => {
    //   inviteMember({ email, message, role });
    // });
    inviteMember({ email: email.trim(), message, role });

    toggleModal();
  };

  handleEmailBlur = event => {
    const { membersMeta } = this.props;
    const { quantity } = this.state;
    const { value } = event.target;
    const emails = splitEmails(value);
    const newQuantity = emails.length + membersMeta.counts.active;

    this.calculateSubscription(newQuantity < quantity ? quantity : newQuantity);
  };

  calculateSubscription = async quantity => {
    const { isAnOldPlan, isSubscriptionInvoiced } = this.props;

    if (isAnOldPlan || isSubscriptionInvoiced) return;

    const { subscriptionCalculated } = this.state;
    const params = { quantity };
    const response = await api.get(`/v3/subscriptions/${subscriptionCalculated.id}/calculate`, {
      params,
    });

    this.setState(() => ({
      subscriptionCalculated: response.data.data[0],
    }));
  };

  render() {
    const {
      isAnOldPlan,
      isOpen,
      isSubscriptionAutomaticallyCharged,
      membersMeta,
      team,
      toggleModal,
    } = this.props;
    const { subscriptionCalculated } = this.state;

    return (
      <Modal isOpen={isOpen} onClose={toggleModal}>
        <Modal.Header onClose={toggleModal}>Invite new member</Modal.Header>
        <Form initialValues={{ role: 'member' }} onSubmit={this.handleSubmit}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body width="640px">
                <Flex justifyContent="space-between" marginBottom="16px">
                  <Label htmlFor="emails" width="424px">
                    Invite new member
                    <Field
                      autoComplete="off"
                      autoFocus
                      component={TextField}
                      id="email"
                      name="email"
                      onBlur={this.handleEmailBlur}
                      parse={normalizers.preventSpace}
                      placeholder="Email address of the member"
                      validate={emailsValidator(
                        membersMeta.counts.active,
                        membersMeta.counts.pending,
                        team.attributes.member_limit,
                      )}
                    />
                  </Label>

                  <Label htmlFor="role" width="200px">
                    Role
                    <Field
                      component={Select}
                      id="role"
                      name="role"
                      options={SELECTABLE_ROLES}
                      validate={validators.required}
                    />
                  </Label>
                </Flex>

                <Box>
                  <Label htmlFor="message">
                    Message (optional)
                    <Field
                      component={TextareaField}
                      css={{ height: '228px' }}
                      id="message"
                      name="message"
                      placeholder="Enter a personal message here if you'd like!"
                    />
                  </Label>
                </Box>
              </Modal.Body>

              <Modal.Footer justifyContent="space-between">
                {!isAnOldPlan &&
                  isSubscriptionAutomaticallyCharged && (
                    <Text>
                      You will be billed $
                      {subscriptionCalculated.attributes.updated_invoice_amount / 100} immediately.{' '}
                      <br />
                      Your next bill on{' '}
                      {moment(subscriptionCalculated.attributes.next_invoice_date).format(
                        'ddd, MMM. D, YYYY',
                      )}{' '}
                      will total ${subscriptionCalculated.attributes.next_invoice_amount / 100} for
                      your entire team.
                    </Text>
                  )}

                <Button
                  variant="primary"
                  type="submit"
                  css={{ height: '40px', lineHeight: '40px', padding: 0, width: '134px' }}
                >
                  Send invitation
                </Button>
              </Modal.Footer>
            </form>
          )}
        </Form>
      </Modal>
    );
  }
}

InviteFormModal.propTypes = {
  inviteMember: PropTypes.func.isRequired,
  isAnOldPlan: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isSubscriptionAutomaticallyCharged: PropTypes.bool.isRequired,
  isSubscriptionInvoiced: PropTypes.bool.isRequired,
  membersMeta: PropTypes.shape({
    counts: PropTypes.shape({
      active: PropTypes.number,
      pending: PropTypes.number,
      total: PropTypes.number,
    }),
  }).isRequired,
  subscriptionCalculated: PropTypes.shape({}).isRequired,
  team: PropTypes.shape({
    attributes: PropTypes.shape({
      member_limit: PropTypes.number,
    }),
  }).isRequired,
  toggleModal: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isAnOldPlan: teamsSelectors.isAnOldPlanSelector(state),
  isSubscriptionAutomaticallyCharged: teamsSelectors.isSubscriptionAutomaticallyChargedSelector(
    state,
  ),
  isSubscriptionInvoiced: teamsSelectors.isSubscriptionInvoicedSelector(state),
  team: teamsSelectors.teamSelector(state),
});
const mapDispatchToProps = (dispatch, { teamId }) => ({
  inviteMember: payload => dispatch(teamsActions.inviteMemberRequest(teamId, payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InviteFormModal);
