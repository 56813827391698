import * as React from 'react';
import PropTypes from 'prop-types';

function MobileIcon({ fillOpacity, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M6.8 4A1.2 1.2 0 018 2.8h8A1.2 1.2 0 0117.2 4v16a1.2 1.2 0 01-1.2 1.2H8A1.2 1.2 0 016.8 20V4z"
        stroke="currentColor"
        strokeWidth={1.6}
        fillOpacity={fillOpacity}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 5.333h12v11H6v-11zM12 19.2a1 1 0 100-2 1 1 0 000 2z"
        fill="currentColor"
        fillOpacity={fillOpacity}
      />
    </svg>
  );
}

MobileIcon.propTypes = {
  fillOpacity: PropTypes.number,
};

MobileIcon.defaultProps = {
  fillOpacity: 1,
};

export default MobileIcon;
