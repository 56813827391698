import React from 'react';
import PropTypes from 'prop-types';
import styled from "@emotion/styled";

import JourneyTypeIcon from './JourneyTypeIcon';
import JourneyExampleEmails from './JourneyExampleEmails';

const JourneyExampleHeaderContainer = styled("div")(() => ({
  backgroundColor: '#f3f5f7',
  backgroundPosition: 'center bottom',
  backgroundRepeat: 'no-repeat',
  height: '150px',
  overflow: 'hidden',
  position: 'relative',
}));


const JourneyExampleHeader = ({ journeyType, emails }) => (
  <JourneyExampleHeaderContainer>
    <JourneyTypeIcon type={journeyType} />
    <JourneyExampleEmails emails={emails} />
  </JourneyExampleHeaderContainer>
);

JourneyExampleHeader.propTypes = {
  emails: PropTypes.arrayOf(PropTypes.object).isRequired,
  journeyType: PropTypes.string.isRequired,
};

export default JourneyExampleHeader;
