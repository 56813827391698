/** @jsx jsx */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { flatten, isEmpty, uniq } from 'ramda';
import { jsx, withTheme } from '@emotion/react';

import { shuffle } from '../../../../../lib/utils';
import {
  subjectLinesApi as api,
  subjectLinesSelectors as selectors,
  subjectLinesUtils as utils,
} from '../../../../../modules/newReports/subjectLines';

import { Flex, Heading, Loader } from '../../../../mc-ui';

import Chart from '../Chart';
import CompareWithBrandForm from '../CompareWithBrandForm';
import Subjects from '../Subjects';

class CompareWithBrands extends React.Component {
  state = {
    allEmails: [],
    comparisonModel: null,
    comparison: { data: {}, loading: false },
    subjectsFilter: 'all',
  };

  subjectsFilters = [
    { value: 'all', label: 'All' },
    { value: '< 25', label: '< 25 chars' },
    { value: '25 - 55', label: '25 - 55 chars' },
    { value: '55 - 120', label: '55 - 120 chars' },
    { value: '120+', label: '120+ chars' },
  ];

  handleBrandSubmit = brand => {
    const comparisonModel =
      brand.id === 'premium'
        ? { type: 'groups', id: 'premium', name: 'Mailcharts Index' }
        : { ...brand, type: 'brands' };
    this.setState(
      () => ({ comparisonModel, comparison: { data: {}, loading: true } }),
      this.request,
    );
  };

  changeSubjectsFilter = event => {
    event.preventDefault();
    const { filter } = event.target.dataset;

    this.setState(() => ({ subjectsFilter: filter }));
  };

  request = async () => {
    const { endDate, startDate } = this.props;
    const { comparisonModel } = this.state;

    const response = await api.fetchData(
      comparisonModel.id,
      comparisonModel.type,
      startDate,
      endDate,
    );

    const { stats } = response.meta;
    const distribution = {
      '< 25': utils.jsonToData(stats, 'subject_line_distribution', '< 25'),
      '25 - 55': utils.jsonToData(stats, 'subject_line_distribution', '25 - 55'),
      '55 - 120': utils.jsonToData(stats, 'subject_line_distribution', '55 - 120'),
      '120+': utils.jsonToData(stats, 'subject_line_distribution', '120+'),
    };
    const payload = { distribution };

    const allEmails = shuffle(
      uniq(
        flatten([
          distribution['< 25'].emails,
          distribution['25 - 55'].emails,
          distribution['55 - 120'].emails,
          distribution['120+'].emails,
        ]),
      ),
    );

    this.setState(() => ({
      allEmails,
      comparison: {
        data: payload,
        loading: false,
      },
    }));
  };

  chartData = () => {
    const { data } = this.props;

    return [
      { color: 'blueMedium', label: '< 25', width: data.distribution['< 25'].value },
      { color: 'blueLight', label: '25 - 55', width: data.distribution['25 - 55'].value },
      { color: 'blueDark', label: '55 - 120', width: data.distribution['55 - 120'].value },
      { color: 'redDark', label: '120+', width: data.distribution['120+'].value },
    ];
  };

  comparisonData = () => {
    const { comparison } = this.state;
    return comparison.data || {};
  };

  comparisonLoading = () => {
    const { comparison } = this.state;
    return comparison.loading;
  };

  chartComparisonData = () => {
    const data = this.comparisonData();

    return [
      { color: 'blueMedium', label: '< 25', width: data.distribution['< 25'].value },
      { color: 'blueLight', label: '25 - 55', width: data.distribution['25 - 55'].value },
      { color: 'blueDark', label: '55 - 120', width: data.distribution['55 - 120'].value },
      { color: 'redDark', label: '120+', width: data.distribution['120+'].value },
    ];
  };

  filteredData = () => {
    const comparisonData = this.comparisonData();
    const { allEmails, subjectsFilter } = this.state;

    if (subjectsFilter === 'all') {
      return allEmails;
    }

    return comparisonData.distribution[subjectsFilter].emails;
  };

  render() {
    const { brands, endDate, model, startDate, theme } = this.props;
    const { comparisonModel, subjectsFilter } = this.state;

    const comparisonData = this.comparisonData();
    const comparisonLoading = this.comparisonLoading();

    let pathname = '';
    let query = {};

    if (comparisonModel) {
      pathname =
        comparisonModel.id === 'premium'
          ? '/brands?premium=true'
          : `/companies/${comparisonModel.id}/emails`;
      if (comparisonModel.id !== 'premium') {
        query = subjectsFilter === 'all' ? {} : { subject_line_length: subjectsFilter };
      }
    }

    query.end_date = endDate;
    query.start_date = startDate;

    return (
      <Flex direction="column" css={{ minHeight: '448px' }}>
        <CompareWithBrandForm brands={brands} model={model} onSubmit={this.handleBrandSubmit} />
        {comparisonLoading ? (
          <Flex alignItems height="400px" justifyContent="center" marginTop="16px">
            <Loader />
          </Flex>
        ) : (
          <Flex direction="column" minHeight="400px" marginTop="16px">
            {!isEmpty(comparisonData) && (
              <div>
                <Flex justifyContent="space-between">
                  <Flex direction="column" width="320px">
                    <Heading
                      as="h5"
                      css={{
                        fontSize: '14px',
                        lineHeight: '1.25em',
                        marginBottom: '8px',
                        textTransform: 'none',
                      }}
                    >
                      {model.attributes.name}
                    </Heading>
                    <Chart data={this.chartData()} />
                  </Flex>

                  <Flex direction="column" width="320px">
                    <Heading
                      as="h5"
                      css={{
                        fontSize: '14px',
                        lineHeight: '1.25em',
                        marginBottom: '8px',
                        textTransform: 'none',
                      }}
                    >
                      {comparisonModel.name}
                    </Heading>
                    <Chart data={this.chartComparisonData()} />
                  </Flex>
                </Flex>

                <Subjects
                  changeFilter={this.changeSubjectsFilter}
                  data={this.filteredData()}
                  filters={this.subjectsFilters}
                  selectedFilter={subjectsFilter}
                />

                <Link
                  to={{ pathname, query }}
                  css={{
                    color: theme.colors.blue,
                    fontFamily: theme.font.family,
                    fontSize: '14px',
                    fontWeight: '500',
                    lineHeight: '1.25em',
                    textDecoration: 'none',
                  }}
                >
                  See more
                </Link>
              </div>
            )}
          </Flex>
        )}
      </Flex>
    );
  }
}

CompareWithBrands.propTypes = {
  brands: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  data: PropTypes.shape({
    distribution: PropTypes.shape({
      '< 25': PropTypes.shape({
        docCount: PropTypes.number,
        total: PropTypes.number,
        value: PropTypes.number,
        emails: PropTypes.arrayOf(PropTypes.shape),
      }),
      '25 - 55': PropTypes.shape({
        docCount: PropTypes.number,
        total: PropTypes.number,
        value: PropTypes.number,
        emails: PropTypes.arrayOf(PropTypes.shape),
      }),
      '55 - 120': PropTypes.shape({
        docCount: PropTypes.number,
        total: PropTypes.number,
        value: PropTypes.number,
        emails: PropTypes.arrayOf(PropTypes.shape),
      }),
      '120+': PropTypes.shape({
        docCount: PropTypes.number,
        total: PropTypes.number,
        value: PropTypes.number,
        emails: PropTypes.arrayOf(PropTypes.shape),
      }),
    }),
  }).isRequired,
  endDate: PropTypes.string.isRequired,
  model: PropTypes.shape({ id: PropTypes.string, type: PropTypes.string }).isRequired,
  startDate: PropTypes.string.isRequired,
  theme: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state, { model }) => {
  const { endDate, startDate } = state.newReports.dates;

  return {
    data: selectors.getData(model.id, model.type, startDate, endDate)(state),
    endDate,
    startDate,
  };
};

export default connect(mapStateToProps)(withTheme(CompareWithBrands));
