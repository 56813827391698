import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@emotion/react';

import { Heading } from '../mc-ui';

const Title = ({ children, css, theme }) => (
  <Heading
    as="h4"
    css={{
      color: theme.colors.dark08,
      display: 'inline-block',
      letterSpacing: '0.02em',
      lineHeight: '1.32em',
      margin: '0',
      padding: '0',
      ...css,
    }}
  >
    {children}
  </Heading>
);

Title.defaultProps = {
  css: {},
};

Title.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.shape({}).isRequired,
  css: PropTypes.shape({}),
};

export default withTheme(Title);
