import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { shallowEqual } from '../../../lib/utils';
import {
  groupOverviewActions as actions,
  groupOverviewSelectors as selectors,
} from '../../../modules/groupOverview';

import { Box, ChartTooltip, Flex, Text } from '../../mc-ui';

import Title from '../Title';

import Benchmarks from './Benchmarks';
import LoadingStatus from './LoadingStatus';
import Promotion from './Promotion';
import SendingBehavior from './SendingBehavior';
import SubjectLines from './SubjectLines';
import WeeklyRecap from './WeeklyRecap';

class DataAndInsights extends React.PureComponent {
  componentDidUpdate(prevProps) {
    const prevModel = { id: prevProps.model.id, type: prevProps.model.type };
    const model = { id: this.props.model.id, type: this.props.model.type };

    if (!shallowEqual(prevModel, model)) {
      this.request();
    }
  }

  request = () => {
    this.props.requestData();
  };

  render() {
    const { currentModel, isDataAndInsightsEmpty, loading, model } = this.props;

    return (
      <Box marginTop="48px">
        <Flex justifyContent="space-between" marginBottom="16px">
          <Box>
            <Title>Group data and insights</Title>
            {!loading &&
              !isDataAndInsightsEmpty && (
                <Text css={{ lineHeight: '22px', marginTop: '4px' }}>
                  Data from the last 90 days.
                </Text>
              )}
          </Box>
        </Flex>

        {loading || isDataAndInsightsEmpty || !model.id || model.id !== currentModel.id ? (
          <LoadingStatus isDataAndInsightsEmpty={isDataAndInsightsEmpty} loading={loading} />
        ) : (
          <div>
            <SendingBehavior model={model} />
            <Promotion model={model} />
            <SubjectLines model={model} />
            <Benchmarks model={model} />
            <WeeklyRecap model={model} />
            <ChartTooltip />
          </div>
        )}
      </Box>
    );
  }
}

DataAndInsights.defaultProps = {
  currentModel: {},
  model: {},
};

DataAndInsights.propTypes = {
  currentModel: PropTypes.shape({ id: PropTypes.string, type: PropTypes.string }),
  isDataAndInsightsEmpty: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  model: PropTypes.shape({ id: PropTypes.string, type: PropTypes.string }),
  requestData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  currentModel: selectors.getModel(state),
  isDataAndInsightsEmpty: selectors.isDataAndInsightsEmpty(state),
  loading: selectors.getDataAndInsightsLoading(state),
});

const mapDispatchToProps = (dispatch, { model }) => ({
  requestData: () => dispatch(actions.fetchDataRequest(model || {})),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DataAndInsights);
