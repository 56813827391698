import React from 'react'
import { Link } from 'react-router'
import { connect } from 'react-redux'

import api from '../lib/api'
import Container from './Container'

class TechnologiesDetails extends React.Component {
  render() {
    let technology = this.props.technologies.data.reduce((m, t) => t, {})
    return (
      <Container style={{ margin: '2em auto' }}>
        <div className='pure-u-1' style={{ textAlign: 'center', padding: '2em 0' }}>
          <img
            src='/empty-email.png'
            style={{ margin: '0 auto', maxWidth: 100 }}
            className='pure-img'
          />
          <p>You do not have access to view {technology.attributes.name} details.</p>
          <p>
            <Link
              to='/manage'
              className='pure-button pure-button-primary'
            >
              Contact us
            </Link>
          </p>
        </div>
        {this.props.user.meta.tokens.technologies ?
            <div>
              <p>Would you like a free sample of technology data for one day?</p>
              <p>
                <Link
                  to='/manage/plans'
                  onClick={(e) => {
                    e.preventDefault()
                    api.post(`v2/user/technologies`)
                      .then(r => {
                        this.props.dispatch({ type: 'REFRESH_USER' })
                      })
                  }}
                  className='pure-button pure-button-primary'
                >
                  Get access
                </Link>
              </p>
            </div>
        : null}
      </Container>
    )
  }
}

const s = (state) => ({ user: state.user, searches: state.searches, technologies: state.technologies })
export default connect(s)(TechnologiesDetails)
