import * as React from 'react';
import PropTypes from 'prop-types';

function ChevronDownIcon({ fillOpacity, ...props }) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.37 15.37a1.19 1.19 0 01-.846-.35l-4.3-4.3a.797.797 0 011.127-1.127l3.938 3.939a.113.113 0 00.16 0l3.938-3.939a.797.797 0 011.127 1.127l-4.3 4.3a1.19 1.19 0 01-.845.35z"
        fill="currentColor"
        fillOpacity={fillOpacity}
      />
    </svg>
  );
}

ChevronDownIcon.propTypes = {
  fillOpacity: PropTypes.number,
};

ChevronDownIcon.defaultProps = {
  fillOpacity: 1,
};

export default ChevronDownIcon;
