import React from 'react';
import PropTypes from 'prop-types';
import { find, propEq } from 'ramda';
import { withTheme } from '@emotion/react';

import { Flex } from '../../../../mc-ui';

import Day from './Day';
import WeeklyTotals from './WeeklyTotals';

const getFormattedDate = date => date.format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');

const getDay = (date, days) => {
  const formattedDate = getFormattedDate(date);
  return find(propEq('key_as_string', formattedDate))(days);
};

const Row = ({ data, date, selectedDate, theme, ...props }) => {
  let clonedDate = date.clone(); // eslint-disable-line prefer-const
  let days = []; // eslint-disable-line prefer-const

  for (let i = 0; i < 7; i += 1) {
    const currentDay = getDay(clonedDate, data.stats.days);

    const day = {
      data: {
        frequency: currentDay ? currentDay.frequency.value || 0 : 0,
        perentageBrands: currentDay ? Math.round(currentDay.brands.avg * 100) : 0,
        promotionRate: currentDay ? Math.round(currentDay.promotional.avg * 100) : 0,
      },
      date: clonedDate,
      isCurrentMonth: clonedDate.month() === selectedDate.month(),
      isLastMonth: clonedDate.month() < selectedDate.month(),
      isNextMonth: clonedDate.month() > selectedDate.month(),
      isFutureDate: clonedDate.isAfter(new Date(), 'day'),
      isToday: clonedDate.isSame(new Date(), 'day'),
      name: clonedDate.format('dd').substring(0, 1),
      lastDayOfLastMonth:
        clonedDate.month() < selectedDate.month() &&
        clonedDate
          .clone()
          .endOf('month')
          .isSame(clonedDate, 'day'),
      lastDayOfCurrentMonth:
        clonedDate.month() === selectedDate.month() &&
        clonedDate
          .clone()
          .endOf('month')
          .isSame(clonedDate, 'day'),
    };

    days.push(
      <Day key={`day-${day.date.toString()}`} selectedDate={selectedDate} {...day} {...props} />,
    );
    clonedDate = clonedDate.clone();
    clonedDate.add(1, 'd');
  }

  return (
    <Flex css={{ borderTop: `1px solid ${theme.colors.dark5}` }}>
      {days}
      <WeeklyTotals data={data} date={clonedDate.format('YYYY-MM-DD')} />
    </Flex>
  );
};

Row.propTypes = {
  data: PropTypes.shape({}).isRequired,
  date: PropTypes.shape({}).isRequired,
  firstRow: PropTypes.bool.isRequired,
  lastRow: PropTypes.bool.isRequired,
  selectedDate: PropTypes.shape({}).isRequired,
  theme: PropTypes.shape({}).isRequired,
};

export default withTheme(Row);
