let pendoPromise;
const attempts = 5;

const checkPendoReady = (counter, resolve, reject) => {
  if (window.pendo != undefined && typeof window.pendo.isReady === 'function' && window.pendo.isReady()) {
    return resolve(window.pendo);
  }
  counter--;
  if (counter >= 0) {
    setTimeout(() => {
      checkPendoReady(counter, resolve, reject);
    }, 500);
  }
  else {
    reject(`Pendo not ready after ${attempts} attempts`);
  }
};

export const initTrackingSession = (team, user) => {
  pendoPromise = new Promise((resolve, reject) => {
    if (process.env.NODE_ENV != 'production') {
      // do not initialize tracking
      console.info(`Tracking not available for "${process.env.NODE_ENV}" environment.`);
      reject('Pendo unavailable');
      return;
    }

    if (window.pendo) {
      if (typeof window.pendo.getAccountId === 'function' && window.pendo.getAccountId()) {
        window.pendo.updateOptions(getPendoOptions(team, user));
      } else {
        window.pendo.initialize(getPendoOptions(team, user));
      }
      checkPendoReady(attempts, resolve, reject);
    }
    else {
      console.warn('Tracking not initialized, pendo not available.');
      reject();
    }
  }).catch(console.error);
  return pendoPromise;
};

const getPendoOptions = (team, user) => {
  let pendoAttrs = {
    disableCookies: true,
    visitor: {
      id: `mc-${user.id}`,
      email: user.attributes.email,
      full_name: user.attributes.name,
      company: user.attributes.company,
      user_type: user.attributes.type,
      plan: user.attributes.plan_id,
      plan_version: user.attributes.plan_version,
      plan_level: user.attributes.plan_level,
      account_status: user.attributes.account_status,
      site: "mailcharts",
    }
  };

  if (team) {
    pendoAttrs.account = {
      id: `mc-${team.id}`,
      name: team.attributes.name,
      sms_add_on: user.attributes.sms_addon,
      current_period_end: user.attributes.current_period_end_at
    };
  }

  return pendoAttrs;
};
