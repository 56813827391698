import React from 'react';
import PropTypes from 'prop-types';
import { take } from 'ramda';
import { withTheme } from '@emotion/react';

import { Box, Flex, Text } from '../../../mc-ui';

import Email from '../../Email';
import * as TextMessageStyles from '../../TextMessageThumbnail/styles';
import TextMessageImage from '../../TextMessageThumbnail/TextMessageImage';

import listsImage from './backgrounds/lists.png';
import featuredBrandsImage from './backgrounds/featuredBrands.png';
import journeysImage from './backgrounds/journeys.png';

const backgrounds = {
  emailLists: listsImage,
  featuredBrands: featuredBrandsImage,
  featuredHolidays: listsImage,
  featuredJourneyEmails: journeysImage,
  featuredJourneys: journeysImage,
  featuredSMSLists: listsImage,
};

const backgroundCss = type => ({
  backgroundImage: `url(${backgrounds[type]})`,
  backgroundPosition: 'right bottom',
  backgroundRepeat: 'no-repeat',
  marginLeft: type !== 'featuredSMSLists' ? '0' : '15%',
});

const Emails = ({ model, theme, type }) => {
  let modelEmails = model.relationships.emails.data || [];
  if (type === 'featuredSMSLists') {
    modelEmails = model.relationships.textMessages.data || [];
  }
  const emails =
    modelEmails.length > 0 && modelEmails.length < 3 ? [modelEmails[0]] : take(3, modelEmails);

  return (
    <Box height="100%" position="relative" width="392px" css={backgroundCss(type)}>
      {emails.map((email, emailIndex) => {
        const boxProps =
          emailIndex === 0
            ? { height: '270px', width: '180px' }
            : { height: '240px', width: '160px' };

        if (emailIndex === 0) {
          boxProps.right = '114px';
          boxProps.top =  type !== 'featuredSMSLists' ? '87px' : '107px';
          boxProps.zIndex = '3';
          boxProps.css = type !== 'featuredSMSLists' ? { boxShadow: `0 2px 8px ${theme.colors.dark02}` } : {};
        } else if (emailIndex === 1) {
          boxProps.right = '204px';
          boxProps.top =  type !== 'featuredSMSLists' ? '196px' : '182px';
          boxProps.zIndex = '2';
        } else {
          boxProps.right =  type !== 'featuredSMSLists' ? '40px' : '65px';
          boxProps.top = '32px';
          boxProps.zIndex = '1';
        }

        return (
          <Box key={email.id} borderRadius="4px" position="absolute" {...boxProps}>
            {type === 'featuredSMSLists' ? (
              <TextMessageStyles.Wrapper>
                <Box padding="18px 25px 0px 34px">
                  <Box maxHeight="212px" overflow="hidden">
                    <Flex justifyContent="center" paddingBottom="8px">
                      <TextMessageImage textMessage={email} />
                    </Flex>

                    <Text color="dark08" fontSize="10px" lineHeight="14px">
                      {email.attributes.fullText}
                    </Text>
                  </Box>
                </Box>
              </TextMessageStyles.Wrapper>
            ) : (
              <Email
                email={email}
                height="100%"
                width="100%"
                css={{ height: '100%', margin: '0', padding: '0', width: '100%' }}
              />
            )}
          </Box>
        );
      })}
    </Box>
  );
};

Emails.propTypes = {
  model: PropTypes.shape({
    relationships: PropTypes.shape({
      emails: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape({})),
      }),
    }),
  }).isRequired,
  theme: PropTypes.shape({
    colors: PropTypes.shape({}),
  }).isRequired,
  type: PropTypes.string.isRequired,
};

export default withTheme(Emails);
