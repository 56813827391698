import React, { Component } from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import { connect } from 'react-redux';
import { contains, isEmpty, isNil, merge } from 'ramda';

import { COMPARE_QUERY_KEY } from '../reports/Header';

import DateRangePickerWrapper from '../DateRangePickerWrapper';
import Modal from '../Modal';
import { ascendBy } from '../AddToList/Lists/helpers';

import SelectedFilters from './SelectedFilters';
import FiltersWrapper from './ui/FiltersWrapper';
const CLASSIFICATIONS = [
  { label: 'Account creation', types: [
    { value: 'double_optin', name: 'Double Opt-in', },
    { value: 'welcome', name: 'Welcome', },
    { value: 'onboarding', name: 'Onboarding', },
    { value: 'birthday', name: 'Birthday', },
  ] },
  { label: 'Abandonment', types: [
    { value: 'cart_abandon', name: 'Cart abandon', },
    { value: 'booking_abandon', name: 'Booking abandon', },
    { value: 'subscription_abandon', name: 'Subscription abandon', },
    { value: 'browse_abandon', name: 'Browse abandon', },
  ] },
  { label: 'Purchase', types: [
    { value: 'order_confirmation', name: 'Order confirmation', },
    { value: 'purchase_receipt', name: 'Purchase receipt', },
    { value: 'thank_you', name: 'Thank you', },
    { value: 'shipping_confirmation', name: 'Shipping confirmation', },
    { value: 'delivery_confirmation', name: 'Delivery confirmation', },
    { value: 'refer_a_friend', name: 'Refer a friend', },
    { value: 'product_review', name: 'Product review', },
  ] },
  { label: 'Subscription', types: [
    { value: 'trial', name: 'Trial', },
    { value: 'subscription_reminder', name: 'Subscription reminder', },
    { value: 'retention', name: 'Retention', },
    { value: 'subscription_upsell', name: 'Subscription upsell', },
    { value: 'cancellation', name: 'Cancellation', },
    { value: 'refer_a_friend', name: 'Refer a friend', },
    { value: 'winback', name: 'Winback', },
    { value: 'miscellaneous', name: 'Miscellaneous', },
  ] },
]

class Filters extends Component {
  state = {
    query: {},
    technologies: [],
    endDate: null,
    startDate: null,
  };

  componentDidMount() {
    this.loadStateFromRouterQuery();
    this.loadUserIncluded();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { endDate: nextEndDate, startDate: nextStartDate } = this.props;
    let { endDate, startDate } = this.props;
    const { queryScope, setQuery, show } = this.props;

    if (endDate) {
      endDate = endDate.format('YYYY-MM-DD');
    }
    if (startDate) {
      startDate = startDate.format('YYYY-MM-DD');
    }
    if (nextEndDate) {
      nextEndDate = nextEndDate.format('YYYY-MM-DD');
    }
    if (nextStartDate) {
      nextStartDate = nextStartDate.format('YYYY-MM-DD');
    }

    if (!!queryScope && nextProps.currentQuery[queryScope]) {
      if (
        nextProps.currentQuery[queryScope].company_id &&
        nextProps.currentQuery[queryScope].company_id !== this.state.query.company_id
      ) {
        this.setState(currentState => ({
          query: {
            ...currentState.query,
            company_id: nextProps.currentQuery[queryScope].company_id,
            group_id: null,
          },
        }));
      } else if (
        nextProps.currentQuery[queryScope].group_id &&
        nextProps.currentQuery[queryScope].group_id !== this.state.query.group_id
      ) {
        this.setState(currentState => ({
          query: {
            ...currentState.query,
            group_id: nextProps.currentQuery[queryScope].group_id,
            company_id: null,
          },
        }));
      }
    }

    if (endDate === nextEndDate && startDate === nextStartDate) {
      return;
    }

    if (endDate !== nextEndDate || startDate !== nextStartDate || show !== nextProps.show) {
      const { router } = this.context;
      const search = qs.parse((router.location.search || '').replace('?', ''));

      let queryFromRouter;

      if (queryScope) {
        queryFromRouter = search[queryScope];
      } else {
        delete search[COMPARE_QUERY_KEY];
        queryFromRouter = search;
      }

      if (Object.keys(queryFromRouter).length < Object.keys(this.state.query).length) {
        return;
      }

      const query = merge(queryFromRouter, this.state.query);

      if (nextEndDate) {
        query.end_date = nextEndDate;
      } else if (queryFromRouter.end_date) {
        query.end_date = queryFromRouter.end_date;
      } else {
        delete query.end_date;
      }

      if (nextStartDate) {
        query.start_date = nextStartDate;
      } else if (queryFromRouter.start_date) {
        query.start_date = queryFromRouter.start_date;
      } else {
        delete query.start_date;
      }

      const queryKeys = Object.keys(query);
      const stateQueryKeys = Object.keys(this.state.query).filter(k => k !== COMPARE_QUERY_KEY);

      let shouldUpdateRoute;
      if (queryScope) {
        shouldUpdateRoute = !(
          isEmpty(this.state.query) ||
          (stateQueryKeys.length <= 3 &&
            (contains('group_id', stateQueryKeys) ||
              contains('company_id', stateQueryKeys) ||
              (contains('end_date', stateQueryKeys) && contains('start_date', stateQueryKeys))))
        );
      } else {
        shouldUpdateRoute = !(
          isEmpty(this.state.query) ||
          (queryKeys.length === 2 &&
            contains('end_date', queryKeys) &&
            contains('start_date', queryKeys))
        );
      }

      this.setState({ query }, () => {
        setQuery(query, queryScope, shouldUpdateRoute);
      });
    }
  }

  onDatesChange = (startDate, endDate) => {
    const { queryScope, setQuery } = this.props;
    const { router } = this.context;

    this.setState(
      currentState => ({
        query: {
          ...currentState.query,
          start_date: startDate,
          end_date: endDate,
        },
        startDate,
        endDate,
      }),
      () => {
        let query = qs.parse((router.location.search || '').replace('?', ''));
        if (queryScope && query[queryScope]) {
          query = query[queryScope];
        }

        if (startDate && endDate) {
          query.start_date = startDate;
          query.end_date = endDate;
        } else {
          delete query.start_date;
          delete query.end_date;
        }

        setQuery(query, queryScope, false);
      },
    );
  };

  datesAreDifferent = (nextProps) => {
    const { endDate, startDate, queryScope } = this.props;
    const { query } = this.state;
    const { router } = this.context;

    const search = qs.parse((router.location.search || '').replace('?', ''));
    const queryFromRouter = queryScope ? search[queryScope] : search || {};

    return (
      endDate !== nextProps.endDate ||
      startDate !== nextProps.startDate ||
      queryFromRouter.end_date !== query.end_date ||
      queryFromRouter.start_date !== query.start_date
    );
  };

  loadStateFromRouterQuery = () => {
    const { router } = this.context;
    const { queryScope, setQuery } = this.props;
    const search = qs.parse((router.location.search || '').replace('?', ''));
    const query = (queryScope ? search[queryScope] : search) || {};
    const newState = {};

    if (query.promotional && (query.gt || query.lt)) {
      query.promotional = [query.promotional, query.gt || 0, query.lt].join('|');
    }

    const { searches } = this.props;

    const WHITE_LIST_QUERIES = [
      'animated',
      'company_id',
      'end_date',
      'filter',
      'group_id',
      'gt',
      'image_weight',
      'lt',
      'mobile_optimized',
      'mailcharts_score',
      'promotional',
      'q',
      'segment',
      'segment_id',
      'classifications',
      'start_date',
      'subject_line_length',
      'subject_line_emoji',
      'technology_id',
    ];

    Object.keys(query).forEach((key) => {
      if (WHITE_LIST_QUERIES.indexOf(key) < 0) {
        delete query[key];
      }
    });

    if (query.q && !query.filter) {
      query.filter = 'all_content';
    } else if (query.filter && !query.q) {
      delete query.filter;
    }

    this.setState({ ...newState, query }, () => {
      setQuery(query, queryScope);
    });
  };

  loadUserIncluded = () => {
    const { user } = this.props;
    const options = { companies: [], groups: [] };
    const technologies = [];

    user.included.forEach((included) => {
      if (included.type === 'technologies') {
        technologies.push(included);
      } else if (contains(included.type, ['companies', 'groups'])) {
        options[included.type].push(included);
      }
    });

    options.companies = options.companies.sort(ascendBy('name'));
    options.groups = options.groups.sort(ascendBy('name'));

    this.setState({ technologies });
  };

  handleChange = (event) => {
    const field = event.target;
    const value = field.type === 'checkbox' ? field.checked : field.value;

    this.setState((currentState) => {
      const newState = { ...currentState };
      let query = { ...currentState.query };

      query = merge(currentState.query, { [field.name]: value });

      if (field.name === 'q' && !currentState.query.filter) {
        query.filter = 'all_content';
      }

      if (!value) {
        delete query[field.name];
      }

      newState.query = query;

      return { ...newState };
    });
  };

  removeQueryEntry = (key) => {
    const { queryScope, setQuery } = this.props;
    const query = { ...this.state.query };

    if (contains(key, ['filter', 'q'])) {
      delete query.filter;
      delete query.q;
    } else if (contains(key, ['end_date', 'start_date'])) {
      delete query.end_date;
      delete query.start_date;
    } else {
      delete query[key];
    }

    this.setState({ query }, () => {
      setQuery(query, queryScope, true);
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const { toggleAdvancedOptions, queryScope, setQuery } = this.props;
    const { query } = this.state;

    toggleAdvancedOptions();
    setQuery(query, queryScope, true);
  };

  closeModal = () => {
    const { toggleAdvancedOptions } = this.props;

    this.setState({ query: {} }, this.loadStateFromRouterQuery);
    toggleAdvancedOptions();
  };

  render() {
    const { router } = this.context;
    const {
      currentQuery,
      queryScope,
      searches,
      show,
      showDates,
      toggleAdvancedOptions,
    } = this.props;

    const {
      query: {
        animated,
        classifications,
        filter,
        image_weight: imageWeight,
        mobile_optimized: mobileOptimized,
        mailcharts_score,
        promotional,
        q,
        segment,
        subject_line_length: subjectLineLength,
        subject_line_emoji,
        technology_id: technologyId,
      },
      technologies,
      endDate,
      startDate,
    } = this.state;

    if (!show) {
      return (
        <SelectedFilters
          currentQuery={currentQuery}
          query={this.state.query}
          queryScope={queryScope}
          removeQueryEntry={this.removeQueryEntry}
          showDates={showDates}
        />
      );
    }

    return (
      <Modal onClose={this.closeModal} modalStyle={{ minHeight: 300 }}>
        <FiltersWrapper>
          <form className="pure-form filtersForm" onSubmit={this.handleSubmit}>
            <fieldset className="keywordsGroup">
              <div className="fieldsWrapper">
                <label htmlFor={`${queryScope}filter`} className="filter">
                  <h5>Keywords</h5>
                  <select
                    value={filter}
                    name="filter"
                    id={`${queryScope}filter`}
                    className="pure-input-1"
                    onChange={this.handleChange}
                  >
                    <option value="all_content">Find in all content</option>
                    <option value="subject">Subject line only</option>
                    <option value="preheader">Preheader text</option>
                    <option value="full_text">Exclude subject line</option>
                  </select>
                </label>

                <label htmlFor={`${queryScope}q`} className="query">
                  <input
                    type="text"
                    id={`${queryScope}q`}
                    name="q"
                    value={q || ''}
                    onChange={this.handleChange}
                    placeholder="Type keywords here"
                    className="pure-input-1"
                  />
                </label>

                <div className="buttonGroup">
                  <input type="submit" value="Search" className="pure-button pure-button-primary" />
                </div>
              </div>
            </fieldset>

            <div className="fieldsets">
              <fieldset className="selects">
                <div className="fieldsWrapper">
                  {showDates && (
                    <label htmlFor="dateRange">
                      <h5>Date range</h5>

                      <DateRangePickerWrapper
                        startDate={startDate}
                        endDate={endDate}
                        startDatePlaceholderText="Start date"
                        endDatePlaceholderText="End date"
                        onDatesChange={this.onDatesChange}
                        showClearDates
                      />
                    </label>
                  )}

                  <label htmlFor={`${queryScope}classification`}>
                    <h5>Classifications</h5>

                    <select
                      value={classifications}
                      id={`${queryScope}classification`}
                      name="classifications"
                      onChange={this.handleChange}
                      className="pure-input-1"
                    >
                      <option value="">All emails</option>
                      {CLASSIFICATIONS.map((group, idx) => {
                        return (<optgroup key={idx} label={group.label}>
                          {group.types.map((t, i) => <option key={`${idx}_${i}`} value={t.value}>{t.name}</option>)}
                        </optgroup>
                        );
                      })}
                    </select>
                  </label>

                  <label htmlFor={`${queryScope}promotional`}>
                    <h5>Promotions</h5>

                    <select
                      value={promotional}
                      id={`${queryScope}promotional`}
                      name="promotional"
                      onChange={this.handleChange}
                      className="pure-input-1"
                    >
                      <option value="">All emails</option>
                      <option value="true">Promotions</option>
                      <option value="subject">Promotions in subject lines</option>
                      <option value="html">Promotions in email content</option>
                      <option value="false">No promotions</option>
                      <option value="free_shipping">Free shipping (SL only)</option>
                      <option value="bogo">Buy-one, get-one</option>
                      <option value="percentage">All percent-off discounts</option>
                      <option value="percentage|0|10">0 - 10% off</option>
                      <option value="percentage|10|25">10% - 25% off</option>
                      <option value="percentage|25|">25%+ off</option>
                      <option value="absolute">All dollars-off discounts</option>
                      <option value="absolute|0|25">$0 - $25 off</option>
                      <option value="absolute|25|50">$25 - $50 off</option>
                      <option value="absolute|50|">$50+ off</option>
                    </select>
                  </label>

                  {!isEmpty(technologies) && (
                    <label htmlFor={`${queryScope}technology`}>
                      <h5>Technology</h5>

                      <select
                        value={technologyId}
                        id={`${queryScope}technology`}
                        name="technology_id"
                        onChange={this.handleChange}
                        className="pure-input-1"
                      >
                        <option value="">All technologies</option>
                        {technologies.map(technology => (
                          <option key={technology.id} value={technology.id}>
                            {technology.attributes.name}
                          </option>
                        ))}
                      </select>
                    </label>
                  )}

                  <label htmlFor={`${queryScope}promotional`}>
                    <h5>MailCharts Score</h5>

                    <select
                      value={mailcharts_score}
                      id={`${queryScope}mailcharts_score`}
                      name="mailcharts_score"
                      onChange={this.handleChange}
                      className="pure-input-1"
                    >
                      <option value="">All emails</option>
                      <option value="high">High scores (75% or higher)</option>
                      <option value="low">Low scores (50% or less)</option>
                    </select>
                  </label>

                </div>
              </fieldset>

              <div className="options">
                <fieldset className="subjectLineLength">
                  <div className="fieldsWrapper">
                    <h5>Subject line</h5>
                    <label htmlFor={`${queryScope}anySubjectLineLength`}>
                      <input
                        type="radio"
                        name="subject_line_length"
                        id={`${queryScope}anySubjectLineLength`}
                        value=""
                        checked={isEmpty(subjectLineLength) || isNil(subjectLineLength)}
                        onChange={this.handleChange}
                      />
                      Any length
                    </label>

                    <label htmlFor={`${queryScope}tinySubjectLineLength`}>
                      <input
                        type="radio"
                        name="subject_line_length"
                        id={`${queryScope}tinySubjectLineLength`}
                        value="tiny"
                        checked={subjectLineLength === 'tiny'}
                        onChange={this.handleChange}
                      />
                      Tiny
                    </label>

                    <label htmlFor={`${queryScope}optimalSubjectLineLength`}>
                      <input
                        type="radio"
                        name="subject_line_length"
                        id={`${queryScope}optimalSubjectLineLength`}
                        value="optimal"
                        checked={subjectLineLength === 'optimal'}
                        onChange={this.handleChange}
                      />
                      Optimal
                    </label>

                    <label htmlFor={`${queryScope}tooLongSubjectLineLength`}>
                      <input
                        type="radio"
                        name="subject_line_length"
                        id={`${queryScope}tooLongSubjectLineLength`}
                        value="too-long"
                        checked={subjectLineLength === 'too-long'}
                        onChange={this.handleChange}
                      />
                      Too long
                    </label>
                  </div>
                </fieldset>

                <fieldset className="imageWeight">
                  <div className="fieldsWrapper">
                    <h5>Image weight</h5>
                    <label htmlFor={`${queryScope}anyImageWeight`}>
                      <input
                        type="radio"
                        name="image_weight"
                        id={`${queryScope}anyImageWeight`}
                        value=""
                        checked={isEmpty(imageWeight) || isNil(imageWeight)}
                        onChange={this.handleChange}
                      />
                      Any
                    </label>

                    <label htmlFor={`${queryScope}heavyImageWeight`}>
                      <input
                        type="radio"
                        name="image_weight"
                        id={`${queryScope}heavyImageWeight`}
                        value="heavy"
                        checked={imageWeight === 'heavy'}
                        onChange={this.handleChange}
                      />
                      Heavy
                    </label>

                    <label htmlFor={`${queryScope}lightImageWeight`}>
                      <input
                        type="radio"
                        name="image_weight"
                        id={`${queryScope}lightImageWeight`}
                        value="light"
                        checked={imageWeight === 'light'}
                        onChange={this.handleChange}
                      />
                      Light
                    </label>
                  </div>
                </fieldset>

                <fieldset className="otherOptions">
                  <div className="fieldsWrapper">
                    <h5>Other options</h5>
                    <label htmlFor={`${queryScope}mobileOptimized`} className="pure-checkbox">
                      <input
                        type="checkbox"
                        name="mobile_optimized"
                        id={`${queryScope}mobileOptimized`}
                        checked={!!mobileOptimized}
                        onChange={this.handleChange}
                      />
                      Mobile optimized
                    </label>

                    <label htmlFor={`${queryScope}animated`} className="pure-checkbox">
                      <input
                        type="checkbox"
                        name="animated"
                        id={`${queryScope}animated`}
                        checked={!!animated}
                        onChange={this.handleChange}
                      />
                      Animated / GIF
                    </label>

                    <label htmlFor={`${queryScope}subject_line_emoji`} className="pure-checkbox">
                      <input
                        type="checkbox"
                        name="subject_line_emoji"
                        id={`${queryScope}subject_line_emoji`}
                        checked={!!subject_line_emoji}
                        onChange={this.handleChange}
                      />
                      Emojis
                    </label>
                  </div>
                </fieldset>
              </div>
            </div>
          </form>
        </FiltersWrapper>
      </Modal>
    );
  }
}

Filters.contextTypes = {
  router: PropTypes.object,
};

Filters.propTypes = {
  searches: PropTypes.shape({}).isRequired,
  setQuery: PropTypes.func.isRequired,
  showDates: PropTypes.bool.isRequired,
  toggleAdvancedOptions: PropTypes.func.isRequired,
  user: PropTypes.shape({}).isRequired,
  currentQuery: PropTypes.shape({}),
  endDate: PropTypes.shape({ format: PropTypes.func }),
  queryScope: PropTypes.string,
  show: PropTypes.bool,
  startDate: PropTypes.shape({ format: PropTypes.func }),
};

Filters.defaultProps = {
  currentQuery: {},
  endDate: null,
  queryScope: null,
  show: false,
  startDate: null,
};

const mapStateToProps = state => ({
  searches: state.searches,
  user: state.user,
});

export default connect(mapStateToProps)(Filters);
