import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@emotion/react';
import { Link as RRLink } from 'react-router';

import Container from '../Container';
import Section from '../Section';
import { Heading, Link, Text } from '../mc-ui';

import headerBackground from './header/background.png';

const Header = ({ groupsLength, theme }) => {
  const containerStyle = {
    backgroundImage: `url(${headerBackground})`,
    backgroundPosition: 'right',
    backgroundRepeat: 'no-repeat',
    marginBottom: '0',
    marginTop: '0',
    paddingBottom: '72px',
    paddingTop: '48px',
  };
  const headingStyle = {
    fontWeight: '300',
    margin: '0 0 16px 0',
    padding: '0',
  };
  const discoverBrandsStyle = {
    border: 'none',
    display: 'block',
    margin: '0',
    padding: '0',
    textDecoration: 'none',
  };

  return (
    <Section outerBackground={theme.colors.white}>
      <Container style={containerStyle}>
        <Heading
          as={RRLink}
          color="blue"
          fontSize="14px"
          lineHeight="16px"
          css={discoverBrandsStyle}
          to="/brands"
        >
          Discover Brands /
        </Heading>

        <Heading as="h2" fontSize="32px" lineHeight="40px" css={headingStyle}>
          Industries
        </Heading>

        <Text fontSize="14px" fontWeight="normal" lineHeight="24px" width="792px">
          We&rsquo;ve curated {groupsLength} groups to include brands that reflect popular
          categories of companies—you can see the full list below. Each of these curated groups
          feature data and insights and represent industries that matter to you. Read{' '}
          <Link to="https://www.mailcharts.com/industry-insights" target="_blank">
            how we curate ecommerce brands for you
          </Link>{' '}
          or click to{' '}
          <Link to="https://youtu.be/VIrYWm9qEW8" target="_blank">
            watch a quick video
          </Link>{' '}
          on how to create custom groups of brands that matter most to you.
        </Text>
      </Container>
    </Section>
  );
};

Header.propTypes = {
  groupsLength: PropTypes.number.isRequired,
  theme: PropTypes.shape({}).isRequired,
};

export default withTheme(Header);
