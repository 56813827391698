/* eslint-disable react/no-array-index-key */
import humanize from 'humanize-number';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import { equals, isEmpty, omit } from 'ramda';
import React from 'react';
import { Link as RRLink } from 'react-router';
import { Box, Button, Flex, Grid, Heading, Text } from '../../../mc-ui';
import BrandLogo from '../../../ui/BrandLogo';
import FeaturedSMSLists from '../../FeaturedSMSLists';

export default function Restricted({ data, model, query: q, total }) {
  const query = omit(['company_id', 'group_id', 'list_id', 'sort'], q);

  const brands = data.reduce((acc, textMessage) => {
    const [brand] = textMessage.relationships.companies.data;
    if (brand && !acc.some(b => equals(b.id, brand.id))) {
      acc.push(brand);
    }
    return acc;
  }, []);

  return (
    <Box backgroundColor="white" borderRadius="4px" marginBottom="40px" padding="32px">
      <Flex alignItems="flex-start" marginBottom="40px">
        {isEmpty(query) ? (
          <Box flex="1" marginRight="24px" width="unset">
            <Heading as="h3" fontSize="18px" lineHeight="1.333.em" css={{ marginBottom: '8px' }}>
              Try Filters or upgrade to view all {humanize(total)} {model.attributes.name} text{' '}
              {pluralize('message', total)}.
            </Heading>

            <Text>
              Looking for something specific? You can check if we have data that you need! Click the
              blue “Filter” button above, apply all the relevant filters and confirm that we have
              data that is important to your team.
            </Text>
          </Box>
        ) : (
          <Box flex="1" marginRight="24px" width="unset">
            <Heading as="h3" fontSize="18px" lineHeight="1.333.em" css={{ marginBottom: '8px' }}>
              We have {humanize(total)} text {pluralize('message', total)} that match your search!
              Upgrade to view them.
            </Heading>

            <Text>
              Access your search results and all {model.attributes.name} text messages to jump start
              brand comparisons and insights.
            </Text>
          </Box>
        )}

        <Button
          as={RRLink}
          to="/manage/plan"
          variant="purple"
          css={{
            alignItems: 'center',
            display: 'inline-flex',
            height: '40px',
            justifyContent: 'center',
            padding: '0',
            width: '130px',
          }}
        >
          Upgrade Now
        </Button>
      </Flex>

      {model.type === 'companies' ? (
        <div>
          <Text color="dark08" margin="0 0 16px 0">
            Or, browse thousands of hand-picked emails in our <strong>FREE</strong> curated SMS
            lists:
          </Text>

          <Flex justifyContent="space-between">
            {Array.from({ length: 3 }).map((_, i) => (
              <Flex
                key={`restrictedPreviewList-${i}`}
                alignItems="center"
                border="1px solid"
                borderColor="dark5"
                borderRadius="4px"
                height="240px"
                justifyContent="center"
                width="247px"
              >
                List {i + 1}
              </Flex>
            ))}
          </Flex>
        </div>
      ) : (
        <Grid columnGap="8px" gridTemplateColumns="repeat(16, 1fr)" rowGap="4px">
          {brands.map(brand => (
            <RRLink key={`restrictedTextMessage-Brand${brand.id}`} to={`/companies/${brand.id}`}>
              <BrandLogo brand={brand} />
            </RRLink>
          ))}
        </Grid>
      )}
      <FeaturedSMSLists />
    </Box>
  );
}

Restricted.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      relationships: PropTypes.shape({
        companies: PropTypes.shape({
          data: PropTypes.array,
        }),
      }),
    }),
  ).isRequired,
  model: PropTypes.shape({
    attributes: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
  query: PropTypes.shape({}).isRequired,
  total: PropTypes.number.isRequired,
};
