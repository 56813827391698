import * as React from 'react';
import PropTypes from 'prop-types';

function BetweenEmailsIcon({ fillOpacity, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.8 5.88H2.4V3.72h2.4A3.48 3.48 0 018.28 7.2v9.6a3.48 3.48 0 01-3.48 3.48H2.4v-2.16h2.4a1.32 1.32 0 001.32-1.32V7.2A1.32 1.32 0 004.8 5.88zM19.2 5.88h2.4V3.72h-2.4a3.48 3.48 0 00-3.48 3.48v9.6a3.48 3.48 0 003.48 3.48h2.4v-2.16h-2.4a1.32 1.32 0 01-1.32-1.32V7.2c0-.729.591-1.32 1.32-1.32zM10.8 15.6V8.4h2.16v7.2H10.8z"
        fill="currentColor"
        fillOpacity={fillOpacity}
      />
    </svg>
  );
}

BetweenEmailsIcon.propTypes = {
  fillOpacity: PropTypes.number,
};

BetweenEmailsIcon.defaultProps = {
  fillOpacity: 1,
};

export default BetweenEmailsIcon;
