import axios from 'axios';

import api from '../lib/api';

let req = null;

export default store => next => action => {
  next(action);

  if (action.type === 'REQUEST_NAV_JOURNEYS') {
    const params = { size: 1 };
    req = axios.CancelToken.source();

    api({
      method: 'get',
      url: action.url || '/v3/journeys',
      params,
      cancelToken: req.token,
    })
      .then(r => store.dispatch({ type: 'RECEIVE_NAV_JOURNEYS', data: r.data }))
      .catch(console.error);
  }
};
