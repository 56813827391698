import React from 'react';
import { components } from 'react-select';

import Flex from './Flex';
import FindGlassIcon from './icons/FindGlassIcon';
import ReactSelect from './overrides/ReactSelect';

const placeholder = base => ({
  ...base,
  left: '32px',
});

const singleValue = base => ({
  ...base,
  left: '32px',
});

// eslint-disable-next-line react/prop-types
const ValueContainer = ({ children, ...props }) => (
  <components.ValueContainer {...props}>
    <Flex alignItems="center">
      <Flex alignItems="center" color="dark1" width="24px">
        <FindGlassIcon height="24px" width="24px" />
      </Flex>
      {children}
    </Flex>
  </components.ValueContainer>
);

const SearchSelect = props => (
  <ReactSelect {...props} components={{ ValueContainer }} styles={{ placeholder, singleValue }} />
);

export default SearchSelect;
