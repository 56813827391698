import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

import { Box, Button, Flex, Heading, Text } from '../../../../mc-ui';

import Body from '../../../Body';
import ReportSection from '../../../ReportSection';
import RestrictedHeader from '../../../RestrictedHeader';
import RestrictedUpgradeButton from '../../../RestrictedUpgradeButton';

import Compare from './Compare';
import CompareDate from './CompareDate';
import EmailsSentByThisBrand from './EmailsSentByThisBrand';
import EmailsSentByThisGroup from './EmailsSentByThisGroup';
import MostPopularSendDays from './MostPopularSendDays';

const isGroup = model => model.type === 'groups';

const Upgrade = ({ model }) => (
  <ReportSection css={{ paddingTop: '0' }}>
    <Body>
      <RestrictedHeader
        description="Optimize and fine-tune your campaign planning and strategy."
        reportName="Sending Behavior"
      />

      <Flex justifyContent="space-between" marginBottom="40px">
        <Flex direction="column" justifyContent="center" padding="0 32px" width="360px">
          <Heading as="h4" fontSize="18px" lineHeight="24px" css={{ marginBottom: '8px' }}>
            Analyze sending volume, trends, activity
          </Heading>

          <Text>
            Incorporate this data in campaign planning to fine-tune creative and promotion strategy
            and adjust frequency/cadence schedules for maximum inbox visibility.
          </Text>
        </Flex>

        <Box width="360px">
          {isGroup(model) ? <EmailsSentByThisGroup /> : <EmailsSentByThisBrand />}
        </Box>
      </Flex>

      <Flex justifyContent="space-between" marginBottom="40px">
        <Box width="360px">
          <CompareDate />
        </Box>

        <Flex direction="column" justifyContent="center" padding="0 32px" width="360px">
          <Heading as="h4" fontSize="18px" lineHeight="24px" css={{ marginBottom: '8px' }}>
            Study historical behavior to decode strategy
          </Heading>

          {isGroup(model) ? (
            <Text margin="0 0 8px 0">
              Write stronger audits of competing or complementary brands that show YOY strategy,
              creative, promotion usage and frequency/cadence trends. Click below to watch a quick
              video and learn everything about sending behavior reports.
            </Text>
          ) : (
            <Text margin="0 0 8px 0">
              Write stronger audits of competing brands that show YOY strategy, creative, promotion
              usage and frequency or cadence trends. Click below to watch a quick video and learn
              everything about sending behavior reports.
            </Text>
          )}

          <Button
            as={Link}
            to="https://youtu.be/si2iNUkdx1I"
            target="_blank"
            variant="primary"
            css={{
              alignItems: 'center',
              display: 'inline-flex',
              fontSize: '12px',
              fontWeight: '500',
              justifyContent: 'center',
              lineHeight: '23px',
              height: '23px',
              margin: '0',
              padding: '0',
              width: '80px',
            }}
          >
            Watch now
          </Button>
        </Flex>
      </Flex>

      <Flex justifyContent="space-between" marginBottom="40px">
        <Flex direction="column" justifyContent="center" padding="0 32px" width="360px">
          <Heading as="h4" fontSize="18px" lineHeight="24px" css={{ marginBottom: '8px' }}>
            Identify opportunities for sending email
          </Heading>

          <Text>
            Know the days and times when your target brands are hitting the inbox so you can plan
            campaigns that go head to head or between competitors’ usual delivery days.
          </Text>
        </Flex>

        <Box width="360px">
          <MostPopularSendDays />
        </Box>
      </Flex>

      {isGroup(model) && (
        <Flex justifyContent="space-between" marginBottom="40px">
          <Box width="360px">
            <Compare />
          </Box>

          <Flex direction="column" justifyContent="center" padding="0 32px" width="360px">
            <Heading as="h4" fontSize="18px" lineHeight="24px" css={{ marginBottom: '8px' }}>
              Benchmark the group sending behavior against other groups and brands
            </Heading>

            <Text>
              Capitalize on your strengths and shore up your email strategies and planning against
              your competition or the top brands in your group.
            </Text>
          </Flex>
        </Flex>
      )}

      <RestrictedUpgradeButton />
    </Body>
  </ReportSection>
);

Upgrade.defaultProps = {
  model: { id: null },
};

Upgrade.propTypes = {
  model: PropTypes.shape({ id: PropTypes.string, type: PropTypes.string }),
};

export default Upgrade;
