import React from 'react'
import { Link } from 'react-router'
import styles from './styles.css'

export default class extends React.Component {
  render() {
    return (
      <div style={{ background: '#3186ce' }}>
        <div className={styles.container}>
          <div className="pure-menu pure-menu-horizontal">
            <ul className="pure-menu-list">
                <li className="pure-menu-item">
                  <Link to='/' className={styles.white + ' pure-menu-link'}>Account</Link>
                </li>
                <li className="pure-menu-item">
                  <Link to='/' className={styles.white + ' pure-menu-link'}>Feedback</Link>
                </li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}
