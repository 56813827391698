import * as types from './types';

export const fetchDataRequest = (modelId, modelType, startDate, endDate) => ({
  type: types.FETCH_DATA_REQUEST,
  modelId,
  modelType: modelType === 'companies' ? 'brands' : 'groups',
  startDate,
  endDate,
});

export const fetchDataSuccess = (modelId, modelType, startDate, endDate, payload) => ({
  type: types.FETCH_DATA_SUCCESS,
  modelId,
  modelType,
  startDate,
  endDate,
  payload,
});

export const fetchDataFailure = (modelId, modelType, startDate, endDate, error) => ({
  type: types.FETCH_DATA_FAILURE,
  modelId,
  modelType,
  startDate,
  endDate,
  error,
});

export const toggleDataLoading = (modelId, modelType, startDate, endDate) => ({
  type: types.TOGGLE_DATA_LOADING,
  modelId,
  modelType,
  startDate,
  endDate,
});
