/* eslint consistent-return: "off" */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import api from '../lib/api';

class GroupsCreate extends React.Component {
  state = {
    group: { attributes: {} },
  };

  handleChange = (event) => {
    event.preventDefault();
    const { group } = this.state;
    group.attributes[event.target.name] = event.target.value;
    this.setState({ group });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    let { group } = this.state;

    api
      .post('/v2/groups', { group })
      .then((r) => {
        group = r.data.data.reduce((m, g) => g, {});
        if (!this.props.company) return;
        return api.post(`/v2/groups/${group.id}/companies`, { company_id: this.props.company.id });
      })
      .then(() => {
        this.props.dispatch({ type: 'REFRESH_USER' });
        this.context.router.push(`/groups/${group.id}/companies`);
      })
      .catch(console.error);
  };

  render() {
    return (
      <div className="pure-u-1">
        <div className="pure-u-1 pure-u-md-1-3">
          {this.props.company ? (
            <h4>Add {this.props.company.attributes.name} to a new group</h4>
          ) : (
            <h4>Create a new group</h4>
          )}
        </div>
        <div className="pure-u-1 pure-u-md-2-3">
          <form onSubmit={this.handleSubmit} className="pure-form">
            <fieldset>
              <label htmlFor="name" style={{ display: 'inline-block' }}>
                <h5 style={{ marginBottom: '2px' }}>Name</h5>
                <input
                  type="text"
                  value={this.state.group.attributes.name || ''}
                  id="name"
                  name="name"
                  className="pure-u-4-5"
                  onChange={this.handleChange}
                  style={{
                    marginRight: '10px',
                    width: '255px',
                  }}
                />
              </label>
              <input type="submit" className="pure-button pure-button-primary" value="Create" />
            </fieldset>
          </form>
        </div>
      </div>
    );
  }
}

GroupsCreate.contextTypes = {
  router: PropTypes.object,
};

GroupsCreate.propTypes = {
  company: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    attributes: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
  dispatch: PropTypes.func.isRequired,
};

GroupsCreate.defaultProps = {
  company: null,
};

const s = () => ({});
export default connect(s)(GroupsCreate);
