const INITIAL_STATE = { loading: true, data: { trends: [], companies: [], technologies: [], addresses: [], groups: [] }, meta: { total: 0 } }
export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case 'REQUEST_TRENDS': return INITIAL_STATE
    case 'RECEIVE_COMPANY_TRENDS': return { ...action.data, loading: false }
    case 'RECEIVE_DASHBOARD':
    case 'RECEIVE_TRENDS': return { ...action.data, loading: false }
    case 'FLUSH_COMPANY_TRENDS': return INITIAL_STATE
    case 'FLUSH_TRENDS': return INITIAL_STATE
    default: return state
  }
}

