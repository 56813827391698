import styled from "@emotion/styled";
import { nest, setDisplayName } from 'recompose'

const EmailsWrapper = styled("div")({
  position: 'relative',
  height: '600px',
  width: '50%'
})

const Wrapper = styled("div")({
  alignItems: 'center',
  backgroundColor: '#f3f5f7',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  height: '649px',
  left: '0',
  overflowX: 'auto',
  position: 'absolute',
  top: '-49px',
  width: '100%'
}, ({ single }) => ({
  justifyContent: single ? 'center' : 'flex-start'
}))

const AddToListEmailsWrapper = setDisplayName('AddToListEmailsWrapper')(
  nest(EmailsWrapper, Wrapper)
)

export default AddToListEmailsWrapper
