import React from 'react';
import { components } from 'react-select';

import DropdownIndicatorIcon from '../../icons/DropdownIndicatorIcon';

import colors from '../../theme/colors';

export const dropdownIndicatorStyles = (base, state) => ({
  ...base,
  color: colors.dark1,
  opacity: state.isFocused ? '1' : '0.4',
  padding: '0 16px',
  transform: `rotate(${state.selectProps.menuIsOpen ? '180deg' : 'none'})`,
  transition: `${base.transition || ''}, opacity 150ms, transform 150ms`,
});

const DropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <DropdownIndicatorIcon width="8px" height="6px" />
  </components.DropdownIndicator>
);

export default DropdownIndicator;
