import React from 'react';
import PropTypes from 'prop-types';
import styled from "@emotion/styled";

import Button from '../Button';
import CrossSmallIcon from '../icons/CrossSmallIcon';
import Heading from '../Heading';

const Header = styled("header")(() => ({
  alignItems: 'flex-start',
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'space-between',
  paddingBottom: '32px',
}));

export default function ModalHeader({ onClose, children, wrapperCss, ...props }) {
  return (
    <Header css={wrapperCss}>
      <Heading as="h3" fontSize="18px" lineHeight="24px" {...props}>
        {children}
      </Heading>

      <Button
        css={{
          fontSize: '24px',
          height: '24px',
          lineHeight: '24px',
          margin: 0,
          padding: 0,
          width: '24px',
        }}
        onClick={onClose}
        variant="none"
      >
        <CrossSmallIcon />
      </Button>
    </Header>
  );
}

ModalHeader.defaultProps = {
  wrapperCss: {},
};

ModalHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.node,
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
  onClose: PropTypes.func.isRequired,
  wrapperCss: PropTypes.shape({}),
};
