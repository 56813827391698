import styled from "@emotion/styled";

const Circle = styled("div")(({ theme }) => ({
  alignItems: 'center',
  boxSizing: 'border-box',
  display: 'flex',
  marginBottom: 10,
  overflow: 'hidden',
  position: 'relative',
  '& > input[type="radio"]': {
    height: 1,
    left: -100,
    opacity: 0,
    position: 'absolute',
    width: 1,
    '& + label': {
      cursor: 'pointer',
      lineHeight: `${22 / 14}em`,
      margin: 0,
      marginLeft: '28px',
      padding: 0,
      position: 'relative',
      '&:before': {
        background: theme.colors.white,
        border: `1px solid ${theme.colors.dark4}`,
        borderRadius: '50%',
        boxSizing: 'border-box',
        content: '""',
        height: 18,
        left: -28,
        position: 'absolute',
        top: 2,
        width: 18,
      },
      '&:after': {
        background: 'transparent',
        border: 'none',
        borderRadius: '50%',
        content: '""',
        height: 8,
        left: -23,
        opacity: '1',
        position: 'absolute',
        top: 7,
        width: 8,
      },
    },
    '&:checked': {
      '& + label': {
        '&:before': {
          background: theme.colors.blueLight,
          border: `2px solid ${theme.colors.blue}`,
        },
        '&:after': {
          background: theme.colors.blue,
        },
      },
    },
  },
}));

export default Circle;
