import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isNil } from 'ramda';

import { Box, Flex, Loader } from '../../mc-ui';

import CTA from './CTA';
import Header from './Header';
import List from './List';

const isPresent = val => !isEmpty(val) && !isNil(val);

const ScrollableList = ({ collection, loading, type }) => (
  <Box backgroundColor="white" borderRadius="4px" height="512px" padding="32px" width="338px">
    {loading || !isPresent(collection) ? (
      <Flex alignItems="center" height="100%" justifyContent="center">
        <Loader />
      </Flex>
    ) : (
      <Flex direction="column" height="100%">
        <Header type={type || collection[0].type} />
        <List collection={collection} type={type || collection[0].type} />
        <CTA type={type || collection[0].type} />
      </Flex>
    )}
  </Box>
);

ScrollableList.defaultProps = {
  collection: [],
  loading: false,
  type: null,
};

ScrollableList.propTypes = {
  collection: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
    }),
  ),
  loading: PropTypes.bool,
  type: PropTypes.string,
};

export default ScrollableList;
