import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@emotion/react';

import { Badge } from '../../../../mc-ui';

const formatType = type =>
  type
    .split('_')
    .map(word => `${word.charAt(0).toUpperCase()}${word.slice(1)}`)
    .join(' ');

const TypeBadge = ({ css, theme, type }) => {
  const typeTheme = theme.colors.journeyTypes[type] || theme.colors.journeyTypes.custom;

  return (
    <Badge
      backgroundColor={typeTheme.backgroundColor}
      color={typeTheme.color}
      css={{
        border: `1px solid ${typeTheme.borderColor}`,
        marginRight: '10px',
        marginTop: '5px',
        whiteSpace: 'nowrap',
        ...css,
      }}
    >
      {formatType(type)}
    </Badge>
  );
};

TypeBadge.defaultProps = {
  css: {},
  type: 'custom',
};

TypeBadge.propTypes = {
  css: PropTypes.shape({}),
  theme: PropTypes.shape({}).isRequired,
  type: PropTypes.string,
};

export default withTheme(TypeBadge);
