import React from 'react';
import { withTheme } from '@emotion/react';

import { Box, Heading, Text, Tooltip } from '../../../mc-ui';
import QuestionMarkIcon from '../../../mc-ui/icons/QuestionMarkIcon';

// eslint-disable-next-line react/prop-types
const PromotionTypeTooltip = ({ theme }) => (
  <Box
    css={{
      color: theme.colors.dark06,
      display: 'inline',
      fontSize: '12px',
      lineHeight: '1em',
      marginLeft: '6px',
      width: 'auto',
    }}
  >
    <Tooltip id="promotionTypeTooltip" element={<QuestionMarkIcon fillOpacity={1} />} dark>
      <Heading as="h4">Promotion type</Heading>
      <Text>
        Emails can contain several types of promotions all at once, so the sum of the types of
        promotions may be &quot;higher&quot; than 100%.
      </Text>
    </Tooltip>
  </Box>
);

export default withTheme(PromotionTypeTooltip);
