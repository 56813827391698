import { createSelector } from 'reselect';

import { teamsSelectors } from '../teams';

export const { isMembershipActiveSelector, teamSelector } = teamsSelectors;

const getUser = state => state.user;
export const getState = state => state.customGroups;

export const isCreateFormOpenSelector = createSelector(getState, state => !!state.isCreateFormOpen);
export const isEditFormOpenSelector = group =>
  createSelector(getState, state => {
    if (!state.isEditFormOpen) return false;
    return Number(group.id) === Number(state.isEditFormOpen);
  });

export const isOwnerSelector = group =>
  createSelector(getUser, users => {
    if (users.loading) return false;
    const [user] = users.data;
    return Number(user.id) === Number(group.attributes.user_id || group.attributes.userId);
  });

export const isTeamGroupsLoadingReducer = createSelector(
  getState,
  state => !!state.teamGroups.loading,
);

export const teamGroupsDataReducer = createSelector(getState, state => state.teamGroups.data || []);
export const teamGroupIdsReducer = createSelector(getState, state => state.teamGroupIds || []);

export const isUserGroupsLoadingReducer = createSelector(
  getState,
  state => !!state.userGroups.loading,
);

export const userGroupsDataReducer = createSelector(getState, state => state.userGroups.data || []);
export const userGroupIdsReducer = createSelector(getState, state => state.userGroupIds || []);

export const isValidTeamSelector = createSelector(
  teamSelector,
  isMembershipActiveSelector,
  (team, isMembershipActive) => team && isMembershipActive,
);
