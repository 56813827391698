import React from 'react';
import { Link } from 'react-router';

import { Badge, Button, Heading, Tooltip } from '../../../mc-ui';

const MailChartsProTooltip = () => (
  <Tooltip
    id="technologyTooltip"
    element={
      <Badge backgroundColor="purpleMedium" color="white" css={{ marginLeft: '6px' }}>
        Pro
      </Badge>
    }
  >
    <Heading as="h2">Reveal ESP usage &amp; more</Heading>

    <p>
      Upgrade to MailCharts Pro to view the sending ESP and technologies used by brands in the MailCharts Index.{' '}
    </p>

    <Button as={Link} to="/manage/plan">
      Upgrade to MailCharts Pro
    </Button>
  </Tooltip>
);

export default MailChartsProTooltip;
