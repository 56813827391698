import React from 'react';
import { camelizeKeys } from 'humps';
import { take } from 'ramda';

import api from '../../lib/api';

import ScrollableList from '../ui/ScrollableList';

async function fetchBrands() {
  const response = await api.get('/v3/companies?size=12&sort=-tracked_count');
  return camelizeKeys(take(12, response.data.data));
}

class MostTrackedBrands extends React.PureComponent {
  state = {
    brands: [],
    loading: true,
  };

  componentDidMount() {
    this.request();
  }

  request = async () => {
    const brands = await fetchBrands();

    this.setState(() => ({
      brands,
      loading: false,
    }));
  };

  render() {
    const { brands, loading } = this.state;

    return <ScrollableList collection={brands} loading={loading} type="mostTrackedBrands" />;
  }
}

export default MostTrackedBrands;
