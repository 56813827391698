import React from 'react';
import PropTypes from 'prop-types';

function EmailIcon({ fillOpacity, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M18.333 6.216c-.138-1.658-1.388-2.883-2.916-2.883H4.583c-1.458 0-2.708 1.153-2.916 2.739v7.568c0 1.657 1.32 3.027 2.916 3.027h10.764c1.598 0 2.917-1.37 2.917-3.027l.07-7.424zM4.583 4.991h10.764c.695 0 1.32.576 1.39 1.297v.505l-6.46 4.252c-.207.144-.416.144-.555 0L3.264 6.721v-.433c0-.72.625-1.297 1.32-1.297zm10.834 10.09H4.583c-.763 0-1.388-.649-1.388-1.441V8.667l5.625 3.747c.347.216.763.36 1.18.36.417 0 .764-.143 1.111-.36l5.625-3.747v4.973c0 .792-.555 1.441-1.32 1.441z"
        fill="currentColor"
        fillOpacity={fillOpacity}
      />
    </svg>
  );
}

EmailIcon.propTypes = {
  fillOpacity: PropTypes.number,
};

EmailIcon.defaultProps = {
  fillOpacity: 0.6,
};

export default EmailIcon;
