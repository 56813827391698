import React from 'react';
import PropTypes from 'prop-types';

function ArrowLeftIcon({ fillOpacity, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 11V9H6.883l3.375-3.375A.86.86 0 009.04 4.41L4.398 9.052a1.286 1.286 0 00-.28 1.406c.065.157.16.299.28.419l4.643 4.643a.86.86 0 001.217-1.217L6.955 11H16z"
        fill="currentColor"
        fillOpacity={fillOpacity}
      />
    </svg>
  );
}

ArrowLeftIcon.propTypes = {
  fillOpacity: PropTypes.number,
};

ArrowLeftIcon.defaultProps = {
  fillOpacity: 0.6,
};

export default ArrowLeftIcon;
