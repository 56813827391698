import styled from "@emotion/styled";

export const Button = styled("div")({
  cursor: 'pointer',
});

export const Dropdown = styled("div")(({ theme }) => ({
  backgroundColor: theme.colors.white,
  borderRadius: '4px',
  left: '16px',
  padding: '4px',
  position: 'absolute',
  top: '0',
  width: '216px',
  filter: `drop-shadow(0px 2px 8px ${theme.colors.dark02})`,
  zIndex: theme.zIndices.dropdown,
  '& > div': { width: '100% !important' },
}));

export const DropdownItem = styled("button")(({ theme }) => ({
  ...theme.button.none,
  alignItems: 'center',
  display: 'flex',
  height: '32px',
  justifyContent: 'space-between',
  lineHeight: '32px',
  padding: '0 8px',
  width: '100%',
  '&:hover': {
    backgroundColor: theme.colors.dark6,
    border: 'none',
    color: theme.colors.dark1,
  },
  '&[disabled]': {
    boxShadow: 'none',
    cursor: 'not-allowed',
    filter: 'alpha(opacity=40)',
    opacity: '.4',
    pointerEvents: 'none',
  },
}));
