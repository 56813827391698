import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@emotion/react';

import { Badge } from '../../../mc-ui';

const TypeBadge = ({ theme, type }) => {
  const typeTheme = theme.colors.journeyTypes[type] || theme.colors.journeyTypes.custom;

  return (
    <Badge
      backgroundColor={typeTheme.backgroundColor}
      color={typeTheme.color}
      css={{ border: `1px solid ${typeTheme.borderColor}`, marginRight: '10px', marginTop: '5px' }}
    >
      {type.replace(/_/g, ' ')}
    </Badge>
  );
};

TypeBadge.propTypes = {
  theme: PropTypes.shape({}).isRequired,
  type: PropTypes.string.isRequired,
};

export default withTheme(TypeBadge);
