import { flatten } from 'ramda';

import generateDefaultTooltipHTML from './generateDefaultTooltipHTML';

const brandComparisonRegex = /\{BRAND_COMPARISON\}/;

const generateCompareBrandsTooltipHTML = () => (getBody, tooltipModel) => {
  if (!tooltipModel.body) return null;

  const titleLines = tooltipModel.title || [];
  const bodyLines = flatten(tooltipModel.body.map(getBody));
  let tooltipHTML = generateDefaultTooltipHTML()(getBody, tooltipModel);

  bodyLines.forEach((bodyLine, index) => {
    const color = tooltipModel.labelColors[index];
    const title = titleLines[index];
    if (brandComparisonRegex.test(bodyLine)) {
      tooltipHTML += `<div class="title">${title}</div>`;
      tooltipHTML += `<div class="line"><div class="bullet" style="background-color: ${
        color.borderColor === '#FFF' ? color.backgroundColor : color.borderColor
      }"></div> <div class="content">${bodyLine.replace(brandComparisonRegex, '')}</div></div>`;
    }
  });

  return tooltipHTML;
};

export default generateCompareBrandsTooltipHTML;
