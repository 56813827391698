import React from 'react';
import PropTypes from 'prop-types';

function CalendarIcon({ fillOpacity, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M14.444 2.5H5.556a3.882 3.882 0 00-3.89 3.889v7.57c0 2.083 1.737 3.819 3.89 3.819h8.889a3.882 3.882 0 003.888-3.89v-7.5A3.882 3.882 0 0014.445 2.5zM5.556 4.097h8.889a2.312 2.312 0 012.291 2.292H3.264c0-1.32 1.042-2.292 2.292-2.292zm8.889 12.084h-8.89a2.312 2.312 0 01-2.291-2.292V7.917h13.542v5.972c-.07 1.32-1.112 2.292-2.362 2.292z"
        fill="currentColor"
        fillOpacity={fillOpacity}
      />
      <path
        d="M7.292 9.514H5.139v2.153h2.153V9.514zM11.111 9.514H8.96v2.153h2.152V9.514zM7.292 12.778H5.139v2.153h2.153v-2.153zM11.111 12.778H8.96v2.153h2.152v-2.153zM14.861 12.778h-2.153v2.153h2.153v-2.153z"
        fill="currentColor"
        fillOpacity={fillOpacity}
      />
    </svg>
  );
}

CalendarIcon.propTypes = {
  fillOpacity: PropTypes.number,
};

CalendarIcon.defaultProps = {
  fillOpacity: 0.6,
};

export default CalendarIcon;
