/* global window */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import humanize from 'humanize-number';
import moment from 'moment';
import { connect } from 'react-redux';

import PaymentLink from '../PaymentLink';
import Container from '../Container';

import ContactModalLink from '../ContactModalLink';
import { topics }  from '../ContactUs';

import ContactUs from './ContactUs';
import HeaderContainer from './ui/HeaderContainer';
import Label from './ui/Label';
import Title from './ui/Title';

import JourneysContainer from './ui/JourneysContainer';

const containerStyle = {
  display: 'flex',
  flexDirection: 'column',
  margin: '48px 0',
  padding: '40px',
};

class UpgradeReports extends Component {
  componentDidMount() {
    window.analytics.track('User restricted', { type: 'reports' });
  }

  handlePaywallClicked = (event) => {
    event.preventDefault();
    const type = 'reports';

    window.analytics.track('Paywall clicked', { type });
  }

  render() {
    const { model } = this.props;
    const { attributes } = model
    if (!attributes) return null
    const duration = moment(model.attributes.first_email_sent_at).from().replace('ago', '');
    const trackedCompanies = model.type === 'groups' && model.attributes.tracked;
    const title = trackedCompanies
      ? 'Upgrade to access your tracked companies’ report'
      : `Upgrade for detailed ${model.attributes.name} reports`;

    const unlock = model.type === 'groups'
      ? `Immediately unlock ${humanize(model.attributes.approximate_companies || 0)} ${model.attributes.approximate_companies === 1 ? 'brand\'s' : 'brands\''} detailed data.`
      : `Immediately unlock ${duration} (${humanize(model.attributes.approximate_emails || 0)} emails) of ${model.attributes.name} data.`;

    return (
      <Container style={containerStyle}>
        <HeaderContainer
          style={{
            maxHeight: 400,
            backgroundPosition: 'right center',
            backgroundImage: 'url(/upgrade-reports-bg.svg)',
            backgroundSize: 'auto 325px',
          }}
        >
          <Label>Pro</Label>
          <Title>{title}</Title>
          <p style={{ maxWidth: 500 }}>
            Understand email trends, track and report on competitors, and
            identify low-hanging fruit for your own campaigns.
          </p>
          {!trackedCompanies &&
          <p style={{ maxWidth: 500 }}>
            {unlock}
          </p>}
          <p style={{ display: 'inline-block' }}>
            <PaymentLink
              className="pure-button pure-button-primary"
              style={{ fontSize: `${13 / 16}em`, lineHeight: '1em', padding: '1em 1.8em' }}
              to="/manage/plan"
              onClick={this.handlePaywallClicked}
            >
              Upgrade now
            </PaymentLink>
          </p>
          <p style={{ fontSize: `${12/16}em`, maxWidth: 500 }}>
            <strong>Interested in a free month?</strong> Schedule some time
            to chat with us and we'll unlock your account for one month.{' '}
            <ContactModalLink topic={topics.QUESTION}>
              Contact Support
            </ContactModalLink>
          </p>
        </HeaderContainer>
        <JourneysContainer>
          <div className="pure-g gutters" style={{ marginTop: '40px' }}>
            <div className="pure-u-1 pure-u-md-1-3">
              <div
                className="pure-u-1"
                style={{ textAlign: 'center', margin: '0 auto', background: '#F3F5F7' }}
              >
                <img
                  src="/upgrade-calendar-report.svg"
                  style={{ maxWidth: 139, margin: '29px 0' }}
                  alt="Plan upcoming campaigns"
                />
              </div>
              <h4>Plan upcoming campaigns</h4>
              <p>
                Streamline campaign planning by understanding what brands did
                last year, last quarter or last month.
              </p>
            </div>

            <div className="pure-u-1 pure-u-md-1-3">
              <div
                className="pure-u-1"
                style={{ textAlign: 'center', margin: '0 auto', background: '#F3F5F7' }}
              >
                <img
                  src="/upgrade-comparisons.svg"
                  style={{ maxWidth: 158, margin: '20px 0' }}
                  alt="Powerful comparisons"
                />
              </div>
              <h4>Powerful comparisons</h4>
              <p>Compare brands side-by-side to establish vital benchmarks like
                sending behavior, discount strategies, and content.
              </p>
            </div>

            <div className="pure-u-1 pure-u-md-1-3">
              <div
                className="pure-u-1"
                style={{ textAlign: 'center', margin: '0 auto', background: '#F3F5F7' }}
              >
                <img
                  src="/upgrade-exports.svg"
                  style={{ maxWidth: 133, margin: '21px 0' }}
                  alt="Easy exports"
                />
              </div>
              <h4>Easy exports</h4>
              <p>
                Speed up presentations and internal meetings&mdash;export email
                screenshots and subject lines in just a few clicks.
              </p>
            </div>
          </div>
        </JourneysContainer>
        <ContactUs />
      </Container>
    );
  }
}

UpgradeReports.propTypes = {
  model: PropTypes.shape({}).isRequired,
};

export default connect()(UpgradeReports);
