import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@emotion/react';

import nrfDates from '../../../../../lib/nrfDates';

import { Box, Flex, Heading, Text } from '../../../../mc-ui';
import ArrowDownIcon from '../../../../mc-ui/icons/ArrowDownIcon';
import ArrowUpIcon from '../../../../mc-ui/icons/ArrowUpIcon';
import EqualIcon from '../../../../mc-ui/icons/EqualIcon';

const getIcon = delta => {
  let Icon = EqualIcon;
  let color = 'yellowMedium';
  const percentage = Math.round(delta * 100);

  if (delta < 0) {
    color = 'red1';
    Icon = ArrowDownIcon;
  } else if (delta > 0) {
    color = 'greenMedium';
    Icon = ArrowUpIcon;
  }

  return { Icon, color, percentage };
};

const WeeklyTotals = ({ nrfWeek, data, date, theme }) => {
  const nrfWeekNumber = nrfWeek || nrfDates(date).nrf.week();
  const week = data.stats.weeks.find(w => Number(w.nrf_week) === Number(nrfWeekNumber));
  const promotionalAvg = week ? Math.round(week.promotional.avg * 100) : null;
  const icons = week ? [getIcon(week.frequency.delta), getIcon(week.promotional.delta)] : [];

  return (
    <Flex
      justifyContent="space-between"
      css={{
        backgroundColor: theme.colors.dark004,
        borderRight: null,
        padding: '12px',
        width: '180px',
      }}
    >
      <Flex direction="column" width="100px">
        <Heading
          as="h5"
          col0r="dark08"
          css={{
            fontSize: '10px',
            fontWeight: 'bold',
            letterSpacing: '0.12em',
            lineHeight: '1.56em',
            marginBottom: '12px',
          }}
        >
          {nrfWeekNumber === 0 ? String.fromCharCode(160) : `NRF Week ${nrfWeekNumber}`}
        </Heading>

        <Text
          color="dark06"
          fontSize="10px"
          fontWeight="bold"
          letterSpacing="0.12em"
          lineHeight="16px"
          marginBottom
          textTransform="uppercase"
          css={{ marginBottom: '8px' }}
        >
          {week && week.frequency.value ? week.frequency.value.toFixed(1) : 0} emails{' '}
        </Text>

        <Flex alignItems="center" justifyContent="left" height="16px">
          <Text color="dark06" fontSize="10px" fontWeight="bold" letterSpacing="0.12em">
            {promotionalAvg ? `${promotionalAvg}%` : '–'}
          </Text>
          <Box
            css={{
              backgroundColor: theme.colors.white,
              borderRadius: '4px',
              height: '8px',
              marginLeft: '1px',
              position: 'relative',
              width: '60px',
              '&::after': {
                backgroundColor: theme.colors.tealMedium,
                borderRadius: '4px',
                left: 0,
                content: '""',
                display: 'block',
                height: '8px',
                position: 'absolute',
                width: promotionalAvg ? `${promotionalAvg}%` : 0,
              },
            }}
          />
        </Flex>
      </Flex>

      <Flex direction="column" width="50px">
        <Heading
          as="h5"
          col0r="dark08"
          css={{
            fontSize: '10px',
            fontWeight: 'bold',
            letterSpacing: '0.12em',
            lineHeight: '1.56em',
            marginBottom: '12px',
            textAlign: 'center',
          }}
        >
          YOY ∆
        </Heading>

        {icons.map((icon, index) => {
          const key = `${icon.color}${icon.percentage}${index}`;

          return (
            <Flex
              key={key}
              alignItems="center"
              justifyContent="flex-end"
              css={{ color: theme.colors[icon.color], marginBottom: '8px' }}
            >
              {icon.percentage !== 0 && (
                <Text color={icon.color} fontSize="12px" lineHeight="16px">
                  {icon.percentage}%
                </Text>
              )}
              <icon.Icon height="16px" width="16px" />
            </Flex>
          );
        })}
      </Flex>
    </Flex>
  );
};

WeeklyTotals.propTypes = {
  nrfWeek: PropTypes.number,
  data: PropTypes.shape({}).isRequired,
  date: PropTypes.string.isRequired,
  theme: PropTypes.shape({}).isRequired,
};

export default withTheme(WeeklyTotals);
