import api from '../lib/api'

export default store => next => action => {
  next(action)
  if (action.type === 'REQUEST_COMPANIES_REQUESTED') {
    api.get(`/v2/companies/requested`, { params: action.params })
      .then(r => store.dispatch({ type: 'RECEIVE_COMPANIES_REQUESTED', data: r.data }))
  }
}


