import api from '../lib/api'

export default store => next => action => {
  next(action)
  if (action.type === 'REQUEST_TECHNOLOGIES') {
    let technologies
    let params = action.params || {}
    if (action.technology_id) params.technology_id = action.technology_id
    api.get(`/v2/technologies`, { params })
    .then(r => {
      technologies = r.data
      store.dispatch({ type: 'RECEIVE_TECHNOLOGIES', data: technologies })
      if (technologies.data.length == 1 && !action.index) {
        let technology = technologies.data.filter(t => t.id == params.technology_id).reduce((m, g) => g, {})
        store.dispatch({ type: 'REQUEST_COMPANIES', url: technology.relationships.companies.links.self })
      }
    })
    .catch(err => {
      console.error(err)
    })
  }
  if (action.type == 'REQUEST_TECHNOLOGIES_PAGINATION') {
    let link = store.getState().technologies.links.next
    if (!link) return
    api.get(link)
      .then(r => {
        let technologies = r.data
        store.dispatch({ type: 'RECEIVE_TECHNOLOGIES_PAGINATION', technologies })
      })
  }
}

