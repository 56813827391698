import moment from 'moment';

import api from '../../lib/api';

async function fetchApproximateJourneys(id) {
  const response = await api.get(`/v3/groups/${id}/companies?size=0`);
  const { meta } = response.data;

  return meta.total_approximate_journeys;
}

export const fetchData = async model => {
  const startDate = moment()
    .day('Synday')
    .format('YYYY-MM-DD');
  const endDate = moment().format('YYYY-MM-DD');
  const response = await api.get(
    `/v3/reports/overview?group_id=${model.id}&end_date=${endDate}&start_date=${startDate}`,
  );
  const totalApproximateJourneys = await fetchApproximateJourneys(model.id);
  const result = {
    ...response.data,
    meta: {
      ...response.data.meta,
      stats: {
        ...response.data.meta.stats,
        approximate_journeys: totalApproximateJourneys,
      },
    },
  };

  return result;
};

export const fetchBenchmarks = async model => {
  const response = await api.get(`/v3/reports/benchmarks?group_id=${model.id}`);
  return response.data;
};

export const getJourneys = async () => {
  const journeys = [];

  let response = await api.get('/v2/segments/67762/journey');
  journeys.push(response.data);

  response = await api.get('/v2/segments/29145/journey');
  journeys.push(response.data);

  response = await api.get('/v2/segments/67705/journey');
  journeys.push(response.data);

  return { data: journeys };
};

export const fetchHighlightedBrands = async (groupId, page = 1) => {
  const response = await api.get(
    `/v3/groups/${groupId}/companies?size=4&page=${page}&state=active&sort=-frequency`,
  );
  const brands = response.data.included.filter(i => i.type === 'companies');

  return { brands, links: response.data.links };
};

export const paginateHighlightedBrands = async url => {
  const response = await api.get(url);
  const brands = response.data.included.filter(i => i.type === 'companies');

  return { brands, links: response.data.links };
};
