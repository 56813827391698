import axios from 'axios';
import api from '../lib/api';

let req = null;

const getUserTrackingCompanies = store =>
  store
    .getState()
    .user.included.filter(i => i.type === 'companies')
    .map(c => c.id);

export default store => next => (action) => {
  next(action);
  if (action.type === 'REFRESH_COMPANIES') {
    const params = action.params || {};
    params.company_id = action.company_id || store.getState().companies.data.map(c => c.id);
    if (req) req.cancel();
    req = axios.CancelToken.source();
    api({
      method: 'get',
      url: action.url || '/v3/companies',
      params,
      cancelToken: req.token,
    })
      .then((r) => {
        const companies = r.data;
        store.dispatch({ type: 'RECEIVE_COMPANIES', data: companies });
      })
      .catch((err) => {
        if (axios.isCancel(err)) return; // do nothing
        console.error(err);
      });
  }
  if (action.type === 'REQUEST_COMPANIES') {
    const params = action.params || {};
    if (action.company_id) params.company_id = action.company_id;
    if (req) req.cancel();
    req = axios.CancelToken.source();
    api({
      method: 'get',
      url: action.url || '/v3/companies',
      params,
      cancelToken: req.token,
    })
      .then((r) => {
        const companies = r.data;
        const trackingCompanies = getUserTrackingCompanies(store);

        store.dispatch({ type: 'RECEIVE_COMPANIES', data: companies, trackingCompanies });
      })
      .catch((err) => {
        if (axios.isCancel(err)) return; // do nothing
        console.error(err);
      });
  }
  if (action.type === 'REQUEST_COMPANIES_PAGINATION') {
    const link = store.getState().companies.links.next;
    if (!link) return;
    api.get(link).then((r) => {
      const companies = r.data;
      const trackingCompanies = getUserTrackingCompanies(store);

      store.dispatch({ type: 'RECEIVE_COMPANIES_PAGINATION', companies, trackingCompanies });
    });
  }
};
