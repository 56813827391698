import React from 'react';
import PropTypes from 'prop-types';

import { Box, Heading, Text } from '../mc-ui';

export default function Header({ restricted }) {
  return (
    <Box
      backgroundColor="white"
      borderRadius="4px"
      padding="32px"
      paddingRight="150px"
      position="relative"
      width={restricted ? '702px' : '100%'}
      css={{ minHeight: '168px' }}
    >
      <Heading
        as="h3"
        fontSize="24px"
        fontWeight="300"
        lineHeight="32px"
        css={{ marginBottom: '2px' }}
      >
        MailCharts <strong>Unlocked</strong>
      </Heading>

      <Text color="dark08">
        Every week we unlock sections of Pro data to our Free users. Scroll down to see which
        reports, historical data, and journeys have been unlocked this week. To view data for all
        2500+ brands in 90+ industries, upgrade to <strong>MailCharts Pro</strong>.
      </Text>

      <Box
        css={{ backgroundImage: 'url(/unlocked-header.svg)', backgroundRepeat: 'no-repeat' }}
        height="160px"
        position="absolute"
        right="0"
        top="7px"
        width="111px"
      />
    </Box>
  );
}

Header.propTypes = {
  restricted: PropTypes.bool.isRequired,
};
