import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Section from './Section';

import Header from './browseGroups/Header';
import List from './browseGroups/List';

class BrowseGroups extends React.Component {
  UNSAFE_componentWillMount() {
    const { requestGroups, router } = this.props;
    const { order, owner } = router.location.query;
    const params = { order, owner, global: true, limit: 250 };

    requestGroups(params);
  }

  render() {
    const { groups } = this.props;

    if (groups.loading) {
      return (
        <Section>
          <p>Loading...</p>
        </Section>
      );
    }

    return (
      <div>
        <Header groupsLength={groups.data.length} />
        <List groups={groups} />
      </div>
    );
  }
}

BrowseGroups.propTypes = {
  groups: PropTypes.shape({}).isRequired,
  requestGroups: PropTypes.func.isRequired,
  router: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => ({
  groups: state.groups,
});

const mapDispatchToProps = dispatch => ({
  requestGroups: params =>
    dispatch({
      type: 'REQUEST_GROUPS',
      url: '/v3/groups',
      index: true,
      params,
    }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BrowseGroups);
