import React from 'react';
import { PropTypes } from 'prop-types';

import Flex from '../Flex';

const Body = ({ children, active }) => (
  <Flex direction="column" css={{ paddingTop: '24px' }}>
    {children({ active })}
  </Flex>
);

Body.propTypes = {
  active: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
};

export default Body;
