import { assoc, assocPath, merge } from 'ramda';

import * as types from './types';

const INITIAL_STATE = {
  journeys: {
    data: [],
    error: null,
    loading: true,
  },
  model: null,
  data: {},
  error: null,
  loading: true,
};

const setModel = (state, { model }) => assoc('model', model, state);

// **********  GENERIC REDUCERS/MODIFIERS **********

const setData = (state, key, { payload }) =>
  merge(state, { [key]: { data: payload, loading: false } });

const setError = (state, key, { error }) => merge(state, { [key]: { error, loading: false } });

const setLoading = (state, key, { loading }) => assocPath([key, 'loading'], loading, state);

const setDataPayload = (state, { payload }) => merge(state, { data: payload, loading: false });

const setDataError = (state, { error }) => merge(state, { error, loading: false });

const setDataLoading = (state, { loading }) => merge(state, { loading });

// **********  JOURNEYS **********

const setJourneys = (state, action) => setData(state, 'journeys', action);
const setJourneysError = (state, action) => setError(state, 'journeys', action);
const setJourneysLoading = (state, action) => setLoading(state, 'journeys', action);

// **********  HIGHLIGHTED BRANDS **********

const setHighlightedBrands = (state, { payload }) => {
  const highlightedBrands = {
    brands: [...state.data.highlightedBrands.brands, ...payload.brands],
    links: payload.links,
  };

  return assocPath(['data', 'highlightedBrands'], highlightedBrands, state);
};

const reducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case types.SET_MODEL: {
      return setModel(state, action);
    }
    case types.FETCH_DATA_FAILURE: {
      return setDataError(state, action);
    }
    case types.FETCH_DATA_REQUEST: {
      return setDataLoading(state, { loading: true });
    }
    case types.FETCH_DATA_SUCCESS: {
      return setDataPayload(state, action);
    }
    case types.TOGGLE_DATA_LOADING: {
      return setDataLoading(state, { loading: !state.loading });
    }
    case types.GET_JOURNEYS_FAILURE: {
      return setJourneysError(state, action);
    }
    case types.GET_JOURNEYS_REQUEST: {
      return setJourneysLoading(state, { loading: true });
    }
    case types.GET_JOURNEYS_SUCCESS: {
      return setJourneys(state, action);
    }
    case types.TOGGLE_JOURNEYS_LOADING: {
      return setJourneysLoading(state, { loading: !state.journeys.loading });
    }
    case types.PAGINATE_HIGHLIGHTED_BRANDS_SUCCESS: {
      return setHighlightedBrands(state, action);
    }
    default: {
      return state;
    }
  }
};

export default reducer;
