import moment from 'moment';
import { isEmpty } from 'ramda';

const keyToDate = key => moment(key.split('T')[0]);
const keyDateToLabel = date => date.format('MMM D');
const formatNumber = val => Number(val.toFixed(2));
const reduceData = (buckets, chart) =>
  buckets.reduce(
    (aggregation, bucket) => {
      const keyAsDate = keyToDate(bucket.keyAsString);
      const endDate = keyAsDate.clone().add(1, 'days');
      const title = `${keyAsDate.format('M/D/YY')} - ${endDate.format('M/D/YY')}`;

      aggregation.labels.push(keyDateToLabel(keyAsDate));
      aggregation.data.push(formatNumber((bucket[chart].value || 0)));
      aggregation.titles.push(title);

      return aggregation;
    },
    { labels: [], data: [], titles: [] },
  );

export default function toChartData(buckets, chart) {
  const chartData = isEmpty(buckets) ? {} : reduceData(buckets, chart);
  const max = Math.max(...(chartData.data || [0]));

  return {
    ...chartData,
    max,
  };
}
