import React from 'react';
import PropTypes from 'prop-types';
import styled from "@emotion/styled";

import { Flex, Text } from '../../../../../../mc-ui';

const Bar = styled("div")(({ color, size, theme }) => ({
  backgroundColor: theme.colors.dark6,
  borderRadius: '4px',
  height: '48px',
  marginBottom: '4px',
  position: 'relative',
  width: '8px',
  '&::after': {
    backgroundColor: theme.colors[color],
    borderRadius: '4px',
    bottom: 0,
    content: '""',
    display: 'block',
    height: `${size}%`,
    position: 'absolute',
    width: '8px',
  },
}));

const StyledText = styled(Text)(({ size, theme }) => ({
  color: size === 0 ? theme.colors.dark01 : theme.colors.dark06,
  fontFamily: theme.font.family,
  fontWeight: 'bold',
  fontSize: '10px',
  lineHeight: '1.56em',
  textAlign: 'center',
  letterSpacing: '0.12em',
}));

const GroupsChart = ({ data: { perentageBrands, promotionRate } }) => (
  <Flex justifyContent="space-between" css={{ marginTop: '8px' }}>
    <Flex direction="column" alignItems="center" css={{ width: '32px' }}>
      <Bar color="blue" size={perentageBrands} />
      <StyledText size={perentageBrands}>{perentageBrands}%</StyledText>
    </Flex>

    <Flex direction="column" alignItems="center" css={{ width: '32px' }}>
      <Bar color="tealMedium" size={promotionRate} />
      <StyledText size={promotionRate}>{promotionRate}%</StyledText>
    </Flex>
  </Flex>
);

GroupsChart.propTypes = {
  data: PropTypes.shape({
    perentageBrands: PropTypes.number,
    promotionRate: PropTypes.number,
  }).isRequired,
};

export default GroupsChart;
