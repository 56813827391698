import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';

import { teamsSelectors } from '../../modules/teams';

import { Box, Button, Flex, Heading } from '../mc-ui';

import Restricted from './Restricted';
import ShowTeam from './ShowTeam';

function TeamManagement({ team }) {
  return (
    <Box backgroundColor="white" borderRadius="4px" marginTop="1em" padding="32px">
      <Flex alignItems="flex-start" justifyContent="space-between" marginBottom="24px">
        <Heading as="h3" color="dark08" fontSize="24px" fontWeight="300" lineHeight="32px">
          {team ? 'Team management' : 'MailCharts Enterprise'}
        </Heading>

        <Button
          as={Link}
          to="https://knowledge.validity.com/hc/en-us/sections/6839689729819-FAQ"
          target="_blank"
          variant="secondary"
          css={{
            alignItems: 'center',
            display: 'inline-flex',
            height: '32px',
            justifyContent: 'center',
            lineHeight: '32px',
            margin: '0',
            padding: '0',
            width: '56px',
          }}
        >
          FAQ
        </Button>
      </Flex>

      {team ? <ShowTeam team={team} /> : <Restricted />}
    </Box>
  );
}

TeamManagement.defaultProps = {
  team: null,
};

TeamManagement.propTypes = {
  team: PropTypes.shape({}),
};

const mapStateToProps = state => ({
  team: teamsSelectors.teamSelector(state),
});

export default connect(mapStateToProps)(TeamManagement);
