import React from 'react';

import { Text, Tooltip } from '../../mc-ui';
import WarningCircleIcon from '../../mc-ui/icons/WarningCircleIcon';

export default function SubscribeToWeeklyRollUpTooltip() {
  return (
    <Tooltip
      element={<WarningCircleIcon fillOpacity={0.6} height="1em" width="1em" />}
      id="subscribeToWeeklyRollUpTooltip"
      css={{
        fontSize: '22px',
        height: '22px',
        marginRight: '8px',
        width: '22px',
        '& > span': {
          display: 'inline-block',
          height: '22px',
          margin: '0',
          width: '22px',
        },
        '& [data-id="tooltip"]': {
          marginLeft: '0 !important',
        },
      }}
      dark
    >
      <Text>
        You’ll receive a weekly roll-up email with a data summary from this group. Use the summary
        to stay informed, prepare for meetings, and share insights with your team.
      </Text>
    </Tooltip>
  );
}
