/* eslint-disable react/prop-types */
import React from 'react';
import { components } from 'react-select';
import styled from "@emotion/styled";

import { Text } from '../../../mc-ui';

const StyledText = styled(Text)(({ theme }) => ({
  color: theme.colors.dark08,
  fontSize: '14px',
  lineHeight: '156%',
}));

const SingleValue = ({ children, modelName, ...props }) => {
  let comparisonName = children;
  let comparingToName = modelName;

  if (children === 'Mailcharts Index') {
    comparisonName = modelName;
    comparingToName = children;
  }

  return (
    <components.SingleValue {...props}>
      <StyledText>
        <strong>{comparisonName}</strong> vs {comparingToName}
      </StyledText>
    </components.SingleValue>
  );
};

export const singleValueWithModel = model => {
  const modelName = model.attributes ? model.attributes.name : null;

  return props => <SingleValue modelName={modelName} {...props} />;
};

export default SingleValue;
