import moment from 'moment';
import { createSelector } from 'reselect';

const getUser = state => state.user;
const getTeamState = state => state.teams;

// NOTE: Getting team from state.user in order to have a more complete data
export const teamSelector = createSelector(getUser, user =>
  user.included.find(i => i.type === 'teams'),
);

export const loadingSelector = createSelector(getTeamState, team => !!team.loading);

export const teamDataSelector = createSelector(getTeamState, team => team.data[0]);

export const membersSelector = createSelector(
  teamDataSelector,
  teamData => (teamData ? teamData.relationships.members.data : []),
);

export const membersMetaSelector = createSelector(
  teamDataSelector,
  teamData => (teamData ? teamData.relationships.members.meta : {}),
);

export const isAdminSelector = createSelector(teamSelector, team => {
  if (team) {
    return team.attributes.role === 'admin';
  }

  return false;
});

export const isOwnerSelector = createSelector(teamSelector, team => {
  if (team) {
    return team.attributes.role === 'owner';
  }

  return false;
});

export const subscriptionSelector = createSelector(
  teamDataSelector,
  teamData => (teamData ? teamData.relationships.subscriptions.data[0] : { attributes: {} }),
);

export const subscriptionPriceLoadingSelector = createSelector(
  getTeamState,
  team => team.subscription.calculate.result.loading,
);

export const subscriptionPriceSelector = createSelector(getTeamState, team => {
  const [data] = team.subscription.calculate.result.data;
  return data;
});

export const isSubscriptionAutomaticallyChargedSelector = createSelector(
  subscriptionSelector,
  subscription => subscription.attributes.collection_method === 'charge_automatically',
);

export const isSubscriptionInvoicedSelector = createSelector(
  subscriptionSelector,
  subscription => subscription.attributes.collection_method === 'send_invoice',
);

export const isSubscriptionExemptedSelector = createSelector(
  subscriptionSelector,
  subscription => subscription.attributes.collection_method === 'exempt',
);

export const planExpiredSelector = createSelector(teamSelector, team => {
  if (team) {
    return moment(team.attributes.current_period_end_at).isSameOrBefore(moment());
  }
  return false;
});

export const isCancelingSubscriptionSelector = createSelector(
  subscriptionSelector,
  subscription => (subscription ? !!subscription.attributes.stripe_cancel_at_period_end : false),
);

export const isAnOldPlanSelector = createSelector(
  subscriptionSelector,
  subscription => (subscription ? subscription.attributes.plan_version !== 'v3' : false),
);

export const hasSmsAddonSelector = createSelector(
  subscriptionSelector,
  subscription => (subscription && subscription.attributes.sms_addon != undefined ? subscription.attributes.sms_addon : false),
);

export const isActiveSelector = createSelector(
  teamSelector,
  team => (team && team.attributes.account_status === 'active') || false,
);

export const isCanceledSelector = createSelector(
  teamSelector,
  team => (team && team.attributes.account_status === 'canceled') || false,
);

export const isNewPlanSelector = createSelector(
  subscriptionSelector,
  subscription => (subscription ? subscription.attributes.plan_version === 'v3' : false),
);

export const isFreeSelector = createSelector(
  teamSelector,
  team => !team || ['free', 'past_due'].includes(team.attributes.account_status),
);

export const isProSelector = createSelector(
  teamSelector,
  team => (team && ['active', 'trialing'].includes(team.attributes.account_status)) || false,
);

export const isMembershipActiveSelector = createSelector(
  teamSelector,
  isActiveSelector,
  (team, isActive) => (team && isActive && team.attributes.status === 'active') || false,
);

export const getSetupIntent = createSelector(
  getTeamState,
  teamState => teamState.setupIntent
)

export const getPaymentInfo = createSelector(
  getTeamState,
  teamState => teamState.paymentInfo
)
