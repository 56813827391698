import * as React from 'react';

function SvgAccessCustomerJourneys(props) {
  return (
    <svg width={367} height={224} fill="none" {...props}>
      <path
        d="M29 95.883c11.226.569 16.427-3.094 19.778-14.11C52.079 70.917 52.05 57.912 57 47.66c3.608-7.474 10.357-2.817 13.222 2.445 14.719 27.023 13.822 59.958 8.834 89.389-2.6 15.337-10.453 43.885 8.555 52.333 21.158 9.403 38.32-9.081 45.722-27.167 5.625-13.742 7.223-28.936 7.223-43.666 0-17.062-4.01-29.71-11.612-44.778-5.787-11.471-10.848-23.244-12.333-36.111-.471-4.08-2.196-14.063 2.5-16.778 7.028-4.063 20.127-3.435 27.833-3.278 11.668.238 24.339 3.975 32.5 12.833 8.568 9.3 9.63 25.737 9.667 37.556.068 21.67-11.122 38.791-25.167 54.722-12.664 14.366-29.327 22.436-45.5 32.056-9.343 5.558-21.86 11.912-25.555 23-4.906 14.719 10.592 25.291 23.333 27 34.137 4.579 67.196-12.557 82.889-43.667 6.267-12.424 15.535-38.838 2.445-49.889-6.474-5.464-15.117-7.971-23.223-9.778-14.628-3.26-29.502-5.477-44.055-9.055-26.096-6.417-73.826-22.63-62.39-58.945 3.52-11.172 14.99-18.748 25.112-23.11 24.725-10.66 55.167-14.997 81.722-9.167 32.539 7.142 48.862 39.882 52.5 70.61 2.121 17.917-.695 34.268-8.222 50.445-4.238 9.11-8.624 17.444-10.389 27.445-.506 2.87-1.714 8.422-.167 11.222 2.852 5.16 11.375 7.967 16.5 9.889 20.815 7.805 39.424-1.996 52.834-17.889 9.06-10.738 25.756-32.657 19.389-48.389-2.401-5.931-10.344-11.543-15.778-14.389-10.209-5.347-22.371-6.95-33.722-7.444-13.485-.587-27.044.43-40.5-.778-7.361-.66-15.833-1.46-20.834-7.556-8.792-10.716-10.172-23.98-4.111-36.777 8.341-17.61 24.954-27.978 43.889-28.112 6.571-.046 14.121-.838 20.111 2.445 8.325 4.563 15.464 13.669 20.222 21.722 9.979 16.886 16.105 41.112 12.945 60.778-1.161 7.22-4.389 13.68-4.389 21.055 0 6.423 13.006 4.105 17 3.945 11.585-.464 18.672-7.693 29-10.945 3.84-1.209 11-3.297 11 2"
        stroke="#091E42"
        strokeOpacity={0.4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx={24} cy={98.884} r={24} fill="#0083F1" />
      <path
        d="M31.427 95.37v-2.656a.832.832 0 00-.83-.83h-.248l-14.44 4.232c-.166-.332-.498-.498-.913-.498-.498 0-.996.415-.996.996v3.983c0 .581.498.996.996.996.415 0 .747-.249.913-.581l3.9 1.162v.332c0 1.992 1.66 3.569 3.652 3.569 1.327 0 2.572-.747 3.153-1.909l3.817 1.079h.25c.414 0 .83-.332.83-.83v-2.739c1.41-.332 2.489-1.659 2.489-3.153s-1.162-2.822-2.573-3.154zm-8.05 8.962c-.912 0-1.66-.664-1.825-1.577l3.153.996c-.332.415-.83.581-1.327.581zm8.05-4.481c-.249.166-.497.249-.747.249h-.995v3.153l-3.569-.996-5.477-1.66-4.564-1.327v-1.328l13.61-3.983v3.07h.995c.25 0 .581.083.747.249.415.249.747.747.747 1.245 0 .498-.249 1.079-.747 1.328z"
        fill="#fff"
      />
      <circle cx={343} cy={98.884} r={24} fill="#FF823B" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M338 89.884a1 1 0 012 0h6a1 1 0 012 0h.333A4.659 4.659 0 01353 94.55v9a4.659 4.659 0 01-4.667 4.667h-10.666c-2.584 0-4.667-2.083-4.667-4.583V94.55a4.659 4.659 0 014.667-4.666H338zm8 1.916v.084a1 1 0 002 0V91.8h.333c1.5 0 2.75 1.25 2.75 2.75h-16.166c0-1.583 1.25-2.75 2.75-2.75H338v.084a1 1 0 002 0V91.8h6zm2.333 14.5h-10.666c-1.5 0-2.75-1.25-2.75-2.75v-7.166h16.25v7.166c-.084 1.584-1.334 2.75-2.834 2.75z"
        fill="#fff"
      />
      <path d="M346.4 100.784l-5.8-2.9v5.8l5.8-2.9z" fill="#fff" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M95 27.884a9 9 0 100-18 9 9 0 000 18zm-1.673-4.687l6.543-6.918-1.145-1.082-5.38 5.688-2.92-3.185-1.162 1.065 4.064 4.432zM135 220.884a9 9 0 100-18 9 9 0 000 18zm-1.673-4.687l6.543-6.918-1.145-1.082-5.38 5.688-2.921-3.185-1.161 1.065 4.064 4.432zM234 65.884a9 9 0 009-9 9 9 0 00-9-9 9 9 0 00-9 9 9 9 0 009 9zm-1.673-4.687l6.543-6.918-1.145-1.082-5.38 5.688-2.921-3.185-1.161 1.065 4.064 4.432zM262 25.884a9 9 0 009-9 9 9 0 00-9-9 9 9 0 00-9 9 9 9 0 009 9zm-1.673-4.687l6.543-6.918-1.145-1.082-5.38 5.688-2.921-3.185-1.161 1.065 4.064 4.432zM262 184.884a9 9 0 100-18 9 9 0 000 18zm-1.673-4.687l6.543-6.918-1.145-1.082-5.38 5.688-2.921-3.185-1.161 1.065 4.064 4.432z"
        fill="#FF823B"
      />
    </svg>
  );
}

export default SvgAccessCustomerJourneys;

