import React from 'react';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import { connect } from 'react-redux';
import { isEmpty } from 'ramda';
import { withTheme } from '@emotion/react';

import { sendingBehaviorSelectors as selectors } from '../../../../modules/newReports/sendingBehavior';

import { Box, Flex, Text } from '../../../mc-ui';

const DaysChart = ({ data: { sentAtDayOfWeek }, theme }) => {
  if (isEmpty(sentAtDayOfWeek)) {
    return (
      <Flex alignItems="center" height="324px" justifyContent="center">
        <Text>No data</Text>
      </Flex>
    );
  }

  const colors = [
    theme.colors.blueDark,
    theme.colors.blueMedium,
    theme.colors.tealMedium,
    theme.colors.tealDark,
    theme.colors.dark5,
    theme.colors.dark3,
    theme.colors.dark1,
  ];

  const data = {
    labels: sentAtDayOfWeek.map(day => day.key),
    datasets: [
      {
        backgroundColor: colors,
        borderWidth: 1,
        data: sentAtDayOfWeek.map(day => day.value),
        hoverBackgroundColor: colors,
      },
    ],
  };

  return (
    <Flex alignItems="center" justifyContent="space-between">
      <Flex alignItems="center" height="324px" width="215px">
        <Doughnut
          data={data}
          height={100}
          width={100}
          options={{
            legend: {
              display: false,
            },
            tooltips: { enabled: false },
          }}
        />
      </Flex>
      <Box css={{ width: '100px' }}>
        {sentAtDayOfWeek.map((day, index) => (
          <Flex
            key={day.key}
            alignItems="center"
            justifyContent="space-between"
            css={{ marginTop: index === 0 ? 0 : '20px' }}
          >
            <Box
              css={{
                backgroundColor: colors[index],
                borderRadius: '50%',
                height: '16px',
                width: '16px',
              }}
            />
            <Text
              css={{
                color: theme.colors.dark08,
                fontSize: '12px',
                fontWeight: '500',
                lineHeight: '1.25em',
                letterSpacing: '0.02em',
                textTransform: 'uppercase',
              }}
            >
              {day.key.slice(0, 3)}
            </Text>
            <Text
              css={{
                color: theme.colors.dark08,
                fontSize: '12px',
                fontWeight: '500',
                lineHeight: '1.25em',
                letterSpacing: '0.02em',
              }}
            >
              {day.value}%
            </Text>
          </Flex>
        ))}
      </Box>
    </Flex>
  );
};

DaysChart.propTypes = {
  data: PropTypes.shape({
    sentAtDayOfWeek: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.number,
      }),
    ),
  }).isRequired,
  theme: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state, { model }) => {
  if (!model.id) return { loading: true };

  const { endDate, startDate } = state.newReports.dates;

  return {
    data: selectors.getData(model.id, model.type, startDate, endDate)(state),
  };
};

export default connect(mapStateToProps)(withTheme(DaysChart));
