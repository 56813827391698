import React from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import { connect } from 'react-redux';

import { newReportsActions } from '../../modules/newReports';

import { DateIntervalSelect } from '../mc-ui';

class DateSelect extends React.Component {
  componentDidMount() {
    const query = qs.parse(this.props.router.location.search.replace('?', ''));
    this.props.initialize(query);
    this.updateQuery();
  }

  componentDidUpdate(prevProps) {
    const { endDate, startDate } = this.props;
    const { endDate: prevEndDate, startDate: prevStartDate } = prevProps;

    if (endDate && startDate && prevEndDate && prevStartDate) {
      if (endDate !== prevEndDate || startDate !== prevStartDate) {
        this.updateQuery();
      }
    }
  }

  selectInterval = interval => {
    this.props.updateInterval(interval);
  };

  handleOnChange = ({ endDate, startDate }) => {
    this.props.changeDates(endDate.format('YYYY-MM-DD'), startDate.format('YYYY-MM-DD'));
  };

  updateQuery = () => {
    const { endDate, restricted, router, startDate } = this.props;
    if (restricted || (!endDate || !startDate)) return;
    const query = qs.parse(router.location.search.replace('?', ''));

    query.end_date = endDate;
    query.start_date = startDate;

    router.push({
      pathname: router.location.pathname,
      search: `?${qs.stringify(query)}`,
    });
  };

  render() {
    const { endDate, interval, intervalLabel, restricted, startDate, user } = this.props;

    return (
      <DateIntervalSelect
        restricted={restricted}
        endDate={endDate}
        intervals={user.meta.intervals}
        label={intervalLabel}
        onChange={this.handleOnChange}
        onSelect={this.selectInterval}
        selectedInterval={interval}
        startDate={startDate}
      />
    );
  }
}

DateSelect.defaultProps = {
  endDate: null,
  interval: null,
  startDate: null,
  restricted: false,
};

DateSelect.propTypes = {
  changeDates: PropTypes.func.isRequired,
  endDate: PropTypes.string,
  initialize: PropTypes.func.isRequired,
  interval: PropTypes.string,
  intervalLabel: PropTypes.string.isRequired,
  router: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
  restricted: PropTypes.bool,
  startDate: PropTypes.string,
  updateInterval: PropTypes.func.isRequired,
  user: PropTypes.shape({
    meta: PropTypes.shape({
      intervals: PropTypes.array,
    }),
  }).isRequired,
};

const mapStateToProps = state => ({
  user: state.user,
  endDate: state.newReports.dates.endDate,
  interval: state.newReports.dates.interval,
  intervalLabel: state.newReports.dates.intervalLabel,
  startDate: state.newReports.dates.startDate,
});

const mapDispatchToProps = dispatch => ({
  changeDates: (endDate, startDate) => dispatch(newReportsActions.changeDates(endDate, startDate)),
  initialize: query => dispatch(newReportsActions.initializeDates(query)),
  updateInterval: interval => dispatch(newReportsActions.updateInterval(interval)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DateSelect);
