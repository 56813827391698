import * as types from './types';

export const INITIAL_STATE = {
  open: null,
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.CLOSE: {
      return INITIAL_STATE;
    }
    case types.OPEN: {
      return { open: `${action.contentType}-${action.model.id}` };
    }
    default: {
      return state;
    }
  }
}
