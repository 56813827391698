import api from '../../lib/api';

export const createGroup = async data => {
  const response = await api.post('/v3/groups', { group: { name: data.name } });
  return response;
};

export const deleteGroup = async id => {
  await api.delete(`/v2/groups/${id}`);
};

export const updateGroup = async (id, data) => {
  const response = await api.patch(`/v2/groups/${id}`, data);
  return response;
};

export const fetchUserGroups = async (params = {}) => {
  const response = await api.get(`/v3/user/groups`, { params });
  return response;
};

export const fetchTeamGroups = async (teamId, params) => {
  const response = await api.get(`/v3/teams/${teamId}/groups`, { params });
  return response;
};

export const shareWithTeam = async (teamId, groupId) => {
  const response = await api.post(`/v3/teams/${teamId}/groups`, { group_id: groupId });
  return response;
};

export const deleteTeamGroup = async (teamId, groupId) => {
  const response = await api.delete(`/v3/teams/${teamId}/groups/${groupId}`);
  return response;
};

export const updateTeamGroup = async (teamId, id, data) => {
  const response = await api.patch(`/v3/teams/${teamId}/groups/${id}`, data);
  return response;
};

export const addBrandsToGroup = async (groupId, brandIds) => {
  const response = await api.post(`/v3/groups/${groupId}/companies`, { company_id: brandIds });
  return response;
};

export const removeBrandsToGroup = async (groupId, brandIds) => {
  const response = await api({
    method: 'delete',
    url: `/v3/groups/${groupId}/companies`,
    data: { company_id: brandIds },
  });
  return response;
};
