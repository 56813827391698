/* eslint-disable prefer-const */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withTheme } from '@emotion/react';

import { Box } from '../../../../mc-ui';

import Header from '../../../../newReports/Calendars/Calendar/Header';
import Row from './Row';

const Calendar = ({ data, model, theme, ...props }) => {
  const selectedDate = moment(new Date());
  const days = [0, 1, 2, 3, 4, 5, 6].map(idx => {
    const date = moment(new Date())
      .subtract(1, 'week')
      .startOf('week')
      .add(idx, 'days')
      .format('YYYY-MM-DD');
    const keyAsString = `${date}T00:00:00.000Z`;
    const brands = { avg: 0 };
    const promotional = { avg: 0 };
    const frequency = { value: 0 };
    const day = data.days.find(d => d.key_as_string.split('T')[0] === date);
    return day || { key_as_string: keyAsString, brands, promotional, frequency };
  });

  return (
    <Box css={{ border: `1px solid ${theme.colors.dark5}` }}>
      <Header />
      <Row days={days} model={model} selectedDate={selectedDate} weeks={data.weeks} {...props} />
    </Box>
  );
};

Calendar.propTypes = {
  data: PropTypes.shape({
    days: PropTypes.arrayOf(PropTypes.shape({})),
    weeks: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  model: PropTypes.shape({ id: PropTypes.string, type: PropTypes.string }).isRequired,
  theme: PropTypes.shape({}).isRequired,
};

export default withTheme(Calendar);
