import React from 'react';
import styled from "@emotion/styled";

import { Link } from 'react-router';

export const CollectionTypeButtons = styled("div")({
  display: 'flex',
});

export const CollectionTypeButton = styled(Link, {
  shouldForwardProp: prop => prop !== 'active'
})(
  ({ active, theme }) => ({
    alignItems: 'center',
    backgroundColor: active ? theme.colors.dark1 : theme.colors.white,
    border: '1px solid transparent',
    borderColor: active ? theme.colors.dark1 : theme.colors.dark5,
    boxSizing: 'border-box',
    color: active ? theme.colors.white : theme.colors.dark06,
    display: 'inline-flex',
    fontFamily: theme.font.family,
    fontSize: '14px',
    fontWeight: '500',
    height: '32px',
    justifyContent: 'center',
    margin: '0',
    padding: '0 12px',
    textDecoration: 'none',
  }),
);

CollectionTypeButtons.MyButton = props => (
  <CollectionTypeButton {...props} css={{ borderRadius: '4px 0 0 4px' }} />
);

CollectionTypeButtons.TeamButton = props => (
  <CollectionTypeButton {...props} css={{ borderRadius: '0 4px 4px 0' }} />
);
