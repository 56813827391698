import styled from "@emotion/styled";
import { Link } from 'react-router';

export const Control = styled("div")(({ theme }) => ({
  backgroundColor: theme.colors.white,
  border: `1px solid ${theme.colors.dark5}`,
  borderRadius: '4px',
  boxSizing: 'border-box',
  cursor: 'pointer',
  height: '23px',
  lineHeight: '23px',
  margin: '0',
  marginLeft: '8px',
  position: 'relative',
}));

export const Item = styled(Link)(({ theme }) => ({
  alignItems: 'center',
  boxSizing: 'border-box',
  color: theme.colors.dark08,
  display: 'flex',
  flexWrap: 'nowrap',
  fontFamily: theme.font.family,
  fontSize: '14px',
  height: '32px',
  lineHeight: '32px',
  padding: '5px 12px',
  textDecoration: 'none',
  whiteSpace: 'nowrap',
  '&:hover': {
    backgroundColor: theme.colors.dark6,
  },
}));

export const Label = styled("div")(({ opened = false, theme }) => ({
  alignItems: 'center',
  color: theme.colors.dark1,
  cursor: 'pointer',
  display: 'flex',
  padding: '0 8px',
  '& > svg': {
    cursor: 'pointer',
  },
  '& > span': {
    color: theme.colors.dark08,
    cursor: 'pointer',
    fontFamily: theme.font.family,
    fontSize: '12px',
    fontWeight: '500',
    height: '23px',
    letterSpacing: '0.02em',
    lineHeight: '23px',
    margin: '0 8px',
  },
  '& .indicator': {
    transform: opened ? 'rotate(180deg)' : null,
    transition: 'transform 0.5s ease',
  },
}));

export const Text = styled("p")(({ theme }) => ({
  alignItems: 'center',
  boxSizing: 'border-box',
  color: theme.colors.dark08,
  display: 'flex',
  fontFamily: theme.font.family,
  fontSize: '14px',
  height: '32px',
  lineHeight: '32px',
  margin: 0,
  padding: '5px 12px',
}));

export const Wrapper = styled("div")(({ centered = false, theme }) => {
  const verticalPosition = centered
    ? {
        left: '50%',
        transform: 'translateX(-50%)',
      }
    : { left: 0 };

  return {
    backgroundColor: theme.colors.white,
    borderRadius: '4px',
    boxSizing: 'border-box',
    filter: 'drop-shadow(0px 1px 4px rgba(9, 30, 66, 0.2))',
    maxHeight: '272px',
    padding: '0 4px',
    position: 'absolute',
    top: '23px',
    zIndex: '999999',
    ...verticalPosition,
    '& > div': {
      boxSizing: 'border-box',
      maxHeight: '272px',
      minWidth: '200px',
      overflowY: 'scroll',
      padding: '8px 0',
      scrollbarWidth: 'thin',
      '&::-webkit-scrollbar': {
        WebkitAppearance: 'none',
        width: '6px',
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '4px',
        backgroundColor: theme.colors.dark01,
      },
    },
  };
});
