export const palette = {
  black: '#000000',
  gray6: '#646464',
  gray8: '#8b8b8b',
  grayA: '#A0AEBA',
  grayD: '#D4D9DD',
  grayE: '#e6ebef',
  grayF: '#F3F5F7',
  white: '#FFFFFF',
  red: '#dd2523',
  orange: '#FF7A2B',
  teal: '#21D6C5',
  blue: '#0379D5',
  lavender: '#9E6ACC',
};

export const getColorForEmail = (description) => {
  if (/purchaser/gi.test(description)) return palette.teal;
  else if (/abandon/gi.test(description)) return palette.lavender;
  else if (/generic/gi.test(description)) return palette.blue;
  else if (/newsletter/gi.test(description)) return palette.blue;
  return palette.gray6;
};

export default {
  palette,
  getColorForEmail,
};
