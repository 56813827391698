import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router';
import { connect } from 'react-redux';

import { userSelectors } from '../../modules/user';

import { Button, Flex, SubMenu } from '../mc-ui';

import CalendarIcon from '../mc-ui/icons/CalendarIcon';
import ChartIcon from '../mc-ui/icons/ChartIcon';
import EmailIcon from '../mc-ui/icons/EmailIcon';
import PromoIcon from '../mc-ui/icons/PromoIcon';
import RoundListIcon from '../mc-ui/icons/RoundListIcon';

import SwitchToClassic from './reportsMenu/SwitchToClassic';

const getDefaultInterval = intervals => intervals.find(i => i.default);

const ReportsMenu = ({ dates, isUserRestricted, rootPath, user, userIntervals }, { router }) => {
  const classic =
    !isUserRestricted && moment(user.attributes.created_at).isBefore(moment('2020-07-10'));

  const interval = getDefaultInterval(userIntervals);
  let { end_date: endDate, start_date: startDate } = router.location.query;
  let calendarDate = moment(startDate)
    .startOf('month')
    .format('YYYY-MM-DD');
  if ((!endDate || !startDate) && dates.endDate && dates.startDate) {
    endDate = dates.endDate; // eslint-disable-line prefer-destructuring
    startDate = dates.startDate; // eslint-disable-line prefer-destructuring
    calendarDate = moment(startDate)
      .startOf('month')
      .format('YYYY-MM-DD');
  } else if ((!endDate || !startDate) && interval.end_date && interval.start_date) {
    endDate = interval.end_date;
    startDate = interval.start_date;
    calendarDate = moment(endDate)
      .startOf('month')
      .format('YYYY-MM-DD');
  }

  const query = isUserRestricted ? {} : { end_date: endDate, start_date: startDate };
  const calendarsQuery = isUserRestricted ? {} : { start_date: calendarDate };

  return (
    <div>
      <SubMenu>
        <SubMenu.Header>Reports</SubMenu.Header>
        <SubMenu.Link
          to={{
            pathname: `${rootPath}/sending-behavior`,
            query,
          }}
        >
          <EmailIcon height="20" width="20" fillOpacity={1} />
          Sending behavior
        </SubMenu.Link>
        <SubMenu.Link
          to={{
            pathname: `${rootPath}/subject-lines`,
            query,
          }}
        >
          <RoundListIcon height="20" width="20" fillOpacity={1} />
          Subject lines
        </SubMenu.Link>
        <SubMenu.Link
          to={{
            pathname: `${rootPath}/promotions`,
            query,
          }}
        >
          <PromoIcon height="20" width="20" fillOpacity={1} />
          Promotions
        </SubMenu.Link>
        <SubMenu.Link to={`${rootPath}/benchmarks`}>
          <ChartIcon height="20" width="20" fillOpacity={1} />
          Benchmarks
        </SubMenu.Link>
        <SubMenu.Link to={{ pathname: `${rootPath}/calendars`, query: calendarsQuery }}>
          <CalendarIcon height="20" width="20" fillOpacity={1} />
          Calendars
        </SubMenu.Link>
      </SubMenu>
      {isUserRestricted && (
        <Flex alignItems="center" justifyContent="center">
          <Button
            as={Link}
            to="/manage/plan"
            variant="purple"
            css={{
              fontSize: '12px',
              padding: '3px 0px',
              width: '100%',
            }}
          >
            Upgrade now
          </Button>
        </Flex>
      )}
      {classic && <SwitchToClassic rootPath={rootPath} />}
    </div>
  );
};

ReportsMenu.contextTypes = {
  router: PropTypes.object,
};

ReportsMenu.propTypes = {
  dates: PropTypes.shape({
    endDate: PropTypes.string,
    startDate: PropTypes.string,
  }).isRequired,
  isUserRestricted: PropTypes.bool.isRequired,
  rootPath: PropTypes.string.isRequired,
  user: PropTypes.shape({
    attributes: PropTypes.shape({
      created_at: PropTypes.string,
    }),
  }).isRequired,
  userIntervals: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const mapStateToProps = state => ({
  isUserRestricted: userSelectors.isUserRestrictedSelector(state),
  dates: {
    endDate: state.newReports.dates.endDate,
    startDate: state.newReports.dates.startDate,
  },
  user: state.user.data[0],
  userIntervals: state.user.meta.intervals,
});

export default connect(mapStateToProps)(ReportsMenu);
