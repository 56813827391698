import React from 'react';
import PropTypes from 'prop-types';
import { palette } from '../lib/color';

const transformTypeIdToLabel = (journeyType) => {
  let label = journeyType.replace('_', ' ');
  // Capitalize first word
  label = label.charAt(0).toUpperCase() + label.slice(1);
  return label;
};

const styles = {
  form: {
    position: 'relative',
  },
  discWrapper: {
    position: 'absolute',
    top: 9,
    left: 11,
    height: 10,
    width: 10,
    borderRadius: '50%',
  },
  select: {
    fontSize: `${12 / 16}em`,
    paddingRight: 25,
    paddingLeft: 28,
  },
};

const JourneyTypePicker = ({
  index, journeyTypes, selectedJourneyTypeId, selectedJourneyColor, disabledJourneyTypeIds,
  handleChange, disabled,
}) => {
  let options = [
    <option key="empty" value="" disabled>Pick journey type...</option>,
  ];

  options = options.concat(journeyTypes.map((journeyType) => {
    return (
      <option
        value={journeyType.id}
        key={journeyType.id}
        disabled={disabledJourneyTypeIds.indexOf(journeyType.id) !== -1}
      >
        {transformTypeIdToLabel(journeyType.id)}
      </option>
    );
  }));

  return (
    <form className="pure-form" style={styles.form}>
      <div style={{ ...styles.discWrapper, background: selectedJourneyColor || palette.grayA }}></div>
      <select
        style={{ ...styles.select, color: selectedJourneyColor || palette.grayA }}
        value={selectedJourneyTypeId}
        onChange={(e) => { handleChange(e, index); }}
        disabled={disabled}
      >
        {options}
      </select>
    </form>
  );
};

JourneyTypePicker.propTypes = {
  index: PropTypes.number.isRequired,
  journeyTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedJourneyTypeId: PropTypes.string,
  selectedJourneyColor: PropTypes.string,
  disabledJourneyTypeIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

JourneyTypePicker.defaultProps = {
  selectedJourneyTypeId: null,
  selectedJourneyColor: null,
  disabled: false,
};

export default JourneyTypePicker;
