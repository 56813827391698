import * as React from 'react';
import PropTypes from 'prop-types';

function CheckSmallIcon({ fillOpacity, ...props }) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.87 9.396l-6.543 6.917-4.064-4.431 1.161-1.066 2.92 3.185 5.381-5.688 1.145 1.083z"
        fill="currentColor"
        fillOpacity={fillOpacity}
      />
    </svg>
  );
}

CheckSmallIcon.propTypes = {
  fillOpacity: PropTypes.number,
};

CheckSmallIcon.defaultProps = {
  fillOpacity: 1,
};

export default CheckSmallIcon;
