const INITIAL_STATE = { journeys: { loading: false, data: [], meta: { journey_types: [] } } };

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'REQUEST_NAV_JOURNEYS':
      return { ...state, journeys: { ...state.journeys, loading: true } };
    case 'RECEIVE_NAV_JOURNEYS':
      return { ...state, journeys: { loading: false, ...action.data } };
    default:
      return state;
  }
};
