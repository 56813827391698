import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@emotion/react';

import { Box } from '../../mc-ui';

import Item from './Item';

const Navigation = ({ items, onClick, selectedItem, theme, type }) => (
  <Box
    borderRight={`1px solid ${theme.colors.dark5}`}
    direction="column"
    height="652px"
    position="relative"
    width="242px"
    css={theme.scrollBox({ height: '628px', minWidth: '242px' })}
  >
    <div>
      {items.map(item => (
        <Item
          key={`BrowseByIndustries-Navigation-${type}-${item.id}`}
          item={item}
          onClick={onClick}
          selectedItem={selectedItem}
        />
      ))}
    </div>
  </Box>
);

Navigation.defaultProps = {
  selectedItem: {},
};

Navigation.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ).isRequired,
  onClick: PropTypes.func.isRequired,
  selectedItem: PropTypes.shape({}),
  theme: PropTypes.shape({}).isRequired,
  type: PropTypes.string.isRequired,
};

export default withTheme(Navigation);
