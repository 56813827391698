import * as types from './types';

// **********  DATES **********

export const initializeDates = query => ({ type: types.INITIALIZE_DATES, query });

export const changeDates = (endDate, startDate) => ({
  type: types.CHANGE_DATES,
  endDate,
  startDate,
});

export const saveDates = (endDate, interval, intervalLabel, startDate) => ({
  type: types.SAVE_DATES,
  endDate,
  interval,
  intervalLabel,
  startDate,
});

export const updateInterval = interval => ({ type: types.UPDATE_INTERVAL, interval });

// **********  COMPARISON DATES **********

export const initializeComparisonDates = query => ({
  type: types.INITIALIZE_COMPARISON_DATES,
  query,
});

export const changeComparisonDates = (endDate, startDate) => ({
  type: types.CHANGE_COMPARISON_DATES,
  endDate,
  startDate,
});

export const saveComparisonDates = (endDate, interval, intervalLabel, startDate) => ({
  type: types.SAVE_COMPARISON_DATES,
  endDate,
  interval,
  intervalLabel,
  startDate,
});

export const updateComparisonInterval = interval => ({
  type: types.UPDATE_COMPARISON_INTERVAL,
  interval,
});

export const generateComparisonIntervals = () => ({
  type: types.GENERATE_COMPARISON_INTERVALS,
});

export const updateComparisonIntervals = intervals => ({
  type: types.UPDATE_COMPARISON_INTERVALS,
  intervals,
});
