import React from 'react';

function Welcome() {
  return (
    <div>
      <img
        style={{
          width: 100,
          margin: '0 auto',
        }}
        src="/logo.svg"
        className="pure-img"
        alt="MailCharts"
      />
      <h5>Welcome to MailCharts!</h5>
      <p style={{ fontSize: `${13 / 16}em` }}>We're getting things ready for you.</p>
    </div>
  );
}



export default Welcome;