import React from 'react';

import Body from '../Body';

import AveragePromotionRate from './averagePromotionRate';
import PromotionType from './promotionType';

const BrandContainer = props => (
  <Body>
    <AveragePromotionRate {...props} />
    <PromotionType {...props} />
  </Body>
);

export default BrandContainer;
