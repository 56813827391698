import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { teamsSelectors } from '../../../modules/teams';

import { Box, Button, Flex, Heading, Text } from '../../mc-ui';
import ChevronDownIcon from '../../mc-ui/icons/ChevronDownIcon';
import ChevronUpIcon from '../../mc-ui/icons/ChevronUpIcon';
import CheckBigIcon from '../../mc-ui/icons/CheckBigIcon';

import * as S from './styles';
import CancelProConfirmationModal from './CancelProConfirmationModal';

function boxColors(team, isCanceled, isFree, isProSelected, planExpired) {
  if (isFree || isCanceled || planExpired) {
    return {
      backgroundColor: 'blueLight',
      borderColor: 'blue',
    };
  }

  if (!team && isProSelected) {
    return {
      backgroundColor: 'blueLight',
      borderColor: 'blueLight',
    };
  }

  return {
    backgroundColor: 'white',
    borderColor: 'dark5',
  };
}

class FreeOption extends React.Component {
  state = {
    isModalOpen: false,
  };

  toggleModal = () => {
    this.setState(currentState => ({ isModalOpen: !currentState.isModalOpen }));
  };

  render() {
    const {
      isCanceled,
      isCancelingSubscription,
      isFree,
      isProSelected,
      isSubscriptionExempted,
      isSubscriptionInvoiced,
      planExpired,
      team,
      hasSmsAddon,
    } = this.props;

    if (
      team &&
      (isSubscriptionExempted || isSubscriptionInvoiced || isCancelingSubscription) &&
      !planExpired
    ) {
      return null;
    }

    const { isModalOpen } = this.state;

    const { backgroundColor, borderColor } = boxColors(
      team,
      isCanceled,
      isFree,
      isProSelected,
      planExpired,
    );

    return (
      <Box marginTop="24px"
        backgroundColor={backgroundColor}
        border="2px solid"
        borderColor={borderColor}
        borderRadius="8px"
        marginBottom="16px"
        padding="24px"
      >
        <Flex
          alignItems="center"
          justifyContent="space-between"
          marginBottom="1em"
        >
          <S.PlanChecker
            checked={isCanceled || isFree || planExpired}
            onClick={this.toggleModal}
            planName="Free"
            selected={isModalOpen}
          />

          {isFree && (
            <Heading as="h4" color="blue" fontSize="18px" fontWeight="500" lineHeight="1em">
              Current plan
            </Heading>
          )}

          {!isFree &&
            !isCanceled &&
            !planExpired && (
              <S.CancelButton onClick={this.toggleModal}>Cancel Pro plan</S.CancelButton>
          )}

          <CancelProConfirmationModal closeModal={this.toggleModal} isOpen={isModalOpen} hasSmsAddon={hasSmsAddon} />
        </Flex>

        <Flex justifyContent="space-between">
          <Box width="218px">
            <Heading
              as="h5"
              color="dark08"
              fontSize="10px"
              fontWeight="bold"
              letterSpacing="0.12em"
              lineHeight="1.56em"
            >
              Limited Data
            </Heading>

            <Flex alignItems="flex-start" marginTop="8px">
              <Text color="purpleMedium" fontSize="24px" lineHeight="24px" margin="0 8px 0 0">
                <CheckBigIcon />
              </Text>
              <Text color="dark2">Limited access to email campaigns.</Text>
            </Flex>
          </Box>

          <Box width="218px">
            <Heading
              as="h5"
              color="dark08"
              fontSize="10px"
              fontWeight="bold"
              letterSpacing="0.12em"
              lineHeight="1.56em"
            >
              Limited Journeys
            </Heading>

            <Flex alignItems="flex-start" marginTop="8px">
              <Text color="purpleMedium" fontSize="24px" lineHeight="24px" margin="0 8px 0 0">
                <CheckBigIcon />
              </Text>
              <Text color="dark2">
                Sample weekly, unlocked Journeys.
              </Text>
            </Flex>
          </Box>

          <Box width="218px">
            <Heading
              as="h5"
              color="dark08"
              fontSize="10px"
              fontWeight="bold"
              letterSpacing="0.12em"
              lineHeight="1.56em"
            >
              SMS Campaigns
            </Heading>

            <Flex alignItems="flex-start" marginTop="8px">
              <Text color="purpleMedium" fontSize="24px" lineHeight="24px" margin="0 8px 0 0">
                <CheckBigIcon />
              </Text>
              <Text color="dark2">Currated selections of SMS Campaigns.</Text>
            </Flex>
          </Box>

          <Box width="218px">
          </Box>
        </Flex>
      </Box>
    );
  }
}

FreeOption.defaultProps = {
  isProSelected: false,
  team: null,
};

FreeOption.propTypes = {
  isCanceled: PropTypes.bool.isRequired,
  isCancelingSubscription: PropTypes.bool.isRequired,
  isFree: PropTypes.bool.isRequired,
  isProSelected: PropTypes.bool,
  isSubscriptionExempted: PropTypes.bool.isRequired,
  isSubscriptionInvoiced: PropTypes.bool.isRequired,
  planExpired: PropTypes.bool.isRequired,
  team: PropTypes.shape({
    attributes: PropTypes.shape({
      account_status: PropTypes.string,
      status: PropTypes.string,
    }),
  }),
};

const mapStateToProps = state => ({
  isCanceled: teamsSelectors.isCanceledSelector(state),
  isCancelingSubscription: teamsSelectors.isCancelingSubscriptionSelector(state),
  isFree: teamsSelectors.isFreeSelector(state),
  isSubscriptionExempted: teamsSelectors.isSubscriptionExemptedSelector(state),
  isSubscriptionInvoiced: teamsSelectors.isSubscriptionInvoicedSelector(state),
  planExpired: teamsSelectors.planExpiredSelector(state),
  hasSmsAddon: teamsSelectors.hasSmsAddonSelector(state),
});

export default connect(mapStateToProps)(FreeOption);
