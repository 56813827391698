import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import humanize from 'humanize-number';

const IMAGES = {
  emails: '/lock-email.svg',
  journeys: '/lock-email.svg',
  reports: '/lock-report.svg',
  companies: '/lock-companies.svg',
  groups: '/lock-groups.svg',
  technologies: '/lock-groups.svg'
}

const CONTENT = {
  emails: {
    headline: 'Your current plan is limited to 14 days of data',
    description: function (total = 0) {
      return `Upgrade for unlimited access to all ${humanize(total)} emails that are available.`
    },
    '14_days': true
  },
  journeys: {
    headline: 'Your current plan is limited to 14 days of data',
    description: function () {
      return 'Upgrade for unlimited access to all emails in this user journey.'
    },
    '14_days': false
  },
  reports: {
    headline: 'Your current plan is limited to 14 days of data',
    description: function () {
      return 'Upgrade for unlimited access to all report data.'
    },
    '14_days': true
  },
  companies: {
    headline: 'You have reached the limit for your tracked companies',
    description: function () {
      return `To track this company you can upgrade your account or untrack a company you're no longer interested in.`
    },
    '14_days': false
  },
  groups: {
    headline: 'Your current plan does not allow access to industry data',
    description: function (total = 0) {
      return `Upgrade for unlimited access to all ${humanize(total)} companies.`
    },
    '14_days': false
  },
  technologies: {
    headline: 'You do not have access to ESP & technologies insights.',
    description: function () {
      return `Please contact hi@mailcharts.com.`
    },
    '14_days': false
  }
}

class Restricted extends React.Component {
  static contextTypes = {
    router: PropTypes.object
  }

  handleClick = (event) => {
    let type = this.props.type || 'reports'
    window.analytics.track('Paywall clicked', { type })
  }

  componentDidMount () {
    window.analytics.track('User restricted')
  }

  render () {
    let type = this.props.type || 'reports'
    let restrictedInterval = this.props.user.meta.intervals.filter(i => i.restricted === false).reduce((m, i) => i, {})
    let {
      start_date: startDate,
      end_date: endDate
    } = restrictedInterval

    return (
      <div className='pure-u-1'>
        <div
          style={{
            textAlign: 'center',
            margin: '10px auto'
          }}
        >
          <img
            src={IMAGES[type]}
            style={{ maxWidth: 100 }}
          />
        </div>
        <div className='pure-u-1' style={{ textAlign: 'center' }}>
          <h5 style={{ color: '#DD2325' }}>
            {CONTENT[type].headline}
          </h5>
          <p>
            {CONTENT[type].description(this.props.total)}
          </p>
          <p>
            <Link
              onClick={this.handleClick}
              to='/manage/plan'
              className='pure-button pure-button-primary'
            >
              Upgrade
            </Link>
          </p>
          {CONTENT[type]['14_days'] &&
            <p style={{ fontSize: `${12 / 16}em` }} >
              <Link
                to={{
                  pathname: this.context.router.location.pathname,
                  query: {
                    start_date: startDate,
                    end_date: endDate
                  }
                }}
              >
                Or click here to show 14 days of data
              </Link>
            </p>
          }
        </div>
      </div>
    )
  }
}

const s = (state) => ({ user: state.user })
export default connect(s)(Restricted)
