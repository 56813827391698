import * as React from 'react';

function CoffeeGlassAndBook(props) {
  return (
    <svg width={124} height={87} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0 70h110.787c6.011 3.64 6.011 12.36 0 16H0l1.205-1.355a10 10 0 000-13.29L0 70z"
        fill="#DFE1E6"
      />
      <path d="M0 70h110.787v0c6.011 3.64 6.011 12.36 0 16v0H0" stroke="#A5ADBA" strokeWidth={2} />
      <path d="M13.791 82l-5.038-7h23.64L13.79 82z" fill="#A5ADBA" />
      <circle
        cx={67.03}
        cy={59.238}
        r={9.906}
        transform="rotate(-.345 67.03 59.238)"
        stroke="#A5ADBA"
        strokeWidth={3}
      />
      <circle
        cx={95.728}
        cy={59.065}
        r={9.906}
        transform="rotate(-.345 95.728 59.065)"
        stroke="#A5ADBA"
        strokeWidth={3}
      />
      <path d="M76.594 58.812l8.462-.05" stroke="#A5ADBA" strokeWidth={3} />
      <path
        d="M57.795 53.038l-1.383.009a1.561 1.561 0 00-1.087 2.671l1.02 1.008 1.45-3.688zM104.851 52.756l1.382-.008a1.56 1.56 0 011.12 2.658l-1.009 1.02-1.493-3.67zM10 14h42l-3.867 54.142A2 2 0 0146.138 70H15.862a2 2 0 01-1.995-1.858L10 14zM12 9h38l-3.115-7.746A2 2 0 0045.03 0H16.97a2 2 0 00-1.855 1.254L12 9z"
        fill="#A5ADBA"
      />
      <rect x={8} y={6} width={46} height={16} rx={2} fill="#DFE1E6" />
      <circle cx={31} cy={42} r={12} fill="#DFE1E6" />
      <path d="M10 39h42l-1 17H11l-1-17z" fill="#5E6C84" />
    </svg>
  );
}

export default CoffeeGlassAndBook;
