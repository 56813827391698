import styled from "@emotion/styled";

export const ButtonGroup = styled("div")({
  '& > &:not(:last-child) > button, & > button:not(:last-child)': {
    borderBottomRightRadius: '0',
    borderTopRightRadius: '0',
  },
  '& > &:not(:first-of-type) > button, & > button:nth-of-type(n+2), & > button:last-child': {
    borderTopLeftRadius: '0',
    borderBottomLeftRadius: '0',
  },
});

export const Button = styled("button")(({ active, theme }) => ({
  backgroundColor: active ? theme.colors.dark1 : theme.colors.white,
  border: '1px solid',
  borderColor: active ? theme.colors.dark1 : theme.colors.dark5,
  borderRadius: '4px',
  boxSizing: 'border-box',
  color: active ? theme.colors.white : theme.colors.dark08,
  cursor: 'pointer',
  fontSize: '12px',
  fontWeight: '500',
  height: '23px',
  letterSpacing: '0.02em',
  lineHeight: '23px',
  margin: '0',
  padding: '0 12px',
}));

export const UL = styled("ul")({
  boxSizing: 'border-box',
  listStyle: 'none',
  margin: 0,
  padding: 0,
});

export const LI = styled("li")(({ theme }) => ({
  boxSizing: 'border-box',
  color: theme.colors.dark08,
  fontSize: '14px',
  lineHeight: `${22 / 14}em`,
  margin: '0 0 4px 0',
  padding: '0 0 0 16px',
  position: 'relative',
  '&::before': {
    backgroundColor: theme.colors.blue,
    borderRadius: '50%',
    boxSizing: 'border-box',
    content: '""',
    display: 'block',
    height: '8px',
    left: '0',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    width: '8px',
  },
}));
