import React from 'react';
import PropTypes from 'prop-types';

import { Flex, Text } from '../../mc-ui';

const Header = ({ data }) => (
  <Flex>
    <Flex css={{ width: '48px' }}>&nbsp;</Flex>
    {data.map(day => (
      <Flex
        key={`brands-week-table-header-${day}`}
        justifyContent="center"
        css={{ width: `${100 / 7}%` }}
      >
        <Text
          fontSize="12px"
          fontWeight="500"
          lineHeight="1.25em"
          textTransform="uppercase"
          css={{ margin: 0 }}
        >
          {day.substr(0, 3)}
        </Text>
      </Flex>
    ))}
  </Flex>
);

Header.propTypes = {
  data: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Header;
