import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ContactUs, { topics } from './ContactUs';
import ModalLink from './ModalLink';

const ContactModalLink = ({
  children,
  className,
  closeModal,
  onComplete,
  style,
  topic,
  ...props
}) => (
  <ModalLink
    className={className}
    modalChildren={<ContactUs topic={topic} onComplete={onComplete || closeModal} />}
    modalStyle={{ width: 600 }}
    wrapperStyle={{ padding: '40px' }}
    style={style}
    {...props}
  >
    {children}
  </ModalLink>
);

ContactModalLink.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
  closeModal: PropTypes.func.isRequired,
  className: PropTypes.string,
  onComplete: PropTypes.func,
  style: PropTypes.shape({}),
  topic: PropTypes.string,
};

ContactModalLink.defaultProps = {
  children: 'Contact us',
  className: '',
  onComplete: null,
  style: {},
  topic: topics.QUESTION,
};

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch({ type: 'HIDE_MODAL' }),
});

export default connect(
  null,
  mapDispatchToProps,
)(ContactModalLink);
