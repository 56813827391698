import * as React from 'react';
import PropTypes from 'prop-types';

function ScrollIcon({ fillOpacity, ...props }) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M21.086 9.34c-.083 0-.997-.332-2.327-.083V7.76c0-1.496-.582-2.41-1.08-2.908-.582-.582-1.33-.914-2.16-.831H4.714a1.79 1.79 0 00-1.33.332c-1.412.997-1.745 2.493-.997 4.155.25.582.831.914 1.496.914H6.46v9.972a1.1 1.1 0 001.08 1.08H17.68a1.1 1.1 0 001.08-1.08v-5.069c1.994-1.745 2.825-3.823 2.909-3.906L22 9.59l-.914-.25zM3.884 7.761c-.332-.498-.498-1.33.416-2.077.166-.083.249-.083.415-.083h8.06c-.332.747-.415 1.495-.165 2.16H3.884zm13.13 11.136H8.122V9.423h5.651a.914.914 0 00.831-.498l.25-.5-.333-.331c-.332-.5-.665-1.413 0-2.078.25-.25.582-.415.914-.415.332 0 .665.166.997.415.416.416.582.997.582 1.745v1.911c-.582.25-1.163.499-1.745.914-2.576 1.746-2.825 4.322-2.825 5.319-.25.332-.416.582-.665.914-.25.416-.166.914.249 1.164.166.083.332.166.415.166.25 0 .5-.166.665-.333.166-.249.333-.498.582-.748 1.247-.249 2.327-.664 3.24-1.246v3.075h.084zm-2.825-3.823c.166-.914.581-2.16 1.994-3.158 1.33-.914 2.493-1.08 3.324-1.163-.748 1.33-2.493 3.49-5.318 4.321z"
        fill="currentColor"
        fillOpacity={fillOpacity}
      />
    </svg>
  );
}

ScrollIcon.propTypes = {
  fillOpacity: PropTypes.number,
};

ScrollIcon.defaultProps = {
  fillOpacity: 1,
};

export default ScrollIcon;
