/** @jsx jsx */
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { findIndex, propEq } from 'ramda';
import { jsx, withTheme } from '@emotion/react';

import { MultiSelectField } from '../../../../mc-ui';

import brandColors from '../../LineChart/brandColors';

import MenuList from './MenuList';

const multiValueStyles = theme => (base, state) => {
  const { value } = state.selectProps;
  const index = findIndex(propEq('value', state.data.value))(value);

  return {
    ...base,
    backgroundColor: theme.colors[brandColors[index]],
    border: `1px solid ${theme.colors[brandColors[index]]}`,
    borderRadius: 4,
    height: 16,
    margin: 0,
    marginRight: 5,
    padding: 0,
    '&:hover': {
      borderColor: '#091E42',
    },
  };
};

const multiValueLabelStyles = theme => (base, state) => {
  const { value } = state.selectProps;
  const index = findIndex(propEq('value', state.data.value))(value);
  const color = index === 1 ? 'white' : 'dark08';

  return {
    ...base,
    fontFamily: '"Helvetica Neue", Arial, sans-serif',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '10px',
    lineHeight: '1.56em',
    letterSpacing: '0.12em',
    color: theme.colors[color],
    margin: 0,
    padding: '0 4px',
    textTransform: 'uppercase',
  };
};

const multiValueRemoveStyles = theme => (base, state) => {
  const { value } = state.selectProps;
  const index = findIndex(propEq('value', state.data.value))(value);
  const color = index === 1 ? 'white' : 'dark08';

  return {
    ...base,
    color: theme.colors[color],
    cursor: 'pointer',
    margin: 0,
    padding: '0 4px',
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.colors.redDark,
    },
  };
};

const BrandsForm = ({ brands, onRemove, selectedBrands, setBrands, theme }) => {
  const initialValues = {
    brandIds: selectedBrands.map(brand => ({ value: brand.id, label: brand.attributes.name })),
  };

  return (
    <Form initialValues={initialValues} onSubmit={setBrands}>
      {({ form, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div
            css={{ marginBottom: '10px' }}
          >
            <MultiSelectField
              components={{ MenuList: MenuList({ limit: 5 }) }}
              id="brandIds"
              limit={5}
              name="brandIds"
              onRemove={onRemove}
              onMenuClose={form.submit}
              options={brands.map(brand => ({ value: brand.id, label: brand.attributes.name }))}
              placeholder="Select up to 5 brands"
              styles={{
                multiValue: multiValueStyles(theme),
                multiValueLabel: multiValueLabelStyles(theme),
                multiValueRemove: multiValueRemoveStyles(theme),
              }}
            />
          </div>
        </form>
      )}
    </Form>
  );
};

BrandsForm.propTypes = {
  brands: PropTypes.arrayOf(
    PropTypes.shape({
      attributes: PropTypes.shape({ name: PropTypes.string }),
      id: PropTypes.string,
    }),
  ).isRequired,
  onRemove: PropTypes.func.isRequired,
  selectedBrands: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
  setBrands: PropTypes.func.isRequired,
  theme: PropTypes.shape({}).isRequired,
};

export default withTheme(BrandsForm);
