import React from 'react';
import PropTypes from 'prop-types';

import { Flex, Heading, Link } from '../../mc-ui';

import HistoricalData from './HistoricalData';
import Industries from './Industries';
import TwitterFollower from './TwitterFollower';

class QuickSearch extends React.Component {
  state = {
    opened: null,
  };

  closeDropdown = () => {
    this.setState(() => ({ opened: null }));
  };

  handleBlur = event => {
    const { currentTarget } = event;
    setTimeout(() => {
      const { activeElement } = document;
      if (!currentTarget.contains(activeElement)) {
        this.closeDropdown();
      }
    }, 0);
  };

  openAdvancedFilter = event => {
    event.preventDefault();

    const { router } = this.props;
    const { pathname, query } = router.location;

    router.push({ pathname, query: { ...query, advanced: true } });
  };

  toggleOpened = event => {
    event.preventDefault();

    const el = event.currentTarget;
    const opened = this.state.opened === el.dataset.label ? null : el.dataset.label;

    this.setState(() => ({ opened }));
  };

  render() {
    const { opened } = this.state;
    
    return (
      <Flex alignItems="center" marginBottom="24px">
        <Heading
          as="h5"
          color="dark06"
          fontWeight="bold"
          css={{ letterSpacing: '0.08em', height: '23px', lineHeight: '23px' }}
        >
          Quick Search Options
        </Heading>

        <HistoricalData
          closeDropdown={this.closeDropdown}
          onBlur={this.handleBlur}
          onClick={this.toggleOpened}
          opened={opened === 'historicalData'}
          {...this.props}
        />

        <Industries
          closeDropdown={this.closeDropdown}
          onBlur={this.handleBlur}
          onClick={this.toggleOpened}
          opened={opened === 'industries'}
          {...this.props}
        />

        <TwitterFollower
          closeDropdown={this.closeDropdown}
          onBlur={this.handleBlur}
          onClick={this.toggleOpened}
          opened={opened === 'twitterFollower'}
          {...this.props}
        />

        <Link to="#seeAllFilters" css={{ marginLeft: '8px' }} onClick={this.openAdvancedFilter}>
          See All Filters
        </Link>
      </Flex>
    );
  }
}

QuickSearch.propTypes = {
  router: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
      query: PropTypes.shape({}),
    }),
    push: PropTypes.func,
  }).isRequired,
};

export default QuickSearch;
