import React from 'react';
import PropTypes from 'prop-types';
import styled from "@emotion/styled";
import pluralize from 'pluralize';
import { connect } from 'react-redux';
import { isEmpty, splitEvery } from 'ramda';

import {
  groupOverviewActions as actions,
  groupOverviewSelectors as selectors,
} from '../../modules/groupOverview';

import { Box, Flex, Grid, Heading, Link, Loader, Text } from '../mc-ui';
import LeftChevron from '../mc-ui/icons/LeftChevron';
import RightChevron from '../mc-ui/icons/RightChevron';

import Title from './Title';
import BrandLogo from '../ui/BrandLogo';

const PaginationLink = styled("a")(({ disabled, theme }) => ({
  alignItems: 'center',
  color: disabled ? theme.colors.dark5 : theme.colors.blue,
  cursor: disabled ? 'not-allowed' : 'pointer',
  display: 'flex',
}));

class HighlightByBrand extends React.Component {
  state = {
    brandsCollections: [[]],
    index: 0,
  };

  UNSAFE_componentWillReceiveProps(props) {
    const {
      highlightedBrands: { brands },
      loading,
    } = props;

    if (loading) return;

    this.setState({ brandsCollections: this.splitBrands(brands) });
  }

  splitBrands = brands => {
    if (isEmpty(brands)) return [[]];
    return splitEvery(4, brands);
  };

  fetchNext = () => {
    const {
      highlightedBrands: { links },
      paginate,
    } = this.props;

    paginate(links.next);
  };

  handleNext = event => {
    event.preventDefault();

    const { brandsCollections, index } = this.state;

    if (index + 1 < brandsCollections.length) {
      this.setState({ index: index + 1 }, this.fetchNext);
    }
  };

  handlePrev = event => {
    event.preventDefault();

    const { index } = this.state;

    if (index > 0) {
      this.setState({ index: index - 1 });
    }
  };

  goToBrand = brandId => {
    const { router } = this.context;
    router.push(`/companies/${brandId}`);
  };

  render() {
    const { group, loading, model } = this.props;
    const isLoading = loading || isEmpty(group);
    const groupName = isLoading ? model.attributes.name : group.attributes.name;

    const { brandsCollections, index } = this.state;
    const approximateCompanies = isLoading ? 0 : group.attributes.approximate_companies;

    return (
      <Box marginBottom="40px">
        <Title>Browse {groupName || '...'} brands</Title>

        {isLoading ? (
          <Flex alignItems="center" justifyContent="center" height="107px">
            <Loader />
          </Flex>
        ) : (
          <Box>
            <Flex alignItems="stretch" padding="16px 0">
              <PaginationLink href="#prev" onClick={this.handlePrev} disabled={index === 0}>
                <LeftChevron fillOpacity={1} height="24px" width="24px" />
              </PaginationLink>

              <Grid gridColumnGap="2px" gridTemplateColumns="repeat(4, 1fr)" width="786px">
                {brandsCollections[index].map(brand => (
                  <Flex
                    key={`highlightByBrand-${brand.id}`}
                    backgroundColor="white"
                    borderRadius="4px"
                    padding="8px"
                    width="192px"
                    css={{ cursor: 'pointer' }}
                    onClick={() => {
                      this.goToBrand(brand.id);
                    }}
                  >
                    <BrandLogo brand={brand} css={{ marginRight: '16px' }} />

                    <div>
                      <Heading
                        as="h4"
                        lineHeight="1.25em"
                        css={{
                          fontSize: '14px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          width: '120px',
                        }}
                      >
                        {brand.attributes.name}
                      </Heading>
                      <Text>
                        {brand.attributes.approximateEmails}{' '}
                        {pluralize('email', brand.attributes.approximateEmails)}
                      </Text>
                    </div>
                  </Flex>
                ))}
              </Grid>

              <PaginationLink
                href="#next"
                onClick={this.handleNext}
                disabled={index + 1 === brandsCollections.length}
              >
                <RightChevron fillOpacity={1} height="24px" width="24px" />
              </PaginationLink>
            </Flex>

            <Link to={`/groups/${group.id}/companies`}>
              View all {approximateCompanies} {pluralize('brand', approximateCompanies)}
            </Link>
          </Box>
        )}
      </Box>
    );
  }
}

HighlightByBrand.contextTypes = {
  router: PropTypes.object,
};

HighlightByBrand.propTypes = {
  highlightedBrands: PropTypes.shape({
    brands: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        attributes: PropTypes.shape({
          approximateEmails: PropTypes.number,
          name: PropTypes.string,
        }),
      }),
    ),
    links: PropTypes.shape({
      next: PropTypes.string,
    }),
  }).isRequired,
  group: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.shape({
      approximate_companies: PropTypes.number,
      name: PropTypes.string,
    }),
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  model: PropTypes.shape({
    attributes: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
  paginate: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  highlightedBrands: selectors.getData(state).highlightedBrands || {},
  group: selectors.getModel(state),
  loading: selectors.getLoading(state),
});

const mapDispatchToProps = dispatch => ({
  paginate: url => dispatch(actions.paginateHighlightedBrandsRequest(url)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HighlightByBrand);
