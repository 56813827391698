import React from 'react';

import { Text } from '../mc-ui';

export default function EnabledText() {
  return (
    <Text color="dark08" width="592px">
      You will receive one weekly roll-up email for each of the selected groups. Please select up to
      5 groups.
    </Text>
  );
}
