import React from 'react';
import PropTypes from 'prop-types';
import humanize from 'humanize-number';
import { connect } from 'react-redux';

import api from '../../../lib/api';
import { dollar } from '../../../lib/utils';

import { teamsSelectors } from '../../../modules/teams';

import { Box, Button, Flex, Heading, Text } from '../../mc-ui';

import OldPlanNotice from '../../MyPlan/OldPlanNotice';
import { AMOUNTS } from '../../MyPlan/PlanSwitcher/ProOption';

import InviteFormModal from './InviteFormModal';

function CanInviteMessenger({
  invitedMembers,
  isAnOldPlan,
  isSubscriptionAutomaticallyCharged,
  memberLimit,
  planInterval,
}) {
  if (isSubscriptionAutomaticallyCharged && !isAnOldPlan) {
    const perMemberAmount = AMOUNTS[`${planInterval}Member`];
    return (
      <Text color="dark08">
        Add additional team members to your team for {dollar(perMemberAmount, { fromCents: true })}/
        {planInterval}.
      </Text>
    );
  }

  return (
    <Text color="dark08">
      You are currently using{' '}
      <strong>
        {humanize(invitedMembers)} of {humanize(memberLimit)}
      </strong>{' '}
      seats.
      <br />
      <em>Please contact us to edit your subscription or for plan details.</em>
    </Text>
  );
}

CanInviteMessenger.defaultProps = {
  invitedMembers: 0,
  memberLimit: 0,
};

CanInviteMessenger.propTypes = {
  invitedMembers: PropTypes.number,
  isAnOldPlan: PropTypes.bool.isRequired,
  isSubscriptionAutomaticallyCharged: PropTypes.bool.isRequired,
  memberLimit: PropTypes.number,
  planInterval: PropTypes.string.isRequired,
};

function CannotInviteMessenger() {
  return (
    <Text color="dark08">Please contact your team owner or admin(s) to add more team members.</Text>
  );
}

class InviteMembers extends React.Component {
  state = {
    isModalOpen: false,
    loading: this.props.subscription.id ? this.props.isSubscriptionAutomaticallyCharged : true,
    planInterval: 'month',
    subscriptionCalculated: {},
  };

  componentDidMount() {
    this.mounted = true;
  }

  UNSAFE_componentWillReceiveProps(props) {
    const {
      isSubscriptionAutomaticallyCharged,
      membersMeta,
      subscription: { id: subscriptionId },
    } = props;

    if (membersMeta.counts && isSubscriptionAutomaticallyCharged) {
      const quantity = membersMeta.counts.active + 1;
      const params = { quantity };
      api.get(`/v3/subscriptions/${subscriptionId}/calculate`, { params }).then(res => {
        const [subscriptionCalculated] = res.data.data;
        const { plan_interval: planInterval } = subscriptionCalculated.attributes;

        if (this.mounted) {
          this.setState({
            loading: false,
            planInterval,
            subscriptionCalculated,
          });
        }
      });
    } else if (!isSubscriptionAutomaticallyCharged) {
      if (this.mounted) {
        this.setState({ loading: false });
      }
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  mounted = false;

  toggleModal = event => {
    if (event && event.preventDefault) event.preventDefault();

    this.setState(currentState => ({ isModalOpen: !currentState.isModalOpen }));
  };

  render() {
    const {
      isAdmin,
      isAnOldPlan,
      isOwner,
      isSubscriptionAutomaticallyCharged,
      membersMeta,
      team,
    } = this.props;
    const { member_limit: memberLimit } = team.attributes;
    const canInvite = isAdmin || isOwner;
    const { isModalOpen, loading, planInterval, subscriptionCalculated } = this.state;

    const activeMembers = membersMeta && membersMeta.counts ? membersMeta.counts.active : 0;
    const pendingMembers = membersMeta && membersMeta.counts ? membersMeta.counts.pending : 0;
    const invitedMembers = activeMembers + pendingMembers;

    return (
      <div>
        {isAnOldPlan && (
          <Box marginBottom="24px">
            <OldPlanNotice limitReached={invitedMembers >= memberLimit} />
          </Box>
        )}

        {!loading &&
          ((isAnOldPlan && invitedMembers < memberLimit) || !isAnOldPlan) && (
            <Flex
              alignItems="center"
              backgroundColor="blueLight"
              justifyContent="space-between"
              marginBottom="24px"
              padding="24px"
            >
              <Box flex="1">
                <Heading as="h4" fontSize="18px" lineHeight="24px" css={{ marginBottom: '2px' }}>
                  Invite your team
                </Heading>
                {canInvite ? (
                  <CanInviteMessenger
                    invitedMembers={invitedMembers}
                    isAnOldPlan={isAnOldPlan}
                    isSubscriptionAutomaticallyCharged={isSubscriptionAutomaticallyCharged}
                    memberLimit={memberLimit}
                    planInterval={planInterval}
                  />
                ) : (
                  <CannotInviteMessenger />
                )}
              </Box>
              {canInvite &&
                invitedMembers < memberLimit && (
                  <Button
                    css={{
                      fontSize: '14px',
                      height: '40px',
                      lineHeight: '40px',
                      padding: 0,
                      width: '147px',
                    }}
                    onClick={this.toggleModal}
                  >
                    Invite members
                  </Button>
                )}
              {canInvite &&
                invitedMembers < memberLimit && (
                  <InviteFormModal
                    isOpen={isModalOpen}
                    membersMeta={membersMeta}
                    subscriptionCalculated={subscriptionCalculated}
                    teamId={team.id}
                    toggleModal={this.toggleModal}
                  />
                )}
            </Flex>
          )}
      </div>
    );
  }
}

InviteMembers.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  isAnOldPlan: PropTypes.bool.isRequired,
  isOwner: PropTypes.bool.isRequired,
  isSubscriptionAutomaticallyCharged: PropTypes.bool.isRequired,
  membersMeta: PropTypes.shape({
    counts: PropTypes.shape({
      active: PropTypes.number,
      pending: PropTypes.number,
      total: PropTypes.number,
    }),
  }).isRequired,
  subscription: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.shape({
      updated_invoice_amount: PropTypes.number,
      plan_interval: PropTypes.string,
    }),
  }).isRequired,
  team: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.shape({
      member_limit: PropTypes.number,
    }),
  }).isRequired,
};

const mapStateToProps = state => ({
  isAdmin: teamsSelectors.isAdminSelector(state),
  isAnOldPlan: teamsSelectors.isAnOldPlanSelector(state),
  isOwner: teamsSelectors.isOwnerSelector(state),
  isSubscriptionAutomaticallyCharged: teamsSelectors.isSubscriptionAutomaticallyChargedSelector(
    state,
  ),
  membersMeta: teamsSelectors.membersMetaSelector(state),
  subscription: teamsSelectors.subscriptionSelector(state),
  team: teamsSelectors.teamSelector(state),
});

export default connect(mapStateToProps)(InviteMembers);
