/** @jsx jsx */
import { jsx } from '@emotion/react';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import { Link } from 'react-router';
import { Box, Button, Flex, Grid, Heading, Text, theme } from '../../../mc-ui';
import CharacterIcon from '../../../mc-ui/icons/CharacterIcon';
import PhotoIcon from '../../../mc-ui/icons/PhotoIcon';
import WordIcon from '../../../mc-ui/icons/WordIcon';
import GroupLogo from '../../../ui/GroupLogo';

const isNotEmpty = value => !isEmpty(value);

export default function Details({ data, router }) {
  const { query } = router.location;
  const [brand] = (data.relationships.companies || { data: [] }).data;
  const groups = (data.relationships.groups || { data: [] }).data.filter(
    g => `${g.id}` !== `${query.group_id}`,
  );

  return (
    <Box>
      <Heading as="h4" lineHeight="18px" css={{ marginBottom: '24px' }}>
        About this message
      </Heading>

      <Flex justifyContent="space-between" marginBottom="26px">
        <Box width="48%">
          <Heading
            as="h5"
            color="dark08"
            fontWeight="bold"
            lineHeight="1.56em"
            css={{ fontSize: '10px', letterSpacing: '0.12em', marginBottom: '8px' }}
          >
            Character Count
          </Heading>

          <Flex alignItems="center" color="dark08" marginBottom="8px">
            <CharacterIcon height="20px" width="20px" />

            <Text color="dark1" fontWeight="500" lineHeight="20px" margin="0 0 0 8px">
              {data.attributes.character_count}{' '}
              {pluralize('character', data.attributes.character_count)}
            </Text>
          </Flex>
        </Box>

        <Box width="48%">
          <Heading
            as="h5"
            color="dark08"
            fontWeight="bold"
            lineHeight="1.56em"
            css={{ fontSize: '10px', letterSpacing: '0.12em', marginBottom: '8px' }}
          >
            Word Count
          </Heading>

          <Flex alignItems="center" color="dark08" marginBottom="8px">
            <WordIcon height="20px" width="20px" />

            <Text color="dark1" fontWeight="500" lineHeight="20px" margin="0 0 0 8px">
              {data.attributes.word_count} {pluralize('word', data.attributes.word_count)}
            </Text>
          </Flex>
        </Box>
      </Flex>

      <Flex justifyContent="space-between" marginBottom="26px">
        <Box width="48%">
          <Heading
            as="h5"
            color="dark08"
            fontWeight="bold"
            lineHeight="1.56em"
            css={{ fontSize: '10px', letterSpacing: '0.12em', marginBottom: '8px' }}
          >
            Emoji
          </Heading>

          <Flex alignItems="center" color="dark08" marginBottom="8px">
            <PhotoIcon height="20px" width="20px" />

            <Text color="dark1" fontWeight="500" lineHeight="20px" margin="0 0 0 8px">
              {data.attributes.emoji ? 'Yes' : 'No'}
            </Text>
          </Flex>
        </Box>

        {data.attributes.images.length > 0 && (
          <Box width="48%">
            <Heading
              as="h5"
              color="dark08"
              fontWeight="bold"
              lineHeight="1.56em"
              css={{ fontSize: '10px', letterSpacing: '0.12em', marginBottom: '8px' }}
            >
              Animated
            </Heading>

            <Flex alignItems="center" color="dark08" marginBottom="8px">
              <PhotoIcon height="20px" width="20px" />

              <Text color="dark1" fontWeight="500" lineHeight="20px" margin="0 0 0 8px">
                {data.attributes.animated ? 'Yes' : 'No'}
              </Text>
            </Flex>
          </Box>
        )}
      </Flex>

      <Flex justifyContent="flex-end" marginBottom="26px">
        <Button
          as={Link}
          to={`/companies/${brand.id}/text-messages`}
          variant="secondary"
          css={{
            height: '32px',
            lineHeight: '32px',
            margin: '0',
            padding: '0',
            width: '152px',
          }}
        >
          Browse messages
        </Button>
      </Flex>

      {isNotEmpty(groups) &&
        isNotEmpty(brand) && (
          <Box paddingTop="24px" borderColor="dark4" borderTop="1px dashed">
            <Heading as="h4" lineHeight="18px" css={{ marginBottom: '24px' }}>
              Browse more
            </Heading>

            <Heading
              as="h5"
              color="dark08"
              fontWeight="bold"
              lineHeight="1.56em"
              css={{ fontSize: '10px', letterSpacing: '0.12em', marginBottom: '16px' }}
            >
              Groups that include {brand.attributes.name}
            </Heading>

          <Grid gap="16px" gridTemplateColumns="repeat(2, 1fr)"
            style={{
              '@media screen and (max-width: 1280px)': {
                gridTemplateColumns: "repeat(1, 1fr)"
              }
            }}
          >
              {groups.map(group => (
                <Link
                  key={`TextmessageDetails-Group${group.id}`}
                  to={`/groups/${group.id}`}
                  css={{
                    color: 'currentColor',
                    cursor: 'pointer',
                    textDecoration: 'none',
                  }}
                >
                  <Box
                    borderRadius="4px"
                    width="206px"
                    css={{
                      '&:hover': {
                        backgroundColor: theme.colors.dark6,
                      },
                    }}
                  >
                    <Flex justifyContent="space-between">
                      <GroupLogo group={group} />

                      <Flex direction="column" justifyContent="center" width="142px">
                        <Heading
                          as="h5"
                          css={{
                            fontSize: '14px',
                            letterSpacing: '0.02em',
                            lineHeight: '1.25em',
                            textTransform: 'capitalize',
                          }}
                        >
                          {group.attributes.name}
                        </Heading>

                        <Text fontSize="12px">
                          {group.attributes.approximate_companies}{' '}
                          {pluralize('company', group.attributes.approximate_companies)}
                        </Text>
                      </Flex>
                    </Flex>
                  </Box>
                </Link>
              ))}
            </Grid>
          </Box>
        )}
    </Box>
  );
}

Details.propTypes = {
  data: PropTypes.shape({
    attributes: PropTypes.shape({
      animated: PropTypes.bool,
      character_count: PropTypes.number,
      emoji: PropTypes.bool,
      word_count: PropTypes.number,
    }),
    relationships: PropTypes.shape({
      companies: PropTypes.shape({
        data: PropTypes.arrayOf(
          PropTypes.shape({
            attributes: PropTypes.shape({
              name: PropTypes.string,
            }),
          }),
        ),
      }),
      groups: PropTypes.shape({
        data: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
          }),
        ),
      }),
    }),
  }).isRequired,
  router: PropTypes.shape({
    location: PropTypes.shape({
      query: PropTypes.shape({
        group_id: PropTypes.string,
      }),
    }),
  }).isRequired,
};
