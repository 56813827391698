import React from 'react';
import PropTypes from 'prop-types';
import bytes from 'bytes';
import qs from 'qs';
import { Link } from 'react-router';
import { connect } from 'react-redux';

import axios, { CancelToken } from 'axios';
import api from '../../lib/api';
import { reportsActions } from '../../modules/reports';

import Tooltip from '../ui/Tooltip';
import EmailScore from '../charts/EmailScore';
import Loader from '../Loader';
import Restricted from '../Restricted';
import ScoreValue from '../ScoreValue';

import IntervalFilter from './IntervalFilter';

class ScoreReport extends React.Component {
  state = {
    interval: 'week',
    loading: true,
    report: null,
    searchString: '',
  };

  UNSAFE_componentWillMount() {
    const { router } = this.props;

    this.setState({ searchString: router.location.search }, () => {
      this.fetch(this.props);
    });
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { router } = props;

    if (router.location.search !== this.state.searchString) {
      this.setState({ searchString: router.location.search }, () => {
        this.fetch(props);
      });
    }
  }

  setTotal = (report) => {
    let total;
    let comparisonTotal;
    report.meta.stats.forEach((stat) => {
      if (stat.name === 'Comparison') {
        comparisonTotal = stat.total;
      } else {
        total = stat.total; // eslint-disable-line
      }
    });

    this.props.receiveExportTotal({ total, comparisonTotal });
  };

  cancelTokenSource = null;

  cancelFetch = () => {
    if (this.cancelTokenSource) {
      this.cancelTokenSource.cancel();
    }

    this.cancelTokenSource = CancelToken.source();
  };

  fetch(props) {
    this.cancelFetch();

    const { router } = props;
    const { model, startReportLoading } = this.props;
    const { interval } = this.state;

    startReportLoading();

    this.setState({ loading: true, error: null }, () => {
      const query = qs.parse((router.location.search || '').replace('?', ''));
      const params = {
        ...query,
        interval,
      };

      api
        .get(`/v2/${model.type}/${model.id}/score?${qs.stringify(params)}`, {
          cancelToken: this.cancelTokenSource.token,
        })
        .then((r) => {
          const report = r.data;
          this.setTotal(report);
          this.setState({ loading: false, restricted: null, report });
        })
        .catch((err) => {
          this.setState({ error: true, restricted: /403/.test(err.toString()), report: {} });
        });
    });
  }

  updateInterval = (interval) => {
    this.setState({ interval }, () => this.fetch(this.props));
  };

  render() {
    let slLengthValue = '';
    let imageWeightValue = '';

    if (!this.state.loading) {
      if (this.state.report.meta.stats.reduceRight((m, s) => s.subject_line_length.avg, 0) < 50) {
        slLengthValue = 'success';
      } else if (
        this.state.report.meta.stats.reduceRight((m, s) => s.subject_line_length.avg, 0) > 75
      ) {
        slLengthValue = 'failure';
      } else {
        slLengthValue = 'n/a';
      }

      if (this.state.report.meta.stats.reduceRight((m, s) => s.image_bytes.avg, 0) < 800000) {
        imageWeightValue = 'success';
      } else if (
        this.state.report.meta.stats.reduceRight((m, s) => s.image_bytes.avg, 0) >
        800000 * 5
      ) {
        imageWeightValue = 'failure';
      } else {
        imageWeightValue = 'n/a';
      }
    }
    return (
      <Loader loading={this.state.loading} error={this.state.error}>
        {this.state.report ? (
          <div className="pure-u-1">
            {this.state.restricted ? (
              <Restricted />
            ) : (
              <IntervalFilter onUpdate={this.updateInterval} {...this.props} {...this.state}>
                {({ model, report, IntervalFilterNav }) => (
                  <div>
                    <div className="pure-u-1" style={{ position: 'relative ' }}>
                      <h4 style={{ display: 'inline-block', marginRight: 5 }}>Email score</h4>
                      <Tooltip theme="light">
                        <h5>What is Email score?</h5>
                        <p style={{ fontSize: `${14 / 16}em` }}>
                          Identify easy wins to optimize your campaigns by checking the pulse of email campaigns.
                        </p>
                        <p style={{ fontSize: `${14 / 16}em` }}>
                          <a
                            className="pure-button"
                            href="https://knowledge.validity.com/hc/en-us/articles/9512247991707-Email-Score"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            Learn more
                          </a>
                        </p>
                      </Tooltip>
                      <IntervalFilterNav />
                    </div>
                    <p style={{ fontSize: '.9em' }}>
                      MailCharts tracks key aspects of every email received from
                      {model.type === 'groups' ? ' brands in ' : null} {model.attributes.name}.
                      Email Score follows email marketing best practices, allowing brands to ensure
                      their email program is set up for success.
                    </p>
                    <div
                      style={{
                        padding: '5px 0',
                        marginBottom: 5,
                        borderBottom: '1px solid #F3F5F7',
                      }}
                    >
                      <div className="pure-u-1">
                        <EmailScore
                          height={100}
                          names={report.meta.stats.map(s => s.name)}
                          attribute="mailcharts_score"
                          averages={report.meta.stats.map(s => `${Math.round(s.mailcharts_score.avg * 100)}%`)}
                          buckets={report.meta.stats.map(s => s.interval.buckets)}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        padding: '5px 0',
                        marginBottom: 5,
                        borderBottom: '1px solid #F3F5F7',
                      }}
                    >
                      <div className="pure-u-1 pure-u-md-1-2">
                        <ScoreValue label="sl_length" description value={slLengthValue}>
                          <p style={{ fontSize: `${13 / 16}em` }}>
                            Your subject line has one job: to get the email opened.
                          </p>
                          <p style={{ fontSize: `${13 / 16}em` }}>
                            It&apos;s very difficult for your subject line to do it&apos;s job when
                            it gets interrupted (aka: truncated) halfway through. This is why{' '}
                            <Link
                              to="https://www.mailcharts.com/blog/short-email-subject-lines"
                              target="_blank"
                            >
                              we recommend using shorter subject lines
                            </Link>.
                          </p>
                        </ScoreValue>
                      </div>
                      <div className="pure-u-1 pure-u-md-1-2">
                        <EmailScore
                          height={240}
                          title="Subject line length"
                          names={report.meta.stats.map(s => s.name)}
                          attribute="subject_line_length"
                          calculation="avg"
                          averages={report.meta.stats.map(s =>
                            Math.round(s.subject_line_length.avg))}
                          buckets={report.meta.stats.map(s => s.interval.buckets)}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        padding: '5px 0',
                        marginBottom: 5,
                        borderBottom: '1px solid #F3F5F7',
                      }}
                    >
                      <div className="pure-u-1 pure-u-md-1-2">
                        <ScoreValue
                          label="mobile"
                          description
                          value={
                            report.meta.stats.reduceRight((m, s) => s.mobile_optimized.avg, 0) > 0.8
                              ? 'success'
                              : 'failure'
                          }
                        >
                          <p style={{ fontSize: `${13 / 16}em` }}>
                            <Link
                              to="https://litmus.com/blog/mobile-holds-at-56-for-october-email-market-share"
                              target="_blank"
                            >
                              56% of emails are opened on mobile.
                            </Link>{' '}
                            It is vital to engage with recipients on their turf.
                          </p>
                        </ScoreValue>
                      </div>
                      <div className="pure-u-1 pure-u-md-1-2">
                        <EmailScore
                          height={240}
                          title="Mobile optimization"
                          names={report.meta.stats.map(s => s.name)}
                          attribute="mobile_optimized"
                          averages={report.meta.stats.map(s => `${Math.round(s.mobile_optimized.avg * 100)}%`)}
                          buckets={report.meta.stats.map(s => s.interval.buckets)}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        padding: '5px 0',
                        marginBottom: 5,
                        borderBottom: '1px solid #F3F5F7',
                      }}
                    >
                      <div className="pure-u-1 pure-u-md-1-2">
                        <ScoreValue
                          label="ph_optimized"
                          description
                          value={
                            report.meta.stats.reduceRight((m, s) => s.preheader_optimized.avg, 0) >
                            0.5
                              ? 'success'
                              : 'failure'
                          }
                        >
                          <p style={{ fontSize: `${13 / 16}em` }}>
                            If the subject line is Batman, then the preview text is Robin: It&apos;s
                            the sidekick you need to get emails opened.
                          </p>
                          <p style={{ fontSize: `${13 / 16}em` }}>
                            The native iPhone email app displays 30-40 characters of a subject line
                            and twice as many characters of{' '}
                            <Link
                              to="https://litmus.com/blog/8-embarrassing-preview-text-mistakes-4-tips-on-how-to-avoid-making-them"
                              target="_blank"
                            >
                              preview text
                            </Link>. Optimize this marketing space by avoiding wasteful words such
                            as &quot;click here&quot; or &quot;view online&quot;.
                          </p>
                        </ScoreValue>
                      </div>
                      <div className="pure-u-1 pure-u-md-1-2">
                        <EmailScore
                          height={240}
                          title="Preheader optimization"
                          names={report.meta.stats.map(s => s.name)}
                          attribute="preheader_optimized"
                          averages={report.meta.stats.map(s => `${Math.round(s.preheader_optimized.avg * 100)}%`)}
                          buckets={report.meta.stats.map(s => s.interval.buckets)}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        padding: '5px 0',
                        marginBottom: 5,
                        borderBottom: '1px solid #F3F5F7',
                      }}
                    >
                      <div className="pure-u-1 pure-u-md-1-2">
                        <ScoreValue
                          label="no_reply"
                          description
                          value={
                            report.meta.stats.reduceRight((m, s) => s.no_reply.avg, 0) < 0.05
                              ? 'success'
                              : 'failure'
                          }
                        >
                          <p style={{ fontSize: `${13 / 16}em` }}>
                            Using catch-alls like `no-reply` is a `no-no`. Make subscribers feel
                            like an individual – not just{' '}
                            <Link
                              to="https://blog.aweber.com/articles-tips/do-not-reply-address-dont-bother.htm"
                              target="_blank"
                            >
                              another email address
                            </Link>{' '}
                            by opening up a line of two-way communication.
                          </p>
                          <p style={{ fontSize: `${13 / 16}em` }}>
                            Even if you don&apos;t have the bandwidth to respond to these replies,
                            you can still use a{' '}
                            <Link
                              to="https://litmus.com/blog/say-no-to-no-reply-email-addresses"
                              target="_blank"
                            >
                              replyable address
                            </Link>.
                          </p>
                        </ScoreValue>
                      </div>
                      <div className="pure-u-1 pure-u-md-1-2">
                        <EmailScore
                          height={240}
                          title="No-reply usage"
                          names={report.meta.stats.map(s => s.name)}
                          attribute="no_reply"
                          averages={report.meta.stats.map(s => `${Math.round(s.no_reply.avg * 100)}%`)}
                          buckets={report.meta.stats.map(s => s.interval.buckets)}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        padding: '5px 0',
                        marginBottom: 5,
                        borderBottom: '1px solid #F3F5F7',
                      }}
                    >
                      <div className="pure-u-1 pure-u-md-1-2">
                        <ScoreValue
                          label="html_weight"
                          description
                          value={
                            report.meta.stats.reduceRight((m, s) => s.body_bytes.avg, 0) < 102000
                              ? 'success'
                              : 'failure'
                          }
                        >
                          <p style={{ fontSize: `${13 / 16}em` }}>
                            While it may seem geeky, the size of the HTML code in an email does
                            matter. Gmail, one of the{' '}
                            <Link to="https://emailclientmarketshare.com/" target="_blank">
                              largest email clients
                            </Link>, &quot;clips&quot; emails at{' '}
                            <Link
                              to="https://kb.mailchimp.com/delivery/deliverability-research/gmail-is-clipping-my-email"
                              target="_blank"
                            >
                              102KB of HTML
                            </Link>.
                          </p>
                          <p style={{ fontSize: `${13 / 16}em` }}>
                            Try keeping your HTML weight (excluding images) to less than 100KB. A
                            good trick is to remove tabs and spaces — this can account for ~30% of
                            the email weight.
                          </p>
                        </ScoreValue>
                      </div>
                      <div className="pure-u-1 pure-u-md-1-2">
                        <EmailScore
                          height={240}
                          title="HTML weight"
                          names={report.meta.stats.map(s => s.name)}
                          attribute="body_bytes"
                          calculation="bytes"
                          averages={report.meta.stats.map(s =>
                            bytes(s.body_bytes.avg || 0, { unit: 'kb' }))}
                          buckets={report.meta.stats.map(s => s.interval.buckets)}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        padding: '5px 0',
                        marginBottom: 5,
                        borderBottom: '1px solid #F3F5F7',
                      }}
                    >
                      <div className="pure-u-1 pure-u-md-1-2">
                        <ScoreValue label="image_weight" description value={imageWeightValue}>
                          <p style={{ fontSize: `${13 / 16}em` }}>
                            Large, heavy, images load slowly in subscribers&apos; inboxes. This is
                            particularly a burden for those on mobile or with slow connections —
                            which can lead to lower engagement.
                          </p>
                          <p style={{ fontSize: `${13 / 16}em` }}>
                            Optimize your emails to load quickly by keeping images to under 800KB.
                            Pro tip: When including animated GIFs, consider removing any unnecessary
                            frame from the GIF.
                          </p>
                        </ScoreValue>
                      </div>
                      <div className="pure-u-1 pure-u-md-1-2">
                        <EmailScore
                          height={240}
                          title="Image weight"
                          names={report.meta.stats.map(s => s.name)}
                          attribute="image_bytes"
                          calculation="bytes"
                          averages={report.meta.stats.map(s =>
                            bytes(s.image_bytes.avg || 0, { unit: 'kb' }))}
                          buckets={report.meta.stats.map(s => s.interval.buckets)}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        padding: '5px 0',
                        marginBottom: 5,
                        borderBottom: '1px solid #F3F5F7',
                      }}
                    >
                      <div className="pure-u-1 pure-u-md-1-2">
                        <ScoreValue
                          label="smtp_headers"
                          description
                          value={report.meta.stats.reduceRight((m, s) => {
                            if (
                              s.spf_result.buckets.some(b => b.key === 'fail') ||
                              s.dkim_result.buckets.some(b => b.key === 'fail')
                            ) {
                              return 'failure';
                            } else if (
                              s.spf_result.buckets.every(b => b.key === 'pass') &&
                              s.dkim_result.buckets.some(b => b.key === 'pass')
                            ) {
                              return 'success';
                            }
                            return 'n/a';
                          }, 'n/a')}
                        >
                          <p style={{ fontSize: `${13 / 16}em` }}>
                            Failing DKIM or SPF can lead to dramatic deliverability woes. Make sure
                            all your{' '}
                            <Link
                              to="https://blogs.msdn.microsoft.com/tzink/2015/07/12/what-is-the-best-combination-for-your-spf-record-dkim-record-and-dmarc-record/"
                              target="_blank"
                            >
                              email records are properly set
                            </Link>.
                          </p>
                        </ScoreValue>
                      </div>
                      <div className="pure-u-1 pure-u-md-1-2">
                        <EmailScore
                          height={240}
                          title="DKIM & SPF"
                          names={report.meta.stats.map(s => s.name)}
                          calculation="smpt_headers"
                          averages={report.meta.stats.map((s) => {
                            const no = s.spam_flag.buckets
                              .filter(b => b.key === 'no')
                              .reduce((m, b) => b.doc_count, 0);
                            const yes = s.spam_flag.buckets
                              .filter(b => b.key === 'yes')
                              .reduce((m, b) => b.doc_count, 0);
                            const sum = yes + no;
                            const avg = sum === 0 ? 0 : yes / sum;
                            return `${Math.round(avg * 100)}%`;
                          })}
                          buckets={report.meta.stats.map(s => s.interval.buckets)}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        padding: '5px 0',
                        marginBottom: 5,
                        borderBottom: '1px solid #F3F5F7',
                      }}
                    >
                      <div className="pure-u-1 pure-u-md-1-2">
                        <ScoreValue
                          label="spam_flag"
                          description
                          value={
                            report.meta.stats.reduceRight((m, s) => {
                              const no = s.spam_flag.buckets
                                .filter(b => b.key === 'no')
                                .reduce((_, b) => b.doc_count, 0);
                              const yes = s.spam_flag.buckets
                                .filter(b => b.key === 'yes')
                                .reduce((_, b) => b.doc_count, 0);
                              const sum = yes + no;
                              const avg = sum === 0 ? 0 : yes / sum;
                              return avg;
                            }, 0) < 0.1
                              ? 'success'
                              : 'failure'
                          }
                        >
                          <p style={{ fontSize: `${13 / 16}em` }}>
                            Ensure the content and structure of each email is not
                            &quot;spammy&quot;. Misspellings, spammy words, all caps, and plenty of
                            exclamation points (!!!!!!) are big{' '}
                            <Link
                              to="https://help.mailgun.com/hc/en-us/articles/203306870-Does-the-content-of-my-email-matter-for-deliverability-"
                              target="_blank"
                            >
                              spam red flags
                            </Link>.
                          </p>
                          <p style={{ fontSize: `${13 / 16}em` }}>
                            Pro tip:{' '}
                            <Link to="https://www.mail-tester.com/" target="_blank">
                              Check potential spam issues for free here.
                            </Link>
                          </p>
                        </ScoreValue>
                      </div>
                      <div className="pure-u-1 pure-u-md-1-2">
                        <EmailScore
                          height={240}
                          title="Spammy content"
                          names={report.meta.stats.map(s => s.name)}
                          attribute="spam_flag"
                          calculation="spam"
                          averages={report.meta.stats.map((s) => {
                            const no = s.spam_flag.buckets
                              .filter(b => b.key === 'no')
                              .reduce((m, b) => b.doc_count, 0);
                            const yes = s.spam_flag.buckets
                              .filter(b => b.key === 'yes')
                              .reduce((m, b) => b.doc_count, 0);
                            const sum = yes + no;
                            const avg = sum === 0 ? 0 : yes / sum;
                            return `${Math.round(avg * 100)}%`;
                          })}
                          buckets={report.meta.stats.map(s => s.interval.buckets)}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </IntervalFilter>
            )}
          </div>
        ) : null}
      </Loader>
    );
  }
}

ScoreReport.propTypes = {
  model: PropTypes.shape({}).isRequired,
  router: PropTypes.shape({}).isRequired,
  receiveExportTotal: PropTypes.func.isRequired,
  startReportLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  companies: state.companies,
  groups: state.groups,
});

const mapDispatchToProps = dispatch => ({
  startReportLoading: () => dispatch(reportsActions.startLoading()),
  receiveExportTotal: (...args) => dispatch(reportsActions.receiveExportTotalAmount(...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ScoreReport);
