/* eslint-disable */
import React from 'react'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import humanize from 'humanize-number'

import api from '../lib/api'

class GroupsList extends React.Component {
  handleClick = (model) => {
    if (this.props.company) {
      let url = `/v2/groups/${model.id}/companies`
      let included = this.props.company.relationships.groups.data.some(g => g.id == model.id)
      let company_id = this.props.company.id
      ;(included ? api.delete(url, { params: { company_id } }) : api.post(url, { company_id }))
        .then(r => {
          this.props.dispatch({ type: 'REFRESH_COMPANIES' })
          this.props.dispatch({ type: 'REFRESH_USER' })
        })
        .catch(console.error)
    } else
      this.props.router.push(`/${model.type}/${model.id}`)
  }
  render() {
    return (
      <div className='pure-g gutters'>
        {this.props.groups.map((model, index) => {
          let opacity = 1
          let included = false
          if (this.props.editing) {
            included = this.props.company.relationships.groups.data.some(g => g.id == model.id)
            opacity = included ? 1 : 0.5
          }
          return (
            <div
              key={index}
              onClick={(e) => {
                e.preventDefault()
                this.handleClick(model)
              }}
              style={{
                cursor: 'pointer',
                opacity,
              }}
              className='pure-u-1 pure-u-md-1-3'
            >
              <div className='pure-u-1'>
                <div
                  style={{
                    border: '1px solid #D4D9DE',
                    marginBottom: 25,
                    borderRadius: 3,
                    background: '#fff',
                    padding: 25,
                  }}
                >
                  <div className='pure-u-7-8'>
                    <h4 style={{ margin: 0 }}>{model.attributes.name}</h4>
                    <p
                      style={{ fontSize: `${12/16}em`, color: '#A0AEBA', margin: 0 }}
                    >
                      {humanize(model.attributes.approximate_companies || 0)} brands
                    </p>
                  </div>
                  <div className='pure-u-1-8' style={{ textAlign: 'right' }}>
                    {included ?
                        <img src='/added.png'
                          onMouseOut={(e) => {
                            e.target.src = '/added.png'
                          }}
                          onMouseOver={(e) => {
                            e.target.src = '/remove-company.png'
                          }}
                          style={{ maxWidth: 25, textAlign: 'right', }}
                          className='pure-img'
                        />
                    : null}
                  </div>
                <div style={{ marginTop: 10 }}>
                  {(model.relationships.companies.data || []).slice(0, 5).map((company, idx) => {
                    return (
                      <div
                        key={idx}
                        className='pure-u-1-5'
                      >
                        <div
                          className='pure-u-21-24'
                          style={{ border: '1px solid #F3F5F7', borderRadius: 2, }}
                        >
                          <div
                            style={{ display: 'flex', alignItems: 'center', height: 48, }}
                          >
                            <img
                              onError={(err) => {
                                // only make the replacement one time
                                if (!/defaults/.test(err.target.src))
                                  err.target.src = `//cache.mailcharts.com/logos/v2/defaults/${company.id % 125}.png`
                              }}
                              src={`//cache.mailcharts.com/logos/v2/companies/${company.id}.png`}
                              style={{ height: 'auto' }}
                              title={company.attributes.name}
                              className='pure-img'
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default connect()(GroupsList)
