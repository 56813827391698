import React from 'react';
import { Link } from 'react-router';

import WarningCircleIcon from '../mc-ui/icons/WarningCircleIcon';
import { Box, Button, Flex, Heading, Text, theme } from '../mc-ui';

export default function ExploreAllIndustriesNotice() {
  return (
    <Flex
      alignItems="flex-start"
      backgroundColor="white"
      border="1px solid"
      borderColor="yellowMedium"
      borderRadius="4px"
      padding="24px"
    >
      <WarningCircleIcon color={theme.colors.yellowDark} />

      <Box flex="1" margin="0 16px" width="unset">
        <Heading as="h3" lineHeight={`${24 / 18}em`} css={{ marginBottom: '8px' }}>
          You haven’t set your preferences yet!
        </Heading>

        <Text>
          Click the Industry or Custom group drop-down to select groups for your weekly roll-ups, or
          click the button to explore different ecommerce industries. You can sign-up from the group
          page too!
        </Text>
      </Box>

      <Button
        as={Link}
        css={{
          alignItems: 'center',
          display: 'inline-flex',
          height: '32px',
          justifyContent: 'center',
          lineHeight: '32px',
          margin: '0',
          padding: '0',
          width: '168px',
        }}
        to="/groups"
        variant="primary"
      >
        Explore All Industries
      </Button>
    </Flex>
  );
}
