import { merge } from 'ramda';

import * as c from './constants';
import * as types from './types';

export const INITIAL_STATE = {
  body: null,
  duration: c.DEFAULT_DURATION,
  messageType: null,
  persistOnHover: c.DEFAULT_PERSIST_ON_HOVER,
  title: null,
  visible: false,
};

const hideReducer = state => merge(state, INITIAL_STATE);

const showReducer = (state, { body, duration, messageType, persistOnHover, title }) =>
  merge(state, { body, duration, messageType, persistOnHover, title, visible: true });

const reducers = {
  [types.HIDE]: hideReducer,
  [types.SHOW]: showReducer,
};

export default function reducer(state = INITIAL_STATE, action = {}) {
  const reducerFunction = reducers[action.type];

  if (!reducerFunction) return state;
  return reducerFunction(state, action);
}
