import React from 'react';
import PropTypes from 'prop-types';
import styled from "@emotion/styled";

const Img = styled("img")(({ css = {} }) => ({
  borderRadius: '4px',
  display: 'block',
  height: '40px',
  width: '40px',
  ...css,
}));

const BrandLogo = ({ brand, brandId, className, css, style, ...props }) => {
  const customStyle = { ...css, ...style };
  const brandLogo = `//cache.mailcharts.com/logos/v2/companies/${brandId || brand.id}.png`;
  const defaultLogo = `//cache.mailcharts.com/logos/v2/defaults/${(brandId || brand.id) % 125}.png`;

  return (
    <Img
      onError={err => {
        // only make the replacement one time
        if (!/defaults/.test(err.target.src)) {
          err.target.src = defaultLogo; // eslint-disable-line
        }
      }}
      src={brandLogo}
      css={customStyle}
      className={className}
      {...props}
    />
  );
};

BrandLogo.defaultProps = {
  brand: {},
  brandId: null,
  className: '',
  css: {},
  style: {},
};

BrandLogo.propTypes = {
  brand: PropTypes.shape({}),
  brandId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  css: PropTypes.shape({}),
  style: PropTypes.shape({}),
};

export default BrandLogo;
