import React from 'react';
import PropTypes from 'prop-types';

import { Box, Heading, Link, Text } from '../mc-ui';
import BrandTag from '../BrandTag';

const STATES = ['active', 'archived', 'in_progress', 'regional', 'reviewing'];
const STATES_DESCRIPTIONS = {
  active: 'Curated ecommerce brands in the MailCharts Index.',
  archived: 'Does not meet the criteria of the MailCharts Index',
  in_progress: 'Brands that may be included in the MailCharts Index soon',
  regional: 'Sub-brands in the MailCharts Index from other regions',
  reviewing: 'Brands vetted for inclusion in the MailCharts Index',
};

function filterStates(brands) {
  return STATES.filter(s => brands.meta.brandStates.buckets.find(b => b.key === s));
}

export default function Sidebar({ brands }) {
  const states = filterStates(brands);

  if (states.length === 0) return null;

  return (
    <Box position="sticky" top="0">
      <Heading
        as="h5"
        color="dark08"
        css={{
          fontSize: '14px',
          lineHeight: '1.25em',
          marginBottom: '16px',
          textTransform: 'none',
        }}
      >
        What do these labels mean?
      </Heading>

      {states.map(state => (
        <Box
          borderBottom="1px solid"
          borderColor="dark01"
          key={`brandsResultsSideBar-${state}`}
          marginBottom="16px"
          paddingBottom="10px"
        >
          <BrandTag state={state} />

          <Text color="dark08" fontSize="12px">
            {STATES_DESCRIPTIONS[state]}
          </Text>
        </Box>
      ))}

      <Link
        fontSize="12px"
        rel="noopener noreferrer"
        target="_blank"
        to="https://www.mailcharts.com/companies"
      >
        Learn more about MailCharts Index
      </Link>
    </Box>
  );
}

Sidebar.propTypes = {
  brands: PropTypes.shape({
    meta: PropTypes.shape({
      brandStates: PropTypes.shape({
        buckets: PropTypes.arrayOf(
          PropTypes.shape({
            key: PropTypes.string,
          }),
        ),
      }),
    }),
  }).isRequired,
};
