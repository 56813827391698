import React from 'react';

import { Flex, Link, Text } from '../../mc-ui';
import CoffeeGlassAndBook from '../../ui/illustrations/CoffeeGlassAndBook';

export default function NoGroups() {
  return (
    <Flex
      alignItems="center"
      border="1px solid transparent"
      borderColor="dark5"
      borderRadius="4px"
      direction="column"
      height="480px"
      justifyContent="center"
    >
      <CoffeeGlassAndBook />

      <Text fontWeight="bold" letterSpacing="0.02em" lineHeight="1.25em" margin="24px 0 16px 0">
        Create your first group
      </Text>

      <Text
        letterSpacing="0.02em"
        lineHeight="1.56em"
        textAlign="center"
        width="600px"
        css={{ marginBottom: '16px' }}
      >
        Organize brands into groups to help you easily view reporting, comparisons, and insights.
        <br />
        <br />
        Or, browse the <Link to="/groups">MailCharts Index</Link> to find Brands to add to a group.
      </Text>
    </Flex>
  );
}
