const prefix = 'mc/newReports/benchmarks';

export const FETCH_BRAND_OPTIONS_REQUEST = `${prefix}/FETCH_BRAND_OPTIONS_REQUEST`;
export const FETCH_BRAND_OPTIONS_SUCCESS = `${prefix}/FETCH_BRAND_OPTIONS_SUCCESS`;

export const FETCH_BRAND_DATA_REQUEST = `${prefix}/FETCH_BRAND_DATA_REQUEST`;
export const FETCH_BRAND_DATA_FAILURE = `${prefix}/FETCH_BRAND_DATA_FAILURE`;
export const FETCH_BRAND_DATA_SUCCESS = `${prefix}/FETCH_BRAND_DATA_SUCCESS`;
export const TOGGLE_BRAND_DATA_LOADING = `${prefix}/TOGGLE_BRAND_DATA_LOADING`;

export const FETCH_GROUP_BRANDS_REQUEST = `${prefix}/FETCH_GROUP_BRANDS_REQUEST`;
export const FETCH_GROUP_BRANDS_FAILURE = `${prefix}/FETCH_GROUP_BRANDS_FAILURE`;
export const FETCH_GROUP_BRANDS_SUCCESS = `${prefix}/FETCH_GROUP_BRANDS_SUCCESS`;
export const TOGGLE_GROUP_BRANDS_LOADING = `${prefix}/TOGGLE_GROUP_BRANDS_LOADING`;

export const FETCH_GROUP_DATA_REQUEST = `${prefix}/FETCH_GROUP_DATA_REQUEST`;
export const FETCH_GROUP_DATA_FAILURE = `${prefix}/FETCH_GROUP_DATA_FAILURE`;
export const FETCH_GROUP_DATA_SUCCESS = `${prefix}/FETCH_GROUP_DATA_SUCCESS`;
export const TOGGLE_GROUP_DATA_LOADING = `${prefix}/TOGGLE_GROUP_DATA_LOADING`;
