import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { withTheme } from '@emotion/react';

import { Button, Flex, SubMenu, } from '../../mc-ui';
import ClassicViewIcon from '../../mc-ui/icons/ClassicViewIcon';

const SwitchToClassic = ({ rootPath, theme }) => (
  <SubMenu>
    <SubMenu.Link
      style={{
        marginTop: 13,
        background: theme.colors.dark004,
        padding: '0 10px',
      }}
      to={{
        pathname: `${rootPath}/reports`,
      }}
    >
      <div className='pure-g' style={{ width: '100%' }}>
        <div className='pure-u-3-4' style={{ marginTop: 3, textAlign: 'left' }}>
          Classic Reports
        </div>
        <div className='pure-u-1-4' style={{ textAlign: 'right' }}>
          <img src='/classic-view-icon.svg' height="20" width="20" fillOpacity={1} />
        </div>
      </div>
    </SubMenu.Link>
  </SubMenu>
);

SwitchToClassic.propTypes = {
  rootPath: PropTypes.string.isRequired,
  theme: PropTypes.shape({}).isRequired,
};

export default withTheme(SwitchToClassic);
