
import React from 'react';
import PropTypes from 'prop-types';

import Select from '../../mc-ui/overrides/ReactSelect';

class FiltersSelect extends React.Component {
  constructor(props) {
    super(props);

    const value = this.options().find(r => r.value === props.filter.status);

    this.state = { value };
  }

  handleChange = value => {
    const { value: selectedStatus } = value;
    const { onUpdate } = this.props;
    this.setState(
      () => ({ value }),
      () => {
        onUpdate({ status: selectedStatus });
      },
    );
  };

  options = () => {
    const { membersMeta } = this.props;
    const options = [
      {
        label: `All members ${membersMeta.counts ? `(${membersMeta.counts.total})` : ''}`,
        value: null,
      },
    ];

    if (membersMeta.counts && membersMeta.counts.active > 0) {
      options.push({
        label: `Active members ${membersMeta.counts ? `(${membersMeta.counts.active})` : ''}`,
        value: 'active',
      });
    }

    if (membersMeta.counts && membersMeta.counts.pending > 0) {
      options.push({
        label: `Pending members ${membersMeta.counts ? `(${membersMeta.counts.pending})` : ''}`,
        value: 'pending',
      });
    }

    if (membersMeta.counts && membersMeta.counts.archived > 0) {
      options.push({
        label: `Archived ${membersMeta.counts ? `(${membersMeta.counts.archived})` : ''}`,
        value: 'archived',
      });
    }

    return options;
  };

  render() {
    const { enabled } = this.props;
    const { value } = this.state;

    return (
      <Select
        isDisabled={!enabled}
        onChange={this.handleChange}
        options={this.options()}
        value={value}
      />
    );
  }
}

FiltersSelect.propTypes = {
  enabled: PropTypes.bool.isRequired,
  filter: PropTypes.shape({
    status: PropTypes.string,
  }).isRequired,
  membersMeta: PropTypes.shape({
    counts: PropTypes.shape({
      active: PropTypes.number,
      archived: PropTypes.number,
      pending: PropTypes.number,
      total: PropTypes.number,
    }),
  }).isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default FiltersSelect;
