import * as React from 'react';
import PropTypes from 'prop-types';

function DurationIcon({ fillOpacity, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.449 6.087H15V4h2.449C19.41 4 21 5.505 21 7.362v9.276C21 18.495 19.41 20 17.449 20H15v-2.087h2.449c.744 0 1.347-.571 1.347-1.275V7.362c0-.704-.603-1.275-1.347-1.275zM6.551 6.087H9V4H6.551C4.59 4 3 5.505 3 7.362v9.276C3 18.495 4.59 20 6.551 20H9v-2.087H6.551c-.744 0-1.347-.571-1.347-1.275V7.362c0-.704.603-1.275 1.347-1.275z"
        fill="currentColor"
        fillOpacity={fillOpacity}
      />
      <path fill="currentColor" d="M7 10h10v4H7z" fillOpacity={fillOpacity} />
    </svg>
  );
}

DurationIcon.propTypes = {
  fillOpacity: PropTypes.number,
};

DurationIcon.defaultProps = {
  fillOpacity: 1,
};

export default DurationIcon;
