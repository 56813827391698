/* eslint-disable react/prop-types */

import React from 'react';
import { Field } from 'react-final-form';
import { OnBlur, OnChange, OnFocus } from 'react-final-form-listeners';
import { ascend, sort } from 'ramda';

import ReactSelect from '../../overrides/ReactSelect';

const sortValues = sort(ascend);

const MultiSelectField = ({ isSelectionAllowed, limit, noOptionsMessage, options, ...props }) => (
  <Field {...props}>
    {({ input, meta, onBlur, onChange, onFocus, onRemove, ...rest }) => (
      <div>
        <ReactSelect
          {...input}
          {...rest}
          options={options}
          closeMenuOnSelect={false}
          noOptionsMessage={() => noOptionsMessage || 'No options available'}
          isMulti
          isSearchable
        />

        <OnBlur name={input.name}>
          {() => {
            if (onBlur) {
              onBlur({ value: input.value, valid: meta.valid });
            }
          }}
        </OnBlur>

        <OnChange name={input.name}>
          {(value, previous) => {
            if (!isSelectionAllowed || (limit && value.length > limit)) {
              input.onChange(previous);
              return;
            }

            if (onRemove) {
              const currentValues = sortValues(value.map(v => v.value)).join('|');
              const previousValues = sortValues(previous.map(v => v.value)).join('|');

              if (
                value.length < previous.length &&
                previous.length <= limit &&
                currentValues !== previousValues
              ) {
                onRemove(value);
                return;
              }
            }

            if (onChange) onChange(value, previous);
          }}
        </OnChange>

        <OnFocus name={input.name}>
          {() => {
            if (onFocus) {
              onFocus();
            }
          }}
        </OnFocus>
      </div>
    )}
  </Field>
);

MultiSelectField.defaultProps = {
  isSelectionAllowed: true,
  limit: null,
};

export default MultiSelectField;
