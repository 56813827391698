import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'ramda';

import { brandOverviewSelectors as selectors } from '../../../../modules/brandOverview';

import Section from '../Section';

import Highlights from './Highlights';

const WeeklyRecap = ({ data, loading, model }) => {
  if (loading || isEmpty(data)) return null;

  return (
    <Section>
      <Section.Title title="Weekly Recap" reportName="calendars" />
      <Highlights data={data} model={model} />
    </Section>
  );
};

WeeklyRecap.propTypes = {
  data: PropTypes.shape({
    days: PropTypes.arrayOf(PropTypes.shape({})),
    weeks: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  model: PropTypes.shape({ id: PropTypes.string, type: PropTypes.string }).isRequired,
};

const mapStateToProps = state => ({
  data: selectors.getData(state),
  loading: selectors.getLoading(state),
});

export default connect(mapStateToProps)(WeeklyRecap);
