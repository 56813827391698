import styled from "@emotion/styled";

const ListImage = styled("div")({}, ({ path }) => ({
  backgroundImage: `url(${path})`,
  backgroundSize: 'cover',
  borderRadius: '0 0 3px 3px',
  height: '48px',
  marginRight: '16px',
  width: '48px'
}))

export default ListImage
