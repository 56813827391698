import { assoc, merge } from 'ramda';

import * as types from './types';

export const INITIAL_STATE = {
  export: {
    total: 0,
    comparisonTotal: 0,
  },
  loading: true,
};

const startLoadingReducer = state => assoc('loading', true, state);
const stopLoadingReducer = state => assoc('loading', false, state);

const setTotalReducer = (state, action) =>
  merge(state, {
    export: {
      total: action.total,
      comparisonTotal: action.comparisonTotal,
    },
  });

const reducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case types.START_LOADING:
      return startLoadingReducer(state);
    case types.STOP_LOADING:
      return stopLoadingReducer(state);
    case types.SET_EXPORT_TOTAL_AMOUNT:
      return setTotalReducer(state, action);
    default:
      return state;
  }
};

export default reducer;
