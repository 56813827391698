import React from 'react';
import PropTypes from 'prop-types';
import md5 from 'md5';
import moment from 'moment';
import { connect } from 'react-redux';

import api from '../../lib/api';
import { teamsSelectors } from '../../modules/teams';
import { userSelectors } from '../../modules/user';

import { Box, Flex, Link, Text } from '../mc-ui';

import * as S from './styles';
import CommentForm from '../CommentForm';
import RemoveCommentConfirmationModal from './RemoveCommentConfirmationModal';

function trackEvent(event, data) {
  window.analytics.track(event, data);
}

class Comment extends React.Component {
  state = {
    isDeleting: false,
    isEditing: false,
    isRemoveModalOpen: false,
  };

  handleDelete = async event => {
    event.preventDefault();

    const { comment, emailId, listId, refetch, team, textMessageGuid } = this.props;
    const { isDeleting } = this.state;

    if (isDeleting) return;

    this.setState({ isDeleting: true });

    const params = {};
    if (emailId) {
      params.email_id = emailId;
    }
    if (textMessageGuid) {
      params.text_message_guid = textMessageGuid;
    }
    if (team) {
      params.team_id = team.id;
    }
    await api.delete(`/v3/lists/${listId}/comments/${comment.id}`, { params });

    const eventData = { list_id: listId };
    if (emailId) {
      eventData.email_id = emailId;
    }
    if (textMessageGuid) {
      eventData.text_message_guid = textMessageGuid;
    }
    trackEvent('Comment deleted', eventData);

    await refetch(true);
  };

  handleEdit = async values => {
    const { comment, emailId, listId, refetch, team, textMessageGuid } = this.props;

    const params = { ...values };
    if (emailId) {
      params.email_id = emailId;
    }
    if (textMessageGuid) {
      params.text_message_guid = textMessageGuid;
    }
    if (team) {
      params.team_id = team.id;
    }
    await api.patch(`/v3/lists/${listId}/comments/${comment.id}`, params);

    const eventData = { list_id: listId };
    if (emailId) {
      eventData.email_id = emailId;
    }
    if (textMessageGuid) {
      eventData.text_message_guid = textMessageGuid;
    }
    trackEvent('Comment edited', eventData);

    await refetch(true);
  };

  toggleEditMode = event => {
    if (event) {
      event.preventDefault();
    }

    this.setState(currentState => ({ isEditing: !currentState.isEditing }));
  };

  toggleRemoveModal = event => {
    if (event) {
      event.preventDefault();
    }

    this.setState(currentState => ({ isRemoveModalOpen: !currentState.isRemoveModalOpen }));
  };

  render() {
    const { comment, user } = this.props;
    const { isDeleting, isEditing, isRemoveModalOpen } = this.state;

    const [author] = comment.relationships.users.data;
    const isOwner = user.id === author.id;

    return (
      <div>
        <Box
          key={comment.id}
          borderBottom="1px solid"
          borderColor="dark5"
          padding="24px 0"
          css={{
            '&:last-child': { border: 'none' },
          }}
        >
          <Flex alignItems="center" marginBottom="8px">
            <S.UserAvatar
              src={`https://www.gravatar.com/avatar/${md5(
                (author.attributes.email || '').toLowerCase(),
              )}?s=50&d=mp`}
              alt={author.attributes.name}
            />

            <Box marginLeft="16px">
              <S.UserName>{author.attributes.name}</S.UserName>

              <Text
                fontSize="12px"
                title={moment(comment.attributes.created_at).format('MMM DD, YYYY - LT')}
              >
                {moment(comment.attributes.created_at).fromNow()}
              </Text>
            </Box>
          </Flex>

          {isEditing ? (
            <CommentForm
              editing
              initialValues={{ text: comment.attributes.text }}
              onCancel={this.toggleEditMode}
              onSubmit={this.handleEdit}
            />
          ) : (
            <Text color="dark08" whiteSpace="pre-line">
              {comment.attributes.text}
            </Text>
          )}

          {isOwner &&
            !isEditing && (
              <Flex alignItems="center" marginTop="16px" width="unset">
                <Link fontSize="12px" onClick={this.toggleEditMode} to="#edit">
                  Edit
                </Link>

                <Text
                  color="dark04"
                  fontSize="12px"
                  fontWeight="500"
                  lineHeight="12px"
                  margin="0 4px"
                >
                  •
                </Text>

                <Link fontSize="12px" onClick={this.toggleRemoveModal} to="#remove">
                  Remove
                </Link>
              </Flex>
            )}
        </Box>

        {isRemoveModalOpen && (
          <RemoveCommentConfirmationModal
            closeModal={this.toggleRemoveModal}
            isDeleting={isDeleting}
            removeComment={this.handleDelete}
          />
        )}
      </div>
    );
  }
}

Comment.defaultProps = {
  emailId: null,
  team: null,
  textMessageGuid: null,
};

Comment.propTypes = {
  comment: PropTypes.shape({}).isRequired,
  emailId: PropTypes.string,
  listId: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
  team: PropTypes.shape({
    id: PropTypes.string,
  }),
  textMessageGuid: PropTypes.string,
  user: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = state => ({
  team: teamsSelectors.teamSelector(state),
  user: userSelectors.getUser(state),
});

export default connect(mapStateToProps)(Comment);
