import { createSelector } from 'reselect';

const getState = state => state.weeklyRollUp;
const getUser = state => state.user;

export const getWeeklyRollUpIdSelector = createSelector(getUser, user => {
  const weeklyRollUp = user.included.filter(i => i.type === 'weekly_roll_ups')[0];
  if (weeklyRollUp) return weeklyRollUp.id;
  return null;
});

export const getWeeklyRollUpSelector = createSelector(getState, state => state.data[0] || {});

export const isEnabledSelector = createSelector(
  getWeeklyRollUpSelector,
  weeklyRollUp => !!weeklyRollUp.attributes.enabled,
);

export const isLoadingSelector = createSelector(getState, state => state.loading);

export const groupsSelector = createSelector(
  getWeeklyRollUpSelector,
  weeklyRollUp => weeklyRollUp.relationships.groups.data,
);

export const industryGroupsSelector = createSelector(getState, state => state.industryGroups);

export const customGroupsSelector = createSelector(getUser, user =>
  user.included.filter(i => i.type === 'groups' && !i.attributes.tracked),
);
