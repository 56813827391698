/** @jsx jsx */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment'
import { Link } from 'react-router';
import { jsx, withTheme } from '@emotion/react';

import { Box, Flex, Heading, ScrollableBox, Text } from '../../../../mc-ui';

import CalendarDay from './CalendarDay';

const HolidaysList = ({ collection: lists, theme }) => (
  <ScrollableBox height="385px" scrollBoxHeight="365px">
    {lists.map((list, index) => (
      <Link
        key={list.id}
        to={`/lists/${list.id}`}
        css={{
          textDecoration: 'none',
          '&:hover': { '& h5': { color: `${theme.colors.blue} !important` } },
        }}
      >
        <Flex marginBottom="20px">
          <CalendarDay date={list.attributes.occursAt} />

          <Box marginLeft="16px" width="unset">
            <Heading
              as="h5"
              css={{
                fontSize: '14px',
                letterSpacing: '0.02em',
                lineHeight: '1.25em',
                textTransform: 'capitalize',
              }}
            >
              {list.attributes.name}
            </Heading>

            <Text fontSize="12px">
              {moment(list.attributes.occursAt).fromNow()}
            </Text>
          </Box>
        </Flex>
      </Link>
    ))}
  </ScrollableBox>
);

HolidaysList.propTypes = {
  collection: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      type: PropTypes.string,
      attributes: PropTypes.shape({
        approximateEmails: PropTypes.number,
        name: PropTypes.string,
        updatedAt: PropTypes.string,
      }),
      relationships: PropTypes.shape({
        emails: PropTypes.shape({
          data: PropTypes.arrayOf(PropTypes.shape({})),
        }),
      }),
    }),
  ).isRequired,
  theme: PropTypes.shape({}).isRequired,
};

export default withTheme(HolidaysList);
