/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import DateRangePicker from '../DateRangePicker';

class DateRangePickerWrapper extends React.Component {
  state = {
    endDate: this.props.endDate,
    focusedInput: null,
    startDate: this.props.startDate,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const nextEndDate = nextProps.endDate ? nextProps.endDate.format('YYYY-MM-DD') : null;
    const nextStartDate = nextProps.startDate ? nextProps.startDate.format('YYYY-MM-DD') : null;
    const endDate = this.props.endDate ? this.props.endDate.format('YYYY-MM-DD') : null;
    const startDate = this.props.startDate ? this.props.startDate.format('YYYY-MM-DD') : null;

    if (nextEndDate !== endDate || nextStartDate !== startDate) {
      this.setState({ endDate: nextProps.endDate, startDate: nextProps.startDate });
    }
  }

  onChange = ({ endDate, startDate }) => {
    this.setState({ endDate, startDate });
    this.props.onChange({ endDate, startDate });
  };

  onChange = ({ endDate, startDate }) => {
    this.setState({ endDate, startDate });
  };

  onFocus = focusedInput => {
    this.setState({ focusedInput });
    this.props.onFocus();
  };

  render() {
    const { endDate, focusedInput, startDate, } = this.state;
    const { css, label, onChange, disabled, } = this.props;

    return (
      <DateRangePicker
        startDate={startDate}
        startDateId="reports-start-date"
        endDate={endDate}
        endDateId="reports-end-date"
        onDatesChange={this.onChange}
        onClose={onChange}
        focusedInput={focusedInput}
        onFocusChange={this.onFocus}
        disabled={disabled}
        css={css}
        label={label}
      />
    );
  }
}

DateRangePickerWrapper.propTypes = {
  css: PropTypes.shape({}),
  disabled: PropTypes.bool,
  endDate: PropTypes.any,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  startDate: PropTypes.any,
  label: PropTypes.string,
};

DateRangePickerWrapper.defaultProps = {
  css: {},
  disabled: false,
  endDate: moment().subtract(1, 'day'),
  onChange: () => {},
  onFocus: () => {},
  startDate: moment(),
  label: null,
};

export default DateRangePickerWrapper;
