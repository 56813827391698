import React from 'react';
import PropTypes from 'prop-types';
import { take } from 'ramda';
import { Link } from 'react-router';

import EmailThumbnail from '../../ui/EmailThumbnail';
import { Box } from '../../mc-ui';

// eslint-disable-next-line react/prop-types
function EmailLogo({ commentable }) {
  const email = {
    ...commentable,
    attributes: {
      ...commentable,
    },
  };

  // Use the absolute box to avoid thumbnail's native effects
  return (
    <Box height="40px" position="relative" width="40px">
      <EmailThumbnail email={email} skipLink tiny />
      <Box bottom="0" left="0" position="absolute" right="0" top="0" />
    </Box>
  );
}

// eslint-disable-next-line react/prop-types
function ListLogo({ commentable }) {
  const emails = take(2, commentable.related_emails);

  if (emails.length === 0) {
    return (
      <Box
        backgroundImage="/images/listCardBackground.png"
        border="1px solid"
        borderColor="dark5"
        borderRadius="4px"
        height="40px"
        width="40px"
        css={{
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      />
    );
  }

  if (emails.length === 1) {
    return <EmailLogo commentable={emails[0]} />;
  }

  return (
    <Box height="48px" position="relative" width="48px">
      {emails.map((email, index) => (
        <Box
          key={`CommentableLogo-ListLogo-${email.id}`}
          left={`${index * 8}px`}
          position="absolute"
          top={`${index * 8}px`}
        >
          <EmailLogo commentable={email} />
        </Box>
      ))}
    </Box>
  );
}

export default function CommentableLogo({ commentable }) {
  const url =
    commentable.type === 'lists'
      ? `/lists/${commentable.id}`
      : `/emails/${commentable.guid}?list_id=${commentable.list_id}&panel=notes`;

  if (commentable.type === 'emails') {
    return (
      <Link to={url}>
        <EmailLogo commentable={commentable} />
      </Link>
    );
  }

  return (
    <Link to={url}>
      <ListLogo commentable={commentable} />
    </Link>
  );
}

CommentableLogo.propTypes = {
  commentable: PropTypes.shape({}).isRequired,
};
