import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';

import isQueryEmpty from '../isQueryEmpty';

import Brands from './Brands';
import EmptyQuery from './EmptyQuery';
import NoResultsFound from './NoResultsFound';

const Results = ({
  brands,
  router: {
    location: { query },
  },
  ...props
}) => {
  if (isQueryEmpty(query)) {
    return <EmptyQuery />;
  } else if (isEmpty(brands) || isEmpty(brands.data)) {
    return <NoResultsFound />;
  }

  return <Brands collection={brands} {...props} />;
};

Results.propTypes = {
  brands: PropTypes.shape({}).isRequired,
  router: PropTypes.shape({
    location: PropTypes.shape({
      query: PropTypes.shape({}),
    }),
  }).isRequired,
};

export default Results;
