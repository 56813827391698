/* eslint-disable react/prop-types */
import React from 'react';
import pluralize from 'pluralize';
import { components } from 'react-select';

import { Text } from '../../../../mc-ui';

const MenuList = ({ limit }) => props => {
  const values = props.getValue();
  const selectedCount = values.length;
  const limitReached = selectedCount === limit;
  let content = `${selectedCount} ${pluralize('brand', selectedCount)} selected (${limit} max)`;

  if (limitReached) {
    content = 'Maximum number of brands selected';
  }

  return (
    <components.MenuList {...props}>
      <Text css={{ lineHeight: '30px', margin: 0, padding: '0 12px' }}>{content}</Text>
      {props.children}
    </components.MenuList>
  );
};

export default MenuList;
