import React from 'react';
import PropTypes from 'prop-types';
import slugify from 'slugify';

import { www } from '../../lib/utils';

import { Box, Heading, Link, Text } from '../mc-ui';

export default function InfoView({ handlePrivacy, isOwner, list }) {
  const href = list.attributes.featured
    ? www(`/email-examples/${list.id}/${slugify(list.attributes.name || '').toLowerCase()}`)
    : www(`/lists/${list.attributes.guid}`);

  let collectionsName = '';
  let collectionsPath = '';

  if (list.attributes.approximate_text_messages > 0) {
    if (isOwner) {
      collectionsName = 'Custom SMS Lists';
      collectionsPath = '/my-collections/text-message-lists';
    } else {
      collectionsName = 'Collections';
      collectionsPath = '/text-messages?sort=sent_at:desc';
    }
  } else if (isOwner) {
    collectionsName = 'Custom Lists';
    collectionsPath = '/my-collections/email-lists';
  } else {
    collectionsName = 'Collections';
    collectionsPath = '/emails';
  }

  // TODO: Update `/emails` link from line 28 to the page of curated lists (not developed yet)

  return (
    <Box flex="1" marginRight="32px" width="unset">
      <Text
        color="dark1"
        fontSize="14px"
        fontWeight="500"
        letterSpacing="0.02em"
        lineHeight="1.25em"
      >
        <Link to={collectionsPath}>{collectionsName}</Link> /
      </Text>

      <Heading as="h2" color="dark1" fontSize="32px" fontWeight="300" lineHeight="1.25em">
        {list.attributes.name}
      </Heading>

      {list.attributes.description && <Text margin="6px 0 0 0">{list.attributes.description}</Text>}

      {list.attributes.state === 'private' ? (
        <Text fontSize="12px" margin="6px 0 0 0">
          <Link to="#share" fontSize="12px" color="dark04" onClick={handlePrivacy}>
            Click here to generate a shareable link.
          </Link>
        </Text>
      ) : (
        <Text fontSize="12px" margin="6px 0 0 0">
          Share:{' '}
          <a href={href} fontSize="12px">
            {href}
          </a>
        </Text>
      )}
    </Box>
  );
}

InfoView.propTypes = {
  handlePrivacy: PropTypes.func.isRequired,
  isOwner: PropTypes.bool.isRequired,
  list: PropTypes.shape({
    attributes: PropTypes.shape({
      description: PropTypes.string,
      name: PropTypes.string,
    }),
  }).isRequired,
};
