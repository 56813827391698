import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';

import { teamsActions, teamsSelectors } from '../../modules/teams';

import { Box, Button } from '../mc-ui';
import BillingInfoFormFields from './BillingInfoFormFields';

const INITIAL_VALUES = {
  card: {
    exp_month: moment()
      .add(1, 'month')
      .format('MM'),
    exp_year: moment().format('YY'),
  },
};

class BillingInfo extends React.Component {
  handleSubmit = (values, form, callback) => {
    this.props.updateBillingInfo(values, form, callback);
  };

  initialValues = () => {
    const { subscription } = this.props;

    return {
      ...INITIAL_VALUES,
      billing_info: {
        ...subscription.attributes.stripe_billing_information,
      },
    };
  };

  render() {
    const { subscription } = this.props;

    return (
      <Form initialValues={this.initialValues()} onSubmit={this.handleSubmit}>
        {({ handleSubmit, pristine, submitting }) => (
          <form onSubmit={handleSubmit}>
            <BillingInfoFormFields subscription={subscription} />

            <Box>
              <Button
                disabled={submitting || pristine}
                type="submit"
                variant="primary"
                css={{
                  height: '32px',
                  lineHeight: '32px',
                  margin: '0 16px 0 0',
                  padding: 0,
                  width: '136px',
                }}
              >
                Update and save
              </Button>
            </Box>
          </form>
        )}
      </Form>
    );
  }
}

BillingInfo.propTypes = {
  subscription: PropTypes.shape({}).isRequired,
  updateBillingInfo: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  subscription: teamsSelectors.subscriptionSelector(state),
});

const mapDispatchToProps = dispatch => ({
  updateBillingInfo: (payload, form, resolve) =>
    dispatch(teamsActions.subscriptionUpdateBillingInfoRequest(payload, form, resolve)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BillingInfo);
