import React from 'react'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import api from '../lib/api'
import humanize from 'humanize-number'

import EmailImage from './EmailImage'
import Header from './Header'
import Section from './Section'

import Container from './Container'

class Browse extends React.Component {
  state = {
    q: ''
  }
  handleSubmit = (event) => {
    event.preventDefault()
    this.props.router.push({
      pathname: this.props.router.location.pathname,
      query: { q: this.state.q }
    })
  }
  handleChange = (event) => {
    event.preventDefault()
    this.setState({ q: event.target.value })
  }
  UNSAFE_componentWillReceiveProps(props) {
    let q = props.location.query.q || ''
    if (this.state.q !== q) this.setState({ q })
  }
  UNSAFE_componentWillMount() {
    let q = this.props.router.location.query.q || ''
    this.setState({ q })
  }
  render() {
    return (
      <div className='pure-u-1'>
        <div
          style={{
            backgroundImage: 'url(/browse-bg.png)',
            backgroundSize: '100%',
          }}
        >
          <Header style={{ background: 'none', paddingBottom: 50, }}>
            <h1>Search for emails</h1>
            <div className='pure-u-1'>
              <form className='pure-form' onSubmit={this.handleSubmit}>
                  <div className='pure-u-1 pure-u-md-4-5'>
                  <input
                    id='search'
                    className='pure-u-1'
                    placeholder=''
                    onChange={this.handleChange}
                    style={{ fontSize: '1.25em' }}
                    value={this.state.q}
                  />
                  </div>
                  <div className='pure-u-1 pure-u-md-1-5'>
                  <input
                    type='submit'
                    id='search'
                    className='pure-button pure-button-primary pure-u-1'
                    onChange={this.handleChange}
                    style={{ fontSize: '1.25em' }}
                    value={'Search'}
                  />
                  </div>
              </form>
            </div>
          </Header>
        </div>
      </div>
    )
  }
}

export default connect()(Browse)
