import { createSelector } from 'reselect';

const getUser = state => state.user;
export const getState = state => state.savedJourneys;

export const teamSelector = createSelector(getUser, user =>
  user.included.find(i => i.type === 'teams' && i.attributes.status === 'active'),
);

export const loadingSelector = type => createSelector(getState, state => !!state[type].loading);
export const dataSelector = type => createSelector(getState, state => state[type].data);
export const errorSelector = type => createSelector(getState, state => state[type].error);

export const isInMySelector = id =>
  createSelector(
    getState,
    state => !!(state.my.data.data || []).find(j => Number(j.id) === Number(id)),
  );

export const isInTeamSelector = id =>
  createSelector(
    getState,
    state => !!(state.team.data.data || []).find(j => Number(j.id) === Number(id)),
  );
