/** @jsx jsx */
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { jsx, withTheme } from '@emotion/react';

import { DateRangePicker, Flex } from '../../../mc-ui';

import CompareDateSelect from '../../CompareDateSelect';

const DatesForm = ({ router, selectedDates: { endDate, intervalLabel, startDate }, theme }) => (
  <form>
    <Flex alignItems="center" css={{ marginBottom: '10px' }}>
      <div
        css={{ width: '252px' }}
      >
        <DateRangePicker
          label={intervalLabel}
          startDate={moment(startDate)}
          startDateId="reports-compare-start-date"
          endDate={moment(endDate)}
          endDateId="reports-compare-end-date"
          onDatesChange={() => {}}
          onFocusChange={() => {}}
          disabled
          css={{
            borderColor: theme.colors.blue,
            '& > label': {
              color: theme.colors.blue,
            },
          }}
        />
      </div>
      <span
        css={{
          color: theme.colors.dark06,
          fontSize: '12px',
          margin: '0 8px',
        }}
      >
        vs
      </span>
      <CompareDateSelect router={router} />
    </Flex>
  </form>
);

DatesForm.propTypes = {
  router: PropTypes.shape({}).isRequired,
  selectedDates: PropTypes.shape({
    endDate: PropTypes.string,
    intervalLabel: PropTypes.string,
    startDate: PropTypes.string,
  }).isRequired,
  theme: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => ({
  selectedDates: {
    endDate: state.newReports.dates.endDate,
    intervalLabel: state.newReports.dates.intervalLabel,
    startDate: state.newReports.dates.startDate,
  },
});

export default connect(mapStateToProps)(withTheme(DatesForm));
