const prefix = 'mc/newReports';

export const CHANGE_DATES = `${prefix}/CHANGE_DATES`;
export const CHANGE_COMPARISON_DATES = `${prefix}/CHANGE_COMPARISON_DATES`;

export const INITIALIZE_DATES = `${prefix}/INITIALIZE_DATES`;
export const INITIALIZE_COMPARISON_DATES = `${prefix}/INITIALIZE_COMPARISON_DATES`;

export const SAVE_DATES = `${prefix}/SAVE_DATES`;
export const SAVE_COMPARISON_DATES = `${prefix}/SAVE_COMPARISON_DATES`;

export const UPDATE_INTERVAL = `${prefix}/UPDATE_INTERVAL`;
export const UPDATE_COMPARISON_INTERVAL = `${prefix}/UPDATE_COMPARISON_INTERVAL`;

export const GENERATE_COMPARISON_INTERVALS = `${prefix}/GENERATE_COMPARISON_INTERVALS`;
export const UPDATE_COMPARISON_INTERVALS = `${prefix}/UPDATE_COMPARISON_INTERVALS`;
