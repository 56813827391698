import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { sendingBehaviorSelectors as selectors } from '../../../../modules/newReports/sendingBehavior';

import { Loader } from '../../../mc-ui';

import ReportSection from '../../ReportSection';
import Title from '../../Title';

import DaysChart from './DaysChart';

const PopularSendDayByDays = ({ loading, model, ...props }) => (
  <ReportSection css={{ width: '407px', height: '453px' }}>
    {loading ? (
      <Title>&nbsp;</Title>
    ) : (
      <Title>{model.type === 'groups' ? 'By Days' : 'Most popular send day'}</Title>
    )}
    {loading ? <Loader /> : <DaysChart model={model} {...props} />}
  </ReportSection>
);

PopularSendDayByDays.propTypes = {
  loading: PropTypes.bool.isRequired,
  model: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    type: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state, { model }) => {
  if (!model.id) return { loading: true };

  const { endDate, startDate } = state.newReports.dates;

  return {
    loading: selectors.getLoading(model.id, model.type, startDate, endDate)(state),
  };
};

export default connect(mapStateToProps)(PopularSendDayByDays);
