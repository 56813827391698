/* eslint-disable react/prop-types */

import React from 'react';
import styled from "@emotion/styled";

import { Flex } from '../mc-ui';

const ReportSection = styled("div")(({ theme, css = {} }) => ({
  backgroundColor: theme.colors.white,
  borderRadius: '4px',
  boxSizing: 'border-box',
  marginBottom: '24px',
  padding: '32px',
  width: '100%',
  ...css,
}));

ReportSection.Header = ({ children, justifyContent = 'space-between', ...props }) => (
  <Flex justifyContent={justifyContent} {...props}>
    {children}
  </Flex>
);
ReportSection.Body = styled("div")({ padding: '24px 0' });
ReportSection.Footer = styled("div")(({ theme, padding }) => ({
  color: theme.colors.dark06,
  fontFamily: theme.font.family,
  fontSize: '14px',
  fontWeight: '500',
  lineHeight: '1.25em',
  padding: padding || '32px 0 0 0',
  '& a': {
    color: theme.colors.blue,
    textDecoration: 'none',
  },
}));

export default ReportSection;
