import styled from "@emotion/styled";
import { keyframes } from '@emotion/css';


const moveBar = keyframes({
  '0%': { backgroundPosition: '15px 15px' },
  to: { backgroundPosition: '0 0' },
});

const LoaderBar = styled("div")(({ theme, hasIcon }) => ({
  animation: `${moveBar} 1.5s linear infinite reverse`,
  background: `linear-gradient(-45deg, ${theme.colors.blueMedium} 25%, ${
    theme.colors.blueLight
  } 0, ${theme.colors.blueLight} 50%, ${theme.colors.blueMedium} 0, ${
    theme.colors.blueMedium
  } 75%, ${theme.colors.blueLight} 0, ${theme.colors.blueLight})`,
  backgroundSize: '15px 15px',
  borderRadius: '4px',
  content: '""',
  height: '8px',
  marginTop: hasIcon ? '15px' : null,
  position: 'relative',
  width: '120px',

  '&[data-text]:after': {
    bottom: 'calc(50% - 35px)',
    color: theme.colors.dark04,
    content: 'attr(data-text)',
    fontFamily: theme.font.family,
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    left: '0',
    letterSpacing: '0.08em',
    lineHeight: '1.56em',
    position: 'absolute',
    textAlign: 'center',
    textTransform: 'uppercase',
    width: '100%',
  },
}));

export default LoaderBar;
