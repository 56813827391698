import styled from "@emotion/styled";

const InputWrapper = styled("div")(({ theme }) => ({
  position: 'relative',
  '& [data-tooltip="wrapper"]': {
    position: 'absolute',
    right: '10px',
    top: '8px',
    '& svg': {
      color: theme.colors.red1,
    },
  },
}));

export default InputWrapper;
