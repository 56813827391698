import React from 'react';

import Section from '../Section';

import { Box, Flex } from '../mc-ui';

import BrowseByIndustries from '../BrowseByIndustries';

import CustomerJourneysList from './CustomerJourneysList';
import FeaturedHolidays from './FeaturedHolidays';
import FeaturedJourneyEmails from './FeaturedJourneyEmails';
import Lists from './Lists';
import UpcomingHolidays from './UpcomingHolidays';
import UpgradeToPro from './UpgradeToPro';

const EmailsIndex = () => (
  <div>
    <Section>
      <Box paddingBottom="40px" paddingTop="25px">
        <Flex alignItems="flex-start" justifyContent="space-between" marginBottom="16px">
          <FeaturedJourneyEmails />
          <CustomerJourneysList />
        </Flex>
        <UpgradeToPro type="journeyEmails" />
        <Flex
          alignItems="flex-start"
          justifyContent="space-between"
          marginBottom="16px"
          marginTop="40px"
        >
          <UpcomingHolidays />
          <FeaturedHolidays />
        </Flex>

        <UpgradeToPro type="historicalData" />
      </Box>
    </Section>

    <Section outerBackground="#FFF">
      <BrowseByIndustries type="companies" />
      <Lists />
    </Section>
  </div>
);

export default EmailsIndex;
