import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import NavigationDropdown from '../NavigationDropdown';

import NavigationItem from './NavigationItem';

const capitalize = str =>
  str
    .replace(/\_/, ' ')
    .split(' ')
    .reduce((result, word) => {
      result.push(`${word.charAt(0).toUpperCase()}${word.slice(1)}`);
      return result;
    }, [])
    .join(' ');

class Journeys extends React.Component {
  UNSAFE_componentWillMount() {
    const {
      navigation: { journeys },
      requestJourneys,
    } = this.props;

    if (!journeys.loading && journeys.data.length === 0) {
      requestJourneys();
    }
  }

  render() {
    const {
      navigation: { journeys },
    } = this.props;

    return (
      <NavigationItem label="Journeys" to="/journeys">
        <NavigationDropdown>
          <NavigationDropdown.Item to="/journeys">Journeys Home</NavigationDropdown.Item>
          <NavigationDropdown.Item to="/journeys/results?sort=-start_date&status=classified">
            All Journeys
          </NavigationDropdown.Item>
          <NavigationDropdown.Divider />
          {journeys.loading ? (
            <NavigationDropdown.Text>Loading journeys...</NavigationDropdown.Text>
          ) : (
            journeys.meta.journey_types.map(journeyType => (
              <NavigationDropdown.Item
                key={`navigation-item-journeys-${journeyType.type}`}
                to={`/journeys/results?journey_type=${
                  journeyType.type
                }&sort=-start_date&status=classified`}
              >
                {capitalize(journeyType.type)}
              </NavigationDropdown.Item>
            ))
          )}
          <NavigationDropdown.Item to="/journeys/results?sort=-start_date&status=classified">
            View All Journeys
          </NavigationDropdown.Item>
        </NavigationDropdown>
      </NavigationItem>
    );
  }
}

Journeys.propTypes = {
  navigation: PropTypes.shape({}).isRequired,
  requestJourneys: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  navigation: state.navigation,
});

const mapDispatchToProps = dispatch => ({
  requestJourneys: () => dispatch({ type: 'REQUEST_NAV_JOURNEYS' }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Journeys);
