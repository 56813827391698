import React from 'react'

const Custom = props => (
  <svg viewBox='0 0 40 40' width='1em' height='1em' {...props}>
    <g fill='none' fillRule='evenodd'>
      <circle fill='#FFC027' cx={20} cy={20} r={20} />
      <g
        transform='translate(8 18)'
        stroke='#FFF'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={1.6}
      >
        <circle cx={2.5} cy={2.5} r={2.5} />
        <circle cx={11.833} cy={2.5} r={2.5} />
        <circle cx={21.167} cy={2.5} r={2.5} />
      </g>
    </g>
  </svg>
)

export default Custom
