const planName = id => {
  switch (id) {
    case 'free':
      return 'Free';
    case 'XVcyr':
    case 'fyWet':
      return 'Starter';
    case 'bQkuw':
    case 'RyCee':
      return 'Business';
    case 'LTDgn':
    case 'ENrky':
      return 'Enterprise';
    default:
      return 'Custom';
  }
};

export default planName;
