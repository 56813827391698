import React from 'react';

import Section from '../Section';

import { Box, Flex, Heading } from '../mc-ui';

import BrowseByIndustries from '../BrowseByIndustries';

import FeaturedJourneys from './FeaturedJourneys';
import FilterBar from './FilterBar';
import FutureJourneys from './FutureJourneys';
import Lists from './Lists';
import Questions from './Questions';

const JourneysIndex = () => (
  <div>
    <Section>
      <Box paddingBottom="40px" paddingTop="25px">
        <Heading as="h3" css={{ fontWeight: '300', marginBottom: '20px' }}>
          Search <strong>journeys</strong>
        </Heading>

        <FilterBar />

        <Flex alignItems="flex-start" justifyContent="space-between" marginBottom="16px">
          <FeaturedJourneys />
          <FutureJourneys />
        </Flex>

        <Questions />
      </Box>
    </Section>

    <Section outerBackground="#FFF">
      <BrowseByIndustries type="journeys" />
      <Lists />
    </Section>
  </div>
);

export default JourneysIndex;
