import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isNil } from 'ramda';
import { connect } from 'react-redux';

import { calendars } from '../../../../../../../modules/newReports';

import { shallowEqual } from '../../../../../../../lib/utils';
import { Loader } from '../../../../../../mc-ui';
import Modal from '../../../../../../Modal';

// import mockBrands from './mockBrands';
import BrandsEmails from './BrandsEmails';

// const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

class DayModal extends React.Component {
  componentDidMount() {
    if (!isNil(this.props.model.id)) {
      this.request();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.model.id) return;
    const nextModel = { id: nextProps.model.id, type: nextProps.model.type };
    const model = { id: this.props.model.id, type: this.props.model.type };

    if (!shallowEqual(nextModel, model)) {
      this.request();
    }
  }

  closeModal = () => {
    if (!this.props.loading) this.props.closeModal();
  };

  request = async () => {
    this.props.requestData();
  };

  render() {
    const { data, date, loading } = this.props;
    const isLoading = loading || isEmpty(data);

    return (
      <Modal onClose={this.closeModal} modalStyle={{ maxHeight: 530, width: 520 }} useNewComponent>
        <Modal.Header title={date.format('MMMM DD, YYYY')} handleClose={this.closeModal} />
        <Modal.Body maxHeight="530px" padding="0 32px 12px 32px" customScrollbar>
          {isLoading ? <Loader /> : <BrandsEmails data={data} date={date} />}
          {isLoading && <Loader />}
        </Modal.Body>
      </Modal>
    );
  }
}

DayModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  data: PropTypes.shape({}).isRequired,
  date: PropTypes.shape({}).isRequired,
  loading: PropTypes.bool.isRequired,
  model: PropTypes.shape({ id: PropTypes.string, type: PropTypes.string }).isRequired,
  requestData: PropTypes.func.isRequired,
};

const mapStateToProps = (state, { date, model, selectedDate }) => {
  let data;
  let loading;
  if (model.type === 'groups') {
    data = calendars.calendarsSelectors.getGroupModalData(
      model.id,
      selectedDate.format('YYYY-MM-DD'),
      date.format('YYYY-MM-DD'),
    )(state);

    loading = calendars.calendarsSelectors.getGroupModalLoading(
      model.id,
      selectedDate.format('YYYY-MM-DD'),
      date.format('YYYY-MM-DD'),
    )(state);
  } else {
    data = calendars.calendarsSelectors.getBrandModalData(
      model.id,
      selectedDate.format('YYYY-MM-DD'),
      date.format('YYYY-MM-DD'),
    )(state);
    loading = calendars.calendarsSelectors.getBrandModalLoading(
      model.id,
      selectedDate.format('YYYY-MM-DD'),
      date.format('YYYY-MM-DD'),
    )(state);
  }

  return { data, loading };
};

const mapDispatchToProps = (dispatch, { date, model, selectedDate }) => ({
  requestData: () => {
    const requestAction =
      model.type === 'groups'
        ? calendars.calendarsActions.fetchGroupModalDataRequest
        : calendars.calendarsActions.fetchBrandModalDataRequest;

    dispatch(requestAction(model.id, selectedDate.format('YYYY-MM-DD'), date.format('YYYY-MM-DD')));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DayModal);
