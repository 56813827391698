import PropTypes from 'prop-types';
import { take } from 'ramda';
import React from 'react';
import { Box, Flex, Text, theme } from '../../mc-ui';
import BrandLogo from '../BrandLogo';

const getPosition = index => {
  if (index === 0) return '0';
  if (index === 1) return '87px';
  return null;
};

const TextMessagesBackground = ({ list }) => {
  const textMessages = take(
    2,
    (list.relationships['text-messages'] || list.relationships.textMessages || { data: [] }).data,
  );

  return (
    <Box left="0" height="171px" overflow="hidden" position="absolute" top="0" width="245px">
      <Box
        backgroundImage={textMessages.length === 0 ? '/images/listCardBackground.png' : null}
        height="171px"
        overflow="hidden"
        position="relative"
        width="245px"
      >
        {textMessages.map((textMessage, index) => {
          const brandId = textMessage.attributes.company_id || textMessage.attributes.companyId;

          return (
            <Box
              key={textMessage.id}
              borderRadius="4px"
              boxShadow={index === 1 ? `0px 1px 4px ${theme.colors.dark02}` : null}
              height="240px"
              left={getPosition(index)}
              position="absolute"
              right={index === 3 ? '0' : null}
              top="0"
              width="160px"
            >
              <Flex
                alignItems="center"
                backgroundColor="white"
                border="1px solid transparent"
                borderBottom="none"
                borderColor="dark5"
                borderRadius="4px"
                borderTop="none"
                direction="column"
              >
                <Flex justifyContent="center" padding="12px 0 8px 0">
                  <BrandLogo brandId={brandId} css={{ height: '32px', width: '32px' }} />
                </Flex>

                <Box
                  backgroundColor="dark5"
                  borderRadius="16px 16px 0 0"
                  padding="8px 8px 0 8px"
                  minHeight="120px"
                  width="140px"
                >
                  <Text color="dark1" fontSize="10px" fontWeight="400" lineHeight="14px">
                    {textMessage.attributes.full_text || textMessage.attributes.fullText}
                  </Text>
                </Box>
              </Flex>
            </Box>
          );
        })}

        {textMessages.length > 0 && (
          <Box
            bottom="0"
            left="0"
            height="78px"
            position="absolute"
            css={{
              background:
                'linear-gradient(360deg, rgba(0, 0, 0, 0) 65.21%, rgba(0, 0, 0, 0.2) 100%)',
              transform: 'rotate(-180deg)',
            }}
          />
        )}
      </Box>
    </Box>
  );
};

TextMessagesBackground.propTypes = {
  list: PropTypes.shape({}).isRequired,
};

export default TextMessagesBackground;
