import * as React from 'react';
import PropTypes from 'prop-types';

function WarningTriangleIcon({ fillOpacity, ...props }) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M20.626 21a.602.602 0 00.53-.885L12.142 3.292a.627.627 0 00-1.06 0L2.073 20.115a.6.6 0 00.53.885h18.024zm-9.013-2.203a1.202 1.202 0 110-2.404 1.202 1.202 0 010 2.404zm0-9.813a.801.801 0 01.802.8v4.379a.8.8 0 11-1.603 0V9.785a.801.801 0 01.802-.801z"
        fill="currentColor"
        fillOpacity={fillOpacity}
      />
    </svg>
  );
}

WarningTriangleIcon.propTypes = {
  fillOpacity: PropTypes.number,
};

WarningTriangleIcon.defaultProps = {
  fillOpacity: 1,
};

export default WarningTriangleIcon;
