/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link as RRLink } from 'react-router';
import { isEmpty, isNil } from 'ramda';

import BrandLogo from '../../../ui/BrandLogo.js';
import CalendarStartIcon from '../../../mc-ui/icons/CalendarStartIcon';
import ClassifiedBadge from '../../../NewJourneys/ShowJourney/ClassifiedBadge.js';
import EmailIcon from '../../../mc-ui/icons/EmailIcon';
import { Box, Flex, Heading, Link, Text, theme } from '../../../mc-ui';

const isNull = value => isEmpty(value) || isNil(value);

export default function ExploreEmailJourneys({ email, router }) {
  const { query } = router.location;
  const [brand] = (email.relationships.companies || { data: [] }).data;
  const journeys = (email.relationships.journeys || { data: [] }).data.filter(
    j => `${j.id}` !== `${query.journey_id}`,
  );

  if (isEmpty(journeys)) return null;

  return (
    <Box marginBottom="24px">
      <Heading
        as="h5"
        color="dark08"
        fontWeight="bold"
        lineHeight="1.56em"
        css={{ fontSize: '10px', letterSpacing: '0.12em' }}
      >
        Journeys
      </Heading>

      <Flex justifyContent="space-between" wrap="wrap">
        {journeys.map(journey => (
          <RRLink
            key={`EmailExplore-Journey${journey.id}`}
            to={`/companies/${brand.id}/journeys/${journey.id}`}
            css={{
              color: 'currentColor',
              cursor: 'pointer',
              textDecoration: 'none',
            }}
          >
            <Box
              borderRadius="4px"
              marginTop="16px"
              padding="4px 0"
              width="206px"
              css={{
                '&:hover': {
                  backgroundColor: theme.colors.dark6,
                },
              }}
            >
              <Flex height="40px" marginBottom="8px">
                <BrandLogo brandId={brand.id} />
                <Flex
                  alignItems="flex-start"
                  direction="column"
                  justifyContent="space-between"
                  paddingLeft="8px"
                >
                  <Link to={`/companies/${brand.id}`}>{brand.attributes.name}</Link>
                  <ClassifiedBadge
                    classification={{ type: journey.attributes.type }}
                    css={{
                      boxShadow: 'none',
                      left: 0,
                      position: 'relative',
                      top: 0,
                    }}
                  />
                </Flex>
              </Flex>
              <Box>
                <Flex alignItems="center" color="dark06" height="20px" marginBottom="6px">
                  <CalendarStartIcon fillOpacity={1} height="20px" width="20px" />
                  <Text
                    color="dark06"
                    fontSize="12px"
                    lineHeight="20px"
                    margin="0 0 0 8px"
                    css={{ '& > span': { color: theme.colors.yellowDark } }}
                  >
                    {isNull(journey.attributes.start_date) ||
                    journey.attributes.start_date.toLowerCase() === 'tbd' ? (
                      <span>TBD</span>
                    ) : (
                      `Start date: ${moment(journey.attributes.start_date).format('DD MMM YYYY')}`
                    )}
                  </Text>
                </Flex>
                <Flex alignItems="center" color="dark06" height="20px">
                  <EmailIcon fillOpacity={1} height="20px" width="20px" />
                  <Text color="dark06" fontSize="12px" lineHeight="20px" margin="0 0 0 8px">
                    Triggered emails: {journey.attributes.approximate_triggered_emails}
                  </Text>
                </Flex>
              </Box>
            </Box>
          </RRLink>
        ))}
      </Flex>
    </Box>
  );
}

ExploreEmailJourneys.propTypes = {
  email: PropTypes.shape({
    relationships: PropTypes.shape({
      companies: PropTypes.shape({
        data: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            attributes: PropTypes.shape({
              name: PropTypes.string,
            }),
          }),
        ),
      }),
      journeys: PropTypes.shape({
        data: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            attributes: PropTypes.shape({
              approximate_triggered_emails: PropTypes.number,
              start_date: PropTypes.string,
              type: PropTypes.string,
            }),
          }),
        ),
      }),
    }),
  }).isRequired,
  router: PropTypes.shape({
    location: PropTypes.shape({
      query: PropTypes.shape({
        journey_id: PropTypes.string,
      }),
    }),
  }).isRequired,
};
