import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import styled from "@emotion/styled";
import ReactTooltip from 'react-tooltip';

import { palette, getColorForEmail } from '../lib/color';

import JourneyGridDailyDetails from './JourneyGridDailyDetails';

const MAX_DAYS = 30;
const COLS = 7;
const columnWidth = `${100 / COLS}%`;
let gridTemplateColumns = '';

const JOURNEY_KEY_LABEL_SIZE = 24;

for (let i = 0; i < COLS; i += 1) {
  gridTemplateColumns += `${columnWidth} `;
}

const styles = {
  week: {
    position: 'relative',
    display: 'grid',
    width: '100%',
    gridTemplateColumns,
  },
  day: {
    display: 'flex',
    flexDirection: 'column',
    border: `2px solid ${palette.white}`,
    minHeight: 80,
  },
  dayLabel: {
    display: 'block',
    margin: '16px 16px 8px 16px',
    fontSize: `${12 / 16}em`,
    color: palette.gray8,
  },
};

class JourneyGrid extends React.Component {
  // Get all emails + actions for one day
  getDailyActivity = (date, journeyWithDetails) => journeyWithDetails.included.filter(item => moment(item.attributes.date).isSame(moment(date), 'day'))

  // Generate components for a journey for one day
  getDailyActivityElems = (journeyWithDetails, dayIndex, backgroundColor) => {
    const startDate = moment(journeyWithDetails.data[0].attributes.first_activity_at);
    const date = startDate.clone().add(dayIndex, 'days');
    const dailyActivity = this.getDailyActivity(date, journeyWithDetails) || [];
    const events = dailyActivity.filter(item => item.type === 'events');
    const emails = dailyActivity.filter(item => item.type === 'emails');

    return (<JourneyGridDailyDetails
      events={events}
      emails={emails}
      backgroundColor={backgroundColor}
      key={`${journeyWithDetails.data[0].id}_${dayIndex}`}
    />);
  }

  getToolTipContent = (journey) => {
    return (
      <div style={{ lineHeight: 1.4, textAlign: 'left' }}>
        <div>{journey.data[0].attributes.company_name}</div>
        <div>{this.transformTypeIdToLabel(journey.data[0].attributes.journey_type)}</div>
        <div>{moment(journey.data[0].attributes.first_activity_at).format('MMM. D, YYYY')}</div>
      </div>
    );
  }

  transformTypeIdToLabel = (journeyType) => {
    let label = journeyType.replace('_', ' ');
    // Capitalize first word
    label = label.charAt(0).toUpperCase() + label.slice(1);
    return label;
  };

  render() {
    if (!this.props.journeysWithDetails || this.props.journeysWithDetails.length === 0) return null;

    const GridWrapper = styled("div")({
      border: `1px solid ${palette.white}`,
    });
    const weeks = [];
    for (let weekIndex = 0; weekIndex < (MAX_DAYS / 7); weekIndex += 1) {
      const WeekWrapper = styled("div")(styles.week);
      const daysOfWeek = [];
      const startDay = weekIndex * 7;
      const endDay = (weekIndex * 7) + 7;
      for (let dayIndex = startDay; dayIndex < endDay && dayIndex < MAX_DAYS; dayIndex += 1) {
        const dailyDetailsForAllJourneys = this.props.journeysWithDetails.map((journey, stripeIndex) => this.getDailyActivityElems(journey, dayIndex, `hsla(208, 22%, 80%, ${0.15 * stripeIndex})`));
        const Day = styled("div")(styles.day);
        const DayLabel = styled("div")(styles.dayLabel);
        const assembled = (
          <Day key={dayIndex}>
            <DayLabel>Day {dayIndex + 1}</DayLabel>
            {dailyDetailsForAllJourneys}
          </Day>
        );
        daysOfWeek.push(assembled);
      }
      let journeyKeyLabels = null;
      if (this.props.journeysWithDetails.length > 1) {
        const JourneyKeyLabels = styled("div")({
          position: 'absolute',
          left: JOURNEY_KEY_LABEL_SIZE / -2,
          top: 2,
          bottom: 2,
        });
        const JourneyKeyLabel = styled("div")({
          position: 'absolute',
          left: 0,
          height: JOURNEY_KEY_LABEL_SIZE,
          width: JOURNEY_KEY_LABEL_SIZE,
          lineHeight: `${JOURNEY_KEY_LABEL_SIZE}px`,
          textAlign: 'center',
          borderRadius: 4,
          color: palette.white,
          cursor: 'pointer',
        });
        journeyKeyLabels = (
          <JourneyKeyLabels>
            <JourneyKeyLabel
              data-tip
              data-for="A"
              style={{
                background: getColorForEmail(this.props.journeysWithDetails[0].data[0].attributes.description),
                top: '25%',
              }}
            >
              A
              <ReactTooltip id="A" type="dark" effect="solid">
                { this.getToolTipContent(this.props.journeysWithDetails[0]) }
              </ReactTooltip>
            </JourneyKeyLabel>
            <JourneyKeyLabel
              data-tip
              data-for="B"
              style={{
                background: getColorForEmail(this.props.journeysWithDetails[1].data[0].attributes.description),
                top: '69%',
              }}
            >
              B
            </JourneyKeyLabel>
            <ReactTooltip id="B" type="dark" effect="solid">
              { this.getToolTipContent(this.props.journeysWithDetails[1]) }
            </ReactTooltip>
          </JourneyKeyLabels>
        );
      }
      weeks.push(
        <WeekWrapper key={weekIndex}>
          {journeyKeyLabels}
          {daysOfWeek}
        </WeekWrapper>
      );
    }
    return (
      <GridWrapper>
        {weeks}
      </GridWrapper>
    );
  }
}

JourneyGrid.propTypes = {
  journeysWithDetails: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default JourneyGrid;
