import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { connect } from 'react-redux';

import CheckboxDefaultIcon from '../../mc-ui/icons/CheckboxDefaultIcon';
import CheckboxSelectedIcon from '../../mc-ui/icons/CheckboxSelectedIcon';
import EmailThumbnail from '../../ui/EmailThumbnail';

import { Box, Flex, Heading, Spinner, Text, theme } from '../../mc-ui';
import { customListsActions } from '../../../modules/customLists';

class ListSelector extends React.Component {
  state = {
    toggling: false,
  };

  selectList = async () => {
    const { addItemsToList } = this.props;

    this.setState({ toggling: true });

    await new Promise((resolve, reject) => {
      addItemsToList(resolve, reject);
    });

    this.setState({ toggling: false });
  };

  deselectList = async () => {
    const { removeItemsFromList } = this.props;

    this.setState({ toggling: true });

    await new Promise((resolve, reject) => {
      removeItemsFromList(resolve, reject);
    });

    this.setState({ toggling: false });
  };

  render() {
    const { list, emails, textMessages, listType } = this.props;
    const { toggling } = this.state;
    const email = list.relationships.emails.data[0];
    const emailIds = emails.map(e => e.id);
    let isSelected = list.relationships.emails.data.some(e => emailIds.includes(e.id));
    const textMessageIds = textMessages.map(e => e.id);
    if (!isSelected) {
      isSelected = (
        list.relationships['text-messages'] ||
        list.relationships.textMessages || { data: [] }
      ).data.some(e => textMessageIds.includes(e.id));
    }

    return (
      <Flex
        cursor="pointer"
        marginBottom="16px"
        data-list-id={list.id}
        onClick={isSelected ? this.deselectList : this.selectList}
      >
        {email ? (
          <EmailThumbnail email={email} tiny />
        ) : (
          <Box
            backgroundImage="/images/listCardBackground.png"
            border={`1px solid ${theme.colors.dark01}`}
            borderRadius="4px"
            height="40px"
            width="40px"
            css={{
              backgroundSize: 'cover',
            }}
          />
        )}

        <Box flex="1" margin="0 16px" width="unset">
          <Heading as="h4" fontSize="14px" lineHeight="1.25em">
            {list.attributes.name}
          </Heading>

          {listType === 'emails' ? (
            <Text fontSize="12px">
              {list.attributes.approximate_emails}{' '}
              {pluralize('email', list.attributes.approximate_emails)}
            </Text>
          ) : (
            <Text fontSize="12px">
              {list.attributes.approximate_text_messages} text{' '}
              {pluralize('message', list.attributes.approximate_text_messages)}
            </Text>
          )}
        </Box>

        {toggling ? (
          <Box height="24px" marginRight="5px" width="24px">
            <Spinner size="24px" />
          </Box>
        ) : (
          <Box height="24px" width="24px">
            {isSelected ? <CheckboxSelectedIcon /> : <CheckboxDefaultIcon />}
          </Box>
        )}
      </Flex>
    );
  }
}

ListSelector.defaultProps = {
  listType: 'emails',
};

ListSelector.propTypes = {
  addItemsToList: PropTypes.func.isRequired,
  emails: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  list: PropTypes.shape({}).isRequired,
  listType: PropTypes.string,
  removeItemsFromList: PropTypes.func.isRequired,
  textMessages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const mapDispatchToProps = (dispatch, { emails, textMessages, list }) => ({
  addItemsToList: (resolve, reject) =>
    dispatch(
      customListsActions.addItemsToListRequest(
        list.id,
        [].concat(emails || [], textMessages || []),
        resolve,
        reject,
      ),
    ),
  removeItemsFromList: (resolve, reject) =>
    dispatch(
      customListsActions.removeItemsFromListRequest(
        list.id,
        [].concat(emails || [], textMessages || []),
        resolve,
        reject,
      ),
    ),
});

export default connect(
  null,
  mapDispatchToProps,
)(ListSelector);
