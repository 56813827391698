export default function drawLineOnPoints(chart, color) {
  // eslint-disable-next-line no-underscore-dangle
  const tooltipActive = chart.tooltip._active;

  if (tooltipActive && tooltipActive.length) {
    // eslint-disable-next-line no-underscore-dangle
    const activePoint = chart.controller.tooltip._active[0];
    const { ctx } = chart;
    const tooltipPosition = activePoint.tooltipPosition();
    const bottomY = chart.scales['y-axis-0'].bottom;

    ctx.save();
    ctx.beginPath();
    ctx.moveTo(tooltipPosition.x, tooltipPosition.y + 7);
    ctx.lineTo(tooltipPosition.x, bottomY);
    ctx.lineWidth = 2;
    ctx.strokeStyle = color;
    ctx.stroke();
    ctx.restore();
  }
}
