import api from '../lib/api'
import axios from 'axios'

export default store => next => action => {
  next(action)
  switch (action.type) {
    case 'REQUEST_DASHBOARD':
      axios.all([
          api.get('/v2/dashboard/emails?limit=5'),
          api.get('/v2/dashboard/trends')
        ])
        .then(responses => {
          let emails = responses[0].data.data.emails
          responses[1].data.data.emails = emails
          let data = responses[1].data
          store.dispatch({ type: 'RECEIVE_DASHBOARD', data: data })
        })
    break
  }
}
