/* globals document, window */

import React from 'react';
import PropTypes from 'prop-types';
import humanize from 'humanize-number';
import pluralize from 'pluralize';
import { connect } from 'react-redux';
import { Link } from 'react-router';

import { groupOverviewSelectors } from '../modules/groupOverview';
import { userSelectors } from '../modules/user';

import BrandRow from './BrandsResults/Results/Brands/BrandRow';

import { Box, Button, Flex, Heading, Tooltip, theme, Text } from './mc-ui';
import FYICircleIcon from './mc-ui/icons/FYICircleIcon';

import CompaniesLoadMore from './companies/LoadMore';

class GroupsCompanies extends React.Component {
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleChange = event => {
    event.preventDefault();
    this.setState({ [event.target.name]: event.target.value });
  };

  handleScroll = () => {
    const windowHeight =
      'innerHeight' in window ? window.innerHeight : document.documentElement.offsetHeight;
    const { body, documentElement: html } = document;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight,
    );
    const windowBottom = windowHeight + window.pageYOffset;

    if (windowBottom >= docHeight) {
      setTimeout(() => {
        this.props.requestCompaniesPagination();
      }, 250); // adds a slight delay to increase "fetching" effect for end-user
    }
  };

  render() {
    const {
      companies,
      group = { attributes: {} },
      isUserRestricted,
      loading,
      overviewMeta,
    } = this.props;

    if (loading) return null;

    if (isUserRestricted && !group.attributes.owner && !group.attributes.global) {
      return <div className="pure-u-1" />;
    }

    return (
      <div className="pure-u-1">
        <Flex
          alignItems="center"
          backgroundColor="white"
          borderRadius="4px"
          boxShadow={`0px 1px 4px ${theme.colors.dark02}`}
          height="64px"
          justifyContent="space-between"
          marginTop="16px"
          padding="0 32px 0 24px"
        >
          <Flex alignItems="center" flex="1" width="unset">
            <Heading as="h3" color="dark08" fontSize="24px" fontWeight="300" lineHeight="1em">
              Brands
            </Heading>
            <Tooltip
              id="groupBrandsTooltip"
              element={<FYICircleIcon color={theme.colors.dark1} height="24px" width="24px" />}
              variant="blue"
              css={{
                height: '24px',
                marginLeft: '8px',
                width: '24px',
                '& span': {
                  display: 'inline-block',
                  height: '24px',
                  width: '24px',
                },
                '& [data-id="tooltip"]': {
                  marginLeft: '0 !important',
                },
              }}
            >
              <p>
                Are brands that you care about in MailCharts? Can you request a brand? What is our
                curation process? Find all the answers on our FAQ page.
              </p>
              <Button
                as={Link}
                to="https://knowledge.validity.com/hc/en-us/sections/6839689729819-FAQ"
                target="_blank"
                variant="primary"
                css={{
                  alignItems: 'center !important',
                  display: 'inline-flex !important',
                  justifyContent: 'center !important',
                  height: '32px !important',
                  width: '104px !important',
                }}
              >
                Read more
              </Button>
            </Tooltip>
          </Flex>

          {overviewMeta.totalCompanies && (
            <Text>
              {humanize(overviewMeta.totalCompanies)}{' '}
              {pluralize('brand', overviewMeta.totalCompanies)}
            </Text>
          )}
        </Flex>

        {!companies.loading && (
          <Box margin="24px 0">
            <Box backgroundColor="white" borderRadius="4px" padding="32px">
              {companies.data.map(brand => (
                <BrandRow key={`GroupsCompanies-BrandRow-${brand.id}`} brand={brand} />
              ))}
            </Box>

            {companies.links.next && <CompaniesLoadMore companies={companies} />}
          </Box>
        )}
      </div>
    );
  }
}

GroupsCompanies.propTypes = {
  companies: PropTypes.shape({}).isRequired,
  isUserRestricted: PropTypes.bool.isRequired,
  group: PropTypes.shape({}).isRequired,
  loading: PropTypes.bool,
  overviewMeta: PropTypes.shape({
    totalCompanies: PropTypes.number,
  }).isRequired,
  requestCompaniesPagination: PropTypes.func.isRequired,
};

GroupsCompanies.defaultProps = {
  loading: false,
};

const mapStateToProps = state => ({
  companies: state.companies,
  isUserRestricted: userSelectors.isUserRestrictedSelector(state),
  group: state.groups.data.reduce((m, g) => g, {}) || {},
  overviewMeta: groupOverviewSelectors.getData(state).meta || {},
});

const mapDispatchToProps = dispatch => ({
  requestCompaniesPagination: () => dispatch({ type: 'REQUEST_COMPANIES_PAGINATION' }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GroupsCompanies);
