import React from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import moment from 'moment';

class PromotionFrequency extends React.Component {
  render() {
    const datasets = [].concat(this.props.buckets).map((buckets, idx) => {
      const label = this.props.names[idx];
      const isComparison = label === 'Comparison';
      const color = isComparison ? '#646464' : '#0383E7';
      const bg = isComparison ? 'rgba(36, 36, 36, .05)' : 'rgba(0, 134, 241, .25)';

      return {
        type: 'line',
        label,
        fill: true,
        lineTension: 0,
        backgroundColor: bg,
        borderColor: color,
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: color,
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: color,
        pointHoverBorderColor: color,
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: buckets.map(b => Math.round(b.company_avg.value * 100)),
      };
    });

    // Find the bucket that has more data to build this chart labels
    const buckets = [...this.props.buckets];
    buckets.sort((a, b) => b.length > a.length);
    const bucket = buckets[0];

    const data = {
      labels: bucket.map(b => moment(b.key_as_string).format('YYYY-DD-MMMM')),
      datasets,
    };
    const options = {
      scales: {
        yAxes: [
          {
            afterBuildTicks: (instance) => {
              instance.ticks = instance.ticks.filter(t => t <= 100); // eslint-disable-line
            },
            ticks: {
              beginAtZero: true,
              min: 0,
              max: 110,
              stepSize: 25,
              callback(value) {
                return `${value}%`;
              },
            },
            scaleLabel: {
              display: true,
              labelString: 'Promotional rate',
            },
          },
        ],
      },
      legend: {
        labels: {
          usePointStyle: true,
          align: 'left',
        },
        position: 'top',
      },
    };
    return <Line height={this.props.height || 240} options={options} data={data} />;
  }
}

PromotionFrequency.propTypes = {
  buckets: PropTypes.arrayOf(PropTypes.any).isRequired,
  height: PropTypes.number.isRequired,
  names: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default PromotionFrequency;
