import React from 'react';
import { contains } from 'ramda';

import TooltipPropTypes from './prop-types';
import { DarkTooltip, LightTooltip } from './styles';

import { HelpIcon } from './icons';

const Tooltip = ({
  children, theme, icon, ...props
}) => {
  const MCTooltip = theme === 'dark' ? DarkTooltip : LightTooltip;
  const id = `mc-tooltip-${Date.now()}`;
  const Icon = typeof icon === 'string' ? HelpIcon : icon;

  const childrenTypes = React.Children.map(children, (child) => {
    if (child.type) {
      return child.type;
    }

    return child;
  });

  const hasHeader = t => contains(t, ['h1', 'h2', 'h3', 'h4', 'h5', 'h6']);

  const customProps = {
    withButton: contains('button', childrenTypes),
    withHeader: contains(true, childrenTypes.map(hasHeader)),
    withParagraph: contains('p', childrenTypes),
  };

  return (
    <span>
      <Icon tooltipId={id} />
      <MCTooltip id={id} type={theme} {...props} {...customProps}>
        {children}
      </MCTooltip>
    </span>
  );
};

Tooltip.propTypes = TooltipPropTypes;
Tooltip.defaultProps = {
  place: 'right',
  effect: 'solid',
  delayHide: 200,
  theme: 'dark',
  icon: 'help',
  style: {},
};

export default Tooltip;
