import React from 'react';
import PropTypes from 'prop-types';
import humanize from 'humanize-number';
import pluralize from 'pluralize';

import { Box, Flex, Text } from '../../../../../mc-ui';
import EmailIcon from '../../../../../mc-ui/icons/EmailIcon';
import PromoIcon from '../../../../../mc-ui/icons/PromoIcon';

import NumberDataPoint from './NumberDataPoint';
import formatDate from './formatDate';

const DataPoints = ({ approximateCompanies, firstEmailSentAt, frequency, promotional }) => (
  <Box>
    <Flex alignItems="center" height="25px" marginBottom="8px">
      <Text color="dark08" fontWeight="normal">
        {humanize(approximateCompanies)} {pluralize('brand', approximateCompanies)}
      </Text>
    </Flex>

    <Flex alignItems="center" height="25px" marginBottom="8px">
      <Text color="dark06" height="20px" lineHeight="20px" css={{ marginRight: '6px' }}>
        <EmailIcon fillOpacity={1} height="20px" width="20px" />
      </Text>

      <Text color="dark08" fontWeight="normal">
        Avg. Emails / Week: {Math.ceil(frequency)}%
      </Text>
    </Flex>

    <Flex alignItems="center" height="25px">
      <Text color="dark06" height="20px" lineHeight="20px" css={{ marginRight: '6px' }}>
        <PromoIcon height="20px" width="20px" />
      </Text>

      <Text color="dark08" fontWeight="normal">
        Avg. Promo Rate: {Math.ceil(promotional)}%
      </Text>
    </Flex>
  </Box>
);

DataPoints.defaultProps = {};

DataPoints.propTypes = {
  approximateCompanies: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  frequency: PropTypes.number.isRequired,
  promotional: PropTypes.number.isRequired,
};

export default DataPoints;
