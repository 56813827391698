import { camelizeKeys } from 'humps';

import api from '../../../lib/api';

export const fetchGroups = async () => {
  const response = await api.get('/v3/groups?sort=name:asc&global=true&limit=250');

  return camelizeKeys(response.data.data).filter(g => g.attributes.approximateCompanies > 0);
};

export const fetchGroupOverview = async id => {
  const date = new Date();
  const year = date.getFullYear();
  const url = `/v3/reports/overview?group_id=${id}&start_date=${year}-01-01&end_date=${year}-12-31`;
  const response = await api.get(url);
  const { meta } = response.data;

  return {
    avgEmailsWeek: meta.stats.benchmarks_frequency.model || 0,
    avgPromoRate: meta.stats.current.promotional.value || 0,
    totalBrands: meta.total_companies,
    totalEmails: meta.total_emails,
  };
};

export const fetchGroupBrands = async (id, size = 25, paginationUrl) => {
  const url = paginationUrl || `/v3/groups/${id}/companies?size=${size}`;
  const response = await api.get(url);

  const { included, links, meta } = response.data;
  const brands = included.filter(i => i.type === 'companies');

  return { brands: camelizeKeys(brands), links, meta };
};

export const fetchGroupEmails = async (id, size = 25, paginationUrl) => {
  const url = paginationUrl || `/v2/groups/${id}/emails?limit=${size}`;
  const response = await api.get(url);

  const {
    data: { emails },
    links,
    meta,
  } = response.data;

  return { emails: camelizeKeys(emails), links, meta };
};

export const fetchJourneys = async () => {
  const { data: journeys } = await api.get('/v3/journeys', { params: { size: 1 } });
  return journeys.meta.journey_types;
};

export const fetchJourney = async (type, tab, size = 25, paginationUrl) => {
  let query = `journey_type=${type}&size=${size}`;
  if (tab === 'upcoming') query += '&status=upcoming';
  const url = paginationUrl || `/v3/journeys/?${query}`;
  const response = await api.get(url);

  const { data, links, meta } = response.data;
  return { journeys: camelizeKeys(data), links, meta };
};

export const fetchGroupJourneys = async (id, size = 3, paginationUrl) => {
  const url = paginationUrl || `/v3/journeys?group_id=${id}&size=${size}`;
  const response = await api.get(url);

  const { data, links, meta } = response.data;

  return { journeys: camelizeKeys(data), links, meta };
};
