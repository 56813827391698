import PropTypes from 'prop-types';
import React from 'react';
import NotesBadge from '../../../Email/NotesBadge';
import { Box, Tab } from '../../../mc-ui';
import Details from './Details';
import Notes from './Notes';

class Panels extends React.PureComponent {
  constructor(props) {
    super(props);

    const { panel = 'details' } = props.router.location.query;

    this.state = {
      approximateComments: props.data.attributes.approximate_comments || 0,
      panel,
    };
  }

  showNotes = () => {
    const { data, router } = this.props;
    const lists = data.relationships.lists ? data.relationships.lists.data : [];

    const list = lists.find(l => l.id === router.location.query.list_id);

    return list && !list.attributes.featured;
  };

  updateApproximateComments = approximateComments => {
    this.setState({ approximateComments });
  };

  render() {
    const { approximateComments, panel } = this.state;

    return (
      <Box width="428px">
        <Tab defaultActive={panel} onTabChange={this.handlePanelChange}>
          <Tab.Header>
            <Tab.Item name="details">Details</Tab.Item>
            {this.showNotes() && (
              <Tab.Item name="notes">
                Notes
                <NotesBadge approximateComments={approximateComments} />
              </Tab.Item>
            )}
          </Tab.Header>

          <Tab.Body>
            {({ active }) => {
              if (active === 'notes')
                return (
                  <Notes
                    approximateComments={approximateComments}
                    updateCounter={this.updateApproximateComments}
                    {...this.props}
                  />
                );
              return <Details {...this.props} />;
            }}
          </Tab.Body>
        </Tab>
      </Box>
    );
  }
}

Panels.propTypes = {
  data: PropTypes.shape({
    attributes: PropTypes.shape({
      approximate_comments: PropTypes.number,
    }),
    relationships: PropTypes.shape({
      lists: PropTypes.shape({
        data: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string.isRequired,
            attributes: PropTypes.shape({
              featured: PropTypes.bool,
            }),
          }),
        ),
      }),
    }),
  }).isRequired,
  router: PropTypes.shape({
    location: PropTypes.shape({
      query: PropTypes.shape({
        panel: PropTypes.string,
      }),
    }),
  }).isRequired,
};

export default Panels;
