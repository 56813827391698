import React from 'react'

const EmailSubscriber = props => (
  <svg
    viewBox='0 0 40 40'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width='1em'
    height='1em'
    {...props}
  >
    <defs>
      <path
        d='M2.437.691H19.77a2 2 0 0 1 2 2V13.93a2 2 0 0 1-2 2H2.437a2 2 0 0 1-2-2V2.691a2 2 0 0 1 2-2z'
        id='a'
      />
    </defs>
    <g fill='none' fillRule='evenodd'>
      <circle fill='#21D6C5' cx={20} cy={20} r={20} />
      <g transform='translate(9 11)'>
        <mask id='b' fill='#fff'>
          <use xlinkHref='#a' />
        </mask>
        <path
          stroke='#FFF'
          strokeWidth={1.6}
          d='M2.437-.109H19.77a2.8 2.8 0 0 1 2.8 2.8V13.93a2.8 2.8 0 0 1-2.8 2.8H2.437a2.8 2.8 0 0 1-2.8-2.8V2.691a2.8 2.8 0 0 1 2.8-2.8z'
        />
        <path
          d='M-.306 16.906a1.2 1.2 0 0 0 0 1.697l10.64 10.64a1.2 1.2 0 0 0 1.698 0l10.481-10.481a1.2 1.2 0 0 0 0-1.697l-10.64-10.64a1.2 1.2 0 0 0-1.697 0L-.306 16.905zm-1.131-1.131L9.045 5.293a2.8 2.8 0 0 1 3.96 0l10.64 10.64a2.8 2.8 0 0 1 0 3.96L13.163 30.375a2.8 2.8 0 0 1-3.96 0l-10.64-10.64a2.8 2.8 0 0 1 0-3.96z'
          fill='#FFF'
          fillRule='nonzero'
          mask='url(#b)'
        />
        <path
          d='M-.306-2.776a1.2 1.2 0 0 0 0 1.697l10.64 10.64a1.2 1.2 0 0 0 1.698 0L22.513-.921a1.2 1.2 0 0 0 0-1.697l-10.64-10.64a1.2 1.2 0 0 0-1.697 0L-.306-2.776zm-1.131-1.132L9.045-14.39a2.8 2.8 0 0 1 3.96 0l10.64 10.64a2.8 2.8 0 0 1 0 3.96L13.163 10.693a2.8 2.8 0 0 1-3.96 0L-1.437.052a2.8 2.8 0 0 1 0-3.96z'
          fill='#FFF'
          fillRule='nonzero'
          mask='url(#b)'
        />
      </g>
    </g>
  </svg>
)

export default EmailSubscriber
