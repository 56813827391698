import React from 'react'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import humanize from 'humanize-number'
import api from '../lib/api'
import axios from 'axios'

import EmailImage from './EmailImage'

class ListsList extends React.Component {
  render() {
    return (<div className='pure-g gutters'>
        {this.props.lists.map((model, index) => {
          return (
            <div
              key={index}
              onClick={(e) => {
                e.preventDefault()
                this.props.router.push(`/${model.type}/${model.id}`)
              }}
              style={{
                cursor: 'pointer',
              }}
              className='pure-u-1 pure-u-md-1-2'
            >
              <div className='pure-u-1'>
                <div
                  style={{
                    border: '1px solid #D4D9DE',
                    marginBottom: 25,
                    borderRadius: 3,
                    background: '#fff',
                    padding: '25px 25px 0 25px',
                  }}
                >
                  <div className='pure-u-1 pure-u-md-7-8'>
                    <div className='pure-u-1-8'>
                    <img src={`//cache.mailcharts.com/logos/v2/lists/${model.id % 126}.png`} className='pure-img' />
                    </div>
                    <div className='pure-u-1-24' />
                    <div className='pure-u-12-24'>
                      <h4 style={{ margin: 0 }}>{model.attributes.name}</h4>
                      <div>
                        {model.attributes.featured &&
                        <h5
                          style={{
                            display: 'inline',
                            fontWeight: 'normal',
                            borderRadius: 3,
                            padding: 2,
                            fontSize: `${11/16}em`,
                            marginRight: 5,
                            background: '#FFCB41',
                            color: '#fff',
                          }}
                        >
                          Curated
                        </h5>}
                        <p
                          style={{ fontSize: `${12/16}em`, color: '#A0AEBA', margin: 0, display: 'inline-block', }}
                        >
                          {humanize(model.attributes.approximate_emails || 0)} emails
                        </p>
                      </div>
                    </div>
                  </div>
                <div className='pure-u-1 pure-u-md-1-8'>
                  <div
                    style={{
                      background: '#fff',
                      border: '1px solid #D4D9DE',
                      borderRadius: 3,
                      fontSize: `${24/16}em`,
                      width: 25,
                      display: 'inline-block',
                      textAlign: 'center',
                      margin: 0,
                    }}
                  >
                    <Link
                      style={{
                        color: '#0379D5',
                        textDecoration: 'none',
                        borderBottom: 'none',
                        padding: 0,
                        display: 'block',
                      }}
                      to={`/${model.type}/${model.id}`}
                    >
                      &rsaquo;
                    </Link>
                  </div>
                </div>
                <div style={{ marginTop: 10 }}>
                  {model.relationships.emails.data.map((email, idx) => {
                    let width = idx % 2 ? '40%' : '30%'
                    let zIndex = idx % 2 ? 1 : 0
                    let height = 100
                    let style = { width, height, zIndex, display: 'inline-block', overflow: 'hidden' }
                    return (
                      <div
                        key={idx}
                        style={style}
                      >
                        <EmailImage guid={email.attributes.guid} style={{ width: '100%' }} />
                      </div>
                    )
                  })}
                </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
      )
  }
}

export default connect()(ListsList)
