import React from 'react';
import { PropTypes } from 'prop-types';

import { Flex } from '../../../mc-ui';

const Header = ({ children, ...props }) => (
  <Flex css={{ height: '95px', position: 'relative' }}>
    {React.Children.map(children, child => React.cloneElement(child, props))}
  </Flex>
);

Header.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Header;
