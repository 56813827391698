import React from 'react';
import PropTypes from 'prop-types';
import humanize from 'humanize-number';
import pluralize from 'pluralize';
import { connect } from 'react-redux';
import { isEmpty } from 'ramda';

import { savedSearchesSelectors as selectors } from '../../../modules/savedSearches';

import { Box, Flex, Text } from '../../mc-ui';

import FilterLine from './FilterLine';
import NoSavedFilters from './NoSavedFilters';
import SortSelect from './SortSelect';

class TeamFilters extends React.Component {
  constructor(props) {
    super(props);

    const { router } = props;
    const sort = router.location.query.sort || 'created_at:desc';

    this.state = { sort };
  }

  componentDidMount() {
    this.fetchFilters();
  }

  componentDidUpdate(_prevProps, prevState) {
    if (this.state.sort !== prevState.sort) {
      this.fetchFilters();
    }
  }

  fetchFilters = () => {
    this.props.request({ sort: this.state.sort });
  };

  updateSort = sort => {
    const { router } = this.props;

    this.setState(
      () => ({ sort }),
      () => {
        router.replace({
          pathname: router.location.pathname,
          query: { ...router.location.query, sort },
        });
      },
    );
  };

  render() {
    const { filters, loading } = this.props;
    const { sort } = this.state;

    if (loading) return null;

    return (
      <Box>
        <Flex alignItems="center" marginBottom="24px" width="unset">
          <SortSelect handleChange={this.updateSort} value={sort} />

          <Text css={{ marginLeft: '8px' }}>
            {humanize(filters.length)} {pluralize('filter', filters.length)}
          </Text>
        </Flex>

        {isEmpty(filters) ? (
          <NoSavedFilters collectionType="team" />
        ) : (
          <Box backgroundColor="white" borderRadius="4px" padding="16px">
            {filters.map(filter => (
              <FilterLine key={`TeamFilters-${filter.id}`} collectionType="team" filter={filter} />
            ))}
          </Box>
        )}
      </Box>
    );
  }
}

TeamFilters.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })).isRequired,
  loading: PropTypes.bool.isRequired,
  request: PropTypes.func.isRequired,
  router: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => ({
  filters: selectors.teamFiltersDataSelector(state),
  loading: selectors.isTeamFiltersLoadingSelector(state),
});

export default connect(mapStateToProps)(TeamFilters);
