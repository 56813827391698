import * as types from './types';

// **************************************************
// MY SAVED JOURNEYS
// **************************************************

export const fetchMySavedJourneysRequest = () => ({
  type: types.FETCH_MY_SAVED_JOURNEYS_REQUEST,
});

export const fetchMySavedJourneysSuccess = data => ({
  type: types.FETCH_MY_SAVED_JOURNEYS_SUCCESS,
  data,
});

export const fetchMySavedJourneysFailure = error => ({
  type: types.FETCH_MY_SAVED_JOURNEYS_FAILURE,
  error,
});

export const saveJourneyToMyListRequest = id => ({
  type: types.SAVE_JOURNEY_TO_MY_LIST_REQUEST,
  id,
});

export const saveJourneyToMyListSuccess = data => ({
  type: types.SAVE_JOURNEY_TO_MY_LIST_SUCCESS,
  data,
});

export const saveJourneyToMyListFailure = error => ({
  type: types.SAVE_JOURNEY_TO_MY_LIST_FAILURE,
  error,
});

export const removeJourneyFromMyListRequest = id => ({
  type: types.REMOVE_JOURNEY_FROM_MY_LIST_REQUEST,
  id,
});

export const removeJourneyFromMyListSuccess = data => ({
  type: types.REMOVE_JOURNEY_FROM_MY_LIST_SUCCESS,
  data,
});

export const removeJourneyFromMyListFailure = error => ({
  type: types.REMOVE_JOURNEY_FROM_MY_LIST_FAILURE,
  error,
});

// **************************************************
// TEAM SAVED JOURNEYS
// **************************************************

export const fetchTeamSavedJourneysRequest = () => ({
  type: types.FETCH_TEAM_SAVED_JOURNEYS_REQUEST,
});

export const fetchTeamSavedJourneysSuccess = data => ({
  type: types.FETCH_TEAM_SAVED_JOURNEYS_SUCCESS,
  data,
});

export const fetchTeamSavedJourneysFailure = error => ({
  type: types.FETCH_TEAM_SAVED_JOURNEYS_FAILURE,
  error,
});

export const saveJourneyToTeamListRequest = id => ({
  type: types.SAVE_JOURNEY_TO_TEAM_LIST_REQUEST,
  id,
});

export const saveJourneyToTeamListSuccess = data => ({
  type: types.SAVE_JOURNEY_TO_TEAM_LIST_SUCCESS,
  data,
});

export const saveJourneyToTeamListFailure = error => ({
  type: types.SAVE_JOURNEY_TO_TEAM_LIST_FAILURE,
  error,
});

export const removeJourneyFromTeamListRequest = id => ({
  type: types.REMOVE_JOURNEY_FROM_TEAM_LIST_REQUEST,
  id,
});

export const removeJourneyFromTeamListSuccess = data => ({
  type: types.REMOVE_JOURNEY_FROM_TEAM_LIST_SUCCESS,
  data,
});

export const removeJourneyFromTeamListFailure = error => ({
  type: types.REMOVE_JOURNEY_FROM_TEAM_LIST_FAILURE,
  error,
});
