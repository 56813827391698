import React, { Component } from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import qs from 'qs';
import { connect } from 'react-redux';
import { isEmpty } from 'ramda';

import { ascendBy } from '../../AddToList/Lists/helpers';

class ModelToCompare extends Component {
  state = {
    comparisonModel: {
      id: this.props.model.id,
      type: this.props.model.type,
    },
    options: {},
  };

  componentDidMount() {
    this.loadOptions();
    this.loadStateFromRouterQuery();
  }

  loadOptions = () => {
    const { model, user } = this.props;
    const companies = {};
    const groups = {};

    user.included.forEach((included) => {
      if (included.type === 'companies' && model.type === 'companies') {
        companies[included.id] = included;
      } else if (included.type === 'groups' && model.type === 'groups') {
        groups[included.id] = included;
      }
    });

    if (model.type === 'companies') {
      model.relationships.groups.data.forEach((group) => {
        groups[group.id] = group;
      });
    } else if (model.type === 'groups') {
      model.relationships.companies.data.forEach((company) => {
        companies[company.id] = company;
      });
    }

    this.setState({
      options: {
        companies: Object.keys(companies)
          .map(k => companies[k])
          .sort(ascendBy('name')),
        groups: Object.keys(groups)
          .map(k => groups[k])
          .sort(ascendBy('name')),
      },
    });
  };

  loadStateFromRouterQuery = () => {
    const { router } = this.context;
    const { queryScope } = this.props;
    const search = qs.parse((router.location.search || '').replace('?', ''));
    const query = (queryScope ? search[queryScope] : search) || {};

    const WHITE_LIST_QUERIES = ['company_id', 'group_id'];

    Object.keys(query).forEach((key) => {
      if (WHITE_LIST_QUERIES.indexOf(key) < 0) {
        delete query[key];
      }
    });

    const comparisonModel = {};

    if (query.company_id) {
      comparisonModel.id = query.company_id;
      comparisonModel.type = 'companies';
    } else if (query.group_id) {
      comparisonModel.id = query.group_id;
      comparisonModel.type = 'groups';
    }

    if (!isEmpty(comparisonModel)) {
      this.setState({ comparisonModel });
    }
  };

  handleChange = (event) => {
    const { currentQuery, queryScope, setQuery } = this.props;
    const { name, value } = event.target;

    const comparisonModel = {
      ...this.state.comparisonModel,
      [name]: value,
    };

    this.setState({ comparisonModel }, () => {
      if (name === 'id') {
        const query = queryScope ? { ...currentQuery[queryScope] } : { ...currentQuery };

        if (comparisonModel.type === 'companies') {
          if (query.group_id) {
            delete query.group_id;
          }
          query.company_id = comparisonModel.id;
        } else if (comparisonModel.type === 'groups') {
          if (query.company_id) {
            delete query.company_id;
          }
          query.group_id = comparisonModel.id;
        }

        setQuery(query, queryScope, true);
      }
    });
  };

  render() {
    const { comparisonModel, options } = this.state;

    if (isEmpty(options)) {
      return null;
    }

    return (
      <form className="pure-form">
        <fieldset style={{ padding: '0' }}>
          <div style={{ display: 'flex' }}>
            <label htmlFor="comparisonModelType" style={{ margin: '0' }}>
              <select
                value={comparisonModel.type || ''}
                name="type"
                id="comparisonModelType"
                className="pure-input-1"
                onChange={this.handleChange}
                style={{
                  border: '1px solid #d4d9de',
                  borderRadius: '3px 0 0 3px',
                  borderRight: 'none',
                  color: '#242424',
                  fontSize: `${13 / 16}em`,
                  padding: '7px 9px',
                  width: '130px',
                }}
              >
                <option value="companies">Companies</option>
                <option value="groups">Groups</option>
              </select>
            </label>

            <label htmlFor="comparisonModelId" style={{ margin: '0' }}>
              <select
                value={comparisonModel.id || ''}
                name="id"
                id="comparisonModelId"
                className="pure-input-1"
                onChange={this.handleChange}
                style={{
                  border: '1px solid #d4d9de',
                  borderRadius: '0 3px 3px 0',
                  color: '#242424',
                  fontSize: `${13 / 16}em`,
                  padding: '7px 9px',
                  width: '210px',
                }}
              >
                <option>Select a {pluralize.singular(comparisonModel.type).toLowerCase()}</option>
                {options[comparisonModel.type].map(opt => (
                  <option key={opt.id} value={opt.id}>
                    {opt.attributes.name}
                  </option>
                ))}
              </select>
            </label>
          </div>
        </fieldset>
      </form>
    );
  }
}

ModelToCompare.contextTypes = {
  router: PropTypes.object,
};

ModelToCompare.propTypes = {
  model: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    type: PropTypes.string,
  }).isRequired,
  queryScope: PropTypes.string.isRequired,
  setQuery: PropTypes.func.isRequired,
  user: PropTypes.shape({}).isRequired,
  currentQuery: PropTypes.shape({}),
};

ModelToCompare.defaultProps = {
  currentQuery: {},
};

const mapStateToProps = state => ({
  user: state.user,
});

export default connect(mapStateToProps)(ModelToCompare);
