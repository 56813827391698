import React from 'react'

class BrandLogo extends React.Component {
  render() {
    const { model, className } = this.props
    const BRAND_LOGO = `//cache.mailcharts.com/logos/v2/companies/${model.id}.png`
    const DEFAULT_LOGO = `//cache.mailcharts.com/logos/v2/defaults/${model.id % 125}.png`
    return (
      <img
        onError={(err) => {
          // only make the replacement one time
          if (!/defaults/.test(err.target.src)) {
            err.target.src = DEFAULT_LOGO
          }
        }}
        src={BRAND_LOGO}
        className={className || 'pure-img'}
      />
    );
  }
}

export default BrandLogo
