import { isEmpty, isNil, sortBy, compose, toLower, prop, map, contains } from 'ramda';

const filterMostPopular = (mostPopular, value) =>
  compose(
    contains(toLower(value)),
    map(mp => toLower(mp)),
  )(mostPopular);

const fromKeys = arr => map(item => item.key, arr);
const sortOptions = sortBy(
  compose(
    toLower,
    prop('label'),
  ),
);

const mapToOptions = value => ({ label: value, value });
const toOptions = (label, mostPopularItems, allItems) => {
  const allOptions = map(mapToOptions, allItems);

  return [
    {
      label: 'Most Popular',
      options: map(mapToOptions, mostPopularItems),
    },
    {
      label: `All ${label}`,
      options: sortOptions(allOptions),
    },
  ];
};

export const industriesOptions = groups =>
  groups.map(g => ({ label: g.attributes.name, value: g.id }));

const mostPopularCountries = ['United States', 'United Kingdom', 'Canada', 'France', 'Australia'];
export const countriesOptions = countries =>
  [{ value: undefined, label: 'Any' }].concat(
    toOptions(
      'Countries',
      mostPopularCountries,
      fromKeys(countries.filter(country => !filterMostPopular(mostPopularCountries, country.key))),
    ),
  );

export const twitterFollowersOptions = twitterFollowers =>
  [{ value: undefined, label: 'Any' }].concat(
    twitterFollowers.map(t => {
      let value = `${t.from}|${t.to}`;
      if (!t.to) {
        value = t.from;
      }

      return { label: t.key, value };
    }),
  );

export const arrayToOptions = (arr, key = 'key', includeAny = true) => {
  if (isEmpty(arr) || isNil(arr)) return [];

  const options = sortOptions(map(mapToOptions, map(i => i[key], arr)));
  if (includeAny) {
    return [{ value: undefined, label: 'Any' }].concat(options);
  }
  return options;
};
