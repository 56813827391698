import React from 'react';
import PropTypes from 'prop-types';

import { dollar } from '../../../../lib/utils';

import { Flex, Heading, Link, Spinner } from '../../../mc-ui';

import * as S from './styles';
import IntervalUpdateConfirmationModal from './IntervalUpdateConfirmationModal';
import UpdateCreditCardInformation from './UpdateCreditCardInformation';

class IntervalSwitcher extends React.Component {
  state = {
    isModalOpen: false,
    selectedInterval: null,
  };

  toggleModal = () => {
    this.setState(currentState => ({ isModalOpen: !currentState.isModalOpen }));
  };

  selectInterval = (interval,event) => {
    event.preventDefault();

    const { intervalProp } = this.props;
    this.setState({selectedInterval: interval});

    if (intervalProp !== interval) {
      this.setState({ isModalOpen: true });
    }
  };

  handleClick = (interval,event) => {
    const { onSwitch, planExpired, team } = this.props;
    const isActive = team && team.attributes.account_status === 'active' && !planExpired;

    if (team && isActive) {
      this.selectInterval(interval,event);
    } else {
      onSwitch(interval,event);
    }
  };

  render() {
    const {
      amount,
      interval,
      isAnOldPlan,
      isOwner,
      isPro,
      planExpired,
      subscriptionPriceLoading,
      team,
    } = this.props;
    const { isModalOpen, selectedInterval } = this.state;

    if (!isOwner && isPro) return null;

    const isActive = team && team.attributes.account_status === 'active';

    if (isActive && subscriptionPriceLoading) {
      return (
        <Flex width="unset">
          <Spinner color="blue" size="24px" />
        </Flex>
      );
    }

    return (
      <Flex
        alignItems={isActive && !planExpired ? 'flex-end' : 'center'}
        direction={isActive && !planExpired ? 'column' : 'row'}
        width="unset"
      >
        <Heading
          as="h4"
          color={isActive ? 'blue' : 'dark1'}
          fontSize="18px"
          fontWeight="500"
          lineHeight="24px"
          css={{
            marginBottom: isActive && !planExpired ? '4px' : null,
            marginRight: isActive && !planExpired ? null : '8px',
          }}
        >
          {isActive ? 'Current plan -' : ''} {dollar(amount, { fromCents: true })}/{interval}
        </Heading>
        {isActive &&
        !planExpired &&
        !isAnOldPlan && 
          <UpdateCreditCardInformation />
        }

        {isActive &&
          !planExpired &&
          interval === 'month' &&
          !isAnOldPlan && (
            <Link
              to="#updatePlan"
              onClick={(e) => this.selectInterval(interval === 'month' ? 'year' : 'month',e)}
              textDecoration="underline"
            >
              Switch to {interval === 'month' ? 'annual' : 'monthly'} billing
            </Link>
          )}

        {(!isActive || planExpired) && (
          <S.ButtonGroup>
            <S.Button active={interval === 'year'} onClick={(e) => this.handleClick("year",e)}>
              Yearly
            </S.Button>

            <S.Button
              active={interval === 'month'}
              onClick={(e) => this.handleClick('month',e)}
            >
              Monthly
            </S.Button>
          </S.ButtonGroup>
        )}

        <IntervalUpdateConfirmationModal
          closeModal={this.toggleModal}
          isOpen={isModalOpen}
          newInterval={selectedInterval}
        />
      </Flex>
    );
  }
}

IntervalSwitcher.defaultProps = {
  isPro: false,
  team: null,
};

IntervalSwitcher.propTypes = {
  amount: PropTypes.number.isRequired,
  interval: PropTypes.string.isRequired,
  isAnOldPlan: PropTypes.bool.isRequired,
  isOwner: PropTypes.bool.isRequired,
  isPro: PropTypes.bool,
  onSwitch: PropTypes.func.isRequired,
  planExpired: PropTypes.bool.isRequired,
  subscriptionPriceLoading: PropTypes.bool.isRequired,
  team: PropTypes.shape({}),
};

export default IntervalSwitcher;
