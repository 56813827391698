import styled from "@emotion/styled";

export const GetInTouchWrapper = styled("div")({
  alignItems: 'center',
  display: 'flex',
  height: '24px',
  justifyContent: 'center',
  width: '24px',
});

export const LogoWrapper = styled("div")({
  alignItems: 'center',
  display: 'flex',
  width: '148px',
  '& a, & img': {
    height: '30px',
    width: '148px',
  }
});

export const NavigationItemsWrapper = styled("nav")({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  width: '584px',
});

export const NavigationWrapper = styled("div")({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
});

export const SearchWrapper = styled("div")({
  width: '200px',
});
