import { assocPath } from 'ramda';

import * as types from './types';

/**
 * State structure is divided per date, like the following
 * {
 *   [modelType]: {
 *     [modelId]: {
 *       [chart]: {
 *         [interval]: {
 *           [`start_date-end_date`]: {
 *             loading: boolean,
 *             data: {
 *               current: object,
 *               previous: object,
 *               yoy: object,
 *             },
 *             brands: {
 *               loading: boolean,
 *               data: [{
 *                 brand: object,
 *                 current: object,
 *                 previous: object,
 *                 yoy: object,
 *               }],
 *             },
 *             comparison: {
 *               [`start_date-end_date`]: {
 *                 {
 *                   loading: boolean,
 *                   data: {
 *                     current: object,
 *                     previous: object,
 *                     yoy: object,
 *                   },
 *                 },
 *               },
 *             },
 *             historicalTrend: {
 *               {
 *                 loading: boolean,
 *                 data: {
 *                   current: object,
 *                   previous: object,
 *                   yoy: object,
 *                 },
 *               },
 *             },
 *           },
 *         },
 *       },
 *     },
 *   },
 * }
 *
 * modelType can be: brands or groups
 * modelId can be: brandId or groupId
 * chart can be: all, promotional, subject_line_length or frequency
 * interval can be: day, week or month
 */
const INITIAL_STATE = {
  brands: {},
  groups: {},
};

const setDataLoading = (
  state,
  loading,
  { modelId, modelType, chart, interval, startDate, endDate },
) => {
  const dateKey = `${startDate}-${endDate}`;
  return assocPath([modelType, `${modelId}`, chart, interval, dateKey, 'loading'], loading, state);
};

const setDataError = (
  state,
  { modelId, modelType, chart, interval, startDate, endDate, error },
) => {
  const dateKey = `${startDate}-${endDate}`;
  return assocPath(
    [modelType, `${modelId}`, chart, interval, dateKey],
    { error, loading: false },
    state,
  );
};

const setDataPayload = (
  state,
  { modelId, modelType, chart, interval, startDate, endDate, payload },
) => {
  const dateKey = `${startDate}-${endDate}`;
  return assocPath(
    [modelType, `${modelId}`, chart, interval, dateKey],
    { data: payload, loading: false },
    state,
  );
};

const toggleDataLoading = (state, { modelId, modelType, chart, interval, startDate, endDate }) => {
  const dateKey = `${startDate}-${endDate}`;
  const dataObj = state[modelType][`${modelId}`] || {};
  const chartObj = dataObj[chart] || {};
  const intervalObj = chartObj[interval] || {};
  const { loading } = intervalObj[dateKey] || { loading: false };

  return assocPath([modelType, `${modelId}`, chart, interval, dateKey, 'loading'], !loading, state);
};

const setHistoricalTrendLoading = (
  state,
  loading,
  { modelId, modelType, chart, interval, startDate, endDate },
) => {
  const dateKey = `${startDate}-${endDate}`;
  return assocPath(
    [modelType, `${modelId}`, chart, interval, dateKey, 'historicalTrend', 'loading'],
    loading,
    state,
  );
};

const setHistoricalTrendError = (
  state,
  { modelId, modelType, chart, interval, startDate, endDate, error },
) => {
  const dateKey = `${startDate}-${endDate}`;
  return assocPath(
    [modelType, `${modelId}`, chart, interval, dateKey, 'historicalTrend'],
    { error, loading: false },
    state,
  );
};

const setHistoricalTrendData = (
  state,
  { modelId, modelType, chart, interval, startDate, endDate, payload },
) => {
  const dateKey = `${startDate}-${endDate}`;
  return assocPath(
    [modelType, `${modelId}`, chart, interval, dateKey, 'historicalTrend'],
    { data: payload, loading: false },
    state,
  );
};

const toggleHistoricalTrendLoading = (
  state,
  { modelId, modelType, chart, interval, startDate, endDate },
) => {
  const dateKey = `${startDate}-${endDate}`;
  const dataObj = state[modelType][`${modelId}`] || {};
  const chartObj = dataObj[chart] || {};
  const intervalObj = chartObj[interval] || {};
  const dateObject = intervalObj[dateKey] || {};
  const { loading } = dateObject.historicalTrend || { loading: false };

  return assocPath(
    [modelType, `${modelId}`, chart, interval, dateKey, 'historicalTrend', 'loading'],
    !loading,
    state,
  );
};

const setComparisonLoading = (
  state,
  loading,
  {
    modelId,
    modelType,
    chart,
    interval,
    startDate,
    endDate,
    comparisonStartDate,
    comparisonEndDate,
  },
) => {
  const dateKey = `${startDate}-${endDate}`;
  const comparisonDateKey = `${comparisonStartDate}-${comparisonEndDate}`;
  return assocPath(
    [modelType, `${modelId}`, chart, interval, dateKey, 'comparison', comparisonDateKey, 'loading'],
    loading,
    state,
  );
};

const setComparisonError = (
  state,
  {
    modelId,
    modelType,
    chart,
    interval,
    startDate,
    endDate,
    comparisonStartDate,
    comparisonEndDate,
    error,
  },
) => {
  const dateKey = `${startDate}-${endDate}`;
  const comparisonDateKey = `${comparisonStartDate}-${comparisonEndDate}`;
  return assocPath(
    [modelType, `${modelId}`, chart, interval, dateKey, 'comparison', comparisonDateKey],
    { error, loading: false },
    state,
  );
};

const setComparisonData = (
  state,
  {
    modelId,
    modelType,
    chart,
    interval,
    startDate,
    endDate,
    comparisonStartDate,
    comparisonEndDate,
    payload,
  },
) => {
  const dateKey = `${startDate}-${endDate}`;
  const comparisonDateKey = `${comparisonStartDate}-${comparisonEndDate}`;
  return assocPath(
    [modelType, `${modelId}`, chart, interval, dateKey, 'comparison', comparisonDateKey],
    { data: payload, loading: false },
    state,
  );
};

const toggleComparisonLoading = (
  state,
  {
    modelId,
    modelType,
    chart,
    interval,
    startDate,
    endDate,
    comparisonStartDate,
    comparisonEndDate,
  },
) => {
  const dateKey = `${startDate}-${endDate}`;
  const comparisonDateKey = `${comparisonStartDate}-${comparisonEndDate}`;
  const dataObj = state[modelType][`${modelId}`] || {};
  const chartObj = dataObj[chart] || {};
  const intervalObj = chartObj[interval] || {};
  const dateObject = intervalObj[dateKey] || {};
  const comparisonObj = dateObject.comparison || {};
  const { loading } = comparisonObj[comparisonDateKey] || { loading: false };

  return assocPath(
    [modelType, `${modelId}`, chart, interval, dateKey, 'comparison', comparisonDateKey, 'loading'],
    !loading,
    state,
  );
};

const setBrandsLoading = (
  state,
  loading,
  { modelId, modelType, chart, interval, startDate, endDate },
) => {
  const dateKey = `${startDate}-${endDate}`;
  return assocPath(
    [modelType, `${modelId}`, chart, interval, dateKey, 'brands', 'loading'],
    loading,
    state,
  );
};

const setBrandsError = (
  state,
  { modelId, modelType, chart, interval, startDate, endDate, error },
) => {
  const dateKey = `${startDate}-${endDate}`;
  return assocPath(
    [modelType, `${modelId}`, chart, interval, dateKey, 'brands'],
    { error, loading: false },
    state,
  );
};

const setBrandsData = (
  state,
  { modelId, modelType, chart, interval, startDate, endDate, payload },
) => {
  const dateKey = `${startDate}-${endDate}`;
  return assocPath(
    [modelType, `${modelId}`, chart, interval, dateKey, 'brands'],
    { data: payload, loading: false },
    state,
  );
};

const toggleBrandsLoading = (
  state,
  { modelId, modelType, chart, interval, startDate, endDate },
) => {
  const dateKey = `${startDate}-${endDate}`;
  const dataObj = state[modelType][`${modelId}`] || {};
  const chartObj = dataObj[chart] || {};
  const intervalObj = chartObj[interval] || {};
  const { loading } = intervalObj.brands || { loading: false };

  return assocPath(
    [modelType, `${modelId}`, chart, interval, dateKey, 'brands', 'loading'],
    !loading,
    state,
  );
};

const reducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case types.FETCH_DATA_REQUEST: {
      return setDataLoading(state, true, action);
    }
    case types.FETCH_DATA_FAILURE: {
      return setDataError(state, action);
    }
    case types.FETCH_DATA_SUCCESS: {
      return setDataPayload(state, action);
    }
    case types.TOGGLE_DATA_LOADING: {
      return toggleDataLoading(state, action);
    }
    case types.FETCH_HISTORICAL_TREND_REQUEST: {
      return setHistoricalTrendLoading(state, true, action);
    }
    case types.FETCH_HISTORICAL_TREND_FAILURE: {
      return setHistoricalTrendError(state, action);
    }
    case types.FETCH_HISTORICAL_TREND_SUCCESS: {
      return setHistoricalTrendData(state, action);
    }
    case types.TOGGLE_HISTORICAL_TREND_LOADING: {
      return toggleHistoricalTrendLoading(state, action);
    }
    case types.FETCH_COMPARISON_REQUEST: {
      return setComparisonLoading(state, true, action);
    }
    case types.FETCH_COMPARISON_FAILURE: {
      return setComparisonError(state, action);
    }
    case types.FETCH_COMPARISON_SUCCESS: {
      return setComparisonData(state, action);
    }
    case types.TOGGLE_COMPARISON_LOADING: {
      return toggleComparisonLoading(state, action);
    }
    case types.FETCH_BRANDS_REQUEST: {
      return setBrandsLoading(state, true, action);
    }
    case types.FETCH_BRANDS_FAILURE: {
      return setBrandsError(state, action);
    }
    case types.FETCH_BRANDS_SUCCESS: {
      return setBrandsData(state, action);
    }
    case types.TOGGLE_BRANDS_LOADING: {
      return toggleBrandsLoading(state, action);
    }
    default: {
      return state;
    }
  }
};

export default reducer;
