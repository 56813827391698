import * as c from './constants';
import * as types from './types';

export const hide = () => ({ type: types.HIDE });

const show = ({
  body = null,
  duration = c.DEFAULT_DURATION,
  messageType,
  persistOnHover = c.DEFAULT_PERSIST_ON_HOVER,
  title = null,
}) => ({
  type: types.SHOW,
  body,
  duration,
  messageType,
  persistOnHover,
  title,
});

export const showError = options => show({ ...options, messageType: c.MESSAGE_TYPE_ERROR });
export const showInfo = options => show({ ...options, messageType: c.MESSAGE_TYPE_INFO });
export const showSuccess = options => show({ ...options, messageType: c.MESSAGE_TYPE_SUCCESS });
export const showWarning = options => show({ ...options, messageType: c.MESSAGE_TYPE_WARNING });
