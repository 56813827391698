import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  customGroupsActions as actions,
  customGroupsSelectors as selectors,
} from '../../../modules/customGroups';

import CheckSmallIcon from '../../mc-ui/icons/CheckSmallIcon';
import VerticalDotsIcon from '../../mc-ui/icons/VerticalDotsIcon';
import { Box, Button, theme } from '../../mc-ui';

import GroupForm from './GroupForm';

// eslint-disable-next-line react/prop-types
const MenuItem = ({ as: As = Button, ...props }) => (
  <As
    variant="none"
    css={{
      alignItems: 'center',
      border: 'none !important',
      display: 'flex',
      height: '32px',
      justifyContent: 'space-between',
      lineHeight: '32px',
      padding: '0 8px',
      width: '100%',
      '&:hover': { backgroundColor: theme.colors.dark6, border: 'none', color: theme.colors.dark1 },
    }}
    {...props}
  />
);

class GroupControlDropdown extends React.Component {
  state = {
    sharingWithTeam: false,
  };

  shareGroupWithTeam = event => {
    event.preventDefault();

    this.setState(() => ({ sharingWithTeam: true }), this.props.shareGroupWithTeam);
  };

  handleEdit = (values, callback) => {
    const { handleEdit, handleEditTeamGroup, isListingTeamGroups, refetchGroups } = this.props;
    const func = isListingTeamGroups ? handleEditTeamGroup : handleEdit;

    func(values, () => {
      if (callback) {
        callback();
      }
      refetchGroups();
    });
  };

  handleDelete = event => {
    if (event) {
      event.preventDefault();
    }

    const { deleteGroup, deleteTeamGroup, isListingTeamGroups, refetchGroups } = this.props;
    const func = isListingTeamGroups ? deleteTeamGroup : deleteGroup;
    func(refetchGroups);
  };

  render() {
    const {
      closeForm,
      isFormOpen,
      isListingTeamGroups,
      isOwner,
      isValidTeam,
      group,
      openForm,
      teamGroupIds,
    } = this.props;
    const { sharingWithTeam } = this.state;
    const shared = teamGroupIds.includes(group.id.toString());

    return (
      <Box
        position="relative"
        width="unset"
        css={{
          '& > button': {
            color: `${theme.colors.dark06} !important`,
          },
          '& > div': {
            display: 'none',
          },
          '&:hover': {
            '& > button': {
              color: `${theme.colors.dark1} !important`,
            },
            '& > div': {
              display: 'block',
            },
          },
        }}
      >
        <Button
          variant="none"
          css={{
            alignItems: 'center',
            display: 'inline-flex',
            fontSize: '24px',
            height: '24px',
            justifyContent: 'center',
            margin: '0',
            padding: '0',
            width: '24px',
          }}
        >
          <VerticalDotsIcon />
        </Button>

        <Box
          backgroundColor="white"
          borderRadius="4px"
          padding="4px"
          position="absolute"
          right="0"
          top="14px"
          width="216px"
          css={{
            filter: 'drop-shadow(0px 2px 8px rgba(9, 30, 66, 0.2))',
            '& > div': { width: '100% !important' },
          }}
          zIndex="6"
        >
          {isOwner &&
            !isListingTeamGroups &&
            isValidTeam && (
              <MenuItem disabled={shared || sharingWithTeam} onClick={this.shareGroupWithTeam}>
                Share with team
                {(shared || sharingWithTeam) && <CheckSmallIcon />}
              </MenuItem>
            )}

          <Box position="relative" width="unset">
            <MenuItem onClick={openForm}>Edit</MenuItem>

            {isFormOpen && (
              <Box
                left="0"
                position="absolute"
                top="16px"
                zIndex={theme.zIndices.dropdown}
                width="unset"
              >
                <GroupForm
                  handleClose={closeForm}
                  initialValues={{
                    ...group.attributes,
                  }}
                  onSubmit={this.handleEdit}
                  hideCloseButton
                />
              </Box>
            )}
          </Box>

          <MenuItem onClick={this.handleDelete} variant="destructive">
            Remove
          </MenuItem>
        </Box>
      </Box>
    );
  }
}

GroupControlDropdown.defaultProps = {
  isListingTeamGroups: false,
};

GroupControlDropdown.propTypes = {
  closeForm: PropTypes.func.isRequired,
  deleteGroup: PropTypes.func.isRequired,
  deleteTeamGroup: PropTypes.func.isRequired,
  group: PropTypes.shape({
    attributes: PropTypes.object,
  }).isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleEditTeamGroup: PropTypes.func.isRequired,
  isFormOpen: PropTypes.bool.isRequired,
  isListingTeamGroups: PropTypes.bool,
  isOwner: PropTypes.bool.isRequired,
  isValidTeam: PropTypes.bool,
  openForm: PropTypes.func.isRequired,
  refetchGroups: PropTypes.func.isRequired,
  shareGroupWithTeam: PropTypes.func.isRequired,
  teamGroupIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = (state, { group }) => ({
  isOwner: selectors.isOwnerSelector(group)(state),
  isFormOpen: selectors.isEditFormOpenSelector(group)(state),
  isValidTeam: selectors.isValidTeamSelector(state),
  teamGroupIds: selectors.teamGroupIdsReducer(state),
});

const mapDispatchToProps = (dispatch, { group }) => ({
  closeForm: () => dispatch(actions.closeEditForm()),
  deleteGroup: () => dispatch(actions.deleteRequest(group)),
  deleteTeamGroup: () => dispatch(actions.deleteTeamGroupRequest(group.id)),
  handleEdit: (...args) => dispatch(actions.updateRequest(group.id, ...args)),
  handleEditTeamGroup: (...args) => dispatch(actions.updateTeamGroupRequest(group.id, ...args)),
  openForm: () => dispatch(actions.openEditForm(group)),
  shareGroupWithTeam: () => dispatch(actions.shareWithTeamRequest(group.id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GroupControlDropdown);
