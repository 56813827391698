/* eslint-disable jsx-a11y/no-autofocus */
import React from 'react';
import PropTypes from 'prop-types';

import { Box, Button, Flex, Text, theme } from '../mc-ui';
import Toggle from '../ui/Toggle';

export default function EditView({
  handleDelete,
  handleFieldChange,
  handlePrivacy,
  handleSubmit,
  isOwner,
  list,
}) {
  if (!isOwner) return null;

  return (
    <Box flex="1" marginRight="32px" width="unset" zIndex={theme.zIndices.docked}>
      <form className="pure-form" onSubmit={handleSubmit}>
        <Flex alignItems="center" marginBottom="16px">
          <input
            autoFocus
            name="name"
            onChange={handleFieldChange}
            onFocus={e => e.target.select()}
            placeholder="Name"
            type="text"
            value={list.attributes.name || ''}
            style={{
              border: `1px solid ${theme.colors.dark5}`,
              borderRadius: '4px',
              boxSizing: 'border-box',
              color: theme.colors.dark08,
              flex: '1',
              fontSize: '14px',
              margin: '0 8px 0 0',
              padding: '7px 12px',
              width: '100%',
            }}
          />
          <input
            name="description"
            onChange={handleFieldChange}
            placeholder="Description"
            type="text"
            value={list.attributes.description || ''}
            style={{
              border: `1px solid ${theme.colors.dark5}`,
              borderRadius: '4px',
              boxSizing: 'border-box',
              color: theme.colors.dark08,
              flex: '2',
              fontSize: '14px',
              margin: '0 8px 0',
              padding: '7px 12px',
              width: '100%',
            }}
          />

          <Flex
            alignItems="center"
            direction="column"
            height="32px"
            justifyContent="space-between"
            width="48px"
          >
            <Toggle active={list.attributes.state === 'public'} handleClick={handlePrivacy} />
            <Text
              fontSize="10px"
              letterSpacing="0.08em"
              lineHeight="10px"
              textTransform="uppercase"
            >
              {list.attributes.state}
            </Text>
          </Flex>
        </Flex>

        <Flex alignItems="center" justifyContent="flex-end">
          <Button
            onClick={handleDelete}
            variant="destructive"
            css={{
              alignItems: 'center',
              display: 'inline-flex',
              height: '32px',
              justifyContent: 'center',
              margin: '0 8px 0 0',
              padding: '0',
              width: '72px',
            }}
          >
            Delete
          </Button>

          <Button
            type="submit"
            variant="primary"
            css={{
              alignItems: 'center',
              display: 'inline-flex',
              height: '32px',
              justifyContent: 'center',
              margin: '0',
              padding: '0',
              width: '56px',
            }}
          >
            Save
          </Button>
        </Flex>
      </form>
    </Box>
  );
}

EditView.propTypes = {
  handleDelete: PropTypes.func.isRequired,
  handleFieldChange: PropTypes.func.isRequired,
  handlePrivacy: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isOwner: PropTypes.bool.isRequired,
  list: PropTypes.shape({
    attributes: PropTypes.shape({
      description: PropTypes.string,
      name: PropTypes.string,
    }),
  }).isRequired,
};
