import PropTypes from 'prop-types';
import styled from "@emotion/styled";
import { withTheme } from '@emotion/react';

const Label = styled("label")(({ inline, theme, width }) => ({
  color: theme.colors.dark08,
  display: inline ? 'inline-block' : 'block',
  fontFamily: theme.font.family,
  fontSize: '14px',
  fontWeight: '500',
  letterSpacing: '0.02em',
  lineHeight: `${18 / 14}em`,
  whiteSpace: 'nowrap',
  width,
  '& > *': {
    marginTop: '8px',
  },
  '& > [data-tooltip="wrapper"]': {
    margin: '0 0 0 8px',
  },
}));

Label.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.shape({}).isRequired,
  inline: PropTypes.bool,
  width: PropTypes.string,
};

Label.defaultProps = {
  inline: false,
  width: '100%',
};

export default withTheme(Label);
