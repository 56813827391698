/* global window */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import NewModal from './NewModal';
import OldModal from './OldModal';

import ModalBody from './newModal/Body';
import ModalFooter from './newModal/FooterActions';
import ModalHeader from './newModal/Header';

class Modal extends React.PureComponent {
  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  handleClick = event => {
    if (event.target === event.currentTarget) this.closeModal();
  };

  handleKeyDown = event => {
    if (event.code === 'Escape') this.closeModal();
  };

  closeModal = event => {
    if (event) {
      event.preventDefault();
    }

    const { hideModal, onClose, returnTo } = this.props;
    const { router } = this.context;

    if (returnTo) {
      router.replace(returnTo);
    } else {
      hideModal();
      onClose();
    }
  };

  render() {
    const { modal, useNewComponent } = this.props;
    let ModalComponent = OldModal;

    if (modal.useNewComponent || useNewComponent) {
      ModalComponent = NewModal;
    }

    return (
      <ModalComponent
        {...this.props}
        handleClick={this.handleClick}
        handleClose={this.closeModal}
      />
    );
  }
}

Modal.contextTypes = {
  router: PropTypes.object.isRequired,
};

Modal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]).isRequired,
  hideModal: PropTypes.func.isRequired,
  modal: PropTypes.shape({}).isRequired,
  modalStyle: PropTypes.shape({}),
  onClose: PropTypes.func,
  returnTo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  useNewComponent: PropTypes.bool,
  wrapperStyle: PropTypes.shape({}),
};

Modal.defaultProps = {
  modalStyle: {},
  onClose: () => {},
  returnTo: null,
  useNewComponent: false,
  wrapperStyle: null,
};

Modal.Body = ModalBody;
Modal.Footer = ModalFooter;
Modal.Header = ModalHeader;

const mapStateToProps = state => ({
  modal: state.modal,
});

const mapDispatchToProps = dispatch => ({
  hideModal: () => dispatch({ type: 'HIDE_MODAL' }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Modal);
