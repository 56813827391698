import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';

import CommentIcon from '../mc-ui/icons/CommentIcon';
import { Flex, Text, Tooltip, theme } from '../mc-ui/';

export default function CommentCounterBadge({ id, total }) {
  if (!total || total === 0) return null;

  return (
    <Tooltip
      dark
      element={
        <Flex
          alignItems="center"
          backgroundColor="orange"
          borderRadius="4px"
          boxShadow={`0px 1px 4px ${theme.colors.dark02}`}
          color="white"
          height="24px"
          padding="0 4px"
          css={{ fontSize: '16px' }}
        >
          <Text
            color="white"
            fontSize="12px"
            fontWeight="500"
            letterSpacing="0.02"
            lineHeight="12px"
            margin="0 4px 0 0"
          >
            {total}
          </Text>

          <CommentIcon />
        </Flex>
      }
      id={`commentCounterBadgeTooltip-${id}`}
      css={{
        '& > span': {
          display: 'inline-block',
          margin: '0',
        },
        '& [data-id="tooltip"]': {
          marginLeft: '0 !important',
        },
      }}
    >
      See {pluralize('comment', total)}
    </Tooltip>
  );
}

CommentCounterBadge.defaultProps = {
  id: Math.random(),
  total: 0,
};

CommentCounterBadge.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  total: PropTypes.number,
};
