import * as React from 'react';

function CheckboxDisabledIcon(props) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x={3.5} y={3.5} width={17} height={17} rx={3.5} fill="#F4F5F7" stroke="#DFE1E6" />
    </svg>
  );
}

export default CheckboxDisabledIcon;
