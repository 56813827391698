import colors from './colors';
import font from './font';

const lightStyle = {
  backgroundColor: colors.white,
  border: `2px solid ${colors.purpleMedium}`,
  borderColor: colors.purpleMedium,
  borderRadius: 4,
  boxShadow: `0px 4px 16px rgba(9, 30, 66, 0.2)`,
  boxSizing: 'border-box',
  maxWidth: 288,
  padding: 16,
  button: {
    backgroundColor: colors.purpleMedium,
    border: 'none',
    color: colors.white,
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '1.25em',
    marginTop: 12,
    width: '100%',
  },
  text: {
    color: colors.dark08,
    fontFamily: font.family,
    fontSize: '14px',
    lineHeight: '1.56em',
    margin: 0,
    padding: 0,
  },
  title: {
    color: colors.purpleDark,
    fontSize: 16,
    letterSpacing: '0.02em',
    marginBottom: 8,
  },
};

const tooltip = {
  dark: {
    backgroundColor: colors.dark1,
    border: `2px solid ${colors.dark1}`,
    borderColor: colors.dark1,
    borderRadius: 4,
    boxShadow: `0px 4px 16px rgba(9, 30, 66, 0.2)`,
    boxSizing: 'border-box',
    maxWidth: 288,
    padding: 16,
    button: {
      backgroundColor: colors.dark5,
      border: 'none',
      color: colors.dark08,
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '1.25em',
      marginTop: 12,
      width: '100%',
    },
    text: {
      color: colors.white,
      fontFamily: font.family,
      fontSize: '14px',
      lineHeight: '1.56em',
      margin: 0,
      padding: 0,
    },
    title: {
      color: colors.white,
      fontSize: 16,
      letterSpacing: '0.02em',
      marginBottom: 8,
    },
  },
  light: {
    ...lightStyle,
  },
  lightBlue: {
    ...lightStyle,
    border: `2px solid ${colors.blue}`,
    borderColor: colors.blue,
    button: {
      ...lightStyle.button,
      backgroundColor: colors.blue,
    },
    title: {
      ...lightStyle.title,
      color: colors.blueDark,
    },
  },
};

export default tooltip;
