import React from 'react';

import Section from './Section';

import { Flex } from './mc-ui';

import BrowseByIndustries from './BrowseByIndustries';

import FeaturedEmailLists from './dashboard/FeaturedEmailLists';
import FeaturedJourneys from './dashboard/FeaturedJourneys';
import FutureJourneys from './dashboard/FutureJourneys';
import Header from './dashboard/Header';
import RecentLists from './dashboard/RecentLists';
import Updates from './dashboard/Updates';

const HomeDashboard = () => (
  <div>
    <Header />

    <Section>
      <Flex alignItems="flex-start" justifyContent="space-between" paddingBottom="40px">
        <FeaturedEmailLists />
        <RecentLists />
      </Flex>
    </Section>

    <Section outerBackground="#FFF">
      <BrowseByIndustries />
    </Section>

    <Section>
      <Flex alignItems="flex-start" justifyContent="space-between" padding="40px 0">
        <FeaturedJourneys />
        <FutureJourneys />
      </Flex>
    </Section>

    <Section>
      <Updates />
    </Section>
  </div>
);

export default HomeDashboard;
