/* eslint-disable react/prop-types */

import React from 'react';
import styled from "@emotion/styled";

import Error from './Error';

const Textarea = styled("textarea")(({ theme, invalid }) => ({
  border: `1px solid ${invalid ? theme.colors.redDark : theme.colors.dark5}`,
  borderRadius: 4,
  boxSizing: 'border-box',
  color: theme.colors.dark08,
  fontSize: '14px',
  lineHeight: `${22 / 14}em`,
  margin: 0,
  outline: 'none',
  padding: `${4 / 14}em ${12 / 14}em`,
  resize: 'none',
  width: '100%',
  '&:focus': {
    borderColor: `${invalid ? theme.colors.redDark : theme.colors.blue}`,
  },
  '&::placeholder': {
    color: theme.colors.dark04,
  },
}));

const TextareaField = ({ input, meta, hideErrorMessage, theme, render, ...rest }) => {
  const invalid = (meta.error || meta.submitError) && meta.touched;

  return (
    <div>
      <Textarea
        {...rest}
        {...input}
        onChange={(event, val) => {
          if (rest.onChange) {
            rest.onChange(event);
          }
          input.onChange(event, val);
          input.onBlur(event, val);
        }}
        invalid={invalid}
      />
      {!hideErrorMessage && <Error error={meta.error || meta.submitError} touched={meta.touched} />}
    </div>
  );
};

export default TextareaField;
