import styled from "@emotion/styled";

const Wrapper = styled("div")(({ comparisonMode }) => {
  let padding = '40px 0 25px 0';

  if (comparisonMode) {
    padding = '20px';
  }

  return {
    backgroundColor: comparisonMode ? '#fff' : null,
    borderBottom: comparisonMode ? 'none' : '1px solid #d4d9dd',
    borderRadius: comparisonMode ? '3px' : null,
    boxSizing: 'border-box',
    marginBottom: comparisonMode ? '0' : '40px',
    padding,
    width: comparisonMode ? '49%' : null,
    '& > div': {
      alignItems: 'center',
      boxSizing: 'border-box',
      display: 'flex',
      flexWrap: comparisonMode ? 'wrap' : null,
      justifyContent: 'space-between',
      paddingBottom: '25px',
      position: 'relative',
      '& > a': {
        cursor: 'pointer',
        position: 'absolute',
        right: '-10px',
        top: '-10px',
      },
    },
  };
});

export default Wrapper;
