import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'ramda';

import { groupOverviewSelectors as selectors } from '../../../../modules/groupOverview';

import SparklineTooltip from '../../../newReports/SendingBehavior/NewsletterCampaignsSent/SparklineTooltip';

import Section from '../Section';

import Highlights from './Highlights';

const SendingBehavior = ({ data, loading, ...props }) => {
  if (loading || isEmpty(data)) return null;

  return (
    <Section>
      <Section.Title
        reportName="sending-behavior"
        title="Sending Behavior"
        tooltip={SparklineTooltip}
      />
      <Highlights data={data} {...props} />
    </Section>
  );
};

SendingBehavior.propTypes = {
  data: PropTypes.shape({}).isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  data: selectors.getData(state),
  loading: selectors.getLoading(state),
});

export default connect(mapStateToProps)(SendingBehavior);
