import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Box, Flex, Text } from '../../../mc-ui';

const Divider = ({ currentDate, previousDate }) => {
  const difference = moment.duration(moment(currentDate).diff(moment(previousDate))).humanize();

  return (
    <Flex alignItems="center" height="16px" margin="16px 0">
      <Box backgroundColor="dark01" borderRadius="4px" height="16px" padding="0 4px" width="unset">
        <Text
          color="dark1"
          fontSize="10px"
          fontWeight="bold"
          letterSpacing="0.12em"
          lineHeight="1.6em"
          textTransform="uppercase"
          whiteSpace="nowrap"
        >
          {difference}
        </Text>
      </Box>
      <Box backgroundColor="dark01" height="1" />
    </Flex>
  );
};

Divider.propTypes = {
  currentDate: PropTypes.string.isRequired,
  previousDate: PropTypes.string.isRequired,
};

export default Divider;
