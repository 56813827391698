import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

import { Box, Flex, Button, Heading } from '../../../../mc-ui';
import ArrowRightIcon from '../../../../mc-ui/icons/ArrowRightIcon';

import BrandLogo from '../../../BrandLogo';

import DataPoints from './DataPoints';

const BrandInfo = ({ model: brand }) => (
  <div>
    <Flex alignItems="center" marginBottom="8px">
      <BrandLogo brandId={brand.id} css={{ height: '64px', width: '64px' }} />
    </Flex>

    <Heading as="h2" css={{ fontWeight: '300', lineHeight: '1.32em', marginBottom: '16px' }}>
      {brand.attributes.name}
    </Heading>

    <Box marginBottom="40px">
      <DataPoints
        approximateEmails={brand.attributes.approximateEmails}
        firstEmailSentAt={brand.attributes.firstEmailSentAt}
        promotional={brand.attributes.promotional}
        frequency={brand.attributes.frequency}
      />
    </Box>

    <Button
      as={Link}
      to={`/companies/${brand.id}`}
      css={{
        alignItems: 'center',
        display: 'inline-flex',
        height: '32px',
        justifyContent: 'space-between',
        padding: '0 12px',
        width: '150px',
      }}
    >
      <ArrowRightIcon fillOpacity={1} height="20px" width="20px" /> See this brand
    </Button>
  </div>
);

BrandInfo.propTypes = {
  model: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.shape({
      firstActivityAt: PropTypes.string,
      type: PropTypes.string,
      lastEmailSentAt: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    meta: PropTypes.shape({
      durationDays: PropTypes.shape({
        value: PropTypes.number,
      }),
      intervalDays: PropTypes.shape({
        value: PropTypes.number,
      }),
      totalEmails: PropTypes.shape({
        value: PropTypes.number,
      }),
    }),
    relationships: PropTypes.shape({
      companies: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape({})),
      }),
    }),
  }).isRequired,
};

export default BrandInfo;
