import React from 'react'
import { Link } from 'react-router'
import { connect } from 'react-redux'

import api from '../lib/api'

class TrackCompany extends React.Component {
  state = { 
    description: false,
    icon: '/add.png',
  }
  handleClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
    let { tracking } = this.props.company.attributes;
    let company_id = this.props.company.id
    if (this.props.group) {
      let url = `/v2/groups/${this.props.group.id}/companies`
      ;(tracking ? api.delete(url, { params: { company_id } }) : api.post(url, { company_id }))
        .then(r => {
          this.props.dispatch({ type: 'REFRESH_USER' })
          if (this.props.handleTracked)
            return this.props.handleTracked(company_id, !tracking)
          this.props.dispatch({ type: 'REFRESH_COMPANIES' })
          this.props.dispatch({ type: 'REFRESH_GROUPS' })
        })
      .catch(err => {
        this.setState({ error: 'Limit reached', })
      })
    } else {
    (tracking ? 
     api.delete(`/v2/companies/${this.props.company.id}/untrack`)
     :
     api.post(`/v2/companies/${this.props.company.id}/track`)
    ).then(r => {
        this.props.dispatch({ type: 'REFRESH_USER' })
        if (this.props.handleTracked) 
          return this.props.handleTracked(company_id, !tracking)
        this.props.dispatch({ type: 'REFRESH_COMPANIES' })
      })
      .catch(err => {
        this.setState({ error: 'Limit reached', })
      })
    }
  }
  render() {
    let m = this.props.company
    return (
      <div className='pure-u-1'>
        <Link
          onClick={this.handleClick}
          to={`/${m.type}/${m.id}`}
        >
              <p
                style={{
                  margin: 0,
                  borderColor: m.attributes.tracking || this.state.error ? '#DD2325' : '#7ECA5B',
                  color: m.attributes.tracking || this.state.error ? '#DD2325' : '#7ECA5B',
                  fontSize: this.props.button ? `${24/16}em` : `${10/16}em`
                }}
                className='pure-button'
              >
                {this.state.error || this.props.label}
            </p>
        </Link>
      </div>
    )
  }
}

const s = (state) => ({})
export default connect(s)(TrackCompany)
