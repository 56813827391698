import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  savedSearchesActions as actions,
  savedSearchesSelectors as selectors,
} from '../../../modules/savedSearches';

import CheckSmallIcon from '../../mc-ui/icons/CheckSmallIcon';
import VerticalDotsIcon from '../../mc-ui/icons/VerticalDotsIcon';
import { Box, Button, theme } from '../../mc-ui';

import SaveSearchForm from '../../ui/SaveSearch/SaveSearchForm';

// eslint-disable-next-line react/prop-types
const MenuItem = ({ as: As = Button, ...props }) => (
  <As
    variant="none"
    css={{
      alignItems: 'center',
      border: 'none !important',
      display: 'flex',
      height: '32px',
      justifyContent: 'space-between',
      lineHeight: '32px',
      padding: '0 8px',
      width: '100%',
      '&:hover': { backgroundColor: theme.colors.dark6, border: 'none', color: theme.colors.dark1 },
    }}
    {...props}
  />
);

class FilterControlDropdown extends React.Component {
  state = {
    formOpen: false,
    open: false,
    sharingWithTeam: false,
    sharingWithUser: false,
  };

  close = event => {
    if (event) event.preventDefault();
    this.setState(() => ({ formOpen: false, open: false }));
  };

  open = event => {
    if (event) event.preventDefault();
    this.setState(() => ({ open: true }));
  };

  closeEditForm = event => {
    if (event) event.preventDefault();
    this.setState(() => ({ formOpen: false }));
  };

  openEditForm = event => {
    if (event) event.preventDefault();
    this.setState(() => ({ formOpen: true }));
  };

  handleSubmit = values => {
    const { isFilteringTeamFilters, updateTeamFilter, updateUserFilter } = this.props;
    const updateFunc = isFilteringTeamFilters ? updateTeamFilter : updateUserFilter;
    updateFunc(values, this.close);
  };

  shareFilterWithTeam = event => {
    event.preventDefault();
    const { createTeamFilter, filter } = this.props;

    this.setState(
      () => ({ sharingWithTeam: true }),
      () => {
        createTeamFilter({ filter_id: filter.id });
      },
    );
  };

  shareFilterWithUser = event => {
    event.preventDefault();
    const { createUserFilter, filter } = this.props;

    this.setState(
      () => ({ sharingWithUser: true }),
      () => {
        createUserFilter({ filter_id: filter.id });
      },
    );
  };

  render() {
    const {
      deleteTeamFilter,
      deleteUserFilter,
      filter,
      isFilteringTeamFilters,
      isValidTeam,
      teamFilterIds,
      userFilterIds,
    } = this.props;
    const { formOpen, open, sharingWithTeam, sharingWithUser } = this.state;
    const shared = isFilteringTeamFilters
      ? userFilterIds.includes(filter.id.toString())
      : teamFilterIds.includes(filter.id.toString());

    return (
      <Box onMouseLeave={this.close} position="relative" width="unset">
        <Button
          onClick={this.open}
          variant="none"
          css={{
            alignItems: 'center',
            color: open ? `${theme.colors.dark1} !important` : `${theme.colors.dark06} !important`,
            display: 'inline-flex',
            fontSize: '24px',
            height: '24px',
            justifyContent: 'center',
            margin: '0',
            padding: '0',
            width: '24px',
          }}
        >
          <VerticalDotsIcon />
        </Button>

        {open && (
          <Box
            backgroundColor="white"
            borderRadius="4px"
            padding="4px"
            position="absolute"
            right="0"
            top="14px"
            width="232px"
            css={{
              filter: 'drop-shadow(0px 2px 8px rgba(9, 30, 66, 0.2))',
              '& > div': { width: '100% !important' },
            }}
            zIndex={theme.zIndices.dropdown}
          >
            {isFilteringTeamFilters && (
              <MenuItem disabled={shared || sharingWithUser} onClick={this.shareFilterWithUser}>
                Copy to My Saved Filters
                {(shared || sharingWithUser) && <CheckSmallIcon />}
              </MenuItem>
            )}

            {!isFilteringTeamFilters &&
              isValidTeam && (
                <MenuItem disabled={shared || sharingWithTeam} onClick={this.shareFilterWithTeam}>
                  Copy to Team Saved Filters
                  {(shared || sharingWithTeam) && <CheckSmallIcon />}
                </MenuItem>
              )}

            <MenuItem onClick={this.openEditForm}>Edit</MenuItem>

            <MenuItem
              onClick={isFilteringTeamFilters ? deleteTeamFilter : deleteUserFilter}
              variant="destructive"
            >
              Remove
            </MenuItem>
          </Box>
        )}

        {formOpen && (
          <Box
            position="absolute"
            right="104px"
            top="64px"
            width="unset"
            zIndex={theme.zIndices.dropdown + 1}
          >
            <SaveSearchForm
              handleClose={this.closeEditForm}
              initialValues={{ name: filter.attributes.name }}
              onSubmit={this.handleSubmit}
            />
          </Box>
        )}
      </Box>
    );
  }
}

FilterControlDropdown.defaultProps = {
  isFilteringTeamFilters: false,
};

FilterControlDropdown.propTypes = {
  createTeamFilter: PropTypes.func.isRequired,
  createUserFilter: PropTypes.func.isRequired,
  deleteTeamFilter: PropTypes.func.isRequired,
  deleteUserFilter: PropTypes.func.isRequired,
  filter: PropTypes.shape({}).isRequired,
  isFilteringTeamFilters: PropTypes.bool,
  isValidTeam: PropTypes.bool.isRequired,
  teamFilterIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  updateTeamFilter: PropTypes.func.isRequired,
  updateUserFilter: PropTypes.func.isRequired,
  userFilterIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = state => ({
  isValidTeam: selectors.isValidTeamSelector(state),
  teamFilterIds: selectors.teamFilterIdsSelector(state),
  userFilterIds: selectors.userFilterIdsSelector(state),
});

const mapDispatchToProps = (dispatch, { filter }) => ({
  createTeamFilter: (...args) => dispatch(actions.createTeamFilterRequest(...args)),
  createUserFilter: (...args) => dispatch(actions.createUserFilterRequest(...args)),
  deleteTeamFilter: () => dispatch(actions.deleteTeamFilterRequest(filter.id)),
  deleteUserFilter: () => dispatch(actions.deleteUserFilterRequest(filter.id)),
  updateTeamFilter: (...args) => dispatch(actions.updateTeamFilterRequest(filter.id, ...args)),
  updateUserFilter: (...args) => dispatch(actions.updateUserFilterRequest(filter.id, ...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FilterControlDropdown);
