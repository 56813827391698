import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';

import { Flex, Text } from '../../../mc-ui';

import BrandsTable from '../../BrandsTable';

const BrandsList = ({ brands, full }) => {
  if (isEmpty(brands)) {
    return (
      <Flex alignItems="center" height="257px" justifyContent="center">
        <Text>No data</Text>
      </Flex>
    );
  }

  const max = Math.max(...brands.map(({ value }) => value));

  return (
    <BrandsTable>
      {brands.map(brand => (
        <BrandsTable.Row key={brand.id} full={full}>
          <BrandsTable.Logo brand={brand} />
          <BrandsTable.Bar
            id={brand.id}
            label={`${brand.value.toFixed(2)}`}
            size={Math.round((brand.value * 100) / max)}
            tooltip={brand.attributes.name}
          />
        </BrandsTable.Row>
      ))}
    </BrandsTable>
  );
};

BrandsList.defaultProps = {
  full: false,
};

BrandsList.propTypes = {
  brands: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  full: PropTypes.bool,
};

export default BrandsList;
