import React from 'react';
import PropTypes from 'prop-types';

import Dropdown from './intervalDropdown/Dropdown';
import DropdownItem from './intervalDropdown/DropdownItem';

const IntervalDropdown = ({ intervals, isOpen, onSelect, selectedInterval }) => {
  if (!isOpen) return null;

  return (
    <Dropdown>
      {intervals.map(interval => (
        <DropdownItem
          key={interval.value}
          data-interval={interval.value}
          onClick={onSelect}
          selected={interval.value === selectedInterval}
        >
          {interval.label}
        </DropdownItem>
      ))}
    </Dropdown>
  );
};

IntervalDropdown.propTypes = {
  intervals: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  selectedInterval: PropTypes.string,
  isOpen: PropTypes.bool,
};

IntervalDropdown.defaultProps = {
  isOpen: false,
  selectedInterval: '',
};

export default IntervalDropdown;
