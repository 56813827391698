import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import qs from 'qs';
import { connect } from 'react-redux';
import { withTheme } from '@emotion/react';

import { newReportsActions } from '../../modules/newReports';

import { DateIntervalSelect } from '../mc-ui';

class CompareDateSelect extends React.Component {
  componentDidMount() {
    const query = qs.parse(this.props.router.location.search.replace('?', ''));
    this.props.initialize(query);
    this.updateQuery();
  }

  componentDidUpdate(prevProps) {
    const { endDate, startDate } = this.props;
    const { endDate: prevEndDate, startDate: prevStartDate } = prevProps;

    if (endDate !== prevEndDate || startDate !== prevStartDate) {
      this.updateQuery();
    }
  }

  componentWillUnmount() {
    this.clearQuery();
  }

  selectInterval = interval => {
    this.props.updateInterval(interval);
  };

  handleOnChange = ({ endDate, startDate }) => {
    this.props.changeDates(endDate.format('YYYY-MM-DD'), startDate.format('YYYY-MM-DD'));
  };

  updateQuery = () => {
    const { endDate, startDate, router } = this.props;
    const query = qs.parse(router.location.search.replace('?', ''));

    query.compare = query.compare || {};
    query.compare.end_date = moment(endDate).format('YYYY-MM-DD');
    query.compare.start_date = moment(startDate).format('YYYY-MM-DD');

    router.push({
      pathname: router.location.pathname,
      search: `?${qs.stringify(query)}`,
    });
  };

  clearQuery = () => {
    const { router } = this.props;
    const query = qs.parse(router.location.search.replace('?', ''));

    delete query.compare;

    router.push({
      pathname: router.location.pathname,
      search: `?${qs.stringify(query)}`,
    });
  };

  render() {
    const { endDate, interval, intervalLabel, intervalsOptions, startDate, theme } = this.props;

    return (
      <DateIntervalSelect
        endDate={endDate}
        intervals={intervalsOptions}
        label={intervalLabel}
        onChange={this.handleOnChange}
        onSelect={this.selectInterval}
        selectedInterval={interval}
        startDate={startDate}
        css={{
          borderColor: theme.colors.tealMedium,
          '& > label': {
            color: theme.colors.tealDark,
          },
        }}
      />
    );
  }
}

CompareDateSelect.defaultProps = {
  interval: null,
};

CompareDateSelect.propTypes = {
  changeDates: PropTypes.func.isRequired,
  endDate: PropTypes.string.isRequired,
  initialize: PropTypes.func.isRequired,
  interval: PropTypes.string,
  intervalLabel: PropTypes.string.isRequired,
  intervalsOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  router: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
  startDate: PropTypes.string.isRequired,
  theme: PropTypes.shape({}).isRequired,
  updateInterval: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  endDate: state.newReports.dates.compare.endDate,
  interval: state.newReports.dates.compare.interval,
  intervalLabel: state.newReports.dates.compare.intervalLabel,
  intervalsOptions: state.newReports.dates.compare.intervalsOptions,
  startDate: state.newReports.dates.compare.startDate,
});

const mapDispatchToProps = dispatch => ({
  changeDates: (endDate, startDate) =>
    dispatch(newReportsActions.changeComparisonDates(endDate, startDate)),
  initialize: query => dispatch(newReportsActions.initializeComparisonDates(query)),
  updateInterval: interval => dispatch(newReportsActions.updateComparisonInterval(interval)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTheme(CompareDateSelect));
