import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { flatten, isEmpty } from 'ramda';
import { withTheme } from '@emotion/react';

import { timeSeriesSelectors } from '../../../../modules/newReports/timeSeries';

import { Badge, Checkbox, Flex, Loader, Tooltip } from '../../../mc-ui';

import Chart from './Chart';
import brandColors from './brandColors';
import generateDefaultTooltipHTML from './Chart/generateDefaultTooltipHTML';

const keyToDate = key => moment(key.split('T')[0]);
const keyDateToLabel = date => date.format('MMM D');
const formatNumber = (val, chart) => {
  if (!val) return 0;
  if (chart === 'promotional') return Number((val * 100).toFixed(2));
  if (chart === 'subject_line_length') return Math.round(val);
  return Number(val.toFixed(2));
};
const reduceData = (buckets, chart, filter) =>
  buckets.reduce(
    (aggregation, bucket) => {
      const keyAsDate = keyToDate(bucket.key_as_string);
      let title = '';

      if (filter === 'day') {
        title = keyAsDate.format('M/D/YY');
      } else if (filter === 'week') {
        const endDate = keyAsDate.clone().add(7, 'd');
        title = `${keyAsDate.format('M/D/YY')} - ${endDate.format('M/D/YY')}`;
      } else {
        title = keyAsDate.format('MMMM');
      }

      aggregation.labels.push(keyDateToLabel(keyAsDate));
      aggregation.data.push(formatNumber(bucket[chart].value, chart));
      aggregation.titles.push(title);

      return aggregation;
    },
    { labels: [], data: [], titles: [] },
  );

const toChartData = (
  chart,
  filter,
  data,
  historicalTrendData,
  datesComparisonData,
  brandsComparisonData,
) => {
  const chartData = reduceData(data.current.interval.buckets, chart, filter);

  const chartHistoricalTrendData = isEmpty(historicalTrendData)
    ? {}
    : reduceData(historicalTrendData.current.interval.buckets, chart, filter);

  const chartDatesData = isEmpty(datesComparisonData)
    ? {}
    : reduceData(datesComparisonData.current.interval.buckets, chart, filter);

  const chartBrandsData = isEmpty(brandsComparisonData)
    ? []
    : brandsComparisonData.map((brandData, index) => ({
        brandName: brandData.brand.name,
        color: brandColors[index],
        ...reduceData(brandData.current.interval.buckets, chart, filter),
      }));
  const brandsValues = flatten(chartBrandsData.map(b => b.data));

  const max = Math.max(
    ...chartData.data,
    ...(chartHistoricalTrendData.data || []),
    ...(chartDatesData.data || []),
    ...brandsValues,
  );

  return {
    data: chartData,
    historicalTrendData: chartHistoricalTrendData,
    datesData: chartDatesData,
    brandsData: chartBrandsData,
    max,
  };
};

const LineChart = ({
  availableFilters,
  brandsComparisonData,
  chart,
  data,
  datesComparisonData,
  endDate,
  filter,
  generateTooltipHTML,
  historicalTrend,
  historicalTrendData,
  loading,
  setFilter,
  showHolidays,
  theme,
  toggleHistoricalTrend,
}) => {
  const badgeProps = {
    default: {
      color: 'dark08',
      css: {
        border: `1px solid ${theme.colors.dark5}`,
        cursor: 'pointer',
        lineHeight: '1em',
        marginLeft: '12px',
      },
    },
    active: {
      backgroundColor: 'blueLight',
      color: 'dark08',
      css: {
        border: `1px solid ${theme.colors.blueMedium}`,
        lineHeight: '1em',
        marginLeft: '12px',
      },
    },
  };

  if (loading || !endDate) {
    return (
      <Flex alignItems="center" height="303px" justifyContent="center">
        <Loader />
      </Flex>
    );
  }

  const chartData = toChartData(
    chart,
    filter,
    data,
    historicalTrendData,
    datesComparisonData,
    brandsComparisonData,
  );

  return (
    <Flex direction="column">
      <Flex alignItems="center" justifyContent="space-between" css={{ marginBottom: '25px' }}>
        <Flex>
          <Tooltip
            id="historicalTrendTooltip"
            element={
              <Checkbox
                id="historicalTrend"
                label={`Historical Trend (Last Year: ${moment(endDate)
                  .subtract(1, 'y')
                  .format('YYYY')})`}
                defaultChecked={historicalTrend}
                onChange={toggleHistoricalTrend}
                css={{ margin: '0' }}
              />
            }
            dark
          >
            <p>
              View the historical trend line to see reporting data during the same date range last
              year. Track year-over-year data to gain efficiency and foresight.
            </p>
          </Tooltip>
        </Flex>

        <div>
          {availableFilters.includes('day') && (
            <Badge
              {...(filter === 'day' ? badgeProps.active : badgeProps.default)}
              onClick={() => setFilter('day')}
            >
              Daily
            </Badge>
          )}

          {availableFilters.includes('week') && (
            <Badge
              {...(filter === 'week' ? badgeProps.active : badgeProps.default)}
              onClick={() => setFilter('week')}
            >
              Weekly
            </Badge>
          )}

          {availableFilters.includes('month') && (
            <Badge
              {...(filter === 'month' ? badgeProps.active : badgeProps.default)}
              onClick={() => setFilter('month')}
            >
              Monthly
            </Badge>
          )}
        </div>
      </Flex>

      <Chart
        chart={chart}
        data={chartData}
        generateTooltipHTML={generateTooltipHTML}
        historicalTrend={historicalTrend}
        showHolidays={showHolidays}
      />
    </Flex>
  );
};

LineChart.defaultProps = {
  compareBrands: [],
  compareDates: {},
  endDate: null,
  generateTooltipHTML: generateDefaultTooltipHTML,
};

LineChart.propTypes = {
  availableFilters: PropTypes.arrayOf(PropTypes.string).isRequired,
  brandsComparisonData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  chart: PropTypes.string.isRequired,
  compareBrands: PropTypes.arrayOf(PropTypes.shape({})),
  compareDates: PropTypes.shape({}),
  data: PropTypes.shape({}).isRequired,
  datesComparisonData: PropTypes.shape({}).isRequired,
  endDate: PropTypes.string,
  filter: PropTypes.string.isRequired,
  generateTooltipHTML: PropTypes.func,
  historicalTrend: PropTypes.bool.isRequired,
  historicalTrendData: PropTypes.shape({}).isRequired,
  loading: PropTypes.bool.isRequired,
  setFilter: PropTypes.func.isRequired,
  showHolidays: PropTypes.bool.isRequired,
  theme: PropTypes.shape({}).isRequired,
  toggleHistoricalTrend: PropTypes.func.isRequired,
};

const mapStateToProps = (
  state,
  { brandsComparison, chart, datesComparison, filter, historicalTrend, model },
) => {
  const { compare, endDate, startDate } = state.newReports.dates;

  const timeSeriesData = timeSeriesSelectors.getTimeSeriesData(
    model.id,
    model.type,
    chart,
    filter,
    startDate,
    endDate,
  )(state);

  const timeSeriesLoading = timeSeriesSelectors.getTimeSeriesLoading(
    model.id,
    model.type,
    chart,
    filter,
    startDate,
    endDate,
  )(state);

  let timeSeriesHistoricalTrendData = {};
  let timeSeriesHistoricalTrendLoading = false;
  if (historicalTrend) {
    timeSeriesHistoricalTrendData = timeSeriesSelectors.getTimeSeriesHistoricalTrendData(
      model.id,
      model.type,
      chart,
      filter,
      startDate,
      endDate,
    )(state);

    timeSeriesHistoricalTrendLoading = timeSeriesSelectors.getTimeSeriesHistoricalTrendLoading(
      model.id,
      model.type,
      chart,
      filter,
      startDate,
      endDate,
    )(state);
  }

  let timeSeriesComparisonData = {};
  let timeSeriesComparisonLoading = false;
  if (datesComparison) {
    timeSeriesComparisonData = timeSeriesSelectors.getTimeSeriesComparisonData(
      model.id,
      model.type,
      chart,
      filter,
      startDate,
      endDate,
      compare.startDate,
      compare.endDate,
    )(state);

    timeSeriesComparisonLoading = timeSeriesSelectors.getTimeSeriesComparisonLoading(
      model.id,
      model.type,
      chart,
      filter,
      startDate,
      endDate,
      compare.startDate,
      compare.endDate,
    )(state);
  }

  let timeSeriesBrandsData = [];
  if (brandsComparison) {
    timeSeriesBrandsData = timeSeriesSelectors.getTimeSeriesBrandsData(
      model.id,
      model.type,
      chart,
      filter,
      startDate,
      endDate,
    )(state);
  }

  return {
    data: timeSeriesData,
    historicalTrendData: timeSeriesHistoricalTrendData,
    datesComparisonData: timeSeriesComparisonData,
    brandsComparisonData: timeSeriesBrandsData,
    loading: timeSeriesLoading || timeSeriesHistoricalTrendLoading || timeSeriesComparisonLoading,
    endDate,
  };
};

export default connect(mapStateToProps)(withTheme(LineChart));
