import { camelizeKeys } from 'humps';
import { take } from 'ramda';
import moment from 'moment';

import api from '../../../lib/api';

export const fetchFeaturedJourneys = async () => {
  const response = await api.get('/v3/journeys?featured=true&size=7');
  return camelizeKeys(take(7, response.data.data));
};

export const fetchFutureJourneys = async () => {
  const pathname = `/v3/journeys`;
  const from = moment(new Date())
    .startOf('day')
    .add(1, 'days')
    .format('YYYY-MM-DD');
  const params = { status: 'upcoming', sort: 'start_date', from };
  const response = await api.get(pathname, { params });
  const journeys = camelizeKeys(response.data.data);
  return journeys.slice(0, 7);
};

export const fetchCustomerJourneys = () =>
  Promise.resolve([
    { id: '684', type: 'lists', attributes: { journeyType: 'welcome' } },
    { id: '673', type: 'lists', attributes: { journeyType: 'account_confirmation' } },
    { id: '690', type: 'lists', attributes: { journeyType: 'onboarding' } },
    { id: '671', type: 'lists', attributes: { journeyType: 'cart_abandonment' } },
    { id: '2388', type: 'lists', attributes: { journeyType: 'browse_abandonment' } },
    { id: '57', type: 'lists', attributes: { journeyType: 'order_confirmation' } },
    { id: '687', type: 'lists', attributes: { journeyType: 'shipping_confirmation' } },
    { id: '1330', type: 'lists', attributes: { journeyType: 'delivery_confirmation' } },
    { id: '2480', type: 'lists', attributes: { journeyType: 'refer_a_friend' } },
    { id: '1379', type: 'lists', attributes: { journeyType: 'cancellation' } },
    { id: '9', type: 'lists', attributes: { journeyType: 'win_back' } },
  ]);

export const fetchLists = async () => {
  const responses = await Promise.all([
    api.get('/v3/lists/587'), // Birthdays
    api.get('/v3/lists/2657'), // Introducing new collection
    api.get('/v3/lists/796'), // Leave a review
    api.get('/v3/lists/2481'), // Loyalty & Rewards clubs
    api.get('/v3/lists/2656'), // Product promotion
    api.get('/v3/lists/2480'), // Refer a friend
    api.get('/v3/lists/2655'), // Sales and Discounts
    api.get('/v3/lists/52'), // Surveys
  ]);

  const lists = responses.map(response => response.data.data[0]);
  return camelizeKeys(lists);
};
