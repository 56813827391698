import React from 'react';
import PropTypes from 'prop-types';

import Badge from './Badge';

const DEFAULT_TITLE = 'Unlocked';
const DEFAULT_TEXT =
  'You are viewing unlocked data. MailCharts Pro users have access to all MailCharts email data and reporting from thousands of ecommerce brands.';

const Unlocked = ({ title, text, style, user, unlocked, overrideRestriction }) => {
  if (!overrideRestriction && (!user.attributes.restricted || !unlocked)) return null;

  return (
    <div className="pure-g">
      <div
        className="pure-u-1"
        style={{
          background: '#FFFFFF',
          border: '1px solid #FFD336',
          borderRadius: 4,
          padding: 16,
          ...style,
        }}
      >
        <div
          className="pure-u-1-24"
          style={{
            padding: '8px 0',
          }}
        >
          <img src="/lock.svg" width="18" alt="Lock" />
        </div>
        <div className="pure-u-23-24">
          <div>
            <Badge
              backgroundColor="yellowMedium"
              color="dark1"
              fontSize="10px"
              letterSpacing="0.08em"
              lineHeight="1.56em"
              padding="4px 7.625px"
            >
              {title}
            </Badge>
            <p
              style={{
                margin: '5px 0',
                padding: 0,
                lineHeight: '21.84px',
                fontSize: '14px',
                color: 'rgba(9,30,66,0.8)',
              }}
            >
              {text}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

Unlocked.defaultProps = {
  title: DEFAULT_TITLE,
  text: DEFAULT_TEXT,
  style: {},
  unlocked: false,
  overrideRestriction: false,
};

Unlocked.propTypes = {
  title: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  style: PropTypes.shape({}),
  unlocked: PropTypes.bool,
  user: PropTypes.shape({
    attributes: PropTypes.shape({
      restricted: PropTypes.bool,
    }),
  }).isRequired,
  overrideRestriction: PropTypes.bool,
};

export default Unlocked;
