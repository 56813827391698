import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@emotion/react';

import { Box, Flex, Heading, Tooltip } from '../mc-ui';
import QuestionCircleIcon from '../mc-ui/icons/QuestionCircleIcon';

class HeaderContainer extends React.Component {
  state = {
    sticky: false,
  };

  componentDidMount() {
    window.setTimeout(() => {
      const header = document.querySelector('[data-identifier=header]');
      this.headerOffsetTop = header.offsetTop;
      this.mainWrapper = document.querySelector('[data-identifier=main-wrapper]');

      window.addEventListener('scroll', this.handleScroll);
    }, 500);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    this.setState(() => ({ sticky: window.pageYOffset >= this.headerOffsetTop }));
  };

  headerOffsetTop = 0;
  mainWrapper = null;

  render() {
    const { sticky } = this.state;
    const { children, css, theme, title, tooltip } = this.props;

    const stickyStyle = {
      margin: '0',
      position: 'fixed',
      top: '0',
      width: (this.mainWrapper || {}).offsetWidth,
      '& + .report-body': {
        paddingTop: '96px',
      },
    };

    return (
      <Flex
        alignItems="center"
        justifyContent="space-between"
        css={{
          backgroundColor: theme.colors.white,
          borderRadius: '4px',
          boxShadow: `0px 1px 4px ${theme.colors.dark02}`,
          boxSizing: 'border-box',
          height: '64px',
          marginBottom: '16px',
          marginTop: '1em',
          padding: '16px',
          width: '100%',
          zIndex: 999,
          ...css,
          ...(sticky ? stickyStyle : {}),
        }}
        data-identifier="header"
      >
        <Heading
          as="h3"
          color="dark08"
          fontSize="24px"
          fontWeight="300"
          lineHeight="32px"
          css={{ margin: '0 0 0 16px', padding: '0' }}
        >
          {title}
          {tooltip && (
            <Box
              css={{
                color: theme.colors.dark06,
                display: 'inline',
                fontSize: '12px',
                lineHeight: '1em',
                marginLeft: '6px',
                width: 'auto',
              }}
            >
              <Tooltip
                css={{
                  fontSize: '22px',
                  height: '22px',
                  marginRight: '8px',
                  width: '22px',
                  '& > span': {
                    display: 'inline-block',
                    height: '22px',
                    margin: '0',
                    width: '22px',
                  },
                  '& [data-id="tooltip"]': {
                    marginLeft: '0 !important',
                  },
                }}
                id="headerContainerTooltip"
                element={<QuestionCircleIcon fillOpacity={1} height="22px" width="22px" />}
                dark
              >
                {tooltip}
              </Tooltip>
            </Box>
          )}
        </Heading>
        {children}
      </Flex>
    );
  }
}

HeaderContainer.defaultProps = {
  children: null,
  css: {},
  tooltip: null,
};

HeaderContainer.propTypes = {
  children: PropTypes.node,
  theme: PropTypes.shape({}).isRequired,
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.node,
  css: PropTypes.shape({}),
};

export default withTheme(HeaderContainer);
