/** @jsx jsx */
import PropTypes from 'prop-types';
import { jsx, withTheme } from '@emotion/react';

import Body from './table/Body';
import Cell from './table/Cell';
import Head from './table/Head';
import IndexCell from './table/IndexCell';
import Row from './table/Row';

const Table = ({ 'aria-label': ariaLabel, children, css: customCSS, style, theme }) => (
  <table
    css={{
      border: 'none',
      borderCollapse: 'collapse',
      borderSpacing: 0,
      boxSizing: 'border-box',
      color: theme.colors.dark1,
      display: 'table',
      fontFamily: theme.font.family,
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: '1.571428571em',
      margin: 0,
      minWidth: customCSS.width || style.width ? null : '650px',
      padding: 0,
      width: '100%',
      ...customCSS,
      ...style,
      '& *': {
        boxSizing: 'border-box',
        color: theme.colors.dark1,
        fontFamily: theme.font.family,
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 'normal',
        lineHeight: '1.571428571em',
      },
    }}
    aria-label={ariaLabel}
  >
    {children}
  </table>
);

Table.defaultProps = {
  'aria-label': 'table',
  css: {},
  style: {},
};

Table.propTypes = {
  'aria-label': PropTypes.string,
  children: PropTypes.node.isRequired,
  css: PropTypes.shape({}),
  style: PropTypes.shape({}),
  theme: PropTypes.shape({}).isRequired,
};

const ThemeTable = withTheme(Table);

ThemeTable.Body = Body;
ThemeTable.Cell = Cell;
ThemeTable.Head = Head;
ThemeTable.IndexCell = IndexCell;
ThemeTable.Row = Row;

export default ThemeTable;