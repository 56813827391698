import moment from 'moment';

const groupByDay = data =>
  data.reduce((acc, model) => {
    const date = moment(
      model.type === 'emails' ? model.attributes.sentAt : model.attributes.occurredAt,
    );
    const key = date.format('YYYY-MM-DD');
    const current = acc[key] || { emails: [], events: [] };
    current[model.type].push(model);

    return {
      ...acc,
      [key]: current,
    };
  }, {});

export default groupByDay;
