import styled from "@emotion/styled";

const IntervalWrapper = styled("div")(({ comparisonMode }) => ({
  alignItems: comparisonMode ? 'flex-start' : 'center',
  display: 'flex',
  marginTop: comparisonMode ? '8px' : null,
  width: comparisonMode ? '100%' : null,
  '& h5': {
    display: 'inline',
    marginRight: 10,
  },
  '& select': {
    fontSize: `${13 / 16}em`,
    maxHeight: '32px',
    minWidth: '112px',
  },
}));

export default IntervalWrapper;
