import * as types from './types';

// MAIN DATASET DATA
export const fetchDataRequest = (model, chart, interval, startDate, endDate) => ({
  type: types.FETCH_DATA_REQUEST,
  modelId: model.id,
  modelType: model.type === 'companies' ? 'brands' : 'groups',
  chart,
  interval,
  startDate,
  endDate,
});

export const fetchDataFailure = (
  modelId,
  modelType,
  chart,
  interval,
  startDate,
  endDate,
  error,
) => ({
  type: types.FETCH_DATA_FAILURE,
  modelId,
  modelType,
  chart,
  interval,
  startDate,
  endDate,
  error,
});

export const fetchDataSuccess = (
  modelId,
  modelType,
  chart,
  interval,
  startDate,
  endDate,
  payload,
) => ({
  type: types.FETCH_DATA_SUCCESS,
  modelId,
  modelType,
  chart,
  interval,
  startDate,
  endDate,
  payload,
});

export const toggleDataLoading = (modelId, modelType, chart, interval, startDate, endDate) => ({
  type: types.TOGGLE_DATA_LOADING,
  modelId,
  modelType,
  chart,
  interval,
  startDate,
  endDate,
});

// BRANDS COMPARISION DATASET DATA
export const fetchBrandsRequest = (model, chart, interval, startDate, endDate, brandIds) => ({
  type: types.FETCH_BRANDS_REQUEST,
  modelId: model.id,
  modelType: model.type === 'companies' ? 'brands' : 'groups',
  chart,
  interval,
  startDate,
  endDate,
  brandIds,
});

export const fetchBrandsFailure = (
  modelId,
  modelType,
  chart,
  interval,
  startDate,
  endDate,
  error,
) => ({
  type: types.FETCH_BRANDS_FAILURE,
  modelId,
  modelType,
  chart,
  interval,
  startDate,
  endDate,
  error,
});

export const fetchBrandsSuccess = (
  modelId,
  modelType,
  chart,
  interval,
  startDate,
  endDate,
  payload,
) => ({
  type: types.FETCH_BRANDS_SUCCESS,
  modelId,
  modelType,
  chart,
  interval,
  startDate,
  endDate,
  payload,
});

export const toggleBrandsLoading = (
  modelId,
  modelType,
  chart,
  interval,
  startDate,
  endDate,
  brandId,
) => ({
  type: types.TOGGLE_BRANDS_LOADING,
  modelId,
  modelType,
  chart,
  interval,
  startDate,
  brandId,
  endDate,
});

// DATES COMPARISON DATASET DATA
export const fetchComparisonDataRequest = (
  model,
  chart,
  interval,
  startDate,
  endDate,
  comparisonStartDate,
  comparisonEndDate,
) => ({
  type: types.FETCH_COMPARISON_REQUEST,
  modelId: model.id,
  modelType: model.type === 'companies' ? 'brands' : 'groups',
  chart,
  interval,
  startDate,
  endDate,
  comparisonStartDate,
  comparisonEndDate,
});

export const fetchComparisonDataFailure = (
  modelId,
  modelType,
  chart,
  interval,
  startDate,
  endDate,
  comparisonStartDate,
  comparisonEndDate,
  error,
) => ({
  type: types.FETCH_COMPARISON_FAILURE,
  modelId,
  modelType,
  chart,
  interval,
  startDate,
  endDate,
  comparisonStartDate,
  comparisonEndDate,
  error,
});

export const fetchComparisonDataSuccess = (
  modelId,
  modelType,
  chart,
  interval,
  startDate,
  endDate,
  comparisonStartDate,
  comparisonEndDate,
  payload,
) => ({
  type: types.FETCH_COMPARISON_SUCCESS,
  modelId,
  modelType,
  chart,
  interval,
  startDate,
  endDate,
  comparisonStartDate,
  comparisonEndDate,
  payload,
});

export const toggleComparisonDataLoading = (
  modelId,
  modelType,
  chart,
  interval,
  startDate,
  endDate,
  comparisonStartDate,
  comparisonEndDate,
) => ({
  type: types.TOGGLE_COMPARISON_LOADING,
  modelId,
  modelType,
  chart,
  interval,
  startDate,
  comparisonStartDate,
  comparisonEndDate,
  endDate,
});

// HISTORICAL_TREND DATASET DATA
export const fetchHistoricalTrendRequest = (model, chart, interval, startDate, endDate) => ({
  type: types.FETCH_HISTORICAL_TREND_REQUEST,
  modelId: model.id,
  modelType: model.type === 'companies' ? 'brands' : 'groups',
  chart,
  interval,
  startDate,
  endDate,
});

export const fetchHistoricalTrendFailure = (
  modelId,
  modelType,
  chart,
  interval,
  startDate,
  endDate,
  error,
) => ({
  type: types.FETCH_HISTORICAL_TREND_FAILURE,
  modelId,
  modelType,
  chart,
  interval,
  startDate,
  endDate,
  error,
});

export const fetchHistoricalTrendSuccess = (
  modelId,
  modelType,
  chart,
  interval,
  startDate,
  endDate,
  payload,
) => ({
  type: types.FETCH_HISTORICAL_TREND_SUCCESS,
  modelId,
  modelType,
  chart,
  interval,
  startDate,
  endDate,
  payload,
});

export const toggleHistoricalTrendLoading = (
  modelId,
  modelType,
  chart,
  interval,
  startDate,
  endDate,
) => ({
  type: types.TOGGLE_HISTORICAL_TREND_LOADING,
  modelId,
  modelType,
  chart,
  interval,
  startDate,
  endDate,
});
