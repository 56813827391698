import React from 'react';
import PropTypes from 'prop-types';

import { Box, Flex } from '../../mc-ui';

import ViewControls from './ViewControls';
import EmailAsImage from './EmailAsImage';
import EmailIframe from './EmailIframe';

const ALLOWED_VIEWS = ['alt', 'desktop', 'mobile', 'source'];
const DEFAULT_VIEW = 'desktop';

class EmailView extends React.Component {
  constructor(props) {
    super(props);

    let { view = DEFAULT_VIEW } = props.router.location.query;

    if (!ALLOWED_VIEWS.includes(view)) {
      view = DEFAULT_VIEW;
    }

    this.state = {
      view,
      zoom: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.email.data[0].id !== this.props.email.data[0].id) {
      this.resetState();
    }
  }

  handleViewChange = view => {
    const { router } = this.props;

    this.setState({ view, zoom: false }, () => {
      const query = { ...router.location.query, view };
      const { pathname } = router.location;

      router.push({ pathname, query });
    });
  };

  resetState = () => {
    let { view = DEFAULT_VIEW } = this.props.router.location.query;

    if (!ALLOWED_VIEWS.includes(view)) {
      view = DEFAULT_VIEW;
    }

    this.setState({ view, zoom: false });
  };

  toggleZoom = event => {
    if (event) event.preventDefault();

    this.setState(currentState => ({ zoom: !currentState.zoom }));
  };

  render() {
    const { view, zoom } = this.state;
    const { email, router } = this.props;
    const { action } = router.location.query
    const [model] = ((email || {}).data || []);

    const View = view === 'source' || zoom ? EmailIframe : EmailAsImage;

    return (
      <Box paddingBottom="32px">
        <ViewControls
          action={action}
          email={model}
          onViewChange={this.handleViewChange}
          view={view}
        />

        <Flex backgroundColor="dark6" justifyContent="center">
          <View
            email={model}
            guid={router.params.guid}
            loading={email.loading}
            size={view}
            toggleZoom={this.toggleZoom}
            view={view}
          />
        </Flex>
      </Box>
    );
  }
}

EmailView.propTypes = {
  email: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.object),
    loading: PropTypes.bool,
  }).isRequired,
  router: PropTypes.shape({
    location: PropTypes.shape({
      query: PropTypes.object,
    }),
    params: PropTypes.shape({
      guid: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
};

export default EmailView;
