import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'ramda';

import { sendingBehaviorSelectors as selectors } from '../../../../../modules/newReports/sendingBehavior';

import { Flex, Text } from '../../../../mc-ui';

import Header from './Header';
import Row from './Row';

const WeeklyHoursTable = ({ data: { sentAtDayOfWeek } }) =>
  isEmpty(sentAtDayOfWeek) ? (
    <Flex alignItems="center" height="309px" justifyContent="center">
      <Text>No data</Text>
    </Flex>
  ) : (
    <div>
      <Header />
      <Row data={sentAtDayOfWeek[0]} day='monday' idx={0} />
      <Row data={sentAtDayOfWeek[1]} day='tuesday'  idx={1}/>
      <Row data={sentAtDayOfWeek[2]} day='wednesday'  idx={2}/>
      <Row data={sentAtDayOfWeek[3]} day='thursday'  idx={3}/>
      <Row data={sentAtDayOfWeek[4]} day='friday'  idx={4}/>
      <Row data={sentAtDayOfWeek[5]} day='saturday'  idx={5}/>
      <Row data={sentAtDayOfWeek[6]} day='sunday'  idx={6}/>
    </div>
  );

WeeklyHoursTable.propTypes = {
  data: PropTypes.shape({
    sentAtDayOfWeek: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.number,
        sentAtHourOfDay: PropTypes.arrayOf(
          PropTypes.shape({
            docCount: PropTypes.number,
            key: PropTypes.number,
            value: PropTypes.number,
          }),
        ),
      }),
    ),
  }).isRequired,
};

const mapStateToProps = (state, { model }) => {
  if (!model.id) return { loading: true };

  const { endDate, startDate } = state.newReports.dates;

  return {
    data: selectors.getData(model.id, model.type, startDate, endDate)(state),
  };
};

export default connect(mapStateToProps)(WeeklyHoursTable);
