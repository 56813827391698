import colors from './colors';

const mozCss = () => {
  if (window.navigator.appCodeName !== 'Mozilla') return {};

  return {
    paddingBottom: '22px',
  };
};

const scrollBox = (css, afterCss = {}) => ({
  '& > div': {
    boxSizing: 'border-box',
    paddingBottom: '18px',
    paddingRight: '4px',
    overflowY: 'scroll',
    ...css,
    ...mozCss(),
    '&::-webkit-scrollbar': {
      WebkitAppearance: 'none',
      width: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '4px',
      backgroundColor: colors.dark01,
    },
  },
  '&::after': {
    background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 49.48%)',
    bottom: '0',
    content: "''",
    display: 'block',
    height: '46px',
    left: '0',
    position: 'absolute',
    width: '97%',
    ...afterCss,
  },
});

export default scrollBox;
