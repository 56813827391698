import React from 'react';
import PropTypes from 'prop-types';
import humanize from 'humanize-number';
import pluralize from 'pluralize';
import { connect } from 'react-redux';
import { isEmpty } from 'ramda';

import {
  customGroupsActions as actions,
  customGroupsSelectors as selectors,
} from '../../../modules/customGroups';

import GroupCard from '../../ui/GroupCard';
import { Box, Flex, Grid, Text } from '../../mc-ui';

import CreateNewGroupButton from './CreateNewGroupButton';
import GroupControlDropdown from './GroupControlDropdown';
import NoGroups from './NoGroups';
import SortSelect from './SortSelect';

class TeamGroups extends React.Component {
  constructor(props) {
    super(props);

    const { router } = props;
    const sort = router.location.query.sort || 'updated_at:desc';

    this.state = { sort };
  }

  componentDidMount() {
    this.fetchGroups();
  }

  componentDidUpdate(_prevProps, prevState) {
    if (this.state.sort !== prevState.sort) {
      this.fetchGroups();
    }
  }

  fetchGroups = () => {
    this.props.request({ sort: this.state.sort });
  };

  updateSort = sort => {
    const { router } = this.props;

    this.setState(
      () => ({ sort }),
      () => {
        router.replace({
          pathname: router.location.pathname,
          query: { ...router.location.query, sort },
        });
      },
    );
  };

  render() {
    const { handleCreate, groups, loading } = this.props;
    const { sort } = this.state;

    if (loading) return null;

    return (
      <Box>
        <Flex alignItems="center" justifyContent="space-between" marginBottom="24px">
          <Flex alignItems="center" width="unset">
            <SortSelect handleChange={this.updateSort} value={sort} />

            <Text css={{ marginLeft: '8px' }}>
              {humanize(groups.length)} {pluralize('group', groups.length)}
            </Text>
          </Flex>

          <CreateNewGroupButton buttonLabel="Create New Shared Group" handleCreate={handleCreate} />
        </Flex>

        {isEmpty(groups) ? (
          <NoGroups />
        ) : (
          <Grid columnGap="45px" gridTemplateColumns="repeat(3, 1fr)" rowGap="24px">
            {groups.map(group => (
              <GroupCard key={`my-EmailGroups-${group.id}`} group={group}>
                {() => (
                  <Box bottom="16px" height="24px" position="absolute" right="16px" width="24px">
                    <GroupControlDropdown
                      group={group}
                      refetchGroups={this.fetchGroups}
                      isListingTeamGroups
                    />
                  </Box>
                )}
              </GroupCard>
            ))}
          </Grid>
        )}
      </Box>
    );
  }
}

TeamGroups.propTypes = {
  handleCreate: PropTypes.func.isRequired,
  groups: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })).isRequired,
  loading: PropTypes.bool.isRequired,
  request: PropTypes.func.isRequired,
  router: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => ({
  groups: selectors.teamGroupsDataReducer(state),
  loading: selectors.isTeamGroupsLoadingReducer(state),
});

const mapDispatchToProps = dispatch => ({
  handleCreate: (...args) => dispatch(actions.createTeamGroupRequest(...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TeamGroups);
