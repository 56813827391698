import React from 'react';
import PropTypes from 'prop-types';
import { camelizeKeys } from 'humps';
import { connect } from 'react-redux';
import { dropLast, isEmpty } from 'ramda';

import api from '../../../../lib/api';

import { journeysSelectors as selectors } from '../../../../modules/journeys';

import { Box, Flex, Heading } from '../../../mc-ui';
import BrandLogo from '../../../ui/BrandLogo';

import SimpleArrowLeftIcon from '../../../mc-ui/icons/SimpleArrowLeftIcon';

import DataPoints from '../../DataPoints';
import TypeBadge from '../../JourneysList/JourneyLine/TypeBadge';
import RelatedJourneys from '../../JourneysList/JourneyLine/RelatedJourneys';
import JourneysModal from '../../JourneysList/JourneyLine/RelatedJourneys/JourneysModal';

import Link from './Link';
import ShareJourneyButton from '../../JourneysList/JourneyLine/ShareJourneyButton/indes';
import SaveJourneyDropdown from '../../JourneysList/JourneyLine/SaveJourneyDropdown';

class Header extends React.Component {
  state = {
    loadingRelatedJourneys: true,
    relatedJourneys: [],
    showRelatedModal: false,
  };

  UNSAFE_componentWillReceiveProps() {
    this.setState({ showRelatedModal: false });
  }

  componentDidUpdate() {
    const { journey } = this.props;
    const { relatedJourneys } = this.state;
    if (isEmpty(journey) || !isEmpty(relatedJourneys)) return;

    const { addressId } = journey.attributes;

    api
      .get(`/v3/journeys`, { params: { address_id: addressId } })
      .then(res => {
        const journeys = camelizeKeys(res.data.data);
        this.setState({ loadingRelatedJourneys: false, relatedJourneys: journeys });
      })
      .catch(error => {
        console.log('error', error);
        this.setState({ loadingRelatedJourneys: false });
      });
  }

  toggleRelatedModal = event => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.setState(currentState => ({ showRelatedModal: !currentState.showRelatedModal }));
  };

  render() {
    const { journey, loading, meta } = this.props;
    const { router } = this.context;
    const { loadingRelatedJourneys, relatedJourneys, showRelatedModal } = this.state;

    if (loading || loadingRelatedJourneys) return null;
    const [brand] = journey.relationships.companies.data;
    const pathname = dropLast(1, router.location.pathname.split('/')).join('/');

    return (
      <Box marginBottom="48px" marginTop="16px">
        <Flex alignItems="center" justifyContent="space-between">
          <Link to={{ pathname, query: router.location.state }}>
            <span>
              <SimpleArrowLeftIcon />
            </span>{' '}
            Back
          </Link>

          <Flex alignItems="center" width="unset">
            <RelatedJourneys
              currentJourney={journey}
              journeys={journey.relationships.journeys.data}
              toggleModal={this.toggleRelatedModal}
            />
            {showRelatedModal && (
              <JourneysModal
                closeModal={this.toggleRelatedModal}
                currentJourney={journey}
                journeys={relatedJourneys}
              />
            )}
            <ShareJourneyButton journey={journey} variant="secondary" />
            <SaveJourneyDropdown journey={journey} />
          </Flex>
        </Flex>

        <Flex justifyContent="space-between" marginTop="20px">
          <Flex width="unset">
            <Box marginRight="16px" width="unset">
              <BrandLogo brandId={brand.id} css={{ height: '48px', width: '48px' }} />
            </Box>

            <Box width="unset">
              <Heading
                as="h4"
                css={{ letterSpacing: '0.02em', lineHeight: '1.32em', marginBottom: '4px' }}
              >
                {brand.attributes.name}
              </Heading>

              <TypeBadge type={journey.attributes.type} />
            </Box>
          </Flex>

          <DataPoints
            durationDays={meta.durationDays.avg}
            endDate={journey.attributes.endDate}
            intervalDays={meta.intervalDays.avg}
            startDate={journey.attributes.startDate}
            triggeredEmails={meta.triggeredEmails.avg}
            totalEmails={meta.totalEmails.avg}
          />
        </Flex>
      </Box>
    );
  }
}

Header.contextTypes = {
  router: PropTypes.object,
};

Header.defaultProps = {
  journey: {},
  meta: {},
};

Header.propTypes = {
  journey: PropTypes.shape({
    attributes: PropTypes.shape({
      journeyType: PropTypes.string,
    }),
    relationships: PropTypes.shape({
      companies: PropTypes.shape({
        data: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            attributes: PropTypes.shape({
              name: PropTypes.string,
            }),
          }),
        ),
      }),
    }),
  }),
  loading: PropTypes.bool.isRequired,
  meta: PropTypes.shape({
    durationDays: PropTypes.shape({
      avg: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    intervalDays: PropTypes.shape({
      avg: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    totalEmails: PropTypes.shape({
      avg: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  }),
};

const mapStateToProps = (state, { location, model, params }) => ({
  meta: selectors.getJourneyMeta(model, params.journey_id, location.query)(state),
});

export default connect(mapStateToProps)(Header);
