import React from 'react';
import PropTypes from 'prop-types';

import { Box, Tab } from '../mc-ui';

import AllIndustries from './AllIndustries';
import Journeys from './Journeys';
import MyGroups from './MyGroups';

const BrowseByIndustries = ({ myGroups, title, type }) => (
  <Box padding="50px 0">
    <Tab defaultActive={type === 'journeys' ? 'journeyTypes' : 'allIndustries'}>
      {type === 'journeys' ? (
        <Tab.Header>
          <Tab.Item name="journeyTypes" big>
            Browse by type
          </Tab.Item>
          {myGroups && (
            <Tab.Item type={type} name="myGroups" big>
              My groups
            </Tab.Item>
          )}
        </Tab.Header>
      ) : (
        <Tab.Header>
          <Tab.Item name="allIndustries" big>
            {title}
          </Tab.Item>
          {myGroups && (
            <Tab.Item name="myGroups" big>
              My groups
            </Tab.Item>
          )}
        </Tab.Header>
      )}
      <Tab.Body>
        {({ active }) => {
          if (active === 'allIndustries') return <AllIndustries type={type} />;
          if (active === 'myGroups') return <MyGroups type={type} />;

          return <Journeys filter={active} tab={active} />;
        }}
      </Tab.Body>
    </Tab>
  </Box>
);

BrowseByIndustries.defaultProps = {
  myGroups: true,
  type: 'companies',
  title: (
    <span>
      Browse by <strong>Industries</strong>
    </span>
  ),
};

BrowseByIndustries.propTypes = {
  myGroups: PropTypes.bool,
  type: PropTypes.oneOf(['companies', 'emails', 'journeys']),
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default BrowseByIndustries;
