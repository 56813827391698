import * as types from './types';

export const fetchBrandOptionsRequest = brandId => ({
  type: types.FETCH_BRAND_OPTIONS_REQUEST,
  modelId: brandId,
});
export const fetchBrandOptionsSuccess = (brandId, payload) => ({
  type: types.FETCH_BRAND_OPTIONS_SUCCESS,
  modelId: brandId,
  payload,
});

export const fetchBrandDataRequest = brandId => ({
  type: types.FETCH_BRAND_DATA_REQUEST,
  modelId: brandId,
});
export const fetchBrandDataFailure = (brandId, error) => ({
  type: types.FETCH_BRAND_DATA_FAILURE,
  modelId: brandId,
  error,
});
export const fetchBrandDataSuccess = (brandId, payload) => ({
  type: types.FETCH_BRAND_DATA_SUCCESS,
  modelId: brandId,
  payload,
});

export const toggleBrandDataLoading = brandId => ({
  type: types.TOGGLE_BRAND_DATA_LOADING,
  modelId: brandId,
});

export const fetchGroupBrandsRequest = groupId => ({
  type: types.FETCH_GROUP_BRANDS_REQUEST,
  modelId: groupId,
});
export const fetchGroupBrandsFailure = (groupId, error) => ({
  type: types.FETCH_GROUP_BRANDS_FAILURE,
  modelId: groupId,
  error,
});
export const fetchGroupBrandsSuccess = (groupId, payload) => ({
  type: types.FETCH_GROUP_BRANDS_SUCCESS,
  modelId: groupId,
  payload,
});

export const toggleGroupBrandsLoading = groupId => ({
  type: types.TOGGLE_GROUP_BRANDS_LOADING,
  modelId: groupId,
});

export const fetchGroupDataRequest = (groupId, brandId) => ({
  type: types.FETCH_GROUP_DATA_REQUEST,
  modelId: groupId,
  brandId,
});
export const fetchGroupDataFailure = (groupId, brandId, error) => ({
  type: types.FETCH_GROUP_DATA_FAILURE,
  modelId: groupId,
  brandId,
  error,
});
export const fetchGroupDataSuccess = (groupId, brandId, payload) => ({
  type: types.FETCH_GROUP_DATA_SUCCESS,
  modelId: groupId,
  brandId,
  payload,
});

export const toggleGroupDataLoading = (groupId, brandId) => ({
  type: types.TOGGLE_GROUP_DATA_LOADING,
  modelId: groupId,
  brandId,
});
