import React, { Component } from 'react';
import ContactModalLink from '../../ContactModalLink'
import { QUESTION } from '../../ContactUs/topics'
import PropTypes from 'prop-types';
import qs from 'qs';
import { isEmpty, merge } from 'ramda';

import Container from '../../Container'
import { normalizeQuery } from '../../AdvancedOptions/utils';

import ComparisonHeader from './ComparisonHeader';
import SingleHeader from './SingleHeader';

export const COMPARE_QUERY_KEY = 'compare';

const INITIAL_STATE = {
  comparisonMode: false,
  query: {},
};

class Header extends Component {
  state = INITIAL_STATE;

  UNSAFE_componentWillMount() {
    const search = qs.parse((this.context.router.location.search || '').replace('?', ''));

    if (!isEmpty(search) && isEmpty(this.state.query)) {
      this.setState({ query: search });
    }
  }

  componentDidMount() {
    const { router } = this.context;
    const query = qs.parse((router.location.search || '').replace('?', ''));

    if (query[COMPARE_QUERY_KEY]) {
      this.toggleComparisonMode();
    }
  }

  UNSAFE_componentWillReceiveProps() {
    const search = qs.parse((this.context.router.location.search || '').replace('?', ''));
    if (isEmpty(search) && !isEmpty(this.state.query)) {
      this.setState(INITIAL_STATE);
    }
  }

  setQuery = (data, queryScope = null, updateRoute = false) => {
    const { query } = this.state;

    const newQuery = queryScope
      ? merge(query, { [queryScope]: merge(query[queryScope], data) })
      : merge(query, data);

    Object.keys(queryScope ? newQuery[queryScope] : newQuery).forEach((key) => {
      if (!data[key]) {
        if (queryScope) {
          delete newQuery[queryScope][key];
        } else {
          delete newQuery[key];
        }
      }
    });

    if (!queryScope && query[COMPARE_QUERY_KEY]) {
      newQuery[COMPARE_QUERY_KEY] = query[COMPARE_QUERY_KEY];
    }

    this.setState({ query: this.cleanupCompanyAndGroupFromQuery(data, newQuery) }, () => {
      if (updateRoute) {
        this.updateRouterQuery();
      }
    });
  };

  cleanupCompanyAndGroupFromQuery = (data, query) => {
    const newQuery = { ...query };
    // company_id and group_id can't be present at the same time, this filter is
    // here to guarantee that only one keeps in the final query.
    if (data.company_id || (data[COMPARE_QUERY_KEY] || {}).company_id) {
      delete newQuery[COMPARE_QUERY_KEY].group_id;
    } else if (data.group_id || (data[COMPARE_QUERY_KEY] || {}).group_id) {
      delete newQuery[COMPARE_QUERY_KEY].company_id;
    }

    return newQuery;
  };

  toggleComparisonMode = (event) => {
    if (event) {
      event.preventDefault();
    }

    this.setState(
      currentState => ({ comparisonMode: !currentState.comparisonMode }),
      () => {
        const { comparisonMode, query } = this.state;

        if (!comparisonMode) {
          delete query[COMPARE_QUERY_KEY];
          this.setQuery(query, null, true);
        }
      },
    );
  };

  updateRouterQuery = (event) => {
    const { comparisonMode, query } = this.state;
    const { model } = this.props;
    const { router } = this.context;

    if (event) {
      event.preventDefault();
    }

    const newQuery = normalizeQuery(query);
    if (comparisonMode && query[COMPARE_QUERY_KEY]) {
      newQuery[COMPARE_QUERY_KEY] = normalizeQuery(query[COMPARE_QUERY_KEY]);
    }

    const keysByModelType = {
      companies: 'company_id',
      groups: 'group_id',
      technologies: 'technology_id',
    };
    const modelKey = keysByModelType[model.type];

    const search = qs.parse((router.location.search || '').replace('?', ''));

    let { [COMPARE_QUERY_KEY]: compare } = newQuery
    if (compare && !search[COMPARE_QUERY_KEY]) {
      compare = { ...search, [modelKey]: model.id, ...compare, }
      delete compare[COMPARE_QUERY_KEY]
      newQuery[COMPARE_QUERY_KEY] = compare
    }

    if (
      search[COMPARE_QUERY_KEY] &&
      search[COMPARE_QUERY_KEY][modelKey] &&
      newQuery[COMPARE_QUERY_KEY] &&
      !newQuery[COMPARE_QUERY_KEY][modelKey]
    ) {
      newQuery[COMPARE_QUERY_KEY] = merge(newQuery[COMPARE_QUERY_KEY], {
        [modelKey]: search[COMPARE_QUERY_KEY][modelKey],
      });
    }

    router.push({
      pathname: router.location.pathname,
      search: `?${qs.stringify(this.cleanupCompanyAndGroupFromQuery(query, newQuery))}`,
    });
  };

  render() {
    const { model } = this.props;
    const { comparisonMode, query } = this.state;

    return (
      <div>
        {comparisonMode ?
          <ComparisonHeader
            currentQuery={query}
            model={model}
            queryScope={COMPARE_QUERY_KEY}
            setQuery={this.setQuery}
            toggleComparisonMode={this.toggleComparisonMode}
            updateRouterQuery={this.updateRouterQuery}
          />
          :
          <SingleHeader
            comparisonMode={comparisonMode}
            currentQuery={query}
            model={model}
            setQuery={this.setQuery}
            toggleComparisonMode={this.toggleComparisonMode}
            updateRouterQuery={this.updateRouterQuery}
            showExport
          />
        }
      </div>
    )

  }
}

Header.contextTypes = {
  router: PropTypes.object,
};

Header.propTypes = {
  model: PropTypes.shape({}).isRequired,
};

export default Header;
