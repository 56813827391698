import styled from "@emotion/styled";

const FormHeader = styled("div")({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  margin: '0 auto',
  maxWidth: '360px',
  padding: '0',
  width: '100%'
})

export default FormHeader
