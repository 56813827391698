import React from 'react';
import PropTypes from 'prop-types';

import BrandsChart from './BrandsChart';
import GroupsChart from './GroupsChart';

const Chart = ({ model, ...props }) =>
  model.type === 'groups' ? <GroupsChart {...props} /> : <BrandsChart {...props} />;

Chart.propTypes = {
  model: PropTypes.shape({ id: PropTypes.string, type: PropTypes.string }).isRequired,
};

export default Chart;
