import React from 'react';

import { Flex } from '../../../../../../../../mc-ui';
import PromoIcon from '../../../../../../../../mc-ui/icons/PromoIcon';

const PromotionTag = () => (
  <Flex
    alignItems="center"
    backgroundColor="white"
    borderRadius="50%"
    color="blue"
    height="16px"
    justifyContent="center"
    position="absolute"
    right="-8px"
    top="0"
    width="16px"
  >
    <PromoIcon fillOpacity={1} height="12px" width="12px" />
  </Flex>
);

export default PromotionTag;
