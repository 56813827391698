import React from 'react';
import PropTypes from 'prop-types';
import styled from "@emotion/styled";

import DropdownIndicatorIcon from '../../../../mc-ui/icons/DropdownIndicatorIcon';

const Wrapper = styled("button")(({ open, theme }) => ({
  border: `1px solid`,
  borderColor: theme.colors.dark5,
  borderRadius: '4px',
  boxSizing: 'border-box',
  color: open ? theme.colors.blue : theme.colors.dark06,
  outline: 'none',
  padding: '2px 7px',
}));

const Button = ({ open, ...props }) => (
  <Wrapper open={open} {...props}>
    <DropdownIndicatorIcon width="8px" height="6px" />
  </Wrapper>
);

Button.defaultProps = {
  open: false,
};

Button.propTypes = {
  open: PropTypes.bool,
};

export default Button;
