import * as React from 'react';
import PropTypes from 'prop-types';

function TimelineIcon({ fillOpacity, ...props }) {
  return (
    <svg width={24} height={24} fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 14.17V9.83a3.001 3.001 0 010-5.66V2h2v2.17a3.001 3.001 0 010 5.66v4.34a3.001 3.001 0 010 5.66V22h-2v-2.17a3.001 3.001 0 010-5.66zM22 8h-6V6h6v2zM2 18h6v-2H2v2z"
        fill="currentColor"
        fillOpacity={fillOpacity}
      />
    </svg>
  );
}

TimelineIcon.propTypes = {
  fillOpacity: PropTypes.number,
};

TimelineIcon.defaultProps = {
  fillOpacity: 1,
};

export default TimelineIcon;
