import styled from "@emotion/styled";

const DropdownItem = styled("div")(({ selected, theme }) => ({
  backgroundColor: selected ? theme.colors.blueLight : theme.colors.white,
  boxSizing: 'border-box',
  color: theme.colors.dark08,
  cursor: 'pointer',
  fontFamily: theme.font.family,
  fontSize: '14px',
  fontWeight: '500',
  padding: `${8.5 / 14}em ${12 / 14}em`,
  width: '100%',
  '&:hover': {
    backgroundColor: theme.colors.blueLight,
  },
}));

export default DropdownItem;
