import React from 'react';
import PropTypes from 'prop-types';
import styled from "@emotion/styled";

import StyledTooltip from './tooltip/StyledTooltip';

const Tooltip = ({
  children,
  css: cssProp,
  dark,
  effect,
  element,
  id,
  place,
  tooltipWidth,
  variant,
  wrapperWidth,
  tooltipSpanWidth,
}) => {

  const TooltipWrapper = styled("div")({
    display: 'inline-block',
    textAlign: 'left',
    width: wrapperWidth,
    '& > span': {
      display: 'inline-block',
    },
    '& [data-id="tooltip"]': {
      marginLeft: '7px !important',
    },
    ...cssProp,
  });

  const TooltipSpanWrapper = styled("span")({
    width: tooltipSpanWidth,
  });

  const offset = {};

  if (effect === 'solid') {
    if (place === 'left' || place === 'right') {
      offset.top = '4';
    } else if (place === 'bottom') {
      offset.left = '1';
      offset.top = '4';
    } else if (place === 'top') {
      offset.left = '2';
    }
  }

  return (
    <TooltipWrapper data-tooltip="wrapper">
      <TooltipSpanWrapper data-tip data-for={id}>
        {element}
      </TooltipSpanWrapper>
      <StyledTooltip
        border={!dark}
        dark={dark}
        delayHide={200}
        effect={effect}
        id={id}
        offset={offset}
        place={place}
        variant={variant}
        width={tooltipWidth}
      >
        {children}
      </StyledTooltip>
    </TooltipWrapper>
  );
};

Tooltip.defaultProps = {
  css: {},
  dark: false,
  effect: 'solid',
  place: 'top',
  tooltipWidth: null,
  variant: null,
  wrapperWidth: 'unset',
};

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  css: PropTypes.shape({}),
  dark: PropTypes.bool,
  effect: PropTypes.string,
  element: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  place: PropTypes.string,
  tooltipWidth: PropTypes.string,
  tooltipSpanWidth: PropTypes.string,
  variant: PropTypes.string,
  wrapperWidth: PropTypes.string,
};

export default Tooltip;
