/* global window */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Container from '../Container';

import Header from './Header';
import ContactUs from './ContactUs';
import Title from './ui/Title';
import JourneysContainer from './ui/JourneysContainer';
import JourneysExamples from './JourneysExamples';

const containerStyle = {
  display: 'flex',
  flexDirection: 'column',
  margin: '48px 0',
  padding: '40px',
};

class UpgradeJourneys extends Component {
  componentDidMount() {
    window.analytics.track('User restricted', { type: 'journeys' });

    const {
      demo: { journeys },
      requestDemoJourneys,
    } = this.props;

    if (journeys.loading) {
      requestDemoJourneys();
    }
  }

  upgradeHandler = (event) => {
    event.preventDefault();
    const type = 'journeys';

    window.analytics.track('Paywall clicked', { type });
  };

  render() {
    const {
      demo: { journeys },
      model,
    } = this.props;

    if (journeys.loading) {
      return null;
    }

    return (
      <Container style={containerStyle}>
        <Header
          journeys={journeys}
          model={model}
          upgradeHandler={this.upgradeHandler}
        />
        <JourneysContainer>
          <Title>Still not convinced? Check out some best-of examples</Title>
          <JourneysExamples journeys={journeys.data.map(j => j.data[0])} />
        </JourneysContainer>
        <ContactUs />
      </Container>
    );
  }
}

UpgradeJourneys.propTypes = {
  demo: PropTypes.shape({
    journeys: PropTypes.object,
  }).isRequired,
  model: PropTypes.shape({}).isRequired,
  requestDemoJourneys: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  demo: state.demo,
});

const mapDispatchToProps = dispatch => ({
  requestDemoJourneys: () => dispatch({ type: 'REQUEST_DEMO_JOURNEYS' }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UpgradeJourneys);
