import * as types from './types';

// **********  MODEL **********

export const setModel = model => ({
  type: types.SET_MODEL,
  model,
});

export const fetchDataFailure = error => ({
  type: types.FETCH_DATA_FAILURE,
  error,
});

export const fetchDataRequest = model => ({
  type: types.FETCH_DATA_REQUEST,
  model,
});

export const fetchDataSuccess = payload => ({
  type: types.FETCH_DATA_SUCCESS,
  payload,
});

export const toggleDataLoading = () => ({
  type: types.TOGGLE_DATA_LOADING,
});

// **********  JOURNEYS **********

export const getJourneysFailure = error => ({
  type: types.GET_JOURNEYS_FAILURE,
  error,
});

export const getJourneysRequest = model => ({
  type: types.GET_JOURNEYS_REQUEST,
  model,
});

export const getJourneysSuccess = payload => ({
  type: types.GET_JOURNEYS_SUCCESS,
  payload,
});

export const toggleJourneysLoading = () => ({
  type: types.TOGGLE_JOURNEYS_LOADING,
});

// **********  HIGHLIGHTED BRANDS **********

export const paginateHighlightedBrandsRequest = url => ({
  type: types.PAGINATE_HIGHLIGHTED_BRANDS_REQUEST,
  url,
});

export const paginateHighlightedBrandsSuccess = payload => ({
  type: types.PAGINATE_HIGHLIGHTED_BRANDS_SUCCESS,
  payload,
});
