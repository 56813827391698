import { assoc, assocPath, merge } from 'ramda';
import * as types from './types';

export const INITIAL_STATE = {};

const setLoadingReducer = (state, { pathname, query: q }) => {
  const query = JSON.stringify(q || '');
  const newState = {
    [query]: { loading: true },
  };

  return merge(state, { [pathname]: newState });
};

const setErrorReducer = (state, { pathname, query: q, error }) => {
  const query = JSON.stringify(q || '');
  const newState = {
    [query]: { error },
  };

  return merge(state, { [pathname]: newState });
};

const setDataReducer = (state, { pathname, query: q, data }) => {
  const query = JSON.stringify(q || '');
  const newState = {
    [query]: { data, error: null, loading: false },
  };

  return merge(state, { [pathname]: newState });
};

const setPaginatingReducer = (state, { pathname, query: q }) => {
  const query = JSON.stringify(q || '');

  if (state[pathname] && state[pathname][query]) {
    return assocPath([pathname, query, 'paginating'], true, state);
  } else if (state[pathname]) {
    const obj = { ...state[pathname], [query]: { paginating: true } };
    return assoc(pathname, obj, state);
  }

  const obj = { [pathname]: { [query]: { paginating: true } } };
  return assoc(pathname, obj, state);
};

const setPaginatedDataReducer = (state, { pathname, query: q, data }) => {
  const query = JSON.stringify(q || '');

  const newState = {
    ...state[pathname][query],
    error: null,
    loading: false,
    paginating: false,
    data: {
      ...state[pathname][query].data,
      data: [...state[pathname][query].data.data, ...data.data],
      included: {
        ...state[pathname][query].data.included,
        ...data.included,
      },
      links: data.links,
      meta: data.meta,
    },
  };

  return assocPath([pathname, query], newState, state);
};

const toggleMessageSelectedReducer = (state, { pathname, query: q, id }) => {
  const query = JSON.stringify(q || '');

  const newState = {
    ...state[pathname][query],
    data: {
      ...state[pathname][query].data,
      data: state[pathname][query].data.data.map(message => {
        if (message.id === id) {
          return { ...message, selected: !message.selected };
        }

        return message;
      }),
    },
  };

  return assocPath([pathname, query], newState, state);
};

const toggleAllMessagesSelectedReducer = selected => (state, { pathname, query: q }) => {
  const query = JSON.stringify(q || '');

  const newState = {
    ...state[pathname][query],
    data: {
      ...state[pathname][query].data,
      data: state[pathname][query].data.data.map(message => ({
        ...message,
        selected,
      })),
    },
  };

  return assocPath([pathname, query], newState, state);
};

const reducer = {
  [types.FETCH_DATA_REQUEST]: setLoadingReducer,
  [types.FETCH_DATA_SUCCESS]: setDataReducer,
  [types.FETCH_DATA_FAILURE]: setErrorReducer,
  [types.PAGINATE_DATA_REQUEST]: setPaginatingReducer,
  [types.PAGINATE_DATA_SUCCESS]: setPaginatedDataReducer,
  [types.PAGINATE_DATA_FAILURE]: setErrorReducer,
  [types.SELECT_MESSAGE]: toggleMessageSelectedReducer,
  [types.DESELECT_MESSAGE]: toggleMessageSelectedReducer,
  [types.SELECT_ALL_MESSAGES]: toggleAllMessagesSelectedReducer(true),
  [types.DESELECT_ALL_MESSAGES]: toggleAllMessagesSelectedReducer(false),
};

export default (state = INITIAL_STATE, action = {}) => {
  const reducerFunction = reducer[action.type];

  if (!reducerFunction) return state;
  return reducerFunction(state, action);
};
