import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { benchmarksSelectors as selectors } from '../../../modules/newReports/benchmarks';

import { Flex } from '../../mc-ui';
import ChartLineIcon from '../../mc-ui/icons/ChartLineIcon';

import ReportSection from '../ReportSection';
import Title from '../Title';

import BarsChart from './BarsChart';
import SectionInformation from './SectionInformation';

const EmailScores = ({ data, loading, ...props }) => (
  <Flex>
    <ReportSection css={{ minHeight: '285px' }}>
      <ReportSection.Header>
        <Title>MailCharts Email Score</Title>
      </ReportSection.Header>
      <ReportSection.Body>
        <Flex justifyContent="space-between">
          <SectionInformation
            content="MailCharts tracks key aspects of every email received for this group. Email Score follows email marketing best practices, allowing brands to ensure their email program is set up for success."
            icon={ChartLineIcon}
            type="ahref"
            linkTo="https://knowledge.validity.com/hc/en-us/articles/9512247991707-Email-Score"
          />

          <BarsChart
            adjustment={r => Math.round(r)}
            append=" %"
            data={data.mailcharts_score}
            loading={loading}
            {...props}
          />
        </Flex>
      </ReportSection.Body>
    </ReportSection>
  </Flex>
);

EmailScores.propTypes = {
  data: PropTypes.shape({
    mailcharts_score: PropTypes.shape({}),
  }).isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, { model, brandId }) => {
  if (brandId && model.id && model.type === 'groups') {
    return {
      data: selectors.getGroupComparisonData(model.id, brandId)(state),
      loading: selectors.getGroupComparisonLoading(model.id, brandId)(state),
    };
  }

  if (model.id && model.type === 'companies') {
    return {
      data: selectors.getBrandComparisonData(model.id)(state),
      loading: selectors.getBrandComparisonLoading(model.id)(state),
    };
  }

  return {
    data: { mailcharts_score: {} },
    loading: true,
  };
};

export default connect(mapStateToProps)(EmailScores);
