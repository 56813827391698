import React from 'react';
import PropTypes from 'prop-types';

import { Box, Flex } from '../../../mc-ui';
import OverviewTrendingLineChart from '../../../OverviewTrendingLineChart';

import NegativeIcon from '../../../newReports/stats/NegativeIcon';
import PositiveIcon from '../../../newReports/stats/PositiveIcon';

import Heading from '../Heading';
import Text from '../Text';

const fixedValue = value => {
  let number = null;
  if (!value || value === 'NaN' || Number.isNaN(value)) {
    number = 0;
  } else {
    number = Number(value);
  }

  return number.toFixed(2);
};

const calcDiff = (previousValue, currentValue) => {
  const diff = currentValue - previousValue;
  const result = (diff / previousValue) * 100;

  if (Number.isNaN(result)) {
    return 0;
  }
  return result;
};

const Highlights = ({ data, ...props }) => {
  const previousPeriodDiff = calcDiff(data.previous.frequency.value, data.current.frequency.value);

  let DiffIcon = null;
  if (previousPeriodDiff > 0) {
    DiffIcon = PositiveIcon;
  } else if (previousPeriodDiff < 0) {
    DiffIcon = NegativeIcon;
  }

  return (
    <Box>
      <Flex justifyContent="space-between">
        <Box>
          <Heading>Average emails / week</Heading>
          <Text css={{ lineHeight: '32px' }}>{fixedValue(data.current.frequency.value)}</Text>
        </Box>
        <Box>
          <Heading>vs Previous Period</Heading>
          {previousPeriodDiff === 0 ? (
            <Text color="dark4" css={{ fontSize: '18px', fontWeight: '400', lineHeight: '32px' }}>
              No Change
            </Text>
          ) : (
            <Text
              variant="bold"
              css={{
                alignItems: 'center',
                display: 'flex',
                lineHeight: '32px',
                '& > svg': { marginRight: '8px' },
              }}
            >
              <DiffIcon height="24px" width="24px" />{' '}
              {fixedValue(previousPeriodDiff < 0 ? previousPeriodDiff * -1 : previousPeriodDiff)}%
            </Text>
          )}
        </Box>
      </Flex>

      <OverviewTrendingLineChart chart="frequency" {...props} />
    </Box>
  );
};

Highlights.propTypes = {
  data: PropTypes.shape({
    brands: PropTypes.arrayOf(PropTypes.shape({})),
    frequency: PropTypes.shape({
      delta: PropTypes.number,
      previous: PropTypes.number,
      value: PropTypes.number,
    }),
    sentAtDaysOfWeek: PropTypes.arrayOf(
      PropTypes.shape({ avg: PropTypes.number, label: PropTypes.string }),
    ),
    sentAtHoursOfDay: PropTypes.arrayOf(
      PropTypes.shape({ avg: PropTypes.number, label: PropTypes.string }),
    ),
  }).isRequired,
};

export default Highlights;
