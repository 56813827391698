import { all, takeEvery, takeLatest } from 'redux-saga/effects';

import { couponSagas, couponTypes } from './modules/coupon';
import { reportsSagas, reportsTypes } from './modules/reports';
import { teamsSagas, teamsTypes } from './modules/teams';
import {
  newReportsSagas,
  newReportsTypes,
  benchmarks,
  calendars,
  sendingBehavior,
  subjectLines,
  timeSeries,
} from './modules/newReports';
import { groupOverviewSagas, groupOverviewTypes } from './modules/groupOverview';
import { brandOverviewSagas, brandOverviewTypes } from './modules/brandOverview';
import { journeysSagas, journeysTypes } from './modules/journeys';
import { brandsSagas, brandsTypes } from './modules/brands';
import { weeklyRollUpSagas, weeklyRollUpTypes } from './modules/weeklyRollUp';
import { savedJourneysSagas, savedJourneysTypes } from './modules/savedJourneys';
import { savedSearchesSagas, savedSearchesTypes } from './modules/savedSearches';
import { customGroupsSagas, customGroupsTypes } from './modules/customGroups';
import { customListsSagas, customListsTypes } from './modules/customLists';
import { textMessagesSagas, textMessagesTypes } from './modules/textMessages';

export default function* rootSaga() {
  yield all([
    takeLatest(couponTypes.FETCH_COUPON_REQUEST, couponSagas.fetchCoupon),
    takeLatest(reportsTypes.RECEIVE_EXPORT_TOTAL_AMOUNT, reportsSagas.receiveExportTotalAmount),

    takeLatest(groupOverviewTypes.GET_JOURNEYS_REQUEST, groupOverviewSagas.getJourneys),
    takeLatest(groupOverviewTypes.FETCH_DATA_REQUEST, groupOverviewSagas.fetchData),
    takeLatest(
      groupOverviewTypes.PAGINATE_HIGHLIGHTED_BRANDS_REQUEST,
      groupOverviewSagas.paginateHighlightedBrands,
    ),

    takeLatest(brandOverviewTypes.GET_JOURNEYS_REQUEST, brandOverviewSagas.getJourneys),
    takeLatest(brandOverviewTypes.FETCH_DATA_REQUEST, brandOverviewSagas.fetchData),

    // Reports
    takeLatest(newReportsTypes.CHANGE_DATES, newReportsSagas.changeDates),
    takeLatest(newReportsTypes.INITIALIZE_DATES, newReportsSagas.initializeDates),
    takeLatest(newReportsTypes.UPDATE_INTERVAL, newReportsSagas.updateInterval),
    takeLatest(
      newReportsTypes.GENERATE_COMPARISON_INTERVALS,
      newReportsSagas.generateComparisonIntervals,
    ),
    takeLatest(newReportsTypes.CHANGE_COMPARISON_DATES, newReportsSagas.changeComparisonDates),
    takeLatest(
      newReportsTypes.INITIALIZE_COMPARISON_DATES,
      newReportsSagas.initializeComparisonDates,
    ),
    takeLatest(
      newReportsTypes.UPDATE_COMPARISON_INTERVAL,
      newReportsSagas.updateComparisonInterval,
    ),
    takeLatest(
      calendars.calendarsTypes.FETCH_GROUP_DATA_REQUEST,
      calendars.calendarsSagas.fetchGroupData,
    ),
    takeLatest(
      calendars.calendarsTypes.FETCH_GROUP_MODAL_DATA_REQUEST,
      calendars.calendarsSagas.fetchGroupModalData,
    ),
    takeLatest(
      calendars.calendarsTypes.FETCH_BRAND_DATA_REQUEST,
      calendars.calendarsSagas.fetchBrandData,
    ),
    takeLatest(
      calendars.calendarsTypes.FETCH_BRAND_MODAL_DATA_REQUEST,
      calendars.calendarsSagas.fetchBrandModalData,
    ),
    takeLatest(
      benchmarks.benchmarksTypes.FETCH_GROUP_BRANDS_REQUEST,
      benchmarks.benchmarksSagas.fetchGroupBrands,
    ),
    takeLatest(
      benchmarks.benchmarksTypes.FETCH_GROUP_DATA_REQUEST,
      benchmarks.benchmarksSagas.fetchGroupData,
    ),
    takeLatest(
      benchmarks.benchmarksTypes.FETCH_BRAND_OPTIONS_REQUEST,
      benchmarks.benchmarksSagas.fetchBrandOptions,
    ),
    takeLatest(
      benchmarks.benchmarksTypes.FETCH_BRAND_DATA_REQUEST,
      benchmarks.benchmarksSagas.fetchBrandData,
    ),
    takeLatest(timeSeries.timeSeriesTypes.FETCH_DATA_REQUEST, timeSeries.timeSeriesSagas.fetchData),
    takeLatest(
      timeSeries.timeSeriesTypes.FETCH_HISTORICAL_TREND_REQUEST,
      timeSeries.timeSeriesSagas.fetchHistoricalTrendData,
    ),
    takeLatest(
      timeSeries.timeSeriesTypes.FETCH_COMPARISON_REQUEST,
      timeSeries.timeSeriesSagas.fetchComparisonData,
    ),
    takeLatest(
      timeSeries.timeSeriesTypes.FETCH_BRANDS_REQUEST,
      timeSeries.timeSeriesSagas.fetchBrandsData,
    ),
    takeLatest(
      subjectLines.subjectLinesTypes.FETCH_DATA_REQUEST,
      subjectLines.subjectLinesSagas.fetchData,
    ),
    takeLatest(
      sendingBehavior.sendingBehaviorTypes.FETCH_DATA_REQUEST,
      sendingBehavior.sendingBehaviorSagas.fetchData,
    ),
    takeLatest(
      sendingBehavior.sendingBehaviorTypes.FETCH_PAGINATION_DAYS_DATA_REQUEST,
      sendingBehavior.sendingBehaviorSagas.fetchPaginationData,
    ),
    takeLatest(
      sendingBehavior.sendingBehaviorTypes.FETCH_PAGINATION_HOURS_DATA_REQUEST,
      sendingBehavior.sendingBehaviorSagas.fetchPaginationData,
    ),
    takeLatest(
      sendingBehavior.sendingBehaviorTypes.SORT_DAYS_DATA_REQUEST,
      sendingBehavior.sendingBehaviorSagas.sortData,
    ),
    takeLatest(
      sendingBehavior.sendingBehaviorTypes.SORT_HOURS_DATA_REQUEST,
      sendingBehavior.sendingBehaviorSagas.sortData,
    ),

    takeLatest(journeysTypes.GET_JOURNEYS_REQUEST, journeysSagas.fetchJourneys),
    takeLatest(journeysTypes.GET_JOURNEY_REQUEST, journeysSagas.fetchJourney),

    takeLatest(teamsTypes.FETCH_TEAM_REQUEST, teamsSagas.fetchTeam),
    takeLatest(teamsTypes.UPDATE_TEAM_REQUEST, teamsSagas.updateTeam),
    takeLatest(teamsTypes.INVITE_MEMBER_REQUEST, teamsSagas.inviteMember),
    takeLatest(teamsTypes.ARCHIVE_MEMBER_REQUEST, teamsSagas.archiveMember),
    takeLatest(teamsTypes.ACTIVATE_MEMBER_REQUEST, teamsSagas.activateMember),
    takeLatest(teamsTypes.REMOVE_MEMBER_REQUEST, teamsSagas.removeMember),
    takeLatest(teamsTypes.UPDATE_MEMBER_REQUEST, teamsSagas.updateMember),
    takeLatest(teamsTypes.CANCEL_INVITATION_REQUEST, teamsSagas.cancelInvitation),
    takeLatest(teamsTypes.RESEND_INVITATION_REQUEST, teamsSagas.resendInvitation),
    takeLatest(teamsTypes.SUBSCRIPTION_REQUEST_SETUP_PAYMENT_INTENT, teamsSagas.requestSetupIntent),
    takeLatest(teamsTypes.SUBSCRIPTION_GET_PAYMENT_INFO, teamsSagas.getSubscriptionPaymentInfo),
    takeLatest(teamsTypes.CREATE_SUBSCRIPTION_REQUEST, teamsSagas.createSubscription),
    takeLatest(
      teamsTypes.SUBSCRIPTION_UPDATE_CREDIT_CARD_REQUEST,
      teamsSagas.subscriptionUpdateCreditCard,
    ),
    takeLatest(teamsTypes.SUBSCRIPTION_CALCULATE_REQUEST, teamsSagas.subscriptionCalculate),
    takeLatest(teamsTypes.SUBSCRIPTION_UPDATE_REQUEST, teamsSagas.subscriptionUpdate),
    takeLatest(teamsTypes.SUBSCRIPTION_ADDONS_REQUEST, teamsSagas.subscriptionAddons),
    takeLatest(teamsTypes.SUBSCRIPTION_MIGRATE_REQUEST, teamsSagas.subscriptionMigrate),
    takeLatest(teamsTypes.SUBSCRIPTION_CANCEL_REQUEST, teamsSagas.subscriptionCancel),
    takeLatest(teamsTypes.SUBSCRIPTION_REACTIVATE_REQUEST, teamsSagas.subscriptionReactivate),
    takeLatest(
      teamsTypes.SUBSCRIPTION_UPDATE_BILLING_INFO_REQUEST,
      teamsSagas.subscriptionUpdateBillingInfo,
    ),
    takeLatest(teamsTypes.INVITATION_ACCEPT_REQUEST, teamsSagas.invitationAccept),
    takeLatest(teamsTypes.INVITATION_DECLINE_REQUEST, teamsSagas.invitationDecline),

    takeLatest(brandsTypes.REQUEST, brandsSagas.fetchBrands),
    takeLatest(brandsTypes.REQUEST_PAGINATION, brandsSagas.paginateBrands),

    takeLatest(weeklyRollUpTypes.DISABLE_REQUEST, weeklyRollUpSagas.disable),
    takeLatest(weeklyRollUpTypes.ENABLE_REQUEST, weeklyRollUpSagas.enable),
    takeLatest(weeklyRollUpTypes.FETCH_REQUEST, weeklyRollUpSagas.fetch),
    takeLatest(weeklyRollUpTypes.ADD_GROUP_REQUEST, weeklyRollUpSagas.addGroup),
    takeLatest(weeklyRollUpTypes.REMOVE_GROUP_REQUEST, weeklyRollUpSagas.removeGroup),

    takeLatest(
      savedJourneysTypes.FETCH_MY_SAVED_JOURNEYS_REQUEST,
      savedJourneysSagas.fetchMySavedJourneys,
    ),
    takeLatest(
      savedJourneysTypes.SAVE_JOURNEY_TO_MY_LIST_REQUEST,
      savedJourneysSagas.saveJourneyToMyList,
    ),
    takeLatest(
      savedJourneysTypes.REMOVE_JOURNEY_FROM_MY_LIST_REQUEST,
      savedJourneysSagas.removeJourneyFromMyList,
    ),
    takeLatest(
      savedJourneysTypes.FETCH_TEAM_SAVED_JOURNEYS_REQUEST,
      savedJourneysSagas.fetchTeamSavedJourneys,
    ),
    takeLatest(
      savedJourneysTypes.SAVE_JOURNEY_TO_TEAM_LIST_REQUEST,
      savedJourneysSagas.saveJourneyToTeamList,
    ),
    takeLatest(
      savedJourneysTypes.REMOVE_JOURNEY_FROM_TEAM_LIST_REQUEST,
      savedJourneysSagas.removeJourneyFromTeamList,
    ),

    takeLatest(savedSearchesTypes.FETCH_TEAM_FILTERS_REQUEST, savedSearchesSagas.fetchTeamFilters),
    takeLatest(savedSearchesTypes.FETCH_USER_FILTERS_REQUEST, savedSearchesSagas.fetchUserFilters),
    takeLatest(savedSearchesTypes.CREATE_TEAM_FILTER_REQUEST, savedSearchesSagas.createTeamFilter),
    takeLatest(savedSearchesTypes.CREATE_USER_FILTER_REQUEST, savedSearchesSagas.createUserFilter),
    takeLatest(savedSearchesTypes.DELETE_TEAM_FILTER_REQUEST, savedSearchesSagas.deleteTeamFilter),
    takeLatest(savedSearchesTypes.DELETE_USER_FILTER_REQUEST, savedSearchesSagas.deleteUserFilter),
    takeLatest(savedSearchesTypes.UPDATE_TEAM_FILTER_REQUEST, savedSearchesSagas.updateTeamFilter),
    takeLatest(savedSearchesTypes.UPDATE_USER_FILTER_REQUEST, savedSearchesSagas.updateUserFilter),

    takeLatest(customListsTypes.CREATE_REQUEST, customListsSagas.createList),
    takeLatest(customListsTypes.DELETE_REQUEST, customListsSagas.deleteList),
    takeLatest(customListsTypes.UPDATE_REQUEST, customListsSagas.updateList),
    takeLatest(customListsTypes.FETCH_USER_LISTS_REQUEST, customListsSagas.fetchUserLists),
    takeLatest(customListsTypes.FETCH_TEAM_LISTS_REQUEST, customListsSagas.fetchTeamLists),
    takeLatest(customListsTypes.SHARE_WITH_TEAM_REQUEST, customListsSagas.shareWithTeam),
    takeLatest(customListsTypes.CREATE_TEAM_LIST_REQUEST, customListsSagas.createTeamList),
    takeLatest(customListsTypes.DELETE_TEAM_LIST_REQUEST, customListsSagas.deleteTeamList),
    takeLatest(customListsTypes.UPDATE_TEAM_LIST_REQUEST, customListsSagas.updateTeamList),
    takeLatest(customListsTypes.ADD_ITEMS_TO_LIST_REQUEST, customListsSagas.addItemsToList),
    takeLatest(
      customListsTypes.REMOVE_ITEMS_FROM_LIST_REQUEST,
      customListsSagas.removeItemsFromList,
    ),
    takeLatest(
      customListsTypes.CREATE_LIST_AND_ADD_ITEMS_REQUEST,
      customListsSagas.createListAndAddItems,
    ),

    takeLatest(customGroupsTypes.CREATE_REQUEST, customGroupsSagas.createGroup),
    takeLatest(customGroupsTypes.DELETE_REQUEST, customGroupsSagas.deleteGroup),
    takeLatest(customGroupsTypes.UPDATE_REQUEST, customGroupsSagas.updateGroup),
    takeLatest(customGroupsTypes.FETCH_USER_GROUPS_REQUEST, customGroupsSagas.fetchUserGroups),
    takeLatest(customGroupsTypes.FETCH_TEAM_GROUPS_REQUEST, customGroupsSagas.fetchTeamGroups),
    takeLatest(customGroupsTypes.SHARE_WITH_TEAM_REQUEST, customGroupsSagas.shareWithTeam),
    takeLatest(customGroupsTypes.CREATE_TEAM_GROUP_REQUEST, customGroupsSagas.createTeamGroup),
    takeLatest(customGroupsTypes.DELETE_TEAM_GROUP_REQUEST, customGroupsSagas.deleteTeamGroup),
    takeLatest(customGroupsTypes.UPDATE_TEAM_GROUP_REQUEST, customGroupsSagas.updateTeamGroup),
    takeLatest(customGroupsTypes.ADD_BRANDS_TO_GROUP_REQUEST, customGroupsSagas.addBrandsToGroup),
    takeLatest(
      customGroupsTypes.REMOVE_BRANDS_TO_GROUP_REQUEST,
      customGroupsSagas.removeBrandsToGroup,
    ),
    takeLatest(
      customGroupsTypes.CREATE_GROUP_AND_ADD_BRANDS_REQUEST,
      customGroupsSagas.createGroupAndAddBrands,
    ),

    takeLatest(textMessagesTypes.FETCH_DATA_REQUEST, textMessagesSagas.fetchData),
    takeLatest(textMessagesTypes.PAGINATE_DATA_REQUEST, textMessagesSagas.paginateData),

    takeEvery('USER_LOADED_SUCCESS', teamsSagas.fetchTeam),
    takeEvery('USER_LOADED_SUCCESS', savedJourneysSagas.fetchMySavedJourneys),
    takeEvery('USER_LOADED_SUCCESS', savedJourneysSagas.fetchTeamSavedJourneys),
    takeEvery('USER_LOADED_SUCCESS', savedSearchesSagas.fetchUserFilters),
    // takeEvery('USER_LOADED_SUCCESS', customListsSagas.fetchUserLists),
    takeEvery('USER_LOADED_SUCCESS', customGroupsSagas.fetchUserGroups),
  ]);
}
