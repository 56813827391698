import React from 'react';
import PropTypes from 'prop-types';

import { Flex } from '../mc-ui';

import ProgressBar from './brandsTable/ProgressBar';
import Logo from './brandsTable/Logo';
import Row from './brandsTable/Row';

const BrandsTable = ({ children }) => <Flex wrap="wrap">{children}</Flex>;

BrandsTable.Bar = ProgressBar;
BrandsTable.Row = Row;
BrandsTable.Logo = Logo;

BrandsTable.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BrandsTable;
