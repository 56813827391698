import React from 'react';

import { Tooltip } from '../../../mc-ui';
import QuestionMarkIcon from '../../../mc-ui/icons/QuestionMarkIcon';

const PromotionalTooltip = () => (
  <Tooltip id="promotionalTooltip" element={<QuestionMarkIcon height="16" width="16" />} dark>
    Our promotional rate is determined by the number of emails containing a promotion, like `20% off` or `free shipping`.{' '}
    Uncover how brands merchandise, launch and discount products to prepare upcoming reports and campaign strategies.
  </Tooltip>
);

export default PromotionalTooltip;
