import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@emotion/react';

import { Flex, Text, Tooltip } from '../../../mc-ui';
import QuestionMarkIcon from '../../../mc-ui/icons/QuestionMarkIcon';

const Header = ({ theme }) => {
  const thStyles = {
    backgroundColor: theme.colors.dark004,
    borderRight: `1px solid ${theme.colors.dark5}`,
    height: '40px',
    width: '84px',
  };

  const textStyles = {
    color: theme.colors.dark08,
    fontFamily: theme.font.family,
    fontSize: '10px',
    fontWeight: 'bold',
    letterSpacing: '0.12em',
    lineHeight: '1.56em',
    textAlign: 'center',
    textTransform: 'uppercase',
  };

  return (
    <Flex>
      <Flex alignItems="center" justifyContent="center" css={thStyles}>
        <Text css={textStyles}>Sun</Text>
      </Flex>
      <Flex alignItems="center" justifyContent="center" css={thStyles}>
        <Text css={textStyles}>Mon</Text>
      </Flex>
      <Flex alignItems="center" justifyContent="center" css={thStyles}>
        <Text css={textStyles}>Tue</Text>
      </Flex>
      <Flex alignItems="center" justifyContent="center" css={thStyles}>
        <Text css={textStyles}>Wed</Text>
      </Flex>
      <Flex alignItems="center" justifyContent="center" css={thStyles}>
        <Text css={textStyles}>Thu</Text>
      </Flex>
      <Flex alignItems="center" justifyContent="center" css={thStyles}>
        <Text css={textStyles}>Fri</Text>
      </Flex>
      <Flex alignItems="center" justifyContent="center" css={thStyles}>
        <Text css={textStyles}>Sat</Text>
      </Flex>
      <Flex
        alignItems="center"
        justifyContent="center"
        css={{ ...thStyles, borderRight: null, width: '180px' }}
      >
        <Tooltip
          id="weekly-focus-totals-tooltip"
          dark
          element={
            <Text css={textStyles}>
              Weekly Totals <QuestionMarkIcon fillOpacity={1} />
            </Text>
          }
        >
          <Text>
            Comparing weekly data to the previous year provides insight into how brands change send{' '}
            frequency and promotional activity.
          </Text>
        </Tooltip>
      </Flex>
    </Flex>
  );
};

Header.propTypes = {
  theme: PropTypes.shape({}).isRequired,
};

export default withTheme(Header);
