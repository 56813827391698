import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@emotion/react';

import { Heading } from '../../mc-ui';

const MenuHeader = ({ children, theme }) => (
  <Heading
    as="h5"
    css={{
      color: theme.colors.dark08,
      fontWeight: 'bold',
      lineHeight: '1.56em',
      letterSpacing: '0.08em',
      margin: '36px 0 16px 0',
      padding: '0',
    }}
  >
    {children}
  </Heading>
);

MenuHeader.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.shape({}).isRequired,
};

export default withTheme(MenuHeader);
