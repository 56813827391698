import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { Link as RRLink } from 'react-router';
import styled from "@emotion/styled";

import { Box, Flex, Text } from '../../../../../../../../mc-ui';
import BrandLogo from '../../../../../../../../ui/BrandLogo';

import Thumbnail from './Thumbnail';

const Row = styled(Flex)(({ theme }) => ({
  alignItems: 'center',
  borderBottom: `1px solid ${theme.colors.dark01}`,
  padding: '16px 0',
}));

const Link = styled(RRLink)(({ theme }) => ({
  border: 'none',
  color: theme.colors.blue,
  fontFamily: theme.font.family,
  fontSize: '14px',
  fontWeight: '500',
  letterSpacing: '0.02em',
  lineHeight: '1.25em',
  textDecoration: 'none',
}));

const BrandEmails = ({ brand, date }) => {
  const { total_emails: totalEmails } = brand.relationships.emails.meta;
  const emails = brand.relationships.emails.data;

  const pathname = `/companies/${brand.id}/emails`;
  const query = {
    end_date: date
      .clone()
      .add(1, 'day')
      .format('YYYY-MM-DD'),
    start_date: date.format('YYYY-MM-DD'),
  };

  return (
    <Row>
      <Box marginRight="20px" width="40px">
        <RRLink to={`/companies/${brand.id}`}>
          <BrandLogo brand={brand} />
        </RRLink>
      </Box>

      <Flex alignItems="center" marginRight="20px" width="unset">
        <Text
          color="dark08"
          fontSize="14px"
          fontWeight="500"
          lineHeight="1.25em"
          letterSpacing="0.02em"
        >
          {brand.attributes.name}
        </Text>
      </Flex>

      <Flex alignItems="center" flex="1" justifyContent="flex-end" marginRight="12px" width="unset">
        {emails.map(email => (
          <Thumbnail key={email.id} email={email} />
        ))}
      </Flex>

      <Flex alignItems="center" justifyContent="flex-end" width="80px">
        {totalEmails === 0 ? (
          <Text
            color="dark04"
            fontSize="14px"
            fontWeight="500"
            lineHeight="1.25em"
            letterSpacing="0.02em"
          >
            No emails
          </Text>
        ) : (
          <Link to={{ pathname, query }}>
            {totalEmails} {pluralize('email', totalEmails)}
          </Link>
        )}
      </Flex>
    </Row>
  );
};

BrandEmails.propTypes = {
  brand: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.shape({
      name: PropTypes.string,
    }),
    relationships: PropTypes.shape({
      emails: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape({})),
        meta: PropTypes.shape({
          total_emails: PropTypes.number,
        }),
      }),
    }),
  }).isRequired,
  date: PropTypes.shape({}).isRequired,
};

export default BrandEmails;
