import moment from 'moment';
import { all, call, put, select } from 'redux-saga/effects';
import { isEmpty } from 'ramda';

import * as actions from './actions';
import * as api from './api';
import * as selectors from './selectors';

export function* fetchData({ modelId, modelType, chart, interval, startDate, endDate }) {
  try {
    if (!modelId || !modelType || !chart || !interval || !startDate || !endDate) {
      return;
    }

    const data = yield select(
      selectors.getTimeSeriesData(modelId, modelType, chart, interval, startDate, endDate),
    );

    if (isEmpty(data)) {
      const response = yield call(
        api.fetchData,
        modelId,
        modelType,
        chart,
        interval,
        startDate,
        endDate,
      );

      const payload = response.meta.stats;

      yield put(
        actions.fetchDataSuccess(modelId, modelType, chart, interval, startDate, endDate, payload),
      );
    } else {
      yield put(actions.toggleDataLoading(modelId, modelType, chart, interval, startDate, endDate));
    }
  } catch (error) {
    console.log('error', error);
    yield put(
      actions.fetchDataFailure(modelId, modelType, chart, interval, startDate, endDate, error),
    );
  }
}

export function* fetchHistoricalTrendData({
  modelId,
  modelType,
  chart,
  interval,
  startDate,
  endDate,
}) {
  try {
    if (!modelId || !modelType || !chart || !interval || !startDate || !endDate) {
      return;
    }

    const data = yield select(
      selectors.getTimeSeriesHistoricalTrendData(
        modelId,
        modelType,
        chart,
        interval,
        startDate,
        endDate,
      ),
    );

    if (isEmpty(data)) {
      const historicalTrendStartDate = moment(startDate).subtract(1, 'y');
      const historicalTrendEndDate = moment(endDate).subtract(1, 'y');

      const response = yield call(
        api.fetchData,
        modelId,
        modelType,
        chart,
        interval,
        historicalTrendStartDate.format('YYYY-MM-DD'),
        historicalTrendEndDate.format('YYYY-MM-DD'),
      );

      const payload = response.meta.stats;

      yield put(
        actions.fetchHistoricalTrendSuccess(
          modelId,
          modelType,
          chart,
          interval,
          startDate,
          endDate,
          payload,
        ),
      );
    } else {
      yield put(
        actions.toggleHistoricalTrendLoading(
          modelId,
          modelType,
          chart,
          interval,
          startDate,
          endDate,
        ),
      );
    }
  } catch (error) {
    console.log('error', error);
    yield put(
      actions.fetchHistoricalTrendFailure(
        modelId,
        modelType,
        chart,
        interval,
        startDate,
        endDate,
        error,
      ),
    );
  }
}

export function* fetchComparisonData({
  modelId,
  modelType,
  chart,
  interval,
  startDate,
  endDate,
  comparisonStartDate,
  comparisonEndDate,
}) {
  try {
    if (
      !modelId ||
      !modelType ||
      !chart ||
      !interval ||
      !startDate ||
      !endDate ||
      !comparisonStartDate ||
      !comparisonEndDate
    ) {
      return;
    }

    const data = yield select(
      selectors.getTimeSeriesComparisonData(
        modelId,
        modelType,
        chart,
        interval,
        startDate,
        endDate,
        comparisonStartDate,
        comparisonEndDate,
      ),
    );

    if (isEmpty(data)) {
      const response = yield call(
        api.fetchData,
        modelId,
        modelType,
        chart,
        interval,
        comparisonStartDate,
        comparisonEndDate,
      );

      const payload = response.meta.stats;

      yield put(
        actions.fetchComparisonDataSuccess(
          modelId,
          modelType,
          chart,
          interval,
          startDate,
          endDate,
          comparisonStartDate,
          comparisonEndDate,
          payload,
        ),
      );
    } else {
      yield put(
        actions.toggleComparisonDataLoading(
          modelId,
          modelType,
          chart,
          interval,
          startDate,
          endDate,
          comparisonStartDate,
          comparisonEndDate,
        ),
      );
    }
  } catch (error) {
    console.log('error', error);
    yield put(
      actions.fetchComparisonDataFailure(
        modelId,
        modelType,
        chart,
        interval,
        startDate,
        endDate,
        comparisonStartDate,
        comparisonEndDate,
        error,
      ),
    );
  }
}

export function* fetchBrandsData({
  modelId,
  modelType,
  chart,
  interval,
  startDate,
  endDate,
  brandIds,
}) {
  try {
    if (!modelId || !modelType || !chart || !interval || !startDate || !endDate || !brandIds) {
      return;
    }

    const brandsData = yield select(
      selectors.getTimeSeriesBrandsData(modelId, modelType, chart, interval, startDate, endDate),
    );

    const responses = yield all(
      brandIds.map(brandId =>
        call(api.fetchData, brandId, 'companies', chart, interval, startDate, endDate),
      ),
    );

    const payload = brandIds.map((brandId, index) => {
      const brand = brandsData.find(d => Number(d.brand.id) === Number(brandId));

      if (brand) return brand;

      const response = responses[index];

      return {
        brand: {
          id: brandId,
          name: response.data[0].attributes.name,
        },
        ...response.meta.stats,
      };
    });

    yield put(
      actions.fetchBrandsSuccess(modelId, modelType, chart, interval, startDate, endDate, payload),
    );
  } catch (error) {
    console.log('error', error);
    yield put(
      actions.fetchBrandsFailure(modelId, modelType, chart, interval, startDate, endDate, error),
    );
  }
}
