import React from 'react';
import { withTheme } from '@emotion/react';

import { Box, Text, Tooltip } from '../../../mc-ui';
import QuestionMarkIcon from '../../../mc-ui/icons/QuestionMarkIcon';

// eslint-disable-next-line react/prop-types
const SignificantTermsTooltip = ({ theme }) => (
  <Box
    css={{
      color: theme.colors.dark06,
      display: 'inline',
      fontSize: '12px',
      lineHeight: '1em',
      marginLeft: '6px',
      width: 'auto',
    }}
  >
    <Tooltip id="significantTermsTooltip" element={<QuestionMarkIcon fillOpacity={1} />} dark>
      <Text>
        Significant terms include interesting or unusual occurrences of words and phrases in the
        subject lines for the selected date range.{' '}
        <a
          target="_blank"
          href="https://knowledge.validity.com/hc/en-us/articles/9513216584731-Email-Metrics"
          style={{ textDecoration: 'underline' }}
        >
          Learn more.
        </a>
      </Text>
    </Tooltip>
  </Box>
);

export default withTheme(SignificantTermsTooltip);
