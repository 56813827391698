import React from 'react';

import { Flex } from '../../mc-ui';

import Body from '../Body';

import PopularSendDayByDays from './PopularSendDayByDays';
import HoursInWeek from './HoursInWeek';
import NewsletterCampaignsSent from './NewsletterCampaignsSent';

const GroupContainer = props => (
  <Body>
    <NewsletterCampaignsSent {...props} />

    <Flex justifyContent="space-between">
      <PopularSendDayByDays {...props} />
      <HoursInWeek {...props} />
    </Flex>
  </Body>
);

export default GroupContainer;
