import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

import { Box, Button, Flex, Heading, Text } from '../mc-ui';

const RestrictedHeader = ({ description, link, reportName, ...props }) => {
  const title = props.title || `Upgrade to unlock ${reportName} reports`;

  return (
    <Flex
      backgroundColor="purpleLight"
      borderRadius="4px"
      justifyContent="space-between"
      marginBottom="40px"
      padding="24px"
    >
      <Box width="unset">
        <Heading as="h4" color="dark08" fontSize="18px" lineHeight="24px">
          {title}
        </Heading>
        <Text css={{ marginTop: '2px' }}>{description}</Text>
      </Box>

      <Button
        as={Link}
        to={link.to}
        variant="purple"
        css={{
          alignItems: 'center',
          display: 'inline-flex',
          height: '40px',
          justifyContent: 'center',
          padding: '0',
          width: '147px',
        }}
      >
        {link.text}
      </Button>
    </Flex>
  );
};

RestrictedHeader.defaultProps = {
  link: {
    text: 'Upgrade Now',
    to: '/manage/plan',
  },
  title: null,
};

RestrictedHeader.propTypes = {
  description: PropTypes.string.isRequired,
  link: PropTypes.shape({
    text: PropTypes.string,
    to: PropTypes.string,
  }),
  reportName: PropTypes.string.isRequired,
  title: PropTypes.string,
};

export default RestrictedHeader;
