import humanize from 'humanize-number';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Badge, SubMenu } from '../mc-ui';
import EmailIcon from '../mc-ui/icons/EmailIcon';
import MobileIcon from '../mc-ui/icons/MobileIcon';
import OverviewIcon from '../mc-ui/icons/OverviewIcon';
import TimelineIcon from '../mc-ui/icons/TimelineIcon';
import ReportsMenu from './ReportsMenu';

let forceReportsMenuRerender = 0;

function CompaniesMenu({ company, router }) {
  if (!company) return null;
  const rootPath = `/companies/${company.id}`;

  forceReportsMenuRerender += 1;

  return (
    <div key={company.id}>
      <SubMenu>
        <SubMenu.Link
          to={{
            pathname: `${rootPath}/overview`,
            query: router.location.query,
          }}
          activePathnames={['overview', company.id.toString()]}
        >
          <OverviewIcon height="20" width="20" fillOpacity={1} />
          Overview
        </SubMenu.Link>

        <SubMenu.Link to={`${rootPath}/emails`} activePathnames={['emails', 'inbox', 'calendar']}>
          <EmailIcon height="20" width="20" fillOpacity={1} />
          Emails
          <Badge
            backgroundColor="white"
            border="1px solid"
            borderColor="dark5"
            lineHeight="1.56em"
            margin="0 0 0 4px"
          >
            {humanize(company.attributes.approximate_emails || 0)}
          </Badge>
        </SubMenu.Link>

        <SubMenu.Link to={`${rootPath}/journeys`}>
          <TimelineIcon height="20" width="20" fillOpacity={1} />
          Journeys
          <Badge
            backgroundColor="white"
            border="1px solid"
            borderColor="dark5"
            lineHeight="1.56em"
            margin="0 0 0 4px"
          >
            {humanize(company.attributes.approximate_journeys || 0)}
          </Badge>
        </SubMenu.Link>

        <SubMenu.Link to={`${rootPath}/text-messages?sort=sent_at:desc`}>
          <MobileIcon height="20" width="20" fillOpacity={1} />
          SMS
        </SubMenu.Link>
      </SubMenu>
      <ReportsMenu rootPath={rootPath} rerender={forceReportsMenuRerender} />
    </div>
  );
}

CompaniesMenu.propTypes = {
  company: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.shape({
      approximate_emails: PropTypes.number,
      approximate_journeys: PropTypes.number,
    }),
  }).isRequired,
  router: PropTypes.shape({
    location: PropTypes.shape({ query: PropTypes.object }),
    params: PropTypes.object,
  }).isRequired,
};

const mapStateToProps = state => ({ companies: state.companies });
export default connect(mapStateToProps)(CompaniesMenu);
