import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CollectionsMenu from '../menus/CollectionsMenu';
import Header from '../Header';
import Section from '../Section';
import { Flex, Heading } from '../mc-ui';
import { teamsSelectors } from '../../modules/teams';

class Collections extends React.Component {
  constructor(props) {
    super(props);

    const { isMembershipActive, router } = props;
    const {
      params: { collection_type: collectionType },
    } = router;

    let renderComponent = true;
    if (collectionType === 'team' && !isMembershipActive) {
      router.replace('/not-found');
      renderComponent = false;
    }

    this.state = { renderComponent };
  }

  render() {
    if (!this.state.renderComponent) return null;

    const { children, router } = this.props;
    const {
      params: { collection_type: collectionType },
    } = router;

    return (
      <div className="pure-u-1">
        <Header>
          <Flex alignItems="center" height="144px">
            <Heading as="h2" color="dark1" fontSize="32px" fontWeight="300" lineHeight="1.32em">
              {collectionType === 'my' ? 'My' : 'Team'} collections
            </Heading>
          </Flex>
        </Header>

        <Section>
          <Flex justifyContent="space-between" marginTop="1em">
            <Flex width="200px">
              <CollectionsMenu collectionType={collectionType} />
            </Flex>

            <Flex paddingBottom="32px" width="834px">
              {React.Children.map(children, child => React.cloneElement(child, { collectionType }))}
            </Flex>
          </Flex>
        </Section>
      </div>
    );
  }
}

Collections.propTypes = {
  children: PropTypes.node.isRequired,
  isMembershipActive: PropTypes.bool.isRequired,
  router: PropTypes.shape({
    params: PropTypes.shape({
      collection_type: PropTypes.oneOf(['my', 'team']),
    }),
  }).isRequired,
};

const mapStateToProps = state => ({
  isMembershipActive: teamsSelectors.isMembershipActiveSelector(state),
});

export default connect(mapStateToProps)(Collections);
