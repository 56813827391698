import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';

import { subjectLinesApi as api } from '../../../../modules/newReports/subjectLines';

import { shallowEqual } from '../../../../lib/utils';
import { Flex, Heading, Loader, Text } from '../../../mc-ui';

import ReportSection from '../../ReportSection';
import Title from '../../Title';

import TermsTab from '../TermsTab';

import TermsTable from './TermsTable';

class HistoricalComparison extends React.Component {
  state = {
    loading: true,
    data: {
      previousPeriod: {},
      lastYear: {},
    },
  };

  componentDidMount() {
    if (this.props.model.id) {
      this.request(this.props);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.model.id) return;
    const nextModel = { id: nextProps.model.id, type: nextProps.model.type };
    const model = { id: this.props.model.id, type: this.props.model.type };
    const nextDates = { endDate: nextProps.endDate, startDate: nextProps.startDate };
    const dates = { endDate: this.props.endDate, startDate: this.props.startDate };

    if (!shallowEqual(nextModel, model) || !shallowEqual(nextDates, dates)) {
      this.setState(
        () => ({ loading: true }),
        () => {
          this.request(nextProps);
        },
      );
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const nextModel = { id: nextProps.model.id, type: nextProps.model.type };
    const model = { id: this.props.model.id, type: this.props.model.type };
    const nextDates = { endDate: nextProps.endDate, startDate: nextProps.startDate };
    const dates = { endDate: this.props.endDate, startDate: this.props.startDate };

    return (
      !shallowEqual(nextModel, model) ||
      !shallowEqual(nextDates, dates) ||
      !shallowEqual(nextState, this.state)
    );
  }

  request = async ({ model, endDate, startDate }) => {
    if (!endDate || !startDate) return;

    const response = await api.fetchHistoricalTerms(model.id, model.type, startDate, endDate);

    const previousPeriod = {
      from: moment(response.meta.stats.significant_previous_period.from).format('MM/DD/YYYY'),
      to: moment(response.meta.stats.significant_previous_period.to).format('MM/DD/YYYY'),
      terms: response.meta.stats.significant_previous_period.buckets.map(bucket => ({
        key: `${bucket.key}-${bucket.doc_count}`,
        docCount: bucket.doc_count,
        term: bucket.key,
        word: bucket.word,
        score: bucket.score,
        emails: bucket.emails,
      })),
    };

    const lastYear = {
      from: moment(response.meta.stats.significant_last_year.from).format('MM/DD/YYYY'),
      to: moment(response.meta.stats.significant_last_year.to).format('MM/DD/YYYY'),
      terms: response.meta.stats.significant_last_year.buckets.map(bucket => ({
        key: `${bucket.key}-${bucket.doc_count}`,
        docCount: bucket.doc_count,
        term: bucket.key,
        word: bucket.word,
        score: bucket.score,
        emails: bucket.emails,
      })),
    };

    this.setState(() => ({ loading: false, data: { previousPeriod, lastYear } }));
  };

  render() {
    const { data, loading } = this.state;
    const dates = { endDate: this.props.endDate, startDate: this.props.startDate };

    return (
      <Flex>
        <ReportSection css={{ minHeight: '557px' }}>
          <ReportSection.Header alignItems="center" justifyContent="flex-start">
            <Title>What Significant Terms are trending?</Title>
          </ReportSection.Header>

          <ReportSection.Body>
            {loading ? (
              <Flex alignItems="center" height="580px" justifyContent="center">
                <Loader />
              </Flex>
            ) : (
              <TermsTab defaultActive="tabOne">
                <TermsTab.Header>
                  <TermsTab.Item name="tabOne">
                    {({ isActive }) => (
                      <Flex direction="column">
                        <Heading
                          as="h4"
                          color={isActive ? 'dark1' : 'dark06'}
                          css={{
                            fontSize: '16px',
                            letterSpacing: '0.02em',
                            lineHeight: '24px',
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          vs. Previous Period
                        </Heading>
                        <Text css={{ marginTop: '7px' }}>
                          {data.previousPeriod.from} - {data.previousPeriod.to}
                        </Text>
                      </Flex>
                    )}
                  </TermsTab.Item>
                  <TermsTab.Item name="tabTwo">
                    {({ isActive }) => (
                      <Flex direction="column">
                        <Heading
                          as="h4"
                          color={isActive ? 'dark1' : 'dark06'}
                          css={{
                            fontSize: '16px',
                            letterSpacing: '0.02em',
                            lineHeight: '24px',
                            margin: '0 5px 0 0',
                            padding: 0,
                          }}
                        >
                          vs. Last Year
                        </Heading>
                        <Text css={{ marginTop: '7px' }}>
                          {data.lastYear.from} - {data.lastYear.to}
                        </Text>
                      </Flex>
                    )}
                  </TermsTab.Item>
                </TermsTab.Header>

                <TermsTab.Body>
                  {({ active }) => {
                    const { terms } =
                      active === 'tabOne' ? data.previousPeriod : data.lastYear;
                    const from = moment(dates.startDate).format('MM/DD/YYYY')
                    const to = moment(dates.endDate).format('MM/DD/YYYY')

                    return (
                      <Flex css={{ minHeight: '333px' }}>
                        <TermsTable from={from} terms={terms} to={to} />
                      </Flex>
                    );
                  }}
                </TermsTab.Body>
              </TermsTab>
            )}
          </ReportSection.Body>
        </ReportSection>
      </Flex>
    );
  }
}

HistoricalComparison.defaultProps = {
  endDate: null,
  startDate: null,
};

HistoricalComparison.propTypes = {
  model: PropTypes.shape({ id: PropTypes.string, type: PropTypes.string }).isRequired,
  endDate: PropTypes.string,
  startDate: PropTypes.string,
};

const mapStateToProps = state => ({
  endDate: state.newReports.dates.endDate,
  startDate: state.newReports.dates.startDate,
});

export default connect(mapStateToProps)(HistoricalComparison);
