import { combineReducers } from 'redux';

import reducer from './reducer';
import benchmarksReducer from './benchmarks';
import calendarsReducer from './calendars';
import sendingBehaviorReducer from './sendingBehavior';
import subjectLinesReducer from './subjectLines';
import timeSeriesReducer from './timeSeries';

export * as benchmarks from './benchmarks';
export * as calendars from './calendars';
export * as sendingBehavior from './sendingBehavior';
export * as subjectLines from './subjectLines';
export * as timeSeries from './timeSeries';

export * as newReportsActions from './actions';
export * as newReportsSagas from './sagas';
export * as newReportsTypes from './types';

export default combineReducers({
  benchmarks: benchmarksReducer,
  calendars: calendarsReducer,
  dates: reducer,
  sendingBehavior: sendingBehaviorReducer,
  subjectLines: subjectLinesReducer,
  timeSeries: timeSeriesReducer,
});
