import { PREFIX } from './constants';

export const FETCH_DATA_REQUEST = `${PREFIX}/FETCH_DATA_REQUEST`;
export const FETCH_DATA_SUCCESS = `${PREFIX}/FETCH_DATA_SUCCESS`;
export const FETCH_DATA_FAILURE = `${PREFIX}/FETCH_DATA_FAILURE`;

export const PAGINATE_DATA_REQUEST = `${PREFIX}/PAGINATE_DATA_REQUEST`;
export const PAGINATE_DATA_SUCCESS = `${PREFIX}/PAGINATE_DATA_SUCCESS`;
export const PAGINATE_DATA_FAILURE = `${PREFIX}/PAGINATE_DATA_FAILURE`;

export const SELECT_MESSAGE = `${PREFIX}/SELECT_MESSAGE`;
export const DESELECT_MESSAGE = `${PREFIX}/DESELECT_MESSAGE`;

export const SELECT_ALL_MESSAGES = `${PREFIX}/SELECT_ALL_MESSAGES`;
export const DESELECT_ALL_MESSAGES = `${PREFIX}/DESELECT_ALL_MESSAGES`;
