import * as types from './types';

export const fetchDataRequest = (pathname, query) => ({
  type: types.FETCH_DATA_REQUEST,
  pathname,
  query,
});

export const fetchDataSuccess = (pathname, query, data) => ({
  type: types.FETCH_DATA_SUCCESS,
  pathname,
  query,
  data,
});

export const fetchDataFailure = (pathname, query, error) => ({
  type: types.FETCH_DATA_FAILURE,
  pathname,
  query,
  error,
});

export const paginateDataRequest = (pathname, query, url) => ({
  type: types.PAGINATE_DATA_REQUEST,
  pathname,
  query,
  url,
});

export const paginateDataSuccess = (pathname, query, data) => ({
  type: types.PAGINATE_DATA_SUCCESS,
  pathname,
  query,
  data,
});

export const paginateDataFailure = (pathname, query, error) => ({
  type: types.PAGINATE_DATA_FAILURE,
  pathname,
  query,
  error,
});

export const selectMessage = (pathname, query, id) => ({
  type: types.SELECT_MESSAGE,
  pathname,
  query,
  id,
});

export const deselectMessage = (pathname, query, id) => ({
  type: types.DESELECT_MESSAGE,
  pathname,
  query,
  id,
});

export const selectAllMessages = (pathname, query) => ({
  type: types.SELECT_ALL_MESSAGES,
  pathname,
  query,
});

export const deselectAllMessages = (pathname, query) => ({
  type: types.DESELECT_ALL_MESSAGES,
  pathname,
  query,
});
