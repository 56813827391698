import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { userSelectors } from '../../modules/user';

import Section from '../Section';
import { Flex, Link } from '../mc-ui';

import FeaturedBrands from '../BrandsIndex/FeaturedBrands';
import FeaturedEmailLists from '../dashboard/FeaturedEmailLists';
import FeaturedGroups from '../BrandsIndex/FeaturedGroups';
import FeaturedJourneys from '../dashboard/FeaturedJourneys';

import Details from './Details';
import Header from './Header';
import Upgrade from './Upgrade';

const Unlocked = ({ isUserRestricted }) => (
  <div>
    <Section>
      <Flex justifyContent="space-between" padding="12px 0">
        <Header restricted={isUserRestricted} />
        <Upgrade restricted={isUserRestricted} />
      </Flex>
    </Section>

    <Section>
      <Flex alignItems="flex-start" justifyContent="space-between" padding="12px 0">
        <FeaturedGroups />
        <Details
          title="What’s unlocked in this industry?"
          bullets={[
            'Full access to Pro reporting and insights',
            'Historical emails from curated brands',
            'Curated lifecycle journeys',
            <span>
              Learn everything about industry groups by watching{' '}
              <Link to="https://youtu.be/F9kDHXzo1LM" target="_blank">
                this quick video
              </Link>
              .
            </span>,
          ]}
          imgSrc="/unlocked-industries-details.svg"
        />
      </Flex>
    </Section>

    <Section>
      <Flex alignItems="flex-start" justifyContent="space-between" padding="12px 0">
        <FeaturedBrands />
        <Details
          title="What’s unlocked in this brand?"
          bullets={[
            'Full access to Pro reporting and insights',
            'Historical emails and advanced filtering',
            'Curated lifecycle journeys',
            <span>
              Learn how to find trends for ecommerce brands in{' '}
              <Link to="https://youtu.be/-AZ26NGnNOs" target="_blank">
                this video
              </Link>
              .
            </span>,
          ]}
          imgSrc="/unlocked-brands-details.svg"
        />
      </Flex>
    </Section>

    <Section>
      <Flex alignItems="flex-start" justifyContent="space-between" padding="12px 0">
        <FeaturedJourneys />
        <Details
          title="How to use unlocked journeys?"
          bullets={[
            'View all emails in an automated campaign',
            'See best practices for journey length and number of emails',
            'Get messaging and design inspiration',
            <span>
              Learn how to build your next journey by{' '}
              <Link to="https://youtu.be/OPvHgKUJa1I" target="_blank">
                watching this video
              </Link>
              .
            </span>,
          ]}
          imgSrc="/unlocked-journeys-details.svg"
        />
      </Flex>
    </Section>

    <Section>
      <Flex alignItems="flex-start" justifyContent="space-between" padding="12px 0">
        <FeaturedEmailLists />
        <Details
          title="How to use curated emails?"
          bullets={[
            'Browse thousands of email examples and save your favorites',
            'Find inspiration for your next holiday campaign',
            'View best practices and optimize your triggered emails',
          ]}
          imgSrc="/unlocked-emails-details.svg"
        />
      </Flex>
    </Section>
  </div>
);

Unlocked.propTypes = {
  isUserRestricted: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  isUserRestricted: userSelectors.isUserRestrictedSelector(state),
});

export default connect(mapStateToProps)(Unlocked);
