import React from 'react';
import PropTypes from 'prop-types';

import Wrapper from './ui/Wrapper';

import AddToListEmails from './AddToListEmails';
import AddToListForm from './AddToListForm';

const AddToList = ({ model }) => (
  <Wrapper>
    <AddToListEmails model={model} />
    <AddToListForm model={model} />
  </Wrapper>
);

AddToList.defaultProps = {
  model: {},
};

AddToList.propTypes = {
  model: PropTypes.shape({}),
};

export default AddToList;
