import styled from "@emotion/styled";

export const ButtonGroup = styled("div")({
  display: 'flex',
  width: '120px',
  '& > &:not(:last-child) > a, & > a:not(:last-child)': {
    borderBottomRightRadius: '0',
    borderTopRightRadius: '0',
  },
  '& > &:not(:first-child) > a, & > a:nth-child(n+2), & > a:last-child': {
    borderTopLeftRadius: '0',
    borderBottomLeftRadius: '0',
  },
});

export const Button = styled("a")(({ active, theme }) => ({
  alignItems: 'center',
  backgroundColor: active ? theme.colors.dark1 : theme.colors.white,
  border: `1px solid ${active ? theme.colors.dark1 : theme.colors.dark5}`,
  borderRadius: '4px',
  boxSizing: 'border-box',
  color: active ? theme.colors.white : theme.colors.dark08,
  cursor: 'pointer',
  display: 'inline-flex',
  fontSize: '14px',
  fontWeight: '500',
  height: '32px',
  justifyContent: 'center',
  letterSpacing: '0.02em',
  lineHeight: '1em',
  margin: '0',
  padding: '0',
  width: '50%',
}));
