import styled from "@emotion/styled";

const Logo = styled("div")({
  alignItems: 'center',
  display: 'flex',
  fontSize: `${12 / 16}em`,
  textTransform: 'uppercase',
  '& a': {
    marginRight: '6px',
  },
  '& img': {
    height: '40px',
  },
});

export default Logo;
