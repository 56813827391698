import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import api from '../../../lib/api';
import { teamsSelectors } from '../../../modules/teams';

import { Box, Loader } from '../../mc-ui';

import ControlBar from '../ControlBar';

import Comments from './Comments';
import SortSelect from './SortSelect';

class NotesCollections extends React.PureComponent {
  state = {
    comments: [],
    loading: true,
    sort: this.props.router.location.query.sort || 'created_at:desc',
  };

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(_prevProps, prevState) {
    if (this.state.sort !== prevState.sort) {
      this.fetch();
    }
  }

  fetch = async () => {
    const { team } = this.props;
    const { sort } = this.state;

    this.setState({ loading: true });

    const url = team ? `/v3/teams/${team.id}/comments` : '/v3/user/comments';
    const response = await api.get(url, { params: { sort } });

    this.setState({
      comments: response.data.data,
      loading: false,
    });
  };

  updateSort = sort => {
    const { router } = this.props;

    this.setState(
      () => ({ sort }),
      () => {
        router.replace({
          pathname: router.location.pathname,
          query: { ...router.location.query, sort },
        });
      },
    );
  };

  render() {
    const { collectionType } = this.props;

    if (!collectionType) return null;

    const { comments, loading, sort } = this.state;

    return (
      <Box>
        <ControlBar collectionType={collectionType} hideSelector />

        <Box marginBottom="24px">
          <SortSelect
            disabled={loading || comments.length === 0}
            handleChange={this.updateSort}
            value={sort}
          />
        </Box>

        <Box
          backgroundColor="white"
          borderRadius="4px"
          height={loading ? '160px' : null}
          padding="0 24px"
        >
          {loading ? <Loader /> : <Comments comments={comments} />}
        </Box>
      </Box>
    );
  }
}

NotesCollections.defaultProps = {
  collectionType: null,
  team: null,
};

NotesCollections.propTypes = {
  collectionType: PropTypes.string,
  router: PropTypes.shape({
    location: PropTypes.shape({
      query: PropTypes.shape({
        sort: PropTypes.string,
      }),
    }),
  }).isRequired,
  team: PropTypes.shape({}),
};

const mapStateToProps = state => ({
  team: teamsSelectors.teamSelector(state),
});

export default connect(mapStateToProps)(NotesCollections);
