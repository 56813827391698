import { descend, prop, sort } from 'ramda';

const sortEmojis = sort(descend(prop('doc_count')));

export const jsonToData = (stats, section, filter) => {
  const data = stats[section].buckets.find(b => b.key === filter) || {};

  return {
    docCount: data.doc_count || 0,
    total: data.total || 0,
    value: data.value || 0,
    emails: data.emails || [],
  };
};

export const jsonToEmojis = buckets => {
  const emojis = sortEmojis(buckets);

  return emojis.map(emoji => ({
    docCount: emoji.doc_count,
    emails: emoji.emails,
    emoji: emoji.key,
    total: emoji.total,
    value: emoji.value,
  }));
};
