import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form, FormSpy } from 'react-final-form';

import { Button, Flex, Label, Text, TextareaField } from '../mc-ui';

import { maxLength, required } from '../../lib/forms/validators';

const MAX_LENGTH = 500;
const validator = value => required(value) || maxLength(MAX_LENGTH)(value) || undefined;

export default function CommentForm({ editing, onCancel, ...props }) {
  return (
    <Form {...props}>
      {({ form, handleSubmit, invalid, pristine, submitting }) => (
        <form
          onSubmit={event => {
            handleSubmit(event).then(form.reset);
          }}
        >
          <Label htmlFor="text" css={{ marginBottom: '8px', '& > *': { marginTop: '0' } }}>
            <Field
              autoFocus={editing}
              component={TextareaField}
              css={{ height: '72px' }}
              id="text"
              name="text"
              placeholder="Make a note"
              validate={validator}
            />
            <FormSpy>
              {fsProps => {
                const textLength = fsProps.values.text ? fsProps.values.text.length : 0;
                if (textLength > MAX_LENGTH) return null;

                return (
                  <Text fontSize="80%" lineHeight="18px" css={{ marginTop: '0.25rem' }}>
                    {textLength}/{MAX_LENGTH}
                  </Text>
                );
              }}
            </FormSpy>
          </Label>

          <Flex justifyContent="flex-end">
            {editing && (
              <Button
                disabled={submitting}
                onClick={onCancel}
                variant="secondary"
                type="button"
                css={{
                  alignItems: 'center',
                  display: 'inline-flex',
                  fontSize: '12px',
                  height: '24px',
                  justifyContent: 'center',
                  letterSpacing: '0.02em',
                  lineHeight: '1em',
                  margin: '0 8px 0 0',
                  padding: '0 8px',
                }}
              >
                Cancel
              </Button>
            )}

            <Button
              disabled={submitting || pristine || invalid}
              variant="primary"
              type="submit"
              css={{
                alignItems: 'center',
                display: 'inline-flex',
                fontSize: '12px',
                height: '24px',
                justifyContent: 'center',
                letterSpacing: '0.02em',
                lineHeight: '1em',
                margin: '0',
                padding: '0 8px',
              }}
            >
              {editing ? 'Save' : 'Leave note'}
            </Button>
          </Flex>
        </form>
      )}
    </Form>
  );
}

CommentForm.defaultProps = {
  editing: false,
  onCancel: null,
};

CommentForm.propTypes = {
  editing: PropTypes.bool,
  onCancel: PropTypes.func,
};
