import React from 'react';
import PropTypes from 'prop-types';
import styled from "@emotion/styled";

import BrandLogo from '../../../Brands/BrandLogo';

const GroupBrands = ({ background, brands }) => {

  const GroupBrandsWrapper = styled("div")(({
    alignItems: 'flex-end',
    backgroundImage: `url(${background})`,
    borderRadius: '3px 3px 0 0',
    boxSizing: 'border-box',
    display: 'flex',
    height: '164px',
    padding: '8px',
    '& > div': {
      backgroundColor: '#fff',
      border: '1px solid #fff',
      borderRadius: '3px',
      display: 'inline-block',
      height: '40px',
      marginLeft: '8px',
      overflow: 'hidden',
      width: '40px',
    },
  }));

  return (
    <GroupBrandsWrapper>
      {brands.map(brand => (
        <div key={brand.id}>
          <BrandLogo model={brand} />
        </div>
      ))}
    </GroupBrandsWrapper>
  );
};

GroupBrands.propTypes = {
  background: PropTypes.string.isRequired,
  brands: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.shape({
      logo_src: PropTypes.string,
      name: PropTypes.string,
    }),
  })).isRequired,
};

export default GroupBrands;
