import React, { useState} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { couponActions } from '../../../../modules/coupon';
import { teamsActions, teamsSelectors } from '../../../../modules/teams';

import { Box, Heading, Text } from '../../../mc-ui';
import CheckoutForm from './CheckoutForm';
import CreditCardInfo from './CreditCardInfo';
import CheckoutFailureModal from './CheckoutFailureModal';
import CheckoutSuccessModal from './CheckoutSuccessModal';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import api from '../../../../lib/api';


const Checkout = ({
  isPro,
  isSubscriptionAutomaticallyCharged,
  interval,
  onCancel,
  planExpired,
  subscription,
  hasSmsAddon,
  isCancelingSubscription,
  team,
  amount,
  clearCoupon,
  createSubscription,
  updateCreditCard,
  updateAddons,
  setupIntent,
  getPaymentIntent,
  paymentInfo
}) => {
  const [modal,setModal] = useState('modal');
  const [isSubmitting,setIsSubmitting] = useState(false);
  const [attachedToCustomer,setAttachedToCustomer] = useState(false);
  const [customerAddress,setCustomerAddress]=useState({});

  const stripe = useStripe();
  const elements = useElements();
  const isActive = team && team.attributes.account_status === 'active';

  const onAddressChange=(event) => {
    if (event.complete) {
      const address = event.value.address;
      setCustomerAddress(address!=null?address:{});
    }
  }

  const handleSubmit = async (values, form, callback) => {

    try{
      setIsSubmitting(true);
      const callbackFunc = () => {
        callback();
        clearCoupon();
        setIsSubmitting(false);

      };

      if (!stripe) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        setIsSubmitting(false);
        return;
      }

      /**
       * Only submit if the user is not subscribed yet
       */
      if(!isActive || !team){

        let attachCustomerResponse;
        if(!attachedToCustomer){
          const requestBody = {
            setup_intent_id: setupIntent.id,
            postal_code: customerAddress.postal_code,
            country: customerAddress.country
          };
          
          if (subscription.attributes.stripe_customer_id) {
            requestBody.customer_id = subscription.attributes.stripe_customer_id;
          }
          attachCustomerResponse = await api.post('/v3/stripe/setup-payment-intent/attach-customer', requestBody);

          setAttachedToCustomer(true)
        }

        if(attachCustomerResponse.status !== 200){
          setModal("failure");
        }

        const return_url = `${location.origin}/manage/plan`;

        const {error} = await stripe.confirmSetup({
          //`Elements` instance that was used to create the Payment Element
          elements,
          confirmParams: {
            return_url,
          },
          redirect: 'if_required'
        });

        if (error) {
          setModal("failure");
          setIsSubmitting(false);
          return;
        }

        values.setup_intent_id = setupIntent.id
        values.stripe_customer_id =  subscription && subscription.attributes.stripe_customer_id && subscription.attributes.stripe_customer_id !== '__manual__' ? subscription.attributes.stripe_customer_id : attachCustomerResponse.data.updateSetupIntent.customer;
        createSubscription(values, submitResolver(callbackFunc));
      } else {
        values.stripe_customer_id = subscription.attributes.stripe_customer_id;
        if (typeof values.sms_addon !== 'undefined') {
          if (values.sms_addon && interval == "month") {
            updateAddons({ sms_addon: "month" }, callbackFunc);
          }
          if (values.sms_addon && interval == "year") {
            updateAddons({ sms_addon: "year" }, callbackFunc);
          }
          if (!values.sms_addon) {
            updateAddons({ sms_addon: false }, callbackFunc);
          }
        }
      }
    } catch (error){
      setModal("failure");
      setIsSubmitting(false);
    }
  };

  const submitResolver = callback => success => {
    setIsSubmitting(false);
    openModal(success ? 'success' : 'failure', callback)
    // get a new paymentIntent key incase the user wants to update his credit card information
    getPaymentIntent();
  };

  const openModal = (callback) => setModal(callback);

  const closeModal = () => setModal({ modal: null });

  return (
    <Box marginTop={isActive && !planExpired ? '24px' : '32px'}>
      <Heading as="h4" fontSize="16px" lineHeight="20px" css={{ marginBottom: '4px' }}>
        {isActive && !planExpired
          ? 'Your billing info'
          : 'Enter your credit card info to upgrade'}
      </Heading>

      <Text margin="0 0 16px 0">No long term commitments. Cancel entire plan at any time.</Text>

      {isActive &&
        !planExpired &&
        isSubscriptionAutomaticallyCharged && <CreditCardInfo paymentInfo={paymentInfo} />}

      <CheckoutForm
        isPro={isPro}
        interval={interval}
        onCancel={onCancel}
        amount={amount}
        onSubmit={handleSubmit}
        planExpired={planExpired}
        hasSmsAddon={hasSmsAddon}
        isCancelingSubscription={isCancelingSubscription}
        team={team}
        isSubmitting={isSubmitting}
        showCouponField
        onAddressChange={onAddressChange}
      />

      <CheckoutFailureModal closeModal={closeModal} isOpen={modal === 'failure'} />
      <CheckoutSuccessModal closeModal={closeModal} isOpen={modal === 'success'} />
    </Box>
  );
}

Checkout.defaultProps = {
  team: null,
};

Checkout.propTypes = {
  interval: PropTypes.func.isRequired,
  clearCoupon: PropTypes.func.isRequired,
  createSubscription: PropTypes.func.isRequired,
  isPro: PropTypes.bool.isRequired,
  isSubscriptionAutomaticallyCharged: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  planExpired: PropTypes.bool.isRequired,
  subscription: PropTypes.shape({}).isRequired,
  team: PropTypes.shape({}),
  updateCreditCard: PropTypes.func.isRequired,
  updateAddons: PropTypes.func.isRequired,
  setupIntent: PropTypes.shape({}).isRequired
};

const mapStateToProps = state => ({
  isPro: teamsSelectors.isProSelector(state),
  isSubscriptionAutomaticallyCharged: teamsSelectors.isSubscriptionAutomaticallyChargedSelector(
    state,
  ),
  planExpired: teamsSelectors.planExpiredSelector(state),
  subscription: teamsSelectors.subscriptionSelector(state),
  hasSmsAddon: teamsSelectors.hasSmsAddonSelector(state),
  isCancelingSubscription: teamsSelectors.isCancelingSubscriptionSelector(state),
  setupIntent: teamsSelectors.getSetupIntent(state),
  paymentInfo: teamsSelectors.getPaymentInfo(state),
  subscription: teamsSelectors.subscriptionSelector(state),
});

const mapDispatchToProps = (dispatch, { interval }) => ({
  clearCoupon: () => dispatch(couponActions.clearCoupon()),
  createSubscription: (payload, resolve) =>
    dispatch(teamsActions.createSubscriptionRequest({ ...payload, interval }, resolve)),
  updateCreditCard: (payload, resolve) =>
    dispatch(
      teamsActions.subscriptionUpdateCreditCardRequest({ ...payload, interval }, resolve),
    ),
  updateAddons: (payload, resolve) =>
    dispatch(teamsActions.subscriptionAddonsRequest(payload, resolve)),
  getPaymentIntent: () => dispatch(teamsActions.requestPaymentIntent()),
  getPaymentInfoRequest: (subscriptionId) => dispatch(teamsActions.getPaymentInfoRequest(subscriptionId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Checkout);