import humanize from 'humanize-number';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import {
  groupOverviewActions as actions,
  groupOverviewSelectors as selectors,
} from '../../modules/groupOverview';
import { Badge, SubMenu } from '../mc-ui';
import BrandIcon from '../mc-ui/icons/BrandIcon';
import EmailIcon from '../mc-ui/icons/EmailIcon';
import MobileIcon from '../mc-ui/icons/MobileIcon';
import OverviewIcon from '../mc-ui/icons/OverviewIcon';
import TimelineIcon from '../mc-ui/icons/TimelineIcon';
import ReportsMenu from './ReportsMenu';

let forceReportsMenuRerender = 0;

class GroupsMenu extends React.Component {
  componentDidUpdate(prevProps) {
    if (isEmpty(prevProps.groups.data) && !isEmpty(this.props.groups.data)) {
      const { group, groups, meta, requestOverview } = this.props;
      const [model] = groups.data;

      if ((isEmpty(group) || isEmpty(meta)) && model) {
        requestOverview(model);
      }
    }
  }

  render() {
    const { dashboard, group, loading, meta } = this.props;

    if (loading || isEmpty(group) || isEmpty(meta)) return <div />;

    let rootPath = `/${group.type}/${group.id}`;
    if (dashboard) rootPath = '/dashboard';

    forceReportsMenuRerender += 1;

    return (
      <div>
        <SubMenu>
          <SubMenu.Link
            to={`${rootPath}/overview`}
            activePathnames={['', 'dashboard', 'overview', group.id.toString()]}
          >
            <OverviewIcon height="20" width="20" fillOpacity={1} />
            Overview
          </SubMenu.Link>

          <SubMenu.Link to={`${rootPath}/emails`} activePathnames={['emails', 'inbox', 'calendar']}>
            <EmailIcon height="20" width="20" fillOpacity={1} />
            Emails
            <Badge
              backgroundColor="white"
              border="1px solid"
              borderColor="dark5"
              lineHeight="1.56em"
              margin="0 0 0 4px"
            >
              {humanize(meta.stats.approximateEmails || 0)}
            </Badge>
          </SubMenu.Link>

          <SubMenu.Link to={`${rootPath}/companies`}>
            <BrandIcon height="20" width="20" fillOpacity={1} />
            Brands
            <Badge
              backgroundColor="white"
              border="1px solid"
              borderColor="dark5"
              lineHeight="1.56em"
              margin="0 0 0 4px"
            >
              {humanize(meta.totalCompanies || 0)}
            </Badge>
          </SubMenu.Link>

          <SubMenu.Link to={`${rootPath}/journeys`}>
            <TimelineIcon height="20" width="20" fillOpacity={1} />
            Journeys
            <Badge
              backgroundColor="white"
              border="1px solid"
              borderColor="dark5"
              lineHeight="1.56em"
              margin="0 0 0 4px"
            >
              {humanize(meta.stats.approximateJourneys || 0)}
            </Badge>
          </SubMenu.Link>

          <SubMenu.Link to={`${rootPath}/text-messages?sort=sent_at:desc`}>
            <MobileIcon height="20" width="20" fillOpacity={1} />
            SMS
          </SubMenu.Link>
        </SubMenu>
        <ReportsMenu rootPath={rootPath} rerender={forceReportsMenuRerender} />
      </div>
    );
  }
}

GroupsMenu.defaultProps = {
  dashboard: false,
};

GroupsMenu.propTypes = {
  dashboard: PropTypes.bool,
  group: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  groups: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        type: PropTypes.string,
      }),
    ),
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  meta: PropTypes.shape({
    totalCompanies: PropTypes.number,
    stats: PropTypes.shape({
      approximateEmails: PropTypes.number,
      approximateJourneys: PropTypes.number,
    }),
  }).isRequired,
  requestOverview: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  group: selectors.getModel(state),
  loading: selectors.getLoading(state),
  meta: selectors.getData(state).meta || {},
});

const mapDispatchToProps = dispatch => ({
  requestOverview: model => dispatch(actions.fetchDataRequest(model || {})),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GroupsMenu);
