const INITIAL_STATE = { data: [], loading: false, meta: {} }
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'FLUSH': return { ...INITIAL_STATE }
    case 'RESET_SUGGESTIONS': return { ...INITIAL_STATE, loading: false }
    case 'REQUEST_SUGGESTIONS': return { ...INITIAL_STATE, loading: true }
    case 'RECEIVE_SUGGESTIONS':
      return { ...state, ...action.data, loading: false }
    default: return state
  }
}

