import * as types from './types';

export const fetchBrandDataRequest = (brandId, startDate) => ({
  type: types.FETCH_BRAND_DATA_REQUEST,
  modelId: brandId,
  startDate,
});
export const fetchBrandDataFailure = (brandId, startDate, error) => ({
  type: types.FETCH_BRAND_DATA_FAILURE,
  modelId: brandId,
  startDate,
  error,
});
export const fetchBrandDataSuccess = (brandId, startDate, payload) => ({
  type: types.FETCH_BRAND_DATA_SUCCESS,
  modelId: brandId,
  startDate,
  payload,
});
export const toggleBrandLoading = (brandId, startDate) => ({
  type: types.TOGGLE_BRAND_LOADING,
  modelId: brandId,
  startDate,
});

export const fetchBrandModalDataRequest = (brandId, firstDayOfMonth, date) => ({
  type: types.FETCH_BRAND_MODAL_DATA_REQUEST,
  modelId: brandId,
  firstDayOfMonth,
  date,
});
export const fetchBrandModalDataFailure = (brandId, firstDayOfMonth, date, error) => ({
  type: types.FETCH_BRAND_MODAL_DATA_FAILURE,
  modelId: brandId,
  firstDayOfMonth,
  date,
  error,
});
export const fetchBrandModalDataSuccess = (brandId, firstDayOfMonth, date, payload) => ({
  type: types.FETCH_BRAND_MODAL_DATA_SUCCESS,
  modelId: brandId,
  firstDayOfMonth,
  date,
  payload,
});
export const toggleBrandModalLoading = (brandId, firstDayOfMonth, date) => ({
  type: types.TOGGLE_BRAND_MODAL_LOADING,
  modelId: brandId,
  firstDayOfMonth,
  date,
});

export const fetchGroupDataRequest = (groupId, startDate) => ({
  type: types.FETCH_GROUP_DATA_REQUEST,
  modelId: groupId,
  startDate,
});
export const fetchGroupDataFailure = (groupId, startDate, error) => ({
  type: types.FETCH_GROUP_DATA_FAILURE,
  modelId: groupId,
  startDate,
  error,
});
export const fetchGroupDataSuccess = (groupId, startDate, payload) => ({
  type: types.FETCH_GROUP_DATA_SUCCESS,
  modelId: groupId,
  startDate,
  payload,
});
export const toggleGroupLoading = (groupId, startDate) => ({
  type: types.TOGGLE_GROUP_LOADING,
  modelId: groupId,
  startDate,
});

export const fetchGroupModalDataRequest = (groupId, firstDayOfMonth, date) => ({
  type: types.FETCH_GROUP_MODAL_DATA_REQUEST,
  modelId: groupId,
  firstDayOfMonth,
  date,
});
export const fetchGroupModalDataFailure = (groupId, firstDayOfMonth, date, error) => ({
  type: types.FETCH_GROUP_MODAL_DATA_FAILURE,
  modelId: groupId,
  firstDayOfMonth,
  date,
  error,
});
export const fetchGroupModalDataSuccess = (groupId, firstDayOfMonth, date, payload) => ({
  type: types.FETCH_GROUP_MODAL_DATA_SUCCESS,
  modelId: groupId,
  firstDayOfMonth,
  date,
  payload,
});
export const toggleGroupModalLoading = (groupId, firstDayOfMonth, date) => ({
  type: types.TOGGLE_GROUP_MODAL_LOADING,
  modelId: groupId,
  firstDayOfMonth,
  date,
});
