import React from 'react';

import { Box, Flex, Heading, Text } from '../../mc-ui';
import ScrollIcon from '../../mc-ui/icons/ScrollIcon';

export default function EmptyResult() {
  return (
    <Flex
      alignItems="flex-start"
      backgroundColor="white"
      border="1px solid"
      borderColor="yellowMedium"
      borderRadius="4px"
      padding="16px"
    >
      <ScrollIcon />

      <Box flex="1" margin="0 16px" width="unset">
        <Heading as="h3" lineHeight={`${24 / 18}em`} css={{ marginBottom: '8px' }}>
          Sorry! No results found.
        </Heading>

        <Text color="dark08">There are no journeys that match your search.</Text>
      </Box>
    </Flex>
  );
}
