export default function generateChartOptions(chart, theme, chartData) {
  const { max } = chartData;
  const PERCENTAGE_SIGN = chart === 'promotional' ? '%' : '';

  let yMax = 0;
  let yStepSize = 0;
  if (max < 10) {
    yMax = 10;
    yStepSize = 2;
  } else if (max < 15) {
    yMax = 15;
    yStepSize = 5;
  } else if (max < 25) {
    yMax = 25;
    yStepSize = 5;
  } else if (max < 50) {
    yMax = 50;
    yStepSize = 10;
  } else if (max < 75) {
    yMax = 75;
    yStepSize = 15;
  } else if (max < 100) {
    yMax = 100;
    yStepSize = 20;
  } else {
    yMax = 100;
    yStepSize = 20;
  }

  return {
    animation: false,
    legend: { display: false },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            fontColor: theme.colors.dark04,
            fontFamily: theme.font.family,
            fontSize: 10,
            lineHeight: 1.56,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            beginAtZero: true,
            fontColor: theme.colors.dark04,
            fontFamily: theme.font.family,
            fontSize: 10,
            lineHeight: 1.56,
            max: yMax,
            min: 0,
            stepSize: yStepSize,
            userCallback(label) {
              return `${label}${PERCENTAGE_SIGN}`;
            },
          },
        },
      ],
    },
    tooltips: {
      enabled: false,
    },
  };
}
