import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { isEmpty, isNil } from 'ramda';

import { Box, Flex, Heading, Label, Link, TextField } from '../mc-ui';
import CreditCardInfo from '../MyPlan/PlanSwitcher/Checkout/CreditCardInfo';
import CreditCardFormFields from '../MyPlan/PlanSwitcher/Checkout/CreditCardFormFields';

const isNotPresent = value => isEmpty(value) || isNil(value);
const required = value => (isNotPresent(value) ? 'Required' : undefined);

class BillingInfoFormFields extends React.Component {
  state = {
    updateCreditCard: false,
  };

  toggleUpdateCreditCard = event => {
    if (event) event.preventDefault();
    this.setState(currentState => ({ updateCreditCard: !currentState.updateCreditCard }));
  };

  render() {
    const { subscription } = this.props;
    const { updateCreditCard } = this.state;

    return (
      <Box>
        <Heading as="h4" fontSize="16px" lineHeight="20px" css={{ marginBottom: '16px' }}>
          Current plan is paid on this card
        </Heading>

        <Flex alignItems="center" justifyContent="flex-start" marginBottom="24px">
          <CreditCardInfo
            subscription={subscription}
            marginBottom="0"
            marginRight="8px"
            width="unset"
          />
          <Link to="#updateCreditCard" onClick={this.toggleUpdateCreditCard}>
            {updateCreditCard ? '(cancel)' : '(update)'}
          </Link>
        </Flex>

        {updateCreditCard && <CreditCardFormFields />}

        <Heading as="h4" fontSize="16px" lineHeight="20px" css={{ margin: '32px 0 16px 0' }}>
          Business information
        </Heading>

        <Box marginBottom="16px">
          <Label htmlFor="companyName" width="346px">
            Company name
            <Field
              component={TextField}
              id="companyName"
              name="billing_info[company_name]"
              placeholder="Your company name"
              validate={required}
            />
          </Label>
        </Box>

        <Flex justifyContent="space-between" marginBottom="32px">
          <Label htmlFor="streetAddress" width="346px">
            Street address <small>(optional)</small>
            <Field
              component={TextField}
              id="streetAddress"
              name="billing_info[address]"
              placeholder=""
            />
          </Label>

          <Label htmlFor="vatNumber" width="346px">
            VAT number <small>(optional)</small>
            <Field
              component={TextField}
              id="vatNumber"
              name="billing_info[vat_number]"
              placeholder=""
            />
          </Label>
        </Flex>
      </Box>
    );
  }
}

BillingInfoFormFields.propTypes = {
  subscription: PropTypes.shape({}).isRequired,
};

export default BillingInfoFormFields;
