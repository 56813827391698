import { camelizeKeys } from 'humps';
import { ascend, path, sort } from 'ramda';

const sortAscend = sort(ascend(path(['attributes', 'name'])));

function sortGroups(groups) {
  const trackedCompanies = groups.find(g => g.attributes.tracked);
  const createGroup = {
    id: 'createGroup',
    type: 'groups',
    attributes: { name: 'Create New Group' },
  };
  const allGroups = sortAscend(groups.filter(g => g.id !== trackedCompanies.id));

  return camelizeKeys([trackedCompanies, createGroup, ...allGroups]);
}

export default sortGroups;
