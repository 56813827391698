import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { connect } from 'react-redux';

import { getColorForEmail } from '../lib/color';

import PlusIcon from './ui/PlusIcon';
import AddToList from './AddToList';

import EmailDate from './EmailDate';
import Recipient from './Recipient';
import From from './From';

import styles from './styles.css';

const emailImage = email =>
  `//assets.mailcharts.com/emails/${email.attributes.guid}/${'300x400'}.png`;

class EmailThumbnail extends React.Component {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      bg: { backgroundImage: `url(/loading.png)` },
      isBookmarked: false,
      selected: !!props.email.selected,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ selected: !!nextProps.email.selected });
  }

  toggleSelected = event => {
    if (this.props.preventClick) return;

    const { editing } = this.props;

    if (!editing) return;

    event.preventDefault();
    event.stopPropagation();

    const { dispatch, email } = this.props;
    const selected = !email.selected;

    this.setState({ selected });
    dispatch({
      type: selected ? 'SELECT_EMAIL' : 'DESELECT_EMAIL',
      guid: email.attributes.guid,
    });
  };

  openAddToListModal = event => {
    event.preventDefault();
    event.stopPropagation();

    const { email, showModal, requestEmail } = this.props;

    const modalChildren = <AddToList model={email} />;

    requestEmail(email.attributes.guid);
    showModal(modalChildren);
  };

  handleClick = event => {
    if (this.props.preventClick) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  handleBrandLogoClick = event => {
    if (this.props.preventClick) return;

    event.preventDefault();
    event.stopPropagation();

    const { email } = this.props;
    const { router } = this.context;

    router.push({
      pathname: `/companies/${email.attributes.company_id}/emails`,
      query: { ...this.context.router.location.query },
    });
  };

  handleLoad = event => {
    const { hideDetails } = this.props;

    this.setState({
      loaded: true,
      bg: {
        backgroundImage: `url(${emailImage(this.props.email)})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: this.props.backgroundSize || 'contain',
        backgroundPosition: hideDetails ? 'center' : 'center 95px',
      },
    });
  };

  render() {
    const { editing, email, height, hideDetails, padding, width } = this.props;
    const { selected } = this.state;
    const { router } = this.context;
    const logo =
      email.type === 'loading' || hideDetails
        ? '/logo_placeholder.jpg'
        : `//cache.mailcharts.com/logos/v2/companies/${email.attributes.company_id}.png`;

    const { classifications = [] } = email.attributes;
    const border = classifications.length
      ? `2px solid ${getColorForEmail(classifications[0].type)}`
      : this.props.border;
    return (
      <div className={styles['email-container']} style={{ padding, width }}>
        {!this.state.loading &&
          email.attributes.guid && (
            <img src={emailImage(email)} onLoad={this.handleLoad} style={{ display: 'none' }} />
          )}
        <Link
          to={{
            pathname: `/emails/${email.attributes.guid}`,
            query: { ...router.location.query, ...router.params },
          }}
          onClick={editing ? this.toggleSelected : this.handleClick}
        >
          <div
            style={{
              opacity: editing && !selected ? '.5' : null,
              border,
            }}
            className={styles.email}
          >
            <div
              className={styles['email-details']}
              style={{
                ...this.state.bg,
                height,
                backgroundColor: hideDetails ? '#fff' : 'transparent',
              }}
            >
              {!hideDetails && (
                <div
                  className="pure-g"
                  style={{ paddingTop: 5, background: 'white', borderBottom: '1px solid #eee' }}
                >
                  <div className="pure-u-1-24" />
                  <div className="pure-u-22-24" style={{ position: 'relative' }}>
                    <div className="pure-u-1-6" onClick={this.handleBrandLogoClick}>
                      <img
                        onError={err => {
                          // only make the replacement one time
                          if (!/defaults/.test(err.target.src)) {
                            err.target.src = `//cache.mailcharts.com/logos/v2/defaults/${email
                              .attributes.company_id % 125}.png`;
                          }
                        }}
                        src={logo}
                        className="pure-img"
                        style={{ borderRadius: 2 }}
                        title={`View more emails from ${email.attributes.company_name}`}
                      />
                    </div>
                    <div className={editing ? `pure-u-2-3` : `pure-u-5-6`}>
                      <div style={{ marginLeft: 5 }}>
                        <div className="pure-u-1">
                          <From from={email.attributes.from || []} />
                        </div>
                        <div className="pure-u-1">
                          <EmailDate className={styles.date} date={email.attributes.sent_at} />
                        </div>
                      </div>
                    </div>
                    {editing ? (
                      <div className="pure-u-1-6" style={{ textAlign: 'right' }}>
                        <form className="pure-form">
                          <input
                            checked={selected}
                            readOnly
                            className="pure-input"
                            type="checkbox"
                          />
                        </form>
                      </div>
                    ) : (
                      <div style={{ position: 'absolute', top: '10px', right: 0 }}>
                        <PlusIcon
                          onClick={this.openAddToListModal}
                          active={!!email.attributes.saved}
                          data-add-to-list
                        />
                      </div>
                    )}
                    <div className="pure-u-1">
                      <div>
                        <p className={styles.subject}>{email.attributes.subject}</p>
                      </div>
                    </div>
                  </div>
                  <div className="pure-u-1-24" />
                </div>
              )}
              {(email.attributes.animated || email.attributes.mobile_optimized) && (
                <div style={{ opacity: 0.8, textAlign: 'right', margin: 5, marginLeft: 0 }}>
                  {email.attributes.mobile_optimized && (
                    <p
                      style={{
                        fontSize: `${10 / 16}em`,
                        display: 'inline',
                        background: '#fff',
                        border: '1px solid #D8D8D8',
                        marginLeft: 5,
                        borderRadius: 2,
                        padding: 4,
                      }}
                    >
                      Mobile
                    </p>
                  )}
                  {email.attributes.animated && (
                    <p
                      style={{
                        fontSize: `${10 / 16}em`,
                        display: 'inline',
                        background: '#fff',
                        border: '1px solid #D8D8D8',
                        marginLeft: 5,
                        borderRadius: 2,
                        padding: 4,
                      }}
                    >
                      GIF
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>
        </Link>
      </div>
    );
  }
}

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => ({
  requestEmail: (guid, params = {}) => dispatch({ type: 'REQUEST_EMAIL', guid, params }),
  showModal: (modalChildren, modalStyle = {}) =>
    dispatch({ type: 'SHOW_MODAL', modalChildren, modalStyle }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmailThumbnail);
