import * as React from 'react';
import PropTypes from 'prop-types';

function InvoiceIcon({ fillOpacity, ...props }) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 7H3V4h18v3zM9 20H3v-3h6v3zM9 14H3v-3h6v3z"
        fill="currentColor"
        fillOpacity={fillOpacity}
      />
      <path
        d="M18.222 17.95H13.11a1.029 1.029 0 00-.422.075 1.071 1.071 0 00-.36.242 1.136 1.136 0 00-.243.371 1.182 1.182 0 000 .882c.056.14.139.266.242.372.104.105.227.188.361.242.135.055.278.08.422.075h2.053c.074 0 .144.032.196.086a.3.3 0 01.081.206v.368c0 .3.114.588.316.8.201.212.475.331.76.331.286 0 .56-.12.76-.331.203-.212.316-.5.316-.8v-.368a.3.3 0 01.082-.206.271.271 0 01.195-.086h.353c.365 0 .726-.075 1.063-.222.337-.147.643-.362.901-.633s.463-.593.602-.947a3.05 3.05 0 000-2.235 2.934 2.934 0 00-.602-.947 2.775 2.775 0 00-.901-.633 2.662 2.662 0 00-1.063-.223h-3.41a.612.612 0 01-.442-.193.676.676 0 01-.184-.465c0-.175.066-.343.184-.466a.612.612 0 01.443-.193h5.108c.285 0 .559-.12.76-.331.202-.212.316-.5.316-.8 0-.3-.114-.587-.315-.8a1.05 1.05 0 00-.761-.33h-2.052a.27.27 0 01-.196-.086.3.3 0 01-.081-.206v-.368c0-.3-.113-.588-.315-.8a1.05 1.05 0 00-.76-.331c-.286 0-.56.12-.761.331a1.16 1.16 0 00-.316.8v.368a.3.3 0 01-.08.206.27.27 0 01-.197.085h-.35c-.365 0-.726.076-1.064.223a2.776 2.776 0 00-.9.633 2.936 2.936 0 00-.603.947 3.05 3.05 0 000 2.235c.14.354.344.676.602.947.258.272.564.487.901.633.338.147.699.223 1.064.223h3.409c.162.007.314.08.427.202a.679.679 0 01.174.457c0 .17-.062.334-.174.457a.615.615 0 01-.427.202z"
        fill="currentColor"
        fillOpacity={fillOpacity}
      />
    </svg>
  );
}

InvoiceIcon.propTypes = {
  fillOpacity: PropTypes.number,
};

InvoiceIcon.defaultProps = {
  fillOpacity: 1,
};

export default InvoiceIcon;
