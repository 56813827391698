import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Box, Flex, Text } from '../../mc-ui';
import BetweenEmailsIcon from '../../mc-ui/icons/BetweenEmailsIcon';
import CalendarStartIcon from '../../mc-ui/icons/CalendarStartIcon';
import DurationIcon from '../../mc-ui/icons/DurationIcon';
import EmailIcon from '../../mc-ui/icons/EmailIcon';

import DateDataPoint from './DateDataPoint';
import NumberDataPoint from './NumberDataPoint';
import TriggeredEmailsDataPoint from './TriggeredEmailsDataPoint';

const DataPoints = ({
  durationDays,
  endDate,
  intervalDays,
  startDate,
  triggeredEmails,
  upcoming,
}) => (
  <Flex justifyContent="space-between" width="500px">
    {!upcoming && (
      <Box width="240px">
        <Flex marginBottom="6px">
          <Text color="dark06" css={{ marginRight: '10px' }}>
            <CalendarStartIcon height="20px" width="20px" />
          </Text>

          <DateDataPoint endDate={endDate} startDate={startDate} />
        </Flex>

        <Flex>
          <Text color="dark06" css={{ marginRight: '10px' }}>
            <DurationIcon height="20px" width="20px" />
          </Text>

          <NumberDataPoint label="Duration" pluralLabel="day" value={durationDays} />
        </Flex>
      </Box>
    )}

    {!upcoming && (
      <Box width="240px">
        <Flex marginBottom="6px">
          <Text color="dark06" css={{ marginRight: '10px' }}>
            <EmailIcon fillOpacity={1} height="20px" width="20px" />
          </Text>

          <TriggeredEmailsDataPoint value={triggeredEmails} />
        </Flex>

        <Flex>
          <Text color="dark06" css={{ marginRight: '10px' }}>
            <BetweenEmailsIcon height="20px" width="20px" />
          </Text>

          <NumberDataPoint label="Time between emails" pluralLabel="day" value={intervalDays} />
        </Flex>
      </Box>
    )}

    {upcoming && (
      <Box width="240px">
        <Flex marginBottom="6px">
          <Text color="dark06" css={{ marginRight: '10px' }}>
            <CalendarStartIcon height="20px" width="20px" />
          </Text>

          <Text>Est. Completion Date: {moment(startDate).format('MMM YYYY')}</Text>
        </Flex>
      </Box>
    )}
  </Flex>
);

DataPoints.defaultProps = {
  durationDays: null,
  endDate: null,
  intervalDays: null,
  startDate: null,
  triggeredEmails: null,
  upcoming: false,
};

DataPoints.propTypes = {
  durationDays: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  endDate: PropTypes.string,
  intervalDays: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  startDate: PropTypes.string,
  triggeredEmails: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  upcoming: PropTypes.bool,
};

export default DataPoints;
