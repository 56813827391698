const relationships = { companies: { data: [] }, technologies: { data: [] }, groups: { data: [], }, lists: { data: [], }, journeys: { data: [] }, }
const DEFAULT_STATE = { loading: true, included: [], data: [{ attributes: {}, relationships }] } 
export default (state = { ...DEFAULT_STATE }, action) => {
  let included = state.included
  switch(action.type) {
    case 'RECEIVE_EMAIL': return { ...action.data, loading: false, next: action.next, previous: action.previous }
    case 'REQUEST_EMAIL': return { ...state, loading: true }
    case 'ADD_EMAILS_TO_LIST_REQUEST':
      included = state.included.filter(i => {
        if (i.type !== 'lists') return i
        return i.id != action.listId
      }).concat({ type: 'lists', id: action.listId })
      return { ...state, included }
    case 'DELETE_EMAILS_FROM_LIST_REQUEST':
      included = state.included.filter(i => {
        if (i.type !== 'lists') return i
        return i.id != action.listId
      })
      return { ...state, included }
    case 'FLUSH_EMAIL': return { ...DEFAULT_STATE }
    default: return state
  }
}

