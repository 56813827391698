import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { journeysSelectors as selectors } from '../../../modules/journeys';

import List from './List';

const JourneysList = props => <List {...props} />;

JourneysList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isJourneysListEmpty: PropTypes.bool.isRequired,
  links: PropTypes.shape({}).isRequired,
  loading: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    query: PropTypes.shape({}),
  }).isRequired,
  meta: PropTypes.shape({}).isRequired,
  updateQuery: PropTypes.func.isRequired,
};

const mapStateToProps = (state, { location, model }) => ({
  data: selectors.getData(model, location.query)(state),
  isJourneysListEmpty: selectors.isJourneysListEmpty(model, location.query)(state),
  links: selectors.getLinks(model, location.query)(state),
  meta: selectors.getMeta(model, location.query)(state),
});

export default connect(mapStateToProps)(JourneysList);
