import PropTypes from 'prop-types'

const ReactTooltipPropTypes = {
  afterHide: PropTypes.func,
  afterShow: PropTypes.func,
  border: PropTypes.bool,
  children: PropTypes.any,
  class: PropTypes.string,
  delayHide: PropTypes.number,
  delayShow: PropTypes.number,
  disable: PropTypes.bool,
  effect: PropTypes.string,
  event: PropTypes.string,
  eventOff: PropTypes.string,
  getContent: PropTypes.any,
  globalEventOff: PropTypes.string,
  html: PropTypes.bool,
  id: PropTypes.string,
  insecure: PropTypes.bool,
  isCapture: PropTypes.bool,
  multiline: PropTypes.bool,
  offset: PropTypes.object,
  place: PropTypes.string,
  resizeHide: PropTypes.bool,
  scrollHide: PropTypes.bool,
  theme: PropTypes.string,
  type: PropTypes.string,
  wrapper: PropTypes.string
}

const TooltipPropTypes = {
  ...ReactTooltipPropTypes,
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  style: PropTypes.any
}

export default TooltipPropTypes
