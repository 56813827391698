import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { userSelectors } from '../modules/user';

import Container from './Container';
import { UpgradeReports } from './Upgrade';

import ReportsHeader from './reports/Header';
import ReportsNavigation from './reports/ReportsNavigation';

const CompaniesReports = props => {
  const { isUserRestricted, model } = props;

  if (isUserRestricted) {
    return <UpgradeReports model={model} />;
  }

  if (!model) {
    return null;
  }

  return (
    <div className="pure-u-1">
      <div className="pure-u-1">
        <ReportsHeader model={model} />

        <ReportsNavigation {...props} model={model} relationship="segments" />

        <Container style={{ margin: 0, padding: 25, minHeight: 300 }}>
          {React.Children.map(props.children, child => React.cloneElement(child, { model }))}
        </Container>
      </div>
    </div>
  );
};

CompaniesReports.defaultProps = {
  model: null,
};

CompaniesReports.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]).isRequired,
  companies: PropTypes.shape({ data: PropTypes.array }).isRequired,
  isUserRestricted: PropTypes.bool.isRequired,
  model: PropTypes.shape({}),
};

const mapStateToProps = state => ({
  isUserRestricted: userSelectors.isUserRestrictedSelector(state),
  companies: state.companies,
  searches: state.searches,
});

export default connect(mapStateToProps)(CompaniesReports);
