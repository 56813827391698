/* eslint-disable react/prop-types */

import React from 'react';
import PropTypes from 'prop-types';
import Select, { Async } from 'react-select';

import ClearIndicator, { clearIndicatorStyles } from './reactSelect/ClearIndicator';
import DropdownIndicator, { dropdownIndicatorStyles } from './reactSelect/DropdownIndicator';
import IndicatorSeparator from './reactSelect/IndicatorSeparator';
import MultiValueRemove, { multiValueRemoveStyles } from './reactSelect/MultiValueRemove';
import controlStyles from './reactSelect/controlStyles';
import customTheme from './reactSelect/customTheme';
import disabledOptionStyles from './reactSelect/disabledOptionStyles';
import indicatorsContainerStyles from './reactSelect/indicatorsContainerStyles';
import multiValueLabelStyles from './reactSelect/multiValueLabelStyles';
import multiValueStyles from './reactSelect/multiValueStyles';
import menuStyles from './reactSelect/menuStyles';

const ReactSelect = ({ async, components, invalid, styles, ...props }) => {
  const customStyles = {
    clearIndicator: clearIndicatorStyles,
    control: controlStyles(invalid),
    dropdownIndicator: dropdownIndicatorStyles,
    indicatorsContainer: indicatorsContainerStyles,
    multiValue: multiValueStyles,
    multiValueLabel: multiValueLabelStyles,
    multiValueRemove: multiValueRemoveStyles,
    menu: menuStyles,
    ...(styles || {}),
  };

  if (!props.isSelectionAllowed) {
    customStyles.option = disabledOptionStyles;
  }

  const customComponents = {
    ClearIndicator,
    DropdownIndicator,
    IndicatorSeparator,
    MultiValueRemove,
    ...(components || {}),
  };

  const SelectComponent = async ? Async : Select;

  return (
    <SelectComponent
      {...props}
      theme={customTheme}
      components={customComponents}
      styles={customStyles}
    />
  );
};

ReactSelect.defaultProps = {
  async: false,
  invalid: false,
  isSelectionAllowed: true,
};

ReactSelect.propTypes = {
  async: PropTypes.bool,
  invalid: PropTypes.bool,
  isSelectionAllowed: PropTypes.bool,
};

export default ReactSelect;
