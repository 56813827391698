import React from 'react'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import api from '../lib/api'
import humanize from 'humanize-number'

import GroupsList from './GroupsList'
import ListsList from './ListsList'
import Header from './Header'
import Section from './Section'

import Container from './Container'
import ContactModalLink from './ContactModalLink';

class Browse extends React.Component {
  state = {
    loading: true,
  }
  fetchBrowse() {
    this.setState({ loading: true })
    api.get('/v2/browse')
    .then(resp => {
      let browse = resp.data
      this.setState({ loading: false, browse })
    })
  }
  componentDidMount() { this.fetchBrowse() }
  render() {
    return (
        <div className='pure-u-1'>
          {this.state.loading ? <p style={{ textAlign: 'center' }}>Loading ...</p> :
          <div>
            <div>
              <h3>Curated lists</h3>
              <ListsList router={this.props.router} lists={this.state.browse.included.filter(i => i.type == 'lists')} />
            </div>
            <div>
              <h3>Popular groups</h3>
              <GroupsList
                router={this.props.router}
                groups={this.state.browse.included.filter(i => i.type == 'groups')}
              />
            </div>
            <div className='pure-u-1' style={{ marginBottom: 25 }}>
              <div
                style={{ background: '#fff', padding: '25px 0', textAlign: 'center' }}
              >
                <h3>Still haven't found what you're looking for?</h3>
                <p>We'll climb the highest mountains and run through the fields &mdash; just for you.</p>
                <p>
                  <ContactModalLink
                    className='pure-button pure-button-primary'
                    style={{ borderRadius: 20, padding: '10px 40px' }}
                  >
                    Contact Support
                  </ContactModalLink>
                </p>
              </div>
            </div>
          </div>}
        </div>
    )
  }
}

export default connect()(Browse)
