import styled from "@emotion/styled";
import { Link as RouterLink } from 'react-router';

const Link = styled(RouterLink)(({ theme }) => ({
  alignItems: 'center',
  color: theme.colors.blue,
  display: 'inline-flex',
  fontFamily: theme.font.family,
  fontSize: '14px',
  fontWeight: '500',
  letterSpacing: '0.02em',
  lineHeight: '1.25em',
  textDecoration: 'none',
  '& > span': {
    color: theme.colors.dark1,
    display: 'inline-block',
    fontSize: '17px',
    lineHeight: '0',
  },
}));

export default Link;
