import React from 'react';
import PropTypes from 'prop-types';
import styled from "@emotion/styled";
import { connect } from 'react-redux';

import Container from './Container';
import JourneyViewToggle from './JourneyViewToggle';
import { getColorForEmail, palette } from '../lib/color';
import JourneyVisualization from './JourneyVisualization';
import Loader from './Loader';
import JourneyPickers from './JourneyPickers';

const styles = {
  container: {
    padding: 25,
    paddingBottom: 0,
    boxShadow: '0 4px 8px rgba(0,0,0,0.3)',
  },
  vizContainer: {
    width: 'calc(100% + 50px)', // Stretch to full container width
    minHeight: '40vh',
    marginLeft: -25,
    boxSizing: 'border-box',
    background: palette.grayF,
  },
  p: {
    textAlign: 'center',
    fontStyle: 'italic',
    fontSize: `${12 / 16}em`,
    color: palette.grayA,
  },
};


class JourneyDetails extends React.Component {
  state = {
    journeyView: 'grid',
    comparisonInProgress: false,
    comparisonInProgressModel: {
      companyId: '',
    },
  }

  // Get array of segment ID's from segment_id query parameter
  getSegmentIds = () => {
    const segmentId = this.props.router.location.query.segment_id;
    if (typeof segmentId === 'string') return [segmentId];
    if (Array.isArray(segmentId)) return segmentId;
    return [];
  };

  getComparedJourney = index => (index === 0 ? this.props.journeysWithDetails[1] || null : this.props.journeysWithDetails[0] || null);

  getJourney = journeyId => this.props.allJourneys.find(journey => journey.id === journeyId)

  getJourneyTypes(companyId) {
    const types = [];
    this.props.allJourneys.forEach((journey) => {
      const typeId = journey.attributes.journey_type;
      if (companyId === journey.attributes.company_id && !types.find(type => type.id === typeId)) {
        types.push({ id: typeId, label: journey.attributes.description });
      }
    });
    return types;
  }

  // Select the first journey to have the newly selected type
  handleJourneyTypeChange = (event, index) => {
    const journeyType = event.target.value;
    const comparedJourney = this.getComparedJourney(index);
    const newlySelectedJourney = this.props.allJourneys.find((journey) => {
      const isCompared = comparedJourney && comparedJourney.data[0].id === journey.id;
      return !isCompared && journey.attributes.journey_type === journeyType;
    });
    if (!newlySelectedJourney) return console.error(`No unique journeys found for journey type: ${journeyType}`);
    this.updateSelectedJourney(newlySelectedJourney, index);
  }

  handleJourneyTypeChangeForComparisonInProgress = (event, index) => {
    const journeyType = event.target.value;
    const comparedJourney = this.props.journeysWithDetails[0];
    const newlySelectedJourney = this.props.allJourneys.find((journey) => {
      const isNotCompared = comparedJourney && comparedJourney.data[0].id !== journey.id;
      return isNotCompared && journey.attributes.journey_type === journeyType;
    });
    if (!newlySelectedJourney) return console.error(`No unique journeys found for journey type: ${journeyType}`);
    this.updateSelectedJourney(newlySelectedJourney, index);
  }

  handleJourneyStartDateChange = (event, index) => {
    this.updateSelectedJourney(this.getJourney(event.target.value), index);
  }

  handleJourneyCompanyChange = (event, index) => {
    // If this is the compared journey, remove the existing journey
    // and restart the comparison selection
    if (index === 1) {
      if (this.props.journeysWithDetails.length > 1) {
        this.props.dispatch({ type: 'REMOVE_JOURNEY', id: this.props.journeysWithDetails[1].data[0].id });
      }
      this.setState({
        comparisonInProgress: true,
        comparisonInProgressModel: {
          companyId: parseInt(event.target.value, 10),
        },
      });
    } else {
    // Otherwise, select the first journey from the newly selected company
      const companyId = parseInt(event.target.value, 10);
      const comparedJourney = this.getComparedJourney(index);
      let newlySelectedJourney = null;
      if (comparedJourney) {
        newlySelectedJourney = this.props.allJourneys.find(journey => journey.attributes.company_id === companyId && (comparedJourney.id !== journey.id));
      } else {
        newlySelectedJourney = this.props.allJourneys.find(journey => journey.attributes.company_id === companyId);
      }
      if (!newlySelectedJourney) return console.error(`No unique journeys found for company: ${companyId}`);
      this.updateSelectedJourney(newlySelectedJourney, index);
    }
  }

  addComparison = () => {
    let companyId = '';
    // If the company picker isn't being used, there is only one company in play;
    // grab the company from the first journey
    if (!this.props.enableCompanyPicker) {
      companyId = this.props.journeysWithDetails[0].data[0].attributes.company_id;
    }
    this.setState({
      comparisonInProgress: true,
      comparisonInProgressModel: { companyId },
    });
  }

  removeComparison = () => {
    const query = { ...this.props.router.location.query };
    query.segment_id = [this.props.journeysWithDetails[0].data[0].id];
    this.props.router.push({ pathname: this.props.router.location.pathname, query });
    if (this.props.journeysWithDetails.length > 1) {
      this.props.dispatch({ type: 'REMOVE_JOURNEY', id: this.props.journeysWithDetails[1].data[0].id });
    }
    this.setState({ comparisonInProgress: false });
  }

  updateSelectedJourney(selectedJourney, index) {
    const query = { ...this.props.router.location.query };
    if (!selectedJourney.id) {
      delete query.segment_id;
    } else {
      const segmentIds = this.getSegmentIds();
      segmentIds[index] = selectedJourney.id;
      query.segment_id = segmentIds;
      this.fetchJourneyDetails(segmentIds);
    }
    this.props.router.push({ pathname: this.props.router.location.pathname, query });
    this.setState({ comparisonInProgress: false });
  }

  handleJourneyViewChange = (journeyView) => {
    this.setState({ journeyView });
  }

  fetchJourneyDetails(segmentIds) {
    if (segmentIds.length === 0) return;
    this.props.dispatch({ type: 'REQUEST_JOURNEYS_WITH_DETAILS', segmentIds });
  }

  // For a given selected Journey index, find which journeyTypes should be disabled
  getDisabledJourneyTypeIds(selectedJourneyIndex) {
    // Do nothing if not yet comparing
    if (this.props.journeysWithDetails.length <= 1 && !this.state.comparisonInProgress) return [];

    // Do nothing if companies compared are different (groups only)
    if (!this.state.comparisonInProgress &&
      (this.props.journeysWithDetails[0].data[0].attributes.company_id !==
      this.props.journeysWithDetails[1].data[0].attributes.company_id)) {
      return [];
    }

    const companyId = this.state.comparisonInProgress
      ? this.state.comparisonInProgressModel.companyId
      : this.props.journeysWithDetails[selectedJourneyIndex].data[0].attributes.company_id;

    // Disable a JourneyType IFF:
    // 1. Type contains only one journey AND
    // 2. That single journey is the comparedJourney
    const disabledTypes = this.getJourneyTypes(companyId)
      .filter((type) => {
        const journeysInType = this.props.allJourneys.filter(j => j.attributes.journey_type === type.id);
        if (journeysInType.length !== 1) return false;
        const comparedJourney = this.getComparedJourney(selectedJourneyIndex);
        return comparedJourney && comparedJourney.data[0].id === journeysInType[0].id;
      });

    return disabledTypes.map(type => type.id);
  }

  journeysForCurrentType(index) {
    return this.props.allJourneys.filter(journey => journey.attributes.journey_type === this.props.journeysWithDetails[index].data[0].attributes.journey_type);
  }


  render() {
    let restricted = false;
    if (this.props.journeysWithDetails && this.props.journeysWithDetails.length) {
      ({ restricted } = this.props.journeysWithDetails[0].meta);
    }

    const twoJourneyHeadersDisplayed = this.props.journeysWithDetails.length === 2 || this.state.comparisonInProgress;

    let content = (
      <div>
        <Container style={styles.container}>
          <Loader loading={this.props.loading}>
            <div style={{ minHeight: 400 }} />
          </Loader>
        </Container>
      </div>
    );

    let leftJourneyHeaderElem = null;
    if (this.props.journeysWithDetails.length > 0) {
      const leftJourney = this.props.journeysWithDetails[0].data[0];
      const index = 0;
      let comparedJourneyIds = [];
      if (this.props.journeysWithDetails.length > 1) {
        comparedJourneyIds = [this.props.journeysWithDetails[1].data[0].id];
      }
      leftJourneyHeaderElem = (
        <JourneyPickers
          index={index}
          loading={false}
          includeKeyLabel={twoJourneyHeadersDisplayed}
          keyLabelColor={getColorForEmail(leftJourney.attributes.description)}
          removeComparison={this.removeComparison}

          enableCompanyPicker={this.props.enableCompanyPicker}
          companies={this.props.companies}
          selectedCompanyId={leftJourney.attributes.company_id}
          handleJourneyCompanyChange={this.handleJourneyCompanyChange}

          journeyTypes={this.getJourneyTypes(this.props.journeysWithDetails[index].data[0].attributes.company_id)}
          selectedJourneyTypeId={leftJourney.attributes.journey_type}
          selectedJourneyColor={getColorForEmail(leftJourney.attributes.description)}
          disabledJourneyTypeIds={this.getDisabledJourneyTypeIds(index)}
          handleJourneyTypeChange={this.handleJourneyTypeChange}
          journeyTypeDisabled={false}

          journeysForStartDatePicker={this.journeysForCurrentType(index)}
          selectedJourneyId={leftJourney.id}
          disabledJourneyIds={comparedJourneyIds}
          handleJourneyStartDateChange={this.handleJourneyStartDateChange}
          journeyStartDateDisabled={false}

          selectedJourney={leftJourney}
          restricted={restricted}
        />
      );
    }

    let rightJourneyHeaderElem = null;
    if (this.props.journeysWithDetails.length === 2) {
      const rightJourney = this.props.journeysWithDetails[1].data[0];
      const index = 1;
      const comparedJourneyIds = [this.props.journeysWithDetails[0].data[0].id];
      rightJourneyHeaderElem = (
        <JourneyPickers
          index={index}
          loading={false}
          includeKeyLabel
          keyLabelColor={getColorForEmail(rightJourney.attributes.description)}
          removeComparison={this.removeComparison}

          enableCompanyPicker={this.props.enableCompanyPicker}
          companies={this.props.companies}
          selectedCompanyId={rightJourney.attributes.company_id}
          handleJourneyCompanyChange={this.handleJourneyCompanyChange}

          journeyTypes={this.getJourneyTypes(this.props.journeysWithDetails[index].data[0].attributes.company_id)}
          selectedJourneyTypeId={rightJourney.attributes.journey_type}
          selectedJourneyColor={getColorForEmail(rightJourney.attributes.description)}
          disabledJourneyTypeIds={this.getDisabledJourneyTypeIds(index)}
          handleJourneyTypeChange={this.handleJourneyTypeChange}
          journeyTypeDisabled={false}

          journeysForStartDatePicker={this.journeysForCurrentType(index)}
          selectedJourneyId={rightJourney.id}
          disabledJourneyIds={comparedJourneyIds}
          handleJourneyStartDateChange={this.handleJourneyStartDateChange}
          journeyStartDateDisabled={false}

          selectedJourney={rightJourney}
          restricted={restricted}
        />
      );
    }

    // Comparison in progress
    // 1. Use the comparisonInProgressModel to populate the header
    // 2. Use special change handlers
    else if (this.state.comparisonInProgress) {
      const index = 1;
      const comparedJourneyIds = [this.props.journeysWithDetails[0].id];
      rightJourneyHeaderElem = (
        <JourneyPickers
          index={index}
          loading={false}
          includeKeyLabel
          removeComparison={this.removeComparison}

          enableCompanyPicker={this.props.enableCompanyPicker}
          companies={this.props.companies}
          selectedCompanyId={this.state.comparisonInProgressModel.companyId}
          handleJourneyCompanyChange={this.handleJourneyCompanyChange}

          journeyTypes={this.state.comparisonInProgressModel.companyId
            ? this.getJourneyTypes(this.state.comparisonInProgressModel.companyId)
            : []}
          selectedJourneyTypeId=""
          selectedJourneyColor={null}
          disabledJourneyTypeIds={this.getDisabledJourneyTypeIds(index)}
          handleJourneyTypeChange={this.handleJourneyTypeChangeForComparisonInProgress}
          journeyTypeDisabled={this.state.comparisonInProgressModel.companyId.length === 0}

          journeysForStartDatePicker={[]}
          selectedJourneyId=""
          disabledJourneyIds={comparedJourneyIds}
          handleJourneyStartDateChange={this.handleJourneyStartDateChange}
          journeyStartDateDisabled

          selectedJourney={null}
          restricted={false}
        />
      );
    }

    const HeaderWrapper = styled("div")({
      display: 'flex',
      flexDirection: 'columns',
      justifyItems: 'space-between',
      opacity: this.props.loading ? 0 : 1,
      transition: 'opacity 0.3s',
    });

    if (!this.props.loading) {
      let addComparisonButton = null;
      if (this.props.enableComparison
        && this.props.journeysWithDetails
        && this.props.journeysWithDetails.length < 2) {
        const ComparisonButtonWrapper = styled("p")({
          margin: 0,
          fontSize: `${12 / 16}em`,
          textAlign: 'right',
        });
        addComparisonButton = (
          <ComparisonButtonWrapper>
            <button
              className="pure-button"
              onClick={this.addComparison}
            >
              + Compare another journey
            </button>
          </ComparisonButtonWrapper>
        );
      }

      const LeftJourneyHeader = styled("div")({
        boxSizing: 'border-box',
        width: '50%',
        paddingRight: '10%',
      });

      const RightJourneyHeader = styled("div")({
        boxSizing: 'border-box',
        width: twoJourneyHeadersDisplayed ? '50%' : '100%',
        paddingLeft: 30,
        paddingBottom: 20,
        paddingRight: twoJourneyHeadersDisplayed ? '10%' : 0,
        borderLeft: twoJourneyHeadersDisplayed ? `1px solid ${palette.grayD}` : '',
      });

      content = (
        <div>
          <Container style={styles.container}>
            <Loader loading={this.props.loading}>
              <HeaderWrapper>
                <LeftJourneyHeader>
                  {leftJourneyHeaderElem}
                </LeftJourneyHeader>
                <RightJourneyHeader>
                  {rightJourneyHeaderElem || addComparisonButton}
                </RightJourneyHeader>
              </HeaderWrapper>
              <div className="pure-u-1" style={{ padding: '30px 0', textAlign: 'center' }}>
                {this.props.loading
                  ? <p style={{ ...styles.p, minHeight: 87 }}> Loading Journey Stats... </p>
                  : <div>
                    {/* disabling until backend is ready! <JourneyStatsBar journey={this.props.selectedJourney}/> */}
                    <JourneyViewToggle
                      journeyView={this.state.journeyView}
                      handleJourneyViewChange={this.handleJourneyViewChange}
                    />
                    </div>
                }
              </div>
              <div style={styles.vizContainer}>
                <JourneyVisualization
                  loading={this.props.loading}
                  journeysWithDetails={this.props.journeysWithDetails}
                  journeyView={this.state.journeyView}
                />
              </div>
            </Loader>
          </Container>
        </div>
      );
    }
    return (
      <div>
        {content}
      </div>
    );
  }
}

JourneyDetails.defaultProps = {
  journeysWithDetails: [],
  companies: [],
  enableCompanyPicker: false,
};

JourneyDetails.propTypes = {
  loading: PropTypes.bool.isRequired,
  journeysWithDetails: PropTypes.arrayOf(PropTypes.object),
  allJourneys: PropTypes.arrayOf(PropTypes.object).isRequired,
  enableCompanyPicker: PropTypes.bool,
  enableComparison: PropTypes.bool.isRequired,
  companies: PropTypes.arrayOf(PropTypes.shape({})),
  router: PropTypes.shape({
    location: PropTypes.shape({
      query: PropTypes.shape({
        segment_id: PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
          PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
        ]),
      }),
      pathname: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
};

export default connect()(JourneyDetails);
