import React from 'react'

import api from '../../lib/api'

class GroupCompaniesLogos extends React.Component {
  state = {
    loading: true,
    logos: [],
    error: null
  }
  handleClick = (event) => {
    event.preventDefault()
    if (this.props.handleClick)
      this.props.handleClick({ group: this.props.group, included: this.state.included })
  }
  componentDidMount() {
    let loading = false
    api.get(`/v2/groups/${this.props.group.id}`)
      .then(r => {
        let logos = r.data.included.filter(i => i.type == 'companies')
          .slice(0, 3).map(c => {
           return  `//cache.mailcharts.com/logos/v2/companies/${c.id}.png`
          })
        let included = r.data.included.filter(i => i.type == 'companies')
          .some(i => i.id == this.props.company_id)
        this.setState({ loading, logos, included })
      })
      .catch(error => {
        this.setState({ loading, error })
      })
  }
  render() {
    return this.state.loading ? null : (
      <div className='pure-u-1' style={{ background: '#F3F5F7', padding: '5px 0', cursor: 'pointer' }} onClick={this.handleClick}>
        <div className='pure-u-1-3'>
          <div style={{ position: 'relative', height: 50, }}>
          {this.state.logos.map((logo_src, idx) => {
          return (
            <div
              key={idx}
              style={{
                background: '#fff',
                width: 50,
                height: 50,
                display: 'inline-block',
                position: 'absolute',
                top: 0,
                left: (idx * 35),
              }}
            >
            <img src={logo_src} style={{ position: 'absolute', display: 'inline-block', }} className='pure-img' />
          </div>
          )
          })}
        </div>
        </div>
        <div className='pure-u-1-3'>
          <p style={{ fontWeight: 'bold', margin: 0, fontSize: `${14/16}em`, }}>{this.props.group.attributes.name}</p>
          <p style={{ margin: 0, fontSize: `${14/16}em`, }}>{this.props.group.attributes.description || 'No description'}</p>
        </div>
        <div className='pure-u-1-3' style={{ textAlign: 'right' }}>
          {this.state.included ?
            <img src='/added.png' width='32'/>
          :
            <img src='/add.png' width='32'/>
          }
        </div>
      </div>
    )
  }
}

export default GroupCompaniesLogos
