import moment from 'moment';
import { call, put, select } from 'redux-saga/effects';
import { isEmpty } from 'ramda';

import * as actions from './actions';
import * as api from './api';
import * as selectors from './selectors';

export function* fetchBrandData({ modelId: brandId, startDate }) {
  const date = moment(startDate).format('YYYY-MM-DD');

  try {
    const brandData = yield select(selectors.getBrandData(brandId, date));

    if (isEmpty(brandData)) {
      const response = yield call(api.fetchData, 'brands', brandId, date);

      yield put(actions.fetchBrandDataSuccess(brandId, date, response.meta));
    } else {
      yield put(actions.toggleBrandLoading(brandId, date));
    }
  } catch (error) {
    console.log('error', error);
    yield put(actions.fetchGroupDataFailure(brandId, date, error));
  }
}

export function* fetchBrandModalData({
  modelId: brandId,
  firstDayOfMonth: fDOM,
  date: unformattedDate,
}) {
  const date = moment(unformattedDate).format('YYYY-MM-DD');
  const firstDayOfMonth = moment(fDOM)
    .startOf('month')
    .format('YYYY-MM-DD');

  try {
    const modalData = yield select(selectors.getBrandModalData(brandId, firstDayOfMonth, date));

    if (isEmpty(modalData)) {
      const response = yield call(api.fetchModalData, 'brands', brandId, date);
      const payload = response.data.data ? response.data.data[0] : {};

      yield put(actions.fetchBrandModalDataSuccess(brandId, firstDayOfMonth, date, payload));
    } else {
      yield put(actions.toggleBrandModalLoading(brandId, firstDayOfMonth, date));
    }
  } catch (error) {
    console.log('error', error);
    yield put(actions.fetchBrandModalDataFailure(brandId, firstDayOfMonth, date, error));
  }
}

export function* fetchGroupData({ modelId: groupId, startDate }) {
  const date = moment(startDate).format('YYYY-MM-DD');

  try {
    const groupData = yield select(selectors.getGroupData(groupId, date));

    if (isEmpty(groupData)) {
      const response = yield call(api.fetchData, 'groups', groupId, date);

      yield put(actions.fetchGroupDataSuccess(groupId, date, response.meta));
    } else {
      yield put(actions.toggleGroupLoading(groupId, date));
    }
  } catch (error) {
    console.log('error', error);
    yield put(actions.fetchGroupDataFailure(groupId, date, error));
  }
}

export function* fetchGroupModalData({
  modelId: groupId,
  firstDayOfMonth: fDOM,
  date: unformattedDate,
}) {
  const date = moment(unformattedDate).format('YYYY-MM-DD');
  const firstDayOfMonth = moment(fDOM)
    .startOf('month')
    .format('YYYY-MM-DD');

  try {
    const modalData = yield select(selectors.getGroupModalData(groupId, firstDayOfMonth, date));

    if (isEmpty(modalData)) {
      const response = yield call(api.fetchModalData, 'groups', groupId, date);
      const payload = response.data.data ? response.data.data[0] : {};

      yield put(actions.fetchGroupModalDataSuccess(groupId, firstDayOfMonth, date, payload));
    } else {
      yield put(actions.toggleGroupModalLoading(groupId, firstDayOfMonth, date));
    }
  } catch (error) {
    console.log('error', error);
    yield put(actions.fetchGroupModalDataFailure(groupId, firstDayOfMonth, date, error));
  }
}
