import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import BrandLogo from '../ui/BrandLogo';
import { Box, Flex, Heading, Link, Text } from '../mc-ui';
import AddToGroupButton from '../AddToGroupButton';

export default function EmailInfo({ email }) {
  const [model] = email.data;
  const [brand] = (((model || {}).relationships || {}).companies || { data: [] }).data;
  const isLoading = (model || {}).loading || !brand;

  if (isLoading) return <Box height="140px" />;

  return (
    <Box
      borderColor="dark5"
      borderBottom="1px solid"
      height="140px"
      marginBottom="16px"
      paddingBottom="24px"
    >
      <Heading
        as="h3"
        lineHeight="1.32em"
        css={{
          marginBottom: '4px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          width: '733px',
        }}
      >
        {model.attributes.subject}
      </Heading>
      <Text color="dark08" css={{ marginBottom: '24px' }}>
        {model.attributes.preheader}
      </Text>

      <Flex>
        <Flex alignItems="center" width="50%">
          <BrandLogo brandId={brand.id} css={{ marginRight: '16px' }} />

          <Flex direction="column">
            <Link
              to={`/companies/${brand.id}`}
              css={{ fontSize: '14px', lineHeight: '16px', marginBottom: '4px' }}
            >
              {brand.attributes.name}
            </Link>

            <AddToGroupButton
              brands={[brand]}
              variant="secondary"
              withOverlay
              css={{
                width: '96px',
                '& > button': {
                  display: 'inline-flex',
                  fontSize: '12px',
                  height: '24px',
                  lineHeight: '24px',
                  width: '96px !important',
                },
              }}
            />
          </Flex>
        </Flex>

        <Box width="50%">
          <Text fontSize="12px" textAlign="right">
            {moment(model.attributes.sent_at).format('dddd, MMMM DD, YYYY')}
            <br />@{moment(model.attributes.sent_at).format('h:mm a')}
          </Text>
        </Box>
      </Flex>
    </Box>
  );
}

EmailInfo.propTypes = {
  email: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        attributes: PropTypes.shape({
          preheader: PropTypes.string,
          sent_at: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          subject: PropTypes.string,
        }),
        companies: PropTypes.shape({
          data: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string,
              attributes: PropTypes.shape({
                name: PropTypes.string,
              }),
            }),
          ),
        }),
      }),
    ),
    loading: PropTypes.bool,
  }).isRequired,
};
