/* eslint-disable react/prop-types */

import React, { PureComponent } from 'react';
import ReactSelect from '../overrides/ReactSelect';

import Error from './Error';

const flatten = options => {
  let result = [];

  options.forEach(option => {
    if (option.options) {
      result = [].concat(result, option.options);
    } else {
      result = [].concat(result, [option]);
    }
  });

  return result;
};

class MultiSelect extends PureComponent {
  handleChange = selected => {
    const values = selected.map(s => s.value);
    const { input, isSelectionAllowed, limit, ...rest } = this.props;

    if (!isSelectionAllowed || (limit && values.length > limit)) {
      return;
    }

    if (rest.onChange) {
      rest.onChange(values);
    }

    input.onChange(values);
    input.onBlur(selected);
  };

  render() {
    const {
      options,
      input,
      input: { value },
      meta,
      hideErrorMessage,
      limit,
      noOptionsMessage,
      ...rest
    } = this.props;
    const selectedValues = flatten(options).filter(o => value.includes(o.value));

    return (
      <div>
        <ReactSelect
          {...input}
          {...rest}
          options={options}
          onChange={this.handleChange}
          value={selectedValues}
          closeMenuOnSelect={false}
          isMulti
          isSearchable
        />
        {!hideErrorMessage && (
          <Error error={meta.error || meta.submitError} touched={meta.touched} />
        )}
      </div>
    );
  }
}

MultiSelect.defaultProps = {
  isSelectionAllowed: true,
  limit: null,
};

export default MultiSelect;
