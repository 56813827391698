import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

class ReportsNavigation extends React.Component {
  state = {
    sticky: false,
  };

  render() {
    const { router } = this.props;

    const { sticky } = this.state;

    let active = 'overview';
    active = router.location.pathname.split('/').pop();
    if (
      router.params.company_id === active ||
      router.params.group_id === active ||
      router.location.pathname === '/'
    ) {
      active = 'overview'; // root
    }

    const route = router.routes[router.routes.length - 1];
    let { pathname } = router.location;
    if (route.path) pathname = pathname.replace(`/${route.path}`, '');
    const demo = /demo/.test(pathname);
    if (pathname === '/') {
      pathname = '/dashboard';
    } else if (demo) {
      pathname = '/demo/reports';
    } else {
      pathname = pathname.replace(/\/(reports|behavior|content|promotions-report|score)$/, '');
    }

    return (
      <div
        ref={(i) => {
          this.div = i;
        }}
      >
        <div
          className="pure-u-1"
          style={
            sticky
              ? {
                  position: 'fixed',
                  top: 0,
                  maxWidth: 756,
                  background: 'rgb(243, 245, 247)',
                }
              : { padding: 0 }
          }
        >
          <div className="pure-u-1" style={{ paddingTop: '1em' }}>
            <div className="pure-menu pure-menu-horizontal">
              <ul className="pure-menu-list">
                <li className="pure-menu-item">
                  <Link
                    to={{
                      pathname: `${pathname}/reports`,
                      query: router.location.query,
                    }}
                    style={{
                      fontSize: `${13 / 16}em`,
                      background: active === 'reports' ? '#fff' : null,
                      borderRadius: active === 'reports' ? 2 : 0,
                    }}
                    className="pure-menu-link"
                  >
                    Sending Behavior
                  </Link>
                </li>
                <li className="pure-menu-item">
                  <Link
                    to={{
                      pathname: `${pathname}/content`,
                      query: router.location.query,
                    }}
                    style={{
                      fontSize: `${13 / 16}em`,
                      background: active === 'content' ? '#fff' : null,
                      borderRadius: active === 'content' ? 2 : 0,
                    }}
                    className="pure-menu-link"
                  >
                    Content analysis
                  </Link>
                </li>
                <li className="pure-menu-item">
                  <Link
                    to={{
                      pathname: `${pathname}/promotions-report`,
                      query: router.location.query,
                    }}
                    style={{
                      fontSize: `${13 / 16}em`,
                      background: active === 'promotions-report' ? '#fff' : null,
                      borderRadius: active === 'promotions-report' ? 2 : 0,
                    }}
                    className="pure-menu-link"
                  >
                    Promotions
                  </Link>
                </li>
                <li className="pure-menu-item">
                  <Link
                    to={{
                      pathname: `${pathname}/score`,
                      query: router.location.query,
                    }}
                    style={{
                      fontSize: `${13 / 16}em`,
                      background: active === 'score' ? '#fff' : null,
                      borderRadius: active === 'score' ? 2 : 0,
                    }}
                    className="pure-menu-link"
                  >
                    Email score
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ReportsNavigation.propTypes = {
  router: PropTypes.shape({}).isRequired,
};

export default ReportsNavigation;
