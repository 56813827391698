import React from 'react';

const SvgAgencies = props => (
  <svg width="1em" height="1em" viewBox="0 0 132 98" fill="none" {...props}>
    <path
      d="M25.45 35.19a1.93 1.93 0 100-3.86 1.93 1.93 0 000 3.86z"
      fill="#fff"
      stroke="#A1AEBA"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M126.52 32.33a3 3 0 100-6 3 3 0 000 6z"
      fill="#fff"
      stroke="#E99635"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.52 72.33a3 3 0 100-6 3 3 0 000 6z"
      fill="#fff"
      stroke="#9E6ACC"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.03 19.07c-2.25-2.32-2.56-6.32-2.56-6.32s-.35 4-2.6 6.32c-.97 1-2.01 1.37-2.01 1.37s1.04.37 2.01 1.37c2.25 2.32 2.6 6.32 2.6 6.32s.31-4 2.56-6.32c.97-1 2.02-1.37 2.02-1.37S9 20.07 8.03 19.07zM90.07 3.72C88.75 2.36 88.56 0 88.56 0s-.21 2.36-1.53 3.72c-.57.59-1.19.81-1.19.81s.61.22 1.19.81c1.33 1.36 1.53 3.72 1.53 3.72s.18-2.36 1.51-3.72c.57-.59 1.19-.81 1.19-.81s-.61-.22-1.19-.81z"
      fill="#027AD7"
    />
    <path
      d="M89.92 85.8H33.81c-2.1 0-3.81-1.7-3.81-3.81V51.55h63.72V82c0 2.1-1.7 3.8-3.8 3.8z"
      fill="#fff"
      stroke="#027AD7"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M93.44 49.36L61.86 25.24 30.28 49.36l1.94 15.92 31.81 10.61 25.64-12.04 3.77-14.49z"
      fill="#D7EEFF"
      stroke="#027AD7"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M89.71 21.4s7.15-.86 7.15 9.05H82.53s-4.83-8.85 7.18-9.05z"
      fill="#EBEBEB"
      stroke="#A1AEBA"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M85.14 71.06H38.87V27.18c0-4.5 1.83-5.79 5.3-5.79h45.54s-5.01.8-5.01 6.59.44 43.08.44 43.08z"
      fill="#fff"
      stroke="#A1AEBA"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M48.96 41.87h24.97M67.82 55.77h6.11M48.96 35.1h24.97M48.96 28.74h24.97"
      stroke="#D7DADE"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M73.93 48.89h-1.57c-.73 0-1.44-.21-1.99-.59l-1.24-.86c-1.1-.77-2.78-.72-3.81.11l-8.18 6.54c-1.15.92-3.08.85-4.12-.15l-2.12-2.03c-.57-.55-1.42-.86-2.32-.86"
      stroke="#80C963"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30 81.97V49.51c0-.14.16-.23.28-.15L54.51 65c3.47 2.24 3.41 7.32-.09 9.49"
      fill="#fff"
    />
    <path
      d="M30 81.97V49.51c0-.14.16-.23.28-.15L54.51 65c3.47 2.24 3.41 7.32-.09 9.49"
      stroke="#027AD7"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M93.72 81.97V49.51c0-.14-.16-.23-.28-.15L70.59 64.12c-4.1 2.65-4.04 8.67.11 11.24"
      fill="#fff"
    />
    <path
      d="M93.72 81.97V49.51c0-.14-.16-.23-.28-.15L70.59 64.12c-4.1 2.65-4.04 8.67.11 11.24"
      stroke="#027AD7"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M31.24 84.14l26.32-22.66c1.45-1.2 2.34-1.81 3.83-1.81h2.33c1.54 0 2.42.64 3.92 1.93l25.58 22.54"
      fill="#fff"
    />
    <path
      d="M31.24 84.14l26.32-22.66c1.45-1.2 2.34-1.81 3.83-1.81h2.33c1.54 0 2.42.64 3.92 1.93l25.58 22.54"
      stroke="#027AD7"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M119.25 57.402v-4.724M111.457 59l-1.923-4.313M127.403 59.007l1.916-4.32"
      stroke="#48DDCF"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M99.83 71.78H87.16l-8.45 8.6h29.27l-8.15-8.6zM78.71 80.38L93.34 96.9l14.64-16.52H78.71z"
      fill="#F3FFED"
      stroke="#80C963"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M93.34 96.9l-6.41-16.52 3.94-8.6M93.34 96.9l6.42-16.52-3.95-8.6"
      stroke="#80C963"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M129.82 77.48l-6.24-6.95-8.89-.4 14.43 16.05.7-8.7z"
      fill="#F3FFED"
      stroke="#48DDCF"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M114.69 70.13l-1.84 16.17 16.27-.12-14.43-16.05z"
      fill="#F3FFED"
      stroke="#48DDCF"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M112.85 86.3l5.89-11.66 6.67-2.08M112.85 86.3l12.22-4.62 2.77-6.41"
      stroke="#48DDCF"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgAgencies;
