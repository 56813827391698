/** @jsx jsx */
import { jsx } from '@emotion/react';
import React from 'react';
import PropTypes from 'prop-types';

const Head = ({ children, css: customCSS, style }) => (
  <thead
    css={{
      display: 'table-header-group',
      ...customCSS,
      ...style,
    }}
  >
    {React.Children.map(children, child => React.cloneElement(child, { header: true }))}
  </thead>
);

Head.defaultProps = {
  css: {},
  style: {},
};

Head.propTypes = {
  children: PropTypes.node.isRequired,
  css: PropTypes.shape({}),
  style: PropTypes.shape({}),
};

export default Head;
