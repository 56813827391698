import React from 'react';

import ScrollableList from '../ui/ScrollableList';

import { fetchFutureJourneys } from './utils/api';

class FutureJourneys extends React.PureComponent {
  state = {
    loading: true,
    journeys: [],
  };

  componentDidMount() {
    this.request();
  }

  request = async () => {
    const journeys = await fetchFutureJourneys();
    this.setState(() => ({
      journeys,
      loading: false,
    }));
  };

  render() {
    const { loading, journeys } = this.state;
    return <ScrollableList collection={journeys} loading={loading} />;
  }
}

export default FutureJourneys;
