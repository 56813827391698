import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { connect } from 'react-redux';

import { userSelectors } from '../../../modules/user';

import { Button, Flex, Heading } from '../../mc-ui';

import CalendarIcon from './CalendarIcon';
import EmailIcon from './EmailIcon';

const UpgradeToPro = ({ isUserRestricted, link, type }) => {
  if (!isUserRestricted) return null;

  let Icon;
  let text;

  if (type === 'journeyEmails') {
    Icon = EmailIcon;
    text = 'Upgrade to unlock access to view journey emails from all of our brands.';
  } else if (type === 'historicalData') {
    Icon = CalendarIcon;
    text = 'Upgrade to view unlimited historical data and plan for holidays year round.';
  }

  return (
    <Flex
      alignItems="center"
      backgroundColor="white"
      borderRadius="4px"
      height="80px"
      justifyContent="space-between"
      padding="0 24px 0 30px"
    >
      <Flex alignItems="center">
        <Icon />

        <Heading
          as="h4"
          css={{
            fontSize: '16px',
            letterSpacing: '0.02em',
            lineHeight: '1.25.em',
            marginLeft: '24px',
          }}
        >
          {text}
        </Heading>
      </Flex>

      <Button
        as={Link}
        to={link.to}
        variant="purple"
        css={{
          alignItems: 'center',
          display: 'inline-flex',
          height: '32px',
          justifyContent: 'center',
          padding: '0',
          width: '130px',
        }}
      >
        {link.text}
      </Button>
    </Flex>
  );
};

UpgradeToPro.defaultProps = {
  link: {
    text: 'Upgrade to pro',
    to: '/manage/plan',
  },
};

UpgradeToPro.propTypes = {
  isUserRestricted: PropTypes.bool.isRequired,
  link: PropTypes.shape({
    text: PropTypes.string,
    to: PropTypes.string,
  }),
  type: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  isUserRestricted: userSelectors.isUserRestrictedSelector(state),
});

export default connect(mapStateToProps)(UpgradeToPro);
