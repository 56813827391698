import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';

import { timeSeriesSelectors } from '../../../../modules/newReports/timeSeries';

import { Flex } from '../../../mc-ui';

import Stats from '../../Stats';

const calcDiff = (originalVal, newVal) => {
  const diff = newVal - originalVal;
  return (diff / originalVal) * 100;
};

const formatDateRange = (startDate, endDate) =>
  `${moment(startDate).format('MM/DD/YYYY')} - ${moment(endDate).format('MM/DD/YYYY')}`;

const frequencyValues = (chart, data) => {
  const avgWeek = data.current[chart].value || 0;
  const previousPeriod = data.previous[chart].value || 0;
  const lastYear = data.yoy[chart].value || 0;
  const previousPeriodComparison = calcDiff(previousPeriod, avgWeek);
  const lastYearComparison = calcDiff(lastYear, avgWeek);

  return {
    avgWeek: avgWeek.toFixed(2),
    previousPeriod: previousPeriod.toFixed(2),
    lastYear: lastYear.toFixed(2),
    previousPeriodComparison,
    lastYearComparison,
  };
};

const promotionalValues = (chart, data) => {
  const avgWeek = data.current[chart].value * 100 || 0;
  const previousPeriod = data.previous[chart].value * 100 || 0;
  const lastYear = data.yoy[chart].value * 100 || 0;
  const previousPeriodComparison = calcDiff(previousPeriod, avgWeek);
  const lastYearComparison = calcDiff(lastYear, avgWeek);

  return {
    avgWeek: `${avgWeek.toFixed(2)}%`,
    previousPeriod: `${previousPeriod.toFixed(2)}%`,
    lastYear: `${lastYear.toFixed(2)}%`,
    previousPeriodComparison,
    lastYearComparison,
  };
};

const subjectLineLengthValues = (chart, data) => {
  const avgWeek = data.current[chart].value || 0;
  const previousPeriod = data.previous[chart].value || 0;
  const lastYear = data.yoy[chart].value || 0;
  const previousPeriodComparison = calcDiff(previousPeriod, avgWeek);
  const lastYearComparison = calcDiff(lastYear, avgWeek);

  return {
    avgWeek: Math.round(avgWeek),
    previousPeriod: Math.round(previousPeriod),
    lastYear: Math.round(lastYear),
    previousPeriodComparison,
    lastYearComparison,
  };
};

const valuesFunctions = {
  frequency: frequencyValues,
  promotional: promotionalValues,
  subject_line_length: subjectLineLengthValues,
};

const valueSuffix = chart => {
  if (chart === 'subject_line_length') return 'characters';
  return null;
};

const OverviewStats = ({ chart, data, filter, loading }) => {
  if (loading) return <Flex height="73px" />;

  const {
    avgWeek,
    previousPeriod,
    lastYear,
    previousPeriodComparison,
    lastYearComparison,
  } = valuesFunctions[chart](chart, data);

  return (
    <Stats>
      <Stats.Item
        title={`AVG / ${filter}`}
        subtitle={formatDateRange(
          data.current.interval.meta.start_date,
          data.current.interval.meta.end_date,
        )}
        value={avgWeek}
        valueSuffix={valueSuffix(chart)}
      />
      <Stats.Item
        title="Previous period"
        subtitle={formatDateRange(
          data.previous.interval.meta.start_date,
          data.previous.interval.meta.end_date,
        )}
        value={previousPeriod}
        valueSuffix={valueSuffix(chart)}
        comparison={previousPeriodComparison}
      />
      <Stats.Item
        title="Last year"
        subtitle={formatDateRange(
          data.yoy.interval.meta.start_date,
          data.yoy.interval.meta.end_date,
        )}
        value={lastYear}
        valueSuffix={valueSuffix(chart)}
        comparison={lastYearComparison}
      />
    </Stats>
  );
};

OverviewStats.propTypes = {
  chart: PropTypes.string.isRequired,
  data: PropTypes.shape({}).isRequired,
  filter: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, { chart, filter, model }) => {
  const { endDate, startDate } = state.newReports.dates;

  const timeSeriesData = timeSeriesSelectors.getTimeSeriesData(
    model.id,
    model.type,
    chart,
    filter,
    startDate,
    endDate,
  )(state);

  const timeSeriesLoading = timeSeriesSelectors.getTimeSeriesLoading(
    model.id,
    model.type,
    chart,
    filter,
    startDate,
    endDate,
  )(state);

  return {
    data: timeSeriesData,
    loading: timeSeriesLoading,
  };
};

export default connect(mapStateToProps)(OverviewStats);
