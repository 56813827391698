import React from 'react';
import PropTypes from 'prop-types';

import { Flex } from '../mc-ui';

const Body = ({ children }) => (
  <Flex direction="column" css={{ width: '100%' }} className="report-body">
    {children}
  </Flex>
);

Body.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Body;
