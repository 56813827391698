import React from 'react';
import PropTypes from 'prop-types';
import { Heading as MCUIHeading } from '../../mc-ui';

const Heading = ({ css, ...props }) => (
  <MCUIHeading
    as="h5"
    color="dark06"
    css={{
      fontSize: '10px',
      fontWeight: 'bold',
      letterSpacing: '0.12em',
      lineHeight: '1.56em',
      textTransform: 'uppercase',
      ...css,
    }}
    {...props}
  />
);

Heading.defaultProps = { css: {} };
Heading.propTypes = { css: PropTypes.shape({}) };

export default Heading;
