import styled from "@emotion/styled";
import { Link as RRLink } from 'react-router';

export const Button = styled("button")(() => ({
  background: 'transparent',
  border: 'none',
  cursor: 'pointer',
  display: 'inline-flex',
  margin: 0,
  padding: 0,
}));

export const Link = styled(RRLink)(({ theme }) => ({
  color: theme.colors.dark1,
  margin: 0,
  padding: 0,
  textDecoration: 'none',
}));
