const prefix = 'mc/newReports/sendingBehavior';

export const FETCH_DATA_REQUEST = `${prefix}/FETCH_DATA_REQUEST`;
export const FETCH_DATA_SUCCESS = `${prefix}/FETCH_DATA_SUCCESS`;
export const FETCH_DATA_FAILURE = `${prefix}/FETCH_DATA_FAILURE`;
export const TOGGLE_DATA_LOADING = `${prefix}/TOGGLE_DATA_LOADING`;

export const FETCH_PAGINATION_DAYS_DATA_REQUEST = `${prefix}/FETCH_PAGINATION_DAYS_DATA_REQUEST`;
export const FETCH_PAGINATION_DAYS_DATA_SUCCESS = `${prefix}/FETCH_PAGINATION_DAYS_DATA_SUCCESS`;
export const FETCH_PAGINATION_DAYS_DATA_FAILURE = `${prefix}/FETCH_PAGINATION_DAYS_DATA_FAILURE`;

export const FETCH_PAGINATION_HOURS_DATA_REQUEST = `${prefix}/FETCH_PAGINATION_DATA_REQUEST`;
export const FETCH_PAGINATION_HOURS_DATA_SUCCESS = `${prefix}/FETCH_PAGINATION_DATA_SUCCESS`;
export const FETCH_PAGINATION_HOURS_DATA_FAILURE = `${prefix}/FETCH_PAGINATION_DATA_FAILURE`;

export const SORT_DAYS_DATA_REQUEST = `${prefix}/SORT_DAYS_DATA_REQUEST`;
export const SORT_DAYS_DATA_SUCCESS = `${prefix}/SORT_DAYS_DATA_SUCCESS`;

export const SORT_HOURS_DATA_REQUEST = `${prefix}/SORT_DATA_REQUEST`;
export const SORT_HOURS_DATA_SUCCESS = `${prefix}/SORT_DATA_SUCCESS`;
