import styled from "@emotion/styled";

const EmptyListDescription = styled("div")({
  '& h3, & h4': {
    margin: '22px 0 0 0',
    textAlign: 'center',
  },
  '& p': {
    color: '#656565',
    fontSize: '14px',
    lineHeight: '20px',
    margin: '4px 0 0 0',
    textAlign: 'center',
  },
});

export default EmptyListDescription;
