import { assoc, assocPath } from 'ramda';

import * as types from './types';

export const INITIAL_STATE = {
  teamFilters: {
    loading: false,
  },
  userFilters: {
    loading: false,
  },
  teamFilterIds: [],
  userFilterIds: [],
};

const setTeamFiltersLoadingReducer = state => assocPath(['teamFilters', 'loading'], true, state);
const setUserFiltersLoadingReducer = state => assocPath(['userFilters', 'loading'], true, state);

const setTeamFiltersPayloadReducer = (state, action) => {
  const newState = assoc('teamFilters', { ...action.data, loading: false }, state);
  return assoc('teamFilterIds', ((action.data || {}).data || []).map(l => l.id), newState);
};

const setUserFiltersPayloadReducer = (state, action) => {
  const newState = assoc('userFilters', { ...action.data, loading: false }, state);
  return assoc('userFilterIds', action.data.data.map(l => l.id), newState);
};

const reducer = {
  [types.FETCH_TEAM_FILTERS_REQUEST]: setTeamFiltersLoadingReducer,
  [types.FETCH_TEAM_FILTERS_SUCCESS]: setTeamFiltersPayloadReducer,
  [types.FETCH_USER_FILTERS_REQUEST]: setUserFiltersLoadingReducer,
  [types.FETCH_USER_FILTERS_SUCCESS]: setUserFiltersPayloadReducer,
  [types.CREATE_TEAM_FILTER_SUCCESS]: setTeamFiltersPayloadReducer,
  [types.CREATE_USER_FILTER_SUCCESS]: setUserFiltersPayloadReducer,
  [types.DELETE_TEAM_FILTER_SUCCESS]: setTeamFiltersPayloadReducer,
  [types.DELETE_USER_FILTER_SUCCESS]: setUserFiltersPayloadReducer,
  [types.UPDATE_TEAM_FILTER_SUCCESS]: setTeamFiltersPayloadReducer,
  [types.UPDATE_USER_FILTER_SUCCESS]: setUserFiltersPayloadReducer,
};

export default (state = INITIAL_STATE, action = {}) => {
  const reducerFunction = reducer[action.type];

  if (!reducerFunction) return state;
  return reducerFunction(state, action);
};
