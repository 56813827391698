import React from 'react';

import { Heading } from '../mc-ui';

const Title = props => {
  const css = {
    fontSize: '24px',
    fontWeight: '300',
    lineHeight: '32px',
  };

  return <Heading as="h3" color="dark1" css={css} {...props} />;
};

export default Title;
