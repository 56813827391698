import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { teamsSelectors } from '../../../modules/teams';
import { Box, Button, Flex, Heading, Text, theme } from '../../mc-ui';
import WarningCircleIcon from '../../mc-ui/icons/WarningCircleIcon';
import UpgradeModal from './UpgradeModal';

class OldPlanNotice extends React.Component {
  state = {
    isModalOpen: false,
  };

  toggleModal = event => {
    if (event) event.preventDefault();

    this.setState(currentState => ({ isModalOpen: !currentState.isModalOpen }));
  };

  render() {
    const { isAnOldPlan, isCancelingSubscription, isOwner, limitReached, team } = this.props;

    if (!team || isCancelingSubscription || !isOwner || !isAnOldPlan) return null;

    const { isModalOpen } = this.state;
    const { member_limit: memberLimit } = team.attributes;

    return (
      <Flex
        alignItems="flex-start"
        backgroundColor="white"
        border="1px solid"
        borderColor="yellowMedium"
        borderRadius="4px"
        padding="24px"
      >
        <WarningCircleIcon color={theme.colors.yellowDark} />

        <Box flex="1" margin="0 16px" width="unset">
          <Heading as="h3" lineHeight={`${24 / 18}em`} css={{ marginBottom: '8px' }}>
            {limitReached ? `Notice! You are subscribed to a grandfathered plan.` : `Notice`}
          </Heading>

          {limitReached ? (
            <Text>
              You’ve reached your team member limit of <strong>{memberLimit}</strong>. Migrate to a
              new subscription to add more team members.
            </Text>
          ) : (
            <Text>You are on a grandfathered plan no longer offered by MailCharts.</Text>
          )}
        </Box>

        <Button
          css={{ height: '32px', lineHeight: '32px', margin: '0', padding: '0', width: '80px' }}
          onClick={this.toggleModal}
          variant="primary"
        >
          Migrate
        </Button>

        <UpgradeModal closeModal={this.toggleModal} isOpen={isModalOpen} />
      </Flex>
    );
  }
}

OldPlanNotice.defaultProps = {
  limitReached: false,
  team: null,
};

OldPlanNotice.propTypes = {
  isAnOldPlan: PropTypes.bool.isRequired,
  isCancelingSubscription: PropTypes.bool.isRequired,
  isOwner: PropTypes.bool.isRequired,
  limitReached: PropTypes.bool,
  team: PropTypes.shape({
    attributes: PropTypes.shape({
      current_period_end_at: PropTypes.string,
    }),
  }),
};

const mapStateToProps = state => ({
  isAnOldPlan: teamsSelectors.isAnOldPlanSelector(state),
  isCancelingSubscription: teamsSelectors.isCancelingSubscriptionSelector(state),
  isOwner: teamsSelectors.isOwnerSelector(state),
  team: teamsSelectors.teamSelector(state),
});

export default connect(mapStateToProps)(OldPlanNotice);
