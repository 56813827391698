import React from 'react';
import PropTypes from 'prop-types';

import { Box, Flex, Text, Tooltip } from '../../mc-ui';
import QuestionMarkIcon from '../../mc-ui/icons/QuestionMarkIcon';

import NumberDataPoint from './NumberDataPoint';

const TriggeredEmailsDataPoint = ({ value }) => {
  if (value === 0) {
    return (
      <Flex alignItems="center">
        <Text color="dark08">Triggered emails:&nbsp;</Text>
        <Text color="yellowDark">0</Text>
        <Box
          color="yellowDark"
          marginLeft="5px"
          width="auto"
          css={{
            display: 'inline',
            fontSize: '18px',
            lineHeight: '1em',
          }}
        >
          <Tooltip
            element={<QuestionMarkIcon fillOpacity={1} />}
            id={`noTriggeredEmails${new Date().getTime()}`}
            dark
          >
            <Text>
              Our team initiated a journey for this brand but no triggered emails were received for
              this journey.
            </Text>
          </Tooltip>
        </Box>
      </Flex>
    );
  }

  return <NumberDataPoint label="Triggered emails" value={value} />;
};

TriggeredEmailsDataPoint.defaultProps = {
  value: 0,
};

TriggeredEmailsDataPoint.propTypes = {
  value: PropTypes.number,
};

export default TriggeredEmailsDataPoint;
