import React from 'react';
import PropTypes from 'prop-types';

import NoComments from '../../Comments/NoComments';
import { Flex, Link, Text } from '../../mc-ui';

import Comment from './Comment';

export default function Comments({ comments }) {
  if (comments.length === 0) {
    return (
      <Flex alignItems="center" direction="column" justifyContent="center" padding="80px 0">
        <NoComments />
        <Text css={{ marginTop: '10px' }}>
          Add your first note! You can add notes to any email in your{' '}
          <Link to="/my-collections/email-lists">email lists</Link>.
        </Text>
      </Flex>
    );
  }

  return (
    <div>
      {comments.map(comment => (
        <Comment key={comment.id} comment={comment} />
      ))}
    </div>
  );
}

Comments.propTypes = {
  comments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
};
