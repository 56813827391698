import React from 'react';
import PropTypes from 'prop-types';

import { Flex } from '../mc-ui';

import Item from './stats/Item';

const Stats = ({ children, ...props }) => <Flex {...props}>{children}</Flex>;

Stats.Item = Item;

Stats.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Stats;
