import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import styled from "@emotion/styled";

import { Heading } from '../../mc-ui';
import GroupBrands from './groupCard/GroupBrands';
import CardBody from './groupCard/CardBody';

const GroupCardWrapper = styled("div")({
  display: 'inline-block',
  margin: '0 0 24px',
  width: '100%',
});

const GroupCard = ({
  group,
  sub_groups = [],
  background = 'images/groups-background/apparel.png',
  brands = { data: [] },
}) => (
  <GroupCardWrapper>
    <GroupBrands background={background} brands={brands.data.slice(0, 5)} />
    <CardBody>
      <Heading
        as="h3"
        css={{
          fontWeight: '300',
          lineHeight: '1.32em',
          margin: '0',
          padding: '0',
        }}
      >
        {group.attributes.name}
      </Heading>
      <ul>
        {sub_groups.filter(g => g.id !== group.id).map(rGroup => (
          <li key={`rgroup-${rGroup.id}`}>
            <Link to={`/groups/${rGroup.id}`}>{rGroup.attributes.name}</Link>
            <small>{rGroup.attributes.approximate_companies} brands</small>
          </li>
        ))}

        {group.id !== '__custom__' && (
          <li key={`parent-group-${group.id}`}>
            <Link to={`/groups/${group.id}`}>
              <strong>View All {group.attributes.name}</strong>
            </Link>
          </li>
        )}
      </ul>
    </CardBody>
  </GroupCardWrapper>
);

GroupCard.propTypes = {
  group: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    attributes: PropTypes.shape({
      background: PropTypes.string,
      name: PropTypes.string,
    }),
    relationships: PropTypes.shape({
      companies: PropTypes.shape({
        data: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            attributes: PropTypes.shape({
              logo_src: PropTypes.string,
              name: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            }),
          }),
        ),
      }),
      groups: PropTypes.shape({
        data: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            attributes: PropTypes.shape({
              approximate_companies: PropTypes.number,
              name: PropTypes.string,
            }),
          }),
        ),
      }),
    }),
  }).isRequired,
};

export default GroupCard;
