import React from 'react';
import { Badge } from '../mc-ui';

export default function Active() {
  return (
    <Badge
      backgroundColor="blueLight"
      color="dark1"
      css={{ alignItems: 'center', justifyContent: 'center', marginBottom: '8px' }}
      display="inline-flex"
      fontSize="10px"
      height="16px"
      letterSpacing="0.12em"
      lineHeight="16px"
      padding="0 4px"
    >
      Active
    </Badge>
  );
}
