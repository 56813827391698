import React from 'react';
import api from '../lib/api';

import { useAuth0 } from '@auth0/auth0-react';

function Signin() {
  const { loginWithRedirect } = useAuth0();

  React.useEffect(() => {

    async function signInOrRedirect() {
      await fetchData();
      await redirectToAuth0();
    }
    async function redirectToAuth0() {
        loginWithRedirect();
    }
    async function fetchData() {
      try {
        const response = await api.get('/v2/user');
        if (!response.ok) {
          throw new Error('User record was not found');
        } else {
          return response.json();
        }
      } catch (err) {
        console.log('There was an error authenticating your user:');
        console.log(err);
      }
    }
    signInOrRedirect();
  }, []);

  return null;
}

export default Signin;