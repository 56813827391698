import { call, put, select } from 'redux-saga/effects';
import { push } from 'react-router-redux';

import { flashMessageActions } from '../flashMessage';

import * as actions from './actions';
import * as api from './api';
import * as selectors from './selectors';
import * as types from './types';

export function* fetchUserGroups({ params }) {
  try {
    const response = yield call(api.fetchUserGroups, params);
    yield put(actions.fetchUserGroupsSuccess(response.data));
  } catch (error) {
    console.log('customGroups/sagas › fetchUserGroups › error', error);
    yield put(actions.fetchUserGroupsFailure(error));
  }
}

export function* fetchTeamGroups({ params }) {
  try {
    const isValidTeam = yield select(selectors.isValidTeamSelector);

    if (!isValidTeam) {
      yield put(actions.fetchTeamGroupsSuccess({ data: [] }));
      return;
    }

    const team = yield select(selectors.teamSelector);
    const response = yield call(api.fetchTeamGroups, team.id, params);
    yield put(actions.fetchTeamGroupsSuccess(response.data));
  } catch (error) {
    console.log('customGroups/sagas › fetchTeamGroups › error', error);
    yield put(actions.fetchTeamGroupsFailure(error));
  }
}

export function* createGroup({ values, resolve, type }) {
  try {
    const response = yield call(api.createGroup, values);
    const [group] = response.data.data;
    yield put(actions.createSuccess(group));

    yield put({ type: 'REFRESH_USER' });

    if (type === types.CREATE_REQUEST) {
      yield put(push(`/groups/${group.id}`));
      yield call(fetchUserGroups, {});

      yield call(window.analytics.track, 'Group created (personal)', { group_id: group.id });
    }

    yield put(
      flashMessageActions.showSuccess({
        body: 'Group has been created successfully.',
      }),
    );

    if (resolve) {
      resolve();
    }
    return group;
  } catch (error) {
    console.log('customGroups/sagas › createGroup › error', error);
    yield put(actions.createFailure(error));
    return null;
  }
}

export function* deleteGroup({ group }) {
  try {
    yield call(api.deleteGroup, group.id);
    yield put(actions.deleteSuccess(group));
    yield put({ type: 'REFRESH_USER' });
    yield call(fetchUserGroups, {});
    yield put(push('/my-collections/groups'));
    yield put(
      flashMessageActions.showSuccess({
        body: 'Group has been deleted from your Collections.',
      }),
    );

    yield call(window.analytics.track, 'Group removed (personal)', { group_id: group.id });
  } catch (error) {
    console.log('customGroups/sagas › deleteGroup › error', error);
    yield put(actions.deleteFailure(error));
  }
}

export function* updateGroup({ id, values, resolve }) {
  try {
    yield call(api.updateGroup, id, values);
    yield put(actions.updateSuccess());
    yield put({ type: 'REFRESH_USER' });
    if (!values.state) {
      yield put(
        flashMessageActions.showSuccess({
          body: 'Group has been updated successfully.',
        }),
      );
    }

    if (resolve) {
      resolve();
    }
  } catch (error) {
    console.log('customGroups/sagas › updateGroup › error', error);
    yield put(actions.updateFailure(error));
  }
}

export function* shareWithTeam({ groupId, type }) {
  try {
    const isValidTeam = yield select(selectors.isValidTeamSelector);

    if (!isValidTeam) {
      yield put(actions.shareWithTeamSuccess());
      return;
    }

    const team = yield select(selectors.teamSelector);
    const response = yield call(api.shareWithTeam, team.id, groupId);
    yield put(actions.shareWithTeamSuccess(response.data));

    if (
      ![types.CREATE_TEAM_GROUP_REQUEST, types.CREATE_GROUP_AND_ADD_BRANDS_REQUEST].includes(type)
    ) {
      yield put(
        flashMessageActions.showSuccess({
          body: 'Group has been shared with team successfully.',
        }),
      );

      yield call(window.analytics.track, 'Group shared with team', { group_id: groupId });
    }
  } catch (error) {
    console.log('customGroups/sagas › createTeamGroup › error', error);
    yield put(actions.shareWithTeamFailure(error));
  }
}

export function* createTeamGroup(action) {
  const group = yield call(createGroup, action);

  yield call(window.analytics.track, 'Group created (team)', { group_id: group.id });

  yield call(shareWithTeam, { groupId: group.id, type: action.type });
  yield put(push(`/groups/${group.id}`));
}

export function* deleteTeamGroup({ groupId }) {
  try {
    const isValidTeam = yield select(selectors.isValidTeamSelector);

    if (!isValidTeam) {
      yield put(actions.deleteTeamGroupSuccess());
      return;
    }

    const team = yield select(selectors.teamSelector);
    const response = yield call(api.deleteTeamGroup, team.id, groupId);
    yield put(actions.deleteTeamGroupSuccess(response.data));
    yield put(push('/team-collections/groups'));
    yield put(
      flashMessageActions.showSuccess({
        body: 'Group has been delete from team Collections.',
      }),
    );

    yield call(window.analytics.track, 'Group removed (team)', { group_id: groupId });
  } catch (error) {
    console.log('customGroups/sagas › deleteTeamGroup › error', error);
    yield put(actions.deleteTeamGroupFailure(error));
  }
}

export function* updateTeamGroup({ id, values, resolve }) {
  try {
    const isValidTeam = yield select(selectors.isValidTeamSelector);

    if (!isValidTeam) {
      yield put(actions.updateTeamGroupSuccess());
      return;
    }

    const team = yield select(selectors.teamSelector);
    const response = yield call(api.updateTeamGroup, team.id, id, values);
    const [group] = response.data.data;
    yield put(actions.updateTeamGroupSuccess(group));
    yield put({ type: 'REFRESH_USER' });
    if (!values.state) {
      yield put(
        flashMessageActions.showSuccess({
          body: 'Group has been updated successfully.',
        }),
      );
    }

    if (resolve) {
      resolve();
    }
  } catch (error) {
    console.log('customGroups/sagas › updateTeamGroup › error', error);
    yield put(actions.updateTeamGroupFailure(error));
  }
}

export function* addBrandsToGroup({ groupId, brands, resolve, reject }) {
  try {
    const brandIds = brands.map(b => b.id);
    const response = yield call(api.addBrandsToGroup, groupId, brandIds);
    const [group] = response.data.data;
    yield put(actions.addBrandsToGroupSuccess(groupId, brands));
    yield put({ type: 'REFRESH_USER' });

    yield call(window.analytics.track, 'Brand added to group', { group_id: groupId });

    if (resolve) {
      resolve(group);
    }
  } catch (error) {
    console.log('customGroups/sagas › addBrandsToGroup › error', error);
    yield put(actions.addBrandsToGroupFailure(error));

    if (reject) {
      reject(error);
    }
  }
}

export function* removeBrandsToGroup({ groupId, brands, resolve, reject }) {
  try {
    const brandIds = brands.map(b => b.id);
    const response = yield call(api.removeBrandsToGroup, groupId, brandIds);
    const [group] = response.data.data;
    yield put(actions.removeBrandsToGroupSuccess(groupId, brands));
    yield put({ type: 'REFRESH_USER' });
    yield put({ type: 'REFRESH_COMPANIES' });
    yield put({ type: 'REFRESH_GROUPS' });

    yield call(window.analytics.track, 'Brand removed from group', { group_id: groupId });

    if (resolve) {
      resolve(group);
    }
  } catch (error) {
    console.log('customGroups/sagas › removeBrandsToGroup › error', error);
    yield put(actions.removeBrandsToGroupFailure(error));

    if (reject) {
      reject(error);
    }
  }
}

export function* createGroupAndAddBrands({ brands, values, resolve, type }) {
  try {
    const { owner } = values;
    const group = yield call(createGroup, { values, type });

    yield call(addBrandsToGroup, { groupId: group.id, brands });
    yield call(fetchUserGroups, {});

    if (owner === 'team') {
      yield call(shareWithTeam, { groupId: group.id, type });
      yield call(fetchTeamGroups, {});
    }

    yield put(actions.createGroupAndAddBrandsSuccess());

    if (resolve) {
      resolve(group);
    }

    return group;
  } catch (error) {
    console.log('customGroups/sagas › createGroup › error', error);
    yield put(actions.createGroupAndAddBrandsFailure(error));
    return null;
  }
}
