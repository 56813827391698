import React from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import { connect } from 'react-redux';

import axios, { CancelToken } from 'axios';
import api from '../../lib/api';
import { reportsActions } from '../../modules/reports';

import HorizontalBar from '../HorizontalBar';
import Loader from '../Loader';
import Restricted from '../Restricted';
import SubjectLineDistribution from '../charts/SubjectLineDistribution';

import IntervalFilter from './IntervalFilter';

class ContentAnalysisReport extends React.Component {
  state = {
    interval: 'week',
    loading: true,
    report: null,
    searchString: '',
  };

  UNSAFE_componentWillMount() {
    const { router } = this.props;

    this.setState({ searchString: router.location.search }, () => {
      this.fetch(this.props);
    });
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { router } = props;

    if (router.location.search !== this.state.searchString) {
      this.setState({ searchString: router.location.search }, () => {
        this.fetch(props);
      });
    }
  }

  setTotal = (report) => {
    let total;
    let comparisonTotal;
    report.meta.stats.forEach((stat) => {
      if (stat.name === 'Comparison') {
        comparisonTotal = stat.total;
      } else {
        total = stat.total; // eslint-disable-line
      }
    });

    this.props.receiveExportTotal({ total, comparisonTotal });
  };

  cancelTokenSource = null;

  cancelFetch = () => {
    if (this.cancelTokenSource) {
      this.cancelTokenSource.cancel();
    }

    this.cancelTokenSource = CancelToken.source();
  };

  fetch(props) {
    this.cancelFetch();

    const { router } = props;
    const { model, startReportLoading } = this.props;
    const { interval } = this.state;

    startReportLoading();

    this.setState({ loading: true, error: null }, () => {
      const query = qs.parse((router.location.search || '').replace('?', ''));
      const params = {
        ...query,
        interval,
      };

      api
        .get(`/v2/${model.type}/${model.id}/content-analysis?${qs.stringify(params)}`, {
          cancelToken: this.cancelTokenSource.token,
        })
        .then((r) => {
          const report = r.data;
          this.setTotal(report);
          this.setState({ loading: false, restricted: null, report });
        })
        .catch((err) => {
          this.setState({ error: true, restricted: /403/.test(err.toString()), report: {} });
        });
    });
  }

  updateInterval = (interval) => {
    this.setState({ interval }, () => this.fetch(this.props));
  };

  render() {
    return (
      <Loader loading={this.state.loading} error={this.state.error}>
        {this.state.report ? (
          <div
            ref={(i) => {
              this.div = i;
            }}
            className="pure-u-1"
          >
            {this.state.restricted ? (
              <Restricted />
            ) : (
              <IntervalFilter onUpdate={this.updateInterval} {...this.props} {...this.state}>
                {({ report }) => (
                  <div>
                    <div className="pure-u-1" style={{ position: 'relative ' }}>
                      <h4>Content analysis</h4>
                    </div>
                    <div className="pure-g gutters" style={{ paddingBottom: '1em' }}>
                      <div className="pure-u-1-2">
                        <h5>Subject line length</h5>
                        {report.meta.stats.map((s, idx) => {
                          const key = `subject-line-length-${idx}`;
                          return (
                            <div key={key}>
                              <HorizontalBar
                                max={75}
                                value={s.subject_line_length.avg}
                                name={s.name}
                              >
                                {Math.round(s.subject_line_length.avg)} characters
                              </HorizontalBar>
                            </div>
                          );
                        })}
                      </div>
                      <div className="pure-u-1-2">
                        <h5>
                          Emojis in subject line{' '}
                          <span role="img" aria-label="pizza emoji">
                            🍕
                          </span>
                        </h5>
                        {report.meta.stats.map((s, idx) => {
                          const key = `emojis-in-subject-line-${idx}`;
                          return (
                            <div key={key}>
                              <HorizontalBar max={1} value={s.subject_line_emoji.avg} name={s.name}>
                                {Math.round(s.subject_line_emoji.avg * 100)}%
                              </HorizontalBar>
                            </div>
                          );
                        })}
                      </div>
                      <div className="pure-u-1-2">
                        <h5>Mobile optimization rate</h5>
                        {report.meta.stats.map((s, idx) => {
                          const key = `mobile-optimization-rate-${idx}`;
                          return (
                            <div key={key}>
                              <HorizontalBar max={1} value={s.mobile_optimized.avg} name={s.name}>
                                {Math.round(s.mobile_optimized.avg * 100)}%
                              </HorizontalBar>
                            </div>
                          );
                        })}
                      </div>
                      <div className="pure-u-1-2">
                        <h5>Animated GIF usage</h5>
                        {report.meta.stats.map((s, idx) => {
                          const key = `animated-gif-usage-${idx}`;
                          return (
                            <div key={key}>
                              <HorizontalBar max={1} value={s.animated.avg} name={s.name}>
                                {Math.round(s.animated.avg * 100)}%
                              </HorizontalBar>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div>
                      <h5>Average subject line length</h5>
                      <SubjectLineDistribution
                        height={100}
                        names={report.meta.stats.map(s => s.name)}
                        buckets={report.meta.stats.map(s => s.subject_line_distribution.buckets)}
                      />
                      <h5>Text vs. images</h5>
                      <table className="pure-table pure-table-striped" style={{ width: '100%' }}>
                        <thead>
                          <tr>
                            <th />
                            {report.meta.stats.map((s, idx) => {
                              const name = report.meta.stats[idx].name || '';
                              const key = `${name.toLowerCase().replace(' ', '-')}-${idx}`;
                              return <th key={key}>{name}</th>;
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          {['primary_content', 'word_count', 'reading_time_seconds'].map((type) => {
                            const key = type.toLowerCase();
                            let label = key;
                            if (label === 'primary_content') label = 'Primary content';
                            else if (label === 'word_count') label = 'Word count';
                            else if (label === 'reading_time_seconds') {
                              label = 'Average reading time';
                            }
                            return (
                              <tr key={key}>
                                <td>{label}</td>
                                {report.meta.stats.map((s, idx) => {
                                  let value = Math.round((s[key] || {}).avg || 0);
                                  if (type === 'primary_content') {
                                    value =
                                      s.image_bytes.avg > s.body_bytes.avg ? 'Images' : 'Text';
                                  } else if (type === 'reading_time_seconds') {
                                    value = `${Math.round(s.reading_time_seconds.avg / 60)}:${Math.round(s.reading_time_seconds.avg % 60)}`;
                                  }
                                  const newKey = `${key}-${value}-${idx}`;
                                  return <td key={newKey}>{value}</td>;
                                })}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </IntervalFilter>
            )}
          </div>
        ) : null}
      </Loader>
    );
  }
}

ContentAnalysisReport.propTypes = {
  model: PropTypes.shape({}).isRequired,
  router: PropTypes.shape({}).isRequired,
  receiveExportTotal: PropTypes.func.isRequired,
  startReportLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  companies: state.companies,
  groups: state.groups,
});

const mapDispatchToProps = dispatch => ({
  startReportLoading: () => dispatch(reportsActions.startLoading()),
  receiveExportTotal: (...args) => dispatch(reportsActions.receiveExportTotalAmount(...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContentAnalysisReport);
