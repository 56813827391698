/** @jsx jsx */
import { jsx } from '@emotion/react';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import pluralize from 'pluralize';
import { Link } from 'react-router';

import { Box, Heading, Text, theme } from '../../mc-ui';

import CommentCounterBadge from '../CommentCounterBadge';

import EmailsBackground from './EmailsBackground';
import TextMessagesBackground from './TextMessagesBackground';

const ListCard = ({ children, list, listType }) => {
  const approximateEmails = list.attributes.approximate_emails || list.attributes.approximateEmails;
  const approximateTextMessages =
    list.attributes.approximate_text_messages || list.attributes.approximateTextMessages;

  return (
    <Box
      backgroundColor="white"
      border="1px solid"
      borderColor="dark5"
      borderRadius="4px"
      height="240px"
      position="relative"
      width="247px"
      css={{
        '&:hover': {
          boxShadow: `0 2px 8px ${theme.colors.dark02}`,
          '& h4': {
            color: `${theme.colors.blue} !important`,
          },
        },
      }}
    >
      <Link
        to={`/lists/${list.id}`}
        css={{
          borderRadius: '4px',
          boxSizing: 'border-box',
          display: 'block',
          height: '240px',
          padding: '171px 16px 16px 16px',
          position: 'relative',
          textDecoration: 'none',
          width: '247px',
        }}
      >
        {listType === 'emails' ? (
          <EmailsBackground list={list} />
        ) : (
          <TextMessagesBackground list={list} />
        )}

        <Box position="relative">
          <Heading
            as="h4"
            css={{
              fontSize: '14px',
              lineHeight: '1.25em',
              marginTop: '16px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              width: '215px', // 215 === 240 - 16 - 16 (parentWidth - parentPaddingLeft - parentPaddingRight)
            }}
          >
            {list.attributes.name}
          </Heading>

          {listType === 'emails' ? (
            <Text fontSize="12px">
              {approximateEmails} {pluralize('email', approximateEmails)}.{' '}
              {!list.attributes.featured && `Added ${moment(list.attributes.updatedAt).fromNow()}.`}
            </Text>
          ) : (
            <Text fontSize="12px">
              {approximateTextMessages} {pluralize('message', approximateTextMessages)}.{' '}
              {!list.attributes.featured && `Added ${moment(list.attributes.updatedAt).fromNow()}.`}
            </Text>
          )}
        </Box>

        <Box height="24px" position="absolute" left="6px" top="6px" width="unset">
          <CommentCounterBadge
            id={list.id}
            total={list.attributes.approximate_comments || list.attributes.approximateComments}
          />
        </Box>
      </Link>
      {children && children(list)}
    </Box>
  );
};

ListCard.defaultProps = {
  children: null,
  listType: 'emails',
};

ListCard.propTypes = {
  children: PropTypes.func,
  list: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    attributes: PropTypes.shape({
      approximateComments: PropTypes.number,
      approximateEmails: PropTypes.number,
      approximateTextMessages: PropTypes.number,
      approximate_comments: PropTypes.number,
      approximate_emails: PropTypes.number,
      approximate_text_messages: PropTypes.number,
      createdAt: PropTypes.string,
      featured: PropTypes.bool,
      name: PropTypes.string,
      updatedAt: PropTypes.string,
    }),
  }).isRequired,
  listType: PropTypes.oneOf(['emails', 'text-messages']),
};

export default ListCard;
