import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';

import { shallowEqual } from '../../../../lib/utils';
import api from '../../../../lib/api';
import { Flex, Loader } from '../../../mc-ui';

import ReportSection from '../../ReportSection';
import Title from '../../Title';

import Chart from './Chart';
import Emails from './emails';
import PromotionTypeTooltip from './PromotionTypeTooltip';

class PromotionType extends React.Component {
  state = {
    buyOneGetOneFree: { brands: [], total: 0 },
    dollarsOff: { brands: [], total: 0 },
    emailQuery: { promotional: 'percentage' },
    freeShipping: { brands: [], total: 0 },
    loading: true,
    percentOff: { brands: [], total: 0 },
  };

  componentDidMount() {
    if (this.props.model.id) {
      this.request(this.props);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.model.id) return;
    const nextModel = { id: nextProps.model.id, type: nextProps.model.type };
    const model = { id: this.props.model.id, type: this.props.model.type };
    const nextDates = { endDate: nextProps.endDate, startDate: nextProps.startDate };
    const dates = { endDate: this.props.endDate, startDate: this.props.startDate };

    if (!shallowEqual(nextModel, model) || !shallowEqual(nextDates, dates)) {
      this.setState(
        () => ({ loading: true }),
        () => {
          this.request(nextProps);
        },
      );
    }
  }

  request = async ({ model, endDate, startDate }) => {
    if (!endDate || !startDate) return;

    const { id, type } = model;

    let query = type === 'groups' ? `?group_id=${id}` : `?company_id=${id}`;
    query += `&start_date=${startDate}`;
    query += `&end_date=${endDate}`;

    const response = await api.get(`/v3/reports/promotion-types${query}`);
    const {
      data: { meta },
    } = response;

    const buyOneGetOneFree =
      meta.stats.promotion_types.buckets.find(bucket => bucket.key === 'bogof') || {};
    const dollarsOff =
      meta.stats.promotion_types.buckets.find(bucket => bucket.key === 'discount_absolute') || {};
    const freeShipping =
      meta.stats.promotion_types.buckets.find(bucket => bucket.key === 'free_shipping') || {};
    const percentOff =
      meta.stats.promotion_types.buckets.find(bucket => bucket.key === 'discount_percentage') || {};

    this.setState({
      buyOneGetOneFree,
      dollarsOff,
      freeShipping,
      loading: false,
      percentOff,
    });
  };

  handleTypeChange = name => {
    let promotional = '';
    if (name === 'percentOff') {
      promotional = 'percentage';
    } else if (name === 'dollarsOff') {
      promotional = 'absolute';
    } else if (name === 'freeShipping') {
      promotional = 'free_shipping';
    } else if (name === 'buyOneGetOneFree') {
      promotional = 'bogo';
    }

    this.setState({ emailQuery: { promotional } });
  };

  render() {
    const { endDate, startDate } = this.props;
    const {
      buyOneGetOneFree,
      dollarsOff,
      emailQuery,
      freeShipping,
      loading,
      percentOff,
    } = this.state;
    const { router } = this.context;
    const pathname = `${router.location.pathname.replace('/promotions', '')}/emails`;
    const query = {
      ...emailQuery,
      end_date: endDate,
      start_date: startDate,
    };

    return (
      <Flex>
        <ReportSection css={{ minHeight: '742px' }}>
          <ReportSection.Header
            alignItems="center"
            justifyContent="flex-start"
            css={{ marginBottom: '22px' }}
          >
            <Title>Promotion Type</Title>
            <PromotionTypeTooltip />
          </ReportSection.Header>

          {loading ? (
            <Loader />
          ) : (
            <div>
              <ReportSection.Body>
                <Chart
                  buyOneGetOneFree={buyOneGetOneFree}
                  dollarsOff={dollarsOff}
                  freeShipping={freeShipping}
                  percentOff={percentOff}
                />
                <Emails
                  buyOneGetOneFree={buyOneGetOneFree}
                  dollarsOff={dollarsOff}
                  freeShipping={freeShipping}
                  percentOff={percentOff}
                  onEmailChange={this.handleTypeChange}
                />
              </ReportSection.Body>
              <ReportSection.Footer padding="0">
                <Link to={{ pathname, query }}>See more</Link>
              </ReportSection.Footer>
            </div>
          )}
        </ReportSection>
      </Flex>
    );
  }
}

PromotionType.contextTypes = {
  router: PropTypes.shape({}).isRequired,
};

PromotionType.defaultProps = {
  endDate: null,
  startDate: null,
};

PromotionType.propTypes = {
  model: PropTypes.shape({ id: PropTypes.string, type: PropTypes.string }).isRequired,
  endDate: PropTypes.string,
  startDate: PropTypes.string,
};

const mapStateToProps = state => ({
  endDate: state.newReports.dates.endDate,
  startDate: state.newReports.dates.startDate,
});

export default connect(mapStateToProps)(PromotionType);
