import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Flex, Loader } from '../../mc-ui';

import BrandContainer from './BrandContainer';
import GroupContainer from './GroupContainer';

const Benchmarks = ({ model, user, ...props }) => {
  if (!model || !model.id) {
    return (
      <Flex alignItems="center" height="482px" justifyContent="center">
        <Loader />
      </Flex>
    );
  }

  if (model.type === 'groups') {
    return <GroupContainer model={model} user={user} {...props} />;
  }

  return <BrandContainer model={model} user={user} {...props} />;
};

Benchmarks.defaultProps = {
  model: { id: null, type: null },
};

Benchmarks.propTypes = {
  model: PropTypes.shape({ id: PropTypes.string, type: PropTypes.string }),
  user: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => ({
  user: state.user.data[0],
});

export default connect(mapStateToProps)(Benchmarks);
