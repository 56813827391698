import React from 'react';
import humanize from 'humanize-number';
import { Link } from 'react-router';
import { connect } from 'react-redux';

import api from '../../lib/api';

import Loader from '../Loader';
import Restricted from '../Restricted';

class ExportReport extends React.Component {
  state = {
    searches: { links: {}, meta: {} },
    restricted: false,
    loading: true,
  };

  UNSAFE_componentWillMount() {
    this.fetch(this.props);
  }

  UNSAFE_componentWillUpdate(props) {
    const { start_date, end_date, company_id, segment_id } = props.router.location.query;
    if (
      start_date != this.query.start_date ||
      end_date != this.query.end_date ||
      company_id != this.query.company_id ||
      segment_id != this.query.segment_id
    ) {
      this.fetch(props);
    }
  }

  handleClick = event => {
    event.preventDefault();
    const url = this.state.searches.links.self;
    const { model } = this.props;
    const u = new URL(url);
    u.pathname = `v2/companies/${model.id}/xlsx`;
    const format = event.target.name;
    const type = 'report';
    window.analytics.track('Export clicked', { format, type });

    if (format == 'xlsx') return window.open(`${u.toString()}`, '_blank');
    window.open(`${url}&format=${format}`, '_blank');
  };
  fetch(props) {
    this.query = { ...props.router.location.query };
    let loading = true;
    const error = null;
    this.setState({ loading, error });
    const params = { ...props.router.params, ...this.query };
    if (/\/dashboard/i.test(props.router.location.pathname)) {
      params.group_id = props.groups.data[0].id;
    }
    api
      .get(`/v2/searches`, { params })
      .then(r => {
        loading = false;
        const searches = r.data;
        const restricted =
          searches.meta.data_access == 'limited' &&
          searches.meta.total > searches.meta.limited_total;
        this.setState({ searches, loading, restricted });
      })
      .catch(e => {
        console.error(e);
        this.setState({ error: e });
      });
  }
  render() {
    const user = this.props.user.data.reduce((m, u) => u, {});
    const xlsx = this.props.model.type == 'companies' && !user.attributes.restricted;
    return (
      <Loader loading={this.state.loading}>
        <div className="pure-u-1">
          {this.state.restricted ? (
            <Restricted />
          ) : (
            <div>
              <h4>Export</h4>
              <p>
                {humanize(this.state.searches.meta.total || 0)} emails are available over your
                selected date from {this.state.searches.meta.start_date} to{' '}
                {this.state.searches.meta.end_date}.
              </p>
              <div className="pure-g gutters">
                <div className="pure-u-1 pure-u-md-1-3">
                  <h5>Images</h5>
                  <p style={{ fontSize: `${12 / 16}em` }}>
                    Download up to 50 images in a zip file to instantly create presentations to
                    shine in your next meeting.
                  </p>
                  <p style={{ fontSize: `${12 / 16}em` }}>
                    <Link name="images" onClick={this.handleClick} className="pure-button" to="#">
                      Download images
                    </Link>
                  </p>
                </div>
                <div className="pure-u-1 pure-u-md-1-3">
                  <h5>CSV</h5>
                  <p style={{ fontSize: `${12 / 16}em` }}>
                    Export the data for up to 5,000 emails in order to build custom reports for your
                    own needs.
                  </p>
                  <p style={{ fontSize: `${12 / 16}em` }}>
                    <Link name="csv" onClick={this.handleClick} className="pure-button" to="#">
                      Download CSV
                    </Link>
                  </p>
                </div>
                <div className="pure-u-1 pure-u-md-1-3">
                  <h5>HTML</h5>
                  <p style={{ fontSize: `${12 / 16}em` }}>
                    Export parsed HTML for up to 50 emails in order to kick-start a new template or
                    create a mock-up.
                  </p>
                  <p style={{ fontSize: `${12 / 16}em` }}>
                    <Link name="html" onClick={this.handleClick} className="pure-button" to="#">
                      Download HTML
                    </Link>
                  </p>
                </div>
              </div>
              {xlsx && (
                <div className="pure-u-1">
                  <h5>
                    <span
                      style={{
                        borderRadius: 3,
                        padding: 2,
                        fontSize: `${11 / 16}em`,
                        marginRight: 5,
                        background: '#AD6FD1',
                        color: '#fff',
                      }}
                    >
                      Pro
                    </span>
                    Excel spreadsheet
                  </h5>
                  <p style={{ fontSize: `${12 / 16}em` }}>
                    One convenient Excel spreadsheet with week-over-week and month-over-month
                    sending behavior, as well as another workbook detailing promotions for all{' '}
                    {humanize(this.state.searches.meta.total || 0)} emails.
                  </p>
                  <p style={{ fontSize: `${12 / 16}em` }}>
                    <Link name="xlsx" onClick={this.handleClick} className="pure-button" to="#">
                      Download XLSX
                    </Link>
                  </p>
                </div>
              )}
              <p>
                <Link
                  to={{
                    pathname: `${this.props.router.location.pathname.replace('export', 'emails')}`,
                    query: this.props.router.location.query,
                  }}
                >
                  Click here
                </Link>{' '}
                to select specific emails to export as images, CSV or HTML.
              </p>
            </div>
          )}
        </div>
      </Loader>
    );
  }
}

const s = state => ({ user: state.user, companies: state.companies, groups: state.groups });
export default connect(s)(ExportReport);
