const INITIAL_STATE = {
  loading: true,
  paginating: false,
  links: {},
  meta: {},
  data: { emails: [], companies: [], addresses: [], technologies: [], groups: [], lists: [] },
  query: {},
  url: null,
};
export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case 'REQUEST_SEARCH':
      return {
        ...state,
        loading: true,
      };
    case 'RECEIVE_SEARCH':
      return {
        ...action.data,
        paginating: false,
        loading: false,
        query: action.query || {},
        url: action.url || null,
      };
    case 'REQUEST_PAGINATION':
      return {
        ...state,
        paginating: true,
      };
    case 'RECEIVE_DASHBOARD':
      return {
        ...action.data,
        loading: false,
      };
    case 'RECEIVE_PAGINATION': {
      const emails = state.data.emails.concat(action.searches.data.emails);
      return {
        ...state,
        data: {
          ...state.data,
          emails,
        },
        paginating: false,
        links: action.searches.links,
      };
    }
    default:
      return state;
  }
};
