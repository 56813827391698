import React from 'react';

import { Tooltip } from '../../../mc-ui';
import QuestionMarkIcon from '../../../mc-ui/icons/QuestionMarkIcon';

const BestMatchTooltip = () => (
  <Tooltip id="bestMatchTooltip" element={<QuestionMarkIcon height="16" width="16" />} dark>
    MailCharts&rsquo; Best Match is designed to show you the most relevant brands. It considers a
    number of factors, including: Recent Email Activity, Available Historical Data, and Premium
    Brands.
  </Tooltip>
);

export default BestMatchTooltip;
