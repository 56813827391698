import moment from 'moment';

const handlePrevious14Days = ({ restricted, ...props }, { startDate }) => ({
  ...props,
  label: 'Previous 14 days',
  value: 'previous_14_days',
  start_date: moment(startDate)
    .subtract(14, 'days')
    .format('YYYY-MM-DD'),
  end_date: moment(startDate).format('YYYY-MM-DD'),
});

const handlePrevious30Days = ({ restricted, ...props }, { startDate }) => ({
  ...props,
  label: 'Previous 30 days',
  value: 'previous_30_days',
  start_date: moment(startDate)
    .subtract(30, 'days')
    .format('YYYY-MM-DD'),
  end_date: moment(startDate).format('YYYY-MM-DD'),
});

const handlePrevious90Days = ({ restricted, ...props }, { startDate }) => ({
  ...props,
  label: 'Previous 90 days',
  value: 'previous_90_days',
  start_date: moment(startDate)
    .subtract(90, 'days')
    .format('YYYY-MM-DD'),
  end_date: moment(startDate).format('YYYY-MM-DD'),
});

const handlePrevious365Days = ({ restricted, ...props }, { startDate }) => ({
  ...props,
  label: 'Previous 365 days',
  value: 'previous_365_days',
  start_date: moment(startDate)
    .subtract(365, 'days')
    .format('YYYY-MM-DD'),
  end_date: moment(startDate).format('YYYY-MM-DD'),
});

const handlePreviousMonth = ({ restricted, ...props }, { startDate }) => ({
  ...props,
  label: 'Previous month',
  value: 'previous_month',
  start_date: moment(startDate)
    .subtract(1, 'month')
    .startOf('month')
    .format('YYYY-MM-DD'),
  end_date: moment(startDate)
    .subtract(1, 'month')
    .endOf('month')
    .format('YYYY-MM-DD'),
});

const handlePreviousQuarter = ({ restricted, ...props }, { startDate }) => ({
  ...props,
  label: 'Previous quarter',
  value: 'previous_quarter',
  start_date: moment(startDate)
    .subtract(1, 'quarter')
    .startOf('quarter')
    .format('YYYY-MM-DD'),
  end_date: moment(startDate)
    .subtract(1, 'quarter')
    .endOf('quarter')
    .format('YYYY-MM-DD'),
});

const handleLastYear = ({ restricted, ...props }, { endDate, startDate }) => ({
  ...props,
  label: 'Last year',
  value: 'last_year',
  start_date: moment(startDate)
    .subtract(1, 'year')
    .startOf('year')
    .format('YYYY-MM-DD'),
  end_date: moment(endDate)
    .subtract(1, 'year')
    .endOf('year')
    .format('YYYY-MM-DD'),
});

const handlePreviousYear = ({ restricted, ...props }, { endDate, startDate }) => ({
  ...props,
  label: 'Previous year',
  value: 'previous_year',
  start_date: moment(startDate)
    .subtract(1, 'year')
    .startOf('year')
    .format('YYYY-MM-DD'),
  end_date: moment(endDate)
    .subtract(1, 'year')
    .endOf('year')
    .format('YYYY-MM-DD'),
});

const handlers = {
  '14_days': handlePrevious14Days,
  '30_days': handlePrevious30Days,
  '90_days': handlePrevious90Days,
  '365_days': handlePrevious365Days,
  last_month: handlePreviousMonth,
  last_quarter: handlePreviousQuarter,
  this_year: handleLastYear,
  last_year: handlePreviousYear,
};

const generateIntervals = (intervals, selectedDates) =>
  intervals.map(interval => {
    const handlerFunc = handlers[interval.value];
    return handlerFunc(interval, selectedDates);
  });

export default generateIntervals;
