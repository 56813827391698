/* global window */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { www } from '../../lib/utils';

import Header from '../Header';
import Section from '../Section';
import JourneyDetails from '../JourneyDetails';

import ContactUs from '../Upgrade/ContactUs';
import JourneysContainer from '../Upgrade/ui/JourneysContainer';
import JourneysExamples from '../Upgrade/JourneysExamples';
import JourneysTitle from '../Upgrade/ui/Title';

import Icon from './ui/Icon';
import HeaderText from './ui/HeaderText';
import HeaderTitle from './ui/HeaderTitle';
import JourneysIcon from './icons/JourneysIcon';

class DemoJourneys extends Component {
  state = {
    journeyWithDetails: null,
  };

  componentDidMount() {
    this.props.requestDemoJourneys();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.demo.journeys.loading) {
      const { location: { query } } = this.context.router;
      const journeys = nextProps.demo.journeys.data;

      const journeyWithDetails = journeys.find(journey => (
        journey.data[0].id === query.segment_id
      ));

      this.updateSelectedJourney(journeyWithDetails);

      this.props.loaded();
    }
  }

  componentDidUpdate() {
    window.scroll({
      behavior: 'smooth',
      left: 0,
      top: 0,
    });
  }

  updateSelectedJourney = (journeyWithDetails) => {
    const { router } = this.context;

    this.setState({ journeyWithDetails }, () => {
      if (journeyWithDetails.data[0].id === router.location.query.segment_id) {
        return;
      }

      router.push({
        pathname: router.location.pathname,
        query: {
          ...router.location.query,
          segment_id: journeyWithDetails.data[0].id,
        },
        state: router.location.state || {},
      });
    });
  }

  render() {
    const { demo: { journeys } } = this.props;
    const {
      journeyWithDetails
    } = this.state;

    const loading = (journeys.loading || !journeyWithDetails);
    const journeysWithDetails = journeyWithDetails ? [journeyWithDetails] : [];

    return (
      <div className="pure-u-1">
        {!loading &&
          <Header>
            <div className="pure-u-1" style={{ margin: '1.5em 0' }}>
              <div style={{ alignItems: 'center', display: 'flex' }}>
                <Icon icon={<JourneysIcon />} />
                <div>
                  <HeaderTitle>User Journeys</HeaderTitle>
                  <HeaderText>
                    Journeys are the series of emails triggered from an action,
                    whether that’s placing an order, abandoning a cart, or
                    signing up for a newsletter. We’re constantly refreshing our
                    database to ensure the most up-to-date data.{' '}
                    <a href={www('/features/email-journeys')} target="_blank">
                      Learn more
                    </a>
                  </HeaderText>
                </div>
              </div>
            </div>
          </Header>
        }

        <Section>
          <JourneyDetails
            loading={loading}
            allJourneys={journeys.data.map(j => j.data[0])}
            journeysWithDetails={journeysWithDetails}
            enableComparison={false}
            router={this.props.router}
          />

          {!journeys.loading &&
            <div
              style={{
                background: '#fff',
                borderRadius: '3px',
                display: 'flex',
                flexDirection: 'column',
                margin: '48px 0px',
                padding: '43px 40px 0 40px',
              }}
            >
              <JourneysContainer>
                <JourneysTitle>A few of our favorite journeys</JourneysTitle>
                <JourneysExamples journeys={journeys.data.map(j => j.data[0])} />
              </JourneysContainer>
              <ContactUs />
            </div>
          }
        </Section>
      </div>
    );
  }
}

DemoJourneys.contextTypes = {
  router: PropTypes.object,
};

DemoJourneys.propTypes = {
  demo: PropTypes.shape({
    journeys: PropTypes.object,
  }).isRequired,
  requestDemoJourneys: PropTypes.func.isRequired,
  loaded: PropTypes.func.isRequired,
  router: PropTypes.shape({
    location: PropTypes.shape({
      query: PropTypes.shape({
        segment_id: PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
          PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
        ]),
      }),
      pathname: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
};

const mapStateToProps = state => ({
  demo: state.demo,
});

const mapDispatchToProps = dispatch => ({
  requestDemoJourneys: () => dispatch({ type: 'REQUEST_DEMO_JOURNEYS' }),
});

export default connect(mapStateToProps, mapDispatchToProps)(DemoJourneys);
