import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

import { Box, Button, Flex, Heading, Text } from '../../../../mc-ui';

import Body from '../../../Body';
import ReportSection from '../../../ReportSection';
import RestrictedHeader from '../../../RestrictedHeader';
import RestrictedUpgradeButton from '../../../RestrictedUpgradeButton';

import AveragePromotionByBrands from './AveragePromotionByBrands';
import AveragePromotionRate from './AveragePromotionRate';
import Examples from './Examples';
import HistoricalTrendLastYear from './HistoricalTrendLastYear';
import PromotionType from './PromotionType';

const isGroup = model => model.type === 'groups';

const Upgrade = ({ model }) => (
  <ReportSection>
    <Body>
      <RestrictedHeader
        description="Discover and improve discount strategies."
        reportName="Promotions"
      />

      <Flex justifyContent="space-between" marginBottom="40px">
        <Flex direction="column" justifyContent="center" padding="0 32px" width="360px">
          <Heading as="h4" fontSize="18px" lineHeight="24px" css={{ marginBottom: '8px' }}>
            Track size/use of discounts and other promotions
          </Heading>

          <Text margin="0 0 8px 0">
            Beat the competition at its own game! Learn their incentive sizes &amp; frequency to
            build a competitive promotion strategy that also predicts incentives’ impact on campaign
            revenue and profit margin.
          </Text>

          <Button
            as={Link}
            to="https://youtu.be/qmDGC34O3Zg"
            target="_blank"
            variant="primary"
            css={{
              alignItems: 'center',
              display: 'inline-flex',
              fontSize: '12px',
              fontWeight: '500',
              justifyContent: 'center',
              lineHeight: '23px',
              height: '23px',
              margin: '0',
              padding: '0',
              width: '173px',
            }}
          >
            Watch a video to learn how
          </Button>
        </Flex>

        <Box width="360px">
          <PromotionType />
        </Box>
      </Flex>

      <Flex justifyContent="space-between" marginBottom="40px">
        <Box width="360px">
          <AveragePromotionRate />
        </Box>

        <Flex direction="column" justifyContent="center" padding="0 32px" width="360px">
          <Heading as="h4" fontSize="18px" lineHeight="24px" css={{ marginBottom: '8px' }}>
            Understand promotional trends and activity
          </Heading>

          <Text>
            Get deeper insight into the trends that brands like yours are using - find out when,
            where and how brands are promoting and see how your strategy compares.
          </Text>
        </Flex>
      </Flex>

      <Flex justifyContent="space-between" marginBottom="40px">
        <Flex direction="column" justifyContent="center" padding="0 32px" width="360px">
          <Heading as="h4" fontSize="18px" lineHeight="24px" css={{ marginBottom: '8px' }}>
            Track historical promotion periods
          </Heading>

          <Text>
            Learn what to expect for long-term campaign planning and see where you should step up or
            pull back on promotions to create campaigns that stand out against the competition.
          </Text>
        </Flex>

        <Box width="360px">
          <HistoricalTrendLastYear />
        </Box>
      </Flex>

      {isGroup(model) && (
        <Flex justifyContent="space-between" marginBottom="40px">
          <Box width="360px">
            <AveragePromotionByBrands />
          </Box>

          <Flex direction="column" justifyContent="center" padding="0 32px" width="360px">
            <Heading as="h4" fontSize="18px" lineHeight="24px" css={{ marginBottom: '8px' }}>
              Compare promotional activity amongst brands
            </Heading>

            <Text>
              Set up a year-long strategic plan using data showing how competitive or like brands
              use promotions at different times of the year. Improve competitive analysis by showing
              the promotion sizes and types that different brands rely on.
            </Text>
          </Flex>
        </Flex>
      )}

      {isGroup(model) ? (
        <Flex justifyContent="space-between" marginBottom="40px">
          <Flex direction="column" justifyContent="center" padding="0 32px" width="360px">
            <Heading as="h4" fontSize="18px" lineHeight="24px" css={{ marginBottom: '8px' }}>
              Research brands’ creative content
            </Heading>

            <Text>
              Streamline the creative process with robust search to zero in on the emails, brands
              and features you want. Download your favorite emails and share quickly with your team.
              Grab the HTML code and share with your developers.
            </Text>
          </Flex>

          <Box width="360px">
            <Examples />
          </Box>
        </Flex>
      ) : (
        <Flex justifyContent="space-between" marginBottom="40px">
          <Box width="360px">
            <Examples />
          </Box>
          <Flex direction="column" justifyContent="center" padding="0 32px" width="360px">
            <Heading as="h4" fontSize="18px" lineHeight="24px" css={{ marginBottom: '8px' }}>
              Research brands’ creative content
            </Heading>

            <Text>
              Streamline the creative process with robust search to zero in on the emails, brands
              and features you want. Download your favorite emails and share quickly with your team.
              Grab the HTML code and share with your developers.
            </Text>
          </Flex>
        </Flex>
      )}

      <RestrictedUpgradeButton />
    </Body>
  </ReportSection>
);

Upgrade.defaultProps = {
  model: { id: null },
};

Upgrade.propTypes = {
  model: PropTypes.shape({ id: PropTypes.string, type: PropTypes.string }),
};

export default Upgrade;
