const INITIAL_STATE = { loading: true, data: [], links: {} }
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'FLUSH': return INITIAL_STATE
    case 'REQUEST_TECHNOLOGIES': return {...state, loading: true }
    case 'RECEIVE_TECHNOLOGIES': return { ...action.data, loading: false }
    case 'REQUEST_TECHNOLOGIES_PAGINATION': return { ...state, paginating: true }
    case 'RECEIVE_TECHNOLOGIES_PAGINATION':
      let data = [].concat(state.data || [], action.technologies.data)
      return { ...state, data, paginating: false, links: action.technologies.links }
    default: return state
  }
}
