/* eslint-disable react/prop-types */

import React, { PureComponent } from 'react';
import { withProps } from 'recompose';

import ReactSelect from '../overrides/ReactSelect';

import Error from './Error';
import InputWrapper from './InputWrapper';

const flatten = options => {
  let result = [];

  options.forEach(option => {
    if (option.options) {
      result = [].concat(result, option.options);
    } else {
      result = [].concat(result, [option]);
    }
  });

  return result;
};

class Select extends PureComponent {
  handleChange = selected => {
    const { value } = selected;
    const { input, isSelectionAllowed, ...rest } = this.props;

    if (!isSelectionAllowed) {
      return;
    }

    if (rest.onChange) {
      rest.onChange(value);
    }

    input.onChange(value);
    input.onBlur(selected);
  };

  render() {
    const {
      hideErrorMessage,
      options,
      input,
      input: { value },
      meta,
      ...rest
    } = this.props;
    const invalid =
      (meta.error || meta.submitError || (meta.data && meta.data.error)) &&
      (meta.touched || (meta.data && meta.data.touched));
    const selectedValue = flatten(options).find(o => o.value === value);

    return (
      <InputWrapper>
        <ReactSelect
          {...input}
          {...rest}
          invalid={invalid}
          options={options}
          onChange={this.handleChange}
          value={selectedValue}
          isSearchable
        />

        {!hideErrorMessage && (
          <Error
            error={meta.error || meta.submitError || (meta.data && meta.data.error)}
            touched={meta.touched || (meta.data && meta.data.touched)}
          />
        )}
      </InputWrapper>
    );
  }
}

Select.defaultProps = {
  isSelectionAllowed: true,
};

const filterProps = props => {
  let { hideErrorMessage, showErrorTooltip } = props;
  const error = props.meta.error || (props.meta.data && props.meta.data.error);

  if (error === 'showErrorTooltip') {
    hideErrorMessage = true;
    showErrorTooltip = !!props.errorTooltipText;
  }

  return { hideErrorMessage, showErrorTooltip };
};

export default withProps(filterProps)(Select);
