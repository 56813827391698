import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';

import * as validators from '../../../lib/forms/validators';

import { Box, Button, Label, TextField, theme } from '../../mc-ui';

export default function EditTeamNameForm({ onCancel, onSubmit, team }) {
  const INITIAL_VALUES = {
    name: team.attributes.name,
  };

  return (
    <Box
      backgroundColor="white"
      borderRadius="4px"
      left="0"
      padding="16px"
      position="absolute"
      top="-6px"
      width="272px"
      css={{
        boxShadow: `0px 2px 8px ${theme.colors.dark02}`,
      }}
    >
      <Form initialValues={INITIAL_VALUES} onSubmit={onSubmit}>
        {({ handleSubmit, pristine, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Label
              htmlFor="name"
              width="100%"
              css={{
                lineHeight: '1em',
                '& > *': {
                  marginTop: '16px',
                },
              }}
            >
              Edit company name
              <Field
                autoComplete="off"
                autoFocus
                component={TextField}
                id="name"
                name="name"
                validate={validators.required}
              />
            </Label>

            <Box marginTop="16px">
              <Button
                css={{
                  height: '24px',
                  lineHeight: '24px',
                  margin: '0 8px 0 0',
                  padding: 0,
                  width: '48px',
                }}
                disabled={submitting || pristine}
                type="submit"
                variant="primary"
              >
                Save
              </Button>

              <Button
                css={{
                  height: '24px',
                  lineHeight: '24px',
                  margin: 0,
                  padding: 0,
                  width: '56px',
                }}
                disabled={submitting}
                onClick={onCancel}
                variant="secondary"
              >
                Cancel
              </Button>
            </Box>
          </form>
        )}
      </Form>
    </Box>
  );
}

EditTeamNameForm.propTypes = {
  team: PropTypes.shape({
    attributes: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
