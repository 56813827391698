import React from 'react';
import { Link } from 'react-router';
import humanize from 'humanize-number';

const BrowseMore = ({ journey_count = 0, journey_type = '', }) => (
  <div className='pure-u-1'>
    <div style={{
      border: '1px solid #0072D3',
      marginBottom: 25,
      borderRadius: 4,
      background: '#FFF',
      padding: 16,
    }}>
    <div className='pure-u-1-12'>
      <img src='/icn-timeline.svg' />
    </div>
    <div className='pure-u-11-12'>
        <p
          style={{
            margin: 0,
            padding: 0,
            lineHeight: '21.84px',
            fontWeight: 'bold',
            fontSize: '16px',
            color: 'rgba(9,30,66,1)',
          }}
        >
          Browse {humanize(journey_count)} more {journey_type.split('_').join(' ')} journeys
        </p>
        <p
          style={{
            margin: '5px 0',
            padding: 0,
            lineHeight: '21.84px',
            fontSize: '14px',
            color: 'rgba(9,30,66,0.8)',
          }}
          >
            We've triggered {humanize(journey_count)} {journey_type.split('_').join(' ')} journeys. Use them to 
            improve your own messaging and discover the best industry practices 
            and trends. Our easy-to-use filters help you find ecommerce lifecycle journeys
            from any brand within the MailCharts Index.
          </p>
          <p
            style={{ fontSize: '14px', }}
          >
            <Link to={{ pathname: `/journeys/results`, query: { journey_type } }} className='pure-button pure-button-primary'>
              Browse 'em all
            </Link>
          </p>
        </div>
      </div>
    </div>
)
export default BrowseMore;
