import { call, put, select } from 'redux-saga/effects';
import { push } from 'react-router-redux';

import { flashMessageActions } from '../flashMessage';

import * as actions from './actions';
import * as api from './api';
import * as selectors from './selectors';
import * as types from './types';

export function* fetchUserLists({ params }) {
  try {
    const response = yield call(api.fetchUserLists, params);
    yield put(actions.fetchUserListsSuccess(response.data));
  } catch (error) {
    console.log('customLists/sagas › fetchUserLists › error', error);
    yield put(actions.fetchUserListsFailure(error));
  }
}

export function* createList({ values, resolve, type }) {
  try {
    const response = yield call(api.createList, values);
    const [list] = response.data.data;
    yield put(actions.createSuccess(list));
    list.attributes.new = true;
    yield put({ type: 'CREATE_LIST_SUCCESS', list });
    yield put(push(`/lists/${list.id}`));

    if (type === types.CREATE_REQUEST) {
      yield call(fetchUserLists, {});

      yield call(window.analytics.track, 'List created (personal)', { list_id: list.id });
    }

    yield put(
      flashMessageActions.showSuccess({
        body: 'List has been created successfully.',
      }),
    );

    if (resolve) {
      resolve();
    }
    return list;
  } catch (error) {
    console.log('customLists/sagas › createList › error', error);
    yield put(actions.createFailure(error));
    return null;
  }
}

export function* deleteList({ list, listType, resolve }) {
  try {
    yield call(api.deleteList, list.id);
    yield put(actions.deleteSuccess(list, listType));
    yield put({ type: 'DELETE_LIST_SUCCESS', list });
    const path =
      listType === 'text-messages'
        ? '/my-collections/text-message-lists'
        : '/my-collections/email-lists';
    yield put(push(path));
    yield put(
      flashMessageActions.showSuccess({
        body: 'List has been deleted from your Collections.',
      }),
    );

    yield call(window.analytics.track, 'List removed (personal)', { list_id: list.id });

    if (resolve) {
      resolve();
    }
  } catch (error) {
    console.log('customLists/sagas › deleteList › error', error);
    yield put(actions.deleteFailure(error));
  }
}

export function* updateList({ id, values, resolve }) {
  try {
    const response = yield call(api.updateList, id, values);
    const [list] = response.data.data;
    yield put(actions.updateSuccess(list));
    yield put({ type: 'UPDATE_LIST_SUCCESS', list });
    yield put({ type: 'UPDATE_USER_LISTS', list });
    if (!values.state) {
      yield put(
        flashMessageActions.showSuccess({
          body: 'List has been updated successfully.',
        }),
      );
    }

    if (resolve) {
      resolve();
    }
  } catch (error) {
    console.log('customLists/sagas › updateList › error', error);
    yield put(actions.updateFailure(error));
  }
}

export function* fetchTeamLists({ params }) {
  try {
    const isValidTeam = yield select(selectors.isValidTeamSelector);

    if (!isValidTeam) {
      yield put(actions.fetchTeamListsSuccess({ data: [] }));
      return;
    }

    const team = yield select(selectors.teamSelector);
    const response = yield call(api.fetchTeamLists, team.id, params);
    yield put(actions.fetchTeamListsSuccess(response.data));
  } catch (error) {
    console.log('customLists/sagas › fetchTeamLists › error', error);
    yield put(actions.fetchTeamListsFailure(error));
  }
}

export function* shareWithTeam({ listId, type }) {
  try {
    const isValidTeam = yield select(selectors.isValidTeamSelector);

    if (!isValidTeam) {
      yield put(actions.shareWithTeamSuccess());
      return;
    }

    const team = yield select(selectors.teamSelector);
    const response = yield call(api.shareWithTeam, team.id, listId);
    yield put(actions.shareWithTeamSuccess(response.data));

    if (![types.CREATE_TEAM_LIST_REQUEST, types.CREATE_LIST_AND_ADD_ITEMS_REQUEST].includes(type)) {
      yield put(
        flashMessageActions.showSuccess({
          body: 'List has been shared with team successfully.',
        }),
      );

      yield call(window.analytics.track, 'List shared with team', { list_id: listId });
    }
  } catch (error) {
    console.log('customLists/sagas › createTeamList › error', error);
    yield put(actions.shareWithTeamFailure(error));
  }
}

export function* createTeamList(action) {
  const list = yield call(createList, action);

  yield call(window.analytics.track, 'List created (team)', { list_id: list.id });

  yield call(shareWithTeam, { listId: list.id, type: action.type });
}

export function* deleteTeamList({ listId, listType, resolve }) {
  try {
    const isValidTeam = yield select(selectors.isValidTeamSelector);

    if (!isValidTeam) {
      yield put(actions.deleteTeamListSuccess());
      return;
    }

    const team = yield select(selectors.teamSelector);
    const response = yield call(api.deleteTeamList, team.id, listId);
    yield put(actions.deleteTeamListSuccess(response.data, listType));
    const path =
      listType === 'text-messages'
        ? '/team-collections/text-message-lists'
        : '/team-collections/email-lists';
    yield put(push(path));
    yield put(
      flashMessageActions.showSuccess({
        body: 'List has been delete from team Collections.',
      }),
    );

    yield call(window.analytics.track, 'List removed (team)', { list_id: listId });

    if (resolve) {
      resolve();
    }
  } catch (error) {
    console.log('customLists/sagas › deleteTeamList › error', error);
    yield put(actions.deleteTeamListFailure(error));
  }
}

export function* updateTeamList({ id, values, resolve }) {
  try {
    const isValidTeam = yield select(selectors.isValidTeamSelector);

    if (!isValidTeam) {
      yield put(actions.updateTeamListSuccess());
      return;
    }

    const team = yield select(selectors.teamSelector);
    const response = yield call(api.updateTeamList, team.id, id, values);
    const [list] = response.data.data;
    yield put(actions.updateTeamListSuccess(list));
    yield put({ type: 'UPDATE_LIST_SUCCESS', list });
    yield put({ type: 'UPDATE_USER_LISTS', list });
    if (!values.state) {
      yield put(
        flashMessageActions.showSuccess({
          body: 'List has been updated successfully.',
        }),
      );
    }

    if (resolve) {
      resolve();
    }
  } catch (error) {
    console.log('customLists/sagas › updateTeamList › error', error);
    yield put(actions.updateTeamListFailure(error));
  }
}

export function* addItemsToList({ listId, items, resolve, reject }) {
  try {
    const data = items.map(i => ({ id: i.id, type: i.type }));
    const response = yield call(api.addItemsToList, listId, data);
    const [list] = response.data.data;
    yield put(actions.addItemsToListSuccess(listId, items));
    yield put({ type: 'ADD_EMAILS_TO_LIST_SUCCESS', list });
    yield put({ type: 'UPDATE_USER_LISTS', list });

    yield call(window.analytics.track, 'Item added to list', { list_id: listId });

    if (resolve) {
      resolve(list);
    }
  } catch (error) {
    console.log('customLists/sagas › addItemsToList › error', error);
    yield put(actions.addItemsToListFailure(error));

    if (reject) {
      reject(error);
    }
  }
}

export function* removeItemsFromList({ listId, items, resolve, reject, options = {} }) {
  try {
    const data = items.map(i => ({ id: i.id, type: i.type }));
    const response = yield call(api.removeItemsFromList, listId, data);
    const [list] = response.data.data;

    if (!options.bulk) {
      yield put(actions.removeItemsFromListSuccess(listId, items));
      yield put({ type: 'DELETE_EMAILS_FROM_LIST_SUCCESS', list });
      yield put({ type: 'UPDATE_USER_LISTS', list });
    }

    yield call(window.analytics.track, 'Item removed from list', { list_id: listId });

    if (resolve) {
      resolve(list);
    }
  } catch (error) {
    console.log('customLists/sagas › removeItemsFromList › error', error);
    yield put(actions.removeItemsFromListFailure(error));

    if (reject) {
      reject(error);
    }
  }
}

export function* createListAndAddItems({ items, values, resolve, type }) {
  try {
    const { owner } = values;
    const list = yield call(createList, { values, type });

    yield call(addItemsToList, { listId: list.id, items });
    yield call(fetchUserLists, {});

    if (owner === 'team') {
      yield call(shareWithTeam, { listId: list.id, type });
      yield call(fetchTeamLists, {});
    }

    yield put(actions.createListAndAddItemsSuccess());

    if (resolve) {
      resolve(list);
    }

    return list;
  } catch (error) {
    console.log('customLists/sagas › createListAndAddItems › error', error);
    yield put(actions.createListAndAddItemsFailure(error));
    return null;
  }
}
