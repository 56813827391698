import React from 'react';
import PropTypes from 'prop-types';
import styled from "@emotion/styled";

const JourneyCompanyPicker = ({
  index, companies, selectedCompanyId, handleChange,
}) => {
  let options = [
    <option value="" key="empty" disabled>
      Pick a company...
    </option>,
  ];
  options = options.concat(companies.map(company => (
    <option
      value={company.id}
      key={company.id}
    >
      {company.attributes.name}
    </option>
  )));
  const Select = styled("select")({
    width: 400,
    maxWidth: 400,
    marginBottom: 10,
    paddingRight: 25,
    fontSize: `${18 / 16}em`,
  });

  return (
    <form className="pure-form">
      <Select
        value={selectedCompanyId}
        onChange={(e) => { handleChange(e, index); }}
      >
        {options}
      </Select>
    </form>
  );
};

JourneyCompanyPicker.propTypes = {
  index: PropTypes.number.isRequired,
  companies: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedCompanyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default JourneyCompanyPicker;
