/** @jsx jsx */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { jsx, withTheme } from '@emotion/react';

import { subjectLinesSelectors as selectors } from '../../../../../modules/newReports/subjectLines';

import { Box, Flex, Loader } from '../../../../mc-ui';

import Chart from '../Chart';
import Subjects from '../Subjects';

class Overview extends React.Component {
  state = {
    subjectsFilter: 'all',
  };

  subjectsFilters = [
    { value: 'all', label: 'All' },
    { value: '< 25', label: '< 25 chars' },
    { value: '25 - 55', label: '25 - 55 chars' },
    { value: '55 - 120', label: '55 - 120 chars' },
    { value: '120+', label: '120+ chars' },
  ];

  changeSubjectsFilter = event => {
    event.preventDefault();
    const { filter } = event.target.dataset;

    this.setState(() => ({ subjectsFilter: filter }));
  };

  chartData = () => {
    const { data } = this.props;

    return [
      { color: 'blueMedium', label: '< 25', width: data.distribution['< 25'].value },
      { color: 'blueLight', label: '25 - 55', width: data.distribution['25 - 55'].value },
      { color: 'blueDark', label: '55 - 120', width: data.distribution['55 - 120'].value },
      { color: 'redDark', label: '120+', width: data.distribution['120+'].value },
    ];
  };

  filteredData = () => {
    const { data } = this.props;
    const { subjectsFilter } = this.state;

    if (subjectsFilter === 'all') {
      return data.distribution.allEmails;
    }

    return data.distribution[subjectsFilter].emails;
  };

  render() {
    const { endDate, loading, startDate, theme } = this.props;

    if (loading) {
      return (
        <Flex alignItems height="403px" justifyContent="center">
          <Loader />
        </Flex>
      );
    }

    const { subjectsFilter } = this.state;
    const { router } = this.context;
    const pathname = `${router.location.pathname.replace('/subject-lines', '')}/emails`;
    const query = subjectsFilter === 'all' ? {} : { subject_line_length: subjectsFilter };

    query.end_date = endDate;
    query.start_date = startDate;

    return (
      <Box minHeight="403px">
        <Chart data={this.chartData()} />
        <Subjects
          changeFilter={this.changeSubjectsFilter}
          data={this.filteredData()}
          filters={this.subjectsFilters}
          selectedFilter={subjectsFilter}
        />
        <Link
          to={{ pathname, query }}
          css={{
            color: theme.colors.blue,
            fontFamily: theme.font.family,
            fontSize: '14px',
            fontWeight: '500',
            lineHeight: '1.25em',
            textDecoration: 'none',
          }}
        >
          See more
        </Link>
      </Box>
    );
  }
}

Overview.contextTypes = {
  router: PropTypes.shape({}).isRequired,
};

Overview.defaultProps = {
  endDate: null,
  startDate: null,
};

Overview.propTypes = {
  data: PropTypes.shape({
    distribution: PropTypes.shape({
      allEmails: PropTypes.arrayOf(PropTypes.shape),
      '< 25': PropTypes.shape({
        docCount: PropTypes.number,
        total: PropTypes.number,
        value: PropTypes.number,
        emails: PropTypes.arrayOf(PropTypes.shape),
      }),
      '25 - 55': PropTypes.shape({
        docCount: PropTypes.number,
        total: PropTypes.number,
        value: PropTypes.number,
        emails: PropTypes.arrayOf(PropTypes.shape),
      }),
      '55 - 120': PropTypes.shape({
        docCount: PropTypes.number,
        total: PropTypes.number,
        value: PropTypes.number,
        emails: PropTypes.arrayOf(PropTypes.shape),
      }),
      '120+': PropTypes.shape({
        docCount: PropTypes.number,
        total: PropTypes.number,
        value: PropTypes.number,
        emails: PropTypes.arrayOf(PropTypes.shape),
      }),
    }),
  }).isRequired,
  endDate: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  startDate: PropTypes.string,
  theme: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state, { model }) => {
  const { endDate, startDate } = state.newReports.dates;

  return {
    data: selectors.getData(model.id, model.type, startDate, endDate)(state),
    loading: selectors.getLoading(model.id, model.type, startDate, endDate)(state),
    endDate,
    startDate,
  };
};

export default connect(mapStateToProps)(withTheme(Overview));
