import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { sort, ascend, isNil, path } from 'ramda';

import api from '../../../lib/api';
import { shallowEqual } from '../../../lib/utils';
import { userSelectors } from '../../../modules/user';

import { subjectLinesActions as actions } from '../../../modules/newReports/subjectLines';

import { ChartTooltip } from '../../mc-ui';

import HeaderContainer from '../HeaderContainer';
import DateSelect from '../DateSelect';
import MainWrapper from '../MainWrapper';
import Body from '../Body';
import Heading from '../Heading';

import AverageSubjectLineLength from './AverageSubjectLineLength';
import HistoricalComparison from './HistoricalComparison';
import MostPopularTerms from './MostPopularTerms';
import Restricted from './Restricted';
import SignificantTerms from './SignificantTerms';
import SubjectLinesByByLength from './SubjectLinesBy/Length';

const sortByName = sort(ascend(path(['attributes', 'name'])));

class SubjectLines extends React.Component {
  state = {
    brands: [],
  };

  componentDidMount() {
    if (!isNil(this.props.model.id)) {
      this.request(this.props);
      this.requestBrands(this.props);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.model.id) return;
    const nextModel = { id: nextProps.model.id, type: nextProps.model.type };
    const model = { id: this.props.model.id, type: this.props.model.type };
    const nextDates = { endDate: nextProps.endDate, startDate: nextProps.startDate };
    const dates = { endDate: this.props.endDate, startDate: this.props.startDate };

    if (!shallowEqual(nextModel, model) || !shallowEqual(nextDates, dates)) {
      this.request(nextProps);
    }

    if (!shallowEqual(nextModel, model)) {
      this.requestBrands(nextProps);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const nextModel = { id: nextProps.model.id, type: nextProps.model.type };
    const model = { id: this.props.model.id, type: this.props.model.type };
    const nextDates = { endDate: nextProps.endDate, startDate: nextProps.startDate };
    const dates = { endDate: this.props.endDate, startDate: this.props.startDate };

    return (
      !shallowEqual(nextModel, model) ||
      !shallowEqual(nextDates, dates) ||
      !shallowEqual(nextState, this.state)
    );
  }

  request = ({ model, endDate, startDate }) => {
    if (this.restricted()) return;
    this.props.fetchData(model.id, model.type, startDate, endDate);
  };

  requestBrands = async ({ model }) => {
    if (model.type === 'companies') return;
    const response = await api.get(`/v3/${model.type}/${model.id}/companies`);

    this.setState({ brands: sortByName(response.data.included) });
  };

  accessible = () => !this.restricted();

  restricted = () => {
    const { isUserRestricted, model } = this.props;
    if (!model.type) return false;
    if (model.attributes.featured) return false;
    if (isUserRestricted) window.analytics.track('User restricted', { type: `reports` });
    return isUserRestricted;
  };

  render() {
    const { model, router, ...props } = this.props;
    const { brands } = this.state;

    if (!model.id) return null;

    return (
      <MainWrapper>
        <HeaderContainer title="Subject lines">
          <DateSelect restricted={this.restricted()} model={model} router={router} {...props} />
        </HeaderContainer>

        {this.restricted() ? (
          <Restricted model={model} />
        ) : (
          <Body>
            <AverageSubjectLineLength model={model} router={router} />
            <Heading>Popular and significant terms</Heading>
            <MostPopularTerms brands={brands} model={model} />
            <SignificantTerms brands={brands} model={model} />
            <HistoricalComparison model={model} />
            <Heading>Subject line examples</Heading>
            <SubjectLinesByByLength brands={brands} model={model} />
          </Body>
        )}
        <ChartTooltip />
      </MainWrapper>
    );
  }
}

SubjectLines.defaultProps = {
  endDate: null,
  model: { id: null },
  startDate: null,
};

SubjectLines.propTypes = {
  endDate: PropTypes.string,
  fetchData: PropTypes.func.isRequired,
  isUserRestricted: PropTypes.bool.isRequired,
  model: PropTypes.shape({ id: PropTypes.string, type: PropTypes.string }),
  router: PropTypes.shape({
    location: PropTypes.shape({
      query: PropTypes.object,
      search: PropTypes.string,
    }),
  }).isRequired,
  startDate: PropTypes.string,
};

const mapStateToProps = state => ({
  endDate: state.newReports.dates.endDate,
  isUserRestricted: userSelectors.isUserRestrictedSelector(state),
  startDate: state.newReports.dates.startDate,
});

const mapDispatchToProps = dispatch => ({
  fetchData: (...args) => dispatch(actions.fetchDataRequest(...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SubjectLines);
