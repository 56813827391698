import React from 'react';
import PropTypes from 'prop-types';

import Flex from './Flex';
import ArrowLeftIcon from './icons/pagination/ArrowLeftIcon';
import ArrowRightIcon from './icons/pagination/ArrowRightIcon';
import Button from './pagination/Button';
import Results from './pagination/Results';

const RemotePagination = ({ from, nextUrl, onClick, prevUrl, selfUrl, to, total }) => {
  if (!selfUrl) return null;

  return (
    <Flex alignItems="center" justifyContent="center" css={{ marginTop: '14px' }}>
      <Button
        onClick={() => {
          onClick('prev');
        }}
        disabled={!prevUrl}
      >
        <ArrowLeftIcon />
      </Button>
      <Results>
        Showing {from} to {to} of {total}
      </Results>
      <Button
        onClick={() => {
          onClick('next');
        }}
        disabled={!nextUrl}
      >
        <ArrowRightIcon />
      </Button>
    </Flex>
  );
};

RemotePagination.defaultProps = {
  nextUrl: null,
  prevUrl: null,
  selfUrl: null,
};

RemotePagination.propTypes = {
  from: PropTypes.number.isRequired,
  nextUrl: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  prevUrl: PropTypes.string,
  selfUrl: PropTypes.string,
  to: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};

export default RemotePagination;
