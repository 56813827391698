import React from 'react';
import PropTypes from 'prop-types';

import { Flex, Text } from '../mc-ui';

export default function NotesBadge({ approximateComments }) {
  if (!approximateComments || approximateComments === 0) return null;

  return (
    <Flex
      alignItems="center"
      backgroundColor="orange"
      borderRadius="4px"
      justifyContent="center"
      marginLeft="8px"
      padding="4px"
      height="18px"
    >
      <Text color="white" fontSize="12px" fontWeight="500" lineHeight="12px">
        {approximateComments}
      </Text>
    </Flex>
  );
}

NotesBadge.defaultProps = {
  approximateComments: 0,
};

NotesBadge.propTypes = {
  approximateComments: PropTypes.number,
};
