import * as React from 'react';
import PropTypes from 'prop-types';

function DownloadIcon({ fillOpacity, ...props }) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 4h2v8.908l3.49-3.49a.89.89 0 011.26 1.258l-4.806 4.805a1.332 1.332 0 01-1.455.29 1.332 1.332 0 01-.433-.29L6.25 10.676A.89.89 0 017.51 9.417l3.49 3.49V4zM4 18v2h16v-2H4z"
        fill="currentColor"
        fillOpacity={fillOpacity}
      />
    </svg>
  );
}

DownloadIcon.propTypes = {
  fillOpacity: PropTypes.number,
};

DownloadIcon.defaultProps = {
  fillOpacity: 1,
};

export default DownloadIcon;
