import React from 'react';
import PropTypes from 'prop-types';
import { camelizeKeys } from 'humps';
import { isEmpty, isNil, omit } from 'ramda';
import { Link } from 'react-router';

import api from '../../../lib/api';

import Section from '../../Section';

import { Box, Button, Flex, Heading, Tooltip, theme } from '../../mc-ui';
import FYICircleIcon from '../../mc-ui/icons/FYICircleIcon';
import List from '../../NewJourneys/JourneysList/List';

import FilterBar from '../FilterBar';

import Filters from './Filters';

const DEFAULT_STATUS = 'classified';
const DEFAULT_SORT = '-start_date';

const isNotPresent = value => isEmpty(value) || isNil(value);

class Results extends React.Component {
  state = {
    sort: this.props.location.query.sort || DEFAULT_SORT,
    status: this.props.location.query.status || DEFAULT_STATUS,
    journeys: {},
    loading: true,
    type: this.props.location.query.journey_type || null,
  };

  componentDidMount() {
    this.request();
  }

  componentDidUpdate(prevProps) {
    const {
      location: { query },
    } = this.props;
    const {
      location: { query: prevQuery },
    } = prevProps;

    if (
      query.company_id !== prevQuery.company_id ||
      query.sort !== prevQuery.sort ||
      query.status !== prevQuery.status ||
      query.group_id !== prevQuery.group_id ||
      query.journey_type !== prevQuery.journey_type ||
      query.page !== prevQuery.page
    ) {
      this.request();
    }

    if (query.journey_type !== prevQuery.journey_type) {
      this.updateType({ value: query.journey_type });
    }
  }

  request = () => {
    this.setState(
      () => ({ loading: true }),
      async () => {
        const {
          location: { query: locationQuery },
        } = this.props;

        const query = Object.keys(locationQuery)
          .map(key => `${key}=${locationQuery[key]}`)
          .join('&');

        const response = await api.get(`/v3/journeys?${query}`);

        this.setState(() => ({
          journeys: {
            ...camelizeKeys(response.data),
          },
          loading: false,
        }));
      },
    );
  };

  updateSort = ({ value }) => {
    this.setState(() => ({ sort: value }), this.updateQuery);
  };

  updateStatus = ({ value }) => {
    this.setState(() => ({ status: value }), this.updateQuery);
  };

  updateType = ({ value }) => {
    this.setState(() => ({ type: value }), this.updateQuery);
  };

  updateQuery = (paginationQuery = {}) => {
    const {
      location: { pathname },
      router,
    } = this.props;
    const { sort, status, type } = this.state;
    const currentQuery = omit('page', this.props.location.query);

    const query = { ...currentQuery, sort, status, journey_type: type, ...paginationQuery };
    if (!sort) delete query.sort;
    if (!status) delete query.status;
    if (!type) delete query.journey_type;
    if (!query.page) delete query.page;

    router.push({ pathname, query });
  };

  render() {
    const {
      location: { query },
    } = this.props;
    const {
      sort,
      status,
      journeys: { data, links, meta },
      loading,
      type,
      ...state
    } = this.state;

    const pathPrefix = query.company_id
      ? `/companies/${query.company_id}/journeys`
      : `/groups/${query.group_id}/journeys`;

    return (
      <div>
        <Section>
          <Box paddingBottom="40px" paddingTop="25px">
            <Flex alignItems="center" marginBottom="20px">
              <Heading as="h3" fontSize="24px" fontWeight="300" lineHeight="32px">
                Search the <strong>journeys</strong>
              </Heading>

              <Tooltip
                id="journeysResultsTooltip"
                element={
                  <Box
                    inline
                    height="24px"
                    width="24px"
                    css={{
                      color: theme.colors.dark06,
                      '&:hover': {
                        color: theme.colors.dark1,
                      },
                    }}
                  >
                    <FYICircleIcon height="24px" width="24px" />
                  </Box>
                }
                variant="blue"
                css={{
                  height: '24px',
                  marginLeft: '8px',
                  width: '24px',
                  '& span': {
                    display: 'inline-block',
                    height: '24px',
                    width: '24px',
                  },
                  '& [data-id="tooltip"]': {
                    marginLeft: '0 !important',
                  },
                }}
              >
                <p>
                  Learn what types of email journeys MailCharts collects, how often are new journeys
                  triggered for each brand and more on our FAQ page.
                </p>

                <Button
                  as={Link}
                  to="https://knowledge.validity.com/hc/en-us/sections/6839689729819-FAQ"
                  target="_blank"
                  variant="primary"
                  css={{
                    alignItems: 'center !important',
                    display: 'inline-flex !important',
                    justifyContent: 'center !important',
                    height: '32px !important',
                    width: '104px !important',
                  }}
                >
                  Read more
                </Button>
              </Tooltip>
            </Flex>

            <FilterBar showBackButton />
          </Box>
        </Section>

        <Section outerBackground="#FFF">
          <Box paddingBottom="32px" paddingTop="32px">
            <Filters
              classificationTypes={(meta || {}).classificationTypes || []}
              sort={sort}
              status={status}
              journeyTypes={(meta || {}).journeyTypes || []}
              loading={loading}
              type={type}
              updateSort={this.updateSort}
              updateStatus={this.updateStatus}
              updateType={this.updateType}
              {...this.props}
              {...state}
            />

            <List
              data={data || []}
              isJourneysListEmpty={isNotPresent(data)}
              links={links || {}}
              loading={loading}
              meta={meta || {}}
              noPadding
              pathPrefix={pathPrefix}
              updateQuery={this.updateQuery}
              {...this.props}
            />
          </Box>
        </Section>
      </div>
    );
  }
}

Results.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    query: PropTypes.shape({
      sort: PropTypes.string,
      status: PropTypes.string,
      journey_type: PropTypes.string,
    }),
  }).isRequired,
  router: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default Results;
