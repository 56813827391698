/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router';

import { Box, Flex, Heading, Text, Tooltip } from '../../../mc-ui';
import CheckCircleIcon from '../../../mc-ui/icons/CheckCircleIcon';
import QuestionMarkIcon from '../../../mc-ui/icons/QuestionMarkIcon';


const formatDate = date => moment(date).format('MMM D, YYYY');

import browseCuratedEmails from './browseCuratedEmails.png';
import browseJourneys from './browseJourneys.png';
import viewAllEmails from './viewAllEmails.png';

const NoTriggeredEmailsReceived = ({ journey }) => {
  const [brand] = journey.relationships.companies.data;
  const events = journey.relationships.events.data;

  return (
    <Box backgroundColor="white" borderRadius="4px" marginBottom="32px" padding="32px">
      <Box marginBottom="35px">
        <Heading as='h4' color="dark08">
          No triggered emails received.
        </Heading>
        <Text color="dark08" lineHeight="1.56em" css={{ padding: "10px 0px" }}>
          What does this mean? MailCharts initiated a journey but didn't receive any{' '}
          triggered emails. Most often, this means that {brand.attributes.name} does{' '}
          not have an active {journey.attributes.type.replace(/_/g, ' ')} journey.
        </Text>
        {events.map(event => (
          <Flex key={event.id} alignItems="center" marginBottom="16px">
            <Box height="24px" marginRight="10px" width="24px">
              <Text color="orangeMedium" fontSize="24px" lineHeight="1em">
                <CheckCircleIcon />
              </Text>
            </Box>

            <Text color="dark08" lineHeight="1.56em">
              <strong>{formatDate(event.attributes.occurredAt)}</strong> -{' '}
              {event.attributes.label || event.attributes.description}
            </Text>
          </Flex>
        ))}
      </Box>

      <Text color="dark08" lineHeight="1.56em">
        Explore other journey emails below:
      </Text>

      <Flex justifyContent="space-between" marginTop="8px">
        <Link
          to="/emails"
          css={{ textDecoration: 'none' }}
        >
          <Flex
            alignItems="center"
            backgroundColor="dark6"
            borderRadius="8px"
            direction="column"
            height="240px"
            padding="24px 32px 0 32px"
            width="240px"
          >
            <Text
              color="blue"
              fontWeight="500"
              lineHeight="1.25em"
              textAlign="center"
              width="155px"
            >
              Browse curated emails
            </Text>

            <img
              alt="Browse Curated Emails"
              src={browseCuratedEmails}
              height="122px"
              width="122px"
              css={{ marginTop: '30px' }}
            />
          </Flex>
        </Link>

        <Link
          to={{
            pathname: '/journeys/results',
            query: { journey_type: journey.attributes.type },
          }}
          css={{ textDecoration: 'none' }}
        >
          <Flex
            alignItems="center"
            backgroundColor="dark6"
            borderRadius="8px"
            direction="column"
            height="240px"
            padding="24px 32px 0 32px"
            width="240px"
          >
            <Text
              color="blue"
              fontWeight="500"
              lineHeight="1.25em"
              textAlign="center"
              width="155px"
            >
              Browse {journey.attributes.type.replace(/_/g, ' ')} journeys
            </Text>

            <img
              alt={`Browse ${journey.attributes.type.replace(/_/g, ' ')} journeys`}
              src={browseJourneys}
              height="118px"
              width="114px"
              css={{ marginTop: '34px' }}
            />
          </Flex>
        </Link>

        <Link
          to={{
            pathname: `/companies/${brand.id}/emails`,
            query: { segment_id: journey.attributes.addressId, sort: 'asc', },
          }}
          css={{ textDecoration: 'none' }}
        >
          <Flex
            alignItems="center"
            backgroundColor="dark6"
            borderRadius="8px"
            direction="column"
            height="240px"
            padding="24px 32px 0 32px"
            width="240px"
          >
            <Flex alignItems="center" justifyContent="center">
              <Text color="blue" fontWeight="500" lineHeight="1.25em" textAlign="center">
                View the full inbox
              </Text>

              <Box
                color="blue"
                marginLeft="5px"
                width="auto"
                css={{
                  display: 'inline',
                  fontSize: '18px',
                  lineHeight: '1em',
                }}
              >
                <Tooltip element={<QuestionMarkIcon fillOpacity={1} />} id="viewAllEmails" dark>
                  <Heading as="h3">See the full inbox</Heading>
                  <Text>
                    View all non-triggered emails that were sent to this MailCharts inbox.
                  </Text>
                </Tooltip>
              </Box>
            </Flex>

            <img
              alt="View all emails"
              src={viewAllEmails}
              height="155px"
              width="147px"
              css={{ marginTop: '15px' }}
            />
          </Flex>
        </Link>
      </Flex>
    </Box>
  );
};

NoTriggeredEmailsReceived.propTypes = {
  journey: PropTypes.shape({}).isRequired,
};

export default NoTriggeredEmailsReceived;
