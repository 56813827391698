/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import { DateRangePicker as DatePicker } from 'react-dates';

import ArrowLeftIcon from './icons/ArrowLeftIcon';
import ArrowRightIcon from './icons/ArrowRightIcon';
import CalendarIcon from './icons//CalendarIcon';

import Wrapper from './dateRangePicker/Wrapper';

const DateRangePicker = ({ css, disabled, label, ...props }) => (
  <Wrapper css={css}>
    <CalendarIcon height="20px" width="20px" />
    <DatePicker
      customArrowIcon={<ArrowRightIcon />}
      disabled={disabled}
      hideKeyboardShortcutsPanel
      isOutsideRange={() => false}
      navNext={<ArrowRightIcon />}
      navPrev={<ArrowLeftIcon />}
      noBorder
      transitionDuration={100}
      {...props}
    />
    {label && <label>{label}</label>}
  </Wrapper>
);

DateRangePicker.propTypes = {
  css: PropTypes.shape({}),
  disabled: PropTypes.bool,
  label: PropTypes.string,
};

DateRangePicker.defaultProps = {
  css: {},
  disabled: false,
  label: null,
};

export default DateRangePicker;
