import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { Box, Button, Flex, Heading, Text, Checkbox, TextField, Label, Spinner, }from '../../../mc-ui';
import StripeCheckoutForm from '../StripeCheckoutForm';
import { couponActions, couponSelectors } from "../../../../modules/coupon";


import CheckBigIcon from "../../../mc-ui/icons/CheckBigIcon";
import WarningTriangleIcon from "../../../mc-ui/icons/WarningTriangleIcon";

class CheckoutForm extends React.Component {

  calculateCoupon = event => {
    event.preventDefault();
    const { value } = event.target;
    props.fetchCoupon({ id: value });
  };

  render() {
    const {
      interval,
      team,
      isPro,
      onCancel,
      onSubmit,
      planExpired,
      hasSmsAddon,
      isCancelingSubscription,
      amount,
      isSubmitting,
      onAddressChange
    } = this.props;
    const isActive = team && team.attributes.account_status === 'active' && !planExpired;

    const INITIAL_VALUES = {
      card: {
        exp_month: moment()
          .add(1, 'month')
          .format('MM'),
        exp_year: moment().format('YY'),
      },
      sms_addon: hasSmsAddon,
    };

    return (
      <Box>
        <Form initialValues={INITIAL_VALUES} onSubmit={onSubmit}>
          {({ form, handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>
              {!isActive && <StripeCheckoutForm
                isCancelingSubscription={isCancelingSubscription}
                onAddressChange={onAddressChange}
              />}

        {this.props.showCouponField &&
          <Label htmlFor="coupon" width="348px" css={{ position: 'relative', marginTop: '5px' }}>
            Coupon <small>(optional)</small>
            <Field
              autoComplete="off"
              component={TextField}
              error={this.props.couponStatus === 'invalid' ? 'Invalid code' : null}
              id="coupon"
              invalid={this.props.couponStatus === 'invalid'}
              name="coupon"
              onChange={this.calculateCoupon}
            />
              {this.props.couponIsLoading && 
                <Spinner
                  color="dark06"
                  size="20px"
                  css={{
                    position: 'absolute',
                    right: '8px',
                    top: '25px',
                  }}
                />
              }
              {this.props.couponStatus && 
                <Box
                  color={this.props.couponStatus === 'valid' ? 'greenMedium' : 'red1'}
                  height="20px"
                  margin="0"
                  padding="0"
                  position="absolute"
                  right="8px"
                  top="32px"
                  width="20px"
                >
                {this.props.couponStatus === 'valid' ? <CheckBigIcon height="20px" width="20px" /> : <WarningTriangleIcon height="20px" width="20px" />}
                </Box>
              }
              </Label>
            }
            <Box css={{ marginTop: '35px' }}>
              <Heading as="h2" fontSize="16px" lineHeight="20px" css={{ marginBottom: '4px' }}>
                Add-Ons
              </Heading>

              <Text margin="0 0 5px 0">Cancel add-ons at any time.</Text>

              <Flex justifyContent="space-between" width="712px">
                {interval == "month" ?
                  <Field
                    component={Checkbox}
                    type="checkbox"
                    id="sms_addon"
                    name="sms_addon"
                    label="Include SMS Campaigns (Additional $49/month)"
                    disabled={isCancelingSubscription}
                  />
                  :
                  <Field
                    component={Checkbox}
                    type="checkbox"
                    id="sms_addon"
                    name="sms_addon"
                    label="Include SMS Campaigns (Additional $499/year)"
                    disabled={isCancelingSubscription}
                  />}
              </Flex>
              <Text margin="0px 0px 16px 30px" fontSize="11px">*When adding SMS in the middle of your billing cycle, your card will be charged for the prorated amount.</Text>
            </Box>

              <Flex alignItems="center" justifyContent="space-between">
                <Box>
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant={isPro ? 'primary' : 'purple'}
                    css={{
                      height: '32px',
                      lineHeight: '32px',
                      margin: '0 16px 0 0',
                      padding: 0,
                      width: isActive ? '165px' : '224px',
                    }}
                  >
                    {isActive ? 'Update Subscription' : 'Upgrade to MailCharts Pro'}
                  </Button>

                  <Button
                    css={{
                      height: '32px',
                      lineHeight: '32px',
                      margin: 0,
                      padding: 0,
                      width: '72px',
                    }}
                    disabled={isActive && (submitting)}
                    onClick={isActive ? form.reset : onCancel}
                    variant="secondary"
                  >
                    Cancel
                  </Button>
                </Box>
              </Flex>
            </form>
          )}
        </Form>
      </Box>
    );
  };
}

CheckoutForm.defaultProps = {
  isPro: false,
  team: null,
};

CheckoutForm.propTypes = {
  isPro: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  planExpired: PropTypes.bool.isRequired,
  hasSmsAddon: PropTypes.bool.isRequired,
  isCancelingSubscription: PropTypes.bool.isRequired,
  team: PropTypes.shape({
    attributes: PropTypes.shape({
      account_status: PropTypes.string,
    }),
  }),
};

const mapStateToProps = state => ({
    coupon: couponSelectors.couponSelector(state),
    couponIsLoading: couponSelectors.isLoadingSelector(state),
    couponStatus: couponSelectors.statusSelector(state),
})

const mapDispatchToProps = dispatch => ({
  fetchCoupon: (...args) => dispatch(couponActions.fetchCouponRequest(...args))
});

export default connect(mapStateToProps,mapDispatchToProps)(CheckoutForm);