import React from 'react';
import PropTypes from 'prop-types';

import FYICircleIcon from '../../mc-ui/icons/FYICircleIcon';
import { Box, Button, Flex, Heading, Text, Tooltip, theme } from '../../mc-ui';

// eslint-disable-next-line react/prop-types
function LookingForASpecificEmailTooltip({ css = {} }) {
  return (
    <Tooltip
      variant="blue"
      element={
        <Flex
          alignItems="center"
          inline
          height="24px"
          width="24px"
          css={{ color: theme.colors.dark06, '&:hover': { color: theme.colors.dark1 } }}
        >
          <FYICircleIcon height="24px" width="24px" />
        </Flex>
      }
      id="advancedFiltersTooltip"
      css={{
        height: '24px',
        position: 'absolute',
        width: '24px',
        ...css,
        '& > span': {
          display: 'inline-block',
          height: '24px',
          margin: '0',
          width: '24px',
        },
        '& [data-id="tooltip"]': {
          marginLeft: '0 !important',
        },
      }}
    >
      <Heading as="h5">Looking for a specific email?</Heading>
      <Text>
        Watch a two-minute video to learn how to find emails that you need and create custom list of
        emails that you can share with your team.
      </Text>
      <Button
        as="a"
        href="https://youtu.be/xoUhBnC6D5M"
        target="_blank"
        css={{
          alignItems: 'center',
          display: 'inline-flex',
          height: '32px !important',
          justifyContent: 'center',
          margin: '0',
          padding: '0',
          width: '104px !important',
        }}
      >
        Watch now
      </Button>
    </Tooltip>
  );
}

const Header = ({ type }) => {
  if (type === 'lists') {
    return (
      <Box marginBottom="24px">
        <Heading as="h4" fontSize="18px" lineHeight="24px">
          Most recently updated email lists
        </Heading>
      </Box>
    );
  }

  if (type === 'journeys') {
    return (
      <Box marginBottom="24px">
        <Heading as="h4" fontSize="18px" lineHeight="24px" css={{ marginBottom: '9px' }}>
          Here’s what’s coming!
        </Heading>
        <Text>
          We’re actively signing up, triggering, and organizing emails. Below are a few journeys
          that will be completed soon.
        </Text>
      </Box>
    );
  }

  if (type === 'customerJourneysList') {
    return (
      <Box marginBottom="24px">
        <Box position="relative">
          <Heading
            as="h3"
            color="dark1"
            fontSize="24px"
            fontWeight="300"
            lineHeight="32px"
            css={{ display: 'inline-block', marginBottom: '8px' }}
          >
            Curated examples of lifecycle <strong>emails</strong>
          </Heading>

          <LookingForASpecificEmailTooltip css={{ bottom: '12px', left: '182px' }} />
        </Box>

        <Text>We’ve hand-picked thousands of emails to get inspiration from.</Text>
      </Box>
    );
  }

  if (type === 'upcomingHolidays') {
    return (
      <Box marginBottom="24px" position="relative">
        <Heading
          as="h3"
          color="dark1"
          fontSize="24px"
          fontWeight="300"
          lineHeight="32px"
          css={{ marginBottom: '8px' }}
        >
          Upcoming <strong>Holidays</strong>
        </Heading>

        <LookingForASpecificEmailTooltip css={{ bottom: '12px', right: '20px' }} />
      </Box>
    );
  }

  if (type === 'mostTrackedBrands') {
    return (
      <Box marginBottom="24px">
        <Heading as="h4" fontSize="18px" lineHeight="24px" css={{ marginBottom: '8px' }}>
          What’s hot?
        </Heading>
        <Text>Check out some of the most popular brands in the MailCharts Index.</Text>
      </Box>
    );
  }

  return null;
};

Header.propTypes = {
  type: PropTypes.string.isRequired,
};

export default Header;
