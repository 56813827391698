import React from 'react';

import { Text } from '../mc-ui';

export default function DisabledText() {
  return (
    <Text color="dark08" width="592px">
      Click “Enable” and start receiving weekly email full of data that matters to you. It will help
      you stay informed, prepare for meetings, report on competitors and share insights with your
      team!
    </Text>
  );
}
