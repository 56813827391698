import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { descend, path, sort, take } from 'ramda';
import { withTheme } from '@emotion/react';

import { Box, Flex, Table } from '../../../mc-ui';
import BrandLogo from '../../../ui/BrandLogo';
import OverviewTrendingLineChart from '../../../OverviewTrendingLineChart';

import NegativeIcon from '../../../newReports/stats/NegativeIcon';
import PositiveIcon from '../../../newReports/stats/PositiveIcon';

import Heading from '../Heading';
import Text from '../Text';

const sortBrands = sort(descend(path(['meta', 'stats', 'promotional', 'value'])));
const fixedValue = value => {
  let number = null;
  if (!value || value === 'NaN' || Number.isNaN(value)) {
    number = 0;
  } else {
    number = Number(value);
  }

  return number.toFixed(2);
};

const calcDiff = (previousValue, currentValue) => {
  const diff = currentValue - previousValue;
  const result = (diff / previousValue) * 100;

  if (Number.isNaN(result)) {
    return 0;
  }
  return result;
};

const Highlights = ({ data, model, theme }) => {
  const topBrands = take(4, sortBrands(data.brands));
  const previousPeriodDiff = calcDiff(
    data.previous.promotional.value,
    data.current.promotional.value,
  );

  let DiffIcon = null;
  if (previousPeriodDiff > 0) {
    DiffIcon = PositiveIcon;
  } else if (previousPeriodDiff < 0) {
    DiffIcon = NegativeIcon;
  }

  return (
    <Flex justifyContent="space-between">
      <Box width="360px">
        <Flex justifyContent="space-between">
          <Box>
            <Heading>AVG Promo Rate</Heading>
            <Text css={{ lineHeight: '32px' }}>{fixedValue(data.current.promotional.value)}%</Text>
          </Box>

          <Box>
            <Heading>vs Previous Period</Heading>
            {previousPeriodDiff === 0 ? (
              <Text color="dark4" css={{ fontSize: '18px', fontWeight: '400', lineHeight: '32px' }}>
                No Change
              </Text>
            ) : (
              <Text
                variant="bold"
                css={{
                  alignItems: 'center',
                  display: 'flex',
                  lineHeight: '32px',
                  '& > svg': { marginRight: '8px' },
                }}
              >
                <DiffIcon height="24px" width="24px" />{' '}
                {fixedValue(previousPeriodDiff < 0 ? previousPeriodDiff * -1 : previousPeriodDiff)}%
              </Text>
            )}
          </Box>
        </Flex>

        <OverviewTrendingLineChart chart="promotional" model={model} />
      </Box>

      <Flex direction="column" width="344px">
        <Heading>Most Promotional Rate by Brand</Heading>

        <Table css={{ marginTop: '16px', width: '344px' }}>
          <Table.Body>
            {topBrands.map((brand, index) => (
              <Table.Row key={`mostActiveBrand-${brand.id}`} css={{ border: 'none' }}>
                <Table.IndexCell verticalAlign="center" index={index} page={1} pageSize={1} />
                <Table.Cell width="34px">
                  <Link to={`/companies/${brand.id}`}>
                    <BrandLogo brand={brand} css={{ height: '24px', width: '24px' }} />
                  </Link>
                </Table.Cell>
                <Table.Cell
                  css={{
                    '& > a': {
                      color: theme.colors.dark08,
                    },
                  }}
                >
                  <Link to={`/companies/${brand.id}`}>{brand.attributes.name}</Link>
                </Table.Cell>
                <Table.Cell align="right">
                  {brand.meta.stats.promotional.value.toFixed(2)}%
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Flex>
    </Flex>
  );
};

Highlights.propTypes = {
  data: PropTypes.shape({
    brands: PropTypes.arrayOf(PropTypes.shape({})),
    promotional: PropTypes.shape({
      avg: PropTypes.number,
      delta: PropTypes.number,
      previous: PropTypes.number,
    }),
  }).isRequired,
  model: PropTypes.shape({}).isRequired,
  theme: PropTypes.shape({}).isRequired,
};

export default withTheme(Highlights);
