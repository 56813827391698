import { ascend, assoc, assocPath, merge, path, reject, sort } from 'ramda';

import * as types from './types';

const sortByName = sort(ascend(path(['attributes', 'name'])));

export const INITIAL_STATE = {
  data: [],
  error: null,
  loading: true,
  subscription: {
    calculate: {
      result: {
        data: [],
        loading: true,
      },
    },
  },
  setupIntent: {},
  paymentInfo: {}
};

const toggleLoadingReducer = (state, { updateLoading }) => {
  if (!updateLoading) return state;
  return assoc('loading', !state.loading, state);
};

const toggleErrorReducer = state => assoc('error', !state.error, state);

const setTeamDataReducer = (state, { data }) => merge(state, { loading: false, ...data });

// This reducer instantly updates the UI by adding the new member without needing to wait for the // API response
const addMemberReducer = (state, { teamId, payload }) => {
  const member = {
    id: new Date().getTime().toString(),
    type: 'users',
    attributes: {
      email: payload.email,
      name: payload.email,
      role: payload.role,
      status: 'pending',
      team_id: Number(teamId),
    },
  };

  const members = sortByName([].concat(state.data[0].relationships.members.data, [member]));

  return assocPath(['data', 0, 'relationships', 'members', 'data'], members, state);
};

// This reducer instantly updates the UI by removing the member without needing to wait for the API response
const removeMemberReducer = (state, { id }) => {
  const members = reject(
    m => m.id.toString() === id.toString(),
    state.data[0].relationships.members.data,
  );

  return assocPath(['data', 0, 'relationships', 'members', 'data'], members, state);
};

// This reducer updates the given member locally so we don't need to hit the API again
const archiveMemberReducer = (state, { id }) => {
  const members = state.data[0].relationships.members.data;
  const index = members.findIndex(m => m.id.toString() === id.toString());

  if (index === -1) return state;

  return assocPath(
    ['data', 0, 'relationships', 'members', 'data', index, 'attributes', 'status'],
    'archived',
    state,
  );
};

// This reducer updates the given member locally so we don't need to hit the API again
const updateMemberReducer = (state, { id, payload }) => {
  const members = state.data[0].relationships.members.data;
  const index = members.findIndex(m => m.id.toString() === id.toString());

  if (index === -1) return state;

  return assocPath(
    ['data', 0, 'relationships', 'members', 'data', index, 'attributes'],
    { ...state.data[0].relationships.members.data[index].attributes, ...payload },
    state,
  );
};

const setSubscriptionCalculateLoaderReducer = state =>
  assocPath(['subscription', 'calculate', 'result', 'loading'], true, state);

const setSubscriptionCalculateResultReducer = (state, { data }) =>
  assocPath(['subscription', 'calculate', 'result'], { loading: false, ...data }, state);

const setSubscriptionSetupIntentReducer = (state, {data}) =>
   assoc('setupIntent', data, state)

const setSubscriptionPaymentInfoReducer = (state, { data }) =>
  assoc('paymentInfo', data, state);

const resetReducer = () => INITIAL_STATE;

const reducer = {
  [types.FETCH_TEAM_REQUEST]: toggleLoadingReducer,
  [types.FETCH_TEAM_SUCCESS]: setTeamDataReducer,
  [types.FETCH_TEAM_FAILURE]: toggleErrorReducer,
  [types.INVITE_MEMBER_REQUEST]: addMemberReducer,
  [types.REMOVE_MEMBER_SUCCESS]: archiveMemberReducer,
  [types.UPDATE_MEMBER_REQUEST]: updateMemberReducer,
  [types.CANCEL_INVITATION_REQUEST]: removeMemberReducer,
  [types.LEAVE_TEAM_SUCCESS]: archiveMemberReducer,
  [types.CREATE_SUBSCRIPTION_SUCCESS]: setTeamDataReducer,
  [types.SUBSCRIPTION_CALCULATE_REQUEST]: setSubscriptionCalculateLoaderReducer,
  [types.SUBSCRIPTION_CALCULATE_SUCCESS]: setSubscriptionCalculateResultReducer,
  [types.SUBSCRIPTION_CANCEL_SUCCESS]: resetReducer,
  [types.SUBSCRIPTION_SETUP_PAYMENT_INTENT]: setSubscriptionSetupIntentReducer,
  [types.SUBSCRIPTION_SETUP_PAYMENT_INFO]: setSubscriptionPaymentInfoReducer,
};

export default (state = INITIAL_STATE, action = {}) => {
  const reducerFunction = reducer[action.type];

  if (!reducerFunction) return state;
  return reducerFunction(state, action);
};
