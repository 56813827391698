import React from 'react';
import PropTypes from 'prop-types';
import Link from 'react-router/lib/Link';
import humanize from 'humanize-number';
import pluralize from 'pluralize';
import { connect } from 'react-redux';
import { isEmpty, isNil } from 'ramda';

import {
  savedJourneysActions as actions,
  savedJourneysSelectors as selectors,
} from '../../../modules/savedJourneys';

import { Box, Button, Flex, Text } from '../../mc-ui';

import JourneyLine from '../../NewJourneys/JourneysList/JourneyLine';

import ControlBar from '../ControlBar';
import NoSavedJourneys from './NoSavedJourneys';

const isNotPresent = value => isNil(value) || isEmpty(value);

class SavedJourneys extends React.Component {
  componentDidMount() {
    this.props.fetchTeamSavedJourneys();
  }

  componentDidUpdate(prevProps) {
    if (this.props.collectionType !== prevProps.collectionType) {
      this.props.fetchTeamSavedJourneys();
    }
  }

  render() {
    const { collectionType, journeys } = this.props;

    return (
      <Box>
        <ControlBar collectionType={collectionType} />

        <Flex alignItems="center" justifyContent="space-between" marginBottom="24px">
          <Text>
            {humanize(journeys.meta.total)} {pluralize('journey', journeys.meta.total)}
          </Text>

          <Button
            as={Link}
            to="/journeys/results"
            css={{
              alignItems: 'center',
              justifyContent: 'center',
              display: 'inline-flex',
              margin: '0',
              padding: '0',
              height: '32px',
              width: '140px',
            }}
          >
            Browse Journeys
          </Button>
        </Flex>

        {isNotPresent(journeys.data) ? (
          <NoSavedJourneys />
        ) : (
          <Box backgroundColor="white" borderRadius="4px" padding="16px 0">
            {journeys.data.map(journey => (
              <JourneyLine
                key={`${collectionType}-SavedJourneys-${journey.id}`}
                journey={journey}
              />
            ))}
          </Box>
        )}
      </Box>
    );
  }
}

SavedJourneys.propTypes = {
  collectionType: PropTypes.string.isRequired,
  fetchTeamSavedJourneys: PropTypes.func.isRequired,
  journeys: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state, { collectionType }) => ({
  journeys: selectors.dataSelector(collectionType)(state),
});

const mapDispatchToProps = dispatch => ({
  fetchTeamSavedJourneys: () => dispatch(actions.fetchTeamSavedJourneysRequest()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SavedJourneys);
