/** @jsx jsx */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router';
import { isEmpty } from 'ramda';
import { jsx, withTheme } from '@emotion/react';

import Badge from '../../../mc-ui/Badge';
import { Box, Flex, Heading, Text } from '../../../mc-ui';

import BrandLogo from '../../../ui/BrandLogo';

import DataPoints from '../../DataPoints';

import Emails from './Emails';
import JourneysModal from './RelatedJourneys/JourneysModal';
import RelatedJourneys from './RelatedJourneys';
import SaveJourneyDropdown from './SaveJourneyDropdown';
import TypeBadge from './TypeBadge';
import VerticalDotsDropdown from './VerticalDotsDropdown';
import ShareJourneyButton from './ShareJourneyButton/indes';

const isInTheFuture = date => moment(date).isAfter(moment(new Date()));

class JourneyLine extends React.Component {
  state = {
    showRelatedModal: false,
  };

  toggleRelatedModal = event => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.setState(currentState => ({ showRelatedModal: !currentState.showRelatedModal }));
  };

  upcoming = () => {
    const { journey } = this.props;
    const { startDate } = journey.attributes;

    if (isInTheFuture(startDate)) {
      return true;
    }

    return false;
  };

  renderLeftContent = () => {
    const { journey, showRelated } = this.props;
    const emails = journey.relationships.emails.data;
    const relatedJourneys = (journey.relationships.journeys || {}).data || [];

    if (!isEmpty(emails) || showRelated) {
      return (
        <Flex alignItems="flex-end" direction="column" height="126px" width="unset">
          {isEmpty(emails) ? (
            <Flex alignItems="center" height="44px" marginBottom="22px" width="unset">
              <ShareJourneyButton journey={journey} />
              <SaveJourneyDropdown journey={journey} />
            </Flex>
          ) : (
            <Flex alignItems="center" marginBottom="22px" width="unset">
              <Emails emails={emails} />
              <ShareJourneyButton journey={journey} />
              <SaveJourneyDropdown journey={journey} />
            </Flex>
          )}

          {showRelated && (
            <RelatedJourneys
              currentJourney={journey}
              journeys={relatedJourneys}
              toggleModal={this.toggleRelatedModal}
            />
          )}
        </Flex>
      );
    }

    return (
      <Flex alignItems="center" height="44px" marginBottom="22px" width="unset">
        <ShareJourneyButton journey={journey} />
        <SaveJourneyDropdown journey={journey} />
      </Flex>
    );
  };

  render() {
    const { current, journey, noPadding, theme } = this.props;
    const { router } = this.context;
    const { showRelatedModal } = this.state;

    const [brand] = journey.relationships.companies.data;
    const groupId = router.location.query.group_id || router.params.group_id;
    const path = groupId ? `/groups/${groupId}` : `/companies/${brand.id}`;
    const pathname = `${path}/journeys/${journey.id}`;

    return (
      <Box position="relative">
        <Link
          to={{ pathname, state: router.location.query }}
          css={{
            boxSizing: 'border-box',
            color: 'unset',
            cursor: 'pointer',
            display: 'block',
            textDecoration: 'none',
            padding: noPadding ? '0 3px' : '0 32px',
            '& *': {
              cursor: 'pointer',
            },
            '&:hover': {
              border: `3px solid ${theme.colors.blueLight}`,
              borderRadius: '4px',
              padding: noPadding ? '0' : '0 29px',
              '& > div': {
                padding: '21px 0',
              },
            },
          }}
        >
          <Flex
            alignItems={this.upcoming() ? 'center' : 'flex-start'}
            backgroundColor={current ? 'dark6' : 'white'}
            justifyContent="space-between"
            padding="24px 0"
            css={{ borderBottom: '1px solid rgba(9, 30, 66, 0.1)' }}
          >
            <Flex direction="column" width={this.upcoming() ? '240px' : '500px'}>
              <Flex marginBottom="24px">
                <BrandLogo
                  brandId={Number(brand.id)}
                  css={{ height: '40px', marginRight: '16px', width: '40px' }}
                />
                <div>
                  <Heading
                    as="h4"
                    css={{
                      fontSize: '14px',
                      letterSpacing: '0.02em',
                      lineHeight: '1.25em',
                      margin: '0 !important',
                      padding: '0 !important',
                    }}
                  >
                    {brand.attributes.name}
                  </Heading>

                  <Flex alignItems="center" width="unset">
                    <TypeBadge type={journey.attributes.type} />
                    {journey.attributes.featured && (
                      <Badge
                        backgroundColor="yellowMedium"
                        color="dark1"
                        fontSize="10px"
                        letterSpacing="0.08em"
                        lineHeight="1.56em"
                        padding="4px 7.625px"
                        css={{ marginTop: 5 }}
                      >
                        Unlocked
                      </Badge>
                    )}
                    {current && (
                      <Text
                        fontSize="12px"
                        fontWeight="500"
                        lineHeight="1.25em"
                        letterSpacing="0.02em"
                        css={{ marginLeft: '-2px', marginTop: '5px' }}
                      >
                        Current Journey
                      </Text>
                    )}
                  </Flex>
                </div>
              </Flex>

              <DataPoints
                durationDays={journey.meta.durationDays.value}
                endDate={journey.attributes.endDate}
                intervalDays={journey.meta.intervalDays.value}
                startDate={journey.attributes.startDate}
                triggeredEmails={journey.meta.triggeredEmails.value}
                upcoming={this.upcoming()}
              />
            </Flex>

            {this.renderLeftContent()}
          </Flex>
        </Link>

        <Box bottom="24px" position="absolute" right={noPadding ? '3px' : '32px'} width="unset">
          <VerticalDotsDropdown journey={journey} />
        </Box>

        {showRelatedModal && (
          <JourneysModal closeModal={this.toggleRelatedModal} currentJourney={journey} />
        )}
      </Box>
    );
  }
}

JourneyLine.contextTypes = {
  router: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
      query: PropTypes.object,
    }),
  }).isRequired,
};

JourneyLine.defaultProps = {
  current: false,
  noPadding: false,
  showRelated: true,
};

JourneyLine.propTypes = {
  current: PropTypes.bool,
  journey: PropTypes.shape({}).isRequired,
  noPadding: PropTypes.bool,
  showRelated: PropTypes.bool,
  theme: PropTypes.shape({}).isRequired,
};

export default withTheme(JourneyLine);
