import React from 'react';
import { Link } from 'react-router';

import { Box, Button, Flex, Heading, Text } from '../../mc-ui';
import CheckCircleIcon from '../../mc-ui/icons/CheckCircleIcon';

import background from './brandVsGroup.png';

const CreateGroup = () => (
  <Box width="792px">
    <Heading as="h4" css={{ lineHeight: '1.32em', marginBottom: '24px' }}>
      Create New Group
    </Heading>

    <Box
      backgroundColor="dark004"
      borderRadius="4px"
      height="327px"
      padding="32px 32px 32px 248px"
      css={{
        backgroundImage: `url(${background})`,
        backgroundPosition: '32px 32px',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Heading
        as="h5"
        color="dark08"
        css={{ fontSize: '18px', lineHeight: '1.32em', marginBottom: '9px', textTransform: 'none' }}
      >
        Create a group to start comparing brands
      </Heading>

      <Text color="dark08" css={{ marginBottom: '20px' }}>
        Find and add brands to a group to easily compare key email data and see how the brands stack
        up against each other.
      </Text>

      <Flex alignItems="center" marginBottom="10px">
        <Flex alignItems="center" color="dark1" marginRight="10px" width="24px">
          <CheckCircleIcon height="24px" width="24px" />
        </Flex>
        <Text color="dark08">View top performing brands in each group</Text>
      </Flex>

      <Flex alignItems="center" marginBottom="10px">
        <Flex alignItems="center" color="dark1" marginRight="10px" width="24px">
          <CheckCircleIcon height="24px" width="24px" />
        </Flex>
        <Text color="dark08">Benchmark brands in the group vs. the group</Text>
      </Flex>

      <Flex alignItems="center" marginBottom="34px">
        <Flex alignItems="center" color="dark1" marginRight="10px" width="24px">
          <CheckCircleIcon height="24px" width="24px" />
        </Flex>
        <Text color="dark08">Analyze group-level emails and metrics</Text>
      </Flex>

      <Button
        as={Link}
        to="/brands"
        variant="primary"
        css={{
          alignItems: 'center',
          display: 'inline-flex',
          height: '32px',
          justifyContent: 'space-between',
          padding: '0 15px',
          width: '127px',
        }}
      >
        Browse Brands
      </Button>
    </Box>
  </Box>
);

export default CreateGroup;
