import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { splitEvery } from 'ramda';

import { userSelectors } from '../modules/user';

import EmailThumbnail from './ui/EmailThumbnail';
import { Box, Button, Grid, Text } from './mc-ui';

const DEFAULT_EMAILS = [...Array(8).keys()].map((_, idx) => {
  const email = {
    id: idx,
    type: 'loading',
    attributes: {
      from: [{ name: 'Loading' }],
      company_logo_src: '//cache.mailcharts.com/logos/companies/0-default.png',
      guid: '',
      subject: '...',
      company_id: '',
      company_name: '',
    },
  };

  return email;
});

// eslint-disable-next-line react/prop-types
function EmailsGrid({ emails, model, ...props }) {
  return (
    <div>
      <Grid
        gap="38px"
        gridTemplateColumns={model && model.type === 'lists' ? 'repeat(5, 1fr)' : 'repeat(4, 1fr)'}
      >
        {emails.map((email, index) => (
          <EmailThumbnail
            key={`newEmailThumbnail-${index}-${email.id}`}
            brand={{
              id: email.attributes.company_id,
              attributes: { name: email.attributes.company_name },
            }}
            email={email}
            {...props}
          />
        ))}
      </Grid>
    </div>
  );
}

// eslint-disable-next-line react/prop-types
function EmailContainerForLists({ emails: emailsProp, isListOwner, model, ...props }) {
  const groupedEmails = splitEvery(15, emailsProp);

  return (
    <div>
      {groupedEmails.map((emails, idx) => {
        const key = `emailContainerForLists-${idx}`;
        return (
          <div key={key}>
            <EmailsGrid emails={emails} isListOwner={isListOwner} model={model} {...props} />

            {!isListOwner && (
              <Box
                backgroundColor="white"
                borderRadius="8px"
                margin="32px 0 24px 0"
                padding="32px"
                css={{
                  backgroundImage: 'url(/images/curatedListUpgradeBackground.png)',
                  backgroundPosition: '788px -42px',
                  backgroundRepeat: 'no-repeat',
                }}
              >
                <Text
                  color="dark08"
                  fontSize="16px"
                  letterSpacing="0.02em"
                  lineHeight="20px"
                  width="820px"
                  css={{ marginBottom: '16px' }}
                >
                  You are viewing a list of curated email examples. Upgrade to Pro and get full
                  access to email data for thousands of ecommerce brands, industry insights,
                  historical reports and lifecycle journeys.
                </Text>
                <Button
                  as={Link}
                  to="/manage/plan"
                  variant="purple"
                  css={{
                    alignItems: 'center',
                    display: 'inline-flex',
                    height: '32px',
                    justifyContent: 'center',
                    margin: '0',
                    padding: '0',
                    width: '136px',
                  }}
                >
                  Upgrade to PRO
                </Button>
              </Box>
            )}
          </div>
        );
      })}
    </div>
  );
}

function EmailContainer({ isUserRestricted, model, ...props }) {
  const emails = props.searches.loading ? DEFAULT_EMAILS : props.emails;

  if (!props.searches.loading && model && model.type === 'lists' && isUserRestricted) {
    return <EmailContainerForLists emails={emails} model={model} {...props} />;
  }

  return <EmailsGrid emails={emails} model={model} {...props} />;
}

EmailContainer.defaultProps = {
  isListOwner: null,
  model: { type: '' },
};

EmailContainer.propTypes = {
  emails: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      attributes: PropTypes.shape({
        company_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        company_name: PropTypes.string,
      }),
    }),
  ).isRequired,
  isListOwner: PropTypes.bool,
  isUserRestricted: PropTypes.bool.isRequired,
  model: PropTypes.shape({
    type: PropTypes.string,
  }),
  searches: PropTypes.shape({
    loading: PropTypes.bool,
  }).isRequired,
};

const mapStateToProps = state => ({
  emails: state.emails.data,
  isUserRestricted: userSelectors.isUserRestrictedSelector(state),
  searches: state.searches,
});

export default connect(mapStateToProps)(EmailContainer);
