/* global window */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import { withHandlers } from 'recompose';

import api from '../../lib/api';
import { www } from '../../lib/utils';

import SearchInput from '../SearchInput';

import * as S from './styles';
import Logo from './Logo';
import NavigationItems from './NavigationItems';
import UserAvatar from './NavigationItems/UserAvatar';

const Navigation = props => (
  <div
    className={'pure-u-1'}
    css={{
      background: '#fff',
      boxShadow: '0px 1px 4px rgba(9, 30, 66, 0.2)',
      boxSizing: 'border-box',
    }}
  >
    <div
      css={{
        margin: '0 auto',
        maxWidth: 1280,
      }}
    >
      <div className="pure-u-1-24 pure-u-sm-1-12" />
      <div
        className="pure-u-11-12 pure-u-sm-5-6 jsNavigationContainer"
        style={{ position: 'relative' }}
      >
        <S.NavigationWrapper>
          <Logo />

          <NavigationItems />

          <S.SearchWrapper>
            <SearchInput {...props} />
          </S.SearchWrapper>

          <UserAvatar user={props.user.data[0]} />
        </S.NavigationWrapper>

      </div>
      <div className="pure-u-1-24 pure-u-sm-1-12" />
    </div>
  </div>
);

Navigation.contextTypes = {
  router: PropTypes.object,
};

Navigation.propTypes = {
  signOut: PropTypes.func.isRequired,
  user: PropTypes.shape({
    data: PropTypes.array,
  }).isRequired,
};

const enhance = withHandlers({
  signOut: () => event => {
    event.preventDefault();

    api
      .post('/auth/destroy')
      .then(() => {
        window.location.href = www('/signin');
      })
      .catch(console.error);
  },
});

export default enhance(Navigation);
