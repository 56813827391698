import styled from "@emotion/styled";

export const UL = styled("ul")({
  boxSizing: 'border-box',
  listStyle: 'none',
  margin: 0,
  padding: 0,
});

export const LI = styled("li")(({ theme }) => ({
  boxSizing: 'border-box',
  color: theme.colors.dark08,
  fontSize: '14px',
  lineHeight: `${22 / 14}em`,
  margin: '0 0 4px 0',
  padding: '0 0 0 16px',
  position: 'relative',
  '&::before': {
    backgroundColor: theme.colors.blue,
    borderRadius: '50%',
    boxSizing: 'border-box',
    content: '""',
    display: 'block',
    height: '8px',
    left: '0',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    width: '8px',
  },
}));
