import React from 'react';

import NoteIcon from '../../mc-ui/icons/NoteIcon';
import { Box, Heading, Text } from '../../mc-ui';

import * as S from './styles';

export default function NoResultsFound() {
  return (
    <S.AlertWrapper>
      <Box height="32px" marginRight="16px" width="32px">
        <NoteIcon height="32px" width="32px" />
      </Box>

      <Box flex="1" marginRight="16px" width="unset">
        <Heading as="h4" css={{ marginBottom: '8px' }}>
          Sorry! No results found.
        </Heading>
        <Text>There is one perfect solution: You can send your request to us!</Text>
      </Box>

      <Box width="114px">
        <S.ModalButton to="/manage/brandrequestboard">Send request</S.ModalButton>
      </Box>
    </S.AlertWrapper>
  );
}
