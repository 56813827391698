const journeyTypes = {
  blue: {
    backgroundColor: '#E2F2FF',
    borderColor: '#0083F1',
    color: 'dark08',
  },
  purple: {
    backgroundColor: '#F7EEFF',
    borderColor: '#631AA4',
    color: 'dark08',
  },
  teal: {
    backgroundColor: '#D9FAF7',
    borderColor: '#48DDCF',
    color: 'dark08',
  },
  yellow: {
    backgroundColor: '#FFF7DB',
    borderColor: '#FFD336',
    color: 'dark08',
  },
};

const colors = {
  black: '#000',
  blue: '#0072D3',
  blueDark: '#003872',
  blueLight: '#E2F2FF',
  blueMedium: '#0083F1',
  blueSelected: 'rgba(0, 114, 211, 0.4)',
  dark004: 'rgba(9, 30, 66, 0.04)',
  dark01: 'rgba(9, 30, 66, 0.1)',
  dark02: 'rgba(9, 30, 66, 0.2)',
  dark04: 'rgba(9, 30, 66, 0.4)',
  dark06: 'rgba(9, 30, 66, 0.6)',
  dark08: 'rgba(9, 30, 66, 0.8)',
  dark1: '#091E42',
  dark2: '#253858',
  dark3: '#5E6C84',
  dark4: '#A5ADBA',
  dark5: '#DFE1E6',
  dark6: '#F4F5F7',
  greenDark: '#3F9C15',
  greenMedium: '#78C854',
  orange: '#FF823B',
  orangeDark: '#DE5000',
  orangeLight: '#FFEADE',
  orangeMedium: '#FF823B',
  purpleDark: '#631AA4',
  purpleLight: '#F7EEFF',
  purpleMedium: '#9E6ACC',
  red1: '#F2222F',
  redDark: '#BF0022',
  redLight: '#FFE9EA',
  tealDark: '#09B5B5',
  tealLight: '#D9FAF7',
  tealMedium: '#48DDCF',
  white: '#FFF',
  yellowDark: '#E99635',
  yellowMedium: '#FFD336',
  journeyTypes: {
    account_confirmation: journeyTypes.blue,
    active_subscription: journeyTypes.yellow,
    birthday: journeyTypes.blue,
    booking_abandoner: journeyTypes.purple,
    booking_abandonment: journeyTypes.purple,
    browse_abandon: journeyTypes.purple,
    browse_abandoner: journeyTypes.purple,
    browse_abandonment: journeyTypes.purple,
    cancelation: journeyTypes.yellow,
    cancelation_and_winback: journeyTypes.yellow,
    cancellation: journeyTypes.yellow,
    cancellation_and_winback: journeyTypes.yellow,
    cart_abandon: journeyTypes.purple,
    cart_abandoner: journeyTypes.purple,
    cart_abandonment: journeyTypes.purple,
    custom: journeyTypes.yellow,
    delivery_confirmation: journeyTypes.teal,
    double_optin: journeyTypes.blue,
    email_subscriber: journeyTypes.blue,
    loyalty: journeyTypes.blue,
    loyalty_and_rewards: journeyTypes.blue,
    onboarding: journeyTypes.blue,
    order_confirmation: journeyTypes.teal,
    product_review: journeyTypes.yellow,
    purchase: journeyTypes.teal,
    purchase_receipt: journeyTypes.teal,
    purchaser: journeyTypes.teal,
    refer_a_friend: journeyTypes.blue,
    rewards: journeyTypes.blue,
    shipping_confirmation: journeyTypes.teal,
    subscription_abandoner: journeyTypes.purple,
    subscription_abandonment: journeyTypes.purple,
    subscription_reminder: journeyTypes.yellow,
    welcome: journeyTypes.blue,
    welcome_and_onboarding: journeyTypes.blue,
    win_back: journeyTypes.yellow,
    winback: journeyTypes.yellow,
  },
};

export default colors;
