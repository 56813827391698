import React from 'react'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import Container from './Container'
import moment from 'moment'
import api from '../lib/api'

import ContactModalLink from './ContactModalLink';

const TYPES = {
  email_subcriber: 'Newsletter signup',
  cart_abandoner: 'Cart abandoner',
  browse_abandoner: 'Browse abandoner',
  purchaser: 'Purchaser',
}

class CompaniesAddSegments extends React.Component {
  state = {
    type: 'email_subscriber',
    custom: '',
    success: false,
    disabled: false,
    modal: false,
  }
  handleSubmit = (event) => {
    event.preventDefault()
    let company = this.props.companies.data.reduce((m, c) => c, null)
    let disabled = true
    this.setState({ disabled })


    let journey_type = this.state.custom ? 'custom' : (this.state.type || 'email_subscriber')
    let description = this.state.custom || TYPES[this.state.type] || 'Newsletter signup'
    let attrs = [].concat({ journey_type, description })

    if (journey_type == 'email_subscriber')
      attrs.push({ journey_type, description: 'Free account signup' })

    let requests = attrs.map(attributes => {
      let data = { attributes }
      return api.post(`/v2/companies/${company.id}/segments`, { data })
    })

    Promise.all(requests)
      .then(resp => {
        this.props.dispatch({ type: 'REFRESH_COMPANIES', company_id: company.id })
        let success = true
        this.setState({ success, custom: '', type: 'email_subscriber' })
      })
      .catch(console.error)
  }
  handleCustom = (event) => {
    event.preventDefault()
    this.setState({ custom: event.target.value })
  }
  handleChange = (event) => {
    event.stopPropagation()
    this.setState({ type: event.target.value })
  }
  componentDidMount() {
    let company = this.props.companies.data.reduce((m, c) => c, null)
    this.setState({ modal:  !!company })
    if (!company)
      this.props.dispatch({ type: 'REQUEST_COMPANIES', company_id: this.props.params.company_id })
  }
  render() {
    let company = this.props.companies.data.reduce((m, c) => c, null)
    return company ? (
      <Container style={{ maxWidth: 800, margin: '0 auto', }}>
        {this.state.success ?
          <div className='pure-u-1'>
            <h2>Thanks! Your request will be added soon.</h2>
            <img
              style={{ margin: '0 auto', }}
              src='/journey-success.svg' className='pure-img'
            />
            <p>When you request a new user segment, we will go and perform the requisite steps to sign-up for a newsletter, new user account, or otherwise.</p>
            <p>
              <Link
                to={`/companies/${company.id}`}
              >
                Back to {company.attributes.name}
              </Link>
            </p>
          </div>
        :
        <div className='pure-u-1'>
          <h2>Request new journey for {company.attributes.name}</h2>
          <form
            onSubmit={this.handleSubmit}
            className='pure-form pure-form-stacked'
          >
            <fieldset>
              <label htmlFor='type'>
                <h3>Which user journey should we mimic?</h3>
              </label>
              <div className='pure-u-1-4'>
                <select
                  style={{ minWidth: 200, }}
                  value={this.state.type}
                  onChange={this.handleChange} id='type'
                >
                  <option value='email_subscriber'>Email subscriber</option>
                  <option value='cart_abandoner'>Cart abandoner</option>
                  <option value='browse_abandoner'>Browse abandoner</option>
                  <option value='purchaser'>Purchaser</option>
                  <option value='custom'>Custom journey</option>
                </select>
                </div>
                {this.state.type == 'custom' ?
                <div className='pure-u-1-2'>
                  <input
                    type='text'
                    value={this.state.custom}
                    onChange={this.handleCustom}
                    className='pure-u-1'
                    placeholder='Describe your segment'
                  />
                </div>
              : null }
              </fieldset>
              {this.state.type == 'purchaser' &&
                  <div
                    style={{
                      borderRadius: 3,
                      background: '#F3F5F7',
                      marginBottom: 10,
                      padding: '10px 15px',
                    }}
                    className='pure-u-1'
                  >
                    <h5
                      style={{
                        margin: 0,
                        padding: 0,
                        color: '#242424',
                      }}
                    >
                      Heads-up!
                    </h5>
                    <p style={{
                      fontSize: `${13/16}em`,
                      margin: 0,
                      color: '#242424',
                      padding: 0,
                    }}>
                      Purchaser journeys are available for customers of annual plans &mdash; where we'll gladly spend up to 10% of your contract value. And if additional budget is needed, we're easily able to create a custom proposal for you.
                    </p>
                    <p style={{
                      fontSize: `${13/16}em`,
                      color: '#242424',
                      padding: 0,
                    }}>
                    To get started, simply <a href='#' onClick={this.handleSubmit}>click Create</a> below and we'll be in touch! No obligation at all.
                    </p>
                  </div>
              }
              <fieldset>
                <input
                  type='submit'
                  className={`pure-button pure-button-primary ${this.state.disabled ? 'pure-button-disabled' : null}`}
                  value='Create'
                />
              </fieldset>
          </form>
          <p
            style={{
              fontSize: `${13/16}em`,
            }}
          >
            Want to know how we add the data you care about?  <a href='https://www.mailcharts.com/companies' target='_blank'>Learn more.</a>
          </p>
        <h3>Available journeys</h3>
        <table className='pure-table pure-table-striped' style={{ width: '100%' }}>
          <thead>
            <tr>
              <th>Segment</th>
              <th>Created</th>
              <th>Last email received</th>
            </tr>
          </thead>
          <tbody>
          {company.relationships.journeys.data.map(m => {
            return (
            <tr key={m.id}>
              <td>{m.attributes.description} {m.attributes.private ? '[P]' : null}</td>
              <td>{moment(m.attributes.first_activity_at || m.attributes.created_at).format('DD MMM \'YY')}</td>
              <td>{m.attributes.last_email_sent_at && m.attributes.visible ?
                moment(m.attributes.last_email_sent_at).fromNow() : 'Awaiting first email' }</td>
            </tr>
            )
          })}
          </tbody>
        </table>
        <h3>FAQ</h3>
        <h4>How we add user segments</h4>
        <p>When you request a new user segment, we will go and perform the requisite steps to sign-up for a newsletter, new user account, or otherwise.</p>
        <h4>Custom requests or seed addresses</h4>
        <p>Please do not hesitate to <ContactModalLink>reach out to us</ContactModalLink> if you have special requests -- like special geographic regions and complicated user behaviors. We can also provide email addresses that you can seed on your own. Don't be shy!</p>
        {this.state.modal ? null :
        <p>
          <Link
            to={`/companies/${company.id}`}
          >
            Back to {company.attributes.name}
          </Link>
        </p>}
      </div>}
    </Container>
    ) : null
  }
}

const s = (state) => ({ companies: state.companies })
export default connect(s)(CompaniesAddSegments)
