import styled from "@emotion/styled";

const Overlay = styled("div")({
  bottom: 0,
  cursor: 'default',
  left: 0,
  position: 'fixed',
  right: 0,
  top: 0,
  zIndex: 1,
});

export default Overlay;
