import React from 'react'
import colorLib from '../lib/color'

class RecipientColor extends React.Component {

  render() {

    const desc = this.props.address_description
    const color = colorLib.getColorForEmail(desc)
    const size = this.props.size || 10

    return (this.props.duplicates || []).length > 0 ?
      (<div className='pure-u-1' style={{ textAlign: 'left', marginTop: 3 }}>
        <div style={{ margin: '0 auto', background: '#0379D5', borderRadius: '50%', height: size / 2, width: size / 2, position: 'relative' }} />
        <div style={{ margin: '0 auto', background: '#21D6C6', borderRadius: '50%', height: size / 2, width: size / 2, position: 'relative', left: 3, top: 1 }} />
        <div style={{ margin: '0 auto', background: '#9E6ACC', borderRadius: '50%', height: size / 2, width: size / 2, position: 'relative', left: -3, top: -4 }} />
      </div>)
      :
        <div style={{ margin: '0 auto', background: color, borderRadius: '50%', height: size, width: size, position: 'relative', top: 3 }} />
  }
}

export default RecipientColor
