import api from '../../lib/api';

// **************************************************
// MY SAVED JOURNEYS
// **************************************************

export const fetchMySavedJourneys = async () => {
  const response = await api.get('/v3/users/journeys');
  return response;
};

export const saveJourneyToMyList = async journeyId => {
  const response = await api.post('/v3/users/journeys', { journey_id: journeyId });
  return response;
};

export const deleteJourneyFromMyList = async journeyId => {
  const response = await api.delete(`/v3/users/journeys/${journeyId}`);
  return response;
};

// **************************************************
// TEAM SAVED JOURNEYS
// **************************************************

export const fetchTeamSavedJourneys = async teamId => {
  const response = await api.get(`/v3/teams/${teamId}/journeys`);
  return response;
};

export const saveJourneyToTeamList = async (teamId, journeyId) => {
  const response = await api.post(`/v3/teams/${teamId}/journeys`, { journey_id: journeyId });
  return response;
};

export const deleteJourneyFromTeamList = async (teamId, journeyId) => {
  const response = await api.delete(`/v3/teams/${teamId}/journeys/${journeyId}`);
  return response;
};
