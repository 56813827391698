/** @jsx jsx */
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { jsx, withTheme } from '@emotion/react';

import { Flex, Text } from '../../mc-ui';

const SectionInformation = ({ content, icon: Icon, linkTo, title = 'Learn more', type = 'link', theme }) => {
  const Content = content;
  return (
    <Flex direction="column" justifyContent="space-between" css={{ width: '240px' }}>
      {typeof content === 'string' ? <Text>{content}</Text> : <Content />}
      <Flex alignItems="center" css={{ marginTop: '24px' }}>
        <div
          css={{
            alignItems: 'center',
            backgroundColor: theme.colors.blueLight,
            borderRadius: '50%',
            color: theme.colors.blue,
            display: 'flex',
            fontSize: '24px',
            height: '40px',
            justifyContent: 'center',
            width: '40px',
          }}
        >
          <Icon fillOpacity={1} />
        </div>
        {type == 'link' ?
          <Link
            to={linkTo}
            css={{
              color: theme.colors.blue,
              fontFamily: theme.font.family,
              fontSize: '14px',
              fontWeight: '500',
              letterSpacing: '0.02em',
              lineHeight: '1.25em',
              marginLeft: '8px',
              textDecoration: 'none',
          }}
          >{title}</Link>
          :
          <a
            href={linkTo}
            target="_blank"
            css={{
              color: theme.colors.blue,
              fontFamily: theme.font.family,
              fontSize: '14px',
              fontWeight: '500',
              letterSpacing: '0.02em',
              lineHeight: '1.25em',
              marginLeft: '8px',
              textDecoration: 'none',
            }}
          >{title}</a>
        }
      </Flex>
    </Flex>
  );
};

SectionInformation.propTypes = {
  content: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.string]).isRequired,
  icon: PropTypes.func.isRequired,
  linkTo: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({}),]).isRequired,
  theme: PropTypes.shape({}).isRequired,
};

export default withTheme(SectionInformation);
