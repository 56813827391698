import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import api from '../../lib/api';

import FindGlassIcon from '../mc-ui/icons/FindGlassIcon';

import * as S from './styles';
import SearchInputDropdown from './SearchInputDropdown';

const INITIAL_STATE = {
  brands: { data: [] },
  index: -1,
  loading: false,
  pointer: '',
};

class SearchInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_STATE,
      active: false,
      value: this.props.router.location.query.q || '',
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Update value if the query changed or if the input is not active
    // (like when both inputs from Navigation and BrandsResults are mounted)
    if (
      nextProps.router.location.query.q !== this.props.router.location.query.q ||
      !this.state.active
    ) {
      this.setState({ value: nextProps.router.location.query.q || '' });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyPress);
  }

  handleBlur = event => {
    window.removeEventListener('keydown', this.handleKeyPress);

    const current = event.currentTarget;

    setTimeout(() => {
      if (!current.contains(document.activeElement)) {
        this.setState({ ...INITIAL_STATE, active: false });
      }
    }, 0);
  };

  handleChange = event => {
    event.preventDefault();

    const { value } = event.target;

    this.setState({ value });

    if (this.req) this.req.cancel();

    if (value.length < 1) return;

    this.setState({ loading: true });

    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.req = axios.CancelToken.source();
      api
        .get(`/v3/companies`, { params: { q: value }, cancelToken: this.req.token })
        .then(resp => {
          this.setState({ brands: resp.data, loading: false });
        })
        .catch(err => {
          if (axios.isCancel(err)) return; // do nothing
          console.error(err);
          this.setState({ loading: false });
        });
    }, 200);
  };

  handleClick = event => {
    this.setState({ value: '', brands: { data: [] } });
    this.props.router.push(event.target.href);
  };

  handleFocus = event => {
    event.preventDefault();

    window.addEventListener('keydown', this.handleKeyPress);

    this.setState({ active: true });
  };

  handleKeyPress = event => {
    let { index } = this.state;

    if (event.key === 'ArrowDown') {
      index += 1;
    } else if (event.key === 'ArrowUp') {
      index -= 1;
    }

    if (index < -1) {
      index = -1;
    }

    if (index > this.state.brands.data.length - 1) {
      index = this.state.brands.data.length - 1;
    }

    const brand = this.state.brands.data[index] || {};

    this.setState({ index, model: brand });
  };

  handleSubmit = event => {
    event.preventDefault();

    const { router } = this.props;

    this.setState(INITIAL_STATE);

    if (this.state.index > -1) {
      const brand = this.state.brands.data[this.state.index];
      router.push(`/${brand.type}/${brand.id}`);
    } else {
      const query = { q: this.state.value };
      const pathname = '/brands/results';
      router.push({ pathname, query });
    }
  };

  render() {
    const {
      button,
      placeholder,
      router: { location },
    } = this.props;

    const { active, value } = this.state;

    return (
      <S.Wrapper tabIndex="0" role="textbox" onBlur={this.handleBlur}>
        <form
          action={location.pathname}
          onSubmit={this.handleSubmit}
          style={button ? { display: 'flex' } : {}}
        >
          <S.InputWrapper>
            <S.Input
              active={active}
              type="text"
              onChange={this.handleChange}
              onFocus={this.handleFocus}
              placeholder={placeholder}
              value={value}
            />

            <S.InputIconWrapper>
              <FindGlassIcon height="24px" width="24px" />
            </S.InputIconWrapper>
          </S.InputWrapper>
          {button}
        </form>

        {!button && <SearchInputDropdown {...this.state} onClick={this.handleClick} />}
      </S.Wrapper>
    );
  }
}

SearchInput.propTypes = {
  button: PropTypes.node,
  placeholder: PropTypes.string,
  router: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
      query: PropTypes.shape({
        q: PropTypes.string,
      }),
    }),
    push: PropTypes.func,
  }).isRequired,
};

SearchInput.defaultProps = {
  button: null,
  placeholder: 'search “mailcharts.com”',
};

export default SearchInput;
