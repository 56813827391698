import React from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import moment from 'moment';

class SendFrequency extends React.Component {
  render() {
    let max = 0;
    const datasets = [].concat(this.props.buckets).map((buckets, idx) => {
      const label = this.props.names[idx];
      const isComparison = label === 'Comparison';
      const color = isComparison ? '#646464' : '#0383E7';
      const bg = isComparison ? 'rgba(36, 36, 36, .05)' : 'rgba(0, 134, 241, .25)';

      return {
        type: 'line',
        label,
        fill: true,
        lineTension: 0,
        backgroundColor: bg,
        borderColor: color,
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: color,
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: color,
        pointHoverBorderColor: color,
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: buckets.map((b) => {
          const value = +(b.company_avg.value || 0).toFixed(2);
          max = value > max ? value : max;
          return value;
        }),
      };
    });

    const data = (canvas) => {
      const ctx = canvas.getContext('2d');
      const gradient = ctx.createLinearGradient(0, 0, 0, this.props.height || 240);
      gradient.addColorStop(1, 'rgba(50, 211, 194, .1)');
      gradient.addColorStop(0, 'rgba(50, 211, 194, .8)');

      // Find the bucket that has more data to build this chart labels
      const buckets = [...this.props.buckets];
      buckets.sort((a, b) => b.length > a.length);
      const bucket = buckets[0];

      return {
        labels: bucket.map(b => moment(b.key).format('MMM DD')),
        datasets: datasets.map(d =>
        // d.backgroundColor = gradient
          d),
      };
    };
    const options = {
      onClick: (e, item) => {
        if (item.length < 1) return;
        const target = item[0];
        const { buckets } = this.props;
        const index = target._index; // eslint-disable-line
        const dataset = target._datasetIndex; // eslint-disable-line
        const bucket = buckets[dataset];
        const startDate = moment(bucket[index].key).format('YYYY-MM-DD');
        this.props.handleClick({ start_date: startDate });
      },
      scales: {
        yAxes: [
          {
            afterDataLimits: (instance) => {
              instance.max += 1; // eslint-disable-line
            },
            ticks: { beginAtZero: true, stepSize: Math.round(max / 5) || 1 },
            scaleLabel: {
              display: true,
              labelString: 'Emails',
            },
          },
        ],
      },
      legend: {
        labels: {
          usePointStyle: true,
          align: 'left',
        },
        position: 'top',
      },
    };
    return <Line height={this.props.height || 240} options={options} data={data} />;
  }
}

SendFrequency.propTypes = {
  buckets: PropTypes.arrayOf(PropTypes.any).isRequired,
  height: PropTypes.number.isRequired,
  names: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleClick: PropTypes.func,
};

SendFrequency.defaultProps = {
  handleClick: () => {},
};

export default SendFrequency;
