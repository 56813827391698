import styled from "@emotion/styled";
import { Link } from 'react-router';

const Wrapper = styled(Link)(({ theme }) => ({
  backgroundColor: theme.colors.white,
  borderRadius: '4px',
  boxSizing: 'border-box',
  cursor: 'pointer',
  marginBottom: '32px',
  padding: '32px',
  textDecoration: 'none',
  width: '338px',
}));

export default Wrapper;
