import styled from "@emotion/styled";

const Bar = styled("div")(({ size, theme }) => ({
  backgroundColor: theme.colors.dark6,
  borderRadius: '2px 2px 0 0',
  height: '30px',
  position: 'relative',
  width: '12px',
  '&::after': {
    backgroundColor: theme.colors.dark3,
    borderRadius: '2px 2px 0 0',
    bottom: '0',
    content: '""',
    display: 'block',
    height: `${size}%`,
    position: 'absolute',
    width: '12px',
  },
}));

export default Bar;
