import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'ramda';

import { brandOverviewSelectors as selectors } from '../../../../modules/brandOverview';
import Section from '../Section';

import Highlights from './Highlights';

const SubjectLines = ({ data, model, loading }) => {
  if (loading || isEmpty(data)) return null;

  return (
    <Section>
      <Section.Title title="Subject Lines" reportName="subject-lines" />
      <Highlights data={data} model={model} />
    </Section>
  );
};

SubjectLines.propTypes = {
  data: PropTypes.shape({}).isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  model: selectors.getModel(state),
  data: selectors.getData(state),
  loading: selectors.getLoading(state),
});

export default connect(mapStateToProps)(SubjectLines);
