import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';

import { Box, Heading } from '../../../mc-ui';

import ExploreBrandGroups from './ExploreBrandGroups';
import ExploreEmailJourneys from './ExploreEmailJourneys';
import ExploreEmailLists from './ExploreEmailLists';
import ExploreHistoricalData from './ExploreHistoricalData';

const isNotEmpty = value => !isEmpty(value);

export default function Explore({ email, router }) {
  const [model] = email.data;

  const { query } = router.location;
  const groups = (((model || {}).relationships || {}).groups || { data: [] }).data.filter(
    g => `${g.id}` !== `${query.group_id}`,
  );
  const journeys = (((model || {}).relationships || {}).journeys || { data: [] }).data.filter(
    j => `${j.id}` !== `${query.journey_id}`,
  );
  const lists = (((model || {}).relationships || {}).lists || { data: [] }).data.filter(
    l => `${l.id}` !== `${query.list_id}`,
  );

  return (
    <div>
      <Box>
        {(isNotEmpty(journeys) || isNotEmpty(lists)) && (
          <Heading as="h4" lineHeight="18px" css={{ marginBottom: '24px' }}>
            Where can you find this email?
          </Heading>
        )}

        <ExploreEmailJourneys email={model} router={router} />
        <ExploreEmailLists email={model} router={router} />
        <ExploreHistoricalData email={model} />
      </Box>

      <Box paddingTop="24px" borderColor="dark4" borderTop="1px dashed">
        {isNotEmpty(groups) && (
          <Heading as="h4" lineHeight="18px" css={{ marginBottom: '24px' }}>
            Browse more
          </Heading>
        )}

        <ExploreBrandGroups email={model} router={router} />
      </Box>
    </div>
  );
}

Explore.propTypes = {
  email: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  router: PropTypes.shape({}).isRequired,
};
