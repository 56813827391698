import React from 'react';

import { Heading } from '../../../mc-ui';

const Title = props => (
  <Heading
    as="h5"
    color="dark06"
    css={{
      fontSize: '10px',
      fontWeight: 'bold',
      letterSpacing: '0.12em',
      lineHeight: '1.56em',
    }}
    {...props}
  />
);

export default Title;
