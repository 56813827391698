import React from 'react';
import { PropTypes } from 'prop-types';
import styled from "@emotion/styled";

import Flex from '../Flex';

const FakeBorder = styled("div")(({ theme }) => ({
  backgroundColor: theme.colors.dark01,
  bottom: 0,
  height: '1px',
  left: 0,
  position: 'absolute',
  width: '100%',
}));

const Header = ({ children, ...props }) => (
  <Flex css={{ position: 'relative' }}>
    {React.Children.map(children, child => (child ? React.cloneElement(child, props) : null))}
    <FakeBorder />
  </Flex>
);

Header.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Header;
