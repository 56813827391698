import styled from "@emotion/styled";

const Nav = styled("nav")({
  backgroundColor: '#242424',
  boxSizing: 'border-box',
  position: 'fixed',
  width: '100%',
  zIndex: '999',
});

export default Nav;
