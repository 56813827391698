import styled from "@emotion/styled";

const ActionsWrapper = styled("div")({
  alignItems: 'center',
  boxSizing: 'border-box',
  display: 'flex',
  '& > form select': {
    width: '115px',
  },
});

export default ActionsWrapper;
