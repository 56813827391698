/** @jsx jsx */
import PropTypes from 'prop-types';
import { jsx, withTheme } from '@emotion/react';
import { Link } from 'react-router';

import { Flex, Text, Tooltip } from '../../mc-ui';
import BrandLogo from '../../ui/BrandLogo';

const SubjectLineWithLink = ({ email, theme }) => (
  <Flex alignItems="center">
    <Link to={`/companies/${email.attributes.company_id}`}>
      <BrandLogo
        brandId={email.attributes.company_id}
        css={{ borderRadius: '50%', height: '16px', width: '16px' }}
      />
    </Link>

    <Link
      to={`/emails/${email.attributes.guid}`}
      css={{
        color: theme.colors.dark08,
        marginLeft: '16px',
        textDecoration: 'none',
        width: '100%',
      }}
    >
      <Text
        css={{
          color: theme.colors.dark08,
          fontWeight: 500,
          letterSpacing: '0.02em',
          lineHeight: '16px',
        }}
      >
        {email.attributes.subject.slice(0, 108)}
        {email.attributes.subject.length > 108 && '...'}
      </Text>
    </Link>
  </Flex>
);

SubjectLineWithLink.propTypes = {
  email: PropTypes.shape({
    attributes: PropTypes.shape({
      company_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      guid: PropTypes.string,
      subject: PropTypes.string,
    }),
  }).isRequired,
  theme: PropTypes.shape({}).isRequired,
};

const SubjectWithBrandLogo = props => (
  <Flex css={{ marginBottom: '16px' }}>
    <Tooltip
      element={<SubjectLineWithLink {...props} />}
      id={`${props.email.attributes.guid}-${Math.random()}`}
      tooltipWidth="770px"
      effect="float"
      dark
    >
      <span
        css={{ whiteSpace: 'nowrap' }}
      >
        <strong>{props.email.attributes.company_name}</strong>: {props.email.attributes.subject}
      </span>
    </Tooltip>
  </Flex>
);

SubjectWithBrandLogo.propTypes = {
  email: PropTypes.shape({
    attributes: PropTypes.shape({
      company_name: PropTypes.string,
      guid: PropTypes.string,
      subject: PropTypes.string,
    }),
  }).isRequired,
};

export default withTheme(SubjectWithBrandLogo);
