import React from 'react';
import PropTypes from 'prop-types';

import { Flex, Heading, Text } from '../../../mc-ui';

import ReportSection from '../../ReportSection';
import Title from '../../Title';

import TermsTab from '../TermsTab';

import BrandTab from './BrandTab';
import GroupTab from './GroupTab';
import SignificantTermsTooltip from './SignificantTermsTooltip';

class SignificantTerms extends React.Component {
  state = {
    selectedBrand: null,
  };

  onBrandChange = value => {
    this.setState(() => {
      const selectedBrand = this.props.brands.find(brand => brand.id === value.value);
      return { selectedBrand };
    });
  };

  render() {
    const { brands, model } = this.props;
    const { selectedBrand } = this.state;

    return (
      <Flex>
        <ReportSection css={{ minHeight: '557px' }}>
          <ReportSection.Header alignItems="center" justifyContent="flex-start">
            <Title>
              {model.type === 'groups' ? 'Significant Terms by Group/Brand' : 'Significant Terms'}
            </Title>
            <SignificantTermsTooltip />
          </ReportSection.Header>
          <ReportSection.Body>
            {model.type === 'groups' ? (
              <TermsTab defaultActive="tabOne">
                <TermsTab.Header>
                  <TermsTab.Item name="tabOne">
                    {({ isActive }) => (
                      <Flex direction="column">
                        <Heading
                          as="h4"
                          color={isActive ? 'dark1' : 'dark06'}
                          css={{
                            fontSize: '16px',
                            letterSpacing: '0.02em',
                            lineHeight: '24px',
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          Group
                        </Heading>
                        <Text css={{ marginTop: '7px' }}>This group vs Mailcharts Index</Text>
                      </Flex>
                    )}
                  </TermsTab.Item>
                  <TermsTab.Item name="tabTwo">
                    {({ isActive }) => (
                      <Flex direction="column">
                        <Flex alignItems="center">
                          <Heading
                            as="h4"
                            color={isActive ? 'dark1' : 'dark06'}
                            css={{
                              fontSize: '16px',
                              letterSpacing: '0.02em',
                              lineHeight: '24px',
                              margin: '0 5px 0 0',
                              padding: 0,
                            }}
                          >
                            {selectedBrand ? selectedBrand.attributes.name : 'Brand'}
                          </Heading>
                          {isActive && (
                            <TermsTab.Dropdown
                              open
                              options={brands.map(brand => ({
                                label: brand.attributes.name,
                                value: brand.id,
                              }))}
                              onSelect={this.onBrandChange}
                            />
                          )}
                        </Flex>
                        <Text css={{ marginTop: '7px' }}>Specific brand vs This group</Text>
                      </Flex>
                    )}
                  </TermsTab.Item>
                </TermsTab.Header>

                <TermsTab.Body>
                  {({ active }) => (
                    <Flex css={{ minHeight: '333px' }}>
                      {active === 'tabOne' ? (
                        <GroupTab model={model} />
                      ) : (
                        <BrandTab model={model} brand={selectedBrand} />
                      )}
                    </Flex>
                  )}
                </TermsTab.Body>
              </TermsTab>
            ) : (
              <BrandTab model={model} brand={model} />
            )}
          </ReportSection.Body>
          <ReportSection.Footer padding="0">
            To understand how this works,{' '}
            <a
              href="https://knowledge.validity.com/hc/en-us/articles/9513216584731-Email-Metrics"
              rel="noopener noreferrer"
              target="_blank"
            >
              visit here
            </a>
            .
          </ReportSection.Footer>
        </ReportSection>
      </Flex>
    );
  }
}

SignificantTerms.propTypes = {
  brands: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  model: PropTypes.shape({ id: PropTypes.string, type: PropTypes.string }).isRequired,
};

export default SignificantTerms;
