import React from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import moment from 'moment';

class SendTime extends React.Component {
  render() {
    const datasets = [].concat(this.props.buckets).map((buckets, idx) => {
      const total = buckets.reduce((m, b) => m + (b.company_avg.value || 0), 0);
      const label = this.props.names[idx];
      const isComparison = label === 'Comparison';
      const color = isComparison ? '#646464' : '#0383E7';
      const bg = isComparison ? 'rgba(36, 36, 36, .05)' : 'rgba(0, 134, 241, .25)';

      return {
        type: 'line',
        label,
        fill: true,
        lineTension: 0,
        backgroundColor: bg,
        borderColor: color,
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: color,
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: color,
        pointHoverBorderColor: color,
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: buckets.map((b) => {
          const value = +(b.company_avg.value || 0);
          const percent = Math.round((value / total) * 100);
          return percent;
        }),
      };
    });

    // Find the bucket that has more data to build this chart labels
    const buckets = [...this.props.buckets];
    buckets.sort((a, b) => b.length > a.length);
    const bucket = buckets[0];

    const data = {
      labels: bucket.map(b =>
        moment()
          .hour(b.key)
          .format('h A')),
      datasets,
    };
    const options = {
      layout: {
        padding: { top: 5 },
      },
      scales: {
        yAxes: [
          {
            afterDataLimits: (instance) => {
              instance.max += 1; // eslint-disable-line
            },
            scaleLabel: {
              display: true,
              labelString: 'Percent of emails',
            },
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
      legend: {
        labels: {
          usePointStyle: true,
          align: 'left',
        },
        position: 'top',
      },
    };
    return <Line height={this.props.height || 240} options={options} data={data} />;
  }
}

SendTime.propTypes = {
  buckets: PropTypes.arrayOf(PropTypes.any).isRequired,
  height: PropTypes.number.isRequired,
  names: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default SendTime;
