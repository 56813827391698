import { assoc, assocPath, compose, merge, path, sortBy, toLower } from 'ramda';

import * as types from './types';

export const INITIAL_STATE = {
  data: [
    {
      attributes: { enabled: true },
      relationships: {
        groups: {
          data: [],
        },
      },
    },
  ],
  error: null,
  industryGroups: [],
  loading: true,
};

const sortByName = sortBy(
  compose(
    toLower,
    path(['attributes', 'name']),
  ),
);

const setDataReducer = (state, { data, industryGroups }) =>
  merge(state, {
    error: false,
    loading: false,
    ...data,
    industryGroups: industryGroups || state.industryGroups,
  });

const setErrorReducer = (state, { error }) => merge(state, { loading: false, error });

const setLoadingReducer = state => assoc('loading', true, state);

const enableReducer = state => assocPath(['data', 0, 'attributes', 'enabled'], true, state);

const disableReducer = state => assocPath(['data', 0, 'attributes', 'enabled'], false, state);

const addGroupReducer = (state, { group }) => {
  const groups = sortByName(state.data[0].relationships.groups.data.concat(group));
  return assocPath(['data', 0, 'relationships', 'groups', 'data'], groups, state);
};

const removeGroupReducer = (state, { group }) => {
  const groups = sortByName(state.data[0].relationships.groups.data.filter(g => g.id !== group.id));
  return assocPath(['data', 0, 'relationships', 'groups', 'data'], groups, state);
};

const reducers = {
  [types.FETCH_REQUEST]: setLoadingReducer,
  [types.FETCH_FAILURE]: setErrorReducer,
  [types.FETCH_SUCCESS]: setDataReducer,
  [types.DISABLE_REQUEST]: disableReducer,
  [types.DISABLE_FAILURE]: enableReducer,
  [types.DISABLE_SUCCESS]: disableReducer,
  [types.ENABLE_REQUEST]: enableReducer,
  [types.ENABLE_FAILURE]: disableReducer,
  [types.ENABLE_SUCCESS]: enableReducer,
  [types.ADD_GROUP_REQUEST]: addGroupReducer,
  [types.ADD_GROUP_FAILURE]: setErrorReducer,
  [types.ADD_GROUP_SUCCESS]: setDataReducer,
  [types.REMOVE_GROUP_REQUEST]: removeGroupReducer,
  [types.REMOVE_GROUP_FAILURE]: setErrorReducer,
  [types.REMOVE_GROUP_SUCCESS]: setDataReducer,
};

export default (state = INITIAL_STATE, action) => {
  const reducer = reducers[action.type];

  if (!reducer) return state;
  return reducer(state, action);
};
