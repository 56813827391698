import * as React from 'react';
import PropTypes from 'prop-types';

function EqualIcon({ fillOpacity, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 12.074v2h10v-2H7zM7 8v2h10V8H7z"
        fill="currentColor"
        fillOpacity={fillOpacity}
      />
    </svg>
  );
}

EqualIcon.propTypes = {
  fillOpacity: PropTypes.number,
};

EqualIcon.defaultProps = {
  fillOpacity: 1,
};

export default EqualIcon;
