import api from '../lib/api'

export default store => next => action => {
  next(action)
  if (action.type === 'REQUEST_TRENDS') {
    api.get(action.route)
      .then(r => store.dispatch({ type: 'RECEIVE_COMPANY_TRENDS', data: r.data }))
  }
}

