import React from 'react';

import { Flex, Heading } from '../mc-ui';

import * as api from './utils/api';

import Update from './updates/Update';

class Updates extends React.PureComponent {
  state = {
    loading: true,
    updates: [],
  };

  async UNSAFE_componentWillMount() {
    const updates = await api.fetchUpdates();

    this.setState(() => ({
      loading: false,
      updates,
    }));
  }

  render() {
    const { loading, updates } = this.state;

    if (loading) return null;

    return (
      <div>
        <Heading as="h4" color="dark08" css={{ lineHeight: '1.33em', marginBottom: '16px' }}>
          Updates from MailCharts
        </Heading>
        <Flex justifyContent="space-between">
          {updates.map(update => (
            <Update key={`update-${update.id}`} update={update} />
          ))}
        </Flex>
      </div>
    );
  }
}

export default Updates;
