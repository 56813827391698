/** @jsx jsx */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { jsx, withTheme } from '@emotion/react';

import { Box, Flex, Heading } from '../../../mc-ui';
import ArrowRightIcon from '../../../mc-ui/icons/ArrowRightIcon';

const isActive = (item, selectedItem) => item.type === selectedItem.type;

const JourneyItem = ({ item, onClick, selectedItem, theme }) => (
  <Link
    key={item.type}
    css={{
      display: 'flex',
      marginBottom: '16px',
      textDecoration: 'none',
      '&:hover': { '& h4': { color: `${theme.colors.blue} !important` } },
    }}
    onClick={onClick(item.type)}
    to={`#${item.type}`}
  >
    <Flex alignItems="center">
      <Flex
        alignItems="center"
        backgroundColor={isActive(item, selectedItem) ? 'blue' : 'dark6'}
        borderRadius="4px"
        color={isActive(item, selectedItem) ? 'white' : 'dark1'}
        justifyContent="center"
        marginRight="16px"
        height="40px"
        width="40px"
      >
        <ArrowRightIcon fillOpacity={1} height="20px" width="20px" />
      </Flex>

      <Box width="unset">
        <Heading
          as="h4"
          color={isActive(item, selectedItem) ? 'blue' : 'dark08'}
          css={{ fontSize: '14px', lineHeight: '1.25em', textTransform: 'capitalize', }}
        >
          {(item.type || '').split('_').join(' ')}
        </Heading>
      </Box>
    </Flex>
  </Link>
);

JourneyItem.propTypes = {
  item: PropTypes.shape({}).isRequired,
  onClick: PropTypes.func.isRequired,
  selectedItem: PropTypes.shape({}).isRequired,
  theme: PropTypes.shape({}).isRequired,
};

export default withTheme(JourneyItem);
