import React from 'react';

import { Flex, Text } from '../../mc-ui';
import CoffeeGlassAndBook from '../../ui/illustrations/CoffeeGlassAndBook';

export default function NoLists() {
  return (
    <Flex alignItems="center" direction="column" justifyContent="center">
      <CoffeeGlassAndBook />

      <Text fontWeight="bold" letterSpacing="0.02em" lineHeight="1.25em" margin="24px 0 16px 0">
        Create your first email list
      </Text>

      <Text
        letterSpacing="0.02em"
        lineHeight="1.56em"
        textAlign="center"
        width="328px"
        css={{ marginBottom: '16px' }}
      >
        No lists? No problem!
        <br />
        <br />
        Create your first Email List now and keep track of emails you love.
      </Text>
    </Flex>
  );
}
