import React from 'react';
import PropTypes from 'prop-types';
import { compose, withStateHandlers } from 'recompose';

import Lists from './Lists';
import hoc from './hoc';

import AddToListFormWrapper from './ui/AddToListFormWrapper';
import FormControl from './ui/FormControl';
import FormHeader from './ui/FormHeader';
import FormTitle from './ui/FormTitle';
import Input from './ui/Input';

const AddToListForm = ({
  model,
  clearListName,
  dispatch,
  email,
  emptyListDescription,
  fluidSize,
  listName,
  renderOutside,
  selectedEmails,
  setListName,
}) => {
  const [m, ..._] = email ? email.data : []
  model = model || m
  if (selectedEmails.length === 0 && model) {
    selectedEmails = selectedEmails.concat(model)
  }
  const createList = (event) => {
    event.preventDefault();
    dispatch({
      type: 'CREATE_LIST_REQUEST',
      name: listName,
      emailId: model ? [model.id] : selectedEmails.map(e => e.id),
    });
    clearListName();
  };

  return (
    <AddToListFormWrapper fluidSize={fluidSize}>
      <FormHeader>
        {!fluidSize && (
          <FormTitle>
            {selectedEmails.length === 1 ? 'Add this email to' : 'Add these emails to'}
          </FormTitle>
        )}
        <FormControl onSubmit={createList}>
          <Input
            type="text"
            placeholder="New list name"
            className="pure-input-1"
            onChange={setListName}
            value={listName}
          />
          <button className="pure-button pure-button-primary" type="submit">
            Create
          </button>
        </FormControl>
      </FormHeader>
      <Lists
        email={email}
        emptyListDescription={emptyListDescription}
        renderOutside={renderOutside}
      />
    </AddToListFormWrapper>
  );
};

AddToListForm.propTypes = {
  clearListName: PropTypes.func.isRequired,
  listName: PropTypes.string.isRequired,
  selectedEmails: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setListName: PropTypes.func.isRequired,
  dispatch: PropTypes.func,
  email: PropTypes.shape({}),
  emptyListDescription: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
  fluidSize: PropTypes.bool,
  renderOutside: PropTypes.bool,
};

AddToListForm.defaultProps = {
  dispatch: () => {},
  fluidSize: false,
  email: null,
  emptyListDescription: null,
  renderOutside: false,
};

const enhance = compose(
  hoc('AddToListForm'),
  withStateHandlers(
    { listName: '' },
    {
      setListName: () => (event) => {
        const field = event.target;
        const name = field.value;
        return { listName: name };
      },
      clearListName: () => () => ({ listName: '' }),
    },
  ),
);

export default enhance(AddToListForm);
