import React from 'react';

import NavigationDropdown from '../NavigationDropdown';

import NavigationItem from './NavigationItem';

const Collections = () => (
  <NavigationItem label="Collections" to="/my-collections/email-lists">
    <NavigationDropdown>
      <NavigationDropdown.Item to="/my-collections/email-lists">
        Email Lists
      </NavigationDropdown.Item>
      <NavigationDropdown.Item to="/my-collections/text-message-lists">
        SMS Lists
      </NavigationDropdown.Item>
      <NavigationDropdown.Item to="/my-collections/groups">Groups</NavigationDropdown.Item>
      <NavigationDropdown.Item to="/my-collections/saved-journeys">
        Saved Journeys
      </NavigationDropdown.Item>
      <NavigationDropdown.Item to="/my-collections/saved-filters">
        Saved Filters
      </NavigationDropdown.Item>
      <NavigationDropdown.Item to="/my-collections/notes">Notes</NavigationDropdown.Item>
    </NavigationDropdown>
  </NavigationItem>
);

export default Collections;
