import React from 'react'
import { Link } from 'react-router'
import { connect } from 'react-redux'

import ReportsPlaceholder from './ReportsPlaceholder'
import Container from './Container'

class TechnologiesReports extends React.Component {
  render() {
    let user = this.props.user.data.reduce((m, u) => u, {})
    let technology = this.props.technologies.data.reduce((m, c) => c, {})
    return (
      <div className='pure-u-1'>
        {user.attributes.has_technologies_access ?
        <ReportsPlaceholder />
        :
        <Container style={{ margin: '2em auto' }}>
          <div className='pure-u-1' style={{ textAlign: 'center', padding: '2em 0' }}>
            <img
              src='/empty-report.png'
              style={{ margin: '0 auto', maxWidth: 100 }}
              className='pure-img'
            />
            <p>You do not have access to view reports for {technology.attributes.name}.</p>
            <p>
              <Link
                to='/manage'
                className='pure-button pure-button-primary'
              >
                Contact us
              </Link>
            </p>
          </div>
        </Container>
        }
      </div>
    )
  }
}

const s = (state) => ({ technologies: state.technologies, user: state.user })
export default connect(s)(TechnologiesReports)

