/* global window */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

import PaymentLink from '../../PaymentLink';

import Button from './ui/Button';
import Buttons from './ui/Buttons';
import Logo from './ui/Logo';
import Nav from './ui/Nav';
import Wrapper from './ui/Wrapper';

const DemoNavigation = (props, { router }) => {
  const upgradeHandler = (event) => {
    event.preventDefault();

    window.analytics.track('Paywall clicked', { type: 'journeys' });
  };

  const restrictedUrl = router.location.state
    ? router.location.state.restrictedUrl
    : '/';

  return (
    <Nav>
      <div style={{ maxWidth: '1280px', margin: '0 auto' }}>
        <div className="pure-u-1-24 pure-u-sm-1-12" />
        <div className="pure-u-11-12 pure-u-sm-5-6">
          <Wrapper>
            <Logo>
              <Link to="/">
                <img src="/logo-white.svg" alt="MailCharts" />
              </Link>
              Demo
            </Logo>

            <Buttons>
              <Button
                component={Link}
                to={restrictedUrl}
              >
                Leave demo
              </Button>
              <Button
                component={PaymentLink}
                onClick={upgradeHandler}
                type="primary"
                to="#upgrage"
              >
                Upgrade now
              </Button>
            </Buttons>
          </Wrapper>
        </div>
        <div className="pure-u-1-24 pure-u-sm-1-12" />
      </div>
    </Nav>
  );
};

DemoNavigation.contextTypes = {
  router: PropTypes.object,
};

export default DemoNavigation;
