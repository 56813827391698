import api from '../../../lib/api';

export const fetchData = async (modelId, modelType, startDate, endDate) => {
  let query = `?start_date=${startDate}&end_date=${endDate}`;

  if (modelType === 'groups' && modelId !== 'premium') {
    query += `&group_id=${modelId}`;
  } else if (modelType === 'brands' || modelType === 'companies') {
    query += `&company_id=${modelId}`;
  }

  const response = await api.get(`/v3/reports/subject-lines${query}`);
  return response.data;
};

export const fetchPopularTerms = async (modelId, modelType, startDate, endDate) => {
  let query = `?start_date=${startDate}&end_date=${endDate}`;

  if (modelType === 'groups' && modelId !== 'premium') {
    query += `&group_id=${modelId}`;
  } else if (modelType === 'brands' || modelType === 'companies') {
    query += `&company_id=${modelId}`;
  }

  const response = await api.get(`/v3/reports/popular-terms${query}`);
  return response.data;
};

export const fetchSignificantTerms = async (
  modelId,
  modelType,
  startDate,
  endDate,
  comparisonModelId,
  comparisonModelType,
) => {
  let query = `?start_date=${startDate}&end_date=${endDate}`;

  if (modelType === 'groups' && modelId !== 'premium') {
    query += `&group_id=${modelId}`;
  } else if (modelType === 'brands' || modelType === 'companies') {
    query += `&company_id=${modelId}`;
  }

  if (comparisonModelId) {
    if (comparisonModelType === 'groups' && comparisonModelId !== 'premium') {
      query += `&comparison[group_id]=${comparisonModelId}`;
    } else if (comparisonModelType === 'brands' || comparisonModelType === 'companies') {
      query += `&comparison[company_id]=${comparisonModelId}`;
    }
  }

  const response = await api.get(`/v3/reports/significant-terms${query}`);
  return response.data;
};

export const fetchHistoricalTerms = async (modelId, modelType, startDate, endDate) => {
  let query = `?start_date=${startDate}&end_date=${endDate}`;

  if (modelType === 'groups' && modelId !== 'premium') {
    query += `&group_id=${modelId}`;
  } else if (modelType === 'brands' || modelType === 'companies') {
    query += `&company_id=${modelId}`;
  }

  const response = await api.get(`/v3/reports/historical-terms${query}`);
  return response.data;
};
