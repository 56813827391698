import * as React from 'react';

function RadioSelectedIcon(props) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect
        x={4}
        y={4}
        width={16}
        height={16}
        rx={8}
        fill="#E2F2FF"
        stroke="#0072D3"
        strokeWidth={2}
      />
      <rect x={8} y={8} width={8} height={8} rx={4} fill="#0072D3" />
    </svg>
  );
}

export default RadioSelectedIcon;
