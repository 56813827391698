import React from 'react';
import PropTypes from 'prop-types';
import styled from "@emotion/styled";

import { Flex } from '../mc-ui';

const Wrapper = styled("div")(({ css = {}, cursor = 'default', loading, theme }) => {
  return ({
    border: loading ? 'none' : `1px solid ${theme.colors.dark5}`,
    borderRadius: '4px',
    boxSizing: 'border-box',
    cursor,
    marginRight: '16px',
    opacity: '1',
    padding: '1px',
    position: 'relative',
    ...css,
    '& *': {
      cursor: `${cursor} !important`,
    },
  })
});

const emailImageSrc = email =>
  `//assets.mailcharts.com/emails/${email.guid || email.attributes.guid}/300x400.png`;

const Img = styled("img")(({ display }) => ({
  display,
  opacity: '0',
  padding: '0',
  height: '1px',
  width: '1px',
}));

class Email extends React.Component {
  state = {
    loading: true,
    background: {},
  };

  handleLoad = () => {
    this.setState({
      loading: false,
      background: {
        backgroundImage: `url(${emailImageSrc(this.props.email)})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      },
    });
  };

  render() {
    const { loading, background } = this.state;
    const { email, height, width, css, style, cssStyle = {}, onClick, cursor } = this.props;

    const borderRadius = css.borderRadius || style.borderRadius || '4px';

    return (
      <Wrapper
        css={{ ...css, ...style, ...cssStyle }}
        cursor={css.cursor || style.cursor || cssStyle.cursor || 'default'}
        loading={loading ? 1 : 0}
        onClick={onClick}
      >
        <Img
          display={loading ? null : 'none'}
          onLoad={this.handleLoad}
          src={emailImageSrc(email)}
        />
        <Flex borderRadius={borderRadius} css={{ height, width, ...background }} />
      </Wrapper>
    );
  }
}

Email.defaultProps = {
  css: {},
  height: '60px',
  onClick: () => {},
  style: {},
  width: '60px',
};

Email.propTypes = {
  email: PropTypes.shape({}).isRequired,
  css: PropTypes.shape({}),
  height: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.shape({}),
  width: PropTypes.string,
};

export default Email;
