import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { brandsActions as actions, brandsSelectors as selectors } from '../../modules/brands';

import SearchBar from '../BrandsIndex/SearchBar';
import Section from '../Section';
import { Box, Flex, Heading, Link, Loader } from '../mc-ui';

import Results from './Results';
import SearchQuery from './SearchQuery';
import Sidebar from './Sidebar';
import TopControls from './TopControls';
import isQueryEmpty from './isQueryEmpty';

class BrandsResults extends React.PureComponent {
  constructor(props) {
    super(props);

    const { params } = props;
    const {
      location: { query },
    } = props.router;

    this.state = { query };

    if (JSON.stringify(params) !== JSON.stringify(query)) {
      this.search();
    }
  }

  componentDidUpdate() {
    this.request();
  }

  request = () => {
    const {
      location: { query },
    } = this.props.router;

    if (isQueryEmpty(query)) {
      this.setState({ query });
    } else if (JSON.stringify(this.state.query) !== JSON.stringify(query)) {
      this.setState({ query }, this.search);
    }
  };

  search = () => {
    const { query } = this.state;
    const { request } = this.props;

    request(query);
  };

  render() {
    const { query } = this.state;
    const { brands, loading, paginating, requestPagination, router } = this.props;

    return (
      <div>
        <Section>
          <Box paddingBottom="40px" paddingTop="25px">
            <Flex alignItems="center" justifyContent="space-between">
              <Heading as="h3" css={{ fontWeight: '300', marginBottom: '20px' }}>
                Which <strong>brand</strong> are you looking for?
              </Heading>
              <Link to="/brands" fontSize="12px">
                Back to Brands
              </Link>
            </Flex>

            <SearchBar {...this.props} />
            <SearchQuery query={query} router={router} />
          </Box>
        </Section>

        <Section outerBackground="#FFF">
          <Box padding="40px 0">
            <Flex justifyContent="space-between">
              <Box width="793px">
                <TopControls brands={brands} loading={loading} router={router} />

                {loading ? (
                  <Loader />
                ) : (
                  <Results
                    brands={brands}
                    handlePagination={requestPagination}
                    paginating={paginating}
                    router={router}
                  />
                )}
              </Box>

              <Box position="relative" width="244px">
                {!loading && <Sidebar brands={brands} />}
              </Box>
            </Flex>
          </Box>
        </Section>
      </div>
    );
  }
}

BrandsResults.propTypes = {
  brands: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
    included: PropTypes.arrayOf(PropTypes.shape({})),
    links: PropTypes.shape({}),
    meta: PropTypes.shape({}),
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  paginating: PropTypes.bool.isRequired,
  params: PropTypes.shape({}).isRequired,
  request: PropTypes.func.isRequired,
  requestPagination: PropTypes.func.isRequired,
  router: PropTypes.shape({
    location: PropTypes.shape({
      query: PropTypes.shape({}),
    }),
  }).isRequired,
};

const mapStateToProps = state => ({
  brands: selectors.brandsSelector(state),
  loading: selectors.loadingSelector(state),
  paginating: selectors.loadingSelector(state),
  params: selectors.paramsSelector(state),
});

const mapDispatchToProps = dispatch => ({
  request: (...args) => dispatch(actions.request(...args)),
  requestPagination: () => dispatch(actions.requestPagination()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BrandsResults);
