import * as React from 'react';
import PropTypes from 'prop-types';

function ClassifiedEmailIcon({ fillOpacity, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.558 3.334H5.166c-1.75 0-3.25 1.384-3.5 3.286v9.082c0 1.989 1.584 3.632 3.5 3.632h12.917c1.917 0 3.5-1.643 3.5-3.632l.06-6.295a4 4 0 01-1.893 1.178v5.117c0 .95-.667 1.73-1.584 1.73h-13c-.916 0-1.666-.78-1.666-1.73V9.734l6.75 4.497c.416.26.916.433 1.416.433.5 0 .917-.173 1.334-.433l5.277-3.516a3.981 3.981 0 01-2.119-.865L12 12.588c-.25.173-.5.173-.667 0l-7.75-5.19V6.88c0-.865.75-1.557 1.583-1.557h9.756a4.013 4.013 0 011.636-1.989z"
        fill="currentColor"
        fillOpacity={fillOpacity}
      />
      <circle cx={18.767} cy={6.6} fill="currentColor" r={2.6} fillOpacity={fillOpacity} />
    </svg>
  );
}

ClassifiedEmailIcon.propTypes = {
  fillOpacity: PropTypes.number,
};

ClassifiedEmailIcon.defaultProps = {
  fillOpacity: 1,
};

export default ClassifiedEmailIcon;
