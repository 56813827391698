import React from 'react'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import EmailThumbnail from './EmailThumbnail'

class ListSummary extends React.Component {
  render() {
    return (
      <div style={{ background: 'white' }} className='pure-g'>
        <div className='pure-u-1'>
          <div className='pure-u-1 pure-u-md-1-8'>
            <img src='http://placehold.it/800x800' className='pure-img' />
          </div>
          <div className='pure-u-1 pure-u-md-7-8'>
            <div className='pure-u-1'>
              <h1>{this.props.data.attributes.name}</h1>
              <p>{(this.props.data.attributes.description || 'n/a').slice(0, 200)}</p>
              <p>#group, #group, #group, #group, #group, #group | <strong>Add to your groups</strong></p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

class ListsDetails extends React.Component {
  render() {
    return (
      <div className='pure-u-1'>
        {this.props.searches.data.lists.map(m => {
        return (
        <div key={m.id} className='pure-u-1'>
            <ListSummary data={m} />
            <div className='pure-u-1-4'>
              <h2>Emails</h2>
              <div className='pure-u-1'>
              {this.props.searches.data.emails.slice(0, 18).map(m => {
                return (<div className='pure-u-1-6' key={m.id}>
                  <img src={`http://lorempixel.com/300/240?q=${m.id}`} className='pure-img' />
                </div>)
              })}
              </div>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum nunc purus, lobortis ac sodales sit amet, euismod porta libero. Duis vehicula sollicitudin aliquam. Sed ut magna eget diam varius aliquet in at enim. Quisque quis ornare quam. Integer semper suscipit massa, ac sollicitudin urna tincidunt at. Curabitur et leo elit. Maecenas magna massa, iaculis et ullamcorper vitae, ornare ut diam. Fusce venenatis at quam eget elementum.</p>
              <Link
                className='pure-button'
                to={`/${m.type}/${m.id}/emails`}
              >
                View all
              </Link>
            </div>
            <div className='pure-u-1-8' />
            <div className='pure-u-1-4'>
              <h2>Reports</h2>
              <h2>Pie chart of days of week</h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum nunc purus, lobortis ac sodales sit amet, euismod porta libero. Duis vehicula sollicitudin aliquam. Sed ut magna eget diam varius aliquet in at enim. Quisque quis ornare quam. Integer semper suscipit massa, ac sollicitudin urna tincidunt at. Curabitur et leo elit. Maecenas magna massa, iaculis et ullamcorper vitae, ornare ut diam. Fusce venenatis at quam eget elementum.</p>
              <Link
                className='pure-button'
                to={`/${m.type}/${m.id}/emails`}
              >
                Select a report
              </Link>
            </div>
            <div className='pure-u-1-8' />
            <div className='pure-u-1-4'>
              <h2>Segments</h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum nunc purus, lobortis ac sodales sit amet, euismod porta libero. Duis vehicula sollicitudin aliquam. Sed ut magna eget diam varius aliquet in at enim. Quisque quis ornare quam. Integer semper suscipit massa, ac sollicitudin urna tincidunt at. Curabitur et leo elit. Maecenas magna massa, iaculis et ullamcorper vitae, ornare ut diam. Fusce venenatis at quam eget elementum.</p>
              <Link
                className='pure-button'
                to={`/${m.type}/${m.id}/emails`}
              >
                Add a user segment
              </Link>
            </div>
          </div>
        )
        })}
      </div>
    )
  }
}

const s = (state) => ({ searches: state.searches })
export default connect(s)(ListsDetails)
