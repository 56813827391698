import React from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import { isEmpty } from 'ramda';
import { withTheme } from '@emotion/react';

import { Box, customTooltip } from '../../../../mc-ui';

const drawLineOnPoints = (chart, color) => {
  // eslint-disable-next-line no-underscore-dangle
  const tooltipActive = chart.tooltip._active;

  if (tooltipActive && tooltipActive.length) {
    // eslint-disable-next-line no-underscore-dangle
    const activePoint = chart.controller.tooltip._active[0];
    const { ctx } = chart;
    const tooltipPosition = activePoint.tooltipPosition();
    const bottomY = chart.scales['y-axis-0'].bottom;

    ctx.save();
    ctx.beginPath();
    ctx.moveTo(tooltipPosition.x, tooltipPosition.y + 7);
    ctx.lineTo(tooltipPosition.x, bottomY);
    ctx.lineWidth = 2;
    ctx.strokeStyle = color;
    ctx.stroke();
    ctx.restore();
  }
};

const dataset = {
  borderWidth: 2,
  fill: false,
  lineTension: 0,
  pointHoverRadius: 8,
  pointBorderWidth: 2,
  pointRadius: 0,
  pointHitRadius: 10,
  pointStyle: 'circle',
  type: 'line',
};

class Chart extends React.PureComponent {
  state = {
    chartReference: null,
  };

  setChartReference = ref => {
    this.setState(currentState => {
      if (currentState.chartReference) return {};
      return { chartReference: ref };
    });
  };

  mainDataset = () => {
    const {
      data: { data },
      theme,
    } = this.props;

    return {
      ...dataset,
      borderColor: theme.colors.blue,
      data: data.data,
      label: 'This Period',
      pointBackgroundColor: theme.colors.white,
      pointBorderColor: theme.colors.blue,
      pointHoverBackgroundColor: theme.colors.blue,
      pointHoverBorderColor: theme.colors.white,
      titles: data.titles,
    };
  };

  historicalTrendDataset = () => {
    const {
      data: { historicalTrendData },
      theme,
    } = this.props;

    return {
      ...dataset,
      borderColor: theme.colors.dark04,
      borderDash: [2, 4],
      data: historicalTrendData.data,
      label: 'Last Year',
      pointBackgroundColor: theme.colors.white,
      pointBorderColor: theme.colors.dark04,
      pointHoverBackgroundColor: theme.colors.dark04,
      pointHoverBorderColor: theme.colors.white,
      titles: historicalTrendData.titles,
    };
  };

  holidaysDataset = () => {
    const {
      data: { data },
      theme,
    } = this.props;
    const tooltipLabels = [];
    let tooltipLabelsIndex = 0;

    const holidaysData = data.data.map((value, index) => {
      if (data.holidays.data.includes(index)) {
        tooltipLabels.push(data.holidays.labels[tooltipLabelsIndex]);
        tooltipLabelsIndex += 1;
        return value;
      }

      tooltipLabels.push(null);
      return null;
    });

    return {
      ...dataset,
      borderColor: 'rgba(0, 0, 0, 0)', // transparent
      data: holidaysData,
      label: 'Holidays',
      pointBackgroundColor: theme.colors.purpleMedium,
      pointBorderColor: theme.colors.white,
      pointHoverBackgroundColor: theme.colors.purpleMedium,
      pointHoverBorderColor: theme.colors.white,
      pointRadius: 8,
      titles: data.titles,
      tooltipLabels,
    };
  };

  compareDatesDataset = () => {
    const {
      data: { datesData },
      theme,
    } = this.props;

    return {
      ...dataset,
      borderColor: theme.colors.tealMedium,
      data: datesData.data,
      label: 'Comparison Period',
      pointBackgroundColor: theme.colors.white,
      pointBorderColor: theme.colors.tealMedium,
      pointHoverBackgroundColor: theme.colors.tealMedium,
      pointHoverBorderColor: theme.colors.white,
      titles: datesData.titles,
    };
  };

  compareBrandsDataset = brandData => {
    const { theme } = this.props;

    return {
      ...dataset,
      borderColor: theme.colors[brandData.color],
      data: brandData.data,
      label: brandData.brandName,
      pointBackgroundColor: theme.colors.white,
      pointBorderColor: theme.colors[brandData.color],
      pointHoverBackgroundColor: theme.colors[brandData.color],
      pointHoverBorderColor: theme.colors.white,
      titles: brandData.titles,
    };
  };

  data = () => {
    const {
      data: { brandsData, data, datesData, historicalTrendData },
      historicalTrend,
      showHolidays,
    } = this.props;

    const chartData = { labels: [], datasets: [] };

    chartData.datasets.push(this.mainDataset());
    chartData.labels = data.labels;

    if (showHolidays) {
      chartData.datasets.push(this.holidaysDataset());
    }

    if (historicalTrend) {
      chartData.labels =
        historicalTrendData.labels.length > data.labels.length
          ? historicalTrendData.labels
          : data.labels;
      chartData.datasets.push(this.historicalTrendDataset());
    }

    if (datesData && !isEmpty(datesData)) {
      chartData.datasets.push(this.compareDatesDataset());
    }

    if (brandsData && !isEmpty(brandsData)) {
      brandsData.forEach(brandData => {
        chartData.datasets.push(this.compareBrandsDataset(brandData));
      });
    }

    return chartData;
  };

  options = () => {
    const {
      chart,
      data: { brandsData, datesData, max },
      generateTooltipHTML,
      historicalTrend,
      showHolidays,
      theme,
    } = this.props;
    const PERCENTAGE_SIGN = chart === 'promotional' ? '%' : '';

    let tooltipLabelPrefix = '';
    if (chart === 'frequency') {
      tooltipLabelPrefix = 'Avg. Emails Sent';
    } else if (chart === 'subject_line_length') {
      tooltipLabelPrefix = 'Avg. Subject Line Length';
    } else if (chart === 'promotional') {
      tooltipLabelPrefix = 'Avg. Promo Rate';
    }

    let yMax = 0;
    let yStepSize = 0;
    if (max < 10) {
      yMax = 10;
      yStepSize = 2;
    } else if (max < 15) {
      yMax = 15;
      yStepSize = 5;
    } else if (max < 25) {
      yMax = 25;
      yStepSize = 5;
    } else if (max < 50) {
      yMax = 50;
      yStepSize = 10;
    } else if (max < 75) {
      yMax = 75;
      yStepSize = 15;
    } else if (max < 100) {
      yMax = 100;
      yStepSize = 20;
    } else {
      yMax = 100;
      yStepSize = 20;
    }

    return {
      animation: false,
      legend: { display: false },
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false,
            },
            ticks: {
              fontColor: theme.colors.dark04,
              fontFamily: theme.font.family,
              fontSize: 10,
              lineHeight: 1.56,
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              fontColor: theme.colors.dark04,
              fontFamily: theme.font.family,
              fontSize: 10,
              lineHeight: 1.56,
              max: yMax,
              min: 0,
              stepSize: yStepSize,
              userCallback(label) {
                return `${label}${PERCENTAGE_SIGN}`;
              },
            },
          },
        ],
      },
      tooltips: {
        ...customTooltip(
          this.state.chartReference,
          generateTooltipHTML({ historicalTrend, showHolidays }),
        ),
        callbacks: {
          label(tooltipItem, data) {
            const value =
              chart === 'subject_line_length' ? tooltipItem.yLabel : tooltipItem.yLabel.toFixed(2);

            if (tooltipItem.datasetIndex === 0) {
              return `${tooltipLabelPrefix}: ${value}${PERCENTAGE_SIGN}{THIS_PERIOD}`;
            } else if (showHolidays && tooltipItem.datasetIndex === 1) {
              const { tooltipLabels } = data.datasets[tooltipItem.datasetIndex];
              return `${tooltipLabels[tooltipItem.index]}{HOLIDAYS}`;
            } else if (
              (showHolidays && historicalTrend && tooltipItem.datasetIndex === 2) ||
              (!showHolidays && historicalTrend && tooltipItem.datasetIndex === 1)
            ) {
              return `${tooltipLabelPrefix}: ${value}${PERCENTAGE_SIGN}{LAST_YEAR}`;
            } else if (
              datesData &&
              !isEmpty(datesData) &&
              ((!showHolidays && !historicalTrend && tooltipItem.datasetIndex === 1) ||
                (showHolidays && !historicalTrend && tooltipItem.datasetIndex === 2) ||
                (!showHolidays && historicalTrend && tooltipItem.datasetIndex === 2) ||
                (showHolidays && historicalTrend && tooltipItem.datasetIndex === 3))
            ) {
              return `${tooltipLabelPrefix}: ${value}${PERCENTAGE_SIGN}{COMPARISON_PERIOD}`;
            } else if (
              brandsData &&
              !isEmpty(brandsData) &&
              ((!showHolidays &&
                !historicalTrend &&
                [1, 2, 3, 4, 5].includes(tooltipItem.datasetIndex)) ||
                (showHolidays &&
                  !historicalTrend &&
                  [2, 3, 4, 5, 6].includes(tooltipItem.datasetIndex)) ||
                (!showHolidays &&
                  historicalTrend &&
                  [2, 3, 4, 5, 6].includes(tooltipItem.datasetIndex)) ||
                (showHolidays &&
                  historicalTrend &&
                  [3, 4, 5, 6, 7].includes(tooltipItem.datasetIndex)))
            ) {
              const { label: brandName } = data.datasets[tooltipItem.datasetIndex];
              return `${brandName} - ${tooltipLabelPrefix}: ${value}${PERCENTAGE_SIGN}{BRAND_COMPARISON}`;
            }

            return null;
          },
          title(tooltipItems, data) {
            return tooltipItems.map(
              tooltipItem => data.datasets[tooltipItem.datasetIndex].titles[tooltipItem.index],
            );
          },
        },
      },
    };
  };

  plugins = () => {
    const { theme } = this.props;

    return [
      {
        afterDatasetsDraw: chart => {
          drawLineOnPoints(chart, theme.colors.dark4);
        },
      },
    ];
  };

  render() {
    return (
      <Box css={{ height: '256px' }}>
        <Line
          data={this.data()}
          height={100}
          options={this.options()}
          plugins={this.plugins()}
          ref={this.setChartReference}
        />
      </Box>
    );
  }
}

Chart.defaultProps = {
  historicalTrend: null,
};

Chart.propTypes = {
  chart: PropTypes.string.isRequired,
  data: PropTypes.shape({}).isRequired,
  generateTooltipHTML: PropTypes.func.isRequired,
  historicalTrend: PropTypes.bool,
  showHolidays: PropTypes.bool.isRequired,
  theme: PropTypes.shape({}).isRequired,
};

export default withTheme(Chart);
