import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import {
  Checkbox,
  CheckboxGroup,
  Flex,
  Heading,
  Label,
  MultiSelect,
  NumberField,
  Select,
  TextField,
} from '../../mc-ui';

import MailChartsProTooltip from './formFields/MailChartsProTooltip';
import HistoricalDataTooltip from './formFields/HistoricalDataTooltip';
import PromotionalTooltip from './formFields/PromotionalTooltip';

import {
  countriesOptions,
  industriesOptions,
  arrayToOptions,
  twitterFollowersOptions,
} from './helpers';

class FormFields extends PureComponent {
  constructor(props) {
    super(props);

    const { query } = props.router.location;

    let regions = [];
    if (query.countries) {
      regions = this.getRegionsByCountry(query.countries);
    }

    this.state = {
      regions: {
        loading: false,
        data: regions,
      },
    };
  }

  getRegionsByCountry = countryName => {
    const { countries } = this.props;
    const country = countries.find(c => c.key === countryName);
    return country.regions.buckets;
  };

  handleCountryChange = value => {
    if (!value) return;
    const regions = this.getRegionsByCountry(value);

    this.setState(
      () => ({ regions: { loading: true, data: [] } }),
      () => {
        this.setState(() => ({
          regions: { loading: false, data: regions },
        }));
      },
    );
  };

  handlePromotionalRateChange = value => {
    const { changeForm } = this.props;
    let promotionalFrom;
    let promotionalTo;

    switch (value) {
      case 'none':
        promotionalFrom = 0;
        promotionalTo = 0;
        break;
      case 'light':
        promotionalFrom = 0;
        promotionalTo = 25;
        break;
      case 'average':
        promotionalFrom = 25;
        promotionalTo = 50;
        break;
      case 'heavy':
        promotionalFrom = 50;
        promotionalTo = 75;
        break;
      case 'custom':
        promotionalFrom = 25;
        promotionalTo = 100;
        break;
      default:
        promotionalFrom = undefined;
        promotionalTo = undefined;
    }

    changeForm('promotional_from', promotionalFrom);
    changeForm('promotional_to', promotionalTo);
  };

  render() {
    const { countries, groups, technologies, twitterFollowers, user } = this.props;
    const { regions } = this.state;
    const isPro = !user.attributes.restricted;

    return (
      <div>
        <Flex justifyContent="space-between">
          <Label htmlFor="q" width="746px">
            Search by keyword or website
            <Field
              autoComplete="mcBrandQuery"
              component={TextField}
              id="q"
              name="q"
              placeholder="nike or http://www.nike.com"
            />
          </Label>

          <Label htmlFor="twitterFollower" width="230px">
            Twitter followers
            <Field
              component={Select}
              id="twitterFollower"
              name="twitter_followers"
              options={twitterFollowersOptions(twitterFollowers)}
            />
          </Label>
        </Flex>

        <Flex justifyContent="space-between" css={{ marginTop: '24px' }}>
          <Label htmlFor="group_id" width="490px">
            Industries
            <Field
              component={MultiSelect}
              id="group_id"
              name="group_id"
              options={industriesOptions(groups.data)}
              isDisabled={groups.loading}
            />
          </Label>

          <Label htmlFor="country" width="230px">
            Country (Location of HQ)
            <Field
              component={Select}
              id="country"
              name="countries"
              options={countriesOptions(countries)}
              onChange={this.handleCountryChange}
            />
          </Label>

        </Flex>

        <Heading
          as="h4"
          css={{
            margin: '32px 0 16px 0 !important',
            lineHeight: `${24 / 18}em`,
            padding: 0,
          }}
        >
          Campaign activity
        </Heading>

        <Flex justifyContent="space-between">
          <CheckboxGroup title="Weekly frequency" width="230px">
            <Field
              component={Checkbox}
              type="checkbox"
              id="allFrequency"
              label="Any"
              name="frequency"
              value="any"
            />
            <Field
              component={Checkbox}
              type="checkbox"
              id="oneWeekFrequency"
              label="1 / week"
              name="frequency"
              value="<1"
            />
            <Field
              component={Checkbox}
              type="checkbox"
              id="twoWeeksFrequency"
              label="2 / week"
              name="frequency"
              value="2"
            />
            <Field
              component={Checkbox}
              type="checkbox"
              id="threeWeeksFrequency"
              label="3 / week"
              name="frequency"
              value="3"
            />
            <Field
              component={Checkbox}
              type="checkbox"
              id="fourWeeksFrequency"
              label="4 / week"
              name="frequency"
              value="4"
            />
            <Field
              component={Checkbox}
              type="checkbox"
              id="fiveWeeksFrequency"
              label="5 / week"
              name="frequency"
              value="5"
            />
            <Field
              component={Checkbox}
              type="checkbox"
              id="moreThanFiveWeeksFrequency"
              label="5+ / week"
              name="frequency"
              value="5+"
            />
          </CheckboxGroup>

          <CheckboxGroup title="User journeys" width="230px">
            <Field
              component={Checkbox}
              type="checkbox"
              id="allUserJourneys"
              label="Any"
              name="journey_types"
              value="any"
            />
            <Field
              component={Checkbox}
              type="checkbox"
              id="emailSubscriberUserJourneys"
              label="Email subscriber"
              name="journey_types"
              value="email_subscriber"
            />
            <Field
              component={Checkbox}
              type="checkbox"
              id="subscriptionLifecycleUserJourneys"
              label="Subscription lifecycle"
              name="journey_types"
              value="lifecycle"
            />
            <Field
              component={Checkbox}
              type="checkbox"
              id="cartAbandonerUserJourneys"
              label="Cart abandoner"
              name="journey_types"
              value="cart_abandoner"
            />
            <Field
              component={Checkbox}
              type="checkbox"
              id="browseAbandonerUserJourneys"
              label="Browse abandoner"
              name="journey_types"
              value="browse_abandoner"
            />
            <Field
              component={Checkbox}
              type="checkbox"
              id="purchaserUserJourneys"
              label="Purchaser"
              name="journey_types"
              value="purchaser"
            />
            <Field
              component={Checkbox}
              type="checkbox"
              id="churnUserJourneys"
              label="Churn"
              name="journey_types"
              value="churn"
            />
          </CheckboxGroup>

          <Flex direction="column" alignItems="center" css={{ width: '230px' }}>
            <Label htmlFor="promotional" css={{ marginBottom: '24px' }}>
              Promotional rate
              <PromotionalTooltip />
              <Field
                component={Select}
                id="promotional"
                name="promotional"
                onChange={this.handlePromotionalRateChange}
                options={[
                  { value: 'any', label: 'Any' },
                  { value: 'none', label: 'None' },
                  { value: 'light', label: 'Light' },
                  { value: 'average', label: 'Average' },
                  { value: 'heavy', label: 'Heavy' },
                  { value: 'custom', label: 'Custom' },
                ]}
              />
            </Label>

            <Flex alignItems="center" justifyContent="space-between" css={{ width: '174px' }}>
              <Field
                component={NumberField}
                name="promotional_from"
                min="0"
                max="100"
                placeholder="min"
              />
              <Label
                css={{
                  display: 'inline',
                  margin: '0',
                  padding: '0',
                  textAlign: 'center',
                  width: '10px',
                }}
              >
                to
              </Label>
              <Field
                component={NumberField}
                name="promotional_to"
                min="0"
                max="100"
                placeholder="max"
              />
            </Flex>
          </Flex>

          <Flex direction="column" css={{ width: '230px' }}>
            <Label htmlFor="historicalData" css={{ marginBottom: '24px' }}>
              Historical data
              <HistoricalDataTooltip />
              <Field
                component={MultiSelect}
                id="historicalData"
                name="history"
                options={[
                  { value: '1y', label: '1 year' },
                  { value: '2y', label: '2 years' },
                  { value: '3y', label: '3 years' },
                  { value: '4y', label: '4 years' },
                  { value: '5y', label: '5 years' },
                ]}
              />
            </Label>

            <Label htmlFor="mostPopularSendDay" css={{ marginBottom: '24px' }}>
              Most popular send day
              <Field
                component={MultiSelect}
                id="mostPopularSendDay"
                name="sent_at_day_of_week"
                options={[
                  { value: 'monday', label: 'Monday' },
                  { value: 'tuesday', label: 'Tuesday' },
                  { value: 'wednesday', label: 'Wednesday' },
                  { value: 'thursday', label: 'Thursday' },
                  { value: 'friday', label: 'Friday' },
                  { value: 'saturday', label: 'Saturday' },
                  { value: 'sunday', label: 'Sunday' },
                ]}
              />
            </Label>

            <Label htmlFor="mostPopularSendTime">
              Most popular send time
              <Field
                component={MultiSelect}
                id="mostPopularSendTime"
                name="sent_at_hour_of_day"
                options={[
                  { value: '00', label: '00:00' },
                  { value: '01', label: '01:00' },
                  { value: '02', label: '02:00' },
                  { value: '03', label: '03:00' },
                  { value: '04', label: '04:00' },
                  { value: '05', label: '05:00' },
                  { value: '06', label: '06:00' },
                  { value: '07', label: '07:00' },
                  { value: '08', label: '08:00' },
                  { value: '09', label: '09:00' },
                  { value: '10', label: '10:00' },
                  { value: '11', label: '11:00' },
                  { value: '12', label: '12:00' },
                  { value: '13', label: '13:00' },
                  { value: '14', label: '14:00' },
                  { value: '15', label: '15:00' },
                  { value: '16', label: '16:00' },
                  { value: '17', label: '17:00' },
                  { value: '18', label: '18:00' },
                  { value: '19', label: '19:00' },
                  { value: '20', label: '20:00' },
                  { value: '21', label: '21:00' },
                  { value: '22', label: '22:00' },
                  { value: '23', label: '23:00' },
                ]}
              />
            </Label>
          </Flex>
        </Flex>

        <Heading
          as="h4"
          css={{
            margin: '14px 0 8px 0 !important',
            lineHeight: `${24 / 18}em`,
            padding: 0,
          }}
        >
          Technologies
          {!isPro && <MailChartsProTooltip />}
        </Heading>

        <Flex>
          <Label htmlFor="technology">
            <Field
              component={MultiSelect}
              id="technology"
              name="technologies"
              options={arrayToOptions(technologies, 'name', false)}
              menuPlacement="top"
              isSelectionAllowed={isPro}
            />
          </Label>
        </Flex>
      </div>
    );
  }
}

FormFields.propTypes = {
  changeForm: PropTypes.func.isRequired,
  countries: PropTypes.arrayOf(PropTypes.object).isRequired,
  groups: PropTypes.shape({
    data: PropTypes.array,
    loading: PropTypes.bool,
  }).isRequired,
  router: PropTypes.shape({
    location: PropTypes.shape({
      query: PropTypes.object,
    }),
  }).isRequired,
  technologies: PropTypes.arrayOf(PropTypes.object).isRequired,
  twitterFollowers: PropTypes.arrayOf(PropTypes.object).isRequired,
  user: PropTypes.shape({}).isRequired,
};

export default FormFields;
