import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ascend, prop, sort } from 'ramda';

import { journeysSelectors as selectors } from '../../../modules/journeys';

import { Box, Flex } from '../../mc-ui';
import Select from '../../mc-ui/overrides/ReactSelect';

import { singleValueWithLabel } from './SingleValue';

const sortTypes = sort(ascend(prop('type')));
const formatType = type =>
  type
    .split('_')
    .map(word => `${word.charAt(0).toUpperCase()}${word.slice(1)}`)
    .join(' ');

const SORT_OPTIONS = [
  { value: '-start_date', label: 'Newest' },
  { value: 'start_date', label: 'Oldest' },
];

const STATUS_OPTIONS = [
  { value: undefined, label: 'Completed' },
  { value: 'upcoming', label: 'Upcoming' },
];

class Filters extends React.PureComponent {
  brandOptions = () => {
    const { brands } = this.props;

    return [{ label: 'All brands in this group', value: undefined }].concat(
      brands.map(brand => ({ label: brand.attributes.name, value: brand.id })),
    );
  };

  updateValues = (option, meta) => {
    const { location, onSubmit } = this.props;

    const query = {
      ...location.query,
      [meta.name]: option.value,
    };

    onSubmit(query);
  };

  values = () => {
    const {
      location: { query },
    } = this.props;

    return {
      brand: this.brandOptions().find(option => option.value === query.company_id),
      journey_type: this.journeyTypesOptions().find(option => option.value === query.journey_type),
      sort: SORT_OPTIONS.find(option => option.value === query.sort) || { label: 'Newest' },
      status: STATUS_OPTIONS.find(option => option.value === query.status),
    };
  };

  journeyTypesOptions = () => {
    const {
      loading,
      meta: { journeyTypes },
    } = this.props;

    const options = [{ value: undefined, label: 'All journey types' }];

    if (!loading) {
      sortTypes(journeyTypes).forEach(({ type }) => {
        options.push({ value: type, label: formatType(type) });
      });
    }

    return options;
  };

  render() {
    const { isJourneysListEmpty, loading, model } = this.props;

    const isDisabled = loading || isJourneysListEmpty;

    return (
      <Flex padding="0 32px">
        {model.type !== 'companies' && (
          <Box flex="3" marginRight="8px">
            <Select
              isDisabled={isDisabled}
              name="company_id"
              onChange={this.updateValues}
              options={this.brandOptions()}
              value={this.values().brand}
            />
          </Box>
        )}

        <Box flex="3" marginRight="8px">
          <Select
            isDisabled={isDisabled}
            name="journey_type"
            onChange={this.updateValues}
            options={this.journeyTypesOptions()}
            value={this.values().journey_type}
          />
        </Box>

        <Box flex="2" marginRight="8px">
          <Select
            components={{ SingleValue: singleValueWithLabel('Sort') }}
            isDisabled={isDisabled}
            name="sort"
            onChange={this.updateValues}
            options={SORT_OPTIONS}
            placeholder="Sort by"
            value={this.values().sort}
          />
        </Box>

        <Box flex="2">
          <Select
            components={{ SingleValue: singleValueWithLabel('Status') }}
            isDisabled={isDisabled}
            name="status"
            onChange={this.updateValues}
            options={STATUS_OPTIONS}
            value={this.values().status}
          />
        </Box>
      </Flex>
    );
  }
}

Filters.defaultProps = {
  brands: [],
};

Filters.propTypes = {
  brands: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      attributes: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
  ),
  isJourneysListEmpty: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    query: PropTypes.shape({
      company_id: PropTypes.string,
      journey_type: PropTypes.string,
      sort: PropTypes.string,
      status: PropTypes.string,
    }),
  }).isRequired,
  meta: PropTypes.shape({
    journeyTypes: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
      }),
    ),
  }).isRequired,
  model: PropTypes.shape({
    type: PropTypes.string,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = (state, { location, model }) => ({
  isJourneysListEmpty: selectors.isJourneysListEmpty(model, location.query)(state),
  meta: selectors.getMeta(model, location.query)(state),
});

export default connect(mapStateToProps)(Filters);
