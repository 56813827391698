import styled from "@emotion/styled";

const JourneyExampleContainer = styled("div")(({ active }) => ({
  opacity: active ? '1' : '0.3',
  '& > h4': {
    lineHeight: `${24 / 18}em`,
    margin: '24px 0 4px 0',
  },
  '& > small': {
    color: '#A0AEBA',
    display: 'block',
    fontSize: `${12 / 16}em`,
    lineHeight: `${20 / 16}em`,
  },
  '& > p': {
    lineHeight: `${24 / 16}em`,
    margin: '12px 0 0 0',
  },
  '& .pure-button': {
    fontSize: `${12 / 16}em`,
  },
}));

export default JourneyExampleContainer;
