import React from 'react';
import PropTypes from 'prop-types';

import cssStyles from './styles.css';

const JourneyViewToggle = function ({ journeyView, handleJourneyViewChange }) {
  // Consistent item width so the centerline of the widget aligns with the centerline of the screen
  const width = 76;
  return (
    <div className={`${cssStyles['toggle-view']}`}>
      <div className="pure-menu pure-menu-horizontal" style={{ textAlign: 'right' }}>
        <ul className="pure-menu-list" style={{ marginRight: 0 }}>
          <li
            className={`pure-menu-item ${journeyView === 'grid' ? cssStyles['toggle-view-active'] : null}`}
            style={{ width }}
          >
            <a
              className="pure-menu-link"
              onClick={() => { handleJourneyViewChange('grid'); }}
              style={{
                  background: `url(/thumbnail-${journeyView === 'grid' ? 'white' : 'blue'}.svg) no-repeat left center`,
                  paddingLeft: 22,
                }}
            > 30 Day
            </a>
          </li>
          <li
            className={`pure-menu-item ${journeyView === 'timeline' ? cssStyles['toggle-view-active'] : null}`}
            style={{ width }}
          >
            <a
              className="pure-menu-link"
              onClick={() => {
                            handleJourneyViewChange('timeline');
                          }}
              style={{ background: `url(/timeline-${journeyView === 'timeline' ? 'white' : 'blue'}.svg) no-repeat left center` }}
            > Timeline
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

JourneyViewToggle.propTypes = {
  journeyView: PropTypes.oneOf(['grid', 'timeline']).isRequired,
  handleJourneyViewChange: PropTypes.func.isRequired,
};

export default JourneyViewToggle;
