import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { palette } from '../lib/color';

const styles = {
  event: {
    position: 'relative',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: 80,
    padding: 20,
    paddingLeft: 74,
    borderRadius: 3,
    background: palette.orange,
  },
  icon: {
    position: 'absolute',
    left: 20,
    top: 28,
    width: 24,
    height: 24,
  },
  text: {
    margin: 0,
    color: palette.white,
    fontSize: `${12 / 16}em`,
  },
  textSecondary: {
    margin: 0,
    color: palette.white,
    fontSize: `${8 / 16}em`,
  },
};

const JourneyTimelineEvent = ({ event, width }) => {
  return (
    <div style={{...styles.event, width }}>
      <img src="/journey-event.svg" alt="" style={styles.icon} />
      <p style={styles.text}>{event.attributes.label}</p>
      <p style={styles.textSecondary}>{moment(event.attributes.date).format('MMM. DD, YYYY – h:mm a')}</p>
    </div>
  );
};

JourneyTimelineEvent.propTypes = {
  event: PropTypes.shape({
    attributes: PropTypes.shape({
      label: PropTypes.string,
      date: PropTypes.string,
    }),
  }).isRequired,
  width: PropTypes.number.isRequired,
};


export default JourneyTimelineEvent;
