import humanize from 'humanize-number';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React from 'react';
import { Flex, Text } from '../mc-ui';
import CheckboxDefaultIcon from '../mc-ui/icons/CheckboxDefaultIcon';
import CheckboxExcludeIcon from '../mc-ui/icons/CheckboxExcludeIcon';
import CheckboxSelectedIcon from '../mc-ui/icons/CheckboxSelectedIcon';

export default function ItemsSelector({ onToggle, total, totalSelected }) {
  return (
    <Flex alignItems="center" cursor="pointer" height="32px" onClick={onToggle} width="unset">
      {totalSelected === 0 && <CheckboxDefaultIcon />}
      {totalSelected > 0 && totalSelected < total && <CheckboxExcludeIcon />}
      {totalSelected === total && <CheckboxSelectedIcon />}

      {totalSelected === 0 ? (
        <Text lineHeight="32px" margin="0 0 0 8px">
          Click to select all {humanize(total)} visible {pluralize('email', total)}
        </Text>
      ) : (
        <Text lineHeight="32px" margin="0 0 0 8px">
          {humanize(totalSelected)} {pluralize('email', totalSelected)} selected
        </Text>
      )}
    </Flex>
  );
}

ItemsSelector.propTypes = {
  onToggle: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  totalSelected: PropTypes.number.isRequired,
};
