import React from 'react';
import PropTypes from 'prop-types';
import humanize from 'humanize-number';
import pluralize from 'pluralize';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { isEmpty, take } from 'ramda';

import { groupOverviewSelectors as selectors } from '../../../modules/groupOverview';

import { Box, Flex, Grid, Text } from '../../mc-ui';
import DownloadEmailsDropdown from '../../DownloadEmailsDropdown';
import EmailThumbnail from '../../ui/EmailThumbnail';

import Title from '../Title';

import LoadingStatus from './LoadingStatus';

const MAX_EMAILS = 8;

function isLastEmail(index, latestEmails) {
  return index === MAX_EMAILS - 1 || index === latestEmails.length - 1;
}

function LatestEmails({ latestEmails, loading, meta, model }, { router }) {
  const {
    location: { pathname },
  } = router;

  let browseEmailsPathname = `${pathname.replace('/overview', '')}/emails`;
  if (pathname === '/') {
    browseEmailsPathname = '/dashboard/emails';
  }

  const approximateEmails = isEmpty(meta) ? 0 : meta.stats.approximateEmails;

  return (
    <Flex direction="column">
      <Flex justifyContent="space-between" marginBottom="24px">
        <Title>Latest emails</Title>

        {!loading && <DownloadEmailsDropdown model={model} />}
      </Flex>

      {loading || isEmpty(latestEmails) ? (
        <LoadingStatus latestEmails={latestEmails} loading={loading} />
      ) : (
        <Grid columnGap="38px" gridTemplateColumns="repeat(4, 1fr)" rowGap="22px">
          {take(MAX_EMAILS, latestEmails).map((email, index) => (
            <Box
              height="300px"
              key={`groupOverview-latestEmails-${email.id}`}
              position="relative"
              width="180px"
            >
              <EmailThumbnail
                brand={{
                  id: email.attributes.company_id,
                  attributes: { name: email.attributes.company_name },
                }}
                email={email}
              />
              {isLastEmail(index, latestEmails) && (
                <Link to={{ pathname: browseEmailsPathname }}>
                  <Flex
                    alignItems="center"
                    backgroundColor="dark08"
                    borderRadius="4px"
                    bottom="0"
                    justifyContent="center"
                    left="0"
                    position="absolute"
                    right="0"
                    top="0"
                  >
                    <Text
                      color="white"
                      fontSize="14px"
                      fontWeight="500"
                      letterSpacing="0.02em"
                      lineHeight="1.25em"
                      textAlign="center"
                      width="72px"
                    >
                      See all {humanize(approximateEmails)} {pluralize('email', approximateEmails)}
                    </Text>
                  </Flex>
                </Link>
              )}
            </Box>
          ))}
        </Grid>
      )}
    </Flex>
  );
}

LatestEmails.contextTypes = {
  router: PropTypes.object,
};

LatestEmails.defaultProps = {
  model: {},
};

LatestEmails.propTypes = {
  latestEmails: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      attributes: PropTypes.shape({
        company_id: PropTypes.number,
        company_name: PropTypes.string,
      }),
    }),
  ).isRequired,
  loading: PropTypes.bool.isRequired,
  meta: PropTypes.shape({
    stats: PropTypes.shape({
      approximateEmails: PropTypes.number,
    }),
  }).isRequired,
  model: PropTypes.shape({}),
};

const mapStateToProps = state => ({
  latestEmails: selectors.getData(state).latestEmails || [],
  meta: selectors.getData(state).meta || {},
  loading: selectors.getDataAndInsightsLoading(state),
});

export default connect(mapStateToProps)(LatestEmails);
