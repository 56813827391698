/* eslint-disable react/prop-types */
import React from 'react';
import styled from "@emotion/styled";

import { Link } from 'react-router';

import CheckboxMultiSelectIcon from '../../mc-ui/icons/CheckboxMultiSelectIcon';
import GridIcon from '../../mc-ui/icons/GridIcon';
import ListInboxIcon from '../../mc-ui/icons/ListInboxIcon';

export const ViewButtons = styled("div")({
  display: 'flex',
  marginRight: '8px',
  width: '88px',
});

export const ViewButton = styled(Link, {
  shouldForwardProp: prop => ['active', 'disabled'].indexOf(prop) === -1
})(
  ({ active, disabled, theme }) => ({
    alignItems: 'center',
    backgroundColor: active ? theme.colors.dark1 : theme.colors.white,
    border: '1px solid transparent',
    borderColor: active ? theme.colors.dark1 : theme.colors.dark5,
    boxSizing: 'border-box',
    color: active ? theme.colors.white : theme.colors.dark06,
    cursor: disabled ? 'default' : 'pointer',
    display: 'flex',
    height: '32px',
    justifyContent: 'center',
    margin: '0',
    opacity: disabled ? 0.4 : 1,
    padding: '0',
    width: '44px',
  }),
);

const disabledOnClickHandler = event => {
  event.preventDefault();
};

ViewButtons.ListButton = ({ disabled, onClick, ...props }) => {
  const onClickFunc = disabled ? disabledOnClickHandler : () => {};
  return (
    <ViewButton
      disabled={disabled}
      onClick={onClickFunc}
      {...props}
      css={{ borderRadius: '0 4px 4px 0' }}
    >
      <ListInboxIcon />
    </ViewButton>
  );
};

ViewButtons.ThumbnailButton = ({ disabled, onClick, ...props }) => {
  const onClickFunc = disabled ? disabledOnClickHandler : () => {};
  return (
    <ViewButton
      disabled={disabled}
      onClick={onClickFunc}
      {...props}
      css={{ borderRadius: '4px 0 0 4px' }}
    >
      <GridIcon />
    </ViewButton>
  );
};

const BulkButtonWrapper = styled("button")(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.colors.white,
  border: `1px solid ${theme.colors.dark5}`,
  borderRadius: '4px',
  boxSizing: 'border-box',
  color: theme.colors.dark06,
  display: 'flex',
  fontFamily: theme.font.family,
  fontSize: 14,
  fontWeight: 500,
  height: '32px',
  justifyContent: 'center',
  margin: '0 0 0 8px',
  padding: '0',
  transition: 'border 250ms, background 250ms, color 250ms, opacity 250ms',
  width: '40px',
  '&[disabled], &:disabled': {
    color: theme.colors.dark02,
  },
}));

export const BulkButton = props => (
  <BulkButtonWrapper {...props}>
    <CheckboxMultiSelectIcon />
  </BulkButtonWrapper>
);

export const CancelBulkButton = styled("button")(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.colors.white,
  border: `1px solid ${theme.colors.dark5}`,
  borderRadius: '4px',
  boxSizing: 'border-box',
  color: theme.colors.redDark,
  display: 'flex',
  fontFamily: theme.font.family,
  fontSize: 14,
  fontWeight: 500,
  height: '32px',
  justifyContent: 'center',
  margin: '0',
  padding: '0',
  transition: 'border 250ms, background 250ms, color 250ms, opacity 250ms',
  width: '72px',
}));
