import styled from "@emotion/styled";

const SentWrapper = styled("div")({
  margin: '0 auto',
  paddingTop: '80px',
  textAlign: 'center',
  width: '380px',
  '& > h3': {
    fontSize: `${24 / 16}em`,
    lineHeight: `${32 / 24}em`,
    margin: '24px 0 0 3px',
    textAlign: 'center',
  },
  '& > p': {
    fontSize: `${14 / 16}em`,
    lineHeight: `${20 / 14}em`,
    margin: '0',
    textAlign: 'center',
  },
  '& > button': {
    marginTop: '30px',
    maxHeight: '40px',
    maxWidth: '100px',
  },
});

export default SentWrapper;
