import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { isEmpty } from 'ramda';

import { Flex, Heading } from '../../mc-ui';

import isQueryEmpty from '../isQueryEmpty';

import SortControl from './SortControl';

const TopControls = ({ brands, loading, ...props }) => {
  const {
    router: {
      location: { query },
    },
  } = props;

  let currentBrandsTotal = 0;
  let brandsTotal = 0;
  let disabled = true;

  if (!isQueryEmpty(query) && !loading && !isEmpty(brands)) {
    currentBrandsTotal = brands.data.length;
    brandsTotal = brands.meta.total;
    disabled = false;
  }

  return (
    <Flex alignItems="center" justifyContent="space-between" marginBottom="32px">
      <Heading
        as="h5"
        color="dark08"
        fontWeight="bold"
        lineHeight="1.56em"
        css={{ letterSpacing: '0.08em' }}
      >
        {currentBrandsTotal === brandsTotal
          ? `${brandsTotal} ${pluralize('result', brandsTotal)}`
          : `Showing ${currentBrandsTotal} of ${brandsTotal} ${pluralize('result', brandsTotal)}`}
      </Heading>

      <Flex alignItems="center" justifyContent="flex-end" width="294px">
        <SortControl disabled={disabled} {...props} />
      </Flex>
    </Flex>
  );
};

TopControls.propTypes = {
  brands: PropTypes.shape({
    meta: PropTypes.shape({ total: PropTypes.number }),
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  router: PropTypes.shape({
    location: PropTypes.shape({
      query: PropTypes.shape({}),
    }),
  }).isRequired,
};

export default TopControls;
