import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Box, Flex, Heading } from '../../../mc-ui';
import BrandLogo from '../../../ui/BrandLogo';
import Controls from './Controls';
import MessageInfo from './MessageInfo';
import * as S from './styles';
import MessageImage from './MessageImage';

export default function Message({ data }) {
  const [brand] = data.relationships.companies.data;

  return (
    <Box width="600px">
      <MessageInfo data={data} />
      <Controls data={data} />

      <Flex backgroundColor="dark6" justifyContent="center" padding="16px 0 44px 0">
        <S.PhoneWrapper>
          <S.PhoneHeader>
            <BrandLogo brand={brand} css={{ height: '32px', width: '32px' }} />
            <Heading as="h4" fontSize="14px" lineHeight="18px">
              {brand.attributes.name}
            </Heading>
          </S.PhoneHeader>

          <S.PhoneBody>
            <S.Message>{data.attributes.full_text}</S.Message>

            {data.attributes.images.map(image => (
              <MessageImage key={image.original} guid={data.attributes.guid} image={image} />
            ))}

            <S.SentAt>
              {moment(data.attributes.sent_at).format('h:mm A, ddd DD MMMM YYYY')}
            </S.SentAt>
          </S.PhoneBody>
        </S.PhoneWrapper>
      </Flex>
    </Box>
  );
}

Message.propTypes = {
  data: PropTypes.shape({}).isRequired,
};
