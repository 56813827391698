/* global document, window */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import TechnologiesList from './TechnologiesList';
import TechnologiesLoadMore from './technologies/LoadMore';

class BrowseTechnologies extends React.Component {
  UNSAFE_componentWillMount() {
    const { requestTechnologies, suggestions } = this.props;
    const technologyId = suggestions.data.filter(d => d.type === 'technologies').map(c => c.id);
    requestTechnologies(technologyId);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const windowHeight =
      'innerHeight' in window ? window.innerHeight : document.documentElement.offsetHeight;
    const { body, documentElement: html } = document;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight,
    );
    const windowBottom = windowHeight + window.pageYOffset;
    if (windowBottom >= docHeight) {
      setTimeout(() => {
        this.props.requestTechnologiesPagination();
      }, 250); // adds a slight delay to increase "fetching" effect for end-user
    }
  };

  renderQueryInfo = () => {
    const { router, technologies } = this.props;

    if (!router.location.query.q) return null;

    return (
      <p>
        Searching{' '}
        <span
          style={{
            fontFamily: 'monospace',
            textTransform: 'uppercase',
            whitespace: 'pre',
          }}
        >
          {router.location.query.q}
        </span>{' '}
        found {technologies.data.length} technologies.
      </p>
    );
  };

  render() {
    const { router, technologies } = this.props;

    if (technologies.loading) {
      return <p>Loading...</p>;
    }

    return (
      <div style={{ marginTop: '1em' }}>
        {this.renderQueryInfo()}
        <TechnologiesList technologies={technologies.data} router={router} />
        {technologies.links.next && <TechnologiesLoadMore />}
      </div>
    );
  }
}

BrowseTechnologies.propTypes = {
  requestTechnologies: PropTypes.func.isRequired,
  requestTechnologiesPagination: PropTypes.func.isRequired,
  router: PropTypes.shape({}).isRequired,
  suggestions: PropTypes.shape({}).isRequired,
  technologies: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => ({
  suggestions: state.suggestions,
  technologies: state.technologies,
});

const mapDispatchToProps = dispatch => ({
  requestTechnologies: technologyId =>
    dispatch({
      type: 'REQUEST_TECHNOLOGIES',
      index: true,
      technology_id: technologyId,
    }),
  requestTechnologiesPagination: () => dispatch({ type: 'REQUEST_TECHNOLOGIES_PAGINATION' }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BrowseTechnologies);
