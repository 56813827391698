import React from 'react';
import PropTypes from 'prop-types';

import { Box, Flex } from '../../../mc-ui';
import BrandIcon from '../../../mc-ui/icons/BrandIcon';
import EmailIcon from '../../../mc-ui/icons/EmailIcon';
import PromoIcon from '../../../mc-ui/icons/PromoIcon';

import Text from './Text';
import Title from './Title';

const fixedValue = value => {
  let number = null;
  if (!value || value === 'NaN' || Number.isNaN(value)) {
    number = 0;
  } else {
    number = Number(value);
  }

  return number.toFixed(2);
};

const Stats = ({ overview }) => (
  <Flex alignItems="center" marginBottom="16px" paddingLeft="30px">
    <Box width="206px">
      <Title>Total Emails</Title>
      <Text>{overview.totalEmails}</Text>
    </Box>

    <Box width="243px">
      <Title>Avg. Emails / Week</Title>

      <Flex alignItems="center" color="dark1">
        <EmailIcon fillOpacity={1} height="20px" width="20px" />
        <Text css={{ marginLeft: '4px' }}>{fixedValue(overview.avgEmailsWeek)}</Text>
      </Flex>
    </Box>

    <Box width="230px">
      <Title>Avg. Promo Rate</Title>

      <Flex alignItems="center" color="dark1">
        <PromoIcon fillOpacity={1} height="20px" width="20px" />
        <Text css={{ marginLeft: '4px' }}>{fixedValue(overview.avgPromoRate)}%</Text>
      </Flex>
    </Box>

    <Box width="113px">
      <Title>Brands</Title>

      <Flex alignItems="center" color="dark1">
        <BrandIcon fillOpacity={1} height="20px" width="20px" />
        <Text css={{ marginLeft: '4px' }}>{overview.totalBrands}</Text>
      </Flex>
    </Box>
  </Flex>
);

Stats.propTypes = {
  overview: PropTypes.shape({
    avgEmailsWeek: PropTypes.number,
    avgPromoRate: PropTypes.number,
    totalBrands: PropTypes.number,
    totalEmails: PropTypes.number,
  }).isRequired,
};

export default Stats;
