import React from 'react';
import { components } from 'react-select';

import CloseIcon from '../../icons/OldCloseIcon';

import colors from '../../theme/colors';

export const multiValueRemoveStyles = base => ({
  ...base,
  cursor: 'pointer',
  margin: 0,
  padding: '0 4px',
  '&:hover': {
    backgroundColor: 'transparent',
    color: colors.redDark,
  },
});

const MultiValueRemove = props => (
  <components.MultiValueRemove {...props}>
    <CloseIcon width="8px" height="8px" />
  </components.MultiValueRemove>
);

export default MultiValueRemove;
