/* eslint-disable prefer-destructuring */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'ramda';
import { withTheme } from '@emotion/react';

import { calendars } from '../../../modules/newReports';

import { Box, Flex, Loader, Heading, Text, Tooltip, } from '../../mc-ui';
import QuestionMarkIcon from '../../mc-ui/icons/QuestionMarkIcon';

import Stats from '../Stats';

import Calendar from './Calendar';
import Legend from './Legend';

const Container = ({ calendarData, loading, model, selectedDate, theme }) => {
  if (loading || isEmpty(calendarData)) return <Loader />;

  const comparisonLabel = ` vs ${selectedDate.clone().subtract(1, 'year').format('MMM, YYYY').toUpperCase()}`
  return (
    <div>
      <Flex justifyContent="space-between">
        <Tooltip
          dark
          id={'calendar-heading'}
          element={
            <Heading as="h4">
              {selectedDate.format('MMMM YYYY')}{' '}
              <QuestionMarkIcon fillOpacity={1} />
            </Heading>
          }
        >
          <Text>  Track send volume and promotional activity by day, week, and month.</Text>
        </Tooltip>

        <Text>
          To understand NRF week comparison.{' '}
          <a
            href="https://nrf.com/resources/4-5-4-calendar"
            target="_blank"
            css={{
              border: 'none',
              color: theme.colors.blue,
              fontWeight: 500,
              textDecoration: 'none',
            }}
          >
            Learn more
          </a>
        </Text>
      </Flex>

      <Box css={{ marginTop: '22px', width: '100%' }}>
        <Stats>
          <Stats.Item
            title="Total Emails"
            value={String(calendarData.stats.total_emails.value)}
            comparison={Math.round(calendarData.stats.total_emails.delta * 100)}
            comparisonLabel={comparisonLabel}
          />
          <Stats.Item
            title="AVG Promo Rate"
            value={`${Math.round(calendarData.stats.promotional.avg * 100)}%`}
            comparison={Math.round(calendarData.stats.promotional.delta * 100)}
            comparisonLabel={comparisonLabel}
          />
          <Stats.Item
            title="AVG Emails/Brand"
            value={calendarData.stats.frequency.value.toFixed(1)}
            comparison={Math.round(calendarData.stats.frequency.delta * 100)}
            comparisonLabel={comparisonLabel}
          />
        </Stats>
      </Box>

      <Legend model={model} />

      <Calendar data={calendarData} model={model} selectedDate={selectedDate} />
    </div>
  );
};

Container.propTypes = {
  calendarData: PropTypes.shape({}).isRequired,
  loading: PropTypes.bool.isRequired,
  model: PropTypes.shape({ id: PropTypes.string, type: PropTypes.string }).isRequired,
  selectedDate: PropTypes.shape({}).isRequired,
  theme: PropTypes.shape().isRequired,
};

const mapStateToProps = (state, { model, selectedDate }) => {
  let calendarData = {};
  let loading = true;

  if (model.id) {
    const startDate = selectedDate.format('YYYY-MM-DD');
    if (model.type === 'groups') {
      calendarData = calendars.calendarsSelectors.getGroupData(model.id, startDate)(state);
      loading = calendars.calendarsSelectors.getGroupLoading(model.id, startDate)(state);
    } else {
      calendarData = calendars.calendarsSelectors.getBrandData(model.id, startDate)(state);
      loading = calendars.calendarsSelectors.getBrandLoading(model.id, startDate)(state);
    }
  }

  return {
    calendarData,
    loading,
  };
};

export default connect(mapStateToProps)(withTheme(Container));
