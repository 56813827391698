import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

import JourneyExampleContainer from './ui/JourneyExampleContainer';
import JourneyExampleHeader from './ui/JourneyExampleHeader';

const JourneysExamples = ({ journeys }, { router }) => {
  const [journey1, journey2, journey3] = journeys;
  const [company1] = journey1.relationships.company.data;
  const [company2] = journey2.relationships.company.data;
  const [company3] = journey3.relationships.company.data;

  const restrictedUrl = router.location.state
    ? router.location.state.restrictedUrl
    : `${router.location.pathname}${router.location.search}`;

  const demo = (/demo/.test(router.location.pathname))
  const selectedJourneyId = router.location.query.segment_id 
  const set_active = demo && selectedJourneyId

  return (
    <div className="pure-g gutters">
      <div className="pure-u-1 pure-u-md-1-3">
        <Link
          to={{
            pathname: '/demo/journeys',
            query: {
              segment_id: journey1.id,
            },
            state: { restrictedUrl },
          }}
          style={{ textDecoration: 'none' }}
        >
          <JourneyExampleContainer
            active={set_active ? selectedJourneyId === journey1.id : true}
          >
            <JourneyExampleHeader
              journeyType={journey1.attributes.journey_type}
              emails={journey1.attributes.related_emails}
            />
            <h4>{company1.attributes.name} welcome journey</h4>
            <small>{journey1.attributes.journey_emails} emails</small>
            <p>
              Welcome drips are critical to how new subscribers are introduced
              to brands.
              <br />
              <br />
              <button className="pure-button">View example</button>
            </p>
          </JourneyExampleContainer>
        </Link>
      </div>

      <div className="pure-u-1 pure-u-md-1-3">
        <Link
          to={{
            pathname: '/demo/journeys',
            query: {
              segment_id: journey2.id,
            },
            state: { restrictedUrl },
          }}
          style={{ textDecoration: 'none' }}
        >
          <JourneyExampleContainer
            active={set_active ? selectedJourneyId === journey2.id : true}
          >
            <JourneyExampleHeader
              journeyType={journey2.attributes.journey_type}
              emails={journey2.attributes.related_emails}
            />
            <h4>{company2.attributes.name} cart abandoner</h4>
            <small>{journey2.attributes.journey_emails} emails</small>
            <p>
              Cart abandon emails are essential to converting visitors into
              customers.
              <br />
              <br />
              <button className="pure-button">View example</button>
            </p>
          </JourneyExampleContainer>
        </Link>
      </div>

      <div className="pure-u-1 pure-u-md-1-3">
        <Link
          to={{
            pathname: '/demo/journeys',
            query: {
              segment_id: journey3.id,
            },
            state: { restrictedUrl },
          }}
          style={{ textDecoration: 'none' }}
        >
          <JourneyExampleContainer
            active={set_active ? selectedJourneyId === journey3.id : true}
          >
            <JourneyExampleHeader
              journeyType={journey3.attributes.journey_type}
              emails={journey3.attributes.related_emails}
            />
            <h4>{company3.attributes.name} post-purchase campaign</h4>
            <small>{journey3.attributes.journey_emails} emails</small>
            <p>
              Once a subscriber has converted into a customer, it’s time to
              build loyalty.
              <br />
              <br />
              <button className="pure-button">View example</button>
            </p>
          </JourneyExampleContainer>
        </Link>
      </div>
    </div>
  );
};

JourneysExamples.contextTypes = {
  router: PropTypes.object,
};

JourneysExamples.propTypes = {
  journeys: PropTypes.arrayOf(PropTypes.shape({
    attributes: PropTypes.object,
    id: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    relationships: PropTypes.object,
  })).isRequired,
};

export default JourneysExamples;
