import * as types from './types';

export const createRequest = (values, resolve) => ({
  type: types.CREATE_REQUEST,
  values,
  resolve,
});

export const createSuccess = data => ({
  type: types.CREATE_SUCCESS,
  data,
});

export const createFailure = error => ({
  type: types.CREATE_FAILURE,
  error,
});

export const deleteRequest = (list, listType, resolve) => ({
  type: types.DELETE_REQUEST,
  list,
  listType,
  resolve,
});

export const deleteSuccess = (list, listType) => ({
  type: types.DELETE_SUCCESS,
  list,
  listType,
});

export const deleteFailure = error => ({
  type: types.DELETE_FAILURE,
  error,
});

export const closeCreateForm = () => ({
  type: types.CLOSE_CREATE_FORM,
});

export const openCreateForm = () => ({
  type: types.OPEN_CREATE_FORM,
});

export const closeEditForm = () => ({
  type: types.CLOSE_EDIT_FORM,
});

export const openEditForm = list => ({
  type: types.OPEN_EDIT_FORM,
  list,
});

export const updateRequest = (id, values, resolve) => ({
  type: types.UPDATE_REQUEST,
  id,
  values,
  resolve,
});

export const updateSuccess = data => ({
  type: types.UPDATE_SUCCESS,
  data,
});

export const updateFailure = error => ({
  type: types.UPDATE_FAILURE,
  error,
});

export const fetchUserListsRequest = (params = {}) => ({
  type: types.FETCH_USER_LISTS_REQUEST,
  params,
});

export const fetchUserListsSuccess = data => ({
  type: types.FETCH_USER_LISTS_SUCCESS,
  data,
});

export const fetchUserListsFailure = error => ({
  type: types.FETCH_USER_LISTS_FAILURE,
  error,
});

export const fetchTeamListsRequest = (params = {}) => ({
  type: types.FETCH_TEAM_LISTS_REQUEST,
  params,
});

export const fetchTeamListsSuccess = data => ({
  type: types.FETCH_TEAM_LISTS_SUCCESS,
  data,
});

export const fetchTeamListsFailure = error => ({
  type: types.FETCH_TEAM_LISTS_FAILURE,
  error,
});

export const shareWithTeamRequest = listId => ({
  type: types.SHARE_WITH_TEAM_REQUEST,
  listId,
});

export const shareWithTeamSuccess = data => ({
  type: types.SHARE_WITH_TEAM_SUCCESS,
  data,
});

export const shareWithTeamFailure = error => ({
  type: types.SHARE_WITH_TEAM_FAILURE,
  error,
});

export const createTeamListRequest = (values, resolve) => ({
  type: types.CREATE_TEAM_LIST_REQUEST,
  values,
  resolve,
});

export const createTeamListSuccess = data => ({
  type: types.CREATE_TEAM_LIST_SUCCESS,
  data,
});

export const createTeamListFailure = error => ({
  type: types.CREATE_TEAM_LIST_FAILURE,
  error,
});

export const deleteTeamListRequest = (listId, listType, resolve) => ({
  type: types.DELETE_TEAM_LIST_REQUEST,
  listId,
  listType,
  resolve,
});

export const deleteTeamListSuccess = (data, listType) => ({
  type: types.DELETE_TEAM_LIST_SUCCESS,
  data,
  listType,
});

export const deleteTeamListFailure = error => ({
  type: types.DELETE_TEAM_LIST_FAILURE,
  error,
});

export const updateTeamListRequest = (id, values, resolve) => ({
  type: types.UPDATE_TEAM_LIST_REQUEST,
  id,
  values,
  resolve,
});

export const updateTeamListSuccess = data => ({
  type: types.UPDATE_TEAM_LIST_SUCCESS,
  data,
});

export const updateTeamListFailure = error => ({
  type: types.UPDATE_TEAM_LIST_FAILURE,
  error,
});

export const addItemsToListRequest = (listId, items, resolve, reject) => ({
  type: types.ADD_ITEMS_TO_LIST_REQUEST,
  listId,
  items,
  resolve,
  reject,
});

export const addItemsToListSuccess = (listId, items) => ({
  type: types.ADD_ITEMS_TO_LIST_SUCCESS,
  listId,
  items,
});

export const addItemsToListFailure = error => ({
  type: types.ADD_ITEMS_TO_LIST_FAILURE,
  error,
});

export const removeItemsFromListRequest = (listId, items, resolve, reject, options = {}) => ({
  type: types.REMOVE_ITEMS_FROM_LIST_REQUEST,
  listId,
  items,
  resolve,
  reject,
  options,
});

export const removeItemsFromListSuccess = (listId, items) => ({
  type: types.REMOVE_ITEMS_FROM_LIST_SUCCESS,
  listId,
  items,
});

export const removeItemsFromListFailure = error => ({
  type: types.REMOVE_ITEMS_FROM_LIST_FAILURE,
  error,
});

export const createListAndAddItemsRequest = (items, values, resolve) => ({
  type: types.CREATE_LIST_AND_ADD_ITEMS_REQUEST,
  items,
  values,
  resolve,
});

export const createListAndAddItemsSuccess = () => ({
  type: types.CREATE_LIST_AND_ADD_ITEMS_SUCCESS,
});

export const createListAndAddItemsFailure = error => ({
  type: types.CREATE_LIST_AND_ADD_ITEMS_FAILURE,
  error,
});
