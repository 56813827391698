import * as React from 'react';

function CheckboxExcludeIcon(props) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect
        x={4}
        y={4}
        width={16}
        height={16}
        rx={3}
        fill="#E2F2FF"
        stroke="#0072D3"
        strokeWidth={2}
      />
      <path stroke="#0072D3" strokeWidth={2} d="M7 12h10" />
    </svg>
  );
}

export default CheckboxExcludeIcon;
