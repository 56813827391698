import React from 'react';
import PropTypes from 'prop-types';
import styled from "@emotion/styled";

import WordpressIcon from '../../../mc-ui/icons/WordpressIcon.js';
import GiftboxIcon from '../../../mc-ui/icons/GiftboxIcon';
import TwitterIcon from '../../../mc-ui/icons/TwitterIcon';

const iconComponent = type => {
  if (type === 'blog') {
    return WordpressIcon;
  }

  if (type === 'twitter') {
    return TwitterIcon;
  }

  return GiftboxIcon;
};

const IconWrapper = styled("div")(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.colors.dark6,
  borderRadius: '50%',
  boxSizing: 'border-box',
  display: 'flex',
  height: '32px',
  justifyContent: 'center',
  width: '32px',
}));

const Icon = ({ type }) => {
  const IconComponent = iconComponent(type);

  return (
    <IconWrapper>
      <IconComponent type={type} />
    </IconWrapper>
  );
};

Icon.propTypes = {
  type: PropTypes.string.isRequired,
};

export default Icon;
