import React from 'react';
import PropTypes from 'prop-types';
import bytes from 'bytes';
import moment from 'moment';
import { Line } from 'react-chartjs-2';
import { defaultTo } from 'ramda';

const DEFAULT_OPTS = {
  afterDataLimits: (instance) => {
    // eslint-disable-next-line no-param-reassign
    instance.max += 5;
  },
};

const PERCENTAGE_OPTS = {
  afterBuildTicks: (instance) => {
    // eslint-disable-next-line no-param-reassign
    instance.ticks = instance.ticks.filter(t => t <= 100);
  },
  ticks: {
    min: 0,
    max: 110,
    stepSize: 25,
    callback(value) {
      return `${value}%`;
    },
  },
};

const defaultToZero = num => defaultTo(0, Math.round(num));

class EmailScore extends React.Component {
  render() {
    const datasets = [].concat(this.props.buckets).map((buckets, idx) => {
      const average = this.props.averages[idx];
      const name = this.props.names[idx] || 'Email score';
      const label = `${average} - ${name}`;

      const isComparison = /Comparison/.test(label);
      const color = isComparison ? '#646464' : '#0383E7';
      const bg = isComparison ? 'rgba(36, 36, 36, .05)' : 'rgba(0, 134, 241, .25)';

      return {
        type: 'line',
        label,
        fill: true,
        lineTension: 0.1,
        backgroundColor: bg,
        borderColor: color,
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: color,
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: color,
        pointHoverBorderColor: color,
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: buckets.map((bucket) => {
          const { attribute, calculation } = this.props;

          if (calculation === 'avg') {
            return defaultToZero(bucket[attribute].avg);
          } else if (calculation === 'bytes') {
            return bytes(defaultToZero(bucket[attribute].avg), { unit: 'kb' }).replace(/kb/i, '');
          } else if (calculation === 'smpt_headers') {
            if (
              bucket.spf_result.buckets.some(b => b.key === 'fail') ||
              bucket.dkim_result.buckets.some(b => b.key === 'fail')
            ) {
              return 0;
            } else if (
              bucket.spf_result.buckets.every(b => b.key === 'pass') &&
              bucket.dkim_result.buckets.some(b => b.key === 'pass')
            ) {
              return 100;
            }
            return 50;
          } else if (calculation === 'spam') {
            const no = bucket[attribute].buckets
              .filter(b => b.key === 'no')
              .reduce((m, b) => b.doc_count, 0);
            const yes = bucket[attribute].buckets
              .filter(b => b.key === 'yes')
              .reduce((m, b) => b.doc_count, 0);
            const sum = yes + no;
            const avg = sum === 0 ? 0 : yes / sum;
            return defaultToZero(avg);
          }

          return defaultToZero(bucket[attribute].avg * 100);
        }),
      };
    });
    const data = (canvas) => {
      const ctx = canvas.getContext('2d');
      const gradient = ctx.createLinearGradient(0, 0, 0, this.props.height);
      gradient.addColorStop(1, 'rgba(50, 211, 194, .1)');
      gradient.addColorStop(0, 'rgba(50, 211, 194, .8)');

      // Find the bucket that has more data to build this chart labels
      const buckets = [...this.props.buckets];
      buckets.sort((a, b) => b.length > a.length);
      const bucket = buckets[0];

      return {
        labels: bucket.map(b => moment(b.key).format('MMM DD')),
        datasets,
      };
    };
    const options = {
      title: { display: true, text: this.props.title },
      scales: {
        yAxes: [
          ['avg', 'bytes'].indexOf(this.props.calculation) > -1 ? DEFAULT_OPTS : PERCENTAGE_OPTS,
        ],
      },
      legend: {
        labels: {
          usePointStyle: true,
          align: 'left',
        },
        position: 'top',
      },
    };
    return <Line height={this.props.height || 240} options={options} data={data} />;
  }
}

EmailScore.propTypes = {
  averages: PropTypes.arrayOf(PropTypes.any).isRequired,
  buckets: PropTypes.arrayOf(PropTypes.any).isRequired,
  names: PropTypes.arrayOf(PropTypes.string).isRequired,
  attribute: PropTypes.string,
  calculation: PropTypes.string,
  height: PropTypes.number,
  title: PropTypes.string,
};

EmailScore.defaultProps = {
  attribute: 'mailcharts_score',
  calculation: null,
  height: 240,
  title: 'Email score',
};

export default EmailScore;
