import React from 'react';
import PropTypes from 'prop-types';
import humanize from 'humanize-number';
import moment from 'moment';
import pluralize from 'pluralize';
import { Link } from 'react-router';

import { Box, Button, Flex, Heading, Text } from '../../../mc-ui';

export default function ExploreHistoricalData({ email }) {
  const [brand] = (email.relationships.companies || { data: [] }).data;

  if (!brand) return null;

  return (
    <Box alignItems="center" marginBottom="32px">
      <Heading
        as="h5"
        color="dark08"
        fontWeight="bold"
        lineHeight="1.56em"
        css={{ fontSize: '10px', letterSpacing: '0.12em', marginBottom: '8px' }}
      >
        {brand.attributes.name}
        ’s historical data
      </Heading>

      <Flex alignItems="center" justifyContent="space-between"
        style={{
          '@media screen and (max-width: 1280px)': {
            flexDirection: "column",
          }
        }}
      >
        <Text color="dark08">
          Total {brand.attributes.name} Emails Received:
          <br />
          <strong>
            {humanize(brand.attributes.approximate_emails)}{' '}
            {pluralize('email', brand.attributes.approximate_emails)}
          </strong>{' '}
          since <strong>{moment(brand.attributes.first_email_sent_at).format('YYYY')}</strong>
        </Text>

        <Button
          as={Link}
          to={`/companies/${brand.id}/emails`}
          variant="secondary"
          css={{
            height: '32px',
            lineHeight: '32px',
            margin: '0',
            padding: '0',
            width: '123px',
          }}
        >
          Browse emails
        </Button>
      </Flex>
    </Box>
  );
}

ExploreHistoricalData.propTypes = {
  email: PropTypes.shape({
    relationships: PropTypes.shape({
      companies: PropTypes.shape({
        data: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            attributes: PropTypes.shape({
              approximate_emails: PropTypes.number,
              first_email_sent_at: PropTypes.string,
              name: PropTypes.string,
            }),
          }),
        ),
      }),
    }),
  }).isRequired,
};
