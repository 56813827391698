import React from 'react';
import styled from "@emotion/styled";

import FindGlassIcon from '../../../../mc-ui/icons/FindGlassIcon';

const Div = styled("div")(({ theme }) => ({
  alignItems: 'center',
  color: theme.colors.dark1,
  display: 'flex',
  fontSize: '24px',
  justifyContent: 'center',
}));

const DropdownIndicator = () => (
  <Div>
    <FindGlassIcon />
  </Div>
);

export default DropdownIndicator;
