import styled from "@emotion/styled";

const NotificationSign = styled("div")(({ theme, type, }) => ({
  backgroundColor: type == 'pro' ? theme.colors.purpleMedium : theme.colors.orange,
  border: `1px solid ${theme.colors.white}`,
  borderRadius: '50%',
  display: 'inline-block',
  height: '8px',
  width: '8px',
}));

export default NotificationSign;
