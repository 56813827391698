import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@emotion/react';

import Box from './Box';

const ScrollableBox = ({ children, css, height, scrollBoxHeight, style, theme, ...props }) => {
  const customCss = {
    ...css,
    ...style,
    ...theme.scrollBox({ height: scrollBoxHeight || height }),
  };

  return (
    <Box {...props} height={height} css={customCss} position="relative">
      <div>{children}</div>
    </Box>
  );
};

ScrollableBox.defaultProps = {
  css: {},
  scrollBoxHeight: null,
  style: {},
};

ScrollableBox.propTypes = {
  children: PropTypes.node.isRequired,
  css: PropTypes.shape({}),
  height: PropTypes.string.isRequired,
  scrollBoxHeight: PropTypes.string,
  style: PropTypes.shape({}),
  theme: PropTypes.shape({}).isRequired,
};

export default withTheme(ScrollableBox);
