import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { teamsSelectors } from '../../modules/teams';

import { Badge } from '../mc-ui';

function AccountStatusBadge({ css, planExpired, small, team, isSubscriptionInvoiced, isSubscriptionAutomaticallyCharged, isSubscriptionExempted }) {
  let backgroundColor = 'blue';
  let border = null;
  let borderColor = null;
  let color = 'white';
  let label = 'Free';

  const { account_status: accountStatus, status } = team ? team.attributes : {};

  if (!team || accountStatus === 'free') {
    backgroundColor = 'blue';
    label = 'Free';
  } else if (status === 'archived') {
    backgroundColor = 'dark5';
    color = 'dark1';
    label = 'Archived';
  } else if (status === 'pending') {
    backgroundColor = 'blueLight';
    color = 'dark08';
    border = '1px solid';
    borderColor = 'blueMedium';
    label = 'Pending';
  } else if (accountStatus === 'canceled') {
    backgroundColor = 'dark5';
    color = 'dark1';
    label = 'Canceled';
  } else if (accountStatus === 'past_due') {
    backgroundColor = 'dark5';
    color = 'dark1';
    label = 'Past due';
  } else if (planExpired) {
    if (accountStatus === 'trialing') {
      backgroundColor = 'orange';
      color = 'white';
      label = 'Trial Expired';
    } else if (status === 'active') {
      backgroundColor = 'dark5';
      color = 'dark1';
      label = 'Pro Expired';
    }
  } else if (accountStatus === 'trialing') {
    backgroundColor = 'orange';
    color = 'white';
    label = 'Trial';
  } else if (status === 'active') {
    backgroundColor = 'purpleMedium';
    if (isSubscriptionAutomaticallyCharged) {
      label = 'Pro';
    } else if (isSubscriptionInvoiced || isSubscriptionExempted) {
      label = 'Enterprise';
    }
  }

  return (
    <Badge
      backgroundColor={backgroundColor}
      border={border}
      borderColor={borderColor}
      color={color}
      fontSize={small ? '10px' : '12px'}
      lineHeight="1em"
      padding={small ? '3px 4px' : '5px 7px'}
      css={css}
    >
      {label}
    </Badge>
  );
}

AccountStatusBadge.defaultProps = {
  css: {},
  small: false,
  team: null,
};

AccountStatusBadge.propTypes = {
  css: PropTypes.shape({}),
  planExpired: PropTypes.bool.isRequired,
  small: PropTypes.bool,
  team: PropTypes.shape({
    attributes: PropTypes.shape({
      account_status: PropTypes.string,
      status: PropTypes.string,
    }),
  }),
  isSubscriptionInvoiced: PropTypes.bool.isRequired,
  isSubscriptionAutomaticallyCharged: PropTypes.bool.isRequired,
  isSubscriptionExemptedSelector: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  planExpired: teamsSelectors.planExpiredSelector(state),
  team: teamsSelectors.teamSelector(state),
  isSubscriptionInvoiced: teamsSelectors.isSubscriptionInvoicedSelector(state),
  isSubscriptionAutomaticallyCharged: teamsSelectors.isSubscriptionAutomaticallyChargedSelector(state),
  isSubscriptionExempted: teamsSelectors.isSubscriptionExemptedSelector(state),
});

export default connect(mapStateToProps)(AccountStatusBadge);
