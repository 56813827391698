import * as React from 'react';
import PropTypes from 'prop-types';

function CalendarStartIcon({ fillOpacity, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 3a1 1 0 012 0h6a1 1 0 112 0h.333A4.659 4.659 0 0122 7.667v9a4.659 4.659 0 01-4.667 4.666H6.667C4.083 21.333 2 19.25 2 16.75V7.667A4.659 4.659 0 016.667 3H7zm8 1.917V5a1 1 0 102 0v-.083h.333c1.5 0 2.75 1.25 2.75 2.75H3.917c0-1.584 1.25-2.75 2.75-2.75H7V5a1 1 0 002 0v-.083h6zm2.333 14.5H6.667c-1.5 0-2.75-1.25-2.75-2.75V9.5h16.25v7.167c-.084 1.583-1.334 2.75-2.834 2.75z"
        fill="currentColor"
        fillOpacity={fillOpacity}
      />
      <path d="M15.4 13.9L9.6 11v5.8l5.8-2.9z" fill="currentColor" fillOpacity={fillOpacity} />
    </svg>
  );
}

CalendarStartIcon.propTypes = {
  fillOpacity: PropTypes.number,
};

CalendarStartIcon.defaultProps = {
  fillOpacity: 1,
};

export default CalendarStartIcon;
