import React from 'react'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import EmailThumbnail from './EmailThumbnail'

class ReportsPlaceholder extends React.Component {
  render() {
    return (
      <div className='pure-u-1'>
        <div className='pure-u-1'>
          <h2>Overview email program</h2>
        </div>
        <div className='pure-u-1-4'>
          <h3>Company overview</h3>
          <p>Lorem ipsum dolor sit amet.</p>
          <button className='pure-button'>View report</button>
        </div>
        <div className='pure-u-1-4'>
          <h3>Calendar overlay</h3>
          <p>Lorem ipsum dolor sit amet.</p>
          <button className='pure-button'>View report</button>
        </div>
        <div className='pure-u-1-4'>
          <h3>Discounts and promotions</h3>
          <p>Lorem ipsum dolor sit amet.</p>
          <button className='pure-button pure-button-disabled'>Upgrade</button>
        </div>
        <div className='pure-u-1-4'>
          <h3>MailCharts score</h3>
          <p>Lorem ipsum dolor sit amet.</p>
          <button className='pure-button pure-button-disabled'>Upgrade</button>
        </div>
        <div className='pure-u-1'>
          <hr />
          <h2>Segmentation strategies</h2>
        </div>
        <div className='pure-u-1-4'>
          <h3>User journeys</h3>
          <p>Lorem ipsum dolor sit amet.</p>
          <button className='pure-button pure-button-disabled'>Upgrade</button>
        </div>
        <div className='pure-u-1-4'>
          <h3>Side-by-side comparisons</h3>
          <p>Lorem ipsum dolor sit amet.</p>
          <button className='pure-button pure-button-disabled'>Upgrade</button>
        </div>
        <div className='pure-u-1'>
          <hr />
          <h2>Analysis</h2>
        </div>
        <div className='pure-u-1-4'>
          <h3>Benchmarks</h3>
          <p>Compare to other groups and industries.</p>
          <button className='pure-button pure-button-disabled'>Upgrade</button>
        </div>
        <div className='pure-u-1-4'>
          <h3>CSV exports</h3>
          <p>Wrangle data to suit your needs best.</p>
          <button className='pure-button pure-button-disabled'>Upgrade</button>
        </div>
      </div>
    )
  }
}

const s = (state) => ({ searches: state.searches })
export default connect(s)(ReportsPlaceholder)
