import { isNil } from 'ramda';
import { createSelector } from 'reselect';

const getState = state => state.newReports.subjectLines || {};

const getModel = (modelId, modelType) => {
  const type = modelType === 'companies' ? 'brands' : modelType;
  return createSelector(getState, state => (!type ? {} : state[type][modelId] || {}));
};

export const getModelByDate = (modelId, modelType, startDate, endDate) =>
  createSelector(getModel(modelId, modelType), model => model[`${startDate}-${endDate}`] || {});

export const getData = (modelId, modelType, startDate, endDate) =>
  createSelector(getModelByDate(modelId, modelType, startDate, endDate), model => model.data || {});

export const getLoading = (modelId, modelType, startDate, endDate) =>
  createSelector(
    getModelByDate(modelId, modelType, startDate, endDate),
    model => (isNil(model.loading) ? true : model.loading),
  );
export const getError = (modelId, modelType, startDate, endDate) =>
  createSelector(
    getModelByDate(modelId, modelType, startDate, endDate),
    model => model.error || null,
  );
