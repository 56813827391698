import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { baseUrl } from '../../lib/api';
import { customListsActions } from '../../modules/customLists';
import {
  textMessagesActions,
  textMessagesHelpers,
  textMessagesSelectors,
} from '../../modules/textMessages';
import AddToListButton from '../AddToListButton';
import * as DownloadStyles from '../DownloadEmailsDropdown/styles';
import { Flex, theme } from '../mc-ui';
import ItemsSelector from './ItemsSelector';
import * as S from './styles';

class BottomTray extends Component {
  toggleSelectAll = () => {
    const { deselectAll, collection, selectAll } = this.props;
    const totalSelected = collection.filter(e => e.selected).length;

    if (totalSelected === 0) {
      selectAll();
    } else {
      deselectAll();
    }
  };

  handleExport = event => {
    event.preventDefault();
    const { collection, contentType } = this.props;
    const format = event.target.id || event.currentTarget.dataset.format;
    const type = 'list';
    const ids = collection
      .filter(i => i.selected)
      .map(i => (contentType === 'text-messages' ? `text_message_id=${i.id}` : `email_id=${i.id}`))
      .join('&');

    window.analytics.track('Export clicked', { format, type });

    const apiURL = baseUrl();

    const url = `${apiURL}/v2/searches?${ids}&format=${format}`;
    window.open(url, '_blank');
  };

  handleRemove = () => {
    const { close, collection, contentType, deleteFromListRequest } = this.props;
    const values =
      contentType === 'emails'
        ? collection.filter(i => i.selected).map(i => i.id)
        : collection.filter(i => i.selected).map(i => ({ id: i.id, type: i.type }));

    new Promise((resolve, reject) => {
      deleteFromListRequest(values, resolve, reject);
    }).then(close);
  };

  render() {
    const { router } = this.context;
    const { collection, contentType, enabled, model, removeEnabled } = this.props;

    const hasLeftSidebar =
      model || !/\/lists\/[\d]+|\/text-messages\/?$/i.test(router.location.pathname);

    if (!enabled) {
      return null;
    }

    const totalItems = collection.length;
    const selectedItems = collection.filter(e => e.selected);
    const totalSelected = selectedItems.length;

    return (
      <Flex
        alignItems="center"
        backgroundColor="white"
        borderRadius="4px"
        boxShadow={`0px 1px 4px ${theme.colors.dark02}`}
        bottom="0"
        justifyContent="space-between"
        padding="16px"
        width={hasLeftSidebar ? '834px' : '1060px'}
        position="fixed"
        zIndex="1001"
        css={{
          animation: `${S.riseFromBottom} .5s`,
        }}
      >
        <ItemsSelector
          onToggle={this.toggleSelectAll}
          total={totalItems}
          totalSelected={totalSelected}
        />

        <Flex width="unset">
          {removeEnabled &&
            totalSelected > 0 && (
              <S.RemoveButton onClick={this.handleRemove}>Remove</S.RemoveButton>
            )}

          {/* TODO: Enable this when we have a way to add text messages to lists */}
          <AddToListButton
            disabled={totalSelected === 0}
            emails={contentType === 'emails' ? selectedItems : []}
            textMessages={contentType === 'text-messages' ? selectedItems : []}
            css={{ marginRight: '8px', '& > button': { boxShadow: 'none !important' } }}
            listType={contentType}
            asModal
          />

          {/* TODO: Show this button when we have a way to download text messages */}
          {contentType === 'emails' && (
            <DownloadStyles.Dropdown disabled={totalSelected === 0}>
              <DownloadStyles.DropdownButton disabled={totalSelected === 0} variant="secondary" />

              <DownloadStyles.DropdownMenu up>
                <DownloadStyles.DropdownMenuItem onClick={this.handleExport} data-format="html">
                  HTML
                </DownloadStyles.DropdownMenuItem>

                <DownloadStyles.DropdownMenuItem onClick={this.handleExport} data-format="images">
                  Images
                </DownloadStyles.DropdownMenuItem>

                <DownloadStyles.DropdownMenuItem onClick={this.handleExport} data-format="csv">
                  CSV
                </DownloadStyles.DropdownMenuItem>
              </DownloadStyles.DropdownMenu>
            </DownloadStyles.Dropdown>
          )}
        </Flex>
      </Flex>
    );
  }
}

BottomTray.contextTypes = {
  router: PropTypes.object,
};

BottomTray.defaultProps = {
  contentType: 'emails',
  enabled: false,
  model: null,
  removeEnabled: false,
  close: () => {},
};

BottomTray.propTypes = {
  close: PropTypes.func,
  contentType: PropTypes.string,
  deleteFromListRequest: PropTypes.func.isRequired,
  deselectAll: PropTypes.func.isRequired,
  collection: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      selected: PropTypes.bool,
    }),
  ).isRequired,
  enabled: PropTypes.bool,
  model: PropTypes.shape({}),
  removeEnabled: PropTypes.bool,
  selectAll: PropTypes.func.isRequired,
};

const mapStateToProps = (state, { contentType = 'emails', router }) => {
  if (contentType === 'text-messages') {
    const { pathname } = router.location;
    const query = textMessagesHelpers.filterQuery(router.location.query, router.params);

    return {
      collection: textMessagesSelectors.dataSelector({ pathname, query })(state).data,
    };
  }

  return {
    collection: state.emails.data,
  };
};

const mapDispatchToProps = (dispatch, { contentType = 'emails', listId, router }) => {
  if (contentType === 'text-messages') {
    const { pathname } = router.location;
    const query = textMessagesHelpers.filterQuery(router.location.query, router.params);

    return {
      deleteFromListRequest: (items, resolve, reject) =>
        dispatch(
          customListsActions.removeItemsFromListRequest(listId, items, resolve, reject, {
            bulk: true,
          }),
        ),
      deselectAll: () => dispatch(textMessagesActions.deselectAllMessages(pathname, query)),
      selectAll: () => dispatch(textMessagesActions.selectAllMessages(pathname, query)),
    };
  }

  return {
    deleteFromListRequest: (emailId, resolve, reject) =>
      dispatch({
        type: 'DELETE_EMAILS_FROM_LIST_REQUEST',
        bulk: true,
        listId,
        emailId,
        resolve,
        reject,
      }),
    deselectAll: () => dispatch({ type: 'DESELECT_ALL_EMAILS' }),
    selectAll: () => dispatch({ type: 'SELECT_ALL_EMAILS' }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BottomTray);
