import React from 'react';

import { Flex } from '../../mc-ui';

import Body from '../Body';
import Heading from '../Heading';

import CampaignsSentByBrands from './CampaignsSentByBrands';
import PopularSendDayByBrands from './PopularSendDayByBrands';
import PopularSendDayByDays from './PopularSendDayByDays';
import HoursInWeek from './HoursInWeek';
import NewsletterCampaignsSent from './NewsletterCampaignsSent';
import PopularBrandsByHour from './PopularBrandsByHour';

const GroupContainer = props => (
  <Body>
    <NewsletterCampaignsSent {...props} />
    <CampaignsSentByBrands {...props} />

    <Heading>Most popular send day</Heading>

    <Flex justifyContent="space-between">
      <PopularSendDayByDays {...props} />
      <PopularSendDayByBrands {...props} />
    </Flex>

    <Heading>Most popular send hour</Heading>

    <Flex justifyContent="space-between">
      <HoursInWeek {...props} />
      <PopularBrandsByHour {...props} />
    </Flex>
  </Body>
);

export default GroupContainer;
