import React from 'react';
import { Link } from 'react-router';

import { Button, Flex } from '../mc-ui';

const RestrictedUpgradeButton = () => (
  <Flex alignItems="center" justifyContent="center">
    <Button
      as={Link}
      to="/manage/plan"
      variant="purple"
      css={{
        alignItems: 'center',
        display: 'inline-flex',
        height: '40px',
        justifyContent: 'center',
        padding: '0',
        width: '220px',
      }}
    >
      Upgrade Now
    </Button>
  </Flex>
);

export default RestrictedUpgradeButton;
