/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import moment from 'moment';
import { splitEvery, take } from 'ramda';

import { Box, Flex, Table, Tag } from '../../../mc-ui';

// import Emojis from '../../../newReports/SubjectLines/TopEmojis/Emojis';

import Heading from '../Heading';
import Text from '../Text';

const Highlights = ({ data, model }) => {
  const mostPopularTermsPairs = splitEvery(2, take(4, data.popularTerms.buckets));
  const [bucket] = data.subjectLineLength.buckets.filter(b => b.primary);
  const subjectLineLength = bucket.value;

  return (
    <Box>
      <Box marginBottom="32px">
        <Heading>Most Popular Terms</Heading>

        <Flex justifyContent="space-between" marginTop="16px">
          {mostPopularTermsPairs.map((mostPopularTerms, i) => (
            <Table
              key={`subjectLines-Highlights-mostPopularTermsPairs-${i}`}
              css={{ width: '344px' }}
            >
              <Table.Body>
                {mostPopularTerms.map((mostPopularTerm, index) => (
                  <Table.Row
                    key={`subjectLines-Highlights-mostPopularTerms-${mostPopularTerm.key}`}
                    css={{
                      border: index === 1 ? 'none' : null,
                    }}
                  >
                    <Table.IndexCell
                      verticalAlign="top"
                      index={index}
                      page={i + 1}
                      pageSize={i + 1}
                    />
                    <Table.Cell>
                      <Link
                        to={{
                          pathname: `/companies/${model.id}/emails`,
                          query: {
                            q: mostPopularTerm.word,
                            filter: 'subject',
                            end_date: moment(new Date()).format('YYYY-MM-DD'),
                            start_date: moment(new Date())
                              .subtract(90, 'days')
                              .format('YYYY-MM-DD'),
                          },
                        }}
                      >
                        <Tag color="dark08" css={{ marginBottom: '4px' }}>
                          {mostPopularTerm.word}
                        </Tag>
                      </Link>
                      <Box
                        marginTop="4px"
                        width="314px"
                        css={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {mostPopularTerm.emails[0].attributes.subject}
                      </Box>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          ))}
        </Flex>
      </Box>

      <Box width="344px">
        <Heading>AVG Subject Line Length</Heading>
        <Text>
          {Math.round(subjectLineLength)} {pluralize('character', subjectLineLength)}
        </Text>
      </Box>
    </Box>
  );
};
Highlights.propTypes = {
  data: PropTypes.shape({
    popularTerms: PropTypes.shape({
      buckets: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    subjectLineLength: PropTypes.shape({
      values: PropTypes.shape({}),
    }),
  }).isRequired,
  model: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

export default Highlights;
