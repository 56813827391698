import React from 'react';

function SvgWordpressIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M8 0C3.59 0 0 3.587 0 8c0 4.41 3.59 8 8 8s8-3.59 8-8c0-4.413-3.59-8-8-8zM.807 8c0-1.043.222-2.033.622-2.927l3.432 9.4A7.196 7.196 0 01.807 8zM8 15.192a7.147 7.147 0 01-2.032-.294l2.158-6.27 2.21 6.058c.016.036.033.067.051.1A7.203 7.203 0 018 15.193zM8.99 4.63c.432-.023.822-.068.822-.068.387-.045.342-.616-.045-.594 0 0-1.165.09-1.916.09-.707 0-1.894-.09-1.894-.09-.387-.023-.432.571-.045.594 0 0 .367.045.755.067L7.786 7.7l-1.574 4.72-2.619-7.79c.433-.023.823-.068.823-.068.387-.045.342-.616-.045-.594 0 0-1.165.09-1.916.09-.136 0-.294-.003-.465-.01A7.188 7.188 0 018 .808c1.871 0 3.577.716 4.858 1.886a.89.89 0 00-.093-.006c-.707 0-1.207.616-1.207 1.278 0 .593.342 1.093.707 1.687.274.477.594 1.093.594 1.984 0 .616-.236 1.329-.549 2.326l-.717 2.397L8.99 4.63zm5.323-.081c.562 1.026.881 2.2.881 3.452a7.184 7.184 0 01-3.577 6.216l-.001-.001 2.197-6.352c.409-1.026.548-1.845.548-2.577 0-.264-.016-.51-.048-.738z"
        fill="#09B5B5"
      />
    </svg>
  );
}

export default SvgWordpressIcon;

