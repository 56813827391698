import React from 'react';
import PropTypes from 'prop-types';
import styled from "@emotion/styled";
import { Box } from '../../mc-ui';

const Picture = styled("picture")({
  display: 'none',
  height: '92px',
  '& > *': {
    height: '92px',
  },
});

export default function TextMessageImage({ textMessage }) {
  const [image] = textMessage.attributes.images;
  if (!image) return null;

  const url = `//assets.mailcharts.com/text-messages/${textMessage.id}/images/`;

  const css = {
    backgroundImage: `url(${url}${image.webp}), ${
      image.gif ? `url(${url}${image.gif})` : `url(${url}${image.jpg})`
    }`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  };

  return (
    <Box borderRadius="8px" height="92px" css={css}>
      <Picture>
        <source srcSet={`${url}${image.webp}`} type="image/webp" />
        {image.gif ? (
          <source srcSet={`${url}${image.gif}`} type="image/gif" />
        ) : (
          <source srcSet={`${url}${image.jpg}`} type="image/jpeg" />
        )}
        <img src={`${url}${image.gif || image.jpg}`} alt="" />
      </Picture>
    </Box>
  );
}

TextMessageImage.propTypes = {
  textMessage: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.shape({
      images: PropTypes.arrayOf(
        PropTypes.shape({
          gif: PropTypes.string,
          jpg: PropTypes.string,
          original: PropTypes.string,
          png: PropTypes.string,
          webp: PropTypes.string,
        }),
      ),
    }),
  }).isRequired,
};
