import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { teamsSelectors } from '../../modules/teams';

import ContactModalLink from '../ContactModalLink';
import EmailSubscribeIcon from '../mc-ui/icons/EmailSubscribeIcon';
import LightBulbExclamationIcon from '../mc-ui/icons/LightBulbExclamationIcon';
import CommentPlusIcon from '../mc-ui/icons/CommentPlusIcon';
import HumanIcon from '../mc-ui/icons/HumanIcon';
import InvoiceIcon from '../mc-ui/icons/InvoiceIcon';
import NotificationSign from '../ui/NotificationSign';
import ScrollIcon from '../mc-ui/icons/ScrollIcon';
import SpeakerIcon from '../mc-ui/icons/SpeakerIcon';
import TeamIcon from '../mc-ui/icons/TeamIcon';
import { SubMenu } from '../mc-ui';

const ManageMenu = ({ isOwner, isSubscriptionAutomaticallyCharged, team }) => {
  const { status } = team ? team.attributes : {};
  const pendingMember = team ? status === 'pending' : false;
  const notification = pendingMember || !team;

  return (
    <SubMenu>
      <SubMenu.Link to="/manage">
        <HumanIcon />
        My profile
      </SubMenu.Link>
      <SubMenu.Link to="/manage/plan">
        <ScrollIcon />
        Plan
      </SubMenu.Link>
      <SubMenu.Link to="/manage/teams">
        <TeamIcon />
        {team ? 'Team management' : 'Looking for Enterprise?'}
        {notification && <NotificationSign type="pro" css={{ marginLeft: '4px' }} />}
      </SubMenu.Link>
      {team &&
        isOwner &&
        isSubscriptionAutomaticallyCharged && (
          <SubMenu.Link to="/manage/invoices">
            <InvoiceIcon />
            Invoices &amp; Billing
          </SubMenu.Link>
        )}

      <SubMenu.Link as={ContactModalLink}>
        <SpeakerIcon fillOpacity={1} />
        Contact Support
      </SubMenu.Link>

      <SubMenu.Link to="/manage/preferences">
        <EmailSubscribeIcon />
        Preferences
      </SubMenu.Link>

      <SubMenu.Link to="/manage/productboard">
        <LightBulbExclamationIcon />
        Feature Request
      </SubMenu.Link>

      <SubMenu.Link to="/manage/brandrequestboard">
        <CommentPlusIcon />
        Brand Request
      </SubMenu.Link>
    </SubMenu>
  );
};

ManageMenu.defaultProps = {
  team: null,
};

ManageMenu.propTypes = {
  isOwner: PropTypes.bool.isRequired,
  isSubscriptionAutomaticallyCharged: PropTypes.bool.isRequired,
  team: PropTypes.shape({
    attributes: PropTypes.shape({
      account_status: PropTypes.string,
      status: PropTypes.string,
    }),
  }),
};

const mapStateToProps = state => ({
  isOwner: teamsSelectors.isOwnerSelector(state),
  isSubscriptionAutomaticallyCharged: teamsSelectors.isSubscriptionAutomaticallyChargedSelector(
    state,
  ),
  team: teamsSelectors.teamSelector(state),
});

export default connect(mapStateToProps)(ManageMenu);
