import * as React from 'react';
import PropTypes from 'prop-types';

function HumanIcon({ fillOpacity, ...props }) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.557 9.554a3.277 3.277 0 100-6.554 3.277 3.277 0 000 6.554zm0 2.73L9.81 10.135l-2.443.815a2 2 0 00-1.082 2.926l3.556 5.926c.777 1.295 2.653 1.295 3.43 0l3.556-5.926a2 2 0 00-1.083-2.926l-2.442-.814-1.746 2.148z"
        fill="currentColor"
        fillOpacity={fillOpacity}
      />
    </svg>
  );
}

HumanIcon.propTypes = {
  fillOpacity: PropTypes.number,
};

HumanIcon.defaultProps = {
  fillOpacity: 1,
};

export default HumanIcon;
