import React from 'react';
import { Link } from 'react-router';

import { Button, Heading, Tooltip } from '../../../mc-ui';
import QuestionMarkIcon from '../../../mc-ui/icons/QuestionMarkIcon';

const HistoricalDataTooltip = () => (
  <Tooltip id="historicalDataTooltip" element={<QuestionMarkIcon height="16" width="16" />} dark>
    Our historical data can be used to gain efficiency and foresight when planning campaign strategy.{' '}
    Track year-over-year campaigns and promotions for an outlook into what to expect for the current year's calendar and beyond.
  </Tooltip>
);

export default HistoricalDataTooltip;
