import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import { contains, isEmpty, isNil, type } from 'ramda';

import Modal from '../../Modal';
import { Button } from '../../mc-ui';

import CleanCheckboxes from './CleanCheckboxes';
import FormFields from './FormFields';

const arrayFields = [
  'frequency',
  'history',
  'industries',
  'journey_types',
  'sent_at_day_of_week',
  'sent_at_hour_of_day',
  'technologies',
];

class AdvancedFiltersForm extends Component {
  constructor(props) {
    super(props);

    const { query } = props.router.location;
    const initialValues = { ...query };

    delete initialValues.advanced;
    Object.keys(initialValues).forEach(key => {
      if (isEmpty(initialValues[key]) || isNil(initialValues[key])) {
        delete initialValues[key];
      } else if (contains(key, arrayFields) && type(initialValues[key]) === 'String') {
        const val = initialValues[key];
        initialValues[key] = [val];
      }
    });

    this.state = { initialValues };
  }

  componentDidMount() {
    const params = { sort: 'name:asc', global: true, limit: 250 };
    this.props.requestGroups(params);
  }

  onSubmit = values => {
    const { router } = this.props;
    const query = { ...values };

    Object.keys(query).forEach(key => {
      if (isEmpty(query[key]) || isNil(query[key]) || key === 'promotional') {
        delete query[key];
      }
    });

    if (!isNil(query.premium) && query.premium === false) {
      delete query.premium;
    }

    router.push({ pathname: '/brands/results', query });
  };

  closeModal = event => {
    if (event) {
      event.preventDefault();
    }
    const { router } = this.props;
    const { pathname, query } = router.location;
    delete query.advanced;
    router.push({ pathname, query });
  };

  render() {
    const { initialValues } = this.state;
    const { groups, router } = this.props;
    const [user] = this.props.user.data;
    const { companies_search_params: companiesSearchParams } = this.props.user.meta;
    const formProps = {
      groups,
      user,
      countries: companiesSearchParams.countries.buckets,
      twitterFollowers: companiesSearchParams.twitter_followers.buckets,
      technologies: companiesSearchParams.technology_id.buckets,
    };

    return (
      <Modal onClose={this.closeModal} modalStyle={{ maxHeight: '717px' }} useNewComponent>
        <Modal.Header title="Advanced Brand Search" handleClose={this.closeModal} />
        <Form initialValues={initialValues} onSubmit={this.onSubmit}>
          {({ handleSubmit, form }) => (
            <form onSubmit={handleSubmit}>
              <CleanCheckboxes form={form} />
              <Modal.Body maxHeight="717px">
                <FormFields {...formProps} changeForm={form.change} router={router} />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.closeModal}>
                  Cancel
                </Button>
                <div>
                  <Button
                    variant="secondary"
                    onClick={() => form.reset({})}
                    css={{ marginRight: '16px' }}
                  >
                    Reset
                  </Button>
                  <Button
                    css={{ paddingLeft: '7.34em', paddingRight: '7.34em' }}
                    type="submit"
                    variant="primary"
                  >
                    Search
                  </Button>
                </div>
              </Modal.Footer>
            </form>
          )}
        </Form>
      </Modal>
    );
  }
}

AdvancedFiltersForm.propTypes = {
  groups: PropTypes.shape({}).isRequired,
  requestGroups: PropTypes.func.isRequired,
  router: PropTypes.shape({
    location: PropTypes.shape({
      query: PropTypes.object,
      pathname: PropTypes.string,
    }),
  }).isRequired,
  user: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.object),
    meta: PropTypes.shape({
      companies_search_params: PropTypes.shape({
        countries: PropTypes.shape({ buckets: PropTypes.array }),
        states: PropTypes.shape({ buckets: PropTypes.array }),
        twitter_followers: PropTypes.shape({ buckets: PropTypes.array }),
        technology_id: PropTypes.shape({ buckets: PropTypes.array }),
      }),
    }),
  }).isRequired,
};

const mapStateToProps = state => ({
  groups: state.groups,
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  requestGroups: params =>
    dispatch({
      type: 'REQUEST_GROUPS',
      url: '/v3/groups',
      index: true,
      params,
    }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdvancedFiltersForm);
