import React from 'react';
import PropTypes from 'prop-types';
import styled from "@emotion/styled";
import { isEmpty } from 'ramda';

import { Flex, Heading, Loader } from '../../mc-ui';
import EmptyEnvelop from '../../mc-ui/icons/EmptyEnvelop';

const Wrapper = styled(Flex)(({ theme }) => ({
  alignItems: 'center',
  border: `1px solid ${theme.colors.dark5}`,
  borderRadius: '3px',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '240px',
}));

const Loading = () => (
  <Wrapper>
    <Loader>
      <EmptyEnvelop height="64px" width="102px" />
    </Loader>
  </Wrapper>
);

const NoDataFound = () => (
  <Wrapper>
    <EmptyEnvelop height="64px" width="102px" />
    <Heading as="h4" color="dark06" css={{ lineHeight: '1.32em', marginTop: '30px' }}>
      No recent emails.
    </Heading>
  </Wrapper>
);

const LoadingStatus = ({ loading, latestEmails }) => {
  if (loading) return <Loading />;
  if (!loading && isEmpty(latestEmails)) return <NoDataFound />;
  return null;
};

LoadingStatus.defaultProps = {
  latestEmails: [],
};

LoadingStatus.propTypes = {
  latestEmails: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool.isRequired,
};

export default LoadingStatus;
