import React from 'react';
import PropTypes from 'prop-types';
import styled from "@emotion/styled";

import { Heading, Text, Tooltip } from '../../../../../mc-ui';

import isHoliday from './isHoliday';

const Dot = styled("div")(({ theme }) => ({
  backgroundColor: theme.colors.purpleMedium,
  border: `1px solid ${theme.colors.white}`,
  borderRadius: '50%',
  boxSizing: 'border-box',
  display: 'inline-block',
  height: '8px',
  marginRight: '8px',
  width: '8px',
}));

const Tag = styled("div")(({ theme }) => ({
  border: '8px solid transparent',
  borderLeftColor: theme.colors.purpleMedium,
  borderTopColor: theme.colors.purpleMedium,
  boxSizing: 'border-box',
  height: '8px',
  left: '0',
  position: 'absolute',
  top: '0',
  width: '8px',
}));

const HolidayTag = ({ date, isToday }) => {
  const holiday = isHoliday(date);

  if (!holiday) return null;

  return (
    <Tooltip dark element={<Tag />} id={`holidayTooltip-${date.format('YYYY-MM-DD')}`}>
      <Heading as="h2">
        {date.format('dddd, MMMM DD YYYY')}
        {isToday && ` - Today`}
      </Heading>

      <Text>
        <Dot />
        {holiday}
      </Text>
    </Tooltip>
  );
};

HolidayTag.propTypes = {
  date: PropTypes.shape({}).isRequired,
  isToday: PropTypes.bool.isRequired,
};

export default HolidayTag;
