import PropTypes from 'prop-types';
import React from 'react';
import { Box, Text } from '../../../mc-ui';

export default function EmailScoreBar({ email }) {
  const score = (email.attributes.mailcharts_score || 0) * 100;
  let color;

  if (score < 60) {
    color = 'red1';
  } else if (score >= 60 && score < 78) {
    color = 'yellowMedium';
  } else {
    color = 'greenMedium';
  }

  return (
    <Box position="relative">
      <Text
        color={color}
        fontSize="18px"
        fontWeight="500"
        letterSpacing="0.02em"
        lineHeight="1.32em"
        css={{
          position: 'absolute',
          right: '0',
          top: '-32px',
        }}
      >
        {score}%
      </Text>
      <Box backgroundColor="dark6" borderRadius="8px" height="8px">
        <Box backgroundColor={color} borderRadius="8px" height="8px" width={`${score}%`} />
      </Box>
    </Box>
  );
}

EmailScoreBar.propTypes = {
  email: PropTypes.shape({}).isRequired,
};
