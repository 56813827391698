import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';

import { Box, Select } from '../../mc-ui';

const options = [
  { value: 'desc', label: 'Newest to oldest' },
  { value: 'asc', label: 'Oldest to newest' },
];

class SortSelect extends React.Component {
  handleChange = sort => {
    const { router } = this.props;
    const { pathname, query: q } = router.location;
    const query = sort ? { ...q, sort } : q;
    if (!sort) delete query.sort;
    router.push({ pathname, query });
  };

  render() {
    const { disabled, router } = this.props;
    const { sort = '' } = router.location.query;
    const initialValues = { sort };

    return (
      <Box
        width="176px"
        css={{
          fontSize: '14px',
          height: '32px',
        }}
      >
        <Form initialValues={initialValues} onSubmit={() => {}}>
          {() => (
            <form>
              <Field
                component={Select}
                id="sort"
                isDisabled={disabled}
                name="sort"
                options={options}
                onChange={this.handleChange}
              />
            </form>
          )}
        </Form>
      </Box>
    );
  }
}

SortSelect.defaultProps = {
  disabled: false,
};

SortSelect.propTypes = {
  disabled: PropTypes.bool,
  router: PropTypes.shape({
    location: PropTypes.shape({
      query: PropTypes.object,
      pathname: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
};

export default SortSelect;
