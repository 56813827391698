import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from '../../Tooltip';
import ErrorIcon from '../../icons/ErrorIcon';

const ErrorTooltip = ({ inputId, children }) => (
  <Tooltip id={`errorTooltip${inputId}`} element={<ErrorIcon height="16" width="16" />} dark>
    {children}
  </Tooltip>
);

ErrorTooltip.defaultProps = {
  inputId: null,
};

ErrorTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  inputId: PropTypes.string,
};

export default ErrorTooltip;
