/* eslint-disable no-param-reassign */
import { has } from 'ramda';

const INITIAL_STATE = {
  loading: true,
  links: {},
  meta: {},
  data: [],
  included: [],
};

export default (state = INITIAL_STATE, action) => {
  let data = [];
  switch (action.type) {
    case 'FLUSH':
      return { ...INITIAL_STATE };
    case 'REQUEST_COMPANIES':
      return { ...state, loading: true };
    case 'RECEIVE_COMPANIES':
      if (action.trackingCompanies) {
        let companies = [];
        if (action.data.data[0] && action.data.data[0].type === 'groups') {
          // loaded companies from `/v3/groups/:group_id/companies`
          companies = action.data.included.filter(i => i.type === 'companies');
        } else {
          companies = action.data.data;
        }

        if (typeof companies.data != 'undefined') {
          companies = companies.data;
        }
        data = (companies || []).map(model => {
          if (!has('tracking')(model.attributes)) {
            model.attributes.tracking = action.trackingCompanies.includes(model.id);
          }
          return model;
        });
      } else if (action.data.data[0] && action.data.data[0].type === 'groups') {
        // loaded companies from `/v3/groups/:group_id/companies`
        data = action.data.included.filter(i => i.type === 'companies');
      } else {
        // eslint-disable-next-line prefer-destructuring
        data = action.data.data;
      }

      return { ...action.data, data, loading: false };
    case 'REQUEST_COMPANIES_PAGINATION':
      return { ...state, paginating: true };
    case 'RECEIVE_COMPANIES_PAGINATION':
      if (action.companies.data[0] && action.companies.data[0].type === 'groups') {
        data = [].concat(
          state.data || [],
          action.companies.included.filter(i => i.type === 'companies'),
        );
      } else {
        data = [].concat(state.data || [], action.companies.data);
      }

      if (action.trackingCompanies) {
        const newData = data.map(model => {
          if (!has('tracking')(model.attributes)) {
            model.attributes.tracking = action.trackingCompanies.includes(model.id);
          }
          return model;
        });
        data = newData;
      }
      return {
        ...state,
        data,
        paginating: false,
        links: action.companies.links,
      };
    case 'COMPANIES_UNTRACKED':
      data = state.data.map(model => {
        model.attributes.tracking = ![].concat(action.company_id || []).find(id => id === model.id);
        return model;
      });
      return { ...state, data };
    case 'COMPANIES_TRACKED':
      data = state.data.map(model => {
        model.attributes.tracking = !![]
          .concat(action.company_id || [])
          .find(id => id === model.id);
        return model;
      });
      return { ...state, data };
    default:
      return state;
  }
};
