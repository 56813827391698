const prefix = 'mc/newReports/calendars';

export const FETCH_BRAND_DATA_REQUEST = `${prefix}/FETCH_BRAND_DATA_REQUEST`;
export const FETCH_BRAND_DATA_FAILURE = `${prefix}/FETCH_BRAND_DATA_FAILURE`;
export const FETCH_BRAND_DATA_SUCCESS = `${prefix}/FETCH_BRAND_DATA_SUCCESS`;
export const TOGGLE_BRAND_LOADING = `${prefix}/TOGGLE_BRAND_LOADING`;

export const FETCH_BRAND_MODAL_DATA_REQUEST = `${prefix}/FETCH_BRAND_MODAL_DATA_REQUEST`;
export const FETCH_BRAND_MODAL_DATA_FAILURE = `${prefix}/FETCH_BRAND_MODAL_DATA_FAILURE`;
export const FETCH_BRAND_MODAL_DATA_SUCCESS = `${prefix}/FETCH_BRAND_MODAL_DATA_SUCCESS`;
export const TOGGLE_BRAND_MODAL_LOADING = `${prefix}/TOGGLE_BRAND_MODAL_LOADING`;

export const FETCH_GROUP_DATA_REQUEST = `${prefix}/FETCH_GROUP_DATA_REQUEST`;
export const FETCH_GROUP_DATA_FAILURE = `${prefix}/FETCH_GROUP_DATA_FAILURE`;
export const FETCH_GROUP_DATA_SUCCESS = `${prefix}/FETCH_GROUP_DATA_SUCCESS`;
export const TOGGLE_GROUP_LOADING = `${prefix}/TOGGLE_GROUP_LOADING`;

export const FETCH_GROUP_MODAL_DATA_REQUEST = `${prefix}/FETCH_GROUP_MODAL_DATA_REQUEST`;
export const FETCH_GROUP_MODAL_DATA_FAILURE = `${prefix}/FETCH_GROUP_MODAL_DATA_FAILURE`;
export const FETCH_GROUP_MODAL_DATA_SUCCESS = `${prefix}/FETCH_GROUP_MODAL_DATA_SUCCESS`;
export const TOGGLE_GROUP_MODAL_LOADING = `${prefix}/TOGGLE_GROUP_MODAL_LOADING`;
