import React from "react";

import { PaymentElement, AddressElement } from "@stripe/react-stripe-js";
import { connect } from "react-redux";

import { Box } from "../../mc-ui";


const StripeCheckoutForm = ( props ) => {

  const paymentElementOptions = {
    layout: "tabs"
  }
  
  return (
    <Box>
      <AddressElement options={{mode: 'billing'}} onChange={props.onAddressChange} />
      <div style={{marginTop: "10px"}}></div>
      <PaymentElement id="payment-element" options={paymentElementOptions} />
    </Box>
  );
}

export default connect()(StripeCheckoutForm);