/* global window */

import React from 'react';
import PropTypes from 'prop-types';
import styled from "@emotion/styled";

const ThumbnailsWrapper = styled("div")({
  boxSizing: 'border-box',
  display: 'flex',
  minHeight: '90px',
  margin: '0 auto',
  maxWidth: '220px',
  paddingTop: '30px',
});

const ThumbnailWrapper = styled("div")(({ count, index }) => ({
  flexShrink: index === count - 1 ? 0 : 1, // Let all but the last email shrink if needed
  marginTop: index === 0 ? '9px' : '0', // Stagger stacked emails
  minWidth: 0,
  transition: 'transform 0.3s',
}));

const Thumbnail = styled("div")(({ email }) => {
  const emailImageUrl = `//assets.mailcharts.com/emails/${email.guid}/${'300x400'}.png`;

  return {
    border: '1px solid #D4D9DD',
    borderRadius: '3px',
    backgroundImage: `url(${emailImageUrl})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    height: '160px',
    padding: '0',
    width: '120px',
  };
});

const JourneyExampleEmails = ({ emails }) => (
  <ThumbnailsWrapper>
    {emails.slice(0, 2).map((email, index) => (
      <ThumbnailWrapper
        key={email.guid}
        count={emails.length > 2 ? 2 : emails.length}
        index={index}
      >
        <Thumbnail email={email} />
      </ThumbnailWrapper>
    ))}
  </ThumbnailsWrapper>
);

JourneyExampleEmails.propTypes = {
  emails: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default JourneyExampleEmails;
