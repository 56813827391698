import api from '../../../lib/api';

// eslint-disable-next-line import/prefer-default-export
export const fetchData = async (modelId, modelType, chart, interval, startDate, endDate) => {
  let query = `?group_id=${modelId}`;

  if (modelType === 'brands' || modelType === 'companies') {
    query = `?company_id=${modelId}`;
  }

  query += `&start_date=${startDate}`;
  query += `&end_date=${endDate}`;
  query += `&chart=${chart}`;
  query += `&interval=${interval}`;

  const response = await api.get(`/v3/reports/time-series${query}`);
  return response.data;
};
