import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Flex, Loader } from './mc-ui';
import Unlocked from './mc-ui/Unlocked';

import EmptyGroup from './groups/EmptyGroup';
import GroupInformation from './groups/GroupInformation';
import GroupsMenu from './menus/GroupsMenu';
import Header from './Header';
import Section from './Section';
import NotFound from './NotFound';

class Groups extends React.Component {
  componentDidMount() {
    this.request();
  }

  componentDidUpdate(prevProps) {
    const {
      params,
      router: {
        location: { query },
      },
    } = this.props;
    const {
      params: prevParams,
      router: {
        location: { query: prevQuery },
      },
    } = prevProps;

    const queryStr = JSON.stringify({ ...query, ...params });
    const prevQueryStr = JSON.stringify({ ...prevQuery, ...prevParams });

    if (queryStr !== prevQueryStr) {
      this.request();
    }
  }

  componentWillUnmount() {
    this.props.flush();
  }

  request = () => {
    const {
      groups,
      requestGroups,
      params,
      router: { location },
    } = this.props;

    const query = {
      ...location.query,
      ...params,
    };

    // eslint-disable-next-line no-unused-vars
    const [space, model, id, type] = location.pathname.split('/');
    const [group = {}] = groups.data;
    if (type && params.group_id === group.id && group.relationships) return;

    requestGroups(query);
  };

  isLoading = () => {
    const { groups } = this.props;
    return groups.data.length < 1 || groups.loading;
  };

  render() {
    const { children, groups, params, user: u } = this.props;
    const [user] = u.data;

    if (groups.response && groups.response.status === 404 || groups.data && groups.data.length == 0) {
      console.log("Resource not found");
      return <NotFound />
    }

    const group = groups.data.find(g => g.id === params.group_id) || { attributes: {} };

    return (
      <div className="pure-u-1">
        <Header>
          <GroupInformation router={this.props.router} loading={this.isLoading()} />
        </Header>

        <Section>
          {!group.id && (
            <Flex alignItems="center" flexDirection="column" height="448px" justifyContent="center">
              <Loader />
            </Flex>
          )}

          {group.id && group.attributes.approximate_companies === 0 && <EmptyGroup />}

          {group.id &&
            group.attributes.approximate_companies > 0 && (
              <Flex justifyContent="space-between">
                <Flex css={{ width: '200px', marginTop: '1em' }}>
                  <GroupsMenu {...this.props} />
                </Flex>

                <Flex css={{ width: '834px' }}>
                  <div style={{ width: '100%' }}>
                    {user && (
                      <Unlocked
                        title="Unlocked"
                        text={`You are viewing unlocked historical data, select journeys, and reports from ${
                          group.attributes.name
                        }. Upgrade now for complete access to all curated data and industries in the MailCharts Index.`}
                        user={user}
                        unlocked={(group.attributes || {}).featured}
                        style={{ marginTop: 15 }}
                      />
                    )}
                    {React.Children.map(children, child =>
                      React.cloneElement(child, { model: group }),
                    )}
                  </div>
                </Flex>
              </Flex>
            )}
        </Section>
      </div>
    );
  }
}

Groups.propTypes = {
  children: PropTypes.node.isRequired,
  flush: PropTypes.func.isRequired,
  groups: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.object),
    included: PropTypes.arrayOf(PropTypes.object),
    links: PropTypes.object,
    loading: PropTypes.bool,
    meta: PropTypes.object,
  }).isRequired,
  params: PropTypes.shape({ group_id: PropTypes.string }).isRequired,
  requestGroups: PropTypes.func.isRequired,
  router: PropTypes.shape({
    location: PropTypes.shape({ query: PropTypes.object }),
  }).isRequired,
  user: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};

const mapStateToProps = state => ({
  groups: state.groups,
  user: state.user,
});

const mapDispatchToProps = (dispatch, { params }) => ({
  flush: () => dispatch({ type: 'FLUSH' }),
  requestGroups: query =>
    dispatch({ type: 'REQUEST_GROUPS', group_id: params.group_id, params: query }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Groups);
