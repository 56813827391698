import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { contains, is, isEmpty, merge, omit } from 'ramda';

import { COMPARE_QUERY_KEY } from '../reports/Header';

import SelectedFiltersWrapper from './ui/SelectedFiltersWrapper';

const isString = is(String);

const capitalize = str =>
  str
    .replace(/_/, ' ')
    .split(' ')
    .reduce((result, word) => {
      result.push(`${word.charAt(0).toUpperCase()}${word.slice(1)}`);
      return result;
    }, [])
    .join(' ');

const filtersMap = {
  all_content: 'Keywords',
  subject: 'Subject',
  preheader: 'Preheader',
  full_text: 'Exclude subject line',
};

class SelectedFilters extends Component {
  getValueFromUser = (id, type) => {
    const { user } = this.props;
    const model = user.included.find(i => i.type === type && i.id === id);
    return model.attributes.name;
  };

  filters = () => {
    const { currentQuery, query, queryScope, showDates } = this.props;

    if ((isEmpty(currentQuery) || isEmpty(currentQuery[queryScope])) && isEmpty(query)) {
      return {};
    }

    const mergedQuery = merge(
      queryScope ? currentQuery[queryScope] : omit([COMPARE_QUERY_KEY], currentQuery) || {},
      query,
    );

    const IGNORE_LIST = ['q', 'company_id', 'group_id', COMPARE_QUERY_KEY];
    if (!showDates) {
      IGNORE_LIST.push('end_date');
      IGNORE_LIST.push('start_date');
    }

    return Object.keys(mergedQuery)
      .filter(k => !!mergedQuery[k])
      .reduce((f, k) => {
        if (contains(k, IGNORE_LIST)) {
          return f;
        }

        let result = null;

        if (k === 'animated') {
          result = { key: null, value: 'Animated GIFs' };
        } else if (k === 'mobile_optimized') {
          result = { key: null, value: 'Mobile Optimized' };
        } else if (k === 'subject_line_emoji') {
          result = { key: null, value: 'Emojis' };
        } else if (k === 'segment') {
          result = { key: 'User type', value: capitalize(mergedQuery[k]) };
        } else if (k === 'technology_id') {
          const value = this.getValueFromUser(mergedQuery[k], 'technologies');
          result = { key: 'Technology', value };
        } else if (k === 'filter') {
          result = {
            key: `${capitalize(filtersMap[mergedQuery[k]].replace(/_/g, ' '))} contains`,
            value: mergedQuery.q,
          };
        } else if (contains(k, ['end_date', 'start_date'])) {
          const value = isString(mergedQuery[k])
            ? mergedQuery[k]
            : mergedQuery[k].format('YYYY-MM-DD');
          const key = capitalize(k.replace(/_/g, ' '));
          result = { key, value };
        } else {
          const value = capitalize(mergedQuery[k]);
          const key = capitalize(k.replace(/_/g, ' '));
          result = { key, value };
        }

        return merge(f, { [k]: result });
      }, {});
  };

  handleRemove = event => {
    event.preventDefault();
    const { removeQueryEntry } = this.props;

    let { target } = event;
    if (!target.dataset.key) {
      target = target.parentElement;
    }

    removeQueryEntry(target.dataset.key);
  };

  render() {
    const filters = this.filters();

    if (isEmpty(filters)) {
      return null;
    }

    return (
      <section style={{ marginBottom: '16px' }}>
        <SelectedFiltersWrapper>
          {Object.keys(filters).map(fKey => {
            const filter = filters[fKey];

            return (
              <a key={fKey} data-key={fKey} href={`#remove-${fKey}`} onClick={this.handleRemove}>
                {filter.key && `${filter.key}: `}
                <strong>{filter.value}</strong>
                <img src="/close.png" alt="Remove" />
              </a>
            );
          })}
        </SelectedFiltersWrapper>
      </section>
    );
  }
}

SelectedFilters.propTypes = {
  query: PropTypes.shape({}).isRequired,
  removeQueryEntry: PropTypes.func.isRequired,
  showDates: PropTypes.bool.isRequired,
  user: PropTypes.shape({}).isRequired,
  currentQuery: PropTypes.shape({}),
  queryScope: PropTypes.string,
};

SelectedFilters.defaultProps = {
  currentQuery: {},
  queryScope: null,
};

const mapStateToProps = state => ({
  user: state.user,
});

export default connect(mapStateToProps)(SelectedFilters);
