import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { Form, Field } from 'react-final-form';
import { connect } from 'react-redux';
import { isEmpty } from 'ramda';

import {
  weeklyRollUpActions as actions,
  weeklyRollUpConstants as constants,
  weeklyRollUpSelectors as selectors,
} from '../../modules/weeklyRollUp';

import GroupLogo from '../ui/GroupLogo';
import { Box, Button, Flex, Heading, Loader, Select, Text } from '../mc-ui';

import * as S from './styles';
import DisabledText from './DisabledText';
import EnabledText from './EnabledText';
import ExploreAllIndustriesNotice from './ExploreAllIndustriesNotice';
import LimitReachedNotice from './LimitReachedNotice';

function Title() {
  return (
    <Heading
      as="h3"
      color="dark08"
      fontSize="24px"
      fontWeight="300"
      lineHeight="32px"
      css={{ marginBottom: '24px' }}
    >
      Weekly roll-up email
    </Heading>
  );
}

function customGroupsOptions(groups, customGroups) {
  const groupIds = groups.map(group => group.id);
  return customGroups.filter(g => !groupIds.includes(g.id)).map(group => ({
    label: group.attributes.name,
    value: group.id,
  }));
}

function industryGroupsOptions(groups, industryGroups) {
  const groupIds = groups.map(group => group.id);
  return industryGroups.filter(g => !groupIds.includes(g.id)).map(group => ({
    label: group.attributes.name,
    value: group.id,
  }));
}

class WeeklyRollUpEmail extends React.Component {
  componentDidMount() {
    this.props.request();
  }

  handleChange = groupId => {
    const { addGroup, customGroups, industryGroups } = this.props;
    const group =
      customGroups.find(g => g.id === groupId) || industryGroups.find(g => g.id === groupId);

    addGroup(group);
  };

  render() {
    const {
      customGroups,
      disable,
      enable,
      enabled,
      groups,
      industryGroups,
      loading,
      removeGroup,
    } = this.props;

    if (loading) {
      return (
        <Box backgroundColor="white" borderRadius="4px" marginTop="1em" padding="32px">
          <Title />
          <Loader />
        </Box>
      );
    }

    return (
      <Box backgroundColor="white" borderRadius="4px" marginTop="1em" padding="32px">
        <Title />

        <Flex justifyContent="space-between" marginBottom="24px">
          {enabled ? <EnabledText /> : <DisabledText />}

          <S.Toggle>
            <S.Toggle.Button active={enabled} onClick={enable}>
              Enabled
            </S.Toggle.Button>

            <S.Toggle.Button active={!enabled} onClick={disable}>
              Disabled
            </S.Toggle.Button>
          </S.Toggle>
        </Flex>

        <S.Divider />

        <Flex
          alignItems="center"
          justifyContent="space-between"
          marginBottom="24px"
          marginTop="24px"
        >
          <Heading as="h3" color="dark08" lineHeight="24px">
            Selected groups ({groups.length}/{constants.MAX_GROUPS_ALLOWED})
          </Heading>

          <Form initialValues={{}} onSubmit={() => {}}>
            {() => (
              <form>
                <Flex justifyContent="space-between" width="360px">
                  <Box
                    width="176px"
                    css={{
                      fontSize: '14px',
                      height: '32px',
                    }}
                  >
                    <Field
                      component={Select}
                      id="industryGroup"
                      isDisabled={!enabled || groups.length === constants.MAX_GROUPS_ALLOWED}
                      name="industryGroup"
                      onChange={this.handleChange}
                      options={industryGroupsOptions(groups, industryGroups)}
                      placeholder="Industry groups"
                    />
                  </Box>

                  <Box
                    width="176px"
                    css={{
                      fontSize: '14px',
                      height: '32px',
                    }}
                  >
                    <Field
                      component={Select}
                      id="customGroup"
                      isDisabled={!enabled || groups.length === constants.MAX_GROUPS_ALLOWED}
                      name="customGroup"
                      onChange={this.handleChange}
                      options={customGroupsOptions(groups, customGroups)}
                      placeholder="Custom groups"
                    />
                  </Box>
                </Flex>
              </form>
            )}
          </Form>
        </Flex>

        {isEmpty(groups) ? (
          <ExploreAllIndustriesNotice />
        ) : (
          <Box>
            {groups.map(group => (
              <Flex
                alignItems="center"
                justifyContent="space-between"
                key={`weeklyRollUp-Groups-${group.id}`}
                marginBottom="8px"
              >
                <GroupLogo group={group} />

                <Flex
                  direction="column"
                  flex="1"
                  justifyContent="center"
                  marginLeft="8px"
                  width="unset"
                >
                  <Heading
                    as="h5"
                    css={{
                      fontSize: '14px',
                      letterSpacing: '0.02em',
                      lineHeight: '1.25em',
                      textTransform: 'capitalize',
                    }}
                  >
                    {group.attributes.name}
                  </Heading>

                  <Text fontSize="12px">
                    {group.attributes.approximate_companies}{' '}
                    {pluralize('company', group.attributes.approximate_companies)}
                  </Text>
                </Flex>

                <Button
                  css={{
                    alignItems: 'center',
                    display: 'inline-flex',
                    height: '24px',
                    justifyContent: 'center',
                    lineHeight: '24px',
                    width: '96px',
                  }}
                  onClick={() => removeGroup(group)}
                  variant="secondary"
                >
                  Unsubscribe
                </Button>
              </Flex>
            ))}
          </Box>
        )}

        {groups.length === constants.MAX_GROUPS_ALLOWED && (
          <Box marginTop="24px">
            <LimitReachedNotice />
          </Box>
        )}
      </Box>
    );
  }
}

WeeklyRollUpEmail.propTypes = {
  addGroup: PropTypes.func.isRequired,
  customGroups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      attributes: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
  ).isRequired,
  disable: PropTypes.func.isRequired,
  enable: PropTypes.func.isRequired,
  enabled: PropTypes.bool.isRequired,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      attributes: PropTypes.shape({
        name: PropTypes.string,
        approximate_companies: PropTypes.number,
      }),
    }),
  ).isRequired,
  industryGroups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      attributes: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
  ).isRequired,
  loading: PropTypes.bool.isRequired,
  removeGroup: PropTypes.func.isRequired,
  request: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  customGroups: selectors.customGroupsSelector(state),
  enabled: selectors.isEnabledSelector(state),
  groups: selectors.groupsSelector(state),
  industryGroups: selectors.industryGroupsSelector(state),
  loading: selectors.isLoadingSelector(state),
});

const mapDispatchToProps = dispatch => ({
  addGroup: group => dispatch(actions.addGroupRequest(group)),
  disable: () => dispatch(actions.disableRequest()),
  enable: () => dispatch(actions.enableRequest()),
  removeGroup: group => dispatch(actions.removeGroupRequest(group)),
  request: () => dispatch(actions.fetchRequest()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WeeklyRollUpEmail);
