import React from 'react'
import PropTypes from 'prop-types';
import { Link } from 'react-router'
import { connect } from 'react-redux'
import humanize from 'humanize-number'
import api from '../lib/api'
import axios from 'axios'
import EmailImage from './EmailImage'

class TechnologiesList extends React.Component {
  render() {
    const { router, technologies } = this.props;
    return (
      <div className='pure-g gutters'>
        {technologies.map((model, index) => {
          return (
            <div
              key={index}
              onClick={(e) => {
                e.preventDefault()
                router.push(`/${model.type}/${model.id}`)
              }}
              style={{
                cursor: 'pointer',
              }}
              className='pure-u-1 pure-u-md-1-2'
            >
              <div className='pure-u-1'>
                <div
                  style={{
                    border: '1px solid #D4D9DE',
                    marginBottom: 25,
                    borderRadius: 3,
                    background: '#fff',
                    padding: '25px 25px 0 25px',
                  }}
                >
                  <div className='pure-u-1 pure-u-md-7-8'>
                    <div className='pure-u-1-8'>
                      <img
                        src={model.attributes.logo_src}
                        className='pure-img'
                      />
                    </div>
                    <div className='pure-u-1-24' />
                    <div className='pure-u-12-24'>
                      <h4 style={{ margin: 0 }}>{model.attributes.name}</h4>
                      <p
                        style={{ fontSize: `${12/16}em`, color: '#A0AEBA', margin: 0 }}
                      >
                        {humanize(model.attributes.approximate_emails || 0)} emails
                      </p>
                    </div>
                  </div>
                <div style={{ marginTop: 10, minHeight: 102 }}>
                  {(model.relationships.emails.data || []).map((email, idx) => {
                    let width = idx % 2 ? '40%' : '30%'
                    let zIndex = idx % 2 ? 1 : 0
                    let height = 100
                    let style = { width, height, zIndex, display: 'inline-block', overflow: 'hidden' }
                    return (
                      <div
                        key={idx}
                        style={style}
                      >
                        <EmailImage guid={email.attributes.guid} style={{ width: '100%' }} />
                      </div>
                    )
                  })}
                </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    )
  }
}

TechnologiesList.propTypes = {
  technologies: PropTypes.shape({}).isRequired,
  router: PropTypes.shape({}).isRequired,
};

export default connect()(TechnologiesList)
