import React from 'react';

import NoteIcon from '../../mc-ui/icons/NoteIcon';
import { Box, Heading, Text } from '../../mc-ui';

import * as S from './styles';

export default function EmptyQuery() {
  console.log('EmptyQuery needs a real copy.');
  return (
    <S.AlertWrapper>
      <Box height="32px" marginRight="16px" width="32px">
        <NoteIcon height="32px" width="32px" />
      </Box>

      <Box flex="1" marginRight="16px" width="unset">
        <Heading as="h4" css={{ marginBottom: '8px' }}>
          Nothing to show.
        </Heading>
        <Text>
          Enter a query in the field above or use the Advanced Filters in order to search for
          brands.
        </Text>
      </Box>
    </S.AlertWrapper>
  );
}
