import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';

import { customGroupsActions, customGroupsSelectors } from '../../../modules/customGroups';

import ArrowLeftIcon from '../../mc-ui/icons/ArrowLeftIcon';
import { Box, Button, Flex, Label, TextField, theme } from '../../mc-ui';

import * as S from '../styles';
import { getPositions } from '../helpers';

class CreateGroupForm extends React.Component {
  constructor(props) {
    super(props);

    const { parentEl } = props;
    const { bottom, left, marginLeft, right, top } = getPositions(parentEl);

    this.state = {
      bottom,
      left,
      marginLeft,
      right,
      top,
    };
  }

  handleBack = event => {
    if (event) event.preventDefault();

    this.props.onCloseCreateForm();
    this.props.onOpenGroupsForm();
  };

  handleSubmit = (values, _form, callback) => {
    const { brands, handleCreate, onCloseCreateForm, onOpenGroupsForm } = this.props;

    handleCreate(brands, values, () => {
      callback();
      onCloseCreateForm();
      onOpenGroupsForm();
    });
  };

  render() {
    const { isValidTeam, owner } = this.props;
    const { bottom, left, marginLeft, right, top } = this.state;

    return (
      <Box
        backgroundColor="white"
        borderRadius="4px"
        bottom={bottom}
        boxShadow={`0px 2px 8px ${theme.colors.dark02}`}
        left={left}
        marginLeft={marginLeft}
        padding="24px"
        position="absolute"
        right={right}
        top={top}
        width="384px"
        zIndex={theme.zIndices.dropdown}
      >
        <Box marginBottom="16px">
          <Button
            onClick={this.handleBack}
            variant="none"
            css={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              padding: '0',
              margin: '0',
              fontSize: '18px',
              lineHeight: '24px',
              fontWeight: '500',
              color: theme.colors.dark1,
              '& > svg': {
                marginRight: '8px',
              },
            }}
          >
            <ArrowLeftIcon fillOpacity={1} height="24px" width="24px" />
            Create new group
          </Button>
        </Box>

        <Form initialValues={{ owner }} onSubmit={this.handleSubmit}>
          {({ form, handleSubmit, pristine, submitting, values }) => (
            <form onSubmit={handleSubmit}>
              <Label htmlFor="name" css={{ marginBottom: '16px' }}>
                <Field
                  autoComplete="off"
                  autoFocus
                  component={TextField}
                  id="name"
                  name="name"
                  placeholder="Group name"
                />
              </Label>

              <Flex alignItems="center" justifyContent={isValidTeam ? 'space-between' : 'flex-end'}>
                {isValidTeam && (
                  <S.ButtonGroup>
                    <S.Button
                      active={values.owner === 'me'}
                      onClick={event => {
                        event.preventDefault();
                        form.change('owner', 'me');
                      }}
                    >
                      Mine
                    </S.Button>
                    <S.Button
                      active={values.owner === 'team'}
                      onClick={event => {
                        event.preventDefault();
                        form.change('owner', 'team');
                      }}
                    >
                      Team
                    </S.Button>
                  </S.ButtonGroup>
                )}

                <Button
                  disabled={submitting || pristine}
                  variant="primary"
                  type="submit"
                  css={{
                    alignItems: 'center',
                    display: 'inline-flex',
                    height: '32px',
                    justifyContent: 'center',
                    margin: '0',
                    padding: '0 12px',
                    '& > svg': {
                      marginRight: '8px',
                    },
                  }}
                >
                  Create new
                </Button>
              </Flex>
            </form>
          )}
        </Form>
      </Box>
    );
  }
}

CreateGroupForm.propTypes = {
  brands: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleCreate: PropTypes.func.isRequired,
  isValidTeam: PropTypes.bool.isRequired,
  onCloseCreateForm: PropTypes.func.isRequired,
  onOpenGroupsForm: PropTypes.func.isRequired,
  owner: PropTypes.string.isRequired,
  parentEl: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => ({
  isValidTeam: customGroupsSelectors.isValidTeamSelector(state),
});

const mapDispatchToProps = dispatch => ({
  handleCreate: (...args) => dispatch(customGroupsActions.createGroupAndAddBrandsRequest(...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateGroupForm);
