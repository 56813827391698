import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Container from './Container';
import { Box, Flex } from './mc-ui';

class ProductBoard extends React.Component {
  render() {
    const user = this.props.user.data.reduce((m, u) => u, {});

    return (
      <Box backgroundColor="white" borderRadius="4px" marginTop="1em" padding="32px">
          <iframe src={`${user.attributes.product_board_url}`} frameBorder="0" height="650" width="100%"></iframe>
      </Box>
    );
  };
};

ProductBoard.propTypes = {
  user: PropTypes.shape({
    data: PropTypes.array
  }),
};

const mapStateToProps = state => ({
  user: state.user,
});

export default connect(
  mapStateToProps,
)(ProductBoard);
