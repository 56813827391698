import moment from 'moment';

const FIVE_WEEK_MONTHS = [1, 4, 7, 10];
const FIVE_FIRST_WEEKS = [4, 17, 30, 43];
const LAST_FIVE_WEEKS = [8, 21, 34, 47, 52];

function nrf(mixed = new Date()) {
  const date = moment(mixed).clone();

  const first = obj => {
    const year = obj.isBefore(moment(`${obj.format('YYYY')}-01-29`))
      ? obj.clone().year() - 1
      : obj.clone().year();
    const sunday = moment(`${year}-01-29`).day('sunday');
    const result = sunday.date() >= 29 ? sunday.clone() : sunday.clone().add(1, 'week');

    return obj.isBefore(result) ? first(obj.clone().add(-1, 'year')) : result;
  };

  // eslint-disable-next-line prefer-const
  let firstDayOfNRFYear = first(date);

  const startOf = (_interval = '') => {
    const interval = _interval.toLowerCase();

    if (interval === 'year' || interval === 'years' || interval === 'y') {
      return firstDayOfNRFYear;
    } else if (interval === 'month' || interval === 'months' || interval === 'm') {
      const month = date.nrf.month();
      let result = nrf(firstDayOfNRFYear);

      while (month > result.nrf.month()) {
        result = nrf(result.clone().add(1, 'week'));
      }

      return result;
    }

    return date.startOf(interval);
  };

  const endOf = (_interval = '') => {
    const interval = _interval.toLowerCase();

    if (interval === 'year' || interval === 'years' || interval === 'y') {
      // eslint-disable-next-line prefer-const
      let next = nrf(date.add(1, 'year'));

      if (next.nrf.week() === 52) {
        next.add(1, 'week');
      }

      const start = nrf(next).nrf.startOf('year');
      return start.add(-1, 'day');
    } else if (interval === 'month' || interval === 'months' || interval === 'm') {
      // eslint-disable-next-line prefer-const
      let next = nrf(date.add(1, 'month'));

      if (next.nrf.week() === 52) {
        next.add(1, 'week');
      }

      const end = nrf(next).nrf.startOf('month');
      return end.add(-1, 'day');
    } else if (interval === 'quarter' || interval === 'quarters' || interval === 'q') {
      return nrf(date.add(13, 'week'))
        .nrf.startOf('quarter')
        .add(-1, 'day');
    }

    return date.endOf(interval);
  };

  const week = () => date.diff(date.nrf.startOf('year'), 'weeks');
  const weeks = week;

  const month = () => {
    const nrfWeek = date.nrf.week();
    if (nrfWeek === 52) return 11;

    let nrfWeeks = 4;
    let nrfMonth = 0;
    let result = date.nrf.week() - nrfWeeks;
    while (result >= 0) {
      nrfMonth += 1;
      nrfWeeks = FIVE_WEEK_MONTHS.indexOf(nrfMonth) > -1 ? 5 : 4;
      result -= nrfWeeks;
    }

    return nrfMonth;
  };
  const months = month;

  const add = (value = 0, _interval = '') => {
    if (value < 0) return date.nrf.subtract(value, _interval);

    const interval = _interval.toLowerCase();
    const nrfWeek = date.nrf.week();

    if (interval === 'year' || interval === 'years' || interval === 'y') {
      return nrf(firstDayOfNRFYear.add(1, 'year')).nrf.add(nrfWeek, 'weeks');
    } else if (interval === 'month' || interval === 'months' || interval === 'm') {
      if (nrfWeek === 52 || FIVE_FIRST_WEEKS.indexOf(nrfWeek) > -1) return date.add(5, 'weeks');
      return date.add(4, 'weeks');
    } else if (interval === 'quarter' || interval === 'quarters' || interval === 'q') {
      return date.add(13, 'weeks');
    }

    return date.add(value, interval);
  };

  const subtract = (value = 0, _interval = '') => {
    if (value < 0) return date.nrf.add(value, _interval);

    const interval = _interval.toLowerCase();
    const nrfWeek = date.nrf.week();

    if (interval === 'year' || interval === 'years' || interval === 'y') {
      const d = first(firstDayOfNRFYear.clone().subtract(value, 'year'));
      return nrf(d)
        .nrf.add(nrfWeek, 'weeks')
        .day(date.day());
    } else if (interval === 'month' || interval === 'months' || interval === 'm') {
      if (LAST_FIVE_WEEKS.indexOf(nrfWeek) > -1) return date.subtract(5, 'weeks');
      return date.subtract(4, 'weeks');
    } else if (interval === 'quarter' || interval === 'quarters' || interval === 'q') {
      const v = nrfWeek === 52 ? 14 : 13;
      return date.subtract(v, 'weeks');
    }

    return date.add(value, interval);
  };

  date.firstDayOfNRFYear = firstDayOfNRFYear;
  date.isNRF = true;
  date.isNrf = true;
  date.nrf = { startOf, endOf, add, subtract, month, months, week, weeks };

  return date;
}

export default nrf;
