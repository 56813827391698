import * as React from 'react';
import PropTypes from 'prop-types';

function RightChevron({ fillOpacity, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M15 12.37a1.192 1.192 0 01-.35.844l-4.299 4.3a.797.797 0 01-1.127-1.127l3.939-3.938a.11.11 0 00.033-.08.112.112 0 00-.033-.08L9.224 8.351a.797.797 0 011.127-1.127l4.3 4.3a1.192 1.192 0 01.349.845z"
        fill="currentColor"
        fillOpacity={fillOpacity}
      />
    </svg>
  );
}

RightChevron.propTypes = {
  fillOpacity: PropTypes.number,
};

RightChevron.defaultProps = {
  fillOpacity: 1,
};

export default RightChevron;
