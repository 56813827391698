import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { withTheme } from '@emotion/react';

import { Box, Button, Flex, Loader } from '../../mc-ui';
import ArrowRightIcon from '../../mc-ui/icons/ArrowRightIcon';
import JourneyLine from '../../NewJourneys/JourneysList/JourneyLine';
import Pagination from '../../ui/Pagination';

import { fetchGroupJourneys } from '../utils/api';

class Journeys extends React.PureComponent {
  state = {
    journeys: [],
    links: {},
    loading: true,
    meta: {},
  };

  componentDidMount() {
    this.request();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.group.id !== this.props.group.id) {
      this.request();
    }
  }

  paginate = direction => {
    this.request(this.state.links[direction]);
  };

  request = url => {
    const { group, pageSize:size = 3} = this.props;

    this.setState(
      () => ({ loading: true }),
      async () => {
        const { journeys, links, meta } = await fetchGroupJourneys(group.id, size, url);

        this.setState(() => ({
          journeys,
          links,
          loading: false,
          meta,
        }));
      },
    );
  };

  render() {
    const { group, theme } = this.props;
    const { journeys, links, loading, meta } = this.state;

    if (loading) {
      return (
        <Flex alignItems="center" height="552px" justifyContent="center" width="824px">
          <Loader />
        </Flex>
      );
    }

    return (
      <Flex direction="column" height="552px" justifyContent="space-between">
        <Box
          height="532px"
          position="relative"
          css={theme.scrollBox({ height: '484px' }, { width: '99%' })}
        >
          <Flex direction="column">
            {journeys.map(journey => {
              const [brand] = journey.relationships.companies.data;
              return (
                <JourneyLine
                  key={journey.id}
                  journey={journey}
                  pathPrefix={`/companies/${brand.id}/journeys`}
                />
              );
            })}
          </Flex>
        </Box>

        <Flex alignItems="center" justifyContent="space-between">
          <Pagination
            align="left"
            css={{ marginTop: '0', width: 'unset' }}
            from={meta.from}
            nextUrl={links.next}
            onClick={this.paginate}
            prevUrl={links.prev}
            selfUrl={links.self}
            to={meta.to}
            total={meta.total}
          />

          <Button
            as={Link}
            to={`/groups/${group.id}/journeys`}
            css={{
              alignItems: 'center',
              display: 'inline-flex',
              height: '34px',
              justifyContent: 'space-between',
              padding: '0 12px',
              width: '155px',
            }}
          >
            <ArrowRightIcon fillOpacity={1} height="20px" width="20px" /> View all journeys
          </Button>
        </Flex>
      </Flex>
    );
  }
}

Journeys.propTypes = {
  group: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  pageSize: PropTypes.number.isRequired,
  theme: PropTypes.shape({}).isRequired,
};

export default withTheme(Journeys);
