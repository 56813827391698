import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { flashMessageActions } from '../../../modules/flashMessage';

import { Heading, Text } from '../../mc-ui';

import * as S from './styles';

class FlashMessage extends React.Component {
  componentDidMount() {
    const { duration } = this.props;
    this.remaining = duration;
    this.resumeTimer();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.body !== this.props.body ||
      prevProps.duration !== this.props.duration ||
      prevProps.hide !== this.props.hide ||
      prevProps.messageType !== this.props.messageType ||
      prevProps.persistOnHover !== this.props.persistOnHover ||
      prevProps.title !== this.props.title ||
      prevProps.visible !== this.props.visible
    ) {
      this.remaining = this.props.duration;
      this.resumeTimer();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  remaining = 0;
  start = 0;
  timer = null;

  pauseTimer = () => {
    const { persistOnHover } = this.props;

    if (!persistOnHover) return;

    window.clearTimeout(this.timer);
    this.remaining -= new Date() - this.start;
  };

  resumeTimer = () => {
    window.clearTimeout(this.timer);

    this.start = new Date();
    this.timer = window.setTimeout(this.props.hide, this.remaining);
  };

  render() {
    const { body, messageType, title, visible } = this.props;

    if (!visible) return null;

    return (
      <S.Wrapper
        centered={!body || !title}
        messageType={messageType}
        onMouseEnter={this.pauseTimer}
        onMouseLeave={this.resumeTimer}
      >
        <S.Icon messageType={messageType} />

        <div>
          {title && (
            <Heading
              as="h4"
              fontSize="18px"
              lineHeight="18px"
              css={{ marginBottom: body ? '2px' : null }}
            >
              {title}
            </Heading>
          )}
          {body && <Text>{body}</Text>}
        </div>
      </S.Wrapper>
    );
  }
}

FlashMessage.defaultProps = {
  body: null,
  messageType: null,
  title: null,
};

FlashMessage.propTypes = {
  body: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  duration: PropTypes.number.isRequired,
  hide: PropTypes.func.isRequired,
  messageType: PropTypes.string,
  persistOnHover: PropTypes.bool.isRequired,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  visible: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  ...state.flashMessage,
});

const mapDispatchToProps = dispatch => ({
  hide: () => dispatch(flashMessageActions.hide()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FlashMessage);
