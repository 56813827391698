import React from 'react'

const CartAbandoner = props => (
  <svg viewBox='0 0 40 40' width='1em' height='1em' {...props}>
    <g fill='none' fillRule='evenodd'>
      <circle fill='#9E6ACC' cx={20} cy={20} r={20} />
      <g transform='translate(7 12)'>
        <circle fill='#FFF' cx={9.5} cy={17.5} r={1.5} />
        <circle fill='#FFF' cx={19.5} cy={17.5} r={1.5} />
        <path
          stroke='#FFF'
          strokeWidth={1.6}
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M.376.833H4.93l2.444 8.314 1.281 4.433H21.06l3.648-9.447h-3.334M11.5 1.5l6 6M17.5 1.5l-6 6'
        />
      </g>
    </g>
  </svg>
)

export default CartAbandoner
