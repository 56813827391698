import { createSelector } from 'reselect';
import { isEmpty } from 'ramda';

const getState = state => state.groupOverview || {};

export const getData = createSelector(getState, state => state.data || {});
export const getLoading = createSelector(getState, state => !!state.loading);
export const getModel = createSelector(getState, state => state.model || {});

const journeys = createSelector(getState, state => state.journeys || {});
export const getJourneys = createSelector(journeys, j => j.data || []);
export const getJourneysLoading = createSelector(journeys, j => !!j.loading);

// TODO: Uncomment these when the endpoint includes Journeys data
// export const getDataAndInsightsLoading = createSelector(
//   [getLoading, getJourneysLoading],
//   (dataLoading, journeysLoading) => dataLoading || journeysLoading,
// );
// export const isDataAndInsightsEmpty = createSelector(
//   [getData, getJourneys],
//   (data, journeysData) => isEmpty(data) && isEmpty(journeysData),
// );
export const getDataAndInsightsLoading = createSelector([getLoading], dataLoading => dataLoading);
export const isDataAndInsightsEmpty = createSelector([getData], data => isEmpty(data));
