/* eslint-disable import/prefer-default-export */
import api from '../../../lib/api';

export const fetchData = async (modelId, modelType, startDate, endDate, size, chart) => {
  let query = `?start_date=${startDate}&end_date=${endDate}`;

  if (modelType === 'groups' && modelId !== 'premium') {
    query += `&group_id=${modelId}`;
  } else if (modelType === 'brands' || modelType === 'companies') {
    query += `&company_id=${modelId}`;
  }

  if (size) {
    query += `&size=${size}`;
  }

  if (chart) {
    query += `&chart=${chart}`;
  } else {
    query += `&chart=all`;
  }

  const [response, byBrand] = await Promise.all([
    api.get(`/v3/reports/days-hours${query}`),
    // THE BELOW QUERY SHOULD BE CONSIDERED AS A SEPARATE ACTION FOR THE "BY BRANDS" DATA:
    api.get(`/v3/reports/days-hours-by-brand${query}`),
  ]);

  response.data.included = byBrand.data.included;
  response.data.links = byBrand.data.links;
  response.data.meta = {
    ...response.data.meta,
    current: byBrand.data.meta.current,
    from: byBrand.data.meta.from,
    to: byBrand.data.meta.to,
    total: byBrand.data.meta.total,
  };

  return response.data;
};

export const fetchPaginationData = async url => {
  const response = await api.get(url);
  return response.data;
};

export const fetchSorting = async (modelId, modelType, startDate, endDate, size, chart, sort) => {
  let query = `?start_date=${startDate}&end_date=${endDate}`;

  if (modelType === 'groups' && modelId !== 'premium') {
    query += `&group_id=${modelId}`;
  } else if (modelType === 'brands' || modelType === 'companies') {
    query += `&company_id=${modelId}`;
  }

  if (size) {
    query += `&size=${size}`;
  }

  query += `&chart=${chart}`;
  query += `&sort=${sort}`;

  const response = await api.get(`/v3/reports/days-hours-by-brand${query}`);

  return response.data;
};
