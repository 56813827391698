import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { type } from 'ramda';

class ModalLink extends React.Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  handleClick = event => {
    event.preventDefault();
    const returnTo = this.context.router.location;
    const modal = true;
    const modalStyle = this.props.modalStyle || {};
    const wrapperStyle = this.props.wrapperStyle ? this.props.wrapperStyle : { padding: 10 };
    const state = { modal, returnTo, wrapperStyle, modalStyle };
    const to = type(this.props.to) === 'String' ? { pathname: this.props.to } : this.props.to;

    if (this.props.to) {
      return this.context.router.replace({ ...to, state });
    }

    // Check if there is any modal and close it before opening the new one.
    if ((this.context.router.location.state || {}).modal) {
      this.context.router.push(this.context.router.location.state.returnTo);
    }

    const { modalChildren, onClick } = this.props;
    if (onClick) {
      onClick();
    }
    this.props.dispatch({ type: 'SHOW_MODAL', modalChildren, wrapperStyle, modalStyle });
  };

  render() {
    return (
      <Link
        style={{ cursor: 'pointer', ...this.props.style }}
        className={this.props.className}
        onClick={this.handleClick}
      >
        {this.props.children}
      </Link>
    );
  }
}

const s = state => ({});
export default connect(s)(ModalLink);
