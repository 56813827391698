import React from 'react';
import styled from "@emotion/styled";

import ModalLink from '../../ModalLink';
import { Button } from '../../mc-ui';

export const AlertWrapper = styled("div")(({ color = 'yellowMedium', theme }) => ({
  alignItems: 'center',
  border: `1px solid ${theme.colors[color]}`,
  boxSizing: 'border-box',
  borderRadius: '4px',
  display: 'flex',
  padding: '16px',
}));

export const ModalButton = props => (
  <Button
    as={ModalLink}
    variant="secondary"
    css={{ height: '32px', lineHeight: '32px', margin: '0', padding: '0', width: '114px' }}
    {...props}
  />
);
