import api from '../../lib/api';

import { MAX_PER_PAGE } from './constants';

export function fetchBrands(params, url) {
  if (params) {
    return api({
      method: 'get',
      url: '/v3/companies',
      params: {
        ...params,
        size: MAX_PER_PAGE,
      },
    });
  }

  return api(url);
}

export function paginateBrands(url) {
  return fetchBrands(null, url);
}
