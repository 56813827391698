import React from 'react';
import PropTypes from 'prop-types';

import { Box, Button } from '../mc-ui';

import Groups from './Groups';
import CreateGroupForm from './CreateGroupForm';

class AddToGroupButton extends React.Component {
  state = {
    isCreateFormOpen: false,
    isGroupsFormOpen: false,
    owner: 'me',
  };

  componentDidMount() {
    this.interval = setInterval(this.watchWrapperVisibility, 500);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (!prevState.isGroupsFormOpen && this.state.isGroupsFormOpen) ||
      (!prevState.isCreateFormOpen && this.state.isCreateFormOpen)
    ) {
      this.interval = setInterval(this.watchWrapperVisibility, 500);
    }

    if (this.overlayEl && !this.state.isCreateFormOpen && !this.state.isGroupsFormOpen) {
      document.body.removeChild(this.overlayEl);
      this.overlayEl = null;
    }

    if (
      this.props.withOverlay &&
      !this.overlayEl &&
      (this.state.isCreateFormOpen || this.state.isGroupsFormOpen)
    ) {
      this.overlayEl = document.createElement('div');
      this.overlayEl.style.position = 'fixed';
      this.overlayEl.style.bottom = '0';
      this.overlayEl.style.left = '0';
      this.overlayEl.style.right = '0';
      this.overlayEl.style.top = '0';
      this.overlayEl.style.cursor = 'pointer';
      this.overlayEl.style.backgroundColor = 'rgba(0, 0, 0, .33)';
      this.overlayEl.onclick = () => {
        this.closeGroupsForm();
        this.closeCreateForm();
        document.body.removeChild(this.overlayEl);
        this.overlayEl = null;
      };
      document.body.appendChild(this.overlayEl);
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  watchWrapperVisibility = () => {
    if (!this.wrapperEl || (!this.state.isGroupsFormOpen && !this.state.isCreateFormOpen)) return;

    const parent = this.wrapperEl.parentElement;
    const isVisible = parent.offsetWidth > 0 || parent.offsetHeight > 0;

    if (!isVisible) {
      this.setState(() => ({ isCreateFormOpen: false, isGroupsFormOpen: false }));
      clearInterval(this.interval);
      this.interval = null;
    }
  };

  interval = null;
  overlayEl = null;
  wrapperEl = null;

  closeCreateForm = event => {
    if (event) event.preventDefault();
    this.setState(() => ({ isCreateFormOpen: false }));
  };

  openCreateForm = event => {
    if (event) event.preventDefault();
    this.setState(() => ({ isCreateFormOpen: true }));
  };

  closeGroupsForm = event => {
    if (event) event.preventDefault();
    this.setState(() => ({ isGroupsFormOpen: false }));
  };

  openGroupsForm = event => {
    if (event) event.preventDefault();
    this.setState(() => ({ isGroupsFormOpen: true }));
  };

  updateOwner = owner => {
    this.setState(() => ({ owner }));
  };

  render() {
    const { brands, children, css, disabled, onClose, variant } = this.props;
    const { isCreateFormOpen, isGroupsFormOpen, owner } = this.state;

    return (
      <Box
        position="relative"
        width="112px"
        inline
        ref={parentRef => {
          this.wrapperEl = parentRef;
        }}
        css={css}
      >
        <Button
          disabled={disabled}
          onClick={this.openGroupsForm}
          variant={variant}
          css={{
            alignItems: 'center',
            display: 'flex',
            height: '32px',
            justifyContent: 'center',
            lineHeight: '32px',
            margin: '0',
            padding: '0',
            width: '112px',
          }}
        >
          {children || 'Add to Group'}
        </Button>

        {isGroupsFormOpen && (
          <Groups
            brands={brands}
            groupsOwner={owner}
            onClose={onClose}
            onCloseGroupsForm={this.closeGroupsForm}
            onOpenCreateForm={this.openCreateForm}
            onUpdateOwner={this.updateOwner}
            parentEl={this.wrapperEl}
          />
        )}

        {isCreateFormOpen && (
          <CreateGroupForm
            brands={brands}
            onCloseCreateForm={this.closeCreateForm}
            onOpenGroupsForm={this.openGroupsForm}
            owner={owner}
            parentEl={this.wrapperEl}
          />
        )}
      </Box>
    );
  }
}

AddToGroupButton.defaultProps = {
  children: null,
  css: {},
  disabled: false,
  onClose: () => {},
  variant: 'primary',
  withOverlay: false,
};

AddToGroupButton.propTypes = {
  brands: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  children: PropTypes.node,
  css: PropTypes.shape({}),
  disabled: PropTypes.bool,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['primary', 'secondary', 'none']),
  withOverlay: PropTypes.bool,
};

export default AddToGroupButton;
