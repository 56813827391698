import api from '../../lib/api';

export const fetch = async query => {
  const response = await api.get('/v3/text-messages', { params: query });
  return response;
};

export const paginate = async url => {
  const response = await api.get(url);
  return response;
};
