import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'ramda';

import { shallowEqual } from '../../../../lib/utils';
import { brandOverviewActions, brandOverviewSelectors } from '../../../../modules/brandOverview';
import { groupOverviewActions, groupOverviewSelectors } from '../../../../modules/groupOverview';

import { Flex, Heading, Loader } from '../../../mc-ui';
import BrandHighlights from '../../../BrandOverview/DataAndInsights/WeeklyRecap/Highlights';
import GroupHighlights from '../../../GroupOverview/DataAndInsights/WeeklyRecap/Highlights';

import ReportSection from '../../ReportSection';
import Body from '../../Body';

class ReportHighlights extends React.PureComponent {
  componentDidMount() {
    this.props.request();
  }

  componentDidUpdate(prevProps) {
    const prevModel = { id: prevProps.model.id, type: prevProps.model.type };
    const model = { id: this.props.model.id, type: this.props.model.type };

    if (!shallowEqual(prevModel, model)) {
      this.props.request();
    }
  }

  render() {
    const { data, loading, model } = this.props;
    const isLoading = loading || isEmpty(data);

    const Highlights = model.type === 'companies' ? BrandHighlights : GroupHighlights;

    return (
      <ReportSection css={{ marginBottom: '8px' }}>
        <Body>
          <Heading
            as="h4"
            color="dark08"
            fontSize="18px"
            lineHeight="24px"
            css={{ marginBottom: '20px' }}
          >
            Report Highlights
          </Heading>

          {isLoading ? (
            <Flex alignItems="center" justifyContent="center" height="195px">
              <Loader />
            </Flex>
          ) : (
            <Highlights data={data} {...this.props} />
          )}
        </Body>
      </ReportSection>
    );
  }
}

ReportHighlights.defaultProps = {
  model: { id: null },
};

ReportHighlights.propTypes = {
  data: PropTypes.shape({}).isRequired,
  loading: PropTypes.bool.isRequired,
  model: PropTypes.shape({ id: PropTypes.string, type: PropTypes.string }),
  request: PropTypes.func.isRequired,
};

const mapStateToProps = (state, { model }) => {
  const selectors = model.type === 'companies' ? brandOverviewSelectors : groupOverviewSelectors;

  return {
    data: selectors.getData(state),
    loading: selectors.getLoading(state),
  };
};

const mapDispatchToProps = (dispatch, { model }) => {
  const actions = model.type === 'companies' ? brandOverviewActions : groupOverviewActions;

  return {
    request: () => dispatch(actions.fetchDataRequest(model || {})),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReportHighlights);
