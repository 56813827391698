import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { test } from 'ramda';

import { COMPARE_QUERY_KEY } from './index';

class ComparisonSelect extends Component {
  state = {
    selectedOption: '',
    previousPeriod: {},
    primaryIndustry: {},
  };

  componentDidMount() {
    this.getPreviousPeriod(this.props);
    this.getPrimaryIndustry();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.currentPeriod !== this.props.currentPeriod) {
      this.getPreviousPeriod(nextProps);
    }
  }

  getCurrentInterval = (props) => {
    const { currentPeriod, user } = props;

    if (currentPeriod === 'custom') {
      return null;
    }

    return user.meta.intervals.find(i => i.value === currentPeriod);
  };

  getPreviousPeriod = (props) => {
    const interval = this.getCurrentInterval(props);

    if (!interval) {
      return;
    }

    const endDate = moment(interval.end_date);
    const startDate = moment(interval.start_date);
    const diff = endDate.diff(startDate, 'days');

    const previousEndDate = moment(interval.end_date);
    const previousStartDate = moment(interval.start_date);
    previousEndDate.subtract(diff, 'days');
    previousStartDate.subtract(diff, 'days');

    const previousPeriod = {
      endDate: previousEndDate,
      startDate: previousStartDate,
    };

    this.setState({ previousPeriod });
  };

  getPrimaryIndustry = () => {
    const { model, user } = this.props;

    if (model.type !== 'companies') {
      return;
    }

    const group = user.included.filter(i => i.type == 'groups').find(g => test(/^tracked/i, g.attributes.name));

    if (group) {
      this.setState({
        primaryIndustry: {
          id: group.id,
          name: group.attributes.name,
        },
      });
    }
  };

  handleChange = (event) => {
    event.preventDefault();

    const selectedOption = event.target.value;
    const { previousPeriod, primaryIndustry } = this.state;
    const {
      model, setQuery, toggleComparisonMode, user,
    } = this.props;

    if (selectedOption === 'previous_period') {
      setQuery(
        {
          end_date: previousPeriod.endDate.format('YYYY-MM-DD'),
          start_date: previousPeriod.startDate.format('YYYY-MM-DD'),
        },
        COMPARE_QUERY_KEY,
        true,
      );
    } else if (selectedOption === 'primary_industry') {
      setQuery({ group_id: primaryIndustry.id }, COMPARE_QUERY_KEY, true);
    } else if (selectedOption === 'custom') {
      const query = {};
      if (model.type === 'companies') {
        query.company_id = model.id;
      } else if (model.type === 'groups') {
        query.group_id = model.id;
      }

      const interval =
        this.getCurrentInterval(this.props) || user.meta.intervals.find(i => i.value === '90_days');
      query.end_date = interval.end_date;
      query.start_date = interval.start_date;

      setQuery(query, COMPARE_QUERY_KEY, true);
    }

    this.setState({ selectedOption: '' }, toggleComparisonMode);
  };

  render() {
    const { currentPeriod, model } = this.props;
    const { selectedOption, previousPeriod, primaryIndustry } = this.state;

    return (
      <form className="pure-form">
        <select
          onChange={this.handleChange}
          value={selectedOption}
          style={{
            fontSize: `${13 / 16}em`,
            marginRight: '16px',
            maxWidth: '155px',
            paddingRight: '2em',
          }}
        >
          <option value="" disabled>
            + Comparison
          </option>
          {currentPeriod !== 'custom' && <option value="previous_period">Previous period</option>}
          {model.type === 'companies' && primaryIndustry.name && (
            <option value="primary_industry">{primaryIndustry.name}</option>
          )}
          <option value="custom">Custom</option>
        </select>
      </form>
    );
  }
}

ComparisonSelect.contextTypes = {
  router: PropTypes.object,
};

ComparisonSelect.propTypes = {
  currentPeriod: PropTypes.string.isRequired,
  model: PropTypes.shape({}).isRequired,
  setQuery: PropTypes.func.isRequired,
  toggleComparisonMode: PropTypes.func.isRequired,
  user: PropTypes.shape({}).isRequired, // eslint-disable-line
};

const mapStateToProps = state => ({
  user: state.user,
});

export default connect(mapStateToProps)(ComparisonSelect);
