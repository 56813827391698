import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Form, Field } from 'react-final-form';

import { Box, Select } from '../../mc-ui';
import RestrictedTooltip from '../../RestrictedTooltip';

const generateOptions = () => {
  const result = [];
  let date = moment('2018-01-01'); // eslint-disable-line prefer-const
  let done = false;

  while (!done) {
    result.push({
      label: date.format('MMMM YYYY'),
      value: date.startOf('month').format('YYYY-MM-DD'),
    });
    done = date.format('YYYY-MM') === moment().format('YYYY-MM');
    date.add(1, 'M');
  }

  return result;
};

const options = generateOptions();

const DateSelectForm = ({ onChange, restricted, selectedDate }) => (
  <RestrictedTooltip restricted={restricted}>
    <Form onSubmit={() => {}} initialValues={{ date: selectedDate }}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Box css={{ width: '240px' }}>
            <Field
              component={Select}
              id="date"
              isDisabled={restricted}
              name="date"
              options={options}
              onChange={onChange}
            />
          </Box>
        </form>
      )}
    </Form>
  </RestrictedTooltip>
);

DateSelectForm.defaultProps = {
  restricted: false,
};

DateSelectForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  restricted: PropTypes.bool,
  selectedDate: PropTypes.string.isRequired,
};

export default DateSelectForm;
