import React from 'react';
import PropTypes from 'prop-types';
import humanize from 'humanize-number';
import pluralize from 'pluralize';
import { Link as RRLink } from 'react-router';
import { camelizeKeys } from 'humps';
import { connect } from 'react-redux';
import { isEmpty } from 'ramda';

import api from '../../../lib/api';

import EmailThumbnail from '../../ui/EmailThumbnail';
import ListCard from '../../ui/ListCard';
import { Box, Button, Flex, Grid, Heading, Link, Text } from '../../mc-ui';

// eslint-disable-next-line react/prop-types
function HeaderCTA({ emails, brand, router, total }) {
  const { query } = router.location;
  delete query.advanced;

  return (
    <Box marginBottom="40px">
      <Flex alignItems="flex-start" marginBottom="40px">
        {isEmpty(query) ? (
          <Box flex="1" marginRight="24px" width="unset">
            <Heading as="h3" fontSize="18px" lineHeight="1.333.em" css={{ marginBottom: '8px' }}>
              Try Filters or upgrade to view all {humanize(total)} {brand.attributes.name}{' '}
              {pluralize('email', total)}.
            </Heading>

            <Text>
              Looking for something specific? You can check if we have data that you need! Click the
              blue “Filter” button above, apply all the relevant filters and confirm that we have
              data that is important to your team.
            </Text>
          </Box>
        ) : (
          <Box flex="1" marginRight="24px" width="unset">
            <Heading as="h3" fontSize="18px" lineHeight="1.333.em" css={{ marginBottom: '8px' }}>
              We have {humanize(total)} {pluralize('email', total)} that match your search! Upgrade
              to view them.
            </Heading>

            <Text>
              Access your search results and all {brand.attributes.name} emails and insights.
            </Text>
          </Box>
        )}

        <Button
          as={RRLink}
          to="/manage/plan"
          variant="purple"
          css={{
            alignItems: 'center',
            display: 'inline-flex',
            height: '40px',
            justifyContent: 'center',
            padding: '0',
            width: '130px',
          }}
        >
          Upgrade Now
        </Button>
      </Flex>

      <Grid columnGap="8px" gridTemplateColumns="repeat(16, 1fr)" rowGap="4px">
        {emails.map(email => (
          <EmailThumbnail
            key={`restrictedPreviewEmail-${email.id}`}
            brand={{
              id: email.attributes.company_id,
              attributes: { name: email.attributes.company_name },
            }}
            email={email}
            skipLink
            tiny
          />
        ))}
      </Grid>
    </Box>
  );
}

class BrandEmailsRestricted extends React.Component {
  state = {
    lists: [],
  };

  componentDidMount() {
    this.mounted = true;
    this.requestLists();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  mounted = null;

  requestLists = async () => {
    if (this.mounted) {
      const responses = await Promise.all([
        api.get('/v3/lists/671'), // Cart abandonment
        api.get('/v3/lists/2656'), // Product promotion
        api.get('/v3/lists/684'), // Welcome
      ]);

      const lists = responses.map(response => response.data.data[0]);
      this.setState(() => ({ lists: camelizeKeys(lists) }));
    }
  };

  render() {
    const { emails, brand, searches } = this.props;
    const { lists } = this.state;
    const { router } = this.context;

    if (searches.loading) return null;

    const { total } = searches.meta || { total: 0 };

    return (
      <Box backgroundColor="white" borderRadius="4px" padding="32px">
        {total === 0 ? (
          <Box marginBottom="40px">
            <Heading as="h3" fontSize="18px" lineHeight="1.333.em" css={{ marginBottom: '8px' }}>
              Sorry! No results found.
            </Heading>

            <Text>
              There were no matches for your search results. Try updating the keywords or other
              filters to confirm if we have data that you need before you upgrade. Not ready to
              upgrade? Use our <Link to="/emails">curated email examples</Link> for inspiration and
              planning.
            </Text>
          </Box>
        ) : (
          <HeaderCTA emails={emails} brand={brand} router={router} total={total} />
        )}

        <Text color="dark08" css={{ marginBottom: '16px' }}>
          Or, browse thousands of hand-picked emails in our <strong>FREE</strong> curated email
          lists:
        </Text>
        <Flex justifyContent="space-between" margin="15px 0 24px 0">
          {lists.map(list => (
            <Box key={list.id} width="unset">
              <ListCard list={list} />
            </Box>
          ))}
        </Flex>

        <Button
          as={RRLink}
          to="/emails"
          variant="primary"
          css={{
            fontSize: '12px',
            fontWeight: '500',
            height: '23px',
            letterSpacing: '0.02em',
            lineHeight: '23px',
            padding: '0',
            width: '83px',
          }}
        >
          See all lists
        </Button>
      </Box>
    );
  }
}

BrandEmailsRestricted.contextTypes = {
  router: PropTypes.object,
};

BrandEmailsRestricted.propTypes = {
  brand: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
  emails: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      attributes: PropTypes.shape({
        company_id: PropTypes.number,
        company_name: PropTypes.string,
      }),
    }),
  ).isRequired,
  searches: PropTypes.shape({
    loading: PropTypes.bool,
    meta: PropTypes.shape({
      total: PropTypes.number,
    }),
  }).isRequired,
};

const mapStateToProps = (state, { brand }) => {
  if (!brand || !brand.id) {
    return {
      emails: [],
      searches: { loading: true },
    };
  }

  return {
    emails: state.emails.data,
    searches: state.searches,
  };
};

export default connect(mapStateToProps)(BrandEmailsRestricted);
