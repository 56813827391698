import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { connect } from 'react-redux';
import { isEmpty } from 'ramda';

import { journeysSelectors as selectors } from '../../modules/journeys';

import { Flex, Box, Loader } from '../mc-ui';
import BetweenEmailsIcon from '../mc-ui/icons/BetweenEmailsIcon';
import CalendarIcon from '../mc-ui/icons/CalendarIcon';
import EmailIcon from '../mc-ui/icons/EmailIcon';

import Stats from '../newReports/Stats';

// eslint-disable-next-line react/prop-types
const CustomIcon = ({ icon: Icon }) => (
  <Box color="dark06" height="24px" marginRight="10px" width="24px" css={{ fontSize: '24px' }}>
    <Icon fillOpacity={1} />
  </Box>
);

const Meta = ({ data, loading, meta }) => {
  if (loading) {
    return (
      <Flex alignItems="center" justifyContent="center" height="79px">
        <Loader />
      </Flex>
    );
  }

  if (!loading && isEmpty(data)) return null;

  return (
    <Box marginBottom="6px" paddingLeft="32px" width="580px">
      <Stats justifyContent="space-between">
        <Stats.Item
          title="AVG Journey Duration"
          icon={<CustomIcon icon={CalendarIcon} />}
          value={`${meta.durationDays.avg} ${pluralize('day', meta.durationDays.avg)}`}
          width="auto"
        />
        <Stats.Item
          title="AVG Time Between Emails"
          icon={<CustomIcon icon={BetweenEmailsIcon} />}
          value={`${meta.intervalDays.avg} ${pluralize('day', meta.intervalDays.avg)}`}
          width="auto"
        />
        <Stats.Item
          title="AVG Emails / Journey"
          icon={<CustomIcon icon={EmailIcon} />}
          value={`${meta.triggeredEmails.avg} ${pluralize('email', meta.triggeredEmails.avg)}`}
          width="auto"
        />
      </Stats>
    </Box>
  );
};

Meta.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loading: PropTypes.bool.isRequired,
  meta: PropTypes.shape({
    durationDays: PropTypes.shape({ avg: PropTypes.number }),
    intervalDays: PropTypes.shape({ avg: PropTypes.number }),
    totalEmails: PropTypes.shape({ avg: PropTypes.number }),
  }).isRequired,
};

const mapStateToProps = (state, { location, model }) => ({
  data: selectors.getData(model, location.query)(state),
  meta: selectors.getMeta(model, location.query)(state),
});

export default connect(mapStateToProps)(Meta);
