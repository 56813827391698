import React from 'react';
import PropTypes from 'prop-types';
import md5 from 'md5';
import { connect } from 'react-redux';

import { Box, Flex, Heading, Text } from './mc-ui';
import AccountStatusBadge from './ui/AccountStatusBadge';

import Header from './Header';
import Section from './Section';
import ManageMenu from './menus/ManageMenu';
import ProPlanWillBeCanceledNotice from './ui/ProPlanWillBeCanceledNotice';

class Manage extends React.Component {
  componentWillUnmount() {
    // If leaving a modal this.unmount will be false.
    // This is necessary so we don't lose the previous page state when modal is
    // closed.
    if (this.unmount) {
      this.props.flush();
    }
  }

  render() {
    const { router } = this.context;
    const { modal } = router.location.state || {};

    if (modal) {
      this.unmount = false;
      return this.props.children;
    }
    this.unmount = true;

    const user = this.props.user.data.reduce((m, u) => u, {});
    return this.props.user.loading ? null : (
      <div className="pure-u-1">
        <Header>
          <div className="pure-u-1" style={{ margin: '1.5em 0' }}>
            <div className="pure-u-1-4 pure-u-md-1-12">
              <img
                src={`//secure.gravatar.com/avatar/${md5(user.attributes.email)}?d=mm&r=x&s=128`}
                title={`${user.attributes.name} (${user.attributes.email})`}
                style={{ width: '80%', borderRadius: '50%' }}
                alt={user.attributes.name}
              />
            </div>

            <div className="pure-u-3-4 pure-u-md-11-12">
              <Flex alignItems="center" marginBottom="4px">
                <Heading
                  as="h1"
                  fontSize="24px"
                  fontWeight="300"
                  lineHeight={`${32 / 24}em`}
                  css={{ marginRight: '8px', whiteSpace: 'nowrap' }}
                >
                  {user.attributes.name}
                </Heading>

                <AccountStatusBadge />

                <Box marginLeft="8px">
                  <ProPlanWillBeCanceledNotice />
                </Box>
              </Flex>

              <Text color="dark08">{user.attributes.email}</Text>
            </div>
          </div>
        </Header>

        <Section>
          <Flex justifyContent="space-between">
            <Flex css={{ width: '200px', marginTop: '1em' }}>
              <ManageMenu {...this.props} />
            </Flex>

            <Flex css={{ width: '834px' }}>{this.props.children}</Flex>
          </Flex>
        </Section>
      </div>
    );
  }
}

Manage.contextTypes = {
  router: PropTypes.object,
};

Manage.propTypes = {
  children: PropTypes.node.isRequired,
  flush: PropTypes.func.isRequired,
  user: PropTypes.shape({
    data: PropTypes.array,
    loading: PropTypes.bool,
  }).isRequired,
};

const mapStateToProps = state => ({
  user: state.user,
});
const mapDispatchToProps = dispatch => ({
  flush: () => dispatch({ type: 'FLUSH' }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Manage);
