import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { benchmarksSelectors as selectors } from '../../../modules/newReports/benchmarks';

import { Flex } from '../../mc-ui';
import EmailIcon from '../../mc-ui/icons/EmailIcon';

import ReportSection from '../ReportSection';
import Title from '../Title';

import BarsChart from './BarsChart';
import SectionInformation from './SectionInformation';

const EmailsSent = ({ data, endDate, loading, router, startDate, ...props }) => (
  <Flex>
    <ReportSection css={{ minHeight: '285px' }}>
      <ReportSection.Header>
        <Title>Emails Sent</Title>
      </ReportSection.Header>
      <ReportSection.Body>
        <Flex justifyContent="space-between">
          <SectionInformation
            content="Send volume tracks the total number of emails sent in the selected time period. Gain insight into how active this group is compared to other industries and brands."
            icon={EmailIcon}
            title="Sending Behavior Report"
            linkTo={{
              pathname: router.location.pathname.replace('benchmarks', 'sending-behavior'),
              query: { end_date: endDate, start_date: startDate },
            }}
          />

          <BarsChart append="/wk" data={data.frequency} loading={loading} {...props} />
        </Flex>
      </ReportSection.Body>
    </ReportSection>
  </Flex>
);

EmailsSent.defaultProps = {
  endDate: null,
  startDate: null,
};

EmailsSent.propTypes = {
  data: PropTypes.shape({
    frequency: PropTypes.shape({}),
  }).isRequired,
  endDate: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  router: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }).isRequired,
  startDate: PropTypes.string,
};

const mapStateToProps = (state, { model, brandId }) => {
  if (brandId && model.id && model.type === 'groups') {
    return {
      data: selectors.getGroupComparisonData(model.id, brandId)(state),
      loading: selectors.getGroupComparisonLoading(model.id, brandId)(state),
    };
  }

  if (model.id && model.type === 'companies') {
    return {
      data: selectors.getBrandComparisonData(model.id)(state),
      loading: selectors.getBrandComparisonLoading(model.id)(state),
    };
  }

  return {
    data: { frequency: {} },
    loading: true,
  };
};

export default connect(mapStateToProps)(EmailsSent);
