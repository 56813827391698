import styled from "@emotion/styled";

export const Wrapper = styled("div")({
  backgroundImage: 'url(/images/text-message-thumbnail/background.svg)',
  backgroundRepeat: 'no-repeat',
  display: 'flex',
  flexDirection: 'column',
  height: '300px',
  justifyContent: 'space-between',
  position: 'relative',
  width: '191px',
  '&:hover::before': {
    backgroundImage: 'url(/images/text-message-thumbnail/background-hover.svg)',
    backgroundRepeat: 'no-repeat',
    bottom: '0',
    content: '""',
    display: 'block',
    left: '0',
    position: 'absolute',
    right: '0',
    top: '0',
    transform: 'translateY(2px)',
    zIndex: '1',
  },
});

export const SelectWrapper = styled("div")(({ selected }) => ({
  backgroundColor: 'transparent',
  cursor: 'pointer',
  height: '300px',
  position: 'relative',
  width: '191px',
  '&::before': selected
    ? {
        backgroundImage: 'url(/images/text-message-thumbnail/background-selected.svg)',
        backgroundRepeat: 'no-repeat',
        bottom: '0',
        content: '""',
        display: 'block',
        left: '0',
        position: 'absolute',
        right: '0',
        top: '0',
        transform: 'translate(6px, 4px)',
        zIndex: '1',
      }
    : {
        backgroundColor: 'white',
        borderRadius: '16px',
        bottom: '54px',
        content: '""',
        display: 'block',
        left: '14px',
        position: 'absolute',
        opacity: '0.5',
        right: '4px',
        top: '3px',
      },
}));
