import React from 'react'

const DEFAULT_STATE = {
  guid: '',
  loading: true,
  style: {
    width: '100%',
    height: '75vh',
    backgroundImage: `url(/loading.png)`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundColor: `#ffffff`,
  }
}

export default class extends React.Component {
  state = { ...DEFAULT_STATE }
  handleLoad = (event) => {
    setTimeout(() => {
      this.setState({ loading: false })
    }, 250)
  }
  UNSAFE_componentWillReceiveProps(props) {
    if (props.guid !== this.props.guid)
      this.setState({ loading: true })
  }
  render () {
    let size = this.props.size || '300x400'
    const loading = this.state.loading || this.props.loading
    let img = <img
      onLoad={this.handleLoad}
      onClick={this.props.handleClick}
      style={{
        display: loading ? 'none' : 'block',
        margin: '0 auto',
        boxShadow: this.props.boxShadow ? this.props.boxShadow : '0px 4px 8px 0px rgba(36, 36, 36, .3)'
      }}
      className='pure-img'
      src={`//assets.mailcharts.com/emails/${this.props.guid}/${size}.png`} />
      const style = loading ? { ...DEFAULT_STATE.style } : {}
      return (
        <div style={{ ...style, padding: '0 5px', }} >
          {img}
        </div>
      )
  }
}
