import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import styled from "@emotion/styled";

import ProgressIndicator from './ui/ProgressIndicator';

const MAX_DAYS = 30;

const JourneyProgressIndicator = ({ journey }) => {
  if (!journey) return null;

  const startDate = moment(journey.attributes.first_activity_at).startOf('day')
  const now = moment().endOf('day')
  const daysElapsed = now.diff(startDate, 'days') + 1 // no such thing as "zero" days
  const progressPercent = (daysElapsed / MAX_DAYS) * 100;

  if (progressPercent >= 100) return null;
  if (progressPercent < 0) return null;

  const ProgressIndicatorWrapper = styled("div")({
    maxWidth: 400,
    marginBottom: 20,
  });

  return (
    <ProgressIndicatorWrapper className="pure-u-1">
      <ProgressIndicator progressPercent={progressPercent} label={`Collecting in progress. ${daysElapsed}/${MAX_DAYS} days`} />
    </ProgressIndicatorWrapper>
  );
};

JourneyProgressIndicator.propTypes = {
  journey: PropTypes.shape({
    attributes: PropTypes.shape({
      first_activity_at: PropTypes.string,
    }),
  }),
};

JourneyProgressIndicator.defaultProps = {
  journey: null,
};

export default JourneyProgressIndicator;
