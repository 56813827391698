import styled from "@emotion/styled";

const ListButton = styled("div")({
  border: '1px solid #d4d9dd',
  borderRadius: '3px',
  boxSizing: 'border-box',
  cursor: 'pointer',
  height: '32px',
  position: 'relative',
  width: '32px',
  '&::after, &::before': {
    backgroundColor: '#a0afba',
    content: '""',
    display: 'block',
    height: '2px',
    left: '50%',
    marginLeft: '-6px',
    marginTop: '-1px',
    position: 'absolute',
    top: '50%',
    width: '12px'
  },
  '&::after': {
    transform: 'rotate(90deg)'
  }
})

export default ListButton
