import styled from "@emotion/styled";

const Wrapper = styled("div")(({ css = {}, theme }) => ({
  alignItems: 'center',
  border: `1px solid ${theme.colors.dark5}`,
  borderRadius: 4,
  boxSizing: 'border-box',
  color: theme.colors.dark08,
  display: 'flex',
  height: '32px',
  justifyContent: 'center',
  position: 'relative',
  ...css,
  '& > label': {
    background: theme.colors.white,
    color: theme.colors.dark4,
    fontSize: '10px',
    left: '10px',
    lineHeight: '10px',
    padding: '2px 4px',
    position: 'absolute',
    top: '-8px',
  },
  '& > svg': {
    height: '20px',
    marginLeft: '12px',
    width: '20px',
  },
  '& *': {
    boxSizing: 'border-box',
    fontFamily: theme.font.family,
  },
  '& .DateRangePicker > div': {
    position: 'relative',
    '& .DateInput_fang': {
      top: '32px !important',
    },
    '& .DateRangePicker_picker': {
      top: '42px !important',
      '& *': {
        outline: 0,
      },
    },
  },
  '& .DateRangePickerInput': {
    alignItems: 'center',
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '0 1px',
    '& .DateInput': {
      width: '100px',
      '& .DateInput_input': {
        border: 'none',
        color: theme.colors.dark08,
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: `${24 / 14}em`,
        margin: 0,
        outline: 0,
        padding: `${3.01 / 14}em ${12 / 14}em`,
        width: '100%',
        '&::placeholder': {
          color: theme.colors.dark04,
        },
      },
      '& .DateInput_input__focused': {
        border: 'none',
        color: theme.colors.dark1,
      },
    },
    '& .DateRangePickerInput_arrow': {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
    },
  },
  '& .DayPickerNavigation_button__horizontal': {
    border: `1px solid ${theme.colors.dark5}`,
    borderRadius: 4,
    color: theme.colors.dark08,
    padding: '7px',
  },
  '& .CalendarMonth_caption': {
    color: theme.colors.dark08,
    '& > strong': {
      fontWeight: '500',
    },
  },
  '& .DayPicker_weekHeader_li': {
    color: theme.colors.dark08,
    fontWeight: '500',
  },
  '& .CalendarDay__default': {
    border: `1px solid ${theme.colors.dark5}`,
    color: theme.colors.dark08,
    '&:hover': {
      backgroundColor: theme.colors.dark004,
    },
  },
  '& .CalendarDay__selected': {
    backgroundColor: theme.colors.blue,
    color: theme.colors.white,
    '&:active, &:hover': {
      backgroundColor: theme.colors.blue,
    },
  },
  '& .CalendarDay__hovered_span, & .CalendarDay__selected_span': {
    backgroundColor: theme.colors.blueLight,
    color: theme.colors.blueDark,
    '&:active, &:hover': {
      backgroundColor: theme.colors.blueLight,
    },
  },
  '& .DateRangePickerInput__disabled': {
    background: theme.colors.white,
    cursor: 'not-allowed',
    '& .DateInput_input__disabled': {
      background: theme.colors.white,
      color: `${theme.colors.dark04} !important`,
      cursor: 'not-allowed',
      fontStyle: 'normal',
    },
  },
}));

export default Wrapper;
