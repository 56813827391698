/** @jsx jsx */
import { jsx } from '@emotion/react';
import React from 'react';
import PropTypes from 'prop-types';
import { Link as RRLink } from 'react-router';
import { connect } from 'react-redux';
import { findIndex, isEmpty, splitEvery } from 'ramda';

import { Box, Button, Flex, theme } from '../../mc-ui';
import ArrowLeftIcon from '../../mc-ui/icons/ArrowLeftIcon';
import ArrowRightIcon from '../../mc-ui/icons/ArrowRightIcon';

const buttonStyles = {
  alignItems: 'center',
  border: 'none',
  borderRadius: '50%',
  color: theme.colors.dark1,
  display: 'inline-flex',
  fontSize: '20px',
  height: '32px',
  justifyContent: 'center',
  lineHeight: '32px',
  padding: 0,
  width: '32px',
  '&:hover': {
    border: 'none',
    boxShadow: `0px 1px 4px ${theme.colors.dark02}`,
  },
};

const emailImageURL = email =>
  `//assets.mailcharts.com/emails/${email.attributes.guid}/${'300x400'}.png`;

const MAX_EMAILS = 8;

class ThumbnailsNav extends React.Component {
  state = {
    emailsCollections: [[]],
    index: 0,
  };

  UNSAFE_componentWillReceiveProps(props) {
    const { emails, guid } = props;

    if (emails.loading) return;

    let index = findIndex(e => e.attributes.guid === guid, emails.data);
    if (index < 0) index = 0;

    this.setState({
      emailsCollections: this.splitEmails(emails),
      index: Math.floor(index / MAX_EMAILS),
    });
  }

  splitEmails = emails => {
    if (isEmpty(emails.data)) return [[]];
    return splitEvery(MAX_EMAILS, emails.data);
  };

  handleEmailClick = guid => event => {
    event.preventDefault();

    const { requestEmail, router } = this.props;

    requestEmail(guid);

    router.push({ pathname: `/emails/${guid}`, query: router.location.query });
  };

  handleNext = event => {
    event.preventDefault();

    const { emailsCollections, index } = this.state;

    if (index + 1 < emailsCollections.length) {
      this.setState({ index: index + 1 });
    }
  };

  handlePrev = event => {
    event.preventDefault();

    const { index } = this.state;

    if (index > 0) {
      this.setState({ index: index - 1 });
    }
  };

  render() {
    if (this.props.emails.loading && isEmpty(this.props.emails.data)) {
      return null;
    }

    if (this.props.emails.data.length <= 1) return null;

    const { guid, router } = this.props;

    const { emailsCollections, index } = this.state;

    return (
      <Flex alignItems="center" width="720px"
        style={{
          '@media screen and (max-width: 1280px)': {
            marginRight: "10px",
            width: "700px"
          }
        }}
      >
        <Button
          css={buttonStyles}
          disabled={index === 0 ? 'disabled' : null}
          onClick={this.handlePrev}
          variant="secondary"
        >
          <ArrowLeftIcon fillOpacity={1} />
        </Button>
        <Flex marginLeft="16px" width="640px"
          style={{
            '@media screen and (max-width: 1280px)': {
              marginLeft: "10px"
            }
          }}
        >
          {emailsCollections[index].map(email => (
            <Box
              key={`EmailTopNavigation-Thumbnails-Email${email.attributes.guid}`}
              height="64px"
              marginRight="16px"
              width="64px"
              style={{
                '@media screen and (max-width: 1280px)': {
                  marginRight: "10px"
                }
              }}
            >
              <RRLink
                css={{
                  display: 'block',
                  height: '64px',
                  width: '64px',
                }}
                onClick={this.handleEmailClick(email.attributes.guid)}
                title={email.attributes.subject}
                to={{ pathname: `/emails/${email.attributes.guid}`, query: router.location.query }}
              >
                <img
                  src={emailImageURL(email)}
                  alt={email.attributes.subject}
                  style={{ display: 'none' }}
                />

                <Box
                  backgroundColor="white"
                  border={email.attributes.guid === guid ? '4px solid' : '1px solid'}
                  borderColor={email.attributes.guid === guid ? 'blue' : 'dark5'}
                  borderRadius="4px"
                  height="64px"
                  width="64px"
                  css={{
                    backgroundImage: `url(${emailImageURL(email)})`,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                  }}
                />
              </RRLink>
            </Box>
          ))}
        </Flex>

        <Button
          css={buttonStyles}
          disabled={index + 1 === emailsCollections.length}
          onClick={this.handleNext}
          variant="secondary"
        >
          <ArrowRightIcon fillOpacity={1} />
        </Button>
      </Flex>
    );
  }
}

ThumbnailsNav.propTypes = {
  emails: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        attributes: PropTypes.shape({
          guid: PropTypes.string,
          subject: PropTypes.string,
        }),
      }),
    ),
    loading: PropTypes.bool,
  }).isRequired,
  guid: PropTypes.string.isRequired,
  requestEmail: PropTypes.func.isRequired,
  router: PropTypes.shape({
    location: PropTypes.shape({
      query: PropTypes.object,
    }),
    push: PropTypes.func,
  }).isRequired,
};

const mapStateToProps = state => ({
  emails: state.emails,
});

const mapDispatchToProps = dispatch => ({
  requestEmail: (guid, params = {}) => dispatch({ type: 'REQUEST_EMAIL', guid, params }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ThumbnailsNav);
