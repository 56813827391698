import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { userSelectors } from '../../../modules/user';
import * as S from '../styles';

import Brands from './Brands';
import Collections from './Collections';
import Emails from './Emails';
import Home from './Home';
import Journeys from './Journeys';
import SMS from './SMS';
import Unlocked from './Unlocked';

function NavigationItems({isUserRestricted}) {

  return (
    <S.NavigationItemsWrapper>
      <Home />
      <Brands />
      <Emails />
      <Journeys />
      <SMS />
      { isUserRestricted && (<Unlocked />)}
      <Collections />
    </S.NavigationItemsWrapper>
  );
}

NavigationItems.defaultProps = {
  isUserRestricted: true
};

NavigationItems.propTypes = {
  isUserRestricted: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  isUserRestricted: userSelectors.isUserRestrictedSelector(state),
});

export default connect(mapStateToProps)(NavigationItems);
