import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const LoadMore = ({ requestTechnologiesPagination }) => (
  <div className="pure-u-1" style={{ textAlign: 'center' }}>
    <p>Scroll to load more technologies.</p>
    <p style={{ margin: '0 0 24px 0', fontSize: `${12 / 16}em` }}>
      If you’re having trouble loading more technologies,{' '}
      <a href="#paginate" onClick={requestTechnologiesPagination}>
        click here
      </a>.
    </p>
  </div>
);

LoadMore.propTypes = {
  requestTechnologiesPagination: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  requestTechnologiesPagination: (event) => {
    event.preventDefault();
    dispatch({ type: 'REQUEST_TECHNOLOGIES_PAGINATION' });
  },
});

export default connect(
  null,
  mapDispatchToProps,
)(LoadMore);
