import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { teamsActions, teamsSelectors } from '../../../modules/teams';

import { Box, Flex, Heading, Link } from '../../mc-ui';

import EditTeamNameForm from './EditTeamNameForm';
import FiltersSelect from './FiltersSelect';
import Members from './Members';

class Team extends React.Component {
  state = {
    editing: false,
    filter: { status: null },
  };

  handleEditNameSubmit = values => {
    this.props.updateTeam(values, this.toggleEditing);
  };

  handleFilterChange = filter => {
    const { requestTeam, team } = this.props;
    this.setState(
      () => ({ filter }),
      () => {
        requestTeam(team.id, filter);
      },
    );
  };

  toggleEditing = event => {
    if (event) event.preventDefault();
    this.setState(currentState => ({ editing: !currentState.editing }));
  };

  render() {
    const { isAdmin, isOwner, membersMeta, team } = this.props;
    const { editing, filter } = this.state;

    return (
      <Box>
        <Flex alignItems="center" position="relative">
          <Heading
            as="h4"
            fontSize="18px"
            fontWeight="600"
            lineHeight="24px"
            color="dark08"
            css={{ marginRight: '8px' }}
          >
            My Company Name:
          </Heading>
        </Flex>
        <Flex alignItems="center" justifyContent="space-between" marginBottom="24px">
          <Flex alignItems="center" position="relative">
            <Heading
              as="h4"
              fontSize="18px"
              lineHeight="24px"
              color="dark08"
              css={{ marginRight: '8px' }}
            >
              {team.attributes.name}
            </Heading>

            {isOwner && (
              <Link to="#editName" onClick={this.toggleEditing}>
                Edit
              </Link>
            )}

            {editing &&
              isOwner && (
                <EditTeamNameForm
                  onCancel={this.toggleEditing}
                  onSubmit={this.handleEditNameSubmit}
                  team={team}
                />
              )}
          </Flex>

          <Box width="184px">
            <FiltersSelect
              enabled={isAdmin || isOwner}
              filter={filter}
              membersMeta={membersMeta}
              onUpdate={this.handleFilterChange}
            />
          </Box>
        </Flex>

        <Members teamId={team.id} />
      </Box>
    );
  }
}

Team.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  isOwner: PropTypes.bool.isRequired,
  membersMeta: PropTypes.shape({}).isRequired,
  requestTeam: PropTypes.func.isRequired,
  team: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
  updateTeam: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isAdmin: teamsSelectors.isAdminSelector(state),
  isOwner: teamsSelectors.isOwnerSelector(state),
  membersMeta: teamsSelectors.membersMetaSelector(state),
  team: teamsSelectors.teamSelector(state),
});

const mapDispatchToProps = dispatch => ({
  requestTeam: (teamId, filter) =>
    dispatch(teamsActions.fetchTeamRequest(teamId, { filter, updateLoading: false })),
  updateTeam: (payload, callback) => dispatch(teamsActions.updateTeamRequest(payload, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Team);
