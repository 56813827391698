import colors from '../../theme/colors';

const controlStyles = invalid => (base, state) => {
  let borderColor = state.isFocused ? colors.dark1 : colors.dark5;
  if (invalid) {
    borderColor = colors.redDark;
  }
  return {
    ...base,
    borderColor,
    transition: `${base.transition || ''}, borderColor 150ms`,
    '&:hover': {},
  };
};

export default controlStyles;
