import * as React from 'react';

function CalendarIcon() {
  return (
    <svg width="70px" height="68px" viewBox="0 0 70 68" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.993 6.704c-6.3 1.965-21.803 11.31-25.391 16.687-2.974 4.457 8.553 6.644 6.551 11.55-2.43 5.953-3.927 13.076-.73 18.492 3.24 5.485 10.318 7.925 16.889 8.874 5.871.849 11.77-1.231 17.117-3.959 4.524-2.308 7.545-6.264 10.63-10.198 2.994-3.817 6.81-7.545 6.966-12.24.155-4.657-3.122-8.54-6.157-12.076-2.767-3.223 4.195-7.922.608-10.297-5.255-3.48-20.243-8.78-26.483-6.833z"
        fill="#F7EEFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.757 23.663a1 1 0 011-1h.63a1 1 0 011 1v.334h7.892v-.334a1 1 0 011-1h.63a1 1 0 011 1v.334h.438c3.398 0 6.138 2.778 6.138 6.222V42.22c0 3.445-2.74 6.223-6.138 6.223H25.318c-3.397 0-6.138-2.778-6.138-6.112V30.22c0-3.444 2.74-6.222 6.138-6.222h.439v-.334zm10.521 2.89v.444a1 1 0 001 1h.631a1 1 0 001-1v-.445h.438c1.973 0 3.617 1.667 3.617 3.667H21.701c0-2.111 1.644-3.667 3.617-3.667h.439v.445a1 1 0 001 1h.63a1 1 0 001-1v-.445h7.892zm3.07 19.334h-14.03c-1.973 0-3.617-1.667-3.617-3.667v-9.556h21.373v9.556c-.11 2.111-1.754 3.667-3.727 3.667z"
        fill="#9E6ACC"
      />
      <path
        d="M28.058 35.22h-3.397v3.444h3.397V35.22zM34.086 35.22H30.69v3.444h3.397V35.22zM28.058 40.442h-3.397v3.445h3.397v-3.445zM34.086 40.442H30.69v3.445h3.397v-3.445zM40.005 40.442h-3.398v3.445h3.398v-3.445zM9.316 34.22H5.92v3.444h3.397V34.22zM52.719 40.22H49.32v3.444h3.398V40.22zM58.637 40.22H55.24v3.444h3.397V40.22zM15.344 34.22h-3.397v3.444h3.397V34.22zM52.828 34.22h-3.397v3.444h3.397V34.22zM3.398 40.22H0v3.444h3.398V40.22zM58.637 34.22H55.24v3.444h3.397V34.22zM64.556 34.22h-3.398v3.444h3.398V34.22zM9.426 40.22H6.028v3.444h3.398V40.22zM59.13 18.495h-.704v-1.428c0-1.965-1.585-3.572-3.523-3.572-1.938 0-3.523 1.607-3.523 3.572v1.428h-.704c-.776 0-1.41.643-1.41 1.429v7.143c0 .786.635 1.43 1.41 1.43h8.455c.775 0 1.409-.644 1.409-1.43v-7.143c0-.786-.634-1.429-1.41-1.429zm-4.227 6.5c-.775 0-1.41-.642-1.41-1.428 0-.786.635-1.429 1.41-1.429.775 0 1.41.643 1.41 1.43 0 .785-.635 1.428-1.41 1.428zm2.184-6.5H52.72v-1.428c0-1.215.986-2.215 2.184-2.215 1.198 0 2.184 1 2.184 2.215v1.428z"
        fill="#9E6ACC"
      />
    </svg>
  );
}

export default CalendarIcon;
