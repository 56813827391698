import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';

import { timeSeriesActions } from '../../../../modules/newReports/timeSeries';

import { shallowEqual } from '../../../../lib/utils';
import { Flex, Loader } from '../../../mc-ui';

import LineChart from '../LineChart';

import OverviewStats from './OverviewStats';

class Overview extends React.Component {
  state = {
    availableFilters: ['day', 'week', 'month'],
    filter: 'week',
    showHolidays: false,
    historicalTrend: false,
  };

  UNSAFE_componentWillMount() {
    const { router } = this.props;
    const filter = router.location.query.filter || 'week';
    const historicalTrend = router.location.query.historicalTrend === 'true';
    this.setState({ historicalTrend, filter });
  }

  componentDidMount() {
    if (this.props.model.id) {
      this.request(this.props);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.model.id) return;
    const nextModel = { id: nextProps.model.id, type: nextProps.model.type };
    const model = { id: this.props.model.id, type: this.props.model.type };
    const nextDates = { endDate: nextProps.endDate, startDate: nextProps.startDate };
    const dates = { endDate: this.props.endDate, startDate: this.props.startDate };

    if (!shallowEqual(nextModel, model) || !shallowEqual(nextDates, dates)) {
      this.request(nextProps);
    }
  }

  componentDidUpdate(prevProps) {
    const { endDate, intervalLabel, startDate } = this.props;
    let filter = 'week';

    if (
      prevProps.intervalLabel === intervalLabel &&
      prevProps.endDate === endDate &&
      prevProps.startDate === startDate
    ) {
      return;
    }

    if (intervalLabel === 'Custom') {
      filter = 'day';
    }

    const showDailyFilter = moment(endDate).diff(moment(startDate), 'days', true) >= 1;
    const showWeeklyFilter = moment(endDate).diff(moment(startDate), 'weeks', true) > 1;
    const showMonthlyFilter = moment(endDate).diff(moment(startDate), 'months', true) > 1;

    if (showWeeklyFilter) {
      filter = 'week';
    } else if (showDailyFilter) {
      filter = 'day';
    }

    this.setFilter(filter);
    this.setAvailableFilters(showDailyFilter, showWeeklyFilter, showMonthlyFilter);
    this.request(this.props);
  }

  setAvailableFilters = (showDailyFilter, showWeeklyFilter, showMonthlyFilter) => {
    const availableFilters = [];

    if (showDailyFilter) {
      availableFilters.push('day');
    }

    if (showWeeklyFilter) {
      availableFilters.push('week');
    }

    if (showMonthlyFilter) {
      availableFilters.push('month');
    }

    this.setState({ availableFilters });
  };

  setFilter = filter => {
    const { router } = this.props;
    const { pathname, query } = router.location;
    router.push({ pathname, query: { ...query, filter } });

    this.setState(
      () => ({ filter }),
      () => {
        this.request(this.props);
      },
    );
  };

  request = async props => {
    const { chart, model, endDate, startDate } = props;
    if (!model.id) return;

    const { filter } = this.state;
    this.props.fetchTimeSeriesData(model, chart, filter, startDate, endDate);
    this.requestHistoricalTrend(props);
  };

  requestHistoricalTrend = async props => {
    const { chart, model, endDate, startDate } = props;
    const { filter, historicalTrend } = this.state;

    if (historicalTrend) {
      window.analytics.track('Comparison activity');
      this.props.fetchHistoricalTrend(model, chart, filter, startDate, endDate);
    }
  };

  toggleShowHolidays = event => {
    if (event.persist) event.persist();

    this.setState(
      () => ({ showHolidays: event.target.checked }),
      () => {
        this.request(this.props);
      },
    );
  };

  toggleHistoricalTrend = event => {
    if (event.persist) event.persist();
    const { router } = this.props;
    const historicalTrend = event.target.checked;
    const { pathname, query } = router.location;
    router.push({ pathname, query: { ...query, historicalTrend } });
    this.setState(
      () => ({ historicalTrend }),
      () => {
        this.request(this.props);
      },
    );
  };

  render() {
    const { chart, model } = this.props;
    const { availableFilters, filter, historicalTrend, showHolidays } = this.state;

    if (!model.id) {
      return (
        <Flex alignItems="center" height="373px" justifyContent="center">
          <Loader />
        </Flex>
      );
    }

    return (
      <div>
        <OverviewStats chart={chart} filter={filter} model={model} />
        <LineChart
          availableFilters={availableFilters}
          chart={chart}
          filter={filter}
          historicalTrend={historicalTrend}
          model={model}
          setFilter={this.setFilter}
          showHolidays={showHolidays}
          toggleHistoricalTrend={this.toggleHistoricalTrend}
          toggleShowHolidays={this.toggleShowHolidays}
        />
      </div>
    );
  }
}

Overview.defaultProps = {
  endDate: null,
  intervalLabel: null,
  startDate: null,
};

Overview.propTypes = {
  chart: PropTypes.string.isRequired,
  endDate: PropTypes.string,
  fetchHistoricalTrend: PropTypes.func.isRequired,
  fetchTimeSeriesData: PropTypes.func.isRequired,
  intervalLabel: PropTypes.string,
  model: PropTypes.shape({ id: PropTypes.string, type: PropTypes.string }).isRequired,
  router: PropTypes.shape({
    location: PropTypes.shape({
      query: PropTypes.object,
    }),
  }).isRequired,
  startDate: PropTypes.string,
};

const mapStateToProps = state => ({
  endDate: state.newReports.dates.endDate,
  intervalLabel: state.newReports.dates.intervalLabel,
  startDate: state.newReports.dates.startDate,
});

const mapDispatchToProps = dispatch => ({
  fetchHistoricalTrend: (...args) =>
    dispatch(timeSeriesActions.fetchHistoricalTrendRequest(...args)),
  fetchTimeSeriesData: (...args) => dispatch(timeSeriesActions.fetchDataRequest(...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Overview);
