/** @jsx jsx */
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { jsx, withTheme } from '@emotion/react';

import { Flex, Heading } from '../../mc-ui';

const Header = ({ group, theme }) => (
  <Flex alignItems="center" justifyContent="space-between" marginBottom="14px" paddingLeft="30px">
    <Heading as="h4" css={{ lineHeight: '1.32em' }}>
      {group.attributes.name}
    </Heading>

    <Link
      to={`/groups/${group.id}`}
      css={{
        color: theme.colors.blue,
        display: 'inline-block',
        fontSize: '12px',
        fontWeight: '500',
        letterSpacing: '0.02em',
        lineHeight: '1.25em',
        textDecoration: 'none',
      }}
    >
      View overview
    </Link>
  </Flex>
);

Header.propTypes = {
  group: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
  theme: PropTypes.shape({}).isRequired,
};

export default withTheme(Header);
