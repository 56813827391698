import * as React from 'react';

function SvgPositiveIcon(props) {
  return (
    <svg width="16px" height="16px" viewBox="0 0 16 16" fill="none" {...props}>
      <circle cx={8} cy={8} r={8} transform="rotate(-180 8 8)" fill="#78C854" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.333 12h1.334V5.964l2.326 2.313A.596.596 0 0012 7.857a.588.588 0 00-.167-.415L8.63 4.26A.888.888 0 008 4a.892.892 0 00-.63.259L4.167 7.442a.588.588 0 00.422 1 .596.596 0 00.417-.165l2.327-2.313V12zm.594-6.626h.146L8.06 5.36a.084.084 0 00-.118 0l-.014.014z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgPositiveIcon;
