import React from 'react';
import { PropTypes } from 'prop-types';
import styled from "@emotion/styled";

// #DFE1E6 === theme.colors.dark02

const tabOneActive = () => ({
  borderLeftColor: '#DFE1E6',
  borderRightColor: '#DFE1E6',
  borderTopColor: '#DFE1E6',
  left: 0,
});

const tabOneInactive = () => ({
  borderBottomColor: '#DFE1E6',
  left: 0,
});

const tabTwoActive = () => ({
  borderLeftColor: '#DFE1E6',
  borderRightColor: '#DFE1E6',
  borderTopColor: '#DFE1E6',
  right: 0,
});

const tabTwoInactive = () => ({
  borderBottomColor: '#DFE1E6',
  right: 0,
});

const tabOneStyles = active => (active === 'tabOne' ? tabOneActive() : tabOneInactive());
const tabTwoStyles = active => (active === 'tabTwo' ? tabTwoActive() : tabTwoInactive());

const Tab = styled("a")(({ active, name, theme }) => ({
  border: `1px solid ${theme.colors.white}`,
  borderRadius: '4px 4px 0 0',
  boxSizing: 'border-box',
  color: 'inherit',
  curcor: active === name ? 'normal' : 'pointer',
  height: '96px',
  padding: '24px',
  position: 'absolute',
  textDecoration: 'none',
  width: '50%',
  ...(name === 'tabOne' ? tabOneStyles(active, theme) : tabTwoStyles(active, theme)),
}));

const Item = ({ active, children, name, setActive }) => {
  const handleOnClick = event => {
    event.preventDefault();
    event.stopPropagation();
    setActive(name);
  };

  return (
    <Tab href="#changeTab" onClick={handleOnClick} active={active} name={name}>
      {typeof children === 'function' ? children({ isActive: active === name }) : children}
    </Tab>
  );
};

Item.propTypes = {
  active: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  name: PropTypes.string.isRequired,
  setActive: PropTypes.func.isRequired,
};

export default Item;
