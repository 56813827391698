const customTooltip = (chart, generateHTML) => ({
  enabled: false,
  intersect: true,
  mode: 'point',
  custom: tooltipModel => {
    // Tooltip Element
    const tooltipEl = document.getElementById('custom-chart-tooltip');

    // Set caret Position
    tooltipEl.classList.remove('above', 'below', 'no-transform');
    if (tooltipModel.yAlign) {
      tooltipEl.classList.add(tooltipModel.yAlign);
    } else {
      tooltipEl.classList.add('no-transform');
    }

    function getBody(bodyItem) {
      return bodyItem.lines;
    }

    // Set Text
    tooltipEl.innerHTML = generateHTML(getBody, tooltipModel);

    // `this` will be the overall tooltip
    const position = chart.chartInstance.canvas.getBoundingClientRect();

    // Display and set positions
    tooltipEl.style.left = `${position.left + window.pageXOffset + tooltipModel.caretX}px`;
    tooltipEl.style.opacity = tooltipModel.opacity;
    tooltipEl.style.top = `${position.top + window.pageYOffset + tooltipModel.caretY}px`;
  },
});

export default customTooltip;
