/* eslint-disable no-param-reassign */

import axios from 'axios';
import moment from 'moment';
import store from '../store';

export const baseUrl = () => {
  if (process.env.base_url) return `//${process.env.base_url}`;
  else if (process.env.NODE_ENV === 'production') return '//api.mailcharts.com';
  else if (process.env.NODE_ENV === 'beta') return '//api.mailcharts.com';
  else if (process.env.NODE_ENV === 'staging') return '//staging-api.mailcharts.com';
  else if (process.env.NODE_ENV === 'pr') return '//pr-api.mailcharts.com';
  return '//localhost:3001';
};

const instance = axios.create({
  baseURL: baseUrl(),
  withCredentials: true,
});

const tzo = moment().utcOffset();

instance.interceptors.request.use(config => {
  store.dispatch({ type: 'NETWORK_REQUEST' });
  // APPEND A TIME ZONE OFFSET FROM THE CLIENT REQUEST FOR ALL REQUESTS:
  if (!/tzo/i.test(config.url)) config.params = { ...config.params, tzo };
  return config;
});
instance.interceptors.response.use(
  resp => resp,
  err => {
    if (/failed with status code 401/i.test(err)) store.dispatch({ type: 'INVALID_CREDENTIALS' });
    else if (/failed with status code 503/i.test(err))
      store.dispatch({ type: 'UNDER_MAINTENANCE' });
    else store.dispatch({ type: 'NETWORK_ERR', message: err });
    return Promise.reject(err);
  },
);

export default instance;
