import React from 'react';
import styled from "@emotion/styled";
import PropTypes from 'prop-types';

import { palette } from '../../lib/color';

const ProgressIndicator = ({ label, progressPercent }) => {
  const Wrapper = styled("div")({
    position: 'relative',
    padding: '6px 8px 6px',
    border: `1px solid ${palette.grayD}`,
    borderRadius: 3,
    background: palette.white,
    overflow: 'hidden',
  });

  const Bar = styled("div")({
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    width: `${progressPercent}%`,
    background: palette.grayF,
  });

  const Label = styled("div")({
    position: 'relative',
    zIndex: 1,
    color: palette.grayA,
    fontSize: `${12 / 16}em`,
  });

  return (
    <Wrapper>
      <Bar />
      <Label>{label}</Label>
    </Wrapper>
  );
};

ProgressIndicator.propTypes = {
  label: PropTypes.string,
  progressPercent: PropTypes.number.isRequired,
};

ProgressIndicator.defaultProps = {
  label: null,
};

export default ProgressIndicator;
