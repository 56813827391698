import React from 'react';
import PropTypes from 'prop-types';

import Restricted from './Restricted';
import JourneyGrid from './JourneyGrid';
import MultiJourneyTimeline from './MultiJourneyTimeline';

const styles = {
  mainColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
};

class JourneyVisualization extends React.PureComponent {

  render() {
    const { loading, journeysWithDetails, journeyView } = this.props;
    const { meta = {} } = journeysWithDetails || {};
    let visualization;

    if (loading) {
      visualization = null;
    } else if (meta.restricted) {
      visualization = <div style={{ marginTop: '10px' }}><Restricted type="journeys" total={0} /></div>;
    } else if (journeyView === 'grid') {
      visualization = <JourneyGrid journeysWithDetails={journeysWithDetails} />;
    } else {
      visualization = <MultiJourneyTimeline journeysWithDetails={journeysWithDetails} />;
    }

    const header = null;
    // TODO: email type filter, once email.type data is available
    //   <div className='pure-u-1' style={{ textAlign: 'right' }}>
    //     <p style={{ display: 'inline-block' }}>
    //       <Link
    //         to={`/companies/${segment.attributes.company_id}/emails?segment_id=${segment.id}`}
    //         style={{ fontSize: `${12 / 16}em` }}
    //         className='pure-button'> View all emails </Link>
    //     </p>
    //   </div>

    return (
      <div>
        {header}
        <div className="pure-u-1" style={styles.mainColumn}>
          {visualization}
        </div>
      </div>
    );
  }
}

JourneyVisualization.contextTypes = {
  router: PropTypes.object,
};

JourneyVisualization.propTypes = {
  loading: PropTypes.bool.isRequired,
  journeysWithDetails: PropTypes.arrayOf(PropTypes.object).isRequired,
  journeyView: PropTypes.oneOf(['grid', 'timeline']).isRequired,
};

export default JourneyVisualization;
