import { call, put, select } from 'redux-saga/effects';

import * as actions from './actions';
import * as api from './api';
import * as selectors from './selectors';

export function* disable() {
  try {
    const response = yield call(api.update, { enabled: false });
    yield put(actions.disableSuccess(response.data));
  } catch (error) {
    console.log('weeklyRollUp/sagas › disable › error', error);
    yield put(actions.disableFailure(error));
  }
}

export function* enable() {
  try {
    const response = yield call(api.update, { enabled: true });
    yield put(actions.enableSuccess(response.data));
  } catch (error) {
    console.log('weeklyRollUp/sagas › enable › error', error);
    yield put(actions.enableFailure(error));
  }
}

export function* fetch() {
  try {
    const rollUpSubscriptionId = yield select(selectors.getWeeklyRollUpIdSelector);
    const industryGroupsResponse = yield call(api.fetchIndustryGroups);

    const response = rollUpSubscriptionId ? (yield call(api.fetch)) : (yield call(api.create));
    yield put(actions.fetchSuccess(response.data, industryGroupsResponse.data.data));
  } catch (error) {
    console.log('weeklyRollUp/sagas › fetch › error', error);
    yield put(actions.fetchFailure(error));
  }
}

export function* addGroup({ group }) {
  try {
    const response = yield call(api.addGroup, group.id);
    yield put(actions.addGroupSuccess(response.data));
  } catch (error) {
    console.log('weeklyRollUp/sagas › addGroup › error', error);
    yield put(actions.addGroupFailure(error));
  }
}

export function* removeGroup({ group }) {
  try {
    const response = yield call(api.removeGroup, group.id);
    yield put(actions.removeGroupSuccess(response.data));
  } catch (error) {
    console.log('weeklyRollUp/sagas › removeGroup › error', error);
    yield put(actions.removeGroupFailure(error));
  }
}
