import { compose, withProps } from 'recompose'
import { connect } from 'react-redux'

const mapStateToProps = state => ({
  browser: state.browser
})
const mapDispatchToProps = dispatch => ({ })

const responsive = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withProps((props) => ({
    isMobile: props.browser.is.extraSmall
  }))
)

export default responsive
