import styled from "@emotion/styled";
import React from 'react';
import { Flex, theme } from '../../../mc-ui';
import Section from '../../../Section';
import BrandLogo from '../../../ui/BrandLogo';
import ContextInfo from './ContextInfo';

const Img = styled("img")({
  borderRadius: '4px',
  height: '20px',
  width: '20px',
});

function buildContext({ loading, router, textMessage }) {
  if (loading) return { loading: true };

  const [brand] = (textMessage.relationships.companies || { data: [] }).data;

  if (!brand) return { loading: true };

  const { list_id: listId } = router.location.query;

  const context = {
    approximateTextMessages: brand.attributes.approximate_text_messages || 0,
    backLabel: 'Back to Brand',
    Logo: () => <BrandLogo brand={brand} css={{ height: '20px', width: '20px' }} />,
    text: brand.attributes.name,
    to: { pathname: `/companies/${brand.id}/text-messages` },
    type: 'companies',
  };

  if (listId) {
    const list = textMessage.relationships.lists.data.find(l => l.id === listId);

    if (list) {
      const attributes = list.attributes.attributes || list.attributes || {};
      context.approximateTextMessages = attributes.approximate_text_messages;
      context.backLabel = 'Back to SMS List';
      context.Logo = () => (
        <Img src={`//cache.mailcharts.com/logos/v2/lists/${list.id % 126}.png`} />
      );
      context.text = attributes.name;
      context.to = { pathname: `/lists/${list.id}` };
      context.type = 'lists';
    }
  }

  return context;
}

export default function ContextNavigation(props) {
  const context = buildContext(props);

  return (
    <Section outerBackground={theme.colors.dark004}>
      <Flex padding="28px 0">
        <Flex height="65px" justifyContent="space-between">
          <ContextInfo context={context} />
        </Flex>
      </Flex>
    </Section>
  );
}
