import React from 'react';

import NavigationDropdown from '../NavigationDropdown';

import NavigationItem from './NavigationItem';

const Brands = () => (
  <NavigationItem label="Brands" to="/brands">
    <NavigationDropdown>
      <NavigationDropdown.Item to="/brands">Brands Home</NavigationDropdown.Item>
      <NavigationDropdown.Item to="/groups">All Industry Groups</NavigationDropdown.Item>
      <NavigationDropdown.Divider />
      <NavigationDropdown.Item to="/groups/23691">Accessories</NavigationDropdown.Item>
      <NavigationDropdown.Item to="/groups/23699">Apparel</NavigationDropdown.Item>
      <NavigationDropdown.Item to="/groups/56983">Art &amp; Gifts</NavigationDropdown.Item>
      <NavigationDropdown.Item to="/groups/23694">
        Beauty &amp; Personal Care
      </NavigationDropdown.Item>
      <NavigationDropdown.Item to="/groups/54596">DTC</NavigationDropdown.Item>
      <NavigationDropdown.Item to="/groups/23695">Food &amp; Drink</NavigationDropdown.Item>
      <NavigationDropdown.Item to="/groups/23693">Footwear</NavigationDropdown.Item>
      <NavigationDropdown.Item to="/groups/23701">Health &amp; Fitness</NavigationDropdown.Item>
      <NavigationDropdown.Item to="/groups/23692">Home</NavigationDropdown.Item>
      <NavigationDropdown.Item to="/groups/23696">Sports &amp; Activities</NavigationDropdown.Item>
      <NavigationDropdown.Item to="/groups/59527">Subscription</NavigationDropdown.Item>
      <NavigationDropdown.Item to="/groups/56984">Tech</NavigationDropdown.Item>
      <NavigationDropdown.Item to="/groups/56982">Toys, Kids, &amp; Baby</NavigationDropdown.Item>
      <NavigationDropdown.Item to="/groups/23689">Travel &amp; Tourism</NavigationDropdown.Item>
      <NavigationDropdown.Item to="/groups">View All Industry Groups</NavigationDropdown.Item>
    </NavigationDropdown>
  </NavigationItem>
);

export default Brands;
