import React from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import api from '../lib/api';

import Container from './Container';

class Signup extends React.Component {
  state = {
    user: { attributes: {} },
    error: null,
    success: false,
  };
  handleSubmit = event => {
    event.preventDefault();
    if (this.state.loading || this.state.error) return;
    this.setState({ loading: true });
    api
      .post(`/v2/verify`, { ...this.state.user.attributes })
      .then(r => {
        this.setState({ error: null, loading: false, success: true });
      })
      .catch(err => {
        if (/409/.test(err.toString()))
          return this.props.router.replace({
            pathname: '/signin',
            query: { email: this.state.user.attributes.email },
          });
        this.setState({ error: true, loading: false, success: false });
      });
  };
  handleChange = event => {
    event.preventDefault();
    let user = this.state.user;
    user.attributes[event.target.name] = event.target.value;
    this.setState({ user, error: null });
  };
  render() {
    return (
      <div>
        <div className="pure-g">
          <div
            style={{
              background: `url('/signup.svg')`,
              backgroundSize: 'cover',
              padding: '100px 0',
              textAlign: 'center',
            }}
            className="pure-u-1"
          >
            <h1
              style={{
                color: '#ffffff',
              }}
            >
              Get started with MailCharts
            </h1>
            <h2
              style={{
                color: '#ffffff',
              }}
            >
              You're 2 minutes away from email reporting bliss.
            </h2>
          </div>
          <div
            className="pure-g"
            style={{
              width: '100%',
              margin: '0 auto',
            }}
          >
            <div
              className="pure-u-1"
              style={{
                textAlign: 'center',
                marginTop: -75,
                padding: '0 2%',
              }}
            >
              <Container style={{ margin: '0 auto', maxWidth: 400, padding: 50 }}>
                {this.state.success == true ? (
                  <div>
                    <img
                      src="/almost-there.svg"
                      style={{ maxWidth: 300, margin: '0 auto' }}
                      className="pure-img"
                    />
                    <h2>Almost there!</h2>
                    <p>Please check your email for a confirmation link.</p>
                    <p
                      style={{
                        fontSize: `${14 / 16}em`,
                      }}
                    >
                      We've sent a confirmation email to {this.state.user.attributes.email}.
                    </p>
                    <p
                      style={{
                        fontSize: `${14 / 16}em`,
                      }}
                    >
                      Please <a href="mailto:hi@mailcharts.com">contact us at hi@mailcharts.com</a>{' '}
                      if you have any trouble.
                    </p>
                    <p
                      style={{
                        borderTop: '1px solid #D8D8D8',
                        marginTop: 20,
                        paddingTop: 20,
                        textAlign: 'left',
                        fontStyle: 'italic',
                        color: '#646464',
                        fontSize: `${14 / 16}em`,
                      }}
                    >
                      If the confirmation email has not arrived within a minute or two, please check
                      your spam folder.
                    </p>
                    <p
                      style={{
                        fontSize: `${14 / 16}em`,
                      }}
                    >
                      <Link to="#" onClick={this.handleSubmit}>
                        Or click here to resend the confirmation
                      </Link>
                    </p>
                  </div>
                ) : (
                  <div>
                    {this.state.error ? (
                      <div
                        style={{
                          background: '#DD2325',
                          borderRadius: 2,
                          padding: '5px 10px',
                        }}
                      >
                        <h5 style={{ color: '#ffffff' }}>Please try again</h5>
                        <p style={{ fontSize: `${12 / 16}em`, color: '#ffffff' }}>
                          Try another email or contact hi@mailcharts.com.
                        </p>
                        <p style={{ fontSize: `${12 / 16}em`, color: '#ffffff' }}>
                          We're happy to help get you signed up!
                        </p>
                      </div>
                    ) : null}
                    <form onSubmit={this.handleSubmit} className="pure-form">
                      <fieldset>
                        <input
                          type="email"
                          required={true}
                          className="pure-u-1"
                          autoComplete="off"
                          style={{ textAlign: 'center' }}
                          name="email"
                          placeholder="Email address"
                          value={this.state.user.attributes.email || ''}
                          onChange={this.handleChange}
                        />
                      </fieldset>
                      <fieldset>
                        <input
                          style={{
                            padding: '25px 50px',
                          }}
                          type="submit"
                          className="pure-button pure-button-primary"
                          value={this.state.loading ? 'Validating email...' : 'Get started'}
                        />
                      </fieldset>
                    </form>
                    <p
                      style={{
                        fontSize: `${14 / 16}em`,
                      }}
                    >
                      Already have an account? <Link to="/signin">Sign in here</Link>
                    </p>
                  </div>
                )}
              </Container>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const s = state => ({ user: state.user });
export default connect(s)(Signup);
