import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { isNil, isEmpty } from 'ramda';

import { Text } from '../../../../../mc-ui';

const NumberDataPoint = ({ label, pluralLabel, value }) => {
  const number = Number(value);

  if (isNil(value) || isEmpty(value) || Number.isNaN(number)) {
    return (
      <Text color="yellowDark" fontWeight="normal">
        TBD
      </Text>
    );
  }

  return (
    <Text color="dark08" fontWeight="normal">
      {label}: {value} {pluralLabel && pluralize(pluralLabel, number)}
    </Text>
  );
};

NumberDataPoint.defaultProps = {
  pluralLabel: null,
  value: null,
};

NumberDataPoint.propTypes = {
  label: PropTypes.string.isRequired,
  pluralLabel: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default NumberDataPoint;
