import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withTheme } from '@emotion/react';

import { Flex, Heading, Text } from '../../mc-ui';
import RoundedRightArrowIcon from '../../mc-ui/icons/RoundedRightArrowIcon';

import Icon from './update/Icon';
import Wrapper from './update/Wrapper';

const Update = ({ theme, update }) => (
  <Wrapper to={update.attributes.link} target="_blank">
    <Flex alignItems="center" css={{ color: theme.colors.blue }}>
      <Icon type={update.type} />
      <Heading
        as="h3"
        css={{
          color: theme.colors.dark08,
          flex: '1',
          fontSize: '12px',
          fontWeight: 'bold',
          letterSpacing: '0.08em',
          lineHeight: '1.56em',
          margin: '0 0 0 13px',
          padding: '0',
          textTransform: 'uppercase',
        }}
      >
        {update.type}
      </Heading>
      <RoundedRightArrowIcon />
    </Flex>

    {update.attributes.title && (
      <Heading
        as="h4"
        css={{
          color: theme.colors.dark1,
          fontSize: '14px',
          fontWeight: 'bold',
          letterSpacing: '0.02em',
          lineHeight: '1.25em',
          margin: '16px 0',
          padding: '0',
        }}
      >
        {update.attributes.title}
      </Heading>
    )}

    <Text css={{ fontSize: '12px', lineHeight: '1.56em' }}>{update.attributes.description}</Text>

    <Text css={{ fontSize: '12px', lineHeight: '1.56em' }}>
      {moment(update.attributes.created_at).format('MMM D, YYYY')}
    </Text>
  </Wrapper>
);

Update.propTypes = {
  theme: PropTypes.shape({}).isRequired,
  update: PropTypes.shape({
    type: PropTypes.string,
    attributes: PropTypes.shape({
      created_at: PropTypes.number,
      description: PropTypes.string,
      has_media: PropTypes.bool,
      title: PropTypes.string,
    }),
  }).isRequired,
};

export default withTheme(Update);
