/* eslint-disable no-alert */
import React from 'react';
import PropTypes from 'prop-types';

import api from '../../lib/api';
import { TYPES } from '../../modules/shareButton/constants';

import ShareButton from '../ui/ShareButton';
import { Box, Button, Flex, theme } from '../mc-ui';

import EditView from './EditView';
import InfoView from './InfoView';

class ListInformation extends React.Component {
  state = {
    editing: false,
    list: this.props.list,
  };

  isCurrentUserOwner = () => {
    const { user } = this.props;
    const { list } = this.state;

    return list.attributes.user_id === parseInt(user.id, 10);
  };

  toggleEditing = () => {
    const { editing } = this.state;
    this.setState({ editing: !editing });
  };

  handleFieldChange = event => {
    const field = event.target;
    const { name, value } = field;
    const { list } = this.state;

    list.attributes[name] = value;

    this.setState({ list });
  };

  handlePrivacy = event => {
    if (event) {
      event.preventDefault();
    }

    const { updateListRequest } = this.props;
    const { list } = this.state;
    const privacy = list.attributes.state === 'private' ? 'public' : 'private';
    updateListRequest({ listId: list.id, name: list.attributes.name, description: list.attributes.description, state: privacy });
    this.setState({
      list: {
        ...list,
        attributes: {
          ...list.attributes,
          state: privacy,
        },
      },
    });
  };

  handleDelete = event => {
    event.preventDefault();
    const { deleteListSuccess, router } = this.props;
    const { list } = this.state;
    const confirmed = window.confirm(`You are about to delete list: ${list.attributes.name}!`);
    if (!confirmed) return;
    api
      .delete(`v2/lists/${list.id}`)
      .then(() => deleteListSuccess({ list }))
      .then(() => {
        router.push({ pathname: '/my-collections/email-lists' });
      })
      .catch(console.error);
  };

  handleSubmit = () => {
    const { updateListRequest } = this.props;
    const { list } = this.state;

    updateListRequest({
      listId: list.id,
      name: list.attributes.name,
      description: list.attributes.description,
      state: list.attributes.state,
    });

    this.toggleEditing();
  };

  componentDidUpdate(prevProps) {
    if (this.props.list !== prevProps.list) {
      this.setState({ list: this.props.list });
    }
  }

  render() {
    const { editing, list } = this.state;

    const isOwner = this.isCurrentUserOwner();

    return (
      <Flex alignItems="center" height="144px">
        <Box
          height="88px"
          marginRight="32px"
          width="88px"
          css={{
            '& > img': {
              height: '88px',
              width: '88px',
            },
          }}
        >
          <img
            src={`//cache.mailcharts.com/logos/v2/lists/${list.id % 126}.png`}
            alt={list.attributes.name}
          />
        </Box>

        {isOwner && editing ? (
          <EditView
            handleDelete={this.handleDelete}
            handleFieldChange={this.handleFieldChange}
            handlePrivacy={this.handlePrivacy}
            handleSubmit={this.handleSubmit}
            isOwner={isOwner}
            list={list}
          />
        ) : (
          <InfoView handlePrivacy={this.handlePrivacy} isOwner={isOwner} list={list} />
        )}

        <Flex
          alignItems={editing ? 'flex-end' : 'center'}
          height={editing ? '80px' : null}
          width="unset"
          wrap="nowrap"
          zIndex={theme.zIndices.docked}
        >
          {isOwner && (
            <Button
              onClick={this.toggleEditing}
              variant={editing ? 'secondary' : 'primary'}
              css={{
                alignItems: 'center',
                display: 'inline-flex',
                height: '32px',
                justifyContent: 'center',
                margin: '0',
                padding: '0',
                width: editing ? '56px' : '96px',
              }}
            >
              {editing ? 'Cancel' : 'Edit list'}
            </Button>
          )}

          {!editing && (
            <ShareButton
              type={TYPES.emailList}
              model={list}
              css={{ marginLeft: isOwner ? '8px' : null }}
            />
          )}
        </Flex>
      </Flex>
    );
  }
}

ListInformation.propTypes = {
  deleteListSuccess: PropTypes.func.isRequired,
  list: PropTypes.shape({
    id: PropTypes.string.isRequired,
    attributes: PropTypes.shape({
      description: PropTypes.string,
      name: PropTypes.string,
      state: PropTypes.string,
    }),
  }).isRequired,
  router: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  updateListRequest: PropTypes.func.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

export default ListInformation;
