import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

import { Box, Flex, Button, Heading } from '../../../../mc-ui';
import ArrowRightIcon from '../../../../mc-ui/icons/ArrowRightIcon';

import BrandLogo from '../../../BrandLogo';

import DataPoints from './DataPoints';

const GroupInfo = ({ model: group }) => (
  <div>
    <Heading as="h2" css={{ fontWeight: '300', lineHeight: '1.32em', marginBottom: '16px' }}>
      {group.attributes.name}
    </Heading>

    <Flex alignItems="center" marginBottom="8px">
      {group.relationships.companies.data.slice(0, 4).map((brand, index) => {
        const marginLeft = index > 0 ? '-8px' : '0px';
        return (
          <BrandLogo
            key={`Carousel-GroupInfo${brand.id}`}
            brandId={brand.id}
            css={{ marginLeft, height: '32px', width: '32px' }}
          />
        );
      })}
    </Flex>

    <Box marginBottom="40px">
      <DataPoints
        approximateCompanies={group.attributes.approximateCompanies}
        promotional={group.attributes.promotional}
        frequency={group.attributes.frequency}
      />
    </Box>

    <Button
      as={Link}
      to={`/groups/${group.id}`}
      css={{
        alignItems: 'center',
        display: 'inline-flex',
        height: '32px',
        justifyContent: 'space-between',
        padding: '0 12px',
        width: '164px',
      }}
    >
      <ArrowRightIcon fillOpacity={1} height="20px" width="20px" /> See this industry
    </Button>
  </div>
);

GroupInfo.propTypes = {
  model: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    attributes: PropTypes.shape({
      firstActivityAt: PropTypes.string,
      type: PropTypes.string,
      lastEmailSentAt: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    meta: PropTypes.shape({
      durationDays: PropTypes.shape({
        value: PropTypes.number,
      }),
      intervalDays: PropTypes.shape({
        value: PropTypes.number,
      }),
      totalEmails: PropTypes.shape({
        value: PropTypes.number,
      }),
    }),
    relationships: PropTypes.shape({
      companies: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape({})),
      }),
    }),
  }).isRequired,
};

export default GroupInfo;
