import React from 'react';
import PropTypes from 'prop-types';
import { isNil, isEmpty } from 'ramda';
import { withTheme } from '@emotion/react';

import { Text } from '../../../../../mc-ui';

import formatDate from './formatDate';

const DateDataPoint = ({ endDate, startDate, theme }) => (
  <Text color="dark08" fontWeight="normal" css={{ '& > span': { color: theme.colors.yellowDark } }}>
    {isEmpty(startDate) || isNil(startDate) || startDate.toLowerCase() === 'tbd' ? (
      <span>TBD</span>
    ) : (
      formatDate(startDate)
    )}{' '}
    -{' '}
    {isEmpty(endDate) || isNil(endDate) || endDate.toLowerCase() === 'tbd' ? (
      <span>TBD</span>
    ) : (
      formatDate(endDate)
    )}
  </Text>
);

DateDataPoint.defaultProps = {
  endDate: null,
  startDate: null,
};

DateDataPoint.propTypes = {
  endDate: PropTypes.string,
  startDate: PropTypes.string,
  theme: PropTypes.shape({}).isRequired,
};

export default withTheme(DateDataPoint);
