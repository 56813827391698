import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from "@emotion/styled";

import api from '../../../lib/api';
import { www } from '../../../lib/utils';

import { teamsSelectors } from '../../../modules/teams';

import ContactModalLink from '../../ContactModalLink';
import ModalLink from '../../ModalLink';
import NavigationDropdown from '../NavigationDropdown';

import NavigationItem from './NavigationItem';
import NotificationSign from '../../ui/NotificationSign';
import Avatar from './Avatar';
import AccountStatusBadge from '../../ui/AccountStatusBadge';
import { useAuth0 } from '@auth0/auth0-react';

const ContactModalItem = NavigationDropdown.Item.withComponent(ContactModalLink);
const ModalItem = NavigationDropdown.Item.withComponent(ModalLink);

const LogoutButton = () => {
  const { logout } = useAuth0();
  const redirectURI = www('/');
  const signOut = async () => {
    await api.post('/auth/destroy')
      .catch(console.error);
    logout({ returnTo: redirectURI });
  };
  return (
    <NavigationDropdown.Item
      style={{ cursor: 'pointer' }}
      onClick={() => { signOut(); }}
    >
      Sign out
    </NavigationDropdown.Item>
  );
};

class UserAvatar extends React.Component {

  render() {
    const { isOwner, isSubscriptionAutomaticallyCharged, team } = this.props;

    const { status } = team ? team.attributes : {};
    const pendingMember = team ? status === 'pending' : false;
    const UserAvatarWrapper = styled("nav")({
      alignItems: 'center',
      display: 'flex',
    });

    return (
      <UserAvatarWrapper>
        <NavigationItem css={{ margin: '0' }} label={<Avatar />} to="/manage">
          <NavigationDropdown centered>
            <NavigationDropdown.Item to="/manage">
              My profile
              <AccountStatusBadge css={{ marginLeft: '4px' }} small />
            </NavigationDropdown.Item>
            <NavigationDropdown.Item to="/manage/plan">Plan</NavigationDropdown.Item>
            <NavigationDropdown.Item to="/manage/teams">
              {team ? 'Team management' : 'Looking for Enterprise?'}
              {pendingMember && <NotificationSign css={{ marginLeft: '4px' }} />}
            </NavigationDropdown.Item>
            {team &&
              isOwner &&
              isSubscriptionAutomaticallyCharged && (
                <NavigationDropdown.Item to="/manage/invoices">
                  Invoices &amp; Billing
                </NavigationDropdown.Item>
              )}
            <ContactModalItem>Contact Support</ContactModalItem>
            <NavigationDropdown.Item to="/manage/preferences">Preferences</NavigationDropdown.Item>
            <ModalItem to="/manage/productboard">Feature Request</ModalItem>
            <ModalItem to="/manage/brandrequestboard">Brand Request</ModalItem>
            <LogoutButton></LogoutButton>
          </NavigationDropdown>
        </NavigationItem>
      </UserAvatarWrapper>
    );
  }
}

UserAvatar.defaultProps = {
  team: null,
};

UserAvatar.propTypes = {
  isOwner: PropTypes.bool.isRequired,
  isSubscriptionAutomaticallyCharged: PropTypes.bool.isRequired,
  team: PropTypes.shape({
    attributes: PropTypes.shape({
      status: PropTypes.string,
    }),
  }),
};

const mapStateToProps = state => ({
  isOwner: teamsSelectors.isOwnerSelector(state),
  isSubscriptionAutomaticallyCharged: teamsSelectors.isSubscriptionAutomaticallyChargedSelector(
    state,
  ),
  team: teamsSelectors.teamSelector(state)
});

export default connect(mapStateToProps)(UserAvatar);
