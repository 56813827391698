import React from 'react';
import PropTypes from 'prop-types';

function DropdownIcon({ fillOpacity, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.667 7.5l3.434 5 3.232-4.935L6.667 7.5z"
        fill="currentColor"
        fillOpacity={fillOpacity}
      />
    </svg>
  );
}

DropdownIcon.propTypes = {
  fillOpacity: PropTypes.number,
};

DropdownIcon.defaultProps = {
  fillOpacity: 0.6,
};

export default DropdownIcon;
