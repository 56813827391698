import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { withTheme } from '@emotion/react';

import { Box, Button, Flex, Loader } from '../../mc-ui';
import ArrowRightIcon from '../../mc-ui/icons/ArrowRightIcon';
import EmailThumbnail from '../../ui/EmailThumbnail';
import Pagination from '../../ui/Pagination';

import { fetchGroupEmails } from '../utils/api';

class Emails extends React.PureComponent {
  state = {
    emails: [],
    links: {},
    loading: true,
    meta: {},
  };

  componentDidMount() {
    this.request();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.group.id !== this.props.group.id) {
      this.request();
    }
  }

  paginate = direction => {
    this.request(this.state.links[direction]);
  };

  request = url => {
    const { group, pageSize } = this.props;

    this.setState(
      () => ({ loading: true }),
      async () => {
        const { emails, links, meta } = await fetchGroupEmails(group.id, pageSize, url);

        this.setState(() => ({
          emails,
          links,
          loading: false,
          meta,
        }));
      },
    );
  };

  render() {
    const { group, theme } = this.props;
    const { emails, links, loading, meta } = this.state;

    if (loading) {
      return (
        <Flex alignItems="center" height="552px" justifyContent="center" width="824px">
          <Loader />
        </Flex>
      );
    }

    return (
      <Flex direction="column" height="552px" justifyContent="space-between" paddingLeft="10px">
        <Box
          height="532px"
          position="relative"
          css={theme.scrollBox({ height: '484px' }, { width: '99%' })}
        >
          <Flex wrap="wrap">
            {emails.map(email => (
              <Box key={email.id} padding="0 0 20px 20px" width="unset">
                <EmailThumbnail
                  brand={{
                    id: email.attributes.companyId,
                    attributes: { name: email.attributes.companyName },
                  }}
                  email={email}
                />
              </Box>
            ))}
          </Flex>
        </Box>

        <Flex alignItems="center" justifyContent="space-between">
          <Pagination
            align="left"
            css={{ marginTop: '0', width: 'unset' }}
            from={meta.start}
            nextUrl={links.next}
            onClick={this.paginate}
            prevUrl={links.prev}
            selfUrl={links.self}
            to={meta.end}
            total={meta.total}
          />

          <Button
            as={Link}
            to={`/groups/${group.id}/emails`}
            css={{
              alignItems: 'center',
              display: 'inline-flex',
              height: '34px',
              justifyContent: 'space-between',
              padding: '0 12px',
              width: '155px',
            }}
          >
            <ArrowRightIcon fillOpacity={1} height="20px" width="20px" /> View all emails
          </Button>
        </Flex>
      </Flex>
    );
  }
}

Emails.propTypes = {
  group: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  pageSize: PropTypes.number.isRequired,
  theme: PropTypes.shape({}).isRequired,
};

export default withTheme(Emails);
