import styled from "@emotion/styled";

const Wrapper = styled("div")(({ isCentered, theme }) => ({
  backgroundColor: theme.colors.white,
  borderRadius: '4px',
  boxSizing: 'border-box',
  left: '50%',
  padding: '32px',
  position: 'absolute',
  top: isCentered ? '50%' : '180px',
  transform: isCentered ? 'translateX(-50%) translateY(-50%)' : 'translateX(-50%)',
  zIndex: '9996',
}));

export default Wrapper;
