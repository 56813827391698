import React from 'react';
import styled from "@emotion/styled";

import ListButton from './ListButton'

const EmptyListIconWrapper = styled("div")({
  boxSizing: 'border-box',
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '80px',
  width: '80px'
})

const rectangleAndSquareStyles = {
  border: '1px solid #a0afba',
  borderRadius: '3px',
  boxSizing: 'border-box',
  height: '34px',
  width: '34px'
}

const Rectangle = styled("div")({
  ...rectangleAndSquareStyles,
  height: '100%'
})

const Square = styled("div")(rectangleAndSquareStyles)

const PlusIcon = styled(ListButton)({
  backgroundColor: '#a0afba',
  border: 'none',
  cursor: 'default',
  height: '34px',
  width: '34px',
  '&::after, &::before': {
    backgroundColor: '#fff'
  }
})

const EmptyListIcon = () => (
  <EmptyListIconWrapper>
    <Rectangle />
    <Square />
    <PlusIcon />
  </EmptyListIconWrapper>
)

export default EmptyListIcon
