import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { shallowEqual } from '../../../lib/utils';
import { userSelectors } from '../../../modules/user';

import { ChartTooltip } from '../../mc-ui';

import HeaderContainer from '../HeaderContainer';
import DateSelect from '../DateSelect';
import MainWrapper from '../MainWrapper';

import BrandContainer from './BrandContainer';
import GroupContainer from './GroupContainer';
import Restricted from './Restricted';

class Promotions extends React.Component {
  shouldComponentUpdate(nextProps) {
    const nextModel = { id: nextProps.model.id, type: nextProps.model.type };
    const model = { id: this.props.model.id, type: this.props.model.type };

    return !shallowEqual(nextModel, model);
  }

  accessible = () => !this.restricted();

  restricted = () => {
    const { isUserRestricted, model } = this.props;
    if (!model.type) return false;
    if (model.attributes.featured) return false;
    if (isUserRestricted) window.analytics.track('User restricted', { type: `reports` });
    return isUserRestricted;
  };

  render() {
    const { model, router, ...props } = this.props;
    if (!model.id) return null;

    return (
      <MainWrapper>
        <HeaderContainer title="Promotion">
          <DateSelect restricted={this.restricted()} model={model} router={router} {...props} />
        </HeaderContainer>

        {this.restricted() ? (
          <Restricted model={model} />
        ) : (
          <div>
            {model.type === 'groups' && <GroupContainer model={model} router={router} />}
            {model.type !== 'groups' && <BrandContainer model={model} router={router} />}
          </div>
        )}
        <ChartTooltip />
      </MainWrapper>
    );
  }
}

Promotions.defaultProps = {
  model: { id: null },
};

Promotions.propTypes = {
  isUserRestricted: PropTypes.bool.isRequired,
  model: PropTypes.shape({ id: PropTypes.string, type: PropTypes.string }),
  router: PropTypes.shape({
    location: PropTypes.shape({
      query: PropTypes.object,
      search: PropTypes.string,
    }),
  }).isRequired,
};

const mapStateToProps = state => ({
  isUserRestricted: userSelectors.isUserRestrictedSelector(state),
});

export default connect(mapStateToProps)(Promotions);
