import React from 'react';
import PropTypes from 'prop-types';
import styled from "@emotion/styled";
import pluralize from 'pluralize';

import { Flex, Text } from '../../../../../../mc-ui';

const Bar = styled("div")(({ size, theme }) => ({
  backgroundColor: theme.colors.dark004,
  height: '10px',
  position: 'relative',
  width: '56px',
  '&::before': {
    backgroundColor: theme.colors.tealMedium,
    content: '""',
    display: 'block',
    height: '10px',
    left: '0',
    position: 'absolute',
    top: '0',
    width: `${size}%`,
    zIndex: '1',
  },
  '&::after': {
    background: `repeating-linear-gradient(
      90deg,
      transparent,
      transparent 8px,
      ${theme.colors.white} 8px,
      ${theme.colors.white} 12px
    )`,
    content: '""',
    display: 'block',
    height: '10px',
    left: '0',
    position: 'absolute',
    top: '0',
    width: '56px',
    zIndex: '2',
  },
}));

const BrandsChart = ({ data: { frequency, promotionRate } }) => (
  <Flex direction="column" marginTop="8px">
    <Flex alignItems="center" direction="column">
      <Text
        color={(frequency || 0) === 0 ? 'dark06' : 'black'}
        fontSize="18px"
        fontWeight="500"
        lineHeight="1.32em"
        letterSpacing="0.02em"
      >
        {Math.round(frequency || 0)}
      </Text>
      <Text color={(frequency || 0) === 0 ? 'dark04' : 'dark08'} fontSize="12px">
        {pluralize('Email', Math.round(frequency || 0))}
      </Text>
    </Flex>

    <Flex alignItems="center" direction="column" marginTop="8px">
      <Bar color="tealMedium" size={promotionRate} />
      <Text fontSize="10px" letterSpacing="0.12em" css={{ marginTop: '2px' }}>
        {promotionRate}%
      </Text>
    </Flex>
  </Flex>
);

BrandsChart.propTypes = {
  data: PropTypes.shape({
    frequency: PropTypes.number,
    promotionRate: PropTypes.number,
  }).isRequired,
};

export default BrandsChart;
