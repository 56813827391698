import React from 'react';
import { Link } from 'react-router';

import * as S from './styles';

const Logo = () => (
  <S.LogoWrapper>
    <Link to="/">
      <img src="/new-logo.png" className="pure-img" alt="MailCharts" />
    </Link>
  </S.LogoWrapper>
);

export default Logo;
