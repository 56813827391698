import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Comments from './lists/Comments';
import Header from './Header';
import ListInformation from './ListInformation';
import Section from './Section';
import Unlocked from './mc-ui/Unlocked';
import { Flex, Link, Loader } from './mc-ui';
import { teamsSelectors } from '../modules/teams';
import NotFound from './NotFound';

class Lists extends React.Component {
  componentDidMount() {
    this.request();
  }

  UNSAFE_componentWillReceiveProps() {
    this.request();
  }

  componentWillUnmount() {
    this.props.flush();
  }

  query = {};

  request = () => {
    const {
      requestLists,
      router: { location, params },
    } = this.props;

    const query = {
      ...location.query,
      ...params,
    };

    if (JSON.stringify(query) !== JSON.stringify(this.query)) {
      this.query = query;
      requestLists(params.list_id, query);
    }
  };

  render() {
    const { children, lists, router, user, isPro, hasSmsAddon, isSubscriptionExempted } = this.props;

    if (lists.response && lists.response.status === 404) {
      console.log("Resource not found");
      return <NotFound />
    }

    const list = lists && (lists.data ? lists.data.find(g => g.id === router.params.list_id) : lists) || { attributes: {} };
    const isOwner = parseInt(user.id, 10) === parseInt(list.attributes.user_id, 10);
    const type = list.attributes.approximate_text_messages > 0 ? 'text message' : 'email';
    const overrideRestriction = type === 'text message' && isPro && !hasSmsAddon && !isSubscriptionExempted;

    return (
      <div className="pure-u-1">
        <Header>
          {!lists.loading && list.id && <ListInformation list={list} {...this.props} />}
        </Header>
        {lists.loading ? (
          <Section>
            <Flex alignItems="center" justifyContent="center">
              <Loader />
            </Flex>
          </Section>
        ) : (
          <div>
            <Comments list={list} />
            <Section>
              {(overrideRestriction || !isOwner) && (
                <Unlocked
                  style={{ margin: '40px 0 32px 0' }}
                  text={
                    <span>
                      You are viewing a curated list of {type} examples. MailCharts Pro users have
                      access to historical {type} data from thousands of ecommerce brands and can
                      create custom lists to plan campaigns and share with teammates.{' '}
                      {type === 'email' && (
                        <span>
                          Curious to learn how to use filters to find emails?{' '}
                          <Link to="https://youtu.be/xoUhBnC6D5M" target="_blank">
                            Click to watch this two-minute video!
                          </Link>
                        </span>
                      )}
                    </span>
                  }
                  title="Unlocked"
                  user={user}
                  unlocked={list.attributes.featured}
                  overrideRestriction={overrideRestriction}
                />
              )}
              <div className="pure-u-1">
                {React.Children.map(children, child =>
                  React.cloneElement(child, { isListOwner: isOwner, model: list }),
                )}
              </div>
            </Section>
          </div>
        )}
      </div>
    );
  }
}

Lists.propTypes = {
  children: PropTypes.node.isRequired,
  flush: PropTypes.func.isRequired,
  lists: PropTypes.shape({}).isRequired,
  requestLists: PropTypes.func.isRequired,
  router: PropTypes.shape({
    location: PropTypes.shape({}),
    params: PropTypes.shape({}),
  }).isRequired,
  user: PropTypes.shape({}).isRequired,
  isPro: PropTypes.bool.isRequired,
  hasSmsAddon: PropTypes.bool.isRequired,
  isSubscriptionExempted: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  lists: state.lists,
  user: state.user.data.reduce((m, g) => g, {}),
  isPro: teamsSelectors.isProSelector(state),
  hasSmsAddon: teamsSelectors.hasSmsAddonSelector(state),
  isSubscriptionExempted: teamsSelectors.isSubscriptionExemptedSelector(state),
});

const mapDispatchToProps = dispatch => ({
  flush: () => dispatch({ type: 'FLUSH' }),
  requestLists: (listId, params) => dispatch({ type: 'REQUEST_LISTS', list_id: listId, params }),
  updateListRequest: opts => dispatch({ type: 'UPDATE_LIST_REQUEST', ...opts }),
  deleteListSuccess: list => dispatch({ type: 'DELETE_LIST_SUCCESS', list }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Lists);
