import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

import { Box, Button, Flex, Tooltip, theme } from '../../mc-ui';
import FYICircleIcon from '../../mc-ui/icons/FYICircleIcon';

const CTA = ({ type }) => {
  let label = '';
  let to = '';
  let width = null;

  if (
    type === 'customerJourneysList' ||
    type === 'upcomingHolidays' ||
    type === 'mostTrackedBrands'
  ) {
    return null;
  }

  if (type === 'lists') {
    label = 'Browse all lists';
    to = '/emails';
    width = '132px';
  } else if (type === 'journeys') {
    label = 'Browse all journeys';
    to = '/journeys/results';
    width = '158px';
  }

  return (
    <Flex alignItems="center">
      <Button
        as={Link}
        to={to}
        css={{
          alignItems: 'center',
          display: 'inline-flex',
          height: '32px',
          justifyContent: 'space-between',
          padding: '0 15px',
          width,
        }}
      >
        {label}
      </Button>

      {type === 'journeys' && (
        <Tooltip
          id="CTABrowseAllJourneysTolltip"
          element={
            <Box
              inline
              height="24px"
              width="24px"
              css={{
                color: theme.colors.dark06,
                '&:hover': {
                  color: theme.colors.dark1,
                },
              }}
            >
              <FYICircleIcon height="24px" width="24px" />
            </Box>
          }
          variant="blue"
          css={{
            height: '24px',
            marginLeft: '8px',
            width: '24px',
            '& span': {
              display: 'inline-block',
              height: '24px',
              width: '24px',
            },
            '& [data-id="tooltip"]': {
              marginLeft: '0 !important',
            },
          }}
        >
          <p>
            Learn what types of email journeys MailCharts collects, how often are new journeys
            triggered for each brand and more on our FAQ page.
          </p>

          <Button
            as={Link}
            to="https://knowledge.validity.com/hc/en-us/sections/6839689729819-FAQ"
            target="_blank"
            variant="primary"
            css={{
              alignItems: 'center !important',
              display: 'inline-flex !important',
              justifyContent: 'center !important',
              height: '32px !important',
              width: '104px !important',
            }}
          >
            Read more
          </Button>
        </Tooltip>
      )}
    </Flex>
  );
};

CTA.propTypes = {
  type: PropTypes.string.isRequired,
};

export default CTA;
