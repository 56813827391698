import api from '../../lib/api';

export const fetch = async (model, options = {}) => {
  let query = '';
  if (model.type === 'groups') {
    query += `group_id=${model.id}`;
  } else {
    query += `company_id=${model.id}`;
  }

  Object.keys(options).forEach(key => {
    query += `&${key}=${options[key]}`;
  });

  const response = await api.get(`/v3/journeys?${query}`);

  return response;
};

const DEFAULT_FILTER = 'classified';

export const fetchJourney = async (id, options) => {
  let query = '';
  query += `filter=${options.filter || DEFAULT_FILTER}`;

  if (options.classification_type) {
    query += `&classification_type=${options.classification_type}`;
  }

  const response = await api.get(`/v3/journeys/${id}?${query}`);

  return response.data;
};

export const prioritizeJourney = async id => {
  await api.post(`/v3/journeys/${id}/prioritize`);
};
