import React from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';

class PromotionValues extends React.Component {
  render() {
    let max = 0;
    const datasets = [].concat(this.props.buckets).map((buckets, idx) => {
      const total = buckets.reduce((m, b) => m + (b.company_avg.value || 0), 0);
      const label = this.props.names[idx];
      const isComparison = label === 'Comparison';
      const color = isComparison ? '#646464' : '#0383E7';
      const bg = isComparison ? 'rgba(36, 36, 36, .05)' : 'rgba(0, 134, 241, .25)';

      const data = buckets.map((b) => {
        const value = +(b.company_avg.value || 0).toFixed(2);
        const percent = Math.round((value / total) * 100);
        max = percent > max ? percent : max;
        return percent;
      });

      return {
        type: 'line',
        label,
        fill: true,
        lineTension: 0,
        backgroundColor: bg,
        borderColor: color,
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: color,
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: color,
        pointHoverBorderColor: color,
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data,
      };
    });

    // Find the bucket that has more data to build this chart labels
    const buckets = [...this.props.buckets];
    buckets.sort((a, b) => b.length > a.length);
    const bucket = buckets[0];

    const data = {
      labels: bucket.map((b) => {
        let value = `${b.from}${b.to ? `-${b.to}` : '+'}`;
        if (this.props.type === 'discount_percentage') value += '%';
        else value = `$${value}`;
        return value;
      }),
      datasets,
    };
    let stepSize = 10;
    if (max < 10) {
      stepSize = 1;
    } else if (max < 50) {
      stepSize = 5;
    }
    const options = {
      scales: {
        xAxes: [
          {
            callback(value) {
              return `${value}%`;
            },
          },
        ],
        yAxes: [
          {
            afterDataLimits: (instance) => {
              instance.max += 1; // eslint-disable-line
            },
            ticks: {
              beginAtZero: true,
              min: 0,
              stepSize,
              callback(value) {
                return value;
              },
            },
            scaleLabel: {
              display: true,
              labelString:
                this.props.type === 'discount_percentage'
                  ? '% of percent-off discounts'
                  : '% of dollars-off discounts',
            },
          },
        ],
      },
      legend: {
        labels: {
          usePointStyle: true,
          align: 'left',
        },
        position: 'top',
      },
    };
    return <Line height={this.props.height || 240} options={options} data={data} />;
  }
}

PromotionValues.propTypes = {
  buckets: PropTypes.arrayOf(PropTypes.any).isRequired,
  height: PropTypes.number.isRequired,
  names: PropTypes.arrayOf(PropTypes.any).isRequired,
  type: PropTypes.string,
};

PromotionValues.defaultProps = {
  type: '',
};

export default PromotionValues;
