import React from 'react';
import PropTypes from 'prop-types';

import ContactModalLink from '../ContactModalLink';
import { topics as contactTopics } from '../ContactUs';

import ContactUsContainer from './ui/ContactUsContainer';
import Title from './ui/Title';

const buttonStyle = {
  fontSize: '1em',
  lineHeight: `${24 / 16}em`,
  padding: '0.438em 2em',
};

const ContactUs = ({ white }) => (
  <ContactUsContainer white={white}>
    <div>
      <Title style={{ margin: '0' }}>Interested in a demo for you and your team?</Title>

      <p style={{ margin: '7px 0 0 0' }}>We’d love to chat about your needs.</p>
    </div>

    <ContactModalLink
      className="pure-button pure-button-primary"
      style={buttonStyle}
      topic={contactTopics.ENTERPRISE}
    >
      Contact us
    </ContactModalLink>
  </ContactUsContainer>
);

ContactUs.propTypes = {
  white: PropTypes.bool,
};

ContactUs.defaultProps = {
  white: false,
};

export default ContactUs;
