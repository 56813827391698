import PropTypes from 'prop-types';
import React from 'react';
import Emails from './Emails';
import TextMessagesFromModel from './TextMessages/TextMessagesFromModel';

export default function ListsIndex(props) {
  if (!props.model) return null;
  if (props.model.attributes.approximate_emails > 0) return <Emails {...props} />;
  return <TextMessagesFromModel {...props} />;
}

ListsIndex.defaultProps = {
  model: null,
};

ListsIndex.propTypes = {
  model: PropTypes.shape({
    attributes: PropTypes.shape({
      approximate_emails: PropTypes.number,
    }),
  }),
};
