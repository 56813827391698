import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { sendingBehaviorSelectors as selectors } from '../../../../modules/newReports/sendingBehavior';

import { Flex, Loader } from '../../../mc-ui';

import ReportSection from '../../ReportSection';
import Title from '../../Title';
import WeeklyHoursTable from './WeeklyHoursTable';

const HoursInWeek = ({ loading, model, ...props }) => (
  <ReportSection css={{ height: '453px', width: '407px' }}>
    <ReportSection.Header>
      {loading ? (
        <Title>&nbsp;</Title>
      ) : (
        <Title>{model.type === 'groups' ? 'By Hours In The Week' : 'Most popular send time'}</Title>
      )}
    </ReportSection.Header>
    {loading ? (
      <Flex alignItems="center" height="309px" justifyContent="center">
        <Loader />
      </Flex>
    ) : (
      <div>
        <ReportSection.Body>
          <WeeklyHoursTable model={model} {...props} />
        </ReportSection.Body>
      </div>
    )}
  </ReportSection>
);

HoursInWeek.propTypes = {
  loading: PropTypes.bool.isRequired,
  model: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    type: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state, { model }) => {
  if (!model.id) return { loading: true };

  const { endDate, startDate } = state.newReports.dates;

  return {
    loading: selectors.getLoading(model.id, model.type, startDate, endDate)(state),
  };
};

export default connect(mapStateToProps)(HoursInWeek);
