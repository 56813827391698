import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTheme } from '@emotion/react';

import {
  benchmarksActions as actions,
  benchmarksSelectors as selectors,
} from '../../../modules/newReports/benchmarks';
import { userSelectors } from '../../../modules/user';

import { shallowEqual } from '../../../lib/utils';
import { Flex, Text } from '../../mc-ui';

import HeaderContainer from '../HeaderContainer';
import MainWrapper from '../MainWrapper';
import Body from '../Body';

import CompareBrandsForm from './CompareBrandsForm';
import EmailScores from './EmailScores';
import MobileOptimized from './MobileOptimized';
import MostPopularSendDay from './MostPopularSendDay';
import MostPopularSendTime from './MostPopularSendTime';
import EmailsSent from './EmailsSent';
import PromotionRate from './PromotionRate';
import Restricted from './Restricted';
import SubjectLineLength from './SubjectLineLength';

const getDefaultInterval = intervals => intervals.find(i => i.default);

class GroupContainer extends React.Component {
  state = {
    interval: getDefaultInterval(this.props.userIntervals),
    selectedBrand: {},
  };

  componentDidMount() {
    const { model } = this.props;

    if (model.id) {
      this.requestBrandsOptions(model.id);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.model.id) return;
    const nextModel = { id: nextProps.model.id, type: nextProps.model.type };
    const model = { id: this.props.model.id, type: this.props.model.type };

    if (!shallowEqual(nextModel, model)) {
      this.requestBrandsOptions(nextModel.id);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const nextModel = { id: nextProps.model.id, type: nextProps.model.type };
    const model = { id: this.props.model.id, type: this.props.model.type };
    const nextBrandsOptions = {
      brandsOptionsLoading: nextProps.brandsOptionsLoading,
      brandsOptions: nextProps.brandsOptions,
    };
    const brandsOptions = {
      brandsOptionsLoading: this.props.brandsOptionsLoading,
      brandsOptions: this.props.brandsOptions,
    };

    return (
      !shallowEqual(nextModel, model) ||
      !shallowEqual(nextBrandsOptions, brandsOptions) ||
      !shallowEqual(nextState, this.state)
    );
  }

  setSelectedBrand = selectedBrand => {
    if (this.state.selectedBrand.value === selectedBrand.value) return;
    this.setState(() => ({ selectedBrand }), this.request);
  };

  request = () => {
    if (this.restricted()) return;

    const { fetchData } = this.props;
    const { selectedBrand } = this.state;

    if (!selectedBrand) return;

    const brandId = selectedBrand.value === 'premium' ? null : selectedBrand.value;
    fetchData(brandId);
  };

  requestBrandsOptions = modelId => {
    if (this.restricted()) return;
    this.props.fetchGroupBrands(modelId);
  };

  restricted = () => {
    const { isUserRestricted, model } = this.props;
    if (!model.type) return false;
    if (model.attributes.featured) return false;
    if (isUserRestricted) window.analytics.track('User restricted', { type: `Benchmarks` });
    return isUserRestricted;
  };

  accessible = () => !this.restricted();

  render() {
    const { brandsOptions, brandsOptionsLoading, model, router, theme } = this.props;
    const { interval, selectedBrand } = this.state;
    return (
      <MainWrapper>
        <HeaderContainer
          title="Benchmarks"
          tooltip={
            this.restricted() ? null : (
              <Text>
                See how this group’s key data points compare to{' '}
                <a
                  href="https://www.mailcharts.com/companies"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Mailcharts Index
                </a>{' '}
                in the MailCharts database. Or, select a brand in the group to see how it stacks up
                vs. the groups’ data points
              </Text>
            )
          }
        >
          <Flex
            alignItems="center"
            justifyContent={this.accessible() ? 'space-between' : 'flex-end'}
            css={{ width: '462px' }}
          >
            {this.accessible() && (
              <Text
                css={{
                  color: theme.colors.dark04,
                  fontSize: '14px',
                  fontWeight: '500',
                  letterSpacing: '0.02em',
                  lineHeight: '1.25em',
                }}
              >
                Last 90 days
              </Text>
            )}
            <CompareBrandsForm
              brandsOptions={brandsOptions}
              loading={brandsOptionsLoading}
              model={model}
              router={router}
              selectedBrand={selectedBrand}
              setBrand={this.setSelectedBrand}
              restricted={this.restricted()}
            />
          </Flex>
        </HeaderContainer>

        {this.restricted() ? (
          <Restricted model={model} />
        ) : (
          <Body>
            <EmailsSent
              router={router}
              model={model}
              brandId={selectedBrand.value}
              endDate={interval.end_date}
              startDate={interval.start_date}
            />
            <Flex justifyContent="space-between">
              <MostPopularSendDay model={model} brandId={selectedBrand.value} />
              <MostPopularSendTime model={model} brandId={selectedBrand.value} />
            </Flex>
            <PromotionRate
              router={router}
              model={model}
              brandId={selectedBrand.value}
              endDate={interval.end_date}
              startDate={interval.start_date}
            />
            <SubjectLineLength
              router={router}
              model={model}
              brandId={selectedBrand.value}
              endDate={interval.end_date}
              startDate={interval.start_date}
            />
            <MobileOptimized router={router} model={model} brandId={selectedBrand.value} />
            <EmailScores router={router} model={model} brandId={selectedBrand.value} />
          </Body>
        )}
      </MainWrapper>
    );
  }
}

GroupContainer.defaultProps = {
  model: { id: null, type: null },
};

GroupContainer.propTypes = {
  brandsOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  brandsOptionsLoading: PropTypes.bool.isRequired,
  fetchData: PropTypes.func.isRequired,
  fetchGroupBrands: PropTypes.func.isRequired,
  isUserRestricted: PropTypes.bool.isRequired,
  model: PropTypes.shape({ id: PropTypes.string, type: PropTypes.string }),
  router: PropTypes.shape({
    location: PropTypes.shape({
      query: PropTypes.object,
    }),
  }).isRequired,
  theme: PropTypes.shape({}).isRequired,
  userIntervals: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const mapStateToProps = (state, { model }) => ({
  brandsOptions: model.id ? selectors.getGroupBrandsOptionsData(model.id)(state) : [],
  brandsOptionsLoading: model.id ? selectors.getGroupBrandsOptionsLoading(model.id)(state) : true,
  isUserRestricted: userSelectors.isUserRestrictedSelector(state),
  userIntervals: state.user.meta.intervals,
});

const mapDispatchToProps = (dispatch, { model }) => ({
  fetchGroupBrands: modelId => dispatch(actions.fetchGroupBrandsRequest(modelId)),
  fetchData: brandId => dispatch(actions.fetchGroupDataRequest(model.id, brandId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTheme(GroupContainer));
