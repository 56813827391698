/** @jsx jsx */
import { jsx } from '@emotion/react';

import { Box, Flex, Text } from '../mc-ui';
import ArrowRightIcon from '../mc-ui/icons/ArrowRightIcon';
import FYICircleIcon from '../mc-ui/icons/FYICircleIcon';
import TimelineIcon from '../mc-ui/icons/TimelineIcon';

const Questions = () => (
  <Flex justifyContent="space-between" marginTop="24px">
    <a
      css={{
        display: 'flex',
        outline: 'none',
        textDecoration: 'none',
        width: '526px',
      }}
      href="https://knowledge.validity.com/hc/en-us/articles/9510530214043-How-do-I-know-you-have-all-the-lifecycle-journeys-Do-you-have-every-kind-of-email-journey-a-brand-could-send-"
      rel="noopener noreferrer"
      target="_blank"
    >
      <Flex
        alignItems="center"
        backgroundColor="white"
        borderRadius="4px"
        color="dark1"
        justifyContent="space-between"
        padding="12px 24px"
      >
        <Flex>
          <Box
            backgroundColor="dark6"
            color="dark1"
            borderRadius="50%"
            height="24px"
            marginRight="24px"
            padding="2px"
            width="24px"
          >
            <TimelineIcon height="20px" width="20px" />
          </Box>

          <Text
            color="dark1"
            fontSize="16px"
            fontWeight="500"
            letterSpacing="0.02em"
            lineHeight="1.25em"
          >
              Learn more about our journeys.
          </Text>
        </Flex>

        <ArrowRightIcon fillOpacity={1} />
      </Flex>
    </a>

    <a
      css={{
        display: 'flex',
        outline: 'none',
        textDecoration: 'none',
        width: '526px',
      }}
      href="https://www.mailcharts.com/our-data"
      rel="noopener noreferrer"
      target="_blank"
    >
      <Flex
        alignItems="center"
        backgroundColor="white"
        borderRadius="4px"
        color="dark1"
        justifyContent="space-between"
        padding="12px 24px"
      >
        <Flex>
          <Box
            backgroundColor="dark6"
            color="dark08"
            borderRadius="50%"
            height="24px"
            marginRight="24px"
            padding="2px"
            width="24px"
          >
            <FYICircleIcon height="20px" width="20px" />
          </Box>

          <Text
            color="dark1"
            fontSize="16px"
            fontWeight="500"
            letterSpacing="0.02em"
            lineHeight="1.25em"
          >
              Learn how we collect lifecycle journeys.
          </Text>
        </Flex>

        <ArrowRightIcon fillOpacity={1} />
      </Flex>
    </a>
  </Flex>
);

export default Questions;
