import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@emotion/react';
import { isEmpty, take } from 'ramda';

import { Box, Flex, Tag, Text } from '../../../mc-ui';

import SubjectWithBrandLogo from '../SubjectWithBrandLogo';

const takeFive = take(5);

class Subjects extends React.Component {
  cssTag = filter => {
    const { selectedFilter, theme } = this.props;

    if (filter === selectedFilter) {
      return {
        backgroundColor: theme.colors.blueLight,
        borderColor: theme.colors.blueMedium,
        marginRight: '8px',
      };
    }
    return { cursor: 'pointer', marginRight: '8px' };
  };

  render() {
    const { changeFilter, data, filters, selectedFilter } = this.props;

    return (
      <Flex direction="column" css={{ margin: '32px 0 6px 0' }}>
        <Flex css={{ marginBottom: '24px' }}>
          {filters.map(filter => (
            <Tag
              key={filter.value}
              css={this.cssTag(filter.value)}
              onClick={changeFilter}
              data-filter={filter.value}
            >
              {filter.label}
            </Tag>
          ))}
        </Flex>

        {isEmpty(data) ? (
          <Flex alignItems="center" height="165px" justifyContent="center">
            <Text>No data</Text>
          </Flex>
        ) : (
          <Box height="165px">
            {isEmpty(data) && <Text>No data</Text>}
            {takeFive(data).map(email => (
              <SubjectWithBrandLogo key={`${selectedFilter}-${email.id}`} email={email} />
            ))}
          </Box>
        )}
      </Flex>
    );
  }
}

Subjects.propTypes = {
  changeFilter: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  filters: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedFilter: PropTypes.string.isRequired,
  theme: PropTypes.shape({}).isRequired,
};

export default withTheme(Subjects);
