import { assoc, assocPath } from 'ramda';

import * as types from './types';

export const INITIAL_STATE = {
  isCreateFormOpen: false,
  isEditFormOpen: null,
  teamGroups: {
    loading: false,
  },
  userGroups: {
    loading: false,
  },
  teamGroupIds: [],
  userGroupIds: [],
};

const closeCreateFormReducer = state => assoc('isCreateFormOpen', false, state);
const openCreateFormReducer = state => assoc('isCreateFormOpen', true, state);
const closeEditFormReducer = state => assoc('isEditFormOpen', null, state);
const openEditFormReducer = (state, { group }) => assoc('isEditFormOpen', group.id, state);

const setTeamGroupsLoadingReducer = state => assocPath(['teamGroups', 'loading'], true, state);

const setTeamGroupsPayloadReducer = (state, action) => {
  const newState = assoc('teamGroups', { ...action.data, loading: false }, state);
  return assoc('teamGroupIds', action.data.data.map(l => l.id), newState);
};

const setUserGroupsLoadingReducer = state => assocPath(['userGroups', 'loading'], true, state);

const setUserGroupsPayloadReducer = (state, action) => {
  const newState = assoc('userGroups', { ...action.data, loading: false }, state);
  return assoc('userGroupIds', action.data.data.map(l => l.id), newState);
};

const addBrandsToGroupReducer = (state, { groupId, brands }) => {
  const { teamGroups, userGroups } = state;
  const teamGroupIndex = teamGroups.data.findIndex(l => l.id === groupId);
  const userGroupIndex = userGroups.data.findIndex(l => l.id === groupId);

  let teamGroup = null;
  if (teamGroupIndex !== -1) {
    teamGroup = teamGroups.data[teamGroupIndex];
    const teamBrandsCount = teamGroup.attributes.approximate_companies + brands.length;
    teamGroup.attributes.approximate_companies = teamBrandsCount;
    teamGroup.relationships.companies.data = teamGroup.relationships.companies.data.concat(brands);
  }

  let userGroup = null;
  if (userGroupIndex !== -1) {
    userGroup = userGroups.data[userGroupIndex];
    const userBrandsCount = userGroup.attributes.approximate_companies + brands.length;
    userGroup.attributes.approximate_companies = userBrandsCount;
    userGroup.relationships.companies.data = userGroup.relationships.companies.data.concat(brands);
  }

  const newState1 = teamGroup
    ? assocPath(['teamGroups', 'data', teamGroupIndex], teamGroup, state)
    : state;

  const newState2 = userGroup
    ? assocPath(['userGroups', 'data', userGroupIndex], userGroup, newState1)
    : newState1;

  return newState2;
};

const removeBrandsToGroupReducer = (state, { groupId, brands }) => {
  const { teamGroups, userGroups } = state;
  const brandIds = brands.map(b => b.id);
  const teamGroupIndex = teamGroups.data.findIndex(l => l.id === groupId);
  const userGroupIndex = userGroups.data.findIndex(l => l.id === groupId);

  let teamGroup = null;
  if (teamGroupIndex !== -1) {
    teamGroup = teamGroups.data[teamGroupIndex];
    const teamBrandsCount = teamGroup.attributes.approximate_companies - brands.length;
    teamGroup.attributes.approximate_companies = teamBrandsCount;
    teamGroup.relationships.companies.data = teamGroup.relationships.companies.data.filter(
      b => !brandIds.includes(b.id),
    );
  }

  let userGroup = null;
  if (userGroupIndex !== -1) {
    userGroup = userGroups.data[userGroupIndex];
    const userBrandsCount = userGroup.attributes.approximate_companies - brands.length;
    userGroup.attributes.approximate_companies = userBrandsCount;
    userGroup.relationships.companies.data = userGroup.relationships.companies.data.filter(
      b => !brandIds.includes(b.id),
    );
  }

  const newState1 = teamGroup
    ? assocPath(['teamGroups', 'data', teamGroupIndex], teamGroup, state)
    : state;

  const newState2 = userGroup
    ? assocPath(['userGroups', 'data', userGroupIndex], userGroup, newState1)
    : newState1;

  return newState2;
};

const reducer = {
  [types.CLOSE_CREATE_FORM]: closeCreateFormReducer,
  [types.OPEN_CREATE_FORM]: openCreateFormReducer,
  [types.CLOSE_EDIT_FORM]: closeEditFormReducer,
  [types.OPEN_EDIT_FORM]: openEditFormReducer,
  [types.CREATE_SUCCESS]: closeCreateFormReducer,
  [types.UPDATE_SUCCESS]: closeEditFormReducer,
  [types.FETCH_USER_GROUPS_REQUEST]: setUserGroupsLoadingReducer,
  [types.FETCH_USER_GROUPS_SUCCESS]: setUserGroupsPayloadReducer,
  [types.FETCH_TEAM_GROUPS_REQUEST]: setTeamGroupsLoadingReducer,
  [types.FETCH_TEAM_GROUPS_SUCCESS]: setTeamGroupsPayloadReducer,
  [types.SHARE_WITH_TEAM_SUCCESS]: setTeamGroupsPayloadReducer,
  [types.DELETE_TEAM_GROUP_SUCCESS]: setTeamGroupsPayloadReducer,
  [types.UPDATE_TEAM_GROUP_SUCCESS]: closeEditFormReducer,
  [types.ADD_BRANDS_TO_GROUP_SUCCESS]: addBrandsToGroupReducer,
  [types.REMOVE_BRANDS_TO_GROUP_SUCCESS]: removeBrandsToGroupReducer,
};

export default (state = INITIAL_STATE, action = {}) => {
  const reducerFunction = reducer[action.type];

  if (!reducerFunction) return state;
  return reducerFunction(state, action);
};
