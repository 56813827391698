import React from 'react';
import PropTypes from 'prop-types';
import { camelizeKeys } from 'humps';
import { connect } from 'react-redux';

import {
  customListsActions as actions,
  customListsSelectors as selectors,
} from '../../../modules/customLists';

import { Box } from '../../mc-ui';

import ControlBar from '../ControlBar';

import MyLists from './MyLists';
import TeamLists from './TeamLists';

class CustomLists extends React.PureComponent {
  state = {
    loading: true,
  };

  componentDidUpdate(prevProps) {
    const { userListsLoading, teamListsLoading } = this.props;
    const userListsLoaded = prevProps.userListsLoading && !userListsLoading;
    const teamListsLoaded = prevProps.teamListsLoading && !teamListsLoading;
    if (userListsLoaded && this.props.userLists.length > 0) {
      this.props.fetchTeamLists({ list_type: this.props.listType });
    }

    if (userListsLoaded || teamListsLoaded) {
      this.setLoading(false);
    }
  }

  setLoading = value => {
    this.setState({ loading: value });
  };

  render() {
    const { collectionType, fetchTeamLists, fetchUserLists, listType, router } = this.props;
    const { loading } = this.state;

    if (!collectionType) return null;

    return (
      <Box>
        <ControlBar collectionType={collectionType} />
        {collectionType === 'my' ? (
          <MyLists
            listType={listType}
            parentLoading={loading}
            request={fetchUserLists}
            router={router}
          />
        ) : (
          <TeamLists
            listType={listType}
            parentLoading={loading}
            request={fetchTeamLists}
            router={router}
          />
        )}
      </Box>
    );
  }
}

CustomLists.defaultProps = {
  collectionType: null,
};

CustomLists.propTypes = {
  collectionType: PropTypes.string,
  fetchTeamLists: PropTypes.func.isRequired,
  fetchUserLists: PropTypes.func.isRequired,
  listType: PropTypes.string.isRequired,
  router: PropTypes.shape({}).isRequired,
  teamListsLoading: PropTypes.bool.isRequired,
  userLists: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  userListsLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  teamListsLoading: selectors.isTeamListsLoadingSelector(state),
  userLists: camelizeKeys(selectors.userListsDataSelector(state)),
  userListsLoading: selectors.isUserListsLoadingSelector(state),
});

const mapDispatchToProps = dispatch => ({
  fetchTeamLists: (...args) => dispatch(actions.fetchTeamListsRequest(...args)),
  fetchUserLists: (...args) => dispatch(actions.fetchUserListsRequest(...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomLists);
