import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Flex, Loader } from '../mc-ui';

import { fetchGroups } from './utils/api';

import GroupViews from './GroupViews';
import Navigation from './Navigation';

class AllIndustries extends React.PureComponent {
  state = {
    groups: [],
    loading: true,
    selectedGroup: null,
  };

  componentDidMount() {
    this.request();
  }

  request = async () => {
    const groups = await fetchGroups();

    this.setState(() => ({
      groups,
      loading: false,
      selectedGroup: groups[0],
    }));
  };

  selectGroup = id => event => {
    event.preventDefault();
    const group = this.state.groups.find(g => g.id === id);
    this.setState(() => ({ selectedGroup: group }));
  };

  render() {
    const { type } = this.props;
    const { groups, loading, selectedGroup } = this.state;

    if (loading) {
      return (
        <Flex alignItems="center" height="676px" justifyContent="center">
          <Loader />
        </Flex>
      );
    }

    return (
      <Flex justifyContent="space-between">
        <Navigation
          items={groups}
          loading={loading}
          onClick={this.selectGroup}
          selectedItem={selectedGroup}
          type={type}
        />

        <GroupViews group={selectedGroup} type={type} />
      </Flex>
    );
  }
}

AllIndustries.propTypes = {
  type: PropTypes.string.isRequired,
};

export default AllIndustries;
