import * as React from 'react';
import PropTypes from 'prop-types';

function CrossSmallIcon({ fillOpacity, ...props }) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M17.674 16.084l-4.081-4.082 4.081-4.08c.44-.442.44-1.15 0-1.592-.44-.44-1.15-.44-1.59 0l-4.082 4.082-4.08-4.082c-.442-.44-1.15-.44-1.592 0-.44.441-.44 1.15 0 1.591l4.082 4.081-4.082 4.082c-.44.44-.44 1.15 0 1.59.441.44 1.15.44 1.591 0l4.081-4.081 4.082 4.081c.44.44 1.15.44 1.59 0a1.128 1.128 0 000-1.59z"
        fill="currentColor"
        fillOpacity={fillOpacity}
      />
    </svg>
  );
}

CrossSmallIcon.propTypes = {
  fillOpacity: PropTypes.number,
};

CrossSmallIcon.defaultProps = {
  fillOpacity: 1,
};

export default CrossSmallIcon;
