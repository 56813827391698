import React from 'react';
import PropTypes from 'prop-types';
import styled from "@emotion/styled";
import { Link } from 'react-router';

import { Box, Button, Flex, Heading } from '../../mc-ui';

const Wrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.colors.white,
  borderRadius: '4px',
  marginBottom: '16px',
  padding: '32px',
}));

const Title = ({ reportName, title, tooltip: Tooltip }, { router }) => {
  const {
    location: { pathname },
  } = router;

  let seeReport = `${pathname.replace('/overview', '')}/${reportName}`;
  if (pathname === '/') {
    seeReport = `/dashboard/${reportName}`;
  }

  return (
    <Flex alignItems="center" justifyContent="space-between" marginBottom="24px">
      <Heading as="h4" color="dark08">
        {title}
        {Tooltip && <Tooltip />}
      </Heading>

      <Button
        as={Link}
        to={{ pathname: seeReport }}
        css={{ fontSize: '12px', lineHeight: '1.25em', padding: '0.25em 0.65em' }}
      >
        See report
      </Button>
    </Flex>
  );
};

Title.contextTypes = {
  router: PropTypes.object,
};

Title.defaultProps = {
  tooltip: null,
};

Title.propTypes = {
  reportName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.object]),
};

const Section = props => <Wrapper {...props} />;
Section.Title = Title;

export default Section;
