import ReactTooltip from 'react-tooltip';
import styled from "@emotion/styled";

function getTooltipTheme(dark, theme, variant) {
  if (dark) {
    return theme.tooltip.dark;
  } else if (variant === 'blue') {
    return theme.tooltip.lightBlue;
  }

  return theme.tooltip.light;
}

const StyledTooltip = styled(ReactTooltip)(({ dark, theme, variant, width, id }) => {
  const tooltipTheme = getTooltipTheme(dark, theme, variant);

  return {
    backgroundColor: `${tooltipTheme.backgroundColor} !important`,
    border: `${tooltipTheme.border} !important`,
    borderRadius: `${tooltipTheme.borderRadius} !important`,
    boxShadow: `${tooltipTheme.boxShadow} !important`,
    boxSizing: `${tooltipTheme.boxSizing} !important`,
    color: `${tooltipTheme.text.color} !important`,
    fontWeight: 'initial !important',
    textTransform: 'none  !important',
    maxWidth: width || tooltipTheme.maxWidth,
    opacity: '1 !important',
    padding: `${tooltipTheme.padding}px !important`,
    pointerEvents: 'auto !important',
    zIndex: '9997 !important',
    '& > h1, & > h2, & > h3, & > h4, & > h5': {
      fontWeight: 'initial !important',
      margin: 0,
      padding: 0,
      textTransform: 'none !important',
      ...tooltipTheme.title,
    },
    '& > p, & > a, & > p a': {
      fontWeight: 'initial !important',
      textTransform: 'none !important',
      ...tooltipTheme.text,
    },
    '& strong': {
      fontWeight: 'bold !important',
    },
    '& > button, & > .pure-button': {
      fontWeight: 'initial !important',
      textTransform: 'none !important',
      ...tooltipTheme.button,
      '&:hover': {
        ...tooltipTheme.button,
      },
      '&.pure-button-primary': {
        ...theme.button.primary,
        width: 'unset',
      },
    },
    // Arrow
    '&.place-top': {
      '&:after': {
        borderTopColor: `${tooltipTheme.backgroundColor} !important`,
      },
      '&:before': {
        borderTop: `6px solid ${tooltipTheme.borderColor} !important`,
      },
    },
    '&.place-left': {
      '&:after': {
        borderLeftColor: `${tooltipTheme.backgroundColor} !important`,
      },
      '&:before': {
        borderLeft: `6px solid ${tooltipTheme.borderColor} !important`,
      },
    },
    '&.place-bottom': {
      '&:after': {
        borderBottomColor: `${tooltipTheme.backgroundColor} !important`,
      },
      '&:before': {
        borderBottom: `6px solid ${tooltipTheme.borderColor} !important`,
      },
    },
    '&.place-right': {
      '&:after': {
        borderRightColor: `${tooltipTheme.backgroundColor} !important`,
      },
      '&:before': {
        borderRight: `6px solid ${tooltipTheme.borderColor} !important`,
      },
    },
    // Visibility
    '&:hover': {
      opacity: '1 !important',
      visibility: 'visible !important',
    },
  };
});

export default StyledTooltip;
