import api from '../lib/api';

export default store => next => action => {
  next(action);
  switch (action.type) {
    case 'REQUEST_EMAIL':
      const { params = {}, guid } = action;
      api.get(`/v3/emails/${guid}`, { params }).then(r => {
        let emails = store.getState().emails.data;
        if (store.getState().emails.data.length < 1 && r.data.data[0]) {
          emails = (r.data.data[0].relationships.emails || { data: [] }).data;
          store.dispatch({ type: 'RECEIVE_RELATED_EMAILS', data: emails });
        }
        const index = emails.reduce(
          (m, v, i) => (v.attributes.guid == action.guid ? i : m),
          undefined,
        );
        const prev = index - 1 > -1 ? index - 1 : undefined;
        const nxt = index + 1 < emails.length ? index + 1 : undefined;
        const data = r.data || {};
        store.dispatch({
          type: 'RECEIVE_EMAIL',
          data,
          next: emails[nxt],
          previous: emails[prev],
        });
      });
      break;
  }
};
