/** @jsx jsx */
import PropTypes from 'prop-types';
import styled from "@emotion/styled";
import { jsx, withTheme } from '@emotion/react';
import { withProps } from 'recompose';

const ButtonComponent = ({
  as,
  css = {},
  style = {},
  buttonStyle = {},
  className = '',
  ...props
}) => {
  if (!as) {
    throw new Error('Prop `as` is required when rendering any UI component');
  }
  const buttonComponentStyle = {
    ...buttonStyle,
    ...css,
    ...style,
  };

  return withProps(() => props)(styled(as)(() => (buttonComponentStyle)));
};

const Button = ({
  children,
  css,
  theme,
  variant,
  ...props
}) => {
  const buttonStyle = {
    ...theme.button[variant],
    borderRadius: '4px',
    boxSizing: 'border-box',
    cursor: 'pointer',
    fontFamily: theme.font.family,
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: '0.02em',
    margin: 0,
    padding: '.75em 1.243em',
    textAlign: 'center',
    transition: 'border 250ms, background 250ms, color 250ms, opacity 250ms',
    verticalAlign: 'middle',
  };
  const Component = ButtonComponent({ buttonStyle, css, ...props });
  return <Component className={'pure-button'}>{children}</Component>;
};

Button.defaultProps = {
  as: 'button',
  children: null,
  css: {},
  style: {},
  variant: 'primary',
};

Button.propTypes = {
  as: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.func, PropTypes.object]),
  children: PropTypes.node,
  css: PropTypes.shape({}),
  style: PropTypes.shape({}),
  theme: PropTypes.shape({}).isRequired,
  variant: PropTypes.oneOf(['primary', 'secondary', 'purple', 'none', 'dark', 'destructive']),
};

export default withTheme(Button);
