/* eslint-disable react/prop-types */
import React from 'react';
import styled from "@emotion/styled";

import CloseIcon from '../../mc-ui/icons/OldCloseIcon';
import { Heading, Text } from '../../mc-ui';

const Header = styled("header")({
  alignItems: 'baseline',
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '32px 32px 16px 32px',
});

const CloseLink = styled("a")({
  color: '#091E42',
  cursor: 'pointer',
  fontSize: '12px',
});

export default ({ title, subtitle, handleClose }) => (
  <Header>
    <div>
      <Heading
        as="h3"
        css={{
          fontWeight: '300',
          letterSpacing: '0.02em',
          lineHeight: '1.32em',
          margin: 0,
        }}
      >
        {title}
      </Heading>
      {subtitle && (
        <Text
          letterSpacing="0.02em"
          lineHeight="1.56em"
          css={{
            marginTop: '4px',
          }}
        >
          {subtitle}
        </Text>
      )}
    </div>
    <CloseLink onClick={handleClose}>
      <CloseIcon />
    </CloseLink>
  </Header>
);
