import React from 'react';
import PropTypes from 'prop-types';

const QuestionMarkIcon = ({ fillOpacity, ...props }) => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M8 .5A7.5 7.5 0 1015.5 8 7.508 7.508 0 008 .5zm0 11.875A.938.938 0 118 10.5a.938.938 0 010 1.875zm1-3.8a.625.625 0 00-.375.573.625.625 0 01-1.25 0A1.875 1.875 0 018.5 7.43a1.25 1.25 0 10-1.75-1.145.625.625 0 01-1.25 0A2.5 2.5 0 119 8.576v-.001z"
      fill="currentColor"
      fillOpacity={fillOpacity}
    />
  </svg>
);

QuestionMarkIcon.defaultProps = {
  fillOpacity: 0.6,
};

QuestionMarkIcon.propTypes = {
  fillOpacity: PropTypes.number,
};

export default QuestionMarkIcon;
