/* eslint-disable react/prop-types */

import React from 'react';
import { components } from 'react-select';

import BestMatchTooltip from './BestMatchTooltip';

const CustomOption = ({ children, ...props }) => (
  <components.Option {...props}>
    {children}
    &nbsp;
    {children === 'Best Match' && <BestMatchTooltip />}
  </components.Option>
);

export default CustomOption;
