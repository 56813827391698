import styled from "@emotion/styled";

const Badge = styled("span")(({
  backgroundColor,
  border = null,
  borderColor = null,
  boxShadow,
  color,
  css = {},
  display = null,
  fontSize = '10px',
  height = null,
  letterSpacing = '0.12em',
  lineHeight = `${16 / 10}em`,
  margin = null,
  padding = '2px 5px',
  theme,
  width = null,
}) => ({
  backgroundColor: theme.colors[backgroundColor] || backgroundColor,
  borderRadius: '4px',
  border,
  borderColor: theme.colors[borderColor] || borderColor,
  boxShadow,
  boxSizing: 'border-box',
  color: `${theme.colors[color]} !important`,
  display,
  fontFamily: theme.font.family,
  fontSize,
  fontWeight: 'bold',
  height,
  letterSpacing,
  lineHeight,
  margin,
  padding,
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
  width,
  '& > a': {
    color: theme.colors[color],
    textDecoration: 'none',
  },
  ...css,
}));

export default Badge;
