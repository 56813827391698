import { call, put, select } from 'redux-saga/effects';
import { push } from 'react-router-redux';

import { flashMessageActions } from '../flashMessage';
import { AMOUNTS } from '../../components/MyPlan/PlanSwitcher/ProOption';
import { dollar } from '../../lib/utils';

import * as actions from './actions';
import * as api from './api';
import * as selectors from './selectors';

const getUser = state => state.user.data[0];

export function* fetchTeam({ id, filter }) {
  try {
    const userTeam = yield select(selectors.teamSelector);
    if (id || (userTeam && userTeam.attributes.status === 'active')) {
      const response = yield call(api.getTeam, id || userTeam.id, filter);
      console.log(response);
      yield put(actions.fetchTeamSuccess(response.data));

      const [team] = response.data.data;
      const activeMembers = team.relationships.members.meta.counts.active;
      const currentInterval = team.relationships.subscriptions.data[0].attributes ? team.relationships.subscriptions.data[0].attributes.plan_interval : 0;
      const isAnOldPlan = yield select(selectors.isAnOldPlanSelector);
      yield put(actions.subscriptionCalculateRequest(activeMembers, currentInterval, isAnOldPlan));
    }
  } catch (error) {
    console.log('teams/sagas › fetchTeam › error', error);
    yield put(actions.fetchTeamFailure(error));
  }
}

export function* updateTeam({ payload, callback }) {
  try {
    const team = yield select(selectors.teamDataSelector);
    const teamPayload = {
      team: {
        attributes: {
          ...team.attributes,
          ...payload,
        },
        relationships: {
          subscriptions: {
            ...team.relationships.subscriptions,
          },
        },
      },
    };
    const response = yield call(api.updateTeam, team.id, teamPayload);
    yield put(actions.updateTeamSuccess(response.data));

    // Passing false as the 2nd argument will avoid the table blink when loading the new data from
    // the API
    yield put(actions.fetchTeamRequest(team.id, { updateLoading: false }));
    yield put({ type: 'REFRESH_USER' });
    if (callback) {
      yield call(callback);
    }
    yield put(
      flashMessageActions.showSuccess({
        title: "Your team's name was updated successfully!",
      }),
    );
  } catch (error) {
    console.log('teams/sagas › updateTeam › error', error);
    yield put(actions.updateTeamFailure(error));
  }
}

export function* inviteMember({ teamId, payload }) {
  try {
    const response = yield call(api.inviteTeamMember, teamId, payload);
    yield put(actions.inviteMemberSuccess(response.data));
    yield put(
      flashMessageActions.showSuccess({
        title: 'Invitation was sent successfully!',
      }),
    );

    // Passing false as the 2nd argument will avoid the table blink when loading the new data from
    // the API
    yield put(actions.fetchTeamRequest(teamId, { updateLoading: false }));
  } catch (error) {
    console.log('teams/sagas › inviteMember › error', error);
    yield put(actions.inviteMemberFailure(error));
  }
}

export function* activateMember({ teamId, id }) {
  try {
    const response = yield call(api.activateTeamMember, teamId, id);
    yield put(actions.updateMemberSuccess(response.data));

    // Passing false as the 2nd argument will avoid the table blink when loading the new data from
    // the API
    yield put(actions.fetchTeamRequest(teamId, { updateLoading: false }));
    yield put(
      flashMessageActions.showSuccess({
        title: 'Member was activated successfully!',
      }),
    );
  } catch (error) {
    console.log('teams/sagas › activateMember › error', error);
    yield put(actions.updateMemberFailure(error));
  }
}

export function* archiveMember({ teamId, id }) {
  try {
    yield call(api.archiveTeamMember, teamId, id);
    yield put(actions.archiveMemberSuccess(id));

    yield put(
      flashMessageActions.showSuccess({
        title: 'Member was archived successfully!',
      }),
    );

    // Passing false as the 2nd argument will avoid the table blink when loading the new data from
    // the API
    yield put(actions.fetchTeamRequest(teamId, { updateLoading: false }));
  } catch (error) {
    console.log('teams/sagas › archiveMember › error', error);
    yield put(actions.archiveMemberFailure(error));
  }
}

export function* removeMember({ teamId, id }) {
  try {
    const user = yield select(getUser);
    yield call(api.removeTeamMember, teamId, id);
    yield put(actions.removeMemberSuccess(id));

    yield put(
      flashMessageActions.showSuccess({
        title:
          user.id === id ? 'You left the team successfully!' : 'Member was removed successfully!',
      }),
    );

    // Passing false as the 2nd argument will avoid the table blink when loading the new data from
    // the API
    yield put(actions.fetchTeamRequest(teamId, { updateLoading: false }));

    if (user.id === id) {
      yield put(push('/manage'));
      yield put({ type: 'REFRESH_USER' });
    }
  } catch (error) {
    console.log('teams/sagas › removeMember › error', error);
    yield put(actions.removeMemberFailure(error));
  }
}

export function* updateMember({ teamId, id, payload }) {
  try {
    const response = yield call(api.updateTeamMember, teamId, id, payload);
    yield put(actions.updateMemberSuccess(response.data));

    // Passing false as the 2nd argument will avoid the table blink when loading the new data from
    // the API
    yield put(actions.fetchTeamRequest(teamId, { updateLoading: false }));
    yield put(
      flashMessageActions.showSuccess({
        title: 'Member was updated successfully!',
      }),
    );
  } catch (error) {
    console.log('teams/sagas › updateMember › error', error);
    yield put(actions.updateMemberFailure(error));
  }
}

export function* cancelInvitation({ teamId, id }) {
  try {
    const response = yield call(api.cancelInvitation, teamId, id);
    yield put(actions.cancelInvitationSuccess(response.data));
    yield put(
      flashMessageActions.showSuccess({
        title: 'Invitation was canceled successfully!',
      }),
    );

    // Passing false as the 2nd argument will avoid the table blink when loading the new data from
    // the API
    yield put(actions.fetchTeamRequest(teamId, { updateLoading: false }));
  } catch (error) {
    console.log('teams/sagas › cancelInvitation › error', error);
    yield put(actions.cancelInvitationFailure(error));
  }
}

export function* resendInvitation({ teamId, id }) {
  try {
    const response = yield call(api.resendInvitation, teamId, id);
    yield put(actions.resendInvitationSuccess(response.data));
    yield put(
      flashMessageActions.showSuccess({
        title: 'Invitation was resent successfully!',
      }),
    );
  } catch (error) {
    console.log('teams/sagas › resendInvitation › error', error);
    yield put(actions.resendInvitationFailure(error));
  }
}

// ******************** SUBSCRIPTION ********************
export function* createSubscription({ payload, callback }) {
  try {
    const subscription = yield select(selectors.subscriptionSelector);
    const team = yield select(selectors.teamSelector);
    const response = yield call(api.createSubscription, payload, subscription, team);
    yield put(actions.createSubscriptionSuccess(response.data));
    const teamId = response.data.data[0].id;

    // Passing false as the 2nd argument will avoid the table blink when loading the new data from
    // the API
    yield put(actions.fetchTeamRequest(teamId, { updateLoading: false }));
    yield put({ type: 'REFRESH_USER' });
    yield call(callback, true);
  } catch (error) {
    console.log('teams/sagas › createSubscription › error', error);
    yield put(actions.createSubscriptionFailure(error));
    yield call(callback, false);
  }
}

export function* requestSetupIntent({}){
  try {
    let response = yield call(api.getSetupIntent);
    yield put(actions.setupPaymentIntent(response.data.setupPaymentIntentResponse));
  } catch (error){
    console.log('teams/sagas › getSetupIntent › error', error)
  }
}

export function* getSubscriptionPaymentInfo({subscriptionId}){
  try {
    let response = yield call(api.subscriptionPaymentInfo, subscriptionId);
    yield put(actions.setupPaymentInfo(response.data.paymentInfo));
  } catch (error) {
    console.log('teams/sagas › getSubscriptionPaymentInfo › error', error);
  }
}

export function* subscriptionUpdateCreditCard({ payload, callback }) {
  try {
    const subscription = yield select(selectors.subscriptionSelector);
    const team = yield select(selectors.teamSelector);
    const planExpired = yield select(selectors.planExpiredSelector);
    yield call(api.subscriptionUpdateCreditCard, subscription.id, payload);
    if (payload.coupon) {
      yield call(api.subscriptionUpdate, subscription.id, { coupon: payload.coupon });
    }
    yield put(actions.subscriptionUpdateCreditCardSuccess());

    const isNotActive = team && team.attributes.account_status !== 'active';

    // If current subscription is canceled, past_due or trialing we want to make sure that it ends
    // up active and updated
    if (isNotActive || planExpired) {
      yield put(actions.subscriptionReactivateRequest());
      yield put(actions.subscriptionUpdateRequest(payload));
    }

    // Passing false as the 2nd argument will avoid the table blink when loading the new data from
    // the API
    yield put(actions.fetchTeamRequest(team.id, { updateLoading: false }));
    yield put({ type: 'REFRESH_USER' });
    yield call(callback);
  } catch (error) {
    console.log('teams/sagas › subscriptionUpdateCreditCard › error', error);
    yield put(actions.subscriptionUpdateCreditCardFailure(error));
    yield call(callback);
  }
}

// Use `current` when calculating an old plan
export function* subscriptionCalculate({ interval, quantity, current }) {
  try {
    const subscription = yield select(selectors.subscriptionSelector);
    const isSubscriptionAutomaticallyCharged = yield select(
      selectors.isSubscriptionAutomaticallyChargedSelector,
    );

    if (isSubscriptionAutomaticallyCharged && subscription.attributes.account_status === 'active') {
      const response = yield call(
        api.subscriptionCalculate,
        subscription.id,
        interval,
        quantity,
        current,
      );

      yield put(actions.subscriptionCalculateSuccess(response.data));
    }
  } catch (error) {
    console.log('teams/sagas › subscriptionCalculate › error', error);
    yield put(actions.subscriptionCalculateFailure(error));
  }
}

export function* subscriptionUpdate({ payload, resolve }) {
  try {
    const subscription = yield select(selectors.subscriptionSelector);
    const team = yield select(selectors.teamSelector);
    yield call(api.subscriptionUpdate, subscription.id, payload);
    yield put(actions.subscriptionUpdateSuccess());

    // Passing false as the 2nd argument will avoid the table blink when loading the new data from
    // the API
    yield put(actions.fetchTeamRequest(team.id, { updateLoading: false }));
    yield put({ type: 'REFRESH_USER' });
    if (resolve) {
      yield call(resolve);
    }
  } catch (error) {
    console.log('teams/sagas › subscriptionUpdate › error', error);
    yield put(actions.subscriptionUpdateFailure(error));
    if (resolve) {
      yield call(resolve);
    }
  }
}

export function* subscriptionAddons({ payload, resolve }) {
  try {
    const subscription = yield select(selectors.subscriptionSelector);
    const team = yield select(selectors.teamSelector);
    yield call(api.subscriptionAddons, subscription.id, payload);
    yield put(actions.subscriptionUpdateSuccess());

    // Passing false as the 2nd argument will avoid the table blink when loading the new data from
    // the API
    yield put(actions.fetchTeamRequest(team.id, { updateLoading: false }));
    yield put({ type: 'REFRESH_USER' });
    if (resolve) {
      yield call(resolve);
    }
  } catch (error) {
    console.log('teams/sagas › subscriptionAddon › error', error);
    yield put(actions.subscriptionUpdateFailure(error));
    if (resolve) {
      yield call(resolve);
    }
  }
}

export function* subscriptionMigrate({ payload, resolve }) {
  try {
    const subscription = yield select(selectors.subscriptionSelector);
    const team = yield select(selectors.teamSelector);
    yield call(api.subscriptionMigrate, subscription.id, payload);
    yield put(actions.subscriptionUpdateSuccess());

    // Passing false as the 2nd argument will avoid the table blink when loading the new data from
    // the API
    yield put(actions.fetchTeamRequest(team.id, { updateLoading: false }));
    yield put({ type: 'REFRESH_USER' });
    if (resolve) {
      yield call(resolve);
    }
  } catch (error) {
    console.log('teams/sagas › subscriptionMigrate › error', error);
    yield put(actions.subscriptionUpdateFailure(error));
    if (resolve) {
      yield call(resolve);
    }
  }
}

export function* subscriptionUpdateBillingInfo({ payload, form, resolve }) {
  try {
    const subscription = yield select(selectors.subscriptionSelector);
    const team = yield select(selectors.teamSelector);
    yield call(api.subscriptionUpdateBillingInfo, subscription.id, payload);
    yield put(actions.subscriptionUpdateBillingInfoSuccess());

    // Passing false as the 2nd argument will avoid the table blink when loading the new data from
    // the API
    yield put(actions.fetchTeamRequest(team.id, { updateLoading: false }));
    yield put({ type: 'REFRESH_USER' });
    yield call(resolve);
    yield call(form.reset);
  } catch (error) {
    console.log('teams/sagas › subscriptionUpdateBillingInfo › error', error);
    yield put(actions.subscriptionUpdateBillingInfoFailure(error));
    yield call(resolve);
  }
}

export function* subscriptionCancel({ resolve }) {
  try {
    const subscription = yield select(selectors.subscriptionSelector);
    const team = yield select(selectors.teamSelector);
    yield call(api.subscriptionCancel, subscription.id);
    yield put(actions.subscriptionCancelSuccess());

    // Passing false as the 2nd argument will avoid the table blink when loading the new data from
    // the API
    yield put(actions.fetchTeamRequest(team.id, { updateLoading: false }));
    yield put({ type: 'REFRESH_USER' });
    yield call(resolve);
  } catch (error) {
    console.log('teams/sagas › subscriptionCancel › error', error);
    yield put(actions.subscriptionCancelFailure(error));
    yield call(resolve);
  }
}

export function* subscriptionReactivate({ callback }) {
  try {
    const subscription = yield select(selectors.subscriptionSelector);
    const team = yield select(selectors.teamSelector);
    yield call(api.subscriptionReactivate, subscription.id);
    yield put(actions.subscriptionReactivateSuccess());

    // Passing false as the 2nd argument will avoid the table blink when loading the new data from
    // the API
    yield put(actions.fetchTeamRequest(team.id, { updateLoading: false }));
    yield put({ type: 'REFRESH_USER' });
    if (callback) {
      callback();
    }
  } catch (error) {
    console.log('teams/sagas › subscriptionReactivate › error', error);
    yield put(actions.subscriptionReactivateFailure(error));
  }
}

export function* invitationAccept({ resolve }) {
  try {
    const team = yield select(selectors.teamSelector);
    yield call(api.invitationAccept, team.attributes.invitation_guid);
    yield put(actions.invitationAcceptSuccess());
    yield put(actions.fetchTeamRequest(team.id));
    yield put({ type: 'REFRESH_USER' });
    yield call(resolve);
  } catch (error) {
    console.log('teams/sagas › invitationAccept › error', error);
    yield put(actions.invitationAcceptFailure(error));
    yield call(resolve);
  }
}

export function* invitationDecline({ resolve }) {
  try {
    const team = yield select(selectors.teamSelector);
    yield call(api.invitationDecline, team.attributes.invitation_guid);
    yield put(actions.invitationDeclineSuccess());
    yield put(push('/manage'));
    yield put({ type: 'REFRESH_USER' });
    yield call(resolve);
  } catch (error) {
    console.log('teams/sagas › invitationDecline › error', error);
    yield put(actions.invitationDeclineFailure(error));
    yield call(resolve);
  }
}
