import React from 'react';

import Body from '../Body';

import AveragePromotionRate from './averagePromotionRate';
import AveragePromotionRateByBrands from './averagePromotionRateByBrands';
import PromotionType from './promotionType';

const GroupContainer = props => (
  <Body>
    <AveragePromotionRate {...props} />
    <AveragePromotionRateByBrands {...props} />
    <PromotionType {...props} />
  </Body>
);

export default GroupContainer;
