import React from 'react';

import { Flex } from '../mc-ui';

import DataAndInsights from './DataAndInsights';
import LatestEmails from './LatestEmails';

const BrandOverview = props => (
  <Flex direction="column" marginTop="1em">
    <LatestEmails {...props} />
    <DataAndInsights {...props} />
  </Flex>
);

export default BrandOverview;
