import React from 'react';
import PropTypes from 'prop-types';

import { Flex } from '../mc-ui';

import Header from './brandsWeekTable/Header';
import Logo from './brandsWeekTable/Logo';
import Row from './brandsWeekTable/Row';
import VerticalBar from './brandsWeekTable/VerticalBar';

const BrandsWeekTable = ({ children }) => <Flex direction="column">{children}</Flex>;

BrandsWeekTable.Bar = VerticalBar;
BrandsWeekTable.Header = Header;
BrandsWeekTable.Logo = Logo;
BrandsWeekTable.Row = Row;

BrandsWeekTable.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BrandsWeekTable;
