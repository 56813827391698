import React from 'react';
import PropTypes from 'prop-types';

import Active from './Active';
import Archived from './Archived';
import InProgress from './InProgress';
import Regional from './Regional';
import Reviewing from './Reviewing';

export default function BrandTag({ state }) {
  switch (state) {
    case 'active':
      return <Active />;
    case 'archived':
      return <Archived />;
    case 'in_progress':
      return <InProgress />;
    case 'regional':
      return <Regional />;
    case 'reviewing':
      return <Reviewing />;
    default:
      return null;
  }
}

BrandTag.propTypes = {
  state: PropTypes.string.isRequired,
};
