import React from 'react'
import { Link } from 'react-router'
import { connect } from 'react-redux'

import Section from './Section'

class Segments extends React.Component {
  requestCompany(props) {
    let query = {
      ...props.router.location.query,
      ...props.router.params
    }
    let { address_id } = props.router.params
    this.props.dispatch({ type: 'REQUEST_ADDRESS', address_id, query })
  }
  UNSAFE_componentWillReceiveProps(props) {
    if (
        props.params.address_id != this.props.params.address_id ||
        props.router.location.query != this.props.router.location.query
        )
      this.requestCompany(props)
  }
  componentDidMount() {
    this.requestCompany(this.props)
  }
  render() {
    return this.props.addresses.loading ? null :
      (<div className='pure-u-1'>
        <Section>
          {this.props.children}
        </Section>
      </div>
    )
  }
}

const s = (state) => ({ addresses: state.addresses })
export default connect(s)(Segments)
