import * as React from 'react';
import PropTypes from 'prop-types';

function WarningCircleIcon({ fillOpacity, ...props }) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M20.996 11.844a9.033 9.033 0 00-2.668-6.31 8.735 8.735 0 00-6.344-2.532 9.153 9.153 0 00-6.38 2.74A8.852 8.852 0 003 12.156 8.833 8.833 0 0011.851 21h.162a9.082 9.082 0 008.983-9.156zM10.909 7.64a1.09 1.09 0 012.18 0v4.903a1.09 1.09 0 11-2.18 0V7.64zm1.114 9.832h-.024a1.362 1.362 0 01-.023-2.725h.022a1.362 1.362 0 11.022 2.725h.003z"
        fill="currentColor"
        fillOpacity={fillOpacity}
      />
    </svg>
  );
}

WarningCircleIcon.propTypes = {
  fillOpacity: PropTypes.number,
};

WarningCircleIcon.defaultProps = {
  fillOpacity: 1,
};

export default WarningCircleIcon;
