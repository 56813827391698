import colors from './colors';

const button = {
  primary: {
    backgroundColor: colors.blue,
    border: `1px solid ${colors.blue}`,
    color: colors.white,
    '&:hover': {
      backgroundColor: colors.blueMedium,
      border: `1px solid ${colors.blueMedium}`,
      color: colors.white,
    },
  },
  secondary: {
    backgroundColor: colors.white,
    border: `1px solid ${colors.dark5}`,
    color: colors.dark08,
    '&:hover': {
      border: `1px solid ${colors.dark4}`,
      color: colors.dark1,
    },
  },
  purple: {
    backgroundColor: colors.purpleMedium,
    border: `1px solid ${colors.purpleMedium}`,
    color: colors.white,
    '&:hover': {
      backgroundColor: colors.purpleMedium,
      border: `1px solid ${colors.purpleMedium}`,
      color: colors.white,
      opacity: '0.9',
    },
  },
  none: {
    backgroundColor: 'transparent',
    border: 'none',
    color: colors.dark08,
    '&:hover': {
      backgroundColor: 'transparent',
      border: 'none',
    },
  },
  dark: {
    backgroundColor: colors.dark1,
    border: `1px solid ${colors.dark1}`,
    color: colors.white,
    '&:hover': {
      backgroundColor: colors.dark2,
      border: `1px solid ${colors.dark2}`,
      color: colors.white,
    },
  },
  destructive: {
    backgroundColor: colors.white,
    border: `1px solid ${colors.dark5}`,
    color: colors.redDark,
    '&:hover': {
      border: `1px solid ${colors.redDark}`,
      color: colors.redDark,
    },
  },
};
export default button;
