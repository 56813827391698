import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';

import { Flex } from '../../../../mc-ui';
import EmailThumbnail from '../../../../ui/EmailThumbnail';

import EmptyList from './EmptyList';

const EmailsList = ({ data }) => {
  if (isEmpty(data)) return <EmptyList />;

  return (
    <Flex justifyContent="space-between">
      {data.emails.map(email => {
        const brand = {
          id: email.attributes.company_id,
          attributes: {
            name: email.attributes.company_name,
          },
        };

        return <EmailThumbnail key={email.id} brand={brand} email={email} />;
      })}
    </Flex>
  );
};

EmailsList.propTypes = {
  data: PropTypes.shape({
    emails: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        attributes: PropTypes.shape({
          company_id: PropTypes.number,
          company_name: PropTypes.string,
        }),
      }),
    ),
  }).isRequired,
};

export default EmailsList;
