import styled from "@emotion/styled";

import { Tag as MCTag } from '../../mc-ui';

export const Label = styled("span")(({ theme }) => ({
  alignItems: 'center',
  color: theme.colors.dark08,
  fontFamily: theme.font.family,
  fontSize: '12px',
  fontWeight: '500',
  height: '23px',
  letterSpacing: '0.02em',
  linHight: '23px',
  marginRight: '4px',
  marginTop: '8px',
  padding: '0',
  textTransform: 'capitalize',
}));

export const Tag = styled(MCTag)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.colors.white,
  color: theme.colors.dark08,
  cursor: 'pointer',
  display: 'inline-flex',
  fontSize: '12px',
  fontWeight: '500',
  height: '23px',
  letterSpacing: '0.02em',
  lineHeight: '23px',
  marginRight: '8px',
  marginTop: '8px',
  padding: '0 8px',
  textTransform: 'capitalize',
  '& > svg': {
    color: theme.colors.dark06,
    marginLeft: '12px',
  },
}));

export const Clear = styled(Tag)(({ theme }) => ({
  color: theme.colors.red1,
  margin: 0,
}));
