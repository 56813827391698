import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { isEmpty, isNil } from 'ramda';

import {
  journeysActions as actions,
  journeysSelectors as selectors,
} from '../../../modules/journeys';
import { shallowEqual } from '../../../lib/utils';
import { userSelectors } from '../../../modules/user';

import { Box } from '../../mc-ui';
import Unlocked from '../../mc-ui/Unlocked';

import Filters from './Filters';
import Header from './Header';
import NoTriggeredEmailsReceived from './NoTriggeredEmailsReceived';
import Restricted from './Restricted';
import Thumbnails from './Thumbnails';
import Timeline from './Timeline';
import Upcoming from './Upcoming';
import BrowseMore from './BrowseMore';

const DEFAULT_FILTER = 'classified';
const DEFAULT_VIEW = 'thumbnail';

const isNotPresent = val => isEmpty(val) || isNil(val);
const isInTheFuture = date => moment(date).isAfter(moment(new Date()));

class ShowJourney extends React.Component {
  state = {
    filter: this.props.location.query.filter || DEFAULT_FILTER,
    view: this.props.location.query.view || DEFAULT_VIEW,
  };

  componentDidMount() {
    this.updateQuery({ mount: true });
    if (this.props.model.id) this.request();
  }

  componentDidUpdate(prevProps) {
    if (!this.props.model.id) return;

    const modelChanged =
      this.props.model.id &&
      this.props.model.id !== prevProps.model.id &&
      this.props.model.type !== prevProps.model.type;
    const queryChanged = !shallowEqual(this.props.location.query, prevProps.location.query);

    if (modelChanged || queryChanged) {
      this.request();
    }
  }

  changeView = view => () => {
    this.setState(
      () => ({
        view,
      }),
      this.updateQuery,
    );
  };

  request = () => {
    this.props.requestJourney();
  };

  updateFilter = option => {
    this.setState(() => ({ filter: option.value }), this.updateQuery);
  };

  updateQuery = (options = {}) => {
    const {
      location: { pathname },
      router,
    } = this.props;
    const { filter, view } = this.state;

    const query = { filter, view };

    if (options.mount) {
      router.replace({ pathname, query });
    } else {
      router.push({ pathname, query });
    }
  };

  restricted = () => {
    const { isUserRestricted, journey, model } = this.props;

    if (!(journey || {}).attributes) return false;
    if (model.attributes.featured && model.type === 'companies') return false;

    const result = journey.attributes.featured === false && isUserRestricted;

    if (result) window.analytics.track('User restricted', { type: `journeys` });

    return result;
  };

  renderView = () => {
    const { journey, journeyMeta, loading, model } = this.props;
    const emails = ((journey.relationships || {}).emails || {}).data;
    const { startDate } = journey.attributes || {};
    if (!journey.attributes) return null;
    const journeyType = journey.attributes.type;
    const journeyCount =
      ((journeyMeta.journeyTypes || []).find(jt => jt.type === journeyType) || {}).count || 0;

    if (this.restricted()) {
      return (
        <div>
          <Restricted />
          <BrowseMore journey_type={journeyType} journey_count={journeyCount} />
        </div>
      );
    }

    if (isInTheFuture(startDate) && !loading) {
      return (
        <div>
          <Upcoming journey={journey} />
          <BrowseMore journey_type={journeyType} journey_count={journeyCount} />
        </div>
      );
    }

    if (isNotPresent(emails) && !loading) {
      return <NoTriggeredEmailsReceived journey={journey} />;
    }

    const childProps = {
      ...this.props,
      ...this.state,
    };

    return (
      <div>
        {!model.attributes.featured && (
          <Unlocked
            title="Unlocked"
            text="You are viewing a temporarily unlocked journey. MailCharts Pro users have access to our entire set of lifecycle journeys from thousands of ecommerce brands."
            user={this.props.user}
            unlocked={(journey.attributes || {}).featured}
            style={{ margin: '-30px 0  15px 0' }}
          />
        )}
        <Filters
          changeView={this.changeView}
          updateFilter={this.updateFilter}
          {...this.props}
          {...childProps}
        />
        {this.state.view === 'thumbnail' ? (
          <Thumbnails {...childProps} />
        ) : (
          <Timeline {...childProps} />
        )}
        <BrowseMore journey_type={journeyType} journey_count={journeyCount} />
      </div>
    );
  };

  render() {
    return (
      <Box>
        <Header {...this.props} {...this.state} />
        {this.renderView()}
      </Box>
    );
  }
}

ShowJourney.defaultProps = {
  model: {},
  journey: {},
};

ShowJourney.propTypes = {
  isUserRestricted: PropTypes.bool.isRequired,
  journey: PropTypes.shape({
    relationships: PropTypes.shape({
      emails: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape({})),
      }),
    }),
  }),
  journeyMeta: PropTypes.shape({
    journeyTypes: PropTypes.array,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    query: PropTypes.shape({
      filter: PropTypes.string,
      view: PropTypes.string,
    }),
  }).isRequired,
  model: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    type: PropTypes.string,
  }),
  requestJourney: PropTypes.func.isRequired,
  router: PropTypes.shape({
    location: PropTypes.shape({
      query: PropTypes.object,
      search: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
  user: PropTypes.shape({
    attributes: PropTypes.shape({}),
  }).isRequired,
};

const mapStateToProps = (state, { location, model, params }) => ({
  isUserRestricted: userSelectors.isUserRestrictedSelector(state),
  journey: selectors.getJourney(model, params.journey_id, location.query)(state),
  journeyMeta: selectors.getJourneyMeta(model, params.journey_id, location.query)(state),
  loading: selectors.getJourneyLoading(model, params.journey_id, location.query)(state),
  user: state.user.data[0],
});
const mapDispatchToProps = (dispatch, { location, model, params }) => ({
  requestJourney: () =>
    dispatch(actions.getJourneyRequest(model, params.journey_id, location.query || {})),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShowJourney);
