import React from 'react';
import styled from "@emotion/styled";
import { keyframes } from '@emotion/css';

const ladingAnimation = keyframes({
  '0%': { opacity: '1' },
  '50%': { opacity: '0.25' },
  '100%': { opacity: '1' },
});

const ContentLoader = styled("div")(({ height = null, lineHeight = null, ...rest }) => ({
  animation: `${ladingAnimation} 1s infinite`,
  background: 'linear-gradient(90deg, #F3F5F7 2.89%, rgba(243, 245, 247, 0) 50.94%, #F3F5F7 100%)',
  backgroundColor: '#fff',
  height,
  lineHeight: lineHeight || height,
  ...rest,
}));

const ContentLoaderWrapper = styled("div")(({ borderRadius = '2px', loadingBorder = true }) => ({
  border: loadingBorder ? '1px solid #D4D9DE' : null,
  borderRadius,
}));

export default props => (
  <ContentLoaderWrapper {...props}>
    <ContentLoader {...props} />
  </ContentLoaderWrapper>
);
