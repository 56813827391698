import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  savedJourneysActions as actions,
  savedJourneysSelectors as selectors,
} from '../../../../../modules/savedJourneys';

import { userSelectors } from '../../../../../modules/user';

import ProBadge from '../../../../ui/ProBadge';
import VerticalDotsIcon from '../../../../mc-ui/icons/VerticalDotsIcon';
import { Box, Button, theme } from '../../../../mc-ui';

// eslint-disable-next-line react/prop-types
const MenuItem = ({ as: As = Button, ...props }) => (
  <As
    variant="none"
    css={{
      alignItems: 'center',
      border: 'none !important',
      display: 'flex',
      height: '32px',
      justifyContent: 'space-between',
      lineHeight: '32px',
      padding: '0 8px',
      width: '100%',
      '&:hover': { backgroundColor: theme.colors.dark6, border: 'none', color: theme.colors.dark1 },
      '& svg': { display: 'none' },
    }}
    {...props}
  />
);

function VerticalDotsDropdown(
  {
    addToMyList,
    addToTeamList,
    isMy,
    isTeam,
    isUserRestricted,
    removeFromMyList,
    removeFromTeamList,
  },
  { router },
) {
  const {
    params: { collection_type: collectionType },
  } = router;

  const renderDropdown =
    (!isMy && collectionType === 'team') ||
    (!isTeam && collectionType === 'my') ||
    (isMy && collectionType === 'my') ||
    (isTeam && collectionType === 'team');

  if (!renderDropdown) {
    return null;
  }

  return (
    <Box
      position="relative"
      width="unset"
      css={{
        '& > button': {
          color: `${theme.colors.dark06} !important`,
        },
        '& > div': {
          display: 'none',
        },
        '&:hover': {
          '& > button': {
            color: `${theme.colors.dark1} !important`,
          },
          '& > div': {
            display: 'block',
          },
        },
      }}
    >
      <Button
        variant="none"
        css={{
          alignItems: 'center',
          display: 'inline-flex',
          fontSize: '24px',
          height: '24px',
          justifyContent: 'center',
          margin: '0',
          padding: '0',
          width: '24px',
        }}
      >
        <VerticalDotsIcon />
      </Button>

      <Box
        backgroundColor="white"
        borderRadius="4px"
        padding="4px"
        position="absolute"
        right="0"
        top="14px"
        width={isUserRestricted ? '240px' : '216px'}
        css={{
          filter: 'drop-shadow(0px 2px 8px rgba(9, 30, 66, 0.2))',
          '& > div': { width: '100% !important' },
        }}
        zIndex="6"
      >
        {!isMy &&
          collectionType === 'team' && (
            <MenuItem onClick={addToMyList}>Copy to My Journeys list</MenuItem>
          )}

        {!isTeam &&
          collectionType === 'my' &&
          !isUserRestricted && (
            <MenuItem onClick={addToTeamList}>Copy to Team Journeys list</MenuItem>
          )}

        {!isTeam &&
          collectionType === 'my' &&
          isUserRestricted && (
            <MenuItem disabled onClick={addToTeamList}>
              Copy to Team Journeys list <ProBadge small />
            </MenuItem>
          )}

        {isMy &&
          collectionType === 'my' && (
            <MenuItem onClick={removeFromMyList} variant="destructive">
              Remove
            </MenuItem>
          )}

        {isTeam &&
          collectionType === 'team' && (
            <MenuItem onClick={removeFromTeamList} variant="destructive">
              Remove
            </MenuItem>
          )}
      </Box>
    </Box>
  );
}

VerticalDotsDropdown.contextTypes = {
  router: PropTypes.object,
};

VerticalDotsDropdown.propTypes = {
  addToMyList: PropTypes.func.isRequired,
  addToTeamList: PropTypes.func.isRequired,
  isMy: PropTypes.bool.isRequired,
  isTeam: PropTypes.bool.isRequired,
  isUserRestricted: PropTypes.bool.isRequired,
  removeFromMyList: PropTypes.func.isRequired,
  removeFromTeamList: PropTypes.func.isRequired,
};

const mapStateToProps = (state, { journey }) => ({
  isMy: selectors.isInMySelector(journey.id)(state),
  isTeam: selectors.isInTeamSelector(journey.id)(state),
  isUserRestricted: userSelectors.isUserRestrictedSelector(state),
});

const mapDispatchToProps = (dispatch, { journey }) => ({
  addToMyList: event => {
    event.preventDefault();
    dispatch(actions.saveJourneyToMyListRequest(journey.id));
  },
  addToTeamList: event => {
    event.preventDefault();
    dispatch(actions.saveJourneyToTeamListRequest(journey.id));
  },
  removeFromMyList: event => {
    event.preventDefault();
    dispatch(actions.removeJourneyFromMyListRequest(journey.id));
  },
  removeFromTeamList: event => {
    event.preventDefault();
    dispatch(actions.removeJourneyFromTeamListRequest(journey.id));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VerticalDotsDropdown);
