/* global document */
import React from 'react';
import PropTypes from 'prop-types';

import Overlay from './newModal/Overlay';
import Wrapper from './newModal/Wrapper';

class NewModal extends React.PureComponent {
  componentDidMount() {
    document.body.classList.toggle('modal-open', true);
  }

  componentWillUnmount() {
    document.body.classList.toggle('modal-open', false);
  }

  render() {
    const { children, handleClick, modalStyle } = this.props;
    const { maxHeight, width, height } = modalStyle;
    const marginLeft = width ? -(width / 2) : undefined;
    const marginTop = maxHeight || height ? -(maxHeight || height) / 2 : undefined;

    return (
      <Overlay onClick={handleClick}>
        <Wrapper
          height={height}
          marginLeft={marginLeft}
          marginTop={marginTop}
          maxHeight={maxHeight}
          width={width}
        >
          {children}
        </Wrapper>
      </Overlay>
    );
  }
}

NewModal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]).isRequired,
  handleClick: PropTypes.func.isRequired,
  modalStyle: PropTypes.shape({}),
};

NewModal.defaultProps = {
  modalStyle: {},
};

export default NewModal;
