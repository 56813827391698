import * as React from 'react';

function SvgNegativeIcon(props) {
  return (
    <svg width="16px" height="16px" viewBox="0 0 16 16" fill="none" {...props}>
      <circle cx={8} cy={8} r={8} fill="#F2222F" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.667 4H7.333v6.036L5.006 7.723A.596.596 0 004 8.143a.588.588 0 00.167.415L7.37 11.74A.888.888 0 008 12a.892.892 0 00.63-.259l3.203-3.183a.588.588 0 00-.422-1 .596.596 0 00-.418.165l-2.326 2.313V4zm-.594 6.626h-.146l.014.014a.085.085 0 00.118 0l.014-.014z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgNegativeIcon;
