import { call, put } from 'redux-saga/effects';
import * as actions from './actions';
import * as api from './api';

export function* fetchData({ pathname, query }) {
  try {
    const response = yield call(api.fetch, query);
    yield put(actions.fetchDataSuccess(pathname, query, response.data));
  } catch (error) {
    console.log('textMessages/sagas › fetchData › error', error);
    yield put(actions.fetchDataFailure(pathname, query, error));
  }
}

export function* paginateData({ pathname, query, url }) {
  try {
    const response = yield call(api.paginate, url);
    yield put(actions.paginateDataSuccess(pathname, query, response.data));
  } catch (error) {
    console.log('textMessages/sagas › paginateData › error', error);
    yield put(actions.paginateDataFailure(pathname, query, error));
  }
}
