import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from "@emotion/styled";
import { connect } from 'react-redux';
import { isEmpty } from 'ramda';

import ListRow from './ListRow';
import { ascendBy, descendBy } from './helpers';

import { customListsActions, customListsSelectors } from '../../../modules/customLists';

import { Flex, Loader } from '../../mc-ui';

import EmptyList from '../ui/EmptyList';
import FilterField from '../ui/FilterField';
import ListsWrapper from '../ui/ListsWrapper';

const Button = styled("button")(({ active, theme }) => ({
  alignItems: 'center',
  backgroundColor: active ? theme.colors.dark1 : theme.colors.white,
  border: `1px solid ${active ? theme.colors.dark1 : theme.colors.dark5}`,
  color: active ? theme.colors.white : theme.colors.dark08,
  display: 'inline-flex',
  fontSize: '14px',
  fontWeight: '500',
  height: '32px',
  justifyContent: 'center',
  lineHeight: '1em',
  margin: '0',
  padding: '0',
  width: '50%',
}));

class Lists extends Component {
  state = {
    listsOwner: 'me',
    sortBy: 'updated_at',
    sortedLists: [],
  };

  componentDidMount() {
    const lists = this.props.user.included.filter(i => i.type === 'lists');
    this.setSortedLists(lists);
  }

  UNSAFE_componentWillReceiveProps(props) {
    let prevLists;
    let newLists;

    if (this.state.listsOwner === 'me') {
      prevLists = this.props.user.included.filter(i => i.type === 'lists');
      newLists = props.user.included.filter(i => i.type === 'lists');
    } else {
      prevLists = this.props.teamLists;
      newLists = props.teamLists;
    }

    if (prevLists.length !== newLists.length) {
      this.setSortedLists(newLists);
    }
  }

  setSortedLists = lists => {
    const sortedLists = lists.sort(descendBy('updated_at'));
    this.setState({ sortedLists, sortBy: 'updated_at' });
  };

  handleSort = event => {
    const sortBy = event.target.value;
    this.sortLists(sortBy);
  };

  sortLists = sortBy => {
    let { sortedLists } = this.state;

    switch (sortBy) {
      case 'asc':
        sortedLists = sortedLists.sort(ascendBy('name'));
        break;
      case 'desc':
        sortedLists = sortedLists.sort(descendBy('name'));
        break;
      case 'updated_at':
        sortedLists = sortedLists.sort(descendBy('updated_at'));
        break;
      default:
        sortedLists = sortedLists.sort(ascendBy('name'));
        break;
    }
    this.setState({ sortedLists, sortBy });
  };

  showMyLists = event => {
    event.preventDefault();
    this.setState(() => ({ listsOwner: 'me' }));
    const lists = this.props.user.included.filter(i => i.type === 'lists');
    this.setSortedLists(lists);
  };

  showTeamLists = event => {
    event.preventDefault();
    this.props.fetchTeamLists();
    this.setState(() => ({ listsOwner: 'team' }));
    const lists = this.props.teamLists;
    this.setSortedLists(lists);
  };

  render() {
    const { email, emptyListDescription, renderOutside, teamListsLoading } = this.props;
    const { listsOwner, sortedLists, sortBy } = this.state;

    if (teamListsLoading) return <Loader />;

    if (isEmpty(sortedLists)) {
      return (
        <EmptyList emptyListDescription={emptyListDescription} renderOutside={renderOutside} />
      );
    }
    const emails = []
      .concat(email.data, this.props.emails.data)
      .filter(e => email.data.some(eml => eml.id === e.id) || e.selected);
    const emailId = emails.map(e => e.id).filter((id, idx, arr) => arr.indexOf(id) === idx);

    if (email.loading && emailId.length < 2) return null;

    return (
      <ListsWrapper>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          margin="0 auto"
          marginBottom="10px"
          maxWidth="360px"
        >
          <Flex width="176px">
            <Button
              active={listsOwner === 'me'}
              onClick={this.showMyLists}
              css={{ borderRadius: '4px 0 0 4px' }}
            >
              Mine
            </Button>
            <Button
              active={listsOwner === 'team'}
              onClick={this.showTeamLists}
              css={{ borderRadius: '0 4px 4px 0' }}
            >
              Team
            </Button>
          </Flex>

          <FilterField onChange={this.handleSort} value={sortBy}>
            <option value="asc">A-Z</option>
            <option value="desc">Z-A</option>
            <option value="updated_at">Last edited</option>
          </FilterField>
        </Flex>

        {sortedLists.map(list => {
          const added = email.included.some(i => i.type === 'lists' && i.id === list.id);
          return <ListRow key={list.id} list={list} added={added} emailId={emailId} />;
        })}
      </ListsWrapper>
    );
  }
}

Lists.propTypes = {
  email: PropTypes.shape({ data: PropTypes.array }).isRequired,
  emails: PropTypes.shape({ data: PropTypes.array }).isRequired,
  emptyListDescription: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
  fetchTeamLists: PropTypes.func.isRequired,
  renderOutside: PropTypes.bool,
  teamLists: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  teamListsLoading: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    included: PropTypes.array,
  }).isRequired,
};

Lists.defaultProps = {
  emptyListDescription: null,
  renderOutside: false,
};

const mapStateToProps = state => ({
  user: state.user,
  email: state.email,
  emails: state.emails,
  teamLists: customListsSelectors.teamListsDataSelector(state),
  teamListsLoading: customListsSelectors.isTeamListsLoadingSelector(state),
});

const mapDispatchToProps = dispatch => ({
  fetchTeamLists: () => dispatch(customListsActions.fetchTeamListsRequest()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Lists);
