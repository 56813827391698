import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router';

import { ScrollableBox, } from '../../../mc-ui';
import JourneyMiniCard from '../../JourneyMiniCard'

const JourneysList = ({ collection: journeys, }) => (
  <ScrollableBox height="297px" scrollBoxHeight="274px">
    {journeys.map(journey => {
      const [company] = journey.relationships.companies.data;
      return <JourneyMiniCard key={journey.id} journey={journey} company={company} />
    })}
  </ScrollableBox>
);

JourneysList.propTypes = {
  collection: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      attributes: PropTypes.shape({
        firstActivityAt: PropTypes.string,
        journeyType: PropTypes.string,
      }),
      relationships: PropTypes.shape({
        companies: PropTypes.shape({
          data: PropTypes.arrayOf(PropTypes.shape({})),
        }),
      }),
    }),
  ).isRequired,
};

export default JourneysList;
