/* eslint import/prefer-default-export: "off" */

import { is } from 'ramda';

const isString = is(String);

export const normalizeQuery = (query) => {
  let newQuery = { ...query };

  // Cleanning it up before getting correct values
  delete newQuery.end_date;
  delete newQuery.gt;
  delete newQuery.lt;
  delete newQuery.promotional;
  delete newQuery.start_date;

  const promotionalAttrs = ['promotional', 'gt', 'lt'];
  if (/^absolute/.test(query.promotional)) {
    query.promotional.split('|').forEach((v, i) => {
      newQuery[promotionalAttrs[i]] = v || null;
    });
  } else if (/^percentage/.test(query.promotional)) {
    query.promotional.split('|').forEach((v, i) => {
      newQuery[promotionalAttrs[i]] = v || null;
    });
  } else if (query.promotional) {
    newQuery.promotional = query.promotional
  }

  if (query.start_date) {
    newQuery.start_date = isString(query.start_date)
      ? query.start_date
      : query.start_date.format('YYYY-MM-DD');
  }

  if (query.end_date) {
    newQuery.end_date = isString(query.end_date)
      ? query.end_date
      : query.end_date.format('YYYY-MM-DD');
  }

  newQuery = Object.keys(newQuery).reduce((m, k) => {
    const newM = { ...m };
    const value = Array.isArray(newQuery[k]) ? newQuery[k].filter(v => v) : newQuery[k];

    if (value || value === false) {
      newM[k] = newQuery[k];
    }

    return newM;
  }, {});

  if (newQuery.q && !newQuery.filter) {
    newQuery.filter = 'all_content';
  }

  return newQuery;
};
