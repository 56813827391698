/* global window */

import React, { Component } from 'react';

import { Link } from 'react-router';
import PaymentLink from '../PaymentLink';
import Container from '../Container';

import ContactUs from './ContactUs';
import HeaderContainer from './ui/HeaderContainer';
import Label from './ui/Label';
import Title from './ui/Title';

import JourneysContainer from './ui/JourneysContainer';

import FindBrandsIcon from './icons/FindBrandsIcon';
import FindCampaignIcon from './icons/FindCampaignIcon';
import StayUpToDateIcon from './icons/StayUpToDateIcon';

const containerStyle = {
  display: 'flex',
  flexDirection: 'column',
  margin: '48px 0',
  padding: '40px',
};

class UpgradeTechnologies extends Component {
  componentDidMount() {
    window.analytics.track('User restricted', { type: 'technologies' });
  }

  handlePaywallClicked = event => {
    const type = 'technologies';
    window.analytics.track('Paywall clicked', { type });
  };

  render() {
    return (
      <Container style={containerStyle}>
        <HeaderContainer
          style={{
            backgroundPosition: 'right center',
            backgroundImage: 'url(/upgrade-technologies-bg.svg)',
            backgroundSize: 'auto 325px',
          }}
        >
          <Label>Pro</Label>

          <Title>Take a deeper look</Title>

          <p style={{ maxWidth: 500 }}>
            Pull back the curtain to see which ESPs and technologies power the world
            of ecommerce email marketing.
          </p>

          <p style={{ display: 'inline-block' }}>
            <Link
              className="pure-button pure-button-primary"
              style={{ fontSize: `${13 / 16}em`, lineHeight: '1em', padding: '1em 1.8em' }}
              to="/manage/plan"
              onClick={this.handlePaywallClicked}
            >
              Upgrade to MailCharts Pro
            </Link>
          </p>
        </HeaderContainer>

        <JourneysContainer>
          <Title>Here’s what’s included</Title>

          <div className="pure-g gutters" style={{ marginTop: '40px' }}>
            <div className="pure-u-1 pure-u-md-1-3">
              <div
                className="pure-u-1"
                style={{ textAlign: 'center', margin: '0 auto', background: '#F3F5F7' }}
              >
                <FindBrandsIcon height="99px" style={{ margin: '20px 0' }} />
              </div>
              <h4>Stay informed</h4>
              <p>
                Instantly find brands using ESPs &amp; technologies that are complimentary or direct
                competitors to your platform.
              </p>
            </div>

            <div className="pure-u-1 pure-u-md-1-3">
              <div
                className="pure-u-1"
                style={{ textAlign: 'center', margin: '0 auto', background: '#F3F5F7' }}
              >
                <StayUpToDateIcon height="99px" style={{ margin: '20px 0' }} />
              </div>
              <h4>Keep tabs on the competition</h4>
              <p>
                Stay up-to-date and which ESPs are powering brands’ campaigns - and identify shifts
                across the landscape.
              </p>
            </div>

            <div className="pure-u-1 pure-u-md-1-3">
              <div
                className="pure-u-1"
                style={{ textAlign: 'center', margin: '0 auto', background: '#F3F5F7' }}
              >
                <FindCampaignIcon height="99px" style={{ margin: '20px 0' }} />
              </div>
              <h4>Find campaign examples</h4>
              <p>
                Export screenshots to present new ideas, and download HTML for mockups.
              </p>
            </div>
          </div>
        </JourneysContainer>
        <ContactUs />
      </Container>
    );
  }
}

export default UpgradeTechnologies;
