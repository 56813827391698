const ascend = (str1, str2) =>
  str1.toLowerCase().localeCompare(str2.toLowerCase())

const descend = (str1, str2) =>
  str2.toLowerCase().localeCompare(str1.toLowerCase())

const sortBy = (str1, str2, attribute, orderFunc) => {
  const value1 = `${str1.attributes[attribute] || ''}`.trim()
  const value2 = `${str2.attributes[attribute] || ''}`.trim()
  return orderFunc(value1, value2)
}

export const ascendBy = (attribute) => (str1, str2) =>
  sortBy(str1, str2, attribute, ascend)

export const descendBy = (attribute) => (str1, str2) =>
  sortBy(str1, str2, attribute, descend)
