import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { Link as RRLink } from 'react-router';
import styled from "@emotion/styled";

import { Flex, Text } from '../../../../../../../mc-ui';

const Row = styled(Flex)(({ theme }) => ({
  borderBottom: `1px solid ${theme.colors.dark01}`,
  padding: '16px 0',
}));

const Link = styled(RRLink)(({ theme }) => ({
  border: 'none',
  color: theme.colors.blue,
  fontFamily: theme.font.family,
  fontSize: '14px',
  fontWeight: '500',
  letterSpacing: '0.02em',
  lineHeight: '1.25em',
  textDecoration: 'none',
}));

const Header = ({ data, date }, { router }) => {
  const { total_emails: totalEmails } = data.attributes;

  const pathname = `${router.location.pathname.replace('/calendars', '')}/emails`;
  const query = {
    end_date: date
      .clone()
      .add(1, 'day')
      .format('YYYY-MM-DD'),
    start_date: date.format('YYYY-MM-DD'),
  };

  return (
    <Row justifyContent="space-between">
      <Text fontWeight="bold" fontSize="10px" letterSpacing="0.12em" textTransform="uppercase">
        Brands
      </Text>
      <Link to={{ pathname, query }}>
        See all {totalEmails} {pluralize('email', totalEmails)}
      </Link>
    </Row>
  );
};

Header.contextTypes = {
  router: PropTypes.object,
};

Header.propTypes = {
  data: PropTypes.shape({
    total_emails: PropTypes.number,
  }).isRequired,
  date: PropTypes.shape({}).isRequired,
};

export default Header;
