import React from 'react';
import PropTypes from 'prop-types';
import styled from "@emotion/styled";

import { Button } from '../mc-ui';
import DownloadIcon from '../mc-ui/icons/DownloadIcon';

export const Dropdown = styled("div")(({ disabled = false }) => {
  const style = {
    position: 'relative',
    width: '120px',
    '& > div': {
      display: 'none',
    },
  };

  if (!disabled) {
    style['&:hover'] = {
      '& > div': {
        display: 'block',
      },
    };
  }

  return style;
});

export const DropdownButton = ({ disabled, variant }) => (
  <Button
    disabled={disabled}
    variant={variant}
    css={{
      alignItems: 'center',
      display: 'inline-flex',
      height: '32px',
      justifyContent: 'center',
      lineHeight: '32px',
      margin: '0',
      padding: '0',
      width: '120px',
      '& > svg': {
        marginRight: '8px',
      },
    }}
  >
    <DownloadIcon />
    Download
  </Button>
);

DropdownButton.defaultProps = {
  disabled: false,
  variant: 'primary',
};

DropdownButton.propTypes = {
  disabled: PropTypes.bool,
  variant: PropTypes.string,
};

export const DropdownMenu = styled("div")(({ theme, up = false }) => ({
  backgroundColor: theme.colors.white,
  borderRadius: '4px',
  bottom: up ? '28px' : null,
  boxSizing: 'border-box',
  filter: `drop-shadow(0px 1px 4px ${theme.colors.dark02})`,
  minWidth: '264px',
  padding: '8px 4px',
  position: 'absolute',
  right: '0',
  top: up ? null : '28px',
  zIndex: '9994',
}));

const DropdownMenuItemButton = styled("button")(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.colors.white,
  border: 'none',
  borderRadius: '4px',
  boxSizing: 'border-box',
  color: theme.colors.dark08,
  cursor: 'pointer',
  display: 'flex',
  fontSize: '14px',
  height: '32px',
  lineHeight: '32px',
  margin: '0',
  whiteSpace: 'nowrap',
  width: '100%',
  '&:active, &:hover': {
    backgroundColor: theme.colors.dark6,
  },
  '& > svg': {
    color: theme.colors.dark1,
    marginRight: '16px',
  },
}));

// eslint-disable-next-line react/prop-types
export const DropdownMenuItem = ({ children, ...props }) => (
  <DropdownMenuItemButton {...props}>
    <DownloadIcon />
    {children}
  </DropdownMenuItemButton>
);

const ProBadge = styled("div")(({ theme }) => ({
  backgroundColor: theme.colors.purpleMedium,
  borderRadius: '4px',
  boxSizing: 'border-box',
  color: theme.colors.white,
  fontFamily: theme.font.family,
  fontSize: '10px',
  fontWeight: 'bold',
  height: '16px',
  letterSpacing: '0.12em',
  lineHeight: '16px',
  marginLeft: '16px',
  padding: '0 4px',
  textTransform: 'uppercase',
}));

export const Pro = () => <ProBadge>Pro</ProBadge>;
