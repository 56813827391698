import styled from "@emotion/styled";

export const Button = styled("button")(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.colors.white,
  border: `1px solid ${theme.colors.dark5}`,
  borderRadius: '4px',
  boxShadow: `0 1px 2px 0 ${theme.colors.dark02}`,
  boxSizing: 'border-box',
  color: theme.colors.dark08,
  display: 'flex',
  fontSize: '20px',
  height: '32px',
  justifyContent: 'center',
  marginTop: '16px',
  padding: '0 11px',
  position: 'absolute',
  right: '8px',
  top: '0',
  zIndex: theme.zIndices.sticky,
}));

export const CommentsWrapper = styled("div")(({ open, theme }) => ({
  backgroundColor: theme.colors.white,
  boxShadow: `0 1px 4px 0 ${theme.colors.dark02}`,
  boxSizing: 'border-box',
  overflow: 'auto',
  position: 'relative',
  transform: open ? 'translateX(4px)' : 'translateX(408px)',
  transitionDuration: '500ms',
  transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
  width: '400px',
  zIndex: theme.zIndices.popover,
}));

export const Header = styled("div")(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.colors.dark6,
  boxSizing: 'border-box',
  display: 'flex',
  height: '56px',
  justifyContent: 'space-between',
  padding: '0 24px',
  width: '400px',
  '& > button': {
    backgroundColor: 'transparent',
    border: 'none',
    boxSizing: 'border-box',
    color: theme.colors.dark08,
    fontSize: '24px',
    height: '24px',
    padding: '0',
    width: '24px',
  },
}));
