import axios from 'axios';
import { isEmpty, isNil } from 'ramda';

import api from '../../lib/api';

const isPresent = value => !isEmpty(value) && !isNil(value);

export const getTeam = (id, params = {}) => api.get(`/v3/teams/${id}/members`, { params });
export const updateTeam = (id, params) => api.patch(`/v3/teams/${id}`, params);
export const inviteTeamMember = (id, params) => api.post(`/v3/teams/${id}/invitations`, params);
export const activateTeamMember = (teamId, id) =>
  api.patch(`/v3/teams/${teamId}/members/${id}/activate`);
export const archiveTeamMember = (teamId, id) =>
  api.delete(`/v3/teams/${teamId}/members/${id}/archive`);
export const removeTeamMember = (teamId, id) =>
  api.delete(`/v3/teams/${teamId}/members/${id}/destroy`);
export const updateTeamMember = (teamId, id, params) =>
  api.patch(`/v3/teams/${teamId}/members/${id}`, params);
export const cancelInvitation = (teamId, id) => api.delete(`/v3/invitations/${id}`);
export const resendInvitation = (teamId, id) =>
  api.patch(`/v3/teams/${teamId}/invitations/${id}/resend`);

// ******************* SUBSCRIPTIONS ********************

const createStripeToken = card => {
  const headers = { Authorization: `Bearer ${process.env.STRIPE_PUBLISH_KEY}` };
  const params = [
    `card[address_zip]=${card.address_zip}`,
    `card[cvc]=${card.cvc}`,
    `card[exp_month]=${card.exp_month}`,
    `card[exp_year]=${card.exp_year}`,
    `card[number]=${card.number.replace(/\s/g, '')}`,
  ].join('&');
  const url = `https://api.stripe.com/v1/tokens?${params}`;

  return axios({ method: 'post', url, headers });
};

export const getSetupIntent = async (subscription_id) => {
  const payload = {subscription_id};
  const response = await api.post('/v3/stripe/setup-payment-intent', payload)

  return response;
}

export const createSubscription = async ({ coupon, sms_addon, interval, status, stripe_subscription_id, setup_intent_id, stripe_customer_id }, subscription, team) => {
  const payload = {
    coupon,
    sms_addon,
    interval,
    status,
    stripe_subscription_id,
    setup_intent_id,
    stripe_customer_id
  };

  if (subscription && team) {
    payload.subscription_id = subscription.id;
    payload.team_id = team.id;
  }

  if (!stripe_customer_id && subscription && subscription.attributes.stripe_customer_id !== '__manual__') {
    payload.stripe_customer_id = subscription.attributes.stripe_customer_id;
  }

  const response = await api.post('/v3/subscriptions', payload);

  return response;
};

export const subscriptionUpdateCreditCard = async (id,payload) => {
  const response = await api.post(`/v3/subscriptions/${id}/cards`,payload);

  return response;
};

// Use `current` when calculating an old plan
export const subscriptionCalculate = async (id, interval, quantity, current) => {
  const params = { interval, quantity };

  if (current) {
    params.current = true;
  }

  const response = await api.get(`/v3/subscriptions/${id}/calculate`, { params });

  return response;
};

export const subscriptionUpdate = async (id, params) => {
  const response = await api.patch(`/v3/subscriptions/${id}`, params);
  return response;
};

export const subscriptionAddons = async (id, params) => {
  const response = await api.patch(`/v3/subscriptions/${id}/addons`, params);
  return response;
};

export const subscriptionMigrate = async (id, params) => {
  const response = await api.patch(`/v3/subscriptions/${id}/migrate`, params);
  return response;
};

export const subscriptionUpdateBillingInfo = async (id, { card, billing_info: billingInfo }) => {
  if (isPresent(card.number)) {
    await subscriptionUpdateCreditCard(id, { card });
  }
  // TODO: Implement the endpoint that updates the billing info and adjust the line below:
  // await api.patch(`/v3/subscriptions/${id}/billing_info`, billingInfo);
};

export const subscriptionCancel = async id => {
  const response = await api.delete(`/v3/subscriptions/${id}`);
  return response;
};

export const subscriptionReactivate = async id => {
  const response = await api.post(`/v3/subscriptions/${id}/reactivate`);
  return response;
};

export const subscriptionPaymentInfo = async subscriptionId => {
  const response = await api.get(`/v3/subscription/get-payment-info/${subscriptionId}`)
  return response;
}

// ******************* INVITATIONS ********************
export const invitationAccept = async invitationGuid => {
  const response = await api.post(`/v3/invitations/${invitationGuid}/accept`);
  return response;
};

export const invitationDecline = async invitationGuid => {
  const response = await api.delete(`/v3/invitations/${invitationGuid}`);
  return response;
};
