import React from 'react';
import PropTypes from 'prop-types';
import styled from "@emotion/styled";

import JourneyTimeline from './JourneyTimeline';

const MultiJourneyTimeline = ({ journeysWithDetails }) => {
  const Wrapper = styled("div")({
    display: 'flex',
    justifyContent: 'space-evenly',
  });

  return (
    <Wrapper>
      {journeysWithDetails.map((journey) => {
        return <JourneyTimeline journeyWithDetails={journey} key={journey.data[0].id} compactMode={journeysWithDetails.length > 1} />;
      })}
    </Wrapper>
  );
};

MultiJourneyTimeline.propTypes = {
  journeysWithDetails: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default MultiJourneyTimeline;
