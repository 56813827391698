import React from 'react';
import PropTypes from 'prop-types';

import Container from '../Container';
import Section from '../Section';

import GroupCard from './list/GroupCard';

const GROUPS_HIERARCHY = [
  {
    attributes: { name: 'Apparel', background: 'images/groups-background/apparel.png' },
    id: 23699,
    sub_group_ids: [23699, 23670, 55644, 23671, 23672, 23687, 55642, 55643],
  },
  {
    attributes: {
      name: 'Beauty & Personal Care',
      background: 'images/groups-background/beautyAndPersonalCare.png',
    },
    id: 23694,
    sub_group_ids: [23694, 23634, 55868, 23653],
  },
  {
    attributes: {
      name: 'MailCharts Trending Groups',
      background: 'images/groups-background/mailchartsCustom.png',
    },
    id: '__custom__',
    sub_group_ids: [54596, 56829, 76544],
  },
  {
    attributes: { name: 'Food & Drink', background: 'images/groups-background/foodAndDrink.png' },
    id: 23695,
    sub_group_ids: [23695, 23684, 23636, 55646, 55645, 23683],
  },
  {
    attributes: { name: 'Accessories', background: 'images/groups-background/accessories.png' },
    id: 23691,
    sub_group_ids: [23691, 23640, 23638, 23639],
  },
  {
    attributes: { name: 'Subscription', background: 'images/groups-background/subscription.png' },
    id: 59527,
    sub_group_ids: [59527, 55878, 55879, 55880, 55881, 55882, 59081, 55885, 55886, 55887, 55888],
  },
  {
    attributes: {
      name: 'Health & Fitness',
      background: 'images/groups-background/healthAndFitness.png',
    },
    id: 23701,
    sub_group_ids: [77067, 23701, 55869, 23681],
  },
  {
    attributes: { name: 'Home', background: 'images/groups-background/home.png' },
    id: 23692,
    sub_group_ids: [23692, 23679, 55854, 23632, 55855, 55858, 23680],
  },
  {
    attributes: { name: 'Travel & Tourism', background: 'images/groups-background/travel.png' },
    id: 23689,
    sub_group_ids: [23689, 23629, 23637, 23635, 23677],
  },
  {
    attributes: {
      name: 'Art & Gifts',
      background: 'images/groups-background/artsEntertainment.png',
    },
    id: 56983,
    sub_group_ids: [56983, 23686, 55870, 55871, 23654, 23656, 23655],
  },
  {
    attributes: { name: 'Sports & Activities', background: 'images/groups-background/sport.png' },
    id: 23696,
    sub_group_ids: [23696, 23647, 23649, 85786, 23641, 23642, 23644, 23650, 23643, 23651, 23646],
  },
  {
    attributes: { name: 'Footwear', background: 'images/groups-background/footwear.png' },
    id: 23693,
    sub_group_ids: [23693, 23673, 23674, 23688, 23675, 23633],
  },
  {
    attributes: { name: 'Toys, Kids, & Baby', background: 'images/groups-background/baby.png' },
    id: 56982,
    sub_group_ids: [56982, 23630, 23676, 23685],
  },
  {
    attributes: { name: 'Tech', background: 'images/groups-background/technology.png' },
    id: 56984,
    sub_group_ids: [56984, 55872, 55873, 55874],
  },
  {
    attributes: { name: 'Other', background: 'images/groups-background/other.png' },
    id: '__custom__',
    sub_group_ids: [55876, 55877, 71643, 55875, 83213, 23657, 23678],
  },
];

const List = ({ groups }) => {
  const containerStyle = {
    background: undefined,
    columnCount: '3',
    columnGap: '1.645em', // 26.32px
    marginTop: '32px',
    padding: '0',
  };

  return (
    <Section>
      <Container style={containerStyle}>
        {GROUPS_HIERARCHY.map((g, idx) => {
          const parentGroup =
            g.id === '__custom__'
              ? g
              : groups.data.find(group => group.id.toString() === g.id.toString());

          const brands =
            parentGroup && g.id !== '__custom__'
              ? parentGroup.relationships.companies
              : { data: [] };

          const subGroups = groups.data.filter(group =>
            g.sub_group_ids.find(id => id.toString() === group.id.toString()),
          );

          const key = `group-card-for-${g.id}-${idx}`;

          return !parentGroup ? null : (
            <GroupCard
              key={key}
              background={g.attributes.background}
              group={parentGroup}
              brands={brands}
              sub_groups={subGroups}
            />
          );
        })}
      </Container>
    </Section>
  );
};

List.propTypes = {
  groups: PropTypes.shape({}).isRequired,
};

export default List;
