import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';

import { teamsActions, teamsSelectors } from '../../modules/teams';
import { Box, Button, Flex, Heading, Text, theme } from '../mc-ui';
import WarningCircleIcon from '../mc-ui/icons/WarningCircleIcon';
import ReactivateConfirmationModal from './ReactivateConfirmationModal';

class ReactivateNotice extends React.Component {
  state = {
    reactivating: false,
    modal: false,
  };

  handleReactivate = event => {
    if (event) event.preventDefault();
    this.setState({ modal: true })
  };

  render() {
    const { isCancelingSubscription, isOwner, team } = this.props;

    if (!team || !isCancelingSubscription || !isOwner) return null;

    const { reactivating } = this.state;

    return (
      <Flex
        alignItems="flex-start"
        backgroundColor="white"
        border="1px solid"
        borderColor="yellowMedium"
        borderRadius="4px"
        padding="24px"
      >
        <WarningCircleIcon color={theme.colors.yellowDark} />

        <Box flex="1" margin="0 16px" width="unset">
          <Heading as="h3" lineHeight={`${24 / 18}em`} css={{ marginBottom: '8px' }}>
            Notice
          </Heading>

          <Text>
            Your subscription will be canceled on{' '}
            {moment(team.attributes.current_period_end_at).format('MMM DD, YYYY')}.
          </Text>
        </Box>

        <Button
          css={{ height: '32px', lineHeight: '32px', margin: '0', padding: '0', width: '96px' }}
          disabled={reactivating}
          onClick={this.handleReactivate}
          variant="primary"
        >
          Reactivate
        </Button>
        {this.state.modal &&
          <ReactivateConfirmationModal closeModal={() => this.setState({ modal: false })} />
        }
      </Flex>
    );
  }
}

ReactivateNotice.defaultProps = {
  team: null,
};

ReactivateNotice.propTypes = {
  isCancelingSubscription: PropTypes.bool.isRequired,
  isOwner: PropTypes.bool.isRequired,
  reactivateSubscription: PropTypes.func.isRequired,
  team: PropTypes.shape({
    attributes: PropTypes.shape({
      current_period_end_at: PropTypes.string,
    }),
  }),
};

const mapStateToProps = state => ({
  isCancelingSubscription: teamsSelectors.isCancelingSubscriptionSelector(state),
  isOwner: teamsSelectors.isOwnerSelector(state),
  team: teamsSelectors.teamSelector(state),
});

const mapDispatchToProps = dispatch => ({
  reactivateSubscription: () => dispatch(teamsActions.subscriptionReactivateRequest()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReactivateNotice);
