import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { benchmarksSelectors as selectors } from '../../../modules/newReports/benchmarks';

import { Flex, Loader, Text } from '../../mc-ui';
import CharacterIcon from '../../mc-ui/icons/CharacterIcon';

import ReportSection from '../ReportSection';
import Title from '../Title';

import BarsChart from './BarsChart';
import SectionInformation from './SectionInformation';

const SubjectLineLength = ({ data, endDate, loading, router, startDate, ...props }) => (
  <Flex>
    <ReportSection css={{ minHeight: '305px' }}>
      <ReportSection.Header>
        <Title>Average Subject Line Length</Title>
      </ReportSection.Header>
      <ReportSection.Body>
        {loading ? (
          <Flex alignItems="center" justifyContent="center" css={{ height: '170px' }}>
            <Loader />
          </Flex>
        ) : (
          <Flex justifyContent="space-between">
            <SectionInformation
              content={() => (
                <Text>
                  Research shows that short subject lines get the highest performance. How short?
                  Data suggests that under <strong>15 characters</strong> maximizes your open rate
                  while <strong>28-39 characters</strong> maximizes your click rate.
                </Text>
              )}
              icon={CharacterIcon}
              title="Subject Lines Report"
              linkTo={{
                pathname: router.location.pathname.replace('benchmarks', 'subject-lines'),
                query: { end_date: endDate, start_date: startDate },
              }}
            />

            <BarsChart
              adjustment={Math.round}
              append=" char"
              data={data.subject_line_length}
              loading={loading}
              {...props}
            />
          </Flex>
        )}
      </ReportSection.Body>
    </ReportSection>
  </Flex>
);

SubjectLineLength.defaultProps = {
  endDate: null,
  startDate: null,
};

SubjectLineLength.propTypes = {
  data: PropTypes.shape({
    subject_line_length: PropTypes.shape({}),
  }).isRequired,
  endDate: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  router: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }).isRequired,
  startDate: PropTypes.string,
};

const mapStateToProps = (state, { model, brandId }) => {
  if (brandId && model.id && model.type === 'groups') {
    return {
      data: selectors.getGroupComparisonData(model.id, brandId)(state),
      loading: selectors.getGroupComparisonLoading(model.id, brandId)(state),
    };
  }

  if (model.id && model.type === 'companies') {
    return {
      data: selectors.getBrandComparisonData(model.id)(state),
      loading: selectors.getBrandComparisonLoading(model.id)(state),
    };
  }

  return {
    data: { subject_line_length: {} },
    loading: true,
  };
};

export default connect(mapStateToProps)(SubjectLineLength);
