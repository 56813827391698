const prefix = 'mc/groupOverview';

// **********  MODEL **********

export const SET_MODEL = `${prefix}/SET_MODEL`;

export const FETCH_DATA_FAILURE = `${prefix}/FETCH_DATA_FAILURE`;
export const FETCH_DATA_REQUEST = `${prefix}/FETCH_DATA_REQUEST`;
export const FETCH_DATA_SUCCESS = `${prefix}/FETCH_DATA_SUCCESS`;
export const TOGGLE_DATA_LOADING = `${prefix}/TOGGLE_DATA_LOADING`;

// **********  JOURNEYS**********

export const GET_JOURNEYS_FAILURE = `${prefix}/GET_JOURNEYS_FAILURE`;
export const GET_JOURNEYS_REQUEST = `${prefix}/GET_JOURNEYS_REQUEST`;
export const GET_JOURNEYS_SUCCESS = `${prefix}/GET_JOURNEYS_SUCCESS`;
export const TOGGLE_JOURNEYS_LOADING = `${prefix}/TOGGLE_JOURNEYS_LOADING`;

// **********  HIGHLIGHTED BRANDS **********

export const PAGINATE_HIGHLIGHTED_BRANDS_REQUEST = `${prefix}/PAGINATE_HIGHLIGHTED_BRANDS_REQUEST`;
export const PAGINATE_HIGHLIGHTED_BRANDS_SUCCESS = `${prefix}/PAGINATE_HIGHLIGHTED_BRANDS_SUCCESS`;
