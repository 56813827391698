/* eslint-disable react/no-unused-state */
import React from 'react';
import PropTypes from 'prop-types';
import { camelizeKeys } from 'humps';
import { isEmpty } from 'ramda';

import api from '../../../../../lib/api';
import Modal from '../../../../Modal';
import { Button, Flex } from '../../../../mc-ui';

import JourneyLine from '../../JourneyLine';

class JourneysModal extends React.Component {
  state = {
    loading: isEmpty(this.props.journeys),
    error: null,
    journeys: this.props.journeys,
  };

  UNSAFE_componentWillMount() {
    if (isEmpty(this.state.journeys)) {
      const { currentJourney } = this.props;
      const { addressId } = currentJourney.attributes;
      api
        .get(`/v3/journeys`, { params: { address_id: addressId } })
        .then(res => {
          const journeys = camelizeKeys(res.data.data);
          this.setState({ loading: false, journeys });
        })
        .catch(error => {
          this.setState({ loading: false, error });
        });
    }
  }

  render() {
    const { closeModal, currentJourney } = this.props;
    const { loading, journeys } = this.state;

    return (
      <Modal onClose={closeModal} modalStyle={{ maxHeight: 724, width: 808 }} useNewComponent>
        {!loading && (
          <div>
            <Modal.Header
              title="Related Journeys"
              subtitle="See all journeys in this full subscription lifecycle journey."
              handleClose={closeModal}
            />
            <Modal.Body maxHeight="660px" customScrollbar customScrollbarCss={{ padding: '0' }}>
              <Flex direction="column">
                {journeys.map(journey => (
                  <JourneyLine
                    key={journey.id}
                    journey={journey}
                    current={journey.id === currentJourney.id}
                    showRelated={false}
                  />
                ))}
              </Flex>
            </Modal.Body>
          </div>
        )}
        <Modal.Footer>
          <Flex justifyContent="flex-end">
            <Button variant="primary" onClick={closeModal}>
              Done
            </Button>
          </Flex>
        </Modal.Footer>
      </Modal>
    );
  }
}

JourneysModal.defaultProps = {
  journeys: [],
};

JourneysModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  currentJourney: PropTypes.shape({ id: PropTypes.string }).isRequired,
  journeys: PropTypes.arrayOf(PropTypes.object),
};

export default JourneysModal;
