import * as React from 'react';

function EmailIcon() {
  return (
    <svg width="70px" height="68px" viewBox="0 0 70 68" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.774 6.704c6.427 1.965 22.245 11.31 25.905 16.687 3.034 4.457-8.727 6.644-6.684 11.55 2.479 5.953 4.007 13.076.744 18.492-3.304 5.485-10.526 7.925-17.23 8.874-5.99.849-12.008-1.231-17.462-3.959C19.43 56.04 16.349 52.084 13.2 48.15c-3.054-3.817-6.947-7.545-7.107-12.24-.158-4.657 3.185-8.54 6.282-12.076 2.824-3.223-4.28-7.922-.62-10.297 5.361-3.48 20.653-8.78 27.018-6.833z"
        fill="#F7EEFF"
      />
      <path
        d="M44.99 29.943c-.223-2.652-2.236-4.613-4.696-4.613H22.85c-2.348 0-4.36 1.845-4.696 4.382v12.11c0 2.652 2.125 4.843 4.696 4.843h17.332c2.572 0 4.697-2.191 4.697-4.844l.111-11.878zm-22.14-1.96h17.332c1.118 0 2.125.922 2.237 2.075v.808l-10.4 6.804c-.335.23-.67.23-.894 0l-10.4-6.92v-.692c0-1.153 1.007-2.075 2.125-2.075zm17.444 16.145H22.85c-1.23 0-2.236-1.038-2.236-2.307V33.864l9.057 5.997c.56.346 1.23.577 1.901.577s1.23-.231 1.79-.577l9.057-5.997v7.957c0 1.269-.895 2.307-2.125 2.307z"
        fill="#9E6ACC"
      />
      <path
        d="M49.687 35.998H67.74M1.381 35.998h11.01"
        stroke="#9E6ACC"
        strokeWidth={2}
        strokeLinecap="round"
        strokeDasharray="1 4"
      />
      <path
        d="M58.912 18.495h-.719v-1.428c0-1.965-1.617-3.572-3.594-3.572-1.977 0-3.594 1.607-3.594 3.572v1.428h-.72c-.79 0-1.437.643-1.437 1.429v7.143c0 .786.647 1.43 1.438 1.43h8.626c.79 0 1.438-.644 1.438-1.43v-7.143c0-.786-.647-1.429-1.438-1.429zm-4.313 6.5c-.79 0-1.438-.642-1.438-1.428a1.437 1.437 0 012.876 0c0 .786-.647 1.429-1.438 1.429zm2.228-6.5H52.37v-1.428c0-1.215 1.007-2.215 2.229-2.215 1.222 0 2.228 1 2.228 2.215v1.428z"
        fill="#9E6ACC"
      />
    </svg>
  );
}

export default EmailIcon;
