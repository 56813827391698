import React from 'react';
import PropTypes from 'prop-types';
import { take } from 'ramda';

import BrandLogo from './BrandLogo';
import { Grid, theme } from '../mc-ui';

export default function GroupLogoSquare({ css, group }) {
  const relatedCompanies = group.relationships
    ? (group.relationships || { companies: { data: [] } }).companies.data
    : group.attributes.related_companies;

  const companies = take(4, relatedCompanies.filter(c => c.id !== 'undefined'));

  return (
    <Grid gap="4px" gridTemplateColumns="repeat(2, 1fr)" height="88px" width="88px" css={css}>
      {companies.map(({ id }) => (
        <BrandLogo
          key={`GroupLogoSquare-BrandLogo-${id}`}
          brandId={id}
          css={{ border: `1px solid ${theme.colors.dark01}` }}
        />
      ))}
    </Grid>
  );
}

GroupLogoSquare.defaultProps = {
  css: {},
};

GroupLogoSquare.propTypes = {
  css: PropTypes.shape({}),
  group: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.shape({
      related_companies: PropTypes.array,
    }),
    relationships: PropTypes.shape({
      companies: PropTypes.shape({
        data: PropTypes.array,
      }),
    }),
  }).isRequired,
};
