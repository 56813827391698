/* eslint-disable react/prop-types */

import React from 'react';
import styled from "@emotion/styled";
import { withProps } from 'recompose';

import Error from './Error';
import ErrorTooltip from './inputField/ErrorTooltip';
import InputWrapper from './InputWrapper';

const Input = styled("input")(({ theme, invalid }) => ({
  border: `1px solid ${invalid ? theme.colors.redDark : theme.colors.dark5}`,
  borderRadius: 4,
  boxSizing: 'border-box',
  color: theme.colors.dark08,
  fontSize: '14px',
  lineHeight: `${22 / 14}em`,
  margin: 0,
  outline: 'none',
  padding: `${4 / 14}em ${12 / 14}em`,
  width: '100%',
  '&:focus': {
    borderColor: `${invalid ? theme.colors.redDark : theme.colors.blue}`,
  },
  '&::placeholder': {
    color: theme.colors.dark04,
  },
}));

const InputField = ({
  input,
  meta,
  hideErrorMessage,
  theme,
  render,
  showErrorTooltip,
  errorTooltipText,
  ...rest
}) => {
  const invalid =
    ((meta.error || meta.submitError || (meta.data && meta.data.error)) &&
      (meta.touched || (meta.data && meta.data.touched))) ||
    rest.invalid;

  return (
    <InputWrapper>
      <Input
        {...rest}
        {...input}
        onChange={(event, val) => {
          if (rest.onChange) {
            rest.onChange(event);
          }
          input.onChange(event, val);
          input.onBlur(event, val);
        }}
        onBlur={event => {
          if (rest.onBlur) {
            rest.onBlur(event);
          }
          input.onBlur(event);
        }}
        invalid={invalid}
      />
      {!hideErrorMessage && (
        <Error
          error={meta.error || meta.submitError || (meta.data && meta.data.error) || rest.error}
          touched={meta.touched || (meta.data && meta.data.touched)}
        />
      )}
      {(meta.error || meta.submitError || (meta.data && meta.data.error)) &&
        showErrorTooltip && <ErrorTooltip inputId={input.id}>{errorTooltipText}</ErrorTooltip>}
    </InputWrapper>
  );
};

const filterProps = props => {
  let { hideErrorMessage, showErrorTooltip } = props;
  const error = props.meta.error || (props.meta.data && props.meta.data.error);

  if (error === 'showErrorTooltip') {
    hideErrorMessage = true;
    showErrorTooltip = !!props.errorTooltipText;
  }

  return { hideErrorMessage, showErrorTooltip };
};

export default withProps(filterProps)(InputField);
