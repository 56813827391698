/* eslint-disable react/prop-types */
import React from 'react';

import GroupItem from './GroupItem';
import JourneyItem from './JourneyItem';

const Item = props =>
  props.item.type === 'groups' ? <GroupItem {...props} /> : <JourneyItem {...props} />;

export default Item;
