import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';

import { Box, Select } from '../../../mc-ui';

import CustomOption from './CustomOption';

const options = [
  { value: '', label: 'Best Match' },
  { value: '-last_email_sent_at', label: 'Most Recent' },
  { value: '-frequency', label: 'Most Frequent' },
  { value: '-promotional', label: 'Most Promotional' },
  { value: 'first_email_sent_at', label: 'Most Historical Data' },
  { value: '-twitter_followers', label: 'Most Twitter Followers' },
  { value: 'frequency', label: 'Least Frequent' },
  { value: 'promotional', label: 'Least Promotional' },
  { value: '-first_email_sent_at', label: 'Least Historical Data' },
  { value: 'twitter_followers', label: 'Least Twitter Followers' },
];

class SortControl extends React.Component {
  handleChange = sort => {
    const { router } = this.props;
    const { pathname, query: q } = router.location;
    const query = sort ? { ...q, sort } : q;
    if (!sort) delete query.sort;
    router.push({ pathname, query });
  };

  render() {
    const { disabled, router } = this.props;
    const { sort = '' } = router.location.query;
    const initialValues = { sort };

    return (
      <Box
        width="176px"
        css={{
          fontSize: '14px',
          height: '32px',
        }}
      >
        <Form initialValues={initialValues} onSubmit={() => {}}>
          {() => (
            <form>
              <Field
                component={Select}
                id="sort"
                isDisabled={disabled}
                name="sort"
                options={options}
                onChange={this.handleChange}
                components={{ Option: CustomOption }}
              />
            </form>
          )}
        </Form>
      </Box>
    );
  }
}

SortControl.defaultProps = {
  disabled: false,
};

SortControl.propTypes = {
  disabled: PropTypes.bool,
  router: PropTypes.shape({
    location: PropTypes.shape({
      query: PropTypes.object,
      pathname: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
};

export default SortControl;
