import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import BrandIcon from '../../mc-ui/icons/BrandIcon';

import Dropdown from './Dropdown';

class Industries extends React.Component {
  componentDidMount() {
    this.props.requestGroups();
  }

  render() {
    const { closeDropdown, groups, router, ...props } = this.props;
    const {
      location: { query },
    } = router;

    const newQuery = { ...query };
    delete newQuery.history;
    delete newQuery.tags;
    delete newQuery.twitter_followers;
    delete newQuery.countries;

    const handleClick = () => {
      setTimeout(closeDropdown, 250);
    };

    return (
      <Dropdown icon={BrandIcon} label="Industries" data-label="industries" {...props}>
        {groups.loading ? (
          <Dropdown.Text>Loading...</Dropdown.Text>
        ) : (
          groups.data.map(group => (
            <Dropdown.Item
              key={`qsIndustry-${group.id}`}
              to={{ pathname: '/brands/results', query: { ...newQuery, group_id: group.id } }}
              onClick={handleClick}
            >
              {group.attributes.name}
            </Dropdown.Item>
          ))
        )}
      </Dropdown>
    );
  }
}

Industries.propTypes = {
  closeDropdown: PropTypes.func.isRequired,
  groups: PropTypes.shape({
    loading: PropTypes.bool,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        attributes: PropTypes.shape({
          name: PropTypes.string,
        }),
      }),
    ),
  }).isRequired,
  requestGroups: PropTypes.func.isRequired,
  router: PropTypes.shape({
    location: PropTypes.shape({
      query: PropTypes.shape({}),
    }),
  }).isRequired,
};

const mapStateToProps = state => ({
  groups: state.groups,
});

const mapDispatchToProps = dispatch => ({
  requestGroups: () =>
    dispatch({
      type: 'REQUEST_GROUPS',
      url: '/v3/groups',
      index: true,
      params: { global: true, limit: 250 },
    }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Industries);
