import React from 'react';

const SvgMarketingTeams = props => (
  <svg width="1em" height="1em" viewBox="0 0 142 103" fill="none" {...props}>
    <path
      d="M37.7 88.7h51.8c1.4 0 2.5-1.1 2.5-2.5V33.8c0-1.4-1.1-2.5-2.5-2.5H37.7c-1.4 0-2.5 1.1-2.5 2.5v52.4c0 1.4 1.1 2.5 2.5 2.5z"
      fill="#fff"
      stroke="#A5ADBA"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M84.1 66.6H43M84.1 75.7H43M84.1 44.9H43M84.1 55.4H63.3"
      stroke="#D7DADE"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M52.3 56.9v6c3.4-.1 6.1-2.7 6.5-6h-6.5zM44.9 56.9c.4 3.3 3.1 5.9 6.5 6v-6h-6.5zM44.8 55.6v.3h6.5v-7c-3.5.1-6.5 3.1-6.5 6.7z"
      fill="#D7DADE"
    />
    <path d="M52.8 48.4v7h6.5v-.3c0-3.6-2.9-6.6-6.5-6.7z" fill="#0072D3" />
    <path
      d="M28.2 49.3c-10.7 0-19.4-8.7-19.4-19.4s8.7-19.5 19.4-19.5 19.4 8.7 19.4 19.4-8.7 19.5-19.4 19.5z"
      fill="#fff"
    />
    <path
      opacity={0.71}
      d="M20.2 45.1c-.2 0-.5-.1-.7-.2-3.9-3.1-6.5-6.9-7.4-11-.9-4-.1-8 2.2-11.2.3-.5 1-.6 1.5-.3s.6 1 .3 1.5c-4.2 5.9-2.3 13.6 4.8 19.2.5.4.5 1.1.2 1.5-.3.3-.6.5-.9.5z"
      fill="#E6F6FD"
    />
    <path
      d="M28.2 8.9C16.5 8.9 7.1 18.4 7.1 30c0 11.6 9.4 21.1 21.1 21.1 11.7 0 21.1-9.4 21.1-21.1 0-11.6-9.5-21.1-21.1-21.1zm0 40C17.7 48.9 9.3 40.5 9.3 30c0-10.5 8.4-18.9 18.9-18.9 10.5 0 18.9 8.5 18.9 18.9 0 10.4-8.5 18.9-18.9 18.9z"
      fill="#027AD7"
    />
    <path
      d="M17 31c.3-.6.7-1.1 1.1-1.6 1.1-1.3 2.4-2.5 3.8-3.5 1.6-1.1 3.4-1.9 5.3-2.2 1.7-.2 3.4 0 5 .7 1.8.7 3.3 1.9 4.6 3.2.9.9 1.7 1.9 2.4 2.9.3.4.3.8 0 1.2-1.8 2.5-4 4.4-6.9 5.6-2.8 1.2-5.6 1.1-8.4-.1-1.8-.8-3.4-2-4.9-3.3-.7-.6-1.3-1.3-1.9-2-.1-.1-.2-.3-.3-.5.2-.2.2-.3.2-.4zm2.2.1c.6.6 1.2 1.1 1.7 1.7 1.3 1.2 2.8 2.3 4.5 2.9 2.1.8 4.3.7 6.4-.1 1.7-.7 3.2-1.8 4.5-3.1.4-.4.8-.8 1.1-1.3v-.1c-.1-.1-.1-.2-.2-.3-1.3-1.6-2.7-3.1-4.5-4.1-2.4-1.4-4.9-1.5-7.4-.4-1.6.7-3 1.7-4.3 3-.7.5-1.2 1.1-1.8 1.8z"
      fill="#737F89"
    />
    <path
      d="M29.1 35.4c-2.5.4-4.9-1.2-5.3-3.7-.4-2.5 1.2-4.9 3.7-5.3 2.5-.4 4.9 1.2 5.3 3.7.5 2.5-1.2 4.8-3.7 5.3zm1.6-1.4c1-.2 1.7-1.1 1.5-2.1-.2-1-1.1-1.7-2.1-1.5-1 .2-1.6 1.1-1.5 2.1.1 1 1.1 1.7 2.1 1.5z"
      fill="#737F89"
    />
    <path
      d="M41.7 58.3c-.3.4-.6.8-1 1.1-.6.4-1.8.2-3-.2-2.2-.8-4.7-2.3-4.9-2.4 0 .1.4 1.6.4 3.8.1 2.1-.2 4.8-1.2 7.4 0 0-2 3.6-4.5 5.8l-1.5 17v9.2H1.6s10-40.8 13.2-44.7c1.7-2 14.9-11.1 17.6-9.2 1.3 1 3.3 2.8 5.2 4.4 1.9 1.7 3.5 3.4 4 3.9.7.9.8 2.6.1 3.9z"
      fill="#fff"
    />
    <path
      d="M27.6 73.9c-1.5 1.1-3.4 1.9-5.8 2.4M33.2 60.6c-.1-2.2-.4-3.7-.4-3.8M37.7 59.2c-2.2-.8-4.7-2.3-4.9-2.4M43.7 56.7c-.6.8-1.7 1.5-1.9 1.6.7-1.3.6-3-.1-3.8-.4-.5-2.1-2.1-4-3.9 0 0 3.2-1.5 4.6.3 1.3 1.9 2 5 1.4 5.8z"
      stroke="#A5ADBA"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M45.5 64c-.1.2-.4.3-.7.5-2.4 1.4-9.2 3.7-12.7 3.5 1-2.6 1.2-5.3 1.2-7.4.8-.1 4.1-1.3 4.5-1.4 1.2.4 2.4.6 3 .2.5-.3.8-.7 1-1.1.2-.1 1.3-.8 1.9-1.6 0 0 1.4 1 1.8 1.9.3 1.1 1.1 4.2 0 5.4z"
      fill="#fff"
      stroke="#A5ADBA"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M44.6 72.3c-.5.3-1.1.6-1.9.8-3.4 1.1-10.1 3.4-13.4 2.3-.8-.3-1.4-.8-1.7-1.5 2.2-1.6 3.6-3.7 4.5-5.8 3.5.2 10.3-2 12.7-3.5 0 0 1.5.8 1.7 2.7.2 1.6.4 3.6-1.9 5z"
      fill="#fff"
      stroke="#A5ADBA"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32.1 81.5l1 9c.1 1.6-.6 2.9-2.2 2.9H29c-1.7 0-3-1.4-2.9-3.1l1.5-16.6"
      fill="#0072D3"
    />
    <path
      d="M28 74l-1.9 26.1H1.7s10-40.8 13.2-44.7c1.7-2 14.9-11.1 17.6-9.2 1.3 1 3.3 2.8 5.2 4.4"
      stroke="#A5ADBA"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M42.2 79.9c-2.1.5-6.8 1.8-10.1 1.6-1.7-.1-3-.6-3.5-1.8-.8-2.8-.2-3.9.7-4.4 3.3 1.1 9.9-1.2 13.4-2.3.8-.2 1.4-.5 1.9-.8 0 0 1.1 1.2 1.4 2.6.2 1.5-.6 4.4-3.8 5.1z"
      fill="#fff"
      stroke="#A5ADBA"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30.6 52.3s.6 3 2.2 4.6"
      stroke="#A5ADBA"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M130.3 77.6c-1.6-1.7-1.8-4.5-1.8-4.5s-.3 2.9-1.9 4.5c-.7.7-1.5 1-1.5 1s.7.3 1.5 1c1.6 1.7 1.9 4.5 1.9 4.5s.2-2.9 1.8-4.5c.7-.7 1.5-1 1.5-1s-.8-.2-1.5-1zM140.4 68.3c-1-1-1.1-2.7-1.1-2.7s-.1 1.7-1.1 2.7c-.4.4-.9.6-.9.6s.4.2.9.6c1 1 1.1 2.7 1.1 2.7s.1-1.7 1.1-2.7c.4-.4.9-.6.9-.6s-.5-.2-.9-.6z"
      fill="#027AD7"
    />
    <path
      d="M116.1 51.9H113v7.8h3.1v-7.8zM121.2 50h-3.1v9.7h3.1V50zM126.3 47.8h-3.1v11.9h3.1V47.8zM131.5 44.4h-3.1v15.3h3.1V44.4z"
      fill="#fff"
      stroke="#80C963"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M113 46.5s9.9 1 18-9.4M127.9 37h3.1v3.1"
      stroke="#80C963"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M110.2 29.2s3.8-6.3 5-9c1.2-2.7.2-10.2-7.8-10.2s-8.6 7.9-7.7 10.5c1 2.6 5.2 8.7 5.2 8.7h5.3z"
      fill="#fff"
      stroke="#FFD336"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M109.6 31.9h-4.1c-.8 0-1.4-.6-1.4-1.4 0-.8.6-1.4 1.4-1.4h4.1c.8 0 1.4.6 1.4 1.4 0 .8-.6 1.4-1.4 1.4zM109.6 34.7h-4.1c-.8 0-1.4-.6-1.4-1.4 0-.8.6-1.4 1.4-1.4h4.1c.8 0 1.4.6 1.4 1.4 0 .8-.6 1.4-1.4 1.4z"
      fill="#fff"
      stroke="#FFD336"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M108.4 29.2v-8.9c0-2 2.9-1.7 2.9 0 0 1.9-2.1 1.7-3.8 1.7h-.2c-1.7 0-3.8.2-3.8-1.7 0-1.7 2.9-2 2.9 0v8.9"
      stroke="#FFD336"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M53.9 14a1.9 1.9 0 100-3.8 1.9 1.9 0 000 3.8zM137.6 17.7a3 3 0 100-6 3 3 0 000 6z"
      fill="#fff"
      stroke="#A1AEBA"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M74.7 73.6l11.8 11.8 2.5 2.5c1.1 1.2 2.7 1.8 4.4 1.8H95c1.6 0 3.1-.6 4.2-1.7l2.8-2.7 11.8-11.5c0-1.4-1.2-2.6-2.6-2.6l-33.9.1c-1.3.1-2.4 1-2.6 2.3z"
      fill="#fff"
      stroke="#027AD7"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M74.8 97.5c0 1.4 1.2 2.6 2.6 2.6l33.9-.1c1.4 0 2.6-1.2 2.6-2.6l-.1-23c0-.2-.2-.3-.4-.2L102 85.3 99.2 88c-1.1 1.1-2.7 1.7-4.2 1.7h-1.5c-1.6 0-3.2-.6-4.4-1.8l-2.5-2.5L75.1 74c-.1-.1-.4 0-.4.1 0 3.5.1 23.4.1 23.4z"
      fill="#fff"
      stroke="#027AD7"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M102 85.3l11.9 11.8M86.6 85.3L74.8 97.2"
      stroke="#027AD7"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M65.306 5.158l5.917-4.113c.284-.19.611-.295.96-.295h13.14C86.251.75 87 1.499 87 2.427v9.238c0 .929-.749 1.677-1.677 1.677h-12.94c-.38 0-.749-.126-1.044-.358l-6.307-5a1.742 1.742 0 01-.527-.716"
      fill="#fff"
    />
    <path
      d="M65.306 5.158l5.917-4.113c.284-.19.611-.295.96-.295h13.14C86.251.75 87 1.499 87 2.427v9.238c0 .929-.749 1.677-1.677 1.677h-12.94c-.38 0-.749-.126-1.044-.358l-6.307-5a1.742 1.742 0 01-.527-.716M60.75 4.357s4.482-.38 7.203 2.7"
      stroke="#F2222F"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M77.94 5.264c0 1.181-.685 2.004-1.877 2.004-1.118 0-1.866-.686-1.866-2.004 0-1.118.664-1.993 1.877-1.993 1.097.01 1.867.685 1.867 1.993zm-2.573 0c0 .685.253 1.055.718 1.055.453 0 .717-.327.717-1.055 0-.749-.296-1.034-.728-1.034-.433 0-.707.327-.707 1.034zm.865 4.609l3.68-6.001a.478.478 0 01.402-.222h.01c.37 0 .59.401.4.717l-3.69 6.001a.47.47 0 01-.802-.496zm6.528-1.16c0 1.18-.685 2.003-1.877 2.003-1.128 0-1.867-.696-1.867-2.003 0-1.118.654-1.994 1.878-1.994 1.096.01 1.866.686 1.866 1.994zm-2.573 0c0 .685.253 1.054.717 1.054.454 0 .717-.327.717-1.054 0-.75-.295-1.034-.727-1.034-.433 0-.707.338-.707 1.034z"
      fill="#F2222F"
    />
  </svg>
);

export default SvgMarketingTeams;
