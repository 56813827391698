import React from 'react';
import { Link as RRLink } from 'react-router';

import { Button, Flex, Heading, Link, Text } from '../../mc-ui';

import Envelope from './Envelope';

export default function ThisListIsEmpty() {
  return (
    <Flex
      alignItems="center"
      backgroundColor="white"
      borderRadius="4px"
      direction="column"
      padding="40px"
    >
      <Flex alignItems="center" direction="column" marginBottom="32px">
        <Envelope />

        <Heading
          as="h4"
          color="dark06"
          fontSize="18px"
          lineHeight="18px"
          css={{ marginTop: '24px' }}
        >
          This list is empty
        </Heading>
      </Flex>

      <Heading as="h3" fontSize="24px" lineHeight="24px" css={{ marginBottom: '32px' }}>
        How to find emails to add to this list:
      </Heading>

      <Flex justifyContent="space-between">
        <Flex alignItems="center" direction="column" width="272px">
          <Link to="/emails" fontSize="18px" lineHeight="24px" marginBottom="8px">
            Curated Email Examples
          </Link>

          <Text fontSize="16px" textAlign="center" css={{ marginBottom: '8px' }}>
            Browse our curated lists of best-of email examples
          </Text>

          <Button
            as={RRLink}
            to="/emails"
            variant="secondary"
            css={{
              alignItems: 'center',
              display: 'inline-flex',
              justifyContent: 'center',
              height: '32px',
              margin: '0',
              padding: '0',
              width: '120px',
            }}
          >
            Browse Emails
          </Button>
        </Flex>

        <Flex alignItems="center" direction="column" width="272px">
          <Link to="/brands" fontSize="18px" lineHeight="24px" marginBottom="8px">
            Brands
          </Link>

          <Text fontSize="16px" textAlign="center" css={{ marginBottom: '8px' }}>
            Search and explore emails from 2,500+ ecommerce brands.
          </Text>

          <Button
            as={RRLink}
            to="/brands"
            variant="secondary"
            css={{
              alignItems: 'center',
              display: 'inline-flex',
              justifyContent: 'center',
              height: '32px',
              margin: '0',
              padding: '0',
              width: '120px',
            }}
          >
            Browse Emails
          </Button>
        </Flex>

        <Flex alignItems="center" direction="column" width="272px">
          <Link to="/groups" fontSize="18px" lineHeight="24px" marginBottom="8px">
            Industry Groups
          </Link>

          <Text fontSize="16px" textAlign="center" css={{ marginBottom: '8px' }}>
            Find emails in industry groups that you are interested in.
          </Text>

          <Button
            as={RRLink}
            to="/groups"
            variant="secondary"
            css={{
              alignItems: 'center',
              display: 'inline-flex',
              justifyContent: 'center',
              height: '32px',
              margin: '0',
              padding: '0',
              width: '120px',
            }}
          >
            Browse Emails
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
}
