import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import api from '../../../../lib/api';

import CommentForm from '../../../CommentForm';
import { Box, Flex } from '../../../mc-ui';

import Comments from '../../../Comments';

import { teamsSelectors } from '../../../../modules/teams';

class Notes extends React.Component {
  constructor(props) {
    super(props);

    const { data } = props;
    const list = data.relationships.lists.data.find(
      l => l.id === props.router.location.query.list_id,
    );

    this.state = {
      comments: [],
      data,
      list,
      loadingComments: false,
    };
  }

  componentDidMount() {
    if (this.state.list.attributes.featured) return;
    this.fetchComments();
  }

  fetchComments = async () => {
    const { approximateComments, team, updateCounter } = this.props;
    const { comments, data, list } = this.state;

    if (comments.length > 0 && comments.length === approximateComments) return;

    this.setState({ loadingComments: true });

    const params = { text_message_guid: data.id };
    if (team) {
      params.team_id = team.id;
    }
    const response = await api.get(`/v3/lists/${list.id}/comments`, { params });

    this.setState(
      {
        comments: response.data.data,
        loadingComments: false,
      },
      () => {
        updateCounter(response.data.data.length);
      },
    );
  };

  handleSubmit = async values => {
    const { team } = this.props;
    const { data, list } = this.state;

    const payload = { ...values, text_message_guid: data.id };
    if (team) {
      payload.team_id = team.id;
    }
    await api.post(`/v3/lists/${list.id}/comments`, payload);

    window.analytics.track('Comment added', {
      text_message_id: data.id,
      text_message_guid: data.attributes.guid,
      list_id: list.id,
    });

    this.refetchComments();
  };

  refetchComments = () => {
    this.setState({ comments: [], loadingComments: true }, this.fetchComments);
  };

  render() {
    const { comments, data, list, loadingComments } = this.state;

    if (list.attributes.featured) return null;

    return (
      <Flex direction="column">
        <Box backgroundColor="dark6" borderRadius="4px" marginBottom="24px" padding="16px">
          <CommentForm onSubmit={this.handleSubmit} />
        </Box>

        <Comments
          comments={comments}
          textMessageGuid={data.id}
          listId={list.id}
          loading={loadingComments}
          refetch={this.refetchComments}
        />
      </Flex>
    );
  }
}

Notes.defaultProps = {
  team: null,
};

Notes.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.shape({
      approximate_comments: PropTypes.number,
    }),
    relationships: PropTypes.shape({
      lists: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape({})),
      }),
    }),
  }).isRequired,
  router: PropTypes.shape({
    location: PropTypes.shape({
      query: PropTypes.shape({
        list_id: PropTypes.string,
      }),
    }),
  }).isRequired,
  team: PropTypes.shape({
    id: PropTypes.string,
  }),
};

const mapStateToProps = state => ({
  team: teamsSelectors.teamSelector(state),
});

export default connect(mapStateToProps)(Notes);
