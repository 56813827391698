import api from '../lib/api';

export default store => next => (action) => {
  next(action);
  if (action.type === 'REQUEST_JOURNEYS') {
    api.get(`/v2/companies/${action.company_id}/journeys`)
      .then(r => store.dispatch({ type: 'RECEIVE_JOURNEYS', data: r.data }));
  }
  if (action.type === 'REQUEST_JOURNEYS_WITH_DETAILS') {
    // Combine results from multiple requests into a single array
    Promise.all(action.segmentIds.map(segmentId => api.get(`/v2/segments/${segmentId}/journey`)))
      .then((responses) => {
        store.dispatch({ type: 'RECEIVE_JOURNEYS_WITH_DETAILS', data: { data: responses.map(r => r.data) } });
      });
  }
};

