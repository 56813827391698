import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';

import { Flex, Text } from '../../../../mc-ui';

import Header from './Header';
import Row from './Row';

const BrandsHoursTable = ({ brands, modal }) =>
  isEmpty(brands) ? (
    <Flex alignItems="center" height="309px" justifyContent="center">
      <Text>No data</Text>
    </Flex>
  ) : (
    <div>
      <Header modal={modal} />
      {brands.map(brand => (
        <Row key={`brandsHoursTable-${brand.id}`} brand={brand} />
      ))}
    </div>
  );

BrandsHoursTable.defaultProps = {
  brands: [],
  modal: false,
};

BrandsHoursTable.propTypes = {
  brands: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      meta: PropTypes.shape({
        stats: PropTypes.shape({
          sentAtHourOfDay: PropTypes.arrayOf(
            PropTypes.shape({
              docCount: PropTypes.number,
              key: PropTypes.number,
              value: PropTypes.number,
            }),
          ),
        }),
      }),
    }),
  ),
  modal: PropTypes.bool,
};

export default BrandsHoursTable;
