import React from 'react';

import ContactModalLink from '../ContactModalLink';
import { Box, Button, Flex, Heading, Link, Text } from '../mc-ui';
import { topics } from '../ContactUs';

const Restricted = () => (
  <div>
    <Flex
      alignItems="center"
      backgroundColor="purpleLight"
      borderRadius="4px"
      justifyContent="space-between"
      marginBottom="40px"
      padding="24px"
    >
      <Box width="536px">
        <Heading
          as="h3"
          color="dark08"
          fontSize="18px"
          letterSpacing="0.02em"
          lineHeight="24px"
          css={{ marginBottom: '8px' }}
        >
          Built for the worlds biggest brands
        </Heading>

        <Text>
          Are you and your team looking to get more out of the MailCharts Index with unlimited
          access with a MailCharts Enterprise plan?
        </Text>
      </Box>

      <Button
        as={ContactModalLink}
        topic={topics.ENTERPRISE}
        variant="purple"
        css={{
          alignItems: 'center',
          display: 'inline-flex',
          height: '40px',
          justifyContent: 'center',
          margin: '0',
          padding: '0',
          width: '144px',
        }}
      >
        Contact sales
      </Button>
    </Flex>

    <Flex justifyContent="space-between" marginBottom="40px">
      <Box width="360px">
        <img
          src="/images/team-management/schedule-a-demo-with-your-team.svg"
          alt="Schedule a demo with your team"
        />
      </Box>

      <Flex direction="column" justifyContent="center" width="304px">
        <Heading
          as="h4"
          color="dark1"
          fontSize="18px"
          letterSpacing="0.02em"
          lineHeight="24px"
          css={{ marginBottom: '8px' }}
        >
          Schedule a demo with your team
        </Heading>

        <Text>
          If you are ready to talk about how MailCharts Enterprise can super charge your organization&mdash;we would love to talk with you soon.
        </Text>
      </Flex>
    </Flex>

    <Flex justifyContent="space-between" marginBottom="40px">
      <Flex direction="column" justifyContent="center" width="304px">
        <Heading
          as="h4"
          color="dark1"
          fontSize="18px"
          letterSpacing="0.02em"
          lineHeight="24px"
          css={{ marginBottom: '8px' }}
        >
          Not ready for Enterprise? No problem.
        </Heading>

        <Text>
          If our Enterprise plan is not a good fit, but you&rsquo;re still curious about MailCharts Pro,{' '}
          <Link
            to="https://www.youtube.com/channel/UCJ7Jvjop4U7mEYw9cX0j4tw/videos"
            target="_blank"
          >
            click here to view tons of on demand demo videos
          </Link>{' '}
          to see how MailCharts helps you dominate campaign planning.
        </Text>
      </Flex>

      <Box width="360px">
        <img
          src="/images/team-management/not-ready-for-a-demo.svg"
          alt="Not ready for a demo? No problem."
        />
      </Box>
    </Flex>
  </div>
);

export default Restricted;
