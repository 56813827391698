import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '../../../mc-ui';

import BrandLogo from '../../../ui/BrandLogo';

export default function GroupBrandsLogo({ group }) {
  const brands = group.relationships
    ? (group.relationships || { companies: { data: [] } }).companies.data
    : group.attributes.related_companies;

  return (
    <Box position="relative">
      <Box
        border="1px solid"
        borderColor="dark01"
        height="8px"
        left="0"
        position="absolute"
        top="0"
        width="8px"
      >
        {brands[0] && <BrandLogo brand={brands[0]} css={{ height: '8px', width: '8px' }} />}
      </Box>
      <Box
        border="1px solid"
        borderColor="dark01"
        height="8px"
        left="10px"
        position="absolute"
        top="0"
        width="8px"
      >
        {brands[1] && <BrandLogo brand={brands[1]} css={{ height: '8px', width: '8px' }} />}
      </Box>
      <Box
        border="1px solid"
        borderColor="dark01"
        height="8px"
        left="0"
        position="absolute"
        top="10px"
        width="8px"
      >
        {brands[2] && <BrandLogo brand={brands[2]} css={{ height: '8px', width: '8px' }} />}
      </Box>
      <Box
        border="1px solid"
        borderColor="dark01"
        height="8px"
        left="10px"
        position="absolute"
        top="10px"
        width="8px"
      >
        {brands[3] && <BrandLogo brand={brands[3]} css={{ height: '8px', width: '8px' }} />}
      </Box>
    </Box>
  );
}

GroupBrandsLogo.propTypes = {
  group: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.shape({
      related_companies: PropTypes.array,
    }),
    relationships: PropTypes.shape({
      companies: PropTypes.shape({
        data: PropTypes.array,
      }),
    }),
  }).isRequired,
};
