import { withProps } from 'recompose';

import Button from '../Button';

export default withProps(({ current }) => ({
  variant: current ? 'primary' : 'secondary',
  css: {
    alignItems: 'center',
    cursor: current ? 'default' : 'pointer',
    display: 'flex',
    fontWeight: current ? 'bold' : 'normal',
    height: '32px',
    justifyContent: 'center',
    margin: '0 0 0 8px !important',
    padding: '0 !important',
    textAlign: 'center',
    width: '32px',
  },
}))(Button);