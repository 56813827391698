import React from 'react';

function SvgGiftboxIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M14.957 3.116h-1.165c.153-.243.255-.516.284-.804a2.053 2.053 0 00-1.22-2.116 2.045 2.045 0 00-2.23.359l-1.47 1.332a1.561 1.561 0 00-2.313.002L5.371.554A2.046 2.046 0 003.14.196a2.053 2.053 0 00-1.22 2.116c.029.289.13.561.284.804H1.043C.467 3.116 0 3.584 0 4.16v1.565c0 .288.234.522.522.522h14.956A.522.522 0 0016 5.725V4.16c0-.576-.467-1.044-1.043-1.044zm-8.522-.173v.173H3.98a1.03 1.03 0 01-.997-1.28c.072-.297.286-.55.564-.678.381-.177.803-.12 1.124.17l1.764 1.6v.015zm6.605-.77c-.038.543-.528.944-1.072.944H9.565v-.174-.019l1.737-1.575c.267-.242.641-.355.987-.252.496.148.788.57.751 1.076zM1 7.3v7.652c0 .576.467 1.044 1.043 1.044h4.87V7.3H1zM9.1 7.3v8.696h4.87c.576 0 1.043-.468 1.043-1.044V7.3H9.1z"
        fill="#9E6ACC"
      />
    </svg>
  );
}

export default SvgGiftboxIcon;
