import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import qs from 'qs';
import { connect } from 'react-redux';

import { calendars } from '../../../modules/newReports';
import { shallowEqual } from '../../../lib/utils';
import { userSelectors } from '../../../modules/user';

import { Flex } from '../../mc-ui';

import HeaderContainer from '../HeaderContainer';
import ReportSection from '../ReportSection';
import MainWrapper from '../MainWrapper';
import Body from '../Body';

import Container from './Container';
import DateSelectForm from './DateSelectForm';
import Restricted from './Restricted';

class Calendars extends React.Component {
  constructor(props) {
    super(props);

    const query = qs.parse(this.props.router.location.search.replace('?', ''));
    const selectedDate = (query.start_date ? moment(query.start_date) : moment()).startOf('month');

    this.state = {
      selectedDate,
    };
  }

  componentDidMount() {
    if (this.props.model.id) {
      this.request(this.props);
    }
    this.updateQuery();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.model.id) return;
    const nextModel = { id: nextProps.model.id, type: nextProps.model.type };
    const model = { id: this.props.model.id, type: this.props.model.type };

    if (!shallowEqual(nextModel, model)) {
      this.request(nextProps);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const nextModel = { id: nextProps.model.id, type: nextProps.model.type };
    const model = { id: this.props.model.id, type: this.props.model.type };

    return !shallowEqual(nextModel, model) || !shallowEqual(nextState, this.state);
  }

  handleDateChange = value => {
    this.setState(
      () => ({ selectedDate: moment(value).startOf('month') }),
      () => {
        this.updateQuery();
        this.request(this.props);
      },
    );
  };

  request = ({ model }) => {
    if (this.restricted()) return;
    if (!model.id) return;
    this.props.requestData(model, this.state.selectedDate);
  };

  updateQuery = () => {
    if (this.restricted()) return;

    const { model, router } = this.props;
    const { selectedDate } = this.state;

    if (!model.type) return;

    router.push({
      pathname: router.location.pathname,
      search: `?start_date=${selectedDate.format('YYYY-MM-DD')}`,
    });
  };

  restricted = () => {
    const { isUserRestricted, model } = this.props;
    if (!model.type) return false;
    if (model.attributes.featured) return false;
    if (isUserRestricted) window.analytics.track('User restricted', { type: `Calendars` });
    return isUserRestricted;
  };

  render() {
    const { model } = this.props;
    const { selectedDate } = this.state;

    return (
      <MainWrapper>
        <HeaderContainer title="Calendar">
          <DateSelectForm
            onChange={this.handleDateChange}
            restricted={this.restricted()}
            selectedDate={selectedDate.format('YYYY-MM-DD')}
          />
        </HeaderContainer>

        {this.restricted() ? (
          <Restricted model={model} />
        ) : (
          <Body>
            <Flex>
              <ReportSection css={{ minHeight: '860px' }}>
                <Container model={model} selectedDate={selectedDate} />
              </ReportSection>
            </Flex>
          </Body>
        )}
      </MainWrapper>
    );
  }
}

Calendars.defaultProps = {
  model: { id: null },
};

Calendars.propTypes = {
  isUserRestricted: PropTypes.bool.isRequired,
  model: PropTypes.shape({ id: PropTypes.string, type: PropTypes.string }),
  requestData: PropTypes.func.isRequired,
  router: PropTypes.shape({
    location: PropTypes.shape({
      query: PropTypes.object,
      search: PropTypes.string,
    }),
  }).isRequired,
};

const mapStateToProps = state => ({
  isUserRestricted: userSelectors.isUserRestrictedSelector(state),
});

const mapDispatchToProps = dispatch => ({
  requestData: (model, startDate) => {
    const requestAction =
      model.type === 'groups'
        ? calendars.calendarsActions.fetchGroupDataRequest
        : calendars.calendarsActions.fetchBrandDataRequest;
    return dispatch(requestAction(model.id, startDate.format('YYYY-MM-DD')));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Calendars);
