import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';

import { dollar } from '../../lib/utils';
import { teamsActions, teamsSelectors } from '../../modules/teams';

import { Button, Flex, Heading, Loader, Modal, Text } from '../mc-ui';

import * as S from './styles';

class ReactivateConfirmationModal extends React.Component {
  state = {
    loading: false,
    reactivating: false,
  };

  confirm = event => {
    if (event) event.preventDefault();
    const { closeModal, reactivateSubscription } = this.props;

    this.setState(
      () => ({ reactivating: true }),
      () => {
        reactivateSubscription(closeModal);
      },
    );
  };

  render() {
    const { closeModal, membersMeta, subscription } = this.props;
    const { loading, reactivating } = this.state;

    let amount = 0;
    if (subscription.attributes.plan_interval === 'year') {
      amount = 149900 + (membersMeta.counts.active - 1) * 49900;
    } else {
      amount = 14900 + (membersMeta.counts.active - 1) * 4900;
    }

    return (
      <Modal isOpen onClose={closeModal}>
        <Modal.Body maxHeight="344px">
          {loading ? (
            <Loader />
          ) : (
            <Flex alignItems="center" direction="column" justifyContent="center" width="696px">
              <img src="/images/alert.png" alt="Alert" />

              <Heading
                as="h2"
                fontSize="24px"
                fontWeight="300"
                lineHeight="1.32em"
                css={{ margin: '40px 0 24px 0' }}
              >
                Are you sure?
              </Heading>

              <Text margin="0 0 24px 0">
                You are about to reactivate <strong>{membersMeta.counts.active} members</strong>.
              </Text>

              <S.UL>
                <S.LI>
                  <em>
                    Your next bill on{' '}
                    {moment(subscription.attributes.current_period_end_at).format(
                      'ddd, MMM. D, YYYY',
                    )}{' '}
                    will total {dollar(amount, { fromCents: true })} for your entire team.
                  </em>
                </S.LI>
              </S.UL>

              <Flex justifyContent="space-between" marginTop="24px" width="220px">
                <Button
                  css={{
                    height: '40px',
                    lineHeight: '40px',
                    margin: 0,
                    padding: 0,
                    textTransform: 'capitalize',
                    width: '100px',
                  }}
                  disabled={reactivating}
                  onClick={this.confirm}
                  variant="primary"
                >
                  Reactivate
                </Button>

                <Button
                  css={{
                    height: '40px',
                    lineHeight: '40px',
                    margin: 0,
                    padding: 0,
                    width: '100px',
                  }}
                  disabled={reactivating}
                  onClick={closeModal}
                  variant="secondary"
                >
                  Nevermind
                </Button>
              </Flex>
            </Flex>
          )}
        </Modal.Body>
      </Modal>
    );
  }
}

ReactivateConfirmationModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  membersMeta: PropTypes.shape({
    counts: PropTypes.shape({
      active: PropTypes.number,
    }),
  }).isRequired,
  reactivateSubscription: PropTypes.func.isRequired,
  subscription: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.shape({
      plan_interval: PropTypes.string,
    }),
  }).isRequired,
};

const mapStateToProps = state => ({
  membersMeta: teamsSelectors.membersMetaSelector(state),
  subscription: teamsSelectors.subscriptionSelector(state),
});

const mapDispatchToProps = dispatch => ({
  reactivateSubscription: callback =>
    dispatch(teamsActions.subscriptionReactivateRequest(callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReactivateConfirmationModal);
