import React from 'react';
import PropTypes from 'prop-types';

import { Box, Flex, Heading, Text } from '../../../../mc-ui';
import CheckCircleIcon from '../../../../mc-ui/icons/CheckCircleIcon';

import Body from '../../../Body';
import ReportSection from '../../../ReportSection';
import RestrictedHeader from '../../../RestrictedHeader';
import RestrictedUpgradeButton from '../../../RestrictedUpgradeButton';

import CompareTo from './CompareTo';
import PremiumBrands from './PremiumBrands';

const isGroup = model => model.type === 'groups';

const Upgrade = ({ model }) => (
  <ReportSection>
    <Body>
      <RestrictedHeader
        description="Discover how brands stack up in ecommerce email marketing."
        reportName="Benchmarks"
      />

      <Flex marginBottom="40px" wrap="wrap">
        <Heading as="h4" fontSize="18px" lineHeight="24px" css={{ marginBottom: '26px' }}>
          Benchmark using MailCharts’ curated collection of ecommerce brands &amp; online retailers
          to compare the following data points:
        </Heading>

        <Flex marginBottom="14px" width="50%">
          <Text color="blue" fontSize="18px" lineHeight="1em" css={{ marginRight: '20px' }}>
            <CheckCircleIcon />
          </Text>

          <Text>Weekly email send frequency</Text>
        </Flex>

        <Flex marginBottom="14px" width="50%">
          <Text color="blue" fontSize="18px" lineHeight="1em" css={{ marginRight: '20px' }}>
            <CheckCircleIcon />
          </Text>

          <Text>Most popular day of week</Text>
        </Flex>

        <Flex marginBottom="14px" width="50%">
          <Text color="blue" fontSize="18px" lineHeight="1em" css={{ marginRight: '20px' }}>
            <CheckCircleIcon />
          </Text>

          <Text>Most popular time of day</Text>
        </Flex>

        <Flex marginBottom="14px" width="50%">
          <Text color="blue" fontSize="18px" lineHeight="1em" css={{ marginRight: '20px' }}>
            <CheckCircleIcon />
          </Text>

          <Text>Promotion rate</Text>
        </Flex>

        <Flex marginBottom="14px" width="50%">
          <Text color="blue" fontSize="18px" lineHeight="1em" css={{ marginRight: '20px' }}>
            <CheckCircleIcon />
          </Text>

          <Text>Subject Line length</Text>
        </Flex>

        <Flex width="50%">
          <Text color="blue" fontSize="18px" lineHeight="1em" css={{ marginRight: '20px' }}>
            <CheckCircleIcon />
          </Text>

          <Text>Mobile optimization rate</Text>
        </Flex>

        <Flex width="50%">
          <Text color="blue" fontSize="18px" lineHeight="1em" css={{ marginRight: '20px' }}>
            <CheckCircleIcon />
          </Text>

          <Text>MailCharts email score (best-practices)</Text>
        </Flex>
      </Flex>

      {isGroup(model) ? (
        <div>
          <Flex justifyContent="space-between" marginBottom="40px">
            <Box width="360px">
              <CompareTo />
            </Box>

            <Flex direction="column" justifyContent="center" padding="0 32px" width="360px">
              <Heading as="h4" fontSize="18px" lineHeight="24px" css={{ marginBottom: '8px' }}>
                Benchmark any brand against its group
              </Heading>

              <Text>
                Speed up weekly competitive reports or brand audits by creating custom groups and
                use the dashboard to see how your brand stacks up on key data points.
              </Text>
            </Flex>
          </Flex>

          <Flex justifyContent="space-between" marginBottom="40px">
            <Flex direction="column" justifyContent="center" padding="0 32px" width="360px">
              <Heading as="h4" fontSize="18px" lineHeight="24px" css={{ marginBottom: '8px' }}>
                Identify areas of opportunity
              </Heading>

              <Text>
                Fill the gaps in your strategic plan by searching data points in custom groups
                (yours or our curated selections).
              </Text>
            </Flex>

            <Box width="360px">
              <PremiumBrands />
            </Box>
          </Flex>
        </div>
      ) : (
        <div>
          <Flex justifyContent="space-between" marginBottom="40px">
            <Box width="360px">
              <PremiumBrands />
            </Box>

            <Flex direction="column" justifyContent="center" padding="0 32px" width="360px">
              <Heading as="h4" fontSize="18px" lineHeight="24px" css={{ marginBottom: '8px' }}>
                Identify areas of opportunity
              </Heading>

              <Text>
                Fill the gaps in your strategic plan by searching data points in custom groups
                (yours or our curated selections).
              </Text>
            </Flex>
          </Flex>

          <Flex justifyContent="space-between" marginBottom="40px">
            <Flex direction="column" justifyContent="center" padding="0 32px" width="360px">
              <Heading as="h4" fontSize="18px" lineHeight="24px" css={{ marginBottom: '8px' }}>
                Benchmark any brand against MailCharts’ curated data
              </Heading>

              <Text>
                Speed up weekly competitive reports or brand audits and see how your brand stacks up
                on key data points.
              </Text>
            </Flex>
            <Box width="360px">
              <CompareTo />
            </Box>
          </Flex>
        </div>
      )}

      <RestrictedUpgradeButton />
    </Body>
  </ReportSection>
);

Upgrade.defaultProps = {
  model: { id: null },
};

Upgrade.propTypes = {
  model: PropTypes.shape({ id: PropTypes.string, type: PropTypes.string }),
};

export default Upgrade;
