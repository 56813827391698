import PropTypes from 'prop-types';
import React from 'react';
import { Box } from '../../mc-ui';
import { connect } from 'react-redux';
import TextMessagesList from '../TextMessagesList';

import { teamsSelectors } from '../../../modules/teams';

class TextMessagesFromModel extends React.Component {
  render() {
    const {
      model,
      isPro,
      hasSmsAddon,
      router
    } = this.props;
    // export default function TextMessagesFromModel({ model, router }) {
    return (
      <Box padding="16px 0 40px 0">
        <TextMessagesList
          model={model}
          router={router}
          isPro={isPro}
          hasSmsAddon={hasSmsAddon}
          isPageRestricted={false}
        />
      </Box>
    );
  };
}

TextMessagesFromModel.defaultProps = {
  model: {},
};

TextMessagesFromModel.propTypes = {
  model: PropTypes.shape({}),
  router: PropTypes.shape({}).isRequired,
  isPro: PropTypes.bool.isRequired,
  hasSmsAddon: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  isPro: teamsSelectors.isProSelector(state),
  hasSmsAddon: teamsSelectors.hasSmsAddonSelector(state),
});

export default connect(
  mapStateToProps,
)(TextMessagesFromModel);
