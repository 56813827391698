import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { isEmpty } from 'ramda';

import LinkIcon from '../../../../mc-ui/icons/LinkIcon';
import { Button } from '../../../../mc-ui';

const RelatedJourneys = ({ journeys, toggleModal }) => {
  if (isEmpty(journeys)) return null;

  return (
    <Button
      onClick={toggleModal}
      variant="secondary"
      css={{
        alignItems: 'center',
        display: 'inline-flex',
        fontSize: '12px',
        fontWeight: '500',
        height: '24px',
        justifyContent: 'space-between',
        letterSpacing: '0.02em',
        margin: '0',
        padding: '0 8px',
        width: '144px',
      }}
    >
      <LinkIcon height="16px" width="16px" /> {journeys.length} related{' '}
      {pluralize('journeys', journeys.length)}
    </Button>
  );
};

RelatedJourneys.propTypes = {
  journeys: PropTypes.arrayOf(PropTypes.object).isRequired,
  toggleModal: PropTypes.func.isRequired,
};

export default RelatedJourneys;
