import api from '../lib/api';
import axios from 'axios';
import { groupOverviewActions } from '../modules/groupOverview';

let req = null;

export default store => next => action => {
  next(action);
  if (action.type === 'REFRESH_COMPANIES') {
    const params = action.params || {};
    params.group_id = action.group_id || store.getState().groups.data.map(g => g.id);
    if (req) req.cancel();
    req = axios.CancelToken.source();
    api({
      method: 'get',
      url: action.url || '/v2/groups',
      params,
      cancelToken: req.token,
    })
      .then(r => {
        const groups = r.data;
        store.dispatch({ type: 'RECEIVE_GROUPS', data: groups });
        if (groups.data.length == 1 && !action.index) {
          const group = groups.data.filter(g => g.id == params.group_id).reduce((m, g) => g, {});
          store.dispatch({ type: 'REQUEST_SEARCH', url: group.relationships.emails.links.self });
          store.dispatch({
            type: 'REQUEST_COMPANIES',
            url: group.relationships.companies.links.self,
          });
        }
      })
      .catch(err => {
        if (axios.isCancel(err)) return; // do nothing
        console.error(err);
      });
  }
  if (action.type === 'REQUEST_GROUPS') {
    let groups;
    const params = action.params || {};
    if (action.group_id) params.group_id = action.group_id;
    req = axios.CancelToken.source();
    api({
      method: 'get',
      url: action.url || '/v3/groups',
      params,
      cancelToken: req.token,
    })
      .then(r => {
        groups = r.data;
        store.dispatch({ type: 'RECEIVE_GROUPS', data: groups });
        if (groups.data.length == 1 && !action.index) {
          const group = groups.data.filter(g => g.id == params.group_id).reduce((m, g) => g, {});
          store.dispatch({
            type: 'REQUEST_COMPANIES',
            url: group.relationships.companies.links.self,
          });
          store.dispatch(groupOverviewActions.fetchDataRequest(group));
        }
      })
      .catch(err => {
        console.error(err);
        store.dispatch({ type: 'CATCH_ERROR', error: err});
      });
  }
  if (action.type == 'REQUEST_GROUPS_PAGINATION') {
    const link = store.getState().groups.links.next;
    if (!link) return;
    api.get(link).then(r => {
      const groups = r.data;
      store.dispatch({ type: 'RECEIVE_GROUPS_PAGINATION', groups });
    });
  }
  if (action.type == 'ADD_OR_REMOVE_GROUPS_COMPANIES') {
    const { tracking = false } = action.company.attributes;
    const { id: company_id } = action.company;
    if (action.group) {
      const url = `/v2/groups/${action.group.id}/companies`;
      (tracking ? api.delete(url, { params: { company_id } }) : api.post(url, { company_id })).then(
        r => {
          store.dispatch({ type: 'REFRESH_USER' });
          store.dispatch({ type: 'REFRESH_COMPANIES' });
          store.dispatch({ type: 'REFRESH_GROUPS' });
        },
      );
    } else {
      (tracking
        ? api.delete(`/v2/companies/${company_id}/untrack`)
        : api.post(`/v2/companies/${company_id}/track`)
      ).then(r => {
        store.dispatch({ type: 'REFRESH_USER' });
        store.dispatch({ type: 'REFRESH_COMPANIES' });
      });
    }
  }
};
