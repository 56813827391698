import React from 'react';
import PropTypes from 'prop-types';
import styled from "@emotion/styled";

import ContactModalLink from '../../ContactModalLink';
import { Flex, Heading, Loader, Text } from '../../mc-ui';
import EmptyCoffeehouse from '../../mc-ui/icons/EmptyCoffeehouse';

const Wrapper = styled(Flex)(({ theme }) => ({
  alignItems: 'center',
  border: `1px solid ${theme.colors.dark5}`,
  borderRadius: '3px',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '480px',
}));

const Loading = () => (
  <Wrapper>
    <Loader>
      <EmptyCoffeehouse height="86px" width="124px" />
    </Loader>
  </Wrapper>
);

const NoDataFound = () => (
  <Wrapper>
    <EmptyCoffeehouse height="86px" width="124px" />
    <Heading
      as="h4"
      color="dark06"
      css={{ lineHeight: '1.32em', marginBottom: '12px', marginTop: '30px' }}
    >
        Hrm, we couldn't find any data!
    </Heading>
    <Text>
      Please <ContactModalLink>contact support</ContactModalLink> if you have
      questions or feedback. We'll be happy to help!
    </Text>
  </Wrapper>
);

const LoadingStatus = ({ loading, isDataAndInsightsEmpty }) => {
  if (loading) return <Loading />;
  if (!loading && isDataAndInsightsEmpty) return <NoDataFound />;
  return null;
};

LoadingStatus.propTypes = {
  isDataAndInsightsEmpty: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default LoadingStatus;
