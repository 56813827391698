import React from 'react';
import PropTypes from 'prop-types';

import { Flex, Heading, Text } from '../mc-ui';

import Wrapper from './Wrapper';

export default function Details({ bullets, imgSrc, title }) {
  return (
    <Wrapper css={{ padding: '32px 24px' }}>
      <Flex alignItems="center" justifyContent="center" marginBottom="24px">
        <img
          style={{ margin: '0 auto', width: '136px', height: '136px' }}
          src={imgSrc}
          alt={title}
        />
      </Flex>

      <Heading as="h4" fontSize="18px" lineHeight="24px" css={{ marginBottom: '24px' }}>
        {title}
      </Heading>

      <ul style={{ margin: 0, padding: 0, listStyle: 'none' }}>
        {bullets.map((bullet, index) => {
          const key = `${title.toLowerCase().replace(/\W/g, '')}-${index}`;

          return (
            <li
              key={key}
              style={{
                paddingLeft: '30px',
                paddingBottom: '8px',
                background: `url('/unlocked-check-circle.svg') no-repeat 0 0`,
              }}
            >
              <Text color="dark2" fontSize="14px" lineHeight="22px">
                {bullet}
              </Text>
            </li>
          );
        })}
      </ul>
    </Wrapper>
  );
}

Details.defaultProps = {
  imgSrc: 'http://placehold.it/136x136',
  title: 'Title',
  bullets: ['Lorem', 'ipsum', 'dolor'],
};

Details.propTypes = {
  bullets: PropTypes.arrayOf(PropTypes.any),
  imgSrc: PropTypes.string,
  title: PropTypes.string,
};
