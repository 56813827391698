import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { connect } from 'react-redux';
import { ascend, prop, sort } from 'ramda';

import { journeysSelectors as selectors } from '../../../../modules/journeys';

import { Box, Button, Flex } from '../../../mc-ui';
import Select from '../../../mc-ui/overrides/ReactSelect';
import GridIcon from '../../../mc-ui/icons/GridIcon';
import TimelineIcon from '../../../mc-ui/icons/TimelineIcon';

const sortAscending = sort(ascend(prop('type')));

const formatClassificationType = classificationType => {
  const type = classificationType.type
    .split('_')
    .map(w => w.charAt(0).toUpperCase() + w.slice(1).toLowerCase())
    .join(' ');

  return `${type} (${classificationType.count})`;
};

const classificationOptions = meta =>
  [
    {
      label: `All  ${pluralize('email', meta.totalEmails.avg)} (${meta.totalEmails.avg})`,
      value: 'all',
    },
    {
      label: `Triggered ${pluralize('email', meta.triggeredEmails.avg)} (${
        meta.triggeredEmails.avg
      })`,
      value: 'classified',
    },
  ].concat(
    sortAscending(meta.classificationTypes || []).map(classificationType => ({
      label: formatClassificationType(classificationType),
      value: classificationType.type,
    })),
  );

const Filters = ({ changeView, classificationType, filter, loading, meta, updateFilter, view }) => {
  if (loading) return null;

  const buttonCSS = {
    alignItems: 'center',
    display: 'inline-flex',
    height: '33px',
    lineHeight: '33px',
    paddingBottom: 'unset',
    paddingTop: 'unset',
    '& > svg': {
      marginRight: '10px',
    },
  };

  const options = classificationOptions(meta);
  const classificationValue = options.find(
    o => o.value === filter || o.value === classificationType,
  );

  return (
    <Flex height="33px" marginBottom="38px">
      <Button
        onClick={changeView('thumbnail')}
        variant={view === 'thumbnail' ? 'primary' : 'secondary'}
        css={buttonCSS}
      >
        <GridIcon height="20px" width="20px" />
        Thumbnail
      </Button>

      <Button
        onClick={changeView('timeline')}
        variant={view === 'timeline' ? 'primary' : 'secondary'}
        css={{ margin: '0 20px 0 8px', ...buttonCSS }}
      >
        <TimelineIcon height="20px" width="20px" />
        Timeline
      </Button>

      <Box width="250px" zIndex="2">
        <Select
          name="filter"
          value={classificationValue}
          onChange={updateFilter}
          options={options}
        />
      </Box>
    </Flex>
  );
};

Filters.defaultProps = {
  classificationType: null,
};

Filters.propTypes = {
  changeView: PropTypes.func.isRequired,
  classificationType: PropTypes.string,
  filter: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  meta: PropTypes.shape({
    classificationTypes: PropTypes.arrayOf(
      PropTypes.shape({
        count: PropTypes.number,
        type: PropTypes.string,
      }),
    ),
  }).isRequired,
  updateFilter: PropTypes.func.isRequired,
  view: PropTypes.string.isRequired,
};

const mapStateToProps = (state, { location, model, params }) => ({
  loading: selectors.getJourneyLoading(model, params.journey_id, location.query)(state),
  meta: selectors.getJourneyMeta(model, params.journey_id, location.query)(state),
});

export default connect(mapStateToProps)(Filters);
