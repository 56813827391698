import React from 'react';
import PropTypes from 'prop-types';

import Wrapper from './ui/Wrapper';

import ExportOptions from './ExportOptions';
import SingleHeader from './SingleHeader';

const ComparisonHeader = ({
  currentQuery,
  model,
  queryScope,
  setQuery,
  toggleComparisonMode,
  updateRouterQuery,
}) => (
  <Wrapper>
    <div style={{ justifyContent: 'space-between' }}>
      <h3 style={{ margin: 0, padding: 0 }}>Compare reports</h3>
      <ExportOptions model={model} queryScope={queryScope} showExport comparisonMode />
    </div>

    <div style={{ alignItems: 'stretch' }}>
      <SingleHeader
        currentQuery={currentQuery}
        model={model}
        setQuery={setQuery}
        toggleComparisonMode={toggleComparisonMode}
        updateRouterQuery={updateRouterQuery}
        comparisonMode
      />
      <SingleHeader
        currentQuery={currentQuery}
        model={model}
        queryScope={queryScope}
        setQuery={setQuery}
        toggleComparisonMode={toggleComparisonMode}
        updateRouterQuery={updateRouterQuery}
        comparisonMode
      />
    </div>
  </Wrapper>
);

ComparisonHeader.propTypes = {
  model: PropTypes.shape({}).isRequired,
  queryScope: PropTypes.string.isRequired,
  setQuery: PropTypes.func.isRequired,
  toggleComparisonMode: PropTypes.func.isRequired,
  updateRouterQuery: PropTypes.func.isRequired,
  currentQuery: PropTypes.shape({}),
};

ComparisonHeader.defaultProps = {
  currentQuery: {},
};

export default ComparisonHeader;
