import React from 'react';

const SvgTrue = props => (
  <svg width="1em" height="1em" viewBox="0 0 16 12" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.052.812c.474.449.495 1.197.046 1.67L6.096 12 .8 6.224A1.182 1.182 0 112.54 4.627l3.582 3.905L13.38.86a1.182 1.182 0 011.671-.047z"
      fill="currentColor"
      fillOpacity={0.8}
    />
  </svg>
);

export default SvgTrue;
