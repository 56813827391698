import React from 'react';
import PropTypes from 'prop-types';

import HolidaysList from './HolidaysList';
import JourneyTypesList from './JourneyTypesList';
import JourneysList from './JourneysList';
import ListsList from './ListsList';
import MostTrackedBrandsList from './MostTrackedBrandsList';

const List = ({ collection, type }) => {
  let Component = null;

  if (type === 'lists') {
    Component = ListsList;
  } else if (type === 'journeys') {
    Component = JourneysList;
  } else if (type === 'customerJourneysList') {
    Component = JourneyTypesList;
  } else if (type === 'upcomingHolidays') {
    Component = HolidaysList;
  } else if (type === 'mostTrackedBrands') {
    Component = MostTrackedBrandsList;
  }

  return <Component collection={collection} />;
};

List.propTypes = {
  collection: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
    }),
  ).isRequired,
  type: PropTypes.string.isRequired,
};

export default List;
