/** @jsx jsx */
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { jsx, withTheme } from '@emotion/react';

import { Box, ScrollableBox } from '../../../mc-ui';

import TypeBadge from '../../../BrandOverview/DataAndInsights/FeaturedJourneys/Journeys/TypeBadge';

import background from './backgrounds/verticalDash.png';

const JourneysList = ({ collection: journeys, theme }) => (
  <ScrollableBox
    height="312px"
    scrollBoxHeight="295px"
    css={{
      backgroundImage: `url(${background})`,
      backgroundPosition: '24px 3px',
      backgroundRepeat: 'repeat-y',
    }}
  >
    {journeys.map(journey => (
      <Box key={journey.id} marginBottom="24px">
        <Link
          to={`/lists/${journey.id}`}
          css={{ textDecoration: 'none' }}
        >
          <TypeBadge
            type={journey.attributes.journeyType}
            css={{
              '&:hover': {
                boxShadow: `0px 2px 8px ${theme.colors.dark02}`
              }
            }}
          />
        </Link>
      </Box>
    ))}
  </ScrollableBox>
);

JourneysList.propTypes = {
  collection: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      attributes: PropTypes.shape({
        journeyType: PropTypes.string,
      }),
    }),
  ).isRequired,
  theme: PropTypes.shape({}).isRequired,
};

export default withTheme(JourneysList);
