/* global window */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import * as topics from './topics';

import SentWrapper from './ui/SentWrapper';
import Wrapper from './ui/Wrapper';

import SuccessIcon from './ui/icons/SuccessIcon';

class ContactUs extends Component {
  state = {
    error: null,
    message: '',
    topic: this.props.topic,
    view: 'form',
  };

  handleChange = (event) => {
    const field = event.target;
    const { name, value } = field;
    const state = { [name]: value };

    if (name === 'message' && this.state.error) {
      state.error = null;
    }

    this.setState(state);
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const { message, topic } = this.state;

    if (message.length > 0) {
      window.analytics.track('Contact form submitted', {
        topic,
        message,
        url: window.location.href,
      });
      this.setState({ error: null, message: '', view: 'thank-you' });
    } else {
      this.setState({ error: 'Message field is required.' });
    }
  };

  renderThankYou = () => {
    const { onComplete, onGoBack } = this.props;
    let onClose = null;

    if (onComplete) {
      onClose = () => onComplete(null);
    } else if (onGoBack) {
      onClose = onGoBack;
    }

    return (
      <SentWrapper>
        <SuccessIcon height={`${80 / 16}em`} width={`${219 / 16}em`} />
        <h3>Thank you</h3>
        <p>We’ll be in touch soon!</p>
        {onClose && (
          <button
            type="button"
            className="pure-button"
            onClick={onClose}
            style={{
              fontSize: `${13 / 16}em`,
              padding: '.93em',
              width: '48%',
            }}
          >
            Go Back
          </button>
        )}
      </SentWrapper>
    );
  };

  renderForm = () => {
    const { error, message, topic } = this.state;
    const { formStyle, onGoBack, title = 'Contact us', placeholder, } = this.props;

    return (
      <div>
        <h3 style={{ margin: '0px' }}>{title}</h3>

        <form className="pure-form" style={formStyle} onSubmit={this.handleSubmit}>
          <div className="pure-g gutters">
            <label htmlFor="topic" className="pure-u-1">
              <h5 style={{ margin: '.5em 0' }}>What do you want to talk about</h5>
              <select name="topic" value={topic} onChange={this.handleChange} className="pure-u-1">
                {Object.keys(topics).map(key => (
                  <option key={key} value={topics[key]}>
                    {topics[key]}
                  </option>
                ))}
              </select>
            </label>

            <label htmlFor="message" className="pure-u-1">
              <h5 style={{ margin: '.5em 0' }}>Your message</h5>
              <textarea
                name="message"
                value={message}
                placeholder={placeholder}
                onChange={this.handleChange}
                className="pure-u-1"
                style={{ height: '160px', resize: 'none' }}
              />
              {error && (
                <p
                  style={{
                    color: '',
                    marginBottom: '0',
                  }}
                >
                  {error}
                </p>
              )}
            </label>

            <div
              className="pure-u-1"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '24px',
              }}
            >
              <button
                type="submit"
                className="pure-button pure-button-primary"
                style={{
                  fontSize: `${13 / 16}em`,
                  padding: '.93em',
                  width: onGoBack ? '48%' : '100%',
                }}
              >
                Submit
              </button>

              {onGoBack && (
                <button
                  type="button"
                  className="pure-button"
                  onClick={onGoBack}
                  style={{
                    fontSize: `${13 / 16}em`,
                    padding: '.93em',
                    width: '48%',
                  }}
                >
                  Go Back
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    );
  };

  render() {
    const { view } = this.state;

    return (
      <Wrapper>
        {view === 'form' && this.renderForm()}
        {view === 'thank-you' && this.renderThankYou()}
      </Wrapper>
    );
  }
}

ContactUs.propTypes = {
  formStyle: PropTypes.shape({}),
  onComplete: PropTypes.func,
  onGoBack: PropTypes.func,
  topic: PropTypes.string,
};

ContactUs.defaultProps = {
  formStyle: {},
  onComplete: null,
  onGoBack: null,
  topic: topics.QUESTION,
};

export { topics };
export default ContactUs;
