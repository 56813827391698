import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { customGroupsActions as actions } from '../../../modules/customGroups';

import { Box } from '../../mc-ui';

import ControlBar from '../ControlBar';

import MyGroups from './MyGroups';
import TeamGroups from './TeamGroups';

class GroupsCollections extends React.PureComponent {
  componentDidMount() {
    this.props.fetchTeamGroups();
  }

  render() {
    const { collectionType, fetchTeamGroups, fetchUserGroups, router } = this.props;

    if (!collectionType) return null;

    return (
      <Box>
        <ControlBar collectionType={collectionType} />
        {collectionType === 'my' ? (
          <MyGroups request={fetchUserGroups} router={router} />
        ) : (
          <TeamGroups request={fetchTeamGroups} router={router} />
        )}
      </Box>
    );
  }
}

GroupsCollections.defaultProps = {
  collectionType: null,
};

GroupsCollections.propTypes = {
  collectionType: PropTypes.string,
  fetchTeamGroups: PropTypes.func.isRequired,
  fetchUserGroups: PropTypes.func.isRequired,
  router: PropTypes.shape({}).isRequired,
};

const mapDispatchToProps = dispatch => ({
  fetchTeamGroups: (...args) => dispatch(actions.fetchTeamGroupsRequest(...args)),
  fetchUserGroups: (...args) => dispatch(actions.fetchUserGroupsRequest(...args)),
});

export default connect(
  null,
  mapDispatchToProps,
)(GroupsCollections);
