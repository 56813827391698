import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

import { Button, Flex, Heading, Modal, Text } from '../../../mc-ui';

export default function CheckoutSuccessModal({ closeModal, isOpen }) {
  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <Modal.Body paddingTop="40px">
        <Flex alignItems="center" direction="column" justifyContent="center">
          <img src="/images/checkout-success.png" alt="Success" />

          <Heading
            as="h2"
            fontSize="24px"
            fontWeight="300"
            lineHeight="1.32em"
            css={{ margin: '40px 0 24px 0' }}
          >
            Thank you for upgrading to MailCharts Pro!
          </Heading>

          <Text margin="0 0 24px 0">Would you like to add more teammates?</Text>

          <Flex justifyContent="space-between" width="232px">
            <Button
              as={Link}
              to="/manage/teams"
              variant="primary"
              css={{ height: '40px', lineHeight: '40px', margin: 0, padding: 0, width: '160px' }}
            >
              Add team members
            </Button>

            <Button
              variant="secondary"
              onClick={closeModal}
              css={{ height: '40px', lineHeight: '40px', margin: 0, padding: 0, width: '64px' }}
            >
              Skip
            </Button>
          </Flex>
        </Flex>
      </Modal.Body>
    </Modal>
  );
}

CheckoutSuccessModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};
