/* global window */
const currency =require('currency.js')
const {curry} =require('ramda')

const camelcaseKeys = require('camelcase-keys');

const www = path => {
  let hostname = '';
  if (process.env.NODE_ENV === 'production') hostname = 'www.mailcharts.com';
  else if (process.env.NODE_ENV === 'staging') hostname = 'staging.mailcharts.com';
  else if (process.env.NODE_ENV === 'pr') hostname = 'pr.mailcharts.com';
  else hostname = 'localhost:8001';
  return `${window.location.protocol}//${hostname}${path}`;
};

const appURL = path => {
  let hostname = '';
  if (process.env.NODE_ENV === 'production') hostname = 'app.mailcharts.com';
  else if (process.env.NODE_ENV === 'staging') hostname = 'staging-app.mailcharts.com';
  else if (process.env.NODE_ENV === 'pr') hostname = 'pr-app.mailcharts.com';
  else hostname = 'localhost:8001';
  return `${window.location.protocol}//${hostname}${path}`;
};

const camelizeKeys = object => camelcaseKeys(object, { deep: true });

const dollar = (value, options = {}) => {
  const amount = options.fromCents ? Number(value) / 100 : value;
  return currency(amount, { formatWithSymbol: true, ...options }).format();
};
const number = (value, precision = 0) => currency(value, { formatWithSymbol: false, precision });
const formatNumber = (value, precision = 0) => number(value, precision).format();

const shallowEqual = (objA, objB) => {
  if (objA === objB) {
    return true;
  }

  if (typeof objA !== 'object' || objA === null || typeof objB !== 'object' || objB === null) {
    return false;
  }

  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) {
    return false;
  }

  // Test for A's keys different from B.
  const bHasOwnProperty = Object.prototype.hasOwnProperty.bind(objB);
  for (let i = 0; i < keysA.length; i += 1) {
    if (!bHasOwnProperty(keysA[i]) || objA[keysA[i]] !== objB[keysA[i]]) {
      return false;
    }
  }

  return true;
};

const shuffler = curry((random, list) => {
  const result = [];
  let index = 0;
  let position;

  while (index < list.length) {
    position = Math.floor((index + 1) * Math.random());
    result[index] = result[position];
    result[position] = list[index];
    index += 1;
  }

  return result;
});

const shuffle = shuffler(Math.random);

const makeString = object => {
  if (object == null) return '';
  return `${object}`;
};

const titleize = str =>
  makeString(str)
    .toLowerCase()
    .replace(/(?:^|\s|-)\S/g, c => c.toUpperCase());

exports.www = www;
exports.camelizeKeys = camelizeKeys;
exports.dollar = dollar;
exports.number = number;
exports.formatNumber = formatNumber;
exports.shallowEqual = shallowEqual;
exports.shuffler = shuffler;
exports.shuffle = shuffle;
exports.appURL = appURL;
exports.makeString = makeString;
exports.titleize = titleize;
