import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { isEmpty } from 'ramda';

import { Flex, Text } from '../../../mc-ui';

import BrandsWeekTable from '../../BrandsWeekTable';

const BrandsWeekDays = ({ brands }) =>
  isEmpty(brands) ? (
    <Flex alignItems="center" height="259px" justifyContent="center">
      <Text>No data</Text>
    </Flex>
  ) : (
    <BrandsWeekTable>
      <BrandsWeekTable.Header
        data={['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']}
      />
      {brands.map(brand => (
        <BrandsWeekTable.Row key={`brands-week-table-${brand.id}`}>
          <BrandsWeekTable.Logo brand={brand} />
          <Flex css={{ boxSizing: 'border-box', paddingLeft: '16px', width: '100%' }}>
            {brand.meta.stats.sentAtDayOfWeek.map(day => (
              <BrandsWeekTable.Bar
                key={`brands-week-table-${brand.id}-${day.key}`}
                uuid={`${brand.id}-${day.key}`}
                size={day.value}
                tooltip={
                  <p>
                    <strong>{brand.attributes.name}</strong>
                    <br />
                    {day.key.charAt(0).toUpperCase() + day.key.slice(1)}: {day.docCount}{' '}
                    {pluralize('email', day.docCount)} sent
                  </p>
                }
              />
            ))}
          </Flex>
        </BrandsWeekTable.Row>
      ))}
    </BrandsWeekTable>
  );

BrandsWeekDays.defaultProps = {
  brands: [],
};

BrandsWeekDays.propTypes = {
  brands: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      meta: PropTypes.shape({
        stats: PropTypes.shape({
          sentAtDayOfWeek: PropTypes.arrayOf(
            PropTypes.shape({
              docCount: PropTypes.number,
              key: PropTypes.string,
              value: PropTypes.number,
            }),
          ),
        }),
      }),
    }),
  ),
};

export default BrandsWeekDays;
