import styled from "@emotion/styled";

export const PhoneWrapper = styled("div")(({ theme }) => ({
  background: theme.colors.white,
  border: `1px solid ${theme.colors.dark5}`,
  borderRadius: '32px',
  boxShadow: `0px 2px 8px ${theme.colors.dark02}`,
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  paddingBottom: '16px',
  width: '360px',
}));

export const PhoneHeader = styled("div")(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.colors.dark6,
  display: 'flex',
  flexDirection: 'column',
  height: '72px',
  justifyContent: 'center',
}));

export const PhoneBody = styled("div")(({ theme }) => ({
  backgroundColor: theme.colors.white,
  height: '552px',
  overflowY: 'auto',
  padding: '24px 16px 0 16px',
}));

export const Message = styled("p")(({ theme }) => ({
  backgroundColor: theme.colors.dark6,
  borderRadius: '16px',
  color: theme.colors.dark1,
  fontFamily: theme.font.family,
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '1.56em',
  margin: '0',
  padding: '8px',
  width: '296px',
}));

export const SentAt = styled("p")(({ theme }) => ({
  color: theme.colors.dark06,
  fontFamily: theme.font.family,
  fontSize: '10px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '1.56em',
  margin: '4px 0 0 0',
}));

export const Picture = styled("picture")({
  borderRadius: '16px',
  marginTop: '16px',
  maxWidth: '296px',
  '& > *': {
    borderRadius: '16px',
    marginTop: '16px',
    maxWidth: '296px',
  },
});
