import * as React from 'react';
import PropTypes from 'prop-types';

function ListInboxIcon({ fillOpacity, ...props }) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        clipRule="evenodd"
        d="M7 7H3v4h4V7zm0 7H3v4h4v-4zm10-4H9V8h8v2zm-8 7h12v-2H9v2z"
        fill="currentColor"
        fillOpacity={fillOpacity}
        fillRule="evenodd"
      />
    </svg>
  );
}

ListInboxIcon.propTypes = {
  fillOpacity: PropTypes.number,
};

ListInboxIcon.defaultProps = {
  fillOpacity: 1,
};

export default ListInboxIcon;
