/* eslint-disable import/prefer-default-export */
import { call, put } from 'redux-saga/effects';

import { camelizeKeys } from '../../lib/utils';

import * as actions from './actions';
import * as api from './api';

export function* fetchCoupon({ id }) {
  try {
    const coupon = yield call(api.fetchCoupon, id);
    if (coupon && coupon.attributes.valid) {
      const data = camelizeKeys(coupon);
      yield put(actions.fetchCouponSuccess(data));
    } else if (!coupon.attributes.valid) {
      yield put(actions.fetchCouponFailure());
    }
  } catch (error) {
    yield put(actions.fetchCouponFailure(error));
  }
}
