import React from 'react'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import { contains } from 'ramda'

import humanize from 'humanize-number'

import styles from '../styles.css'

class SearchMenu extends React.Component {
  render() {
    let m = {}
    let active = this.props.location.pathname.split('/').pop()
    return (
      <div className={styles['sub-menu']}>
        <div style={{ background: '#FFFFFF', }}>
          <div style={{ margin: '0 auto', maxWidth: 1280, }}>
          <div style={{ margin: '0 auto', maxWidth: 1280, }}>
            <div className='pure-u-1-12' />
            <div className='pure-u-5-6'>
            <div key={m.id} className='pure-menu pure-menu-horizontal custom-restricted-width'>
              <ul className='pure-menu-list'>
                <li className="pure-menu-item">
                  <Link
                    to={{
                      pathname: `/search/emails`,
                      query: this.props.router.location.query
                    }}
                    className='pure-menu-link'
                    style={contains(active, ['emails', 'inbox', 'calendar', 'search']) ? { borderBottom: '3px solid #0379D5' } : null}
                  >
                    Emails
                  </Link>
                </li>
                <li className="pure-menu-item">
                  <Link
                    to={{
                      pathname: `/search/${m.id}/journeys`,
                      query: this.props.router.location.query
                    }}
                    style={active == 'journeys' ? { borderBottom: '3px solid #0379D5' } : null }
                    className='pure-menu-link'>
                    Suggestions
                    {this.props.suggestions.loading || this.props.suggestions.data.length === 0 ? null
                    :
                    <span
                      style={{
                        marginLeft: 3,
                        background: '#DD2325',
                        borderRadius: '50%',
                        padding: '3px 6px',
                        color: '#ffffff'
                      }}
                    >
                      {humanize(this.props.suggestions.data.length)}
                    </span>
                    }
                  </Link>
                </li>
                <li className="pure-menu-item">
                  <Link
                    to={{
                      pathname: `/search/reports`,
                      query: this.props.router.location.query
                    }}
                    style={active == 'reports' ? { borderBottom: '3px solid #0379D5' } : null }
                    className='pure-menu-link'>
                    Reports
                  </Link>
                </li>
              </ul>
            </div>
            </div>
            <div className='pure-u-1-12' />
          </div>
          </div>
      </div>
    </div>)
  }
}

const s = (state) => ({ suggestions: state.suggestions, searches: state.searches })
export default connect(s)(SearchMenu)

