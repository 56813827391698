import * as types from './types';

export const createRequest = (values, resolve) => ({
  type: types.CREATE_REQUEST,
  values,
  resolve,
});

export const createSuccess = data => ({
  type: types.CREATE_SUCCESS,
  data,
});

export const createFailure = error => ({
  type: types.CREATE_FAILURE,
  error,
});

export const deleteRequest = group => ({
  type: types.DELETE_REQUEST,
  group,
});

export const deleteSuccess = group => ({
  type: types.DELETE_SUCCESS,
  group,
});

export const deleteFailure = error => ({
  type: types.DELETE_FAILURE,
  error,
});

export const closeCreateForm = () => ({
  type: types.CLOSE_CREATE_FORM,
});

export const openCreateForm = () => ({
  type: types.OPEN_CREATE_FORM,
});

export const closeEditForm = () => ({
  type: types.CLOSE_EDIT_FORM,
});

export const openEditForm = group => ({
  type: types.OPEN_EDIT_FORM,
  group,
});

export const updateRequest = (id, values, resolve) => ({
  type: types.UPDATE_REQUEST,
  id,
  values,
  resolve,
});

export const updateSuccess = data => ({
  type: types.UPDATE_SUCCESS,
  data,
});

export const updateFailure = error => ({
  type: types.UPDATE_FAILURE,
  error,
});

export const fetchUserGroupsRequest = (params = {}) => ({
  type: types.FETCH_USER_GROUPS_REQUEST,
  params,
});

export const fetchUserGroupsSuccess = data => ({
  type: types.FETCH_USER_GROUPS_SUCCESS,
  data,
});

export const fetchUserGroupsFailure = error => ({
  type: types.FETCH_USER_GROUPS_FAILURE,
  error,
});

export const fetchTeamGroupsRequest = (params = {}) => ({
  type: types.FETCH_TEAM_GROUPS_REQUEST,
  params,
});

export const fetchTeamGroupsSuccess = data => ({
  type: types.FETCH_TEAM_GROUPS_SUCCESS,
  data,
});

export const fetchTeamGroupsFailure = error => ({
  type: types.FETCH_TEAM_GROUPS_FAILURE,
  error,
});

export const shareWithTeamRequest = groupId => ({
  type: types.SHARE_WITH_TEAM_REQUEST,
  groupId,
});

export const shareWithTeamSuccess = data => ({
  type: types.SHARE_WITH_TEAM_SUCCESS,
  data,
});

export const shareWithTeamFailure = error => ({
  type: types.SHARE_WITH_TEAM_FAILURE,
  error,
});

export const createTeamGroupRequest = (values, resolve) => ({
  type: types.CREATE_TEAM_GROUP_REQUEST,
  values,
  resolve,
});

export const createTeamGroupSuccess = data => ({
  type: types.CREATE_TEAM_GROUP_SUCCESS,
  data,
});

export const createTeamGroupFailure = error => ({
  type: types.CREATE_TEAM_GROUP_FAILURE,
  error,
});

export const deleteTeamGroupRequest = groupId => ({
  type: types.DELETE_TEAM_GROUP_REQUEST,
  groupId,
});

export const deleteTeamGroupSuccess = data => ({
  type: types.DELETE_TEAM_GROUP_SUCCESS,
  data,
});

export const deleteTeamGroupFailure = error => ({
  type: types.DELETE_TEAM_GROUP_FAILURE,
  error,
});

export const updateTeamGroupRequest = (id, values, resolve) => ({
  type: types.UPDATE_TEAM_GROUP_REQUEST,
  id,
  values,
  resolve,
});

export const updateTeamGroupSuccess = data => ({
  type: types.UPDATE_TEAM_GROUP_SUCCESS,
  data,
});

export const updateTeamGroupFailure = error => ({
  type: types.UPDATE_TEAM_GROUP_FAILURE,
  error,
});

export const addBrandsToGroupRequest = (groupId, brands, resolve, reject) => ({
  type: types.ADD_BRANDS_TO_GROUP_REQUEST,
  groupId,
  brands,
  resolve,
  reject,
});

export const addBrandsToGroupSuccess = (groupId, brands) => ({
  type: types.ADD_BRANDS_TO_GROUP_SUCCESS,
  groupId,
  brands,
});

export const addBrandsToGroupFailure = error => ({
  type: types.ADD_BRANDS_TO_GROUP_FAILURE,
  error,
});

export const removeBrandsToGroupRequest = (groupId, brands, resolve, reject) => ({
  type: types.REMOVE_BRANDS_TO_GROUP_REQUEST,
  groupId,
  brands,
  resolve,
  reject,
});

export const removeBrandsToGroupSuccess = (groupId, brands) => ({
  type: types.REMOVE_BRANDS_TO_GROUP_SUCCESS,
  groupId,
  brands,
});

export const removeBrandsToGroupFailure = error => ({
  type: types.REMOVE_BRANDS_TO_GROUP_FAILURE,
  error,
});

export const createGroupAndAddBrandsRequest = (brands, values, resolve) => ({
  type: types.CREATE_GROUP_AND_ADD_BRANDS_REQUEST,
  brands,
  values,
  resolve,
});

export const createGroupAndAddBrandsSuccess = () => ({
  type: types.CREATE_GROUP_AND_ADD_BRANDS_SUCCESS,
});

export const createGroupAndAddBrandsFailure = error => ({
  type: types.CREATE_GROUP_AND_ADD_BRANDS_FAILURE,
  error,
});
