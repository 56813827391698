import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { benchmarksSelectors as selectors } from '../../../modules/newReports/benchmarks';

import { Flex, Loader, Table } from '../../mc-ui';

import ReportSection from '../ReportSection';
import Title from '../Title';

const MostPopularEmoji = ({ comparingToName, comparisonName, data, loading }) => (
  <Flex>
    <ReportSection css={{ minHeight: '260px' }}>
      <ReportSection.Header>
        <Title>Most Popular Emoji</Title>
      </ReportSection.Header>
      <ReportSection.Body>
        {loading ? (
          <Flex alignItems="center" justifyContent="center" css={{ height: '126px' }}>
            <Loader />
          </Flex>
        ) : (
          <Table>
            <Table.Head>
              <Table.Row>
                <Table.Cell align="center" width="470px">
                  &nbsp;
                </Table.Cell>
                <Table.Cell align="center">#1</Table.Cell>
                <Table.Cell align="center">#2</Table.Cell>
                <Table.Cell align="center">#3</Table.Cell>
                <Table.Cell align="center">#4</Table.Cell>
                <Table.Cell align="center">#5</Table.Cell>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              <Table.Row>
                <Table.Cell colSpan={6 - data.mostPopularEmojis.comparisonModel.length}>
                  {comparisonName}
                </Table.Cell>
                {data.mostPopularEmojis.comparisonModel.map(emoji => (
                  <Table.Cell key={`comparisonModel-${emoji}`} align="center">
                    <span role="img" aria-label="emoji">
                      {emoji}
                    </span>
                  </Table.Cell>
                ))}
              </Table.Row>
              <Table.Row>
                <Table.Cell colSpan={6 - data.mostPopularEmojis.comparingToModel.length}>
                  {comparingToName}
                </Table.Cell>
                {data.mostPopularEmojis.comparingToModel.map(emoji => (
                  <Table.Cell key={`comparingToModel-${emoji}`} align="center">
                    <span role="img" aria-label="emoji">
                      {emoji}
                    </span>
                  </Table.Cell>
                ))}
              </Table.Row>
            </Table.Body>
          </Table>
        )}
      </ReportSection.Body>
    </ReportSection>
  </Flex>
);

MostPopularEmoji.propTypes = {
  comparingToName: PropTypes.string.isRequired,
  comparisonName: PropTypes.string.isRequired,
  data: PropTypes.shape({
    mostPopularEmojis: PropTypes.shape({
      comparingToModel: PropTypes.arrayOf(PropTypes.string),
      comparisonModel: PropTypes.arrayOf(PropTypes.string),
    }),
  }).isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, { model, brandId }) => {
  if (brandId && model.id && model.type === 'groups') {
    return {
      comparingToName: selectors.getGroupComparisonComparingToNamenName(model.id, brandId)(state),
      comparisonName: selectors.getGroupComparisonComparisonName(model.id, brandId)(state),
      data: selectors.getGroupComparisonData(model.id, brandId)(state),
      loading: selectors.getGroupComparisonLoading(model.id, brandId)(state),
    };
  }

  if (model.id && model.type === 'companies') {
    return {
      comparingToName: selectors.getBrandComparisonComparingToNamenName(model.id)(state),
      comparisonName: selectors.getBrandComparisonComparisonName(model.id)(state),
      data: selectors.getBrandComparisonData(model.id)(state),
      loading: selectors.getBrandComparisonLoading(model.id)(state),
    };
  }

  return {
    comparingToName: '',
    comparisonName: '',
    data: { mostPopularEmojis: { comparingToModel: [], comparisonModel: [] } },
    loading: true,
  };
};

// export default connect(mapStateToProps)(MostPopularEmoji);
// HIDES COMPONENT UNTIL EMOJI DATA READY:
export default connect(mapStateToProps)(() => null);
