import { isNil } from 'ramda';
import { createSelector } from 'reselect';

const getState = state => state.newReports.benchmarks || {};

export const getGroup = groupId => createSelector(getState, state => state.groups[groupId] || {});

export const getGroupBrandsOptions = groupId =>
  createSelector(getGroup(groupId), group => group.brandsOptions || {});

export const getGroupBrandsOptionsData = groupId =>
  createSelector(getGroupBrandsOptions(groupId), brandsOptions => brandsOptions.data || []);

export const getGroupBrandsOptionsLoading = groupId =>
  createSelector(
    getGroupBrandsOptions(groupId),
    brandsOptions => (isNil(brandsOptions.loading) ? true : brandsOptions.loading),
  );

export const getGroupComparison = (groupId, brandId) =>
  createSelector(getGroup(groupId), group => (group.comparison || {})[brandId || 'premium'] || {});

export const getGroupComparisonComparingToNamenName = (groupId, brandId) =>
  createSelector(
    getGroupComparison(groupId, brandId),
    comparison => comparison.comparingToName || '',
  );

export const getGroupComparisonComparisonName = (groupId, brandId) =>
  createSelector(
    getGroupComparison(groupId, brandId),
    comparison => comparison.comparisonName || '',
  );

export const getGroupComparisonData = (groupId, brandId) =>
  createSelector(getGroupComparison(groupId, brandId), comparison => comparison.data || {});

export const getGroupComparisonLoading = (groupId, brandId) =>
  createSelector(
    getGroupComparison(groupId, brandId),
    comparison => (isNil(comparison.loading) ? true : comparison.loading),
  );

export const getBrand = brandId => createSelector(getState, state => state.brands[brandId] || {});

export const getBrandOptions = brandId =>
  createSelector(getBrand(brandId), brand => brand.brandOptions || {});

export const getBrandOptionsData = brandId =>
  createSelector(getBrandOptions(brandId), brandOptions => brandOptions.data || []);

export const getBrandOptionsLoading = brandId =>
  createSelector(
    getBrandOptions(brandId),
    brandOptions => (isNil(brandOptions.loading) ? true : brandOptions.loading),
  );

export const getBrandComparison = brandId =>
  createSelector(getBrand(brandId), brand => (brand.comparison || {}).premium || {});

export const getBrandComparisonComparingToNamenName = brandId =>
  createSelector(getBrandComparison(brandId), comparison => comparison.comparingToName || '');

export const getBrandComparisonComparisonName = brandId =>
  createSelector(getBrandComparison(brandId), comparison => comparison.comparisonName || '');

export const getBrandComparisonData = brandId =>
  createSelector(getBrandComparison(brandId), comparison => comparison.data || {});

export const getBrandComparisonLoading = brandId =>
  createSelector(
    getBrandComparison(brandId),
    comparison => (isNil(comparison.loading) ? true : comparison.loading),
  );
