import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import {
  textMessagesActions as actions,
  textMessagesHelpers as helpers,
  textMessagesSelectors as selectors,
} from '../../../modules/textMessages';
import { userSelectors } from '../../../modules/user';
import BottomTray from '../../BottomTray';
import { Box, Flex, Grid, Link, Spinner, Text } from '../../mc-ui';
import TextMessageThumbnail from '../../ui/TextMessageThumbnail';
import ControlBar from './ControlBar';
import FiltersModal from './FiltersModal';
import Restricted from './Restricted';
import { teamsSelectors } from '../../../modules/teams';

const DEFAULT_MODEL = {
  attributes: {
    name: null,
  },
};

class TextMessagesList extends React.PureComponent {
  state = {
    editing: false,
    filtersOpen: this.props.router.location.query.advanced === 'true',
  };

  componentDidMount() {
    const { data, router } = this.props;

    if (isEmpty(data.data)) {
      const query = helpers.filterQuery(router.location.query, router.params);

      this.query = query;
      this.props.fetch(query);
    }

    const isRestricted = this.isRestricted();
    if (!isRestricted) {
      window.addEventListener('scroll', this.handleScroll);
    }
  }

  componentDidUpdate(prevProps) {
    const prevQuery = helpers.filterQuery(prevProps.router.location.query, prevProps.router.params);

    if (JSON.stringify(this.query) !== JSON.stringify(prevQuery)) {
      this.query = prevQuery;
      this.props.fetch(prevQuery);
    }
  }

  componentWillUnmount() {
    const isRestricted = this.isRestricted();
    if (!isRestricted) {
      window.removeEventListener('scroll', this.handleScroll);
    }
  }

  onRemoveFromBulk = () => {
    this.toggleEditing();
    this.props.fetch(this.query);
  };

  setFiltersOpen = open => {
    const { router } = this.props;

    this.setState({ filtersOpen: open }, () => {
      const newQuery = { ...router.location.query };
      if (open) {
        newQuery.advanced = 'true';
      } else {
        delete newQuery.advanced;
      }

      router.push({ pathname: router.location.pathname, query: newQuery });
    });
  };

  closeFilters = event => {
    if (event) {
      event.preventDefault();
    }

    this.setFiltersOpen(false);
  };

  openFilters = event => {
    if (event) {
      event.preventDefault();
    }

    this.setFiltersOpen(true);
  };

  query = {};

  handleScroll = () => {
    const {
      data: { links },
      paginate,
      paginating,
    } = this.props;

    if (!links.next) return;

    const windowHeight =
      'innerHeight' in window ? window.innerHeight : document.documentElement.offsetHeight;
    const { body, documentElement: html } = document;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight,
    );
    const windowBottom = windowHeight + window.pageYOffset;

    if (windowBottom >= docHeight) {
      setTimeout(() => {
        if (!paginating) {
          paginate(this.query, links.next);
        }
      }, 250); // adds a slight delay to increase "fetching" effect for end-user
    }
  };

  handlePagination = event => {
    event.preventDefault();

    const {
      data: { links },
      paginate,
      paginating,
    } = this.props;

    if (!paginating) {
      paginate(this.query, links.next);
    }
  };

  isRestricted = () => {
    const {
      isUserRestricted,
      isPageRestricted,
      model,
      isPro,
      hasSmsAddon,
      isSubscriptionExempted,
    } = this.props;
    const restrictBasedOnSms = isPro && !hasSmsAddon && isPageRestricted;
    const restricted =
      model && (model.type === 'lists' || model.attributes.featured) ? false : isUserRestricted;
    return isSubscriptionExempted ? false : restricted || restrictBasedOnSms;
  };

  isRemoveEnabledFromBulk = () => {
    const { router, user } = this.props;
    const isInListShowPage = /\/lists\/[\d]+/i.test(router.location.pathname);

    if (!isInListShowPage) {
      return false;
    }

    // Checking ownership
    const list = user.included.filter(i => i.type === 'lists' && i.id === router.params.list_id)[0];
    return !!list;
  };

  toggleEditing = () => {
    this.setState(({ editing }) => ({ editing: !editing }));
  };

  toggleSelectedMessage = messageId => {
    const {
      data: { data: textMessages },
      deselectMessage,
      selectMessage,
    } = this.props;
    const textMessage = textMessages.find(message => message.id === messageId);

    if (textMessage && textMessage.selected) {
      deselectMessage(this.query, messageId);
    } else {
      selectMessage(this.query, messageId);
    }
  };

  render() {
    const { data, loading, model, paginating, router, isSubscriptionExempted, isSubscriptionInvoiced } = this.props;
    const { editing, filtersOpen } = this.state;
    const textMessages = data.data;

    const isRestricted = this.isRestricted();

    return (
      <Box position="relative">
        <ControlBar
          editing={editing}
          loading={loading}
          openFilters={this.openFilters}
          restricted={isRestricted}
          router={router}
          toggleEditing={this.toggleEditing}
          total={data.meta.total}
        />

        <FiltersModal
          model={model}
          open={filtersOpen}
          onClose={this.closeFilters}
          router={router}
        />

        {loading && (
          <Flex alignItems="center" justifyContent="center">
            <Spinner color="dark06" size="32px" />
          </Flex>
        )}

        {!loading &&
          isRestricted && !isSubscriptionExempted && !isSubscriptionInvoiced && (
            <Restricted
              data={textMessages}
              model={model}
              query={this.query}
              total={data.meta.total}
            />
          )}

        {!loading &&
          (!isRestricted || isSubscriptionExempted || isSubscriptionInvoiced) && (
            <Grid
              gridTemplateColumns="repeat(auto-fill, minmax(192px, 1fr))"
              justifyItems="center"
              rowGap="40px"
            >
              {textMessages.map(textMessage => (
                <TextMessageThumbnail
                  key={`TextMessagesList-${textMessage.id}`}
                  textMessage={textMessage}
                  toggleSelected={this.toggleSelectedMessage}
                  editing={editing}
                  selected={!!textMessage.selected}
                />
              ))}
            </Grid>
          )}

        {!loading &&
          data.links.next &&
          (!isRestricted || isSubscriptionExempted || isSubscriptionInvoiced) && (
            <Box margin="40px 0">
              {paginating ? (
                <Flex alignItems="center" justifyContent="center">
                  <Spinner color="dark06" size="32px" />
                </Flex>
              ) : (
                <Flex alignItems="center" direction="column" justifyContent="center">
                  <Text>Scroll to load more messages.</Text>
                  <Text>
                    If you are having trouble loading more message,{' '}
                    <Link onClick={this.handlePagination} to={router.location}>
                      click here
                    </Link>
                    .
                  </Text>
                </Flex>
              )}
            </Box>
          )}

        <BottomTray
          close={this.onRemoveFromBulk}
          contentType="text-messages"
          enabled={editing}
          listId={router.params.list_id}
          model={model}
          removeEnabled={this.isRemoveEnabledFromBulk()}
          router={router}
        />
      </Box>
    );
  }
}

TextMessagesList.defaultProps = {
  model: DEFAULT_MODEL,
  isPageRestricted: true,
};

TextMessagesList.propTypes = {
  data: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      }),
    ),
    links: PropTypes.shape({
      next: PropTypes.string,
    }),
    meta: PropTypes.shape({
      total: PropTypes.number,
    }),
  }).isRequired,
  deselectMessage: PropTypes.func.isRequired,
  fetch: PropTypes.func.isRequired,
  isUserRestricted: PropTypes.bool.isRequired,
  isPageRestricted: PropTypes.bool,
  isSubscriptionExempted: PropTypes.bool.isRequired,
  isSubscriptionInvoiced: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  model: PropTypes.shape({}),
  paginate: PropTypes.func.isRequired,
  paginating: PropTypes.bool.isRequired,
  router: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
      query: PropTypes.object,
    }),
    params: PropTypes.shape({}),
  }).isRequired,
  selectMessage: PropTypes.func.isRequired,
  user: PropTypes.shape({}).isRequired,
  isPro: PropTypes.bool.isRequired,
  hasSmsAddon: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, { router }) => {
  const { pathname } = router.location;
  const query = helpers.filterQuery(router.location.query, router.params);

  return {
    data: selectors.dataSelector({ pathname, query })(state),
    isUserRestricted: userSelectors.isUserRestrictedSelector(state),
    loading: selectors.loadingSelector({ pathname, query })(state),
    paginating: selectors.paginatingSelector({ pathname, query })(state),
    user: state.user,
    isSubscriptionExempted: teamsSelectors.isSubscriptionExemptedSelector(state),
    isSubscriptionInvoiced: teamsSelectors.isSubscriptionInvoicedSelector(state),
  };
};

const mapDispatchToProps = (dispatch, { router }) => {
  const { pathname } = router.location;

  return {
    deselectMessage: (query, id) => dispatch(actions.deselectMessage(pathname, query, id)),
    fetch: query => dispatch(actions.fetchDataRequest(pathname, query || {})),
    paginate: (query, url) => dispatch(actions.paginateDataRequest(pathname, query, url)),
    selectMessage: (query, id) => dispatch(actions.selectMessage(pathname, query, id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TextMessagesList);
