/* eslint-disable prefer-const */
import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@emotion/react';

import { Box } from '../../../mc-ui';

import Header from './Header';
import Row from './Row';

const weekRows = (data, selectedDate, props) => {
  let rows = [];
  let done = false;
  let date = selectedDate
    .clone()
    .startOf('month')
    .add('w' - 1)
    .day('Sunday');
  let monthIndex = date.month();
  let count = 0;

  while (!done) {
    rows.push(
      <Row
        key={`row-${date.toString()}`}
        data={data}
        date={date.clone()}
        selectedDate={selectedDate}
        firstRow={count === 0}
        lastRow={
          count + 1 > 2 &&
          monthIndex !==
            date
              .clone()
              .add(1, 'w')
              .month()
        }
        {...props}
      />,
    );
    date.add(1, 'w');
    count += 1;
    done = count > 2 && monthIndex !== date.month();
    monthIndex = date.month();
  }

  return rows;
};

const Calendar = ({ data, selectedDate, theme, ...props }) => (
  <Box css={{ border: `1px solid ${theme.colors.dark5}` }}>
    <Header />
    {weekRows(data, selectedDate, props)}
  </Box>
);

Calendar.propTypes = {
  data: PropTypes.shape({}).isRequired,
  selectedDate: PropTypes.shape({}).isRequired,
  theme: PropTypes.shape({}).isRequired,
};

export default withTheme(Calendar);
