import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { withTheme } from '@emotion/react';

import { Box, Button, Flex, Loader } from '../../../mc-ui';
import ArrowRightIcon from '../../../mc-ui/icons/ArrowRightIcon';
import Pagination from '../../../ui/Pagination';
import JourneyLine from '../../../NewJourneys/JourneysList/JourneyLine';

import { fetchJourney } from '../../utils/api';

import Header from './Header';

const PAGE_SIZE = 3;

class List extends React.PureComponent {
  state = {
    journeys: [],
    links: {},
    loading: true,
    meta: {},
  };

  componentDidMount() {
    this.request();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.journey.type !== this.props.journey.type) {
      this.request();
    }
  }

  paginate = direction => {
    this.request(this.state.links[direction]);
  };

  request = url => {
    const { journey, tab } = this.props;
    if (!journey) return
    this.setState(
      () => ({ loading: true }),
      async () => {
        const { journeys, links, meta } = await fetchJourney(journey.type, tab, PAGE_SIZE, url);
        this.setState(() => ({ journeys, links, loading: false, meta }));
      },
    );
  };

  render() {
    const { journey: model, theme } = this.props;
    const { journeys, links, loading, meta } = this.state;

    if (loading) {
      return (
        <Flex alignItems="center" height="652px" justifyContent="center" width="824px">
          <Loader />
        </Flex>
      );
    }

    return (
      <Box>
        <Header journey={model} />

        <Flex direction="column" height="552px" justifyContent="space-between">
          <Box
            height="532px"
            position="relative"
            css={theme.scrollBox({ height: '484px' }, { width: '99%' })}
          >
            <Flex direction="column">
              {journeys.map(journey => {
                const [brand] = journey.relationships.companies.data;
                return (
                  <JourneyLine
                    key={journey.id}
                    journey={journey}
                    pathPrefix={`/companies/${brand.id}/journeys`}
                  />
                );
              })}
            </Flex>
          </Box>

          <Flex alignItems="center" justifyContent="space-between">
            <Pagination
              align="left"
              css={{ marginTop: '0', width: 'unset' }}
              from={meta.from}
              nextUrl={links.next}
              onClick={this.paginate}
              prevUrl={links.prev}
              selfUrl={links.self}
              to={meta.to}
              total={meta.total}
            />

            <Button
              as={Link}
              to={{
                pathname: '/journeys/results',
                query: { journey_type: model.type },
              }}
              css={{
                alignItems: 'center',
                display: 'inline-flex',
                height: '34px',
                justifyContent: 'space-between',
                padding: '0 12px',
                width: '155px',
              }}
            >
              <ArrowRightIcon fillOpacity={1} height="20px" width="20px" /> View all journeys
            </Button>
          </Flex>
        </Flex>
      </Box>
    );
  }
}

List.propTypes = {
  journey: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  tab: PropTypes.string.isRequired,
  theme: PropTypes.shape({}).isRequired,
};

export default withTheme(List);
