import React from 'react';
import PropTypes from 'prop-types';

import CalendarIcon from '../../mc-ui/icons/CalendarIcon';

import Dropdown from './Dropdown';

const HistoricalData = ({ closeDropdown, router, ...props }) => {
  const {
    location: { query },
  } = router;

  const newQuery = { ...query };
  delete newQuery.history;
  delete newQuery.tags;
  delete newQuery.twitter_followers;
  delete newQuery.countries;

  const handleClick = () => {
    setTimeout(closeDropdown, 250);
  };

  return (
    <Dropdown icon={CalendarIcon} label="Historical data" data-label="historicalData" {...props}>
      <Dropdown.Item
        to={{ pathname: '/brands/results', query: { ...newQuery, history: '1y' } }}
        onClick={handleClick}
      >
        1 year
      </Dropdown.Item>
      <Dropdown.Item
        to={{ pathname: '/brands/results', query: { ...newQuery, history: '2y' } }}
        onClick={handleClick}
      >
        2 years
      </Dropdown.Item>
      <Dropdown.Item
        to={{ pathname: '/brands/results', query: { ...newQuery, history: '3y' } }}
        onClick={handleClick}
      >
        3 years
      </Dropdown.Item>
      <Dropdown.Item
        to={{ pathname: '/brands/results', query: { ...newQuery, history: '4y' } }}
        onClick={handleClick}
      >
        4 years
      </Dropdown.Item>
      <Dropdown.Item
        to={{ pathname: '/brands/results', query: { ...newQuery, history: '5y' } }}
        onClick={handleClick}
      >
        5 years
      </Dropdown.Item>
    </Dropdown>
  );
};

HistoricalData.propTypes = {
  closeDropdown: PropTypes.func.isRequired,
  router: PropTypes.shape({
    location: PropTypes.shape({
      query: PropTypes.shape({}),
    }),
  }).isRequired,
};

export default HistoricalData;
