import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Box } from '../../mc-ui';

import EmailRow from './EmailRow';

const EmailInbox = ({ editing, emails }) => (
  <Box backgroundColor="white" borderRadius="4px" padding="32px">
    {emails.map(email => (
      <EmailRow inbox editing={editing} key={email.id} email={email} selected={email.selected} />
    ))}
  </Box>
);

EmailInbox.propTypes = {
  editing: PropTypes.bool.isRequired,
  emails: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = state => ({
  emails: state.emails.data,
});

export default connect(mapStateToProps)(EmailInbox);
