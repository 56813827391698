import { call, put } from 'redux-saga/effects';
import { delay } from 'redux-saga';

import * as actions from './actions';

export function* receiveExportTotalAmount({ total, comparisonTotal }) {
  try {
    yield put(actions.startLoading());
    yield put(actions.setExportTotalAmount({ total, comparisonTotal }));
    call(delay, 500);
    yield put(actions.stopLoading());
  } catch (error) {
    yield put(actions.stopLoading());
  }
}
