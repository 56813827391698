export const NAME = 'flashMessage';
export const PREFIX = `mc/${NAME}`;

export const DEFAULT_DURATION = 5000;
export const DEFAULT_PERSIST_ON_HOVER = true;

export const MESSAGE_TYPE_ERROR = 'ERROR';
export const MESSAGE_TYPE_INFO = 'INFO';
export const MESSAGE_TYPE_SUCCESS = 'SUCCESS';
export const MESSAGE_TYPE_WARNING = 'WARNING';
