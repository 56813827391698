import React from 'react'
import EmailThumbnail from './EmailThumbnail'
import Disc from './ui/Disc'
import { palette } from '../lib/color'
import moment from 'moment'
import ReactTooltip from 'react-tooltip'
import styled from "@emotion/styled";
import PropTypes from 'prop-types'

class JourneyGridDailyDetails extends React.Component {
  render () {
    const Wrapper = styled("div")({
      flexGrow: 1,
      flexShrink: 1,
      flexBasis: 0,
      padding: 16,
      background: this.props.backgroundColor ? this.props.backgroundColor : 'transparent'
    })
    const ThumbnailWrapper = styled("div")({
      display: 'flex',
      minHeight: 90,
      marginBottom: 16
    })
    const EventsWrapper = styled("div")({
      display: 'flex'
    })

    return (
      <Wrapper>
        <ThumbnailWrapper>
          {(this.props.emails || []).map((email, i) => {
            const EmailThumbnailWrapper = styled("div")({
              minWidth: 0,
              transition: 'transform 0.3s',
              flexShrink: i === this.props.emails.length - 1 ? 0 : 1, // Let all but the last email shrink if needed
              marginTop: i * 4 // Stagger stacked emails
            })

            return (
              <EmailThumbnailWrapper key={email.id}>
                <EmailThumbnail
                  hideDetails
                  height={90}
                  width={72}
                  padding={0}
                  email={email}
                />
              </EmailThumbnailWrapper>
            )
          })}
        </ThumbnailWrapper>
        <EventsWrapper>
          {(this.props.events || []).map((event, i) => {
            const EventWrapper = styled("div")({
              marginRight: 8,
              flexShrink: i === this.props.emails.length - 1 ? 0 : 1 // Let all but the last event shrink if needed
            })

            const tooltip = <div>
              <div>{event.attributes.label}</div>
              <div>{moment(event.attributes.occurred_at).format('MMM. DD, YYYY – h:mm a')}</div>
            </div>

            return (
              <EventWrapper key={event.id}>
                <div data-tip data-for={event.id} style={{ position: 'relative' }}>
                  <Disc color={palette.orange} />
                </div>
                <ReactTooltip id={event.id} type='dark' effect='solid'>
                  {tooltip}
                </ReactTooltip>
              </EventWrapper>
            )
          })}
        </EventsWrapper>
      </Wrapper>
    );
  }
}

JourneyGridDailyDetails.propTypes = {
  emails: PropTypes.arrayOf(PropTypes.object),
  events: PropTypes.arrayOf(PropTypes.object),
  backgroundColor: PropTypes.string
}

export default JourneyGridDailyDetails
