import React from 'react';
import PropTypes from 'prop-types';
import { ascend, prop, sort } from 'ramda';

import { Box, Flex } from '../../mc-ui';
import ReactSelect from '../../mc-ui/overrides/ReactSelect';

import SingleValueType from './SingleValueType';

const sortAscending = sort(ascend(prop('type')));
const formatType = type =>
  type
    .split('_')
    .map(word => `${word.charAt(0).toUpperCase()}${word.slice(1)}`)
    .join(' ');
const formatClassificationType = classificationType => {
  const type = classificationType.type
    .split('_')
    .map(w => w.charAt(0).toUpperCase() + w.slice(1).toLowerCase())
    .join(' ');

  return `${type} (${classificationType.count})`;
};

class Filters extends React.Component {
  classificationOptions = () => {
    const { loading, classificationTypes } = this.props;
    const options = [
      { label: 'Completed', value: undefined },
      { label: 'Upcoming', value: 'upcoming' },
    ];

    if (loading) return options;

    return options.concat(
      sortAscending(classificationTypes).map(classificationType => ({
        label: formatClassificationType(classificationType),
        value: classificationType.type,
      })),
    );
  };

  journeyTypesOptions = () => {
    const { loading, journeyTypes } = this.props;

    const options = [{ value: undefined, label: 'All journey types' }];

    if (!loading) {
      sortAscending(journeyTypes).forEach(({ type }) => {
        options.push({ value: type, label: formatType(type) });
      });
    }

    return options;
  };

  sortOptions = () => [
    { value: '-start_date', label: 'Newest' },
    { value: 'start_date', label: 'Oldest' },
  ];

  typeValue = () => {
    const { type } = this.props;

    if (type) return { label: formatType(type), value: type };
    return null;
  };

  statusValue = () => {
    const { classificationTypes, status } = this.props;

    if (status) {
      if (status === 'classified') return { label: 'Completed', value: undefined };
      if (status === 'upcoming') return { label: 'Upcoming', value: 'upcoming' };

      const classificationType = classificationTypes.find(ct => ct.type === status);
      return {
        label: formatClassificationType(classificationType),
        value: classificationType.type,
      };
    }

    return { label: 'Completed ', value: undefined };
  };

  sortValue = () => {
    const { sort: sortBy } = this.props;

    if (sortBy === 'start_date') return { value: 'start_date', label: 'Oldest' };
    else return { value: '-start_date', label: 'Newest' };
  };

  render() {
    const { updateSort, updateStatus, updateType } = this.props;

    return (
      <Flex marginBottom="40px">
        <Box marginRight="16px" width="320px">
          <ReactSelect
            components={{ SingleValue: SingleValueType }}
            onChange={updateType}
            options={this.journeyTypesOptions()}
            placeholder="Journey Type"
            value={this.typeValue()}
          />
        </Box>

        <Box marginRight="16px" width="240px">
          <ReactSelect
            onChange={updateSort}
            options={this.sortOptions()}
            placeholder="Sort by"
            value={this.sortValue()}
          />
        </Box>

        <Box width="240px">
          <ReactSelect
            onChange={updateStatus}
            options={this.classificationOptions()}
            placeholder="Filter"
            value={this.statusValue()}
          />
        </Box>
      </Flex>
    );
  }
}

Filters.defaultProps = {
  sort: null,
  status: null,
  type: null,
};

Filters.propTypes = {
  classificationTypes: PropTypes.arrayOf(
    PropTypes.shape({
      count: PropTypes.number,
      type: PropTypes.string,
    }),
  ).isRequired,
  sort: PropTypes.string,
  status: PropTypes.string,
  journeyTypes: PropTypes.arrayOf(
    PropTypes.shape({
      count: PropTypes.number,
      type: PropTypes.string,
    }),
  ).isRequired,
  loading: PropTypes.bool.isRequired,
  type: PropTypes.string,
  updateSort: PropTypes.func.isRequired,
  updateStatus: PropTypes.func.isRequired,
  updateType: PropTypes.func.isRequired,
};

export default Filters;
