import * as React from 'react';
import PropTypes from 'prop-types';

function EmailSubscribeIcon({ fillOpacity, ...props }) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 5.46C19.833 3.47 18.333 2 16.5 2h-13C1.75 2 .25 3.384 0 5.286v9.082C0 16.357 1.583 18 3.5 18h8.707a5.5 5.5 0 107.736-6.43L20 5.46zM17.5 11c.197 0 .392.01.583.03V8.4l-6.75 4.497c-.416.26-.833.433-1.333.433s-1-.173-1.417-.433L1.833 8.4v5.968c0 .95.75 1.73 1.667 1.73h8.514A5.5 5.5 0 0117.5 11zm-14-7.01h12.917c.833 0 1.583.691 1.666 1.556v.605l-7.75 5.103c-.25.173-.5.173-.666 0l-7.75-5.19v-.518c0-.865.75-1.557 1.583-1.557zm13.183 9.885a.875.875 0 011.75 0v1.692h1.634a.933.933 0 110 1.866h-1.634v1.692a.875.875 0 11-1.75 0v-1.692h-1.75a.933.933 0 110-1.866h1.75v-1.692z"
        fill="currentColor"
        fillOpacity={fillOpacity}
      />
    </svg>
  );
}

EmailSubscribeIcon.propTypes = {
  fillOpacity: PropTypes.number,
};

EmailSubscribeIcon.defaultProps = {
  fillOpacity: 1,
};

export default EmailSubscribeIcon;
