import React from 'react';
import { withTheme } from '@emotion/react';

import { Box, Text, Tooltip } from '../../../mc-ui';
import QuestionMarkIcon from '../../../mc-ui/icons/QuestionMarkIcon';

// eslint-disable-next-line react/prop-types
const SparklineTooltip = ({ theme }) => (
  <Box
    css={{
      color: theme.colors.dark06,
      display: 'inline',
      fontSize: '12px',
      lineHeight: '1em',
      marginLeft: '6px',
      width: 'auto',
    }}
  >
    <Tooltip
      id="averageSubjectLineLengthTooltip"
      element={<QuestionMarkIcon fillOpacity={1} />}
      dark
    >
      <Text>
        The average subject line length includes data from all email campaigns sent by brands.
      </Text>
    </Tooltip>
  </Box>
);

export default withTheme(SparklineTooltip);
