import * as React from 'react';
import PropTypes from 'prop-types';

function TeamIcon({ fillOpacity, ...props }) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx={11} cy={8} r={4} fill="currentColor" fillOpacity={fillOpacity} />
      <circle cx={17} cy={17} r={4} fill="currentColor" fillOpacity={fillOpacity} />
      <circle cx={6} cy={17} r={4} fill="currentColor" fillOpacity={fillOpacity} />
    </svg>
  );
}

TeamIcon.propTypes = {
  fillOpacity: PropTypes.number,
};

TeamIcon.defaultProps = {
  fillOpacity: 1,
};

export default TeamIcon;
