import React from 'react'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import SearchMenu from './menus/SearchMenu'

import Header from './Header'
import Section from './Section'

import api from '../lib/api'

class Search extends React.Component {
  query = {}
  request() {
    let query = this.query
    this.props.dispatch({ type: 'REQUEST_SEARCH', query })
    this.props.dispatch({ type: 'REQUEST_SUGGESTIONS', query })
  }
  UNSAFE_componentWillReceiveProps(props) {
    let query = {
      ...this.props.router.location.query,
      ...this.props.router.params
    }
    if (query != this.query) {
      this.query = query
      this.request()
    }
  }
  componentDidMount() {
    let query = {
      ...this.props.router.location.query,
      ...this.props.router.params
    }
    if (query != this.query) {
      this.query = query
      this.request()
    }
  }
  render() {
    return (
      <div className='pure-u-1'>
        <Header>
          <h1>Search</h1>
        </Header>
        <SearchMenu {...this.props} />
        <Section>
          {this.props.children}
        </Section>
      </div>
    )
  }
}

const s = (state) => ({})
export default connect(s)(Search)
