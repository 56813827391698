/* eslint-disable react/prop-types */
import React from 'react';
import styled from "@emotion/styled";

const StyledContainer = styled("div")(({ customSyle = {} }) => ({
  background: '#fff',
  borderRadius: '3px',
  margin: '25px 0',
  padding: '5px 15px',
  ...customSyle,
}));

const Container = ({ children, style, ...rest }) => (
  <div {...rest} style={null}>
    <StyledContainer customSyle={style}>{children}</StyledContainer>
  </div>
);

export default Container;
