/** @jsx jsx */
import { jsx } from '@emotion/react';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router';
import AddToListButton from '../../AddToListButton';
import { Box, Flex, Heading, Text } from '../../mc-ui';
import BrandLogo from '../BrandLogo';
import Checkbox from '../Checkbox';
import CommentCounterBadge from '../CommentCounterBadge';
import * as S from './styles';
import TextMessageImage from './TextMessageImage';

const MODEL_KEYS = ['company_id', 'group_id', 'list_id'];

class TextMessageThumbnail extends React.PureComponent {
  state = {
    showAddToListButton: false,
  };

  closeAddToListButton = () => {
    this.setState(() => ({ showAddToListButton: false }));
  };

  showAddToListButton = () => {
    this.setState(() => ({ showAddToListButton: true }));
  };

  render() {
    const { editing, selected, textMessage, toggleSelected } = this.props;
    const { router } = this.context;
    const { showAddToListButton } = this.state;

    const [brand] = textMessage.relationships.companies.data;

    const viewQuery = Object.entries(router.params).reduce((acc, [key, value]) => {
      if (MODEL_KEYS.includes(key)) {
        acc[key] = value;
      }

      return acc;
    }, {});

    const width = '191px';

    return (
      <Box
        position="relative"
        width={width}
        onMouseOver={this.showAddToListButton}
        onMouseLeave={this.closeAddToListButton}
      >
        <Link
          to={{
            pathname: `/text-messages/${textMessage.id}`,
            query: viewQuery,
          }}
          css={{
            display: 'block',
            textDecoration: 'none',
            width,
          }}
        >
          <S.Wrapper>
            <Box padding="18px 25px 0px 34px">
              <Box maxHeight="212px" overflow="hidden">
                <Flex justifyContent="center" paddingBottom="8px">
                  <TextMessageImage textMessage={textMessage} />
                </Flex>

                <Text color="dark08" fontSize="10px" lineHeight="14px">
                  {textMessage.attributes.full_text}
                </Text>
              </Box>
            </Box>

            <Flex>
              <Box height="32px" marginRight="8px" width="32px">
                <BrandLogo brand={brand} css={{ height: '32px', width: '32px' }} />
              </Box>

              <Flex direction="column" justifyContent="space-between">
                <Heading
                  as="h4"
                  color="dark08"
                  css={{
                    fontSize: '12px',
                    lineHeight: '1.25em',
                    margin: 0,
                    overflow: 'hidden',
                    padding: 0,
                    textOverflow: 'ellipsis',
                    textTransform: 'none',
                    whiteSpace: 'nowrap',
                    width: '140px',
                  }}
                >
                  {brand.attributes.name}
                </Heading>

                <Text color="dark06" fontSize="12px" lineHeight="1.56em">
                  {moment(textMessage.attributes.sent_at).format('MMM D YYYY • LT')}
                </Text>
              </Flex>
            </Flex>
          </S.Wrapper>
        </Link>

        {!editing &&
          showAddToListButton && (
            <Box height="32px" position="absolute" right="8px" top="8px" width="122px" zIndex="2">
              <AddToListButton
                onClose={this.closeAddToListButton}
                textMessages={[textMessage]}
                listType="text-messages"
              />
            </Box>
          )}

        {!editing && (
          <Box height="24px" position="absolute" left="22px" top="8px" width="unset" zIndex="2">
            <Link
              to={{
                query: { ...router.params, ...router.location.query, panel: 'notes' },
                pathname: `/text-messages/${textMessage.attributes.guid}`,
              }}
            >
              <CommentCounterBadge
                id={textMessage.id}
                total={textMessage.attributes.approximate_comments}
              />
            </Link>
          </Box>
        )}

        {editing && (
          <Box left="0" position="absolute" top="0">
            <S.SelectWrapper
              onClick={() => toggleSelected(textMessage.id)}
              role="button"
              selected={selected}
            >
              <Box height="24px" position="absolute" right="8px" top="8px" width="24px" zIndex="2">
                <Checkbox checked={selected} />
              </Box>
            </S.SelectWrapper>
          </Box>
        )}
      </Box>
    );
  }
}

TextMessageThumbnail.contextTypes = {
  router: PropTypes.object,
};

TextMessageThumbnail.defaultProps = {
  editing: false,
  selected: false,
};

TextMessageThumbnail.propTypes = {
  editing: PropTypes.bool,
  selected: PropTypes.bool,
  textMessage: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.shape({
      full_text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      images: PropTypes.arrayOf(
        PropTypes.shape({
          gif: PropTypes.string,
          jpg: PropTypes.string,
          original: PropTypes.string,
          png: PropTypes.string,
          webp: PropTypes.string,
        }),
      ),
      sent_at: PropTypes.number,
      type: PropTypes.string,
    }),
    relationships: PropTypes.shape({
      companies: PropTypes.shape({
        data: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            attributes: PropTypes.shape({
              name: PropTypes.string,
            }),
          }),
        ),
      }),
    }),
  }).isRequired,
  toggleSelected: PropTypes.func.isRequired,
};

export default TextMessageThumbnail;
