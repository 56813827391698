import React from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import { connect } from 'react-redux';
import { withTheme } from '@emotion/react';

import { brandOverviewSelectors } from '../../modules/brandOverview';
import { groupOverviewSelectors } from '../../modules/groupOverview';

import { Box, Heading } from '../mc-ui';

import generateChartOptions from './generateChartOptions';
import generateChartPlugins from './generateChartPlugins';
import generateDataset from './generateDataset';
import toChartData from './toChartData';

class OverviewTrendingLineChart extends React.Component {
  state = {
    chartData: {},
    loading: true,
  };

  componentDidMount() {
    if (!this.props.model.id) return;
    this.getChartData();
  }

  componentDidUpdate(prevProps) {
    if (!this.props.model.id) return;

    if (prevProps.model.id !== this.props.model.id) {
      this.getChartData();
    }
  }

  getChartData = () => {
    const { chart, currentWeeks, model } = this.props;
    if (!model.id) return;

    this.setState({
      chartData: toChartData(currentWeeks.buckets, chart),
      loading: false,
    });
  };

  data = () => {
    const { theme } = this.props;
    const { chartData } = this.state;

    const data = { labels: [], datasets: [] };
    const dataset = generateDataset(theme, chartData);

    data.datasets.push(dataset);
    data.labels = chartData.labels;

    return data;
  };

  options = () => {
    const { chart, theme } = this.props;
    const { chartData } = this.state;
    return generateChartOptions(chart, theme, chartData);
  };

  plugins = () => {
    const { theme } = this.props;
    return generateChartPlugins(theme);
  };

  render() {
    const { loading } = this.state;
    const { renderedFromRestricted } = this.props;

    if (loading) return null;

    const chartHeight = renderedFromRestricted ? 50 : 100;

    return (
      <Box
        borderRadius="4px"
        height="155px"
        marginTop="24px"
        padding="8px"
        css={{
          '&:hover': { boxShadow: '0px 1px 4px rgba(9, 30, 66, 0.2)' },
        }}
      >
        <Heading
          as="h5"
          color="dark06"
          fontSize="10px"
          fontWeight="bold"
          letterSpacing="0.12em"
          lineHeight="1.56em"
          css={{ marginBottom: '10px' }}
        >
          90-Day Trend Line
        </Heading>

        <Line
          data={this.data()}
          height={chartHeight}
          options={this.options()}
          plugins={this.plugins()}
        />
      </Box>
    );
  }
}

OverviewTrendingLineChart.defaultProps = {
  currentWeeks: { buckets: [] },
  model: { id: null },
  renderedFromRestricted: false,
};

OverviewTrendingLineChart.propTypes = {
  chart: PropTypes.string.isRequired,
  currentWeeks: PropTypes.shape({
    buckets: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.number,
        keyAsString: PropTypes.string,
        frequency: PropTypes.shape({
          value: PropTypes.number,
        }),
        promotional: PropTypes.shape({
          value: PropTypes.number,
        }),
      }),
    ),
  }),
  model: PropTypes.shape({ id: PropTypes.string, type: PropTypes.string }),
  renderedFromRestricted: PropTypes.bool,
  theme: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state, { model }) => {
  const selectors =
    model && model.type === 'groups' ? groupOverviewSelectors : brandOverviewSelectors;
  const { meta } = selectors.getData(state);

  return {
    currentWeeks: meta.currentWeeks,
  };
};

export default withTheme(connect(mapStateToProps)(OverviewTrendingLineChart));
