import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { isEmpty, isNil } from 'ramda';

import { Box, Flex, Heading, Loader, Text } from '../../../mc-ui';
import EmailThumbnail from '../../../ui/EmailThumbnail';

import ClassifiedBadge from '../ClassifiedBadge';

import groupByDay from './groupByDay';
import sortEmailsAndEvents from './sortEmailsAndEvents';
import Divider from './Divider';
import Events from './Events';

const isPresent = val => !isNil(val) && !isEmpty(val);
const isLastItem = (index, length) => index === length - 1;

const Timeline = ({ journey, loading }) => {
  if (loading) {
    return (
      <Flex alignItems="center" justifyContent="center" height="700px">
        <Loader />
      </Flex>
    );
  }

  const emails = journey.relationships.emails.data;
  const events = journey.relationships.events.data;

  const sortedData = sortEmailsAndEvents(emails, events);
  const data = groupByDay(sortedData);
  const dataKeys = Object.keys(data);

  return (
    <Box backgroundColor="white" borderRadius="4px" marginBottom="32px" padding="32px">
      {dataKeys.map((key, index) => (
        <div key={key}>
          {index > 0 && <Divider currentDate={key} previousDate={dataKeys[index - 1]} />}

          <Flex>
            <Box width="180px">
              <Heading as="h4" css={{ marginBottom: '8px' }}>
                Day {index + 1}
              </Heading>
              <Text fontWeight="500">{moment(key).format('DD MMM YYYY')}</Text>
            </Box>

            <Box width="590px">
              <Events events={data[key].events} />

              <Flex wrap="nowrap" css={{ overflowX: 'auto' }}>
                {data[key].emails.map(email => (
                  <Box
                    key={email.id}
                    marginRight={isLastItem(index, data[key].emails.length) ? '0' : '24px'}
                    position="relative"
                    width="auto"
                  >
                    {isPresent(email.attributes.classifications) && (
                      <ClassifiedBadge classification={email.attributes.classifications[0]} />
                    )}
                    <EmailThumbnail email={email} small />
                    <Text
                      color="dark08"
                      fontWeight="500"
                      letterSpacing="0.02em"
                      lineHeight="1.25em"
                      textAlign="center"
                      css={{ marginTop: '16px' }}
                    >
                      {moment(email.attributes.sentAt).format('LT')}
                    </Text>
                  </Box>
                ))}
              </Flex>
            </Box>
          </Flex>
        </div>
      ))}
    </Box>
  );
};

Timeline.defaultProps = {
  journey: {},
};

Timeline.propTypes = {
  journey: PropTypes.shape({
    attributes: PropTypes.shape({
      journeyType: PropTypes.string,
    }),
    relationships: PropTypes.shape({
      emails: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape({})),
      }),
      events: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape({})),
      }),
    }),
  }),
  loading: PropTypes.bool.isRequired,
};

export default Timeline;
