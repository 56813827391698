/* eslint-disable import/prefer-default-export */
import axios from 'axios';

import api from '../../lib/api';

let axiosSource = null;

export const fetchCoupon = async id => {
  if (axiosSource) axiosSource.cancel();

  axiosSource = axios.CancelToken.source();

  try {
    const response = await api.get(`/v2/billing/coupons/${id}`, { cancelToken: axiosSource.token });
    const [coupon] = response.data.data;
    return coupon;
  } catch (error) {
    if (axios.isCancel(error)) return null; // do nothing
    throw error;
  }
};
