import styled from "@emotion/styled";

const Title = styled("h3")({
  lineHeight: `${32 / 24}em`,
  letterSpacing: '0.45',
  margin: '24px 0',
  padding: '0',
  maxWidth: '510px',
});

export default Title;
