import React from 'react';
import PropTypes from 'prop-types';
import styled from "@emotion/styled";

const Icon = ({ className, icon }) => (
  <i className={className}>{icon}</i>
);

Icon.propTypes = {
  className: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
};

export default styled(Icon)({
  display: 'inline-block',
  fontSize: '64px',
  height: '64px',
  marginRight: '26px',
  width: '64px',
});
