/* eslint default-case: "off" */

import moment from 'moment';
import api from '../lib/api';

export default store => next => action => {
  next(action);

  switch (action.type) {
    case 'REQUEST_USER':
      api.get('/v2/user').then(r => {
        for (const user of r.data.data) {
          user.attributes.restricted = true;
          const team = r.data.included.find(i => i.type == 'teams');
          if (!team) continue;
          user.attributes.restricted = moment(new Date()).isAfter(
            moment(team.attributes.current_period_end_at),
          );
        }
        store.dispatch({ type: 'RECEIVED_USER', data: r.data });
        store.dispatch({ type: 'USER_LOADED_SUCCESS', data: r.data });
      })
      .catch(error => {
        let data = { error: true, error_data: (error && error.message) ? error.message : "unknown error" };
        store.dispatch({ type: 'INVALID_USER', data: data });
      });
      break;
    case 'REFRESH_USER':
      api.get('/v2/user').then(r => {
        store.dispatch({ type: 'RECEIVED_USER', data: r.data });
      });
      break;
    case 'UPDATE_USER':
    case 'UPDATE_USER_NO_FLUSH': {
      const prev = store.getState().user;
      api
        .patch('/v2/user', action.data)
        .then(r => store.dispatch({ type: 'RECEIVED_USER', data: r.data }))
        .catch(() => {
          prev["error"] = "error";
          store.dispatch({ type: 'RECEIVED_USER', data: prev })
        });
      break;
    }
  }
};
