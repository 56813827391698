import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTheme } from '@emotion/react';

import { benchmarksActions as actions } from '../../../modules/newReports/benchmarks';
import { userSelectors } from '../../../modules/user';

import { shallowEqual } from '../../../lib/utils';
import { Flex, Text } from '../../mc-ui';

import HeaderContainer from '../HeaderContainer';
import MainWrapper from '../MainWrapper';
import Body from '../Body';

import EmailScores from './EmailScores';
import MobileOptimized from './MobileOptimized';
import MostPopularEmoji from './MostPopularEmoji';
import MostPopularSendDay from './MostPopularSendDay';
import MostPopularSendTime from './MostPopularSendTime';
import EmailsSent from './EmailsSent';
import PromotionRate from './PromotionRate';
import Restricted from './Restricted';
import SubjectLineLength from './SubjectLineLength';

const getDefaultInterval = intervals => intervals.find(i => i.default);

class BrandContainer extends React.Component {
  state = {
    interval: getDefaultInterval(this.props.userIntervals),
    selectedBrand: {},
  };

  componentDidMount() {
    const { model } = this.props;

    if (model.id) {
      this.selectBrand(model);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.model.id) return;
    const nextModel = { id: nextProps.model.id, type: nextProps.model.type };
    const model = { id: this.props.model.id, type: this.props.model.type };

    if (!shallowEqual(nextModel, model)) {
      this.selectBrand(nextModel);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const nextModel = { id: nextProps.model.id, type: nextProps.model.type };
    const model = { id: this.props.model.id, type: this.props.model.type };

    return !shallowEqual(nextModel, model) || !shallowEqual(nextState, this.state);
  }

  selectBrand = model => {
    this.setState(() => ({ value: model.id, label: model.attributes.name }), this.request);
  };

  request = () => {
    if (this.restricted()) return;

    const { fetchData } = this.props;
    const { selectedBrand } = this.state;

    if (!selectedBrand) return;

    fetchData();
  };

  restricted = () => {
    const { isUserRestricted, model } = this.props;
    if (!model.type) return false;
    if (model.attributes.featured) return false;
    if (isUserRestricted) window.analytics.track('User restricted', { type: `Benchmarks` });
    return isUserRestricted;
  };

  accessible = () => !this.restricted();

  render() {
    const { model, router, theme } = this.props;
    const { interval, selectedBrand } = this.state;

    return (
      <MainWrapper>
        <HeaderContainer
          title="Benchmarks"
          tooltip={
            this.restricted() ? null : (
              <Text>
                See how this brand’s key data points compare to{' '}
                <a
                  href="https://www.mailcharts.com/companies"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Mailcharts Index
                </a>{' '}
                in the MailCharts database.
              </Text>
            )
          }
          css={{ alignItems: 'center' }}
        >
          {this.accessible() && (
            <Text
              css={{
                color: theme.colors.dark04,
                fontSize: '14px',
                fontWeight: '500',
                letterSpacing: '0.02em',
              }}
            >
              Last 90 days
            </Text>
          )}
        </HeaderContainer>

        {this.restricted() ? (
          <Restricted model={model} />
        ) : (
          <Body>
            <EmailsSent
              router={router}
              model={model}
              brandId={selectedBrand.value}
              endDate={interval.end_date}
              startDate={interval.start_date}
            />
            <Flex justifyContent="space-between">
              <MostPopularSendDay model={model} brandId={selectedBrand.value} />
              <MostPopularSendTime model={model} brandId={selectedBrand.value} />
            </Flex>
            <MostPopularEmoji model={model} brandId={selectedBrand.value} />
            <PromotionRate
              router={router}
              model={model}
              brandId={selectedBrand.value}
              endDate={interval.end_date}
              startDate={interval.start_date}
            />
            <SubjectLineLength
              router={router}
              model={model}
              brandId={selectedBrand.value}
              endDate={interval.end_date}
              startDate={interval.start_date}
            />
            <MobileOptimized router={router} model={model} brandId={selectedBrand.value} />
            <EmailScores router={router} model={model} brandId={selectedBrand.value} />
          </Body>
        )}
      </MainWrapper>
    );
  }
}

BrandContainer.defaultProps = {
  model: { id: null, type: null },
};

BrandContainer.propTypes = {
  fetchData: PropTypes.func.isRequired,
  isUserRestricted: PropTypes.bool.isRequired,
  model: PropTypes.shape({ id: PropTypes.string, type: PropTypes.string }),
  router: PropTypes.shape({
    location: PropTypes.shape({
      query: PropTypes.object,
    }),
  }).isRequired,
  theme: PropTypes.shape({}).isRequired,
  userIntervals: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const mapStateToProps = state => ({
  isUserRestricted: userSelectors.isUserRestrictedSelector(state),
  userIntervals: state.user.meta.intervals,
});

const mapDispatchToProps = (dispatch, { model }) => ({
  fetchData: () => dispatch(actions.fetchBrandDataRequest(model.id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTheme(BrandContainer));
