import React from 'react'
import { Link } from 'react-router'

class TechnologyInformation extends React.Component {
  state = { 
    description: false,
  }
  render() {
    let m = this.props.technologies.data.reduce((m, g) => g, {})
    return (
      <div className='pure-u-1' style={{ paddingTop: 25 }}>
        <div className='pure-u-1 pure-u-md-1-12'>
          <img src={m.attributes.logo_src} className='pure-img' />
        </div>
        <div className='pure-u-1 pure-u-md-1-24' />
        <div className='pure-u-1 pure-u-md-17-24'>
          <h2 style={{ margin: 0 }}>{m.attributes.name}</h2>
          <p>{(m.attributes.description || 'n/a').slice(0, 200)}</p>
        </div>
        <div className='pure-u-1 pure-u-md-1-24' />
        {m.attributes.owner ?
        <div className='pure-u-1 pure-u-md-1-8'>
          <p>
            <Link
              to={`/technologies/${m.id}`}
              className='pure-button'
            >
              Edit details
            </Link>
          </p>
        </div> : null}
      </div>
    )
  }
}

export default TechnologyInformation

