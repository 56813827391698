import * as React from 'react';
import PropTypes from 'prop-types';

function ArrowUpIcon({ fillOpacity, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.883 19h2.32V8.437l4.047 4.047a1.033 1.033 0 001.46-1.46l-5.572-5.571a1.543 1.543 0 00-1.688-.336 1.543 1.543 0 00-.502.336l-5.571 5.571a1.033 1.033 0 001.46 1.46l4.046-4.047V19zm1.032-11.595h.256l-.025-.024a.145.145 0 00-.159-.032.147.147 0 00-.047.032l-.025.024z"
        fill="currentColor"
        fillOpacity={fillOpacity}
      />
    </svg>
  );
}

ArrowUpIcon.propTypes = {
  fillOpacity: PropTypes.number,
};

ArrowUpIcon.defaultProps = {
  fillOpacity: 1,
};

export default ArrowUpIcon;
