import React from 'react';
import PropTypes from 'prop-types';

import { prioritizeJourney } from '../../../../modules/journeys/api';

import { Box, Button, Flex, Loader, Text } from '../../../mc-ui';
import CheckCircleIcon from '../../../mc-ui/icons/CheckCircleIcon';
import ErrorIcon from '../../../mc-ui/icons/ErrorIcon';

import imgUrl from './background.png';

class Upcoming extends React.Component {
  state = {
    error: null,
    prioritizing: false,
    status: this.props.journey.attributes.prioritized ? 'success' : null,
  };

  prioritize = event => {
    event.preventDefault();
    event.stopPropagation();

    const { journey } = this.props;

    this.setState(
      () => ({ prioritizing: true }),
      async () => {
        try {
          await prioritizeJourney(journey.id);
          this.setState(() => ({ error: null, prioritizing: false, status: 'success' }));
        } catch (error) {
          console.log(error);
          this.setState(() => ({ error, prioritizing: false, status: 'fail' }));
        }
      },
    );
  };

  renderStatus = () => {
    const { error, prioritizing, status } = this.state;

    if (status === 'success') {
      return (
        <Flex alignItems="center" height="20px" justifyContent="space-between" width="85px">
          <Text color="tealDark" fontSize="20px" css={{ height: '24px' }}>
            <CheckCircleIcon fillOpacity={1} />
          </Text>
          <Text
            color="dark3"
            fontSize="12px"
            fontWeight="500"
            lineHeight="1.25em"
            letterSpacing="0.02em"
          >
            Prioritized
          </Text>
        </Flex>
      );
    }

    if (prioritizing) {
      return (
        <Box height="23px" width="120px">
          <Loader />
        </Box>
      );
    }

    if (status === 'fail') {
      return (
        <Flex alignItems="center" height="20px" justifyContent="space-between" width="55px">
          <Text color="red1" fontSize="20px" css={{ height: '24px' }}>
            <ErrorIcon fillOpacity={1} />
          </Text>
          <Text
            color="dark3"
            fontSize="12px"
            fontWeight="500"
            lineHeight="1.25em"
            letterSpacing="0.02em"
            title={error}
          >
            Error
          </Text>
        </Flex>
      );
    }

    return (
      <Button
        onClick={this.prioritize}
        variant="primary"
        css={{ height: '32px', padding: '0 14px' }}
      >
        Prioritize this journey
      </Button>
    );
  };

  render() {
    return (
      <Flex
        alignItems="center"
        border="1px solid"
        marginBottom="24px"
        borderColor="dark5"
        borderRadius="3px"
        direction="column"
        height="414px"
        justifyContent="center"
      >
        <img alt="Prioritize Journey" height="89" src={imgUrl} width="199" />
        <Text
          fontSize="18px"
          fontWeight="500"
          lineHeight="1.32em"
          letterSpacing="0.02em"
          css={{ marginBottom: '16px', marginTop: '20px' }}
        >
          We'll be working this journey soon!
        </Text>
      </Flex>
    );
  }
}

Upcoming.propTypes = {
  journey: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.shape({
      estimatedCompletedAt: PropTypes.string,
      prioritized: PropTypes.bool,
    }),
  }).isRequired,
};

export default Upcoming;
