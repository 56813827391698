import styled from "@emotion/styled";

const Dropdown = styled("div")(({ theme }) => ({
  backgroundColor: theme.colors.white,
  borderRadius: '4px',
  boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 11px rgba(0, 0, 0, 0.1)',
  left: '0',
  margin: '8px 0',
  padding: '4px 0',
  position: 'absolute',
  top: '32px',
  width: '100%',
  zIndex: '9994',
}));

export default Dropdown;
