import styled from "@emotion/styled";

const FormControl = styled("form")({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '0px',
  marginBottom: '32px',
  marginTop: '20px',
  maxWidth: '360px',
  padding: '0px',
  width: '100%'
})

export default FormControl
