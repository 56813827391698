import styled from "@emotion/styled";

import Badge from './Badge';

const Tag = styled(Badge)(({ css = {}, theme }) => ({
  border: `1px solid ${theme.colors.dark5}`,
  borderRadius: '4px',
  boxSizing: 'border-box',
  display: 'inline-block',
  padding: '1px 5px',
  ...css,
}));

export default Tag;
