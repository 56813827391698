/* eslint-disable react/prop-types */
import React from 'react';
import { components } from 'react-select';
import styled from "@emotion/styled";

import { Text } from '../../mc-ui';

const StyledText = styled(Text)(({ theme }) => ({
  color: theme.colors.dark08,
  fontSize: '14px',
  lineHeight: '156%',
}));

const SingleValue = ({ children, label, ...props }) => (
  <components.SingleValue {...props}>
    <StyledText>
      <strong>{label}:</strong> {children}
    </StyledText>
  </components.SingleValue>
);

export const singleValueWithLabel = label => props => <SingleValue label={label} {...props} />;

export default SingleValue;
