import styled from "@emotion/styled";

const colors = {
  active: '#0379d5',
  default: '#a0aeba'
}

const PlusIcon = styled("div")({
  boxSizing: 'border-box',
  cursor: 'pointer',
  height: '20px',
  position: 'relative',
  width: '20px',
  '&::after, &::before': {
    backgroundColor: colors.default,
    content: '""',
    display: 'block',
    height: '2px',
    left: '50%',
    marginLeft: '-7px',
    marginTop: '-1px',
    position: 'absolute',
    top: '50%',
    width: '14px'
  },
  '&::after': {
    transform: 'rotate(90deg)'
  },
  '&:hover': {
    '&::after, &::before': {
      backgroundColor: colors.active
    }
  }
}, ({ active }) => ({
  '&::after, &::before': {
    backgroundColor: active ? colors.active : colors.default
  }
}))

PlusIcon.defaultProps = {
  active: false
}

export default PlusIcon
