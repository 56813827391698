/* eslint-disable react/prop-types */

import React from 'react';
import { Field } from 'react-final-form';
import { OnBlur, OnChange, OnFocus } from 'react-final-form-listeners';

import ReactSelect from '../../overrides/ReactSelect';

const SelectField = ({ isSelectionAllowed, limit, noOptionsMessage, options, ...props }) => (
  <Field {...props}>
    {({ input, meta, onBlur, onChange, onFocus, ...rest }) => (
      <div>
        <ReactSelect
          {...input}
          {...rest}
          options={options}
          noOptionsMessage={() => noOptionsMessage || 'No options available'}
          isSearchable
        />

        <OnBlur name={input.name}>
          {() => {
            if (onBlur) {
              onBlur({ value: input.value, valid: meta.valid });
            }
          }}
        </OnBlur>

        <OnChange name={input.name}>
          {(value, previous) => {
            if (!isSelectionAllowed) {
              input.onChange(previous);
              return;
            }

            if (onChange) onChange(value, previous);
          }}
        </OnChange>

        <OnFocus name={input.name}>
          {() => {
            if (onFocus) {
              onFocus();
            }
          }}
        </OnFocus>
      </div>
    )}
  </Field>
);

SelectField.defaultProps = {
  isSelectionAllowed: true,
};

export default SelectField;
