import React from 'react';

const FindBrandsIcon = props => (
  <svg viewBox="0 0 134 106" {...props}>
    <g transform="translate(-4)" fill="none" fillRule="evenodd">
      <path
        d="M114.573 56.94c0 21.42-17.365 38.785-38.786 38.785C54.366 95.725 37 78.36 37 56.94c0-21.42 17.366-38.786 38.787-38.786s38.786 17.365 38.786 38.786"
        fill="#FFF"
      />
      <path
        d="M74.114 66.606l-.225.008a4.646 4.646 0 0 1-4.805-4.481l-.204-5.842 9.51-.332.205 5.842a4.646 4.646 0 0 1-4.481 4.805"
        fill="#FFF"
      />
      <path
        d="M74.114 66.606l-.225.008a4.646 4.646 0 0 1-4.805-4.481l-.204-5.842 9.51-.332.205 5.842a4.646 4.646 0 0 1-4.481 4.805z"
        stroke="#A1AEBA"
        strokeWidth={2}
        strokeLinecap="round"
      />
      <path
        d="M44.795 68.53l-7.566 4.209a.57.57 0 0 1-.775-.221L33.63 67.44a.57.57 0 0 1 .22-.775l7.567-4.21 3.378 6.074z"
        fill="#FFF"
      />
      <path
        d="M44.795 68.53l-7.566 4.209a.57.57 0 0 1-.775-.221L33.63 67.44a.57.57 0 0 1 .22-.775l7.567-4.21 3.378 6.074z"
        stroke="#027AD7"
        strokeWidth={2}
        fill="#D7EEFF"
      />
      <path
        d="M74.911 55.882L47.037 71.39a.57.57 0 0 1-.775-.22l-6.313-11.35a.57.57 0 0 1 .221-.775l27.874-15.506 6.867 12.344z"
        fill="#FFF"
      />
      <path
        d="M74.911 55.882L47.037 71.39a.57.57 0 0 1-.775-.22l-6.313-11.35a.57.57 0 0 1 .221-.775l27.874-15.506 6.867 12.344z"
        stroke="#027AD7"
        strokeWidth={2}
        fill="#D7EEFF"
      />
      <path
        d="M107.843 41.734L77.18 58.79a.569.569 0 0 1-.774-.221l-9.858-17.72a.569.569 0 0 1 .22-.774l30.662-17.058 10.412 18.716z"
        stroke="#027AD7"
        strokeWidth={2}
      />
      <path
        d="M107.843 41.734L77.18 58.79a.569.569 0 0 1-.774-.221l-9.858-17.72a.569.569 0 0 1 .22-.774l30.662-17.058 10.412 18.716z"
        stroke="#027AD7"
        strokeWidth={2}
        fill="#D7EEFF"
      />
      <path
        d="M118.891 41.91l-7.865 4.375a.569.569 0 0 1-.774-.221l-15.23-27.376a.57.57 0 0 1 .221-.775l7.864-4.375a.57.57 0 0 1 .775.22l15.23 27.377a.57.57 0 0 1-.221.775"
        fill="#FFF"
      />
      <path
        d="M118.891 41.91l-7.865 4.375a.569.569 0 0 1-.774-.221l-15.23-27.376a.57.57 0 0 1 .221-.775l7.864-4.375a.57.57 0 0 1 .775.22l15.23 27.377a.57.57 0 0 1-.221.775z"
        stroke="#027AD7"
        strokeWidth={2}
      />
      <path
        d="M74.05 104.153l-.161-37.54M72.22 66.361l-17.218 34.225M75.401 66.374l17.848 34.213"
        stroke="#A1AEBA"
        strokeWidth={2}
        strokeLinecap="round"
      />
      <circle stroke="#027AD7" strokeWidth={2} cx={31.5} cy={23.5} r={3.5} />
      <circle fill="#FFF" cx={81.5} cy={3.5} r={3.5} />
      <g stroke="#A1AEBA" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}>
        <path d="M123.412 21h13.176M130 14.412v13.176" />
      </g>
      <circle fill="#FFF" cx={21.5} cy={93.5} r={3.5} />
      <circle fill="#FFF" cx={131.5} cy={63.5} r={3.5} />
      <g stroke="#0379D5" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}>
        <path d="M125.007 76.007l7.986 7.986M132.993 76.007l-7.986 7.986" />
      </g>
      <g stroke="#80C963" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}>
        <path d="M5.007 66.007l7.986 7.986M12.993 66.007l-7.986 7.986" />
      </g>
    </g>
  </svg>
);

export default FindBrandsIcon;
