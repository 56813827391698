import React from 'react';

import { Flex, Text } from '../../../../mc-ui';

// eslint-disable-next-line react/prop-types
const Column = ({ width = 60, marginBottom = 15, children }) => (
  <Flex css={{ width, marginBottom }}>
    <Text
      fontSize="12px"
      fontWeight="500"
      lineHeight="1.25em"
      textTransform="uppercase"
      css={{ margin: 0 }}
    >
      {children}
    </Text>
  </Flex>
);

const Header = () => (
  <Flex>
    <Column width={43}>&nbsp;</Column>
    <Column width={75}>12AM</Column>
    <Column width={75}>6AM</Column>
    <Column width={75}>12PM</Column>
    <Column width={43}>6PM</Column>
    <Column width={32}>11PM</Column>
  </Flex>
);

export default Header;
