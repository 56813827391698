import React from 'react';
import PropTypes from 'prop-types';

function OverviewIcon({ fillOpacity, ...props }) {
  return (
    <svg width={24} height={24} fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8 3.6H5.2a1.6 1.6 0 00-1.6 1.6v9.6a1.6 1.6 0 001.6 1.6h9.6a1.6 1.6 0 001.6-1.6V5.2a1.6 1.6 0 00-1.6-1.6zM5.2 2A3.2 3.2 0 002 5.2v9.6A3.2 3.2 0 005.2 18h9.6a3.2 3.2 0 003.2-3.2V5.2A3.2 3.2 0 0014.8 2H5.2z"
        fill="currentColor"
        fillOpacity={fillOpacity}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 12.4H3.6v-1.6H10v1.6zM16.4 8.4H10V6.8h6.4v1.6z"
        fill="currentColor"
        fillOpacity={fillOpacity}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.2 16.4V3.6h1.6v12.8H9.2z"
        fill="currentColor"
        fillOpacity={fillOpacity}
      />
    </svg>
  );
}

OverviewIcon.propTypes = {
  fillOpacity: PropTypes.number,
};

OverviewIcon.defaultProps = {
  fillOpacity: 0.6,
};

export default OverviewIcon;
