import styled from "@emotion/styled";

const TechnologyLogo = styled("img")({
  borderRadius: '4px',
  height: '24px',
  marginRight: '16px',
  width: '24px',
});

export default TechnologyLogo;
