import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import PaymentHistory from './PaymentHistory';

import { teamsActions, teamsSelectors } from '../../modules/teams';

import { Box, Heading, Tab } from '../mc-ui';
import BillingInfo from './BillingInfo';

class Invoices extends React.Component {
  componentDidMount() {
    const { requestTeam, team } = this.props;
    if (team) requestTeam(team.id);
  }

  render() {
    const { team, subscription, } = this.props

    return (
      <Box backgroundColor="white" borderRadius="4px" marginTop="1em" padding="32px">
        <Heading
          as="h3"
          color="dark08"
          fontSize="24px"
          fontWeight="300"
          lineHeight="32px"
          css={{ marginBottom: '24px' }}
        >
          Invoices
        </Heading>

        <Tab defaultActive="paymentHistory">
          <Tab.Header>
            <Tab.Item name="paymentHistory">Payment history</Tab.Item>
          </Tab.Header>

          <Tab.Body>
            {({ active }) => (active === 'paymentHistory' ? <PaymentHistory subscription={subscription} /> : <BillingInfo />)}
          </Tab.Body>
        </Tab>
      </Box>
    );
  }
}

Invoices.defaultProps = {
  team: null,
};

Invoices.propTypes = {
  requestTeam: PropTypes.func.isRequired,
  team: PropTypes.shape({}),
};

const mapStateToProps = state => ({
  team: teamsSelectors.teamSelector(state),
  subscription: teamsSelectors.subscriptionSelector(state),
});
const mapDispatchToProps = dispatch => ({
  requestTeam: teamId => dispatch(teamsActions.fetchTeamRequest(teamId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Invoices);
