/** @jsx jsx */
import PropTypes from 'prop-types';
import moment from 'moment';
import pluralize from 'pluralize';
import { Link } from 'react-router';
import { jsx, withTheme } from '@emotion/react';

import { Pagination, Table, Tag } from '../../../mc-ui';

const TermsTable = ({ from, terms, theme, to }, { router }) => {
  const endDate = moment(to, 'MM/DD/YYYY').format('YYYY-MM-DD');
  const startDate = moment(from, 'MM/DD/YYYY').format('YYYY-MM-DD');

  return (
    <Pagination collection={terms} pageSize={5}>
      {({ collection, page, pageSize }) => (
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Cell colSpan={2} width="585px">
                Terms
              </Table.Cell>
              <Table.Cell align="center" nowrap width="105px">
                Total Emails
              </Table.Cell>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {collection.map((row, index) => (
              <Table.Row key={row.key}>
                <Table.IndexCell
                  verticalAlign="top"
                  index={index}
                  page={page}
                  pageSize={pageSize}
                />
                <Table.Cell width="585px">
                  <Link
                    to={{
                      pathname: router.location.pathname.replace('/subject-lines', '/emails'),
                      query: {
                        q: row.word,
                        filter: 'subject',
                        end_date: endDate,
                        start_date: startDate,
                      },
                    }}
                  >
                    <Tag color="dark08" css={{ marginBottom: '8px' }}>
                      {row.word}
                    </Tag>
                  </Link>
                  <br />
                  <Link
                    to={{
                      pathname: `/emails/${row.emails[0].attributes.guid}`,
                      state: { modal: true, returnTo: router.location },
                    }}
                    css={{
                      color: `${theme.colors.dark1} !important`,
                      display: 'block',
                      overflow: 'hidden',
                      textDecoration: 'none',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      width: '460px',
                    }}
                  >
                    <strong
                      css={{
                        fontWeight: 'bold !important',
                        color: `${theme.colors.dark04} !important`,
                      }}
                    >
                      Example:
                    </strong>{' '}
                    {row.emails[0].attributes.subject}
                  </Link>
                </Table.Cell>
                <Table.Cell align="center" width="105px">
                  <Link
                    to={{
                      pathname: router.location.pathname.replace('/subject-lines', '/emails'),
                      query: {
                        q: row.word,
                        filter: 'subject',
                        end_date: endDate,
                        start_date: startDate,
                      },
                    }}
                  >
                    View {row.docCount} {pluralize('email', row.docCount)}
                  </Link>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      )}
    </Pagination>
  );
};

TermsTable.contextTypes = {
  router: PropTypes.shape({}).isRequired,
};

TermsTable.propTypes = {
  from: PropTypes.string.isRequired,
  terms: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      docCount: PropTypes.number,
      term: PropTypes.string,
      score: PropTypes.number,
      emails: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  ).isRequired,
  theme: PropTypes.shape({}).isRequired,
  to: PropTypes.string.isRequired,
};

export default withTheme(TermsTable);
