import button from './theme/button';
import colors from './theme/colors';
import font from './theme/font';
import scrollBox from './theme/scrollBox';
import tooltip from './theme/tooltip';
import zIndices from './theme/zIndices';

const theme = {
  button,
  colors,
  font,
  scrollBox,
  tooltip,
  zIndices,
};

export default theme;
