/* eslint-disable react/prop-types */
import React from 'react';
import styled from "@emotion/styled";

import colors from '../../theme/colors';

export const clearIndicatorStyles = (base, state) => ({
  ...base,
  cursor: 'pointer',
  color: colors.dark1,
  opacity: state.isFocused ? '1' : '0.4',
  padding: 0,
  transition: `${base.transition || ''}, opacity 150ms`,
  '&:hover': {
    color: colors.redDark,
    opacity: 1,
  },
});

const CustomClearText = () => <small>clear all</small>;
const ClearIndicator = props => {
  const {
    children = <CustomClearText />,
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;

  const ClearIndicatorWrapper = styled("div")({
    ...getStyles('clearIndicator', props),
    '& > div': { padding: '0px 5px' },
  });

  return (
    <ClearIndicatorWrapper {...restInnerProps} ref={ref}>
      <div>{children}</div>
    </ClearIndicatorWrapper>
  );
};

export default ClearIndicator;
