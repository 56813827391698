/** @jsx jsx */
import React from 'react';
import PropTypes from 'prop-types';
import ellipsisis from 'ellipsisis';
import moment from 'moment';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { jsx, withTheme } from '@emotion/react';

import { Box, Flex, Heading, Text } from '../mc-ui';

import BrandLogo from './BrandLogo';
import Checkbox from './Checkbox';
import AddToListButton from '../AddToListButton';
import CommentCounterBadge from './CommentCounterBadge';

const emailImage = email =>
  `//assets.mailcharts.com/emails/${email.attributes.guid}/${'300x400'}.png`;

function CustomLink({ children, skip, ...props }) {
  if (skip) {
    return <span>{children}</span>;
  }

  return <Link {...props}>{children}</Link>;
}

class EmailThumbnail extends React.PureComponent {
  state = {
    backgroundImage: '/loading.png',
    showAddToListButton: false,
  };

  handleLoad = () => {
    const { email } = this.props;
    this.setState({ backgroundImage: emailImage(email) });
  };

  toggleSelected = event => {
    const { editing } = this.props;

    if (!editing) return;

    event.preventDefault();
    event.stopPropagation();

    const { deselectEmail, selectEmail, selected } = this.props;
    const actionFunc = selected ? deselectEmail : selectEmail;

    actionFunc();
  };

  closeAddToListButton = () => {
    this.setState(({ showAddToListButton }) => ({ showAddToListButton: false }));
  };

  showAddToListButton = () => {
    this.setState(({ showAddToListButton }) => ({ showAddToListButton: true }));
  };

  render() {
    const { backgroundImage, showAddToListButton } = this.state;
    const { brand, editing, email, skipLink, small, theme, tiny } = this.props;
    const { router } = this.context;

    const simpleVersion = !small && !tiny;
    const smallCSS = small
      ? {
          border: `1px solid ${theme.colors.dark01}`,
          boxSizing: 'border-box',
          height: '120px',
          width: '120px',
          '&:hover': {
            boxShadow: `0px 1px 4px ${theme.colors.dark02}`,
          },
        }
      : {};

    const tinyCSS = tiny
      ? {
          border: `1px solid ${theme.colors.dark01}`,
          boxSizing: 'border-box',
          height: '40px',
          width: '40px',
          '&:hover': {
            boxShadow: `0px 1px 4px ${theme.colors.dark02}`,
          },
        }
      : {};

    const sentAt = email.attributes.sent_at || email.attributes.sentAt;

    const height = smallCSS.height || tinyCSS.height || '300px';
    const width = smallCSS.width || tinyCSS.width || '180px';

    return (
      <Box
        height={height}
        position="relative"
        width={width}
        onMouseOver={this.showAddToListButton}
        onMouseLeave={this.closeAddToListButton}
      >
        <CustomLink
          skip={skipLink}
          to={{
            query: { ...router.params, ...router.location.query },
            pathname: `/emails/${email.attributes.guid}`,
          }}
          css={{
            height,
            textDecoration: 'none',
            width,
          }}
          onClick={this.toggleSelected}
        >
          <img
            alt={email.attributes.subject}
            onLoad={this.handleLoad}
            src={emailImage(email)}
            style={{ display: 'none' }}
          />
          <Box
            css={{
              backgroundColor: theme.colors.white,
              backgroundImage: `url(${backgroundImage})`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              border: `1px solid ${theme.colors.dark5}`,
              borderRadius: '4px',
              height: '240px',
              position: 'relative',
              width: '180px',
              ...smallCSS,
              ...tinyCSS,
            }}
          >
            {simpleVersion && (
              <Box
                css={{
                  background:
                    'linear-gradient(360deg, rgba(0, 0, 0, 0) 65.21%, rgba(0, 0, 0, 0.1) 100%)',
                  height: '78px',
                  left: 0,
                  position: 'absolute',
                  top: '90px',
                  transform: 'rotate(-180deg)',
                  width: '178px',
                }}
              />
            )}

            {simpleVersion && (
              <Box
                css={{
                  backgroundColor: theme.colors.white,
                  borderRadius: '0 0 4px 4px',
                  height: '70px',
                  left: 0,
                  opacity: '0.98',
                  padding: '12px',
                  position: 'absolute',
                  top: '168px',
                  width: '178px',
                }}
              >
                <Text
                  css={{
                    color: theme.colors.dark1,
                    fontSize: '12px',
                    fontWeight: '500',
                    letterSpacing: '0.02em',
                    lineHeight: '1.25em',
                  }}
                  title={email.attributes.subject.length > 65 ? email.attributes.subject : null}
                >
                  {ellipsisis(email.attributes.subject, 65, '...')}
                </Text>
              </Box>
            )}

            {editing && (
              <Box
                backgroundColor={email.selected ? 'blueSelected' : 'white'}
                borderRadius="4px"
                height={smallCSS.height || tinyCSS.height || '240px'}
                left="0"
                opacity={email.selected ? null : '0.5'}
                position="absolute"
                top="0"
                width={smallCSS.width || tinyCSS.width || '180px'}
              />
            )}

            {editing && (
              <Box height="24px" position="absolute" right="8px" top="8px" width="24px">
                <Checkbox checked={email.selected} />
              </Box>
            )}
          </Box>

          {simpleVersion && (
            <Flex css={{ padding: '16px 0 12px 0' }}>
              <Box css={{ marginRight: '8px', width: '32px' }}>
                <BrandLogo brand={brand} css={{ height: '32px', width: '32px' }} />
              </Box>

              <Box css={{ width: '148px' }}>
                {brand.attributes.name && (
                  <Heading
                    as="h4"
                    css={{
                      color: `${theme.colors.dark08} !important`,
                      fontSize: '12px',
                      lineHeight: '1.25em',
                      margin: 0,
                      padding: 0,
                      textTransform: 'none',
                    }}
                  >
                    {brand.attributes.name}
                  </Heading>
                )}

                <Text
                  css={{
                    color: `${theme.colors.dark06} !important`,
                    fontSize: '12px',
                    lineHeight: '1.56em',
                  }}
                >
                  {moment(sentAt).format('MMM D YYYY • LT')}
                </Text>
              </Box>
            </Flex>
          )}
        </CustomLink>

        {!editing &&
          !tiny &&
          showAddToListButton && (
            <Box height="32px" position="absolute" right="8px" top="8px" width="122px">
              <AddToListButton emails={[email]} onClose={this.closeAddToListButton} />
            </Box>
          )}

        {!editing &&
          !tiny && (
            <Box height="24px" position="absolute" left="6px" top="6px" width="unset">
              <Link
                to={{
                  query: { ...router.params, ...router.location.query, panel: 'notes' },
                  pathname: `/emails/${email.attributes.guid}`,
                }}
              >
                <CommentCounterBadge id={email.id} total={email.attributes.approximate_comments} />
              </Link>
            </Box>
          )}
      </Box>
    );
  }
}

EmailThumbnail.contextTypes = {
  router: PropTypes.object,
};

EmailThumbnail.defaultProps = {
  brand: null,
  editing: false,
  skipLink: false,
  small: false,
  tiny: false,
};

EmailThumbnail.propTypes = {
  brand: PropTypes.shape({}),
  deselectEmail: PropTypes.func.isRequired,
  editing: PropTypes.bool,
  email: PropTypes.shape({}).isRequired,
  requestEmail: PropTypes.func.isRequired,
  selectEmail: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  skipLink: PropTypes.bool,
  small: PropTypes.bool,
  theme: PropTypes.shape({}).isRequired,
  tiny: PropTypes.bool,
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  deselectEmail: () => dispatch({ type: 'DESELECT_EMAIL', guid: ownProps.email.attributes.guid }),
  requestEmail: (guid, params = {}) => dispatch({ type: 'REQUEST_EMAIL', guid, params }),
  selectEmail: () => dispatch({ type: 'SELECT_EMAIL', guid: ownProps.email.attributes.guid }),
  showModal: (modalChildren, modalStyle = {}) =>
    dispatch({ type: 'SHOW_MODAL', modalChildren, modalStyle }),
});

export default connect(
  null,
  mapDispatchToProps,
)(withTheme(EmailThumbnail));
