import React from 'react'
import moment from 'moment'

export default class EmailDate extends React.Component {
  render() {
    let now = moment().endOf('day')
    let obj = moment(this.props.date)
    let date = obj.format('ddd, MMM. D, YYYY')
    if (now.diff(obj, 'day') < 1)
       date = obj.format('hh:mm a') 
    return (
      <p style={{ margin: 0 }} className={this.props.className}>{date}</p>
    )
  }
}

