/* eslint-disable import/prefer-default-export */

function addModelToQuery(params, query) {
  const keys = ['company_id', 'group_id', 'list_id'];

  if (Object.keys(params).some(key => keys.includes(key))) {
    const paramsQuery = Object.entries(params).reduce((acc, [key, value]) => {
      if (keys.includes(key)) {
        return { ...acc, [key]: value };
      }

      return acc;
    }, {});

    return {
      ...query,
      ...paramsQuery,
    };
  }

  return query;
}

export function filterQuery(query, params = {}) {
  const keys = Object.keys(query);

  const filteredQuery = keys.reduce((acc, key) => {
    if (key === 'advanced') return acc;

    const value = query[key];

    if (value) {
      if (typeof value === 'string' && value.trim().length > 0) {
        acc[key] = value;
      } else if (typeof value !== 'string') {
        acc[key] = value;
      }
    }

    return acc;
  }, {});

  return addModelToQuery(params, filteredQuery);
}
