/* eslint-disable import/prefer-default-export */
import { call, put, select } from 'redux-saga/effects';
import { flatten, isEmpty, uniq } from 'ramda';

import { shuffle } from '../../../lib/utils';

import * as actions from './actions';
import * as api from './api';
import * as selectors from './selectors';
import * as utils from './utils';

export function* fetchData({ modelId, modelType, startDate, endDate }) {
  try {
    const data = yield select(selectors.getData(modelId, modelType, startDate, endDate));

    if (isEmpty(data)) {
      if (!endDate || !startDate) return;

      const response = yield call(api.fetchData, modelId, modelType, startDate, endDate);
      const { stats } = response.meta;

      const distribution = {
        '< 25': utils.jsonToData(stats, 'subject_line_distribution', '< 25'),
        '25 - 55': utils.jsonToData(stats, 'subject_line_distribution', '25 - 55'),
        '55 - 120': utils.jsonToData(stats, 'subject_line_distribution', '55 - 120'),
        '120+': utils.jsonToData(stats, 'subject_line_distribution', '120+'),
      };

      const payload = {
        distribution: {
          ...distribution,
          allEmails: shuffle(
            uniq(
              flatten([
                distribution['< 25'].emails,
                distribution['25 - 55'].emails,
                distribution['55 - 120'].emails,
                distribution['120+'].emails,
              ]),
            ),
          ),
        },
      };

      yield put(actions.fetchDataSuccess(modelId, modelType, startDate, endDate, payload));
    } else {
      yield put(actions.toggleDataLoading(modelId, modelType, startDate, endDate));
    }
  } catch (error) {
    console.log('error', error);
    yield put(actions.fetchDataFailure(modelId, modelType, startDate, endDate, error));
  }
}
