import React from 'react';
import PropTypes from 'prop-types';

import { Flex, Link, Text } from '../../../mc-ui';
import CoffeeGlassAndBook from '../../../ui/illustrations/CoffeeGlassAndBook';

export default function NoSavedFilters({ collectionType }) {
  return (
    <Flex
      alignItems="center"
      border="1px solid transparent"
      borderColor="dark5"
      borderRadius="4px"
      direction="column"
      height="480px"
      justifyContent="center"
    >
      <CoffeeGlassAndBook />

      <Text fontWeight="bold" letterSpacing="0.02em" lineHeight="1.25em" margin="24px 0 16px 0">
        Save your favorite brand filters
      </Text>

      {collectionType === 'my' ? (
        <Text
          letterSpacing="0.02em"
          lineHeight="1.25em"
          textAlign="center"
          width="600px"
          css={{ marginBottom: '16px' }}
        >
          Use our powerful search filters to easily <Link to="/brands">find brands</Link> in the
          MailCharts Index.
          <br />
          <br />
          Save your favorite filters to quickly access them at any time.
        </Text>
      ) : (
        <Text
          letterSpacing="0.02em"
          lineHeight="1.25em"
          textAlign="center"
          width="600px"
          css={{ marginBottom: '16px' }}
        >
          Use our powerful search filters to easily <Link to="/brands">find brands</Link> in the
          MailCharts Index.
          <br />
          <br />
          Save your favorite filters and share them with your team to quickly access them at any
          time.
        </Text>
      )}
    </Flex>
  );
}

NoSavedFilters.propTypes = {
  collectionType: PropTypes.string.isRequired,
};
