import React from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import api from '../lib/api';

import Container from './Container';
import querystring from 'querystring';
import url from 'url';

import styles from './styles.css';
import { www } from '../lib/utils';
import { Loader } from './mc-ui';
const WWW_DOMAIN =
  (process.env.NODE_ENV === 'production')
    ? 'https://www.mailcharts.com'
    : (process.env.NODE_ENV === 'staging')
      ? 'https://staging.mailcharts.com'
      : 'https://pr.mailcharts.com';

class Invitations extends React.Component {
  state = {
    name: '',
    role: '',
    email: '',
    error: false,
    loading: false,
    errorMsg: '',
    password: '',
    invitation_id: '',
  };
  async componentDidMount() {
    const uri = url.parse(window.location.href);
    const query = querystring.parse(uri.query);
    this.setState({ invitation_id: query.invitation_id });
    try {
      const response = await api.get(`/v3/invitations/${query.invitation_id}`);
      const email = response.data.data[0].attributes.email;
      const role = response.data.data[0].attributes.role;
      const status = response.data.data[0].attributes.status;
      this.setState({ email, role, status });
    } catch (err) {
      console.log('There was an error fetching the invitation');
    }
  }
  handleSubmit = async (event) => {
    event.preventDefault();
    const { invitation_id, email, role, name, password } = this.state;
    this.setState({ loading: true, error: false });
    try {
      await api.post('/v2/user', { invitation_id, name, email, password, role });
      window.location.href = `/signin`;
    } catch (err) {
      this.setState({ errorMsg: err.response.data.message || err.response.data.detail, error: true });
    }
    this.setState({ loading: false });
  }
  handleChange = (event) => {
    event.preventDefault();
    const { value: input } = event.target;
    this.setState({ [event.target.name]: input });
  }
  render() {
    const { invitation_id, email, role, name, password, error, errorMsg, loading } = this.state;
    return (
      <div>
        <div className={`${styles["confirm-container"]} ${styles["confirm-container-default"]} ${styles["invitations-container"]}`}>
          <div className={styles["container"]}>
            <div className={styles["confirm-header"]}>
              <img
                alt="MailCharts"
                src={`${WWW_DOMAIN}/wp-content/uploads/2018/06/lockup-plain-blue.svg"`}
                className={styles["brand-logo"]}
              />
            </div>
            <div className={styles["row"]}>
              <div className={`${styles["col-left"]} col-md-6 mb-4 mb-md-0 hidden-sm-down`}>
                <img
                  alt="Invitation"
                  src={`${WWW_DOMAIN}/wp-content/themes/mailcharts/dist/images/invitations.png`}
                  className={styles["img-responsive"]}
                />
              </div>
              <div className={`${styles["col-right"]} col-md-6 mb-4 mb-md-0`}>
                <div className={`${styles["signup-step-1"]} pl-3 pr-3 pl-md-0 pr-md-0`}>
                  <h1>Join your Team</h1>
                  <p className="mb-0">
                    Please create your account and join <b>Validity.</b>
                  </p>
                  <form
                    onSubmit={this.handleSubmit}
                    className={styles["signup-form"]}
                    data-params={`{&quot;invitation_id&quot;:&quot;${invitation_id}&quot;}`}
                    data-api-url={`${WWW_DOMAIN}/v2/user?invitation_id=${invitation_id}"`}
                    data-success-url={`${WWW_DOMAIN}/manage/teams`}
                  >
                    <div className={styles["form-group"]}>
                      <label htmlFor="email">Email</label>
                      <input
                        id="email"
                        type="email"
                        name="email"
                        value={email}
                        required="required"
                        readOnly={true}
                        className={styles["form-control"]}
                        placeholder="eg: me@gmail.com"
                        autoComplete="off"
                      />
                      <p
                        style={{ display: "none" }}
                        className={`${styles["helper-text"]} mt-2 mb-0`}
                      >
                        <span className={styles["icon"]}>
                          <svg width="16" height="16" fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M13.997 7.896A6.022 6.022 0 0012.22 3.69 5.824 5.824 0 007.989 2a6.102 6.102 0 00-4.253 1.827A5.9 5.9 0 002 8.105 5.888 5.888 0 007.9 14h.107a6.054 6.054 0 005.99-6.104zM7.273 5.093a.726.726 0 111.452 0v3.27a.726.726 0 11-1.452 0v-3.27zm.742 6.555h-.016a.908.908 0 01-.015-1.816h.015a.908.908 0 01.015 1.816h.001z" fill="#F2222F"></path></svg>
                        </span>
                        <span className={styles["msg"]}>
                          This email address is not valid
                        </span>
                      </p>
                    </div>
                    <div className={styles["form-group"]}>
                      <label htmlFor="name">Name</label>
                      <input
                        id="name"
                        type="text"
                        name="name"
                        value={name}
                        required={true}
                        onChange={this.handleChange}
                        className={styles["form-control"]}
                        placeholder=""
                        autoComplete="off"
                      />
                      <p
                        style={{ display: "none" }}
                        className={styles["helper-text mt-2 mb-0"]}
                      >
                        <span className={styles["icon"]}>
                          <svg width="16" height="16" fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M13.997 7.896A6.022 6.022 0 0012.22 3.69 5.824 5.824 0 007.989 2a6.102 6.102 0 00-4.253 1.827A5.9 5.9 0 002 8.105 5.888 5.888 0 007.9 14h.107a6.054 6.054 0 005.99-6.104zM7.273 5.093a.726.726 0 111.452 0v3.27a.726.726 0 11-1.452 0v-3.27zm.742 6.555h-.016a.908.908 0 01-.015-1.816h.015a.908.908 0 01.015 1.816h.001z" fill="#F2222F"></path></svg>
                        </span>
                        <span className={styles["msg"]}>
                          This name is not valid.
                        </span>
                      </p>
                    </div>
                    <div className={styles["form-group"]}>
                      <label htmlFor="role">Your role in team</label>
                      <input
                        id="role"
                        type="text"
                        name="role"
                        value={role}
                        readOnly={true}
                        required="required"
                        className={styles["form-control"]}
                        placeholder=""
                        autoComplete="off"
                      />
                      <p className={styles["helper-text mt-2 mb-0"]} style={{ display: "none" }}>
                        <span className={styles["icon"]}>
                          <svg width="16" height="16" fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M13.997 7.896A6.022 6.022 0 0012.22 3.69 5.824 5.824 0 007.989 2a6.102 6.102 0 00-4.253 1.827A5.9 5.9 0 002 8.105 5.888 5.888 0 007.9 14h.107a6.054 6.054 0 005.99-6.104zM7.273 5.093a.726.726 0 111.452 0v3.27a.726.726 0 11-1.452 0v-3.27zm.742 6.555h-.016a.908.908 0 01-.015-1.816h.015a.908.908 0 01.015 1.816h.001z" fill="#F2222F"></path></svg>
                        </span>
                        <span className={styles["msg"]}>This role is not valid.</span>
                      </p>
                    </div>
                    <div className={`${styles["form-group"]} mb-0`}>
                      <label htmlFor="password">Select A Password</label>
                      <input
                        id="password"
                        type="password"
                        name="password"
                        value={password}
                        onChange={this.handleChange}
                        required="required"
                        className={styles["form-control"]}
                        data-length="8"
                        placeholder="8 characters minimum, contains number"
                        autoComplete="off"
                      />
                      <p className={styles["helper-text mt-2 mb-0"]} style={{ display: "none" }}>
                        <span className="icon">
                          <svg width="16" height="16" fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M13.997 7.896A6.022 6.022 0 0012.22 3.69 5.824 5.824 0 007.989 2a6.102 6.102 0 00-4.253 1.827A5.9 5.9 0 002 8.105 5.888 5.888 0 007.9 14h.107a6.054 6.054 0 005.99-6.104zM7.273 5.093a.726.726 0 111.452 0v3.27a.726.726 0 11-1.452 0v-3.27zm.742 6.555h-.016a.908.908 0 01-.015-1.816h.015a.908.908 0 01.015 1.816h.001z" fill="#F2222F"></path></svg>
                        </span>
                        <span className="msg">This password is not valid.</span>
                      </p>
                    </div>
                    {loading ? (
                      <Loader />
                    ) : ''}
                    {error ? (
                      <div
                        role="alert"
                        className="error alert alert-danger mt-2"
                      >
                        <span className="icon">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.626 21a.602.602 0 00.53-.885L12.142 3.292a.627.627 0 00-1.06 0L2.073 20.115a.6.6 0 00.53.885h18.024zm-9.013-2.203a1.202 1.202 0 110-2.404 1.202 1.202 0 010 2.404zm0-9.813a.801.801 0 01.802.8v4.379a.8.8 0 11-1.603 0V9.785a.801.801 0 01.802-.801z" fill="#BF0022"></path></svg>
                        </span>
                        <span className={styles["error-msg"]} id="error-msg">{errorMsg}</span>
                      </div>
                    ) : ('')}
                    <button
                      type="submit"
                      className={`${styles["button"]} btn btn-primary d-block w-100`}
                    >
                      Accept Invitation
                    </button>
                    <p>
                      <a href="/signin">I already have account. Sign in.</a>
                    </p>
                  </form>
                </div>
                <div className={`${styles["signup-step-2"]} pl-3 pr-3 pl-md-0 pr-md-0`} style={{ display: "none" }}>
                  Success.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

export default Invitations;
