import React from 'react';

import { Flex, Link, Text } from '../../mc-ui';
import CoffeeGlassAndBook from '../../ui/illustrations/CoffeeGlassAndBook';

export default function NoLists() {
  const currentURL = window.location.pathname;
  const lastSegment = currentURL.substring(currentURL.lastIndexOf('/') + 1);
  const isSMSSegment = lastSegment === 'text-message-lists';
  return (
    <Flex
      alignItems="center"
      border="1px solid transparent"
      borderColor="dark5"
      borderRadius="4px"
      direction="column"
      height="480px"
      justifyContent="center"
    >
      <CoffeeGlassAndBook />

      <Text fontWeight="bold" letterSpacing="0.02em" lineHeight="1.25em" margin="24px 0 16px 0">
        Create your first {isSMSSegment ? 'SMS' : 'email'} list
      </Text>

      <Text
        letterSpacing="0.02em"
        lineHeight="1.56em"
        textAlign="center"
        width="600px"
        css={{ marginBottom: '16px' }}
      >
        No lists? No problem!
        <br />
        <br />
        Create your first {isSMSSegment ? 'SMS' : 'Email'} List now and keep track of{' '}
        {isSMSSegment ? 'SMS' : 'emails'} you love.
        <br />
        <br />
        Or, browse our
        {isSMSSegment ? (
          <Link to="/text-messages"> SMS</Link>
        ) : (
          <Link to="/emails"> Emails</Link>
        )}{' '}
        to find {isSMSSegment ? 'SMS' : 'emails'} to add to a list.
      </Text>
    </Flex>
  );
}
