import React from 'react';
import PropTypes from 'prop-types';

import { Flex } from '../../mc-ui';

const Row = ({ children }) => <Flex css={{ marginBottom: '16px', width: '100%' }}>{children}</Flex>;

Row.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Row;
