import * as React from 'react';
import PropTypes from 'prop-types';

const WordIcon = ({ fillOpacity, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path fill="#fff" d="M0 0h24v24H0z" />
    <path
      fill="currentColor"
      d="M20 3h2v18h-2zM15.52 19 19 5h-2.791l-2.12 9.647h-.037L11.842 5H9.176L6.93 14.53h-.037L4.845 5H2l3.425 14h2.882l2.157-9.53h.036L12.693 19h2.827Z"
      fillOpacity={fillOpacity}
    />
  </svg>
);

WordIcon.propTypes = {
  fillOpacity: PropTypes.number,
};

WordIcon.defaultProps = {
  fillOpacity: 1,
};

export default WordIcon;
