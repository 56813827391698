import React from 'react';

const GroupsAnalyzesIcon = props => (
  <svg
    viewBox="0 0 120 83"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <defs>
      <path id="a" d="M0 83.388h119.451V1H0z" />
    </defs>
    <g transform="translate(0 -1)" fill="none" fillRule="evenodd">
      <path
        d="M71.268 82.388H3.935A2.935 2.935 0 0 1 1 79.454V4.936A2.936 2.936 0 0 1 3.935 2h67.333a2.936 2.936 0 0 1 2.935 2.936v74.517a2.935 2.935 0 0 1-2.935 2.935"
        fill="#FFF"
      />
      <path
        d="M71.268 82.388H3.935A2.935 2.935 0 0 1 1 79.454V4.936A2.936 2.936 0 0 1 3.935 2h67.333a2.936 2.936 0 0 1 2.935 2.936v74.517a2.935 2.935 0 0 1-2.935 2.935z"
        stroke="#A1AEBA"
        strokeWidth={2}
      />
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <path fill="#DBE1E6" mask="url(#b)" d="M4.323 11.764H67.03V5.12H4.323z" />
      <g mask="url(#b)">
        <path d="M1 14.898h60.93" fill="#FFF" />
        <path d="M1 14.898h60.93" stroke="#A1AEBA" strokeWidth={2} />
      </g>
      <path
        d="M13.274 61.838H61.93M13.274 71.29H61.93M44.559 51.14H29.553a3.95 3.95 0 0 1-3.95-3.95V32.727h22.906V47.19a3.95 3.95 0 0 1-3.95 3.95z"
        stroke="#D7DADE"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        mask="url(#b)"
      />
      <path fill="#FFF" mask="url(#b)" d="M23.988 38.306h26.136v-5.58H23.988z" />
      <path
        stroke="#D7DADE"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        mask="url(#b)"
        d="M23.988 38.306h26.136v-5.58H23.988zM25.603 38.305h22.905M34.306 38.305v12.352M39.806 38.305v12.352M37.053 32.726s-2.131-5.131-3.612-6.043c-1.481-.912-3.39-.418-4.297 1.292-.908 1.71.064 4.75 2.01 4.75M37.056 32.726s2.13-5.131 3.612-6.043c1.48-.912 3.389-.418 4.297 1.292.908 1.71-.064 4.75-2.01 4.75"
      />
      <path
        d="M107.468 80.205L86.785 60.43a6.494 6.494 0 1 1 8.976-9.388l20.683 19.774a6.495 6.495 0 0 1-8.976 9.389"
        fill="#D7EEFF"
        mask="url(#b)"
      />
      <path
        d="M107.468 80.205L86.785 60.43a6.494 6.494 0 1 1 8.976-9.388l20.683 19.774a6.495 6.495 0 0 1-8.976 9.389z"
        stroke="#027AD7"
        strokeWidth={2}
        mask="url(#b)"
      />
      <path
        d="M105.265 37.048c0 17.102-13.863 30.965-30.965 30.965-17.1 0-30.964-13.864-30.964-30.965 0-17.1 13.863-30.965 30.964-30.965 17.102 0 30.965 13.865 30.965 30.965"
        fill="#D7EEFF"
        mask="url(#b)"
      />
      <path
        d="M105.265 37.048c0 17.102-13.863 30.965-30.965 30.965-17.1 0-30.964-13.864-30.964-30.965 0-17.1 13.863-30.965 30.964-30.965 17.102 0 30.965 13.865 30.965 30.965z"
        stroke="#027AD7"
        strokeWidth={2}
        mask="url(#b)"
      />
      <path
        d="M105.265 37.048c0 17.102-13.863 30.965-30.965 30.965-17.1 0-30.964-13.864-30.964-30.965 0-17.1 13.863-30.965 30.964-30.965 17.102 0 30.965 13.865 30.965 30.965"
        fill="#D7EEFF"
        mask="url(#b)"
      />
      <path
        d="M105.265 37.048c0 17.102-13.863 30.965-30.965 30.965-17.1 0-30.964-13.864-30.964-30.965 0-17.1 13.863-30.965 30.964-30.965 17.102 0 30.965 13.865 30.965 30.965z"
        stroke="#027AD7"
        strokeWidth={2}
        mask="url(#b)"
      />
      <path
        d="M96.525 37.048c0 12.274-9.95 22.225-22.225 22.225-12.274 0-22.225-9.95-22.225-22.225 0-12.274 9.951-22.225 22.225-22.225 12.275 0 22.225 9.951 22.225 22.225"
        fill="#FFF"
        mask="url(#b)"
      />
      <path
        d="M79.192 46.711c1.32 0 1.999-.949 1.999-2.846 0-1.93-.678-2.914-2-2.914-1.32 0-1.998.983-1.998 2.914 0 1.897.678 2.846 1.999 2.846zm4.54-2.846c0 1.39-.407 2.574-1.186 3.524-.847 1.015-1.965 1.523-3.354 1.523-1.39 0-2.473-.508-3.32-1.523-.78-.984-1.186-2.135-1.186-3.524 0-1.423.407-2.609 1.186-3.557.847-1.017 1.93-1.525 3.32-1.525 1.389 0 2.507.508 3.354 1.525.779.948 1.186 2.134 1.186 3.557zm-15.415-8.063c1.32 0 1.999-.948 1.999-2.88 0-1.93-.678-2.879-2-2.879-1.32 0-1.998.95-1.998 2.88 0 1.931.678 2.88 1.999 2.88zm13.755-5.954l-14.85 18.68-1.857-1.546 14.809-18.667 1.898 1.533zm-9.25 3.074c0 1.39-.405 2.575-1.185 3.558-.847 1.016-1.931 1.524-3.32 1.524-1.388 0-2.473-.508-3.32-1.524-.78-.983-1.186-2.169-1.186-3.558 0-1.388.407-2.574 1.186-3.523.847-1.017 1.932-1.524 3.32-1.524 1.389 0 2.473.507 3.32 1.524.78.95 1.186 2.135 1.186 3.523z"
        fill="#A1AEBA"
        mask="url(#b)"
      />
      <path
        d="M71.985 82.388h12.794"
        stroke="#A1AEBA"
        strokeWidth={2}
        strokeLinecap="round"
        mask="url(#b)"
      />
    </g>
  </svg>
);

export default GroupsAnalyzesIcon;
