import React from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { flatten, isEmpty } from 'ramda';
import { withTheme } from '@emotion/react';

import { Box, customTooltip } from '../../../mc-ui';

class Chart extends React.PureComponent {
  state = {
    chartReference: null,
  };

  setChartReference = ref => {
    this.setState(currentState => {
      if (currentState.chartReference) return {};
      return { chartReference: ref };
    });
  };

  colors = () => {
    const { theme } = this.props;

    return [
      theme.colors.blue,
      theme.colors.tealMedium,
      theme.colors.yellowMedium,
      theme.colors.purpleMedium,
    ];
  };

  data = () => {
    const { buyOneGetOneFree, dollarsOff, freeShipping, percentOff } = this.props;
    const buyOneGetOneFreeValue = isEmpty(buyOneGetOneFree)
      ? 0
      : (buyOneGetOneFree.value * 100).toFixed(2);
    const dollarsOffValue = isEmpty(dollarsOff) ? 0 : (dollarsOff.value * 100).toFixed(2);
    const freeShippingValue = isEmpty(freeShipping) ? 0 : (freeShipping.value * 100).toFixed(2);
    const percentOffValue = isEmpty(percentOff) ? 0 : (percentOff.value * 100).toFixed(2);

    return {
      labels: ['PERCENT-OFF', 'DOLLARS-OFF', 'FREE SHIPPING', 'BUY ONE, GET ONE FREE'],
      datasets: [
        {
          backgroundColor: this.colors(),
          borderWidth: 0,
          data: [percentOffValue, dollarsOffValue, freeShippingValue, buyOneGetOneFreeValue],
          hoverBackgroundColor: this.colors(),
        },
      ],
    };
  };

  generateTooltipHTML = (getBody, tooltipModel) => {
    if (!tooltipModel.body) return null;

    const titleLines = tooltipModel.title || [];
    const bodyLines = flatten(tooltipModel.body.map(getBody));
    let tooltipHTML = '';

    bodyLines.forEach((bodyLine, index) => {
      const color = tooltipModel.labelColors[index];
      const title = titleLines[index];
      tooltipHTML += `<div class="title">${title}</div>`;
      tooltipHTML += `<div class="line"><div class="bullet" style="background-color: ${
        color.backgroundColor
      }"></div> <div class="content">${bodyLine}%</div></div>`;
    });

    return tooltipHTML;
  };

  options = () => {
    const { theme } = this.props;

    return {
      legend: {
        display: false,
      },
      maintainAspectRatio: false,
      datasets: {
        bar: {
          barPercentage: 1.0,
          categoryPercentage: 0.4,
        }
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false,
            },
            ticks: {
              fontColor: theme.colors.dark04,
              fontFamily: theme.font.family,
              fontSize: 10,
              lineHeight: 1.56,
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              fontColor: theme.colors.dark04,
              fontFamily: theme.font.family,
              fontSize: 10,
              lineHeight: 1.56,
              max: 100,
              min: 0,
              stepSize: 50,
              userCallback(label) {
                return `${label}%`;
              },
            },
          },
        ],
      },
      tooltips: {
        ...customTooltip(this.state.chartReference, this.generateTooltipHTML),
      },
    };
  };

  render() {
    return (
      <Box css={{ height: '243px' }}>
        <Bar
          data={this.data()}
          height={243}
          options={this.options()}
          ref={this.setChartReference}
          width={100}
        />
      </Box>
    );
  }
}

Chart.propTypes = {
  buyOneGetOneFree: PropTypes.shape({
    total: PropTypes.number,
  }).isRequired,
  dollarsOff: PropTypes.shape({
    total: PropTypes.number,
  }).isRequired,
  freeShipping: PropTypes.shape({
    total: PropTypes.number,
  }).isRequired,
  percentOff: PropTypes.shape({
    total: PropTypes.number,
  }).isRequired,
  theme: PropTypes.shape({}).isRequired,
};

export default withTheme(Chart);
