import React from 'react'
import styles from './styles.css'

const trim = function(string = '', length) {
  return string.length > length
    ? string.slice(0, length - 3) + '...'
    : string
}
class From extends React.Component {
  render() {
    const { from = [{ name: '' }] } = this.props
    let from_name = from.reduce((p, f) => f.name, '')
    return (
      <p className={styles.from}>{trim(from_name, 35)}</p>
    )
  }
}

export default From
