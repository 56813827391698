import React from 'react';

const UnlockAccessIcon = props => (
  <svg viewBox="0 0 171 100" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M94 8H77V3.985C77 2.336 78.313 1 79.934 1h11.131C92.687 1 94 2.336 94 3.985V8z"
        fill="#FFF"
      />
      <path
        d="M94 8H77V3.985C77 2.336 78.313 1 79.934 1h11.131C92.687 1 94 2.336 94 3.985V8z"
        stroke="#A1AEBA"
        strokeWidth={2}
        strokeLinecap="round"
      />
      <path
        d="M128 48H42V8.15C42 6.961 42.967 6 44.16 6h81.68c1.193 0 2.16.962 2.16 2.15V48z"
        fill="#FFF"
      />
      <path
        d="M128 48H42V8.15C42 6.961 42.967 6 44.16 6h81.68c1.193 0 2.16.962 2.16 2.15V48z"
        stroke="#A1AEBA"
        strokeWidth={2}
        strokeLinecap="round"
      />
      <path
        d="M78.782 35H63.218A1.218 1.218 0 0 1 62 33.782V18.218c0-.673.545-1.218 1.218-1.218h15.564c.673 0 1.218.545 1.218 1.218v15.564c0 .673-.545 1.218-1.218 1.218"
        fill="#D7EEFF"
      />
      <path
        d="M78.782 35H63.218A1.218 1.218 0 0 1 62 33.782V18.218c0-.673.545-1.218 1.218-1.218h15.564c.673 0 1.218.545 1.218 1.218v15.564c0 .673-.545 1.218-1.218 1.218z"
        stroke="#027AD7"
        strokeWidth={1.5}
      />
      <path
        d="M107.782 36H92.218A1.218 1.218 0 0 1 91 34.782V19.218c0-.673.545-1.218 1.218-1.218h15.564c.673 0 1.218.545 1.218 1.218v15.564c0 .673-.545 1.218-1.218 1.218"
        fill="#D7EEFF"
      />
      <path
        d="M107.782 36H92.218A1.218 1.218 0 0 1 91 34.782V19.218c0-.673.545-1.218 1.218-1.218h15.564c.673 0 1.218.545 1.218 1.218v15.564c0 .673-.545 1.218-1.218 1.218z"
        stroke="#027AD7"
        strokeWidth={1.5}
      />
      <path
        d="M61.782 55H46.218A1.218 1.218 0 0 1 45 53.782V38.218c0-.673.545-1.218 1.218-1.218h15.564c.673 0 1.218.545 1.218 1.218v15.564c0 .673-.545 1.218-1.218 1.218"
        fill="#D7EEFF"
      />
      <path
        d="M61.782 55H46.218A1.218 1.218 0 0 1 45 53.782V38.218c0-.673.545-1.218 1.218-1.218h15.564c.673 0 1.218.545 1.218 1.218v15.564c0 .673-.545 1.218-1.218 1.218z"
        stroke="#027AD7"
        strokeWidth={1.5}
      />
      <path
        d="M122.782 55h-15.564A1.218 1.218 0 0 1 106 53.782V38.218c0-.673.545-1.218 1.218-1.218h15.564c.673 0 1.218.545 1.218 1.218v15.564c0 .673-.545 1.218-1.218 1.218"
        fill="#D7EEFF"
      />
      <path
        d="M122.782 55h-15.564A1.218 1.218 0 0 1 106 53.782V38.218c0-.673.545-1.218 1.218-1.218h15.564c.673 0 1.218.545 1.218 1.218v15.564c0 .673-.545 1.218-1.218 1.218z"
        stroke="#027AD7"
        strokeWidth={1.5}
      />
      <path
        d="M118.512 52H99.49A1.49 1.49 0 0 1 98 50.512V31.488A1.49 1.49 0 0 1 99.49 30h19.022c.822 0 1.488.667 1.488 1.488v19.024c0 .821-.666 1.488-1.488 1.488"
        fill="#D7EEFF"
      />
      <path
        d="M118.512 52H99.49A1.49 1.49 0 0 1 98 50.512V31.488A1.49 1.49 0 0 1 99.49 30h19.022c.822 0 1.488.667 1.488 1.488v19.024c0 .821-.666 1.488-1.488 1.488z"
        stroke="#027AD7"
        strokeWidth={1.5}
      />
      <path
        d="M103.157 38c-.07.125-.14.244-.204.364a3.153 3.153 0 0 0-.321.899c-.047.266-.054.531.039.789.146.407.444.672.886.789.248.065.5.072.755.061.407-.018.797-.115 1.188-.212 1.034-.26 2.07-.516 3.106-.773.786-.195 1.573-.39 2.36-.587l3.072-.764 1.935-.48a.045.045 0 0 1 .027 0l-.053.023-5.373 2.15c-1.642.659-3.286 1.317-4.929 1.972-.386.154-.774.304-1.165.444-.394.139-.802.238-1.22.292a3.092 3.092 0 0 1-.986-.013c-.518-.1-.909-.36-1.14-.812a1.385 1.385 0 0 1-.13-.728c.023-.399.153-.77.33-1.13.202-.412.472-.785.768-1.143.325-.392.679-.758 1.035-1.123.005-.006.011-.01.02-.018"
        fill="#027AD7"
      />
      <path
        d="M73.444 53H53.557C52.698 53 52 52.303 52 51.444V31.556c0-.86.698-1.556 1.557-1.556h19.887c.86 0 1.556.697 1.556 1.556v19.888c0 .86-.697 1.556-1.556 1.556"
        fill="#D7EEFF"
      />
      <path
        d="M73.444 53H53.557C52.698 53 52 52.303 52 51.444V31.556c0-.86.698-1.556 1.557-1.556h19.887c.86 0 1.556.697 1.556 1.556v19.888c0 .86-.697 1.556-1.556 1.556z"
        stroke="#027AD7"
        strokeWidth={1.5}
      />
      <path
        d="M59 40.791v2.966l3.481.482V40.79H59zm4.5-4.164v3.257H68V36l-4.5.627zm0 4.164v3.593L68 45v-4.209h-4.5zM59 37.243v2.641h3.481v-3.123L59 37.243z"
        fill="#027AD7"
      />
      <path
        d="M97.173 50H73.827A1.827 1.827 0 0 1 72 48.173V24.827c0-1.009.818-1.827 1.827-1.827h23.346c1.009 0 1.827.818 1.827 1.827v23.346C99 49.182 98.182 50 97.173 50"
        fill="#D7EEFF"
      />
      <path
        d="M97.173 50H73.827A1.827 1.827 0 0 1 72 48.173V24.827c0-1.009.818-1.827 1.827-1.827h23.346c1.009 0 1.827.818 1.827 1.827v23.346C99 49.182 98.182 50 97.173 50z"
        stroke="#027AD7"
        strokeWidth={1.5}
      />
      <path
        d="M92 38.502l-.16.395a7.148 7.148 0 0 1-1.081 1.761 6.394 6.394 0 0 1-.647.712c-.21.19-.436.357-.706.47a1.837 1.837 0 0 1-1.032.116 4.718 4.718 0 0 1-.872-.247 3.826 3.826 0 0 0-.918-.223 2.95 2.95 0 0 0-.71.016 4.833 4.833 0 0 0-1.014.275c-.24.088-.485.156-.74.199-.38.064-.733 0-1.064-.177a3.227 3.227 0 0 1-.81-.64c-.772-.812-1.373-1.719-1.76-2.742a7.749 7.749 0 0 1-.396-1.424c-.08-.469-.11-.941-.076-1.415a3.99 3.99 0 0 1 .425-1.57c.241-.469.566-.88 1.003-1.212a3.668 3.668 0 0 1 1.694-.72c.204-.03.41-.05.617-.041.24.008.476.05.704.116.282.082.56.176.84.26.205.063.41.119.616.177a.687.687 0 0 0 .397-.012c.341-.106.682-.21 1.021-.317.308-.098.618-.181.94-.228a3.29 3.29 0 0 1 .819-.01c.323.036.638.095.944.194.638.208 1.159.559 1.556 1.064l.025.033-.126.073a3.487 3.487 0 0 0-.828.675 2.45 2.45 0 0 0-.59 1.214 2.845 2.845 0 0 0 .124 1.495c.165.446.448.82.821 1.14.283.243.6.44.958.58l.026.013M88.986 29c.005.057.01.105.012.154.027.66-.219 1.24-.67 1.754-.295.338-.64.625-1.063.834-.366.18-.756.273-1.177.256l-.056-.003-.015-.003a1.8 1.8 0 0 1-.013-.368c.046-.814.684-1.832 1.786-2.31.29-.127.587-.23.905-.279.095-.015.19-.023.291-.035"
        fill="#027AD7"
      />
      <path fill="#FFF" d="M134 98H37V43l8.249 1.485h81.953L134 43z" />
      <path
        d="M57 70h57M50 90h72"
        stroke="#D7DADE"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M134.587 85.352v10.017c0 2.005-1.607 3.631-3.59 3.631H40.59c-1.982 0-3.59-1.626-3.59-3.631V85.352"
        fill="#FFF"
      />
      <path
        d="M134.587 85.352v10.017c0 2.005-1.607 3.631-3.59 3.631H40.59c-1.982 0-3.59-1.626-3.59-3.631V85.352"
        stroke="#A1AEBA"
        strokeWidth={2}
        strokeLinecap="round"
      />
      <path fill="#FFF" d="M45 55h81v-9H45z" />
      <path stroke="#A1AEBA" strokeWidth={2} strokeLinecap="round" d="M45 55h81v-9H45z" />
      <path
        d="M93.077 70H79.922C78.31 70 77 68.715 77 67.128V46h19v21.128C96 68.715 94.691 70 93.077 70"
        fill="#FFF"
      />
      <path
        d="M93.077 70H79.922C78.31 70 77 68.715 77 67.128V46h19v21.128C96 68.715 94.691 70 93.077 70z"
        stroke="#A1AEBA"
        strokeWidth={2}
        strokeLinecap="round"
      />
      <path d="M90 57a4 4 0 1 1-8 0 4 4 0 0 1 8 0" fill="#D7DADE" />
      <path
        d="M37 91.33V45.49A2.481 2.481 0 0 1 39.47 43h3.06A2.481 2.481 0 0 1 45 45.49V98M126 98V45.49a2.481 2.481 0 0 1 2.47-2.49h3.06a2.481 2.481 0 0 1 2.47 2.49v45.84"
        stroke="#A1AEBA"
        strokeWidth={2}
        strokeLinecap="round"
      />
      <path
        d="M57 84h57"
        stroke="#D7DADE"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g stroke="#A1AEBA" strokeLinecap="round" strokeWidth={2}>
        <path d="M1 41l19 4" strokeDasharray={6} />
        <path d="M11 11l19 10" strokeDasharray={10} />
      </g>
      <g stroke="#A1AEBA" strokeLinecap="round" strokeWidth={2}>
        <path d="M169.5 40.5l-19 4" strokeDasharray={6} />
        <path d="M159.5 10.5l-19 10" strokeDasharray={10} />
      </g>
    </g>
  </svg>
);

export default UnlockAccessIcon;
