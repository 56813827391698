import styled from "@emotion/styled";

export const UserAvatar = styled("img")({
  borderRadius: '50%',
  display: 'inline-block',
  height: '32px',
  width: '32px',
});

export const UserName = styled("h3")(({ theme }) => ({
  color: theme.colors.dark1,
  fontFamily: theme.font.family,
  fontSize: '12px',
  fontWeight: 'bold',
  letterSpacing: '0.08em',
  lineHeight: '1.56em',
  margin: '0',
  padding: '0',
  textTransform: 'uppercase',
}));
