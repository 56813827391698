import React from 'react'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import Container from './Container'
import moment from 'moment'

const emailImage = (email) => {
  return `//assets.mailcharts.com/emails/${email.attributes.guid}/${'300x400'}.png`
}

class SegmentsDetails extends React.Component {
  render() {
    let m = this.props.addresses.data.reduce((m, c) => c)
    let company = this.props.addresses.included.filter(i => i.type == 'companies').reduce((m, c) => c)
    return (
      <div className='pure-u-1'>
        <div key={m.id} className='pure-u-1'>
          <h1>{m.attributes.description} segment</h1>
          <Container className='pure-u-1'>
            <div className='pure-u-1 pure-u-md-1-8'>
              <img
                src={`//cache.mailcharts.com/logos/v2/companies/${company.id}.png`}
              />
            </div>
            <div className='pure-u-1 pure-u-md-7-8'>
              <p>Events</p>
              <ul>
              {this.props.addresses.included.filter(i => i.type == 'events').map(e => {
              return (<li key={e.id}>
                {e.attributes.description} - {moment(e.attributes.occurred_at).format('MM-DD-YYYY')}
              </li>)
              })}
              </ul>
              <p>Want to refresh a user segment?</p>
              <p>
                <Link to='#'>Request a new segment</Link>
              </p>
            </div>
          </Container>
          <h2>Recent emails</h2>
          <Container className='pure-u-1'>
            <div className='pure-u-1'>
            {this.props.searches.data.emails.slice(0, 6).map(m => {
              return (<div className='pure-u-1-6' key={m.id}>
                <img src={emailImage(m)} className='pure-img' />
              </div>)
            })}
            </div>
          </Container>
          <h2>Reports</h2>
          <Container className='pure-u-1'>
            <div className='pure-u-1' style={{ height: 150, background: 'purple' }}>
              <h3>Most popular day of the week</h3>
            </div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum nunc purus, lobortis ac sodales sit amet, euismod porta libero. <Link to={`/${m.type}/${m.id}/reports`}>Select a report.</Link></p>
          </Container>
          <h2>{company.attributes.name}</h2>
          <Container className='pure-u-1'>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum nunc purus, lobortis ac sodales sit amet, euismod porta libero.</p>
            <p>
              <Link
                to={`/companies/${company.id}`}
              >
                See {company.attributes.name} details
              </Link>
            </p>
          </Container>
        </div>
      </div>
    )
  }
}

const s = (state) => ({ addresses: state.addresses, searches: state.searches })
export default connect(s)(SegmentsDetails)
