import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@emotion/react';

import Flex from '../Flex';
import Heading from '../Heading';

const CheckboxGroup = ({ children, theme, title, width }) => (
  <Flex direction="column" css={{ width }}>
    {title && (
      <Heading
        as="h4"
        css={{
          color: theme.colors.dark2,
          fontSize: '14px',
          lineHeight: `${18 / 14}em`,
          margin: '0 0 8px 0 !important',
          padding: '0 !important',
        }}
      >
        {title}
      </Heading>
    )}
    {children}
  </Flex>
);

CheckboxGroup.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.shape({}).isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]),
  width: PropTypes.string,
};

CheckboxGroup.defaultProps = {
  title: null,
  width: null,
};

export default withTheme(CheckboxGroup);
