/** @jsx jsx */
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { Link } from 'react-router';
import { jsx, withTheme } from '@emotion/react';

import { Box, Flex, Heading, Text } from '../../../mc-ui';
import ArrowRightIcon from '../../../mc-ui/icons/ArrowRightIcon';
import FYICircleIcon from '../../../mc-ui/icons/FYICircleIcon';

const isActive = (item, selectedItem) => item.id === selectedItem.id;

const GroupItem = ({ item, onClick, selectedItem, theme }) => (
  <Link
    key={item.id}
    css={{
      display: 'flex',
      marginBottom: '16px',
      textDecoration: 'none',
      '&:hover': { '& h4': { color: `${theme.colors.blue} !important` } },
    }}
    onClick={onClick(item.id)}
    to={`#${item.id}`}
  >
    <Flex alignItems="center">
      <Flex
        alignItems="center"
        backgroundColor={isActive(item, selectedItem) ? 'blue' : 'dark6'}
        borderRadius="4px"
        color={isActive(item, selectedItem) ? 'white' : 'dark1'}
        justifyContent="center"
        marginRight="16px"
        height="40px"
        width="40px"
      >
        {item.id === 'createGroup' ? (
          <FYICircleIcon height="20px" width="20px" />
        ) : (
          <ArrowRightIcon fillOpacity={1} height="20px" width="20px" />
        )}
      </Flex>

      <Box width="unset">
        <Heading
          as="h4"
          color={isActive(item, selectedItem) ? 'blue' : 'dark08'}
          css={{ fontSize: '14px', lineHeight: '1.25em' }}
        >
          {item.attributes.name}
        </Heading>

        {item.id !== 'createGroup' && (
          <Text fontSize="12px">
            {item.attributes.approximateCompanies}{' '}
            {pluralize('brand', item.attributes.approximateCompanies)}
          </Text>
        )}
      </Box>
    </Flex>
  </Link>
);

GroupItem.propTypes = {
  item: PropTypes.shape({}).isRequired,
  onClick: PropTypes.func.isRequired,
  selectedItem: PropTypes.shape({}).isRequired,
  theme: PropTypes.shape({}).isRequired,
};

export default withTheme(GroupItem);
