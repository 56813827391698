import React from 'react';
import PropTypes from 'prop-types';

import { Badge } from '../mc-ui';

export default function ProBadge({ css, small }) {
  const border = null;
  const borderColor = null;
  const color = 'white';
  const backgroundColor = 'purpleMedium';
  const label = 'Pro';

  return (
    <Badge
      backgroundColor={backgroundColor}
      border={border}
      borderColor={borderColor}
      color={color}
      fontSize={small ? '10px' : '12px'}
      lineHeight="1em"
      padding={small ? '3px 4px' : '5px 7px'}
      css={css}
    >
      {label}
    </Badge>
  );
}

ProBadge.defaultProps = {
  css: {},
  small: false,
};

ProBadge.propTypes = {
  css: PropTypes.shape({}),
  small: PropTypes.bool,
};
