const INITIAL_STATE = {
  journeys: {
    loading: true,
    data: [],
  },
};

const receiveDemoJourneys = (state, action) => ({
  ...state,
  journeys: {
    loading: false,
    data: action.data.data,
  },
});

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case 'REQUEST_DEMO_JOURNEYS':
      return INITIAL_STATE;
    case 'RECEIVE_DEMO_JOURNEYS':
      return receiveDemoJourneys(state, action);
    default:
      return state;
  }
};
