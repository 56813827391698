/* eslint-disable import/prefer-default-export */
import { contains, not } from 'ramda';

import { ignoreFilters } from '../../../modules/savedSearches/constants';

const params = [
  { key: 'advertising', label: 'Advertising pixels' },
  { key: 'analytics', label: 'Analytics pixels' },
  { key: 'content', label: 'Dynamic content' },
  { key: 'countries', label: 'Country' },
  { key: 'frequency', label: 'Weekly frequency' },
  { key: 'group_id', label: 'Industries' },
  { key: 'history', label: 'Y/Y data' },
  { key: 'journey_types', label: 'Journey Type' },
  { key: 'misc', label: 'Misc. pixels' },
  { key: 'new_brands', label: 'New brands' },
  { key: 'promotional', label: 'Promotional rate' },
  { key: 'promotional_from', label: 'Promotional From' },
  { key: 'promotional_to', label: 'Promotional To' },
  { key: 'q', label: 'Name' },
  { key: 'sent_at_day_of_week', label: 'Most popular send day' },
  { key: 'sent_at_hour_of_day', label: 'Most popular send time' },
  { key: 'smtpaas', label: 'SMTPaaS' },
  { key: 'sort', label: 'Sort' },
  { key: 'states', label: 'State' },
  { key: 'technologies', label: 'Technologies' },
  { key: 'twitter_followers', label: 'Twitter followers' },
];
export const ignoreKeys = ignoreFilters;

const formatJourneyType = journeyType => journeyType.split('_').join(' ');

const getGroupName = (groupId, { groups }) => {
  const group = groups.data.find(g => g.id === groupId);

  if (!group) return null;

  return group.attributes.name;
};

const paramValues = (queryKey, props, queryValue) => {
  if (typeof queryValue === 'string') {
    if (queryKey === 'group_id') return getGroupName(queryValue, props);
    if (queryKey === 'journey_types') return formatJourneyType(queryValue);
    return queryValue;
  }

  return queryValue
    .map(v => {
      if (queryKey === 'group_id') return getGroupName(v, props);
      if (queryKey === 'journey_types') return formatJourneyType(v);
      return v;
    })
    .filter(v => !!v);
};

export const queryToParams = (query, props) =>
  Object.keys(query)
    .map(queryKey => {
      const param = params.find(p => p.key === queryKey);
      const values = paramValues(queryKey, props, query[queryKey]);
      const value = typeof values === 'string' ? values : values.join(', ');
      const queryValues = query[queryKey];
      return { ...param, queryValues, value, values };
    })
    .filter(p => not(contains(p.key, ignoreKeys)) && p.key);
