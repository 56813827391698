import styled from "@emotion/styled";

const JourneysContainer = styled("section")({
  marginBottom: '64px',
  '& h3': {
    marginBottom: '40px',
    marginTop: '0',
    maxWidth: '100%',
    textAlign: 'center',
  },
});

export default JourneysContainer;
