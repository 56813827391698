import React from 'react';
import PropTypes from 'prop-types';
import { filter, last } from 'ramda';
import { FormSpy } from 'react-final-form';

const CleanCheckboxes = ({ form }) => (
  <FormSpy
    subscription={{ values: true }}
    onChange={({ values }) => {
      const { frequency, journey_types: journeyTypes } = values;

      if (journeyTypes && journeyTypes.length > 1) {
        if (last(journeyTypes) === 'any') {
          form.change('journey_types', ['any']);
        } else {
          const newValues = filter(v => v !== 'any', journeyTypes);
          if (newValues.length < journeyTypes.length) {
            form.change('journey_types', newValues);
          }
        }
      }

      if (frequency && frequency.length > 1) {
        if (last(frequency) === 'any') {
          form.change('frequency', ['any']);
        } else {
          const newValues = filter(v => v !== 'any', frequency);
          if (newValues.length < frequency.length) {
            form.change('frequency', newValues);
          }
        }
      }
    }}
  />
);

CleanCheckboxes.propTypes = {
  form: PropTypes.shape({}).isRequired,
};

export default CleanCheckboxes;
