import React from 'react';
import PropTypes from 'prop-types';
import api from '../lib/api';
import { Button, Flex, Modal, Text } from './mc-ui';
import { useAuth0 } from '@auth0/auth0-react';
import { www } from '../lib/utils';
import { Form, Field } from 'react-final-form';
import { Select } from './mc-ui';

const DeleteButton = ({ handleDelete }) => {
  const { logout } = useAuth0();
  const redirectURI = www('/');
  const signOut = async () => {
    let res = await handleDelete();
    if (res) {
      await api.post('/auth/destroy').catch(console.error);
      logout({ returnTo: redirectURI });
    }
  };
  return (
    <Button
      style={{ cursor: 'pointer' }}
      onClick={() => {
        signOut();
      }}
    >
      Delete Account
    </Button>
  );
};
class AccountDelete extends React.Component {
  state = {
    loading: false,
    error: false,
    errorMsg: '',
    comment: '',
    reason: '',
  };

  handleSubmit = async event => {
    if (!this.state.reason) {
      this.setState({ error: true, errorMsg: 'Select a Reasons for deletion request' });
      if (event) event.preventDefault();
      return false;
    }
    this.setState({ loading: true, error: false });
    try {
      await api.post('/v3/user/deletion-request', {
        user_id: this.props.userId,
        reason: this.state.reason,
        comment: this.state.comment,
      });
      this.setState({ loading: false });
    } catch (err) {
      this.setState({
        errorMsg: err.response?.data?.message || err.response?.message,
        error: true,
      });
      this.setState({ loading: false });
      if (event) event.preventDefault();
      return false;
    }
    return true;
  };

  handleCancel = event => {
    const { toggleModal } = this.props;
    toggleModal();
  };

  handleReasonChange = reason => {
    this.setState({ reason });
  };

  handleCommentChange = event => {
    const comment = event.target.value;
    this.setState({ comment });
  };

  render() {
    const boldText = {
      color: '#000',
      fontWeight: 'bold',
    };
    const options = [
      {
        label: 'No longer needed',
        value: 'No longer needed',
      },
      {
        label: 'Not used enough',
        value: 'Not used enough',
      },
      {
        label: 'Switching to another provider',
        value: 'Switching to another provider',
      },
      {
        label: 'Other',
        value: 'Other',
      },
    ];
    const { isOpen, toggleModal } = this.props;

    return (
      <Modal isOpen={isOpen} onClose={toggleModal}>
        <Modal.Header onClose={toggleModal}>Account Deletion Confirmation</Modal.Header>
        <Form onSubmit={this.handleSubmit}>
          {() => (
            <form>
              <Modal.Body width="640px">
                <Text>
                  Are you sure you want to proceed with deleting your account? This action is
                  <span style={boldText}> irreversible</span> and the account deletion will be completed 30 days after request submission.
                </Text>
                <Text>Please consider the following:</Text>
                <ul>
                  <li>
                    All your data, settings, and history associated with this account will be
                    permanently removed.
                  </li>
                  <li>
                    You won't be able to recover your account or any content once the process is
                    initiated.
                  </li>
                </ul>

                <Text>
                  If you are certain about this decision, click "
                  <span style={boldText}>Delete Account</span>" below. Otherwise, click "
                  <span style={boldText}>Cancel</span>" to retain your account.
                </Text>

                <div style={{ margin: '10px 0' }}>Reasons for deletion request</div>
                <Field
                  component={Select}
                  id="deleteId"
                  name="deleteId"
                  placeholder="Select a reason for deleting"
                  limit={5}
                  options={options}
                  onChange={this.handleReasonChange}
                />
                <div style={{ margin: '10px 0' }}>Comments(optional)</div>
                <input
                  type="text"
                  name="reason"
                  onChange={this.handleCommentChange}
                  className="pure-u-1 pure-u-md-23-24"
                  style={{
                    padding: '1em',
                    fontSize: `${13 / 16}em`,
                    color: '#242424',
                  }}
                  value={this.state.comment}
                />
              </Modal.Body>

              <Modal.Footer
                justifyContent="center"
                css={{
                  display: 'block',
                }}
              >
                <div style={{ height: '21px' }}>
                  {this.state.error && <span style={{ color: 'red' }}>{this.state.errorMsg}</span>}
                </div>
                <Flex justifyContent="space-between" marginBottom="16px">
                  {this.state.loading ? (
                    <div style={{ width: '134px' }}>
                      <DeleteButton css={{ opacity: 0.5 }} handleDelete={e => e.preventDefault()} />
                    </div>
                  ) : (
                    <DeleteButton handleDelete={this.handleSubmit} />
                  )}
                  <Button
                    variant="primary"
                    type="button"
                    onClick={this.handleCancel}
                    css={{ height: '40px', lineHeight: '40px', padding: 0, width: '134px' }}
                  >
                    Cancel
                  </Button>
                </Flex>
              </Modal.Footer>
            </form>
          )}
        </Form>
      </Modal>
    );
  }
}

AccountDelete.propTypes = {
  userID: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

export default AccountDelete;
