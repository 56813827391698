import * as types from './types';

export const fetchRequest = () => ({
  type: types.FETCH_REQUEST,
});

export const fetchSuccess = (data, industryGroups) => ({
  type: types.FETCH_SUCCESS,
  data,
  industryGroups,
});

export const fetchFailure = error => ({
  type: types.FETCH_FAILURE,
  error,
});

export const disableRequest = () => ({
  type: types.DISABLE_REQUEST,
});

export const disableSuccess = data => ({
  type: types.DISABLE_SUCCESS,
  data,
});

export const disableFailure = error => ({
  type: types.DISABLE_FAILURE,
  error,
});

export const enableRequest = () => ({
  type: types.ENABLE_REQUEST,
});

export const enableSuccess = data => ({
  type: types.ENABLE_SUCCESS,
  data,
});

export const enableFailure = error => ({
  type: types.ENABLE_FAILURE,
  error,
});

export const addGroupRequest = group => ({
  type: types.ADD_GROUP_REQUEST,
  group,
});

export const addGroupSuccess = data => ({
  type: types.ADD_GROUP_SUCCESS,
  data,
});

export const addGroupFailure = error => ({
  type: types.ADD_GROUP_FAILURE,
  error,
});

export const removeGroupRequest = group => ({
  type: types.REMOVE_GROUP_REQUEST,
  group,
});

export const removeGroupSuccess = data => ({
  type: types.REMOVE_GROUP_SUCCESS,
  data,
});

export const removeGroupFailure = error => ({
  type: types.REMOVE_GROUP_FAILURE,
  error,
});
