import * as React from "react"
import { palette } from "../../lib/color"

class Disc extends React.Component {

  DEFAULT_DIAMETER = 12
  DEFAULT_COLOR = palette.grayA

  render () {
    const diameter = this.props.diameter || this.DEFAULT_DIAMETER
    const color = this.props.color || this.DEFAULT_COLOR
    const style = {
      display: 'inline-block',
      width: diameter,
      height: diameter,
      borderRadius: '50%',
      background: color
    }

    return <span style={style}></span>
  }
}

export default Disc