import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

import { Box, Button, Flex, Heading, Text } from '../../../../mc-ui';

import ReportSection from '../../../ReportSection';
import Body from '../../../Body';
import RestrictedHeader from '../../../RestrictedHeader';
import RestrictedUpgradeButton from '../../../RestrictedUpgradeButton';

import AverageSubjectLineLength from './AverageSubjectLineLength';
import MostPopularTerms from './MostPopularTerms';
import SubjectLinesByLength from './SubjectLinesByLength';
import WhatsTrending from './WhatsTrending';

const isGroup = model => model.type === 'groups';

const Upgrade = ({ model }) => (
  <ReportSection>
    <Body>
      <RestrictedHeader
        description="View the latest and popular trends in ecommerce."
        reportName="Subject Lines"
      />

      <Flex justifyContent="space-between" marginBottom="40px">
        <Flex direction="column" justifyContent="center" padding="0 32px" width="360px">
          <Heading as="h4" fontSize="18px" lineHeight="24px" css={{ marginBottom: '8px' }}>
            Average subject line length
          </Heading>

          {isGroup(model) ? (
            <Text margin="0 0 8px 0">
              Improve creative briefs by showing how subject line lengths change over time. Compare
              to your competitors to provide data-driven guidance for the entire marketing team.
              Click below to watch a quick video and learn everything about the subject line
              reports.
            </Text>
          ) : (
            <Text margin="0 0 8px 0">
              Improve creative briefs by showing how subject line lengths change over time. Click
              below to watch a quick video and learn everything about the subject lines reports.
            </Text>
          )}

          <Button
            as={Link}
            to="https://youtu.be/MIY80MVM9_g"
            target="_blank"
            variant="primary"
            css={{
              alignItems: 'center',
              display: 'inline-flex',
              fontSize: '12px',
              fontWeight: '500',
              justifyContent: 'center',
              lineHeight: '23px',
              height: '23px',
              margin: '0',
              padding: '0',
              width: '80px',
            }}
          >
            Watch now
          </Button>
        </Flex>

        <Box width="360px">
          <AverageSubjectLineLength />
        </Box>
      </Flex>

      <Flex justifyContent="space-between" marginBottom="40px">
        <Box width="360px">
          <MostPopularTerms />
        </Box>

        <Flex direction="column" justifyContent="center" padding="0 32px" width="360px">
          <Heading as="h4" fontSize="18px" lineHeight="24px" css={{ marginBottom: '8px' }}>
            Learn what’s popular
          </Heading>

          <Text>
            Track trending subject line terms and learn what matters to your audience to find
            relevant and useful testing variables. Can’t do that with a secret Gmail inbox!
          </Text>
        </Flex>
      </Flex>

      <Flex justifyContent="space-between" marginBottom="40px">
        <Flex direction="column" justifyContent="center" padding="0 32px" width="360px">
          <Heading as="h4" fontSize="18px" lineHeight="24px" css={{ marginBottom: '8px' }}>
            View interesting and significant terms for different time periods
          </Heading>

          <Text>
            Improve strategy by tracking how your brands’ or competitors’ subject line content has
            changed over months and years.
          </Text>
        </Flex>

        <Box width="360px">
          <WhatsTrending />
        </Box>
      </Flex>

      <Flex justifyContent="space-between" marginBottom="40px">
        <Box width="360px">
          <SubjectLinesByLength />
        </Box>

        <Flex direction="column" justifyContent="center" padding="0 32px" width="360px">
          <Heading as="h4" fontSize="18px" lineHeight="24px" css={{ marginBottom: '8px' }}>
            Search subject lines by length or type
          </Heading>

          <Text>
            Find inspiration fast for writing and testing stand-out subject lines and use inbox view
            to see what your subscribers see.
          </Text>
        </Flex>
      </Flex>

      <RestrictedUpgradeButton />
    </Body>
  </ReportSection>
);

Upgrade.defaultProps = {
  model: { id: null },
};

Upgrade.propTypes = {
  model: PropTypes.shape({ id: PropTypes.string, type: PropTypes.string }),
};

export default Upgrade;
