import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isNil } from 'ramda';

import { Box, Flex, Loader } from '../../mc-ui';
import EmailThumbnail from '../../ui/EmailThumbnail';

import ClassifiedBadge from './ClassifiedBadge';

const isPresent = val => !isNil(val) && !isEmpty(val);

const Thumbnails = ({ journey, loading }) => {
  if (loading) {
    return (
      <Flex alignItems="center" justifyContent="center" height="700px">
        <Loader />
      </Flex>
    );
  }

  const [brand] = journey.relationships.companies.data;
  const emails = journey.relationships.emails.data;

  return (
    <Flex wrap="wrap" zIndex="0">
      {emails.map((email, index) => (
        <Box
          key={email.id}
          marginBottom="52px"
          marginRight={(index + 1) % 4 === 0 ? '0' : '38px'}
          position="relative"
          width="auto"
        >
          {isPresent(email.attributes.classifications) && (
            <ClassifiedBadge classification={email.attributes.classifications[0]} />
          )}
          <EmailThumbnail brand={brand} email={email} />
        </Box>
      ))}
    </Flex>
  );
};

Thumbnails.defaultProps = {
  journey: {},
};

Thumbnails.propTypes = {
  journey: PropTypes.shape({
    attributes: PropTypes.shape({
      journeyType: PropTypes.string,
    }),
    relationships: PropTypes.shape({
      companies: PropTypes.shape({
        data: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            attributes: PropTypes.shape({
              name: PropTypes.string,
            }),
          }),
        ),
      }),
      emails: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape({})),
      }),
    }),
  }),
  loading: PropTypes.bool.isRequired,
};

export default Thumbnails;
