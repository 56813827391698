import styled from "@emotion/styled";
import { Link } from 'react-router';

const MenuLink = styled(Link)(({ theme, 'data-active': active }) => ({
  alignItems: 'center',
  backgroundColor: active ? theme.colors.dark004 : null,
  borderRadius: '4px',
  boxSizing: 'border-box',
  color: active ? theme.colors.dark1 : theme.colors.dark06,
  display: 'flex',
  fontFamily: '"Neue Helvetica W01", Helvetica, Arial, sans-serif',
  fontSize: '14px',
  fontWeight: '500',
  height: '32px',
  letterSpacing: '0.02em',
  marginBottom: '12px',
  padding: '0px 8px',
  textDecoration: 'none',
  width: '200px',

  '& > svg': {
    color: active ? theme.colors.blue : null,
    marginRight: '20px',
  },

  '&:hover': {
    backgroundColor: theme.colors.dark004,
    color: theme.colors.dark1,
  },
}));

export default MenuLink;
