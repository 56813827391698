import React from 'react';
import PropTypes from 'prop-types';
import styled from "@emotion/styled";

import { Flex } from '../../mc-ui';

const DEFAULT_STYLE = {
  backgroundColor: `#fff`,
  backgroundImage: `url(/loading.png)`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  height: '75vh',
  width: '100%',
};

const Img = styled("img")(({ loading, size }) => ({
  display: loading ? 'none' : 'block',
  margin: '0 auto',
  width: size === 'desktop' ? '100%' : null,
}));

class EmailAsImage extends React.Component {
  state = {
    loading: true,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.guid !== this.props.guid) {
      this.setState(() => ({ loading: true }));
    }
  }

  handleLoad = () => {
    setTimeout(() => {
      this.setState(() => ({ loading: false }));
    }, 250);
  };

  render() {
    const { guid, size, toggleZoom } = this.props;
    const loading = this.state.loading || this.props.loading;

    return (
      <Flex justifyContent="center" onClick={toggleZoom} css={loading ? {} : { cursor: 'zoom-in' }}>
        <Flex
          justifyContent="center"
          padding="0 5px"
          css={loading ? DEFAULT_STYLE : { width: '85%' }}
        >
          <Img
            alt="Email preview"
            loading={loading ? 1 : 0}
            onLoad={this.handleLoad}
            size={size}
            src={`//assets.mailcharts.com/emails/${guid}/${size}.png`}
          />
        </Flex>
      </Flex>
    );
  }
}

EmailAsImage.defaultProps = {
  size: '300x400',
};

EmailAsImage.propTypes = {
  guid: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  size: PropTypes.string,
  toggleZoom: PropTypes.func.isRequired,
};

export default EmailAsImage;
