import { assocPath } from 'ramda';

import * as types from './types';

export const INITIAL_STATE = {
  my: {
    data: {
      data: [],
    },
    error: null,
    loading: true,
  },
  team: {
    data: {
      data: [],
    },
    error: null,
    loading: true,
  },
};

const toggleMyLoadingReducer = state => assocPath(['my', 'loading'], !state.my.loading, state);

const setMyDataReducer = (state, { data }) =>
  assocPath(['my', 'data'], data, assocPath(['my', 'loading'], false, state));

const setMyErrorReducer = (state, { error }) =>
  assocPath(['my', 'error'], error, assocPath(['my', 'loading'], false, state));

const toggleTeamLoadingReducer = state =>
  assocPath(['team', 'loading'], !state.team.loading, state);

const setTeamDataReducer = (state, { data }) =>
  assocPath(['team', 'data'], data, assocPath(['team', 'loading'], false, state));

const setTeamErrorReducer = (state, { error }) =>
  assocPath(['team', 'error'], error, assocPath(['team', 'loading'], false, state));

const removeFromMyReducer = (state, { id }) => {
  const newData = (state.my.data.data || []).filter(j => j.id !== id);
  return assocPath(['my', 'data', 'data'], newData, state);
};

const removeFromTeamReducer = (state, { id }) => {
  const newData = (state.team.data.data || []).filter(j => j.id !== id);
  return assocPath(['team', 'data', 'data'], newData, state);
};

const reducer = {
  [types.FETCH_MY_SAVED_JOURNEYS_REQUEST]: toggleMyLoadingReducer,
  [types.FETCH_MY_SAVED_JOURNEYS_SUCCESS]: setMyDataReducer,
  [types.FETCH_MY_SAVED_JOURNEYS_FAILURE]: setMyErrorReducer,

  [types.SAVE_JOURNEY_TO_MY_LIST_REQUEST]: toggleMyLoadingReducer,
  [types.SAVE_JOURNEY_TO_MY_LIST_SUCCESS]: setMyDataReducer,
  [types.SAVE_JOURNEY_TO_MY_LIST_FAILURE]: setMyErrorReducer,

  [types.REMOVE_JOURNEY_FROM_MY_LIST_REQUEST]: removeFromMyReducer,
  [types.REMOVE_JOURNEY_FROM_MY_LIST_SUCCESS]: setMyDataReducer,
  [types.REMOVE_JOURNEY_FROM_MY_LIST_FAILURE]: setMyErrorReducer,

  [types.FETCH_TEAM_SAVED_JOURNEYS_REQUEST]: toggleTeamLoadingReducer,
  [types.FETCH_TEAM_SAVED_JOURNEYS_SUCCESS]: setTeamDataReducer,
  [types.FETCH_TEAM_SAVED_JOURNEYS_FAILURE]: setTeamErrorReducer,

  [types.SAVE_JOURNEY_TO_TEAM_LIST_REQUEST]: toggleTeamLoadingReducer,
  [types.SAVE_JOURNEY_TO_TEAM_LIST_SUCCESS]: setTeamDataReducer,
  [types.SAVE_JOURNEY_TO_TEAM_LIST_FAILURE]: setTeamErrorReducer,

  [types.REMOVE_JOURNEY_FROM_TEAM_LIST_REQUEST]: removeFromTeamReducer,
  [types.REMOVE_JOURNEY_FROM_TEAM_LIST_SUCCESS]: setTeamDataReducer,
  [types.REMOVE_JOURNEY_FROM_TEAM_LIST_FAILURE]: setTeamErrorReducer,
};

export default (state = INITIAL_STATE, action = {}) => {
  const reducerFunction = reducer[action.type];

  if (!reducerFunction) return state;
  return reducerFunction(state, action);
};
