const prefix = 'mc/newReports/timeSeries';

export const FETCH_DATA_FAILURE = `${prefix}/FETCH_DATA_FAILURE`;
export const FETCH_DATA_REQUEST = `${prefix}/FETCH_DATA_REQUEST`;
export const FETCH_DATA_SUCCESS = `${prefix}/FETCH_DATA_SUCCESS`;
export const TOGGLE_DATA_LOADING = `${prefix}/TOGGLE_DATA_LOADING`;

export const FETCH_BRANDS_FAILURE = `${prefix}/FETCH_BRANDS_FAILURE`;
export const FETCH_BRANDS_REQUEST = `${prefix}/FETCH_BRANDS_REQUEST`;
export const FETCH_BRANDS_SUCCESS = `${prefix}/FETCH_BRANDS_SUCCESS`;
export const TOGGLE_BRANDS_LOADING = `${prefix}/TOGGLE_BRANDS_LOADING`;

export const FETCH_COMPARISON_FAILURE = `${prefix}/FETCH_COMPARISON_FAILURE`;
export const FETCH_COMPARISON_REQUEST = `${prefix}/FETCH_COMPARISON_REQUEST`;
export const FETCH_COMPARISON_SUCCESS = `${prefix}/FETCH_COMPARISON_SUCCESS`;
export const TOGGLE_COMPARISON_LOADING = `${prefix}/TOGGLE_COMPARISON_LOADING`;

export const FETCH_HISTORICAL_TREND_FAILURE = `${prefix}/FETCH_HISTORICAL_TREND_FAILURE`;
export const FETCH_HISTORICAL_TREND_REQUEST = `${prefix}/FETCH_HISTORICAL_TREND_REQUEST`;
export const FETCH_HISTORICAL_TREND_SUCCESS = `${prefix}/FETCH_HISTORICAL_TREND_SUCCESS`;
export const TOGGLE_HISTORICAL_TREND_LOADING = `${prefix}/TOGGLE_HISTORICAL_TREND_LOADING`;
