import styled from "@emotion/styled";
import { Link } from 'react-router';

export const DropdownContainer = styled("div")(({ theme }) => ({
  backgroundColor: theme.colors.white,
  borderRadius: '4px',
  boxSizing: 'border-box',
  filter: 'drop-shadow(0px 1px 4px rgba(9, 30, 66, 0.2))',
  left: '0',
  maxHeight: '500px',
  padding: '0 4px',
  position: 'absolute',
  top: '5px',
  width: '100%',
  zIndex: '999999',
  '& > div': {
    boxSizing: 'border-box',
    maxHeight: '500px',
    overflowX: 'hidden',
    overflowY: 'scroll',
    padding: '8px 0',
    scrollbarWidth: 'thin',
    width: '100%',
    '&::-webkit-scrollbar': {
      WebkitAppearance: 'none',
      width: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '4px',
      backgroundColor: theme.colors.dark01,
    },
  },
}));

export const DropdownLink = styled(Link)(({ selected, theme }) => {
  const backgroundColor = selected ? theme.colors.dark6 : theme.colors.white;
  return {
    alignItems: 'center',
    backgroundColor,
    boxSizing: 'border-box',
    color: theme.colors.dark08,
    display: 'flex',
    flexWrap: 'nowrap',
    fontFamily: theme.font.family,
    fontSize: '14px',
    height: '32px',
    lineHeight: '32px',
    padding: '5px 12px',
    textDecoration: 'none',
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: theme.colors.dark6,
    },
  };
});

export const DropdownText = styled("p")(({ theme }) => ({
  alignItems: 'center',
  boxSizing: 'border-box',
  color: theme.colors.dark08,
  display: 'flex',
  fontFamily: theme.font.family,
  fontSize: '14px',
  height: '32px',
  lineHeight: '32px',
  padding: '5px 12px',
}));

export const DropdownWrapper = styled("div")({ maxHeight: '500px', position: 'relative' });

export const Input = styled("input")(({ active, theme }) => ({
  border: `1px solid transparent`,
  borderColor: active ? theme.colors.blueDark : theme.colors.dark5,
  borderRadius: 4,
  boxSizing: 'border-box',
  color: theme.colors.dark08,
  display: 'block',
  fontFamily: theme.font.family,
  fontSize: '14px',
  lineHeight: `${22 / 14}em`,
  margin: 0,
  outline: 'none',
  padding: `${4 / 14}em ${12 / 14}em`,
  paddingLeft: '40px',
  width: '100%',
  '&::placeholder': {
    color: theme.colors.dark04,
  },
}));

export const InputIconWrapper = styled("div")({
  height: '24px',
  left: '8px',
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  width: '24px',
});

export const InputWrapper = styled("div")({
  boxSizing: 'border-box',
  position: 'relative',
  width: '100%',
});

export const Wrapper = styled("div")({
  outline: 'none',
});
