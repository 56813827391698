import React from 'react';
import PropTypes from 'prop-types';

import AdjustIcon from '../mc-ui/icons/AdjustIcon';
import CollectionIcon from '../mc-ui/icons/CollectionIcon';
import OverviewIcon from '../mc-ui/icons/OverviewIcon';
import TimelineIcon from '../mc-ui/icons/TimelineIcon';
import CommentIcon from '../mc-ui/icons/CommentIcon';
import MobileIcon from '../mc-ui/icons/MobileIcon';
import { SubMenu } from '../mc-ui';

export default function CollectionsMenu({ collectionType }) {
  const prefix = `/${collectionType}`;

  return (
    <SubMenu>
      <SubMenu.Link to={`${prefix}-collections/email-lists`}>
        <CollectionIcon height="20px" width="20px" />
        Email Lists
      </SubMenu.Link>
      <SubMenu.Link to={`${prefix}-collections/text-message-lists`}>
        <MobileIcon height="20px" width="20px" />
        SMS Lists
      </SubMenu.Link>
      <SubMenu.Link to={`${prefix}-collections/groups`}>
        <OverviewIcon height="20px" width="20px" />
        Groups
      </SubMenu.Link>
      <SubMenu.Link to={`${prefix}-collections/saved-journeys`}>
        <TimelineIcon height="20px" width="20px" />
        Saved Journeys
      </SubMenu.Link>
      <SubMenu.Link to={`${prefix}-collections/saved-filters`}>
        <AdjustIcon height="20px" width="20px" />
        Saved Filters
      </SubMenu.Link>
      <SubMenu.Link to="/my-collections/notes">
        <CommentIcon height="20px" width="20px" />
        Notes
      </SubMenu.Link>
    </SubMenu>
  );
}

CollectionsMenu.propTypes = {
  collectionType: PropTypes.string.isRequired,
};
