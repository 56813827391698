import styled from "@emotion/styled";

const Input = styled("input")({
  border: '1px solid #d4d9de',
  borderRadius: '3px',
  boxSizing: 'border-box',
  color: '#242424',
  fontSize: '0.8125em',
  marginRight: '22px',
  outline: 'none',
  padding: '9px',
  width: '100%'
})

export default Input
