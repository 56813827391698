import * as React from 'react';

function CheckboxDefaultIcon(props) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x={3.5} y={3.5} width={17} height={17} rx={3.5} fill="#fff" stroke="#A5ADBA" />
    </svg>
  );
}

export default CheckboxDefaultIcon;
