import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class Loader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      status: this.props.loading === false ? 'complete' : 'initiate',
    };

    if (this.props.loading) {
      this.delay('loading', 25);
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (
      props.loading === this.props.loading &&
      props.error === this.props.error
    ) {
      return;
    }

    if (props.error) {
      this.setState({ status: 'error' });
      this.delay('complete', 500);
    } else if (props.loading) {
      this.setState({ status: 'initiate' });
      this.delay('loading');
    } else {
      this.setState({ status: 'loaded' });
      this.delay('complete');
    }
  }

  shouldComponentUpdate(props, state) {
    // 20180413 PAJ: if finished loading, always re-render.
    // This allows child components to receive updated props from parents above/outside the Loader
    if (
      this.state.status === 'loaded' ||
      this.state.status === 'complete'
    ) {
      return true;
    }

    return props.error ||
      props.loading !== this.props.loading ||
      state.status !== this.state.status;
  }

  delay(status = 'complete', ms = 260) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState({ status });
    }, ms);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  render() {
    const { status } = this.state;
    const { children, style } = this.props;

    const childOpacity = (status === 'complete' || status === 'error') ? 1 : 0.25;

    const wrapperStyle = (status !== 'complete')
      ? { ...style, position: 'relative' }
      : { position: 'relative' };

    return (
      <div style={wrapperStyle}>
        <div className={`loader-container ${status}`}>
          <div className="total" />
          <div className="progress" />
        </div>
        <div style={{ opacity: childOpacity }}>
          {children}
        </div>
      </div>
    );
  }
}

Loader.propTypes = {
  loading: PropTypes.bool.isRequired,
  children: PropTypes.node,
  error: PropTypes.bool,
  style: PropTypes.shape({}),
};

Loader.defaultProps = {
  children: null,
  error: false,
  style: {},
};

const mapStateToProps = () => ({});
export default connect(mapStateToProps)(Loader);
