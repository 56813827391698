import React from 'react';
import PropTypes from 'prop-types';

class Splash extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <div className="pure-g">
        <div style={{ textAlign: 'center' }} className="pure-u-1">
          <div
            style={{
              width: 300,
              height: 400,
              position: 'absolute',
              left: '50%',
              top: '50%',
              margin: '-200px 0 0 -150px',
              background: 'white',
              borderRadius: 3,
            }}
          >
            { children }
          </div>
        </div>
      </div>
    );
  };
}

Splash.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

export default Splash;