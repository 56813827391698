import * as React from 'react';
import PropTypes from 'prop-types';

function ArrowDownIcon({ fillOpacity, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.117 5h-2.32v10.563l-4.046-4.047a1.033 1.033 0 00-1.46 1.46l5.571 5.571a1.543 1.543 0 001.688.336c.188-.078.358-.192.502-.336l5.571-5.571a1.033 1.033 0 00-1.46-1.46l-4.046 4.047V5zm-1.032 11.595h-.256l.025.024a.145.145 0 00.206 0l.025-.024z"
        fill="currentColor"
        fillOpacity={fillOpacity}
      />
    </svg>
  );
}

ArrowDownIcon.propTypes = {
  fillOpacity: PropTypes.number,
};

ArrowDownIcon.defaultProps = {
  fillOpacity: 1,
};

export default ArrowDownIcon;
