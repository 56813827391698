import React from 'react';
import PropTypes from 'prop-types';

import { Flex } from '../mc-ui';

const MainWrapper = ({ children }) => (
  <Flex direction="column" css={{ width: '100%' }} data-identifier="main-wrapper">
    {children}
  </Flex>
);

MainWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MainWrapper;
