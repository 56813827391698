import { createSelector } from 'reselect';
import { pick } from 'ramda';

const getState = state => state.brands;

export const brandsSelector = createSelector(getState, pick(['data', 'included', 'links', 'meta']));
export const linksSelector = createSelector(getState, state => state.links);
export const loadingSelector = createSelector(getState, state => state.loading);
export const paginatingSelector = createSelector(getState, state => state.paginating);
export const paramsSelector = createSelector(getState, state => state.params || {});
