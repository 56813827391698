/* global URL */

import React from 'react'
import { connect } from 'react-redux'
import humanize from 'humanize-number'

import Tooltip from './ui/Tooltip'
import TrackCompany from './TrackCompany'
import EmailImage from './EmailImage'

class CompaniesList extends React.Component {
  renderEmails = (model) => {
    const { numberOfEmailsToShow } = this.props
    const emails = model.relationships.emails.data || []

    return emails.slice(0, (numberOfEmailsToShow || emails.length)).map((email, index) => {
      const width = index % 2 ? '40%' : '30%'
      const zIndex = index % 2 ? 1 : 0
      const height = 100
      const style = {
        width,
        height,
        zIndex,
        display: 'inline-block',
        overflow: 'hidden'
      }

      return (
        <div
          key={index}
          style={style}
        >
          <EmailImage guid={email.attributes.guid} style={{ width: '100%' }} />
        </div>
      )
    })
  }

  render () {
    const {
      companies,
      group,
      handleTracked,
      label,
      dispatch,
      router
    } = this.props


    return (
      <div className='pure-g gutters'>
        {companies.map((model, index) => {
          return (
            <div
              key={index}
              onClick={(e) => {
                e.preventDefault()
                const { tracking } = model.attributes
                if (label && !tracking) dispatch({ type: 'ADD_OR_REMOVE_GROUPS_COMPANIES', group, company: model })
                else router.push(`/${model.type}/${model.id}`)
              }}
              style={{
                cursor: 'pointer'
              }}
              className='pure-u-1 pure-u-md-1-2'
            >
              <div className='pure-u-1'>
                <div
                  style={{
                    border: '1px solid #D4D9DE',
                    marginBottom: 25,
                    borderRadius: 3,
                    background: '#fff',
                    padding: '25px 25px 0 25px'
                  }}
                >
                  <div className='pure-u-1 pure-u-md-3-4'>
                    <div className='pure-u-1-8'>
                      <img
                        onError={(err) => {
                          // only make the replacement one time
                          if (!/defaults/.test(err.target.src)) {
                            err.target.src = `//cache.mailcharts.com/logos/v2/defaults/${model.id % 125}.png`
                          }
                        }}
                        src={`//cache.mailcharts.com/logos/v2/companies/${model.id}.png`}
                        className='pure-img'
                      />
                    </div>
                    <div className='pure-u-1-24' />
                    <div className='pure-u-20-24'>
                      <h4 style={{ margin: 0 }}>{model.attributes.name}</h4>
                      {model.attributes.valid
                        ? <p
                          style={{ fontSize: `${12 / 16}em`, color: '#A0AEBA', margin: 0 }}
                        >
                          {model.attributes.domain_name
                            ? new URL(`https://${model.attributes.domain_name}`).hostname
                            : ''
                          }
                          &nbsp;|&nbsp;
                          {humanize(model.attributes.approximate_emails || 0)} emails
                        </p>
                        : <div>
                          <p
                            style={{ fontSize: `${12 / 16}em`, color: '#A0AEBA', margin: 0 }}
                          >
                            {model.attributes.domain_name
                              ? new URL(`https://${model.attributes.domain_name}`).hostname
                              : ''
                            }
                          </p>
                          <p
                            style={{
                              fontSize: `${12 / 16}em`,
                              color: '#A0AEBA',
                              margin: 0,
                              marginRight: 5,
                              display: 'inline-block'
                            }}
                          >
                            No user journeys available.
                          </p>
                          <Tooltip>
                            <p
                              style={{ fontSize: `${14 / 16}em` }}
                            >
                              Our team was not able to find any way to sign up for {model.attributes.name} emails.
                            </p>
                            <p
                              style={{ fontSize: `${14 / 16}em` }}
                            >
                              <a
                                href='https://www.mailcharts.com/companies'
                                onClick={(e) => e.stopPropagation()}
                                target='_blank'
                                className='pure-button'
                              >
                                Learn more
                              </a>
                            </p>
                          </Tooltip>
                        </div>
                      }
                    </div>
                  </div>
                  <div className='pure-u-1 pure-u-md-1-4' style={{ textAlign: 'right' }}>
                    {(group && group.attributes.global) || !label
                      ? null
                      : <TrackCompany
                        group={group}
                        label={label}
                        handleTracked={handleTracked}
                        company={model}
                      />
                    }
                  </div>
                  <div style={{ marginTop: 10, minHeight: 102 }}>
                    {this.renderEmails(model)}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

const s = (state) => ({ user: state.user })
export default connect(s)(CompaniesList)
