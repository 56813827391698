import * as React from 'react';
import PropTypes from 'prop-types';

function VerticalDotsIcon({ fillOpacity, ...props }) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 6a2 2 0 11-4 0 2 2 0 014 0zm0 6a2 2 0 11-4 0 2 2 0 014 0zm-2 8a2 2 0 100-4 2 2 0 000 4z"
        fill="currentColor"
        fillOpacity={fillOpacity}
      />
    </svg>
  );
}

VerticalDotsIcon.propTypes = {
  fillOpacity: PropTypes.number,
};

VerticalDotsIcon.defaultProps = {
  fillOpacity: 1,
};

export default VerticalDotsIcon;
