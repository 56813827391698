import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isNil } from 'ramda';

import { Flex, Text } from '../../../mc-ui';

const isNotPresent = value => isEmpty(value) || isNil(value);

export default function CreditCardInfo({ paymentInfo, ...props }) {
  if (isNotPresent(paymentInfo.card)) return null;

  const type = paymentInfo.card.brand.toLowerCase();
  const icon = ['amex', 'discover', 'mastercard', 'visa'].includes(type) ? type : 'credit';

  const { last4, exp_year: expYear } = paymentInfo.card;

  const expMonth =
    paymentInfo.card.exp_month < 10
      ? `0${paymentInfo.card.exp_month}`
      : paymentInfo.card.exp_month;

  return (
    <Flex alignItems="center" marginBottom="24px" {...props}>
      <img
        src={`/images/credit-cards/${icon}.png`}
        alt={paymentInfo.card.brand}
        style={{
          height: '32px',
          marginRight: '8px',
        }}
      />
      <Text>
        ******
        {last4} exp. {expMonth}/{expYear}
      </Text>
    </Flex>
  );
}

CreditCardInfo.propTypes = {
  subscription: PropTypes.shape({
    attributes: PropTypes.shape({
      stripe_card: PropTypes.shape({
        exp_month: PropTypes.number,
        exp_year: PropTypes.number,
        last4: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        type: PropTypes.string,
      }),
    }),
  }),
};
