import styled from "@emotion/styled";

const AddToListFormWrapper = styled("div")({
  boxSizing: 'border-box'
}, ({ fluidSize }) => {
  const styles = {
    paddingLeft: fluidSize ? '1em' : '0',
    paddingRight: fluidSize ? '1em' : '0',
    width: fluidSize ? '100%' : '50%'
  }

  if (!fluidSize) {
    styles.maxHeight = '600px'
    styles.overflowX = 'auto'
  }

  return styles
})

export default AddToListFormWrapper
