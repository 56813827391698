import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';

import Header from './Header';
import Section from './Section';
import TechnologyInformation from './TechnologyInformation';
import { UpgradeTechnologies } from './Upgrade';

class Technologies extends React.Component {
  componentDidMount() {
    this.request();
  }

  UNSAFE_componentWillReceiveProps() {
    this.request();
  }

  componentWillUnmount() {
    this.props.flush();
  }

  query = {};

  request = () => {
    const {
      requestTechnologies,
      router: { location, params },
    } = this.props;

    const technologyId = [].concat(location.query.technology_id || [], params.technology_id || []);

    const query = {
      ...location.query,
      ...params,
      technology_id: technologyId,
    };

    if (JSON.stringify(query) !== JSON.stringify(this.query)) {
      this.query = query;
      requestTechnologies(params.technology_id, query);
    }
  };

  render() {
    const { children, technologies } = this.props;

    const user = this.props.user.data.reduce((m, u) => u, {});
    const accessible = !user.attributes.restricted;
    const technology = technologies.data.reduce((m, g) => g, {});

    if (technologies.loading) {
      return null;
    }

    return (
      <div className="pure-u-1">
        <Header>
          <TechnologyInformation {...this.props} />
        </Header>
        <Section>
          {accessible ? (
            React.Children.map(children, child => React.cloneElement(child, { model: technology }))
          ) : (
            <UpgradeTechnologies />
          )}
        </Section>
      </div>
    );
  }
}

Technologies.propTypes = {
  children: PropTypes.node.isRequired,
  flush: PropTypes.func.isRequired,
  requestTechnologies: PropTypes.func.isRequired,
  router: PropTypes.shape({
    location: PropTypes.shape({}),
    params: PropTypes.shape({}),
  }).isRequired,
  technologies: PropTypes.shape({}).isRequired,
  user: PropTypes.shape({
    data: PropTypes.array,
  }).isRequired,
};

const mapStateToProps = state => ({
  technologies: state.technologies,
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  flush: () => dispatch({ type: 'FLUSH' }),
  requestTechnologies: (technologyId, query) =>
    dispatch({ type: 'REQUEST_TECHNOLOGIES', technology_id: technologyId, query }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Technologies);
