/* global window */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

const HIDDEN = {
  height: 'calc(100vh - 60px)',
  opacity: '0',
  transition: 'opacity .5s linear',
  visibility: 'hidden',
};

const VISIBLE = {
  opacity: '1',
  transition: 'opacity .5s linear',
  visibility: 'visible',
};

class Demo extends Component {
  state = {
    css: HIDDEN,
  };

  loaded = () => {
    window.setTimeout(() => {
      this.setState({ css: VISIBLE });
    }, 500);
  }

  render() {
    const { children } = this.props;
    const { css } = this.state;

    return (
      <div className="pure-u-1" style={css}>
        {React.Children.map(children, child => (
          React.cloneElement(child, { loaded: this.loaded })
        ))}
      </div>
    );
  }
}

Demo.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]).isRequired,
};

export default Demo;
