import React from 'react';
import PropTypes from 'prop-types';

import { Flex, Tab } from '../../../../mc-ui';

import ReportSection from '../../../ReportSection';
import Title from '../../../Title';

import Overview from './Overview';
import CompareWithBrands from './CompareWithBrands';

const ByLength = ({ model, ...props }) => (
  <Flex>
    <ReportSection>
      <Title css={{ marginBottom: '24px' }}>Subject Lines by Length</Title>

      {model.type === 'groups' ? (
        <Tab defaultActive="overview">
          <Tab.Header>
            <Tab.Item name="overview">Group Overview</Tab.Item>
            <Tab.Item name="compareWithBrands">Compare with Brands</Tab.Item>
          </Tab.Header>
          <Tab.Body>
            {({ active }) => {
              switch (active) {
                case 'overview': {
                  return <Overview model={model} {...props} />;
                }
                case 'compareWithBrands': {
                  return <CompareWithBrands model={model} {...props} />;
                }
                default: {
                  return null;
                }
              }
            }}
          </Tab.Body>
        </Tab>
      ) : (
        <Overview model={model} {...props} />
      )}
    </ReportSection>
  </Flex>
);

ByLength.propTypes = {
  model: PropTypes.shape({ id: PropTypes.string, type: PropTypes.string }).isRequired,
};

export default ByLength;
