import React from 'react'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import url from 'url'
import querystring from 'querystring'
import humanize from 'humanize-number'
import api from '../lib/api'

import Container from './Container'
import CompaniesList from './CompaniesList'
import GroupsList from './GroupsList'

class ManageCompanies extends React.Component {
  render() {
    let companies = this.props.user.included.filter(i => i.type == 'companies')
    let user = this.props.user.data.reduce((m, u) => u, {})
    let group = this.props.user.included.filter(i => i.type == 'groups' && i.attributes.tracked).reduce((m, g) => g, {})
    return this.props.user.loading ? null :
      (<div className='pure-u-1' style={{ margin: '2em 0' }}>
        <p>You are currently tracking {user.attributes.tracked_companies} brands.</p>
        <CompaniesList
          companies={companies}
          group={group}
          label='Untrack'
          router={this.props.router}
        />
      </div>
    )
  }
}

const s = (state) => ({ user: state.user })
export default connect(s)(ManageCompanies)
