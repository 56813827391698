import React from 'react'

const BrowseAbandoner = props => (
  <svg viewBox='0 0 40 40' width='1em' height='1em' {...props}>
    <g fill='none' fillRule='evenodd'>
      <circle fill='#9E6ACC' cx={20} cy={20} r={20} />
      <g
        transform='translate(10 10)'
        stroke='#FFF'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={1.6}
      >
        <circle cx={8} cy={8} r={8} />
        <path d='M5 5l6 6M11 5l-6 6M14.5 13.5l5.385 5.385' />
      </g>
    </g>
  </svg>
)

export default BrowseAbandoner
