import React from 'react';

import ReportSection from '../../ReportSection';

import ReportHighlights from './ReportHighlights';
import Upgrade from './Upgrade';

const Restricted = props => (
  <ReportSection css={{ padding: '0' }}>
    <ReportHighlights {...props} />
    <Upgrade {...props} />
  </ReportSection>
);

export default Restricted;
