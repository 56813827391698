import React from 'react';
import styled from "@emotion/styled";
import { isEmpty } from 'ramda';

import BrandLogo from '../../../ui/BrandLogo';
import GroupBrandsLogo from './GroupBrandsLogo';

export const formatType = type =>
  type
    .split('_')
    .map(word => `${word.charAt(0).toUpperCase()}${word.slice(1)}`)
    .join(' ');

const Img = styled("img")({
  borderRadius: '4px',
  height: '20px',
  width: '20px',
});

export default function buildContext({ email, router }) {
  if (email.loading && isEmpty(email.data)) return { loading: true };

  const [model] = email.data;
  const [brand] = (((model || {}).relationships || {}).companies || { data: [] }).data;

  if (!brand) return { loading: true };

  const { group_id: groupId, journey_id: journeyId, list_id: listId } = router.location.query;

  const context = {
    approximateEmails: brand.attributes.approximate_emails,
    backLabel: 'Back to Brand',
    Logo: () => <BrandLogo brand={brand} css={{ height: '20px', width: '20px' }} />,
    text: brand.attributes.name,
    to: { pathname: `/companies/${brand.id}/emails` },
    type: 'companies',
  };

  if (groupId) {
    const group = model.relationships.groups.data.find(j => j.id === groupId);
    if (group) {
      context.approximateCompanies = group.attributes.approximate_companies;
      context.backLabel = 'Back to Group';
      context.Logo = () => <GroupBrandsLogo group={group} />;
      context.text = group.attributes.name;
      context.to = { pathname: `/groups/${group.id}` };
      context.type = 'groups';
    }
  } else if (journeyId) {
    const journey = model.relationships.journeys.data.find(j => j.id === journeyId);
    if (journey) {
      context.approximateEmails = journey.attributes.approximate_total_emails;
      context.backLabel = `Back to ${brand.attributes.name}'s Journey`;
      context.text = formatType(journey.attributes.type);
      context.to = { pathname: `/companies/${brand.id}/journeys/${journey.id}` };
      context.type = 'journeys';
    }
  } else if (listId) {
    const list = model.relationships.lists.data.find(l => l.id === listId);
    if (list) {
      context.approximateEmails = list.attributes.approximate_emails;
      context.backLabel = 'Back to Email List';
      context.Logo = () => (
        <Img src={`//cache.mailcharts.com/logos/v2/lists/${list.id % 126}.png`} />
      );
      context.text = list.attributes.name;
      context.to = { pathname: `/lists/${list.id}` };
      context.type = 'lists';
    }
  }

  return context;
}
