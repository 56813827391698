import styled from "@emotion/styled";

const Box = styled("div")(({ disabled, theme, css = {} }) => ({
  alignItems: 'center',
  boxSizing: 'border-box',
  display: 'flex',
  marginBottom: 10,
  overflow: 'hidden',
  position: 'relative',
  ...css,
  '& > input[type="checkbox"]': {
    height: 1,
    left: -100,
    opacity: 0,
    position: 'absolute',
    width: 1,
    '& + label': {
      color: disabled ? theme.colors.dark04 : theme.colors.dark08,
      cursor: disabled ? 'not-allowed' : 'pointer',
      lineHeight: `${22 / 14}em`,
      margin: 0,
      marginLeft: '28px',
      padding: 0,
      position: 'relative',
      '&:before': {
        background: disabled ? theme.colors.dark004 : theme.colors.white,
        border: `1px solid ${theme.colors.dark4}`,
        borderRadius: 4,
        boxSizing: 'border-box',
        content: '""',
        height: 18,
        left: -28,
        position: 'absolute',
        top: 2,
        width: 18,
      },
      '&:after': {
        background: 'transparent',
        border: '2px solid transparent',
        borderRight: 'none',
        borderTop: 'none',
        content: '""',
        height: '4px',
        left: -24,
        opacity: '1',
        position: 'absolute',
        top: 7,
        transform: 'rotate(-45deg)',
        width: '8px',
      },
    },
    '&:checked': {
      '& + label': {
        '&:before': {
          background: theme.colors.blueLight,
          border: `2px solid ${theme.colors.blue}`,
        },
        '&:after': {
          borderBottomColor: theme.colors.blue,
          borderLeftColor: theme.colors.blue,
        },
      },
    },
  },
}));

export default Box;
