const INITIAL_STATE = { loading: false, data: [], links: {} }
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'REQUEST_COMPANIES_REQUESTED': return { ...INITIAL_STATE, loading: true }
    case 'RECEIVE_COMPANIES_REQUESTED': return { ...state, ...action.data, loading: false }
    default: return state
  }
}


