import React, { useEffect, useState } from 'react'


import { teamsActions, teamsSelectors } from '../../../../modules/teams';
import { Button, Heading, Loader, Modal, Text } from '../../../mc-ui';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { connect } from 'react-redux';
import api from '../../../../lib/api';

import StripeCheckoutForm from '../StripeCheckoutForm';

const CreditCardUpdateModal = ({isOpen, closeModal, subscription, getPaymentInfoRequest, paymentInfo, setupIntent, updateCreditCard}) => {

    const stripe = useStripe();
    const elements = useElements();
    const [submitting,setSubmitting] = useState(false);
    const [attachedToCustomer,setAttachedToCustomer] = useState(false);

    useEffect(() => {
      getPaymentInfoRequest(subscription.attributes.stripe_subscription_id, setupIntent);
    },[])

    const handleSubmit = async () => {
      try{

        setSubmitting(true);

        if(!attachedToCustomer){
          await api.post('/v3/stripe/setup-payment-intent/attach-customer',{
            setup_intent_id: setupIntent.id,
            customer_id: subscription.attributes.stripe_customer_id
          });

          setAttachedToCustomer(true)
        }
        const return_url = `${location.origin}/manage/plan`;

        const {error} = await stripe.confirmSetup({
          //`Elements` instance that was used to create the Payment Element
          elements,
          confirmParams: {
            return_url,
          },
          redirect: 'if_required'
        });


        if(error){
          setSubmitting(false);
          return;
        }

        console.log(setupIntent.id)
        const payload = {
          setup_intent_id: setupIntent.id
        };

        updateCreditCard(payload,successSubmit)

      } catch (error) {
        setSubmitting(false);
      }
    }

    const successSubmit = () => {
      setSubmitting(false)
      getPaymentInfoRequest(subscription.attributes.stripe_subscription_id, setupIntent)
      closeModal();
    }

    return (
        <Modal isOpen={isOpen} onClose={closeModal}>
            <Modal.Body maxHeight="800px" width="1000px">
                <Heading
                 as="h2"
                 fontSize="24px"
                 fontWeight="300"
                 lineHeight="1.32em"
                 css={{ marginBottom: '8px' }}
                >
                    Update your Credit Card
                </Heading>

                {paymentInfo &&
                paymentInfo.card &&
                paymentInfo.card.last4 &&
                <Text fontWeight="bold" css={{ marginBottom: '4px' }}>
                  Card Type: { paymentInfo.card.brand.toUpperCase() }
                  <br />
                  Your current card ends with: { paymentInfo.card.last4 }
                </Text>}

                <StripeCheckoutForm />
            </Modal.Body>

            <Modal.Footer justifyContent="center">
                {submitting ? <Loader /> :
                <div>
                  <Button
                  css={{
                      height: '40px',
                      lineHeight: '40px',
                      marginRight: '8px',
                      padding: 0,
                      width: '88px',
                  }}
                  onClick={handleSubmit}
                  variant="primary"
                  >
                  Confirm
                  </Button>

                  <Button
                  css={{ height: '40px', lineHeight: '40px', padding: 0, width: '80px' }}
                  onClick={closeModal}
                  variant="secondary"
                  >
                  Cancel
                  </Button>
                </div>}
            </Modal.Footer>
        </Modal>
    );
}

const mapStateToProps = state => ({
    subscription: teamsSelectors.subscriptionSelector(state),
    paymentInfo: teamsSelectors.getPaymentInfo(state),
    setupIntent: teamsSelectors.getSetupIntent(state)
  });

const mapDispatchToProps = dispatch => ({
    getPaymentInfoRequest: (subscriptionId) => dispatch(teamsActions.getPaymentInfoRequest(subscriptionId)),
    updateCreditCard: (payload,callback) => dispatch(teamsActions.subscriptionUpdateCreditCardRequest(payload,callback)),
  });

export default connect(mapStateToProps,mapDispatchToProps)(CreditCardUpdateModal)