import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { benchmarksSelectors as selectors } from '../../../modules/newReports/benchmarks';

import { Flex, Loader, Table } from '../../mc-ui';

import ReportSection from '../ReportSection';
import Title from '../Title';

const name = (data, type) => {
  // eslint-disable-next-line no-unused-vars
  const [obj, ..._] = data.filter(d => d[type]);
  return obj.name || 'Mailcharts Index';
};

const MostPopularSendDay = ({ data, loading }) => (
  <Flex>
    <ReportSection css={{ width: '407px', minHeight: '180px' }}>
      <ReportSection.Header>
        <Title>Most Popular Send Day</Title>
      </ReportSection.Header>
      <ReportSection.Body>
        {loading ? (
          <Flex alignItems="center" justifyContent="center" css={{ height: '45px' }}>
            <Loader />
          </Flex>
        ) : (
          <Table css={{ width: '343px' }}>
            <Table.Body>
              <Table.Row>
                <Table.Cell color="blue">{name(data.sent_at_days_of_week, 'primary')}</Table.Cell>
                <Table.Cell align="right" color="blue">
                  {data.sent_at_days_of_week
                    .filter(d => d.primary)
                    .map(({ label }) => label.substr(0, 3).toUpperCase())
                    .join(', ')}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>{name(data.sent_at_days_of_week, 'comparison')}</Table.Cell>
                <Table.Cell align="right">
                  {data.sent_at_days_of_week
                    .filter(d => d.comparison)
                    .map(({ label }) => label.substr(0, 3).toUpperCase())
                    .join(', ')}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        )}
      </ReportSection.Body>
    </ReportSection>
  </Flex>
);

MostPopularSendDay.propTypes = {
  data: PropTypes.shape({
    sent_at_days_of_week: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, { model, brandId }) => {
  if (brandId && model.id && model.type === 'groups') {
    return {
      data: selectors.getGroupComparisonData(model.id, brandId)(state),
      loading: selectors.getGroupComparisonLoading(model.id, brandId)(state),
    };
  }

  if (model.id && model.type === 'companies') {
    return {
      data: selectors.getBrandComparisonData(model.id)(state),
      loading: selectors.getBrandComparisonLoading(model.id)(state),
    };
  }

  return {
    data: { sent_at_days_of_week: [] },
    loading: true,
  };
};

export default connect(mapStateToProps)(MostPopularSendDay);
