import styled from "@emotion/styled";

const Grid = styled("div")(({ theme, ...props }) => ({
  alignContent: props.alignContent,
  alignItems: props.alignItems,
  alignSelf: props.alignSelf,
  backgroundColor: props.backgroundColor ? theme.colors[props.backgroundColor] : null,
  borderColor: props.borderColor ? theme.colors[props.borderColor] : null,
  boxSizing: 'border-box',
  color: props.color ? theme.colors[props.color] : null,
  columnGap: props.columnGap,
  display: props.inline ? 'inline-grid' : 'grid',
  gap: props.gap,
  grid: props.grid,
  gridArea: props.gridArea,
  gridAutoColumns: props.gridAutoColumns,
  gridAutoFlow: props.gridAutoFlow,
  gridAutoRows: props.gridAutoRows,
  gridColumn: props.gridColumn,
  gridColumnEnd: props.gridColumnEnd,
  gridColumnGap: props.gridColumnGap,
  gridColumnStart: props.gridColumnStart,
  gridGap: props.gap,
  gridRow: props.gridRow,
  gridRowEnd: props.gridRowEnd,
  gridRowGap: props.gridRowGap,
  gridRowStart: props.gridRowStart,
  gridTemplate: props.gridTemplate,
  gridTemplateAreas: props.gridTemplateAreas,
  gridTemplateColumns: props.gridTemplateColumns,
  gridTemplateRows: props.gridTemplateRows,
  height: props.height || null,
  justifyContent: props.justifyContent,
  justifyItems: props.justifyItems,
  justifySelf: props.justifySelf,
  placeContent: props.placeContent,
  placeItems: props.placeItems,
  placeSelf: props.placeSelf,
  rowGap: props.rowGap,
  width: props.width || '100%',
  ...props.css,
  ...props.style,
}));

export default Grid;
