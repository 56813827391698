/* eslint-disable react/prop-types */
import React from 'react';
import { components } from 'react-select';
import styled from "@emotion/styled";

import { Text } from '../../mc-ui';

const StyledText = styled(Text)(({ theme }) => ({
  color: theme.colors.dark08,
  fontSize: '14px',
  lineHeight: '156%',
}));

const SingleValueType = ({ children, ...props }) => (
  <components.SingleValue {...props}>
    <StyledText>
      <strong>Type:</strong> {children}
    </StyledText>
  </components.SingleValue>
);

export default SingleValueType;
