/* global document */
import 'babel-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { ThemeProvider, theme } from './components/mc-ui';

import Routes from './Routes';
import store from './store';
import { Auth0Provider } from '@auth0/auth0-react';

const ENV = (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging' || process.env.NODE_ENV === 'pr') ? process.env.NODE_ENV : 'development';

if (ENV === 'development') {
  // eslint-disable-next-line global-require
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React, { trackAllPureComponents: true });
}

const auth0ClientId = (ENV === 'production') ? 'iu9v2MrVxxHP6qU714aQVOKsmO7KmskX'
  : (ENV === 'staging') ? 'QCOhZAxZWBvyimjr7HoQqhTd1BGEX74D'
    : 'Lv1SGLuOOwUTw2NrCbfIpMA5F6c9flui';
const auth0Domain = ENV === 'production' ? 'login.validity.com' : 'validity-dev.auth0.com';
const redirectURIs = {
  production: 'https://app.mailcharts.com/signingin',
  staging: 'https://staging-app.mailcharts.com/signingin',
  pr: 'https://pr-app.mailcharts.com/signingin',
  development: 'http://localhost:8001/signingin',
}
const redirectURI = redirectURIs[ENV];

ReactDOM.render(
  <Provider store={store}>
      <Auth0Provider
        domain={auth0Domain}
        clientId={auth0ClientId}
        redirectUri={redirectURI}
      >
      <ThemeProvider theme={theme}>{Routes}</ThemeProvider>
    </Auth0Provider>
  </Provider>,
  document.getElementById('app'),
);
