import * as React from 'react';
import PropTypes from 'prop-types';

function GridIcon({ fillOpacity, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fill="currentColor"
        d="M3 7h4v4H3zM10 7h4v4h-4zM17 7h4v4h-4zM3 14h4v4H3zM10 14h4v4h-4zM17 14h4v4h-4z"
        fillOpacity={fillOpacity}
      />
    </svg>
  );
}

GridIcon.propTypes = {
  fillOpacity: PropTypes.number,
};

GridIcon.defaultProps = {
  fillOpacity: 1,
};

export default GridIcon;
