import React from 'react';

const SvgSalesAndCxTeams = props => (
  <svg width="1em" height="1em" viewBox="0 0 152 107" fill="none" {...props}>
    <path
      d="M51.66 3.438v7.81a2.3 2.3 0 002.3 2.3h1.34v3.73c0 .31.36.48.6.29l4.98-4.02h8.68a2.3 2.3 0 002.3-2.3v-7.81a2.3 2.3 0 00-2.3-2.3H53.95c-1.27 0-2.29 1.03-2.29 2.3z"
      fill="#fff"
      stroke="#A5ADBA"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.02 79.928a3 3 0 100-6 3 3 0 000 6z"
      fill="#fff"
      stroke="#F2222F"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.62 17.168a3 3 0 100-6 3 3 0 000 6z"
      fill="#fff"
      stroke="#9E6ACC"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M147.3 61.198a3 3 0 100-6 3 3 0 000 6z"
      fill="#fff"
      stroke="#027AD7"
      strokeWidth={1.5}
      strokeMiterlimit={10}
    />
    <path
      d="M42.69 49.608H30.42c-1.89 0-3.41-1.53-3.41-3.41v-6.74c0-1.89 1.53-3.41 3.41-3.41h12.27c1.89 0 3.41 1.53 3.41 3.41v6.74c0 1.88-1.53 3.41-3.41 3.41z"
      fill="#fff"
      stroke="#A1AEBA"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path d="M30.07 40.088l6.43 3.92 6.53-3.92" fill="#fff" />
    <path
      d="M30.07 40.088l6.43 3.92 6.53-3.92M7 38.258h7.24M18.46 38.258h3.62M16.5 48.108h5.58M13.84 43.288h8.24"
      stroke="#A1AEBA"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M89.22 4.218c.02-.16.04-.33.07-.49.08-.59.31-1.12.65-1.62.23-.33.51-.6.81-.85a1.11 1.11 0 011.5.07c.47.46.92.92 1.39 1.38l.8.8c.48.47.51 1.14.05 1.64-.29.32-.6.61-.9.91-.08.08-.18.16-.25.23 1.1 2.26 2.76 3.92 5.02 5.02.24-.24.49-.5.74-.75.13-.13.25-.26.38-.38.39-.36.89-.45 1.36-.19.18.1.33.26.48.41.66.65 1.31 1.31 1.97 1.97.19.19.28.41.36.66v.32c-.06.15-.09.31-.17.43-.27.4-.61.73-1.01.99-.73.49-1.53.7-2.41.61-.94-.11-1.84-.38-2.7-.77-.68-.31-1.31-.68-1.92-1.11-.76-.53-1.48-1.12-2.14-1.78-.64-.63-1.26-1.29-1.83-1.99-.65-.8-1.24-1.63-1.66-2.57-.24-.55-.43-1.11-.51-1.7-.02-.17-.05-.33-.07-.5-.01-.25-.01-.49-.01-.74z"
      fill="#fff"
      stroke="#A1AEBA"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path d="M98.31 4.298s2.23.15 2.45 2.45l-2.45-2.45z" fill="#fff" />
    <path
      d="M98.31 4.298s2.23.15 2.45 2.45"
      stroke="#A1AEBA"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path d="M98.31 1.418s4.75.22 5.04 5.62l-5.04-5.62z" fill="#fff" />
    <path
      d="M98.31 1.418s4.75.22 5.04 5.62"
      stroke="#A1AEBA"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M115.07 46.168a9.16 9.16 0 100-18.32 9.16 9.16 0 000 18.32z"
      fill="#fff"
      stroke="#A1AEBA"
      strokeWidth={1.5}
      strokeMiterlimit={10}
    />
    <path
      d="M115.01 33.488v4.47M116.61 36.488l-1.56 1.55-1.63-1.63M112.67 39.788h4.81"
      stroke="#80C963"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M65.46 59.846c1.746 5.394 3.47 10.744 5.207 16.094h.132c.208-.55.439-1.087.604-1.648.604-2.142 1.428-4.218 2.24-6.295.144-.351.11-.604-.109-.9-.747-1-1.307-2.11-1.68-3.296a4.924 4.924 0 01-.231-1.132c-.077-1.252.505-2.164 1.68-2.604 1.44-.527 2.857-.428 4.23.231a2.3 2.3 0 011.296 2.406c-.153 1.209-.692 2.274-1.252 3.329-.253.483-.56.956-.791 1.45a.636.636 0 00-.022.45c.89 2.56 1.802 5.109 2.714 7.669.043.11.098.22.22.461.263-.681.406-.879.428-1.692.142-4.328 1.494-8.481 3.823-12.15 1.307-2.066.373-2.725 1.845-1.956a425.831 425.831 0 019.075 4.91c1.032.572 2 1.242 2.999 1.868 2.736 1.714 4.175 4.263 4.614 7.394.132.945.143 1.912.176 2.878a.978.978 0 01-.242.638A3351.367 3351.367 0 0191.09 89.244a1.26 1.26 0 01-1.802-.022c-1.857-1.911-6.185-6.306-8.591-8.613-2.56-2.46-2.988-2.263-6.372-2.197-.923.022-1.736.374-2.406.978-1.461 1.318-2.911 2.647-4.317 4.032-2.538 2.483-5.032 5.01-7.537 7.525-.22.22-.527.352-.846.352-3.68-.011-7.35-.011-11.03 0-.461 0-.56-.143-.549-.583.088-4.91.154-9.821.23-14.732.034-2.021.341-3.977 1.45-5.735.902-1.439 2.187-2.482 3.571-3.405 3.483-2.33 7.218-4.23 10.91-6.196.45-.242.911-.462 1.373-.681.044-.055.099-.066.285-.121z"
      fill="#E2F2FF"
      stroke="#027AD7"
      strokeWidth={1.5}
      strokeMiterlimit={10}
    />
    <path
      d="M62.23 38.214c0-2.208.098-4.405.68-6.547 1.385-5.065 4.758-8.02 9.877-8.899 3.56-.615 6.965 0 10.008 2.032 2.692 1.802 4.131 4.46 4.812 7.57.648 2.933.626 5.91.396 8.887-.21 2.758-.747 5.428-1.967 7.943-1.538 3.175-3.91 5.394-7.371 6.328a7.34 7.34 0 01-1.692.264c-1.22.044-2.45.099-3.67-.011-2.845-.242-5.207-1.527-7.085-3.658-1.923-2.187-2.879-4.812-3.44-7.614-.406-2.087-.56-4.185-.548-6.295z"
      fill="#fff"
      stroke="#027AD7"
      strokeWidth={1.5}
      strokeMiterlimit={10}
    />
    <path
      d="M90.178 98.813c7.36-7.371 14.688-14.7 22.06-22.082-.099-.11-.242-.263-.385-.417-.934-.989-1.889-1.967-2.79-2.988-.615-.692-.308-1.538.604-1.791.132-.033.275-.055.417-.066 3.395-.154 6.779-.33 10.173-.462 1.539-.065 1.791.187 1.736 1.714a583.84 583.84 0 01-.417 9.844 2.634 2.634 0 01-.33 1.142c-.34.605-.967.736-1.494.286-.923-.802-1.802-1.648-2.691-2.494-.286-.274-.539-.593-.835-.934-.231.22-.374.352-.517.484-5.855 5.833-11.7 11.667-17.555 17.49a672.898 672.898 0 01-6.372 6.239c-.89.868-2.099.901-2.977.033-3.263-3.186-6.493-6.382-9.745-9.59-1.044-1.033-2.076-2.088-3.12-3.12-.099-.1-.198-.187-.33-.308-.142.132-.274.253-.406.384-2.988 2.956-5.965 5.911-8.954 8.855a20.35 20.35 0 01-1.46 1.286c-.803.637-1.77.67-2.572.033-.758-.616-1.549-1.264-1.483-2.384.022-.396.165-.846.407-1.143a55.059 55.059 0 012.78-3c3.152-3.152 6.338-6.283 9.513-9.425.33-.33.703-.615 1.077-.89.835-.593 1.461-.593 2.219.099a133.543 133.543 0 013.856 3.636c3.11 3.065 6.196 6.163 9.294 9.25.088.121.187.22.297.32z"
      fill="#fff"
      stroke="#78C854"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgSalesAndCxTeams;
