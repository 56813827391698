import React from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import { flatten, lastIndexOf } from 'ramda';
import { withTheme } from '@emotion/react';

import { customTooltip } from '../../../../../mc-ui';

const formatHour = hour => {
  if (hour === 0) return '12 AM';
  if (hour < 12) return `${hour} AM`;
  if (hour === 12) return '12 PM';

  return `${hour - 12} PM`;
};

const drawHighestLine = (chart, pointIndex, color) => {
  // eslint-disable-next-line no-underscore-dangle
  const lineLeftOffset = chart.getDatasetMeta(0).data[pointIndex]._model.x;
  const scale = chart.scales['y-axis-0'];
  const { ctx } = chart;

  ctx.save();
  ctx.beginPath();
  ctx.moveTo(lineLeftOffset, scale.top);
  ctx.lineTo(lineLeftOffset, scale.bottom);
  ctx.lineWidth = 2;
  ctx.strokeStyle = color;
  ctx.stroke();
};

const drawLineOnPoints = (chart, color) => {
  // eslint-disable-next-line no-underscore-dangle
  const tooltipActive = chart.tooltip._active;

  if (tooltipActive && tooltipActive.length) {
    // eslint-disable-next-line no-underscore-dangle
    const activePoint = chart.controller.tooltip._active[0];
    const { ctx } = chart;
    const tooltipPosition = activePoint.tooltipPosition();
    const bottomY = chart.scales['y-axis-0'].bottom;

    ctx.save();
    ctx.beginPath();
    ctx.moveTo(tooltipPosition.x, tooltipPosition.y);
    ctx.lineTo(tooltipPosition.x, bottomY);
    ctx.lineWidth = 2;
    ctx.strokeStyle = color;
    ctx.stroke();
    ctx.restore();
  }
};

class SendHourChart extends React.PureComponent {
  state = {
    chartReference: null,
  };

  setChartReference = ref => {
    this.setState(currentState => {
      if (currentState.chartReference) return {};
      return { chartReference: ref };
    });
  };

  hours = () => {
    const { brand } = this.props;

    return brand.meta.stats.sentAtHourOfDay.map(({ value }) => value);
  };

  data = () => {
    const { brand, theme } = this.props;
    const bg = theme.colors.dark6;
    const color = theme.colors.dark6;

    return {
      brandName: brand.attributes.name,
      labels: brand.meta.stats.sentAtHourOfDay.map(({ key }) => formatHour(key)),
      type: 'line',
      datasets: [
        {
          backgroundColor: bg,
          borderColor: color,
          fill: true,
          data: this.hours(),
          lineTension: 0,
          pointBackgroundColor: color,
          pointBorderColor: color,
          pointHoverBackgroundColor: color,
          pointHoverRadius: 0,
          pointBorderWidth: 0,
          pointRadius: 1,
          pointHitRadius: 10,
          pointStyle: 'circle',
        },
      ],
    };
  };

  generateTooltipHTML = (getBody, tooltipModel) => {
    const { theme } = this.props;

    if (!tooltipModel.body) return null;

    const titleLines = tooltipModel.title || [];
    const bodyLines = flatten(tooltipModel.body.map(getBody));
    let tooltipHTML = '';

    bodyLines.forEach(bodyLine => {
      const brandName = titleLines[0];
      const hour = titleLines[1];
      if (brandName && hour) {
        tooltipHTML += `<div class="title"><strong>${brandName}</strong></div>`;
        tooltipHTML += `<div class="title">${hour}</div>`;
        tooltipHTML += `<div class="line"><div class="bullet" style="background-color: ${
          theme.colors.blue
        }"></div> <div class="content">${bodyLine}</div></div>`;
      }
    });

    return tooltipHTML;
  };

  options = () => ({
    scales: {
      xAxes: [{ display: false }],
      yAxes: [{ display: false }],
    },
    legend: { display: false },
    tooltips: {
      mode: 'point',
      intersect: false,
      ...customTooltip(this.state.chartReference, this.generateTooltipHTML),
      callbacks: {
        beforeTitle(tooltipItem, data) {
          return data.brandName;
        },
        label(tooltipItem) {
          if (tooltipItem.datasetIndex === 0) {
            return `${tooltipItem.yLabel}% of emails sent`;
          }

          return null;
        },
      },
    },
  });

  plugins = () => {
    const { theme } = this.props;
    const highestIndex = lastIndexOf(Math.max(...this.hours()), this.hours());

    return [
      {
        afterDatasetsDraw: chart => {
          drawHighestLine(chart, highestIndex, theme.colors.dark1);
          drawLineOnPoints(chart, theme.colors.blue);
        },
      },
    ];
  };

  render() {
    return (
      <Line
        data={this.data()}
        height={30}
        options={this.options()}
        plugins={this.plugins()}
        ref={this.setChartReference}
      />
    );
  }
}

SendHourChart.propTypes = {
  brand: PropTypes.shape({
    id: PropTypes.number,
    meta: PropTypes.shape({
      stats: PropTypes.shape({
        sentAtHourOfDay: PropTypes.arrayOf(
          PropTypes.shape({
            docCount: PropTypes.number,
            key: PropTypes.number,
            value: PropTypes.number,
          }),
        ),
      }),
    }),
  }).isRequired,
  theme: PropTypes.shape({}).isRequired,
};

export default withTheme(SendHourChart);
