import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ContentLoader from './ui/ContentLoader';

const HorizontalBar = ({
  children,
  color,
  height,
  max,
  name,
  value,
  loading,
}) => {
  const calculatedMax = (value / max) * 100;
  const width = calculatedMax > 100 ? 100 : calculatedMax;
  const isComparison = name === 'Comparison';
  let background = isComparison ? '#D8D8D8' : '#0087F1';

  if (color) {
    background = color;
  }

  if (loading) {
    return (
      <div className="pure-u-1" style={{ padding: 1 }}>
        <ContentLoader height={height} lineHeight={height} loadingBorder={false} />
      </div>
    );
  }

  return (
    <div className="pure-u-1" style={{ padding: 1 }}>
      <div
        style={{
          height,
          background: '#F3F5F7',
          borderRadius: 2,
          width: '100%',
        }}
      />

      <div
        style={{
          height,
          marginTop: -1 * height,
          background,
          borderRadius: 2,
          width: `${width}%`,
        }}
      >
        <p
          style={{
            fontWeight: 'bold',
            fontSize: `${12 / 16}em`,
            color: width > 3 ? '#fff' : '#646464',
            margin: 0,
            padding: '3px 4px 0 4px',
            textAlign: 'right',
            whiteSpace: 'nowrap',
          }}
        >
          {children}
        </p>
      </div>
    </div>
  );
};

HorizontalBar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
  color: PropTypes.string,
  height: PropTypes.number,
  loading: PropTypes.bool,
  max: PropTypes.number,
  name: PropTypes.string,
  value: PropTypes.number,
};

HorizontalBar.defaultProps = {
  color: null,
  height: 24,
  max: 100,
  name: '',
  value: 0,
  loading: false,
};

const s = () => ({});
export default connect(s)(HorizontalBar);
