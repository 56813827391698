import styled from "@emotion/styled";

const Wrapper = styled("div")({
  boxSizing: 'border-box',
  color: '#fff',
  display: 'flex',
  fontFamily: '"azo-sans-web", Helvetica, Arial, sans-serif',
  justifyContent: 'space-between',
  padding: '10px 0',
});

export default Wrapper;
