import styled from "@emotion/styled";
import { keyframes } from '@emotion/css';

export const riseFromBottom = keyframes({
  from: { bottom: '-64px' },
  to: { bottom: '0px' },
});

export const RemoveButton = styled("button")(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.colors.white,
  border: `1px solid ${theme.colors.dark5}`,
  borderRadius: '4px',
  boxSizing: 'border-box',
  color: theme.colors.redDark,
  display: 'flex',
  fontFamily: theme.font.family,
  fontSize: 14,
  fontWeight: 500,
  height: '32px',
  justifyContent: 'center',
  margin: '0 8px 0 0',
  padding: '0',
  transition: 'border 250ms, background 250ms, color 250ms, opacity 250ms',
  width: '72px',
}));
