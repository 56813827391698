import React from 'react';

import Carousel from '../ui/Carousel';

import { fetchFeaturedJourneys } from './utils/api';

class FeaturedJourneys extends React.PureComponent {
  state = {
    loading: true,
    journeys: [],
  };

  componentDidMount() {
    this.request();
  }

  request = async () => {
    const journeys = await fetchFeaturedJourneys();

    this.setState(() => ({
      journeys,
      loading: false,
    }));
  };

  render() {
    const { loading, journeys } = this.state;

    return <Carousel collection={journeys} loading={loading} type="featuredJourneys" />;
  }
}

export default FeaturedJourneys;
