import { PREFIX } from './constants';

export const FETCH_REQUEST = `${PREFIX}/FETCH_REQUEST`;
export const FETCH_SUCCESS = `${PREFIX}/FETCH_SUCCESS`;
export const FETCH_FAILURE = `${PREFIX}/FETCH_FAILURE`;

export const DISABLE_REQUEST = `${PREFIX}/DISABLE_REQUEST`;
export const DISABLE_SUCCESS = `${PREFIX}/DISABLE_SUCCESS`;
export const DISABLE_FAILURE = `${PREFIX}/DISABLE_FAILURE`;

export const ENABLE_REQUEST = `${PREFIX}/ENABLE_REQUEST`;
export const ENABLE_SUCCESS = `${PREFIX}/ENABLE_SUCCESS`;
export const ENABLE_FAILURE = `${PREFIX}/ENABLE_FAILURE`;

export const ADD_GROUP_REQUEST = `${PREFIX}/ADD_GROUP_REQUEST`;
export const ADD_GROUP_SUCCESS = `${PREFIX}/ADD_GROUP_SUCCESS`;
export const ADD_GROUP_FAILURE = `${PREFIX}/ADD_GROUP_FAILURE`;

export const REMOVE_GROUP_REQUEST = `${PREFIX}/REMOVE_GROUP_REQUEST`;
export const REMOVE_GROUP_SUCCESS = `${PREFIX}/REMOVE_GROUP_SUCCESS`;
export const REMOVE_GROUP_FAILURE = `${PREFIX}/REMOVE_GROUP_FAILURE`;
