import React from 'react';
import PropTypes from 'prop-types';
import styled from "@emotion/styled";

const formatType = type =>
  type
    .split('_')
    .map(word => `${word.charAt(0).toUpperCase()}${word.slice(1)}`)
    .join(' ');

const Badge = styled("div")(({ theme, type, css, }) => ({
  backgroundColor: theme.colors.blue,
  border: `1px solid ${theme.colors.dark5}`,
  borderRadius: '4px',
  boxShadow: `0px 2px 8px ${theme.colors.dark02}`,
  boxSizing: 'border-box',
  color: theme.colors.white,
  cursor: 'default',
  fontFamily: theme.font.family,
  fontSize: '10px',
  fontWeight: 'bold',
  left: '8px',
  letterSpacing: '0.12em',
  lineHeight: '1.56em',
  maxWidth: '164px',
  overflow: 'hidden',
  padding: '0 4px',
  position: 'absolute',
  textOverflow: 'ellipsis',
  textTransform: 'uppercase',
  top: '8px',
  whiteSpace: 'nowrap',
  zIndex: '1',
  ...(theme.colors.journeyTypes[type] || {}),
  ...css
}));

const ClassifiedBadge = ({ classification, css, }) => (
  <Badge title={formatType(classification.type)} css={css} type={classification.type}>
    {formatType(classification.type)}
  </Badge>
);

ClassifiedBadge.propTypes = {
  classification: PropTypes.shape({
    type: PropTypes.string,
  }).isRequired,
};

export default ClassifiedBadge;
