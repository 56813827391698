/* global window */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';

import Container from '../Container';
import PaymentLink from '../PaymentLink';

import ContactModalLink from '../ContactModalLink';
import { topics }  from '../ContactUs';

import HeaderContainer from './ui/HeaderContainer';
import Label from './ui/Label';
import Title from './ui/Title';

const containerStyle = {
  display: 'flex',
  flexDirection: 'column',
  margin: '48px 0',
  padding: '42px 40px 0 40px',
};

class UpgradeCompanies extends Component {
  componentDidMount() {
    window.analytics.track('User restricted', { type: 'companies' });
  }

  handlePaywallClicked = (event) => {
    event.preventDefault();
    const type = 'companies';

    window.analytics.track('Paywall clicked', { type });
  };

  render() {
    const { model } = this.props;

    return (
      <Container style={containerStyle}>
        <HeaderContainer
          style={{
            backgroundPosition: 'right center',
            backgroundImage: 'url(/upgrade-companies-bg.svg)',
            backgroundSize: 'auto 325px',
            maxHeight: 400,
          }}
        >
          <Label>Pro</Label>
          <Title>You’ve reached your tracked companies limit</Title>

          <p style={{ maxWidth: 500 }}>
            To track {model.attributes.name} you can update your account or untrack a company you’re
            no longer interested in.
          </p>

          <p style={{ display: 'inline-block' }}>
            <PaymentLink
              className="pure-button pure-button-primary"
              onClick={this.handlePaywallClicked}
              style={{
                fontSize: `${13 / 16}em`,
                lineHeight: '1em',
                padding: '1em 1.8em',
              }}
              to="/manage/plan"
            >
              Upgrade now
            </PaymentLink>

            <Link
              className="pure-button"
              style={{
                fontSize: `${13 / 16}em`,
                lineHeight: '1em',
                marginLeft: '24px',
                padding: '1em 1.8em',
              }}
              to="/manage/companies"
            >
              Manage companies
            </Link>
          </p>
          <p style={{ fontSize: `${12/16}em`, maxWidth: 500 }}>
            <strong>Interested in a free month?</strong> Schedule some time
            to chat with us and we'll unlock your account for one month.{' '}
            <ContactModalLink topic={topics.QUESTION}>
              Contact Support
            </ContactModalLink>
          </p>
        </HeaderContainer>
      </Container>
    );
  }
}

UpgradeCompanies.contextTypes = {
  router: PropTypes.object,
};

UpgradeCompanies.propTypes = {
  model: PropTypes.shape({}).isRequired,
};

export default connect()(UpgradeCompanies);
