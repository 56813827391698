import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { couponSelectors } from '../../../modules/coupon';
import { teamsSelectors } from '../../../modules/teams';

import { Flex, Loader } from '../../mc-ui';

import EnterpriseOption from './EnterpriseOption';
import FreeOption from './FreeOption';
import ProOption from './ProOption';

class PlanSwitcher extends React.Component {
  state = {
    proSelected: false,
  };

  componentDidMount() {
    if (this.props.coupon) {
      this.selectPro();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.coupon && this.props.coupon) {
      this.selectPro();
    }
  }

  selectPro = () => {
    this.setState(() => ({ proSelected: true }));
  };

  toggleProSelected = event => {
    const { team, isOwner } = this.props;
    if (event) event.preventDefault();

    let proSelected = !this.state.proSelected;
    if (team && !isOwner) {
      // prevent non-team owners from seeing credit card drop-down
      proSelected = false;
    }
    this.setState({ proSelected });
  };

  handleOnChange = event => {
    const { value } = event.target;

    this.setState(() => ({ selected: value }));
  };

  render() {
    const { couponIsLoading, isCanceled, isFree, isOwner, planExpired, team } = this.props;
    const { proSelected } = this.state;

    const isActive = team && team.attributes.account_status === 'active';
    const isTrialing = team && team.attributes.account_status === 'trialing';

    const isProSelected =
      proSelected ||
      ((isFree && proSelected) || ((isActive && planExpired) || isTrialing) || isCanceled);

    if (couponIsLoading && !isProSelected) {
      return (
        <Flex alignItems="center" justifyContent="center" paddingBottom="32px">
          <Loader />
        </Flex>
      );
    }

    return (
      <div>
        {(!team || isOwner) && <FreeOption isProSelected={proSelected} team={team} />}

        <ProOption
          isOwner={isOwner}
          selected={proSelected}
          team={team}
          toggleSelect={this.toggleProSelected}
        />

        <EnterpriseOption isOwner={isOwner} team={team} />
      </div>
    );
  }
}

PlanSwitcher.defaultProps = {
  coupon: null,
  team: null,
};

PlanSwitcher.propTypes = {
  coupon: PropTypes.shape({}),
  couponIsLoading: PropTypes.bool.isRequired,
  isCanceled: PropTypes.bool.isRequired,
  isFree: PropTypes.bool.isRequired,
  isOwner: PropTypes.bool.isRequired,
  planExpired: PropTypes.bool.isRequired,
  team: PropTypes.shape({}),
};

const mapStateToProps = state => ({
  coupon: couponSelectors.couponSelector(state),
  couponIsLoading: couponSelectors.isLoadingSelector(state),
  isCanceled: teamsSelectors.isCanceledSelector(state),
  isFree: teamsSelectors.isFreeSelector(state),
  planExpired: teamsSelectors.planExpiredSelector(state),
});

export default connect(mapStateToProps)(PlanSwitcher);
