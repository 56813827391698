import React from 'react'

import background from './headerBg.png';

const HeaderContainer = (props) => {
  const { style = {} } = props
  return (<div style={{
    backgroundColor: '#F3F5F7',
    backgroundImage: style.backgroundImage || `url(${background})`,
    backgroundPosition: style.backgroundPosition || 'right bottom',
    backgroundSize: style.backgroundSize,
    backgroundRepeat: 'no-repeat',
    borderRadius: '3px 3px 0 0',
    boxSizing: 'border-box',
    maxHeight: style.maxHeight || '350px',
    marginBottom: '42px',
    padding: '40px 0 46px 52px',
  }}>
    {props.children}
  </div>)
}

export default HeaderContainer;
