/* globals document, window */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import humanize from 'humanize-number';

import CompaniesList from './CompaniesList';
import CompaniesLoadMore from './companies/LoadMore';

class BrowseCompanies extends React.Component {
  UNSAFE_componentWillMount() {
    const { requestCompanies, suggestions } = this.props;
    const companyId = suggestions.data.filter(d => d.type === 'companies').map(c => c.id);
    const { q, tags } = this.props.router.location.query
    requestCompanies({ q, tags, })
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const windowHeight = 'innerHeight' in window
      ? window.innerHeight
      : document.documentElement.offsetHeight;
    const { body, documentElement: html } = document;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight,
    );
    const windowBottom = windowHeight + window.pageYOffset;

    if (windowBottom >= docHeight) {
      setTimeout(() => {
        this.props.requestCompaniesPagination();
      }, 250); // adds a slight delay to increase "fetching" effect for end-user
    }
  };

  render() {
    const { companies, router } = this.props;

    if (companies.loading) {
      return (
        <p>Loading...</p>
      );
    }

    return (
      <div>
        {(router.location.query.q || router.location.query.tags)
          ? (
            <p>
              Searching{' '}
              <span
                style={{ fontFamily: 'monospace', whitespace: 'pre', textTransform: 'uppercase' }}
              >
                {router.location.query.q || router.location.query.tags}
              </span>{' '}
              found {humanize(companies.meta.total || 0)} companies.
            </p>
          )
          : <p>&nbsp;</p>
        }

        <CompaniesList companies={companies.data} router={router} />
        {companies.links.next && <CompaniesLoadMore />}
      </div>
    );
  }
}

BrowseCompanies.propTypes = {
  companies: PropTypes.shape({}).isRequired,
  requestCompanies: PropTypes.func.isRequired,
  requestCompaniesPagination: PropTypes.func.isRequired,
  router: PropTypes.shape({}).isRequired,
  suggestions: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => ({
  companies: state.companies,
  suggestions: state.suggestions,
});

const mapDispatchToProps = dispatch => ({
  requestCompanies: ({ q, tags }) => dispatch({
    type: 'REQUEST_COMPANIES',
    params: { q, tags },
    index: true,
  }),
  requestCompaniesPagination: () => dispatch({ type: 'REQUEST_COMPANIES_PAGINATION' }),
});

export default connect(mapStateToProps, mapDispatchToProps)(BrowseCompanies);
