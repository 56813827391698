import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import api from '../../../lib/api';
import { teamsSelectors } from '../../../modules/teams';

import CommentForm from '../../CommentForm';
import CommentIcon from '../../mc-ui/icons/CommentIcon';
import CrossSmallIcon from '../../mc-ui/icons/CrossSmallIcon';
import { Box, Flex, Text, theme } from '../../mc-ui';

import CommentsMessages from '../../Comments';

import * as S from './styles';

class Comments extends React.Component {
  state = {
    approximateComments: this.props.list.attributes.approximate_comments || 0,
    comments: [],
    isOpen: false,
    loadingComments: false,
  };

  fetchComments = async () => {
    const { list, team } = this.props;
    const { comments } = this.state;
    const approximateComments = list.attributes.approximate_comments || 0;

    if (comments.length > 0 && comments.length === approximateComments) return;

    this.setState({ loadingComments: true });

    const params = {};
    if (team) {
      params.team_id = team.id;
    }
    const response = await api.get(`/v3/lists/${list.id}/comments`, { params });

    this.setState({
      approximateComments: response.data.data.length,
      comments: response.data.data,
      loadingComments: false,
    });
  };

  handleSubmit = async values => {
    const { list, team } = this.props;

    const payload = { ...values };
    if (team) {
      payload.team_id = team.id;
    }
    await api.post(`/v3/lists/${list.id}/comments`, payload);

    window.analytics.track('Comment added', { list_id: list.id });

    this.refetchComments();
  };

  refetchComments = () => {
    this.setState({ comments: [], loadingComments: true }, this.fetchComments);
  };

  toggle = () => {
    this.setState(currentState => ({ isOpen: !currentState.isOpen }));
  };

  render() {
    const { list } = this.props;

    if (list.attributes.featured) return null;

    const { approximateComments, comments, isOpen, loadingComments } = this.state;

    return (
      <Flex margin="0 auto" maxWidth="1280px" position="relative">
        <S.Button onClick={this.toggle} onMouseEnter={this.fetchComments}>
          {approximateComments > 0 && (
            <Text
              fontWeight="500"
              letterSpacing="0.02em"
              lineHeight="1em"
              css={{ marginRight: '8px' }}
            >
              {approximateComments}
            </Text>
          )}
          <CommentIcon />
        </S.Button>

        <Flex
          height="calc(100vh - 60px)"
          overflow="hidden"
          position="absolute"
          right="0"
          top="-144px"
          width="404px"
          zIndex={isOpen ? theme.zIndices.popover : theme.zIndices.auto}
        >
          <S.CommentsWrapper open={isOpen}>
            <S.Header>
              <Text
                color="dark08"
                fontSize="16px"
                fontWeight="500"
                letterSpacing="0.02em"
                lineHeight="1em"
              >
                Collection notes
              </Text>

              <button onClick={this.toggle}>
                <CrossSmallIcon />
              </button>
            </S.Header>

            <Box
              backgroundColor="white"
              borderColor="dark5"
              borderBottom="1px solid"
              padding="24px"
            >
              <CommentForm onSubmit={this.handleSubmit} />
            </Box>

            <Box height="calc(100vh - 60px - 56px - 157px)" overflow="auto" padding="0 24px">
              <CommentsMessages
                comments={comments}
                listId={list.id}
                loading={loadingComments}
                refetch={this.refetchComments}
              />
            </Box>
          </S.CommentsWrapper>
        </Flex>
      </Flex>
    );
  }
}

Comments.defaultProps = {
  team: null,
};

Comments.propTypes = {
  list: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.shape({
      approximate_comments: PropTypes.number,
      featured: PropTypes.bool,
    }),
  }).isRequired,
  team: PropTypes.shape({
    id: PropTypes.string,
  }),
};

const mapStateToProps = state => ({
  team: teamsSelectors.teamSelector(state),
});

export default connect(mapStateToProps)(Comments);
