/* eslint-disable react/prop-types */
import React from 'react';
import styled from "@emotion/styled";
import { withTheme } from '@emotion/react';

const ChartTooltip = ({ theme }) => {
  const tooltipTheme = theme.tooltip.dark;

  const ChartTooltipWrapper = styled("div")({
    backgroundColor: tooltipTheme.backgroundColor,
    borderRadius: tooltipTheme.borderRadius,
    boxSizing: tooltipTheme.boxSizing,
    color: tooltipTheme.text.color,
    display: 'flex',
    flexDirection: 'column',
    fontFamily: tooltipTheme.text.fontFamily,
    fontSize: tooltipTheme.text.fontSize,
    fontStyle: 'normal',
    lineHeight: tooltipTheme.text.lineHeight,
    margin: tooltipTheme.text.margin,
    minWidth: '225px',
    opacity: 0,
    padding: tooltipTheme.padding,
    pointerEvents: 'none',
    position: 'absolute',
    zIndex: '9997',
    '& > .title': {
      fontWeight: '500',
      letterSpacing: '0.02em',
      lineHeight: '1.25em',
      width: '100%',
    },
    '& > .holiday-title': {
      marginTop: '12px',
    },
    '& > .line': {
      alignItems: 'center',
      display: 'flex',
      height: '22px',
      width: '100%',
      '& > .bullet': {
        border: '1px solid #FFF',
        borderRadius: '50%',
        height: '8px',
        marginRight: '8px',
        width: '8px',
      },
    },
  });

  return <ChartTooltipWrapper id="custom-chart-tooltip"></ChartTooltipWrapper>;
};

export default withTheme(ChartTooltip);
