/* global window */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import qs from 'qs';
import { connect } from 'react-redux';
import { contains, isEmpty } from 'ramda';

import ActionsWrapper from './ui/ActionsWrapper';

const apiUrl = () => {
  const { protocol } = window.location;

  if (process.env.base_url) {
    return `${protocol}//${process.env.base_url}`;
  } else if (process.env.NODE_ENV === 'production') {
    return `${protocol}//api.mailcharts.com`;
  } else if (process.env.NODE_ENV === 'staging') {
    return `${protocol}//staging-api.mailcharts.com`;
  } else if (process.env.NODE_ENV === 'pr') {
    return `${protocol}//pr-api.mailcharts.com`;
  }
  return `${protocol}//localhost:3001`;
};

const FORMATS_WHITELIST = ['csv', 'html', 'images', 'xlsx'];

const modelTypes = {
  companies: 'Company',
  groups: 'Group',
  technologies: 'Technology',
};

class ExportOptions extends Component {
  state = {
    exportFormat: '',
  };

  handleExport = (event) => {
    event.preventDefault();

    const { comparisonMode, model, queryScope } = this.props;
    const { router } = this.context;

    let format;
    let exportType;
    const type = 'report';

    if (comparisonMode) {
      [exportType, format] = event.target.value.split('_');
    } else {
      format = event.target.value;
    }

    if (isEmpty(format) || !contains(format, FORMATS_WHITELIST)) {
      return;
    }

    window.analytics.track('Export clicked', { format, type });

    let search = qs.parse((router.location.search || '').replace('?', ''));
    if (exportType === 'comparison') {
      search = search[queryScope] || {};
    } else {
      delete search[queryScope];
    }

    const query = { ...router.params, ...search };
    if (/\/dashboard/i.test(router.location.pathname)) {
      query.group_id = model.id;
    }

    const url = `${apiUrl()}/v2/searches?${qs.stringify(query)}`;

    if (format === 'xlsx' && model.type === 'companies') {
      const u = new URL(url);
      u.pathname = `v2/companies/${model.id}/xlsx`;
      window.open(`${u.toString()}`, '_blank');
    } else {
      window.open(`${url}&format=${format}`, '_blank');
    }
  };

  totalEmails = (format, key = 'total') => {
    const { reports } = this.props;
    const total = reports.export[key] || 0;
    let amount;

    if (total === 0) {
      return null;
    }

    if (format === 'csv' || format === 'xlsx') {
      if (total > 5000) {
        amount = 5000;
      } else {
        amount = total;
      }
    } else if (total > 50) {
      amount = 50;
    } else {
      amount = total;
    }

    return `${amount} ${pluralize('email', amount)} as `;
  };

  totalComparisonEmails = format => this.totalEmails(format, 'comparisonTotal');

  buildSelect = () => {
    const { comparisonMode, model, reports } = this.props;
    const { exportFormat } = this.state;

    if (comparisonMode) {
      const totals = {
        original: {
          csv: this.totalEmails('csv'),
          images: this.totalEmails('images'),
          html: this.totalEmails('html'),
          xlsx: this.totalEmails('xlsx'),
        },
        comparison: {
          csv: this.totalComparisonEmails('csv'),
          images: this.totalComparisonEmails('images'),
          html: this.totalComparisonEmails('html'),
          xlsx: this.totalComparisonEmails('xlsx'),
        },
      };

      return (
        <select
          onChange={this.handleExport}
          value={exportFormat}
          style={{ fontSize: `${13 / 16}em` }}
          disabled={
            reports.loading || (reports.export.total === 0 && reports.export.comparisonTotal === 0)
          }
        >
          <option value="" style={{ display: 'none' }}>
            Export data
          </option>
          {reports.export.total > 0 && (
            <optgroup label={`${[modelTypes[model.type]]} - ${model.attributes.name}`}>
              <option value="original_csv">{totals.original.csv}CSV</option>
              <option value="original_images">{totals.original.images}Images</option>
              <option value="original_html">{totals.original.html}HTML</option>
              {model.type === 'companies' && (
                <option value="original_xlsx">{totals.original.xlsx}XLSX</option>
              )}
            </optgroup>
          )}
          {reports.export.comparisonTotal > 0 && (
            <optgroup label="Comparison">
              <option value="comparison_csv">{totals.comparison.csv}CSV</option>
              <option value="comparison_images">{totals.comparison.images}Images</option>
              <option value="comparison_html">{totals.comparison.html}HTML</option>
              {model.type === 'companies' && (
                <option value="comparison_xlsx">{totals.comparison.xlsx}XLSX</option>
              )}
            </optgroup>
          )}
        </select>
      );
    }

    const totals = {
      csv: this.totalEmails('csv'),
      images: this.totalEmails('images'),
      html: this.totalEmails('html'),
      xlsx: this.totalEmails('xlsx'),
    };

    return (
      <select
        onChange={this.handleExport}
        value={exportFormat}
        style={{ fontSize: `${13 / 16}em` }}
        disabled={reports.loading}
      >
        <option value="" style={{ display: 'none' }}>
          Export data
        </option>
        <option value="csv">{totals.csv}CSV</option>
        <option value="images">{totals.images}Images</option>
        <option value="html">{totals.html}HTML</option>
        {model.type === 'companies' && <option value="xlsx">{totals.xlsx}XLSX</option>}
      </select>
    );
  };

  render() {
    const { showExport } = this.props;

    if (!showExport) {
      return null;
    }

    return (
      <ActionsWrapper>
        <form className="pure-form">{this.buildSelect()}</form>
      </ActionsWrapper>
    );
  }
}

ExportOptions.contextTypes = {
  router: PropTypes.object,
};

ExportOptions.propTypes = {
  model: PropTypes.shape({}).isRequired,
  reports: PropTypes.shape({}).isRequired,
  comparisonMode: PropTypes.bool,
  queryScope: PropTypes.string,
  showExport: PropTypes.bool,
};

ExportOptions.defaultProps = {
  comparisonMode: false,
  queryScope: null,
  showExport: false,
};

const mapStateToProps = state => ({
  reports: state.reports,
});

export default connect(mapStateToProps)(ExportOptions);
