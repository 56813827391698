import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Box, Flex, Text } from '../../../../mc-ui';

const CalendarDay = ({ date }) => {
  const mDate = moment(date);
  const month = mDate.format('MMM');
  const day = mDate.format('D');

  return (
    <Box height="40px" width="40px">
      <Flex
        alignItems="center"
        backgroundColor="blueDark"
        borderRadius="4px 4px 0 0"
        height="16px"
        justifyContent="center"
      >
        <Text
          color="white"
          fontSize="10px"
          fontWeight="bold"
          letterSpacing="0.12em"
          textTransform="uppercase"
        >
          {month}
        </Text>
      </Flex>
      <Flex
        alignItems="center"
        backgroundColor="blueLight"
        borderRadius="0 0 4px 4px"
        height="24px"
        justifyContent="center"
      >
        <Text color="blueDark" fontSize="16px" fontWeight="500" letterSpacing="0.02em">
          {day}
        </Text>
      </Flex>
    </Box>
  );
};

CalendarDay.propTypes = {
  date: PropTypes.string.isRequired,
};

export default CalendarDay;
