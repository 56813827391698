import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

const MAX_LENGTH = 25;
const truncateDescription = (description) => {
  if (description.length < MAX_LENGTH) {
    return description;
  }

  let truncated = description;
  truncated = truncated.slice(0, MAX_LENGTH);

  // If we happen to hit a word break, do nothing
  if (truncated.charAt(MAX_LENGTH) === ' ') {
    return truncated;
  }

  truncated = truncated.slice(0, MAX_LENGTH - 3);
  truncated += '...';
  return truncated;
};

const JourneyStartDatePicker = ({
  index, journeys, selectedJourneyId, disabledJourneyIds, handleChange, disabled,
}) => {
  let options = [
    <option key="empty" value="" disabled>Pick journey...</option>,
  ];
  options = options.concat(journeys.map(journey => (
    <option
      value={journey.id}
      key={journey.id}
      disabled={disabledJourneyIds.indexOf(journey.id) !== -1}
    >
      {moment(journey.attributes.first_activity_at).format('MMM. D, YYYY')}
      {` –  ${truncateDescription(journey.attributes.description)}`}
    </option>
  )));

  return (
    <form
      className="pure-form"
      style={{ width: '100%' }}
    >
      <select
        style={{
            width: '100%', maxWidth: '100%', paddingRight: 25, fontSize: `${12 / 16}em`,
          }}
        value={selectedJourneyId}
        onChange={(e) => { handleChange(e, index); }}
        disabled={disabled}
      >
        {options}
      </select>
    </form>
  );
};

JourneyStartDatePicker.propTypes = {
  index: PropTypes.number.isRequired,
  journeys: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedJourneyId: PropTypes.string.isRequired,
  disabledJourneyIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

JourneyStartDatePicker.defaultProps = {
  disabled: false,
};

export default JourneyStartDatePicker;
