import React from 'react';
import { Router, Route, IndexRoute, browserHistory, Redirect } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';

import {
  Batch,
  BrandOverview,
  Browse,
  BrowseCompanies,
  BrowseGroups,
  BrowseTechnologies,
  Companies,
  CompaniesAddSegments,
  CompaniesReports,
  Confirm,
  ContentAnalysisReport,
  Demo,
  DemoIndex,
  DemoJourneys,
  DemoReports,
  Email,
  EmailsIndex,
  Emails,
  ExportReport,
  GroupOverview,
  Groups,
  GroupsAddCompany,
  GroupsCompanies,
  GroupsEmails,
  GroupsReports,
  HomeDashboard,
  Invitations,
  Lists,
  Main,
  Manage,
  ManageAccount,
  ManageCompanies,
  ManageGroups,
  MyPlan,
  NotFound,
  Passwords,
  ProductBoard,
  BrandRequestBoard,
  PromotionsReport,
  ScoreReport,
  Segments,
  SegmentsDetails,
  SendingBehaviorReport,
  Signin,
  SigningIn,
  Signup,
  Technologies,
  TechnologiesCompanies,
  TechnologiesDetails,
  TechnologiesReports,
  Unlocked,
} from './components/Index';
import {
  Benchmarks,
  Calendars,
  Promotions,
  SendingBehavior,
  SubjectLines,
} from './components/newReports';
import BrandsIndex from './components/BrandsIndex';
import BrandsResults from './components/BrandsResults';
import JourneysIndex from './components/JourneysIndex';
import JourneysResults from './components/JourneysIndex/Results';
import NewJourneys from './components/NewJourneys';
import ShowJourney from './components/NewJourneys/ShowJourney';

import TeamManagement from './components/TeamManagement';
import Invoices from './components/Invoices';
import WeeklyRollUpEmail from './components/WeeklyRollUpEmail';

import Collections from './components/Collections';
import EmailLists from './components/Collections/EmailLists';
import GroupsCollections from './components/Collections/GroupsCollections';
import SavedFilters from './components/Collections/SavedFilters';
import SavedJourneys from './components/Collections/SavedJourneys';
import NotesCollections from './components/Collections/NotesCollections';
import TextMessageLists from './components/Collections/TextMessageLists';

import TextMessagesExamples from './components/TextMessages/Examples';
import TextMessagesFromModel from './components/TextMessages/TextMessagesFromModel';
import TextMessageViewPage from './components/TextMessages/ViewPage';

import ListsIndex from './components/ListsIndex';

import store from './store';

const auth = (next, replace) => {
  store.dispatch({ type: 'REQUEST_USER' });
};

const handleUpdate = function() {
  const properties = { ...this.state.params };
  let name = window.location.pathname
    .split('/')
    .filter(s => s)
    .reduce((m, s, idx, arr) => {
      const is_int = s == parseInt(s);
      const is_guid = s.split('-').length == 5 && arr[0] == 'emails';
      if (is_int && idx == 1)
        m = m
          .replace('Companies', 'Company')
          .replace('Lists', 'List')
          .replace('Technologies', 'Technology')
          .replace('Groups', 'Group');
      else if (is_guid) m = 'Email';
      else if (!is_int)
        m = [m, s.charAt(0).toUpperCase() + s.substring(1)].filter(s => s).join(' ');
      return m;
    }, null);
  name = name || 'Dashboard';
  try {
    window.analytics.page(name, properties);
  } catch (err) {
    /* analytics isn't ready yet */
  }
};

const history = syncHistoryWithStore(browserHistory, store);

export default (
  <Router history={history} onUpdate={handleUpdate}>
    <Route path="/signup" component={Signup} />
    <Route path="/confirm" component={Confirm} />
    <Route path="/signin" component={Signin} />
    <Route path="/signingin" component={SigningIn} />
    <Route path="/invitations" component={Invitations} />

    <Route path="/passwords">
      <Route path="forgot" component={Passwords} />
      <Route path="reset/:token" component={Passwords} />
    </Route>
    {/* LEGACY ROUTE */}
    <Route path="/password-reset" component={Passwords} />
    <Route path="/password-forgot" component={Passwords} />
    {/* END LEGACY ROUTE */}

    <Route component={Main} onEnter={auth}>
      <Route path="/" component={HomeDashboard} />
      <Route path="/emails" component={EmailsIndex} />
      <Route path="/emails/:guid" component={Email} />
      <Route path="/journeys" component={JourneysIndex} />
      <Route path="/unlocked" component={Unlocked} />
      <Route path="/journeys/results" component={JourneysResults} />

      <Route path="/brands">
        <IndexRoute component={BrandsIndex} />
        <Route path="results" component={BrandsResults} />
      </Route>
      <Route path="/browse" component={Browse}>
        <Route path="emails">
          <IndexRoute component={Emails} />
          <Route path="inbox" component={Emails} />
          {/* TODO: Check how to send model to this route */}
          <Route path="calendar" component={Emails} />
        </Route>
        <Route path="companies" component={BrowseCompanies} />
        <Redirect from="lists" to="/emails" />
        <Route path="technologies" component={BrowseTechnologies} />
        <IndexRoute component={Emails} />
      </Route>
      <Route path="/groups" component={BrowseGroups} />

      <Route path="groups/add" component={GroupsAddCompany} />
      <Route path="groups/:group_id/add" component={GroupsAddCompany} />
      <Route path="groups/:group_id" component={Groups}>
        <IndexRoute component={GroupOverview} />
        <Route path="overview" component={GroupOverview} />
        <Route component={GroupsReports}>
          <Route path="reports" component={SendingBehaviorReport} />
          <Route path="content" component={ContentAnalysisReport} />
          <Route path="promotions-report" component={PromotionsReport} />
          <Route path="score" component={ScoreReport} />
          <Route path="export" component={ExportReport} />
        </Route>
        <Route path="emails">
          <IndexRoute component={GroupsEmails} />
          <Route path="inbox" component={Emails} />
          <Route path="calendar" component={Emails} />
        </Route>
        <Route path="companies" component={GroupsCompanies} />
        <Route path="journeys">
          <IndexRoute component={NewJourneys} />
          <Route path=":journey_id" component={ShowJourney} />
        </Route>
        <Route path="sending-behavior" component={SendingBehavior} />
        <Route path="subject-lines" component={SubjectLines} />
        <Route path="promotions" component={Promotions} />
        <Route path="benchmarks" component={Benchmarks} />
        <Route path="calendars" component={Calendars} />
        <Route path="text-messages" component={TextMessagesFromModel} />
      </Route>

      <Route path="companies/:company_id/segments" component={CompaniesAddSegments} />
      <Route path="companies/:company_id" component={Companies}>
        <IndexRoute component={BrandOverview} />
        <Route path="overview" component={BrandOverview} />
        <Route component={CompaniesReports}>
          <Route path="reports" component={SendingBehaviorReport} />
          <Route path="content" component={ContentAnalysisReport} />
          <Route path="promotions-report" component={PromotionsReport} />
          <Route path="score" component={ScoreReport} />
          <Route path="export" component={ExportReport} />
        </Route>
        <Route path="journeys">
          <IndexRoute component={NewJourneys} />
          <Route path=":journey_id" component={ShowJourney} />
        </Route>
        <Route path="emails">
          <IndexRoute component={Emails} />
          <Route path="inbox" component={Emails} />
          <Route path="calendar" component={Emails} />
        </Route>
        <Route path="sending-behavior" component={SendingBehavior} />
        <Route path="subject-lines" component={SubjectLines} />
        <Route path="promotions" component={Promotions} />
        <Route path="benchmarks" component={Benchmarks} />
        <Route path="calendars" component={Calendars} />
        <Route path="text-messages" component={TextMessagesFromModel} />
      </Route>

      <Route path="segments/:address_id" component={Segments}>
        <IndexRoute component={SegmentsDetails} />
        <Route path="details" component={SegmentsDetails} />
        <Route path="emails">
          <IndexRoute component={Emails} />
          <Route path="inbox" component={Emails} />
          <Route path="calendar" component={Emails} />
        </Route>
      </Route>

      <Route path="technologies/:technology_id" component={Technologies}>
        <Route path="details" component={TechnologiesDetails} />
        <Route path="emails">
          <IndexRoute component={Emails} />
          <Route path="inbox" component={Emails} />
          <Route path="calendar" component={Emails} />
        </Route>
        <Route path="reports" component={TechnologiesReports} />
        <Route path="companies" component={TechnologiesCompanies} />
        <IndexRoute component={Emails} />
        <Route path="inbox" component={Emails} />
        <Route path="calendar" component={Emails} />
      </Route>

      <Route path="lists/:list_id" component={Lists}>
        <Route path="emails">
          <IndexRoute component={Emails} />
          <Route path="inbox" component={Emails} />
          <Route path="calendar" component={Emails} />
        </Route>
        <IndexRoute component={ListsIndex} />
        <Route path="inbox" component={ListsIndex} />
        <Route path="calendar" component={ListsIndex} />
      </Route>

      <Route path="manage" component={Manage}>
        <Route path="account" component={ManageAccount} />
        <Route path="plan" component={MyPlan} />
        <Route path="teams" component={TeamManagement} />
        <Route path="companies" component={ManageCompanies} />
        <Route path="groups" component={ManageGroups} />
        <Redirect from="lists" to="/my-collections/email-lists" />
        <Route path="invoices" component={Invoices} />
        <Route path="preferences" component={WeeklyRollUpEmail} />
        <Route path="productboard" component={ProductBoard} />
        <Route path="brandrequestboard" component={BrandRequestBoard} />
        <IndexRoute component={ManageAccount} />
      </Route>

      <Route path=":collection_type-collections" component={Collections}>
        <Route path="saved-filters" component={SavedFilters} />
        <Route path="email-lists" component={EmailLists} />
        <Route path="text-message-lists" component={TextMessageLists} />
        <Route path="groups" component={GroupsCollections} />
        <Route path="saved-journeys" component={SavedJourneys} />
        <Route path="notes" component={NotesCollections} />
      </Route>

      <Route path="text-messages" component={TextMessagesExamples} />
      <Route path="text-messages/:guid" component={TextMessageViewPage} />

      <Route path="demo" component={Demo}>
        <Route path="journeys" component={DemoJourneys} />
        <Route path="reports" component={DemoReports}>
          <IndexRoute component={SendingBehaviorReport} />
          <Route path="behavior" component={SendingBehaviorReport} />
          <Route path="content" component={ContentAnalysisReport} />
          <Route path="promotions" component={PromotionsReport} />
          <Route path="score" component={ScoreReport} />
          <Route path="export" component={ExportReport} />
        </Route>
        <IndexRoute component={DemoIndex} />
      </Route>

      <Route path="batch" component={Batch} />

      <Route path="*" exact component={NotFound} />
    </Route>
  </Router>
);
