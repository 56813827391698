import styled from "@emotion/styled";
import { componentFromProp, defaultProps } from 'recompose';

const enhance = defaultProps({ component: 'button' });
const Component = enhance(componentFromProp('component'));

const Button = styled(Component)('pure-button', ({ type }) => ({
  backgroundColor: type === 'primary' ? '#9E6ACC' : 'transparent',
  border: type === 'primary' ? 'none' : '1px solid #A0AEBA',
  boxSizing: 'border-box',
  color: '#fff',
  fontSize: `${13 / 16}em`,
  marginRight: '8px',
  padding: '.8em 1.8em',
  '& + button': {
    marginRight: '0',
  },
  '&:hover': {
    border: type === 'primary' ? 'none' : '1px solid #A0AEBA',
    backgroundColor: type === 'primary' ? '#AA87C9' : '#4F4E4E',
    color: '#fff',
  },
}));

export default Button;
