import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@emotion/react';

import { Flex, Text } from '../../../../mc-ui';

import Column from './Column';

// eslint-disable-next-line react/prop-types
const Day = withTheme(({ day, theme }) => (
  <Flex alignItems="center" css={{ height: 32, width: 43 }}>
    <Text
      fontSize="12px"
      fontWeight="500"
      lineHeight="32px"
      textTransform="uppercase"
      css={{ color: theme.colors.dark08, margin: 0 }}
    >
      {day.substr(0, 3)}
    </Text>
  </Flex>
));

const Row = ({ data = { sentAtHourOfDay: [] }, day = '', idx = 0, }) => (
  <Flex css={{ marginTop: '1px' }}>
    <Day day={day} />
    {data.sentAtHourOfDay.map(hourOfDay => (
      <Column key={`${idx}-${hourOfDay.key}`} day={day} hourOfDay={hourOfDay} />
    ))}
  </Flex>
);

Row.propTypes = {
  data: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.number,
    sentAtHourOfDay: PropTypes.arrayOf(
      PropTypes.shape({
        docCount: PropTypes.number,
        key: PropTypes.number,
        value: PropTypes.number,
      }),
    ),
  }),
};

export default Row;
