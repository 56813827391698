import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { teamsSelectors } from '../../../../modules/teams';

import { Badge } from '../../../mc-ui';

function MemberStatusBadge({ planExpired, status, team }) {
  let backgroundColor = 'blue';
  let color = 'white';
  let border = null;
  let borderColor = null;
  let statusName = status;

  if (status === 'you') {
    backgroundColor = 'tealLight';
    color = 'dark08';
    border = '1px solid';
    borderColor = 'tealMedium';
  } else if (status === 'pending') {
    if (team.attributes.account_status === 'trialing') {
      backgroundColor = 'orange';
      color = 'white';
    } else {
      backgroundColor = 'blueLight';
      color = 'dark08';
      border = '1px solid';
      borderColor = 'blueMedium';
    }
  } else if (planExpired) {
    statusName = 'Expired';

    if (team.attributes.account_status === 'trialing') {
      backgroundColor = 'orange';
      color = 'white';
    } else {
      backgroundColor = 'dark5';
      color = 'dark1';
    }
  } else if (status === 'archived') {
    backgroundColor = 'dark5';
    color = 'dark1';
  }

  if (status == 'archived') {
    statusName = status
  }

  return (
    <Badge
      backgroundColor={backgroundColor}
      color={color}
      border={border}
      borderColor={borderColor}
      display="inline-block"
      padding="0 4px"
    >
      {statusName}
    </Badge>
  );
}

MemberStatusBadge.propTypes = {
  planExpired: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
  team: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => ({
  planExpired: teamsSelectors.planExpiredSelector(state),
  team: teamsSelectors.teamSelector(state),
});

export default connect(mapStateToProps)(MemberStatusBadge);
