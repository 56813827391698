import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@emotion/react';

import { Flex, Tooltip } from '../../mc-ui';

import Bar from './verticalBar/Bar';

const VerticalBar = ({ size, theme, tooltip, uuid }) => (
  <Flex
    justifyContent="center"
    grow="1"
    css={{
      borderBottom: `2px solid ${theme.colors.dark3}`,
      boxSizing: 'border-box',
      height: '32px',
      width: `${100 / 7}%`,
    }}
  >
    <Tooltip id={`vertical-bar-${uuid}`} element={<Bar size={size} />} effect="float" dark>
      {tooltip}
    </Tooltip>
  </Flex>
);

VerticalBar.propTypes = {
  size: PropTypes.number.isRequired,
  theme: PropTypes.shape({}).isRequired,
  tooltip: PropTypes.node.isRequired,
  uuid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default withTheme(VerticalBar);
