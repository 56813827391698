import * as types from './types';

export const clearCoupon = () => ({
  type: types.CLEAR_COUPON,
});

export const fetchCouponRequest = ({ id }) => ({
  type: types.FETCH_COUPON_REQUEST,
  id,
});

export const fetchCouponSuccess = data => ({
  type: types.FETCH_COUPON_SUCCESS,
  data,
});

export const fetchCouponFailure = (error = null) => ({
  type: types.FETCH_COUPON_FAILURE,
  error,
});
