import React from 'react';
import PropTypes from 'prop-types';

function RoundListIcon({ fillOpacity, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
      <circle cx={3.333} cy={4.167} fill="currentColor" fillOpacity={0.6} r={1.667} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.333 5H6.667V3.333h6.666V5z"
        fill="currentColor"
        fillOpacity={fillOpacity}
      />
      <circle cx={3.333} cy={15.833} fill="currentColor" fillOpacity={fillOpacity} r={1.667} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 16.667H6.667V15H15v1.667z"
        fill="currentColor"
        fillOpacity={fillOpacity}
      />
      <circle cx={3.333} cy={10} fill="currentColor" fillOpacity={fillOpacity} r={1.667} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.333 10.833H6.667V9.167h11.666v1.666z"
        fill="currentColor"
        fillOpacity={fillOpacity}
      />
    </svg>
  );
}

RoundListIcon.propTypes = {
  fillOpacity: PropTypes.number,
};

RoundListIcon.defaultProps = {
  fillOpacity: 0.6,
};

export default RoundListIcon;
