import { PREFIX } from './constants';

// **************************************************
// MY SAVED JOURNEYS
// **************************************************

export const FETCH_MY_SAVED_JOURNEYS_REQUEST = `${PREFIX}/FETCH_MY_SAVED_JOURNEYS_REQUEST`;
export const FETCH_MY_SAVED_JOURNEYS_SUCCESS = `${PREFIX}/FETCH_MY_SAVED_JOURNEYS_SUCCESS`;
export const FETCH_MY_SAVED_JOURNEYS_FAILURE = `${PREFIX}/FETCH_MY_SAVED_JOURNEYS_FAILURE`;

export const SAVE_JOURNEY_TO_MY_LIST_REQUEST = `${PREFIX}/SAVE_JOURNEY_TO_MY_LIST_REQUEST`;
export const SAVE_JOURNEY_TO_MY_LIST_SUCCESS = `${PREFIX}/SAVE_JOURNEY_TO_MY_LIST_SUCCESS`;
export const SAVE_JOURNEY_TO_MY_LIST_FAILURE = `${PREFIX}/SAVE_JOURNEY_TO_MY_LIST_FAILURE`;

export const REMOVE_JOURNEY_FROM_MY_LIST_REQUEST = `${PREFIX}/REMOVE_JOURNEY_FROM_MY_LIST_REQUEST`;
export const REMOVE_JOURNEY_FROM_MY_LIST_SUCCESS = `${PREFIX}/REMOVE_JOURNEY_FROM_MY_LIST_SUCCESS`;
export const REMOVE_JOURNEY_FROM_MY_LIST_FAILURE = `${PREFIX}/REMOVE_JOURNEY_FROM_MY_LIST_FAILURE`;

// **************************************************
// TEAM SAVED JOURNEYS
// **************************************************

export const FETCH_TEAM_SAVED_JOURNEYS_REQUEST = `${PREFIX}/FETCH_TEAM_SAVED_JOURNEYS_REQUEST`;
export const FETCH_TEAM_SAVED_JOURNEYS_SUCCESS = `${PREFIX}/FETCH_TEAM_SAVED_JOURNEYS_SUCCESS`;
export const FETCH_TEAM_SAVED_JOURNEYS_FAILURE = `${PREFIX}/FETCH_TEAM_SAVED_JOURNEYS_FAILURE`;

export const SAVE_JOURNEY_TO_TEAM_LIST_REQUEST = `${PREFIX}/SAVE_JOURNEY_TO_TEAM_LIST_REQUEST`;
export const SAVE_JOURNEY_TO_TEAM_LIST_SUCCESS = `${PREFIX}/SAVE_JOURNEY_TO_TEAM_LIST_SUCCESS`;
export const SAVE_JOURNEY_TO_TEAM_LIST_FAILURE = `${PREFIX}/SAVE_JOURNEY_TO_TEAM_LIST_FAILURE`;

export const REMOVE_JOURNEY_FROM_TEAM_LIST_REQUEST = `${PREFIX}/REMOVE_JOURNEY_FROM_TEAM_LIST_REQUEST`;
export const REMOVE_JOURNEY_FROM_TEAM_LIST_SUCCESS = `${PREFIX}/REMOVE_JOURNEY_FROM_TEAM_LIST_SUCCESS`;
export const REMOVE_JOURNEY_FROM_TEAM_LIST_FAILURE = `${PREFIX}/REMOVE_JOURNEY_FROM_TEAM_LIST_FAILURE`;
