import React from 'react';
import PropTypes from 'prop-types';
import styled from "@emotion/styled";

import DropdownIcon from '../../mc-ui/icons/DropdownIcon';

import NavigationLink from './NavigationLink';

const NavigationItem = ({ children, css: customCSS, label, to, ...props }) => {

  const NavigationItemWrapper = styled('div')({
    position: 'relative',
    '& > div': {
      display: 'none',
    },
    '&:hover': {
      '& > div': {
        display: 'block',
      },
    },
    '@media screen and (max-width: 1280px)': {
      marginLeft: '10px',
    },
    ...customCSS,
  });

  return (
    <NavigationItemWrapper>
      <NavigationLink to={to} {...props}>
        {label}
        {children && <DropdownIcon fillOpacity={1} />}
      </NavigationLink>
      {children}
    </NavigationItemWrapper>
  );
};

NavigationItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  css: PropTypes.shape({}),
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.string]).isRequired,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

NavigationItem.defaultProps = {
  children: null,
  css: {},
};

export default NavigationItem;
