import React from 'react';
import PropTypes from 'prop-types';
import { Link as RRLink } from 'react-router';

import { Box, Button, Heading, Link, Text } from '../mc-ui';

export default function Upgrade({ restricted }) {
  if (!restricted) return null;

  return (
    <Box
      backgroundColor="white"
      borderRadius="4px"
      padding="32px"
      position="relative"
      width="338px"
      css={{
        backgroundImage: 'url(/unlocked-upgrade-background.svg)',
        backgroundPosition: 'bottom right',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Heading
        as="h4"
        fontSize="18px"
        lineHeight="24px"
        css={{ marginBottom: '4px', width: '250px' }}
      >
        Get full access with MailCharts Pro
      </Heading>

      <Text color="dark08" fontSize="12px" css={{ marginBottom: '8px' }}>
        View historical data and insights for the entire MailCharts Index. Watch a quick{' '}
        <Link to="https://youtu.be/6j_z8DT48VA" target="_blank" fontSize="12px">
          video
        </Link>{' '}
        to learn the difference between Pro and Free accounts.
      </Text>

      <Button
        as={RRLink}
        to="/manage/plan"
        variant="purple"
        css={{
          fontSize: '12px',
          height: '23px',
          lineHeight: '23px',
          margin: '0',
          padding: '0',
          width: '66px',
        }}
      >
        Upgrade
      </Button>
    </Box>
  );
}

Upgrade.propTypes = {
  restricted: PropTypes.bool.isRequired,
};
