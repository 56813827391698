import React from 'react';

import { Flex, Text } from '../../../../mc-ui';

const EmptyList = () => (
  <Flex alignItems="center" height="300px" justifyContent="center">
    <Text>No emails</Text>
  </Flex>
);

export default EmptyList;
