/** @jsx jsx */
import React from 'react';
import PropTypes from 'prop-types';
import { range } from 'ramda';
import { jsx, withTheme } from '@emotion/react';

import { Flex } from '../../mc-ui';

const Controls = ({ index: selectedIndex, onClick, size, theme }) => (
  <Flex height="20px">
    {range(0, size).map(index => {
      const key = `control${index}`;

      return (
        <a
          key={key}
          href={`#${index}`}
          css={{
            borderBottom: '4px solid',
            borderColor: index === selectedIndex ? theme.colors.blue : theme.colors.dark5,
            boxSizing: 'border-box',
            display: 'inline-block',
            marginRight: '8px',
            overflow: 'hidden',
            textIndent: '-9999px',
            width: '24px',
          }}
          data-index={index}
          onClick={onClick}
        >
          {index + 1}
        </a>
      );
    })}
  </Flex>
);

Controls.propTypes = {
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  size: PropTypes.number.isRequired,
  theme: PropTypes.shape({
    colors: PropTypes.shape({}),
  }).isRequired,
};

export default withTheme(Controls);
