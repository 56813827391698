import { createSelector } from 'reselect';
import { isEmpty, isNil, path } from 'ramda';

import { queryToStr } from './utils';

const getState = state => state.newJourneys || {};

// ********** LIST OF JOURNEYS SELECTORS **********
export const get = (model = {}, query = {}) =>
  createSelector(getState, state => {
    const modelType = model.type === 'companies' ? 'brands' : model.type;
    const journeys = path([modelType, `${model.id}`, queryToStr(query)], state) || {};
    return journeys;
  });

export const getData = (model, query) =>
  createSelector(get(model, query), journeys => path(['data', 'data'], journeys) || []);
export const getLinks = (model, query) =>
  createSelector(get(model, query), journeys => path(['data', 'links'], journeys) || {});
export const getMeta = (model, query) =>
  createSelector(get(model, query), journeys => path(['data', 'meta'], journeys) || {});
export const getLoading = (model, query) =>
  createSelector(
    get(model, query),
    journeys => (isNil(journeys.loading) ? true : journeys.loading),
  );

export const isJourneysListEmpty = (model, query) =>
  createSelector(get(model, query), journeys => {
    const key = queryToStr(query);
    const data = path(['data', 'data'], journeys) || [];

    return key === 'empty' && isEmpty(data);
  });

// ********** INDIVIDUAL JOURNEY SELECTORS **********
export const getJourneyModel = (model, id, query) =>
  createSelector(getState, state => {
    const modelType = model.type === 'companies' ? 'brands' : model.type;
    const journeyModel = path([modelType, `${model.id}`, `${id}`, queryToStr(query)], state) || {};
    return journeyModel;
  });

export const getJourney = (model, id, query) =>
  createSelector(getJourneyModel(model, id, query), journeyModel => journeyModel.data || {});
export const getJourneyLoading = (model, id, query) =>
  createSelector(
    getJourneyModel(model, id, query),
    journeyModel => (isNil(journeyModel.loading) ? true : journeyModel.loading),
  );
export const getJourneyMeta = (model, id, query) =>
  createSelector(getJourneyModel(model, id, query), journeyModel => journeyModel.meta || {});
