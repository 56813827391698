import React from 'react'

class ScoreValue extends React.Component {
  render() {
    let img = this.props.value == 'failure' ? 'score-fail' : this.props.value == 'success' ? 'score-success' : 'score-neutral'
    let color = this.props.value == 'failure' ? '#DD2325' : this.props.value == 'success' ? '#7ECA5B' : '#D4D9DE'
    let label = this.props.label
    let description = 'Description'
    switch (this.props.label) {
      case 'mobile':
        label = 'Mobile optimized';
        description = 'Over 50% of emails are opened on mobile, it is vital to engage with recipients on their turf.'
        break;
      case 'no_reply':
        label = 'Replyable email address';
        description = 'Ensure customers can engage with you. Using catch-alls like `no-reply` is a `no-no`.'
        break;
      case 'html_weight':
        label = 'Gmail-friendly HTML weight';
        description = 'Gmail "clips" emails if they are heavier than 102kb.'
        break;
      case 'smtp_headers':
        label = 'Passing DKIM & SPF checks';
        description = 'Failing DKIM or SPF can lead to dramatic deliverability woes.'
        break;
      case 'spam_flag':
        label = 'Spam filter compliant';
        description = 'Ensure content and structure of each email is not "spammy".'
        break;
      case 'ph_optimized':
        label = 'Optimized preview text';
        description = 'Preview text is vital to encouraging opens. Avoid wasteful words such as "click here" or "preview".'
        break;
      case 'sl_length': 
        label = 'Inbox-friendly subject line length'; 
        description = 'Your subject line has one job: to get the email opened. It’s very difficult for your subject line to do it’s job when it gets interrupted (aka: truncated) halfway through. This is why we recommend using shorter subject lines.'
        break;
      case 'image_weight':
        label = 'Sensible image weight'; 
        description = 'Heavier emails take longer to load on mobile and slow connections. Ensure images are less than 800kb.'
        break;
    }
    return (
      <div className='pure-u-1'>
        <div style={{ padding: 5 }}>
          <div className='pure-u-1'>
            <img src={`/${img}.png`} className='pure-image' style={{ width: 13, display: 'inline-block', marginRight: 5 }} />
            <h5 style={{ margin: 0, color: color, fontSize: `${13/16}em`, textTransform: 'uppercase', display: 'inline-block' }}>{label}</h5>
          </div>
          {this.props.description ?
          <div className='pure-u-23-24'>
            {this.props.children}
          </div> : null}
        </div>
      </div>
    )
  }
}

export default ScoreValue
