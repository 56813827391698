import styled from "@emotion/styled";

const SelectedFiltersWrapper = styled("div")({
  display: 'flex',
  flexWrap: 'wrap',
  '& > a': {
    backgroundColor: '#fff',
    border: '1px solid #D4D9DD',
    borderRadius: '3px',
    boxSizing: 'border-box',
    color: '#646464',
    fontSize: `${13 / 16}em`,
    lineHeight: `${30 / 13}em`,
    margin: '0 8px 8px 0',
    padding: '0 16px',
    position: 'relative',
    textDecoration: 'none',
    '& strong': {
      color: '#0379d5',
    },
    '& img': {
      height: '10px',
      marginLeft: '8px',
    },
  },
});

export default SelectedFiltersWrapper;
