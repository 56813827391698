import React from 'react'
import PropTypes from 'prop-types'

const HelpIcon = ({ tooltipId }) => {
  const styles = {
    color: '#0379d5',
    cursor: 'pointer',
    display: 'inline-block',
    fontSize: '16px',
    height: '16px',
    width: '14px'
  }

  return (
    <span style={styles} data-tip data-for={tooltipId}>
      <i className='ion-help-circled' />
    </span>
  )
}

HelpIcon.propTypes = {
  tooltipId: PropTypes.string.isRequired
}

export default HelpIcon
