import React from 'react';
import PropTypes from 'prop-types';
import styled from "@emotion/styled";
import { take } from 'ramda';

import { Flex } from '../../../../mc-ui';
import Email from '../../../../ui/Email';

const Wrapper = styled("div")({
  marginLeft: '8px',
  overflow: 'hidden',
  '&:last-child, &:hover': {
    overflow: 'visible',
  },
});

const Emails = ({ emails }) => (
  <Flex justifyContent="flex-end" width="unset">
    {take(4, emails).map(email => (
      <Wrapper key={email.attributes.guid}>
        <Email email={email} height="40px" width="40px" css={{ cursor: 'pointer', margin: '0' }} />
      </Wrapper>
    ))}
  </Flex>
);

Emails.propTypes = {
  emails: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Emails;
