import React from 'react';
import PropTypes from 'prop-types';
import humanize from 'humanize-number';
import moment from 'moment';
import pluralize from 'pluralize';
import { take } from 'ramda';

import BrandLogo from '../../../ui/BrandLogo';
import EmailIcon from '../../../mc-ui/icons/EmailIcon';
import EmailThumbnail from '../../../ui/EmailThumbnail';
import PlusIcon from '../../../mc-ui/icons/PlusIcon';
import { Box, Flex, Heading, Text } from '../../../mc-ui';

import * as S from './styles';
import AddToGroupButton from '../../../AddToGroupButton';
import BrandTag from '../../../BrandTag';

function fixedValue(value) {
  let number = null;
  if (!value || value === 'NaN' || Number.isNaN(value)) {
    number = 0;
  } else {
    number = Number(value);
  }

  return number.toFixed(2);
}

export default function BrandRow({ brand }) {
  const approximateEmails =
    brand.attributes.approximateEmails || brand.attributes.approximate_emails;
  const firstEmailSentAt =
    brand.attributes.firstEmailSentAt ||
    brand.attributes.first_email_sent_at ||
    brand.attributes.createdAt;

  return (
    <Flex
      alignItems="flex-start"
      borderBottom="1px solid"
      borderColor="dark01"
      justifyContent="space-between"
      padding="24px 12px 24px 0"
    >
      <S.Link to={`/companies/${brand.id}`}>
        <BrandLogo brand={brand} css={{ height: '40px', width: '40px' }} />
      </S.Link>

      <S.Link to={`/companies/${brand.id}`}>
        <Box width="480px">
          <Flex alignItems="center">
            <Heading
              as="h4"
              css={{
                fontSize: '16px',
                lineHeight: '20px',
                marginBottom: '8px',
                marginRight: '8px',
              }}
            >
              {brand.attributes.name}
            </Heading>
            {brand.attributes.state && <BrandTag state={brand.attributes.state} />}
          </Flex>

          <Flex alignItems="center" color="dark06" justifyContent="space-between">
            <Flex alignItems="center" width="unset">
              <EmailIcon fillOpacity={1} height="20px" width="20px" />
              <Text color="dark08" margin="0 0 0 6px">
                Avg. Emails / Week: <strong>{fixedValue(brand.attributes.frequency)}</strong>
              </Text>
            </Flex>
            <Text color="dark08" width="220px">
              {humanize(approximateEmails || 0)} {pluralize('email', approximateEmails)} since{' '}
              {moment(firstEmailSentAt).format('MMM YYYY')}
            </Text>
          </Flex>
        </Box>
      </S.Link>

      <Flex justifyContent="space-between" width="192px">
        {take(4, brand.relationships.emails.data).map(email => (
          <EmailThumbnail
            key={`searchResults-brand-${brand.id}-email-${email.id}`}
            brand={brand}
            email={email}
            tiny
          />
        ))}
      </Flex>

      <Flex
        alignItems="center"
        height="49px"
        width="24px"
        css={{ '& > div': { display: 'inline-flex' } }}
      >
        <AddToGroupButton
          brands={[brand]}
          variant="none"
          css={{
            height: '24px !important',
            width: '24px !important',
            '& > button': {
              display: 'inline-flex',
              fontSize: '24px',
              height: '24px',
              lineHeight: '24px',
              width: '24px !important',
            },
          }}
        >
          <PlusIcon />
        </AddToGroupButton>
      </Flex>
    </Flex>
  );
}

BrandRow.propTypes = {
  brand: PropTypes.shape({
    attributes: PropTypes.shape({
      approximateEmails: PropTypes.number,
      createdAt: PropTypes.string,
      firstEmailSentAt: PropTypes.string,
      frequency: PropTypes.number,
      name: PropTypes.string,
    }),
    relationships: PropTypes.shape({
      emails: PropTypes.shape({
        data: PropTypes.array,
      }),
    }),
  }).isRequired,
};
