/** @jsx jsx */
import { jsx } from '@emotion/react';
import React from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import { Form, Field } from 'react-final-form';
import { isEmpty } from 'ramda';

import { Select } from '../../../mc-ui';
import RestrictedTooltip from '../../../RestrictedTooltip';

import { singleValueWithModel } from './SingleValue';

export const DEFAULT_SELCTED_BRAND = { value: 'premium', label: 'Mailcharts Index' };

class CompareBrandsForm extends React.Component {
  componentDidMount() {
    const { brandsOptions, loading, restricted } = this.props;

    if (restricted) return;

    if (!loading && !isEmpty(brandsOptions)) {
      this.selectBrandFromQuery();
    }
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.restricted) return false;
    return (
      nextProps.loading !== this.props.loading ||
      nextProps.selectedBrand.value !== this.props.selectedBrand.value ||
      (!isEmpty(nextProps.brandsOptions) && isEmpty(this.props.brandsOptions))
    );
  }

  componentDidUpdate() {
    const { brandsOptions, loading, restricted } = this.props;

    if (restricted) return;

    if (!loading && !isEmpty(brandsOptions)) {
      this.selectBrandFromQuery();
    }
  }

  handleOnChange = brandId => {
    const { brandsOptions, setBrand } = this.props;
    const selectedBrand =
      brandId === 'premium'
        ? DEFAULT_SELCTED_BRAND
        : brandsOptions.find(brand => brand.value === brandId);

    setBrand(selectedBrand);
    this.updateQuery(brandId);
  };

  selectBrandFromQuery = () => {
    const query = qs.parse(this.props.router.location.search.replace('?', ''));
    const brandId = query.company_id || 'premium';

    this.handleOnChange(brandId);
  };

  updateQuery = brandId => {
    const { router } = this.props;

    router.push({
      pathname: router.location.pathname,
      search: brandId !== 'premium' ? `?company_id=${brandId}` : '',
    });
  };

  render() {
    const { brandsOptions, loading, model, restricted, selectedBrand } = this.props;
    // PLACE `PREMIUM` AT THE TOP OF THE LIST:
    let initialValues = {};
    let options = [];

    if (!restricted) {
      initialValues = { brandId: selectedBrand.value };
      options = [
        ...brandsOptions.filter(brandsOption => brandsOption.value === 'premium'),
        {
          label: `Select a brand to compare to ${model.attributes.name}`,
          options: brandsOptions.filter(brandsOption => brandsOption.value !== 'premium'),
        },
      ];
    }

    return (
      <RestrictedTooltip
        heading='Unlock Comparisons'
        text='Easily benchmark any brand against its group to compare key metrics and gain deeper insight.'
        restricted={restricted}
      >
        <Form onSubmit={() => {}} initialValues={initialValues}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div
                css={{ width: '360px' }}
              >
                <Field
                  component={Select}
                  isDisabled={restricted || loading}
                  id="brandId"
                  name="brandId"
                  placeholder="Select a brand to compare"
                  limit={5}
                  options={options}
                  onChange={restricted ? () => {} : this.handleOnChange}
                  components={{ SingleValue: singleValueWithModel(model) }}
                />
              </div>
            </form>
          )}
        </Form>
      </RestrictedTooltip>
    );
  }
}

CompareBrandsForm.defaultProps = {
  restricted: false,
  selectedBrand: {},
};

CompareBrandsForm.propTypes = {
  brandsOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  loading: PropTypes.bool.isRequired,
  model: PropTypes.shape({ id: PropTypes.string, type: PropTypes.string }).isRequired,
  restricted: PropTypes.bool,
  router: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
  selectedBrand: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  setBrand: PropTypes.func.isRequired,
};

export default CompareBrandsForm;
