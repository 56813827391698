import React from 'react';
import { camelizeKeys } from 'humps';
import { take } from 'ramda';

import api from '../../lib/api';

import Carousel from '../ui/Carousel';

async function fetchFeaturedGroups() {
  const response = await api.get('/v3/groups?size=7&featured=true');
  return camelizeKeys(take(7, response.data.data));
}

class FeaturedGroups extends React.PureComponent {
  state = {
    groups: [],
    loading: true,
  };

  componentDidMount() {
    this.request();
  }

  request = async () => {
    const groups = await fetchFeaturedGroups();

    this.setState(() => ({
      groups,
      loading: false,
    }));
  };

  render() {
    const { groups, loading } = this.state;

    return <Carousel collection={groups} loading={loading} type="featuredGroups" />;
  }
}

export default FeaturedGroups;
