import { createSelector } from 'reselect';

import { teamsSelectors } from '../teams';

export const { isMembershipActiveSelector, teamSelector } = teamsSelectors;

const getUser = state => state.user;
export const getState = state => state.customLists;

export const isCreateFormOpenSelector = createSelector(getState, state => !!state.isCreateFormOpen);
export const isEditFormOpenSelector = list =>
  createSelector(getState, state => {
    if (!state.isEditFormOpen) return false;
    return Number(list.id) === Number(state.isEditFormOpen);
  });

export const isOwnerSelector = list =>
  createSelector(getUser, users => {
    if (users.loading) return false;
    const [user] = users.data;
    return Number(user.id) === Number(list.attributes.user_id || list.attributes.userId);
  });

export const isTeamListsLoadingSelector = createSelector(
  getState,
  state => !!state.teamLists.loading,
);

export const teamListsDataSelector = createSelector(getState, state => state.teamLists.data || []);
export const teamListIdsSelector = createSelector(getState, state => state.teamListIds || []);

export const isUserListsLoadingSelector = createSelector(
  getState,
  state => !!state.userLists.loading,
);

export const userListsDataSelector = createSelector(getState, state => state.userLists.data || []);
export const userListIdsSelector = createSelector(getState, state => state.userListIds || []);

export const isValidTeamSelector = createSelector(
  teamSelector,
  isMembershipActiveSelector,
  (team, isMembershipActive) => team && isMembershipActive,
);
