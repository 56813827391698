import React from 'react';

import Carousel from '../ui/Carousel';

import { fetchFeaturedHolidays } from './utils/api';

class FeaturedHolidays extends React.PureComponent {
  state = {
    holidays: [],
    loading: true,
  };

  componentDidMount() {
    this.request();
  }

  request = async () => {
    const holidays = await fetchFeaturedHolidays();

    this.setState(() => ({
      holidays,
      loading: false,
    }));
  };

  render() {
    const { loading, holidays } = this.state;

    return <Carousel collection={holidays} loading={loading} type="featuredHolidays" />;
  }
}

export default FeaturedHolidays;
