import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Flex, Loader } from '../../mc-ui';

import sortGroups from '../utils/sortGroups';

import GroupViews from '../GroupViews';
import Navigation from '../Navigation';

import CreateGroup from './CreateGroup';
import EmptyGroup from './EmptyGroup';

class MyGroups extends React.PureComponent {
  state = {
    selectedGroup: this.props.groups[0],
  };

  selectGroup = id => event => {
    event.preventDefault();
    const group = this.props.groups.find(g => g.id === id);
    this.setState(() => ({ selectedGroup: group }));
  };

  renderView = () => {
    const { type } = this.props;
    const { selectedGroup } = this.state;

    let view = null;
    if (selectedGroup.id === 'createGroup') {
      view = <CreateGroup />;
    } else if (selectedGroup.attributes.approximateCompanies === 0) {
      view = <EmptyGroup group={selectedGroup} />;
    } else {
      view = <GroupViews group={selectedGroup} type={type} />;
    }

    return view;
  };

  render() {
    const { loading, groups } = this.props;
    const { selectedGroup } = this.state;

    if (loading) {
      return (
        <Flex alignItems="center" height="676px" justifyContent="center">
          <Loader />
        </Flex>
      );
    }

    return (
      <Flex justifyContent="space-between">
        <Navigation
          items={groups}
          loading={loading}
          onClick={this.selectGroup}
          selectedItem={selectedGroup}
        />

        {this.renderView()}
      </Flex>
    );
  }
}

MyGroups.propTypes = {
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
  loading: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  loading: state.user.loading,
  groups: sortGroups((state.user.included || []).filter(i => i.type === 'groups')),
});

export default connect(mapStateToProps)(MyGroups);
