import * as types from './types';

const INITIAL_STATE = {
  data: [],
  included: [],
  links: {},
  loading: true,
  meta: {},
  paginating: true,
  params: {},
};

const resetReducer = () => ({ ...INITIAL_STATE });

const setLoadingReducer = (state, { params }) => ({ ...state, loading: true, params });

const setPaginatingReducer = state => ({ ...state, paginating: true });

const setDataReducer = (state, { payload }) => ({ ...state, ...payload, loading: false });

const setPaginatedDataReducer = (state, { payload }) => ({
  ...state,
  ...payload,
  data: [].concat(state.data || [], payload.data),
  included: [].concat(state.included || [], payload.included || []),
  paginating: false,
});

const reducers = {
  // FLUSH: resetReducer,
  [types.REQUEST]: setLoadingReducer,
  [types.REQUEST_PAGINATION]: setPaginatingReducer,
  [types.RECEIVE]: setDataReducer,
  [types.RECEIVE_PAGINATION]: setPaginatedDataReducer,
  [types.RESET]: resetReducer,
};

export default (state = INITIAL_STATE, action = {}) => {
  const reducer = reducers[action.type];

  if (!reducer) return state;
  return reducer(state, action);
};
