/* eslint jsx-a11y/no-static-element-interactions: "off" */
/* eslint jsx-a11y/click-events-have-key-events: "off" */
/* eslint jsx-a11y/anchor-is-valid: "off" */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

import styles from '../styles.css';

const OldModal = ({ children, handleClick, handleClose, modalStyle, wrapperStyle }) => {
  const { minHeight, width, height } = modalStyle;
  const marginLeft = width ? -(width / 2) : undefined;

  return (
    <div>
      <div onClick={handleClick} className={styles['modal-overlay']}>
        <div
          className={styles.modal}
          style={{
            width,
            marginLeft,
            minHeight,
            height,
          }}
        >
          <div className={styles['modal-body']}>
            <div className="pure-g">
              <div className="pure-u-1">
                <div style={{ textAlign: 'right', padding: '15px 0' }} className="pure-u-1">
                  <Link to="#" onClick={handleClose} style={{ marginRight: 15 }}>
                    <img src="/close.png" width="18" alt="Close Modal" />
                  </Link>
                </div>
                <div style={wrapperStyle}>{children}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

OldModal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]).isRequired,
  handleClick: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  modalStyle: PropTypes.shape({}),
  wrapperStyle: PropTypes.shape({}),
};

OldModal.defaultProps = {
  modalStyle: {},
  wrapperStyle: null,
};

export default OldModal;
