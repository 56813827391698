const INITIAL_STATE = { loading: true, data: [] }
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'REQUEST_LISTS':
      return { ...state, loading: true }
    case 'RECEIVE_LISTS':
      return { ...action.data, loading: false }
    case 'CATCH_ERROR':
      return { ...action.error, loading: false};
    case 'FLUSH':
      return INITIAL_STATE
    case 'REQUEST_LISTS_PAGINATION':
      return { ...state, paginating: true }
    case 'RECEIVE_LISTS_PAGINATION':
      const data = [].concat(state.data || [], action.lists.data)
      return { ...state, data, paginating: false, links: action.lists.links }
    case 'UPDATE_LIST_SUCCESS':
      return { ...action.list, loading: false}
    default:
      return state
  }
}
