import React from 'react';
import PropTypes from 'prop-types';

import BrandRow from './BrandRow';
import LoadMoreButton from './LoadMoreButton';

export default function Brands({ collection, ...props }) {
  return (
    <div>
      {collection.data.map(item => (
        <BrandRow key={`brandResult-BrandRow-${item.id}`} brand={item} {...props} />
      ))}

      <LoadMoreButton collection={collection} {...props} />
    </div>
  );
}

Brands.propTypes = {
  collection: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      }),
    ),
    links: PropTypes.shape({
      next: PropTypes.string,
    }),
  }).isRequired,
};
