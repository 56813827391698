import styled from "@emotion/styled";

import Container from '../../Container';

const wrapperStyle = {
  boxSizing: 'border-box',
  margin: '0 !important',
  padding: '24px !important',
  width: '100%',
};

const formStyle = {
  paddingBottom: '48px',
  '& fieldset': {
    margin: '0',
    padding: '0',
  },
  '& .keywordsGroup': {
    width: '100%',
    '& .fieldsWrapper': {
      alignItems: 'flex-end',
      display: 'flex',
      '& label.filter': {
        flex: '3',
      },
      '& label.query': {
        flex: '8',
      },
      '& .buttonGroup': {
        flex: '1',
        paddingLeft: '20px',
        '& [type=submit]': {
          float: 'right',
          fontSize: `${13 / 16}em`,
          padding: '11px 24px',
        },
      },
    },
  },
  '& .fieldsets': {
    display: 'flex',
    justifyContent: 'space-between',
  },
  '& .selects': {
    display: 'inline-block',
    width: '50%',
    '& .fieldsWrapper': {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      '& label': {
        marginTop: '26px',
        width: '48%',

        '& .DateRangePickerWrapper': {
          padding: '2px 0',
        },
      },
    },
  },
  '& .options': {
    boxSizing: 'border-box',
    display: 'inline-block',
    width: '50%',
    '& .subjectLineLength, & .imageWeight, & .otherOptions': {
      boxSizing: 'border-box',
      display: 'inline-block',
      paddingLeft: '30px',
      width: '33.33%',
      '& .fieldsWrapper': {
        display: 'flex',
        flexDirection: 'column',
        '& > h5': {
          marginTop: '26px',
        },
        '& label': {
          fontSize: `${13 / 16}em`,
          lineHeight: `${32 / 13}em`,
          '& > input': {
            marginRight: '8px',
          },
        },
      },
    },
  },
  '& h5': {
    lineHeight: `${18 / 12}em`,
    margin: '0 0 6px 0',
    padding: '0',
  },
  '& label': {
    margin: '0',
    padding: '0',
    '& input, & select': {
      border: '1px solid #d4d9de',
      color: '#242424',
      fontSize: `${13 / 16}em`,
      maxHeight: '40px',
      padding: '11px 9px',
    },
    '&.filter select': {
      borderRadius: '3px 0 0 3px',
    },
    '&.query input': {
      borderLeft: 'none',
      borderRadius: '0 3px 3px 0',
    },
  },
};

const FiltersWrapper = styled(Container)(() => ({
  '& > div': {
    ...wrapperStyle,
    '& > form.filtersForm': formStyle,
  },
}));

export default FiltersWrapper;
