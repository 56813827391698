import styled from "@emotion/styled";

export const Wrapper = styled("section")(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.colors.white,
  borderRadius: '4px',
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '16px',
  padding: '16px',
}));
