import React, { Component } from 'react';

import Papa from 'papaparse'
import ellipsisis from 'ellipsisis';
import api from '../lib/api';

import Section from './Section'
import Container from './Container'

class Batch extends Component {
  state = {
    status: '',
    domain_name_key: '',
    source: null,
    results: null,
  }
  downloadCSV = (event) => {
    event.preventDefault()
    const data = this.state.results.data.map(d => d.attributes)
    const serialized = Papa.unparse(data)
    const csv = `data:text/csv;charset=utf-8,${serialized}`
    window.open(encodeURI(csv))
  }
  updateDomain = (event) => {
    const domain_name_key = event.target.value
    this.setState({ domain_name_key })
  }
  process = (event) => {
    event.preventDefault()
    const status = 'processing'
    this.setState({ status, results: null })
    const { source, domain_name_key } = this.state
    api.post(`/v2/companies/batch`, { source, domain_name_key })
      .then(res => {
        const status = 'complete'
        const results = res.data
        this.setState({ status, results })
      })
  }
  handleChange = (event) => {
    const file = event.target.files[0]
    if (!file) return
    this.setState({ source: null, status: '', results: null })
    new Promise((resolve, reject) => {
      Papa.parse(file, { header: true, complete: resolve, error: reject  })
    })
    .then(source => {
      const domain_name_key = source.meta.fields[0]
      this.setState({ source, domain_name_key })
    })
  }

  render() {
    return (
      <Section>
        <Container>
          <h3>Upload CSV</h3>
          <form onChange={this.handleChange} className='pure-form'>
            <fieldset>
              <input type='file' />
            </fieldset>
          </form>
        </Container>
        {this.state.source &&
          <Container>
            <h5>Select the column with URL</h5>
            <form onSubmit={this.process} className='pure-form'>
              <fieldset>
                <select value={this.state.domain_name_key} onChange={this.updateDomain}>
                  {this.state.source.meta.fields.map((header, idx) => {
                    return <option value={header} key={idx}>{header}</option>
                  })}
                </select>
              </fieldset>
              <fieldset>
                <input type='submit' className='pure-button pure-button-primary' />
              </fieldset>
            </form>
          </Container>
        }
        {this.state.results &&
          <Container>
            <div className='pure-u-1'>
              <p>
                <a
                  href='#'
                  className='pure-button'
                  onClick={this.downloadCSV}
                >
                  Download CSV
                </a>
              </p>
            </div>
            <div className='pure-u-1' style={{ overflowX: 'scroll' }}>
              <table className='pure-table pure-table-striped'>
                <thead>
                  <tr>
                  {Object.keys(this.state.results.data[0].attributes).map((col, idx) => {
                    const length = col.length
                    const value = length > 40 ? `${col.slice(0, 40)} ...` : col
                    return (<th key={idx}>{value}</th>)
                  })}
                  </tr>
                </thead>
                <tbody>
                  {this.state.results.data.map((row, idx) => {
                    return (<tr key={idx}>
                      {Object.values(row.attributes).map((col, i) => {
                        const length = col.length
                        const value = length > 40 ? `${col.slice(0, 40)} ...` : col
                        return (<td key={i}>{value}</td>)
                      })}
                    </tr>)
                  })}
                </tbody>
              </table>
            </div>
          </Container>
        }
      </Section>
    )
  }
}

export default Batch
