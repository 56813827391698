import React from 'react';
import PropTypes from 'prop-types';

import { Flex, Loader } from '../../mc-ui';

import { fetchJourneys } from '../utils/api';

import Navigation from '../Navigation';
import List from './List';

class Journeys extends React.PureComponent {
  state = {
    journeys: [],
    loading: true,
    selectedJourney: null,
  };

  componentDidMount() {
    this.request();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filter !== this.props.filter) {
      this.request();
    }
  }

  request = async () => {
    this.setState(
      () => ({ loading: true }),
      async () => {
        const journeys = await fetchJourneys();

        this.setState(() => ({
          journeys,
          loading: false,
          selectedJourney: journeys[0],
        }));
      },
    );
  };

  selectJourney = type => event => {
    event.preventDefault();

    const { journeys = [], } = this.state
    const selectedJourney = journeys.find(j => type == j.type)
    this.setState(() => ({ selectedJourney }));
  };

  render() {
    const { journeys, loading, selectedJourney } = this.state;

    if (loading) {
      return (
        <Flex alignItems="center" height="676px" justifyContent="center">
          <Loader />
        </Flex>
      );
    }

    const items = journeys.map(j => {
      j.id = j.type
      return j
    })

    return (
      <Flex justifyContent="space-between">
        <Navigation
          items={items}
          type={'journeys'}
          loading={loading}
          onClick={this.selectJourney}
          selectedItem={selectedJourney}
        />

        <List journey={selectedJourney} {...this.props} />
      </Flex>
    );
  }
}

Journeys.propTypes = {
  filter: PropTypes.string.isRequired,
};

export default Journeys;
