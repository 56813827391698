import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";


import { couponSelectors } from '../../../modules/coupon';
import { teamsActions, teamsSelectors } from '../../../modules/teams';

import api from '../../../lib/api';
import { dollar } from '../../../lib/utils';
import { Box, Flex, Heading, Link, Text } from '../../mc-ui';

import * as S from './styles';

import OldPlanNotice from '../OldPlanNotice';
import PlanExpiredNotice from '../PlanExpiredNotice';
import ReactivateNotice from '../ReactivateNotice';

import Checkout from './Checkout';
import IntervalSwitcher from './IntervalSwitcher';
import ProFeatures from './ProFeatures';

const DEFAULT_INTERVAL = 'month';
export const AMOUNTS = {
  month: 14900,
  monthMember: 4900,
  year: 149900,
  yearMember: 49900,
};


function boxColors(isCanceled, isPro, planExpired, selected) {
  if (isPro && !planExpired) {
    return {
      backgroundColor: 'blueLight',
      borderColor: 'blue',
    };
  }

  if (planExpired || selected || isCanceled) {
    return {
      backgroundColor: 'white',
      borderColor: 'blue',
    };
  }

  return {
    backgroundColor: 'white',
    borderColor: 'dark5',
  };
}

class ProOption extends React.Component {
  state = {
    interval: this.props.subscription.attributes.plan_interval || DEFAULT_INTERVAL,
  };

  stripePromise = loadStripe(process.env.STRIPE_PUBLISH_KEY);

  componentDidUpdate(prevProps) {
    const prevPlanInterval = (prevProps.subscription || { attributes: {} }).attributes
      .plan_interval;
    const currentPlanInterval = (this.props.subscription || { attributes: {} }).attributes
      .plan_interval;

    if (
      prevProps.subscription.id !== this.props.subscription.id ||
      prevPlanInterval !== currentPlanInterval
    ) {
      this.setInterval();
    }
  }

  setInterval = () => {
    this.setState({
      interval: this.props.subscription.attributes.plan_interval || DEFAULT_INTERVAL,
    });
  };

  calculateInterval = () => {
    const { calculateSubscription, isAnOldPlan, membersMeta } = this.props;
    const { interval } = this.state;

    if (membersMeta.counts && membersMeta.counts.active) {
      calculateSubscription(membersMeta.counts.active, interval, isAnOldPlan);
    }
  };

  componentDidMount(){
    this.setupPaymentIntent();
  }

  calculateMembersAmount = (amount) => {
    const { membersMeta } = this.props;
    return (membersMeta.counts.active - 1) * amount;
  };

  handleChangeInterval = (interval,event) => {
    event.preventDefault();

    const { isOwner, team } = this.props;
    if (!isOwner && team) return;

    this.setState(() => ({ interval }), this.calculateInterval);
  };

  async setupPaymentIntent(){
    const {getPaymentIntent, subscription} = this.props
    getPaymentIntent(subscription.id);
  }

  render() {
    const {
      coupon,
      isAnOldPlan,
      isCanceled,
      isCancelingSubscription,
      isFree,
      isOwner,
      isPro,
      hasSmsAddon,
      isSubscriptionInvoiced,
      membersMeta,
      planExpired,
      selected,
      subscription,
      subscriptionPrice,
      subscriptionPriceLoading,
      team,
      toggleSelect,
      setupIntent
    } = this.props;

    const options = {
      clientSecret: setupIntent.client_secret,
      appearance: {
        theme: 'stripe',
      },
    }
    if (team && isSubscriptionInvoiced) return null;

    const { interval } = this.state;

    const { backgroundColor, borderColor } = boxColors(isCanceled, isPro, planExpired, selected);

    const isActive = team && team.attributes.account_status === 'active';
    const isTrialing = team && team.attributes.account_status === 'trialing';
    const notTrialing = team && team.attributes.account_status !== 'trialing';

    const planAmount =
      subscriptionPrice && isPro
        ? subscriptionPrice.attributes.next_invoice_amount
        : AMOUNTS[interval];

    const memberAmount = subscriptionPrice && isPro ? null : AMOUNTS[`${interval}Member`];

    let amount = 0;
    if (membersMeta.counts && membersMeta.counts.active > 1 && !subscriptionPrice) {
      const members = membersMeta.counts.active - 1;
      amount = planAmount + memberAmount * members;
    } else {
      amount = planAmount;
    }

    let amountWithDiscount = null;
    if (coupon) {
      if (coupon.attributes.valid !== true) {
        amountWithDiscount = null;
      } else if (coupon.attributes.amountOff) {
        amountWithDiscount = amount - coupon.attributes.amountOff;
      } else if (coupon.attributes.percentOffPrecise) {
        amountWithDiscount = amount - amount * (coupon.attributes.percentOffPrecise / 100);
      } else if (coupon.attributes.percentOff) {
        amountWithDiscount = amount - amount * (coupon.attributes.percentOff / 100);
      }
    }

    return (
      <Box
        backgroundColor={backgroundColor}
        border="2px solid"
        borderColor={borderColor}
        borderRadius="8px"
        minHeight="84px"
        padding="24px"
      >
        <Flex alignItems="center" justifyContent="space-between">
          <S.PlanChecker
            checked={isActive && !planExpired}
            onClick={toggleSelect}
            planName="Pro"
            selected={
              (isFree && selected) || ((isActive && planExpired) || isTrialing) || isCanceled
            }
          />

          {(isPro || selected || isCanceled) &&
            (isFree || isOwner) &&
            !isCancelingSubscription && (
              <IntervalSwitcher
                amount={amountWithDiscount || amount}
                interval={interval}
                isAnOldPlan={isAnOldPlan}
                isOwner={isOwner}
                isPro={isPro}
                onSwitch={this.handleChangeInterval}
                planExpired={planExpired}
                subscription={subscription}
                subscriptionPriceLoading={subscriptionPriceLoading}
                team={team}
              />
            )}
        </Flex>

        {memberAmount &&
          membersMeta.counts &&
          membersMeta.counts.active > 1 &&
          !isCancelingSubscription &&
          isOwner &&
          (!subscriptionPriceLoading || isTrialing || isCanceled) && (
            <Text textAlign="right" css={{ marginTop: '4px' }}>
              <em>
                {dollar(planAmount, { fromCents: true })}/{interval} +{' '}
                {dollar(this.calculateMembersAmount(memberAmount), { fromCents: true })}/{interval}{' '}
                for {membersMeta.counts.active - 1} teammate(s)
              </em>
            </Text>
          )}

        {(isPro || selected || isCanceled) &&
          (isFree || isOwner) &&
          !isCancelingSubscription &&
          amountWithDiscount && (
            <Text textAlign="right" css={{ marginTop: '4px' }}>
              <em>Discount of {dollar(amount - amountWithDiscount, { fromCents: true })}</em>
            </Text>
          )}

        {isCancelingSubscription &&
          isOwner && (
            <Box padding="24px 0 16px 0">
              <ReactivateNotice />
            </Box>
          )}

        {!isCancelingSubscription &&
          isOwner &&
          isAnOldPlan && (
            <Box padding="24px 0 16px 0">
              <OldPlanNotice />
            </Box>
          )}

        {((isPro && planExpired) || isCanceled) && (
          <Box padding="24px 0 16px 0">
            <PlanExpiredNotice />
          </Box>
        )}

        <Flex direction={isFree || !isOwner ? 'column' : 'column-reverse'}>
          <ProFeatures
            interval={interval}
            isCanceled={isCanceled}
            isFree={isFree}
            isPro={isPro}
            hasSmsAddon={hasSmsAddon}
            planExpired={planExpired}
            selected={selected}
            toggleSelect={toggleSelect}
          />

          {notTrialing &&
            !isOwner &&
            !planExpired && (
              <Heading
                as="h4"
                fontSize="16px"
                fontWeight="500"
                lineHeight="20px"
                css={{ marginTop: '24px' }}
              >
                Your subscription is managed by your team owner. Please visit{' '}
                <Link to="/manage/teams" fontSize="16px">
                  team management
                </Link>
                .
              </Heading>
            )}

          {((isOwner && isPro) || selected || (isOwner && isCanceled)) &&
            !isCancelingSubscription && this.props.setupIntent.client_secret && (
              <div>
                <Elements options={options} stripe={this.stripePromise}>
                  <Checkout
                    interval={interval}
                    onCancel={toggleSelect}
                    selectedAmount={amount}
                    team={team}
                    amount={planAmount}
                  />
                </Elements>
              </div>
            )}
        </Flex>
      </Box>
    );
  }
}

ProOption.defaultProps = {
  coupon: null,
  membersMeta: { counts: { active: 1 } },
  selected: false,
  subscriptionPrice: null,
  team: null,
};

ProOption.propTypes = {
  calculateSubscription: PropTypes.func.isRequired,
  coupon: PropTypes.shape({
    attributes: PropTypes.shape({
      amountOff: PropTypes.number,
      percentOff: PropTypes.number,
      percentOffPrecise: PropTypes.number,
    }),
  }),
  isAnOldPlan: PropTypes.bool.isRequired,
  isCanceled: PropTypes.bool.isRequired,
  isCancelingSubscription: PropTypes.bool.isRequired,
  isFree: PropTypes.bool.isRequired,
  isOwner: PropTypes.bool.isRequired,
  isPro: PropTypes.bool.isRequired,
  hasSmsAddon: PropTypes.bool,
  isSubscriptionInvoiced: PropTypes.bool.isRequired,
  membersMeta: PropTypes.shape({
    counts: PropTypes.shape({
      active: PropTypes.number,
    }),
  }),
  planExpired: PropTypes.bool.isRequired,
  selected: PropTypes.bool,
  subscription: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.shape({
      plan_interval: PropTypes.string,
    }),
  }).isRequired,
  subscriptionPrice: PropTypes.shape({
    attributes: PropTypes.shape({
      next_invoice_amount: PropTypes.number,
    }),
  }),
  subscriptionPriceLoading: PropTypes.bool.isRequired,
  team: PropTypes.shape({
    attributes: PropTypes.shape({
      account_status: PropTypes.string,
    }),
  }),
  toggleSelect: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  coupon: couponSelectors.couponSelector(state),
  isAnOldPlan: teamsSelectors.isAnOldPlanSelector(state),
  isCanceled: teamsSelectors.isCanceledSelector(state),
  isCancelingSubscription: teamsSelectors.isCancelingSubscriptionSelector(state),
  isFree: teamsSelectors.isFreeSelector(state),
  isPro: teamsSelectors.isProSelector(state),
  hasSmsAddon: teamsSelectors.hasSmsAddonSelector(state),
  isSubscriptionInvoiced: teamsSelectors.isSubscriptionInvoicedSelector(state),
  membersMeta: teamsSelectors.membersMetaSelector(state),
  planExpired: teamsSelectors.planExpiredSelector(state),
  subscription: teamsSelectors.subscriptionSelector(state),
  subscriptionPrice: teamsSelectors.subscriptionPriceSelector(state),
  subscriptionPriceLoading: teamsSelectors.subscriptionPriceLoadingSelector(state),
  setupIntent: teamsSelectors.getSetupIntent(state)
});

const mapDispatchToProps = dispatch => ({
  calculateSubscription: (quantity, interval, current = false) => dispatch(teamsActions.subscriptionCalculateRequest(quantity, interval, current)),
  getPaymentIntent: (subscription_id) => dispatch(teamsActions.requestPaymentIntent(subscription_id))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProOption);
