import React from 'react';
import PropTypes from 'prop-types';

import { Box, Flex, Heading, Text } from '../../../../mc-ui';

import Body from '../../../Body';
import ReportSection from '../../../ReportSection';
import RestrictedHeader from '../../../RestrictedHeader';
import RestrictedUpgradeButton from '../../../RestrictedUpgradeButton';

import Calendar from './Calendar';
import BrandCalendar from './BrandCalendar';
import BrandEmails from './BrandEmails';
import MonthExample from './MonthExample';
import WeeklyRecap from './WeeklyRecap';

const isGroup = model => model.type === 'groups';

const Upgrade = ({ model }) => (
  <ReportSection>
    <Body>
      <RestrictedHeader description="Plan promotions all year long." reportName="Calendars" />

      <Flex justifyContent="space-between" marginBottom="40px">
        <Flex direction="column" justifyContent="center" padding="0 32px" width="360px">
          <Heading as="h4" fontSize="18px" lineHeight="24px" css={{ marginBottom: '8px' }}>
            Spend more time planning email strategy and campaigns, less time crunching numbers
          </Heading>

          {isGroup(model) ? (
            <Text>
              See who’s sending when in one glance! Filter data by month/year to identify heavy or
              slow sending days for optimized email campaign scheduling.
            </Text>
          ) : (
            <Text>Get a daily view promotional activity and how often a brand send emails.</Text>
          )}
        </Flex>

        <Box width="360px">{isGroup(model) ? <MonthExample /> : <BrandEmails />}</Box>
      </Flex>

      <Flex justifyContent="space-between" marginBottom="40px">
        <Box width="360px">{isGroup(model) ? <Calendar /> : <BrandCalendar />}</Box>

        <Flex direction="column" justifyContent="center" padding="0 32px" width="360px">
          <Heading as="h4" fontSize="18px" lineHeight="24px" css={{ marginBottom: '8px' }}>
            Plan and execute competitive promotional campaigns
          </Heading>

          {isGroup(model) ? (
            <Text>
              Track average promotion rates across brands or zero in on your competitors. Use the
              day-by-day data map to reveal opportunities to send an attention-getting campaign.
            </Text>
          ) : (
            <Text>
              Track average promotion rates for brands or zero in on your competitors. Use the
              day-by-day data map to reveal opportunities to send an attention-getting campaign.
            </Text>
          )}
        </Flex>
      </Flex>

      <Flex justifyContent="space-between" marginBottom="40px">
        <Flex direction="column" justifyContent="center" padding="0 32px" width="360px">
          <Heading as="h4" fontSize="18px" lineHeight="24px" css={{ marginBottom: '8px' }}>
            Capitalize on ‘apples to apples’ volume and promotional trends for long-term planning
          </Heading>

          <Text>
            Be sure you benchmark your brand efforts accurately! Measure against the NRF’s 4-5-4
            standardized retail calendar for email volume and promotion rates.
          </Text>
        </Flex>

        <Box width="360px">
          <WeeklyRecap />
        </Box>
      </Flex>

      <RestrictedUpgradeButton />
    </Body>
  </ReportSection>
);

Upgrade.defaultProps = {
  model: { id: null },
};

Upgrade.propTypes = {
  model: PropTypes.shape({ id: PropTypes.string, type: PropTypes.string }),
};

export default Upgrade;
