import api from '../lib/api'

export default store => next => action => {
  next(action)
  if (action.type === 'REQUEST_ADDRESS') {
    api.get(`/v2/addresses/${action.address_id}`)
    .then(r => {
      let data = r.data
      store.dispatch({ type: 'RECEIVE_ADDRESS', data })
    })
  }
}

