import styled from "@emotion/styled";

const Bar = styled("div")(({ size, theme }) => ({
  backgroundColor: theme.colors.dark3,
  borderRadius: '2px',
  height: '8px',
  width: `${size}%`,
  '&:hover': {
    backgroundColor: theme.colors.blue,
  },
}));

export default Bar;
