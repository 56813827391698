import React from 'react';

const OptionsIcon = props => (
  <svg viewBox="0 0 16 15" width="1em" height="1em" {...props}>
    <g fill="none" fillRule="evenodd">
      <path fill="#A0AEBA" d="M0 2h16v1.4H0zM0 7h16v1.4H0zM0 12h16v1.4H0z" />
      <circle stroke="#A0AEBA" fill="#FFF" cx={11.5} cy={2.5} r={2} />
      <circle stroke="#A0AEBA" fill="#FFF" cx={3.5} cy={7.5} r={2} />
      <circle stroke="#A0AEBA" fill="#FFF" cx={9.5} cy={12.5} r={2} />
    </g>
  </svg>
);

export default OptionsIcon;
