import * as React from 'react';
import PropTypes from 'prop-types';

function PlusIcon({ fillOpacity, ...props }) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.937 17.816v-4.808h4.808c.519 0 .937-.418.937-.937a.935.935 0 00-.937-.937h-4.808V6.326A.935.935 0 0012 5.39a.935.935 0 00-.937.937v4.808H6.255a.935.935 0 00-.937.937c0 .52.418.937.937.937h4.808v4.808c0 .519.418.937.937.937a.94.94 0 00.937-.937z"
        fill="currentColor"
        fillOpacity={fillOpacity}
      />
    </svg>
  );
}

PlusIcon.propTypes = {
  fillOpacity: PropTypes.number,
};

PlusIcon.defaultProps = {
  fillOpacity: 1,
};

export default PlusIcon;
