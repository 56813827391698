const INITIAL_STATE = { loading: true, data: [] };
const removeJourney = (state, action) => {
  return {
    ...state,
    data: state.data.filter(journey => journey.data[0].id !== action.id),
  };
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'REQUEST_JOURNEYS': return { ...state, loading: true };
    case 'RECEIVE_JOURNEYS': return { ...action.data, loading: false };
    case 'REMOVE_JOURNEY': return removeJourney(state, action);
    case 'REQUEST_JOURNEYS_WITH_DETAILS': return { ...state, loading: true };
    case 'RECEIVE_JOURNEYS_WITH_DETAILS': return { ...action.data, loading: false };
    case 'FLUSH_JOURNEYS_WITH_DETAILS': return { ...INITIAL_STATE };
    default: return state;
  }
};

