import * as types from './types';

export const startLoading = () => ({
  type: types.START_LOADING,
});

export const stopLoading = () => ({
  type: types.STOP_LOADING,
});

export const setExportTotalAmount = ({ total, comparisonTotal }) => ({
  type: types.SET_EXPORT_TOTAL_AMOUNT,
  total,
  comparisonTotal,
});

export const receiveExportTotalAmount = ({ total, comparisonTotal }) => ({
  type: types.RECEIVE_EXPORT_TOTAL_AMOUNT,
  total,
  comparisonTotal,
});
