import React from 'react';
import PropTypes from 'prop-types';

import { Flex, Heading } from '../../../mc-ui';

const Header = ({ journey }) => (
  <Flex alignItems="center" justifyContent="space-between" marginBottom="14px" paddingLeft="30px">
    <Heading as="h4" css={{ lineHeight: '1.32em', textTransform: 'capitalize' }}>
      {(journey.type || '').split('_').join(' ')} journeys
    </Heading>
  </Flex>
);

Header.propTypes = {
  journey: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
};

export default Header;
