import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { teamsSelectors } from '../../../modules/teams';

import ContactModalLink from '../../ContactModalLink';
import { Box, Button, Flex, Heading, Text, Link } from '../../mc-ui';
import { topics } from '../../ContactUs';

import ProFeatures from './ProFeatures';
import PlanExpiredNotice from '../PlanExpiredNotice';

import * as S from './styles';

function EnterpriseOption({ isCancelingSubscription, isOwner, isSubscriptionInvoiced, team, hasSmsAddon }) {
  if (team && isCancelingSubscription) return null;

  const active = team && isSubscriptionInvoiced;

  return (
    <Box
      backgroundColor={active ? 'blueLight' : 'white'}
      border="2px solid"
      borderColor={active ? 'blue' : 'dark5'}
      borderRadius="8px"
      justifyContent="space-between"
      marginTop={active ? null : '1em'}
      padding="24px"
    >
      {team && isSubscriptionInvoiced ? (
        <div>
          <S.PlanChecker planName="Enterprise" checked />
          <Box padding="24px 0 16px 0">
            <PlanExpiredNotice enterprise />
          </Box>
          {isOwner ? (
            <Text>
              Please contact <ContactModalLink>hi@mailcharts.com</ContactModalLink> to update your
              subscription or for additional details.
            </Text>
          ) : (
            <Text>
              Your subscription is managed by your team owner.{' '}
              <Link to="/manage/teams">Please visit team management.</Link>
            </Text>
          )}
          <ProFeatures
            isCanceled={false}
            isFree={false}
            isPro
            hasSmsAddon={hasSmsAddon}
            planExpired={false}
            selected={false}
            toggleSelect={() => {}}
          />
        </div>
      ) : (
        <div>
          <Flex justifyContent="space-between" marginBottom="8px">
            <Heading as="h4" fontSize="18px" fontWeight="500" lineHeight="18px">
              Enterprise
            </Heading>

            <Button
              as={ContactModalLink}
              topic={topics.ENTERPRISE}
              variant="secondary"
              css={{
                height: '32px',
                lineHeight: '32px',
                margin: '0',
                padding: '0',
                width: '135px',
              }}
            >
              Contact Support
            </Button>
          </Flex>

          <Text>
            <ContactModalLink topic={topics.ENTERPRISE}>Contact support</ContactModalLink> to discuss
            getting your account set up today!
          </Text>
        </div>
      )}
    </Box>
  );
}

EnterpriseOption.defaultProps = {
  team: null,
};

EnterpriseOption.propTypes = {
  isCancelingSubscription: PropTypes.bool.isRequired,
  isOwner: PropTypes.bool.isRequired,
  isSubscriptionInvoiced: PropTypes.bool.isRequired,
  team: PropTypes.shape({}),
  hasSmsAddon: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  isCancelingSubscription: teamsSelectors.isCancelingSubscriptionSelector(state),
  isSubscriptionInvoiced: teamsSelectors.isSubscriptionInvoicedSelector(state),
  hasSmsAddon: teamsSelectors.hasSmsAddonSelector(state),
});

export default connect(mapStateToProps)(EnterpriseOption);
