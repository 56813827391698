/* eslint-disable */
import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios';
import Container from './Container';
import WarningCircleIcon from './mc-ui/icons/WarningCircleIcon';
import { Box, Flex, Text, Button, theme } from './mc-ui';
import AccountDelete from './AccountDelete';

const ENV = (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging' || process.env.NODE_ENV === 'pr') ? process.env.NODE_ENV : 'development';
const auth0ClientId = (ENV === 'production') ? 'iu9v2MrVxxHP6qU714aQVOKsmO7KmskX'
  : (ENV === 'staging') ? 'QCOhZAxZWBvyimjr7HoQqhTd1BGEX74D'
    : 'Lv1SGLuOOwUTw2NrCbfIpMA5F6c9flui';
const auth0Domain = ENV === 'production' ? 'login.validity.com' : 'validity-dev.auth0.com';

class ManageAccountInfo extends React.Component {
  state = { user: null, passwordResetMessage: '', isModalOpen: false };
  handleSubmit = (event) => {
    event.preventDefault()
    let data = { ...this.state.user.attributes }
    this.props.dispatch({ type: 'UPDATE_USER', data })
  }
  handleChange = (event) => {
    event.preventDefault()
    let user = this.state.user
    user.attributes[event.target.name] = event.target.value
    this.setState({ user })
  }
  handlePasswordReset = (event) => {
    event.preventDefault();
    let user = this.state.user;
    console.log(user.attributes.email);
    var options = {
      method: 'POST',
      url: `https://${auth0Domain}/dbconnections/change_password`,
      headers: {'content-type': 'application/json'},
      data: {
        client_id: auth0ClientId,
        email: user.attributes.email,
        connection: 'Username-Password-Authentication',
      }
    };
    console.log(options);
    axios.request(options).then((response) => {
      console.log(response.data);
      this.setState({
        passwordResetMessage: {
          message: response.data,
          success: true,
        }
      });
    }).catch((error) => {
      console.error(error);
      this.setState({
        passwordResetMessage: {
          message: "There was an issue requesting the password change. Please try again later.",
          success: false,
        }
      });
    })
  };

  toggleModal = event => {
    if (event && event.preventDefault) event.preventDefault();
    this.setState(currentState => ({ isModalOpen: !currentState.isModalOpen }));
  };

  componentDidMount() {
    let attributes = this.props.user.data.reduce((m, u) => u, {}).attributes
    let user = { attributes: { ...attributes } }
    this.setState({ user })
  }
  render() {
    var userId = 0;
    if (this.props.user.data.length > 0) {
      userId = this.props.user.data[0].id;
    }
    const { isModalOpen } = this.state;
    return this.state.user ? (
      <div className='pure-u-1'>
        <Container>
          <div className='pure-u-1 pure-u-md-1-4'>
            <h2
              style={{ fontSize: `${24/16}em` }}
            >
              Account info
            </h2>
          </div>
          <div className='pure-u-1 pure-u-md-3-4' style={{ margin: '1.25em 0' }}>
            {this.state.user.attributes.error ? <div style={{ color: 'white', backgroundColor: "#d9534f", padding: '10px', fontSize: '12px' }}>There was an error saving your account info.</div> : null}

            <form
              className='pure-form'
              onSubmit={this.handleSubmit}
              autoComplete="off"
            >
              <div className='pure-u-1 pure-u-md-1-2' style={{ margin: '1em 0' }}>
                <label
                >
                  <h5
                    style={{ margin: '.5em 0', }}
                  >
                    Name
                  </h5>
                  <input
                    type='text'
                    name='name'
                    onChange={this.handleChange}
                    className='pure-u-1 pure-u-md-23-24'
                    style={{
                      padding: '1em',
                      fontSize: `${13/16}em`,
                      color: '#242424',
                    }}
                    value={this.state.user.attributes.name}
                  />
                </label>
              </div>
              <div className='pure-u-1 pure-u-md-1-2' style={{ margin: '1em 0' }}>
                <label
                >
                  <h5
                    style={{ margin: '.5em 0', }}
                  >
                    Company name
                  </h5>
                  <input
                    type='text'
                    name='company_name'
                    onChange={this.handleChange}
                    className='pure-u-1 pure-u-md-23-24'
                    style={{
                      padding: '1em',
                      fontSize: `${13/16}em`,
                      color: '#242424',
                    }}
                    value={this.state.user.attributes.company_name}
                  />
                </label>
              </div>
              <div className='pure-u-1 pure-u-md-1-2' style={{ margin: '1em 0' }}>
                <label
                >
                  <h5
                    style={{ margin: '.5em 0', }}
                  >
                    Email
                  </h5>
                  <input
                    type='text'
                    name='email'
                    onChange={this.handleChange}
                    className='pure-u-1 pure-u-md-23-24'
                    style={{
                      padding: '1em',
                      fontSize: `${13/16}em`,
                      color: '#242424',
                    }}
                    value={this.state.user.attributes.email}
                    disabled
                  />
                </label>
              </div>
              <div className='pure-u-1 pure-u-md-1-2' style={{ margin: '1em 0' }}>
                <div className='pure-u-1' style={{ margin: '1em 0' }}>
                  <input
                    type='button'
                    onClick={this.handlePasswordReset}
                    className='pure-button pure-button-primary'
                    style={{ fontSize: `${13/16}em` }}
                    value='Reset password'
                  />
                </div>
                {this.state.passwordResetMessage && (<Flex
                  alignItems="flex-start"
                  backgroundColor="white"
                  border="1px solid"
                  borderColor={!this.state.passwordResetMessage.success ? "yellowMedium" : "greenMedium"}
                  borderRadius="4px"
                  padding="24px"
                >
                  {!this.state.passwordResetMessage.success && (
                    <WarningCircleIcon color={theme.colors.yellowDark} />
                  )}

                  <Box flex="1" margin="0 16px" width="unset">

                    <Text>
                      {this.state.passwordResetMessage.message}
                    </Text>
                  </Box>
                </Flex>
                )}
              </div>
              {this.state.user.attributes.account_status == 'active' &&
               this.state.user.attributes.type == 'standard' &&
              <div className='pure-u-1' style={{ margin: '1em 0' }}>
                <label
                  style={{ cursor: 'pointer', fontSize: `${13/16}em`, }}
                >
                  <input
                    style={{ marginRight: 5 }}
                    type='checkbox'
                    name='send_invoices'
                    onChange={(e) => {
                      const { send_invoices } = this.state.user.attributes
                      const user = { ...this.state.user }
                      user.attributes.send_invoices = !send_invoices
                      this.setState({ user })
                    }}
                    checked={this.state.user.attributes.send_invoices}
                    value={this.state.user.attributes.send_invoices}
                  />
                  Receive an email for every new invoice?
                </label>
              </div>}
              <div className='pure-u-1' style={{ margin: '1em 0' }}>
                <input
                  type='submit'
                  className='pure-button pure-button-primary'
                  style={{ fontSize: `${13/16}em` }}
                  value='Update info'
                />
              </div>
            </form>
          </div>
          <div className="pure-u-1 pure-u-md-1-1">
            <h2 style={{ fontSize: `${24 / 16}em` }}>Delete account</h2>
          </div>
          <div className="pure-u-1" style={{ margin: '1em 0' }}>
            <Button css={{ fontSize: '14px', height: '40px', lineHeight: '40px', padding: 0, width: '147px' }} onClick={this.toggleModal}>
              Delete my account
            </Button>
            <AccountDelete isOpen={isModalOpen} userId={userId} toggleModal={this.toggleModal} />
          </div>
        </Container>
      </div>
    ) : null
  }
}

const s = (state) => ({ user: state.user })
export default connect(s)(ManageAccountInfo)
