import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

import { Grid } from '../../../mc-ui';
import BrandLogo from '../../BrandLogo';

const MostTrackedBrandsList = ({ collection: brands }) => (
  <Grid gap="17px" gridTemplateColumns="repeat(3, 1fr)" gridTemplateRows="repeat(4, 1fr)">
    {brands.map(brand => (
      <Link
        key={`mostTrackedBrandLink${brand.id}`}
        title={brand.attributes.name}
        to={`/companies/${brand.id}`}
      >
        <BrandLogo
          brand={brand}
          alt={brand.attributes.name}
          css={{ height: '80px', width: '80px' }}
        />
      </Link>
    ))}
  </Grid>
);

MostTrackedBrandsList.propTypes = {
  collection: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      attributes: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
  ).isRequired,
};

export default MostTrackedBrandsList;
