import { createSelector } from 'reselect';

const getState = state => state.textMessages;

const DEFAULT_DATA = {
  data: [],
  links: {},
  meta: {
    total: 0,
  },
};

export const dataSelector = ({ pathname, query: q }) => {
  const query = JSON.stringify(q || '');

  return createSelector(getState, state => {
    if (!state[pathname]) return DEFAULT_DATA;
    if (!state[pathname][query]) return DEFAULT_DATA;
    if (!state[pathname][query].data) return DEFAULT_DATA;

    return state[pathname][query].data;
  });
};

export const loadingSelector = ({ pathname, query: q }) => {
  const query = JSON.stringify(q || '');

  return createSelector(getState, state => {
    if (!state[pathname]) return false;
    if (!state[pathname][query]) return false;
    if (!state[pathname][query].loading) return false;

    return state[pathname][query].loading;
  });
};

export const paginatingSelector = ({ pathname, query: q }) => {
  const query = JSON.stringify(q || '');

  return createSelector(getState, state => {
    if (!state[pathname]) return false;
    if (!state[pathname][query]) return false;
    if (!state[pathname][query].paginating) return false;

    return state[pathname][query].paginating;
  });
};
