import React from 'react';
import PropTypes from 'prop-types';
import { Portal } from 'react-portal';

import ModalBody from './modal/ModalBody';
import ModalFooter from './modal/ModalFooter';
import ModalHeader from './modal/ModalHeader';
import Overlay from './modal/Overlay';
import Wrapper from './modal/Wrapper';

class Modal extends React.Component {
  static Body = ModalBody;
  static Footer = ModalFooter;
  static Header = ModalHeader;

  componentDidMount() {
    const { closeOnEsc, isOpen } = this.props;

    if (closeOnEsc) {
      window.addEventListener('keydown', this.handleCloseOnEsc);
    }

    if (isOpen) {
      document.body.classList.toggle('modal-open', isOpen);
    }
  }

  componentDidUpdate() {
    const { isOpen } = this.props;
    setTimeout(() => {
      document.body.classList.toggle('modal-open', isOpen);
    }, 220);
  }

  componentWillUnmount() {
    const { closeOnEsc } = this.props;

    document.body.classList.toggle('modal-open', false);

    if (closeOnEsc) {
      window.removeEventListener('keydown', this.handleCloseOnEsc);
    }
  }

  handleClick = event => {
    if (!this.props.isOpen) return;
    if (event.target === event.currentTarget) this.handleClose();
  };

  handleCloseOnEsc = event => {
    if (!this.props.isOpen) return;
    if (event.code.toLowerCase() === 'escape') this.handleClose();
  };

  handleClose = event => {
    if (event) {
      event.preventDefault();
    }

    this.props.onClose();
  };

  render() {
    const { children, isCentered, isOpen, ...props } = this.props;

    if (!isOpen) return null;

    return (
      <Portal node={document && document.getElementById('portal')}>
        <Overlay onClick={this.handleClick}>
          <Wrapper isCentered={isCentered} css={{ ...props }}>
            {children}
          </Wrapper>
        </Overlay>
      </Portal>
    );
  }
}

Modal.defaultProps = {
  closeOnEsc: true,
  closeOnOverlayClick: true,
  isCentered: false,
};
Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.node,
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
  closeOnEsc: PropTypes.bool,
  closeOnOverlayClick: PropTypes.bool,
  isCentered: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Modal;
