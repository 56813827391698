import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';

import * as validators from '../../../lib/forms/validators';
import * as normalizers from '../../../lib/forms/normalizers';
import { TYPES } from '../../../modules/shareButton/constants';

import { Box, Button, EmailField, Flex, Heading, Label, TextareaField, theme } from '../../mc-ui';
import ShareIcon from '../../mc-ui/icons/ShareIcon';
import LinkIcon from '../../mc-ui/icons/LinkIcon';
import CrossSmallIcon from '../../mc-ui/icons/CrossSmallIcon';

import PinterestIcon from '../../mc-ui/icons/PinterestIcon';

const emailValidator = value => {
  if (validators.required(value)) return validators.required(value);
  if (validators.email(value)) return validators.email(value);

  return undefined;
};

export default function ShareForm({
  copied,
  handleCopyURL,
  handleShareToPinterest,
  includePinterest,
  onSubmit,
  small,
  toggle,
  type,
}) {
  let sharableName = '';
  if (type === TYPES.brandOverview) {
    sharableName = 'Brand';
  } else if (type === TYPES.email) {
    sharableName = 'Email';
  } else if (type === TYPES.emailList) {
    sharableName = 'List';
  } else if (type === TYPES.industryOverview) {
    sharableName = 'Group';
  } else if (type === TYPES.textMessage) {
    sharableName = 'Text Message';
  } else {
    sharableName = 'Journey';
  }

  return (
    <Box
      backgroundColor="white"
      borderRadius="4px"
      boxShadow={`0px 2px 8px ${theme.colors.dark02}`}
      padding="24px"
      position="absolute"
      right="0"
      top={small ? '10px' : '30px'}
      width="504px"
      zIndex={theme.zIndices.dropdown}
    >
      <Box position="relative">
        <Heading
          as="h3"
          fontSize="18px"
          fontWeight="500"
          lineHeight="24px"
          css={{ marginBottom: '16px' }}
        >
          Share this {sharableName}
        </Heading>

        <Button
          onClick={toggle}
          variant="none"
          css={{
            margin: '0',
            padding: '0',
            position: 'absolute',
            right: '0',
            top: '0',
          }}
        >
          <CrossSmallIcon />
        </Button>
      </Box>

      <Form onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Label htmlFor="to" css={{ marginBottom: '16px' }}>
              To
              <Field
                autoComplete="off"
                autoFocus
                component={EmailField}
                id="to"
                name="to"
                parse={normalizers.preventSpace}
                placeholder="Email address"
                validate={emailValidator}
              />
            </Label>

            <Label htmlFor="message" css={{ marginBottom: '16px' }}>
              Message (optional)
              <Field
                component={TextareaField}
                id="message"
                name="message"
                placeholder="I would like to share this"
                css={{ height: '136px' }}
              />
            </Label>

            <Flex justifyContent="space-between">
              <Box width="unset">
                <Button
                  onClick={handleCopyURL}
                  variant="secondary"
                  css={{
                    alignItems: 'center',
                    display: 'inline-flex',
                    height: '32px',
                    justifyContent: 'center',
                    margin: '0 8px 0 0',
                    padding: '0',
                    width: copied ? '152px' : '112px',
                    '& > svg': {
                      marginRight: '8px',
                    },
                  }}
                >
                  <LinkIcon height="20px" width="20px" />
                  Copy link {copied && <small style={{ marginLeft: '4px' }}>copied</small>}
                </Button>

                {includePinterest && (
                  <Button
                    onClick={handleShareToPinterest}
                    variant="secondary"
                    css={{
                      alignItems: 'center',
                      display: 'inline-flex',
                      height: '32px',
                      justifyContent: 'center',
                      margin: '0',
                      padding: '0',
                      width: '168px',
                      '& > svg': {
                        marginRight: '8px',
                      },
                    }}
                  >
                    <PinterestIcon height="20px" width="20px" />
                    Share to Pinterest
                  </Button>
                )}
              </Box>

              <Button
                variant="primary"
                type="submit"
                css={{
                  alignItems: 'center',
                  display: 'inline-flex',
                  height: '32px',
                  justifyContent: 'center',
                  margin: '0',
                  padding: '0',
                  width: '88px',
                  '& > svg': {
                    marginRight: '8px',
                  },
                }}
              >
                <ShareIcon height="20px" width="20px" />
                Share
              </Button>
            </Flex>
          </form>
        )}
      </Form>
    </Box>
  );
}

ShareForm.propTypes = {
  copied: PropTypes.bool.isRequired,
  handleCopyURL: PropTypes.func.isRequired,
  handleShareToPinterest: PropTypes.func.isRequired,
  includePinterest: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  small: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};
