import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ascend, path, sort } from 'ramda';

import api from '../../lib/api';

import { shallowEqual } from '../../lib/utils';

import { journeysActions as actions, journeysSelectors as selectors } from '../../modules/journeys';

import { Box } from '../mc-ui';

import Filters from './Filters';
import Header from './Header';
import JourneysList from './JourneysList';
import Meta from './Meta';
import Total from './Total';

const sortByName = sort(ascend(path(['attributes', 'name'])));

class NewJourneys extends React.Component {
  state = {
    brands: [],
  };

  componentDidMount() {
    if (this.props.model.id) {
      this.request();
      this.requestBrands();
    }
  }

  componentDidUpdate(prevProps) {
    const modelChanged =
      this.props.model.id &&
      this.props.model.id !== prevProps.model.id &&
      this.props.model.type !== prevProps.model.type;
    const queryChanged = !shallowEqual(this.props.location.query, prevProps.location.query);

    if (modelChanged) {
      this.requestBrands();
    }

    if (modelChanged || queryChanged) {
      this.request();
    }
  }

  request = () => {
    this.props.requestJourneys();
  };

  requestBrands = async () => {
    const { model } = this.props;
    if (!model.id || model.type !== 'groups') return;

    const url = `/v3/groups/${model.id}/companies?size=${model.attributes.approximate_companies}`;
    const response = await api.get(url);

    this.setState({
      brands: sortByName(response.data.included),
    });
  };

  updateQuery = query => {
    const {
      location: { pathname },
      router,
    } = this.props;

    router.push({ pathname, query });
  };

  render() {
    const { brands, selectedBrand } = this.state;

    return (
      <Box>
        <Header />
        <Box backgroundColor="white" borderRadius="4px" marginBottom="32px" padding="32px 0">
          <Meta {...this.props} />
          <Filters
            {...this.props}
            brands={brands}
            selectedBrand={selectedBrand}
            onSubmit={this.updateQuery}
          />
          <Total {...this.props} />
          <JourneysList {...this.props} updateQuery={this.updateQuery} />
        </Box>
      </Box>
    );
  }
}

NewJourneys.defaultProps = {
  model: {},
};

NewJourneys.propTypes = {
  loading: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    query: PropTypes.shape({}),
  }).isRequired,
  model: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    type: PropTypes.string,
    attributes: PropTypes.shape({
      approximate_companies: PropTypes.number,
    }),
  }),
  requestJourneys: PropTypes.func.isRequired,
  router: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

const mapStateToProps = (state, { location, model }) => ({
  loading: selectors.getLoading(model, location.query)(state),
});

const mapDispatchToProps = (dispatch, { location, model }) => ({
  requestJourneys: () => dispatch(actions.getJourneysRequest(model, location.query || {})),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewJourneys);
