import { assocPath, isNil, path } from 'ramda';

import * as types from './types';
import { queryToStr } from './utils';

/**
 * State structure is divided per date, like the following
 * {
 *   [modelType]: {
 *     [modelId]: {
 *       [queryToStr(query)]: {
 *         data: array,
 *         error: string?,
 *         links: object,
 *         loading: boolean,
 *         meta: object,
 *       },
 *       [journeyId]: {
 *         [queryToStr(query)]: {
 *           data: object,
 *           error: string?,
 *           loading: boolean,
 *           meta: object,
 *         },
 *       },
 *     },
 *   },
 * }
 */
const INITIAL_STATE = {
  brands: {},
  groups: {},
};

const setJourneysLoading = (state, loading, { model, query }) =>
  assocPath([model.type, `${model.id}`, queryToStr(query), 'loading'], loading, state);

const toggleJourneysLoading = (state, { model, query }) => {
  const dataModel = path([model.type, `${model.id}`, queryToStr(query)], state) || {};
  const loading = isNil(dataModel.loading) ? true : dataModel.loading;

  return setJourneysLoading(state, !loading, { model, query });
};

const setJourneysPayload = (state, { model, query, payload }) =>
  assocPath([model.type, `${model.id}`, queryToStr(query)], { loading: false, ...payload }, state);

const setJourneysError = (state, { model, query, error }) =>
  assocPath([model.type, `${model.id}`, queryToStr(query)], { loading: false, error }, state);

const setJourneyLoading = (state, loading, { model, id, query }) =>
  assocPath([model.type, `${model.id}`, `${id}`, queryToStr(query), 'loading'], loading, state);

const toggleJourneyLoading = (state, { model, id, query }) => {
  const dataModel = path([model.type, `${model.id}`, `${id}`, queryToStr(query)], state) || {};
  const loading = isNil(dataModel.loading) ? true : dataModel.loading;

  return setJourneyLoading(state, !loading, { model, id, query });
};

const setJourneyPayload = (state, { model, id, query, payload }) =>
  assocPath(
    [model.type, `${model.id}`, `${id}`, queryToStr(query)],
    { loading: false, ...payload },
    state,
  );

const setJourneyError = (state, { model, id, query, error }) =>
  assocPath(
    [model.type, `${model.id}`, `${id}`, queryToStr(query)],
    { loading: false, error },
    state,
  );

const reducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case types.GET_JOURNEYS_REQUEST: {
      return setJourneysLoading(state, true, action);
    }
    case types.TOGGLE_JOURNEYS_LOADING: {
      return toggleJourneysLoading(state, action);
    }
    case types.GET_JOURNEYS_SUCCESS: {
      return setJourneysPayload(state, action);
    }
    case types.GET_JOURNEYS_FAILURE: {
      return setJourneysError(state, action);
    }
    case types.GET_JOURNEY_REQUEST: {
      return setJourneyLoading(state, true, action);
    }
    case types.TOGGLE_JOURNEY_LOADING: {
      return toggleJourneyLoading(state, action);
    }
    case types.GET_JOURNEY_SUCCESS: {
      return setJourneyPayload(state, action);
    }
    case types.GET_JOURNEY_FAILURE: {
      return setJourneyError(state, action);
    }
    default: {
      return state;
    }
  }
};

export default reducer;
