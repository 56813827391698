import * as React from 'react';

function EmptyEnvelop(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 103 65" fill="none" {...props}>
      <path
        d="M63.107 15.425c2.227-8.167-.99-10.023-7.796-10.023H12.993C6.496 5.402.928 10.54 0 17.602v33.71c0 7.385 5.878 13.485 12.993 13.485H60.94c7.115 0 12.993-6.1 12.993-13.484l-6.806-20.668-5.135-11.136 1.114-4.084zm-50.114-2.639h42.751c3.094 0-.309 3.512 0 6.723l3.65 6.681L38.36 39.755c-.928.642-1.856.642-2.475 0L7.115 20.492v-1.927c0-3.21 2.784-5.779 5.878-5.779zM61.25 57.734H12.993c-3.403 0-6.187-2.89-6.187-6.421V29.16l25.057 16.695c1.547.963 3.403 1.605 5.259 1.605 1.856 0 3.402-.642 4.95-1.605l18.87-12.486 6.186 17.944c0 3.531-2.475 6.42-5.877 6.42z"
        fill="#091E42"
        fillOpacity={0.1}
      />
      <path
        fill="#DCE0E5"
        d="M58.652 1.69l9.002 5.197-5.197 9.001-9.002-5.197zM71.883 8.262l9.479.028-2.146 10.17-10.17-2.145 2.837-8.053zM68.304 43.266l9.002 5.197-5.197 9.002-9.002-5.197zM63.85 23.963l9 5.197-5.196 9.001-9.002-5.197z"
      />
      <path
        d="M94.64 4.882l5.681 3.28V2.716l-5.682 2.167zM87.215 21.958l5.681 3.28v-5.447l-5.681 2.167zM72.366 38.292l5.682 3.28v-5.447l-5.682 2.167zM84.637 15.846h10.394l-4.315-7.474-6.079 7.474zM84.396 20.841l-8.157 6.442 8.047 3.228.11-9.67z"
        fill="#DFE1E6"
      />
    </svg>
  );
}

export default EmptyEnvelop;
