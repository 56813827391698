import styled from "@emotion/styled";

const ButtonWrapper = styled("button")('pure-button', () => ({
  alignItems: 'center',
  display: 'flex',
  fontSize: '13px',
  height: `${32 / 13}em`,
  justifyContent: 'space-between',
  marginRight: '8px',
  width: `${158 / 13}em`,
}));

export default ButtonWrapper;
