import * as React from 'react';
import PropTypes from 'prop-types';

function ArrowLeftIcon({ fillOpacity, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M9 12.37a1.192 1.192 0 01.35-.846l4.299-4.3a.797.797 0 011.127 1.127l-3.939 3.938a.112.112 0 00-.024.123.11.11 0 00.024.037l3.939 3.938a.797.797 0 01-1.127 1.127l-4.3-4.3A1.191 1.191 0 019 12.37z"
        fill="currentColor"
        fillOpacity={fillOpacity}
      />
    </svg>
  );
}

ArrowLeftIcon.propTypes = {
  fillOpacity: PropTypes.number,
};

ArrowLeftIcon.defaultProps = {
  fillOpacity: 1,
};

export default ArrowLeftIcon;
