const prefix = 'mc/journeys';

export const GET_JOURNEYS_FAILURE = `${prefix}/GET_JOURNEYS_FAILURE`;
export const GET_JOURNEYS_REQUEST = `${prefix}/GET_JOURNEYS_REQUEST`;
export const GET_JOURNEYS_SUCCESS = `${prefix}/GET_JOURNEYS_SUCCESS`;
export const TOGGLE_JOURNEYS_LOADING = `${prefix}/TOGGLE_JOURNEYS_LOADING`;

export const GET_JOURNEY_FAILURE = `${prefix}/GET_JOURNEY_FAILURE`;
export const GET_JOURNEY_REQUEST = `${prefix}/GET_JOURNEY_REQUEST`;
export const GET_JOURNEY_SUCCESS = `${prefix}/GET_JOURNEY_SUCCESS`;
export const TOGGLE_JOURNEY_LOADING = `${prefix}/TOGGLE_JOURNEY_LOADING`;
