import React from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from '../../../../../mc-ui';

import Bar from './Bar';

const formatHour = hour => {
  if (hour === 0) return '12 AM';
  if (hour < 12) return `${hour} AM`;
  if (hour === 12) return '12 PM';

  return `${hour - 12} PM`;
};

const Column = ({ day, hourOfDay: { docCount, key, value } }) => (
  <Tooltip id={`${day}-${key}-${docCount}`} element={<Bar value={value} />} effect="float" dark>
    <strong>{day.charAt(0).toUpperCase() + day.slice(1)}</strong>
    <br />
    {formatHour(Number(key))}
    <br />
    {value}% of emails sent.
  </Tooltip>
);

Column.propTypes = {
  day: PropTypes.string.isRequired,
  hourOfDay: PropTypes.shape({
    docCount: PropTypes.number.isRequired,
    key: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  }).isRequired,
};

export default Column;
