import React from 'react';
import PropTypes from 'prop-types';
// import { take } from 'ramda';
import { connect } from 'react-redux';

import { shallowEqual } from '../../../../lib/utils';
import api from '../../../../lib/api';
import { Loader } from '../../../mc-ui';

import ReportSection from '../../ReportSection';
import SortSelect from '../../SortSelect';
import Title from '../../Title';

import BrandsList from './BrandsList';
import BrandsModal from './BrandsModal';

class AveragePromotionRateByBrands extends React.Component {
  state = {
    brands: [],
    loading: true,
    order: '-', // '-' is descend and '' is ascend
    showModal: false,
  };

  componentDidMount() {
    if (this.props.model.id) {
      this.request(this.props);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.model.id) return;
    const nextModel = { id: nextProps.model.id, type: nextProps.model.type };
    const model = { id: this.props.model.id, type: this.props.model.type };
    const nextDates = { endDate: nextProps.endDate, startDate: nextProps.startDate };
    const dates = { endDate: this.props.endDate, startDate: this.props.startDate };

    if (!shallowEqual(nextModel, model) || !shallowEqual(nextDates, dates)) {
      this.setState(
        () => ({ loading: true }),
        () => {
          this.request(nextProps);
        },
      );
    }
  }

  handleSort = ({ value }) => {
    this.setState(() => ({ loading: true, order: value }), () => this.request(this.props));
  };

  request = async ({ model, endDate, startDate }) => {
    if (!endDate || !startDate) return;

    const { order } = this.state;
    const { id, type } = model;

    let query = type === 'groups' ? `?group_id=${id}` : `?company_id=${id}`;
    query += `&start_date=${startDate}`;
    query += `&end_date=${endDate}`;
    query += `&sort=${order}promotional`;
    query += '&size=10';

    const response = await api.get(`/v3/reports/brands${query}`);
    const {
      data: { included },
    } = response;

    const brands = included.map(brand => ({
      id: brand.id,
      attributes: { name: brand.attributes.name },
      value: brand.meta.stats.promotional.value * 100,
    }));

    this.setState({
      brands,
      loading: false,
    });
  };

  toggleModal = event => {
    if (event) event.preventDefault();

    this.setState(currentState => ({ showModal: !currentState.showModal }));
  };

  render() {
    const { model } = this.props;
    const { brands, loading, order, showModal } = this.state;

    return (
      <ReportSection css={{ height: '394px' }}>
        <ReportSection.Header>
          <Title>Average Promotion Rate by Brands</Title>
          <SortSelect
            options={[{ label: 'Highest', value: '-' }, { label: 'Lowest', value: '' }]}
            onChange={this.handleSort}
            value={order}
          />
        </ReportSection.Header>
        {loading ? (
          <Loader />
        ) : (
          <div>
            <ReportSection.Body>
              <BrandsList brands={brands} />
            </ReportSection.Body>
            <ReportSection.Footer padding="0">
              <a href="#seeMore" onClick={this.toggleModal}>
                See more
              </a>
            </ReportSection.Footer>
            {showModal && <BrandsModal model={model} closeModal={this.toggleModal} />}
          </div>
        )}
      </ReportSection>
    );
  }
}

AveragePromotionRateByBrands.defaultProps = {
  endDate: null,
  startDate: null,
};

AveragePromotionRateByBrands.propTypes = {
  model: PropTypes.shape({ id: PropTypes.string, type: PropTypes.string }).isRequired,
  endDate: PropTypes.string,
  startDate: PropTypes.string,
};

const mapStateToProps = state => ({
  endDate: state.newReports.dates.endDate,
  startDate: state.newReports.dates.startDate,
});

export default connect(mapStateToProps)(AveragePromotionRateByBrands);
