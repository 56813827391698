import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const LoadMore = ({ companies, requestCompaniesPagination }) => companies && companies.links && companies.links.next == null ?  null : (
  <div className="pure-u-1" style={{ textAlign: 'center' }}>
    <p>Scroll to load more companies.</p>
    <p style={{ margin: '0 0 24px 0', fontSize: `${12 / 16}em` }}>
      If you’re having trouble loading more companies,{' '}
      <a href="#paginate" onClick={requestCompaniesPagination}>click here</a>.
    </p>
  </div>
);

LoadMore.propTypes = {
  requestCompaniesPagination: PropTypes.func.isRequired,
};

const mapStateToProps = disptach => ({
  companies: state.companies,
})

const mapDispatchToProps = dispatch => ({
  requestCompaniesPagination: (event) => {
    event.preventDefault();
    dispatch({ type: 'REQUEST_COMPANIES_PAGINATION' });
  },
});

export default connect(null, mapDispatchToProps)(LoadMore);
