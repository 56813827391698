import styled from "@emotion/styled";

const Text = styled("p")(({
  color = 'dark06',
  css = {},
  fontSize = '14px',
  fontWeight = null,
  height = null,
  letterSpacing = null,
  lineHeight = '1.56em',
  margin = '0',
  padding = '0',
  textAlign = null,
  textTransform = null,
  theme,
  whiteSpace = null,
  width = null,
}) => ({
  color: theme.colors[color],
  fontFamily: theme.font.family,
  fontSize,
  fontWeight,
  height,
  letterSpacing,
  lineHeight,
  margin,
  padding,
  textAlign,
  textTransform,
  whiteSpace,
  width,
  ...css,
  '& > a': {
    border: 'none',
    color: theme.colors.blue,
    textDecoration: 'none',
  },
}));

export default Text;
