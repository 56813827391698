import { createSelector } from 'reselect';

const getState = state => state.newReports.calendars || {};

export const getDate = (modelType, modelId, startDate) =>
  createSelector(getState, state => {
    const modelData = state[modelType][modelId] || {};
    const dateData = modelData[startDate] || {};

    return dateData;
  });

export const getModal = (modelType, modelId, firstDayOfMonth, date) =>
  createSelector(getState, state => {
    const modelData = state[modelType][modelId] || {};
    const dateData = modelData[firstDayOfMonth] || { modal: { [date]: null } };
    const modalObj = dateData.modal[date] || {};

    return modalObj;
  });

export const getDateData = (modelType, modelId, startDate) =>
  createSelector(getDate(modelType, modelId, startDate), date => date.data || {});

export const getDateError = (modelType, modelId, startDate) =>
  createSelector(getDate(modelType, modelId, startDate), date => date.error || null);

export const getDateLoading = (modelType, modelId, startDate) =>
  createSelector(getDate(modelType, modelId, startDate), date => !!date.loading);

export const getModalData = (modelType, modelId, firstDayOfMonth, date) =>
  createSelector(getModal(modelType, modelId, firstDayOfMonth, date), modal => modal.data || {});

export const getModalError = (modelType, modelId, firstDayOfMonth, date) =>
  createSelector(getModal(modelType, modelId, firstDayOfMonth, date), modal => modal.error || null);

export const getModalLoading = (modelType, modelId, firstDayOfMonth, date) =>
  createSelector(getModal(modelType, modelId, firstDayOfMonth, date), modal => !!modal.loading);

export const getBrandData = (id, startDate) =>
  createSelector(getDateData('brands', id, startDate), data => data);
export const getBrandError = (id, startDate) =>
  createSelector(getDateError('brands', id, startDate), error => error);
export const getBrandLoading = (id, startDate) =>
  createSelector(getDateLoading('brands', id, startDate), loading => loading);

export const getBrandModalData = (id, firstDayOfMonth, date) =>
  createSelector(getModalData('brands', id, firstDayOfMonth, date), data => data);
export const getBrandModalError = (id, firstDayOfMonth, date) =>
  createSelector(getModalError('brands', id, firstDayOfMonth, date), error => error);
export const getBrandModalLoading = (id, firstDayOfMonth, date) =>
  createSelector(getModalLoading('brands', id, firstDayOfMonth, date), loading => loading);

export const getGroupData = (id, startDate) =>
  createSelector(getDateData('groups', id, startDate), data => data);
export const getGroupError = (id, startDate) =>
  createSelector(getDateError('groups', id, startDate), error => error);
export const getGroupLoading = (id, startDate) =>
  createSelector(getDateLoading('groups', id, startDate), loading => loading);

export const getGroupModalData = (id, firstDayOfMonth, date) =>
  createSelector(getModalData('groups', id, firstDayOfMonth, date), data => data);
export const getGroupModalError = (id, firstDayOfMonth, date) =>
  createSelector(getModalError('groups', id, firstDayOfMonth, date), error => error);
export const getGroupModalLoading = (id, firstDayOfMonth, date) =>
  createSelector(getModalLoading('groups', id, firstDayOfMonth, date), loading => loading);
