import React from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import { isEmpty } from 'ramda';

import { Box, Flex, Loader, RemotePagination } from '../../mc-ui';

import EmptyResult from './EmptyResult';
import JourneyLine from './JourneyLine';
import NoJourneysYet from './NoJourneysYet';

class List extends React.PureComponent {
  getPageQuery = direction => {
    const { links, location, updateQuery } = this.props;
    const link = links[direction];

    if (!link) return;

    const query = qs.parse(link.split('?')[1]);

    updateQuery({
      ...location.query,
      page: query.page,
    });
  };

  render() {
    const { data, isJourneysListEmpty, links, loading, location, meta, ...props } = this.props;

    if (!loading && isJourneysListEmpty) {
      return (
        <Box marginTop="24px" padding={location.pathname === '/journeys/results' ? '0' : '0 32px'}>
          <NoJourneysYet />
        </Box>
      );
    } else if (!loading && isEmpty(data)) {
      return (
        <Box marginTop="24px" padding={location.pathname === '/journeys/results' ? '0' : '0 32px'}>
          <EmptyResult />
        </Box>
      );
    }

    return (
      <Box marginBottom="16px">
        {loading ? (
          <Flex alignItems="center" height="300px" justifyContent="center">
            <Loader />
          </Flex>
        ) : (
          <Box>
            {data.map(journey => (
              <JourneyLine key={journey.id} journey={journey} {...props} />
            ))}

            <Flex alignItems="center" justifyContent="center" marginTop="16px">
              <RemotePagination
                from={meta.from}
                nextUrl={links.next}
                onClick={this.getPageQuery}
                prevUrl={links.prev}
                selfUrl={links.self}
                to={meta.to}
                total={meta.total}
              />
            </Flex>
          </Box>
        )}
      </Box>
    );
  }
}

List.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isJourneysListEmpty: PropTypes.bool.isRequired,
  links: PropTypes.shape({}).isRequired,
  loading: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    query: PropTypes.shape({}),
    pathname: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({}).isRequired,
  updateQuery: PropTypes.func.isRequired,
};

export default List;
