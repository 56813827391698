import { assoc, assocPath } from 'ramda';

import * as types from './types';

export const INITIAL_STATE = {
  isCreateFormOpen: false,
  isEditFormOpen: null,
  teamLists: {
    loading: false,
  },
  userLists: {
    loading: false,
  },
  teamListIds: [],
  userListIds: [],
};

const closeCreateFormReducer = state => assoc('isCreateFormOpen', false, state);
const openCreateFormReducer = state => assoc('isCreateFormOpen', true, state);
const closeEditFormReducer = state => assoc('isEditFormOpen', null, state);
const openEditFormReducer = (state, { list }) => assoc('isEditFormOpen', list.id, state);

const setTeamListsLoadingReducer = state => assocPath(['teamLists', 'loading'], true, state);

const setTeamListsPayloadReducer = (state, action) => {
  const newState = assoc('teamLists', { ...action.data, loading: false }, state);
  return assoc('teamListIds', action.data.data.map(l => l.id), newState);
};

const setUserListsLoadingReducer = state => assocPath(['userLists', 'loading'], true, state);

const setUserListsPayloadReducer = (state, action) => {
  const newState = assoc('userLists', { ...action.data, loading: false }, state);
  return assoc('userListIds', action.data.data.map(l => l.id), newState);
};

const addItemsToListReducer = (state, { listId, items }) => {
  const { teamLists, userLists } = state;
  const teamListIndex = teamLists.data.findIndex(l => l.id === listId);
  const userListIndex = userLists.data.findIndex(l => l.id === listId);

  const emails = items.filter(i => i.type === 'emails');
  const textMessages = items.filter(i => i.type === 'text-messages');

  let teamList = null;
  if (teamListIndex !== -1) {
    teamList = teamLists.data[teamListIndex];
    const teamEmailsCount = teamList.attributes.approximate_emails + emails.length;
    teamList.attributes.approximate_emails = teamEmailsCount;
    teamList.relationships.emails.data = teamList.relationships.emails.data.concat(emails);

    const teamTextMessagesCount =
      teamList.attributes.approximate_text_messages + textMessages.length;
    teamList.attributes.approximate_text_messages = teamTextMessagesCount;
    teamList.relationships['text-messages'].data = teamList.relationships[
      'text-messages'
    ].data.concat(textMessages);
  }

  let userList = null;
  if (userListIndex !== -1) {
    userList = userLists.data[userListIndex];
    const userEmailsCount = userList.attributes.approximate_emails + emails.length;
    userList.attributes.approximate_emails = userEmailsCount;
    userList.relationships.emails.data = userList.relationships.emails.data.concat(emails);

    const userTextMessagesCount =
      userList.attributes.approximate_text_messages + textMessages.length;
    userList.attributes.approximate_text_messages = userTextMessagesCount;
    userList.relationships['text-messages'].data = userList.relationships[
      'text-messages'
    ].data.concat(textMessages);
  }

  const newState1 = teamList
    ? assocPath(['teamLists', 'data', teamListIndex], teamList, state)
    : state;

  const newState2 = userList
    ? assocPath(['userLists', 'data', userListIndex], userList, newState1)
    : newState1;

  return newState2;
};

const removeItemsFromListReducer = (state, { listId, items }) => {
  const { teamLists, userLists } = state;
  const emails = items.filter(i => i.type === 'emails');
  const textMessages = items.filter(i => i.type === 'text-messages');
  const emailIds = emails.map(e => e.id);
  const textMessageIds = textMessages.map(tm => tm.id);
  const teamListIndex = teamLists.data.findIndex(l => l.id === listId);
  const userListIndex = userLists.data.findIndex(l => l.id === listId);

  let teamList = null;
  if (teamListIndex !== -1) {
    teamList = teamLists.data[teamListIndex];
    const teamEmailsCount = teamList.attributes.approximate_emails - emails.length;
    teamList.attributes.approximate_emails = teamEmailsCount;
    teamList.relationships.emails.data = teamList.relationships.emails.data.filter(
      e => !emailIds.includes(e.id),
    );

    const teamTextMessagesCount =
      teamList.attributes.approximate_text_messages - textMessages.length;
    teamList.attributes.approximate_text_messages = teamTextMessagesCount;
    teamList.relationships['text-messages'].data = teamList.relationships[
      'text-messages'
    ].data.filter(tm => !textMessageIds.includes(tm.id));
  }

  let userList = null;
  if (userListIndex !== -1) {
    userList = userLists.data[userListIndex];
    const userEmailsCount = userList.attributes.approximate_emails - emails.length;
    userList.attributes.approximate_emails = userEmailsCount;
    userList.relationships.emails.data = userList.relationships.emails.data.filter(
      e => !emailIds.includes(e.id),
    );

    const userTextMessagesCount =
      userList.attributes.approximate_text_messages - textMessages.length;
    userList.attributes.approximate_text_messages = userTextMessagesCount;
    userList.relationships['text-messages'].data = userList.relationships[
      'text-messages'
    ].data.filter(tm => !textMessageIds.includes(tm.id));
  }

  const newState1 = teamList
    ? assocPath(['teamLists', 'data', teamListIndex], teamList, state)
    : state;

  const newState2 = userList
    ? assocPath(['userLists', 'data', userListIndex], userList, newState1)
    : newState1;

  return newState2;
};

const reducer = {
  [types.CLOSE_CREATE_FORM]: closeCreateFormReducer,
  [types.OPEN_CREATE_FORM]: openCreateFormReducer,
  [types.CLOSE_EDIT_FORM]: closeEditFormReducer,
  [types.OPEN_EDIT_FORM]: openEditFormReducer,
  [types.CREATE_SUCCESS]: closeCreateFormReducer,
  [types.UPDATE_SUCCESS]: closeEditFormReducer,
  [types.FETCH_USER_LISTS_REQUEST]: setUserListsLoadingReducer,
  [types.FETCH_USER_LISTS_SUCCESS]: setUserListsPayloadReducer,
  [types.FETCH_TEAM_LISTS_REQUEST]: setTeamListsLoadingReducer,
  [types.FETCH_TEAM_LISTS_SUCCESS]: setTeamListsPayloadReducer,
  [types.SHARE_WITH_TEAM_SUCCESS]: setTeamListsPayloadReducer,
  [types.DELETE_TEAM_LIST_SUCCESS]: setTeamListsPayloadReducer,
  [types.UPDATE_TEAM_LIST_SUCCESS]: closeEditFormReducer,
  [types.ADD_ITEMS_TO_LIST_SUCCESS]: addItemsToListReducer,
  [types.REMOVE_ITEMS_FROM_LIST_SUCCESS]: removeItemsFromListReducer,
};

export default (state = INITIAL_STATE, action = {}) => {
  const reducerFunction = reducer[action.type];

  if (!reducerFunction) return state;
  return reducerFunction(state, action);
};
