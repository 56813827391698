import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Box, Flex, Tab } from '../mc-ui';

import EmailInfo from './EmailInfo';
import EmailTopNavigation from './EmailTopNavigation';
import EmailView from './EmailView';
import { Details, Explore, Notes } from './Panels';
import NotesBadge from './NotesBadge';

class Email extends React.Component {
  constructor(props) {
    super(props);

    let { panel = 'explore' } = props.router.location.query;

    if (panel === 'notes' && !props.router.location.query.list_id) {
      panel = 'explore';
    }

    this.state = {
      approximateComments: 0,
      panel,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    window.addEventListener('keydown', this.handleKeyDown);
    const { params, requestEmail, router } = this.props;
    requestEmail(params.guid, router.location.query);
  }

  componentDidUpdate(prevProps) {
    if (!this.props.email.loading && prevProps.email.loading) {
      this.updateApproximateComments(this.props.email.data[0].attributes.approximate_comments || 0);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
    this.props.flushEmail();
  }

  handleKeyDown = event => {
    const { email, requestEmail, router } = this.props;

    if (event.code === 'ArrowLeft' && email.previous) {
      const { guid } = email.previous.attributes;

      router.push({
        pathname: `/emails/${guid}`,
        state: router.location.state,
        query: router.location.query,
      });

      requestEmail(guid, router.location.query);
    }

    if (event.code === 'ArrowRight' && email.next) {
      const { guid } = email.next.attributes;

      router.push({
        pathname: `/emails/${guid}`,
        state: router.location.state,
        query: router.location.query,
      });

      requestEmail(guid, router.location.query);
    }
  };

  handlePanelChange = panel => {
    const { router } = this.props;

    this.setState({ panel }, () => {
      const query = { ...router.location.query, panel };
      const { pathname } = router.location;

      router.push({ pathname, query });
    });
  };

  updateApproximateComments = approximateComments => {
    this.setState({ approximateComments });
  };

  showNotes = () => {
    const { email, router } = this.props;
    const [model] = email.data;
    const lists = ((model || {}).relationships || {}).lists ? ((model || {}).relationships || {}).lists.data : [];

    const list = email.loading ? null : lists.find(l => l.id === router.location.query.list_id);

    return list && !list.attributes.featured;
  };

  render() {
    const { email, router } = this.props;
    const { approximateComments, panel } = this.state;

    return (
      <div>
        <EmailTopNavigation email={email} router={router} />

        <Flex justifyContent="center">
          <Flex paddingTop="40px" width="1200px" minWidth="1024px"
            style={{
              '@media screen and (max-width: 1280px)': {
                minWidth: '1024px',
                maxWidth: '560px',
                paddingLeft: '10px',
              }
            }}
          >
            <Box borderColor="dark5" borderRight="1px solid" paddingRight="36px" width="744px"
              style={{
                '@media screen and (max-width: 1280px)': {
                  width: "655px"
                }
              }}
            >
              <EmailInfo email={email} />
              <EmailView email={email} router={router} />
            </Box>

            <Box marginLeft="28px" width="428px"
              style={{
                '@media screen and (max-width: 1280px)': {
                  width: "320px"
                }
              }}
            >
              <Tab defaultActive={panel} onTabChange={this.handlePanelChange}>
                <Tab.Header>
                  <Tab.Item name="explore">Explore</Tab.Item>
                  <Tab.Item name="details">Details</Tab.Item>
                  {this.showNotes() && (
                    <Tab.Item name="notes">
                      Notes
                      <NotesBadge approximateComments={approximateComments} />
                    </Tab.Item>
                  )}
                </Tab.Header>

                <Tab.Body>
                  {({ active }) => {
                    if (active === 'details') return <Details email={email} />;

                    if (active === 'notes' && this.showNotes()) {
                      return (
                        <Notes
                          approximateComments={approximateComments}
                          email={email}
                          router={router}
                          updateCounter={this.updateApproximateComments}
                        />
                      );
                    }

                    return <Explore email={email} router={router} />;
                  }}
                </Tab.Body>
              </Tab>
            </Box>
          </Flex>
        </Flex>
      </div>
    );
  }
}

Email.propTypes = {
  email: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        attributes: PropTypes.shape({
          approximate_comments: PropTypes.number,
          guid: PropTypes.string,
        }),
      }),
    ),
    loading: PropTypes.bool,
  }).isRequired,
  flushEmail: PropTypes.func.isRequired,
  params: PropTypes.shape({}).isRequired,
  requestEmail: PropTypes.func.isRequired,
  router: PropTypes.shape({
    location: PropTypes.shape({
      query: PropTypes.shape({
        list_id: PropTypes.string,
      }),
    }),
  }).isRequired,
};

const mapStateToProps = state => ({
  email: state.email,
});

const mapDispatchToProps = dispatch => ({
  requestEmail: (guid, params = {}) => dispatch({ type: 'REQUEST_EMAIL', guid, params }),
  flushEmail: () => dispatch({ type: 'FLUSH_EMAIL' }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Email);
