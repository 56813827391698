import { and, findIndex, propEq } from 'ramda';

const INITIAL_STATE = {
  loading: true,
  maintenance: false,
  authenticated: false,
  data: [],
  included: [],
  error: null,
};

export default (state = INITIAL_STATE, action) => {
  let included = [];
  switch (action.type) {
    case 'REQUEST_AUTHENTICATION':
      return { ...INITIAL_STATE, loading: true };
    case 'RECEIVED_USER': {
      const user = action.data.data.reduce((m, u) => u, {});
      if (action.data.error) user.attributes["error"] = true;
      const { id } = user;
      const { name, created_at: createdAt, email } = user.attributes;
      window.analytics.identify(id, { name, createdAt, email });
      return { ...state, ...action.data, loading: false, authenticated: true };
    }
    case 'RECEIVE_DASHBOARD':
      return { ...state, requested: action.data.requested };
    case 'UPDATE_USER':
      return { ...state, loading: true };
    case 'INVALID_CREDENTIALS':
      return { ...INITIAL_STATE, loading: false };
    case 'UNDER_MAINTENANCE':
      return { ...INITIAL_STATE, maintenance: true, loading: false };
    case 'INVALID_USER':
      return { ...INITIAL_STATE, loading: false, error: action.error };
    case 'CREATE_LIST_SUCCESS':
      included = [].concat([action.list], state.included || []);
      return { ...state, included, loading: false };
    case 'DELETE_LIST_SUCCESS':
      included = state.included.filter(i => {
        const deleted = i.type === 'lists' && i.id === action.list.id;
        return !deleted;
      });
      return { ...state, included, loading: false };
    case 'UPDATE_USER_LISTS': {
      const { list } = action;
      const index = findIndex(and(propEq('type', 'lists'), propEq('id', list.id)))(state.included);
      const newIncluded = [
        ...state.included.slice(0, index),
        list,
        ...state.included.slice(index + 1),
      ];
      return { ...state, included: newIncluded, loading: false };
    }
    default:
      return state;
  }
};
