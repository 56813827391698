/** @jsx jsx */
import PropTypes from 'prop-types';
import { jsx, withTheme } from '@emotion/react';

import { Flex, Loader, Text, Tooltip } from '../../../mc-ui';
import Bar from './Bar';

const NOOP = ret => ret;
const fixedValue = value => {
  if (Number.isNaN(value) || value === 'NaN') return '';
  return (value || 0).toFixed(2);
};

const barName = bucketName =>
  bucketName.toLowerCase() === 'Mailcharts Index' ? 'MailCharts Index' : bucketName;

const barSize = (buckets, bucket, append) => {
  if (append.trim() === '%') {
    return bucket.value === 0 ? 1 : bucket.value;
  }

  const max = Math.max(...buckets.map(b => b.value));
  const value = (bucket.value / max) * 100;
  return value > 0 ? value : 1;
};

const BarsChart = ({ adjustment, append, data, loading, theme, width }) => {
  if (loading) {
    return (
      <Flex
        alignItems="center"
        direction="column"
        justifyContent="center"
        height="150px"
        width={width}
      >
        <Loader />
      </Flex>
    );
  }

  return (
    <Flex
      style={{ background: `url(/benchmarks-bg.svg) 160px 0px no-repeat` }}
      direction="column"
      width={width}
    >
      {data.buckets.map((bucket, index) => {
        const barWidth = barSize(data.buckets, bucket, append);

        return (
          <Flex
            alignItems="center"
            css={{ marginBottom: index < data.buckets.length - 1 ? '24px' : null }}
            justifyContent="space-between"
            key={`${bucket.value}-${bucket.label}`}
          >
            <div
              css={{
                alignItems: 'center',
                color: theme.colors.dark1,
                fontFamily: theme.font.family,
                fontSize: '14px',
                fontWeight: '500',
                letterSpacing: '0.02em',
                lineHeight: '1.25em',
                overflow: 'hidden',
                textAlign: 'right',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                width: '150px',
              }}
            >
              {bucket.primary ? (
                <p style={{ margin: 0, padding: 0 }}>
                  <strong style={{ color: theme.colors.blue }}>{bucket.name}</strong>
                  <span
                    style={{ display: 'block', color: 'rgba(9, 30, 66, 0.6)', fontSize: '12px' }}
                  >
                    {bucket.type === 'groups' ? 'Group avg.' : 'Brand avg.'}
                  </span>
                </p>
              ) : (
                <p style={{ margin: 0, padding: 0 }}>
                  <strong style={{ color: 'rgba(9, 30, 66, 0.8)' }}>{bucket.label}</strong>
                  <span
                    style={{ display: 'block', color: 'rgba(9, 30, 66, 0.6)', fontSize: '12px' }}
                  >
                    {barName(bucket.name)}
                  </span>
                </p>
              )}
            </div>
            <div
              css={{
                flex: 1,
                margin: '0 0 0 28px',
              }}
            >
              {typeof bucket.value !== 'number' ? (
                <Text
                  css={{
                    color: theme.colors.dark04,
                    fontSize: '12px',
                    fontWeight: 'bold',
                    lineHeight: '1.56em',
                    textTransform: 'uppercase',
                  }}
                >
                  No Data
                </Text>
              ) : (
                <Tooltip
                  dark
                  css={{
                    '& > span': {
                      width: '100%',
                    },
                  }}
                  tooltipSpanWidth="100%"
                  effect="float"
                  element={<Bar size={100} blue={bucket.primary} />}
                  id={`${bucket.label.toLowerCase()}-${bucket.value}`}
                  wrapperWidth={`${barWidth}%`}
                >
                  {bucket.label} - {bucket.name}
                </Tooltip>
              )}
            </div>
            <div
              css={{
                alignItems: 'center',
                color: theme.colors.dark1,
                fontFamily: theme.font.family,
                fontSize: '14px',
                fontWeight: '500',
                letterSpacing: '0.02em',
                lineHeight: '1.25em',
                textAlign: 'right',
                width: '50px',
              }}
            >
              {`${adjustment(fixedValue(bucket.value))}${append}`}
            </div>
          </Flex>
        );
      })}
    </Flex>
  );
};

BarsChart.defaultProps = {
  adjustment: NOOP,
  append: '',
  data: {},
  width: '416px',
};

BarsChart.propTypes = {
  adjustment: PropTypes.func,
  append: PropTypes.string,
  data: PropTypes.shape({
    buckets: PropTypes.arrayOf(
      PropTypes.shape({
        comparison: PropTypes.bool,
        label: PropTypes.string,
        name: PropTypes.string,
        primary: PropTypes.bool,
        quartile: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      }),
    ),
  }),
  loading: PropTypes.bool.isRequired,
  theme: PropTypes.shape({}).isRequired,
  width: PropTypes.string,
};

export default withTheme(BarsChart);
