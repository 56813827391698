import styled from "@emotion/styled";

import background from './contactUsBg.png';

const ContactUsContainer = styled("section")(({ white }) => ({
  alignItems: 'center',
  backgroundColor: white ? '#fff' : '#f3f5f7',
  backgroundImage: `url(${background})`,
  backgroundPosition: '16px 10px',
  backgroundRepeat: 'no-repeat',
  borderRadius: '3px',
  boxSizing: 'border-box',
  display: 'flex',
  height: '160px',
  justifyContent: 'space-between',
  marginBottom: '42px',
  padding: '40px',
}));

export default ContactUsContainer;
