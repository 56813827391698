import React from 'react';
import PropTypes from 'prop-types';

import BrandsTable from '../../BrandsTable';

const BrandsList = ({ brands, full }) => (
  <BrandsTable>
    {brands.map(brand => (
      <BrandsTable.Row key={brand.id} full={full}>
        <BrandsTable.Logo brand={brand} />
        <BrandsTable.Bar
          id={brand.id}
          label={`${brand.value.toFixed(2)}%`}
          size={brand.value}
          tooltip={
            <p>
              <strong>{brand.attributes.name}:</strong>
              <br />
              {brand.value.toFixed(2)}%
            </p>
          }
        />
      </BrandsTable.Row>
    ))}
  </BrandsTable>
);

BrandsList.defaultProps = {
  full: false,
};

BrandsList.propTypes = {
  brands: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  full: PropTypes.bool,
};

export default BrandsList;
