import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Flex } from './mc-ui';

import Section from './Section';
import BrowseSearch from './BrowseSearch';
import BrowseMenu from './menus/BrowseMenu';

class Browse extends React.Component {
  componentDidMount() {
    const { router } = this.props;

    this.query = {
      ...router.location.query,
      ...router.params,
    };

    this.request();
  }

  UNSAFE_componentWillReceiveProps(props) {
    const query = {
      ...this.props.router.location.query,
      ...this.props.router.params,
    };
    if (query.q !== (this.query || {}).q) {
      this.query = query;
      this.request();
    }

    if (this.suggestions.loading === true && props.suggestions.loading === false) {
      this.replaceWithSuggestions(props);
    }

    this.suggestions = props.suggestions;
  }

  componentWillUnmount() {
    this.props.flush();
  }

  replaceWithSuggestions(props) {
    let { pathname } = this.props.router.location;

    if (pathname !== '/browse') return;

    const { query } = props.router.location;

    pathname = 'browse/emails';

    props.router.replace({ pathname, query });
  }

  request() {
    this.props.requestSuggestions(this.query);
  }

  query = {};
  suggestions = {};

  render() {
    return (
      <div className="pure-u-1">
        <BrowseSearch {...this.props} />
        <Section>
          <Flex justifyContent="space-between">
            <Flex css={{ width: '200px', marginRight: 32, marginTop: '1em' }}>
              <BrowseMenu {...this.props} />
            </Flex>
            <Flex grow="1">{this.props.suggestions.loading ? null : this.props.children}</Flex>
          </Flex>
        </Section>
      </div>
    );
  }
}

Browse.defaultProps = {
  children: null,
};

Browse.propTypes = {
  children: PropTypes.node,
  flush: PropTypes.func.isRequired,
  requestSuggestions: PropTypes.func.isRequired,
  router: PropTypes.shape({
    location: PropTypes.shape({
      query: PropTypes.object,
      pathname: PropTypes.string,
    }),
    params: PropTypes.object,
    replace: PropTypes.func,
  }).isRequired,
  suggestions: PropTypes.shape({
    data: PropTypes.array,
    loading: PropTypes.bool,
  }).isRequired,
};

const mapStateToProps = state => ({
  suggestions: state.suggestions,
});

const mapDispatchToProps = dispatch => ({
  flush: () => dispatch({ type: 'FLUSH' }),
  requestSuggestions: query => dispatch({ type: 'REQUEST_SUGGESTIONS', query }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Browse);
