import { camelizeKeys } from 'humps';
import moment from 'moment';
import { slice, take, takeLast } from 'ramda';
import RSSParser from 'rss-parser';

import api from '../../../lib/api';

export const fetchUpdates = () =>
  new Promise(async (resolve, reject) => {
    const response = await api.get('/rss');

    RSSParser.parseString(response.data, (err, { feed }) => {
      if (err) {
        reject(err);
        return;
      }

      const posts = feed.entries.slice(0, 3).map(entry => ({
        id: entry.guid,
        type: 'blog',
        attributes: {
          created_at: new Date(entry.isoDate).getTime(),
          description: entry.contentSnippet,
          link: entry.link,
          title: entry.title,
        },
      }));

      resolve(posts);
    });
  });

export const fetchFeaturedEmailLists = async () => {
  const response = await api.get('/v3/lists?limit=7&featured=true');
  return camelizeKeys(takeLast(7, response.data.data));
};

export const fetchLatestEmailLists = async () => {
  const response = await api.get('/v3/lists?limit=7&featured=true&page=2');
  const lists = camelizeKeys(response.data.data);

  if (lists.length > 7) return slice(0, -7, lists);
  return lists;
};

export const fetchFeaturedJourneys = async () => {
  const response = await api.get('/v3/journeys?featured=true&size=7');
  return camelizeKeys(take(7, response.data.data));
};

export const fetchFutureJourneys = async () => {
  const pathname = `/v3/journeys`;
  const from = moment(new Date())
    .startOf('day')
    .add(1, 'days')
    .format('YYYY-MM-DD');
  const params = { status: 'upcoming', sort: 'start_date', from };
  const response = await api.get(pathname, { params });
  const journeys = camelizeKeys(response.data.data);
  return journeys.slice(0, 7);
};
