import React from 'react';
import PropTypes from 'prop-types';
import humanize from 'humanize-number';
import styled from "@emotion/styled";
import { Link } from 'react-router';
import { palette } from '../lib/color';
import JourneyCompanyPicker from './JourneyCompanyPicker';
import JourneyTypePicker from './JourneyTypePicker';
import JourneyStartDatePicker from './JourneyStartDatePicker';
import JourneyProgressIndicator from './JourneyProgressIndicator';


const RemoveComparison = styled("a")({
  position: 'absolute',
  right: 0,
  top: 0,
  height: 32,
  width: 32,
  display: 'flex',
  justifyItems: 'center',
  alignItems: 'center',
  cursor: 'pointer',
});
const TypeAndStartDatePickers = styled("div")({
  display: 'flex',
  width: 400,
  justifyItems: 'space-between',
  marginBottom: 20,
});
const TypePickerWrapper = styled("div")({
  display: 'flex',
  marginRight: '2%',
  width: '40%',
});
const StartDatePickerWrapper = styled("div")({
  display: 'flex',
  width: '58%',
});
const KeyLabel = styled("div")({
  fontSize: 24,
  height: 36,
  width: 36,
  lineHeight: '36px',
  marginBottom: 10,
  textAlign: 'center',
  borderRadius: 4,
  color: palette.white,
});

const JourneyPickers = ({
  index, loading, includeKeyLabel, keyLabelColor, removeComparison,
  enableCompanyPicker, companies, selectedCompanyId, handleJourneyCompanyChange,
  journeyTypes, selectedJourneyTypeId, selectedJourneyColor, disabledJourneyTypeIds, handleJourneyTypeChange, journeyTypeDisabled,
  journeysForStartDatePicker, selectedJourneyId, disabledJourneyIds, handleJourneyStartDateChange, journeyStartDateDisabled,
  selectedJourney, restricted,
}) => {
  // There can only be 2 journeys compared
  const isLeft = index === 0;
  const isRight = index === 1;
  let action = null;
  if (isRight) {
    action = (
      <RemoveComparison onClick={() => { removeComparison(index); }}>
        <img src="/close.png" width="18" alt="Remove comparison" style={{ display: 'block' }} />
      </RemoveComparison>
    );
  }
  let comparoKeyLabel = null;
  if (includeKeyLabel) {
    comparoKeyLabel = (
      <KeyLabel style={{ background: keyLabelColor }}>
        {isLeft ? 'A' : 'B'}
      </KeyLabel>
    );
  }

  const Wrapper = styled("div")({
    opacity: loading ? 0.25 : 1,
    transition: 'opacity 0.3s',
  });

  return (
    <Wrapper key={index}>
      {comparoKeyLabel}
      {enableCompanyPicker &&
      <JourneyCompanyPicker
        index={index}
        companies={companies}
        selectedCompanyId={selectedCompanyId}
        handleChange={handleJourneyCompanyChange}
      />
        }
      <TypeAndStartDatePickers>
        <TypePickerWrapper>
          <JourneyTypePicker
            index={index}
            journeyTypes={journeyTypes}
            selectedJourneyTypeId={selectedJourneyTypeId}
            selectedJourneyColor={selectedJourneyColor}
            disabledJourneyTypeIds={disabledJourneyTypeIds}
            handleChange={handleJourneyTypeChange}
            disabled={journeyTypeDisabled}
          />
        </TypePickerWrapper>
        <StartDatePickerWrapper>
          <JourneyStartDatePicker
            index={index}
            journeys={journeysForStartDatePicker}
            selectedJourneyId={selectedJourneyId}
            disabledJourneyIds={disabledJourneyIds}
            handleChange={handleJourneyStartDateChange}
            disabled={journeyStartDateDisabled}
          />
        </StartDatePickerWrapper>
      </TypeAndStartDatePickers>
      <JourneyProgressIndicator
        journey={selectedJourney}
      />
      {(!restricted && selectedJourney) &&
      <p
        style={{
              fontSize: `${12 / 16}em`,
              margin: 0,
              marginTop: 10,
              padding: 0,
            }}
      >
        <span style={{
                textTransform: 'uppercase',
                fontSize: 12,
                letterSpacing: 2,
                border: 'none',
                color: palette.black,
              }}
        >
          { humanize(selectedJourney.attributes.journey_emails) } journey emails
          {' '}
          <Link
            to={`/companies/${selectedJourney.attributes.company_id}/emails?segment_id=${selectedJourney.id}&sort=asc`}
          >
            View all emails
          </Link>
        </span>
      </p>}
      {action}
    </Wrapper>);
};

JourneyPickers.propTypes = {
  index: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  includeKeyLabel: PropTypes.bool.isRequired,
  keyLabelColor: PropTypes.string,
  removeComparison: PropTypes.func.isRequired,
  enableCompanyPicker: PropTypes.bool,
  companies: PropTypes.arrayOf(PropTypes.object),
  selectedCompanyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleJourneyCompanyChange: PropTypes.func.isRequired,
  journeyTypes: PropTypes.arrayOf(PropTypes.object),
  selectedJourneyTypeId: PropTypes.string,
  selectedJourneyColor: PropTypes.string,
  disabledJourneyTypeIds: PropTypes.arrayOf(PropTypes.string),
  handleJourneyTypeChange: PropTypes.func.isRequired,
  journeyTypeDisabled: PropTypes.bool,
  journeysForStartDatePicker: PropTypes.arrayOf(PropTypes.object),
  selectedJourneyId: PropTypes.string,
  disabledJourneyIds: PropTypes.arrayOf(PropTypes.string),
  handleJourneyStartDateChange: PropTypes.func.isRequired,
  journeyStartDateDisabled: PropTypes.bool,
  selectedJourney: PropTypes.shape({}),
  restricted: PropTypes.bool,
};

JourneyPickers.defaultProps = {
  companies: [],
  keyLabelColor: palette.grayA,
  enableCompanyPicker: false,
  selectedCompanyId: null,
  journeyTypes: [],
  selectedJourneyTypeId: null,
  selectedJourneyColor: palette.grayA,
  disabledJourneyTypeIds: [],
  journeyTypeDisabled: false,
  journeysForStartDatePicker: [],
  selectedJourneyId: null,
  disabledJourneyIds: [],
  journeyStartDateDisabled: false,
  selectedJourney: null,
  restricted: false,
};

export default JourneyPickers;
