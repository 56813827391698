import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { teamsSelectors } from '../../../modules/teams';
import { userSelectors } from '../../../modules/user';

import Container from '../../Container';
import Section from '../../Section';
import TextMessagesList from '../TextMessagesList';
import PageHeader from './PageHeader';

class TextMessagesExamples extends React.Component {
  render() {
    const {
      isPro,
      isUserRestricted,
      isSubscriptionAutomaticallyCharged,
      hasSmsAddon,
      router
    } = this.props;
    return (
      <div>
        <PageHeader
          isUserRestricted={isUserRestricted}
          isSubscriptionAutomaticallyCharged={isSubscriptionAutomaticallyCharged}
          hasSmsAddon={hasSmsAddon}
        />

        <Section>
          <Container
            style={{
              background: undefined,
              margin: '0',
              paddingBottom: '40px',
              paddingLeft: '0',
              paddingRight: '0',
              paddingTop: '40px',
            }}
          >
            <TextMessagesList
              isPro={isPro}
              router={router}
              hasSmsAddon={hasSmsAddon}
            />
          </Container>
        </Section>
      </div>
    );
  };
}

TextMessagesExamples.propTypes = {
  isPro: PropTypes.bool.isRequired,
  isUserRestricted: PropTypes.bool.isRequired,
  isSubscriptionAutomaticallyCharged: PropTypes.bool.isRequired,
  hasSmsAddon: PropTypes.bool.isRequired,
  router: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => ({
  isPro: teamsSelectors.isProSelector(state),
  isUserRestricted: userSelectors.isUserRestrictedSelector(state),
  isSubscriptionAutomaticallyCharged: teamsSelectors.isSubscriptionAutomaticallyChargedSelector(state),
  hasSmsAddon: teamsSelectors.hasSmsAddonSelector(state),
});

export default connect(
  mapStateToProps,
)(TextMessagesExamples);
