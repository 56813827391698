import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router'
import { isEmpty } from 'ramda';

import { www } from '../lib/utils';

import Container from './Container';
import GroupsList from './GroupsList';
import GroupsCreate from './GroupsCreate';

const ManageGroups = ({ router, user }) => {
  if (user.loading) {
    return null;
  }

  const groups = user.included.filter(i => i.type === 'groups' && !i.attributes.tracked);

  const emptyListDescription = (
    <div>
      <div>
        <h3>Create your first Group</h3>
        <p>
          With Groups, you can focus on specific companies’ email behavior side-by-side,{' '}
          <a href={www('/features/industries-and-groups')} target="_blank">
            learn more here
          </a>.
          <br />
          More importantly, you can{' '}
          <a href={www('/features/compare')} target="_blank">
            create powerful comparisons
          </a>{' '}
          for benchmarking and finding optimization insights.
        </p>
        <p>
          <Link
            to='/brands'
            className='pure-button pure-button-primary'
          >
            Finds Brands &amp; Get Started
          </Link> 
        </p>
      </div>
    </div>
  );

  return (
    <div className="pure-u-1" style={{ margin: '2em 0' }}>
        {isEmpty(groups) && (
          <Container style={{ padding: '15px' }}>
            <div className="pure-u-1" style={{ marginTop: '16px' }}>
              {emptyListDescription}
            </div>
          </Container>
        )}

      {!isEmpty(groups) && (
        <div>
          <h4>Your groups</h4>
          <GroupsList groups={groups} router={router} />
        </div>
      )}
    </div>
  );
};

ManageGroups.propTypes = {
  router: PropTypes.shape({}).isRequired,
  user: PropTypes.shape({
    included: PropTypes.array,
    loading: PropTypes.bool,
  }).isRequired,
};

const s = state => ({ user: state.user });
export default connect(s)(ManageGroups);
