const staticHolidays = {
  '1/1': "New Year's Day",
  '2/2': 'Groundhog Day',
  '2/14': "Valentine's Day",
  '3/17': "St. Patrick's Day",
  '4/22': 'Earth Day',
  '7/4': 'Independence Day',
  '9/11': 'Patriot Day',
  '10/31': 'Halloween',
  '11/11': "Veterans' Day",
  '12/7': 'Pearl Harbor Day',
  '12/25': 'Christmas Day',
  '12/31': "New Year's Eve",
};

const dynamicHolidays = {
  '1/21/2019': 'MLK Day',
  '1/20/2020': 'MLK Day',
  '2/18/2019': "Presidents' Day",
  '2/17/2020': "Presidents' Day",
  '5/12/2019': "Mother's Day",
  '5/10/2020': "Mother's Day",
  '5/27/2019': 'Memorial Day',
  '5/25/2020': 'Memorial Day',
  '6/16/2019': "Father's Day",
  '6/21/2020': "Father's Day",
  '9/2/2019': 'Labor Day',
  '9/7/2020': 'Labor Day',
  '10/14/2019': 'Columbus Day',
  '10/12/2020': 'Columbus Day',
  '11/28/2019': 'Thanksgiving Day',
  '11/26/2020': 'Thanksgiving Day',
};

const isHoliday = date => {
  const staticDate = date.format('M/D');
  if (staticHolidays[staticDate]) return staticHolidays[staticDate];

  const formattedDate = date.format('M/D/YYYY');
  if (dynamicHolidays[formattedDate]) return dynamicHolidays[formattedDate];

  return undefined;
};

export default isHoliday;
