import React from 'react';
import PropTypes from 'prop-types';

import { Flex, Spinner, Text } from '../mc-ui';

import NoComments from './NoComments';

import Comment from './Comment';

export default function Comments({ comments, loading, refetch, ...props }) {
  if (loading) {
    return (
      <Flex alignItems="center" height="88px" justifyContent="center">
        <Spinner size="32px" />
      </Flex>
    );
  }

  if (comments.length === 0) {
    return (
      <Flex alignItems="center" direction="column" justifyContent="center" paddingTop="120px">
        <NoComments />
        <Text css={{ marginTop: '10px' }}>Don’t let your thoughts slip away. Make a Note!</Text>
      </Flex>
    );
  }

  return (
    <div>
      {comments.map(comment => (
        <Comment key={comment.id} comment={comment} refetch={refetch} {...props} />
      ))}
    </div>
  );
}

Comments.propTypes = {
  comments: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  refetch: PropTypes.func.isRequired,
};
