import styled from "@emotion/styled";
import { withProps } from 'recompose';

const componentFactory = ({
  as,
  css = {},
  style = {},
  componentStyle = {},
  className = '',
  ...props
}) => {
  if (!as) {
    throw new Error('Prop `as` is required when rendering any UI component');
  }

  const headingStyle = {
    ...componentStyle,
    ...css,
    ...style,
  };

  return withProps(() => props)(styled(as)(() => (headingStyle)));
};

export default componentFactory;
