/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import humanize from 'humanize-number';
import { Link as RRLink } from 'react-router';
import { connect } from 'react-redux';
import { isEmpty, isNil } from 'ramda';

import api from '../../lib/api';
import { TYPES } from '../../modules/shareButton/constants';

import ContentLoader from '../ui/ContentLoader';
import GroupLogoSquare from '../ui/GroupLogoSquare';
import ShareButton from '../ui/ShareButton';
import { Box, Button, Flex, Heading, Link, Text, theme } from '../mc-ui';

import SubscribeToWeeklyRollUp from './SubscribeToWeeklyRollUp';
import { customGroupsActions } from '../../modules/customGroups';

const isNotPresent = value => isNil(value) || isEmpty(value);

class GroupInformation extends React.Component {
  state = {
    editing: false,
    group: {},
  };

  componentDidUpdate(prevProps) {
    if (this.props.loading !== prevProps.loading && !this.props.loading) {
      const group = this.props.groups.data.reduce((m, g) => g, {});
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ editing: false, group });
    }
  }

  handleDelete = event => {
    event.preventDefault();
    const confirmed = window.confirm('Are you sure you would like to delete this group?');
    if (!confirmed) return;

    const { fetchTeamGroups, fetchUserGroups, refreshUser, router } = this.props;
    const { group } = this.state;

    api
      .delete(`/v2/groups/${group.id}`)
      .then(() => {
        refreshUser();
        fetchTeamGroups();
        fetchUserGroups();
        router.replace('/manage/groups');
      })
      .catch(console.error);
  };

  handleChange = event => {
    event.preventDefault();
    const { group } = this.state;

    group.attributes[event.target.name] = event.target.value;
    this.setState({ group });
  };

  handleEdit = event => {
    event.preventDefault();

    const { fetchTeamGroups, fetchUserGroups, refreshUser } = this.props;

    this.setState({ editing: !this.state.editing });
    if (this.state.editing) {
      api
        .patch(`/v2/groups/${this.state.group.id}`, { ...this.state.group.attributes })
        .then(() => {
          refreshUser();
          fetchTeamGroups();
          fetchUserGroups();
        })
        .catch(console.error);
    }
  };

  render() {
    const { editing, group = {} } = this.state;

    const loading = this.props.loading || isNotPresent(group);
    const isOwner = group.attributes && group.attributes.owner;

    let approximateCompanies = 0;
    let remainingCompanies = 0;
    if (!loading) {
      approximateCompanies = group.attributes.approximate_companies;
      remainingCompanies = approximateCompanies - 4;
    }

    return (
      <Flex alignItems="center" height="144px">
        {loading ? (
          <Box height="88px" marginRight="32px" width="88px">
            <ContentLoader height="88px" width="88px" />
          </Box>
        ) : (
          <Box height="88px" marginRight="32px" position="relative" width="88px">
            <GroupLogoSquare group={group} />
            {remainingCompanies > 0 && (
              <Box
                backgroundColor="white"
                border="1px solid"
                borderColor="dark5"
                borderRadius="4px"
                bottom="-8px"
                height="16px"
                padding="0 4px"
                position="absolute"
                right="-14px"
                width="unset"
                css={{
                  fontSize: '12px',
                  fontWeight: 'bold',
                  lineHeight: '16px',
                  letterSpacing: '0.08em',
                  '& > a': {
                    color: theme.colors.dark08,
                    lineHeight: '16px',
                    textDecoration: 'none',
                  },
                }}
              >
                <RRLink to={`/groups/${group.id}/companies`}>
                  + {humanize(remainingCompanies || 0)}
                </RRLink>
              </Box>
            )}
          </Box>
        )}

        {editing ? (
          <Box flex="1" marginRight="32px" width="unset">
            <form onSubmit={this.handleEdit}>
              <input type="submit" style={{ display: 'none' }} />
              <input
                autoFocus
                name="name"
                onChange={this.handleChange}
                onFocus={e => e.target.select()}
                placeholder="Name"
                value={group.attributes.name}
                style={{
                  border: `1px solid ${theme.colors.dark5}`,
                  borderRadius: '4px',
                  boxSizing: 'border-box',
                  color: theme.colors.dark08,
                  fontSize: '24px',
                  margin: '0',
                  padding: '14px',
                  width: '100%',
                }}
              />
            </form>
          </Box>
        ) : (
          <Box flex="1" width="unset">
            {group.attributes && (
              <Text
                color="dark1"
                fontSize="14px"
                fontWeight="500"
                letterSpacing="0.02em"
                lineHeight="1.25em"
              >
                {isOwner ? (
                  <Link to="/manage/groups">Custom Groups</Link>
                ) : (
                  <Link to="/groups">Industries</Link>
                )}{' '}
                /
              </Text>
            )}

            {loading ? (
              <Box height="40px" width="200px">
                <ContentLoader height="40px" width="200px" />
              </Box>
            ) : (
              <Heading as="h2" color="dark1" fontSize="32px" fontWeight="300" lineHeight="1.25em">
                {group.attributes.name}
              </Heading>
            )}
          </Box>
        )}

        {!loading && (
          <Flex alignItems="center" width="unset" wrap="nowrap">
            {isOwner &&
              editing && (
                <Button
                  onClick={this.handleDelete}
                  variant="destructive"
                  css={{
                    alignItems: 'center',
                    display: 'inline-flex',
                    height: '32px',
                    justifyContent: 'center',
                    margin: '0 8px 0 0',
                    padding: '0',
                    width: '72px',
                  }}
                >
                  Delete
                </Button>
              )}

            {isOwner && (
              <Button
                onClick={this.handleEdit}
                variant="primary"
                css={{
                  alignItems: 'center',
                  display: 'inline-flex',
                  height: '32px',
                  justifyContent: 'center',
                  margin: '0',
                  padding: '0',
                  width: editing ? '56px' : '96px',
                }}
              >
                {editing ? 'Save' : 'Edit group'}
              </Button>
            )}

            {!loading && !isOwner && <SubscribeToWeeklyRollUp group={group} />}

            {!editing && (
              <ShareButton
                type={TYPES.industryOverview}
                model={group}
                css={{ marginLeft: '8px' }}
              />
            )}
          </Flex>
        )}
      </Flex>
    );
  }
}

GroupInformation.propTypes = {
  fetchTeamGroups: PropTypes.func.isRequired,
  fetchUserGroups: PropTypes.func.isRequired,
  groups: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.object),
    included: PropTypes.arrayOf(PropTypes.object),
    links: PropTypes.object,
    loading: PropTypes.bool,
    meta: PropTypes.object,
    relationships: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  refreshUser: PropTypes.func.isRequired,
  router: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
};

const mapStateToProps = state => ({
  groups: state.groups,
});

const mapDispatchToProps = dispatch => ({
  flush: () => dispatch({ type: 'FLUSH' }),
  refreshUser: () => dispatch({ type: 'REFRESH_USER' }),
  fetchTeamGroups: () => dispatch(customGroupsActions.fetchTeamGroupsRequest()),
  fetchUserGroups: () => dispatch(customGroupsActions.fetchUserGroupsRequest()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GroupInformation);
