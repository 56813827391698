import styled from "@emotion/styled";

const NavigationButton = styled("button")(({ left, right, theme }) => ({
  alignItems: 'center',
  backgroundColor: 'transparent !important',
  border: 'none',
  color: theme.colors.dark4,
  display: 'inline-flex',
  fontSize: '32px',
  height: '100%',
  justifyContent: 'center',
  left: left ? '0' : null,
  margin: '0',
  outline: 'none',
  padding: '0',
  position: 'absolute',
  right: right ? '0' : null,
  top: '0',
  width: '32px',
  '&:hover': {
    color: theme.colors.dark2,
  },
}));

export default NavigationButton;
