import React from 'react'
import styles from './styles.css'

class Header extends React.Component {
  render() {
    return (
      <div className='pure-u-1'>
        <div style={{ background: '#FFFFFF', ...this.props.style }}>
          <div style={{ margin: '0 auto', maxWidth: 1280, }}>
            <div className='pure-u-1-12' />
            <div className='pure-u-5-6'>
              {this.props.children}
            </div>
            <div className='pure-u-1-12' />
          </div>
        </div>
      </div>
    )
  }
}

export default Header
