import React from 'react';
import NavigationDropdown from '../NavigationDropdown';
import NavigationItem from './NavigationItem';

function SMSLabel() {
  return (
    <span>
      SMS
    </span>
  );
}

const SMS = () => (
  <NavigationItem
    label={<SMSLabel />}
    to={{ pathname: '/text-messages', query: { sort: 'sent_at:desc' } }}
  >
    <NavigationDropdown>
      <NavigationDropdown.Item to="/lists/9513">Birthday</NavigationDropdown.Item>
      <NavigationDropdown.Item to="/lists/9509">Cart Abandonment</NavigationDropdown.Item>
      <NavigationDropdown.Item to="/lists/9512">Emojis</NavigationDropdown.Item>
      <NavigationDropdown.Item to="/lists/9511">Flash Sale </NavigationDropdown.Item>
      <NavigationDropdown.Item to="/lists/9510">Opt-in</NavigationDropdown.Item>
      <NavigationDropdown.Item to="/lists/9508">Promotional</NavigationDropdown.Item>
      <NavigationDropdown.Item to="/lists/9514">Rewards and Loyalty</NavigationDropdown.Item>
      <NavigationDropdown.Item to="/lists/9507">Welcome</NavigationDropdown.Item>
      <NavigationDropdown.Item to="/lists/10439">Back in Stock</NavigationDropdown.Item>
      <NavigationDropdown.Item to="/lists/10440">Automated Text Messages</NavigationDropdown.Item>
    </NavigationDropdown>
  </NavigationItem>
);

export default SMS;
