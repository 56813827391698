import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import pluralize from 'pluralize';
import { omit } from 'ramda';

import { ignoreFilters } from '../../../modules/savedSearches/constants';

import { Badge, Box, Flex, Link, Text, theme } from '../../mc-ui';

import FilterControlDropdown from './FilterControlDropdown';

export default function FilterLine({ collectionType, filter }) {
  const totalParams = Object.keys(omit(ignoreFilters, filter.attributes.params)).length;
  const params = Object.keys(filter.attributes.params).map(
    key => `${key}=${filter.attributes.params[key]}`,
  );
  const splitParams = param => {
    const [key, value] = param.split('=');
    if (value.includes(',')) {
      const values = value.split(',');
      return values.map(splitvalue => `${key}=${splitvalue}`).join('&');
    }
    return param;
  };
  const url = `/brands/results?${params.map(splitParams).join('&')}`;

  return (
    <Flex
      alignItems="center"
      borderBottom="1px solid transparent"
      borderColor="dark5"
      padding="16px 0"
      css={{
        '&:hover': {
          backgroundColor: theme.colors.dark004,
        },
        '&:last-child': {
          border: 'none',
        },
      }}
    >
      <Box flex="2" width="unset">
        <Badge
          backgroundColor="white"
          border="1px solid transparent"
          borderColor="dark5"
          color="dark08"
          lineHeight="14px"
          padding="0 4px"
        >
          {filter.attributes.type}
        </Badge>
      </Box>

      <Box flex="4" width="unset">
        <Link to={url}>{filter.attributes.name}</Link>
      </Box>

      <Box flex="1" width="unset">
        <Text>
          {totalParams} {pluralize('filter', totalParams)}
        </Text>
      </Box>

      <Box flex="3" width="unset">
        <Text>{moment(filter.attributes.created_at).format('hh:mm A, DD MMMM YYYY')}</Text>
      </Box>

      <Box flex="1" width="unset">
        <Link to={url}>View</Link>
      </Box>

      <FilterControlDropdown filter={filter} isFilteringTeamFilters={collectionType === 'team'} />
    </Flex>
  );
}

FilterLine.propTypes = {
  collectionType: PropTypes.string.isRequired,
  filter: PropTypes.shape({
    attributes: PropTypes.shape({
      created_at: PropTypes.string,
      name: PropTypes.string,
      params: PropTypes.object,
      type: PropTypes.string,
    }),
  }).isRequired,
};
