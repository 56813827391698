import * as types from './types';

export const close = (type, model) => ({
  type: types.CLOSE,
  contentType: type,
  model,
});

export const open = (type, model) => ({
  type: types.OPEN,
  contentType: type,
  model,
});
