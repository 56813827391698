import React from 'react';
import PropTypes from 'prop-types';

import False from './icons/False';
import True from './icons/True';

const Boolean = ({ value, ...props }) => (value ? <True {...props} /> : <False {...props} />);

Boolean.propTypes = {
  value: PropTypes.bool.isRequired,
};

export default Boolean;
