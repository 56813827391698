export default function generateDataset(theme, chartData) {
  return {
    borderColor: theme.colors.blue,
    borderWidth: 2,
    data: chartData.data,
    fill: false,
    label: 'This Period',
    lineTension: 0,
    pointBackgroundColor: theme.colors.white,
    pointBorderColor: theme.colors.blue,
    pointBorderWidth: 2,
    pointHitRadius: 0,
    pointHoverBackgroundColor: theme.colors.blue,
    pointHoverBorderColor: theme.colors.white,
    pointHoverRadius: 8,
    pointRadius: 0,
    pointStyle: 'circle',
    titles: chartData.titles,
    type: 'line',
  };
}
