import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import styled from "@emotion/styled";

import { palette } from '../lib/color';
import JourneyTimelineEvent from './JourneyTimelineEvent';
import JourneyTimelineEmail from './JourneyTimelineEmail';

const discDiam = 8;
const intervalColWidth = 120;
const cardHeaderHeight = 80;
const styles = {
  modelWrapper: {
    display: 'flex',
    position: 'relative',
  },
  p: {
    fontStyle: 'italic',
    display: 'inline-block',
    fontSize: `${12 / 16}em`,
    color: palette.grayA,
  },
  interval: {
    position: 'relative',
    top: cardHeaderHeight / 2,
    width: intervalColWidth,
    borderRight: `2px solid ${palette.grayD}`,
    boxSizing: 'border-box',
  },
  intervalLabel: {
    position: 'absolute',
    bottom: -8,
    right: 20,
    width: '100%',
    textAlign: 'right',
    color: palette.grayA,
    fontSize: `${12 / 16}em`,
  },
  startDateLabel: {
    whiteSpace: 'nowrap',
  },
  disc: {
    position: 'absolute',
    right: (discDiam / -2) - 3,
    top: (discDiam / -2) - 1,
    width: discDiam,
    height: discDiam,
    border: `2px solid ${palette.grayF}`,
    borderRadius: '50%',
    background: palette.grayA,
  },
  stem: {
    position: 'absolute',
    left: '100%',
    top: 0,
    height: 2,
    background: palette.grayD,
  },
};

class JourneyTimeline extends React.Component {
  getIntervalLabel = (start, end) => moment.duration(moment(start).diff(moment(end))).humanize()

  getDayNumber = (firstActivity, startOfDuration, idx) => {
    // Don't display for first item
    if (idx === 0) return null;
    const dayNumber = Math.abs(moment(firstActivity).diff(moment(startOfDuration), 'days')) + 1;
    const DayNumberDiv = styled("div")({
      position: 'absolute',
      bottom: '100%',
      marginBottom: 6,
      fontSize: `${12 / 16}em`,
      color: palette.grayA,
    });
    return <DayNumberDiv>Day {dayNumber}</DayNumberDiv>;
  }

  getIntervalHeight = (start, end, type) => {
    let height = cardHeaderHeight;
    if (type === 'emails') {
      height = cardHeaderHeight + 400;
    }
    return height;
  }

  getTimeline = () => {
    const legs = this.props.journeyWithDetails.included.map((m, idx, arr) => {
      const hasNext = !!arr[idx + 1];
      const start = m.attributes.date;
      const end = hasNext ? arr[idx + 1].attributes.date : null;
      const minHeight = hasNext ? this.getIntervalHeight(start, end, m.attributes.type) : 140;
      const discColor = m.type === 'emails' ? palette.grayA : palette.orange;
      const interval = hasNext
        ? (
          <div style={styles.interval}>
            <div style={styles.intervalLabel}>
              {this.getIntervalLabel(start, end)}
            </div>
            <div style={{ ...styles.stem, width: this.props.compactMode ? 40 : 80 }} />
            <div style={{ ...styles.disc, backgroundColor: discColor }} />
          </div>
        )
        : (
          <div style={{ ...styles.interval, borderRight: 'none' }}>
            <div style={{ ...styles.stem, width: this.props.compactMode ? 40 : 80 }} />
            <div style={{ ...styles.disc, backgroundColor: discColor, right: -5 }} />
          </div>
        );

      const emailOrEvent = m.type === 'emails'
        ? <JourneyTimelineEmail email={m} width={this.props.compactMode ? 300 : 400} />
        : <JourneyTimelineEvent event={m} width={this.props.compactMode ? 300 : 400} />;

      const dayNumber = this.getDayNumber(
        this.props.journeyWithDetails.data[0].attributes.first_activity_at,
        start,
        idx,
      );

      return (
        <div key={m.id} style={{ ...styles.modelWrapper, minHeight }}>
          {interval}
          <div style={{ marginLeft: (this.props.compactMode ? 40 : 80), marginBottom: 40 }}>
            {dayNumber}
            {emailOrEvent}
          </div>
        </div>
      );
    });

    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div style={{ paddingTop: 50 }}>
          <div style={styles.interval}>
            <div style={styles.intervalLabel}>
              <div>Journey created</div>
              <div style={styles.startDateLabel}>
                {moment(this.props.journeyWithDetails.data[0].attributes.first_activity_at).format('MMM. D, YYYY')}
              </div>
            </div>
          </div>
          {legs}
        </div>
      </div>
    );
  }

  render() {
    let content = <p style={styles.p}>No events available</p>;
    const { included } = this.props.journeyWithDetails;
    const events = included.filter(m => m.type === 'events');
    if (events.length >= 0) {
      content = <div>{this.getTimeline()}</div>;
    }
    return (
      <div>{content}</div>
    );
  }
}

JourneyTimeline.defaultProps = {
  compactMode: false,
};

JourneyTimeline.propTypes = {
  journeyWithDetails: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({
      attributes: PropTypes.shape({
        first_activity_at: PropTypes.string,
      }),
    })),
    included: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  compactMode: PropTypes.bool,
};

const s = () => ({});
export default connect(s)(JourneyTimeline);
