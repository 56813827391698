import styled from "@emotion/styled";

const EmptyListWrapper = styled("div")(({ renderOutside }) => ({
  margin: '0 auto',
  position: 'relative',
  maxWidth: renderOutside ? '610px' : '360px',
  width: '100%',
}));

export default EmptyListWrapper;
