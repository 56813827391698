import * as React from 'react';
import PropTypes from 'prop-types';

function CommentPlusIcon({ fillOpacity, ...props }) {
  return (
    <svg width="2em" height="2em" viewBox="0 0 640 512" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M256 32C114.6 32 .0281 125.1 .0281 240c0 49.63 21.35 94.98 56.97 130.7C44.5 421.1 2.729 466 2.229 466.5c-2.25 2.25-2.875 5.734-1.5 8.734C1.979 478.2 4.75 480 8 480c66.25 0 115.1-31.76 140.6-51.39C181.2 440.9 217.6 448 256 448c141.4 0 255.1-93.13 255.1-208S397.4 32 256 32zM336 263.1h-56v56C279.1 333.3 269.3 344 256 344c-13.27 0-23.1-10.74-23.1-23.1V263.1H175.1C162.7 263.1 152 253.3 152 240c0-13.26 10.74-23.1 23.1-23.1h56V159.1C232 146.7 242.7 136 256 136s23.1 10.74 23.1 23.1V216h56C349.3 216 360 226.7 360 240S349.3 263.1 336 263.1z"
        fill="currentColor"
        fillOpacity={fillOpacity}
      />
    </svg>
  );
}

CommentPlusIcon.propTypes = {
  fillOpacity: PropTypes.number,
};

CommentPlusIcon.defaultProps = {
  fillOpacity: 1,
};

export default CommentPlusIcon;
