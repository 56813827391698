import moment from 'moment';

const sortEmailsAndEvents = (emails, events) =>
  [].concat(emails, events).sort((a, b) => {
    const aDate = moment(a.type === 'emails' ? a.attributes.sentAt : a.attributes.occurredAt);
    const bDate = moment(b.type === 'emails' ? b.attributes.sentAt : b.attributes.occurredAt);

    if (aDate.isBefore(bDate)) return -1;
    if (aDate.isAfter(bDate)) return 1;

    return 0;
  });

export default sortEmailsAndEvents;
