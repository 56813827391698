import * as types from './types';

export const fetchTeamRequest = (id, { filter = {}, updateLoading = true } = {}) => ({
  type: types.FETCH_TEAM_REQUEST,
  id,
  filter,
  updateLoading,
});

export const fetchTeamSuccess = data => ({
  type: types.FETCH_TEAM_SUCCESS,
  data,
});

export const fetchTeamFailure = error => ({
  type: types.FETCH_TEAM_FAILURE,
  error,
});

export const updateTeamRequest = (payload, callback) => ({
  type: types.UPDATE_TEAM_REQUEST,
  payload,
  callback,
});

export const updateTeamSuccess = data => ({
  type: types.UPDATE_TEAM_SUCCESS,
  data,
});

export const updateTeamFailure = error => ({
  type: types.UPDATE_TEAM_FAILURE,
  error,
});

export const inviteMemberRequest = (teamId, payload) => ({
  type: types.INVITE_MEMBER_REQUEST,
  teamId,
  payload,
});

export const inviteMemberSuccess = data => ({
  type: types.INVITE_MEMBER_SUCCESS,
  data,
});

export const inviteMemberFailure = error => ({
  type: types.INVITE_MEMBER_FAILURE,
  error,
});

export const archiveMemberRequest = (teamId, id) => ({
  type: types.ARCHIVE_MEMBER_REQUEST,
  teamId,
  id,
});

export const archiveMemberSuccess = id => ({
  type: types.ARCHIVE_MEMBER_SUCCESS,
  id,
});

export const archiveMemberFailure = error => ({
  type: types.ARCHIVE_MEMBER_FAILURE,
  error,
});

export const removeMemberRequest = (teamId, id) => ({
  type: types.REMOVE_MEMBER_REQUEST,
  teamId,
  id,
});

export const removeMemberSuccess = id => ({
  type: types.REMOVE_MEMBER_SUCCESS,
  id,
});

export const removeMemberFailure = error => ({
  type: types.REMOVE_MEMBER_FAILURE,
  error,
});

export const updateMemberRequest = (teamId, id, payload) => ({
  type: types.UPDATE_MEMBER_REQUEST,
  teamId,
  id,
  payload,
});

export const activateMemberRequest = (teamId, id, payload) => ({
  type: types.ACTIVATE_MEMBER_REQUEST,
  teamId,
  id,
  payload,
});

export const updateMemberSuccess = data => ({
  type: types.UPDATE_MEMBER_SUCCESS,
  data,
});

export const updateMemberFailure = error => ({
  type: types.UPDATE_MEMBER_FAILURE,
  error,
});

export const cancelInvitationRequest = (teamId, id) => ({
  type: types.CANCEL_INVITATION_REQUEST,
  teamId,
  id,
});

export const cancelInvitationSuccess = data => ({
  type: types.CANCEL_INVITATION_SUCCESS,
  data,
});

export const cancelInvitationFailure = error => ({
  type: types.CANCEL_INVITATION_FAILURE,
  error,
});

export const resendInvitationRequest = (teamId, id) => ({
  type: types.RESEND_INVITATION_REQUEST,
  teamId,
  id,
});

export const resendInvitationSuccess = data => ({
  type: types.RESEND_INVITATION_SUCCESS,
  data,
});

export const resendInvitationFailure = error => ({
  type: types.RESEND_INVITATION_FAILURE,
  error,
});

export const createSubscriptionRequest = (payload, callback) => ({
  type: types.CREATE_SUBSCRIPTION_REQUEST,
  payload,
  callback,
});

export const createSubscriptionSuccess = data => ({
  type: types.CREATE_SUBSCRIPTION_SUCCESS,
  data,
});

export const createSubscriptionFailure = error => ({
  type: types.CREATE_SUBSCRIPTION_FAILURE,
  error,
});

export const subscriptionUpdateCreditCardRequest = (payload,callback) => ({
  type: types.SUBSCRIPTION_UPDATE_CREDIT_CARD_REQUEST,
  payload,
  callback,
});

export const subscriptionUpdateCreditCardSuccess = data => ({
  type: types.SUBSCRIPTION_UPDATE_CREDIT_CARD_SUCCESS,
  data,
});

export const subscriptionUpdateCreditCardFailure = error => ({
  type: types.SUBSCRIPTION_UPDATE_CREDIT_CARD_FAILURE,
  error,
});

export const subscriptionCalculateRequest = (quantity, interval, current = false) => ({
  type: types.SUBSCRIPTION_CALCULATE_REQUEST,
  interval,
  quantity,
  current,
});

export const subscriptionCalculateSuccess = data => ({
  type: types.SUBSCRIPTION_CALCULATE_SUCCESS,
  data,
});

export const subscriptionCalculateFailure = error => ({
  type: types.SUBSCRIPTION_CALCULATE_FAILURE,
  error,
});

export const subscriptionUpdateRequest = (payload, resolve) => ({
  type: types.SUBSCRIPTION_UPDATE_REQUEST,
  payload,
  resolve,
});

export const subscriptionAddonsRequest = (payload, resolve) => ({
  type: types.SUBSCRIPTION_ADDONS_REQUEST,
  payload,
  resolve,
});

export const subscriptionMigrateRequest = (payload, resolve) => ({
  type: types.SUBSCRIPTION_MIGRATE_REQUEST,
  payload,
  resolve,
});

export const subscriptionUpdateSuccess = data => ({
  type: types.SUBSCRIPTION_UPDATE_SUCCESS,
  data,
});

export const subscriptionUpdateFailure = error => ({
  type: types.SUBSCRIPTION_UPDATE_FAILURE,
  error,
});

export const subscriptionUpdateBillingInfoRequest = (payload, form, resolve) => ({
  type: types.SUBSCRIPTION_UPDATE_BILLING_INFO_REQUEST,
  payload,
  form,
  resolve,
});

export const subscriptionUpdateBillingInfoSuccess = data => ({
  type: types.SUBSCRIPTION_UPDATE_BILLING_INFO_SUCCESS,
  data,
});

export const subscriptionUpdateBillingInfoFailure = error => ({
  type: types.SUBSCRIPTION_UPDATE_BILLING_INFO_FAILURE,
  error,
});

export const subscriptionCancelRequest = resolve => ({
  type: types.SUBSCRIPTION_CANCEL_REQUEST,
  resolve,
});

export const subscriptionCancelSuccess = data => ({
  type: types.SUBSCRIPTION_CANCEL_SUCCESS,
  data,
});

export const subscriptionCancelFailure = error => ({
  type: types.SUBSCRIPTION_CANCEL_FAILURE,
  error,
});

export const subscriptionReactivateRequest = callback => ({
  type: types.SUBSCRIPTION_REACTIVATE_REQUEST,
  callback,
});

export const subscriptionReactivateSuccess = data => ({
  type: types.SUBSCRIPTION_REACTIVATE_SUCCESS,
  data,
});

export const subscriptionReactivateFailure = error => ({
  type: types.SUBSCRIPTION_REACTIVATE_FAILURE,
  error,
});

export const invitationAcceptRequest = resolve => ({
  type: types.INVITATION_ACCEPT_REQUEST,
  resolve,
});

export const invitationAcceptSuccess = () => ({
  type: types.INVITATION_ACCEPT_SUCCESS,
});

export const invitationAcceptFailure = error => ({
  type: types.INVITATION_ACCEPT_FAILURE,
  error,
});

export const invitationDeclineRequest = resolve => ({
  type: types.INVITATION_DECLINE_REQUEST,
  resolve,
});

export const invitationDeclineSuccess = () => ({
  type: types.INVITATION_DECLINE_SUCCESS,
});

export const invitationDeclineFailure = error => ({
  type: types.INVITATION_DECLINE_FAILURE,
  error,
});

export const requestPaymentIntent = subscriptionId => ({
  type: types.SUBSCRIPTION_REQUEST_SETUP_PAYMENT_INTENT
})

export const setupPaymentIntent = data => ({
  type: types.SUBSCRIPTION_SETUP_PAYMENT_INTENT,
  data
})

export const getPaymentInfoRequest = subscriptionId => ({
  type: types.SUBSCRIPTION_GET_PAYMENT_INFO,
  subscriptionId
})

export const setupPaymentInfo = data => ({
  type: types.SUBSCRIPTION_SETUP_PAYMENT_INFO,
  data
})