import React from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import { merge } from 'ramda';

import { normalizeQuery } from './utils';
import Filters from './Filters';

class AdvancedOptions extends React.Component {
  state = {
    query: {},
  };

  setQuery = (data, queryScope = null, updateRoute = false) => {
    if (this.props.setQuery) {
      // Call parent's setQuery when it is availabe
      this.props.setQuery(data, queryScope, updateRoute);
      return;
    }

    this.setState(
      (currentState) => {
        const { query } = currentState;

        const newQuery = merge(query, data);

        Object.keys(newQuery).forEach((key) => {
          if (!data[key]) {
            delete newQuery[key];
          }
        });

        return { query: newQuery };
      },
      () => {
        if (updateRoute) {
          this.updateRouterQuery();
        }
      },
    );
  };

  updateRouterQuery = (event) => {
    if (this.props.updateRouterQuery) {
      // Call parent's updateRouterQuery when it is availabe
      this.props.updateRouterQuery(event);
      return;
    }

    const { query } = this.state;
    const { router } = this.context;

    if (event) {
      event.preventDefault();
    }

    router.push({
      pathname: router.location.pathname,
      search: `?${qs.stringify(normalizeQuery(query))}`,
    });
  };

  render() {
    const {
      currentQuery,
      endDate,
      queryScope,
      showDates,
      showFilter,
      startDate,
      toggleAdvancedOptions,
    } = this.props;

    return (
      <Filters
        currentQuery={currentQuery}
        endDate={endDate}
        queryScope={queryScope}
        setQuery={this.setQuery}
        show={showFilter}
        showDates={showDates}
        startDate={startDate}
        toggleAdvancedOptions={toggleAdvancedOptions}
      />
    );
  }
}

AdvancedOptions.contextTypes = {
  router: PropTypes.object,
};

AdvancedOptions.propTypes = {
  toggleAdvancedOptions: PropTypes.func.isRequired,
  currentQuery: PropTypes.shape({}),
  endDate: PropTypes.shape({}),
  queryScope: PropTypes.string,
  setQuery: PropTypes.func,
  showDates: PropTypes.bool,
  startDate: PropTypes.shape({}),
  updateRouterQuery: PropTypes.func,
};

AdvancedOptions.defaultProps = {
  currentQuery: {},
  endDate: null,
  queryScope: null,
  setQuery: null,
  showDates: false,
  startDate: null,
  updateRouterQuery: null,
};

export default AdvancedOptions;
