import drawLineOnPoints from './drawLineOnPoints';

export default function generateChartPlugins(theme) {
  return [
    {
      afterDatasetsDraw: chart => {
        drawLineOnPoints(chart, theme.colors.dark4);
      },
    },
  ];
}
