/* eslint react/forbid-prop-types: "off" */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { connect } from 'react-redux';

class PaymentLink extends Component {
  handleClick = (event) => {
    event.preventDefault();

    const { router } = this.context;
    const {
      onClick,
      planId,
      returnTo,
      user,
    } = this.props;

    const query = {};
    if (user.attributes.plan_id === 'free') {
      query.plan = planId;
    }

    onClick(event);

    router.push({
      pathname: '/manage/plan',
      query,
      state: {
        modal: true,
        returnTo: returnTo || router.location,
        wrapperStyle: { padding: '40px' },
      },
    });
  };

  render() {
    const {
      children,
      className,
      style,
      to,
    } = this.props;
    const WrappedComponent = this.props.component;

    return (
      <WrappedComponent
        className={className}
        onClick={this.handleClick}
        style={{ cursor: 'pointer', ...style }}
        to={to}
      >
        {children}
      </WrappedComponent>
    );
  }
}

PaymentLink.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
  user: PropTypes.shape({ attributes: PropTypes.shape({}) }).isRequired,
  className: PropTypes.string,
  component: PropTypes.any,
  onClick: PropTypes.func,
  planId: PropTypes.string,
  returnTo: PropTypes.string,
  style: PropTypes.shape({}),
  to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

PaymentLink.defaultProps = {
  className: '',
  component: Link,
  onClick: () => {},
  planId: 'fyWet',
  returnTo: null,
  style: {},
  to: '#',
};

PaymentLink.contextTypes = {
  router: PropTypes.object,
};

const mapStateToProps = state => ({
  user: state.user.data.reduce((m, u) => u, {}),
});

export default connect(mapStateToProps)(PaymentLink);
