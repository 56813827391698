import { assocPath } from 'ramda';

import * as types from './types';

/**
 * State structure is divided per date, like the following
 * {
 *   groups: {
 *     [groupId | 'premium']:
 *     [startDate]: {
 *       data: object,
 *       error: string,
 *       loading: boolean,
 *       modal: {
 *         [date]: {
 *           data: object,
 *           error: string,
 *           loading: boolean,
 *         },
 *       },
 *     },
 *   },
 * }
 */
const INITIAL_STATE = {
  brands: {},
  groups: {},
};

const setData = (state, modelType, { modelId, startDate, payload }) =>
  assocPath(
    [modelType, `${modelId || 'premium'}`, startDate],
    { data: payload, loading: false, modal: {} },
    state,
  );

const setError = (state, modelType, { modelId, startDate, error }) =>
  assocPath([modelType, `${modelId || 'premium'}`, startDate], { error, loading: false }, state);

const setLoading = (state, modelType, loading, { modelId, startDate }) =>
  assocPath([modelType, `${modelId || 'premium'}`, startDate, 'loading'], loading, state);

const toggleLoading = (state, modelType, { modelId, startDate }) =>
  assocPath(
    [modelType, `${modelId || 'premium'}`, startDate, 'loading'],
    !state[modelType][modelId][startDate].loading,
    state,
  );

const setModalData = (state, modelType, { date, firstDayOfMonth, modelId, payload }) =>
  assocPath(
    [modelType, `${modelId || 'premium'}`, firstDayOfMonth, 'modal', date],
    { data: payload, loading: false },
    state,
  );

const setModalError = (state, modelType, { date, firstDayOfMonth, modelId, error }) =>
  assocPath(
    [modelType, `${modelId || 'premium'}`, firstDayOfMonth, 'modal', date],
    { error, loading: false },
    state,
  );

const setModalLoading = (state, modelType, loading, { date, firstDayOfMonth, modelId }) =>
  assocPath(
    [modelType, `${modelId || 'premium'}`, firstDayOfMonth, 'modal', date, 'loading'],
    loading,
    state,
  );

const toggleModalLoading = (state, modelType, { date, firstDayOfMonth, modelId }) =>
  assocPath(
    [modelType, `${modelId || 'premium'}`, firstDayOfMonth, 'modal', date, 'loading'],
    !state[modelType][modelId][firstDayOfMonth].modal[date].loading,
    state,
  );

const reducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case types.FETCH_BRAND_DATA_REQUEST: {
      return setLoading(state, 'brands', true, action);
    }
    case types.FETCH_BRAND_DATA_FAILURE: {
      return setError(state, 'brands', action);
    }
    case types.FETCH_BRAND_DATA_SUCCESS: {
      return setData(state, 'brands', action);
    }
    case types.TOGGLE_BRAND_LOADING: {
      return toggleLoading(state, 'brands', action);
    }
    case types.FETCH_BRAND_MODAL_DATA_REQUEST: {
      return setModalLoading(state, 'brands', true, action);
    }
    case types.FETCH_BRAND_MODAL_DATA_FAILURE: {
      return setModalError(state, 'brands', action);
    }
    case types.FETCH_BRAND_MODAL_DATA_SUCCESS: {
      return setModalData(state, 'brands', action);
    }
    case types.TOGGLE_BRAND_MODAL_LOADING: {
      return toggleModalLoading(state, 'brands', action);
    }
    case types.FETCH_GROUP_DATA_REQUEST: {
      return setLoading(state, 'groups', true, action);
    }
    case types.FETCH_GROUP_DATA_FAILURE: {
      return setError(state, 'groups', action);
    }
    case types.FETCH_GROUP_DATA_SUCCESS: {
      return setData(state, 'groups', action);
    }
    case types.TOGGLE_GROUP_LOADING: {
      return toggleLoading(state, 'groups', action);
    }
    case types.FETCH_GROUP_MODAL_DATA_REQUEST: {
      return setModalLoading(state, 'groups', true, action);
    }
    case types.FETCH_GROUP_MODAL_DATA_FAILURE: {
      return setModalError(state, 'groups', action);
    }
    case types.FETCH_GROUP_MODAL_DATA_SUCCESS: {
      return setModalData(state, 'groups', action);
    }
    case types.TOGGLE_GROUP_MODAL_LOADING: {
      return toggleModalLoading(state, 'groups', action);
    }
    default: {
      return state;
    }
  }
};

export default reducer;
