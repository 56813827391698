import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { connect } from 'react-redux';

import CheckboxDefaultIcon from '../../mc-ui/icons/CheckboxDefaultIcon';
import CheckboxSelectedIcon from '../../mc-ui/icons/CheckboxSelectedIcon';
import GroupLogo from '../../ui/GroupLogo';

import { Box, Flex, Heading, Spinner, Text, theme } from '../../mc-ui';
import { customGroupsActions } from '../../../modules/customGroups';

class GroupSelector extends React.Component {
  state = {
    toggling: false,
  };

  selectGroup = async () => {
    const { addBrandsToGroup } = this.props;

    this.setState({ toggling: true });

    await new Promise((resolve, reject) => {
      addBrandsToGroup(resolve, reject);
    });

    this.setState({ toggling: false });
  };

  deselectGroup = async () => {
    const { removeBrandsToGroup } = this.props;

    this.setState({ toggling: true });

    await new Promise((resolve, reject) => {
      removeBrandsToGroup(resolve, reject);
    });

    this.setState({ toggling: false });
  };

  render() {
    const { brands, group } = this.props;
    const { toggling } = this.state;
    const brand = group.relationships.companies.data[0];
    const brandIds = brands.map(b => b.id);
    const isSelected = group.relationships.companies.data.some(c => brandIds.includes(c.id));

    return (
      <Flex
        cursor="pointer"
        marginBottom="16px"
        data-group-id={group.id}
        onClick={isSelected ? this.deselectGroup : this.selectGroup}
      >
        {brand ? (
          <GroupLogo group={group} />
        ) : (
          <Box
            backgroundColor="dark5"
            border={`1px solid ${theme.colors.dark01}`}
            borderRadius="50%"
            height="48px"
            width="48px"
          />
        )}

        <Box flex="1" margin="0 16px" width="unset">
          <Heading as="h4" fontSize="14px" lineHeight="1.25em">
            {group.attributes.name}
          </Heading>

          <Text fontSize="12px">
            {group.attributes.approximate_companies}{' '}
            {pluralize('brand', group.attributes.approximate_companies)}
          </Text>
        </Box>

        {toggling ? (
          <Box height="24px" marginRight="5px" width="24px">
            <Spinner size="24px" />
          </Box>
        ) : (
          <Box height="24px" width="24px">
            {isSelected ? <CheckboxSelectedIcon /> : <CheckboxDefaultIcon />}
          </Box>
        )}
      </Flex>
    );
  }
}

GroupSelector.propTypes = {
  addBrandsToGroup: PropTypes.func.isRequired,
  brands: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  group: PropTypes.shape({}).isRequired,
  removeBrandsToGroup: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch, { brands, group }) => ({
  addBrandsToGroup: (resolve, reject) =>
    dispatch(customGroupsActions.addBrandsToGroupRequest(group.id, brands, resolve, reject)),
  removeBrandsToGroup: (resolve, reject) =>
    dispatch(customGroupsActions.removeBrandsToGroupRequest(group.id, brands, resolve, reject)),
});

export default connect(
  null,
  mapDispatchToProps,
)(GroupSelector);
