import PropTypes from 'prop-types';
import React from 'react';
import * as S from './styles';

export default function MessageImage({ guid, image }) {
  const url = `//assets.mailcharts.com/text-messages/${guid}/images/`;

  return (
    <S.Picture>
      <source srcSet={`${url}${image.webp}`} type="image/webp" />
      {image.gif ? (
        <source srcSet={`${url}${image.gif}`} type="image/gif" />
      ) : (
        <source srcSet={`${url}${image.jpg}`} type="image/jpeg" />
      )}
      <img src={`${url}${image.gif || image.jpg}`} alt="" />
    </S.Picture>
  );
}

MessageImage.propTypes = {
  guid: PropTypes.string.isRequired,
  image: PropTypes.shape({}).isRequired,
};
