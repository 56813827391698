/* eslint-disable import/prefer-default-export */
import { put, select } from 'redux-saga/effects';
import * as actions from './actions';
import generateComparisonIntervalsOptions from './utils/generateComparisonIntervalsOptions';

// ********** SELECTORS **********

const getIntervals = state => state.user.meta.intervals;
const getInterval = (state, interval) => state.user.meta.intervals.find(i => i.value === interval);
const getDefaultInterval = state => getIntervals(state).find(i => i.default);
const getIntervalByDate = (state, endDate, startDate) =>
  getIntervals(state).find(i => i.end_date === endDate && i.start_date === startDate);
const getIntervalsOptions = state =>
  generateComparisonIntervalsOptions(getIntervals(state), {
    endDate: state.newReports.dates.endDate,
    startDate: state.newReports.dates.startDate,
  });
const getComparisonIntervals = state => state.newReports.dates.compare.intervalsOptions;
const getComparisonInterval = (state, interval) =>
  getComparisonIntervals(state).find(i => i.value === interval);
const getDefaultComparisonInterval = state => getComparisonIntervals(state).find(i => i.default);
const getComparisonIntervalByDate = (state, endDate, startDate) =>
  getComparisonIntervals(state).find(i => i.end_date === endDate && i.start_date === startDate);

// ********** DATES **********

export function* initializeDates({ query }) {
  try {
    let interval;
    let intervalLabel;
    let endDate;
    let startDate;

    if (query.end_date && query.start_date) {
      const intervalModel = yield select(getIntervalByDate, query.end_date, query.start_date);
      interval = intervalModel ? intervalModel.value : null;
      intervalLabel = intervalModel ? intervalModel.label : 'Custom';
      endDate = intervalModel ? intervalModel.end_date : query.end_date;
      startDate = intervalModel ? intervalModel.start_date : query.start_date;
    } else {
      const intervalModel = yield select(getDefaultInterval);
      interval = intervalModel.value;
      intervalLabel = intervalModel.label;
      endDate = intervalModel.end_date;
      startDate = intervalModel.start_date;
    }

    yield put(actions.saveDates(endDate, interval, intervalLabel, startDate));
    yield put(actions.generateComparisonIntervals());
    yield put(actions.initializeComparisonDates({}));
  } catch (error) {
    console.log('initializeDates › error', error);
  }
}

export function* updateInterval({ interval }) {
  try {
    const intervalModel = yield select(getInterval, interval);

    const intervalLabel = intervalModel.label;
    const endDate = intervalModel.end_date;
    const startDate = intervalModel.start_date;

    yield put(actions.saveDates(endDate, interval, intervalLabel, startDate));
    yield put(actions.generateComparisonIntervals());
    yield put(actions.initializeComparisonDates({}));
  } catch (error) {
    console.log('updateInterval › error', error);
  }
}

export function* changeDates({ endDate, startDate }) {
  try {
    const intervalModel = yield select(getIntervalByDate, endDate, startDate);

    const interval = intervalModel ? intervalModel.value : null;
    const intervalLabel = intervalModel ? intervalModel.label : 'Custom';

    yield put(actions.saveDates(endDate, interval, intervalLabel, startDate));
    yield put(actions.generateComparisonIntervals());
    yield put(actions.initializeComparisonDates({}));
  } catch (error) {
    console.log('changeDates › error', error);
  }
}

// ********** COMPARE DATES **********

export function* generateComparisonIntervals() {
  try {
    const intervals = yield select(getIntervalsOptions);
    yield put(actions.updateComparisonIntervals(intervals));
  } catch (error) {
    console.log('generateComparisonIntervals › error', error);
  }
}

export function* initializeComparisonDates({ query }) {
  try {
    const compareQuery = query.compare || {};
    let interval;
    let intervalLabel;
    let endDate;
    let startDate;

    if (compareQuery.end_date && compareQuery.start_date) {
      const intervalModel = yield select(
        getComparisonIntervalByDate,
        compareQuery.end_date,
        compareQuery.start_date,
      );
      interval = intervalModel ? intervalModel.value : null;
      intervalLabel = intervalModel ? intervalModel.label : 'Custom';
      endDate = intervalModel ? intervalModel.end_date : compareQuery.end_date;
      startDate = intervalModel ? intervalModel.start_date : compareQuery.start_date;
    } else {
      const intervalModel = yield select(getDefaultComparisonInterval);
      interval = intervalModel.value;
      intervalLabel = intervalModel.label;
      endDate = intervalModel.end_date;
      startDate = intervalModel.start_date;
    }

    yield put(actions.saveComparisonDates(endDate, interval, intervalLabel, startDate));
  } catch (error) {
    console.log('initializeComparisonDates › error', error);
  }
}

export function* updateComparisonInterval({ interval }) {
  try {
    const intervalModel = yield select(getComparisonInterval, interval);

    const intervalLabel = intervalModel.label;
    const endDate = intervalModel.end_date;
    const startDate = intervalModel.start_date;

    yield put(actions.saveComparisonDates(endDate, interval, intervalLabel, startDate));
  } catch (error) {
    console.log('updateComparisonInterval › error', error);
  }
}

export function* changeComparisonDates({ endDate, startDate }) {
  try {
    const intervalModel = yield select(getComparisonIntervalByDate, endDate, startDate);

    const interval = intervalModel ? intervalModel.value : null;
    const intervalLabel = intervalModel ? intervalModel.label : 'Custom';

    yield put(actions.saveComparisonDates(endDate, interval, intervalLabel, startDate));
  } catch (error) {
    console.log('changeComparisonDates › error', error);
  }
}
