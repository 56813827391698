import React from 'react';
import moment from 'moment/moment';
import EmailThumbnail from './EmailThumbnail';
import PropTypes from 'prop-types';
import { palette, getColorForEmail } from '../lib/color';

const styles = {

  card: {
    boxSizing: 'border-box',
    overflow: 'hidden',
    borderRadius: 3,
    border: `2px solid ${palette.grayA}`,
  },
  cardHeader: {
    position: 'relative',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: 80,
    padding: 20,
    paddingLeft: 74,
    background: palette.grayA,
  },
  icon: {
    position: 'absolute',
    left: 20,
    top: 28,
    width: 24,
    height: 24,
  },
  thumbnailWrapper: {
    // Trim the inner email image have a cleaner edge
    margin: -2,
  },
  text: {
    margin: 0,
    color: '#FFFFFF',
    fontSize: `${12 / 16}em`,
  },
  textSecondary: {
    margin: 0,
    color: '#FFFFFF',
    fontSize: `${8 / 16}em`,
  },
};

const JourneyTimelineEmail = ({ email, width }) => {
  const { classifications = [] } = email.attributes
  const color = classifications.length ?
    `${getColorForEmail(classifications[0].type)}`
    : palette.grayA

  return (
    <div style={{ ...styles.card, borderColor: color }}>
      <div style={{ ...styles.cardHeader, background: color, width: (width - 4) }}>
        <img alt="" style={styles.icon} src="/journey-email.svg" />
        <p style={styles.text}>{email.attributes.subject}</p>
        <p style={styles.textSecondary}>
          {moment(email.attributes.date).format('MMM. DD, \'YY h:mm a')}
        </p>
      </div>
      <div style={styles.thumbnailWrapper}>
        <EmailThumbnail
          hideDetails
          height={400}
          width={width}
          padding={0}
          email={email}
        />
      </div>
    </div>
  );
};

JourneyTimelineEmail.propTypes = {
  email: PropTypes.shape({
    attributes: PropTypes.shape({
      date: PropTypes.string,
      subject: PropTypes.string,
    }),
  }).isRequired,
  width: PropTypes.number.isRequired,
};

export default JourneyTimelineEmail;
