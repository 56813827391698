import styled from "@emotion/styled";

const Bar = styled("div")(({ size, theme, blue }) => ({
  background: blue
    ? theme.colors.blue
    : `repeating-linear-gradient(-45deg, ${theme.colors.dark01}, ${
        theme.colors.dark01
      } 1px, #DFE1E6 1px, #DFE1E6 4px)`,
  borderRadius: '2px',
  height: '40px',
  width: `${size}%`,
}));

export default Bar;
