import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';

import { teamsSelectors } from '../../modules/teams';
import { Flex, Text, theme } from '../mc-ui';
import WarningCircleIcon from '../mc-ui/icons/WarningCircleIcon';

function ProPlanWillBeCanceledNotice({ isCancelingSubscription, team }) {
  if (!team || !isCancelingSubscription) return null;

  return (
    <Flex
      alignItems="center"
      backgroundColor="white"
      border="1px solid"
      borderColor="dark5"
      borderRadius="4px"
      justifyContent="space-between"
      height="32px"
      padding="0 8px"
      width="195px"
    >
      <WarningCircleIcon color={theme.colors.yellowDark} />

      <Text color="dark08" fontSize="12px" fontWeight="500" lineHeight="1em">
        Canceling on{' '}
        {moment(team.attributes.current_period_end_at).format('MMM DD, YYYY')}
      </Text>
    </Flex>
  );
}

ProPlanWillBeCanceledNotice.defaultProps = {
  team: null,
};

ProPlanWillBeCanceledNotice.propTypes = {
  isCancelingSubscription: PropTypes.bool.isRequired,
  team: PropTypes.shape({
    attributes: PropTypes.shape({
      current_period_end_at: PropTypes.string,
    }),
  }),
};

const mapStateToProps = state => ({
  isCancelingSubscription: teamsSelectors.isCancelingSubscriptionSelector(state),
  team: teamsSelectors.teamSelector(state),
});

export default connect(mapStateToProps)(ProPlanWillBeCanceledNotice);
