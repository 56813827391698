import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from '../ui/Tooltip';

import ButtonWrapper from './ui/ButtonWrapper';
import OptionsIcon from './ui/OptionsIcon';

const Button = ({ css, ...props }) => {
  const wrapperStyle = {
    alignItems: 'center',
    display: 'inline-flex',
    margin: props.comparisonMode ? '8px 8px 0 0' : '0 8px 0 16px',
    ...css,
  };

  return (
    <div style={wrapperStyle}>
      <ButtonWrapper {...props}>
        <OptionsIcon />
        Advanced options
      </ButtonWrapper>
      <Tooltip theme="light">
        <h5>Using advanced options</h5>
        <p style={{ fontSize: `${14 / 16}em` }}>
          Watch a two-minute overview on how to use advanced options to find exactly the emails
          you’re looking for.
        </p>
        <p style={{ fontSize: `${14 / 16}em` }}>
          <a
            className="pure-button"
            href="https://knowledge.validity.com/hc/en-us/articles/9515462305563-Getting-around-the-MailCharts-app"
            rel="noopener noreferrer"
            target="_blank"
          >
            Show me
          </a>
        </p>
      </Tooltip>
    </div>
  );
};

Button.propTypes = {
  comparisonMode: PropTypes.bool,
  css: PropTypes.shape({}),
};

Button.defaultProps = {
  comparisonMode: false,
  css: {},
};

export default Button;
