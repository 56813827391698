import React from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router';

import hoc from '../hoc';

import List from '../ui/List';
import ListButton from '../ui/ListButton';
import ListImage from '../ui/ListImage';
import ListName from '../ui/ListName';
import EmailAdded from '../ui/EmailAdded';

class ListRow extends React.Component {
  state = {
    added: false,
    emailId: [],
    list: null,
    listId: null,
  }
  handleRemove = () => {
    const { dispatch, } = this.props;
    const { emailId, listId, } = this.state;
    const type = 'DELETE_EMAILS_FROM_LIST_REQUEST';
    new Promise((resolve, reject) => {
      dispatch({ type, listId, emailId, resolve, reject, });
    }).then(list => {
      this.setState({ list, added: false })
    });
  }
  handleAdd = () => {
    const { dispatch, } = this.props;
    const { emailId, listId, } = this.state;
    const type = 'ADD_EMAILS_TO_LIST_REQUEST';
    new Promise((resolve, reject) => {
      dispatch({ type, listId, emailId, resolve, reject, });
    }).then(list => {
      this.setState({ list, added: true })
    })
  }
  componentDidMount() {
    let { list, emailId, added = false } = this.props
    added = added || list.attributes.new == true
    const listId = list.id
    this.setState({ list, added, emailId, listId })
  }
  render() {
    const { emailId = [] } = this.props
    const { added, list } = this.state
    if (!list) return null
    const { approximate_emails, name, } = list.attributes
    const emails = approximate_emails == 1 ? 'email' : 'emails'
    return (
      <List>
        <Link
          to={{ pathname: `/lists/${list.id}`, state: { modal: false }, }}
          onClick={() => { this.props.dispatch({ type: 'HIDE_MODAL' }) }}
        >
          <ListImage path={`//cache.mailcharts.com/logos/v2/lists/${list.id % 126}.png`} />
        </Link>
        <ListName>
          {name}
          <small>
            {approximate_emails} {emails}
          </small>
        </ListName>
        {added ? (
          <EmailAdded
            multiple={emailId.length > 1}
            added={added}
            onRemoveClick={this.handleRemove}
          />
        ) : (
          <ListButton onClick={this.handleAdd} />
        )}
      </List>
    )
  }
}

export default connect()(ListRow)
