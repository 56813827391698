import React from 'react';
import { call, put, select } from 'redux-saga/effects';

import { Link } from '../../components/mc-ui';

import { flashMessageActions } from '../flashMessage';

import * as actions from './actions';
import * as api from './api';
import * as selectors from './selectors';

// eslint-disable-next-line react/prop-types
function Message({ children }) {
  return <span>{children}</span>;
}

export function* fetchTeamFilters({ params }) {
  try {
    const team = yield select(selectors.teamSelector);
    if (!team) {
      yield put(actions.fetchTeamFiltersSuccess([]));
      return;
    }

    const response = yield call(api.fetchTeamFilters, team.id, params);
    yield put(actions.fetchTeamFiltersSuccess(response.data));
  } catch (error) {
    console.log('customFilters/sagas › fetchTeamFilters › error', error);
    yield put(actions.fetchTeamFiltersFailure(error));
  }
}

export function* fetchUserFilters({ params }) {
  try {
    const response = yield call(api.fetchUserFilters, params);
    yield put(actions.fetchUserFiltersSuccess(response.data));
  } catch (error) {
    console.log('customFilters/sagas › fetchUserFilters › error', error);
    yield put(actions.fetchUserFiltersFailure(error));
  }
}

export function* createTeamFilter({ values, resolve }) {
  try {
    const team = yield select(selectors.teamSelector);
    if (!team) {
      yield put(actions.createTeamFilterSuccess([]));
      return;
    }

    const response = yield call(api.createTeamFilter, team.id, values);
    const filters = response.data;
    yield put(actions.createTeamFilterSuccess(filters));

    yield put(
      flashMessageActions.showSuccess({
        body: (
          <Message>
            Filter has been saved to your{' '}
            <Link to="/team-collections/saved-filters">collections</Link>.
          </Message>
        ),
      }),
    );

    yield call(window.analytics.track, 'Filter saved (team)', { saved_search_id: filters.id });

    if (resolve) {
      resolve();
    }
  } catch (error) {
    console.log('customGroups/sagas › createTeamFilter › error', error);
    yield put(actions.createTeamFilterFailure(error));
  }
}

export function* createUserFilter({ values, resolve }) {
  try {
    const response = yield call(api.createUserFilter, values);
    const filters = response.data;
    yield put(actions.createUserFilterSuccess(filters));

    yield put(
      flashMessageActions.showSuccess({
        body: (
          <Message>
            Filter has been saved to your{' '}
            <Link to="/my-collections/saved-filters">collections</Link>.
          </Message>
        ),
      }),
    );

    yield call(window.analytics.track, 'Filter saved (personal)', { saved_search_id: filters.id });

    if (resolve) {
      resolve();
    }
  } catch (error) {
    console.log('customGroups/sagas › createUserFilter › error', error);
    yield put(actions.createUserFilterFailure(error));
  }
}

export function* updateTeamFilter({ id, values, resolve }) {
  try {
    const team = yield select(selectors.teamSelector);
    if (!team) {
      yield put(actions.updateTeamFilterSuccess([]));
      return;
    }

    const response = yield call(api.updateTeamFilter, id, team.id, values);
    const filters = response.data;
    yield put(actions.updateTeamFilterSuccess(filters));

    yield call(window.analytics.track, 'Filter updated (team)', { saved_search_id: id });

    yield put(
      flashMessageActions.showSuccess({
        body: (
          <Message>
            Filter has been saved to your{' '}
            <Link to="/team-collections/saved-filters">collections</Link>.
          </Message>
        ),
      }),
    );

    if (resolve) {
      resolve();
    }
  } catch (error) {
    console.log('customGroups/sagas › updateTeamFilter › error', error);
    yield put(actions.updateTeamFilterFailure(error));
  }
}

export function* updateUserFilter({ id, values, resolve }) {
  try {
    const response = yield call(api.updateUserFilter, id, values);
    const filters = response.data;
    yield put(actions.updateUserFilterSuccess(filters));

    yield put(
      flashMessageActions.showSuccess({
        body: (
          <Message>
            Filter has been saved to your{' '}
            <Link to="/my-collections/saved-filters">collections</Link>.
          </Message>
        ),
      }),
    );

    yield call(window.analytics.track, 'Filter updated (personal)', { saved_search_id: id });

    if (resolve) {
      resolve();
    }
  } catch (error) {
    console.log('customGroups/sagas › updateUserFilter › error', error);
    yield put(actions.updateUserFilterFailure(error));
  }
}

export function* deleteTeamFilter({ id }) {
  try {
    const team = yield select(selectors.teamSelector);
    if (!team) {
      yield put(actions.deleteTeamFilterSuccess([]));
      return;
    }

    const response = yield call(api.deleteTeamFilter, id, team.id);
    const filters = response.data;
    yield put(actions.deleteTeamFilterSuccess(filters));

    yield put(
      flashMessageActions.showSuccess({
        body: (
          <Message>
            Filter has been removed from your{' '}
            <Link to="/team-collections/saved-filters">collections</Link>.
          </Message>
        ),
      }),
    );

    yield call(window.analytics.track, 'Filter removed (team)', { saved_search_id: id });
  } catch (error) {
    console.log('customGroups/sagas › deleteTeamFilter › error', error);
    yield put(actions.deleteTeamFilterFailure(error));
  }
}

export function* deleteUserFilter({ id }) {
  try {
    const response = yield call(api.deleteUserFilter, id);
    const filters = response.data;
    yield put(actions.deleteUserFilterSuccess(filters));

    yield put(
      flashMessageActions.showSuccess({
        body: (
          <Message>
            Filter has been removed from your{' '}
            <Link to="/my-collections/saved-filters">collections</Link>.
          </Message>
        ),
      }),
    );

    yield call(window.analytics.track, 'Filter removed (personal)', { saved_search_id: id });
  } catch (error) {
    console.log('customGroups/sagas › deleteUserFilter › error', error);
    yield put(actions.deleteUserFilterFailure(error));
  }
}
