import * as React from 'react';
import PropTypes from 'prop-types';

function ShareIcon({ fillOpacity, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx={16.5}
        cy={17.5}
        r={2.5}
        stroke="currentColor"
        strokeWidth={1.4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx={16.5}
        cy={6.5}
        r={2.5}
        stroke="currentColor"
        strokeWidth={1.4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx={6.5}
        cy={12.5}
        r={2.5}
        stroke="currentColor"
        strokeWidth={1.4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8.5 11.5l6-3.5M8.5 13.5l5.5 3" stroke="currentColor" strokeWidth={1.4} />
    </svg>
  );
}

ShareIcon.propTypes = {
  fillOpacity: PropTypes.number,
};

ShareIcon.defaultProps = {
  fillOpacity: 1.0,
};

export default ShareIcon;
