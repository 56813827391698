import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { benchmarksSelectors as selectors } from '../../../modules/newReports/benchmarks';

import { Flex } from '../../mc-ui';
import PromoIcon from '../../mc-ui/icons/PromoIcon';

import ReportSection from '../ReportSection';
import Title from '../Title';

import BarsChart from './BarsChart';
import SectionInformation from './SectionInformation';

const PromotionRate = ({ data, endDate, loading, router, startDate, ...props }) => (
  <Flex>
    <ReportSection css={{ minHeight: '285px' }}>
      <ReportSection.Header>
        <Title>Average Promotion Rate</Title>
      </ReportSection.Header>
      <ReportSection.Body>
        <Flex justifyContent="space-between">
          <SectionInformation
            content="The MailCharts promotion rate is determined by the number of emails containing a promotion. Compare this group’s promotional frequency to other industries and brands."
            icon={PromoIcon}
            title="Promotions Report"
            linkTo={{
              pathname: router.location.pathname.replace('benchmarks', 'promotions'),
              query: { end_date: endDate, start_date: startDate },
            }}
          />

          <BarsChart
            adjustment={r => Math.round(r)}
            append=" %"
            data={data.promotional}
            loading={loading}
            {...props}
          />
        </Flex>
      </ReportSection.Body>
    </ReportSection>
  </Flex>
);

PromotionRate.defaultProps = {
  endDate: null,
  startDate: null,
};

PromotionRate.propTypes = {
  data: PropTypes.shape({
    promotional: PropTypes.shape({}),
  }).isRequired,
  endDate: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  router: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }).isRequired,
  startDate: PropTypes.string,
};

const mapStateToProps = (state, { model, brandId }) => {
  if (brandId && model.id && model.type === 'groups') {
    return {
      data: selectors.getGroupComparisonData(model.id, brandId)(state),
      loading: selectors.getGroupComparisonLoading(model.id, brandId)(state),
    };
  }

  if (model.id && model.type === 'companies') {
    return {
      data: selectors.getBrandComparisonData(model.id)(state),
      loading: selectors.getBrandComparisonLoading(model.id)(state),
    };
  }

  return {
    data: { promotional: {} },
    loading: true,
  };
};

export default connect(mapStateToProps)(PromotionRate);
