import ReactTooltip from 'react-tooltip'
import styled from "@emotion/styled";

const theme = {
  dark: {
    backgroundColor: '#444',
    color: '#f7f9fc'
  },
  light: {
    backgroundColor: '#fff',
    borderColor: '#d4d9dd',
    color: '#444'
  }
}

const arrow = (type) => ({
  '&.place-top': {
    '&:after': {
      borderTopColor: `${theme[type].backgroundColor} !important`
    }
  },
  '&.place-bottom': {
    '&:after': {
      borderBottomColor: `${theme[type].backgroundColor} !important`
    }
  },
  '&.place-left': {
    '&:after': {
      borderLeftColor: `${theme[type].backgroundColor} !important`
    }
  },
  '&.place-right': {
    '&:after': {
      borderRightColor: `${theme[type].backgroundColor} !important`
    }
  }
})

const border = (type) => {
  if (type === 'dark') {
    return {}
  }

  return {
    border: `1px solid ${theme[type].borderColor}`,
    '&.place-top': {
      '&:before': {
        borderTop: `8px solid ${theme[type].borderColor}`
      }
    },
    '&.place-bottom': {
      '&:before': {
        borderBottom: `8px solid ${theme[type].borderColor}`
      }
    },
    '&.place-left': {
      '&:before': {
        borderLeft: `8px solid ${theme[type].borderColor}`
      }
    },
    '&.place-right': {
      '&:before': {
        borderRight: `8px solid ${theme[type].borderColor}`
      }
    }
  }
}

const visibility = () => ({
  '&:hover': {
    visibility: 'visible !important',
    opacity: '1 !important'
  }
})

const styles = (type) => {
  const themeStyles = {}
  themeStyles[`&.type-${type}`] = {
    backgroundColor: `${theme[type].backgroundColor} !important`,
    boxShadow: '0px 2px 4px 0px rgba(160, 174, 186, .3)',
    color: `${theme[type].color} !important`,
    pointerEvents: 'auto !important',
    ...arrow(type),
    ...border(type),
    ...visibility()
  }

  return {
    boxSizing: 'border-box',
    fontSize: '12px',
    '& h1, & h2, & h3, & h4, & h5, & h6': {
      color: `${theme[type].color} !important`,
      margin: '0 !important',
      '& + p': {
        paddingTop: '8px !important'
      }
    },
    '& p': {
      color: `${theme[type].color} !important`,
      fontSize: '14px',
      lineHeight: '20px',
      margin: '0 !important'
    },
    '& button, & .pure-button': {
      marginTop: '24px',
      '& + button, & + .pure-button': {
        marginLeft: '8px'
      }
    },
    ...themeStyles
  }
}

const stylesByProps = ({ withButton, withHeader, withParagraph, style }) => {
  let customStyle = {}
  const defaultStyle = {
    maxWidth: style.width ? null : '400px'
  }

  if (withButton) {
    customStyle.padding = '24px !important'
  } else if (withHeader) {
    customStyle.padding = '16px !important'
  } else if (withParagraph) {
    customStyle.padding = '8px !important'
  }

  return Object.assign({}, customStyle, defaultStyle, style)
}

export const DarkTooltip = styled(ReactTooltip)(styles('dark'), stylesByProps)
export const LightTooltip = styled(ReactTooltip)(styles('light'), stylesByProps)
