import React from 'react';
import { Link as RRLink } from 'react-router';

import { Button, Flex, Heading, Text } from '../mc-ui';

import CoffeeGlassAndBook from '../ui/illustrations/CoffeeGlassAndBook';

export default function EmptyGroup() {
  return (
    <Flex
      alignItems="center"
      border="1px solid transparent"
      borderColor="dark5"
      borderRadius="4px"
      direction="column"
      height="448px"
      justifyContent="center"
      marginTop="24px"
    >
      <CoffeeGlassAndBook />

      <Text fontWeight="bold" letterSpacing="0.02em" lineHeight="1.25em" margin="24px 0">
        This group is empty
      </Text>

      <Heading
        as="h3"
        fontSize="24px"
        fontWeight="300"
        lineHeight="24px"
        css={{ marginBottom: '32px' }}
      >
        How to find brands to add to this group:
      </Heading>

      <Flex justifyContent="center">
        <Flex alignItems="center" direction="column" marginRight="56px" width="312px">
          <Heading
            as="h4"
            fontSize="18px"
            fontWeight="500"
            lineHeight="24px"
            css={{ marginBottom: '8px' }}
          >
            Brands
          </Heading>

          <Text fontSize="16px" textAlign="center" css={{ marginBottom: '8px' }}>
            Our MailCharts Index includes 2,500+ hand-picked brands representing a wide spectrum of
            industry groups.
          </Text>

          <Button
            as={RRLink}
            to="/brands"
            variant="secondary"
            css={{
              alignItems: 'center',
              display: 'inline-flex',
              justifyContent: 'center',
              height: '32px',
              margin: '0',
              padding: '0 12px',
            }}
          >
            Browse Brands
          </Button>
        </Flex>

        <Flex alignItems="center" direction="column" width="312px">
          <Heading
            as="h4"
            fontSize="18px"
            fontWeight="500"
            lineHeight="24px"
            css={{ marginBottom: '8px' }}
          >
            Industry Groups
          </Heading>

          <Text fontSize="16px" textAlign="center" css={{ marginBottom: '8px' }}>
            We’ve organized brands into 80+ industry groups to help you gain insight into data and
            trends in the world of ecommerce.
          </Text>

          <Button
            as={RRLink}
            to="/groups"
            variant="secondary"
            css={{
              alignItems: 'center',
              display: 'inline-flex',
              justifyContent: 'center',
              height: '32px',
              margin: '0',
              padding: '0 12px',
            }}
          >
            Browse Industries
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
}
