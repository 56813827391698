import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'ramda';

import { userSelectors } from '../../../modules/user';

import ProBadge from '../../ui/ProBadge';
import { Box, theme } from '../../mc-ui';

import * as S from './styles';
import SaveSearchForm from './SaveSearchForm';
import { savedSearchesActions } from '../../../modules/savedSearches';

class SaveSearch extends React.Component {
  state = {
    form: null,
    open: false,
  };

  close = event => {
    if (event) event.preventDefault();
    this.setState(() => ({ form: null, open: false }));
  };

  open = event => {
    if (event) event.preventDefault();
    this.setState(() => ({ open: true }));
  };

  closeForm = event => {
    if (event) event.preventDefault();
    this.setState(() => ({ form: null }));
  };

  openForm = event => {
    if (event) event.preventDefault();
    const { formName } = event.currentTarget.dataset;
    this.setState(() => ({ form: formName }));
  };

  handleSubmit = (values, callback) => {
    const { createTeamFilter, createUserFilter, filters, type } = this.props;
    const { form } = this.state;

    if (form === 'myList') {
      createUserFilter({ ...values, params: filters, type }, () => {
        this.setState(() => ({ form: null }));
        callback();
      });
    } else if (form === 'teamList') {
      createTeamFilter({ ...values, params: filters, type }, () => {
        this.setState(() => ({ form: null }));
        callback();
      });
    }
  };

  render() {
    const { children, css, filters, isUserRestricted } = this.props;
    const { form, open } = this.state;

    if (isEmpty(filters)) return null;

    const left = '40px';
    const top = form === 'myList' ? '16px' : '48px';

    return (
      <Box onMouseLeave={this.close} position="relative" width="unset" css={css}>
        <S.Button onClick={this.open}>{children}</S.Button>
        {open && (
          <S.Dropdown>
            <S.DropdownItem data-form-name="myList" onClick={this.openForm}>
              Add to my list
            </S.DropdownItem>
            <S.DropdownItem
              data-form-name="teamList"
              disabled={isUserRestricted}
              onClick={this.openForm}
            >
              Add to team list {isUserRestricted && <ProBadge small />}
            </S.DropdownItem>
          </S.Dropdown>
        )}

        {form && (
          <Box left={left} position="absolute" top={top} zIndex={theme.zIndices.dropdown + 1}>
            <SaveSearchForm handleClose={this.closeForm} onSubmit={this.handleSubmit} />
          </Box>
        )}
      </Box>
    );
  }
}

SaveSearch.defaultProps = {
  css: {},
};

SaveSearch.propTypes = {
  children: PropTypes.node.isRequired,
  createTeamFilter: PropTypes.func.isRequired,
  createUserFilter: PropTypes.func.isRequired,
  css: PropTypes.shape({}),
  filters: PropTypes.shape({}).isRequired,
  isUserRestricted: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  isUserRestricted: userSelectors.isUserRestrictedSelector(state),
});

const mapDispatchToProps = dispatch => ({
  createTeamFilter: (...args) => dispatch(savedSearchesActions.createTeamFilterRequest(...args)),
  createUserFilter: (...args) => dispatch(savedSearchesActions.createUserFilterRequest(...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SaveSearch);
