import api from '../lib/api';

const SEGMENTS = [50050, 50167, 55782];

export default store => next => (action) => {
  next(action);

  switch (action.type) {
    case 'REQUEST_DEMO_JOURNEYS':
      Promise.all([
        api.get(`/v2/segments/${SEGMENTS[0]}/journey`),
        api.get(`/v2/segments/${SEGMENTS[1]}/journey`),
        api.get(`/v2/segments/${SEGMENTS[2]}/journey`),
      ]).then((responses) => {
        const journeysData = responses.map((response) => {
          const model = response.data.data[0];
          const description = `${model.relationships.company.data[0].attributes.name} - ${model.attributes.description}`;

          return {
            ...response.data,
            data: [{
              ...model,
              attributes: {
                ...model.attributes,
                description,
              },
            }],
          };
        });

        store.dispatch({
          type: 'RECEIVE_DEMO_JOURNEYS',
          data: { data: journeysData },
        });
      });
      break;
    default:
      return null;
  }

  return null;
};
