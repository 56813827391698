import api from '../../lib/api';

export const fetchTeamFilters = async (teamId, params = {}) => {
  const response = await api.get(`/v3/teams/${teamId}/saved-searches`, { params });
  return response;
};

export const fetchUserFilters = async (params = {}) => {
  const response = await api.get('/v3/user/saved-searches', { params });
  return response;
};

export const createTeamFilter = async (teamId, params) => {
  const response = await api.post(`/v3/teams/${teamId}/saved-searches`, params);
  return response;
};

export const createUserFilter = async params => {
  const response = await api.post('/v3/user/saved-searches', params);
  return response;
};

export const updateTeamFilter = async (id, teamId, params) => {
  const response = await api.patch(`/v3/teams/${teamId}/saved-searches/${id}`, params);
  return response;
};

export const updateUserFilter = async (id, params) => {
  const response = await api.patch(`/v3/user/saved-searches/${id}`, params);
  return response;
};

export const deleteTeamFilter = async (id, teamId) => {
  const response = await api.delete(`/v3/teams/${teamId}/saved-searches/${id}`);
  return response;
};

export const deleteUserFilter = async id => {
  const response = await api.delete(`/v3/user/saved-searches/${id}`);
  return response;
};
