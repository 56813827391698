import React from 'react';
import PropTypes from 'prop-types';
import styled from "@emotion/styled";

const AddedCheckMark = styled("div")({
  backgroundColor: '#7fc85f',
  borderRadius: '3px',
  boxSizing: 'border-box',
  display: 'inline-block',
  height: '32px',
  marginLeft: '15px',
  position: 'relative',
  width: '32px',
  '&::after': {
    background: 'transparent',
    border: '2px solid #fff',
    borderRight: 'none',
    borderTop: 'none',
    content: '""',
    height: '8px',
    left: '7px',
    opacity: '1',
    position: 'absolute',
    top: '8px',
    transform: 'rotate(-45deg)',
    width: '16px',
  },
});

const Remove = styled("a")({
  border: '1px solid #e34446',
  borderRadius: '3px',
  boxSizing: 'border-box',
  cursor: 'pointer',
  display: 'inline-block',
  height: '32px',
  marginLeft: '10px',
  position: 'relative',
  width: '32px',
  '&::after, &::before': {
    backgroundColor: '#e34446',
    boxSizing: 'border-box',
    content: '""',
    display: 'block',
    height: '2px',
    left: '50%',
    marginLeft: '-6px',
    marginTop: '-1px',
    position: 'absolute',
    top: '50%',
    width: '12px',
  },
  '&::after': {
    transform: 'rotate(45deg)',
  },
  '&:before': {
    transform: 'rotate(-45deg)',
  },
});

class EmailAdded extends React.Component {
  state = {
    hover: false,
  };

  handleOnMouseOut = () => {
    this.setState({ hover: false });
  };

  handleOnMouseOver = () => {
    this.setState({ hover: true });
  };

  render() {
    const { multiple = false, onRemoveClick, } = this.props;
    const { hover } = this.state;
    return (
      <div
        onBlur={this.handleOnMouseOut}
        onFocus={this.handleOnMouseOver}
        onMouseOut={this.handleOnMouseOut}
        onMouseOver={this.handleOnMouseOver}
        style={{ display: 'inline-block' }}
      >
        {hover && multiple === false ?
          <Remove onClick={onRemoveClick} />
          :
          <AddedCheckMark />
        }
      </div>
    );
  }
}

EmailAdded.propTypes = {
  added: PropTypes.bool.isRequired,
  onRemoveClick: PropTypes.func.isRequired,
};

export default EmailAdded;
