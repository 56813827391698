import { assocPath } from 'ramda';

import * as types from './types';

/**
 * State structure is divided per date, like the following
 * {
 *   [modelType]: {
 *     [modelId]: {
 *       [`startDate-endDate`]: {
 *         error: string,
 *         loading: boolean,
 *         data: {,
 *           allEmails: array,
 *           [section]: {
 *             [filter]: {
 *               docCount: number,
 *               total: number,
 *               value: number,
 *               emails: array,
 *             },
 *           },
 *           emojis: [
 *             {
 *               docCount: number,
 *               emoji: string,
 *               total: number,
 *               value: number,
 *               emails: array,
 *             },
 *           ],
 *         },
 *       },
 *     },
 *   },
 * }
 */
const INITIAL_STATE = {
  brands: {},
  groups: {},
};

const setDataLoading = (state, loading, { modelId, modelType, startDate, endDate }) =>
  assocPath([modelType, `${modelId}`, `${startDate}-${endDate}`, 'loading'], loading, state);

const setDataError = (state, { modelId, modelType, startDate, endDate, error }) =>
  assocPath([modelType, `${modelId}`, `${startDate}-${endDate}`], { error, loading: false }, state);

const setDataPayload = (state, { modelId, modelType, startDate, endDate, payload }) =>
  assocPath(
    [modelType, `${modelId}`, `${startDate}-${endDate}`],
    { data: payload, loading: false },
    state,
  );

const toggleDataLoading = (state, { modelId, modelType, startDate, endDate }) => {
  const modelTypeObj = state[modelType] || {};
  const modelObj = modelTypeObj[modelId] || {};
  const { loading } = modelObj[`${startDate}-${endDate}`] || { loading: false };
  return assocPath(
    [modelType, `${modelId}`, `${startDate}-${endDate}`, 'loading'],
    !loading,
    state,
  );
};

const reducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case types.FETCH_DATA_REQUEST: {
      return setDataLoading(state, true, action);
    }
    case types.FETCH_DATA_FAILURE: {
      return setDataError(state, action);
    }
    case types.FETCH_DATA_SUCCESS: {
      return setDataPayload(state, action);
    }
    case types.TOGGLE_DATA_LOADING: {
      return toggleDataLoading(state, action);
    }
    default: {
      return state;
    }
  }
};

export default reducer;
