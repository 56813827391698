import React from 'react';
import PropTypes from 'prop-types';
import humanize from 'humanize-number';
import pluralize from 'pluralize';
import { camelizeKeys } from 'humps';
import { connect } from 'react-redux';
import { isEmpty } from 'ramda';

import {
  customListsActions as actions,
  customListsSelectors as selectors,
} from '../../../modules/customLists';

import ListCard from '../../ui/ListCard';
import { Box, Flex, Grid, Spinner, Text } from '../../mc-ui';

import CreateNewListButton from './CreateNewListButton';
import ListControlDropdown from './ListControlDropdown';
import NoLists from './NoLists';
import SortSelect from './SortSelect';

class MyLists extends React.Component {
  constructor(props) {
    super(props);

    const { router } = props;
    const sort = router.location.query.sort || 'updated_at:desc';

    this.state = { sort };
  }

  componentDidMount() {
    this.fetchLists();
  }

  componentDidUpdate(_prevProps, prevState) {
    if (this.state.sort !== prevState.sort) {
      this.fetchLists();
    }
  }

  fetchLists = () => {
    const params = { sort: this.state.sort };

    if (this.props.listType) {
      params.list_type = this.props.listType;
    }

    this.props.request(params);
  };

  updateSort = sort => {
    const { router } = this.props;

    this.setState(
      () => ({ sort }),
      () => {
        router.replace({
          pathname: router.location.pathname,
          query: { ...router.location.query, sort },
        });
      },
    );
  };

  render() {
    const { handleCreate, listType, lists } = this.props;
    const { sort } = this.state;
    const loading = this.props.parentLoading || this.props.loading;

    if (loading) {
      return (
        <Flex alignItems="center" height="480px" justifyContent="center">
          <Spinner size="40px" />
        </Flex>
      );
    }

    return (
      <Box>
        <Flex alignItems="center" justifyContent="space-between" marginBottom="24px">
          <Flex alignItems="center" width="unset">
            <SortSelect handleChange={this.updateSort} value={sort} />

            <Text css={{ marginLeft: '8px' }}>
              {humanize(lists.length)} {pluralize('list', lists.length)}
            </Text>
          </Flex>

          <CreateNewListButton buttonLabel="Create New List" handleCreate={handleCreate} />
        </Flex>

        {isEmpty(lists) ? (
          <NoLists />
        ) : (
          <Grid columnGap="46px" gridTemplateColumns="repeat(3, 1fr)" rowGap="40px">
            {lists.map(list => (
              <ListCard key={`my-EmailLists-${list.id}`} list={list} listType={listType}>
                {() => (
                  <Box bottom="16px" height="24px" position="absolute" right="16px" width="24px">
                    <ListControlDropdown
                      list={list}
                      listType={listType}
                      refetchLists={this.fetchLists}
                    />
                  </Box>
                )}
              </ListCard>
            ))}
          </Grid>
        )}
      </Box>
    );
  }
}

MyLists.defaultProps = {
  listType: null,
};

MyLists.propTypes = {
  handleCreate: PropTypes.func.isRequired,
  listType: PropTypes.string,
  lists: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })).isRequired,
  parentLoading: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  request: PropTypes.func.isRequired,
  router: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => ({
  lists: camelizeKeys(selectors.userListsDataSelector(state)),
  loading: selectors.isUserListsLoadingSelector(state),
});

const mapDispatchToProps = dispatch => ({
  handleCreate: (...args) => dispatch(actions.createRequest(...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MyLists);
