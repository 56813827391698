import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { userSelectors } from '../../../modules/user';

import { baseUrl } from '../../../lib/api';

import DownloadIcon from '../../mc-ui/icons/DownloadIcon';
import Label from '../../Upgrade/ui/Label';
import { Box, Button, theme } from '../../mc-ui';

const MenuItem = props => (
  <Button
    variant="none"
    css={{
      alignItems: 'center',
      display: 'flex',
      height: '32px',
      lineHeight: '32px',
      padding: '0 8px',
      textAlign: 'left',
      width: '100%',
      '&:hover': { backgroundColor: theme.colors.dark6, border: 'none', color: theme.colors.dark1 },
      '& > svg': {
        height: '24px',
        marginRight: '7px',
        width: '24px',
      },
      '& > em, & > small': {
        marginLeft: '8px',
      },
      '& > small': {
        color: theme.colors.greenDark,
      },
    }}
    {...props}
  />
);

class DownloadEmailDropdown extends React.Component {
  handleExport = event => {
    event.preventDefault();

    const { email, isUserRestricted } = this.props;
    if (isUserRestricted) return;

    const { format } = event.target.dataset;
    const downloadURL = `${baseUrl()}/v2/searches?email_id=${email.id}&format=${format}`;

    window.analytics.track('Export clicked', { format, type: 'email' });
    window.open(downloadURL, '_blank');
  };

  render() {
    const { isUserRestricted } = this.props;

    return (
      <Box
        inline
        height="32px"
        position="relative"
        width="120px"
        css={{
          '& > div': {
            display: 'none',
          },
          '&:hover': {
            '& > div': {
              display: 'block',
            },
          },
        }}
      >
        <Button
          variant="secondary"
          css={{
            alignItems: 'center',
            display: 'inline-flex',
            height: '32px',
            justifyContent: 'center',
            lineHeight: '32px',
            margin: '0',
            padding: '0',
            width: '120px',
            '& > svg': {
              marginRight: '8px',
            },
          }}
        >
          <DownloadIcon />
          Download
        </Button>

        <Box
          backgroundColor="white"
          borderRadius="4px"
          padding="4px"
          position="absolute"
          right="0"
          top="28px"
          width={isUserRestricted ? '280px' : '235px'}
          css={{ filter: 'drop-shadow(0px 2px 8px rgba(9, 30, 66, 0.2))' }}
          zIndex="6"
        >
          <MenuItem data-format="images" onClick={this.handleExport}>
            <DownloadIcon /> Download images {isUserRestricted && <Label>Pro</Label>}
          </MenuItem>

          <MenuItem data-format="csv" onClick={this.handleExport}>
            <DownloadIcon /> Download email data (CSV) {isUserRestricted && <Label>Pro</Label>}
          </MenuItem>
        </Box>
      </Box>
    );
  }
}

DownloadEmailDropdown.propTypes = {
  email: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.shape({
      guid: PropTypes.string,
    }),
  }).isRequired,
  isUserRestricted: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  isUserRestricted: userSelectors.isUserRestrictedSelector(state),
});

export default connect(mapStateToProps)(DownloadEmailDropdown);
