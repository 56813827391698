import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { teamsActions, teamsSelectors } from '../../../modules/teams';
import PlanExpiredNotice from '../../MyPlan/PlanExpiredNotice';

import { Box, Button, Heading, Text } from '../../mc-ui';

class Invitation extends React.Component {
  state = {
    processing: false,
  };

  accept = async event => {
    event.preventDefault();
    this.setState(
      () => ({ processing: true }),
      () =>
        new Promise(resolve => {
          this.props.accept(resolve);
        }),
    );
  };

  decline = async event => {
    event.preventDefault();
    this.setState(
      () => ({ processing: true }),
      () =>
        new Promise(resolve => {
          this.props.decline(resolve);
        }),
    );
  };

  render() {
    const { team, expired, } = this.props;
    const { processing } = this.state;

    return (
      team && (
        <Box backgroundColor="blueLight" borderRaius="4px" padding="24px">
          <Heading as="h4" fontSize="18px" lineHeight="24px" css={{ marginBottom: '8px' }}>
            Join your team
          </Heading>

          <Text color="dark08" css={{ marginBottom: '16px' }}>
            You&apos;ve been invited to join <strong>{team.attributes.name}</strong>.
          </Text>

          {expired && <Box marginBottom="24px">
            <PlanExpiredNotice fromTeamManagement />
          </Box>}
          <Button
            disabled={processing || expired}
            variant="primary"
            css={{
              height: '32px',
              lineHeight: '32px',
              margin: '0 8px 0 0',
              padding: '0',
              width: '136px',
            }}
            onClick={this.accept}
          >
            Accept
          </Button>

          <Button
            disabled={processing}
            variant="secondary"
            css={{
              height: '32px',
              lineHeight: '32px',
              margin: '0',
              padding: '0',
              width: '72px',
            }}
            onClick={this.decline}
          >
            Decline
          </Button>
        </Box>
      )
    );
  }
}

Invitation.propTypes = {
  accept: PropTypes.func.isRequired,
  decline: PropTypes.func.isRequired,
  expired: PropTypes.bool,
  team: PropTypes.shape({
    attributes: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
};

Invitation.defaultProps = {
  expired: false,
}

const mapStateToProps = state => ({
  team: teamsSelectors.teamSelector(state),
});

const mapDispatchToProps = dispatch => ({
  accept: resolve => dispatch(teamsActions.invitationAcceptRequest(resolve)),
  decline: resolve => dispatch(teamsActions.invitationDeclineRequest(resolve)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Invitation);
