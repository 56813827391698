import React from 'react';
import PropTypes from 'prop-types';

import { Box, Button, Flex, Heading, Text, theme } from '../../mc-ui';
import ChevronDownIcon from '../../mc-ui/icons/ChevronDownIcon';
import ChevronUpIcon from '../../mc-ui/icons/ChevronUpIcon';
import CheckBigIcon from '../../mc-ui/icons/CheckBigIcon';
import XmarkIcon from '../../mc-ui/icons/XmarkIcon';

class ProFeatures extends React.Component {
  state = {
    show: !this.props.hide,
  };

  handleToggle = event => {
    event.preventDefault();
    this.setState(currentState => ({ show: !currentState.show }));
  };

  render() {
    const { show } = this.state;
    const {
      interval,
      customTitle,
      isCanceled,
      isFree,
      isPro,
      hasSmsAddon,
      planExpired,
      selected,
      toggleSelect,
    } = this.props;

    if (isCanceled || planExpired) return null;

    const checkColor = isPro ? 'blue' : 'purpleMedium';
    const smsColor = hasSmsAddon ? checkColor : 'red1';

    return (
      <Box marginTop="24px">
        <Flex alignItems="center" marginBottom={show ? '16px' : null}>
          <Heading
            as="h4"
            fontSize="16px"
            lineHeight="20px"
            css={{ margin: '0 !important', padding: '0 !important' }}
          >
            {customTitle || 'Take control in meetings, planning and reporting'}
          </Heading>

          {isPro && (
            <Button
              variant="none"
              onClick={this.handleToggle}
              css={{
                alignItems: 'center',
                color: theme.colors.dark06,
                display: 'inline-flex',
                fontSize: '20px',
                marginLeft: '4px',
                padding: '0',
              }}
            >
              {show ? (
                <ChevronUpIcon height="20px" width="20px" />
              ) : (
                <ChevronDownIcon height="20px" width="20px" />
              )}
            </Button>
          )}
        </Flex>

        {show && (
          <Flex justifyContent="space-between">
            <Box width="218px">
              <Heading
                as="h5"
                color="dark08"
                fontSize="10px"
                fontWeight="bold"
                letterSpacing="0.12em"
                lineHeight="1.56em"
              >
                Unlimited Data
              </Heading>

              <Flex alignItems="flex-start" marginTop="8px">
                <Text color={checkColor} fontSize="24px" lineHeight="24px" margin="0 8px 0 0">
                  <CheckBigIcon />
                </Text>
                <Text color="dark2">Search, save, and export campaigns since 2013</Text>
              </Flex>

              <Flex alignItems="flex-start" marginTop="8px">
                <Text color={checkColor} fontSize="24px" lineHeight="24px" margin="0 8px 0 0">
                  <CheckBigIcon />
                </Text>
                <Text color="dark2">Plan and optimize lifecycle journeys</Text>
              </Flex>
            </Box>

            <Box width="218px">
              <Heading
                as="h5"
                color="dark08"
                fontSize="10px"
                fontWeight="bold"
                letterSpacing="0.12em"
                lineHeight="1.56em"
              >
                Unlimited Journeys
              </Heading>

              <Flex alignItems="flex-start" marginTop="8px">
                <Text color={checkColor} fontSize="24px" lineHeight="24px" margin="0 8px 0 0">
                  <CheckBigIcon />
                </Text>
                <Text color="dark2">
                  Detailed reporting on 2,500+ ecommerce brands and online retailers
                </Text>
              </Flex>

              <Flex alignItems="flex-start" marginTop="8px">
                <Text color={checkColor} fontSize="24px" lineHeight="24px" margin="0 8px 0 0">
                  <CheckBigIcon />
                </Text>
                <Text color="dark2">Industry trends and benchmarks reports</Text>
              </Flex>
            </Box>

            <Box width="218px">
              <Heading
                as="h5"
                color="dark08"
                fontSize="10px"
                fontWeight="bold"
                letterSpacing="0.12em"
                lineHeight="1.56em"
              >
                Collaboration
              </Heading>

              <Flex alignItems="flex-start" marginTop="8px">
                <Text color={checkColor} fontSize="24px" lineHeight="24px" margin="0 8px 0 0">
                  <CheckBigIcon />
                </Text>
                <Text color="dark2">Plan campaigns and share inspiration with teammates</Text>
              </Flex>

              <Flex alignItems="flex-start" marginTop="8px">
                <Text color={checkColor} fontSize="24px" lineHeight="24px" margin="0 8px 0 0">
                  <CheckBigIcon />
                </Text>
                <Text color="dark2">Keep tabs on ecommerce trends with custom groups</Text>
              </Flex>
            </Box>

            <Box width="218px">
              <Heading
                as="h5"
                color="dark08"
                fontSize="10px"
                fontWeight="bold"
                letterSpacing="0.12em"
                lineHeight="1.56em"
              >
                SMS Campaigns
              </Heading>

              <Flex alignItems="flex-start" marginTop="8px">
                <Text color={smsColor} fontSize="24px" lineHeight="24px" margin="0 8px 0 0">
                  {hasSmsAddon ?  <CheckBigIcon /> : <XmarkIcon />}
                </Text>
                <Text color="dark2">Full access to SMS Campaigns. Additional {interval == "month" ? <span>$49/month.</span> : <span>$499/year.</span>}</Text>
              </Flex>
            </Box>
          </Flex>
        )}

        {isFree &&
          !selected && (
            <Button
              onClick={toggleSelect}
              variant="purple"
              css={{ height: '32px', lineHeight: '32px', margin: 0, padding: 0, width: '80px' }}
            >
              Upgrade
            </Button>
          )}
      </Box>
    );
  }
}

ProFeatures.defaultProps = {
  interval: null,
  customTitle: null,
  hide: false,
  isCanceled: false,
  isFree: true,
  isPro: false,
  hasSmsAddon: true,
  planExpired: false,
  selected: false,
  toggleSelect: () => {},
};

ProFeatures.propTypes = {
  interval: PropTypes.string,
  customTitle: PropTypes.string,
  hide: PropTypes.bool,
  isCanceled: PropTypes.bool,
  isFree: PropTypes.bool,
  isPro: PropTypes.bool,
  hasSmsAddon: PropTypes.bool,
  planExpired: PropTypes.bool,
  selected: PropTypes.bool,
  toggleSelect: PropTypes.func,
};

export default ProFeatures;
