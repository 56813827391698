import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { isEmpty, take } from 'ramda';

import { brandOverviewSelectors as selectors } from '../../../modules/brandOverview';

import { Box, Button, Flex } from '../../mc-ui';
import DownloadEmailsDropdown from '../../DownloadEmailsDropdown';
import EmailThumbnail from '../../ui/EmailThumbnail';

import Title from '../Title';

import LoadingStatus from './LoadingStatus';

const MAX_EMAILS = 4;

function LatestEmails({ latestEmails, loading, model }, { router }) {
  const {
    location: { pathname },
  } = router;

  const browseEmailsPathname = `${pathname.replace('/overview', '')}/emails`;

  return (
    <Box>
      <Flex justifyContent="space-between" marginBottom="24px">
        <Title>Latest emails</Title>

        <Flex justifyContent="space-between" width="256px">
          <Button
            as={Link}
            to={{ pathname: browseEmailsPathname }}
            disabled={loading || isEmpty(latestEmails)}
            css={{ height: '32px', lineHeight: '32px', padding: '0', width: '120px' }}
          >
            Browse emails
          </Button>

          {!loading && <DownloadEmailsDropdown model={model} />}
        </Flex>
      </Flex>

      {loading || isEmpty(latestEmails) ? (
        <LoadingStatus latestEmails={latestEmails} loading={loading} />
      ) : (
        <Flex justifyContent="space-between">
          {take(MAX_EMAILS, latestEmails).map(email => (
            <EmailThumbnail
              key={`groupOverview-latestEmails-${email.id}`}
              brand={{
                id: email.attributes.company_id,
                attributes: { name: email.attributes.company_name },
              }}
              email={email}
            />
          ))}
        </Flex>
      )}
    </Box>
  );
}

LatestEmails.contextTypes = {
  router: PropTypes.object,
};

LatestEmails.defaultProps = {
  model: {},
};

LatestEmails.propTypes = {
  latestEmails: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      attributes: PropTypes.shape({
        company_id: PropTypes.number,
        company_name: PropTypes.string,
      }),
    }),
  ).isRequired,
  loading: PropTypes.bool.isRequired,
  model: PropTypes.shape({}),
};

const mapStateToProps = state => ({
  latestEmails: selectors.getData(state).latestEmails || [],
  loading: selectors.getDataAndInsightsLoading(state),
});

export default connect(mapStateToProps)(LatestEmails);
