import React from 'react';

import ScrollableList from '../ui/ScrollableList';

import { fetchUpcomingHolidays } from './utils/api';

class UpcomingHolidays extends React.PureComponent {
  state = {
    loading: true,
    lists: [],
  };

  componentDidMount() {
    this.request();
  }

  request = async () => {
    const lists = await fetchUpcomingHolidays();

    this.setState(() => ({
      lists,
      loading: false,
    }));
  };

  render() {
    const { loading, lists } = this.state;

    return <ScrollableList collection={lists} loading={loading} type="upcomingHolidays" />;
  }
}

export default UpcomingHolidays;
