import { assoc, merge } from 'ramda';

import * as types from './types';

export const INITIAL_STATE = {
  data: null,
  error: null,
  loading: false,
  status: null,
};

const failureReducer = (state, action) =>
  merge(state, { loading: false, error: action.error, status: 'invalid' });

const requestReducer = () => assoc('loading', true, INITIAL_STATE);

const fetchCouponSuccessReducer = (state, action) =>
  merge(state, { loading: false, data: action.data, status: 'valid' });

const resetReducer = () => INITIAL_STATE;

const reducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case types.CLEAR_COUPON:
      return resetReducer();
    case types.FETCH_COUPON_REQUEST:
      return requestReducer();
    case types.FETCH_COUPON_SUCCESS:
      return fetchCouponSuccessReducer(state, action);
    case types.FETCH_COUPON_FAILURE:
      return failureReducer(state, action);
    default:
      return state;
  }
};

export default reducer;
