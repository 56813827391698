import React from 'react';
import PropTypes from 'prop-types';

import Flex from './Flex';
import LoaderBar from './loader/LoaderBar';

const Loader = ({ children, text }) => (
  <Flex
    alignItems="center"
    direction="column"
    justifyContent="center"
    css={{ height: '100%', width: '100%' }}
  >
    {children}
    <LoaderBar data-text={text} hasIcon={children !== null} />
  </Flex>
);

Loader.defaultProps = {
  children: null,
  text: null,
};

Loader.propTypes = {
  children: PropTypes.node,
  text: PropTypes.string,
};

export default Loader;
