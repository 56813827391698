import React from 'react';

import { Box, Flex, Heading, Loader } from '../mc-ui';
import ListCard from '../ui/ListCard';

import { fetchLists } from './utils/api';

class Lists extends React.PureComponent {
  state = {
    lists: [],
    loading: true,
  };

  componentDidMount() {
    this.request();
  }

  request = async () => {
    const lists = await fetchLists();
    this.setState(() => ({ lists, loading: false }));
  };

  render() {
    const { lists, loading } = this.state;

    return (
      <div>
        <Heading as="h3" css={{ fontWeight: '300', lineHeight: '1.32em', marginBottom: '24px' }}>
          Curated ecommerce strategies
        </Heading>

        {loading ? (
          <Flex alignItems="center" height="652px" justifyContent="center" width="824px">
            <Loader />
          </Flex>
        ) : (
          <Flex justifyContent="space-between" wrap="wrap">
            {lists.map(list => (
              <Box key={list.id} marginBottom="32px" width="unset">
                <ListCard list={list} />
              </Box>
            ))}
          </Flex>
        )}
      </div>
    );
  }
}

export default Lists;
