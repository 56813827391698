import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import api from '../../../lib/api';
import { dollar } from '../../../lib/utils';

import { teamsActions, teamsSelectors } from '../../../modules/teams';

import ContactModalLink from '../../ContactModalLink';
import { Box, Button, Flex, Heading, Loader, Modal, Text } from '../../mc-ui';
import { topics } from '../../ContactUs';

import { AMOUNTS } from '../PlanSwitcher/ProOption';

class UpgradeModal extends React.Component {
  state = {
    loading: true,
    monthlyDiffAmount: null,
    monthlyInvoiceAmount: null,
    upgrading: false,
    yearlyDiffAmount: null,
    yearlyInvoiceAmount: null,
  };

  componentDidUpdate = prevProps => {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.calculatePlans();
    }
  };

  calculatePlans = async () => {
    const { membersMeta, subscription } = this.props;
    const activeMembers = membersMeta.counts ? membersMeta.counts.active : 1;

    const monthResponse = await api.get(`/v3/subscriptions/${subscription.id}/calculate`, {
      params: { interval: 'month', quantity: activeMembers },
    });
    const yearResponse = await api.get(`/v3/subscriptions/${subscription.id}/calculate`, {
      params: { interval: 'year', quantity: activeMembers },
    });

    const monthlyInvoiceAmount = monthResponse.data.data[0].attributes.next_invoice_amount;
    const yearlyInvoiceAmount = yearResponse.data.data[0].attributes.next_invoice_amount;

    const monthlyDiffAmount = monthlyInvoiceAmount - AMOUNTS.month;
    const yearlyDiffAmount = yearlyInvoiceAmount - AMOUNTS.year;

    this.setState({
      loading: false,
      monthlyDiffAmount,
      monthlyInvoiceAmount,
      yearlyDiffAmount,
      yearlyInvoiceAmount,
    });
  };

  handleClose = event => {
    if (event) event.preventDefault();

    const { closeModal } = this.props;

    this.setState({ loading: true, upgrading: false }, closeModal);
  };

  handleContactModalClose = () => {
    this.props.closeContactModal();
    this.props.closeModal();
  };

  handleUpgrade = event => {
    if (event) event.preventDefault();
    const { interval } = event.currentTarget.dataset;
    const { migrateSubscription, closeModal } = this.props;

    this.setState(
      () => ({ upgrading: true }),
      () => {
        migrateSubscription({ interval }, closeModal);
      },
    );
  };

  render() {
    const { isOpen, membersMeta } = this.props;
    const {
      loading,
      monthlyDiffAmount,
      monthlyInvoiceAmount,
      upgrading,
      yearlyDiffAmount,
      yearlyInvoiceAmount,
    } = this.state;
    const activeMembers = membersMeta.counts ? membersMeta.counts.active : 1;

    return (
      <Modal isOpen={isOpen} onClose={this.handleClose}>
        <Modal.Header
          as="h2"
          fontSize="24px"
          fontWeight="300"
          lineHeight="32px"
          onClose={this.handleClose}
        >
          Migrate your MailCharts plan
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <Flex alignItems="center" justifyContent="center" padding="96px 0" width="776px">
              <Loader />
            </Flex>
          ) : (
            <div>
              <Flex
                alignItems="flex-start"
                border="2px solid"
                borderColor="dark5"
                borderRadius="4px"
                marginBottom="24px"
                padding="24px"
                width="776px"
              >
                <Box flex="1" marginRight="24px" width="unset">
                  <Heading
                    as="h4"
                    color="purpleMedium"
                    fontSize="18px"
                    lineHeight="24px"
                    css={{ marginBottom: monthlyDiffAmount > 0 ? '8px' : null }}
                  >
                    {dollar(monthlyInvoiceAmount, { fromCents: true })}
                    /mo.
                  </Heading>

                  {monthlyDiffAmount > 0 && (
                    <Text>
                      {dollar(AMOUNTS.month, { fromCents: true })}
                      /mo. plus {dollar(monthlyDiffAmount, { fromCents: true })}
                      /mo. for all {activeMembers} team members.
                    </Text>
                  )}
                </Box>

                <Button
                  data-interval="month"
                  disabled={upgrading}
                  onClick={this.handleUpgrade}
                  variant="purple"
                  css={{
                    height: '32px',
                    lineHeight: '32px',
                    margin: '0',
                    padding: '0',
                    width: '144px',
                  }}
                >
                  Migrate
                </Button>
              </Flex>

              <Flex
                alignItems="flex-start"
                border="2px solid"
                borderColor="dark5"
                borderRadius="4px"
                marginBottom="24px"
                padding="24px"
                width="776px"
              >
                <Box flex="1" marginRight="24px" width="unset">
                  <Heading
                    as="h4"
                    color="purpleMedium"
                    fontSize="18px"
                    lineHeight="24px"
                    css={{ marginBottom: yearlyDiffAmount > 0 ? '8px' : null }}
                  >
                    {dollar(yearlyInvoiceAmount, { fromCents: true })}
                    /yr. — two free months when paying annually
                  </Heading>

                  {yearlyDiffAmount > 0 && (
                    <Text>
                      {dollar(AMOUNTS.year, { fromCents: true })}
                      /yr. plus {dollar(yearlyDiffAmount, { fromCents: true })}
                      /yr. for all {activeMembers} team members.
                    </Text>
                  )}
                </Box>

                <Button
                  data-interval="year"
                  disabled={upgrading}
                  onClick={this.handleUpgrade}
                  variant="purple"
                  css={{
                    height: '32px',
                    lineHeight: '32px',
                    margin: '0',
                    padding: '0',
                    width: '144px',
                  }}
                >
                  Migrate
                </Button>
              </Flex>

              <Flex
                alignItems="flex-start"
                border="2px solid"
                borderColor="dark5"
                borderRadius="4px"
                marginBottom="24px"
                padding="24px"
                width="776px"
              >
                <Box flex="1" marginRight="24px" width="unset">
                  <Heading as="h4" fontSize="18px" lineHeight="24px" css={{ marginBottom: '8px' }}>
                    Enterprise
                  </Heading>

                  <Text>
                    Contact support to discuss getting your account set up today!
                  </Text>
                </Box>

                <Button
                  as={ContactModalLink}
                  disabled={upgrading}
                  onComplete={this.handleContactModalClose}
                  topic={topics.ENTERPRISE}
                  variant="primary"
                  css={{
                    height: '32px',
                    lineHeight: '32px',
                    margin: '0',
                    padding: '0',
                    width: '144px',
                  }}
                >
                  Contact Support
                </Button>
              </Flex>

              <Flex
                alignItems="flex-start"
                backgroundColor="dark6"
                borderRadius="4px"
                padding="24px"
                width="776px"
              >
                <Box flex="1" marginRight="24px" width="unset">
                  <Heading as="h4" fontSize="18px" lineHeight="24px" css={{ marginBottom: '8px' }}>
                    Questions?
                  </Heading>

                  <Text>
                    If you have any questions at all, please do not hesitate to contact support and
                    our team will make sure you’re taken care of!
                  </Text>
                </Box>

                <Button
                  as={ContactModalLink}
                  disabled={upgrading}
                  onComplete={this.handleContactModalClose}
                  topic={topics.QUESTION}
                  variant="primary"
                  css={{
                    height: '32px',
                    lineHeight: '32px',
                    margin: '0',
                    padding: '0',
                    width: '144px',
                  }}
                >
                  Contact
                </Button>
              </Flex>
            </div>
          )}
        </Modal.Body>
      </Modal>
    );
  }
}

UpgradeModal.propTypes = {
  closeContactModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  membersMeta: PropTypes.shape({
    counts: PropTypes.shape({
      active: PropTypes.number,
    }),
  }).isRequired,
  subscription: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  migrateSubscription: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  membersMeta: teamsSelectors.membersMetaSelector(state),
  subscription: teamsSelectors.subscriptionSelector(state),
});

const mapDispatchToProps = dispatch => ({
  migrateSubscription: (payload, resolve) =>
    dispatch(teamsActions.subscriptionMigrateRequest(payload, resolve)),
  closeContactModal: () => dispatch({ type: 'HIDE_MODAL' }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UpgradeModal);
