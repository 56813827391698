import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'ramda';

import {
  sendingBehaviorActions as actions,
  sendingBehaviorSelectors as selectors,
} from '../../../../modules/newReports/sendingBehavior';

import { Loader } from '../../../mc-ui';

import ReportSection from '../../ReportSection';
import SortSelect from '../../SortSelect';
import Title from '../../Title';

import Pagination from '../Pagination';

import BrandsHoursTable from './BrandsHoursTable';

const CHART = 'hours';

class PopularBrandsByHour extends React.Component {
  state = {
    order: '-frequency',
  };

  requestPagination = direction => {
    const { endDate, fetchPaginationData, startDate } = this.props;
    fetchPaginationData(endDate, startDate, direction);
  };

  requestSorting = () => {
    const { order } = this.state;
    const { endDate, sort, startDate } = this.props;
    sort(endDate, startDate, order);
  };

  updateOrder = ({ value }) => {
    this.setState(() => ({ order: value }), this.requestSorting);
  };

  render() {
    const { brands, loading, pagination } = this.props;
    const { order } = this.state;

    return (
      <ReportSection css={{ height: '453px', width: '407px' }}>
        <ReportSection.Header alignItems="center" height="33px">
          <Title>By Brands</Title>
          {!loading && (
            <SortSelect
              options={[
                { label: 'Most emails', value: '-frequency' },
                { label: '12AM', value: '-0' },
                { label: '1AM', value: '-1' },
                { label: '2AM', value: '-2' },
                { label: '3AM', value: '-3' },
                { label: '4AM', value: '-4' },
                { label: '5AM', value: '-5' },
                { label: '6AM', value: '-6' },
                { label: '7AM', value: '-7' },
                { label: '8AM', value: '-8' },
                { label: '9AM', value: '-9' },
                { label: '10AM', value: '-10' },
                { label: '11AM', value: '-11' },
                { label: '12PM', value: '-12' },
                { label: '1PM', value: '-13' },
                { label: '2PM', value: '-14' },
                { label: '3PM', value: '-15' },
                { label: '4PM', value: '-16' },
                { label: '5PM', value: '-17' },
                { label: '6PM', value: '-18' },
                { label: '7PM', value: '-19' },
                { label: '8PM', value: '-20' },
                { label: '9PM', value: '-21' },
                { label: '10PM', value: '-22' },
                { label: '11PM', value: '-23' },
              ]}
              onChange={this.updateOrder}
              value={order}
            />
          )}
        </ReportSection.Header>
        {loading ? (
          <Loader />
        ) : (
          <div>
            <ReportSection.Body>
              <BrandsHoursTable brands={brands} />
            </ReportSection.Body>
            {!isEmpty(brands) && (
              <ReportSection.Footer padding="0">
                <Pagination
                  from={pagination.from}
                  nextUrl={pagination.next}
                  onClick={this.requestPagination}
                  prevUrl={pagination.prev}
                  selfUrl={pagination.self}
                  to={pagination.to}
                  total={pagination.total}
                />
              </ReportSection.Footer>
            )}
          </div>
        )}
      </ReportSection>
    );
  }
}

PopularBrandsByHour.defaultProps = {
  brands: [],
  endDate: null,
  startDate: null,
};

PopularBrandsByHour.propTypes = {
  brands: PropTypes.arrayOf(PropTypes.shape({})),
  endDate: PropTypes.string,
  fetchPaginationData: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  pagination: PropTypes.shape({
    current: PropTypes.number,
    from: PropTypes.number,
    next: PropTypes.string,
    prev: PropTypes.string,
    self: PropTypes.string,
    to: PropTypes.number,
    total: PropTypes.number,
  }).isRequired,
  sort: PropTypes.func.isRequired,
  startDate: PropTypes.string,
};

const mapStateToProps = (state, { model }) => {
  if (!model.id) return { loading: true };

  const { endDate, startDate } = state.newReports.dates;

  return {
    brands: selectors.getBrands(model.id, model.type, startDate, endDate, CHART)(state),
    loading: selectors.getChartLoading(model.id, model.type, startDate, endDate, CHART)(state),
    pagination: selectors.getPagination(model.id, model.type, startDate, endDate, CHART)(state),
    endDate,
    startDate,
  };
};

const mapDispatchToProps = (dispatch, { model }) => ({
  fetchPaginationData: (endDate, startDate, direction) =>
    dispatch(
      actions.fetchPaginationDataRequest(
        model.id,
        model.type,
        startDate,
        endDate,
        CHART,
        direction,
      ),
    ),
  sort: (endDate, startDate, order) =>
    dispatch(actions.sortDataRequest(model.id, model.type, startDate, endDate, CHART, order)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PopularBrandsByHour);
