/* eslint react/no-did-update-set-state: "off" */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { connect } from 'react-redux';

import { userSelectors } from '../../modules/user';

import AdvancedOptions from '../AdvancedOptions';
import BottomTray from '../BottomTray';
import EmailContainer from '../EmailContainer';
import { Box, Flex, Text } from '../mc-ui';

import ControlBar from './ControlBar';
import EmailInbox from './EmailInbox';
import Restricted from './Restricted';
import ThisListIsEmpty from './ThisListIsEmpty';

const userLists = user => user.included.filter(i => i.type === 'lists');

const DEFAULT_SEARCH_SIZE = 64;

class Emails extends React.Component {
  state = {
    advanced: false,
    hasLoaded: false,
    editing: false,
    view: this.props.route.path || 'thumbnail',
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    this.query = this.props.searches.query || {};
    this.requestSearches();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { searches } = nextProps;

    if (searches.loading) {
      this.setState({ advanced: false });
    } else {
      const { advanced } = this.props.router.location.query;
      const hasLoaded = true;
      this.setState({ advanced: advanced === 'true', hasLoaded });
    }

    // Don't refresh the email list if user created a new list
    if (userLists(nextProps.user).length > userLists(this.props.user).length) {
      return;
    }

    this.requestSearches();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.route.path !== this.props.route.path) {
      this.setState({ view: this.props.route.path || 'thumbnail' });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleClick = event => {
    event.preventDefault();
    this.props.requestPagination();
  };

  handleScroll = () => {
    const { isListOwner, isUserRestricted } = this.props;
    if (isUserRestricted && !isListOwner) return;

    const windowHeight =
      'innerHeight' in window ? window.innerHeight : document.documentElement.offsetHeight;
    const { body, documentElement: html } = document;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight,
    );
    const windowBottom = windowHeight + window.pageYOffset;
    if (windowBottom >= docHeight) {
      setTimeout(() => {
        this.props.requestPagination();
      }, 250); // adds a slight delay to increase "fetching" effect for end-user
    }
  };

  toggleAdvanced = event => {
    if (event) {
      event.preventDefault();
    }

    const { router } = this.props;
    const { pathname, query } = router.location;
    const advanced = !this.state.advanced;
    if (!advanced) delete query.advanced;
    else query.advanced = true;
    router.push({ pathname, query });
  };

  toggleBulk = event => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    if (this.state.editing) {
      this.props.deselectAllEmails();
    }

    this.setState({ editing: !this.state.editing });
  };

  isRemoveEnabledFromBulk = () => {
    const { router, user } = this.props;
    const isInListShowPage = /\/lists\/[\d]+/i.test(router.location.pathname);

    if (!isInListShowPage) {
      return false;
    }

    // Checking ownership
    const list = user.included.filter(i => i.type === 'lists' && i.id === router.params.list_id)[0];
    return !!list;
  };

  requestSearches = () => {
    const {
      model,
      requestFeatured,
      requestSearch,
      router: { location, params },
    } = this.props;

    // User is viewing an email, so we won't search again
    if (
      (location.state && location.state.modal) ||
      (location.query.advanced && this.state.hasLoaded)
    ) {
      return;
    }

    const modelParam = /\/dashboard/i.test(location.pathname) ? { group_id: model.id } : {};

    // ensure that ALL technology_id are used to allow for combining technology_id:
    const technologyId = [].concat(params.technology_id || [], location.query.technology_id || []);

    const query = {
      ...location.query,
      ...params,
      ...modelParam,
      technology_id: technologyId,
    };

    if (JSON.stringify(query) !== JSON.stringify(this.query)) {
      this.query = query;
      const featured = location.pathname === '/browse/emails' && Object.keys(query).length === 0;

      if (featured) {
        requestFeatured(query);
      } else {
        requestSearch(query);
      }
    }
  };

  renderView() {
    const { editing, view } = this.state;
    const { isListOwner, model } = this.props;

    if (view === 'inbox') return <EmailInbox editing={editing} isListOwner={isListOwner} />;

    return <EmailContainer editing={editing} isListOwner={isListOwner} model={model} />;
  }

  renderRestricted = () => {
    const { view } = this.state;
    const { hideAdvanced, isListOwner, isUserRestricted, model, router, searches } = this.props;
    if (model) {
      if (model.type === 'lists') return null;
      if (model.attributes.featured) return null;
    }

    const { total } = searches.meta;

    if (isUserRestricted && !isListOwner) {
      return (
        <Box marginTop="1em" position="relative">
          <ControlBar
            editing={false}
            loading={searches.loading}
            model={model}
            restricted
            router={router}
            toggleBulk={this.toggleBulk}
            toggleFilters={this.toggleAdvanced}
            total={total}
            view={view}
          />

          {!hideAdvanced && (
            <Box marginTop="32px">
              <AdvancedOptions
                {...this.props}
                toggleAdvancedOptions={this.toggleAdvanced}
                showFilter={this.state.advanced}
                showDates
              />
            </Box>
          )}

          <Restricted model={model} />
        </Box>
      );
    }

    return null;
  };

  render() {
    const { editing, view } = this.state;
    const { hideAdvanced, model, router, searches } = this.props;
    const { total } = searches.meta;
    const { next } = searches.links;

    return (
      this.renderRestricted() || (
        <Box marginTop="1em" position="relative">
          <ControlBar
            editing={editing}
            loading={searches.loading}
            model={model}
            router={router}
            toggleBulk={this.toggleBulk}
            toggleFilters={this.toggleAdvanced}
            total={total}
            view={view}
          />

          <Flex justifyContent="flex-end" height="32px">
            {editing && (
              <Text color="redDark" fontSize="12px" lineHeight="16px" padding="4px 0 0 0">
                Bulk select emails below and choose an action at the bottom of your browser.
              </Text>
            )}
          </Flex>

          {!hideAdvanced && (
            <AdvancedOptions
              {...this.props}
              toggleAdvancedOptions={this.toggleAdvanced}
              showFilter={this.state.advanced}
              showDates
            />
          )}

          <div className="pure-g" style={{ margin: '0 0 1em 0' }}>
            <div className="pure-u-1">
              {!searches.loading && total === 0 && model.type === 'lists' && <ThisListIsEmpty />}

              {!searches.loading &&
                total === 0 &&
                model.type !== 'lists' && <p style={{ textAlign: 'center' }}>No emails.</p>}

              {this.renderView()}
            </div>

            {next && (
              <div className="pure-u-1" style={{ textAlign: 'center' }}>
                {searches.paginating ? (
                  <p>Loading more emails...</p>
                ) : (
                  <div>
                    <p>Scroll to load more emails.</p>
                    <p
                      style={{
                        margin: 0,
                        fontSize: `${12 / 16}em`,
                      }}
                    >
                      If you’re having trouble loading more emails,{' '}
                      <Link onClick={this.handleClick} to={router.location}>
                        click here
                      </Link>
                      .
                    </p>
                  </div>
                )}
              </div>
            )}
          </div>

          <BottomTray
            close={this.toggleBulk}
            enabled={this.state.editing}
            listId={router.params.list_id}
            removeEnabled={this.isRemoveEnabledFromBulk()}
          />
        </Box>
      )
    );
  }
}

Emails.propTypes = {
  deselectAllEmails: PropTypes.func.isRequired,
  hideAdvanced: PropTypes.bool,
  isListOwner: PropTypes.bool,
  isUserRestricted: PropTypes.bool.isRequired,
  model: PropTypes.shape({}),
  requestFeatured: PropTypes.func.isRequired,
  requestPagination: PropTypes.func.isRequired,
  requestSearch: PropTypes.func.isRequired,
  route: PropTypes.shape({
    path: PropTypes.string,
  }).isRequired,
  router: PropTypes.shape({
    location: PropTypes.shape({
      query: PropTypes.shape({
        advanced: PropTypes.any,
      }),
    }),
  }).isRequired,
  searches: PropTypes.shape({
    query: PropTypes.object,
  }).isRequired,
  user: PropTypes.shape({}).isRequired,
};

Emails.defaultProps = {
  hideAdvanced: false,
  isListOwner: null,
  model: null,
};

const mapStateToProps = state => ({
  isUserRestricted: userSelectors.isUserRestrictedSelector(state),
  searches: state.searches,
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  deselectAllEmails: () => dispatch({ type: 'DESELECT_ALL_EMAILS' }),
  requestFeatured: query => dispatch({ type: 'REQUEST_FEATURED', query }),
  requestPagination: () => dispatch({ type: 'REQUEST_PAGINATION' }),
  requestSearch: query =>
    dispatch({ type: 'REQUEST_SEARCH', query: { ...query, limit: DEFAULT_SEARCH_SIZE } }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Emails);
