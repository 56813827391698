import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { teamsActions } from '../../../modules/teams';

import { Button, Heading, Modal } from '../../mc-ui';
import ProFeatures from './ProFeatures';

class CancelProConfirmationModal extends React.Component {
  state = {
    canceling: false,
  };

  confirm = event => {
    if (event) event.preventDefault();
    const { cancelSubscription, closeModal } = this.props;

    this.setState(
      () => ({ canceling: true }),
      () => {
        cancelSubscription(closeModal);
      },
    );
  };

  render() {
    const { closeModal, isOpen, hasSmsAddon } = this.props;
    const { canceling } = this.state;

    return (
      <Modal isOpen={isOpen} onClose={closeModal}>
        <Modal.Body>
          <Heading
            as="h2"
            fontSize="24px"
            fontWeight="300"
            lineHeight="1.32em"
            css={{ margin: '0 0 8px 0' }}
          >
            We are sorry to see you go!
          </Heading>

          <ProFeatures customTitle="You and your team will no longer have access to MailCharts." isFree={false} isPro hasSmsAddon={hasSmsAddon} />
        </Modal.Body>

        <Modal.Footer justifyContent="flex-start">
          <Button
            css={{
              height: '40px',
              lineHeight: '40px',
              marginRight: '8px',
              padding: 0,
              width: '88px',
            }}
            disabled={canceling}
            onClick={this.confirm}
            variant="primary"
          >
            Confirm
          </Button>

          <Button
            css={{ height: '40px', lineHeight: '40px', padding: 0, width: '80px' }}
            disabled={canceling}
            onClick={closeModal}
            variant="secondary"
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

CancelProConfirmationModal.propTypes = {
  cancelSubscription: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  hasSmsAddon: PropTypes.bool.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  cancelSubscription: resolve => dispatch(teamsActions.subscriptionCancelRequest(resolve)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CancelProConfirmationModal);
