import { connect } from 'react-redux';
import { compose, setDisplayName, shallowEqual, shouldUpdate, withProps } from 'recompose';

const mapStateToProps = state => ({
  emails: state.emails.data,
  user: state.user,
});

const hoc = displayName =>
  compose(
    setDisplayName(displayName),
    connect(mapStateToProps),
    withProps(({ email, emails, user }) => {
      const includedLists = email ? email.included.filter(i => i.type === 'lists') : [];
      const selectedEmails = emails.filter(e => e.selected);
      const lists = user.included.filter(i => i.type === 'lists');

      return {
        includedLists,
        lists,
        selectedEmails,
        selectedEmailsCount: selectedEmails.length,
      };
    }),
    shouldUpdate((props, nextProps) =>
      !(
        props.added &&
          shallowEqual(props.added, nextProps.added) &&
          (props.email && shallowEqual(props.email, nextProps.email)) &&
          (props.list && shallowEqual(props.list, nextProps.list)) &&
          (props.lists && shallowEqual(props.lists, nextProps.lists)) &&
          (props.selectedEmails && shallowEqual(props.selectedEmails, nextProps.selectedEmails)) &&
          (props.selectedEmailsCount &&
            shallowEqual(props.selectedEmailsCount, nextProps.selectedEmailsCount))
      )),
  );

export default hoc;
