import React from 'react';

const CloseIcon = props => (
  <svg width="1em" height="1em" viewBox="0 0 13 13" fill="none" {...props}>
    <path
      d="M11.674 10.084L7.593 6.002l4.081-4.08c.44-.442.44-1.15 0-1.592-.44-.44-1.15-.44-1.59 0L6.002 4.412 1.922.33C1.48-.11.771-.11.33.33c-.44.441-.44 1.15 0 1.591l4.082 4.081L.33 10.084c-.44.44-.44 1.15 0 1.59.441.44 1.15.44 1.591 0l4.081-4.081 4.082 4.081c.44.44 1.15.44 1.59 0a1.128 1.128 0 000-1.59z"
      fill="currentColor"
    />
  </svg>
);

export default CloseIcon;
