import React from 'react'

const Purchaser = props => (
  <svg viewBox='0 0 40 40' width='1em' height='1em' {...props}>
    <g fill='none' fillRule='evenodd'>
      <circle fill='#0379D5' cx={20} cy={20} r={20} />
      <g
        stroke='#FFF'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={1.6}
      >
        <path d='M12 13h17v16H12z' />
        <path d='M16 16.654v-3.87a4.785 4.785 0 0 1 9.57 0v3.87' />
      </g>
    </g>
  </svg>
)

export default Purchaser
