import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styles';

// eslint-disable-next-line react/prop-types
const Searching = ({ loading }) => {
  if (!loading) return null;
  return <S.DropdownText>Searching...</S.DropdownText>;
};

// eslint-disable-next-line react/prop-types
const NoResults = ({ brands, loading }) => {
  if (loading || brands.data.length > 0) return null;
  return (
    <S.DropdownText>
      <em>No matching brands</em>
    </S.DropdownText>
  );
};

// eslint-disable-next-line react/prop-types
const Results = ({ brands, loading, model, onClick }) => {
  if (loading || brands.data.length === 0) return null;
  return (
    <div>
      {brands.data.map(brand => (
        <S.DropdownLink
          key={`search-result-${brand.type}-${brand.id}`}
          onClick={onClick}
          selected={brand.id === model.id}
          to={`/${brand.type}/${brand.id}`}
        >
          {brand.attributes.name}
        </S.DropdownLink>
      ))}
    </div>
  );
};

const SearchInputDropdown = ({ active, brands, loading, model, onClick }) => {
  if (!active) return null;
  return (
    <S.DropdownWrapper>
      <S.DropdownContainer>
        <Searching loading={loading} />
        <NoResults brands={brands} loading={loading} />
        <Results brands={brands} loading={loading} model={model} onClick={onClick} />
      </S.DropdownContainer>
    </S.DropdownWrapper>
  );
};

SearchInputDropdown.propTypes = {
  active: PropTypes.bool.isRequired,
  brands: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        type: PropTypes.string,
      }),
    ),
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  model: PropTypes.shape({
    id: PropTypes.string,
  }),
  onClick: PropTypes.func.isRequired,
};

SearchInputDropdown.defaultProps = {
  model: { id: null },
};

export default SearchInputDropdown;
