/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { Link as RRLink } from 'react-router';
import { isEmpty } from 'ramda';

import GroupLogo from '../../../ui/GroupLogo';
import { Box, Flex, Grid, Heading, Text, theme } from '../../../mc-ui';

export default function ExploreBrandGroups({ email, router }) {
  const { query } = router.location;
  const [brand] = (email.relationships.companies || { data: [] }).data;
  const groups = (email.relationships.groups || { data: [] }).data.filter(
    g => `${g.id}` !== `${query.group_id}`,
  );

  if (isEmpty(groups) || !brand) return null;

  return (
    <Box marginBottom="24px">
      <Heading
        as="h5"
        color="dark08"
        fontWeight="bold"
        lineHeight="1.56em"
        css={{ fontSize: '10px', letterSpacing: '0.12em', marginBottom: '16px' }}
      >
        Groups that include {brand.attributes.name}
      </Heading>

      <Grid gap="16px" gridTemplateColumns="repeat(2, 1fr)"
        style={{
          '@media screen and (max-width: 1280px)': {
            gridTemplateColumns: 'repeat(1, 1fr)'
          }
        }}
      >
        {groups.map(group => (
          <RRLink
            key={`EmailExplore-Group${group.id}`}
            to={`/groups/${group.id}`}
            css={{
              color: 'currentColor',
              cursor: 'pointer',
              textDecoration: 'none',
            }}
          >
            <Box
              borderRadius="4px"
              width="206px"
              css={{
                '&:hover': {
                  backgroundColor: theme.colors.dark6,
                },
              }}
            >
              <Flex justifyContent="space-between">
                <GroupLogo group={group} />
                <Flex direction="column" justifyContent="center" width="142px">
                  <Heading
                    as="h5"
                    css={{
                      fontSize: '14px',
                      letterSpacing: '0.02em',
                      lineHeight: '1.25em',
                      textTransform: 'capitalize',
                    }}
                  >
                    {group.attributes.name}
                  </Heading>

                  <Text fontSize="12px">
                    {group.attributes.approximate_companies}{' '}
                    {pluralize('company', group.attributes.approximate_companies)}
                  </Text>
                </Flex>
              </Flex>
            </Box>
          </RRLink>
        ))}
      </Grid>
    </Box>
  );
}

ExploreBrandGroups.propTypes = {
  email: PropTypes.shape({
    relationships: PropTypes.shape({
      lists: PropTypes.shape({
        data: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            attributes: PropTypes.shape({
              approximate_emails: PropTypes.number,
              name: PropTypes.string,
              related_emails: PropTypes.arrayOf(PropTypes.shape({})),
            }),
          }),
        ),
      }),
    }),
  }).isRequired,
  router: PropTypes.shape({
    location: PropTypes.shape({
      query: PropTypes.shape({
        list_id: PropTypes.string,
      }),
    }),
  }).isRequired,
};
