/** @jsx jsx */
import { jsx } from '@emotion/react';
import React from 'react';
import PropTypes from 'prop-types';
import md5 from 'md5';
import { connect } from 'react-redux';

import { teamsSelectors } from '../../../modules/teams';
import { userSelectors } from '../../../modules/user';

import { Box } from '../../mc-ui';

import NotificationSign from '../../ui/NotificationSign';

import { initTrackingSession } from '../../../lib/pendo';

class Avatar extends React.Component {

  componentDidMount() {
    const { team, user } = this.props;

    if(user) {
      initTrackingSession(team, user);
    }
  }

  render() {
    const { team, user } = this.props;
    const pendingMember = team ? ((team || {}).attributes || {}).status === 'pending' : false;

    return (
      <Box height="24px" position="relative" width="24px" inline>
        <img
          css={{
            borderRadius: '50%',
            display: 'inline-block',
            height: '24px',
            width: '24px',
          }}
          src={`https://www.gravatar.com/avatar/${md5(
            (((user || {}).attributes || {}).email || '').toLowerCase(),
          )}?s=50&d=mp`}
          alt={((user || {}).attributes || {}).name}
        />
        {pendingMember && (
          <NotificationSign css={{ position: 'absolute', right: '-5px', top: '1px' }} />
        )}
      </Box>
    );
  }
};

Avatar.defaultProps = {
  team: null,
  user: null
};

Avatar.propTypes = {
  team: PropTypes.shape({
    attributes: PropTypes.shape({
      status: PropTypes.string,
    }),
  }),
  user: PropTypes.shape({
    attributes: PropTypes.shape({
      email: PropTypes.string,
      name: PropTypes.string,
    }),
  }),
};

const mapStateToProps = state => ({
  team: teamsSelectors.teamSelector(state),
  user: userSelectors.getUser(state)
});

export default connect(mapStateToProps)(Avatar);
