import React from 'react';
import PropTypes from 'prop-types';
import humanize from 'humanize-number';
import { connect } from 'react-redux';

import { SubMenu } from '../mc-ui';

const suggestionStyles = {
  textAlign: 'center',
  height: '1em',
  fontSize: `${14 / 16}em`,
  marginBottom: 5,
};

const BrowseMenu = ({ router, suggestions }) => {
  const rootPath = '/browse';

  return (
    <SubMenu>
      <SubMenu.Link
        to={{
          pathname: `${rootPath}/emails`,
          query: router.location.query,
        }}
        activePathnames={['emails', 'inbox', 'calendar']}
      >
        Emails{' '}
        <span style={suggestionStyles}>
          {suggestions.meta.total ? `(${humanize(suggestions.meta.total)})` : null}
        </span>
      </SubMenu.Link>
      <SubMenu.Link
        to={{
          pathname: `${rootPath}/technologies`,
          query: router.location.query,
        }}
      >
        Technologies{' '}
        <span style={suggestionStyles}>
          {suggestions.data.filter(s => s.type === 'technologies').length
            ? `(${humanize(suggestions.data.filter(s => s.type === 'technologies').length)})`
            : null}
        </span>
      </SubMenu.Link>
    </SubMenu>
  );
};

BrowseMenu.propTypes = {
  router: PropTypes.shape({
    location: PropTypes.shape({
      query: PropTypes.object,
      pathname: PropTypes.string,
    }),
  }).isRequired,
  suggestions: PropTypes.shape({
    data: PropTypes.array,
    loading: PropTypes.bool,
    meta: PropTypes.shape({
      total: PropTypes.number,
    }),
  }).isRequired,
};

const mapStateToProps = state => ({
  suggestions: state.suggestions,
});

export default connect(mapStateToProps)(BrowseMenu);
