const INITIAL_STATE = { error: false, message: null }
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'NETWORK_REQUEST': return INITIAL_STATE
    case 'NETWORK_ERR': return { error: true, message: action.message }
    default: return state
  }
}


