import React from 'react';
import humanize from 'humanize-number';
import { camelizeKeys } from 'humps';

import api from '../../../lib/api';

import { Box, Heading, Link, Loader, Text } from '../../mc-ui';
import BrandIcon from '../../mc-ui/icons/BrandIcon';
import EmailIcon from '../../mc-ui/icons/EmailIcon';
import TimelineIcon from '../../mc-ui/icons/TimelineIcon';

import * as S from './styles';

async function fetchGroups() {
  const response = await api.get('/v3/groups?global=true&limit=250');
  return response.data.data;
}

async function fetchMCIndex() {
  const response = await api.get('/v3/companies?size=0&state=active');
  const meta = camelizeKeys(response.data.meta);
  const groups = await fetchGroups();

  return {
    totalBrands: meta.total,
    totalEmails: meta.totalApproximateEmails,
    totalGroups: groups.length,
    totalJourneys: meta.totalApproximateJourneys,
  };
}

class MCIndex extends React.PureComponent {
  state = {
    dataSince: '2013',
    loading: true,
    totalBrands: 0,
    totalEmails: 0,
    totalGroups: 0,
    totalJourneys: 0,
  };

  componentDidMount() {
    this.request();
  }

  request = async () => {
    const data = await fetchMCIndex();

    this.setState(() => ({
      ...data,
      loading: false,
    }));
  };

  render() {
    const { dataSince, loading, totalBrands, totalEmails, totalGroups, totalJourneys } = this.state;

    if (loading) {
      return (
        <S.Wrapper>
          <Box height="46px">
            <Loader />
          </Box>
        </S.Wrapper>
      );
    }

    return (
      <S.Wrapper>
        <Box width="150px">
          <Heading as="h4" css={{ lineHeight: '1.32em', marginBottom: '4px' }}>
            MailCharts Index
          </Heading>

          <Link
            fontSize="12px"
            rel="noopener noreferrer"
            target="_blank"
            to="https://www.mailcharts.com/companies"
          >
            Learn more
          </Link>
        </Box>

        <Box width="unset">
          <Heading
            as="h5"
            color="dark06"
            fontWeight="bold"
            lineHeight="1.56em"
            css={{ fontSize: '10px', letterSpacing: '0.12em' }}
          >
            Total Brands
          </Heading>

          <Text
            color="dark1"
            fontSize="18px"
            fontWeight="500"
            letterSpacing="0.02em"
            lineHeight="1.32em"
            css={{
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <BrandIcon fillOpacity={1} height="20px" width="20px" style={{ marginRight: '4px' }} />
            {humanize(totalBrands)}
          </Text>
        </Box>

        <Box width="unset">
          <Heading
            as="h5"
            color="dark06"
            fontWeight="bold"
            lineHeight="1.56em"
            css={{ fontSize: '10px', letterSpacing: '0.12em' }}
          >
            Total Emails
          </Heading>

          <Text
            color="dark1"
            fontSize="18px"
            fontWeight="500"
            letterSpacing="0.02em"
            lineHeight="1.32em"
            css={{
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <EmailIcon fillOpacity={1} height="20px" width="20px" style={{ marginRight: '4px' }} />
            {humanize(totalEmails)}
          </Text>
        </Box>

        <Box width="unset">
          <Heading
            as="h5"
            color="dark06"
            fontWeight="bold"
            lineHeight="1.56em"
            css={{ fontSize: '10px', letterSpacing: '0.12em' }}
          >
            Total Industries
          </Heading>

          <Text
            color="dark1"
            fontSize="18px"
            fontWeight="500"
            letterSpacing="0.02em"
            lineHeight="1.32em"
            css={{
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <BrandIcon fillOpacity={1} height="20px" width="20px" style={{ marginRight: '4px' }} />
            {humanize(totalGroups)}
          </Text>
        </Box>

        <Box width="unset">
          <Heading
            as="h5"
            color="dark06"
            fontWeight="bold"
            lineHeight="1.56em"
            css={{ fontSize: '10px', letterSpacing: '0.12em' }}
          >
            Total Journeys
          </Heading>

          <Text
            color="dark1"
            fontSize="18px"
            fontWeight="500"
            letterSpacing="0.02em"
            lineHeight="1.32em"
            css={{
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <TimelineIcon height="20px" width="20px" style={{ marginRight: '4px' }} />
            {humanize(totalJourneys)}
          </Text>
        </Box>

        <Box width="unset">
          <Heading
            as="h5"
            color="dark06"
            fontWeight="bold"
            lineHeight="1.56em"
            css={{ fontSize: '10px', letterSpacing: '0.12em' }}
          >
            Data Since
          </Heading>

          <Text
            color="dark1"
            fontSize="18px"
            fontWeight="500"
            letterSpacing="0.02em"
            lineHeight="1.32em"
            css={{
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <BrandIcon fillOpacity={1} height="20px" width="20px" style={{ marginRight: '4px' }} />
            {dataSince}
          </Text>
        </Box>
      </S.Wrapper>
    );
  }
}

export default MCIndex;
