/* global window */

import React, { Component } from 'react';
import styled from "@emotion/styled";

import PaymentLink from '../PaymentLink';
import Container from '../Container';

import { Flex, Heading, Text, theme } from '../mc-ui';

import ContactUs from './ContactUs';
import HeaderContainer from './ui/HeaderContainer';
import Title from './ui/Title';

import Agencies from './icons/Agencies';
import MarketingTeams from './icons/MarketingTeams';
import SalesAndCXTeams from './icons/SalesAndCXTeams';

import JourneysContainer from './ui/JourneysContainer';

const containerStyle = {
  display: 'flex',
  flexDirection: 'column',
  margin: '32px 0 48px 0',
  padding: '32px',
};

const ColumnWrapper = styled("div")({
  width: '245px',
  '& > header': {
    alignItems: 'center',
    backgroundColor: theme.colors.dark6,
    display: 'flex',
    height: '143px',
    justifyContent: 'center',
  },
  '& > h4': {
    marginTop: '24px',
    padding: '0',
  },
});

class UpgradeTeams extends Component {
  componentDidMount() {
    window.analytics.track('User restricted');
  }

  handlePaywallClicked = event => {
    event.preventDefault();
    const type = 'Teams';

    window.analytics.track('Paywall clicked', { type });
  };

  render() {
    return (
      <Container style={containerStyle}>
        <HeaderContainer
          style={{
            backgroundPosition: 'right center',
            backgroundImage: 'url(/upgrade-teams-bg.svg)',
            backgroundSize: 'auto 223px',
            maxHeight: '400px',
          }}
        >
          <Title>We built MailCharts for you and your team</Title>

          <p style={{ maxWidth: 500 }}>
            Say au revoir to manually subscribing to company emails, sorting through a messy inbox,
            and clipping screenshots while preparing for upcoming meetings and quarterly reports.
          </p>

          <p style={{ display: 'inline-block' }}>
            <PaymentLink
              className="pure-button pure-button-primary"
              style={{ fontSize: `${13 / 16}em`, lineHeight: '1em', padding: '1em 1.8em' }}
              to="/manage/plan"
              planId="bQkuw"
              onClick={this.handlePaywallClicked}
            >
              Upgrade now
            </PaymentLink>
          </p>
        </HeaderContainer>

        <JourneysContainer>
          <Title>A dedicated tool for campaign planning and optimization</Title>

          <Flex style={{ padding: '0 60px' }} justifyContent="space-between">
            <ColumnWrapper>
              <header>
                <MarketingTeams height="102.25" width="140.3" />
              </header>
              <Heading as="h4">Marketing teams</Heading>
              <Text>
                MailCharts does all the legwork to collect and curate email campaigns on your behalf
                &mdash; and provides all the tools to search for, save, and share examples, ideas,
                and competitive insights.
              </Text>
              <Text>
                We&lsquo;re already tracking 30,000+ companies so you can go in and find the
                examples you need from Day 1.
              </Text>
            </ColumnWrapper>

            <ColumnWrapper>
              <header>
                <Agencies height="96.9" width="129.66" />
              </header>
              <Heading as="h4">Agencies</Heading>
              <Text>
                Wow your clients with beautiful email visualizations, inspiring campaigns examples
                and benchmark reports designed to help you get the buy-in you need.
              </Text>
              <Text>
                MailCharts is your always-on email marketing assistant that handles the grunt work —
                delivering email stats, info and competitive reports — so you can focus on creating
                and showing value for your clients.
              </Text>
            </ColumnWrapper>

            <ColumnWrapper>
              <header>
                <SalesAndCXTeams height="104.45" width="149.28" />
              </header>
              <Heading as="h4">Sales &amp; CX teams</Heading>
              <Text>
                Equip your sales team with the context they need to quickly send relevant follow-up
                emails. Use MailCharts to see a brand’s recent emails, compare said company against
                its peers, and measure their emails against industry best practices. And if you like
                to mock things up, you’re going to love our export email as HTML option.
              </Text>
            </ColumnWrapper>
          </Flex>
        </JourneysContainer>
        <ContactUs />
      </Container>
    );
  }
}

export default UpgradeTeams;
