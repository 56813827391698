import React from 'react';
import PropTypes from 'prop-types';

import Legend from '../../../newReports/Calendars/Legend';

import Calendar from './Calendar';

const Highlights = ({ data, model }) => (
  <div>
    <Legend model={model} />
    <Calendar data={data} model={model} />
  </div>
);

Highlights.propTypes = {
  data: PropTypes.shape({
    days: PropTypes.arrayOf(PropTypes.shape({})),
    weeks: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  model: PropTypes.shape({ id: PropTypes.string, type: PropTypes.string }).isRequired,
};

export default Highlights;
