import React from 'react';
import PropTypes from 'prop-types';
import humanize from 'humanize-number';
import { connect } from 'react-redux';

import { baseUrl } from '../../lib/api';

import { brandOverviewSelectors } from '../../modules/brandOverview';
import { groupOverviewSelectors } from '../../modules/groupOverview';
import { userSelectors } from '../../modules/user';

import * as S from './styles';

class DownloadEmailsDropdown extends React.Component {
  handleExport = event => {
    event.preventDefault();

    const { format } = event.currentTarget.dataset;
    const { isUserRestricted, model, totalEmails } = this.props;

    if (isUserRestricted) return;

    const apiURL = baseUrl();

    window.analytics.track('Export clicked', { format, type: 'emails' });

    // If totalEmails is present it means that the filters are present and applied to the list of emails
    const { search } = this.context.router.location;
    const filters = totalEmails ? search.replace(/\?/, '&') : '';

    let url = `${apiURL}/v2/searches?format=${format}${filters}`;
    let modelIdParamName;
    if (model && model.type) {
      if (model.type === 'groups') {
        modelIdParamName = 'group_id';
      } else if (model.type === 'lists') {
        modelIdParamName = 'list_id';
      } else {
        modelIdParamName = 'company_id';
      }

      url = `${url}&${modelIdParamName}=${model.id}`;
    } else {
      const params = Object.keys(this.context.router.params)
        .map(key => `${key}=${this.context.router.params[key]}`)
        .join('&');
      url = `${url}&${params}`;
    }

    window.open(url, '_blank');
  };

  render() {
    const { disabled, isUserRestricted, meta, totalEmails, variant, ...props } = this.props;

    let total;
    if (disabled) {
      total = 0;
    } else if (totalEmails) {
      total = totalEmails;
    } else if (meta) {
      total = meta.stats.approximateEmails;
    } else {
      total = 0;
    }

    const totals = {
      csv: total > 5000 ? 5000 : total,
      html: total > 50 ? 50 : total,
      image: total > 50 ? 50 : total,
    };

    return (
      <S.Dropdown disabled={disabled || total === 0} {...props}>
        <S.DropdownButton disabled={disabled} variant={variant} />

        <S.DropdownMenu>
          <S.DropdownMenuItem onClick={this.handleExport} data-format="html">
            Download {humanize(totals.html)} emails as HTML {isUserRestricted && <S.Pro />}
          </S.DropdownMenuItem>

          <S.DropdownMenuItem onClick={this.handleExport} data-format="images">
            Download {humanize(totals.image)} emails as Image {isUserRestricted && <S.Pro />}
          </S.DropdownMenuItem>

          <S.DropdownMenuItem onClick={this.handleExport} data-format="csv">
            Download {humanize(totals.csv)} emails as CSV {isUserRestricted && <S.Pro />}
          </S.DropdownMenuItem>
        </S.DropdownMenu>
      </S.Dropdown>
    );
  }
}

DownloadEmailsDropdown.contextTypes = {
  router: PropTypes.object,
};

DownloadEmailsDropdown.defaultProps = {
  disabled: false,
  meta: null,
  model: null,
  totalEmails: null,
  variant: 'primary',
};

DownloadEmailsDropdown.propTypes = {
  disabled: PropTypes.bool,
  isUserRestricted: PropTypes.bool.isRequired,
  meta: PropTypes.shape({
    stats: PropTypes.shape({
      approximateEmails: PropTypes.number,
    }),
  }),
  model: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
  }),
  totalEmails: PropTypes.number,
  variant: PropTypes.string,
};

const mapStateToProps = (state, { model }) => {
  const isUserRestricted = userSelectors.isUserRestrictedSelector(state);

  if (!model) {
    return {
      isUserRestricted,
    };
  }

  const selectors = model.type === 'groups' ? groupOverviewSelectors : brandOverviewSelectors;

  return {
    isUserRestricted,
    meta: selectors.getData(state).meta,
  };
};

export default connect(mapStateToProps)(DownloadEmailsDropdown);
