import React from 'react';
import PropTypes from 'prop-types';
import escapeHTML from 'escape-html';
import pretty from 'pretty';

import api from '../../../lib/api';

import { Box } from '../../mc-ui';

class EmailIframe extends React.Component {
  state = {
    display: 'none',
    height: 'auto',
  };

  async componentDidMount() {
    const response = await api.get(`/v2/emails/${this.props.guid}/html`);
    this.data = response.data;
    this.buildIframe();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.size !== this.props.size || prevProps.view !== this.props.view) {
      this.buildIframe();
    }
  }

  getIframeHeight = height => {
    const { email } = this.props;

    const { desktop_height: desktopHeight } = email.attributes;

    const iframeHeight = desktopHeight > height ? desktopHeight : height;
    return iframeHeight;
  };

  data = null;
  iframeRef = null;

  handleLoad = event => {
    const { size } = this.props;
    const iframeEl =
      event.target.contentWindow ||
      event.target.contentDocument.document ||
      event.target.contentDocument;

    if (size === 'alt') {
      [].forEach.call(iframeEl.document.querySelectorAll('img'), imgEl => {
        const spanEl = document.createElement('span');
        spanEl.innerHTML = imgEl.alt;
        imgEl.parentNode.replaceChild(spanEl, imgEl);
      });
    }

    const height = iframeEl.document.body.scrollHeight;

    this.setState({
      display: 'block',
      height: this.getIframeHeight(height),
    });
  };

  buildIframe = () => {
    const { guid, view } = this.props;
    const iframeEl =
      this.iframeRef.contentWindow ||
      this.iframeRef.contentDocument.document ||
      this.iframeRef.contentDocument;
    const htmlEl = document.createElement('html');

    if (view === 'source') {
      const bodyEl = document.createElement('body');
      const preEl = document.createElement('pre');

      try {
        preEl.innerHTML = escapeHTML(pretty(this.data));
      } catch (error) {
        preEl.innerHTML = this.data;
      }

      preEl.style.whiteSpace = 'pre-wrap';
      bodyEl.appendChild(preEl);
      htmlEl.appendChild(bodyEl);
    } else {
      htmlEl.innerHTML = this.data;
      const baseEl = document.createElement('base');
      baseEl.href = `//assets.mailcharts.com/emails/${guid}/`;
      htmlEl.querySelector('head').appendChild(baseEl);
    }

    iframeEl.document.open();
    iframeEl.document.write(htmlEl.innerHTML);
    iframeEl.document.close();
    const height = iframeEl.document.body.scrollHeight;

    this.setState({ height: this.getIframeHeight(height) });
  };

  render() {
    const { size, toggleZoom, view } = this.props;
    const { display, height } = this.state;

    return (
      <Box backgroundColor="white" position="relative">
        <iframe
          height={height}
          onLoad={this.handleLoad}
          ref={i => {
            this.iframeRef = i;
          }}
          scrolling="no"
          style={{ border: 'none', display, margin: '0 auto' }}
          title="Email Iframe"
          width={size === 'mobile' ? '400px' : '100%'}
        />
        {view !== 'source' && (
          <Box
            bottom="0"
            height={height}
            left="0"
            onClick={toggleZoom}
            position="absolute"
            right="0"
            top="0"
            zIndex="5"
            css={{ cursor: 'zoom-out' }}
          />
        )}
      </Box>
    );
  }
}

EmailIframe.defaultProps = {
  email: {
    attributes: {
      desktop_height: 0,
    },
  },
  size: '300x400',
};

EmailIframe.propTypes = {
  email: PropTypes.shape({}),
  guid: PropTypes.string.isRequired,
  size: PropTypes.string,
  toggleZoom: PropTypes.func.isRequired,
  view: PropTypes.string.isRequired,
};

export default EmailIframe;
