import styled from "@emotion/styled";

import Text from '../Text';

const Results = styled(Text)(({ theme }) => ({
  color: theme.colors.dark06,
  fontSize: '14px',
  lineHeight: '1.56em',
  textAlign: 'center',
  width: '230px',
}));

export default Results;
