import React from 'react';
import PropTypes from 'prop-types';

import Select from '../../mc-ui/overrides/ReactSelect';
import { Box } from '../../mc-ui';

const OPTIONS = [
  { label: 'Desktop', value: 'desktop' },
  { label: 'Mobile', value: 'mobile' },
  { label: 'Alt', value: 'alt' },
  { label: 'HTML', value: 'source' },
];

export default function ViewSelector({ onChange, view }) {
  const value = OPTIONS.find(o => o.value === view);

  const updateView = ({ value: selectedView }) => {
    onChange(selectedView);
  };

  return (
    <Box width="176px">
      <Select onChange={updateView} options={OPTIONS} value={value} />
    </Box>
  );
}

ViewSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  view: PropTypes.string.isRequired,
};
