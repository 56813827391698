import React from 'react';
import PropTypes from 'prop-types';

import { TYPES } from '../../../../../modules/shareButton/constants';

import AddToListButton from '../../../../AddToListButton';
import ShareButton from '../../../../ui/ShareButton';
import { Box, Flex } from '../../../../mc-ui';

export default function Controls({ data }, { router }) {
  const { action } = router.location.query;

  return (
    <Flex justifyContent="flex-end" margin="16px 0">
      <Box width="unset">
        <AddToListButton
          textMessages={[data]}
          variant="primary"
          action={action}
          asModal
          css={{
            marginRight: '8px',
            width: '90px !important',
            '& > button': {
              boxShadow: 'none !important',
              width: '90px !important',
              '& > svg': { display: 'none' },
            },
          }}
          listType="text-messages"
        />

        <ShareButton
          css={{ margin: '0 0 0 8px' }}
          model={data}
          type={TYPES.textMessage}
          variant="secondary"
        />
      </Box>
    </Flex>
  );
}

Controls.contextTypes = {
  router: PropTypes.object,
};

Controls.propTypes = {
  data: PropTypes.shape({}).isRequired,
};
