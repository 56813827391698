import React from 'react';
import PropTypes from 'prop-types';
import humanize from 'humanize-number';
import pluralize from 'pluralize';
import { Link as RRLink } from 'react-router';
import { connect } from 'react-redux';
import { isEmpty, take } from 'ramda';

import {
  groupOverviewActions as actions,
  groupOverviewSelectors as selectors,
} from '../../../modules/groupOverview';

import EmailThumbnail from '../../ui/EmailThumbnail';
import { Box, Button, Flex, Grid, Heading, Link, Text } from '../../mc-ui';

// eslint-disable-next-line react/prop-types
function HeaderCTA({ emails, group, router, total }) {
  const { query } = router.location;
  delete query.advanced;

  return (
    <Box backgroundColor="white" borderRadius="4px" marginBottom="40px" padding="32px">
      <Flex alignItems="flex-start" marginBottom="40px">
        {isEmpty(query) ? (
          <Box flex="1" marginRight="24px" width="unset">
            <Heading as="h3" fontSize="18px" lineHeight="1.333.em" css={{ marginBottom: '8px' }}>
              Try Filters or upgrade to view all {humanize(total)} {group.attributes.name}{' '}
              {pluralize('email', total)}.
            </Heading>

            <Text>
              Looking for something specific? You can check if we have data that you need! Click the
              blue “Filter” button above, apply all the relevant filters and confirm that we have
              data that is important to your team.
            </Text>
          </Box>
        ) : (
          <Box flex="1" marginRight="24px" width="unset">
            <Heading as="h3" fontSize="18px" lineHeight="1.333.em" css={{ marginBottom: '8px' }}>
              We have {humanize(total)} {pluralize('email', total)} that match your search! Upgrade
              to view them.
            </Heading>

            <Text>
              Access your search results and all {group.attributes.name} emails to jump start brand
              comparisons and insights.
            </Text>
          </Box>
        )}

        <Button
          as={RRLink}
          to="/manage/plan"
          variant="purple"
          css={{
            alignItems: 'center',
            display: 'inline-flex',
            height: '40px',
            justifyContent: 'center',
            padding: '0',
            width: '130px',
          }}
        >
          Upgrade Now
        </Button>
      </Flex>

      <Grid columnGap="8px" gridTemplateColumns="repeat(16, 1fr)" rowGap="4px">
        {emails.map(email => (
          <EmailThumbnail
            key={`restrictedPreviewEmail-${email.id}`}
            brand={{
              id: email.attributes.company_id,
              attributes: { name: email.attributes.company_name },
            }}
            email={email}
            skipLink
            tiny
          />
        ))}
      </Grid>
    </Box>
  );
}

class GroupEmailsRestricted extends React.Component {
  componentDidMount() {
    this.props.request();
  }

  componentDidUpdate(prevProps) {
    if (!this.props.group) return;

    if (prevProps.group.id !== this.props.group.id) {
      this.props.request();
    }
  }

  render() {
    const { data, emails, group, loading, searches } = this.props;
    const { router } = this.context;
    const isLoading = loading || searches.loading;

    if (isLoading) return null;

    const { latestEmails = [] } = data;
    const { total } = searches.meta || { total: 0 };

    return (
      <Box margin="24px 0">
        {total === 0 ? (
          <Box backgroundColor="white" borderRadius="4px" marginBottom="40px" padding="32px">
            <Heading as="h3" fontSize="18px" lineHeight="1.333.em" css={{ marginBottom: '8px' }}>
              Sorry! No results found.
            </Heading>

            <Text>
              There were no matches for your search results. Try updating the keywords or other
              filters to confirm if we have data that you need before you upgrade. Not ready to
              upgrade? Use our <Link to="/emails">curated email examples</Link> for inspiration and
              planning.
            </Text>
          </Box>
        ) : (
          <HeaderCTA emails={emails} group={group} router={router} total={total} />
        )}

        {!isEmpty(latestEmails) && (
          <Box marginBottom="32px">
            <Heading
              as="h3"
              color="dark08"
              fontSize="16px"
              lineHeight="1.25em"
              css={{ marginBottom: '24px' }}
            >
              View the recent emails from selected brands in this group.
            </Heading>

            <Grid gap="38px" gridTemplateColumns="repeat(4, 1fr)">
              {take(20, latestEmails).map(email => (
                <EmailThumbnail
                  key={`restrictedView-EmailThumbnail-${email.id}`}
                  brand={{
                    id: email.attributes.company_id,
                    attributes: { name: email.attributes.company_name },
                  }}
                  email={email}
                />
              ))}
            </Grid>
          </Box>
        )}

        <Box
          backgroundColor="white"
          backgroundImage="/images/curatedListUpgradeBackground.png"
          borderRadius="8px"
          margin="32px 0 24px 0"
          padding="32px"
          css={{
            backgroundPosition: '562px -42px',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <Heading
            as="h3"
            color="dark08"
            fontSize="16px"
            lineHeight="1.25em"
            css={{ marginBottom: '16px' }}
          >
            Unlock full access to{' '}
            <strong>
              {humanize(data.meta.stats.approximateEmails)}{' '}
              {pluralize('email', data.meta.stats.approximateEmails)}
            </strong>{' '}
            from all{' '}
            <strong>
              {humanize(data.meta.totalCompanies)} {pluralize('brand', data.meta.totalCompanies)}
            </strong>{' '}
            in this group.
          </Heading>

          <Button
            as={Link}
            to="/manage/plan"
            variant="purple"
            css={{
              alignItems: 'center',
              display: 'inline-flex',
              height: '32px',
              justifyContent: 'center',
              margin: '0',
              padding: '0',
              width: '136px',
            }}
          >
            Upgrade to PRO
          </Button>
        </Box>
      </Box>
    );
  }
}

GroupEmailsRestricted.contextTypes = {
  router: PropTypes.object,
};

GroupEmailsRestricted.propTypes = {
  data: PropTypes.shape({
    latestEmails: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        attributes: PropTypes.shape({
          company_id: PropTypes.number,
          company_name: PropTypes.string,
        }),
      }),
    ),
    meta: PropTypes.shape({
      stats: PropTypes.shape({
        approximateEmails: PropTypes.number,
      }),
      totalCompanies: PropTypes.number,
    }),
  }).isRequired,
  emails: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      attributes: PropTypes.shape({
        company_id: PropTypes.number,
        company_name: PropTypes.string,
      }),
    }),
  ).isRequired,
  group: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  request: PropTypes.func.isRequired,
  searches: PropTypes.shape({
    loading: PropTypes.bool,
    meta: PropTypes.shape({
      total: PropTypes.number,
    }),
  }).isRequired,
};

const mapStateToProps = (state, { group }) => {
  if (!group || !group.id) {
    return {
      data: {},
      emails: [],
      loading: true,
      searches: { loading: true },
    };
  }

  return {
    data: selectors.getData(state),
    emails: state.emails.data,
    loading: selectors.getLoading(state),
    searches: state.searches,
  };
};

const mapDispatchToProps = (dispatch, { group }) => ({
  request: () => dispatch(actions.fetchDataRequest(group || {})),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GroupEmailsRestricted);
