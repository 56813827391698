import moment from 'moment';
import { camelizeKeys } from 'humps';
import { take } from 'ramda';

import api from '../../../lib/api';

export const fetchFeaturedJourneyEmails = async () => {
  const limit = 3;
  const featured = true;
  const type = 'lifecycle';
  const response = await api.get('/v3/lists', { params: { limit, featured, type } });
  const journeys = camelizeKeys(response.data.data);
  return take(limit, journeys);
};

export const fetchCustomerJourneys = () =>
  Promise.resolve([
    { id: '684', type: 'lists', attributes: { journeyType: 'welcome' } },
    { id: '673', type: 'lists', attributes: { journeyType: 'account_confirmation' } },
    { id: '690', type: 'lists', attributes: { journeyType: 'onboarding' } },
    { id: '671', type: 'lists', attributes: { journeyType: 'cart_abandonment' } },
    { id: '2388', type: 'lists', attributes: { journeyType: 'browse_abandonment' } },
    { id: '57', type: 'lists', attributes: { journeyType: 'order_confirmation' } },
    { id: '687', type: 'lists', attributes: { journeyType: 'shipping_confirmation' } },
    { id: '1330', type: 'lists', attributes: { journeyType: 'delivery_confirmation' } },
    { id: '2480', type: 'lists', attributes: { journeyType: 'refer_a_friend' } },
    { id: '1379', type: 'lists', attributes: { journeyType: 'cancellation' } },
    { id: '9', type: 'lists', attributes: { journeyType: 'win_back' } },
  ]);

export const fetchFeaturedHolidays = async () => {
  const limit = 3;
  const featured = true;
  const type = 'holiday';
  const response = await api.get('/v3/lists', { params: { limit, featured, type } });
  const holidays = camelizeKeys(response.data.data);
  return take(limit, holidays);
};

export const fetchUpcomingHolidays = async () => {
  const data = await Promise.resolve(
    [
      { id: 1899, type: 'lists', attributes: { name: "New Year's Day", occurs_at: `2025-01-01` } },
      { id: 1898, type: 'lists', attributes: { name: 'Christmas Day', occurs_at: `2024-12-25` } },
      { id: 1234, type: 'lists', attributes: { name: 'Easter', occurs_at: `2024-03-31` } },
      { id: 1235, type: 'lists', attributes: { name: "Mother's Day US", occurs_at: `2024-03-10` } },
      { id: 1382, type: 'lists', attributes: { name: '4th July', occurs_at: `2024-07-04` } },
      { id: 677, type: 'lists', attributes: { name: 'Black Friday', occurs_at: `2024-11-29` } },
      { id: 1381, type: 'lists', attributes: { name: "Father's Day US", occurs_at: `2024-06-17` } },
      {
        id: 1237,
        type: 'lists',
        attributes: { name: "April Fool's Day", occurs_at: `2024-04-01` },
      },
      { id: 1380, type: 'lists', attributes: { name: 'Memorial Day', occurs_at: `2024-05-27` } },
      { id: 1554, type: 'lists', attributes: { name: 'Labor Day', occurs_at: `2024-09-02` } },
      { id: 1555, type: 'lists', attributes: { name: 'Halloween', occurs_at: `2024-10-31` } },
      { id: 678, type: 'lists', attributes: { name: 'Cyber Monday', occurs_at: `2024-12-02` } },
      {
        id: 2195,
        type: 'lists',
        attributes: { name: "St. Patrick's Day", occurs_at: `2024-03-17` },
      },
      { id: 2377, type: 'lists', attributes: { name: 'Earth Day', occurs_at: `2024-04-22` } },
      { id: 2341, type: 'lists', attributes: { name: 'Cinco de Mayo', occurs_at: `2024-05-05` } },
      { id: 2379, type: 'lists', attributes: { name: 'May the 4th', occurs_at: `2024-05-04` } },
      {
        id: 3143,
        type: 'lists',
        attributes: { name: 'Free Shipping Day', occurs_at: `2024-12-14` },
      },
      { id: 2096, type: 'lists', attributes: { name: "Valentine's Day", occurs_at: `2024-02-14` } },
      { id: 7227, type: 'lists', attributes: { name: "Giving Tuesday", occurs_at: `2024-11-28` } },
      { id: 10235, type: 'lists', attributes: { name: "Prime Day", occurs_at: `2024-07-23` } },
    ].sort((a, b) => moment(a.attributes.occurs_at).diff(moment(b.attributes.occurs_at))),
  );
  const upcomingHolidays = data.filter(item => moment(item.attributes.occurs_at) > moment());
  const holidays = camelizeKeys(upcomingHolidays);
  return holidays;
};

export const fetchLists = async () => {
  const limit = 50;
  const featured = true;
  const type = 'default';
  const sort = 'position:desc';
  const response = await api.get('/v3/lists', { params: { limit, featured, type, sort } });
  const lists = camelizeKeys(response.data.data);
  return take(limit, lists);
};
