import * as React from 'react';
import PropTypes from 'prop-types';

function CharacterIcon({ fillOpacity, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M17.048 19h-3.075l-1.223-3.18H7.154L6 19H3L8.453 5h2.989l5.606 14zm-5.205-5.539L9.914 8.266l-1.89 5.195h3.82zM18 3h2v18h-2z"
        fill="currentColor"
        fillOpacity={fillOpacity}
      />
    </svg>
  );
}

CharacterIcon.propTypes = {
  fillOpacity: PropTypes.number,
};

CharacterIcon.defaultProps = {
  fillOpacity: 1,
};

export default CharacterIcon;
