import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Field } from 'react-final-form';
import { connect } from 'react-redux';

import * as normalizers from '../../../../lib/forms/normalizers';
import * as validators from '../../../../lib/forms/validators';

import { couponActions, couponSelectors } from '../../../../modules/coupon';

import { Box, Flex, Heading, Text, Label, Select, Spinner, TextField, Checkbox } from '../../../mc-ui';
import CheckBigIcon from '../../../mc-ui/icons/CheckBigIcon';
import WarningTriangleIcon from '../../../mc-ui/icons/WarningTriangleIcon';

const validateCVC = value => {
  if (value == undefined) {
    return undefined
  }
  return value.length < 3 ? 'CVC must contain 3 or 4 digits' : undefined;
};
const normalizeCCNumber = value => {
  const numbers = normalizers.onlyNumbers(value);
  return numbers.slice(0, 16);
};
const normalizeCVC = value => {
  const numbers = normalizers.onlyNumbers(value);
  return numbers.slice(0, 4);
};

const EXPIRE_OPTIONS = Array.apply(0, Array(12)).reduce(
  (acc, _val, index) => {
    const month = moment().month(index);
    const MM = month.format('MM');
    const monthLabel = `${MM} - ${month.format('MMM')}`;
    const monthOption = { label: monthLabel, value: MM };

    const yearLabel = moment()
      .add(index, 'years')
      .format('YY');
    const yearOption = { label: yearLabel, value: yearLabel };

    return {
      months: [].concat(acc.months, monthOption),
      years: [].concat(acc.years, yearOption),
    };
  },
  { months: [], years: [] },
);

class CreditCardFormFields extends React.Component {
  componentDidMount() {
    const { coupon, form } = this.props;

    if (coupon) {
      form.change('coupon', coupon.id);
    }
  }

  componentDidUpdate(prevProps) {
    const { coupon, form } = this.props;

    if (!prevProps.coupon && coupon) {
      form.change('coupon', coupon.id);
    }
  }

  calculateCoupon = event => {
    event.preventDefault();

    const { fetchCoupon } = this.props;
    const { value } = event.target;

    fetchCoupon({ id: value });
  };

  render() {
    const {
      isActive,
      hasSmsAddon,
      couponStatus,
      showCouponField,
      couponIsLoading,
      isCancelingSubscription,
    } = this.props;

    return (
      <div>
        <Flex justifyContent="space-between" marginBottom="16px" width="712px">
          <Label htmlFor="creditCardNumber" width="348px">
            Card number
            <Field
              autoComplete="off"
              component={TextField}
              id="creditCardNumber"
              name="card[number]"
              parse={normalizeCCNumber}
              placeholder="XXXX XXXX XXXX XXXX"
            />
          </Label>

          <Label htmlFor="expMonth" width="112px">
            Expire month
            <Field
              component={Select}
              id="expMonth"
              name="card[exp_month]"
              options={EXPIRE_OPTIONS.months}
              validate={validators.required}
            />
          </Label>

          <Label htmlFor="expYear" width="104px">
            Expire year
            <Field
              component={Select}
              id="expYear"
              name="card[exp_year]"
              options={EXPIRE_OPTIONS.years}
              validate={validators.required}
            />
          </Label>

          <Label htmlFor="cvc" width="104px">
            CVC
            <Field
              autoComplete="off"
              component={TextField}
              id="cvc"
              name="card[cvc]"
              parse={normalizeCVC}
              placeholder="XXX"
              validate={validateCVC}
            />
          </Label>
        </Flex>

        <Flex justifyContent="space-between" marginBottom="16px" width="712px">
          <Label htmlFor="postalCode" width="348px">
            Postal code
            <Field
              autoComplete="off"
              component={TextField}
              id="postalCode"
              name="card[address_zip]"
              placeholder="XXXXX"
            />
          </Label>

          {showCouponField && (
            <Label htmlFor="coupon" width="348px" css={{ position: 'relative' }}>
              Coupon <small>(optional)</small>
              <Field
                autoComplete="off"
                component={TextField}
                error={couponStatus === 'invalid' ? 'Invalid code' : null}
                id="coupon"
                invalid={couponStatus === 'invalid'}
                name="coupon"
                onChange={this.calculateCoupon}
              />
              {couponIsLoading && (
                <Spinner
                  color="dark06"
                  size="20px"
                  css={{
                    position: 'absolute',
                    right: '8px',
                    top: '25px',
                  }}
                />
              )}
              {couponStatus && (
                <Box
                  color={couponStatus === 'valid' ? 'greenMedium' : 'red1'}
                  height="20px"
                  margin="0"
                  padding="0"
                  position="absolute"
                  right="8px"
                  top="32px"
                  width="20px"
                >
                  {couponStatus === 'valid' ? (
                    <CheckBigIcon height="20px" width="20px" />
                  ) : (
                    <WarningTriangleIcon height="20px" width="20px" />
                  )}
                </Box>
              )}
            </Label>
          )}
        </Flex>
        <Box css={{ marginTop: '35px' }}>
          <Heading as="h2" fontSize="16px" lineHeight="20px" css={{ marginBottom: '4px' }}>
            Add-Ons
          </Heading>

          <Text margin="0 0 5px 0">Cancel add-ons at any time.</Text>

          <Flex justifyContent="space-between" width="712px">
            <Field
              component={Checkbox}
              type="checkbox"
              id="sms_addon"
              name="sms_addon"
              label="Include SMS Campaigns (Additional $49/month)"
              disabled={isCancelingSubscription}
            />
          </Flex>
          <Text margin="0px 0px 16px 30px" fontSize="11px">*When adding SMS in the middle of your billing cycle, your card will be charged for the prorated amount.</Text>
        </Box>
      </div>
    );
  }
}

CreditCardFormFields.defaultProps = {
  coupon: null,
  couponStatus: null,
  showCouponField: false,
};

CreditCardFormFields.propTypes = {
  coupon: PropTypes.shape({
    id: PropTypes.string,
  }),
  couponIsLoading: PropTypes.bool.isRequired,
  couponStatus: PropTypes.string,
  fetchCoupon: PropTypes.func.isRequired,
  form: PropTypes.shape({}).isRequired,
  showCouponField: PropTypes.bool,
  isActive: PropTypes.bool,
  hasSmsAddon: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  coupon: couponSelectors.couponSelector(state),
  couponIsLoading: couponSelectors.isLoadingSelector(state),
  couponStatus: couponSelectors.statusSelector(state),
});

const mapDispatchToProps = dispatch => ({
  fetchCoupon: (...args) => dispatch(couponActions.fetchCouponRequest(...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreditCardFormFields);
