import React from 'react';
import PropTypes from 'prop-types';
import styled from "@emotion/styled";
import { withTheme } from '@emotion/react';

const Error = ({ display, error, touched, theme }) => {
  if (error && touched) {

    const ErrorWrapper = styled("div")({
      color: theme.colors.redDark,
      display: display ? 'block' : null,
      fontSize: '80%',
      marginTop: '0.25rem',
      width: '100%',
    });

    return <ErrorWrapper>{error}</ErrorWrapper>;
  }

  return null;
};

Error.propTypes = {
  display: PropTypes.bool,
  error: PropTypes.string,
  touched: PropTypes.bool,
  theme: PropTypes.shape({}).isRequired,
};

Error.defaultProps = {
  display: null,
  error: null,
  touched: false,
};

export default withTheme(Error);
