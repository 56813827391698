import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

import { Heading, Text, Tooltip } from './mc-ui';

const RestrictedTooltip = ({ children, heading, link, restricted, text }) => {
  if (!restricted) return children;

  return (
    <Tooltip
      id="date-select-restricted"
      place="bottom"
      element={children}
      css={{
        '& [data-id="tooltip"]': {
          marginLeft: '0 !important',
        },
      }}
    >
      <Heading as="h3">{heading}</Heading>
      <Text>{text}</Text>
      <Link
        to={link.to}
        className="pure-button pure-button-primary"
        style={{
          alignItems: 'center',
          display: 'inline-flex',
          height: '32px',
          justifyContent: 'center',
          lineHeight: '32px',
          margin: '16px 0 0 0',
          padding: '0',
          width: '117px',
        }}
      >
        {link.text}
      </Link>
    </Tooltip>
  );
};

RestrictedTooltip.defaultProps = {
  heading: 'Unlock Historical Data',
  restricted: false,
  text: 'View reporting data from customized and predefined date ranges for deeper insight.',
  link: {
    text: 'Upgrade Now',
    to: '/manage/plan',
  },
};

RestrictedTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  heading: PropTypes.string,
  link: PropTypes.shape({
    text: PropTypes.string,
    to: PropTypes.string,
  }),
  restricted: PropTypes.bool,
  text: PropTypes.string,
};

export default RestrictedTooltip;
