import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { savedSearchesActions as actions } from '../../../modules/savedSearches';

import { Box } from '../../mc-ui';

import ControlBar from '../ControlBar';

import MyFilters from './MyFilters';
import TeamFilters from './TeamFilters';

class SavedFilters extends React.Component {
  componentDidMount() {
    this.props.fetchTeamFilters();
  }

  render() {
    const { collectionType, fetchTeamFilters, fetchUserFilters, router } = this.props;

    if (!collectionType) return null;

    return (
      <Box>
        <ControlBar collectionType={collectionType} />
        {collectionType === 'my' ? (
          <MyFilters request={fetchUserFilters} router={router} />
        ) : (
          <TeamFilters request={fetchTeamFilters} router={router} />
        )}
      </Box>
    );
  }
}

SavedFilters.defaultProps = {
  collectionType: null,
};

SavedFilters.propTypes = {
  collectionType: PropTypes.string,
  fetchTeamFilters: PropTypes.func.isRequired,
  fetchUserFilters: PropTypes.func.isRequired,
  router: PropTypes.shape({}).isRequired,
};

const mapDispatchToProps = dispatch => ({
  fetchTeamFilters: (...args) => dispatch(actions.fetchTeamFiltersRequest(...args)),
  fetchUserFilters: (...args) => dispatch(actions.fetchUserFiltersRequest(...args)),
});

export default connect(
  null,
  mapDispatchToProps,
)(SavedFilters);
