import React from 'react';
import PropTypes from 'prop-types';

import { Flex } from '../../../../mc-ui';

import Bar from './Bar';
import Labels from './Labels';

const Chart = ({ data }) => (
  <Flex direction="column" css={{ width: '100%' }}>
    <Bar data={data} />
    <Labels data={data} />
  </Flex>
);

Chart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      label: PropTypes.string,
      width: PropTypes.number,
    }),
  ).isRequired,
};

export default Chart;
