/** @jsx jsx */
import React from 'react';
import PropTypes from 'prop-types';
import { isCancel } from 'axios';
import { Link } from 'react-router';
import { jsx, withTheme } from '@emotion/react';

import api from '../../lib/api';

import { Box, Flex, SearchSelect } from '../mc-ui';
import ReactSelect from '../mc-ui/overrides/ReactSelect';

class FilterBar extends React.Component {
  state = {
    defaultBrandValue: null,
    defaultGroupValue: null,
    groupOptions: [],
  };

  componentDidMount = () => {
    this.fetchGroups();
    this.getBrandValue();
    this.getGroupValue();
  };

  UNSAFE_componentWillReceiveProps = (props) => {
    const {
      router: {
        location: { query },
      },
    } = this.context;
    const  { defaultGroupValue } = this.state
    if (query.group_id != (defaultGroupValue || {}).value) {
      this.getGroupValue()
    }
  }

  getBrandValue = () => {
    const {
      router: {
        location: { query },
      },
    } = this.context;

    if (query.company_id && query.company_name) {
      this.setState(() => ({
        defaultBrandValue: { label: query.company_name, value: query.company_id },
      }));
    }
  };

  getGroupValue = () => {
    const {
      router: {
        location: { query },
      },
    } = this.context;

    if (query.group_id && query.group_name) {
      this.setState(() => ({
        defaultGroupValue: { label: query.group_name, value: query.group_id },
      }));
    }
  };

  source = null;

  fetchGroups = async () => {
    const response = await api.get('/v3/groups?size=300&sort=name:asc');
    const groups = response.data.data;
    const groupOptions = groups.map(group => ({ label: group.attributes.name, value: group.id }));

    this.setState(() => ({ groupOptions }));
  };

  // eslint-disable-next-line consistent-return
  searchBrands = async (value, callback) => {
    if (this.source) this.source.cancel();

    this.source = CancelToken.source();

    try {
      const response = await api.get(`/v3/companies?q=${value}`, {
        cancelToken: this.source.token,
      });

      callback(
        response.data.data.map(brand => ({ label: brand.attributes.name, value: brand.id })),
      );
    } catch (error) {
      if (isCancel(error)) {
        console.log('Request canceled');
      } else {
        console.error(error);
      }
    }
  };

  selectBrand = ({ label, value }) => {
    const { router } = this.context;

    router.push({
      pathname: '/journeys/results',
      query: { company_id: value, company_name: label },
    });
  };

  selectGroup = ({ label, value }) => {
    const { router } = this.context;

    router.push({ pathname: '/journeys/results', query: { group_id: value, group_name: label } });
  };

  render() {
    const { theme, showBackButton } = this.props;
    const { defaultBrandValue, defaultGroupValue, groupOptions } = this.state;

    return (
      <Flex alignItems="center" justifyContent="space-between" marginBottom="25px">
        <Flex width="884px">
          <Box marginRight="26px" width="520px">
            <SearchSelect
              async
              cacheOptions
              loadOptions={this.searchBrands}
              onChange={this.selectBrand}
              placeholder="Search by Brand"
              value={defaultBrandValue}
            />
          </Box>

          <Box width="338px">
            <ReactSelect
              onChange={this.selectGroup}
              options={groupOptions}
              placeholder="View by Industry"
              value={defaultGroupValue}
            />
          </Box>
        </Flex>

        {showBackButton && (
          <Link
            to="/journeys"
            css={{
              color: theme.colors.blue,
              fontFamily: theme.font.family,
              fontSize: '12px',
              fontWeight: '500',
              letterSpacing: '0.02em',
              lineHeight: '1.25em',
              textDecoration: 'none',
            }}
          >
            Back to Journeys
          </Link>
        )}
      </Flex>
    );
  }
}

FilterBar.contextTypes = {
  router: PropTypes.object,
};

FilterBar.defaultProps = {
  showBackButton: false,
};

FilterBar.propTypes = {
  showBackButton: PropTypes.bool,
  theme: PropTypes.shape({}).isRequired,
};

export default withTheme(FilterBar);
