import { call, put, select } from 'redux-saga/effects';
import { camelizeKeys } from 'humps';
import { isEmpty } from 'ramda';

import * as actions from './actions';
import * as api from './api';
import * as selectors from './selectors';

export function* fetchJourneys({ model, query }) {
  try {
    const journeys = yield select(selectors.getData(model, query));

    if (isEmpty(journeys)) {
      const { data, links, meta } = yield call(api.fetch, model, query);

      const payload = camelizeKeys({ data, links, meta });

      yield put(actions.getJourneysSuccess(model, query, payload));
    } else {
      yield put(actions.toggleJourneysLoading(model, query));
    }
  } catch (error) {
    console.log('error', error);
    yield put(actions.getJourneysFailure(model, query, error));
  }
}

export function* fetchJourney({ model, id, query }) {
  try {
    const data = yield select(selectors.getJourney(model, id, query));
    const meta = yield select(selectors.getJourneyMeta(model, id, query));

    if (isEmpty(data)) {
      const response = yield call(api.fetchJourney, id, query);

      const payload = camelizeKeys({
        data: response.data[0],
        meta: response.meta,
      });

      yield put(actions.getJourneySuccess(model, id, query, payload));
    } else {
      yield put(actions.toggleJourneyLoading(model, id, query));
      yield put(actions.getJourneySuccess(model, id, query, { data, meta }));
    }
  } catch (error) {
    console.log('error', error);
    yield put(actions.getJourneyFailure(model, id, query, error));
  }
}
