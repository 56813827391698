import api from '../../lib/api';

export function create() {
  return api.post('/v3/weekly-roll-up');
}

export function fetch() {
  return api.get(`/v3/weekly-roll-up`);
}

export function fetchIndustryGroups() {
  return api.get('/v3/groups', { params: { global: true, limit: 250 } });
}

export function update(params) {
  return api.patch(`/v3/weekly-roll-up`, params);
}

export function addGroup(groupId) {
  return api.post(`/v3/weekly-roll-up/groups`, { group_id: groupId });
}

export function removeGroup(groupId) {
  return api.delete(`/v3/weekly-roll-up/groups/${groupId}`);
}
