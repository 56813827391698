import React from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';
import { connect } from 'react-redux';

import api from '../../../../lib/api';
import { Box, Loader } from '../../../mc-ui';
import Modal from '../../../Modal';

import SortSelect from '../../SortSelect';

import BrandsList from './BrandsList';

class BrandsModal extends React.Component {
  state = {
    brands: [],
    loading: true,
    order: '-', // '-' is descend and '' is ascend
  };

  componentDidMount() {
    if (!isNil(this.props.model.id)) {
      this.request();
    }
  }

  handleSort = ({ value }) => {
    this.setState(() => ({ loading: true, order: value }), this.request);
  };

  request = async () => {
    const {
      model: { id, type },
      endDate,
      startDate,
    } = this.props;
    const { order } = this.state;

    if (!endDate || !startDate) return;

    let query = type === 'groups' ? `?group_id=${id}` : `?company_id=${id}`;
    query += `&start_date=${startDate}`;
    query += `&end_date=${endDate}`;
    query += `&sort=${order}frequency`;

    const response = await api.get(`/v3/reports/brands${query}`);
    const {
      data: { included },
    } = response;

    const brands = included.map(brand => ({
      id: brand.id,
      attributes: { name: brand.attributes.name },
      value: brand.meta.stats.frequency.value,
    }));

    this.setState({
      brands,
      loading: false,
    });
  };

  render() {
    const { closeModal } = this.props;
    const { brands, loading, order } = this.state;

    return (
      <Modal onClose={closeModal} modalStyle={{ maxHeight: 530, width: 520 }} useNewComponent>
        <Modal.Header title="Emails Sent by Brands" handleClose={closeModal} />
        <Modal.Body maxHeight="530px" padding="0 32px 12px 32px" customScrollbar>
          <Box marginBottom="32px">
            <SortSelect
              options={[{ label: 'Most emails', value: '-' }, { label: 'Least emails', value: '' }]}
              onChange={this.handleSort}
              value={order}
            />
          </Box>
          {loading ? <Loader /> : <BrandsList brands={brands} full />}
        </Modal.Body>
      </Modal>
    );
  }
}

BrandsModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  endDate: PropTypes.string.isRequired,
  model: PropTypes.shape({ id: PropTypes.string, type: PropTypes.string }).isRequired,
  startDate: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  endDate: state.newReports.dates.endDate,
  startDate: state.newReports.dates.startDate,
});

export default connect(mapStateToProps)(BrandsModal);
