import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  weeklyRollUpActions as actions,
  weeklyRollUpConstants as constants,
  weeklyRollUpSelectors as selectors,
} from '../../../modules/weeklyRollUp';

import { Button, Flex } from '../../mc-ui';

import SubscribeToWeeklyRollUpTooltip from './SubscribeToWeeklyRollUpTooltip';

class SubscribeToWeeklyRollUp extends React.PureComponent {
  componentDidMount() {
    const { groups, request } = this.props;

    if (groups.length === 0) {
      request();
    }
  }

  render() {
    const { addGroup, enabled, group, groups, loading, removeGroup } = this.props;

    if (loading) return null;

    const isDisabled = !enabled || groups.length === constants.MAX_GROUPS_ALLOWED;
    const isSubscribed = groups.find(g => g.id === group.id);

    return (
      <Flex alignItems="center" flex="1" justifyContent="flex-end" width="unset">
        <SubscribeToWeeklyRollUpTooltip />

        <Button
          css={{
            alignItems: 'center',
            display: 'inline-flex',
            height: '32px',
            justifyContent: 'center',
            lineHeight: '32px',
            margin: '0',
            padding: '0',
            width: isSubscribed ? '104px' : '96px',
          }}
          disabled={isDisabled}
          onClick={isSubscribed ? removeGroup : addGroup}
          variant={isSubscribed ? 'dark' : 'secondary'}
        >
          {isSubscribed ? 'Subscribed' : 'Subscribe'}
        </Button>
      </Flex>
    );
  }
}

SubscribeToWeeklyRollUp.propTypes = {
  addGroup: PropTypes.func.isRequired,
  enabled: PropTypes.bool.isRequired,
  group: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      attributes: PropTypes.shape({
        name: PropTypes.string,
        approximate_companies: PropTypes.number,
      }),
    }),
  ).isRequired,
  loading: PropTypes.bool.isRequired,
  removeGroup: PropTypes.func.isRequired,
  request: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  enabled: selectors.isEnabledSelector(state),
  groups: selectors.groupsSelector(state),
  loading: selectors.isLoadingSelector(state),
});

const mapDispatchToProps = (dispatch, { group }) => ({
  addGroup: () => dispatch(actions.addGroupRequest(group)),
  removeGroup: () => dispatch(actions.removeGroupRequest(group)),
  request: () => dispatch(actions.fetchRequest()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SubscribeToWeeklyRollUp);
