import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isNil } from 'ramda';

import { sendingBehaviorActions as actions } from '../../../modules/newReports/sendingBehavior';
import { shallowEqual } from '../../../lib/utils';
import { userSelectors } from '../../../modules/user';

import { ChartTooltip } from '../../mc-ui';

import HeaderContainer from '../HeaderContainer';
import DateSelect from '../DateSelect';
import MainWrapper from '../MainWrapper';

import BrandContainer from './BrandContainer';
import GroupContainer from './GroupContainer';
import Restricted from './Restricted';

class SendingBehavior extends React.Component {
  componentDidMount() {
    if (!isNil(this.props.model.id)) {
      this.request(this.props);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.model.id) return;
    const nextModel = { id: nextProps.model.id, type: nextProps.model.type };
    const model = { id: this.props.model.id, type: this.props.model.type };
    const nextDates = { endDate: nextProps.endDate, startDate: nextProps.startDate };
    const dates = { endDate: this.props.endDate, startDate: this.props.startDate };

    if (!shallowEqual(nextModel, model) || !shallowEqual(nextDates, dates)) {
      this.request(nextProps);
    }
  }

  shouldComponentUpdate(nextProps) {
    const nextModel = { id: nextProps.model.id, type: nextProps.model.type };
    const model = { id: this.props.model.id, type: this.props.model.type };
    const nextDates = { endDate: nextProps.endDate, startDate: nextProps.startDate };
    const dates = { endDate: this.props.endDate, startDate: this.props.startDate };

    return !shallowEqual(nextModel, model) || !shallowEqual(nextDates, dates);
  }

  request = ({ model, endDate, startDate }) => {
    if (this.restricted()) return;
    this.props.fetchData(model.id, model.type, startDate, endDate);
  };

  accessible = () => !this.restricted();

  restricted = () => {
    const { isUserRestricted, model } = this.props;
    if (!model.type) return false;
    if (model.attributes.featured) return false;
    if (isUserRestricted) window.analytics.track('User restricted', { type: `reports` });
    return isUserRestricted;
  };

  render() {
    const { model, router, ...props } = this.props;

    if (!model.id) return null;

    return (
      <MainWrapper>
        <HeaderContainer title="Sending behavior">
          <DateSelect restricted={this.restricted()} model={model} router={router} {...props} />
        </HeaderContainer>

        {this.restricted() ? (
          <Restricted model={model} />
        ) : (
          <div>
            {model.type === 'groups' && <GroupContainer model={model} router={router} />}
            {model.type !== 'groups' && <BrandContainer model={model} router={router} />}
          </div>
        )}
        <ChartTooltip />
      </MainWrapper>
    );
  }
}

SendingBehavior.defaultProps = {
  endDate: null,
  model: { id: null },
  startDate: null,
};

SendingBehavior.propTypes = {
  endDate: PropTypes.string,
  fetchData: PropTypes.func.isRequired,
  isUserRestricted: PropTypes.bool.isRequired,
  model: PropTypes.shape({ id: PropTypes.string, type: PropTypes.string }),
  router: PropTypes.shape({
    location: PropTypes.shape({
      query: PropTypes.object,
      search: PropTypes.string,
    }),
  }).isRequired,
  startDate: PropTypes.string,
};

const mapStateToProps = state => ({
  endDate: state.newReports.dates.endDate,
  isUserRestricted: userSelectors.isUserRestrictedSelector(state),
  startDate: state.newReports.dates.startDate,
});

const mapDispatchToProps = dispatch => ({
  fetchData: (...args) => dispatch(actions.fetchDataRequest(...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SendingBehavior);
