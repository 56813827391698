import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { withTheme } from '@emotion/react';
import styled from "@emotion/styled";

const NavigationLink = ({ indexOnly, theme, to, ...props }, { router }) => {
  const isActive = router.isActive(to, indexOnly);

  const styles = {
    alignItems: 'center',
    boxSizing: 'border-box',
    color: theme.colors.dark08,
    display: 'flex',
    fontFamily: theme.font.family,
    fontSize: '14px',
    height: '60px',
    textDecoration: 'none',

    '&:hover': {
      borderTop: `2px solid ${theme.colors.white}`,
      borderBottom: `2px solid ${theme.colors.dark01}`,
    },

    '& > svg': {
      color: theme.colors.dark06,
      height: '20px',
      width: '20px',
    },
  };

  if (isActive) {
    styles.borderTop = `2px solid ${theme.colors.white}`;
    styles.borderBottom = `2px solid ${theme.colors.blue} !important`;
  }

  const LinkWrapper = styled(Link)({
    ...styles,
  });

  return (
    <LinkWrapper to={to} {...props}></LinkWrapper>
  );
};

NavigationLink.propTypes = {
  indexOnly: PropTypes.bool,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  theme: PropTypes.shape({}).isRequired,
};

NavigationLink.defaultProps = {
  indexOnly: false,
};

NavigationLink.contextTypes = {
  router: PropTypes.object,
};

export default withTheme(NavigationLink);
