import * as types from './types';

export const fetchDataRequest = (modelId, modelType, startDate, endDate) => ({
  type: types.FETCH_DATA_REQUEST,
  modelId,
  modelType: modelType === 'companies' ? 'brands' : 'groups',
  startDate,
  endDate,
});

export const fetchDataSuccess = (modelId, modelType, startDate, endDate, payload) => ({
  type: types.FETCH_DATA_SUCCESS,
  modelId,
  modelType,
  startDate,
  endDate,
  payload,
});

export const fetchDataFailure = (modelId, modelType, startDate, endDate, error) => ({
  type: types.FETCH_DATA_FAILURE,
  modelId,
  modelType,
  startDate,
  endDate,
  error,
});

export const toggleDataLoading = (modelId, modelType, startDate, endDate) => ({
  type: types.TOGGLE_DATA_LOADING,
  modelId,
  modelType,
  startDate,
  endDate,
});

export const fetchPaginationDataRequest = (
  modelId,
  modelType,
  startDate,
  endDate,
  chart,
  direction,
) => {
  const type =
    chart === 'days'
      ? types.FETCH_PAGINATION_DAYS_DATA_REQUEST
      : types.FETCH_PAGINATION_HOURS_DATA_REQUEST;

  return {
    type,
    modelId,
    modelType: modelType === 'companies' ? 'brands' : 'groups',
    startDate,
    endDate,
    chart,
    direction,
  };
};

export const fetchPaginationDataSuccess = (
  modelId,
  modelType,
  startDate,
  endDate,
  chart,
  payload,
) => {
  const type =
    chart === 'days'
      ? types.FETCH_PAGINATION_DAYS_DATA_SUCCESS
      : types.FETCH_PAGINATION_HOURS_DATA_SUCCESS;

  return {
    type,
    modelId,
    modelType,
    startDate,
    endDate,
    chart,
    payload,
  };
};

export const sortDataRequest = (modelId, modelType, startDate, endDate, chart, order) => {
  const type = chart === 'days' ? types.SORT_DAYS_DATA_REQUEST : types.SORT_HOURS_DATA_REQUEST;

  return {
    type,
    modelId,
    modelType: modelType === 'companies' ? 'brands' : 'groups',
    startDate,
    endDate,
    chart,
    order,
  };
};

export const sortDataSuccess = (modelId, modelType, startDate, endDate, chart, payload) => {
  const type = chart === 'days' ? types.SORT_DAYS_DATA_SUCCESS : types.SORT_HOURS_DATA_SUCCESS;

  return {
    type,
    modelId,
    modelType,
    startDate,
    endDate,
    chart,
    payload,
  };
};
