import * as React from 'react';
import PropTypes from 'prop-types';

function CollectionIcon({ fillOpacity, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x={14}
        y={4}
        width={6}
        height={6}
        rx={1}
        stroke="currentColor"
        strokeWidth={2}
        fillOpacity={fillOpacity}
      />
      <rect x={3} y={3} width={8} height={8} rx={2} fill="currentColor" fillOpacity={fillOpacity} />
      <rect
        x={4}
        y={14}
        width={6}
        height={6}
        rx={1}
        stroke="currentColor"
        strokeWidth={2}
        fillOpacity={fillOpacity}
      />
      <rect
        x={13}
        y={13}
        width={8}
        height={8}
        rx={2}
        fill="currentColor"
        fillOpacity={fillOpacity}
      />
    </svg>
  );
}

CollectionIcon.propTypes = {
  fillOpacity: PropTypes.number,
};

CollectionIcon.defaultProps = {
  fillOpacity: 1,
};

export default CollectionIcon;
