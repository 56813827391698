import React from 'react';
import Footer from './Footer';
import Modal from './Modal';
import Navigation from './Navigation';
import Loader from './Loader';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { isNil } from 'ramda';

import 'react-dates/initialize';

import { couponActions } from '../modules/coupon';

import FlashMessage from './ui/FlashMessage';
import DemoNavigation from './Demo/DemoNavigation';

import styles from './styles.css';
import Welcome from './Welcome';
import Splash from './Splash';

const SEGMENT_WRITE_KEY = process.env.NODE_ENV == 'production' ? '82k4a071yh' : '9hkilfoly7';
window.analytics.load(SEGMENT_WRITE_KEY);
const WWW_DOMAIN =
  process.env.NODE_ENV == 'production'
    ? 'https://www.mailcharts.com'
    : process.env.NODE_ENV == 'beta'
      ? 'https://www.mailcharts.com'
      : process.env.NODE_ENV == 'staging'
        ? 'https://staging.mailcharts.com'
        : process.env.NODE_ENV == 'pr'
          ? 'https://pr.mailcharts.com'
          : 'http://localhost:8001';

class Authorizing extends React.Component {
  render() {
    return (
      <div className={styles.container}>
        <p>Authorizing...</p>
      </div>
    );
  }
}

class Authenticated extends React.Component {
  getCoupon = () => {
    const { coupon: couponId } = this.props.location.query;

    if (!couponId) {
      return;
    }

    if (this.props.coupon.loading || !isNil(this.props.coupon.data)) {
      return;
    }

    this.props.fetchCoupon({ id: couponId });

    const { pathname, query } = this.props.location;

    delete query.coupon;

    this.props.router.push({ pathname, query });
  };

  UNSAFE_componentWillReceiveProps(props) {
    const { location } = props;

    // "fresh" means that the route has changed, `key` tells us if the route is freshly created:
    const fresh = this.props.location.key != props.location.key;

    // don't show the modal for route changes on mobile, just go directly to the route:
    const validWidth = document.documentElement.clientWidth > 768;

    // detect the modal in the location.state
    const modal = (props.router.location.state || {}).modal;

    // modal is valid if it meets the above criteria
    // or it was opened using `modal-reducer` setting `this.props.modal.active => true`
    const validModal =
      (modal && fresh && !this.previousChildren && validWidth) || props.modal.active;

    if (validModal) {
      this.previousChildren = this.props.children;
      document.body.classList.toggle('modal-open', true);
    } else if ((fresh && !modal) || this.props.modal.active != props.modal.active) {
      this.previousChildren = null;
      document.body.classList.toggle('modal-open', false);
    }

    this.demo = /\/demo/.test(location.pathname) || (this.demo && (modal || props.modal.active));

    this.getCoupon();
  }

  UNSAFE_componentWillMount() {
    this.demo = /\/demo/.test(location.pathname);
  }

  render() {
    const { location } = this.props;
    const modal =
      (this.previousChildren && (location.state || {}).modal) || this.props.modal.active;
    const wrapperStyle =
      (this.previousChildren && (location.state || {}).wrapperStyle) ||
      this.props.modal.wrapperStyle;
    const modalStyle =
      (this.previousChildren && (location.state || {}).modalStyle) || this.props.modal.modalStyle;
    const { returnTo } = this.props.location.state || {};
    const modalChildren = this.props.modal.modalChildren || this.props.children;

    if (this.props.user.loading) {
      return null;
    }

    return (
      <div style={{ position: 'relative' }}>
        {this.demo ? <DemoNavigation /> : <Navigation {...this.props} />}
        <div className={styles.main} style={{ paddingTop: this.demo ? '60px' : null }}>
          <div className={styles['main-panel']}>
            <div className="pure-g">
              <div className="pure-u-1">
                <div className="pure-u-1">
                  {modal ? this.previousChildren : this.props.children}
                  {modal && (
                    <div>
                      <Modal
                        returnTo={returnTo}
                        modalStyle={modalStyle}
                        wrapperStyle={wrapperStyle}
                      >
                        {modalChildren}
                      </Modal>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <FlashMessage />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  coupon: state.coupon,
});

const mapDispatchToProps = dispatch => ({
  fetchCoupon: (...args) => dispatch(couponActions.fetchCouponRequest(...args)),
});

Authenticated = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Authenticated);

class Authenticating extends React.Component {
  render() {
    const { maintenance, loading } = this.props;
    return (
      <Splash>
        <Loader loading={loading} />
        <div
          style={{
            marginTop: 50,
            padding: '0 50px',
          }}
        />
        {maintenance ? (
          <div>
            <img
              style={{
                width: 200,
                margin: '0 auto',
              }}
              src="https://mailcharts.com/wp-content/uploads/2018/07/hero.svg"
              className="pure-img"
              alt="MailCharts"
            />
            <h5>Under maintenance</h5>
            <p style={{ padding: '5px 10px', fontSize: `${13 / 16}em`, lineHeight: '2em' }}>
              We're working on our inner-selves.
              <br />
              Please email us <a href="mailto:hi@mailcharts.com">hi@mailcharts.com</a> if your
              wait is longer than 30 minutes.
            </p>
          </div>
        ) : (
          <Welcome></Welcome>
        )}
      </Splash>
    );
  }
}

class Main extends React.Component {
  state = {
    authenticating: true,
    loading: true,
    maintenace: false,
  };
  UNSAFE_componentWillReceiveProps(props) {
    const { authenticated, loading, maintenance } = props.user;
    if (!authenticated && !loading && !maintenance) {
      const redirect = encodeURIComponent(window.location.href);
      return (window.location.href = `${WWW_DOMAIN}/signin?redirect=${redirect}`);
    }
    if (maintenance) this.setState({ maintenance });
    if (!loading)
      setTimeout(() => {
        this.setState({ loading });
      }, 250);
    if (authenticated)
      setTimeout(() => {
        this.setState({ loading, authenticated });
      }, 1000);
  }
  render() {
    return (
      <div>
        {this.state.authenticated ? (
          <Authenticated {...this.props} />
        ) : (
          <Authenticating maintenance={this.state.maintenance} loading={this.state.loading} />
        )}
      </div>
    );
  }
}

const s = state => ({ network: state.network, modal: state.modal, user: state.user });
export default connect(s)(Main);
