import * as React from 'react';

function Envelope(props) {
  return (
    <svg width={104} height={64} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M63.767 14.628c2.227-8.167-.99-10.023-7.796-10.023H13.653c-6.497 0-12.065 5.137-12.993 12.2v33.71C.66 57.9 6.538 64 13.653 64h47.949c7.114 0 12.992-6.1 12.992-13.484L67.79 29.848l-5.136-11.136 1.114-4.084zM13.653 11.99h42.752c3.093 0-.31 3.511 0 6.722l3.65 6.681-21.036 13.565c-.928.642-1.856.642-2.474 0L7.774 19.695v-1.927c0-3.21 2.784-5.778 5.878-5.778zM61.91 56.937H13.653c-3.403 0-6.187-2.89-6.187-6.421V28.363l25.057 16.695c1.547.963 3.403 1.605 5.259 1.605 1.856 0 3.403-.642 4.95-1.605l18.87-12.486 6.187 17.944c0 3.531-2.475 6.42-5.878 6.42z"
        fill="#091E42"
        fillOpacity={0.1}
      />
      <path
        fill="#DCE0E5"
        d="M59.312.893l9.002 5.197-5.197 9.001-9.002-5.197zM72.543 7.465l9.48.028-2.146 10.17-10.17-2.145 2.836-8.053zM68.964 42.47l9.002 5.196-5.197 9.002-9.002-5.197zM64.51 23.166l9 5.197-5.196 9.001-9.002-5.197z"
      />
      <path
        d="M95.3 4.085l5.681 3.28V1.919L95.3 4.085zM87.875 21.161l5.682 3.28v-5.447l-5.682 2.167zM73.026 37.495l5.682 3.28v-5.447l-5.681 2.167zM85.297 15.05h10.395l-4.316-7.475-6.079 7.474zM85.056 20.044L76.9 26.486l8.047 3.228.11-9.67z"
        fill="#DFE1E6"
      />
    </svg>
  );
}

export default Envelope;
