import React from 'react';

import { weeklyRollUpConstants as constants } from '../../modules/weeklyRollUp';

import WarningCircleIcon from '../mc-ui/icons/WarningCircleIcon';
import { Box, Flex, Heading, Text, theme } from '../mc-ui';

export default function LimitReachedNotice() {
  return (
    <Flex
      alignItems="flex-start"
      backgroundColor="white"
      border="1px solid"
      borderColor="yellowMedium"
      borderRadius="4px"
      padding="24px"
    >
      <WarningCircleIcon color={theme.colors.yellowDark} />

      <Box flex="1" marginLeft="16px" width="unset">
        <Heading as="h3" lineHeight={`${24 / 18}em`} css={{ marginBottom: '8px' }}>
          You have reached the limit
        </Heading>

        <Text>
          To replace some of the groups, simply click “Unsubscribe” next to a group you no longer
          want to receive weekly roll-ups for, and select a new group from the drop-down. You can
          select up to {constants.MAX_GROUPS_ALLOWED} groups.
        </Text>
      </Box>
    </Flex>
  );
}
