import React from 'react'
import { connect } from 'react-redux'

import Container from '../Container'
import Section from '../Section'
import CompaniesReports from '../CompaniesReports'
import ReportsNavigation from '../reports/ReportsNavigation'


class DemoReports extends React.Component {
  state = {
    loaded: false,
    type: 'companies',
  }
  UNSAFE_componentWillReceiveProps(props) {
    let { loaded } = this.state
    const models_loaded = props.companies.loading === false
    loaded = loaded || models_loaded
    if (loaded) this.props.loaded()
    this.setState({ loaded })
  }
  componentDidMount() {
    const { dispatch } = this.props
    const params = { demo: true }
    dispatch({ type: 'REQUEST_COMPANIES', params })
  }
  render() {
    const m = this.props[this.state.type].data.reduce((m, o) => o, null)
    if (this.state.loaded === false) return null
    return (
      <div>
        <Section>
          <Container>
            <div className='pure-u-1-8'>
              <div className='pure-u-23-24'>
                <img src='/demo-reports-icon.svg' className='pure-img' />
              </div>
            </div>
            <div className='pure-u-7-8'>
              <h2>Brand reports</h2>
              <p>Value prop</p>
            </div>
          </Container>
        </Section>
        <Section>
          <CompaniesReports
            demo={true}
            router={this.props.router}
            children={this.props.children}
          />
        </Section>
      </div>
    )
  }
}

const s = (state) => ({ companies: state.companies, groups: state.groups })
export default connect(s)(DemoReports)
