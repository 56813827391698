import styled from "@emotion/styled";

const ImageWrapper = styled("div")({
  boxSizing: 'border-box',
  width: '300px',
  '& img': {
    boxShadow: '0px 4px 8px 0px rgba(36, 36, 36, .3)',
    margin: '20px 0',
    width: '300px'
  }
})

export default ImageWrapper
