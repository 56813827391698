import React from 'react';
import PropTypes from 'prop-types';

import { Button, Box, Flex } from '../../mc-ui';
import SearchInput from '../../SearchInput';

import AdvancedFiltersModalForm from '../../Brands/AdvancedFiltersModal/Form';

class SearchBar extends React.PureComponent {
  openAdvancedFilter = event => {
    event.preventDefault();

    const { router } = this.props;
    const { pathname, query } = router.location;

    router.push({ pathname, query: { ...query, advanced: true } });
  };

  render() {
    const { router } = this.props;
    const { query } = router.location;

    return (
      <Flex marginBottom="16px">
        <Box width="924px">
          <SearchInput
            {...this.props}
            button={
              <Button
                type="submit"
                css={{
                  height: '32px',
                  lineHeight: '32px',
                  marginLeft: '18px',
                  padding: 0,
                  width: '71px',
                }}
              >
                Search
              </Button>
            }
            placeholder="Type any keywords to find brand or use advanced filter on the right"
          />
        </Box>
        <Button
          css={{
            height: '32px',
            lineHeight: '32px',
            marginLeft: '16px',
            padding: 0,
            width: '126px',
          }}
          onClick={this.openAdvancedFilter}
          variant="secondary"
        >
          Advanced filter
        </Button>
        {query.advanced && <AdvancedFiltersModalForm router={router} />}
      </Flex>
    );
  }
}

SearchBar.propTypes = {
  router: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
      query: PropTypes.shape({}),
    }),
    push: PropTypes.func,
  }).isRequired,
};

export default SearchBar;
