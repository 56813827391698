import React from 'react';

const StayUpToDateIcon = props => (
  <svg viewBox="0 0 125 97" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M120.185 95.388H52.852a2.935 2.935 0 0 1-2.935-2.934V17.936A2.936 2.936 0 0 1 52.852 15h67.333a2.936 2.936 0 0 1 2.935 2.936v74.517a2.935 2.935 0 0 1-2.935 2.935"
        fill="#FFF"
      />
      <path
        d="M120.268 95.389H52.935A2.935 2.935 0 0 1 50 92.454V17.936A2.936 2.936 0 0 1 52.935 15h67.333a2.936 2.936 0 0 1 2.935 2.936v74.518a2.935 2.935 0 0 1-2.935 2.935z"
        stroke="#A1AEBA"
        strokeWidth={2}
      />
      <path fill="#DBE1E6" d="M59 54h56V22H59z" />
      <path
        d="M73.167 61.79h38.1M73.167 67.855h29.1"
        stroke="#6DC66A"
        strokeWidth={2}
        strokeLinecap="round"
      />
      <path
        d="M73.167 76.79h38.1M73.167 82.855h29.1"
        stroke="#DBE1E6"
        strokeWidth={2}
        strokeLinecap="round"
      />
      <path
        fill="#6DC66A"
        d="M66.248 60.28l-4.594 4.593-1.785-1.784-1.404 1.404 3.19 3.189 5.997-5.998z"
      />
      <path
        d="M32.498 58C18.438 58 7 46.562 7 32.503 7 18.44 18.438 7 32.498 7 46.56 7 58 18.44 58 32.503 58 46.562 46.56 58 32.498 58"
        fill="#FFF"
      />
      <path
        d="M7 27C9.313 16.84 17.584 8.91 28 7M38 7c10.404 1.911 18.687 9.84 21 20M28 59C17.112 56.96 8.6 48.113 7 37M59 37c-1.6 11.113-10.112 19.972-21 22M33 2v12M33 52v12M2 33h12M52 33h12M25 32.271L30.538 38 43 25"
        stroke="#027AD7"
        strokeWidth={2.364}
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default StayUpToDateIcon;
