import React from 'react';
import PropTypes from 'prop-types';

import { Flex } from '../mc-ui';
import ArrowLeftIcon from '../mc-ui/icons/pagination/ArrowLeftIcon';
import ArrowRightIcon from '../mc-ui/icons/pagination/ArrowRightIcon';
import Button from '../mc-ui/pagination/Button';
import Results from '../mc-ui/pagination/Results';

const Pagination = ({ align, css, from, nextUrl, onClick, prevUrl, selfUrl, to, total }) => {
  if (!selfUrl) return null;

  let justifyContent = align;
  if (align === 'left') {
    justifyContent = 'flex-start';
  } else if (align === 'right') {
    justifyContent = 'flex-end';
  }

  return (
    <Flex alignItems="center" justifyContent={justifyContent} css={{ marginTop: '14px', ...css }}>
      <Button
        onClick={() => {
          onClick('prev');
        }}
        disabled={!prevUrl}
      >
        <ArrowLeftIcon />
      </Button>
      <Results>
        Showing {from} to {to} of {total}
      </Results>
      <Button
        onClick={() => {
          onClick('next');
        }}
        disabled={!nextUrl}
      >
        <ArrowRightIcon />
      </Button>
    </Flex>
  );
};

Pagination.defaultProps = {
  align: 'center',
  css: {},
  nextUrl: null,
  prevUrl: null,
  selfUrl: null,
};

Pagination.propTypes = {
  align: PropTypes.string,
  css: PropTypes.shape({}),
  from: PropTypes.number.isRequired,
  nextUrl: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  prevUrl: PropTypes.string,
  selfUrl: PropTypes.string,
  to: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};

export default Pagination;
