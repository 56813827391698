import styled from "@emotion/styled";

const getColor = value => {
  if (value === 0) return 'dark6';
  if (value < 10) return 'dark5';
  if (value < 25) return 'dark4';
  if (value < 50) return 'dark3';
  if (value < 75) return 'dark2';

  return 'dark1';
};

const Bar = styled("div")(({ theme, value }) => ({
  backgroundColor: theme.colors[getColor(value)],
  height: 32,
  marginRight: 1,
  width: 11.5,
}));

export default Bar;
