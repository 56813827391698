import moment from 'moment';

import api from '../../lib/api';

export const fetchData = async model => {
  const startDate = moment()
    .day('Synday')
    .format('YYYY-MM-DD');
  const endDate = moment().format('YYYY-MM-DD');
  const response = await api.get(
    `/v3/reports/overview?company_id=${model.id}&end_date=${endDate}&start_date=${startDate}`,
  );
  return response.data;
};

export const fetchBenchmarks = async model => {
  const response = await api.get(`/v3/reports/benchmarks?company_id=${model.id}`);
  return response.data;
};

export const getJourneys = async () => {
  const journeys = [];

  let response = await api.get('/v2/segments/67762/journey');
  journeys.push(response.data);

  response = await api.get('/v2/segments/29145/journey');
  journeys.push(response.data);

  response = await api.get('/v2/segments/67705/journey');
  journeys.push(response.data);

  return { data: journeys };
};
