import * as React from 'react';

function CheckboxSelectedIcon(props) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect
        x={4}
        y={4}
        width={16}
        height={16}
        rx={3}
        fill="#E2F2FF"
        stroke="#0072D3"
        strokeWidth={2}
      />
      <path d="M7.5 10.875l3.325 3.625 5.675-6" stroke="#0072D3" strokeWidth={2} />
    </svg>
  );
}

export default CheckboxSelectedIcon;
