import React from 'react';

import { Box, Flex, Heading, Text } from '../../../mc-ui';

import ReportSection from '../../../newReports/ReportSection';
import Body from '../../../newReports/Body';
import RestrictedHeader from '../../../newReports/RestrictedHeader';
import RestrictedUpgradeButton from '../../../newReports/RestrictedUpgradeButton';

import AccessCustomerJourneys from './AccessCustomerJourneys';
import Investigate from './Investigate';
import TimelineVisualization from './TimelineVisualization';
import TriggeredEmails from './TriggeredEmails';

const Restricted = () => (
  <ReportSection>
    <Body>
      <RestrictedHeader
        description="Analyze thousands of journeys and gain easy access to actionable data."
        reportName="Journeys"
        title="Upgrade to unlock customer Journeys"
      />

      <Flex justifyContent="space-between" marginBottom="40px">
        <Flex direction="column" justifyContent="center" padding="0 32px" width="344px">
          <Heading as="h4" css={{ lineHeight: '1.32em', marginBottom: '8px' }}>
            Access customer journeys from thousands of brands
          </Heading>

          <Text>
            Our team has been triggering, collecting, and classifying emails for years and we have
            real-world customer journeys from thousands of brands.
          </Text>
        </Flex>

        <Box width="367px">
          <AccessCustomerJourneys />
        </Box>
      </Flex>

      <Flex justifyContent="space-between" marginBottom="40px">
        <Box width="346px">
          <TriggeredEmails />
        </Box>

        <Flex direction="column" justifyContent="center" padding="0 32px" width="344px">
          <Heading as="h4" css={{ lineHeight: '1.32em', marginBottom: '8px' }}>
            View triggered emails for over 15 different journey types
          </Heading>

          <Text>
            Zoom in quickly on the email journeys you’re most interested in from your competitor or
            peer brands.
          </Text>
        </Flex>
      </Flex>

      <Flex justifyContent="space-between" marginBottom="40px">
        <Flex direction="column" justifyContent="center" padding="0 32px" width="344px">
          <Heading as="h4" css={{ lineHeight: '1.32em', marginBottom: '8px' }}>
            Investigate customer journey insights and data
          </Heading>

          <Text>
            Discover the strategies brands and industries use to welcome, nurture and retain
            customers. View group-level and brand-level data to compare and improve your valuable
            user journeys.
          </Text>
        </Flex>

        <Box width="360px">
          <Investigate />
        </Box>
      </Flex>

      <Flex justifyContent="space-between" marginBottom="40px">
        <Box width="330px">
          <TimelineVisualization />
        </Box>

        <Flex direction="column" justifyContent="center" padding="0 32px" width="344px">
          <Heading as="h4" css={{ lineHeight: '1.32em', marginBottom: '8px' }}>
            Easily view all emails in a timeline visualization
          </Heading>

          <Text>
            One glance at our journey timeline shows you instantly how a brand is managing email
            campaigns, frequency, and sending patterns. Use this information to test and recommend
            changes to make your own journey emails more timely and relevant.
          </Text>
        </Flex>
      </Flex>

      <RestrictedUpgradeButton />
    </Body>
  </ReportSection>
);

export default Restricted;
