import styled from "@emotion/styled";

const Menu = styled("div")(({ theme }) => ({
  backgroundColor: theme.colors.white,
  borderRadius: 4,
  boxShadow: `0px 1px 4px ${theme.colors.dark02}`,
  marginTop: 8,
  position: 'absolute',
  width: '450px',
  zIndex: 2,
}));

export default Menu;
