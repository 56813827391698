import api from '../../../lib/api';

export const fetchData = async (modelType, modelId, startDate) => {
  let query = `?start_date=${startDate}`;

  if (modelId) {
    query += `&${modelType === 'groups' ? 'group_id' : 'company_id'}=${modelId}`;
  }

  const response = await api.get(`/v3/reports/calendars${query}`);

  return response.data;
};

export const fetchModalData = async (modelType, modelId, date) => {
  let query = `?start_date=${date}&end_date=${date}&interval=day`;

  if (modelId) {
    query += `&${modelType === 'groups' ? 'group_id' : 'company_id'}=${modelId}`;
  }

  const response = await api.get(`/v3/emails/companies${query}`);

  return response;
};
