import React from 'react';
import PropTypes from 'prop-types';

function BrandIcon({ fillOpacity, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.327 2.256l-4.125-.59-3.535 3.536.589 4.125 8.839 8.839 7.07-7.071-8.838-8.84zm-4.125 3.78a.833.833 0 101.179-1.18.833.833 0 00-1.179 1.18zm5.907 3.192l-.826-1.78-.827 1.78-1.947.236L8.946 10.8l-.378 1.925 1.715-.954 1.714.954-.377-1.925 1.437-1.336-1.948-.236z"
        fill="currentColor"
        fillOpacity={fillOpacity}
      />
    </svg>
  );
}

BrandIcon.propTypes = {
  fillOpacity: PropTypes.number,
};

BrandIcon.defaultProps = {
  fillOpacity: 0.6,
};

export default BrandIcon;
