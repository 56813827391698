/** @jsx jsx */
import PropTypes from 'prop-types';
import moment from 'moment';
import { isEmpty } from 'ramda';
import { jsx, withTheme } from '@emotion/react';

import { Box, Flex, Text } from '../../../mc-ui';
import CheckCircleIcon from '../../../mc-ui/icons/CheckCircleIcon';

const Events = ({ events }) => {
  if (isEmpty(events)) return null;

  return (
    <Box>
      {events.map(event => (
        <Flex key={event.id} alignItems="center" marginBottom="16px">
          <Box height="24px" marginRight="10px" width="24px">
            <Text color="orangeMedium" fontSize="24px" lineHeight="1em">
              <CheckCircleIcon />
            </Text>
          </Box>

          <Text color="dark08" letterSpacing="0.02em" lineHeight="1.25em" textAlign="center">
            <strong
              css={{ fontWeight: '500' }}
            >
              {moment(event.attributes.occurredAt).format('LT')} -
            </strong>{' '}
            {event.attributes.label || event.attributes.description}
          </Text>
        </Flex>
      ))}
    </Box>
  );
};

Events.defaultProps = {
  events: [],
};

Events.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      attributes: PropTypes.shape({
        label: PropTypes.string,
        occurredAt: PropTypes.string,
      }),
    }),
  ),
};

export default withTheme(Events);
