import React from 'react';
import PropTypes from 'prop-types';

import { Flex } from '../../mc-ui';

import ParentContextInfo from './ParentContextInfo';
import ThumbnailsNav from './ThumbnailsNav';

export default function EmailTopNavigation({ email, router }) {
  return (
    <Flex backgroundColor="dark004" justifyContent="center" padding="28px 0">
      <Flex height="65px" justifyContent="space-between" width="1200px" minWidth="1024px" paddingLeft="10px" >
        <ParentContextInfo email={email} router={router} />
        <ThumbnailsNav email={email} guid={router.params.guid} router={router} />
      </Flex>
    </Flex>
  );
}

EmailTopNavigation.propTypes = {
  email: PropTypes.shape({}).isRequired,
  router: PropTypes.shape({}).isRequired,
};
