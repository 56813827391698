/** @jsx jsx */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router';
import { jsx, withTheme } from '@emotion/react';

import { Box, Flex, Heading, Text } from '../mc-ui';
import CalendarStartIcon from '../mc-ui/icons/CalendarStartIcon';
import ClassifiedEmailIcon from '../mc-ui/icons/ClassifiedEmailIcon';

import TypeBadge from '../BrandOverview/DataAndInsights/FeaturedJourneys/Journeys/TypeBadge';

import BrandLogo from './BrandLogo';

const JourneyMiniCard = ({ company, journey, theme, }) => {
  return (<Link
    key={journey.id}
    to={`/companies/${company.id}/journeys/${journey.id}`}
    css={{
      textDecoration: 'none',
      '&:hover': { '& h5': { color: `${theme.colors.blue} !important` } },
    }}
  >
    <Box marginBottom="16px">
      <Flex marginBottom="10px">
        <BrandLogo
          brandId={company.id}
          css={{ height: '40px', marginRight: '16px', width: '40px' }}
        />

        <div>
          <Heading
            as="h5"
            css={{
              fontSize: '16px',
              letterSpacing: '0.02em',
              lineHeight: '1.25em',
              textTransform: 'capitalize',
            }}
          >
            {company.attributes.name}
          </Heading>

          <TypeBadge type={journey.attributes.type} />
        </div>
      </Flex>

      <Flex>
        <Text color="dark06" lineHeight="1em" css={{ marginRight: '10px' }}>
          <CalendarStartIcon height="20px" width="20px" />
        </Text>

        <Text color="dark08" fontWeight="normal">
          Start date: {moment(journey.attributes.startDate || journey.attributes.start_date).format('D MMM YYYY')}
        </Text>

      </Flex>
      <Flex>
        <Text color="dark06" lineHeight="1em" css={{ marginRight: '10px' }}>
          <ClassifiedEmailIcon height="20px" width="20px" />
        </Text>

        <Text color="dark08" fontWeight="normal">
          Triggered emails: {journey.attributes.approximate_triggered_emails}
        </Text>
      </Flex>
    </Box>
  </Link>)
};

JourneyMiniCard.propTypes = {
  company: PropTypes.object.isRequired,
  journey: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withTheme(JourneyMiniCard);
