import { call, put, select } from 'redux-saga/effects';
import { ascend, isEmpty, prop, sort } from 'ramda';

import * as actions from './actions';
import * as api from './api';
import * as selectors from './selectors';

const sortByLabel = sort(ascend(prop(['label'])));
const DEFAULT_SELCTED_BRAND = { value: 'premium', label: 'Mailcharts Index' };

export function* fetchBrandOptions({ modelId: brandId }) {
  const options = [DEFAULT_SELCTED_BRAND];
  const payload = options;
  yield put(actions.fetchBrandOptionsSuccess(brandId, payload));
}

export function* fetchGroupBrands({ modelId: groupId }) {
  try {
    const brandsOptions = yield select(selectors.getGroupBrandsOptionsData(groupId));

    if (isEmpty(brandsOptions)) {
      const response = yield call(api.fetchGroupBrands, groupId);
      const options = [DEFAULT_SELCTED_BRAND].concat(
        response.included.map(brandObject => ({
          value: brandObject.id,
          label: brandObject.attributes.name,
        })),
      );
      const payload = sortByLabel(options);

      yield put(actions.fetchGroupBrandsSuccess(groupId, payload));
    } else {
      yield put(actions.toggleGroupBrandsLoading(groupId));
    }
  } catch (error) {
    console.log('error', error);
    yield put(actions.fetchGroupBrandsFailure(groupId, error));
  }
}

export function* fetchGroupData({ brandId, modelId: groupId }) {
  try {
    if (!groupId) return;
    const comparisonData = yield select(selectors.getGroupComparisonData(groupId, brandId));

    if (isEmpty(comparisonData)) {
      const response = yield call(api.fetchGroupBenchmarks, groupId, brandId);
      const payload = { data: response.meta.stats };

      yield put(actions.fetchGroupDataSuccess(groupId, brandId, payload));
    } else {
      yield put(actions.toggleGroupDataLoading(groupId));
    }
  } catch (error) {
    console.log('error', error);
    yield put(actions.fetchGroupDataFailure(groupId, brandId, error));
  }
}

export function* fetchBrandData({ modelId: brandId }) {
  try {
    if (!brandId) return;
    const comparisonData = yield select(selectors.getBrandComparisonData(brandId));

    if (isEmpty(comparisonData)) {
      const response = yield call(api.fetchBrandBenchmarks, brandId);
      const payload = { data: response.meta.stats };

      yield put(actions.fetchBrandDataSuccess(brandId, payload));
    } else {
      yield put(actions.toggleBrandDataLoading(brandId));
    }
  } catch (error) {
    console.log('error', error);
    yield put(actions.fetchBrandDataFailure(brandId, error));
  }
}
