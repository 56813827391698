import React from 'react';
import PropTypes from 'prop-types';

import ContactModalLink from '../../../ContactModalLink';
import { Button, Flex, Heading, Modal, Text } from '../../../mc-ui';

export default function CheckoutFailureModal({ closeModal, isOpen }) {
  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <Modal.Body paddingTop="40px">
        <Flex alignItems="center" direction="column" justifyContent="center">
          <img src="/images/checkout-failure.png" alt="Failure" />

          <Heading
            as="h2"
            fontSize="24px"
            fontWeight="300"
            lineHeight="1.32em"
            css={{ margin: '40px 0 24px 0' }}
          >
            There was an error processing your subscription.
          </Heading>

          <Text margin="0 0 24px 0" css={{ textAlign: 'center' }}>
            Please review the information you entered and try again.<br />
            If you have questions or need additional help, please click below to contact us at hi@mailcharts.com.
          </Text>

          <Flex justifyContent="space-between" width="200px">
            <Button
              as={ContactModalLink}
              variant="primary"
              css={{ height: '40px', lineHeight: '40px', margin: 0, padding: 0, width: '120px' }}
              onClick={closeModal}
            >
              Contact Support
            </Button>

            <Button
              variant="secondary"
              onClick={closeModal}
              css={{ height: '40px', lineHeight: '40px', margin: 0, padding: 0, width: '72px' }}
            >
              Close
            </Button>
          </Flex>
        </Flex>
      </Modal.Body>
    </Modal>
  );
}

CheckoutFailureModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};
