import api from '../lib/api'

export default store => next => action => {
  next(action)

  if (action.type === 'REQUEST_LISTS') {
    const params = action.params || {}
    let lists

    if (action.list_id) {
      params.list_id = action.list_id
    }

    api.get(`/v2/lists`, { params })
      .then(r => {
        lists = r.data
        store.dispatch({ type: 'RECEIVE_LISTS', data: lists })
      })
      .catch(err => {
        console.error(err)
        store.dispatch({ type: 'CATCH_ERROR', error: err});
      })
  }

  if (action.type === 'REQUEST_LISTS_PAGINATION') {
    const link = store.getState().lists.links.next
    if (!link) {
      return
    }

    api.get(link)
      .then(r => {
        const lists = r.data
        store.dispatch({ type: 'RECEIVE_LISTS_PAGINATION', lists })
      })
  }

  if (action.type === 'CREATE_LIST_REQUEST') {
    const { name, emailId } = action
    const email_id = [].concat(emailId || [])
    const data = { name, email_id }

    api.post(`/v2/lists`, data)
      .then((r) => {
        const list = r.data.data[0]
        list.attributes.new = true
        store.dispatch({ type: 'CREATE_LIST_SUCCESS', list })
      })
      .catch(err => {
        console.error(err)
      })
  }

  if (action.type === 'UPDATE_LIST_REQUEST') {
    const { listId, name, description, state } = action
    const data = { name, description, state }

    api.patch(`/v2/lists/${listId}`, data)
      .then((r) => {
        const list = r.data.data[0]
        store.dispatch({ type: 'UPDATE_LIST_SUCCESS', list })
        store.dispatch({ type: 'UPDATE_USER_LISTS', list })
      })
      .catch(err => {
        console.error(err)
      })
  }

  if (action.type === 'ADD_EMAILS_TO_LIST_REQUEST') {
    const { listId, emailId, resolve, reject } = action
    const data = { email_id: emailId }

    api.post(`/v2/lists/${listId}/emails`, data)
      .then((r) => {
        const list = r.data.data[0]
        store.dispatch({ type: 'ADD_EMAILS_TO_LIST_SUCCESS', list })
        store.dispatch({ type: 'UPDATE_USER_LISTS', list })
        resolve && resolve(list)
      })
      .catch(err => {
        console.error(err)
        reject && reject()
      })
  }

  if (action.type === 'DELETE_EMAILS_FROM_LIST_REQUEST') {
    const {
      listId,
      emailId,
      bulk = false,
      resolve,
      reject
    } = action
    const data = { email_id: emailId }

    api({ method: 'delete', url: `/v2/lists/${listId}/emails`, data })
      .then((r) => {
        const list = r.data.data[0]
        store.dispatch({ type: 'DELETE_EMAILS_FROM_LIST_SUCCESS', list })
        store.dispatch({ type: 'UPDATE_USER_LISTS', list })
        if (store.getState().lists.data.length) {
          store.dispatch({ type: 'REQUEST_SEARCH', query: { list_id: list.id } })
        }
        resolve(list)
      })
      .catch(err => {
        reject()
        console.error(err)
      })
  }
}
