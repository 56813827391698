import * as types from './types';

export const receive = payload => ({
  type: types.RECEIVE,
  payload,
});

export const receivePagination = payload => ({
  type: types.RECEIVE_PAGINATION,
  payload,
});

export const refresh = (params = {}, url = null) => ({
  type: types.REFRESH,
  params,
  url,
});

export const request = (params = {}, url = null) => ({
  type: types.REQUEST,
  params,
  url,
});

export const requestPagination = () => ({
  type: types.REQUEST_PAGINATION,
});

export const reset = () => ({
  type: types.RESET,
});
