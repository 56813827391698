import React from 'react';
import PropTypes from 'prop-types';

import DropdownIcon from '../../../mc-ui/icons/DropdownIcon';

import * as S from './styles';

const Dropdown = ({
  children,
  'data-label': dataLabel,
  icon: Icon,
  label,
  onBlur,
  onClick,
  opened,
  ...props
}) => (
  <S.Control tabIndex="0" role="menu" onBlur={onBlur} className={dataLabel}>
    <S.Label opened={opened} onClick={onClick} data-label={dataLabel}>
      <Icon fillOpacity={1} height="16px" width="16px" />
      <span>{label}</span>
      <DropdownIcon fillOpacity={1} height="16px" width="16px" className="indicator" />
    </S.Label>

    {opened && (
      <S.Wrapper {...props}>
        <div>{children}</div>
      </S.Wrapper>
    )}
  </S.Control>
);

Dropdown.defaultProps = {
  opened: false,
};

Dropdown.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  'data-label': PropTypes.string.isRequired,
  icon: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  opened: PropTypes.bool,
};

Dropdown.Item = S.Item;
Dropdown.Text = S.Text;

export default Dropdown;
