import styled from "@emotion/styled";

const RoundedArrowIcon = styled("div")({
  border: '1px dashed #a0afba',
  borderTop: 'none',
  borderLeft: 'none',
  borderRadius: '0 0 10px 0',
  boxSizing: 'border-box',
  height: '162px',
  marginBottom: '15px',
  position: 'relative',
  width: '70px',
  '&::before': {
    border: '10px solid transparent',
    borderBottomColor: '#a0afba',
    boxSizing: 'border-box',
    content: '""',
    display: 'block',
    position: 'absolute',
    right: '-10px',
    top: '-25px'
  }
})

export default RoundedArrowIcon
