import styled from "@emotion/styled";
import { Link as RRLink } from 'react-router';

const Link = styled(RRLink, {
  shouldForwardProp: prop => [
    'border',
    'color',
    'cursor',
    'fontFamily',
    'fontSize',
    'fontWeight',
    'letterSpacing',
    'lineHeight',
    'margin',
    'marginBottom',
    'marginLeft',
    'marginRight',
    'marginTop',
    'padding',
    'textDecoration',
  ].indexOf(prop) === -1
})(({ color = 'blue', theme, ...props }) => ({
  border: 'none',
  color: `${theme.colors[color]} !important`,
  fontFamily: theme.font.family,
  fontSize: '14px',
  fontWeight: '500',
  letterSpacing: '0.02em',
  lineHeight: '1.25em',
  padding: '0',
  textDecoration: 'none',
  ...props,
}));

export default Link;
