import React from 'react';
import styled from "@emotion/styled";
import { keyframes } from '@emotion/css';

import { flashMessageConstants } from '../../../modules/flashMessage';

import CheckCircleIcon from '../../mc-ui/icons/CheckCircleIcon';
import QuestionCircleIcon from '../../mc-ui/icons/QuestionCircleIcon';
import WarningCircleIcon from '../../mc-ui/icons/WarningCircleIcon';
import WarningTriangleIcon from '../../mc-ui/icons/WarningTriangleIcon';

function getColor(messageType, icon = false) {
  switch (messageType) {
    case flashMessageConstants.MESSAGE_TYPE_ERROR:
      return icon ? 'redDark' : 'red1';
    case flashMessageConstants.MESSAGE_TYPE_INFO:
      return 'blue';
    case flashMessageConstants.MESSAGE_TYPE_SUCCESS:
      return icon ? 'greenDark' : 'greenMedium';
    case flashMessageConstants.MESSAGE_TYPE_WARNING:
      return icon ? 'yellowDark' : 'yellowMedium';
    default:
      return 'blue';
  }
}

const slideIn = keyframes({
  '0%': {
    opacity: 0,
    transform: 'translateY(100%)',
  },
  '80%': {
    opacity: 0.8,
    transform: 'translateY(-50%)',
  },
  '100%': {
    opacity: 1,
    transform: 'translateY(0)',
  },
});

export const Wrapper = styled("div")(({ centered, messageType, theme }) => {
  const borderColor = getColor(messageType);
  const navigationContainer = document.querySelector('.jsNavigationContainer');
  const boundingClientRect = navigationContainer
    ? navigationContainer.getBoundingClientRect()
    : null;
  const left = boundingClientRect ? boundingClientRect.x : 0;

  return {
    alignItems: centered ? 'center' : 'flex-start',
    animation: `${slideIn} 0.3s ease-in-out`,
    backgroundColor: theme.colors.white,
    border: `1px solid ${theme.colors[borderColor]}`,
    borderRadius: '4px',
    bottom: '24px',
    boxShadow: `0px 2px 8px ${theme.colors.dark02}`,
    display: 'flex',
    left,
    maxWidth: '560px',
    minWidth: '392px',
    padding: '15px',
    position: 'fixed',
    zIndex: '9994',
  };
});

const IconWrapper = styled("div")(({ messageType, theme }) => {
  const color = getColor(messageType, true);

  return {
    color: theme.colors[color],
    height: '24px',
    fontSize: '24px',
    marginRight: '8px',
    width: '24px',
  };
});

// eslint-disable-next-line react/prop-types
export const Icon = ({ messageType }) => {
  let I = null;
  switch (messageType) {
    case flashMessageConstants.MESSAGE_TYPE_ERROR:
      I = WarningTriangleIcon;
      break;
    case flashMessageConstants.MESSAGE_TYPE_INFO:
      I = QuestionCircleIcon;
      break;
    case flashMessageConstants.MESSAGE_TYPE_SUCCESS:
      I = CheckCircleIcon;
      break;
    case flashMessageConstants.MESSAGE_TYPE_WARNING:
      I = WarningCircleIcon;
      break;
    default:
      break;
  }

  return (
    <IconWrapper messageType={messageType}>
      <I />
    </IconWrapper>
  );
};
