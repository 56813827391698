import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@emotion/react';

import { Flex, Text } from '../../../../../mc-ui';

const TBD = ({ theme }) => (
  <Flex alignItems="center" justifyContent="center" css={{ height: '67px', marginTop: '8px' }}>
    <Text
      css={{
        color: theme.colors.dark06,
        fontFamily: theme.font.family,
        fontSize: '10px',
        fontWeight: 'bold',
        letterSpacing: '0.12em',
        lineHeight: '1.56em',
        textAlign: 'center',
        textTransform: 'uppercase',
      }}
    >
      TBD
    </Text>
  </Flex>
);

TBD.propTypes = {
  theme: PropTypes.shape({}).isRequired,
};

export default withTheme(TBD);
