import colors from '../../theme/colors';

const multiValueLabelStyles = base => ({
  ...base,
  fontFamily: '"Helvetica Neue", Arial, sans-serif',
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: '10px',
  lineHeight: '1.56em',
  letterSpacing: '0.12em',
  color: colors.dark08,
  margin: 0,
  padding: '0 4px',
  textTransform: 'uppercase',
});

export default multiValueLabelStyles;
