import React from 'react';
import { camelizeKeys } from 'humps';
import { take } from 'ramda';

import api from '../../lib/api';

import Carousel from '../ui/Carousel';

async function fetchFeaturedBrands() {
  const response = await api.get('/v3/companies?size=7&featured=true');
  return camelizeKeys(take(7, response.data.data));
}

class FeaturedBrands extends React.PureComponent {
  state = {
    brands: [],
    loading: true,
  };

  componentDidMount() {
    this.request();
  }

  request = async () => {
    const brands = await fetchFeaturedBrands();

    this.setState(() => ({
      brands,
      loading: false,
    }));
  };

  render() {
    const { brands, loading } = this.state;

    return <Carousel collection={brands} loading={loading} type="featuredBrands" />;
  }
}

export default FeaturedBrands;
