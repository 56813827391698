import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  customGroupsActions as actions,
  customGroupsSelectors as selectors,
} from '../../../modules/customGroups';

import PlusIcon from '../../mc-ui/icons/PlusIcon';
import { Box, Button, theme } from '../../mc-ui';

import GroupForm from './GroupForm';

function CreateNewGroupButton({ buttonLabel, closeForm, handleCreate, isFormOpen, openForm }) {
  return (
    <Box inline position="relative" width="unset">
      <Button
        onClick={isFormOpen ? closeForm : openForm}
        css={{
          alignItems: 'center',
          display: 'inline-flex',
          height: '32px',
          justifyContent: 'center',
          margin: '0',
          padding: '0 12px',
        }}
      >
        <PlusIcon />
        {buttonLabel}
      </Button>

      {isFormOpen && (
        <Box position="absolute" right="0" top="0" zIndex={theme.zIndices.dropdown} width="unset">
          <GroupForm handleClose={closeForm} onSubmit={handleCreate} />
        </Box>
      )}
    </Box>
  );
}

CreateNewGroupButton.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  closeForm: PropTypes.func.isRequired,
  handleCreate: PropTypes.func.isRequired,
  isFormOpen: PropTypes.bool.isRequired,
  openForm: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isFormOpen: selectors.isCreateFormOpenSelector(state),
});

const mapDispatchToProps = dispatch => ({
  closeForm: () => dispatch(actions.closeCreateForm()),
  openForm: () => dispatch(actions.openCreateForm()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateNewGroupButton);
