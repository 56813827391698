import React from 'react';
import { withTheme } from '@emotion/react';

import { Box, Text, Tooltip } from '../../../mc-ui';
import QuestionMarkIcon from '../../../mc-ui/icons/QuestionMarkIcon';

// eslint-disable-next-line react/prop-types
const SparklineTooltip = ({ theme }) => {
  return (
    <Box
      css={{
        color: theme.colors.dark06,
        display: 'inline',
        fontSize: '12px',
        lineHeight: '1em',
        marginLeft: '6px',
        width: 'auto',
      }}
    >
      <Tooltip
        id="newsletterCampaignsSentTooltip"
        element={<QuestionMarkIcon fillOpacity={1} />}
        place="right"
        dark
      >
        <Text>
          Sending behavior data is calculated by averaging the number of emails received across all
          registrations for a brand. Emails include only newsletter campaigns (excludes journey and
          classified emails).
        </Text>
      </Tooltip>
    </Box>
  );
};

SparklineTooltip.defaultProps = {};

SparklineTooltip.propTypes = {};

export default withTheme(SparklineTooltip);
