import React from 'react'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import api from '../lib/api'

import Container from './Container'

const INITIAL_STATE = {
    loading: false,
    success: false,
    error: null,
    reset: null,
    password: '',
    header: 'Forget something?',
    submit: 'Request password reset',
    email: '',
  }
class Passwords extends React.Component {
  state = INITIAL_STATE
  componentDidUpdate() {
    if (this.props.user.data.length) this.props.router.push('/')
  }
  UNSAFE_componentWillReceiveProps() {
    this.setState(INITIAL_STATE)
  }
  handleChange = (event) => {
    event.preventDefault()
    this.setState({ [event.target.id]: event.target.value })
  }
  handleSubmit = (event) => {
    event.preventDefault()
    this.setState({ error: null, success: false })
    let options = {
      email: (this.state.email || '').toLowerCase(),
      password: this.state.password,
      hash: this.props.params.token || this.props.router.location.query.hash
    }
    let fn = this.state.reset
      ? api.post('/v2/password-resets/update', options)
      : api.post('/v2/password-resets/create', options)
    fn
      .then(r =>  {
        let header = this.state.reset ? 'All systems go!' : 'Check your inbox!'
        this.setState({ success: true, header })
        if (this.state.reset)
          api.get('/v2/user')
          .then(r => {
            // redirect back to the app!
            setTimeout(() => {
              this.props.dispatch({ type: 'RECEIVED_USER', data: r.data })
            }, 1000)
          })
      })
      .catch(err => {
        this.setState({ error: 'There was an error resetting your password. Please contact hi@mailcharts.com for help.' })
      })
  }
  componentDidMount() {
    if (!this.props.params.token && !this.props.router.location.query.hash) {
      // manually parse the URL as the location.params are replacing `+` with a space ' '
      let email = this.props.router.location.search.replace('?', '').split('&')
        .reduce((m, s) => {
          let a = s.split('=')
          if (a[0] == 'email') m = decodeURIComponent(a[1])
          return m
        }, '')
      return this.setState({ email })
    }
    this.setState({
      header: 'Reset your password',
      submit: 'Update',
      loading: true,
      reset: true
    })
    let token = this.props.params.token || this.props.location.query.hash
    api.get(`/password-resets/${token}`)
    .then(resp => {
      this.setState({ loading: false, email: resp.data.data.attributes.email })
    })
    .catch(err => {
      this.setState({ error: 'This token is invalid.' })
    })
  }
  render() {
    return (
      <div className='pure-g'>
        <div
          style={{
            background: `url('/signup.svg')`,
            backgroundSize: 'cover',
            padding: '100px 0',
            textAlign: 'center',
          }}
          className='pure-u-1'
        >
          <h1
            style={{
              color: '#ffffff',
            }}
          >
            {this.state.header}
          </h1>
          <h2
            style={{
              color: '#ffffff',
            }}
          >
            {this.state.reset ? 'Update your password now' : 'Enter your email address to reset your password'}
          </h2>
        </div>
        <div
          className='pure-g'
          style={{
            width: '100%',
            margin: '0 auto',
          }}
        >
          <div
            className='pure-u-1'
            style={{
              textAlign: 'center',
              marginTop: -75,
              padding: '0 2%',
            }}
          >
            <Container
              style={{ margin: '0 auto', maxWidth: 400, padding: 50, }}
            >
            {this.state.success ?
              <div className='pure-u-1'>
                <div style={{ background: '#7ECA5B', padding: 10, color: '#FFF', borderRadius: 3 }}>
                  {this.state.reset
                  ? <p style={{ color: '#fff' }}>You're all set!</p>
                  : <p style={{ color: '#fff' }}>Please check your email for a link to complete updating your password.</p>
                  }
                </div>
              </div>
            : null}
            {this.state.error ?
              <div className='pure-u-1'>
                <div style={{ color: '#fff', background: '#DD2325', padding: 10, color: '#FFF', borderRadius: 3 }}>
                  <p style={{ color: '#fff' }}>{this.state.error}</p>
                </div>
              </div>
            : null}
            {this.state.success || this.state.error ? null :
            <form className='pure-form' onSubmit={this.handleSubmit}>
              <fieldset className='pure-group'>
                <input
                  id='email'
                  type='email'
                  style={{ textAlign: 'center' }}
                  className='pure-u-1'
                  disabled={this.state.reset}
                  value={this.state.email}
                  placeholder='Enter your email address'
                  onChange={this.handleChange}
                />
                {this.state.reset ?
                <input
                  id='password'
                  type='password'
                  style={{ textAlign: 'center' }}
                  className='pure-u-1'
                  value={this.state.password}
                  onChange={this.handleChange}
                  placeholder='Enter new password'
                /> : null}
              </fieldset>
              <input type='submit' className='pure-button pure-button-primary pure-u-1' value={this.state.submit} />
            </form>}
            <div className='pure-u-1'>
              <p
                style={{
                  fontSize: `${14/16}em`,
                }}
              >
              {this.state.reset
              ? <Link style={{ color: '#3285ce' }} to='/passwords/forgot'>Request new password</Link>
              : <Link style={{ color: '#3285ce' }} to='/signin'>Sign in.</Link>
              }
              </p>
              <p
                style={{
                  fontSize: `${14/16}em`,
                }}
              >
                <Link style={{ color: '#3285ce' }} to='/signup'>New to MailCharts? Sign up.</Link>
              </p>
            </div>
            </Container>
          </div>
        </div>
      </div>
    )
  }
}

const s = (state) => ({ user: state.user })
export default connect(s)(Passwords)
