import styled from "@emotion/styled";

export const Toggle = styled("div")({
  display: 'flex',
  width: '152px',
});

Toggle.Button = styled("button")(({ active, theme }) => ({
  alignItems: 'center',
  backgroundColor: active ? theme.colors.dark1 : theme.colors.white,
  border: '1px solid transparent',
  borderColor: active ? theme.colors.dark1 : theme.colors.dark5,
  color: active ? theme.colors.white : theme.colors.dark08,
  cursor: active ? 'default' : 'pointer',
  flex: '1',
  fontFamily: theme.font.family,
  fontSize: '14px',
  fontWeight: '500',
  height: '32px',
  justifyContent: 'center',
  letterSpacing: '0.02em',
  lineHeight: '32px',
  margin: '0',
  padding: '0',
  '&:first-of-type': {
    borderRadius: '4px 0 0 4px',
  },
  '&:last-child': {
    borderRadius: '0 4px 4px 0',
  },
}));

export const Divider = styled("hr")(({ theme }) => ({
  border: 'none',
  borderTop: `1px solid ${theme.colors.dark5}`,
}));
