import React from 'react';
import PropTypes from 'prop-types';

import { TYPES } from '../../../../../modules/shareButton/constants';
import ShareIcon from '../../../../mc-ui/icons/ShareIcon';
import ShareButton from '../../../../ui/ShareButton';

export default function ShareJourneyButton({ journey, variant }) {
  return (
    <ShareButton
      css={{ fontSize: '24px', height: '24px', marginLeft: '8px', width: '24px' }}
      model={journey}
      type={TYPES.journey}
      variant={variant}
    >
      <ShareIcon />
    </ShareButton>
  );
}

ShareJourneyButton.defaultProps = {
  variant: 'none',
};

ShareJourneyButton.propTypes = {
  journey: PropTypes.shape({}).isRequired,
  variant: PropTypes.string,
};
