/** @jsx jsx */
import React from 'react';
import { PropTypes } from 'prop-types';
import { jsx, withTheme } from '@emotion/react';

const Item = ({ active, big, children, name, setActive, theme }) => {
  const isActive = active === name;

  const handleOnClick = event => {
    event.preventDefault();
    setActive(name);
  };

  return (
    <a
      href="#changeTab"
      onClick={handleOnClick}
      css={{
        borderBottom: isActive ? `2px solid ${theme.colors.blue}` : 'none',
        color: isActive ? theme.colors.dark1 : theme.colors.dark04,
        display: 'flex',
        fontFamily: theme.font.family,
        fontSize: big ? '24px' : '12px',
        fontWeight: big ? '300' : 'bold',
        letterSpacing: big ? '0.02em' : '0.08em',
        lineHeight: big ? '1.32em' : '1.56em',
        marginLeft: big ? '48px' : '32px',
        paddingBottom: big ? '14px' : '6px',
        textDecoration: 'none',
        textTransform: big ? 'initial' : 'uppercase',
        '&:first-of-type': {
          marginLeft: 0,
        },
      }}
    >
      {children}
    </a>
  );
};

Item.defaultProps = {
  big: false,
};

Item.propTypes = {
  active: PropTypes.string.isRequired,
  big: PropTypes.bool,
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  setActive: PropTypes.func.isRequired,
  theme: PropTypes.shape({}).isRequired,
};

export default withTheme(Item);
