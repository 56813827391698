/** @jsx jsx */
import PropTypes from 'prop-types';
import { jsx, withTheme } from '@emotion/react';

import { Flex, Heading, Text } from '../../mc-ui';

import NegativeIcon from './NegativeIcon';
import NeutralIcon from './NeutralIcon';
import PositiveIcon from './PositiveIcon';

const Item = ({
  comparison,
  comparisonLabel,
  icon,
  subtitle,
  theme,
  title,
  value,
  valueSuffix,
  width,
}) => {
  let ProgressIcon = NeutralIcon;
  if (comparison < 0) {
    ProgressIcon = NegativeIcon;
  } else if (comparison > 0) {
    ProgressIcon = PositiveIcon;
  }

  return (
    <Flex direction="column" marginBottom="26px" width={width}>
      <Heading
        as="h5"
        css={{
          color: theme.colors.dark06,
          fontSize: '10px',
          fontWeight: 'bold',
          letterSpacing: '0.12em',
          lineHeight: '1.56em',
          margin: 0,
          textTransform: 'uppercase',
        }}
      >
        {title}
        {subtitle && <br />}
        {subtitle}
      </Heading>
      <Flex alignItems="center">
        {icon}
        <Text
          color="dark1"
          fontSize="24px"
          fontWeight="300"
          lineHeight="1.32em"
          letterSpacing="0.02em"
          css={{ marginTop: '1px', whiteSpace: 'nowrap' }}
        >
          {value}
          {valueSuffix && (
            <span
              css={{
                fontSize: '12px',
                lineHeight: '12px',
                marginLeft: '4px'
              }}
            >
              {valueSuffix}
            </span>
          )}
        </Text>
        {comparison !== null && (
          <Flex alignItems="center">
            <Text
              color="dark1"
              fontSize="12px"
              fontWeight="500"
              height="16px"
              letterSpacing="0.02em"
              lineHeight="12px"
            >
              <span
                css={{
                  alignItems: 'center',
                  display: 'inline-flex',
                  margin: '0 0 0 8px',
                }}
              >
                <ProgressIcon height="16px" width="16px" />
                {comparison !== 0 && (
                  <span
                    css={{
                      display: 'inline-block',
                      marginLeft: '6px'
                    }}
                  >
                    {comparison.toFixed(2)}%{comparisonLabel}
                  </span>
                )}
              </span>
            </Text>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

Item.defaultProps = {
  comparison: null,
  comparisonLabel: null,
  icon: null,
  subtitle: null,
  valueSuffix: null,
  width: null,
};

Item.propTypes = {
  comparison: PropTypes.number,
  comparisonLabel: PropTypes.string,
  icon: PropTypes.node,
  subtitle: PropTypes.string,
  theme: PropTypes.shape({}).isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  valueSuffix: PropTypes.string,
  width: PropTypes.string,
};

export default withTheme(Item);
