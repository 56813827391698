/* eslint-disable react/forbid-prop-types */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Label from './Label';
import Circle from './Radio/Circle';

class Radio extends PureComponent {
  field = {};

  handleLabelClick = event => {
    event.preventDefault();
    this.field.click();
  };

  render() {
    const { input, id, label, meta, render, css, style, ...rest } = this.props;

    return (
      <Circle css={{ ...css, ...style }}>
        <input
          {...rest}
          {...input}
          id={id}
          type="radio"
          onChange={event => {
            if (rest.onChange) {
              rest.onChange(event);
            }
            if (input.onChange) input.onChange(event);
            if (input.onBlur) input.onBlur(event);
          }}
          ref={i => {
            this.field = i;
          }}
        />
        <Label htmlFor={id} onClick={this.handleLabelClick}>
          {label}
        </Label>
      </Circle>
    );
  }
}

Radio.defaultProps = {
  input: {},
  meta: null,
  render: null,
  css: {},
  style: {},
};

Radio.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  input: PropTypes.shape({}),
  meta: PropTypes.any,
  render: PropTypes.any,
  css: PropTypes.shape({}),
  style: PropTypes.shape({}),
};

export default Radio;
