import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'ramda';

import { customGroupsActions, customGroupsSelectors } from '../../../modules/customGroups';

import CheckSmallIcon from '../../mc-ui/icons/CheckSmallIcon';
import CrossSmallIcon from '../../mc-ui/icons/CrossSmallIcon';
import PlusIcon from '../../mc-ui/icons/PlusIcon';
import { Box, Button, Flex, Heading, Loader, theme } from '../../mc-ui';

import * as S from '../styles';
import { ascendBy, descendBy, getPositions } from '../helpers';

import GroupSelector from './GroupSelector';
import NoGroups from './NoGroups';
import SortSelect from './SortSelect';

class Groups extends React.Component {
  constructor(props) {
    super(props);

    const { parentEl } = props;
    const { bottom, left, marginLeft, right, top } = getPositions(parentEl);

    this.state = {
      bottom,
      left,
      marginLeft,
      sortedBy: 'updated_at',
      sortedGroups: [],
      right,
      top,
    };
  }

  componentDidMount() {
    const { fetchTeamGroups } = this.props;

    fetchTeamGroups();

    const groups = this.props.userGroups;
    this.setSortedGroups(groups);
  }

  componentDidUpdate(prevProps) {
    const { groupsOwner } = this.props;

    if (groupsOwner === 'me' && prevProps.userGroupsLoading && !this.props.userGroupsLoading) {
      const groups = this.props.userGroups;
      this.setSortedGroups(groups);
    } else if (
      groupsOwner === 'team' &&
      prevProps.teamGroupsLoading &&
      !this.props.teamGroupsLoading
    ) {
      const groups = this.props.teamGroups;
      this.setSortedGroups(groups);
    }
  }

  setSortedGroups = groups => {
    const sortedGroups = groups.sort(descendBy('updated_at'));
    this.setState({ sortedGroups, sortedBy: 'updated_at' });
  };

  handleSort = sortedBy => {
    let { sortedGroups } = this.state;

    switch (sortedBy) {
      case 'asc':
        sortedGroups = sortedGroups.sort(ascendBy('name'));
        break;
      case 'desc':
        sortedGroups = sortedGroups.sort(descendBy('name'));
        break;
      case 'updated_at':
        sortedGroups = sortedGroups.sort(descendBy('updated_at'));
        break;
      default:
        sortedGroups = sortedGroups.sort(ascendBy('name'));
        break;
    }
    this.setState({ sortedGroups, sortedBy });
  };

  showUserGroups = event => {
    event.preventDefault();
    this.props.fetchUserGroups();
    this.props.onUpdateOwner('me');
  };

  showTeamGroups = event => {
    event.preventDefault();
    this.props.fetchTeamGroups();
    this.props.onUpdateOwner('team');
  };

  render() {
    const {
      brands,
      groupsOwner,
      isValidTeam,
      onClose,
      onCloseGroupsForm,
      onOpenCreateForm,
      userGroupsLoading,
      teamGroupsLoading,
    } = this.props;
    const { bottom, left, marginLeft, sortedBy, sortedGroups, right, top } = this.state;

    const loading =
      (groupsOwner === 'me' && userGroupsLoading) || (groupsOwner === 'team' && teamGroupsLoading);

    return (
      <Box
        backgroundColor="white"
        borderRadius="4px"
        bottom={bottom}
        boxShadow={`0px 2px 8px ${theme.colors.dark02}`}
        left={left}
        marginLeft={marginLeft}
        padding="24px 0"
        position="absolute"
        right={right}
        top={top}
        width="384px"
        zIndex={theme.zIndices.dropdown}
      >
        <Box padding="0 24px" position="relative">
          <Heading
            as="h3"
            fontSize="18px"
            fontWeight="500"
            lineHeight="24px"
            css={{ marginBottom: '16px' }}
          >
            Add this brand to group
          </Heading>

          <Button
            onClick={() => {
              onClose();
              onCloseGroupsForm();
            }}
            variant="none"
            css={{
              margin: '0',
              padding: '0',
              position: 'absolute',
              right: '24px',
              top: '0',
            }}
          >
            <CrossSmallIcon />
          </Button>
        </Box>

        <Flex
          borderBottom={`1px solid ${theme.colors.dark01}`}
          justifyContent={isValidTeam ? 'space-between' : 'flex-end'}
          marginBottom="16px"
          padding="0 24px 16px 24px"
        >
          {isValidTeam && (
            <S.ButtonGroup>
              <S.Button active={groupsOwner === 'me'} onClick={this.showUserGroups}>
                Mine
              </S.Button>
              <S.Button active={groupsOwner === 'team'} onClick={this.showTeamGroups}>
                Team
              </S.Button>
            </S.ButtonGroup>
          )}

          <SortSelect handleChange={this.handleSort} value={sortedBy} />
        </Flex>

        <Box padding="0 24px">
          <Heading
            as="h5"
            color="dark06"
            fontSize="12px"
            fontWeight="bold"
            letterSpacing="0.08em"
            lineHeight="2em"
            css={{ marginBottom: '16px' }}
          >
            Select Groups to Update
          </Heading>

          {loading ? (
            <Flex alignItems="center" justifyContent="center" height="248px">
              <Loader />
            </Flex>
          ) : (
            <Box height="248px" css={{ overflowY: 'auto' }}>
              {isEmpty(sortedGroups) ? (
                <NoGroups />
              ) : (
                sortedGroups.map(group => (
                  <GroupSelector
                    key={`AddToGroupButton-GroupSelector-${group.id}`}
                    brands={brands}
                    group={group}
                  />
                ))
              )}
            </Box>
          )}

          <Flex justifyContent="space-between" paddingTop="8px">
            <Button
              variant="secondary"
              css={{
                alignItems: 'center',
                display: 'flex',
                height: '32px',
                justifyContent: 'center',
                margin: '0',
                padding: '0 12px',
              }}
              onClick={() => {
                onCloseGroupsForm();
                onOpenCreateForm();
              }}
            >
              <PlusIcon />
              Create new group
            </Button>

            <Button
              variant="primary"
              css={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                height: '32px',
                margin: '0',
                padding: '0 12px',
              }}
              onClick={() => {
                onClose();
                onCloseGroupsForm();
              }}
            >
              <CheckSmallIcon />
              Done
            </Button>
          </Flex>
        </Box>
      </Box>
    );
  }
}

Groups.propTypes = {
  brands: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fetchTeamGroups: PropTypes.func.isRequired,
  fetchUserGroups: PropTypes.func.isRequired,
  groupsOwner: PropTypes.string.isRequired,
  isValidTeam: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCloseGroupsForm: PropTypes.func.isRequired,
  onOpenCreateForm: PropTypes.func.isRequired,
  onUpdateOwner: PropTypes.func.isRequired,
  parentEl: PropTypes.shape({}).isRequired,
  teamGroups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  teamGroupsLoading: PropTypes.bool.isRequired,
  userGroups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  userGroupsLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  isValidTeam: customGroupsSelectors.isValidTeamSelector(state),
  teamGroups: customGroupsSelectors.teamGroupsDataReducer(state),
  teamGroupsLoading: customGroupsSelectors.isTeamGroupsLoadingReducer(state),
  userGroups: customGroupsSelectors.userGroupsDataReducer(state),
  userGroupsLoading: customGroupsSelectors.isUserGroupsLoadingReducer(state),
});

const mapDispatchToProps = dispatch => ({
  fetchTeamGroups: () => dispatch(customGroupsActions.fetchTeamGroupsRequest()),
  fetchUserGroups: () => dispatch(customGroupsActions.fetchUserGroupsRequest()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Groups);
