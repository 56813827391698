import React from 'react';

function SvgTwitterIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 17" fill="none" {...props}>
      <path
        d="M0 14.826c2.167.166 4.167-.334 6-1.75-1.917-.167-3.167-1.084-3.833-2.917.666.083 1.25.083 1.833-.083C1.667 9.492.667 7.326.75 5.909c.333.083.583.25.833.333.334.084.584.084.917.084-1-.75-1.583-1.75-1.75-3-.083-.917.083-1.75.583-2.584 2.25 2.667 5 4.167 8.5 4.417 0-.25-.083-.5-.083-.667-.083-1.666.583-3 2-3.916 1.5-.917 3.583-.75 4.833.583.167.167.334.167.5.167a6.735 6.735 0 002.25-.917c0 .5-.666 1.5-1.666 2.25.333-.083.583-.167.833-.167l.75-.25.75-.25v.084c-.333.416-.667.833-1 1.166-.25.334-.583.584-.917.834-.083.083-.166.166-.166.25.083 2.916-.834 5.5-2.5 7.833-1.75 2.333-4.084 3.75-6.917 4.333-2.167.417-4.25.25-6.333-.5-.75-.333-1.5-.75-2.167-1.166z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgTwitterIcon;
