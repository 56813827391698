/** @jsx jsx */
import { jsx } from '@emotion/react';
import React from 'react';
import PropTypes from 'prop-types';

const Row = ({ children, css: cssProp, header, ...props }) => (
  <tr
    css={{
      borderBottom: header ? '2px solid #8D95A3' : '1px solid #CFD1D6',
      color: 'inherit',
      display: 'table-row',
      outline: '0',
      verticalAlign: 'middle',
      ...cssProp,
    }}
    {...props}
  >
    {React.Children.map(children, child => React.cloneElement(child, { as: header ? 'th' : 'td' }))}
  </tr>
);

Row.defaultProps = {
  css: {},
  header: false,
};

Row.propTypes = {
  children: PropTypes.node.isRequired,
  css: PropTypes.shape({}),
  header: PropTypes.bool,
};

export default Row;
