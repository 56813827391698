/* global window */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import planName from '../../lib/planName';

import PaymentLink from '../PaymentLink';
import Container from '../Container';

import ContactModalLink from '../ContactModalLink';
import { topics }  from '../ContactUs';

import ContactUs from './ContactUs';
import HeaderContainer from './ui/HeaderContainer';
import Label from './ui/Label';
import Title from './ui/Title';

import JourneysContainer from './ui/JourneysContainer';

import CompareIcon from './icons/CompareIcon';
import GroupsAnalyzesIcon from './icons/GroupsAnalyzesIcon';
import UnlockAccessIcon from './icons/UnlockAccessIcon';

const containerStyle = {
  display: 'flex',
  flexDirection: 'column',
  margin: '48px 0',
  padding: '40px',
};

class UpgradeGroups extends Component {
  componentDidMount() {
    window.analytics.track('User restricted', { type: 'groups' });
  }

  handlePaywallClicked = event => {
    event.preventDefault();
    const type = 'groups';

    window.analytics.track('Paywall clicked', { type });
  };

  renderCurrentPlan = () => {
    const [user] = this.props.user.data;

    if (user.attributes.plan_id === 'free') return null;

    const currentPlan = planName(user.attributes.plan_id);
    return <span>&mdash; you&rsquo;re currently subscribed to {currentPlan} &mdash;</span>;
  };

  render() {
    const { model } = this.props;
    const { approximate_companies: approximateCompanies } = model.attributes;

    return (
      <Container style={containerStyle}>
        <HeaderContainer
          style={{
            backgroundPosition: 'right center',
            backgroundImage: 'url(/upgrade-groups-bg.svg)',
            backgroundSize: 'auto 325px',
            maxHeight: '400px',
          }}
        >
          <Label>Pro</Label>

          <Title>
            Upgrade to a Business plan to access industry data for {model.attributes.name}
          </Title>

          <p style={{ maxWidth: 500 }}>
            Upgrade to a Business plan {this.renderCurrentPlan()} for unlimited access to all{' '}
            {approximateCompanies} companies. Super-power your competitive insights and planning
            with industry access.
          </p>

          <p style={{ display: 'inline-block' }}>
            <PaymentLink
              className="pure-button pure-button-primary"
              style={{ fontSize: `${13 / 16}em`, lineHeight: '1em', padding: '1em 1.8em' }}
              to="/manage/plan"
              planId="bQkuw"
              onClick={this.handlePaywallClicked}
            >
              Upgrade to Business
            </PaymentLink>
          </p>
          <p style={{ fontSize: `${12/16}em`, maxWidth: 500 }}>
            <strong>Interested in a free month?</strong> Schedule some time
            to chat with us and we'll unlock your account for one month.{' '}
            <ContactModalLink topic={topics.QUESTION}>
              Contact Support
            </ContactModalLink>
          </p>
        </HeaderContainer>

        <JourneysContainer>
          <Title>Here’s what’s included</Title>
          <div className="pure-g gutters" style={{ marginTop: '40px' }}>
            <div className="pure-u-1 pure-u-md-1-3">
              <div
                className="pure-u-1"
                style={{ textAlign: 'center', margin: '0 auto', background: '#F3F5F7' }}
              >
                <GroupsAnalyzesIcon height="99px" style={{ margin: '20px 0' }} />
              </div>
              <h4>Deal or no deal</h4>
              <p>
                Analyze industries to discover how often brands discount and what types of
                promotions are run most often.
              </p>
            </div>

            <div className="pure-u-1 pure-u-md-1-3">
              <div
                className="pure-u-1"
                style={{ textAlign: 'center', margin: '0 auto', background: '#F3F5F7' }}
              >
                <CompareIcon height="99px" style={{ margin: '20px 0' }} />
              </div>
              <h4>Powerful comparisons</h4>
              <p>
                Compare brands side-by-side to establish vital benchmarks like sending behavior,
                discount strategies, and content.
              </p>
            </div>

            <div className="pure-u-1 pure-u-md-1-3">
              <div
                className="pure-u-1"
                style={{ textAlign: 'center', margin: '0 auto', background: '#F3F5F7' }}
              >
                <UnlockAccessIcon height="99px" style={{ margin: '20px 0' }} />
              </div>
              <h4>Unlock thousands of brands</h4>
              <p>
                Industry access allows for viewing thousands of brands’ user journeys, reports and
                historical emails.
              </p>
            </div>
          </div>
        </JourneysContainer>
        <ContactUs />
      </Container>
    );
  }
}

UpgradeGroups.propTypes = {
  model: PropTypes.shape({}).isRequired,
  user: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        attributes: PropTypes.shape({ plan_id: PropTypes.string }),
      }),
    ),
  }).isRequired,
};

const mapStateToProps = state => ({
  user: state.user,
});

export default connect(mapStateToProps)(UpgradeGroups);
