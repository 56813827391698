import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.css';

import RecipientColor from './RecipientColor'

class Recipient extends React.Component {
  static contextTypes = {
    router: PropTypes.object
  }
  state = {
    color: '#646464'
  }
  handleClick = (event) => {
    event.preventDefault()
    if (this.props.visible === false) return
    const address_id = this.props.address_id
    const route = this.props.route || `/companies/${this.props.company_id}/emails?segment_id=${address_id}`
    this.context.router.push(route)
  }
  componentDidMount() {
    let color = '#646464'
    let desc = this.props.address_description;
    if (/purchaser/gi.test(desc)) color = '#21D6C5'
    else if (/abandon/gi.test(desc)) color = '#9E6ACC'
    this.setState({ color, desc })
  }
  render() {
    return (
      <div className='pure-u-1'>
        <div
          onClick={this.handleClick}
          style={{
            cursor: this.props.visible ? 'pointer' : null,
            color: '#A0AEBA',
          }}
        >
            {(this.props.duplicates || []).length > 0
            ?
            <div>
              <div style={{ background: 'white', display: 'inline-block', borderRadius: '50%', width: 20, height: 20, marginRight: 10, position: 'relative', top: 3 }}>
                <RecipientColor {...this.props} />
              </div>
              <div style={{ display: 'inline-block' }}>
                <h4 style={{ color: this.props.visible ? '#656565' : '#D4D9DE', margin: '0 auto', fontSize: '.667em', whiteSpace: 'nowrap' }}>Many recipients</h4>
              </div>
            </div>
            :
            <div>
              <div style={{ background: 'white', display: 'inline-block', borderRadius: '50%', width: 20, height: 20, marginRight: 5, position: 'relative', top: 7 }}>
                <RecipientColor {...this.props} />
              </div>
              <div style={{ display: 'inline-block' }}>
                <h4 style={{ color: this.props.visible ? '#656565' : '#D4D9DE', margin: '0 auto', fontSize: '.667em', whiteSpace: '' }}>{this.state.desc}</h4>
              </div>
            </div>
            }
        </div>
      </div>
    )
  }
}

export default Recipient
