import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';

import { brandsConstants as C } from '../../../../modules/brands';

import { Box, Button, Flex, Loader } from '../../../mc-ui';

export default function LoadMoreButton({ collection, handlePagination, paginating }) {
  const loadTotal =
    collection.meta.total - collection.data.length < C.MAX_PER_PAGE
      ? collection.meta.total - collection.data.length
      : C.MAX_PER_PAGE;

  if (!collection.links.next || loadTotal === 0) return null;

  return (
    <Box>
      <Flex alignItems="center" height="24px" justifyContent="center">
        {paginating && <Loader />}
      </Flex>

      <Button
        css={{ height: '32px', margin: '0', padding: '0', width: '178px' }}
        disabled={paginating}
        onClick={handlePagination}
        variant="secondary"
      >
        Load more {loadTotal} {pluralize('result', loadTotal)}
      </Button>
    </Box>
  );
}

LoadMoreButton.propTypes = {
  collection: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
    links: PropTypes.shape({
      next: PropTypes.string,
    }),
  }).isRequired,
  handlePagination: PropTypes.func.isRequired,
  paginating: PropTypes.bool.isRequired,
};
