import React from 'react';
import PropTyps from 'prop-types';
import { withTheme } from '@emotion/react';

import { Heading as MCHeading } from '../mc-ui';

const Heading = ({ children, theme }) => (
  <MCHeading
    as="h5"
    css={{
      color: theme.colors.dark06,
      fontWeight: '700',
      lineHeight: '1.56em',
      letterSpacing: '0.08em',
      margin: '0 0 8px 0',
    }}
  >
    {children}
  </MCHeading>
);

Heading.propTypes = {
  children: PropTyps.node.isRequired,
  theme: PropTyps.shape({}).isRequired,
};

export default withTheme(Heading);
