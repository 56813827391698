import React from 'react';
import styled from "@emotion/styled";

import {
  BrowseAbandonerIcon,
  CartAbandonerIcon,
  CustomerIcon,
  EmailSubscriberIcon,
  PurchaserIcon,
} from '../../Journeys/icons';

const Icon = ({ className, type }) => {
  switch (type) {
    case 'browse_abandoner':
      return <BrowseAbandonerIcon className={className} height="32" width="32" />;
    case 'cart_abandoner':
      return <CartAbandonerIcon className={className} height="32" width="32" />;
    case 'custom':
      return <CustomerIcon className={className} height="32" width="32" />;
    case 'email_subscriber':
      return <EmailSubscriberIcon className={className} height="32" width="32" />;
    case 'purchaser':
      return <PurchaserIcon className={className} height="32" width="32" />;
    default:
      return <span />;
  }
};

const JourneyTypeIcon = styled(Icon)({
  left: '16px',
  position: 'absolute',
  top: '16px',
});

export default JourneyTypeIcon;
