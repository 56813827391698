import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

import { Box, Flex, Button, Heading } from '../../../../mc-ui';
import ArrowRightIcon from '../../../../mc-ui/icons/ArrowRightIcon';

import BrandLogo from '../../../BrandLogo';

import DataPoints from './DataPoints';

const formatType = type =>
  type
    .split('_')
    .map(word => `${word.charAt(0).toUpperCase()}${word.slice(1)}`)
    .join(' ');

const JourneyInfo = ({ model }) => {
  const [brand] = model.relationships.companies.data;

  return (
    <div>
      <Flex alignItems="center" marginBottom="16px">
        <BrandLogo
          brandId={brand.id}
          css={{ height: '40px', marginRight: '10px', width: '40px' }}
        />

        <Heading as="h3" css={{ fontWeight: '300', lineHeight: '1.32em' }}>
          {brand.attributes.name}
        </Heading>
      </Flex>

      <Heading as="h2" css={{ fontWeight: '300', lineHeight: '1.32em', marginBottom: '16px' }}>
        {formatType(model.attributes.type)}
      </Heading>

      <Box marginBottom="40px">
        <DataPoints
          durationDays={model.meta.durationDays.value}
          endDate={model.attributes.endDate}
          intervalDays={model.meta.intervalDays.value}
          startDate={model.attributes.startDate}
          triggeredEmails={model.meta.triggeredEmails.value}
          totalEmails={model.meta.totalEmails.value}
        />
      </Box>

      <Button
        as={Link}
        to={`/companies/${brand.id}/journeys/${model.id}`}
        css={{
          alignItems: 'center',
          display: 'inline-flex',
          height: '34px',
          justifyContent: 'space-between',
          padding: '0 15px',
          width: '142px',
        }}
      >
        <ArrowRightIcon fillOpacity={1} height="20px" width="20px" /> View journey
      </Button>
    </div>
  );
};

JourneyInfo.propTypes = {
  model: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.shape({
      firstActivityAt: PropTypes.string,
      type: PropTypes.string,
      lastEmailSentAt: PropTypes.string,
    }),
    meta: PropTypes.shape({
      durationDays: PropTypes.shape({
        value: PropTypes.number,
      }),
      intervalDays: PropTypes.shape({
        value: PropTypes.number,
      }),
      totalEmails: PropTypes.shape({
        value: PropTypes.number,
      }),
    }),
    relationships: PropTypes.shape({
      companies: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape({})),
      }),
    }),
  }).isRequired,
};

export default JourneyInfo;
