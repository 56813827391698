import styled from "@emotion/styled";
import { keyframes } from '@emotion/css';

const rotation = keyframes({
  from: {
    transform: 'rotate(0)',
  },
  to: {
    transform: 'rotate(359deg)',
  },
})

const Spinner = styled("div")(({ color = 'dark08', half = false, size, theme }) => {
  const borderWidth = Number(size.toString().replace('px', '')) / 5;

  return {
    animation: `${rotation} 1s linear infinite`,
    border: `${borderWidth}px solid ${theme.colors[color]}`,
    borderLeftColor: 'transparent',
    borderRadius: '50%',
    borderRightColor: half ? 'transparent' : theme.colors[color],
    boxSizing: 'border-box',
    height: size,
    width: size,
  };
});

export default Spinner;
