import React from 'react';
import PropTypes from 'prop-types';

import Badge from '../Badge';

import Cell from './Cell';

const IndexCell = ({ index, page, pageSize, badgeProps, ...props }) => (
  <Cell {...props} width="30px">
    <Badge {...badgeProps}>{pageSize * (page - 1) + (index + 1)}</Badge>
  </Cell>
);

IndexCell.defaultProps = {
  badgeProps: { backgroundColor: 'dark5' },
};

IndexCell.propTypes = {
  badgeProps: PropTypes.shape({}),
  index: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
};

export default IndexCell;
