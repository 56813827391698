import React from 'react';
import PropTypes from 'prop-types';

import { Button as MCButton, theme } from '../../mc-ui';
import CheckCircleIcon from '../../mc-ui/icons/CheckCircleIcon';
import RadioDefaultIcon from '../../mc-ui/icons/RadioDefaultIcon';
import RadioSelectedIcon from '../../mc-ui/icons/RadioSelectedIcon';

export const PlanChecker = ({ checked, onClick: onClickProp, planName, selected }) => {
  let Icon = RadioDefaultIcon;
  if (checked) {
    Icon = CheckCircleIcon;
  } else if (selected) {
    Icon = RadioSelectedIcon;
  }

  const onClick = checked || selected ? () => {} : onClickProp;

  return (
    <MCButton
      onClick={onClick}
      variant="none"
      css={{
        alignItems: 'center',
        color: theme.colors.dark1,
        cursor: checked || selected ? 'default' : 'pointer',
        display: 'inline-flex',
        fontSize: '18px',
        fontWeight: '500',
        height: '24px',
        lineHeight: '24px',
        margin: '0',
        padding: '0',
        '& > svg': {
          color: theme.colors.blue,
          height: '24px',
          marginRight: '8px',
          width: '24px',
        },
      }}
    >
      <Icon />
      {planName}
    </MCButton>
  );
};

PlanChecker.defaultProps = {
  checked: false,
  onClick: () => {},
  selected: false,
};

PlanChecker.propTypes = {
  checked: PropTypes.bool,
  onClick: PropTypes.func,
  planName: PropTypes.string.isRequired,
  selected: PropTypes.bool,
};

export const CancelButton = props => (
  <MCButton
    variant="secondary"
    css={{
      color: theme.colors.red1,
      height: '32px',
      lineHeight: '32px',
      margin: '0',
      padding: '0',
      width: '136px',
    }}
    {...props}
  />
);
