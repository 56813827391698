import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { Link } from 'react-router';
import { take } from 'ramda';
import ellipsisis from 'ellipsisis';

import { Box, Button, Heading, Text } from '../../../mc-ui';
import ArrowRightIcon from '../../../mc-ui/icons/ArrowRightIcon';

import BrandLogo from '../../BrandLogo';

const HolidayInfo = ({ model }) => {
  const brands = model.relationships.companies.data;

  return (
    <div>
      <Heading as="h2" css={{ fontWeight: '300', lineHeight: '1.32em' }}>
        {model.attributes.name}
      </Heading>

      <Text fontSize="12px">
        {model.attributes.approximateEmails}{' '}
        {pluralize('email', model.attributes.approximateEmails)}
      </Text>

      <Box height="32px" margin="16px 0" position="relative">
        {take(3, brands).map((brand, index) => (
          <BrandLogo
            key={brand.id}
            brandId={brand.id}
            css={{ height: '32px', left: `${24 * index}px`, position: 'absolute', width: '32px' }}
          />
        ))}
      </Box>

      <Box css={{ marginBottom: '24px', overflow: 'hidden' }}>
        <Text
          fontSize="16px"
          css={{
            display: '-webkit-box',
            WebkitLineClamp: '7',
            WebkitBoxOrient: 'vertical',
          }}
        >
          {ellipsisis(model.attributes.description || '', 130, '...')}
        </Text>
      </Box>

      <Button
        as={Link}
        to={`/lists/${model.id}`}
        css={{
          alignItems: 'center',
          display: 'inline-flex',
          height: '34px',
          justifyContent: 'space-between',
          padding: '0 15px',
          width: '130px',
        }}
      >
        <ArrowRightIcon fillOpacity={1} height="20px" width="20px" /> See this list
      </Button>
    </div>
  );
};

HolidayInfo.propTypes = {
  model: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.shape({
      approximateEmails: PropTypes.number,
      description: PropTypes.string,
      name: PropTypes.string,
    }),
  }).isRequired,
};

export default HolidayInfo;
