import React from 'react';
import PropTypes from 'prop-types';
import styled from "@emotion/styled";

const TD = styled("td")(
  ({ align = 'left', color = null, theme, verticalAlign = 'inherit', width = null }) => ({
    color: color ? theme.colors[color] : null,
    display: 'table-cell',
    padding: '10px 0',
    textAlign: align,
    verticalAlign,
    width,
    '& > a': {
      color: theme.colors.blue,
      textDecoration: 'none',
    },
  })
);

const TH = styled("th")(({ align = 'left', nowrap = false, theme, width = null }) => ({
  color: theme.colors.dark08,
  display: 'table-cell',
  fontSize: '12px',
  fontWeight: 'bold',
  letterSpacing: '0.08em',
  padding: '10px 0',
  textAlign: align,
  textTransform: 'uppercase',
  verticalAlign: 'inherit',
  whiteSpace: nowrap ? 'nowrap' : null,
  width,
}));

const Cell = ({ as, ...props }) => (as === 'td' ? <TD {...props} /> : <TH {...props} />);

Cell.defaultProps = {
  as: 'td',
  children: <span>&nbsp;</span>,
};

Cell.propTypes = {
  as: PropTypes.string,
  children: PropTypes.node,
};

export default Cell;
