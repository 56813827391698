import React from 'react';
import PropTypes from 'prop-types';

import { Box, Flex } from '../../../../../mc-ui';

import Logo from './Logo';
import SendHourChart from './SendHourChart';

const Row = ({ brand }) => (
  <Flex css={{ marginBottom: '16px' }}>
    <Logo brand={brand} />
    <Box css={{ paddingLeft: '16px', width: '100%' }}>
      <SendHourChart brand={brand} />
    </Box>
  </Flex>
);

Row.propTypes = {
  brand: PropTypes.shape({
    id: PropTypes.number,
    meta: PropTypes.shape({
      stats: PropTypes.shape({
        sentAtHourOfDay: PropTypes.arrayOf(
          PropTypes.shape({
            docCount: PropTypes.number,
            key: PropTypes.number,
            value: PropTypes.number,
          }),
        ),
      }),
    }),
  }).isRequired,
};

export default Row;
