/** @jsx jsx */
import PropTypes from 'prop-types';
import { jsx, withTheme } from '@emotion/react';

import { Flex } from '../../../../mc-ui';

const borderRadius = (data, index) => {
  if (index === 0) return '4px 0 0 4px';
  if (data.length - 1 === index) return '0 4px 4px 0';
  return '0';
};

const Bar = ({ data, theme }) => (
  <Flex css={{ height: '24px', width: '100%' }}>
    {data.map((item, index) => (
      <div
        key={`Bar-${item.color}-${item.label}`}
        css={{
          backgroundColor: theme.colors[item.color],
          borderRadius: borderRadius(data, index),
          height: '24px',
          width: `${item.width * 100}%`,
        }}
      />
    ))}
  </Flex>
);

Bar.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      label: PropTypes.string,
      width: PropTypes.number,
    }),
  ).isRequired,
  theme: PropTypes.shape({}).isRequired,
};

export default withTheme(Bar);
