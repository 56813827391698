import { PREFIX } from './constants';

export const DELETE_REQUEST = `${PREFIX}/DELETE_REQUEST`;
export const DELETE_SUCCESS = `${PREFIX}/DELETE_SUCCESS`;
export const DELETE_FAILURE = `${PREFIX}/DELETE_FAILURE`;

export const CLOSE_CREATE_FORM = `${PREFIX}/CLOSE_CREATE_FORM`;
export const OPEN_CREATE_FORM = `${PREFIX}/OPEN_CREATE_FORM`;

export const CLOSE_EDIT_FORM = `${PREFIX}/CLOSE_EDIT_FORM`;
export const OPEN_EDIT_FORM = `${PREFIX}/OPEN_EDIT_FORM`;

export const CREATE_REQUEST = `${PREFIX}/CREATE_REQUEST`;
export const CREATE_SUCCESS = `${PREFIX}/CREATE_SUCCESS`;
export const CREATE_FAILURE = `${PREFIX}/CREATE_FAILURE`;

export const UPDATE_REQUEST = `${PREFIX}/UPDATE_REQUEST`;
export const UPDATE_SUCCESS = `${PREFIX}/UPDATE_SUCCESS`;
export const UPDATE_FAILURE = `${PREFIX}/UPDATE_FAILURE`;

export const FETCH_USER_LISTS_REQUEST = `${PREFIX}/FETCH_USER_LISTS_REQUEST`;
export const FETCH_USER_LISTS_SUCCESS = `${PREFIX}/FETCH_USER_LISTS_SUCCESS`;
export const FETCH_USER_LISTS_FAILURE = `${PREFIX}/FETCH_USER_LISTS_FAILURE`;

export const FETCH_TEAM_LISTS_REQUEST = `${PREFIX}/FETCH_TEAM_LISTS_REQUEST`;
export const FETCH_TEAM_LISTS_SUCCESS = `${PREFIX}/FETCH_TEAM_LISTS_SUCCESS`;
export const FETCH_TEAM_LISTS_FAILURE = `${PREFIX}/FETCH_TEAM_LISTS_FAILURE`;

export const SHARE_WITH_TEAM_REQUEST = `${PREFIX}/SHARE_WITH_TEAM_REQUEST`;
export const SHARE_WITH_TEAM_SUCCESS = `${PREFIX}/SHARE_WITH_TEAM_SUCCESS`;
export const SHARE_WITH_TEAM_FAILURE = `${PREFIX}/SHARE_WITH_TEAM_FAILURE`;

export const CREATE_TEAM_LIST_REQUEST = `${PREFIX}/CREATE_TEAM_LIST_REQUEST`;
export const CREATE_TEAM_LIST_SUCCESS = `${PREFIX}/CREATE_TEAM_LIST_SUCCESS`;
export const CREATE_TEAM_LIST_FAILURE = `${PREFIX}/CREATE_TEAM_LIST_FAILURE`;

export const DELETE_TEAM_LIST_REQUEST = `${PREFIX}/DELETE_TEAM_LIST_REQUEST`;
export const DELETE_TEAM_LIST_SUCCESS = `${PREFIX}/DELETE_TEAM_LIST_SUCCESS`;
export const DELETE_TEAM_LIST_FAILURE = `${PREFIX}/DELETE_TEAM_LIST_FAILURE`;

export const UPDATE_TEAM_LIST_REQUEST = `${PREFIX}/UPDATE_TEAM_LIST_REQUEST`;
export const UPDATE_TEAM_LIST_SUCCESS = `${PREFIX}/UPDATE_TEAM_LIST_SUCCESS`;
export const UPDATE_TEAM_LIST_FAILURE = `${PREFIX}/UPDATE_TEAM_LIST_FAILURE`;

export const ADD_ITEMS_TO_LIST_REQUEST = `${PREFIX}/ADD_ITEMS_TO_LIST_REQUEST`;
export const ADD_ITEMS_TO_LIST_SUCCESS = `${PREFIX}/ADD_ITEMS_TO_LIST_SUCCESS`;
export const ADD_ITEMS_TO_LIST_FAILURE = `${PREFIX}/ADD_ITEMS_TO_LIST_FAILURE`;

export const REMOVE_ITEMS_FROM_LIST_REQUEST = `${PREFIX}/REMOVE_ITEMS_FROM_LIST_REQUEST`;
export const REMOVE_ITEMS_FROM_LIST_SUCCESS = `${PREFIX}/REMOVE_ITEMS_FROM_LIST_SUCCESS`;
export const REMOVE_ITEMS_FROM_LIST_FAILURE = `${PREFIX}/REMOVE_ITEMS_FROM_LIST_FAILURE`;

export const CREATE_LIST_AND_ADD_ITEMS_REQUEST = `${PREFIX}/CREATE_LIST_AND_ADD_ITEMS_REQUEST`;
export const CREATE_LIST_AND_ADD_ITEMS_SUCCESS = `${PREFIX}/CREATE_LIST_AND_ADD_ITEMS_SUCCESS`;
export const CREATE_LIST_AND_ADD_ITEMS_FAILURE = `${PREFIX}/CREATE_LIST_AND_ADD_ITEMS_FAILURE`;
