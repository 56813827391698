import React from 'react';

function SvgRoundedRightArrowIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 18A9 9 0 109 0a9 9 0 000 18zm2.81-8.258a.968.968 0 000-.744.97.97 0 00-.21-.316l-3.5-3.5a.649.649 0 00-.917.918l3.205 3.205a.092.092 0 010 .13L7.183 12.64a.649.649 0 00.917.917l3.5-3.5a.971.971 0 00.21-.315z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgRoundedRightArrowIcon;
