import React from 'react'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import humanize from 'humanize-number'
import moment from 'moment'

import Loader from '../Loader'
import Tooltip from '../ui/Tooltip'
import HorizontalBar from '../HorizontalBar'

import api from '../../lib/api'

class OverviewReport extends React.Component {
  render() {
    let stats = this.props[this.props.model.type].meta.stats.reduce((m, s) => s, {})
    let day_of_week = stats.day_of_week.buckets[0].key
    let subject_line_length = Math.round(stats.subject_line_length.avg)
    let promotional = Math.round(stats.promotional.avg * 100)
    let mailcharts_score = Math.round(stats.mailcharts_score.avg * 100)
    let { loading } = this.props[this.props.model.type]
    return (
      <div
        className='pure-u-1'
        style={{
          position: 'relative',
        }}
        >
          <Loader loading={loading}>
            <div className='pure-g gutters'
              style={{
                paddingBottom: 10,
                marginBottom: 10,
                borderBottom: '1px solid #F3F5F7'
              }}
            >
              <div className='pure-u-1 pure-u-md-1-2'>
                <h5>Most popular day of week</h5>
                <h3
                  style={{
                    textTransform: 'capitalize',
                  }}
                >
                  {day_of_week}
                </h3>
              </div>
              <div className='pure-u-1 pure-u-md-1-2'>
                <p>Get a bird's eye view on send frequency, as well as a time-of-day and day-of-week breakdown.</p>
                <p>
                  <Link
                    style={{ fontSize: `${13/16}em` }}
                    to={`/${this.props.model.type}/${this.props.model.id}/behavior`}
                  >
                    View sending behavior
                  </Link>
                </p>
              </div>
            </div>
            <div className='pure-g gutters'
              style={{ paddingBottom: 10, marginBottom: 10, borderBottom: '1px solid #F3F5F7' }}
            >
              <div className='pure-u-1 pure-u-md-1-2'>
                <h5>Subject line length</h5>
                <HorizontalBar
                  max={75}
                  idx={0}
                  value={subject_line_length}
                >
                  {subject_line_length} characters
                </HorizontalBar>
              </div>
              <div className='pure-u-1 pure-u-md-1-2'>
                <p>Get a sense of the subject line, preview text, and content strategy used by {this.props.model.attributes.name}.</p>
                <p>
                  <Link
                    style={{ fontSize: `${13/16}em` }}
                    to={`/${this.props.model.type}/${this.props.model.id}/content`}
                  >
                    View content analysis
                  </Link>
                </p>
              </div>
            </div>
            <div className='pure-g gutters'
              style={{ paddingBottom: 10, marginBottom: 10, borderBottom: '1px solid #F3F5F7' }}
            >
              <div className='pure-u-1 pure-u-md-1-2'>
                <h5
                  style={{
                    display: 'inline-block',
                  }}
                >
                  Promotional rate
                </h5>
                <HorizontalBar
                  max={100}
                  idx={0}
                  value={promotional}
                >
                  {promotional}%
                </HorizontalBar>
              </div>
              <div className='pure-u-1 pure-u-md-1-2'>
                <p>View promotional rate, types of promotions used, and recent promotions.</p>
                <p>
                  <Link
                    style={{ fontSize: `${13/16}em` }}
                    to={`/${this.props.model.type}/${this.props.model.id}/promotions`}
                  >
                    View promotions
                  </Link>
                </p>
              </div>
            </div>
            <div className='pure-g gutters'>
              <div className='pure-u-1 pure-u-md-1-2'>
                <h5>MailCharts Score</h5>
                <h3>{mailcharts_score}%</h3>
              </div>
              <div className='pure-u-1 pure-u-md-1-2'>
                <p>See how {this.props.model.attributes.name} stacks up against the MailCharts list of best practices.</p>
                <p>
                  <Link
                    style={{ fontSize: `${13/16}em` }}
                    to={`/${this.props.model.type}/${this.props.model.id}/score`}
                  >
                    View MailCharts Score
                  </Link>
                </p>
              </div>
            </div>
          </Loader>
        </div>
    )
  }
}

const s = (state) => ({ companies: state.companies, groups: state.groups })
export default connect(s)(OverviewReport)
