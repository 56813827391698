import React from 'react';
import PropTypes from 'prop-types';

function PromoIcon({ fillOpacity, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.687 9.751a.442.442 0 000 .498l.57.842c.52.765.172 1.797-.718 2.124l-.977.359a.46.46 0 00-.304.403l-.054 1.003c-.049.912-.961 1.55-1.881 1.313l-1.01-.261a.488.488 0 00-.493.154l-.658.78a1.534 1.534 0 01-2.324 0l-.658-.78a.488.488 0 00-.493-.154l-1.01.26c-.918.238-1.832-.398-1.88-1.312l-.055-1.003a.459.459 0 00-.304-.403l-.977-.36c-.888-.326-1.238-1.356-.719-2.123l.57-.842a.442.442 0 000-.498l-.57-.842c-.518-.765-.17-1.797.719-2.124l.976-.359a.459.459 0 00.305-.403l.054-1.003c.049-.912.961-1.55 1.881-1.312l1.01.26a.487.487 0 00.493-.154l.658-.78a1.534 1.534 0 012.325 0l.657.78c.118.14.312.201.493.154l1.01-.26c.918-.238 1.832.398 1.88 1.312l.055 1.003c.01.18.129.339.304.403l.977.36c.888.326 1.238 1.356.718 2.123l-.57.842zM11.322 8.18L10 5 8.677 8.18l-3.432.275 2.615 2.24-.799 3.35L10 12.25l2.939 1.795-.8-3.35 2.616-2.24-3.433-.275z"
        fill="currentColor"
        fillOpacity={fillOpacity}
      />
    </svg>
  );
}

PromoIcon.propTypes = {
  fillOpacity: PropTypes.number,
};

PromoIcon.defaultProps = {
  fillOpacity: 0.6,
};

export default PromoIcon;
