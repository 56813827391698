import { contains, not } from 'ramda';
import { createSelector } from 'reselect';

const businessPlanIds = ['bQkuw', 'RyCee'];
const enterprisePlanIds = ['LTDgn', 'ENrky'];
const teamAdminPlans = [].concat(businessPlanIds, enterprisePlanIds);
const freePlans = 'free';

export const getUser = state => {
  const [user] = state.user.data;
  return user;
};

export const getUserTeams = state =>
  state.user.included.filter(i => i.type === 'teams' && i.attributes.status === 'active');
export const getUserInvitations = state =>
  state.user.included.filter(i => i.type === 'invitations');

export const canAccessTeams = state =>
  getUserTeams(state).length > 0 || getUserInvitations(state).length > 0;

export const isTeamsBlocked = state => {
  const user = getUser(state);
  return not(canAccessTeams(state)) && not(contains(user.attributes.plan_id, teamAdminPlans));
};

export const isTeamAdmin = state => {
  const [team] = getUserTeams(state);
  return team && team.attributes.role === 'admin';
};

export const canViewPlansAndBilling = state => {
  const user = getUser(state);
  return isTeamAdmin(state) || contains(user.attributes.plan_id, freePlans);
};

export const isUserRestrictedSelector = createSelector(
  getUser,
  user => (user.attributes.plan_level?user.attributes.plan_level:'free').toLowerCase() == 'free',
);
