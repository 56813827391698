export const NAME = 'shareButton';
export const PREFIX = `mc/${NAME}`;

export const TYPES = {
  brandOverview: 'brand-overview',
  email: 'email',
  emailList: 'email-list',
  industryOverview: 'industry-overview',
  journey: 'journey',
  textMessage: 'text-message',
};
