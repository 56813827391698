import React, { useState } from 'react'

import props from 'prop-types'
import { loadStripe } from "@stripe/stripe-js";
import { connect } from 'react-redux'
import { Link } from '../../../mc-ui'
import { teamsActions, teamsSelectors } from '../../../../modules/teams';

import { Elements } from "@stripe/react-stripe-js";
import CreditCardUpdateModal from './CreditCardUpdateModal'

const UpdateCreditCardInfo = ({ interval, getPaymentIntent, setupIntent }) => {
    const stripePromise = loadStripe(process.env.STRIPE_PUBLISH_KEY);

    const [openUpdateModal,setOpenUpdateModal] = useState(false);
    const options = {
        clientSecret: setupIntent.client_secret,
        appearance: {
          theme: 'stripe',
        },
      }
    const openCreditCardUpdateModal = async (e) => {
        e.preventDefault();
        if(!setupIntent.client_secret){
            getPaymentIntent();
            options.clientSecret = setupIntent.client_secret;
        }
        setOpenUpdateModal(true);
    }

    return (
        <div>
            <Link
             to="#updateCreditCardInfo"
             onClick={(e) => openCreditCardUpdateModal(e)}
             textDecoration="underline"
             marginBottom="5px"
            >
                Update Credit Card Information
            </Link>
            <Elements options={options} stripe={stripePromise}>
                <CreditCardUpdateModal
                isOpen={openUpdateModal}
                closeModal={() => setOpenUpdateModal(false)}
                />
            </Elements>
        </div>
    )
}


const mapStateToProps = state => ({
    setupIntent: teamsSelectors.getSetupIntent(state)
})

const mapDispatchToProps = dispatch => ({
    getPaymentIntent: (subscription_id) => dispatch(teamsActions.requestPaymentIntent(subscription_id))
  });

export default connect(mapStateToProps,mapDispatchToProps)(UpdateCreditCardInfo);