import * as React from 'react';
import PropTypes from 'prop-types';

function CheckBigIcon({ fillOpacity, ...props }) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.052 6.812c.474.449.495 1.197.047 1.67L10.096 18 4.8 12.224a1.182 1.182 0 111.742-1.598l3.582 3.906 7.258-7.673a1.182 1.182 0 011.671-.047z"
        fill="currentColor"
        fillOpacity={fillOpacity}
      />
    </svg>
  );
}

CheckBigIcon.propTypes = {
  fillOpacity: PropTypes.number,
};

CheckBigIcon.defaultProps = {
  fillOpacity: 1,
};

export default CheckBigIcon;
