import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { DateRangePicker } from 'react-dates';
import { omit } from 'ramda';

import responsive from '../lib/responsive';

import DatepickerArrowIcon from './ui/DatepickerArrowIcon';

class DateRangePickerWrapper extends Component {
  state = {
    startDate: this.props.startDate,
    endDate: this.props.endDate,
    focusedInput: null,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.endDate !== this.props.endDate && nextProps.startDate !== this.props.startDate) {
      this.setState({
        endDate: nextProps.endDate,
        startDate: nextProps.startDate,
      });
    }
  }

  onChange = ({ startDate, endDate }) => {
    this.setState({ startDate, endDate });
    if (startDate && endDate) {
      this.props.onDatesChange(startDate, endDate);
    }
  };

  onClear = (event) => {
    event.preventDefault();
    this.setState({ startDate: null, endDate: null });
    this.props.onDatesChange(null, null);
  };

  onFocus = (focusedInput) => {
    this.setState({ focusedInput });
  };

  fixArrowStyle = true;

  showClearDates = () => {
    const { showClearDates } = this.props;
    const { startDate, endDate } = this.state;
    return showClearDates && (startDate || endDate);
  };

  render() {
    const { isMobile, fixedSize } = this.props;
    const { startDate, endDate, focusedInput } = this.state;
    const props = omit(
      [
        'browser',
        'customArrowIcon',
        'endDate',
        'startDate',
        'focusedInput',
        'isMobile',
        'noBorder',
        'onDatesChange',
        'onFocusChange',
        'showClearDates',
        'fixedSize',
      ],
      this.props,
    );

    const wrapperClassName = classNames({
      DateRangePickerWrapper: true,
      DateRangePickerWrapper__focused: focusedInput !== null,
      fixedSize,
    });

    return (
      <div style={{ position: 'relative' }}>
        <div className={wrapperClassName}>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            focusedInput={focusedInput}
            onDatesChange={this.onChange}
            onFocusChange={this.onFocus}
            customArrowIcon={<DatepickerArrowIcon />}
            orientation={isMobile ? 'vertical' : 'horizontal'}
            isOutsideRange={() => false}
            transitionDuration={100}
            hideKeyboardShortcutsPanel
            noBorder
            {...props}
          />
        </div>
        {this.showClearDates() && (
          <p className="DateRangePickerWrapper--clearDates">
            <a href="#clear" onClick={this.onClear}>
              Clear dates{' '}
            </a>
          </p>
        )}
      </div>
    );
  }
}

DateRangePickerWrapper.propTypes = {
  endDate: PropTypes.shape({}),
  endDateId: PropTypes.string,
  fixedSize: PropTypes.bool,
  isMobile: PropTypes.bool,
  onDatesChange: PropTypes.func,
  showClearDates: PropTypes.bool,
  startDate: PropTypes.shape({}),
  startDateId: PropTypes.string,
};

DateRangePickerWrapper.defaultProps = {
  endDate: null,
  endDateId: 'endDate',
  fixedSize: false,
  isMobile: false,
  onDatesChange: () => {},
  showClearDates: false,
  startDate: null,
  startDateId: 'starDate',
};

export default responsive(DateRangePickerWrapper);
