import React from 'react'

class Toggle extends React.Component {
  handleClick = (event) => {
    event.preventDefault()
    if (this.props.handleClick)
      this.props.handleClick()
  }
  render() {
    let background = this.props.active ? '#E6F2FB' : '#F3F5F7'
    let border = this.props.active ? '1px solid #BDDDF4' : '1px solid #D4D9DE'
    let left = this.props.active ? 15 : -5
    let circle = this.props.active ? '#0379D5' : '#A0AEBA'
    return (
      <div
        onClick={this.handleClick}
        style={{ display: 'inline-block', margin: '0 5px', cursor: 'pointer' }}
      >
        <div style={{ borderRadius: 7, border, background, height: 13, width: 25 }} />
        <div style={{ borderRadius: '50%', background: circle, width: 17, height: 17, position: 'relative', marginBottom: -16, top: -16, left }} />
      </div>
    )
  }
}

export default Toggle
