import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

import { Box, Button, Flex, Heading } from '../../mc-ui';

import background from './emptyGroup.png';

const EmptyGroup = ({ group }) => (
  <Box width="792px">
    <Heading as="h4" css={{ lineHeight: '1.32em', marginBottom: '24px' }}>
      {group.attributes.name}
    </Heading>

    <Flex
      alignItems="center"
      backgroundColor="dark004"
      borderRadius="4px"
      direction="column"
      height="327px"
      padding="60px"
    >
      <img alt="brands" height="58" src={background} width="125" />

      <Heading
        as="h5"
        color="dark08"
        css={{
          fontSize: '18px',
          lineHeight: '1.32em',
          marginBottom: '24px',
          marginTop: '22px',
          textTransform: 'none',
        }}
      >
        Any brands that you track will appear in this group. Find brands to track now:
      </Heading>

      <Button
        as={Link}
        to="/brands"
        variant="primary"
        css={{
          alignItems: 'center',
          display: 'inline-flex',
          height: '32px',
          justifyContent: 'space-between',
          padding: '0 15px',
          width: '127px',
        }}
      >
        Browse Brands
      </Button>
    </Flex>
  </Box>
);

EmptyGroup.propTypes = {
  group: PropTypes.shape({
    attributes: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
};

export default EmptyGroup;
