import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@emotion/react';

import { Box, Text, Tooltip } from '../../../../../../../../mc-ui';

import PromotionTag from './PromotionTag';

const emailImage = email =>
  `//assets.mailcharts.com/emails/${email.attributes.guid}/${'300x400'}.png`;

const Thumbnail = ({ email, theme }) => (
  <Box height="40px" position="relative" marginRight="8px" width="40px">
    <img src={emailImage(email)} style={{ display: 'none' }} alt={email.attributes.subject} />
    <Box
      css={{
        backgroundImage: `url(${emailImage(email)})`,
        backgroundPosition: 'top center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        border: `1px solid ${theme.colors.dark01}`,
        borderRadius: '4px',
        height: '40px',
        position: 'relative',
        width: '40px',
      }}
    />

    {email.attributes.promotional && (
      <Tooltip dark element={<PromotionTag />} id={`promotionOfferTooltip-${email.id}`}>
        <Text>This email includes a promotion.</Text>
      </Tooltip>
    )}
  </Box>
);

Thumbnail.contextTypes = {
  router: PropTypes.object,
};

Thumbnail.propTypes = {
  email: PropTypes.shape({}).isRequired,
  theme: PropTypes.shape({}).isRequired,
};

export default withTheme(Thumbnail);
