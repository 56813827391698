import React from 'react';

import { Flex, Grid, Heading, Loader } from '../mc-ui';
import ListCard from '../ui/ListCard';

import { fetchLists } from './utils/api';

class Lists extends React.PureComponent {
  state = {
    lists: [],
    loading: true,
  };

  componentDidMount() {
    this.request();
  }

  request = async () => {
    const lists = await fetchLists();
    this.setState(() => ({ lists, loading: false }));
  };

  render() {
    const { lists, loading } = this.state;

    return (
      <div>
        <Heading as="h3" css={{ fontWeight: '300', lineHeight: '1.32em', marginBottom: '24px' }}>
          More email inspiration
        </Heading>

        {loading ? (
          <Flex alignItems="center" height="652px" justifyContent="center" width="824px">
            <Loader />
          </Flex>
        ) : (
          <Grid
            columnGap="24px"
            rowGap="32px"
            gridTemplateColumns="repeat(4, 1fr)"
            css={{ marginBottom: '32px' }}
          >
            {lists.map(list => (
              <ListCard key={`moreEmailInspiration-${list.id}`} list={list} />
            ))}
          </Grid>
        )}
      </div>
    );
  }
}

export default Lists;
