import styled from "@emotion/styled";

const Wrapper = styled("div")(({
  height = '705px',
  marginLeft = '-533px',
  marginTop = 0,
  maxHeight = '705px',
  width = '1066px',
}) => ({
  background: 'white',
  borderRadius: '4px',
  boxShadow: '0px 2px 8px rgba(9, 30, 66, 0.2)',
  boxSizing: 'border-box',
  left: '50%',
  marginLeft,
  marginTop,
  maxHeight: maxHeight || height,
  position: 'absolute',
  top: !marginTop || marginTop === 0 ? '40px' : '50%',
  width,
  zIndex: '9996',
}));

export default Wrapper;
