/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { Link as RRLink } from 'react-router';
import { isEmpty, take } from 'ramda';

import { Box, Flex, Heading, Text, theme } from '../../../mc-ui';
import Email from '../../../ui/Email.js';

export default function ExploreEmailLists({ email, router }) {
  const { query } = router.location;
  const lists = (email.relationships.lists || { data: [] }).data.filter(
    l => `${l.id}` !== `${query.list_id}`,
  );

  if (isEmpty(lists)) return null;

  return (
    <Box marginBottom="24px">
      <Heading
        as="h5"
        color="dark08"
        fontWeight="bold"
        lineHeight="1.56em"
        css={{ fontSize: '10px', letterSpacing: '0.12em' }}
      >
        Email Lists
      </Heading>

      <Flex justifyContent="space-between" wrap="wrap">
        {lists.filter(l => `${l.id}`.match(/\d+/i)).map(list => (
          <RRLink
            key={`EmailExplore-List${list.id}`}
            to={`/lists/${list.id}`}
            css={{
              color: 'currentColor',
              cursor: 'pointer',
              textDecoration: 'none',
              '&:hover': { '& h5': { color: `${theme.colors.blue} !important` } },
            }}
          >
            <Box
              borderRadius="4px"
              marginTop="16px"
              padding="4px 0"
              width="206px"
              css={{
                '&:hover': {
                  backgroundColor: theme.colors.dark6,
                },
              }}
            >
              <Flex marginBottom="8px">
                {take(3, list.attributes.related_emails).map((listEmail, index) => (
                  <Box
                    key={`EmailExplore-List${list.id}-Email${listEmail.id}`}
                    height="60px"
                    marginRight={index < 2 ? '13px' : 0}
                    width="60px"
                  >
                    <Email
                      email={listEmail}
                      height="100%"
                      width="100%"
                      cssStyle={{
                        cursor: 'pointer',
                        height: '100%',
                        margin: '0',
                        padding: '0',
                        width: '100%',
                      }}
                    />
                  </Box>
                ))}
              </Flex>

              <Heading
                as="h5"
                css={{
                  fontSize: '14px',
                  letterSpacing: '0.02em',
                  lineHeight: '1.25em',
                  padding: '0 4px',
                  textTransform: 'capitalize',
                }}
              >
                {list.attributes.name}
              </Heading>

              <Text fontSize="12px" padding="0 4px">
                {list.attributes.approximate_emails}{' '}
                {pluralize('email', list.attributes.approximate_emails)}.
              </Text>
            </Box>
          </RRLink>
        ))}
      </Flex>
    </Box>
  );
}

ExploreEmailLists.propTypes = {
  email: PropTypes.shape({
    relationships: PropTypes.shape({
      lists: PropTypes.shape({
        data: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            attributes: PropTypes.shape({
              approximate_emails: PropTypes.number,
              name: PropTypes.string,
              related_emails: PropTypes.arrayOf(PropTypes.shape({})),
            }),
          }),
        ),
      }),
    }),
  }).isRequired,
  router: PropTypes.shape({
    location: PropTypes.shape({
      query: PropTypes.shape({
        list_id: PropTypes.string,
      }),
    }),
  }).isRequired,
};
