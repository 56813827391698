import React from 'react';

const Compare = props => (
  <svg viewBox="0 0 94 84" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M89.17 62.587H39.268a3.431 3.431 0 0 1-3.43-3.432V4.432A3.432 3.432 0 0 1 39.267 1H89.17a3.433 3.433 0 0 1 3.432 3.432v54.723a3.432 3.432 0 0 1-3.432 3.432"
        fill="#FFF"
      />
      <path
        d="M89.17 62.587H39.268a3.431 3.431 0 0 1-3.43-3.432V4.432A3.432 3.432 0 0 1 39.267 1H89.17a3.433 3.433 0 0 1 3.432 3.432v54.723a3.432 3.432 0 0 1-3.432 3.432z"
        stroke="#A1AEBA"
        strokeWidth={2}
      />
      <path
        d="M44.807 39.34h38.825M44.807 30.37H60.5M44.807 22.473H60.5M44.807 47.641h38.825"
        stroke="#D7DADE"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M54.334 82.845H4.43a3.431 3.431 0 0 1-3.43-3.432V24.69a3.432 3.432 0 0 1 3.43-3.432h49.903a3.433 3.433 0 0 1 3.431 3.432v54.723a3.432 3.432 0 0 1-3.431 3.432"
        fill="#FFF"
      />
      <path
        d="M54.334 82.845H4.43a3.431 3.431 0 0 1-3.43-3.432V24.69a3.432 3.432 0 0 1 3.43-3.432h49.903a3.433 3.433 0 0 1 3.431 3.432v54.723a3.432 3.432 0 0 1-3.431 3.432z"
        stroke="#A1AEBA"
        strokeWidth={2}
      />
      <path
        d="M36.656 31.821l-19.41 21.995 9.059-.013-6.271 18.413c-.027.082.076.145.136.082l21.349-22.48-10.293.014 5.568-17.934c.026-.083-.08-.142-.138-.077"
        fill="#E74247"
      />
      <path
        d="M44.807 14.439h38.825"
        stroke="#D7DADE"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M75.042 28.158v6.906a7.751 7.751 0 0 1-7.462-6.906h7.462zM83.585 28.158a7.752 7.752 0 0 1-7.462 6.906v-6.906h7.462zM83.632 26.748c0 .113 0 .217-.009.32h-7.5v-8.084a7.757 7.757 0 0 1 7.51 7.764"
        fill="#D7DADE"
      />
      <path
        d="M74.49 18.431v8.084h-7.5c-.01-.104-.01-.207-.01-.32a7.757 7.757 0 0 1 7.51-7.764"
        fill="#A1AEBA"
      />
    </g>
  </svg>
);

export default Compare;
