import React from 'react';

const Success = props => (
  <svg
    viewBox="0 0 219 80"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="1em"
    height="1em"
    {...props}
  >
    <defs>
      <path
        d="M1.734 28.141L42.667 0l40.932 28.141a4 4 0 0 1 1.734 3.296V76a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V31.437a4 4 0 0 1 1.734-3.296z"
        id="a"
      />
      <path id="b" d="M33 61h6v6h-6z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(65)">
        <use fill="#FFF" xlinkHref="#a" />
        <path
          stroke="#A0AEBA"
          strokeWidth={2}
          d="M42.667 1.214L2.3 28.965A3 3 0 0 0 1 31.437V76a3 3 0 0 0 3 3h77.333a3 3 0 0 0 3-3V31.437a3 3 0 0 0-1.3-2.472L42.667 1.214z"
        />
      </g>
      <path
        d="M86.333 9.333a2.667 2.667 0 0 0 5.334 0h4a2.668 2.668 0 0 0 5.333 0h4a2.667 2.667 0 0 0 5.333 0h4a2.667 2.667 0 0 0 5.334 0h4a2.668 2.668 0 0 0 5.333 0 4 4 0 0 1 4 4V36.4l-25.333 16.933L82.333 36.4V13.333a4 4 0 0 1 4-4z"
        fill="#7ECA5B"
      />
      <path
        d="M100.243 30.926a.877.877 0 0 1-.243-.568c0-.162.081-.405.243-.568l1.137-1.136a.784.784 0 0 1 1.136 0l.08.081 4.464 4.788a.392.392 0 0 0 .568 0l10.874-11.28h.081a.784.784 0 0 1 1.136 0l1.137 1.137a.784.784 0 0 1 0 1.136l-12.984 13.47a.737.737 0 0 1-.569.244.737.737 0 0 1-.568-.243l-6.33-6.817-.162-.244z"
        fill="#FFF"
        fillRule="nonzero"
      />
      <path
        stroke="#A0AEBA"
        strokeWidth={2}
        d="M67.24 31.45l40.259 26.667 40.259-26.666"
      />
      <path
        d="M71.667 73.693L91.619 56M143.619 73.693L123.667 56"
        stroke="#A0AEBA"
        strokeWidth={2}
        strokeLinecap="round"
      />
      <circle stroke="#A0AEBA" fill="#FFF" cx={177.5} cy={31.5} r={2.5} />
      <circle stroke="#A0AEBA" fill="#FFF" cx={48.5} cy={33.5} r={2.5} />
      <path
        d="M170.121 60.414l1.768-1.768a.5.5 0 0 1 .707.708l-1.768 1.767 1.768 1.768a.5.5 0 0 1-.707.707l-1.768-1.768-1.767 1.768a.5.5 0 1 1-.708-.707l1.768-1.768-1.768-1.767a.5.5 0 1 1 .708-.708l1.767 1.768zM56.121 46.414l1.768-1.768a.5.5 0 0 1 .707.708l-1.768 1.767 1.768 1.768a.5.5 0 0 1-.707.707l-1.768-1.768-1.767 1.768a.5.5 0 0 1-.708-.707l1.768-1.768-1.768-1.767a.5.5 0 0 1 .708-.708l1.767 1.768z"
        fill="#A0AEBA"
        fillRule="nonzero"
      />
      <path stroke="#A0AEBA" fill="#FFF" d="M195 53l3 5h-6z" />
      <g>
        <use fill="#FFF" xlinkHref="#b" />
        <path stroke="#A0AEBA" d="M33.5 61.5h5v5h-5z" />
      </g>
      <path
        d="M218.5 29.5l-9.618 9.618"
        stroke="#A0AEBA"
        opacity={0.404}
        strokeLinecap="round"
      />
      <path d="M10.5 56.5L.882 66.118" stroke="#A0AEBA" strokeLinecap="round" />
      <path
        d="M30.5 16.5l-9.618 9.618"
        stroke="#A0AEBA"
        opacity={0.405}
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default Success;
