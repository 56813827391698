import React from 'react'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import humanize from 'humanize-number'

class Summary extends React.Component {
  render() {
    return (
      <div className='pure-g'>
        <div className='pure-u-1'>
          <p>
            Your search for `{this.props.location.query.q || 'all emails'}` since {'all time'} found {humanize(this.props.searches.meta.total)} emails.
          </p>
        </div>
      </div>
    )
  } 
}
class Analytics extends React.Component {
  render() {
    return (
      <div className='pure-u-1'>
        <h1>SearchAnalytics</h1>
        <h1>CompanyAnalytics</h1>
        <h1>GroupAnalytics</h1>
        <h1>TechnologyAnalytics</h1>
        <h1>PersonaAnalytics</h1>
        <div style={{ background: 'white', boxShadow: '0px 3px 3px #ccc', border: '1px solid red' }}>
          <Summary {...this.props} />
          <p>Mobile optimization: {this.props.searches.stats.mobile_optimization.avg}</p>
          <p>Animated GIF usage: {this.props.searches.stats.animation.avg}</p>
          <p>SL length: {this.props.searches.stats.subject_line_length.avg}</p>
          <p>SL question rate: {this.props.searches.stats.subject_line_question.avg}</p>
          <p>SL emoji rate: {this.props.searches.stats.subject_line_emoji.avg}</p>
          <p>Promotional rate: {this.props.searches.stats.promotional.avg}</p>
          <p>SL promotions rate: {this.props.searches.stats.subject_line_promotion.avg}</p>
          <h3>Most popular promotion types:</h3>
          <ul>
            {this.props.searches.stats.promotion_types.buckets.map(s => <li key={s.key}>{s.key} - {s.doc_count}</li>)}
          </ul>
          <h3>Most popular days of week:</h3>
          <ul>
            {this.props.searches.stats.day_of_week.buckets.map(s => <li key={s.key}>{s.key} - {s.doc_count}</li>)}
          </ul>
          <h3>Volume by month:</h3>
          <ul>
            {this.props.searches.stats.months.buckets.map(s => <li key={s.key}>{s.key_as_string} - {s.doc_count}</li>)}
          </ul>
          <h3>Volume by week:</h3>
          <ul>
            {this.props.searches.stats.weeks.buckets.map(s => <li key={s.key}>{s.key_as_string} - {s.doc_count}</li>)}
          </ul>
          <h3>Most popular hour of day (UTC):</h3>
          <ul>
            {this.props.searches.stats.hour_of_day.buckets.map(s => <li key={s.key}>{s.key}:00 - {s.doc_count}</li>)}
          </ul>
        </div>
      </div>
    )
  }
}

const s = (state) => { return { searches: state.searches } }
export default connect(s)(Analytics)
