import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { teamsSelectors } from '../../modules/teams';

import { Box, } from '../mc-ui';

import ReactivateNotice from '../MyPlan/ReactivateNotice';

import InviteMembers from './InviteMembers';
import Invitation from './Invitation';
import Team from './Team';
import PlanExpiredNotice from '../MyPlan/PlanExpiredNotice';

class TeamManagement extends React.Component {
  render() {
    const { isCanceled, isCancelingSubscription, isPro, planExpired, team } = this.props;

    const pendingMember = team.attributes.status === 'pending';

    return (
      <div>

        {pendingMember && <Invitation expired={planExpired} />}

        {!pendingMember && isPro && !isCancelingSubscription && !planExpired && <InviteMembers />}

        {!pendingMember &&
          isPro &&
          isCancelingSubscription &&
          !planExpired && (
            <Box marginBottom="24px">
              <ReactivateNotice />
            </Box>
          )}

        {!pendingMember &&
          !isCancelingSubscription &&
          planExpired && (
            <Box marginBottom="24px">
              <PlanExpiredNotice fromTeamManagement />
            </Box>
          )}

        {(isPro || isCanceled) && !pendingMember && <Team />}
      </div>
    );
  }
}

TeamManagement.defaultProps = {
  team: null,
};

TeamManagement.propTypes = {
  isCanceled: PropTypes.bool.isRequired,
  isCancelingSubscription: PropTypes.bool.isRequired,
  isPro: PropTypes.bool.isRequired,
  planExpired: PropTypes.bool.isRequired,
  team: PropTypes.shape({
    attributes: PropTypes.shape({
      status: PropTypes.string,
    }).isRequired,
  }),
};

const mapStateToProps = state => ({
  isCanceled: teamsSelectors.isCanceledSelector(state),
  isCancelingSubscription: teamsSelectors.isCancelingSubscriptionSelector(state),
  isPro: teamsSelectors.isProSelector(state),
  planExpired: teamsSelectors.planExpiredSelector(state),
  team: teamsSelectors.teamSelector(state),
});

export default connect(mapStateToProps)(TeamManagement);
