import React from 'react';
import PropTypes from 'prop-types';

import EmptyListWrapper from './EmptyListWrapper';
import EmptyListIcon from './EmptyListIcon';
import EmptyListIconWrapper from './EmptyListIconWrapper';
import RoundedArrowIcon from './RoundedArrowIcon';
import EmptyListDescription from './EmptyListDescription';

const EmptyList = ({ emptyListDescription, renderOutside, scope }) => (
  <EmptyListWrapper renderOutside={renderOutside}>
    {scope != 'groups' && <EmptyListIconWrapper renderOutside={renderOutside}>
      <EmptyListIcon />
      <RoundedArrowIcon />
    </EmptyListIconWrapper>}
    <EmptyListDescription>
      {emptyListDescription}
      {!emptyListDescription && (
        <div>
          <h4>No list, no problem!</h4>
          <p>Create a new one with blue button above</p>
        </div>
      )}
    </EmptyListDescription>
  </EmptyListWrapper>
);

EmptyList.propTypes = {
  emptyListDescription: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
  renderOutside: PropTypes.bool,
};

EmptyList.defaultProps = {
  emptyListDescription: null,
  renderOutside: false,
};

export default EmptyList;
