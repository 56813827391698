import React from 'react';
import PropTypes from 'prop-types';

import PlusIcon from '../mc-ui/icons/PlusIcon';
import { Box, Button } from '../mc-ui';

import Lists from './Lists';
import CreateListForm from './CreateListForm';

class AddToListButton extends React.Component {
  constructor(props) {
    super(props);

    const isListsFormOpen = props.action === 'add-to-list';

    this.state = {
      isCreateFormOpen: false,
      isListsFormOpen,
      owner: 'me',
    };
  }

  componentDidMount() {
    this.interval = setInterval(this.watchWrapperVisibility, 500);
  }

  componentDidUpdate(_prevProps, prevState) {
    if (
      (!prevState.isListsFormOpen && this.state.isListsFormOpen) ||
      (!prevState.isCreateFormOpen && this.state.isCreateFormOpen)
    ) {
      this.interval = setInterval(this.watchWrapperVisibility, 500);
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  watchWrapperVisibility = () => {
    if (!this.wrapperEl || (!this.state.isListsFormOpen && !this.state.isCreateFormOpen)) return;

    const parent = this.wrapperEl.parentElement;
    const isVisible = parent.offsetWidth > 0 || parent.offsetHeight > 0;

    if (!isVisible) {
      this.setState(() => ({ isCreateFormOpen: false, isListsFormOpen: false }));
      clearInterval(this.interval);
      this.interval = null;
    }
  };

  interval = null;
  overlayEl = null;
  wrapperEl = null;

  closeCreateForm = event => {
    if (event) event.preventDefault();
    this.setState(() => ({ isCreateFormOpen: false }));
  };

  openCreateForm = event => {
    if (event) event.preventDefault();
    this.setState(() => ({ isCreateFormOpen: true }));
  };

  closeListsForm = event => {
    if (event) event.preventDefault();
    this.setState(() => ({ isListsFormOpen: false }));
  };

  openListsForm = event => {
    if (event) event.preventDefault();
    this.setState(() => ({ isListsFormOpen: true }));
  };

  updateOwner = owner => {
    this.setState(() => ({ owner }));
  };

  render() {
    const { asModal, css, disabled, emails, listType, onClose, textMessages, variant } = this.props;
    const { isCreateFormOpen, isListsFormOpen, owner } = this.state;

    return (
      <Box
        position="relative"
        width="122px"
        inline
        ref={parentRef => {
          this.wrapperEl = parentRef;
        }}
        css={css}
      >
        <Button
          disabled={disabled}
          onClick={this.openListsForm}
          variant={variant}
          css={{
            alignItems: 'center',
            boxShadow: '0px 1px 4px rgba(9, 30, 66, 0.2)',
            display: 'flex',
            height: '32px',
            justifyContent: 'center',
            lineHeight: '32px',
            margin: '0',
            padding: '0',
            width: '122px',
            '& > svg': { marginRight: '4px' },
          }}
        >
          <PlusIcon /> Add to list
        </Button>

        {isListsFormOpen && (
          <Lists
            asModal={asModal}
            emails={emails}
            listType={listType}
            listsOwner={owner}
            onClose={onClose}
            onCloseListsForm={this.closeListsForm}
            onOpenCreateForm={this.openCreateForm}
            onUpdateOwner={this.updateOwner}
            parentEl={this.wrapperEl}
            textMessages={textMessages}
          />
        )}

        {isCreateFormOpen && (
          <CreateListForm
            asModal={asModal}
            emails={emails}
            listType={listType}
            onCloseCreateForm={this.closeCreateForm}
            onOpenListsForm={this.openListsForm}
            onUpdateOwner={this.updateOwner}
            owner={owner}
            parentEl={this.wrapperEl}
            textMessages={textMessages}
          />
        )}
      </Box>
    );
  }
}

AddToListButton.defaultProps = {
  action: '',
  asModal: false,
  css: {},
  disabled: false,
  emails: [],
  listType: 'emails',
  onClose: () => {},
  textMessages: [],
  variant: 'secondary',
};

AddToListButton.propTypes = {
  action: PropTypes.string,
  asModal: PropTypes.bool,
  css: PropTypes.shape({}),
  disabled: PropTypes.bool,
  emails: PropTypes.arrayOf(PropTypes.shape({})),
  textMessages: PropTypes.arrayOf(PropTypes.shape({})),
  listType: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['primary', 'secondary']),
};

export default AddToListButton;
