import React from 'react';
import PropTypes from 'prop-types';

import { Button, Flex, Heading, Modal, Text } from '../mc-ui';

export default function RemoveCommentConfirmationModal({ closeModal, isDeleting, removeComment }) {
  return (
    <Modal isOpen onClose={closeModal}>
      <Modal.Body paddingTop="40px">
        <Flex alignItems="center" direction="column" justifyContent="center" width="696px">
          <img src="/images/alert.png" alt="Alert" />

          <Heading
            as="h2"
            fontSize="24px"
            fontWeight="300"
            lineHeight="1.32em"
            css={{ margin: '40px 0 24px 0' }}
          >
            Remove note?
          </Heading>

          <Text margin="0 0 24px 0">Are you sure you want to remove this note?</Text>

          <Flex justifyContent="space-between" width="220px">
            <Button
              css={{ height: '40px', lineHeight: '40px', margin: 0, padding: 0, width: '100px' }}
              disabled={isDeleting}
              onClick={removeComment}
              variant="primary"
            >
              Remove
            </Button>

            <Button
              css={{ height: '40px', lineHeight: '40px', margin: 0, padding: 0, width: '100px' }}
              disabled={isDeleting}
              onClick={closeModal}
              variant="secondary"
            >
              No
            </Button>
          </Flex>
        </Flex>
      </Modal.Body>
    </Modal>
  );
}

RemoveCommentConfirmationModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isDeleting: PropTypes.bool.isRequired,
  removeComment: PropTypes.func.isRequired,
};
