import React from 'react';
import { Link } from 'react-router';

import Section from '../Section';
import { Button, Flex, Heading, Text } from '../mc-ui';

const Header = () => (
  <Section>
    <Flex alignItems="center" justifyContent="space-between" paddingBottom="24px" paddingTop="41px">
      <div>
        <Heading
          as="h3"
          css={{
            fontWeight: '300',
            lineHeight: '1.32em',
            padding: '0',
          }}
        >
          Welcome to MailCharts
        </Heading>
        <Text>Explore the latest email data curated by the MailCharts team.</Text>
      </div>

      <Button as={Link} to="/emails" css={{ padding: '.465em .9297em' }}>
        Discover emails
      </Button>
    </Flex>
  </Section>
);

export default Header;
