import { PREFIX } from './constants';

export const FETCH_USER_FILTERS_REQUEST = `${PREFIX}/FETCH_USER_FILTERS_REQUEST`;
export const FETCH_USER_FILTERS_SUCCESS = `${PREFIX}/FETCH_USER_FILTERS_SUCCESS`;
export const FETCH_USER_FILTERS_FAILURE = `${PREFIX}/FETCH_USER_FILTERS_FAILURE`;

export const CREATE_USER_FILTER_REQUEST = `${PREFIX}/CREATE_USER_FILTER_REQUEST`;
export const CREATE_USER_FILTER_SUCCESS = `${PREFIX}/CREATE_USER_FILTER_SUCCESS`;
export const CREATE_USER_FILTER_FAILURE = `${PREFIX}/CREATE_USER_FILTER_FAILURE`;

export const DELETE_USER_FILTER_REQUEST = `${PREFIX}/DELETE_USER_FILTER_REQUEST`;
export const DELETE_USER_FILTER_SUCCESS = `${PREFIX}/DELETE_USER_FILTER_SUCCESS`;
export const DELETE_USER_FILTER_FAILURE = `${PREFIX}/DELETE_USER_FILTER_FAILURE`;

export const UPDATE_USER_FILTER_REQUEST = `${PREFIX}/UPDATE_USER_FILTER_REQUEST`;
export const UPDATE_USER_FILTER_SUCCESS = `${PREFIX}/UPDATE_USER_FILTER_SUCCESS`;
export const UPDATE_USER_FILTER_FAILURE = `${PREFIX}/UPDATE_USER_FILTER_FAILURE`;

export const FETCH_TEAM_FILTERS_REQUEST = `${PREFIX}/FETCH_TEAM_FILTERS_REQUEST`;
export const FETCH_TEAM_FILTERS_SUCCESS = `${PREFIX}/FETCH_TEAM_FILTERS_SUCCESS`;
export const FETCH_TEAM_FILTERS_FAILURE = `${PREFIX}/FETCH_TEAM_FILTERS_FAILURE`;

export const CREATE_TEAM_FILTER_REQUEST = `${PREFIX}/CREATE_TEAM_FILTER_REQUEST`;
export const CREATE_TEAM_FILTER_SUCCESS = `${PREFIX}/CREATE_TEAM_FILTER_SUCCESS`;
export const CREATE_TEAM_FILTER_FAILURE = `${PREFIX}/CREATE_TEAM_FILTER_FAILURE`;

export const DELETE_TEAM_FILTER_REQUEST = `${PREFIX}/DELETE_TEAM_FILTER_REQUEST`;
export const DELETE_TEAM_FILTER_SUCCESS = `${PREFIX}/DELETE_TEAM_FILTER_SUCCESS`;
export const DELETE_TEAM_FILTER_FAILURE = `${PREFIX}/DELETE_TEAM_FILTER_FAILURE`;

export const UPDATE_TEAM_FILTER_REQUEST = `${PREFIX}/UPDATE_TEAM_FILTER_REQUEST`;
export const UPDATE_TEAM_FILTER_SUCCESS = `${PREFIX}/UPDATE_TEAM_FILTER_SUCCESS`;
export const UPDATE_TEAM_FILTER_FAILURE = `${PREFIX}/UPDATE_TEAM_FILTER_FAILURE`;
