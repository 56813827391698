import React from 'react';
import PropTypes from 'prop-types';
import humanize from 'humanize-number';
import pluralize from 'pluralize';
import { isEmpty, omit } from 'ramda';
import { Link } from 'react-router';

import AdjustIcon from '../../mc-ui/icons/AdjustIcon';
import DownloadEmailsDropdown from '../../DownloadEmailsDropdown';
import * as downloadEmailsDropdownStyles from '../../DownloadEmailsDropdown/styles';
import FYICircleIcon from '../../mc-ui/icons/FYICircleIcon';
import { Box, Button, Flex, Heading, Text, Tooltip, theme } from '../../mc-ui';

import * as S from './styles';
import SortSelect from './SortSelect';

// eslint-disable-next-line react/prop-types
function ControlBarDownloadButton({ restricted, ...props }) {
  if (restricted) {
    return (
      <Tooltip
        element={<downloadEmailsDropdownStyles.DropdownButton disabled variant="secondary" />}
        id="exportDropdownTooltip"
        css={{
          height: '32px',
          width: '120px',
          '& > span': {
            display: 'inline-block',
            height: '32px',
            margin: '0',
            width: '120px',
          },
          '& [data-id="tooltip"]': {
            marginLeft: '0 !important',
          },
        }}
      >
        <Heading as="h5">Unlock Historical Data</Heading>

        <Text>View emails from customized and predefined date ranges for deeper insight.</Text>

        <Button
          as={Link}
          to="/manage/plan"
          css={{
            alignItems: 'center',
            display: 'inline-flex',
            height: '32px !important',
            justifyContent: 'center',
            margin: '0',
            padding: '0',
            width: '116px !important',
          }}
        >
          Upgrade Now
        </Button>
      </Tooltip>
    );
  }

  return <DownloadEmailsDropdown {...props} />;
}

function ControlBar({
  editing,
  loading,
  model,
  toggleBulk,
  toggleFilters,
  total,
  restricted,
  router,
  view,
}) {
  const pathName = /\/(inbox)$/.test(router.location.pathname)
    ? router.location.pathname.replace(/\/(inbox)$/, '')
    : router.location.pathname;
  const { query } = router.location;

  const isAllDisabled =
    model &&
    model.type === 'lists' &&
    total === 0 &&
    isEmpty(omit(['advanced'], router.location.query));

  return (
    <Flex
      alignItems="center"
      backgroundColor="white"
      borderRadius="4px"
      boxShadow={`0px 1px 4px ${theme.colors.dark02}`}
      justifyContent="space-between"
      padding="16px"
    >
      <Flex alignItems="center" flex="1">
        <S.ViewButtons>
          <S.ViewButtons.ThumbnailButton
            active={view === 'thumbnail'}
            to={{
              pathname: pathName,
              query,
            }}
            disabled={restricted || isAllDisabled}
          />

          <S.ViewButtons.ListButton
            active={view !== 'thumbnail'}
            to={{
              pathname: `${pathName}/inbox`,
              query,
            }}
            disabled={restricted || isAllDisabled}
          />
        </S.ViewButtons>

        <SortSelect disabled={restricted || isAllDisabled} router={router} />

        {!loading && (
          <Text fontSize="12px" lineHeight="32px" margin="0 0 0 8px">
            {humanize(total)} {pluralize('email', total)}
          </Text>
        )}
      </Flex>

      <Flex alignItems="center" justifyContent="flex-end" flex="1">
        <Tooltip
          variant="blue"
          element={
            <Box
              inline
              height="24px"
              width="24px"
              css={{ color: theme.colors.dark06, '&:hover': { color: theme.colors.dark1 } }}
            >
              <FYICircleIcon height="24px" width="24px" />
            </Box>
          }
          id="advancedFiltersTooltip"
          css={{
            height: '24px',
            width: '24px',
            '& > span': {
              display: 'inline-block',
              height: '24px',
              margin: '0',
              width: '24px',
            },
            '& [data-id="tooltip"]': {
              marginLeft: '0 !important',
            },
          }}
        >
          <Heading as="h5">Filters</Heading>

          <Text>
            Looking for a specific kind of email? Click &quot;Filter&quot; and choose your search
            options or click to watch a two-minute video to learn how to use filters.
          </Text>

          <Button
            as="a"
            href="https://youtu.be/xoUhBnC6D5M"
            target="_blank"
            css={{
              alignItems: 'center',
              display: 'inline-flex',
              height: '32px !important',
              justifyContent: 'center',
              margin: '0',
              padding: '0',
              width: '104px !important',
            }}
          >
            Watch now
          </Button>
        </Tooltip>

        <Button
          css={{
            alignItems: 'center',
            display: 'inline-flex',
            height: '32px',
            justifyContent: 'center',
            lineHeight: '32px',
            margin: '0 8px',
            padding: 0,
            width: '80px',
            '& > svg': { marginLeft: '4px' },
          }}
          disabled={isAllDisabled}
          onClick={toggleFilters}
          variant="primary"
        >
          Filter
          <AdjustIcon />
        </Button>

        {!editing && (
          <ControlBarDownloadButton
            disabled={loading || isAllDisabled}
            model={model}
            restricted={restricted}
            totalEmails={total}
            variant="secondary"
          />
        )}

        {!editing && <S.BulkButton disabled={restricted || isAllDisabled} onClick={toggleBulk} />}

        {editing && (
          <S.CancelBulkButton disabled={restricted || isAllDisabled} onClick={toggleBulk}>
            Cancel
          </S.CancelBulkButton>
        )}
      </Flex>
    </Flex>
  );
}

ControlBar.defaultProps = {
  model: null,
  restricted: false,
  total: 0,
};

ControlBar.propTypes = {
  editing: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  model: PropTypes.shape({}),
  restricted: PropTypes.bool,
  router: PropTypes.shape({
    location: PropTypes.shape({
      query: PropTypes.shape({}),
    }),
    push: PropTypes.func,
  }).isRequired,
  toggleBulk: PropTypes.func.isRequired,
  toggleFilters: PropTypes.func.isRequired,
  total: PropTypes.number,
  view: PropTypes.string.isRequired,
};

export default ControlBar;
