/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import moment from 'moment';
import pluralize from 'pluralize';
import { Link } from 'react-router';
import { take } from 'ramda';

import { Box, Grid, Heading, Text, theme } from '../mc-ui';
import BrandLogo from './BrandLogo';

export default function GroupCard({ children, group }) {
  return (
    <Box
      backgroundColor="white"
      border="1px solid"
      borderColor="dark5"
      borderRadius="4px"
      height="148px"
      position="relative"
      width="248px"
      css={{
        '&:hover': {
          boxShadow: `0 2px 8px ${theme.colors.dark02}`,
          '& h4': {
            color: `${theme.colors.blue} !important`,
          },
        },
      }}
    >
      <Link
        to={`/groups/${group.id}`}
        css={{
          borderRadius: '4px',
          boxSizing: 'border-box',
          display: 'block',
          height: '148px',
          padding: '16px',
          position: 'relative',
          textDecoration: 'none',
          width: '248px',
        }}
      >
        {group.attributes.approximate_companies > 0 && (
          <Grid columnGap="8px" gridTemplateColumns="repeat(4, 1fr)" css={{ marginBottom: '16px' }}>
            {take(4, group.relationships.companies.data).map(brand => (
              <BrandLogo
                key={`GroupCard-${group.id}-${brand.id}`}
                brand={brand}
                css={{ height: '48px', width: '48px' }}
              />
            ))}
          </Grid>
        )}

        <Box>
          <Heading as="h4" css={{ fontSize: '14px', lineHeight: '1.25em' }}>
            {group.attributes.name}
          </Heading>

          <Text fontSize="12px">
            {group.attributes.approximate_companies}{' '}
            {pluralize('brand', group.attributes.approximate_companies)}. Added{' '}
            {moment(group.attributes.updated_at).fromNow()}
          </Text>
        </Box>
      </Link>
      {children && children(group)}
    </Box>
  );
}

GroupCard.defaultProps = {
  children: null,
};

GroupCard.propTypes = {
  children: PropTypes.func,
  group: PropTypes.shape({}).isRequired,
};
