import React from 'react';
import PropTypes from 'prop-types';

import { Flex } from '../../mc-ui';

const Row = ({ children, full }) => (
  <Flex css={{ marginBottom: '16px', width: full ? '100%' : '50%' }}>{children}</Flex>
);

Row.defaultProps = {
  full: false,
};

Row.propTypes = {
  children: PropTypes.node.isRequired,
  full: PropTypes.bool,
};

export default Row;
