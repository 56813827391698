/** @jsx jsx */
import React from 'react';
import PropTypes from 'prop-types';
import humanize from 'humanize-number';
import moment from 'moment';
import pluralize from 'pluralize';
import { Link } from 'react-router';
import { jsx, withTheme } from '@emotion/react';

import { Box, Flex, Heading, Text } from '../../mc-ui';
import EmailIcon from '../../mc-ui/icons/EmailIcon';
import PromoIcon from '../../mc-ui/icons/PromoIcon';

import BrandLogo from '../BrandLogo';

import EmailsBackground from './EmailsBackground';

const BrandCard = ({ brand, theme }) => (
  <Link
    to={`/companies/${brand.id}`}
    css={{
      border: `1px solid ${theme.colors.dark5}`,
      borderRadius: '4px',
      boxSizing: 'border-box',
      display: 'block',
      height: '240px',
      textDecoration: 'none',
      width: '247px',
      '&:hover': {
        boxShadow: `0 2px 8px ${theme.colors.dark02}`,
        '& h4': {
          color: `${theme.colors.blue} !important`,
        },
      },
    }}
  >
    <Box borderRadius="4px" height="240px" padding="16px" position="relative" width="247px">
      <EmailsBackground brand={brand} />

      <Box position="relative">
        <BrandLogo brandId={brand.id} css={{ height: '64px', width: '64px' }} />
        <Heading as="h4" css={{ fontSize: '16px', lineHeight: '1.25em', margin: '8px 0' }}>
          {brand.attributes.name}
        </Heading>

        <Flex alignItems="center" color="dark08" marginBottom="5px">
          <EmailIcon fillOpacity={1} height="20px" width="20px" />
          <Text color="dark08" fontSize="12px" css={{ marginLeft: '6px' }}>
            Avg. Emails / Week: <strong>{(brand.attributes.frequency || 0).toFixed(2)}</strong>
          </Text>
        </Flex>

        <Flex alignItems="center" color="dark08" marginBottom="5px">
          <PromoIcon fillOpacity={1} height="20px" width="20px" />
          <Text color="dark08" fontSize="12px" css={{ marginLeft: '6px' }}>
            Avg. Promo Rate: <strong>{(brand.attributes.promotional || 0).toFixed(2)}%</strong>
          </Text>
        </Flex>

        <Text color="dark08" fontSize="12px">
          <strong>
            {humanize(brand.attributes.approximateEmails)}{' '}
            {pluralize('email', brand.attributes.approximateEmails)}
          </strong>{' '}
          since <strong>{moment(brand.attributes.firstEmailSentAt || new Date).format('MMM YYYY')}</strong>
        </Text>
      </Box>
    </Box>
  </Link>
);

BrandCard.propTypes = {
  brand: PropTypes.shape({}).isRequired,
  theme: PropTypes.shape({}).isRequired,
};

export default withTheme(BrandCard);
