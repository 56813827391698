/** @jsx jsx */
import { jsx } from '@emotion/react';
import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { isEmpty } from 'ramda';

import { Button, Flex, Select } from '../../../mc-ui';

class CompareWithBrandForm extends React.Component {
  onSubmit = values => {
    this.props.onSubmit(values.brand);
  };

  render() {
    const { brands } = this.props;

    return (
      <Form onSubmit={this.onSubmit}>
        {({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit}>
            <Flex css={{ height: '32px' }}>
              <div
                css={{ marginRight: '8px', width: '320px' }}
              >
                <Field
                  component={Select}
                  id="brand"
                  name="brand"
                  options={brands.map(brand => ({
                    value: { id: brand.id, name: brand.attributes.name },
                    label: brand.attributes.name,
                  }))}
                  placeholder={isEmpty(brands) ? 'Loading...' : 'Select a brand'}
                />
              </div>
              <Button css={{ padding: '0 0.803em' }} type="submit" variant="primary">
                Compare
              </Button>
            </Flex>
          </form>
        )}
      </Form>
    );
  }
}

CompareWithBrandForm.propTypes = {
  brands: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default CompareWithBrandForm;
