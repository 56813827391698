import styled from "@emotion/styled";

const Overlay = styled("div")(({ theme }) => ({
  backgroundColor: theme.colors.dark06,
  boxSizing: 'border-box',
  height: '100vh',
  left: '0',
  overflowY: 'scroll',
  position: 'fixed',
  top: '0',
  width: '100vw',
  zIndex: '9995',
}));

export default Overlay;
