const INITIAL_STATE = { active: false, useNewComponent: false };

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SHOW_MODAL':
      return { ...state, active: true, ...action };
    case 'HIDE_MODAL':
      return INITIAL_STATE;
    default:
      return { ...state };
  }
};
