import React from 'react';
import PropTypes from 'prop-types';
import { Text as MCUIText } from '../../mc-ui';

const Text = ({ css, variant, ...props }) => (
  <MCUIText
    color="dark1"
    css={{
      fontSize: variant === 'bold' ? '18px' : '24px',
      fontWeight: variant === 'bold' ? '500' : '300',
      letterSpacing: '0.02em',
      lineHeight: '1.32em',
      ...css,
    }}
    {...props}
  />
);

Text.defaultProps = {
  css: {},
  variant: 'big',
};
Text.propTypes = {
  css: PropTypes.shape({}),
  variant: PropTypes.string,
};

export default Text;
