import * as React from 'react';
import PropTypes from 'prop-types';

function ChartLineIcon({ fillOpacity, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.748 7.837a1 1 0 01-.085 1.411l-5.64 5a1 1 0 01-1.313.013l-3.409-2.908-4.988 4.863a1 1 0 01-1.44-.046l-3.615-4a1 1 0 111.484-1.34l2.919 3.229 4.897-4.775a1 1 0 011.347-.045l3.441 2.936 4.99-4.423a1 1 0 011.412.085zM11 4H8V2h3v2zM5 4H2V2h3v2zM17 4h-3V2h3v2zM23 4h-3V2h3v2zM22 22H2v-2h20v2z"
        fill="currentColor"
        fillOpacity={fillOpacity}
      />
      <path d="M19 7h3.5v4L19 7z" fill="#000" />
    </svg>
  );
}

ChartLineIcon.propTypes = {
  fillOpacity: PropTypes.number,
};

ChartLineIcon.defaultProps = {
  fillOpacity: 1,
};

export default ChartLineIcon;
