import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';

import { teamsActions, teamsSelectors } from '../../modules/teams';

import { Box, Button, Flex, Heading } from '../mc-ui';

import PlanSwitcher from './PlanSwitcher';

class MyPlan extends React.Component {
  componentDidMount() {
    const { requestTeam, team } = this.props;
    if (team) requestTeam(team.id);
  }

  render() {
    const {
      isOwner,
      team,
      user: { data: userData, loading: userLoading },
    } = this.props;

    const [user] = userData;
    if (userLoading || !user) return null;

    return (
      <Box backgroundColor="white" borderRadius="4px" marginTop="1em" padding="32px">
        <Flex alignItems="flex-start" justifyContent="space-between" marginBottom="24px">
          <Heading as="h3" color="dark08" fontSize="24px" fontWeight="300" lineHeight="32px">
            Plan and Billing
          </Heading>

          <Button
            as={Link}
            to="https://knowledge.validity.com/hc/en-us/sections/6839689729819-FAQ"
            target="_blank"
            variant="secondary"
            css={{
              alignItems: 'center',
              display: 'inline-flex',
              height: '32px',
              justifyContent: 'center',
              lineHeight: '32px',
              margin: '0',
              padding: '0',
              width: '56px',
            }}
          >
            FAQ
          </Button>
        </Flex>

        <PlanSwitcher isOwner={isOwner} team={team} user={user} />
      </Box>
    );
  }
}

MyPlan.defaultProps = {
  team: null,
};

MyPlan.propTypes = {
  isOwner: PropTypes.bool.isRequired,
  requestTeam: PropTypes.func.isRequired,
  team: PropTypes.shape({}),
  user: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
    loading: PropTypes.bool,
  }).isRequired,
};

const mapStateToProps = state => ({
  isOwner: teamsSelectors.isOwnerSelector(state),
  team: teamsSelectors.teamSelector(state),
  user: state.user,
});
const mapDispatchToProps = dispatch => ({
  requestTeam: teamId => dispatch(teamsActions.fetchTeamRequest(teamId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MyPlan);
