import { assocPath } from 'ramda';

import * as types from './types';

/**
 * State structure is divided per date, like the following
 * {
 *   brands: {
 *     [brandId]: {
 *       brandOptions: {
 *         data: array,
 *         error: string,
 *         loading: boolean,
 *       }
 *       comparison: {
 *         premium: {
 *           data: object,
 *           error: string,
 *           loading: boolean,
 *         }
 *       },
 *     },
 *   },
 *   groups: {
 *     [groupId]: {
 *       brandsOptions: {
 *         data: array,
 *         error: string,
 *         loading: boolean,
 *       }
 *       comparison: {
 *         [brandId | 'premium']: {
 *           brandName: string,
 *           data: object,
 *           error: string,
 *           loading: boolean,
 *         }
 *       },
 *     },
 *   },
 * }
 */
const INITIAL_STATE = {
  brands: {},
  groups: {},
};

const setGroupBrandsOptionsLoading = (state, loading, { modelId }) =>
  assocPath(['groups', `${modelId}`, 'brandsOptions', 'loading'], loading, state);

const setGroupBrandsOptionsData = (state, { modelId, payload }) =>
  assocPath(['groups', `${modelId}`, 'brandsOptions'], { data: payload, loading: false }, state);

const setGroupBrandsOptionsError = (state, { modelId, error }) =>
  assocPath(['groups', `${modelId}`, 'brandsOptions'], { error, loading: false }, state);

const toggleGroupBrandsOptionsLoading = (state, { modelId }) => {
  const group = state.groups[modelId] || {};
  const { loading } = group.brandsOptions || { loading: false };
  return assocPath(['groups', `${modelId}`, 'brandsOptions', 'loading'], !loading, state);
};

const setGroupComparisonLoading = (state, loading, { modelId, brandId }) =>
  assocPath(
    ['groups', `${modelId}`, 'comparison', `${brandId || 'premium'}`, 'loading'],
    loading,
    state,
  );

const setGroupComparisonError = (state, { modelId, brandId, error }) =>
  assocPath(
    ['groups', `${modelId}`, 'comparison', `${brandId || 'premium'}`],
    { error, loading: false },
    state,
  );

const setGroupComparisonData = (state, { modelId, brandId, payload }) =>
  assocPath(
    ['groups', `${modelId}`, 'comparison', `${brandId || 'premium'}`],
    { ...payload, loading: false },
    state,
  );

const toggleGroupComparisonLoading = (state, { modelId, brandId }) => {
  const group = state.groups[modelId];
  const { loading } = group.comparison[brandId || 'premium'] || { loading: false };
  return assocPath(
    ['groups', `${modelId}`, 'comparison', `${brandId || 'premium'}`, 'loading'],
    !loading,
    state,
  );
};

const setBrandOptionsLoading = (state, loading, { modelId }) =>
  assocPath(['brands', `${modelId}`, 'brandOptions', 'loading'], loading, state);

const setBrandOptionsData = (state, { modelId, payload }) =>
  assocPath(['brands', `${modelId}`, 'brandOptions'], { data: payload, loading: false }, state);

const setBrandComparisonLoading = (state, loading, { modelId }) =>
  assocPath(['brands', `${modelId}`, 'comparison', 'premium', 'loading'], loading, state);

const setBrandComparisonError = (state, { modelId, error }) =>
  assocPath(['brands', `${modelId}`, 'comparison', 'premium'], { error, loading: false }, state);

const setBrandComparisonData = (state, { modelId, payload }) =>
  assocPath(
    ['brands', `${modelId}`, 'comparison', 'premium'],
    { ...payload, loading: false },
    state,
  );

const toggleBrandComparisonLoading = (state, { modelId }) => {
  const brand = state.brands[modelId];
  const { loading } = brand.comparison.premium || { loading: false };
  return assocPath(['brands', `${modelId}`, 'comparison', 'premium', 'loading'], !loading, state);
};

const reducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case types.FETCH_BRAND_OPTIONS_REQUEST: {
      return setBrandOptionsLoading(state, true, action);
    }
    case types.FETCH_BRAND_OPTIONS_SUCCESS: {
      return setBrandOptionsData(state, action);
    }
    case types.FETCH_BRAND_DATA_REQUEST: {
      return setBrandComparisonLoading(state, true, action);
    }
    case types.FETCH_BRAND_DATA_FAILURE: {
      return setBrandComparisonError(state, action);
    }
    case types.FETCH_BRAND_DATA_SUCCESS: {
      return setBrandComparisonData(state, action);
    }
    case types.TOGGLE_BRAND_DATA_LOADING: {
      return toggleBrandComparisonLoading(state, action);
    }
    case types.FETCH_GROUP_BRANDS_REQUEST: {
      return setGroupBrandsOptionsLoading(state, true, action);
    }
    case types.FETCH_GROUP_BRANDS_FAILURE: {
      return setGroupBrandsOptionsError(state, action);
    }
    case types.FETCH_GROUP_BRANDS_SUCCESS: {
      return setGroupBrandsOptionsData(state, action);
    }
    case types.TOGGLE_GROUP_BRANDS_LOADING: {
      return toggleGroupBrandsOptionsLoading(state, action);
    }
    case types.FETCH_GROUP_DATA_REQUEST: {
      return setGroupComparisonLoading(state, true, action);
    }
    case types.FETCH_GROUP_DATA_FAILURE: {
      return setGroupComparisonError(state, action);
    }
    case types.FETCH_GROUP_DATA_SUCCESS: {
      return setGroupComparisonData(state, action);
    }
    case types.TOGGLE_GROUP_DATA_LOADING: {
      return toggleGroupComparisonLoading(state, action);
    }
    default: {
      return state;
    }
  }
};

export default reducer;
