/** @jsx jsx */
import PropTypes from 'prop-types';
import { jsx, withTheme } from '@emotion/react';

import { Flex, Text } from '../../../../mc-ui';

const Labels = ({ data, theme }) => (
  <Flex direction="column" css={{ marginTop: '4px' }}>
    {data.map(item => (
      <Flex
        key={`Labels-${item.color}-${item.label}`}
        css={{ marginTop: '20px', width: '168px' }}
        justifyContent="space-between"
      >
        <Flex>
          <div
            css={{
              backgroundColor: theme.colors[item.color],
              borderRadius: '50%',
              height: '16px',
              marginRight: '8px',
              width: '16px',
            }}
          />
          <Text
            css={{
              color: theme.colors.dark1,
              fontWeight: 500,
              fontSize: '12px',
              letterSpacing: '0.02em',
              lineHeight: '125%',
            }}
          >
            {item.label}
          </Text>
        </Flex>
        <Text
          css={{
            color: theme.colors.dark1,
            fontWeight: 500,
            fontSize: '12px',
            letterSpacing: '0.02em',
            lineHeight: '125%',
          }}
        >
          {(item.width * 100).toFixed(2)}%
        </Text>
      </Flex>
    ))}
  </Flex>
);

Labels.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      label: PropTypes.string,
      width: PropTypes.number,
    }),
  ).isRequired,
  theme: PropTypes.shape({}).isRequired,
};

export default withTheme(Labels);
