import styled from "@emotion/styled";

const Wrapper = styled("div")(({ theme, css = {} }) => ({
  backgroundColor: theme.colors.white,
  borderRadius: '4px',
  boxSizing: 'border-box',
  marginBottom: '32px',
  padding: css.padding || '32px',
  textDecoration: 'none',
  width: css.width || '338px',
  minHeight: css.minHeight || '512px',
}));

export default Wrapper;
