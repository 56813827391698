import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styles';

const NavigationDropdown = ({ children, ...props }) => (
  <S.Wrapper {...props}>
    <div>{children}</div>
  </S.Wrapper>
);

NavigationDropdown.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

NavigationDropdown.Divider = S.Divider;
NavigationDropdown.Item = S.Item;
NavigationDropdown.Text = S.Text;

export default NavigationDropdown;
