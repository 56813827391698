import React from 'react';

import { Badge, Button } from '../mc-ui';
import DownloadIcon from '../mc-ui/icons/DownloadIcon';

export const DownloadButton = props => (
  <Button
    variant="none"
    css={{
      alignItems: 'center',
      display: 'inline-flex',
      fontSize: '24px',
      height: '24px',
      lineHeight: '24px',
      marginLeft: '0',
      padding: '0',
    }}
    {...props}
  >
    <DownloadIcon />
  </Button>
);

export const PaidBadge = () => (
  <Badge backgroundColor="greenMedium" color="white">
    Paid
  </Badge>
);

export const UnpaidBadge = () => (
  <Badge backgroundColor="redDark" color="white">
    Unpaid
  </Badge>
);
