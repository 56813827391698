import React from 'react';
import PropTypes from 'prop-types';
import { last } from 'ramda';
import { connect } from 'react-redux';

import * as U from '../../../lib/utils';

import { teamsSelectors } from '../../../modules/teams';
import { userSelectors } from '../../../modules/user';

import { Flex, Heading, theme } from '../../mc-ui';

import * as S from './styles';

const titleize = str =>
  U.titleize(str)
    .replace(/-/gi, ' ')
    .replace(/sms/i, 'SMS');

function ControlBar(
  { collectionType, hideSelector, isMembershipActive, isUserRestricted },
  { router },
) {
  const collectionName = last(router.location.pathname.split('/'));
  const title =
    collectionName === 'text-message-lists' ? titleize('sms-lists') : titleize(collectionName);

  return (
    <Flex
      alignItems="center"
      backgroundColor="white"
      borderRadius="4px"
      boxShadow={`0px 1px 4px ${theme.colors.dark02}`}
      justifyContent="space-between"
      marginBottom="24px"
      padding="16px"
    >
      <Flex alignItems="center" flex="1">
        <Heading as="h3" color="dark08" fontSize="24px" fontWeight="300" lineHeight="1.32em">
          {collectionName === 'notes' ? 'All Notes' : title}
        </Heading>
      </Flex>

      {!isUserRestricted &&
        isMembershipActive &&
        !hideSelector && (
          <S.CollectionTypeButtons>
            <S.CollectionTypeButtons.MyButton
              active={collectionType === 'my'}
              to={`/my-collections/${collectionName}`}
            >
              My {title}
            </S.CollectionTypeButtons.MyButton>

            <S.CollectionTypeButtons.TeamButton
              active={collectionType === 'team'}
              to={`/team-collections/${collectionName}`}
            >
              Team {title}
            </S.CollectionTypeButtons.TeamButton>
          </S.CollectionTypeButtons>
        )}
    </Flex>
  );
}

ControlBar.contextTypes = {
  router: PropTypes.object,
};

ControlBar.defaultProps = {
  hideSelector: false,
};

ControlBar.propTypes = {
  collectionType: PropTypes.string.isRequired,
  hideSelector: PropTypes.bool,
  isMembershipActive: PropTypes.bool.isRequired,
  isUserRestricted: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  isMembershipActive: teamsSelectors.isMembershipActiveSelector(state),
  isUserRestricted: userSelectors.isUserRestrictedSelector(state),
});

export default connect(mapStateToProps)(ControlBar);
