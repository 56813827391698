import React from 'react';
import PropTypes from 'prop-types';

function ChartIcon({ fillOpacity, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.333 1.667H1.667v16.666h1.666V1.667zm7.334 1.666H5v3.334h5.667a1 1 0 001-1V4.333a1 1 0 00-1-1zM5 13.333h9a1 1 0 011 1v1.334a1 1 0 01-1 1H5v-3.334zm11.5-5H5v3.334h11.5a1 1 0 001-1V9.333a1 1 0 00-1-1z"
        fill="currentColor"
        fillOpacity={fillOpacity}
      />
    </svg>
  );
}

ChartIcon.propTypes = {
  fillOpacity: PropTypes.number,
};

ChartIcon.defaultProps = {
  fillOpacity: 0.6,
};

export default ChartIcon;
