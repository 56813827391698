import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Field, Form } from 'react-final-form';
import api from '../../../../lib/api';
import { textMessagesHelpers } from '../../../../modules/textMessages';
import {
  Button,
  Checkbox,
  CheckboxGroup,
  DateRangePicker,
  Flex,
  Label,
  Modal,
  MultiSelect,
  TextField,
} from '../../../mc-ui';

export default class FiltersModal extends React.PureComponent {
  constructor(props) {
    super(props);

    const query = textMessagesHelpers.filterQuery(props.router.location.query, props.router.params);

    this.state = {
      endDate: query.end_date ? moment(query.end_date) : null,
      focusedInput: null,
      groups: {
        loading: true,
        data: [],
      },
      startDate: query.start_date ? moment(query.start_date) : null,
    };
  }

  componentDidMount() {
    this.fetchGroups();
  }

  onSubmit = values => {
    const { onClose, router } = this.props;
    const query = textMessagesHelpers.filterQuery(values, router.params);

    if (query.end_date && query.start_date) {
      query.end_date = moment(query.end_date).format('YYYY-MM-DD');
      query.start_date = moment(query.start_date).format('YYYY-MM-DD');
    }

    router.push({
      pathname: router.location.pathname,
      query,
    });

    onClose();
  };

  onDateRangeChange = form => ({ startDate, endDate }) => {
    this.setState({ startDate, endDate }, () => {
      form.change('start_date', startDate);
      form.change('end_date', endDate);
    });
  };

  onDateRangeFocus = form => focusedInput => {
    this.setState({ focusedInput }, () => {
      form.focus(focusedInput);
    });
  };

  onFormReset = form => () => {
    form.reset({});
    this.setState({ startDate: null, endDate: null });
  };

  fetchGroups = async () => {
    if (this.state.groups.loading && !this.state.groups.data.length) {
      const response = await api.get('/v3/groups', {
        params: { sort: 'name:asc', global: true, limit: 250 },
      });

      this.setState({
        groups: {
          loading: false,
          ...response.data,
        },
      });
    }
  };

  render() {
    const { model, onClose, open, router } = this.props;
    const { endDate, focusedInput, groups, startDate } = this.state;
    const initialValues = {
      start_date: '',
      end_date: '',
      ...textMessagesHelpers.filterQuery(router.location.query, router.params),
    };

    return (
      <Modal isOpen={open} onClose={onClose} padding="0">
        <Modal.Header onClose={onClose} wrapperCss={{ padding: '32px 32px 0 32px' }}>
          Filters
        </Modal.Header>

        <Form initialValues={initialValues} onSubmit={this.onSubmit}>
          {({ form, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body padding="32px">
                <Flex marginBottom="24px">
                  <Label htmlFor="q" width="736px">
                    Search by keyword
                    <Field
                      autoComplete="textMessageQuery"
                      component={TextField}
                      id="q"
                      name="q"
                      placeholder="Type a keyword here"
                    />
                  </Label>
                </Flex>

                <Flex justifyContent="space-between" marginBottom="24px">
                  {!model && (
                    <Label htmlFor="group_id" width="352px">
                      Industries
                      <Field
                        component={MultiSelect}
                        id="group_id"
                        name="group_id"
                        options={groups.data.map(g => ({ label: g.attributes.name, value: g.id }))}
                        isDisabled={groups.loading}
                      />
                    </Label>
                  )}

                  <Label htmlFor="start_date" width="352px">
                    Date range
                    <DateRangePicker
                      endDate={endDate}
                      endDateId="end_date"
                      focusedInput={focusedInput}
                      onDatesChange={this.onDateRangeChange(form)}
                      onFocusChange={this.onDateRangeFocus(form)}
                      startDate={startDate}
                      startDateId="start_date"
                      css={{
                        justifyContent: 'flex-start',
                        width: '100%',
                        '& > svg': {
                          display: 'none',
                        },
                        '& > .DateRangePicker': {
                          width: '100%',
                        },
                        '& .DateRangePickerInput': {
                          '& .DateInput': {
                            width: 'calc(50% - 7px)',
                          },
                        },
                      }}
                    />
                  </Label>
                </Flex>

                <Flex>
                  <CheckboxGroup title="Content" width="228px">
                    <Field
                      component={Checkbox}
                      type="checkbox"
                      id="animated"
                      name="animated"
                      label="Contains animation/GIF"
                    />

                    <Field
                      component={Checkbox}
                      type="checkbox"
                      id="emoji"
                      name="emoji"
                      label="Contains emojis"
                    />
                  </CheckboxGroup>
                </Flex>
              </Modal.Body>

              <Modal.Footer
                borderColor="dark5"
                borderTop="1px solid"
                justifyContent="flex-end"
                paddingBottom="12px"
                paddingLeft="32px"
                paddingRight="32px"
                paddingTop="12px"
              >
                <Button
                  variant="secondary"
                  onClick={this.onFormReset(form)}
                  css={{ marginRight: '16px' }}
                >
                  Reset
                </Button>

                <Button variant="primary" type="submit">
                  Filter
                </Button>
              </Modal.Footer>
            </form>
          )}
        </Form>
      </Modal>
    );
  }
}

FiltersModal.defaultProps = {
  model: null,
};

FiltersModal.propTypes = {
  model: PropTypes.shape({}),
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  router: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
      query: PropTypes.object,
    }),
    params: PropTypes.shape({}),
    push: PropTypes.func,
  }).isRequired,
};
