import React from 'react';
import { call, put, select } from 'redux-saga/effects';
import { camelizeKeys } from 'humps';

import { Link } from '../../components/mc-ui';

import { flashMessageActions } from '../flashMessage';

import * as actions from './actions';
import * as api from './api';
import * as selectors from './selectors';

// eslint-disable-next-line react/prop-types
function Message({ children }) {
  return <span>{children}</span>;
}

export function* fetchMySavedJourneys() {
  try {
    const response = yield call(api.fetchMySavedJourneys);
    yield put(actions.fetchMySavedJourneysSuccess(camelizeKeys(response.data)));
  } catch (error) {
    console.log('savedJourneys/sagas › fetchMySavedJourneys › error', error);
    yield put(actions.fetchMySavedJourneysFailure(error));
  }
}

export function* saveJourneyToMyList({ id }) {
  try {
    const response = yield call(api.saveJourneyToMyList, id);
    yield put(actions.saveJourneyToMyListSuccess(camelizeKeys(response.data)));
    yield put(
      flashMessageActions.showSuccess({
        body: (
          <Message>
            Journey has been saved to your{' '}
            <Link to="/my-collections/saved-journeys">collections</Link>.
          </Message>
        ),
      }),
    );
    yield call(window.analytics.track, 'Journey saved (personal)', { journey_id: id });
  } catch (error) {
    console.log('savedJourneys/sagas › saveJourneyToMyList › error', error);
    yield put(actions.saveJourneyToMyListFailure(error));
  }
}

export function* removeJourneyFromMyList({ id }) {
  try {
    const response = yield call(api.deleteJourneyFromMyList, id);
    yield put(actions.removeJourneyFromMyListSuccess(camelizeKeys(response.data)));
    yield put(
      flashMessageActions.showSuccess({
        body: (
          <Message>
            Journey has been removed from your{' '}
            <Link to="/my-collections/saved-journeys">collections</Link>.
          </Message>
        ),
      }),
    );
    yield call(window.analytics.track, 'Journey removed (personal)', { journey_id: id });
  } catch (error) {
    console.log('savedJourneys/sagas › removeJourneyFromMyList › error', error);
    yield put(actions.removeJourneyFromMyListFailure(error));
  }
}

export function* fetchTeamSavedJourneys() {
  try {
    const team = yield select(selectors.teamSelector);
    if (!team) {
      yield put(actions.fetchTeamSavedJourneysSuccess([]));
      return;
    }
    const response = yield call(api.fetchTeamSavedJourneys, team.id);
    yield put(actions.fetchTeamSavedJourneysSuccess(camelizeKeys(response.data)));
  } catch (error) {
    console.log('savedJourneys/sagas › fetchTeamSavedJourneys › error', error);
    yield put(actions.fetchTeamSavedJourneysFailure(error));
  }
}

export function* saveJourneyToTeamList({ id }) {
  try {
    const team = yield select(selectors.teamSelector);
    const response = yield call(api.saveJourneyToTeamList, team.id, id);
    yield put(actions.saveJourneyToTeamListSuccess(camelizeKeys(response.data)));
    yield put(
      flashMessageActions.showSuccess({
        body: (
          <Message>
            Journey has been saved to your{' '}
            <Link to="/team-collections/saved-journeys">collections</Link>.
          </Message>
        ),
      }),
    );
    yield call(window.analytics.track, 'Journey saved (team)', { journey_id: id });
  } catch (error) {
    console.log('savedJourneys/sagas › saveJourneyToTeamList › error', error);
    yield put(actions.saveJourneyToTeamListFailure(error));
  }
}

export function* removeJourneyFromTeamList({ id }) {
  try {
    const team = yield select(selectors.teamSelector);
    const response = yield call(api.deleteJourneyFromTeamList, team.id, id);
    yield put(actions.removeJourneyFromTeamListSuccess(camelizeKeys(response.data)));
    yield put(
      flashMessageActions.showSuccess({
        body: (
          <Message>
            Journey has been removed from your{' '}
            <Link to="/team-collections/saved-journeys">collections</Link>.
          </Message>
        ),
      }),
    );
    yield call(window.analytics.track, 'Journey removed (team)', { journey_id: id });
  } catch (error) {
    console.log('savedJourneys/sagas › removeJourneyFromTeamList › error', error);
    yield put(actions.removeJourneyFromTeamListFailure(error));
  }
}
