import * as React from 'react';
import PropTypes from 'prop-types';

function PinterestIcon({ fillOpacity, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.16 3.58A8.501 8.501 0 008.598 19.79a6.593 6.593 0 01.138-1.948c.157-.713 1.102-4.643 1.102-4.643a3.178 3.178 0 01-.276-1.34c0-1.262.729-2.204 1.635-2.204a1.134 1.134 0 011.14 1.281c0 .765-.491 1.923-.748 3.009a1.31 1.31 0 001.339 1.634c1.613 0 2.694-2.066 2.694-4.505 0-1.87-1.239-3.27-3.521-3.27a4.033 4.033 0 00-4.19 4.074c-.024.606.171 1.2.55 1.674a.408.408 0 01.138.471c-.039.157-.137.53-.176.667a.301.301 0 01-.434.215c-1.176-.47-1.73-1.765-1.73-3.243 0-2.42 2.026-5.316 6.08-5.316 3.226 0 5.37 2.36 5.37 4.885 0 3.322-1.85 5.82-4.583 5.82a2.432 2.432 0 01-2.086-1.06s-.491 1.969-.588 2.341a6.817 6.817 0 01-.866 1.811c.784.238 1.6.357 2.419.354a8.49 8.49 0 008.495-8.502 8.5 8.5 0 00-7.339-8.416h-.001z"
        fill="currentColor"
        fillOpacity={fillOpacity}
      />
    </svg>
  );
}

PinterestIcon.propTypes = {
  fillOpacity: PropTypes.number,
};

PinterestIcon.defaultProps = {
  fillOpacity: 1,
};

export default PinterestIcon;
