import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import { Box, Flex } from './mc-ui';
import Unlocked from './mc-ui/Unlocked';

import CompaniesMenu from './menus/CompaniesMenu';
import CompanyInformation from './companies/CompanyInformation';
import ContactModalLink from './ContactModalLink';
import Container from './Container';
import Header from './Header';
import ModalLink from './ModalLink';
import Section from './Section';

class Companies extends React.Component {
  UNSAFE_componentWillMount() {
    const { params } = this.props;
    if (!this.getCompany(params.company_id)) {
      this.request();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.params.company_id !== this.props.params.company_id &&
      !this.getCompany(nextProps.params.company_id)
    ) {
      this.request(nextProps.params);
    }
  }

  componentWillUnmount() {
    this.props.flush();
  }

  getCompany(companyId) {
    return this.props.companies.data.find(c => c.id === companyId) || null;
  }

  query = {};

  request = (nextPropsParams = {}) => {
    const {
      requestCompanies,
      companies,
      params,
      router: { location },
    } = this.props;

    const query = {
      ...location.query,
      ...params,
      ...nextPropsParams,
    };

    if (JSON.stringify(query) !== JSON.stringify(this.query)) {
      // eslint-disable-next-line no-unused-vars
      const [space, model, id, type] = location.pathname.split('/');
      // eslint-disable-next-line no-unused-vars
      const [company = {}, ..._] = companies.data;
      if (type && params.company_id === company.id) return;

      this.query = query;
      requestCompanies(query);
    }
  };

  isLoading = () => {
    const { companies, params } = this.props;
    const company = this.getCompany(params.company_id);
    return !company || companies.loading;
  };

  render() {
    const { children, params, user: u } = this.props;
    const [user] = u.data;
    const company = this.getCompany(params.company_id) || { attributes: {} };
    const loading = this.isLoading();

    return (
      <div className="pure-u-1">
        <Header>
          <CompanyInformation {...this.props} loading={this.isLoading()} company={company} />
        </Header>

        <Section>
          <Flex justifyContent="space-between">
            <Flex css={{ width: '200px', marginTop: '1em' }}>
              {!loading && <CompaniesMenu {...this.props} company={company} />}
            </Flex>

            {company.attributes.approximate_emails < 1 ||
              (company.attributes.valid === false && (
                <Box width="834px">
                  <Container>
                    <h4>
                      {company.attributes.valid
                        ? "Uh oh! We haven't received any emails yet."
                        : 'No user journeys available.'}
                    </h4>
                    <p style={{ fontSize: `${13 / 16}em` }}>
                      {company.attributes.valid
                        ? `We're still waiting to receive our first email from ${
                            company.attributes.name
                          }.`
                        : `Our team was not able to find any way to sign up for ${
                            company.attributes.name
                          } emails.`}
                    </p>
                    {moment(company.attributes.created_at).isBefore(
                      moment().subtract(3, 'months'),
                    ) ? (
                      <p style={{ fontSize: `${13 / 16}em` }}>
                        <ModalLink to={`/companies/${company.id}/segments`}>Click here</ModalLink>{' '}
                        to request another journey and we&rsquo;ll try again.
                      </p>
                    ) : (
                      <p style={{ fontSize: `${13 / 16}em` }}>
                        <a
                          href="https://www.mailcharts.com/companies"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Learn more about how we add the data you care about.
                        </a>{' '}
                        If you have any questions, please just{' '}
                        <ContactModalLink>let us know</ContactModalLink>.
                      </p>
                    )}
                  </Container>
                </Box>
              ))}
            <Box width="834px">
              {user && (
                <Unlocked
                  title="Unlocked"
                  text={`You are viewing unlocked historical data and reports from ${
                    company.attributes.name
                  }. Upgrade now for complete access to more than 2,500 ecommerce brands in the MailCharts Index.`}
                  user={user}
                  unlocked={company.attributes.featured}
                  style={{ marginTop: 15 }}
                />
              )}
              {React.Children.map(children, child => React.cloneElement(child, { model: company }))}
            </Box>
          </Flex>
        </Section>
      </div>
    );
  }
}

Companies.propTypes = {
  children: PropTypes.node.isRequired,
  companies: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.object),
    included: PropTypes.arrayOf(PropTypes.object),
    links: PropTypes.object,
    loading: PropTypes.bool,
    meta: PropTypes.object,
  }).isRequired,
  flush: PropTypes.func.isRequired,
  params: PropTypes.shape({ company_id: PropTypes.string }).isRequired,
  requestCompanies: PropTypes.func.isRequired,
  router: PropTypes.shape({
    location: PropTypes.shape({ query: PropTypes.object }),
  }).isRequired,
  user: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};

const mapStateToProps = state => ({
  companies: state.companies,
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  flush: () => dispatch({ type: 'FLUSH' }),
  requestCompanies: query =>
    dispatch({ type: 'REQUEST_COMPANIES', company_id: query.company_id, params: query }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Companies);
