import React from 'react';
import styled from "@emotion/styled";

const Form = styled('form', {
  withProps: { className: 'pure-form' }
})({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'flex-end',
  height: '31px',
  maxWidth: '360px',
});

const Label = styled('h5')({
  display: 'inline-block',
  margin: '0',
  marginRight: '5px',
  padding: '0',
});

const Select = styled('select', {
  withProps: { className: 'pure-input-1' }
})({
  border: '1px solid #a0aeba !important',
  borderRadius: '3px !important',
  fontSize: '0.8125em !important',
  padding: '6.5px 7.8px !important',
  width: '120px !important',
});

const FilterField = ({ children, ...rest }) => (
  <Form>
    <Label>Sort</Label>
    <Select {...rest}>{children}</Select>
  </Form>
);

export default FilterField;
