/** @jsx jsx */
import { jsx } from '@emotion/react';
import React from 'react';
import PropTypes from 'prop-types';

import Flex from './Flex';
import ArrowLeftIcon from './icons/pagination/ArrowLeftIcon';
import ArrowRightIcon from './icons/pagination/ArrowRightIcon';

import Button from './pagination/Button';
import Results from './pagination/Results';

class Pagination extends React.Component {
  state = {
    page: this.props.page,
  };

  setNextPage = () => {
    const { collection, pageSize } = this.props;
    const totalPages = Math.ceil(collection.length / pageSize);

    this.setState(currentState => {
      if (currentState.page < totalPages) {
        return { page: currentState.page + 1 };
      }
      return currentState;
    });
  };

  setPreviousPage = () => {
    this.setState(currentState => {
      if (currentState.page > 1) {
        return { page: currentState.page - 1 };
      }
      return currentState;
    });
  };

  currentCollection = () => {
    const { collection, pageSize } = this.props;
    const { page } = this.state;

    return collection.slice((page - 1) * pageSize, pageSize * page);
  };

  render() {
    const { page } = this.state;
    const { children, collection, pageSize } = this.props;
    const totalPages = Math.ceil(collection.length / pageSize);

    return (
      <div
        css={{ width: '100%' }}
      >
        {children({ collection: this.currentCollection(), page, pageSize })}
        <Flex alignItems="center" justifyContent="center" css={{ marginTop: '14px' }}>
          <Button onClick={this.setPreviousPage} disabled={page === 1}>
            <ArrowLeftIcon />
          </Button>
          <Results>
            Showing {page * pageSize - (pageSize - 1)} to {pageSize * page} of {collection.length}
          </Results>
          <Button onClick={this.setNextPage} disabled={page === totalPages}>
            <ArrowRightIcon />
          </Button>
        </Flex>
      </div>
    );
  }
}

Pagination.defaultProps = {
  page: 1,
};

Pagination.propTypes = {
  children: PropTypes.func.isRequired,
  collection: PropTypes.arrayOf(PropTypes.any).isRequired,
  page: PropTypes.number,
  pageSize: PropTypes.number.isRequired,
};

export default Pagination;
