import React from 'react';
import PropTypes from 'prop-types';

import Select from '../../../mc-ui/overrides/ReactSelect';

export const AVAILABLE_ROLES = [
  { label: 'Admin', value: 'admin' },
  { label: 'Member', value: 'member' },
  { label: 'Owner', value: 'owner' },
  // { label: 'Billing', value: 'billing' },
];

export const SELECTABLE_ROLES = AVAILABLE_ROLES.filter(r => r.value != 'owner');

class RoleSelect extends React.Component {
  constructor(props) {
    super(props);

    const value = AVAILABLE_ROLES.find(r => r.value === props.role);

    this.state = { value };
  }

  handleChange = value => {
    const { value: selectedRole } = value;
    const { memberId, onUpdate, teamId } = this.props;
    this.setState(
      () => ({ value }),
      () => {
        onUpdate(teamId, memberId, { role: selectedRole });
      },
    );
  };

  render() {
    const { enabled } = this.props;
    const { value } = this.state;

    return (
      <Select
        isDisabled={!enabled}
        onChange={this.handleChange}
        options={SELECTABLE_ROLES}
        value={value}
      />
    );
  }
}

RoleSelect.propTypes = {
  enabled: PropTypes.bool.isRequired,
  memberId: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
  teamId: PropTypes.number.isRequired,
};

export default RoleSelect;
