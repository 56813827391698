import React from 'react';

const SvgErrorIcon = props => (
  <svg width="1em" height="1em" viewBox="0 0 20 18" fill="none" {...props}>
    <path
      d="M18.626 18a.602.602 0 00.53-.885L10.142.292a.627.627 0 00-1.06 0L.073 17.115a.6.6 0 00.53.885h18.024zm-9.013-2.203a1.202 1.202 0 110-2.404 1.202 1.202 0 010 2.404zm0-9.813a.801.801 0 01.802.8v4.379a.8.8 0 11-1.603 0V6.785a.801.801 0 01.801-.801z"
      fill="currentColor"
    />
  </svg>
);

export default SvgErrorIcon;

