import React from 'react';
import PropTypes from 'prop-types';

class IntervalFilter extends React.Component {
  state = {
    interval: 'week',
  };

  Nav = () => {
    const { interval } = this.state;

    const intervalNavStyle = {
      fontSize: `${13 / 16}em`,
      position: 'absolute',
      right: '0',
      top: '24px',
    };

    const intervalLinksStyle = {
      borderBottom: '1px solid #0379D5',
      color: '#0379D5',
      paddingBottom: '2px',
      textDecoration: 'none',
    };
    const activeIntervalStyle = {
      borderBottom: 'none',
      color: 'inherit',
      cursor: 'default',
      fontWeight: 'bold',
    };
    const dayIntervalStyle = interval === 'day' ? activeIntervalStyle : {};
    const weekIntervalStyle = interval === 'week' ? activeIntervalStyle : {};
    const monthIntervalStyle = interval === 'month' ? activeIntervalStyle : {};

    return (
      <nav style={intervalNavStyle}>
        <a
          href="#day"
          onClick={this.updateInterval}
          style={{ ...intervalLinksStyle, ...dayIntervalStyle }}
        >
          Daily
        </a>
        &nbsp;|&nbsp;
        <a
          href="#week"
          onClick={this.updateInterval}
          style={{ ...intervalLinksStyle, ...weekIntervalStyle }}
        >
          Weekly
        </a>
        &nbsp;|&nbsp;
        <a
          href="#month"
          onClick={this.updateInterval}
          style={{ ...intervalLinksStyle, ...monthIntervalStyle }}
        >
          Monthly
        </a>
      </nav>
    );
  }

  updateInterval = (event) => {
    event.preventDefault();
    const { interval: currentInterval } = this.state;
    const interval = event.target.hash.replace('#', '');

    if (currentInterval !== interval) {
      this.setState({ interval }, () => this.props.onUpdate(interval));
    }
  };

  render() {
    return this.props.children({
      ...this.props,
      ...this.state,
      IntervalFilterNav: this.Nav,
    });
  }
}

IntervalFilter.propTypes = {
  children: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default IntervalFilter;
