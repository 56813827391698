import React from 'react';
import PropTypes from 'prop-types';

import { TYPES } from '../../../modules/shareButton/constants';

import AddToListButton from '../../AddToListButton';
import ShareButton from '../../ui/ShareButton';
import { Box, Flex } from '../../mc-ui';

import DownloadEmailDropdown from './DownloadEmailDropdown';
import ViewSelector from './ViewSelector';

export default function ViewControls({ action, email, onViewChange, view }) {
  return (
    <Flex justifyContent="space-between" marginBottom="16px">
      <ViewSelector onChange={onViewChange} view={view} />

      <Box width="unset">
        <AddToListButton
          emails={[email]}
          variant="primary"
          action={action}
          asModal
          css={{
            marginRight: '8px',
            width: '90px !important',
            '& > button': {
              boxShadow: 'none !important',
              width: '90px !important',
              '& > svg': { display: 'none' },
            },
          }}
        />

        <DownloadEmailDropdown email={email} />

        <ShareButton
          css={{ margin: '0 0 0 8px' }}
          includePinterest
          model={email}
          type={TYPES.email}
          variant="secondary"
        />
      </Box>
    </Flex>
  );
}

ViewControls.propTypes = {
  email: PropTypes.shape({}).isRequired,
  onViewChange: PropTypes.func.isRequired,
  view: PropTypes.string.isRequired,
};
