import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import qs from 'qs';
import { connect } from 'react-redux';

import axios, { CancelToken } from 'axios';
import api from '../../lib/api';
import { reportsActions } from '../../modules/reports';

import AverageExplanation from '../AverageExplanation';
import Restricted from '../Restricted';
import Loader from '../Loader';
import SendTime from '../charts/SendTime';
import SendFrequency from '../charts/SendFrequency';

import IntervalFilter from './IntervalFilter';

class SendingBehaviorReport extends React.Component {
  state = {
    interval: 'week',
    loading: true,
    report: null,
    searchString: '',
  };

  UNSAFE_componentWillMount() {
    const { router } = this.props;

    this.setState({ searchString: router.location.search }, () => {
      this.fetch(this.props);
    });
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { router } = props;

    if (router.location.search !== this.state.searchString) {
      this.setState({ searchString: router.location.search }, () => {
        this.fetch(props);
      });
    }
  }

  setTotal = (report) => {
    let total = 0;
    let comparisonTotal = 0;
    report.meta.stats.forEach((stat) => {
      if (stat.name === 'Comparison') {
        comparisonTotal = stat.total;
      } else {
        total = stat.total; // eslint-disable-line
      }
    });

    this.props.receiveExportTotal({ total, comparisonTotal });
  };

  cancelTokenSource = null;

  cancelFetch = () => {
    if (this.cancelTokenSource) {
      this.cancelTokenSource.cancel();
    }

    this.cancelTokenSource = CancelToken.source();
  };

  fetch(props) {
    this.cancelFetch();

    const { router } = props;
    const { model, startReportLoading } = this.props;
    const { interval } = this.state;

    startReportLoading();

    this.setState({ loading: true, error: null }, () => {
      const query = qs.parse((router.location.search || '').replace('?', ''));
      const params = {
        ...query,
        interval,
      };

      api
        .get(`/v2/${model.type}/${model.id}/sending-behavior?${qs.stringify(params)}`, {
          cancelToken: this.cancelTokenSource.token,
        })
        .then((r) => {
          const report = r.data;
          this.setTotal(report);
          this.setState({ loading: false, restricted: null, report });
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            this.setState({ error: true, restricted: /403/.test(err.toString()), report: {} });
          }
        });
    });
  }

  updateInterval = (interval) => {
    this.setState({ interval }, () => this.fetch(this.props));
  };

  handleClick = (options) => {
    const { model, router } = this.props;
    const { start_date: startDate } = options;
    const endDate = moment(startDate)
      .add(1, this.state.interval)
      .format('YYYY-MM-DD');
    const query = {
      ...router.query,
      compare: null,
      start_date: startDate,
      end_date: endDate,
    };
    const pathname = `/${model.type}/${model.id}/emails`;
    router.push({ pathname, query });
  };

  render() {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
    const tz_abbr = moment(new Date).tz(tz).format('z')
    return (
      <Loader loading={this.state.loading} error={this.state.error}>
        {this.state.report ? (
          <div className="pure-u-1">
            {this.state.restricted ? (
              <Restricted />
            ) : (
              <IntervalFilter onUpdate={this.updateInterval} {...this.props} {...this.state}>
                {({ model, report, IntervalFilterNav }) => (
                  <div>
                    <div className="pure-u-1" style={{ position: 'relative ' }}>
                      <h4 style={{ display: 'inline-block', marginRight: 5 }}>Sending behavior</h4>
                      <AverageExplanation model={model} />
                      <IntervalFilterNav />
                    </div>
                    <div className="pure-u-1-3">
                      <h5>Most popular day of week</h5>
                      <h2
                        style={{
                          textTransform: 'capitalize',
                        }}
                      >
                        {report.meta.stats.reduceRight((m, s) => s, {}).day_of_week.buckets[0].key}
                      </h2>
                      <h5>Most popular time of day ({tz_abbr})</h5>
                      <h2>
                        {moment(new Date())
                          .hours(report.meta.stats
                              .reduceRight((m, s) => s, {})
                              .hour_of_day.buckets.map(b => b)
                              .sort((a, b) => (b.company_avg.value || 0) - (a.company_avg.value || 0))[0].key)
                          .format('h a')}
                      </h2>
                    </div>
                    <div className="pure-u-2-3">
                      <SendFrequency
                        handleClick={this.handleClick}
                        height={180}
                        names={report.meta.stats.map(s => s.name)}
                        buckets={report.meta.stats.map(s => s.interval.buckets)}
                      />
                    </div>
                    <div style={{ paddingTop: 10, marginTop: 20, borderTop: '1px solid #F3F5F7' }}>
                      <h5>Send time ({tz_abbr})</h5>
                      <SendTime
                        height={100}
                        names={report.meta.stats.map(s => s.name)}
                        buckets={report.meta.stats.map(s => s.hour_of_day.buckets)}
                      />
                      <h5>Day of week analysis</h5>
                      <table className="pure-table pure-table-striped" style={{ width: '100%' }}>
                        <thead>
                          <tr>
                            <th>Day of week</th>
                            {report.meta.stats.map((s, idx) => {
                              const total = s.day_of_week.buckets.reduce(
                                (m, b) => b.company_avg.value + m,
                                0,
                              );
                              const name = report.meta.stats[idx].name || '';
                              const key = `${name.toLowerCase().replace(' ', '-')}-${idx}`;

                              return (
                                <th key={key}>
                                  {name.replace(/average/i, '')} (~{Math.round(total)} total)
                                </th>
                              );
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          {[
                            'Sunday',
                            'Monday',
                            'Tuesday',
                            'Wednesday',
                            'Thursday',
                            'Friday',
                            'Saturday',
                          ].map((day) => {
                            const key = day.toLowerCase();

                            return (
                              <tr key={key}>
                                <td>{day}</td>
                                {report.meta.stats.map((s, idx) => {
                                  const total = s.day_of_week.buckets.reduce(
                                    (m, b) => b.company_avg.value + m,
                                    0,
                                  );
                                  const value = +(
                                    s.day_of_week.buckets.reduce(
                                      (m, b) => (b.key === key ? b : m),
                                      {},
                                    ).company_avg.value || 0
                                  );
                                  const nestedKey = `${total}-${value}-${idx}`;

                                  return (
                                    <td key={nestedKey}>
                                      {Math.round((value / total) * 100)}% ({value.toFixed(2)})
                                    </td>
                                  );
                                })}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </IntervalFilter>
            )}
          </div>
        ) : null}
      </Loader>
    );
  }
}

SendingBehaviorReport.propTypes = {
  model: PropTypes.shape({}).isRequired,
  router: PropTypes.shape({}).isRequired,
  receiveExportTotal: PropTypes.func.isRequired,
  startReportLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  companies: state.companies,
  groups: state.groups,
});

const mapDispatchToProps = dispatch => ({
  startReportLoading: () => dispatch(reportsActions.startLoading()),
  receiveExportTotal: (...args) => dispatch(reportsActions.receiveExportTotalAmount(...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SendingBehaviorReport);
