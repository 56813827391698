import * as React from 'react';
import PropTypes from 'prop-types';

function ChevronUpIcon({ fillOpacity, ...props }) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.369 9.37a1.19 1.19 0 01.845.348l4.3 4.3a.796.796 0 01-1.127 1.127l-3.938-3.939a.112.112 0 00-.16 0l-3.938 3.939a.797.797 0 01-1.127-1.127l4.3-4.3a1.191 1.191 0 01.845-.349z"
        fill="currentColor"
        fillOpacity={fillOpacity}
      />
    </svg>
  );
}

ChevronUpIcon.propTypes = {
  fillOpacity: PropTypes.number,
};

ChevronUpIcon.defaultProps = {
  fillOpacity: 1,
};

export default ChevronUpIcon;
