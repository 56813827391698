import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withTheme } from '@emotion/react';

import { Flex } from '../../../../mc-ui';

import Day from '../../../../newReports/Calendars/Calendar/Row/Day';
import WeeklyTotals from '../../../../newReports/Calendars/Calendar/Row/WeeklyTotals';

const Row = ({ days, selectedDate, theme, weeks, ...props }) => (
  <Flex css={{ borderTop: `1px solid ${theme.colors.dark5}` }}>
    {days.map(day => {
      const clonedDate = moment(day.key_as_string.split('T')[0]);
      const data = {
        perentageBrands: Math.round(day.brands.avg * 100),
        promotionRate: Math.round(day.promotional.avg * 100),
      };

      return (
        <Day
          key={day.key_as_string}
          data={data}
          date={clonedDate}
          disableClick
          firstRow={false}
          isCurrentMonth={clonedDate.month() === selectedDate.month()}
          isLastMonth={clonedDate.month() < selectedDate.month()}
          isNextMonth={clonedDate.month() > selectedDate.month()}
          isFutureDate={clonedDate.isAfter(new Date(), 'day')}
          isToday={clonedDate.isSame(new Date(), 'day')}
          lastRow={false}
          name={clonedDate.format('dd').substring(0, 1)}
          lastDayOfLastMonth={
            clonedDate.month() < selectedDate.month() &&
            clonedDate
              .clone()
              .endOf('month')
              .isSame(clonedDate, 'day')
          }
          lastDayOfCurrentMonth={
            clonedDate.month() === selectedDate.month() &&
            clonedDate
              .clone()
              .endOf('month')
              .isSame(clonedDate, 'day')
          }
          todayIsNotDefined
          {...props}
        />
      );
    })}
    <WeeklyTotals
      data={{ stats: { weeks } }}
      nrfWeek={days.length ? days[0].nrf_week : null}
      date={moment(days[1].key_as_string.split('T')[0]).format('YYYY-MM-DD')}
    />
  </Flex>
);

Row.propTypes = {
  days: PropTypes.arrayOf(
    PropTypes.shape({
      key_as_string: PropTypes.string,
      brands: PropTypes.shape({ avg: PropTypes.number }),
      promotional: PropTypes.shape({ avg: PropTypes.number }),
    }),
  ).isRequired,
  selectedDate: PropTypes.shape({}).isRequired,
  theme: PropTypes.shape({}).isRequired,
  weeks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default withTheme(Row);
