import styled from "@emotion/styled";
import { Link } from 'react-router';

export const UserAvatar = styled("img")({
  borderRadius: '50%',
  display: 'inline-block',
  height: '32px',
  marginRight: '16px',
  width: '32px',
});

export const UserName = styled("h3")(({ theme }) => ({
  color: theme.colors.dark1,
  fontFamily: theme.font.family,
  fontSize: '12px',
  fontWeight: 'bold',
  letterSpacing: '0.08em',
  lineHeight: '24px',
  margin: '0',
  padding: '0',
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
}));

export const CommentableLink = styled(Link)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.colors.white,
  border: `1px solid ${theme.colors.dark5}`,
  borderRadius: '4px',
  boxSizing: 'border-box',
  color: theme.colors.dark1,
  cursor: 'pointer',
  display: 'flex',
  minWidth: 0,
  padding: '0 8px',
  textDecoration: 'none',
  '& > svg': {
    color: theme.colors.dark1,
    marginRight: '8px',
  },
  '& > span': {
    boxSizing: 'border-box',
    display: 'inline-block',
    fontSize: '12px',
    lineHeight: '24px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));
