import React from 'react';
import PropTypes from 'prop-types';

import { Flex, Tab } from '../../../mc-ui';

import ReportSection from '../../ReportSection';
import Title from '../../Title';

import { CompareBrands, CompareDates, Overview } from '../../Sparklines';

import SparklineTooltip from './SparklineTooltip';

const CHART = 'subject_line_length';
const TAB_NAMES = ['overview', 'compareBrands', 'compareDates',];

const AverageSubjectLineLength = ({ model, router }) => (
  <Flex>
    <ReportSection css={{ minHeight: '513px' }}>
      <ReportSection.Header
        alignItems="center"
        justifyContent="flex-start"
        css={{ marginBottom: '22px' }}
      >
        <Title>Average Subject Line Length</Title>
        <SparklineTooltip />
      </ReportSection.Header>

      <Tab
        defaultActive={TAB_NAMES.find(n => n == router.location.query.tab) || 'overview'}
        onTabChange={name => {
          const tab = TAB_NAMES.find(n => n == name)
          const { query, pathname } = router.location
          const location = { pathname, query: { ...query, tab, } }
          router.push(location)
        }
      }>
        <Tab.Header>
          {model.type === 'companies' ? (
            <Tab.Header>
              <Tab.Item name="overview">Overview</Tab.Item>
              <Tab.Item name="compareDates">Compare Dates</Tab.Item>
            </Tab.Header>
          ) : (
            <Tab.Header>
              <Tab.Item name="overview">Overview</Tab.Item>
              <Tab.Item name="compareBrands">Compare Brands</Tab.Item>
              <Tab.Item name="compareDates">Compare Dates</Tab.Item>
            </Tab.Header>
          )}
        </Tab.Header>
        <Tab.Body>
          {({ active }) => {
            if (active === 'compareBrands') return <CompareBrands chart={CHART} model={model} router={router} />;
            else if (active === 'compareDates')
              return <CompareDates chart={CHART} model={model} router={router} />;
            else return <Overview chart={CHART} model={model} router={router} />;
          }}
        </Tab.Body>
      </Tab>
    </ReportSection>
  </Flex>
);

AverageSubjectLineLength.propTypes = {
  model: PropTypes.shape({ id: PropTypes.string, type: PropTypes.string }).isRequired,
  router: PropTypes.shape({}).isRequired,
};

export default AverageSubjectLineLength;
