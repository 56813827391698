import * as React from 'react';
import PropTypes from 'prop-types';

function CheckCircleIcon({ fillOpacity, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 21a9 9 0 100-18 9 9 0 000 18zm-1.673-4.687l6.543-6.917-1.145-1.083L10.345 14l-2.92-3.184-1.162 1.065 4.064 4.43z"
        fill="currentColor"
        fillOpacity={fillOpacity}
      />
    </svg>
  );
}

CheckCircleIcon.propTypes = {
  fillOpacity: PropTypes.number,
};

CheckCircleIcon.defaultProps = {
  fillOpacity: 1,
};

export default CheckCircleIcon;
