import React from 'react';

import ScrollableList from '../ui/ScrollableList';

import { fetchCustomerJourneys } from './utils/api';

class CustomerJourneysList extends React.PureComponent {
  state = {
    loading: true,
    lists: [],
  };

  componentDidMount() {
    this.request();
  }

  request = async () => {
    const lists = await fetchCustomerJourneys();

    this.setState(() => ({
      lists,
      loading: false,
    }));
  };

  render() {
    const { loading, lists } = this.state;

    return <ScrollableList collection={lists} loading={loading} type="customerJourneysList" />;
  }
}

export default CustomerJourneysList;
