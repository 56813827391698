import * as types from './types';

export const fetchUserFiltersRequest = (params = {}) => ({
  type: types.FETCH_USER_FILTERS_REQUEST,
  params,
});

export const fetchUserFiltersSuccess = data => ({
  type: types.FETCH_USER_FILTERS_SUCCESS,
  data,
});

export const fetchUserFiltersFailure = error => ({
  type: types.FETCH_USER_FILTERS_FAILURE,
  error,
});

export const createUserFilterRequest = (values, resolve) => ({
  type: types.CREATE_USER_FILTER_REQUEST,
  values,
  resolve,
});

export const createUserFilterSuccess = data => ({
  type: types.CREATE_USER_FILTER_SUCCESS,
  data,
});

export const createUserFilterFailure = error => ({
  type: types.CREATE_USER_FILTER_FAILURE,
  error,
});

export const deleteUserFilterRequest = id => ({
  type: types.DELETE_USER_FILTER_REQUEST,
  id,
});

export const deleteUserFilterSuccess = data => ({
  type: types.DELETE_USER_FILTER_SUCCESS,
  data,
});

export const deleteUserFilterFailure = error => ({
  type: types.DELETE_USER_FILTER_FAILURE,
  error,
});

export const updateUserFilterRequest = (id, values, resolve) => ({
  type: types.UPDATE_USER_FILTER_REQUEST,
  id,
  values,
  resolve,
});

export const updateUserFilterSuccess = data => ({
  type: types.UPDATE_USER_FILTER_SUCCESS,
  data,
});

export const updateUserFilterFailure = error => ({
  type: types.UPDATE_USER_FILTER_FAILURE,
  error,
});

export const fetchTeamFiltersRequest = (params = {}) => ({
  type: types.FETCH_TEAM_FILTERS_REQUEST,
  params,
});

export const fetchTeamFiltersSuccess = data => ({
  type: types.FETCH_TEAM_FILTERS_SUCCESS,
  data,
});

export const fetchTeamFiltersFailure = error => ({
  type: types.FETCH_TEAM_FILTERS_FAILURE,
  error,
});

export const createTeamFilterRequest = (values, resolve) => ({
  type: types.CREATE_TEAM_FILTER_REQUEST,
  values,
  resolve,
});

export const createTeamFilterSuccess = data => ({
  type: types.CREATE_TEAM_FILTER_SUCCESS,
  data,
});

export const createTeamFilterFailure = error => ({
  type: types.CREATE_TEAM_FILTER_FAILURE,
  error,
});

export const deleteTeamFilterRequest = id => ({
  type: types.DELETE_TEAM_FILTER_REQUEST,
  id,
});

export const deleteTeamFilterSuccess = data => ({
  type: types.DELETE_TEAM_FILTER_SUCCESS,
  data,
});

export const deleteTeamFilterFailure = error => ({
  type: types.DELETE_TEAM_FILTER_FAILURE,
  error,
});

export const updateTeamFilterRequest = (id, values, resolve) => ({
  type: types.UPDATE_TEAM_FILTER_REQUEST,
  id,
  values,
  resolve,
});

export const updateTeamFilterSuccess = data => ({
  type: types.UPDATE_TEAM_FILTER_SUCCESS,
  data,
});

export const updateTeamFilterFailure = error => ({
  type: types.UPDATE_TEAM_FILTER_FAILURE,
  error,
});
