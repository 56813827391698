/** @jsx jsx */
import { jsx } from '@emotion/react';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router';
import { connect } from 'react-redux';

import { Box, Flex, Heading, Text, theme } from '../../mc-ui';
import Checkbox from '../../ui/Checkbox';

import BrandLogo from '../../ui/BrandLogo';

class EmailRow extends React.Component {
  toggleSelected = event => {
    const { editing } = this.props;

    if (!editing) return;

    event.preventDefault();
    event.stopPropagation();

    const { deselectEmail, selectEmail, selected } = this.props;
    const actionFunc = selected ? deselectEmail : selectEmail;

    actionFunc();
  };

  handleClick = event => {
    event.preventDefault();
    event.stopPropagation();

    const { email } = this.props;
    const { router } = this.context;

    const pathname =
      router.location.pathname === `/companies/${email.attributes.company_id}/emails/inbox`
        ? `/companies/${email.attributes.company_id}`
        : `/companies/${email.attributes.company_id}/emails/inbox`;

    return router.push({ pathname, query: router.location.query });
  };

  render() {
    const { editing, email, selected } = this.props;
    const { router } = this.context;

    const logo =
      email.type === 'loading'
        ? '/logo_placeholder.jpg'
        : `//cache.mailcharts.com/logos/v2/companies/${email.attributes.company_id}.png`;

    return (
      <Link
        css={{ textDecoration: 'none' }}
        onClick={this.toggleSelected}
        to={{
          pathname: `/emails/${email.attributes.guid}`,
          query: { ...router.location.query, ...router.params },
        }}
      >
        <Flex
          backgroundColor={selected ? 'blueLight' : 'white'}
          borderBottom="1px solid"
          borderColor="dark01"
          css={{ outline: 'none' }}
          padding="16px 0 24px 0"
        >
          <Box
            height="40px"
            marginRight="16px"
            onClick={this.handleClick}
            role="link"
            tabIndex="0"
            width="40px"
          >
            <BrandLogo brandId={email.attributes.company_id} alt={email.attributes.company_name} />
          </Box>

          <Box marginRight="16px" width="192px">
            <Heading
              as="h4"
              lineHeight="18px"
              css={{
                marginBottom: '4px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                width: '192px',
              }}
            >
              {email.attributes.company_name}
            </Heading>

            <Text fontSize="12px">
              {moment(email.attributes.sent_at).format('MMMM DD, YYYY [at] HH:mm a')}
            </Text>
          </Box>

          <Box flex="1" marginRight="16px">
            <Text
              fontWeight="400"
              letterSpacing="0.02em"
              lineHeight="18px"
              css={{ '& > strong': { color: theme.colors.dark1, fontWeight: '500' } }}
            >
              <strong>
                {email.attributes.subject.trim()}
                {email.attributes.preheader.trim().length > 0 ? ' -' : ''}
              </strong>{' '}
              {email.attributes.preheader}
            </Text>
          </Box>

          <Flex alignItems="center" justifyContent="center" marginRight="8px" width="24px">
            {editing && <Checkbox checked={selected} />}
          </Flex>
        </Flex>
      </Link>
    );
  }
}

EmailRow.contextTypes = {
  router: PropTypes.object,
};

EmailRow.propTypes = {
  deselectEmail: PropTypes.func.isRequired,
  email: PropTypes.shape({}).isRequired,
  selectEmail: PropTypes.func.isRequired,
  editing: PropTypes.bool,
  selected: PropTypes.bool,
};

EmailRow.defaultProps = {
  editing: false,
  selected: false,
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  selectEmail: () => dispatch({ type: 'SELECT_EMAIL', guid: ownProps.email.attributes.guid }),
  deselectEmail: () => dispatch({ type: 'DESELECT_EMAIL', guid: ownProps.email.attributes.guid }),
});

export default connect(
  null,
  mapDispatchToProps,
)(EmailRow);
