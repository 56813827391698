import { assocPath } from 'ramda';

import * as types from './types';

/**
 * State structure is divided per date, like the following
 * {
 *   [modelType]: {
 *     [modelId]: {
 *       [`startDate-endDate`]: {
 *         error: string,
 *         loading: boolean,
 *          days: {
 *            brands: array,
 *            loading: boolean,
 *            pagination: {
 *              next: string?,
 *              prev: string?,
 *              self: string,
 *            },
 *          },
 *          hours: {
 *            brands: array,
 *            loading: boolean,
 *            pagination: {
 *              next: string?,
 *              prev: string?,
 *              self: string,
 *            },
 *          }
 *         data: {,
 *           sentAtDayOfWeek: object,
 *         },
 *       },
 *     },
 *   },
 * }
 */
const INITIAL_STATE = {
  brands: {},
  groups: {},
};

const setDataLoading = (state, loading, { modelId, modelType, startDate, endDate }) =>
  assocPath([modelType, `${modelId}`, `${startDate}-${endDate}`, 'loading'], loading, state);

const setDataError = (state, { modelId, modelType, startDate, endDate, error }) =>
  assocPath([modelType, `${modelId}`, `${startDate}-${endDate}`], { error, loading: false }, state);

const setDataPayload = (state, { modelId, modelType, startDate, endDate, payload }) =>
  assocPath(
    [modelType, `${modelId}`, `${startDate}-${endDate}`],
    { ...payload, loading: false },
    state,
  );

const toggleDataLoading = (state, { modelId, modelType, startDate, endDate }) => {
  const modelTypeObj = state[modelType] || {};
  const modelObj = modelTypeObj[modelId] || {};
  const { loading } = modelObj[`${startDate}-${endDate}`] || { loading: false };
  return assocPath(
    [modelType, `${modelId}`, `${startDate}-${endDate}`, 'loading'],
    !loading,
    state,
  );
};

const setPaginationPayload = (state, { modelId, modelType, startDate, endDate, chart, payload }) =>
  assocPath([modelType, `${modelId}`, `${startDate}-${endDate}`, `${chart}`], payload, state);

const setChartLoading = (state, loading, { modelId, modelType, startDate, endDate, chart }) =>
  assocPath(
    [modelType, `${modelId}`, `${startDate}-${endDate}`, `${chart}`, 'loading'],
    loading,
    state,
  );

const reducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case types.FETCH_DATA_REQUEST: {
      return setDataLoading(state, true, action);
    }
    case types.FETCH_DATA_FAILURE: {
      return setDataError(state, action);
    }
    case types.FETCH_DATA_SUCCESS: {
      return setDataPayload(state, action);
    }
    case types.TOGGLE_DATA_LOADING: {
      return toggleDataLoading(state, action);
    }
    case types.FETCH_PAGINATION_DAYS_DATA_REQUEST: {
      return setChartLoading(state, true, action);
    }
    case types.FETCH_PAGINATION_DAYS_DATA_SUCCESS: {
      return setPaginationPayload(state, action);
    }
    case types.FETCH_PAGINATION_HOURS_DATA_REQUEST: {
      return setChartLoading(state, true, action);
    }
    case types.FETCH_PAGINATION_HOURS_DATA_SUCCESS: {
      return setPaginationPayload(state, action);
    }
    case types.SORT_DAYS_DATA_REQUEST: {
      return setChartLoading(state, true, action);
    }
    case types.SORT_DAYS_DATA_SUCCESS: {
      return setPaginationPayload(state, action);
    }
    case types.SORT_HOURS_DATA_REQUEST: {
      return setChartLoading(state, true, action);
    }
    case types.SORT_HOURS_DATA_SUCCESS: {
      return setPaginationPayload(state, action);
    }
    default: {
      return state;
    }
  }
};

export default reducer;
