import React from 'react';
import PropTypes from 'prop-types';

import BrandEmailsRestricted from './BrandEmailsRestricted';
import GroupEmailsRestricted from './GroupEmailsRestricted';

class Restricted extends React.Component {
  componentDidMount() {
    window.analytics.track('User restricted', { type: 'emails' });
  }

  render() {
    const { model } = this.props;
    if (!model || !model.id) return null;
    if (model.type === 'groups') return <GroupEmailsRestricted group={model} />;
    return <BrandEmailsRestricted brand={model} />;
  }
}

Restricted.defaultProps = {
  model: { id: null },
};

Restricted.propTypes = {
  model: PropTypes.shape({ id: PropTypes.string, type: PropTypes.string }),
};

export default Restricted;
