/* eslint-disable import/prefer-default-export */
import { call, put, select } from 'redux-saga/effects';
import { isEmpty } from 'ramda';
import { camelizeKeys } from 'humps';

import * as actions from './actions';
import * as api from './api';
import * as selectors from './selectors';

const getByBrandsPagination = (type, links, meta) => {
  const { next, prev, self } = links;
  if (type === 'all') {
    return {
      days: {
        next: next ? next.replace('chart=all', 'chart=days') : null,
        prev: prev ? prev.replace('chart=all', 'chart=days') : null,
        self: self ? self.replace('chart=all', 'chart=days') : null,
        current: meta.current,
        from: meta.from,
        to: meta.to,
        total: meta.total,
      },
      hours: {
        next: next ? next.replace('chart=all', 'chart=hours') : null,
        prev: prev ? prev.replace('chart=all', 'chart=hours') : null,
        self: self ? self.replace('chart=all', 'chart=hours') : null,
        current: meta.current,
        from: meta.from,
        to: meta.to,
        total: meta.total,
      },
    };
  }

  return {
    next: next ? next.replace('chart=all', `chart=${type}`) : null,
    prev: prev ? prev.replace('chart=all', `chart=${type}`) : null,
    self: self ? self.replace('chart=all', `chart=${type}`) : null,
    current: meta.current,
    from: meta.from,
    to: meta.to,
    total: meta.total,
  };
};

const brandsByChart = (companies, chart) => {
  const method = chart === 'days' ? 'sentAtDayOfWeek' : 'sentAtHourOfDay';

  return companies.map(brand => ({
    attributes: brand.attributes,
    id: brand.id,
    meta: {
      stats: {
        [method]: brand.meta.stats[method],
      },
    },
    type: brand.type,
  }));
};

export function* fetchData({ modelId, modelType, startDate, endDate }) {
  try {
    if (!endDate || !startDate) return;

    const data = yield select(selectors.getData(modelId, modelType, startDate, endDate));

    if (isEmpty(data)) {
      const response = yield call(api.fetchData, modelId, modelType, startDate, endDate, 5);
      const companies = camelizeKeys(response.included.filter(i => i.type === 'companies'));
      const { stats } = response.meta;

      const pagination = yield select(
        selectors.getByBrandsPagination(modelId, modelType, startDate, endDate),
      );

      const byBrandsPagination = {
        ...pagination,
        ...getByBrandsPagination('all', response.links, response.meta),
      };

      const payload = {
        days: {
          brands: brandsByChart(companies, 'days'),
          loading: false,
          pagination: byBrandsPagination.days,
        },
        hours: {
          brands: brandsByChart(companies, 'hours'),
          loading: false,
          pagination: byBrandsPagination.hours,
        },
        // This is necessary to support By Days and XXX sections
        data: {
          sentAtDayOfWeek: camelizeKeys(stats.sent_at_day_of_week),
        },
      };

      yield put(actions.fetchDataSuccess(modelId, modelType, startDate, endDate, payload));
    } else {
      yield put(actions.toggleDataLoading(modelId, modelType, startDate, endDate));
    }
  } catch (error) {
    console.log('error', error);
    yield put(actions.fetchDataFailure(modelId, modelType, startDate, endDate, error));
  }
}

export function* fetchPaginationData({ modelId, modelType, startDate, endDate, chart, direction }) {
  try {
    const pagination = yield select(
      selectors.getPagination(modelId, modelType, startDate, endDate, chart),
    );

    if (direction === 'next' && !pagination.next) return;
    if (direction === 'prev' && !pagination.prev) return;

    const response = yield call(api.fetchPaginationData, pagination[direction]);
    const companies = camelizeKeys(response.included.filter(i => i.type === 'companies'));

    const payload = {
      brands: brandsByChart(companies, chart),
      loading: false,
      pagination: getByBrandsPagination(chart, response.links, response.meta),
    };

    yield put(
      actions.fetchPaginationDataSuccess(modelId, modelType, startDate, endDate, chart, payload),
    );
  } catch (error) {
    console.log('error', error);
    yield put(actions.fetchPaginationDataFailure(modelId, modelType, startDate, endDate, error));
  }
}

export function* sortData({ modelId, modelType, startDate, endDate, chart, order }) {
  try {
    const response = yield call(
      api.fetchSorting,
      modelId,
      modelType,
      startDate,
      endDate,
      5,
      chart,
      order,
    );
    const companies = camelizeKeys(response.included.filter(i => i.type === 'companies'));

    const payload = {
      brands: brandsByChart(companies, chart),
      loading: false,
      pagination: getByBrandsPagination(chart, response.links, response.meta),
    };

    yield put(actions.sortDataSuccess(modelId, modelType, startDate, endDate, chart, payload));
  } catch (error) {
    console.log('error', error);
    yield put(actions.fetchDataFailure(modelId, modelType, startDate, endDate, error));
  }
}
