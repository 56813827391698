
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'

import ContactModalLink from './ContactModalLink';
import Container from './Container'
import Section from './Section'
import { topics as contactTopics } from './ContactUs';

class NotFound extends React.Component {
  render() {
    return (
      <Section>
        <Container>
          <h1>It's not you, it's us.</h1>
          <p>
            Unfortunately, we weren't able to find the page you're looking for. Are you sure the URL is typed correctly?
          </p>
          <p>
            If you're looking for suggestions, here's some of our favorite pages:
          </p>
          <ul>
            <li>
              <Link to='/'>
                Your dashboard
              </Link>
            </li>
            <li>
              <Link to='/browse/companies'>
                Find companies
              </Link>
            </li>
            <li>
              <Link to='/manage'>
                Manage your account
              </Link>
            </li>
          </ul>
          <p>
            If you have any questions at any time, please{' '}
            <ContactModalLink topic={contactTopics.PROBLEM}>
              contact support
            </ContactModalLink>.
          </p>
        </Container>
      </Section>
    )
  }
}

const s = (state) => ({})
export default connect(s)(NotFound)

