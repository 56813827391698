import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import pluralize from 'pluralize';

import { journeysSelectors as selectors } from '../../modules/journeys';

import { Box, Text } from '../mc-ui';

function Total({ loading, meta }) {
  if (!loading && meta.total === 0) return null;

  return (
    <Box height="18px" marginBottom="22px" marginTop="16px" paddingLeft="32px">
      {!loading && (
        <Text fontWeight="500" lineHeight="18px">
          {meta.total} {pluralize('journey', meta.total)}
        </Text>
      )}
    </Box>
  );
}

Total.propTypes = {
  loading: PropTypes.bool.isRequired,
  meta: PropTypes.shape({
    total: PropTypes.number,
  }).isRequired,
};

const mapStateToProps = (state, { location, model }) => ({
  meta: selectors.getMeta(model, location.query)(state),
});

export default connect(mapStateToProps)(Total);
