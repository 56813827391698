import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withTheme } from '@emotion/react';

import RestrictedTooltip from '../RestrictedTooltip';

import Flex from './Flex';
import DropdownIcon from './icons/DropdownIcon';
import DateRangePickerWrapper from './dateIntervalSelect/DateRangePickerWrapper';
import IntervalDropdown from './dateIntervalSelect/IntervalDropdown';

class DateIntervalSelect extends React.Component {
  state = {
    dropdownOpen: false,
  };

  componentDidMount() {
    window.addEventListener('click', this.closeDropdown);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.closeDropdown);
  }

  closeDropdown = () => {
    this.setState(() => ({ dropdownOpen: false }));
  };

  handleOnChange = dates => {
    this.setState(
      () => ({ dropdownOpen: false }),
      () => {
        this.props.onChange(dates);
      },
    );
  };

  selectInterval = event => {
    event.preventDefault();
    const { interval } = event.target.dataset;

    this.closeDropdown();
    this.props.onSelect(interval);
  };

  toggleDropdown = event => {
    event.stopPropagation();
    this.setState(currentState => ({ dropdownOpen: !currentState.dropdownOpen }));
  };

  render() {
    const {
      css,
      restricted,
      endDate,
      intervals,
      label,
      selectedInterval,
      startDate,
      theme,
      width,
      ...rest
    } = this.props;
    const { dropdownOpen } = this.state;

    return (
      <RestrictedTooltip restricted={restricted}>
        <Flex css={{ position: 'relative', width }}>
          <DateRangePickerWrapper
            css={{ borderRadius: '4px 0 0 4px', ...css }}
            disabled={restricted}
            onChange={this.handleOnChange}
            onFocus={this.closeDropdown}
            label={label}
            startDate={startDate ? moment(startDate) : null}
            endDate={endDate ? moment(endDate) : null}
            {...rest}
          />

          <Flex
            css={{
              border: `1px solid ${theme.colors.dark5}`,
              borderColor: css.borderColor || theme.colors.dark5,
              borderLeft: 'none',
              borderRadius: '0px 4px 4px 0px',
              color: theme.colors.dark06,
              cursor: 'pointer',
              fontSize: '24px',
              padding: '3px 3.5px',
              '&:hover': {
                color: theme.colors.dark1,
              },
            }}
            onClick={restricted ? () => {} : this.toggleDropdown}
          >
            <DropdownIcon />
          </Flex>

          {!restricted && (
            <IntervalDropdown
              intervals={intervals}
              isOpen={dropdownOpen}
              onSelect={this.selectInterval}
              selectedInterval={selectedInterval}
            />
          )}
        </Flex>
      </RestrictedTooltip>
    );
  }
}

DateIntervalSelect.defaultProps = {
  css: {},
  restricted: false,
  endDate: null,
  intervals: null,
  label: null,
  onChange: () => {},
  onSelect: () => {},
  selectedInterval: null,
  startDate: null,
  width: '284px',
};

DateIntervalSelect.propTypes = {
  css: PropTypes.shape({}),
  restricted: PropTypes.bool,
  endDate: PropTypes.string,
  intervals: PropTypes.arrayOf(PropTypes.object),
  label: PropTypes.string,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  selectedInterval: PropTypes.string,
  startDate: PropTypes.string,
  theme: PropTypes.shape({}).isRequired,
  width: PropTypes.string,
};

export default withTheme(DateIntervalSelect);
