import { assocPath, merge } from 'ramda';

import * as types from './types';

const INITIAL_STATE = {
  endDate: null,
  interval: null,
  intervalLabel: 'Default',
  startDate: null,
  compare: {
    endDate: null,
    interval: null,
    intervalLabel: 'Default',
    startDate: null,
    intervalsOptions: [],
  },
};

const saveDatesReducer = (state, { endDate, interval, intervalLabel, startDate }) =>
  merge(state, { endDate, interval, intervalLabel, startDate });
const saveComparisonDatesReducer = (state, { endDate, interval, intervalLabel, startDate }) =>
  merge(state, {
    compare: {
      endDate,
      interval,
      intervalLabel,
      startDate,
      intervalsOptions: state.compare.intervalsOptions,
    },
  });
const updateComparisonIntervals = (state, { intervals }) =>
  assocPath(['compare', 'intervalsOptions'], intervals, state);

const reducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case types.SAVE_DATES: {
      return saveDatesReducer(state, action);
    }
    case types.SAVE_COMPARISON_DATES: {
      return saveComparisonDatesReducer(state, action);
    }
    case types.INITIALIZE_COMPARISON_DATES_SUCCESS: {
      return saveDatesReducer(state, action);
    }
    case types.UPDATE_COMPARISON_INTERVALS: {
      return updateComparisonIntervals(state, action);
    }
    default: {
      return state;
    }
  }
};

export default reducer;
