import React from 'react'

const DatepickerArrowIcon = props => (
  <svg viewBox='0 0 14 10' width='1em' height='1em' {...props}>
    <g
      stroke='#BDCCD4'
      strokeWidth={2}
      fill='none'
      fillRule='evenodd'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <path d='M8.544 1l3.824 3.824L8.544 8.65M1 5h11.368' />
    </g>
  </svg>
)

export default DatepickerArrowIcon
