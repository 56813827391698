import * as React from 'react';
import PropTypes from 'prop-types';

function LightBulbExclamationIcon({ fillOpacity, ...props }) {
  return (
    <svg width="2em" height="2em" viewBox="0 0 640 512" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M131.1 55.25l-55.5-32C68.25 18.75 59 18.62 51.38 22.88c-7.5 4.375-12.12 12.38-12.12 21c.125 8.75 4.875 16.75 12.38 20.88l55.5 32C114.5 101.3 123.8 101.4 131.4 97.13c7.5-4.375 12.12-12.38 12.12-21C143.4 67.38 138.6 59.38 131.1 55.25zM107.1 287.3l-55.5 32c-7.5 4.125-12.25 12.13-12.38 20.88c0 8.625 4.625 16.62 12.12 21c7.625 4.25 16.88 4.125 24.25-.375l55.5-32C142.3 322 145.1 307.5 139.5 296.3C133 284.9 118.5 280.9 107.1 287.3zM112 192c0-13.25-10.75-24-24-24h-64c-13.25 0-24 10.75-24 24s10.75 24 24 24h64C101.3 216 112 205.3 112 192zM521 100c4.125 0 8.25-1.125 12-3.25l55.38-32c11.25-6.75 14.88-21.25 8.375-32.5c-6.5-11.38-21-15.38-32.38-9l-55.38 32c-9.5 5.375-14 16.5-11.25 27C500.6 92.75 510.1 100 521 100zM588.4 319.3l-55.5-32c-7.375-4.5-16.62-4.625-24.25-.375c-7.5 4.375-12.12 12.38-12.12 21c.125 8.75 4.875 16.75 12.38 20.88l55.5 32c11.38 6.375 25.88 2.375 32.38-9C603.3 340.5 599.5 326 588.4 319.3zM616 168h-64c-13.25 0-24 10.75-24 24s10.75 24 24 24h64c13.25 0 24-10.75 24-24S629.3 168 616 168zM240.2 454.4c.125 6.25 1.855 12.43 5.355 17.68l17.08 25.69C268.6 506.6 278.6 512 289.2 512h61.67c10.75 0 20.73-5.395 26.61-14.27l17.02-25.69c3.5-5.25 5.419-11.44 5.419-17.69L400 415.1L240.1 415.1L240.2 454.4zM319.5 .0127C217.5 .2627 144 82.1 144 175.1c0 44.38 16.52 84.89 43.52 115.8c16.62 18.88 42.36 58.23 52.24 91.48c0 .25 .1146 .5104 .1146 .7604l160.3 .0006c0-.25 .0833-.5111 .0833-.7611c9.875-33.25 35.61-72.61 52.24-91.48C479.5 260.9 496 220.4 496 175.1C496 78.62 416.8-.2373 319.5 .0127zM296 88C296 74.75 306.8 64 320 64s24 10.75 24 24v112C344 213.3 333.3 224 320 224S296 213.3 296 200V88zM320 320c-17.67 0-32-14.33-32-32s14.33-32 32-32s32 14.33 32 32S337.7 320 320 320z"
        fill="currentColor"
        fillOpacity={fillOpacity}
      />
    </svg>
  );
}

LightBulbExclamationIcon.propTypes = {
  fillOpacity: PropTypes.number,
};

LightBulbExclamationIcon.defaultProps = {
  fillOpacity: 1,
};

export default LightBulbExclamationIcon;
