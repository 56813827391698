/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';

const Section = ({ outerBackground, children }) => {

  return (
    <div className="pure-u-1">
      <div
        css={{
          background: outerBackground,
        }}
      >
        <div
          css={{
            margin: '0 auto',
            maxWidth: '1280px',
          }}
        >
          <div className="pure-u-1-12" />
          <div className="pure-u-5-6">{children}</div>
          <div className="pure-u-1-12" />
        </div>
      </div>
    </div>
  );
};

Section.defaultProps = {
  children: null,
  outerBackground: '#F3F5F7',
};

Section.propTypes = {
  children: PropTypes.node,
  outerBackground: PropTypes.string,
};

export default Section;
