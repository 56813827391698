import api from '../../../lib/api';

export const fetchBrandData = async brandId => {
  const comparingToResponse = await api.get('/v3/reports/benchmarks');
  const comparisonResponse = await api.get(`/v3/reports/benchmarks?company_id=${brandId}`);

  const comparingToMeta = comparingToResponse.data.meta;
  const comparingToName = comparingToResponse.data.data[0].attributes.name;
  const comparisonMeta = comparisonResponse.data.meta;
  const comparisonName = comparisonResponse.data.data[0].attributes.name;

  return { comparingToMeta, comparingToName, comparisonMeta, comparisonName };
};

export const fetchGroupBrands = async groupId => {
  const response = await api.get(`/v3/groups/${groupId}/companies`, { params: { size: 999 } });

  return response.data;
};

export const fetchGroupData = async (groupId, brandId) => {
  let comparingToResponse;
  let comparisonResponse;

  if (brandId) {
    comparingToResponse = await api.get(`/v3/reports/benchmarks?group_id=${groupId}`);
    comparisonResponse = await api.get(`/v3/reports/benchmarks?company_id=${brandId}`);
  } else {
    comparingToResponse = await api.get('/v3/reports/benchmarks');
    comparisonResponse = await api.get(`/v3/reports/benchmarks?group_id=${groupId}`);
  }

  const comparingToMeta = comparingToResponse.data.meta;
  const comparingToName = comparingToResponse.data.data[0].attributes.name;
  const comparisonMeta = comparisonResponse.data.meta;
  const comparisonName = comparisonResponse.data.data[0].attributes.name;

  return { comparingToMeta, comparingToName, comparisonMeta, comparisonName };
};

export const fetchGroupBenchmarks = async (groupId, brandId) => {
  let query = '';

  if (brandId) {
    query = `?company_id=${brandId}`;
    query += `&comparison[group_id]=${groupId}`;
  } else {
    query = `?group_id=${groupId}`;
  }

  const response = await api.get(`/v3/reports/benchmarks${query}`);

  return response.data;
};

export const fetchBrandBenchmarks = async brandId => {
  const query = `?company_id=${brandId}`;

  const response = await api.get(`/v3/reports/benchmarks${query}`);

  return response.data;
};
