import * as React from 'react';
import PropTypes from 'prop-types';

function FYICircleIcon({ fillOpacity, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M3 12.156a9.033 9.033 0 002.668 6.31 8.736 8.736 0 006.344 2.532 9.153 9.153 0 006.38-2.74 8.851 8.851 0 002.603-6.415A8.833 8.833 0 0012.145 3h-.162A9.081 9.081 0 003 12.156zm10.087 4.204a1.09 1.09 0 11-2.18 0v-4.903a1.09 1.09 0 112.18 0v4.903zm-1.113-9.832h.024a1.362 1.362 0 01.023 2.724h-.023a1.362 1.362 0 11-.022-2.724h-.002z"
        fill="currentColor"
        fillOpacity={fillOpacity}
      />
    </svg>
  );
}

FYICircleIcon.propTypes = {
  fillOpacity: PropTypes.number,
};

FYICircleIcon.defaultProps = {
  fillOpacity: 1,
};

export default FYICircleIcon;
