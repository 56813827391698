import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

import PaymentLink from '../PaymentLink';
import ContactModalLink from '../ContactModalLink';
import { topics }  from '../ContactUs';

import HeaderContainer from './ui/HeaderContainer';
import Label from './ui/Label';
import Title from './ui/Title';

const buttonStyle = {
  fontSize: `${13 / 16}em`,
  lineHeight: '1em',
  padding: '1em 1.8em',
};

const Header = ({
  journeys,
  model,
  upgradeHandler,
}, {
  router,
}) => {
  if (journeys.loading) {
    return null;
  }

  const [journey] = journeys.data[0].data;
  const [company] = journey.relationships.company.data;
  const { journeys:j, segments:s } = model.relationships

  return (
    <HeaderContainer style={{ maxHeight: 400 }}>
      <Label>Pro</Label>

      <Title>Upgrade to see {model.attributes.name} user journeys</Title>

      <p style={{ maxWidth: '620px' }}>
        User journeys allow you to view brands' on-boarding strategy, cart
        abandonment offers, incentives for repeat purchases, and more.
      </p>

      <p style={{ maxWidth: '620px' }}>
        By upgrading your plan you will unlock these journeys immediately.
      </p>

      <PaymentLink
        component="button"
        className="pure-button pure-button-primary"
        style={{ ...buttonStyle, marginRight: '24px' }}
        onClick={upgradeHandler}
      >
        Upgrade Now
      </PaymentLink>

      <Link
        to={{
          pathname: '/demo/journeys',
          query: {
            company_id: company.id,
            segment_id: journey.id,
          },
          state: {
            restrictedUrl: router.location.pathname,
          },
        }}
        className="pure-button"
        style={{ ...buttonStyle, borderColor: '#d4d9dd' }}
      >
        Try an example
      </Link>
      <p style={{ fontSize: `${12/16}em`, maxWidth: 500 }}>
        <strong>Interested in a free month?</strong> Schedule some time
        to chat with us and we'll unlock your account for one month.{' '}
        <ContactModalLink topic={topics.QUESTION}>
          Contact Support
        </ContactModalLink>
      </p>

    </HeaderContainer>
  );
};

Header.contextTypes = {
  router: PropTypes.object,
};

Header.propTypes = {
  journeys: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
      relationships: PropTypes.object,
    })),
    loading: PropTypes.bool,
  }).isRequired,
  model: PropTypes.shape({}).isRequired,
  upgradeHandler: PropTypes.func.isRequired,
};

export default Header;
