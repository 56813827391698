/** @jsx jsx */
import { jsx } from '@emotion/react';
import React from 'react';
import { PropTypes } from 'prop-types';

import Body from './tab/Body';
import Header from './tab/Header';
import Item from './tab/Item';

class Tab extends React.Component {
  static Body = props => <Body {...props} />;
  static Header = props => <Header {...props} />;
  static Item = props => <Item {...props} />;

  state = {
    active: this.props.defaultActive,
  };

  setActive = name => {
    this.setState({ active: name }, () => {
      this.props.onTabChange(name);
    });
  };

  render() {
    const { css: customCSS, style } = this.props;
    const { active } = this.state;

    return (
      <div
        css={{
          ...customCSS,
          ...style,
        }}
      >
        {React.Children.map(this.props.children, child =>
          React.cloneElement(child, {
            active,
            setActive: this.setActive,
          }),
        )}
      </div>
    );
  }
}

Tab.defaultProps = {
  css: {},
  defaultActive: '',
  onTabChange: () => {},
  style: {},
};

Tab.propTypes = {
  children: PropTypes.node.isRequired,
  css: PropTypes.shape({}),
  defaultActive: PropTypes.string,
  onTabChange: PropTypes.func,
  style: PropTypes.shape({}),
};

export default Tab;
