import { flatten } from 'ramda';
import { theme } from '../../../../mc-ui';

const thisPeriodRegex = /\{THIS_PERIOD\}/;
const holidaysRegex = /\{HOLIDAYS\}/;
const lastYearRegex = /\{LAST_YEAR\}/;

const generateDefaultTooltipHTML = () => (getBody, tooltipModel) => {
  if (!tooltipModel.body) return null;

  const titleLines = tooltipModel.title || [];
  const bodyLines = flatten(tooltipModel.body.map(getBody));

  let tooltipHTML = '';
  bodyLines.forEach((bodyLine, index) => {
    const title = titleLines[index];
    if (thisPeriodRegex.test(bodyLine)) {
      tooltipHTML += `<div class="title">${title}</div>`;
      tooltipHTML += `<div class="line"><div class="bullet" style="background-color: ${
        theme.colors.blue
      }"></div> <div class="content">${bodyLine.replace(thisPeriodRegex, '')}</div></div>`;
    } else if (holidaysRegex.test(bodyLine)) {
      tooltipHTML += `<div class="title">${title}</div>`;
      tooltipHTML += `<div class="line"><div class="bullet" style="background-color: ${
        theme.colors.purpleMedium
      }"></div> <div class="content">${bodyLine.replace(holidaysRegex, '')}</div></div>`;
    } else if (lastYearRegex.test(bodyLine)) {
      tooltipHTML += `<div class="title">${title}</div>`;
      tooltipHTML += `<div class="line"><div class="bullet" style="background-color: #9DA5B3"></div> <div class="content">${bodyLine.replace(
        lastYearRegex,
        '',
      )}</div></div>`;
    }
  });

  return tooltipHTML;
};

export default generateDefaultTooltipHTML;
