import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { www } from '../../../lib/utils';

import {
  customListsActions as actions,
  customListsSelectors as selectors,
} from '../../../modules/customLists';

import CheckSmallIcon from '../../mc-ui/icons/CheckSmallIcon';
import VerticalDotsIcon from '../../mc-ui/icons/VerticalDotsIcon';
import { Box, Button, theme } from '../../mc-ui';

import ListForm from './ListForm';

// eslint-disable-next-line react/prop-types
const MenuItem = ({ as: As = Button, ...props }) => (
  <As
    variant="none"
    css={{
      alignItems: 'center',
      border: 'none !important',
      display: 'flex',
      height: '32px',
      justifyContent: 'space-between',
      lineHeight: '32px',
      padding: '0 8px',
      width: '100%',
      '&:hover': { backgroundColor: theme.colors.dark6, border: 'none', color: theme.colors.dark1 },
    }}
    {...props}
  />
);

class ListControlDropdown extends React.Component {
  state = {
    copied: false,
    sharingWithTeam: false,
  };

  handleCopyPublicURL = event => {
    event.preventDefault();

    const { list } = this.props;

    if (list.attributes.state === 'private') {
      this.handleEdit({ state: 'public' });
    }

    const url = www(`/lists/${list.attributes.guid}`);

    this.setState({ copied: true }, () => {
      window.navigator.clipboard.writeText(url);

      window.setTimeout(() => {
        this.setState(() => ({ copied: false }));
      }, 2000);
    });
  };

  shareListWithTeam = event => {
    event.preventDefault();

    this.setState(() => ({ sharingWithTeam: true }), this.props.shareListWithTeam);
  };

  handleEdit = (values, callback) => {
    const { handleEdit, handleEditTeamList, isListingTeamLists, refetchLists } = this.props;
    const func = isListingTeamLists ? handleEditTeamList : handleEdit;

    func(values, () => {
      if (callback) {
        callback();
      }
      refetchLists();
    });
  };

  handleDelete = event => {
    if (event) {
      event.preventDefault();
    }

    const { deleteList, deleteTeamList, isListingTeamLists, refetchLists } = this.props;
    const func = isListingTeamLists ? deleteTeamList : deleteList;
    func(refetchLists);
  };

  render() {
    const {
      closeForm,
      isFormOpen,
      isListingTeamLists,
      isOwner,
      isValidTeam,
      list,
      openForm,
      teamListIds,
    } = this.props;
    const { copied, sharingWithTeam } = this.state;
    const shared = teamListIds.includes(list.id.toString());

    return (
      <Box
        position="relative"
        width="unset"
        css={{
          '& > button': {
            color: `${theme.colors.dark06} !important`,
          },
          '& > div': {
            display: 'none',
          },
          '&:hover': {
            '& > button': {
              color: `${theme.colors.dark1} !important`,
            },
            '& > div': {
              display: 'block',
            },
          },
        }}
      >
        <Button
          variant="none"
          css={{
            alignItems: 'center',
            display: 'inline-flex',
            fontSize: '24px',
            height: '24px',
            justifyContent: 'center',
            margin: '0',
            padding: '0',
            width: '24px',
          }}
        >
          <VerticalDotsIcon />
        </Button>

        <Box
          backgroundColor="white"
          borderRadius="4px"
          padding="4px"
          position="absolute"
          right="0"
          top="14px"
          width="216px"
          css={{
            filter: 'drop-shadow(0px 2px 8px rgba(9, 30, 66, 0.2))',
            '& > div': { width: '100% !important' },
          }}
          zIndex="6"
        >
          <MenuItem onClick={this.handleCopyPublicURL}>
            Share public URL {copied && <small>copied</small>}
          </MenuItem>

          {isOwner &&
            !isListingTeamLists &&
            isValidTeam && (
              <MenuItem disabled={shared || sharingWithTeam} onClick={this.shareListWithTeam}>
                Share with team
                {(shared || sharingWithTeam) && <CheckSmallIcon />}
              </MenuItem>
            )}

          <Box position="relative" width="unset">
            <MenuItem onClick={openForm}>Edit</MenuItem>

            {isFormOpen && (
              <Box
                left="0"
                position="absolute"
                top="16px"
                zIndex={theme.zIndices.dropdown}
                width="unset"
              >
                <ListForm
                  handleClose={closeForm}
                  initialValues={{
                    ...list.attributes,
                  }}
                  onSubmit={this.handleEdit}
                  hideCloseButton
                />
              </Box>
            )}
          </Box>

          <MenuItem onClick={this.handleDelete} variant="destructive">
            Remove
          </MenuItem>
        </Box>
      </Box>
    );
  }
}

ListControlDropdown.defaultProps = {
  isListingTeamLists: false,
};

ListControlDropdown.propTypes = {
  closeForm: PropTypes.func.isRequired,
  deleteList: PropTypes.func.isRequired,
  deleteTeamList: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleEditTeamList: PropTypes.func.isRequired,
  isFormOpen: PropTypes.bool.isRequired,
  isListingTeamLists: PropTypes.bool,
  isOwner: PropTypes.bool.isRequired,
  isValidTeam: PropTypes.bool.isRequired,
  list: PropTypes.shape({
    attributes: PropTypes.object,
  }).isRequired,
  openForm: PropTypes.func.isRequired,
  refetchLists: PropTypes.func.isRequired,
  shareListWithTeam: PropTypes.func.isRequired,
  teamListIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = (state, { list }) => ({
  isFormOpen: selectors.isEditFormOpenSelector(list)(state),
  isOwner: selectors.isOwnerSelector(list)(state),
  isValidTeam: selectors.isValidTeamSelector(state),
  teamListIds: selectors.teamListIdsSelector(state),
});

const mapDispatchToProps = (dispatch, { list, listType }) => ({
  closeForm: () => dispatch(actions.closeEditForm()),
  deleteList: (...args) => dispatch(actions.deleteRequest(list, listType, ...args)),
  deleteTeamList: (...args) => dispatch(actions.deleteTeamListRequest(list.id, listType, ...args)),
  handleEdit: (...args) => dispatch(actions.updateRequest(list.id, ...args)),
  handleEditTeamList: (...args) => dispatch(actions.updateTeamListRequest(list.id, ...args)),
  openForm: () => dispatch(actions.openEditForm(list)),
  shareListWithTeam: () => dispatch(actions.shareWithTeamRequest(list.id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ListControlDropdown);
