import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { benchmarksSelectors as selectors } from '../../../modules/newReports/benchmarks';

import { Flex } from '../../mc-ui';
import MobileIcon from '../../mc-ui/icons/MobileIcon';

import ReportSection from '../ReportSection';
import Title from '../Title';

import BarsChart from './BarsChart';
import SectionInformation from './SectionInformation';

const MobileOptimized = ({ data, loading, ...props }) => (
  <Flex>
    <ReportSection css={{ minHeight: '285px' }}>
      <ReportSection.Header>
        <Title>Mobile Optimization Rate</Title>
      </ReportSection.Header>
      <ReportSection.Body>
        <Flex justifyContent="space-between">
          <SectionInformation
            content="The majority of emails are opened on mobile devices. Compare this groups mobile optimized rate to other industries and brands."
            icon={MobileIcon}
            type="ahref"
            linkTo="https://knowledge.validity.com/hc/en-us/articles/9513216584731-Email-Metrics"
          />

          <BarsChart
            adjustment={r => Math.round(r)}
            append=" %"
            data={data.mobile_optimized}
            loading={loading}
            {...props}
          />
        </Flex>
      </ReportSection.Body>
    </ReportSection>
  </Flex>
);

MobileOptimized.propTypes = {
  data: PropTypes.shape({
    mobile_optimized: PropTypes.shape({}),
  }).isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, { model, brandId }) => {
  if (brandId && model.id && model.type === 'groups') {
    return {
      data: selectors.getGroupComparisonData(model.id, brandId)(state),
      loading: selectors.getGroupComparisonLoading(model.id, brandId)(state),
    };
  }

  if (model.id && model.type === 'companies') {
    return {
      data: selectors.getBrandComparisonData(model.id)(state),
      loading: selectors.getBrandComparisonLoading(model.id)(state),
    };
  }

  return {
    data: { mobile_optimized: {} },
    loading: true,
  };
};

export default connect(mapStateToProps)(MobileOptimized);
