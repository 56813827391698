import styled from "@emotion/styled";

const Divider = styled("div")(({ color = 'dark01', borderSize = '1px', theme, margin = '', }) => ({
  backgroundColor: theme.colors[color],
  boxSizing: 'border-box',
  margin,
  height: borderSize,
  width: '100%',
}));

export default Divider;
