import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { connect } from 'react-redux';
import { isEmpty, omit } from 'ramda';

import { Box, Flex, Heading, Link } from '../../mc-ui';
import CloseIcon from '../../mc-ui/icons/OldCloseIcon';

import * as S from './styles';
import { ignoreKeys, queryToParams } from './searchParams';
import SaveSearch from '../../ui/SaveSearch';

class SearchQuery extends React.Component {
  componentDidMount() {
    const { groups, requestGroups } = this.props;
    const params = { sort: 'name:asc', global: true, limit: 250 };

    if (groups.data.length === 0) {
      requestGroups(params);
    }
  }

  clear = event => {
    event.preventDefault();
    this.props.router.push('/brands');
  };

  removeQuery = event => {
    const { query, router } = this.props;
    const { paramKey, paramValue, queryValue } = event.currentTarget.dataset;

    const newQuery = { ...query };
    if (paramValue) {
      newQuery[paramKey] = newQuery[paramKey].filter(
        value => value !== paramValue && value !== queryValue,
      );
    } else {
      delete newQuery[paramKey];
    }

    router.push({
      pathname: router.location.pathname,
      query: newQuery,
    });
  };

  saveSearch = event => {
    event.preventDefault();
  };

  render() {
    const { groups, query } = this.props;

    if (isEmpty(query)) return null;

    const queryParams = groups.loading ? [] : queryToParams(query, this.props);
    const filters = omit(ignoreKeys, query);
    const filtersLength = Object.keys(filters).length;

    return (
      <Box paddingTop="16px">
        <Flex alignItems="center">
          <Heading
            as="h5"
            color="dark08"
            fontWeight="bold"
            lineHeight="1.56em"
            css={{ letterSpacing: '0.08em' }}
          >
            {filtersLength} {pluralize('Filter', filtersLength)}
          </Heading>

          <SaveSearch filters={query} type="brand" css={{ marginLeft: '16px' }}>
            <Link to="#saveSearch" onClick={this.saveSearch} css={{ fontSize: '12px' }}>
              Save this filter
            </Link>
          </SaveSearch>

          <Link
            to="#clear"
            onClick={this.clear}
            color="red1"
            css={{ fontSize: '12px', marginLeft: '16px' }}
          >
            Clear
          </Link>
        </Flex>

        {!groups.loading &&
          queryParams.map(param => (
            <S.Label key={`SearchQuery-${param.key}`}>
              {param.label}:{' '}
              {typeof param.values === 'string' ? (
                <S.Tag
                  key={`SearchQuery-${param.key}-value}`}
                  data-param-key={param.key}
                  onClick={this.removeQuery}
                >
                  {param.value}
                  <CloseIcon height="8px" width="8px" />
                </S.Tag>
              ) : (
                param.values.map((value, index) => (
                  <S.Tag
                    key={`SearchQuery-${param.key}-${value}}`}
                    data-param-key={param.key}
                    data-param-value={value}
                    data-query-value={param.queryValues[index]}
                    onClick={this.removeQuery}
                  >
                    {value}
                    <CloseIcon height="8px" width="8px" />
                  </S.Tag>
                ))
              )}
            </S.Label>
          ))}
      </Box>
    );
  }
}

SearchQuery.propTypes = {
  groups: PropTypes.shape({}).isRequired,
  query: PropTypes.shape({}).isRequired,
  requestGroups: PropTypes.func.isRequired,
  router: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
};

const mapStateToProps = state => ({
  groups: state.groups,
});

const mapDispatchToProps = dispatch => ({
  requestGroups: params =>
    dispatch({
      type: 'REQUEST_GROUPS',
      url: '/v3/groups',
      index: true,
      params,
    }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchQuery);
