import styled from "@emotion/styled";

const CardBody = styled("div")(({ theme }) => ({
  backgroundColor: theme.colors.white,
  border: `1px solid ${theme.colors.dark5}`,
  borderTop: 'none',
  borderRadius: '0 0 3px 3px',
  boxSizing: 'border-box',
  padding: '16px 24px 24px 24px',
  '& ul, & li, & a, & small': {
    margin: '0',
    padding: '0',
  },
  '& ul, & li': {
    listStyle: 'none',
  },
  '& ul > li': {
    marginTop: '16px',
    '& > a': {
      color: theme.colors.blue,
      fontFamily: theme.font.family,
      fontSize: '16px',
      lineHeight: '1.56em',
      textDecoration: 'none',
    },
    '& > small': {
      color: theme.colors.dark06,
      display: 'block',
      fontFamily: theme.font.family,
      fontSize: '14px',
      lineHeight: '1.56em',
      marginTop: '2px',
    },
  },
}));

export default CardBody;
