import React from 'react';
import PropTypes from 'prop-types';
import md5 from 'md5';
import moment from 'moment';

import CollectionIcon from '../../mc-ui/icons/CollectionIcon';
import EmailIcon from '../../mc-ui/icons/EmailIcon';
import { Box, Flex, Text } from '../../mc-ui';

import * as S from './styles';
import CommentableLogo from './CommentableLogo';

export default function Comment({ comment }) {
  const [author] = comment.relationships.users.data;
  const [parent] = comment.relationships.comments.data;
  const { commentable } = parent.attributes;

  return (
    <Flex alignItems="flex-start" borderBottom="1px solid" borderColor="dark5" padding="24px 0">
      <S.UserAvatar
        src={`https://www.gravatar.com/avatar/${md5(
          (author.attributes.email || '').toLowerCase(),
        )}?s=50&d=mp`}
        alt={author.attributes.name}
      />

      <Box>
        <Flex alignItems="center" height="24px" width="738px">
          <S.UserName>{author.attributes.name}</S.UserName>

          <Text lineHeight="24px" whiteSpace="nowrap" css={{ margin: '0 8px' }}>
            left a note on {commentable.type === 'lists' ? 'list' : 'email'}
          </Text>

          <S.CommentableLink
            to={
              commentable.type === 'lists'
                ? `/lists/${commentable.id}`
                : `/emails/${commentable.guid}?list_id=${commentable.list_id}&panel=notes`
            }
          >
            {commentable.type === 'lists' ? (
              <CollectionIcon height="20px" width="20px" />
            ) : (
              <EmailIcon fillOpacity={1} height="20px" width="20px" />
            )}
            <span>{commentable.type === 'lists' ? commentable.name : commentable.subject}</span>
          </S.CommentableLink>
        </Flex>

        <Text
          fontSize="12px"
          title={moment(comment.attributes.created_at).format('MMM DD, YYYY - LT')}
          css={{ marginBottom: '16px' }}
        >
          {moment(comment.attributes.created_at).fromNow()}
        </Text>

        <Flex
          alignItems="center"
          backgroundColor="dark004"
          borderRadius="4px"
          justifyContent="space-between"
          padding="16px"
        >
          <Text color="dark08" whiteSpace="pre-line" css={{ flex: 1, marginRight: '24px' }}>
            {comment.attributes.text}
          </Text>

          <CommentableLogo commentable={commentable} />
        </Flex>
      </Box>
    </Flex>
  );
}

Comment.propTypes = {
  comment: PropTypes.shape({}).isRequired,
};
