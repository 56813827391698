import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { connect } from 'react-redux';

import {
  savedJourneysActions as actions,
  savedJourneysSelectors as selectors,
} from '../../../../../modules/savedJourneys';

import { userSelectors } from '../../../../../modules/user';

import CheckBigIcon from '../../../../mc-ui/icons/CheckBigIcon';
import CheckSmallIcon from '../../../../mc-ui/icons/CheckSmallIcon';
import PlusIcon from '../../../../mc-ui/icons/PlusIcon';
import ProBadge from '../../../../ui/ProBadge';
import { Box, Button, Tooltip, theme } from '../../../../mc-ui';

const MenuItem = props => (
  <Button
    variant="none"
    css={{
      alignItems: 'center',
      display: 'flex',
      height: '32px',
      justifyContent: 'space-between',
      lineHeight: '32px',
      padding: '0 8px',
      textAlign: 'left',
      width: '100%',
      '&:hover': { backgroundColor: theme.colors.dark6, border: 'none', color: theme.colors.dark1 },
    }}
    {...props}
  />
);

// eslint-disable-next-line react/prop-types
function SaveButton({ disabled }) {
  return (
    <Button
      disabled={disabled}
      variant="none"
      css={{
        alignItems: 'center',
        backgroundColor: disabled ? theme.colors.dark01 : null,
        display: 'inline-flex',
        fontSize: '24px',
        height: '24px',
        justifyContent: 'center',
        margin: '0',
        padding: '0',
        width: '24px',
      }}
    >
      {disabled ? <CheckBigIcon /> : <PlusIcon />}
    </Button>
  );
}

function SaveJourneyDropdown(
  { addToMyList, addToTeamList, isMy, isTeam, isUserRestricted, journey },
  { router },
) {
  const {
    location: { pathname },
  } = router;

  if (pathname.match(/^\/(my|team)-collections\/saved-journeys$/)) {
    return null;
  }

  const isButtonDisabled = (isMy && isTeam) || (isMy && isUserRestricted);
  return (
    <Box
      marginLeft="8px"
      position="relative"
      width="unset"
      css={{
        '& > div[data-type=dropdown]': {
          display: 'none',
        },
        '&:hover': {
          '& > div[data-type=dropdown]': {
            display: 'block',
          },
        },
      }}
    >
      {isButtonDisabled ? (
        <Tooltip
          id={`savedJourneyTooltip${journey.id}`}
          element={<SaveButton disabled={isButtonDisabled} />}
          dark
        >
          {isUserRestricted
            ? 'This journey is already saved on your collection.'
            : 'This journey is already saved on both collections.'}{' '}
          View your <Link to="/my-collections/saved-journeys">saved journeys</Link>.
        </Tooltip>
      ) : (
        <SaveButton disabled={isButtonDisabled} />
      )}

      {!isButtonDisabled && (
        <Box
          backgroundColor="white"
          borderRadius="4px"
          padding="4px"
          position="absolute"
          right="0"
          top="14px"
          width="216px"
          css={{ filter: 'drop-shadow(0px 2px 8px rgba(9, 30, 66, 0.2))' }}
          zIndex="6"
          data-type="dropdown"
        >
          <MenuItem disabled={isMy} onClick={addToMyList}>
            Add to my list
            {isMy && <CheckSmallIcon />}
          </MenuItem>

          <MenuItem disabled={isUserRestricted || isTeam} onClick={addToTeamList}>
            Add to team list
            {isUserRestricted && <ProBadge small />}
            {!isUserRestricted && isTeam && <CheckSmallIcon />}
          </MenuItem>
        </Box>
      )}
    </Box>
  );
}

SaveJourneyDropdown.contextTypes = {
  router: PropTypes.object,
};

SaveJourneyDropdown.propTypes = {
  addToMyList: PropTypes.func.isRequired,
  addToTeamList: PropTypes.func.isRequired,
  isMy: PropTypes.bool.isRequired,
  isTeam: PropTypes.bool.isRequired,
  isUserRestricted: PropTypes.bool.isRequired,
  journey: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state, { journey }) => ({
  isMy: selectors.isInMySelector(journey.id)(state),
  isTeam: selectors.isInTeamSelector(journey.id)(state),
  isUserRestricted: userSelectors.isUserRestrictedSelector(state),
});

const mapDispatchToProps = (dispatch, { journey }) => ({
  addToMyList: event => {
    event.preventDefault();
    dispatch(actions.saveJourneyToMyListRequest(journey.id));
  },
  addToTeamList: event => {
    event.preventDefault();
    dispatch(actions.saveJourneyToTeamListRequest(journey.id));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SaveJourneyDropdown);
