import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { teamsActions } from '../../../../modules/teams';

import { Button, Flex, Heading, Modal, Text } from '../../../mc-ui';

function RemoveConfirmationModal({
  closeModal,
  isAdminOrOwner,
  isSelf,
  memberEmail,
  removeMember,
}) {
  return (
    <Modal isOpen onClose={closeModal}>
      <Modal.Body paddingTop="40px">
        <Flex alignItems="center" direction="column" justifyContent="center" width="696px">
          <img src="/images/alert.png" alt="Alert" />

          <Heading
            as="h2"
            fontSize="24px"
            fontWeight="300"
            lineHeight="1.32em"
            css={{ margin: '40px 0 24px 0' }}
          >
            Are you sure?
          </Heading>

          {isAdminOrOwner && !isSelf ? (
            <Text margin="0 0 24px 0">
              <strong>{memberEmail}</strong> will lose access immediately and not be able to rejoin
              unless invited again.
            </Text>
          ) : (
            <Text margin="0 0 24px 0">
              You will lose access immediately and not be able to rejoin unless you are invited
              again.
            </Text>
          )}

          <Flex justifyContent="space-between" width="220px">
            <Button
              css={{ height: '40px', lineHeight: '40px', margin: 0, padding: 0, width: '100px' }}
              onClick={removeMember}
              variant="primary"
            >
              Remove
            </Button>

            <Button
              css={{ height: '40px', lineHeight: '40px', margin: 0, padding: 0, width: '100px' }}
              onClick={closeModal}
              variant="secondary"
            >
              Nevermind
            </Button>
          </Flex>
        </Flex>
      </Modal.Body>
    </Modal>
  );
}

RemoveConfirmationModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isAdminOrOwner: PropTypes.bool.isRequired,
  isSelf: PropTypes.bool.isRequired,
  memberEmail: PropTypes.string.isRequired,
  removeMember: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch, { closeModal, memberId, teamId }) => ({
  removeMember: () => {
    dispatch(teamsActions.removeMemberRequest(teamId, memberId));
    closeModal();
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RemoveConfirmationModal);
