/* global window */
/* eslint no-underscore-dangle: "off" */

import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { responsiveStoreEnhancer } from 'redux-responsive';
import createSagaMiddleware, { END } from 'redux-saga';
import { browserHistory } from 'react-router';
import { routerMiddleware, routerReducer } from 'react-router-redux';

import * as reducers from './reducers';
import middleware from './middleware';
import rootSaga from './rootSaga';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  combineReducers({ ...reducers, routing: routerReducer }),
  composeEnhancers(
    responsiveStoreEnhancer,
    applyMiddleware(...middleware, sagaMiddleware, routerMiddleware(browserHistory)),
  ),
);

store.runSaga = sagaMiddleware.run;
store.runSaga(rootSaga);
store.close = () => store.dispatch(END);

export default store;
