import { PREFIX } from './constants';

export const DELETE_REQUEST = `${PREFIX}/DELETE_REQUEST`;
export const DELETE_SUCCESS = `${PREFIX}/DELETE_SUCCESS`;
export const DELETE_FAILURE = `${PREFIX}/DELETE_FAILURE`;

export const CLOSE_CREATE_FORM = `${PREFIX}/CLOSE_CREATE_FORM`;
export const OPEN_CREATE_FORM = `${PREFIX}/OPEN_CREATE_FORM`;

export const CLOSE_EDIT_FORM = `${PREFIX}/CLOSE_EDIT_FORM`;
export const OPEN_EDIT_FORM = `${PREFIX}/OPEN_EDIT_FORM`;

export const CREATE_REQUEST = `${PREFIX}/CREATE_REQUEST`;
export const CREATE_SUCCESS = `${PREFIX}/CREATE_SUCCESS`;
export const CREATE_FAILURE = `${PREFIX}/CREATE_FAILURE`;

export const UPDATE_REQUEST = `${PREFIX}/UPDATE_REQUEST`;
export const UPDATE_SUCCESS = `${PREFIX}/UPDATE_SUCCESS`;
export const UPDATE_FAILURE = `${PREFIX}/UPDATE_FAILURE`;

export const FETCH_USER_GROUPS_REQUEST = `${PREFIX}/FETCH_USER_GROUPS_REQUEST`;
export const FETCH_USER_GROUPS_SUCCESS = `${PREFIX}/FETCH_USER_GROUPS_SUCCESS`;
export const FETCH_USER_GROUPS_FAILURE = `${PREFIX}/FETCH_USER_GROUPS_FAILURE`;

export const FETCH_TEAM_GROUPS_REQUEST = `${PREFIX}/FETCH_TEAM_GROUPS_REQUEST`;
export const FETCH_TEAM_GROUPS_SUCCESS = `${PREFIX}/FETCH_TEAM_GROUPS_SUCCESS`;
export const FETCH_TEAM_GROUPS_FAILURE = `${PREFIX}/FETCH_TEAM_GROUPS_FAILURE`;

export const SHARE_WITH_TEAM_REQUEST = `${PREFIX}/SHARE_WITH_TEAM_REQUEST`;
export const SHARE_WITH_TEAM_SUCCESS = `${PREFIX}/SHARE_WITH_TEAM_SUCCESS`;
export const SHARE_WITH_TEAM_FAILURE = `${PREFIX}/SHARE_WITH_TEAM_FAILURE`;

export const CREATE_TEAM_GROUP_REQUEST = `${PREFIX}/CREATE_TEAM_GROUP_REQUEST`;
export const CREATE_TEAM_GROUP_SUCCESS = `${PREFIX}/CREATE_TEAM_GROUP_SUCCESS`;
export const CREATE_TEAM_GROUP_FAILURE = `${PREFIX}/CREATE_TEAM_GROUP_FAILURE`;

export const DELETE_TEAM_GROUP_REQUEST = `${PREFIX}/DELETE_TEAM_GROUP_REQUEST`;
export const DELETE_TEAM_GROUP_SUCCESS = `${PREFIX}/DELETE_TEAM_GROUP_SUCCESS`;
export const DELETE_TEAM_GROUP_FAILURE = `${PREFIX}/DELETE_TEAM_GROUP_FAILURE`;

export const UPDATE_TEAM_GROUP_REQUEST = `${PREFIX}/UPDATE_TEAM_GROUP_REQUEST`;
export const UPDATE_TEAM_GROUP_SUCCESS = `${PREFIX}/UPDATE_TEAM_GROUP_SUCCESS`;
export const UPDATE_TEAM_GROUP_FAILURE = `${PREFIX}/UPDATE_TEAM_GROUP_FAILURE`;

export const ADD_BRANDS_TO_GROUP_REQUEST = `${PREFIX}/ADD_BRANDS_TO_GROUP_REQUEST`;
export const ADD_BRANDS_TO_GROUP_SUCCESS = `${PREFIX}/ADD_BRANDS_TO_GROUP_SUCCESS`;
export const ADD_BRANDS_TO_GROUP_FAILURE = `${PREFIX}/ADD_BRANDS_TO_GROUP_FAILURE`;

export const REMOVE_BRANDS_TO_GROUP_REQUEST = `${PREFIX}/REMOVE_BRANDS_TO_GROUP_REQUEST`;
export const REMOVE_BRANDS_TO_GROUP_SUCCESS = `${PREFIX}/REMOVE_BRANDS_TO_GROUP_SUCCESS`;
export const REMOVE_BRANDS_TO_GROUP_FAILURE = `${PREFIX}/REMOVE_BRANDS_TO_GROUP_FAILURE`;

export const CREATE_GROUP_AND_ADD_BRANDS_REQUEST = `${PREFIX}/CREATE_GROUP_AND_ADD_BRANDS_REQUEST`;
export const CREATE_GROUP_AND_ADD_BRANDS_SUCCESS = `${PREFIX}/CREATE_GROUP_AND_ADD_BRANDS_SUCCESS`;
export const CREATE_GROUP_AND_ADD_BRANDS_FAILURE = `${PREFIX}/CREATE_GROUP_AND_ADD_BRANDS_FAILURE`;
