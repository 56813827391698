import React from 'react';
import { withTheme } from '@emotion/react';

import { Box, Text, Tooltip } from '../../../mc-ui';
import QuestionMarkIcon from '../../../mc-ui/icons/QuestionMarkIcon';

// eslint-disable-next-line react/prop-types
const MostPopularTermsTooltip = ({ theme }) => (
  <Box
    css={{
      color: theme.colors.dark06,
      display: 'inline',
      fontSize: '12px',
      lineHeight: '1em',
      marginLeft: '6px',
      width: 'auto',
    }}
  >
    <Tooltip id="mostPopularTermsTooltip" element={<QuestionMarkIcon fillOpacity={1} />} dark>
      <Text>
        Popular terms include the words and phrases that occur the most frequently in the subject
        lines for the selected date range.
      </Text>
    </Tooltip>
  </Box>
);

export default withTheme(MostPopularTermsTooltip);
