import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '../mc-ui';
import ReactSelect from '../mc-ui/overrides/ReactSelect';

const SortSelect = ({ name, onChange, options, value }) => {
  const selectedValue = options.find(o => o.value === value);

  return (
    <Box css={{ width: '200px' }}>
      <ReactSelect defaultValue={selectedValue} name={name} onChange={onChange} options={options} />
    </Box>
  );
};

SortSelect.defaultProps = {
  name: `sort-${Math.floor(Math.random() * 100)}`,
  value: '',
};

SortSelect.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  value: PropTypes.string,
};

export default SortSelect;
