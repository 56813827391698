import styled from "@emotion/styled";

const Box = styled("div")(({ theme, ...props }) => ({
  backgroundColor: props.backgroundColor ? theme.colors[props.backgroundColor] : null,
  backgroundImage: props.backgroundImage ? `url(${props.backgroundImage})` : null,
  bottom: props.bottom,
  border: props.border,
  borderBottom: props.borderBottom,
  borderLeft: props.borderLeft,
  borderRadius: props.borderRadius,
  borderRight: props.borderRight,
  borderTop: props.borderTop,
  borderColor: props.borderColor ? theme.colors[props.borderColor] : null,
  boxShadow: props.boxShadow || null,
  boxSizing: 'border-box',
  color: props.color ? theme.colors[props.color] : null,
  display: props.inline ? 'inline-block' : 'block',
  flex: props.flex || null,
  height: props.height || null,
  left: props.left,
  margin: props.margin,
  marginBottom: props.marginBottom,
  marginLeft: props.marginLeft,
  marginRight: props.marginRight,
  marginTop: props.marginTop,
  maxHeight: props.maxHeight,
  maxWidth: props.maxWidth,
  minHeight: props.minHeight,
  minWidth: props.minWidth,
  opacity: props.opacity,
  overflow: props.overflow,
  padding: props.padding,
  paddingBottom: props.paddingBottom,
  paddingLeft: props.paddingLeft,
  paddingRight: props.paddingRight,
  paddingTop: props.paddingTop,
  position: props.position,
  right: props.right,
  top: props.top,
  width: props.width || '100%',
  zIndex: props.zIndex,
  ...props.css,
  ...props.style,
}));

export default Box;
