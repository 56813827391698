import React from 'react';

const FindCampaignIcon = props => (
  <svg viewBox="0 0 136 90" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M98.044 68.089h-54.36a3.738 3.738 0 0 1-3.738-3.738V4.739a3.738 3.738 0 0 1 3.738-3.74h54.36a3.738 3.738 0 0 1 3.738 3.74V64.35a3.738 3.738 0 0 1-3.738 3.738"
        fill="#FFF"
      />
      <path
        d="M98.044 68.089h-54.36a3.738 3.738 0 0 1-3.738-3.738V4.739a3.738 3.738 0 0 1 3.738-3.74h54.36a3.738 3.738 0 0 1 3.738 3.74V64.35a3.738 3.738 0 0 1-3.738 3.738z"
        stroke="#A1AEBA"
        strokeWidth={2}
      />
      <text
        fontFamily="AzoSans-Bold, Azo Sans"
        fontSize={10}
        fontWeight="bold"
        fill="#A1AEBA"
        transform="translate(39 1)"
      >
        <tspan x={9.946} y={15.643}>
          &lt;HTML&gt;
        </tspan>
      </text>
      <path
        d="M47.797 27.46h1.413M53.037 33.46H82.04M47.797 33.46h1.413"
        stroke="#DBE1E6"
        strokeWidth={2}
        strokeLinecap="round"
      />
      <path d="M58.037 39.46H77.04" stroke="#0379D5" strokeWidth={2} strokeLinecap="round" />
      <path
        d="M47.797 39.46h1.413M58.037 45.46H72.04M77.037 45.46H89.04M47.797 45.46h1.413"
        stroke="#DBE1E6"
        strokeWidth={2}
        strokeLinecap="round"
      />
      <path d="M58.037 51.46H72.04" stroke="#9E6ACC" strokeWidth={2} strokeLinecap="round" />
      <path
        d="M77.037 51.46H89.04M47.797 51.46h1.413"
        stroke="#DBE1E6"
        strokeWidth={2}
        strokeLinecap="round"
      />
      <g>
        <path
          d="M59.098 87.732H4.738A3.738 3.738 0 0 1 1 83.994V24.382a3.738 3.738 0 0 1 3.738-3.739h54.36a3.738 3.738 0 0 1 3.738 3.74v59.611a3.738 3.738 0 0 1-3.738 3.738"
          fill="#FFF"
        />
        <path
          d="M59.098 88.089H4.738A3.738 3.738 0 0 1 1 84.351V24.739A3.738 3.738 0 0 1 4.738 21h54.36a3.738 3.738 0 0 1 3.738 3.739v59.612a3.738 3.738 0 0 1-3.738 3.738z"
          stroke="#A1AEBA"
          strokeWidth={2}
        />
        <path fill="#DBE1E6" d="M8 52.417h48V29H8z" />
        <path
          d="M49.8 72.46H10.798M55.04 72.46h-1.413M37.44 78.46H10.798M41.018 78.46h-.965"
          stroke="#DBE1E6"
          strokeWidth={2}
          strokeLinecap="round"
        />
        <path d="M49.8 58.813H10.798" fill="#FFF" />
        <path d="M49.8 58.813H10.798" stroke="#A1AEBA" strokeWidth={2} strokeLinecap="round" />
        <path d="M31.509 64.813H10.798" fill="#FFF" />
        <path d="M31.509 64.813H10.798" stroke="#A1AEBA" strokeWidth={2} strokeLinecap="round" />
        <path d="M47.837 46.46H40.8a3 3 0 1 1 0-6h7.037a3 3 0 1 1 0 6" fill="#027AD7" />
      </g>
      <g transform="translate(86 32)" strokeWidth={2}>
        <circle stroke="#A1AEBA" fill="#FFF" cx={24.191} cy={24.191} r={24.191} />
        <g stroke="#0379D5">
          <path d="M24.166 17.58v8.818M19.568 22.737l4.801 4.592 4.914-4.59M17.595 32.01H31.55" />
        </g>
      </g>
    </g>
  </svg>
);

export default FindCampaignIcon;
