import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { userSelectors } from '../modules/user';

import Container from './Container';
import { UpgradeReports } from './Upgrade';

import ReportsHeader from './reports/Header';
import ReportsNavigation from './reports/ReportsNavigation';

const GroupsReports = props => {
  const { groups, isUserRestricted } = props;
  const model = groups.data.reduce((m, g) => g, {});

  if (isUserRestricted) {
    return <UpgradeReports model={model} />;
  }

  if (!model) {
    return null;
  }

  if (groups.data.length < 1) {
    return (
      <div className="pure-u-1">
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div className="pure-u-1">
      <div className="pure-u-1">
        <ReportsHeader model={model} />

        <ReportsNavigation {...props} relationship="companies" model={model} />

        <Container
          style={{
            background: '#fff',
            margin: 0,
            minHeight: 300,
            padding: '25px',
          }}
        >
          {React.Children.map(props.children, child => React.cloneElement(child, { model }))}
        </Container>
      </div>
    </div>
  );
};

GroupsReports.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]).isRequired,
  groups: PropTypes.shape({ data: PropTypes.array }).isRequired,
  isUserRestricted: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  groups: state.groups,
  isUserRestricted: userSelectors.isUserRestrictedSelector(state),
});

export default connect(mapStateToProps)(GroupsReports);
