import humanize from 'humanize-number';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React from 'react';
import { Box, Flex, Link, Text } from '../../../mc-ui';

export default function ContextInfo({ context }) {
  if (context.loading) return <Box width="480px" />;

  return (
    <Box width="480px">
      <Link color="dark08" to={context.to} css={{ fontSize: '12px' }}>
        {context.backLabel}
      </Link>

      <Flex alignItems="center" height="24px" margin="4px 0">
        <Box height="20px" marginRight="8px" width="20px">
          <context.Logo />
        </Box>

        <Link to={context.to} css={{ fontSize: '18px', lineHeight: '24px' }}>
          {context.text}
        </Link>
      </Flex>

      <Text
        color="dark08"
        fontSize="12px"
        fontWeight="500"
        letterSpacing="0.02em"
        lineHeight="1.25em"
      >
        {context.approximateTextMessages > 0 &&
          `${humanize(context.approximateTextMessages)} text ${pluralize(
            'message',
            context.approximateTextMessages,
          )}`}
      </Text>
    </Box>
  );
}

ContextInfo.propTypes = {
  context: PropTypes.shape({
    Logo: PropTypes.func,
    approximateTextMessages: PropTypes.number,
    backLabel: PropTypes.string,
    loading: PropTypes.bool,
    text: PropTypes.string,
    to: PropTypes.object,
  }).isRequired,
};
