import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Section from '../../../Section';

import { Box, Flex, Heading, Text, theme } from '../../../mc-ui';
import { Link } from 'react-router'
import Container from '../../../Container';

import headerBackground from './background.png';
class PageHeader extends React.Component {
  render() {
    const {
      isUserRestricted,
      isSubscriptionAutomaticallyCharged,
      hasSmsAddon,
    } = this.props;

    const containerStyle = {
      display: 'flex',
      height: '277px',
      marginBottom: '0',
      marginTop: '0',
      paddingBottom: '0',
      paddingLeft: '0',
      paddingRight: '0',
      paddingTop: '36px',
      position: 'relative',
    };

    return (
      <Section outerBackground={theme.colors.white}>
        <Container style={containerStyle}>
          <Flex direction="column" justifyContent="center" width="444px">
            <Heading
              as="h2"
              fontSize="32px"
              fontWeight="300"
              lineHeight="42px"
              css={{ marginBottom: '8px' }}
            >
              SMS (&amp; MMS!) Campaigns
            </Heading>

            <Text>
              The line between SMS and email marketing is blurring as ecommerce marketers are being
              tasked to combine both channels into a cohesive strategy. We&apos;ve curated thousands
              of SMS messages from popular ecommerce brands to be the foundation for your campaign
              planning.
            </Text>
            <br />
            {(isUserRestricted) && (
              <Text>
                <strong>Full access to the SMS library is available to Pro-users as a paid
                  add-on for $49/month. Upgrade to Pro, and <Link to='/manage/plan'>Add it to your plan</Link> today.</strong>
              </Text>
            )}
            {(isSubscriptionAutomaticallyCharged && !hasSmsAddon) && (
              <Text>
                <strong>Full access to the SMS library is available to Pro-users as a paid
                  add-on for $49/month. <Link to='/manage/plan'>Add it to your plan</Link> today.
                </strong>
              </Text>
            )}
          </Flex>

          <Box
            backgroundImage={headerBackground}
            bottom="0"
            height="309px"
            position="absolute"
            right="0"
            width="640px"
            css={{ backgroundRepeat: 'no-repeat' }}
          />
        </Container>
      </Section>
    );
  };
}

PageHeader.propTypes = {
  isUserRestricted: PropTypes.bool.isRequired,
  isSubscriptionAutomaticallyCharged: PropTypes.bool.isRequired,
  hasSmsAddon: PropTypes.bool.isRequired,
};

export default connect()(PageHeader);
