import React from 'react';
import { camelizeKeys } from 'humps';
import { take } from 'ramda';

import api from '../../lib/api';

import Carousel from '../ui/Carousel';

async function fetchFeaturedSMSLists() {
  const limit = 3;
  const featured = true;
  const type = 'default';
  // emojis - 9512
  // back in stock - 10439
  // cart abandoment - 9509
  const q = 'emojis "back in stock" "cart abandonment"';
  const sort = 'position:asc';
  const response = await api.get('/v3/lists', { params: { limit, featured, type, q, sort } });
  const smsLists = camelizeKeys(response.data.data);
  return take(limit, smsLists);
}

class FeaturedSMSLists extends React.PureComponent {
  state = {
    loading: true,
    smsLists: [],
  };

  componentDidMount() {
    this.request();
  }

  request = async () => {
    const smsLists = await fetchFeaturedSMSLists();

    this.setState(() => ({
      smsLists,
      loading: false,
    }));
  };

  render() {
    const { loading, smsLists } = this.state;

    return <Carousel collection={smsLists} loading={loading} type="featuredSMSLists" />;
  }
}

export default FeaturedSMSLists;
