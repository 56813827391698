import React from 'react';
import PropTypes from 'prop-types';
import { take } from 'ramda';

import { Box } from '../../mc-ui';
import Email from '../Email';

const getPosition = index => {
  if (index === 0) return '0';
  if (index === 1) return '87px';
  return null;
};

const EmailsBackground = ({ list }) => {
  const emails = take(2, list.relationships.emails.data);

  return (
    <Box left="0" height="171px" overflow="hidden" position="absolute" top="0" width="245px">
      <Box
        backgroundImage={emails.length === 0 ? '/images/listCardBackground.png' : null}
        height="171px"
        overflow="hidden"
        position="relative"
        width="245px"
      >
        {emails.map((email, index) => (
          <Box
            key={email.id}
            borderRadius="4px"
            height="240px"
            left={getPosition(index)}
            position="absolute"
            right={index === 3 ? '0' : null}
            top="0"
            width="160px"
          >
            <Email
              email={email}
              height="100%"
              width="100%"
              css={{ border: 'none', height: '100%', margin: '0', padding: '0', width: '100%' }}
            />
          </Box>
        ))}
        {emails.length > 0 && (
          <Box
            bottom="0"
            left="0"
            height="78px"
            position="absolute"
            css={{
              background:
                'linear-gradient(360deg, rgba(0, 0, 0, 0) 65.21%, rgba(0, 0, 0, 0.2) 100%)',
              transform: 'rotate(-180deg)',
            }}
          />
        )}
      </Box>
    </Box>
  );
};

EmailsBackground.propTypes = {
  list: PropTypes.shape({}).isRequired,
};

export default EmailsBackground;
