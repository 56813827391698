/* eslint-disable default-case */
import api from '../lib/api';

export default store => next => action => {
  next(action);
  switch (action.type) {
    case 'REQUEST_FEATURED':
      api({
        method: 'get',
        url: action.url || '/v2/featured/emails',
      }).then(r => {
        store.dispatch({ type: 'RECEIVE_SEARCH', data: r.data });
      });
      break;
    case 'REQUEST_SEARCH':
      api({
        method: 'get',
        url: action.url || '/v2/searches',
        params: action.query,
      }).then(r => {
        store.dispatch({
          type: 'RECEIVE_SEARCH',
          data: r.data,
          query: action.query,
          url: action.url || '/v2/searches',
        });
      });
      break;
    case 'REQUEST_PAGINATION': {
      const link = store.getState().searches.links.next;
      if (!link) return;
      api.get(link).then(r => {
        const searches = r.data;
        store.dispatch({ type: 'RECEIVE_PAGINATION', searches });
      });
      break;
    }
  }
};
