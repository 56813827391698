import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@emotion/react';
import { Link } from 'react-router';

import { Box, Button, Flex, Heading, Tooltip } from '../mc-ui';
import FYICircleIcon from '../mc-ui/icons/FYICircleIcon';

const Header = ({ theme }) => (
  <Flex
    alignItems="center"
    backgroundColor="white"
    borderRadius="4px"
    data-identifier="header"
    height="64px"
    marginBottom="32px"
    marginTop="16px"
    padding="16px"
    css={{ boxShadow: `0px 1px 4px ${theme.colors.dark02}` }}
  >
    <Heading
      as="h3"
      color="dark08"
      fontSize="24px"
      fontWeight="300"
      lineHeight="32px"
      css={{
        margin: '0 0 0 16px',
      }}
    >
      Journeys
    </Heading>

    <Tooltip
      id="journeysHeaderTooltip"
      element={
        <Box
          inline
          height="24px"
          width="24px"
          css={{
            color: theme.colors.dark06,
            '&:hover': {
              color: theme.colors.dark1,
            },
          }}
        >
          <FYICircleIcon height="24px" width="24px" />
        </Box>
      }
      variant="blue"
      css={{
        height: '24px',
        marginLeft: '8px',
        width: '24px',
        '& span': {
          display: 'inline-block',
          height: '24px',
          width: '24px',
        },
        '& [data-id="tooltip"]': {
          marginLeft: '0 !important',
        },
      }}
    >
      <p>
        Learn what types of email journeys MailCharts collects, how often are new journeys triggered
        for each brand and more on our FAQ page.
      </p>

      <Button
        as={Link}
        to="https://knowledge.validity.com/hc/en-us/sections/6839689729819-FAQ"
        target="_blank"
        variant="primary"
        css={{
          alignItems: 'center !important',
          display: 'inline-flex !important',
          justifyContent: 'center !important',
          height: '32px !important',
          width: '104px !important',
        }}
      >
        Read more
      </Button>
    </Tooltip>
  </Flex>
);

Header.propTypes = {
  theme: PropTypes.shape({}).isRequired,
};

export default withTheme(Header);
