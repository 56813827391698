import styled from "@emotion/styled";

const EmptyListIconWrapper = styled("div")(({ renderOutside }) => ({
  alignItems: 'flex-end',
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'flex-end',
  paddingRight: renderOutside ? '165px' : '41px',
  width: '100%',
}));

export default EmptyListIconWrapper;
