import { PREFIX } from './constants';

export const FETCH_TEAM_REQUEST = `${PREFIX}/FETCH_TEAM_REQUEST`;
export const FETCH_TEAM_SUCCESS = `${PREFIX}/FETCH_TEAM_SUCCESS`;
export const FETCH_TEAM_FAILURE = `${PREFIX}/FETCH_TEAM_FAILURE`;

export const UPDATE_TEAM_REQUEST = `${PREFIX}/UPDATE_TEAM_REQUEST`;
export const UPDATE_TEAM_SUCCESS = `${PREFIX}/UPDATE_TEAM_SUCCESS`;
export const UPDATE_TEAM_FAILURE = `${PREFIX}/UPDATE_TEAM_FAILURE`;

export const INVITE_MEMBER_REQUEST = `${PREFIX}/INVITE_MEMBER_REQUEST`;
export const INVITE_MEMBER_SUCCESS = `${PREFIX}/INVITE_MEMBER_SUCCESS`;
export const INVITE_MEMBER_FAILURE = `${PREFIX}/INVITE_MEMBER_FAILURE`;

export const ARCHIVE_MEMBER_REQUEST = `${PREFIX}/ARCHIVE_MEMBER_REQUEST`;
export const ARCHIVE_MEMBER_SUCCESS = `${PREFIX}/ARCHIVE_MEMBER_SUCCESS`;
export const ARCHIVE_MEMBER_FAILURE = `${PREFIX}/ARCHIVE_MEMBER_FAILURE`;

export const REMOVE_MEMBER_REQUEST = `${PREFIX}/REMOVE_MEMBER_REQUEST`;
export const REMOVE_MEMBER_SUCCESS = `${PREFIX}/REMOVE_MEMBER_SUCCESS`;
export const REMOVE_MEMBER_FAILURE = `${PREFIX}/REMOVE_MEMBER_FAILURE`;

export const UPDATE_MEMBER_REQUEST = `${PREFIX}/UPDATE_MEMBER_REQUEST`;
export const UPDATE_MEMBER_SUCCESS = `${PREFIX}/UPDATE_MEMBER_SUCCESS`;
export const UPDATE_MEMBER_FAILURE = `${PREFIX}/UPDATE_MEMBER_FAILURE`;

export const ACTIVATE_MEMBER_REQUEST = `${PREFIX}/ACTIVATE_MEMBER_REQUEST`;

export const CANCEL_INVITATION_REQUEST = `${PREFIX}/CANCEL_INVITATION_REQUEST`;
export const CANCEL_INVITATION_SUCCESS = `${PREFIX}/CANCEL_INVITATION_SUCCESS`;
export const CANCEL_INVITATION_FAILURE = `${PREFIX}/CANCEL_INVITATION_FAILURE`;

export const RESEND_INVITATION_REQUEST = `${PREFIX}/RESEND_INVITATION_REQUEST`;
export const RESEND_INVITATION_SUCCESS = `${PREFIX}/RESEND_INVITATION_SUCCESS`;
export const RESEND_INVITATION_FAILURE = `${PREFIX}/RESEND_INVITATION_FAILURE`;

export const SUBSCRIPTION_REQUEST_SETUP_PAYMENT_INTENT = `${PREFIX}/SUBSCRIPTION/REQUEST_SETUP_PAYMENT_INTENT`;
export const SUBSCRIPTION_SETUP_PAYMENT_INTENT = `${PREFIX}/SUBSCRIPTION/SETUP_PAYMENT_INTENT`;
export const SUBSCRIPTION_GET_PAYMENT_INFO = `${PREFIX}/SUBSCRIPTION/GET_PAYMENT_INFO`;
export const SUBSCRIPTION_SETUP_PAYMENT_INFO = `${PREFIX}/SUBSCRIPTION/SETUP_PAYMENT_INFO`;

export const CREATE_SUBSCRIPTION_REQUEST = `${PREFIX}/SUBSCRIPTION/CREATE_REQUEST`;
export const CREATE_SUBSCRIPTION_SUCCESS = `${PREFIX}/SUBSCRIPTION/CREATE_SUCCESS`;
export const CREATE_SUBSCRIPTION_FAILURE = `${PREFIX}/SUBSCRIPTION/CREATE_FAILURE`;

export const SUBSCRIPTION_UPDATE_CREDIT_CARD_REQUEST = `${PREFIX}/SUBSCRIPTION/UPDATE_CREDIT_CARD_REQUEST`;
export const SUBSCRIPTION_UPDATE_CREDIT_CARD_SUCCESS = `${PREFIX}/SUBSCRIPTION/UPDATE_CREDIT_CARD_SUCCESS`;
export const SUBSCRIPTION_UPDATE_CREDIT_CARD_FAILURE = `${PREFIX}/SUBSCRIPTION/UPDATE_CREDIT_CARD_FAILURE`;

export const SUBSCRIPTION_CALCULATE_REQUEST = `${PREFIX}/SUBSCRIPTION/CALCULATE_REQUEST`;
export const SUBSCRIPTION_CALCULATE_SUCCESS = `${PREFIX}/SUBSCRIPTION/CALCULATE_SUCCESS`;
export const SUBSCRIPTION_CALCULATE_FAILURE = `${PREFIX}/SUBSCRIPTION/CALCULATE_FAILURE`;

export const SUBSCRIPTION_UPDATE_REQUEST = `${PREFIX}/SUBSCRIPTION/UPDATE_REQUEST`;
export const SUBSCRIPTION_ADDONS_REQUEST = `${PREFIX}/SUBSCRIPTION/ADDONS_REQUEST`;
export const SUBSCRIPTION_MIGRATE_REQUEST = `${PREFIX}/SUBSCRIPTION/MIGRATE_REQUEST`;
export const SUBSCRIPTION_UPDATE_SUCCESS = `${PREFIX}/SUBSCRIPTION/UPDATE_SUCCESS`;
export const SUBSCRIPTION_UPDATE_FAILURE = `${PREFIX}/SUBSCRIPTION/UPDATE_FAILURE`;

export const SUBSCRIPTION_CANCEL_REQUEST = `${PREFIX}/SUBSCRIPTION/CANCEL_REQUEST`;
export const SUBSCRIPTION_CANCEL_SUCCESS = `${PREFIX}/SUBSCRIPTION/CANCEL_SUCCESS`;
export const SUBSCRIPTION_CANCEL_FAILURE = `${PREFIX}/SUBSCRIPTION/CANCEL_FAILURE`;

export const SUBSCRIPTION_REACTIVATE_REQUEST = `${PREFIX}/SUBSCRIPTION/REACTIVATE_REQUEST`;
export const SUBSCRIPTION_REACTIVATE_SUCCESS = `${PREFIX}/SUBSCRIPTION/REACTIVATE_SUCCESS`;
export const SUBSCRIPTION_REACTIVATE_FAILURE = `${PREFIX}/SUBSCRIPTION/REACTIVATE_FAILURE`;

export const SUBSCRIPTION_UPDATE_BILLING_INFO_REQUEST = `${PREFIX}/SUBSCRIPTION/UPDATE_BILLING_INFO_REQUEST`;
export const SUBSCRIPTION_UPDATE_BILLING_INFO_SUCCESS = `${PREFIX}/SUBSCRIPTION/UPDATE_BILLING_INFO_SUCCESS`;
export const SUBSCRIPTION_UPDATE_BILLING_INFO_FAILURE = `${PREFIX}/SUBSCRIPTION/UPDATE_BILLING_INFO_FAILURE`;

export const INVITATION_ACCEPT_REQUEST = `${PREFIX}/INVITATION/ACCEPT_REQUEST`;
export const INVITATION_ACCEPT_SUCCESS = `${PREFIX}/INVITATION/ACCEPT_SUCCESS`;
export const INVITATION_ACCEPT_FAILURE = `${PREFIX}/INVITATION/ACCEPT_FAILURE`;

export const INVITATION_DECLINE_REQUEST = `${PREFIX}/INVITATION/DECLINE_REQUEST`;
export const INVITATION_DECLINE_SUCCESS = `${PREFIX}/INVITATION/DECLINE_SUCCESS`;
export const INVITATION_DECLINE_FAILURE = `${PREFIX}/INVITATION/DECLINE_FAILURE`;