import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import TwitterIcon from '../../mc-ui/icons/TwitterIcon';
import { twitterFollowersOptions } from '../../Brands/AdvancedFiltersModal/helpers';

import Dropdown from './Dropdown';

const TwitterFollower = ({ closeDropdown, router, user, ...props }) => {
  const {
    location: { query },
  } = router;

  const newQuery = { ...query };
  delete newQuery.history;
  delete newQuery.tags;
  delete newQuery.twitter_followers;
  delete newQuery.countries;

  const handleClick = () => {
    setTimeout(closeDropdown, 250);
  };

  const twitterFollowers = twitterFollowersOptions(
    user.meta.companies_search_params.twitter_followers.buckets,
  );

  return (
    <Dropdown icon={TwitterIcon} label="Twitter Follower" data-label="twitterFollower" {...props}>
      {twitterFollowers.filter(t => !!t.value).map(twitterFollower => (
        <Dropdown.Item
          key={`qsTwitterFollower-${twitterFollower.value}`}
          to={{
            pathname: '/brands/results',
            query: { ...newQuery, twitter_followers: twitterFollower.value },
          }}
          onClick={handleClick}
        >
          {twitterFollower.label}
        </Dropdown.Item>
      ))}
    </Dropdown>
  );
};

TwitterFollower.propTypes = {
  closeDropdown: PropTypes.func.isRequired,
  router: PropTypes.shape({
    location: PropTypes.shape({
      query: PropTypes.shape({}),
    }),
  }).isRequired,
  user: PropTypes.shape({
    meta: PropTypes.shape({
      companies_search_params: PropTypes.shape({
        twitter_followers: PropTypes.shape({ buckets: PropTypes.array }),
      }),
    }),
  }).isRequired,
};

const mapStateToProps = state => ({ user: state.user });

export default connect(mapStateToProps)(TwitterFollower);
