import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { isEmpty } from 'ramda';

import { Box, Button, Heading, Label, TextField, theme } from '../../mc-ui';
import CrossSmallIcon from '../../mc-ui/icons/CrossSmallIcon';

class SaveSearchForm extends React.Component {
  componentWillUnmount() {
    this.props.handleClose();
  }

  handleSubmit = (values, _form, callback) => {
    const { onSubmit } = this.props;
    onSubmit(values, callback);
  };

  render() {
    const { handleClose, hideCloseButton, initialValues } = this.props;
    const creating = isEmpty(initialValues);

    return (
      <Box
        backgroundColor="white"
        borderRadius="4px"
        boxShadow={`0px 2px 8px ${theme.colors.dark02}`}
        padding="16px"
        width="304px"
      >
        <Box position="relative">
          <Heading
            as="h3"
            fontSize="18px"
            fontWeight="500"
            lineHeight="24px"
            css={{ marginBottom: '16px' }}
          >
            {creating ? 'Save this filter' : 'Edit this filter'}
          </Heading>

          {!hideCloseButton && (
            <Button
              onClick={handleClose}
              variant="none"
              css={{
                margin: '0',
                padding: '0',
                position: 'absolute',
                right: '0',
                top: '0',
              }}
            >
              <CrossSmallIcon />
            </Button>
          )}
        </Box>

        <Form initialValues={initialValues} onSubmit={this.handleSubmit}>
          {({ handleSubmit, pristine, submitting }) => (
            <form onSubmit={handleSubmit}>
              <Label htmlFor="name" css={{ marginBottom: '16px' }}>
                <Field
                  autoComplete="off"
                  autoFocus
                  component={TextField}
                  id="name"
                  name="name"
                  placeholder="New list name"
                />
              </Label>

              <Box>
                <Button
                  disabled={submitting || pristine}
                  variant="primary"
                  type="submit"
                  css={{
                    alignItems: 'center',
                    display: 'inline-flex',
                    height: '32px',
                    justifyContent: 'center',
                    margin: '0',
                    padding: '0',
                    width: '88px',
                    '& > svg': {
                      marginRight: '8px',
                    },
                  }}
                >
                  Save
                </Button>

                <Button
                  disabled={submitting}
                  onClick={handleClose}
                  variant="secondary"
                  css={{
                    alignItems: 'center',
                    display: 'inline-flex',
                    height: '32px',
                    justifyContent: 'center',
                    margin: '0 0 0 8px',
                    padding: '0',
                    width: '88px',
                    '& > svg': {
                      marginRight: '8px',
                    },
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </form>
          )}
        </Form>
      </Box>
    );
  }
}

SaveSearchForm.defaultProps = {
  hideCloseButton: false,
  initialValues: {},
};

SaveSearchForm.propTypes = {
  handleClose: PropTypes.func.isRequired,
  hideCloseButton: PropTypes.bool,
  initialValues: PropTypes.shape({}),
  onSubmit: PropTypes.func.isRequired,
};

export default SaveSearchForm;
