import React from 'react';
import PropTypes from 'prop-types';
import { take } from 'ramda';
import styled from "@emotion/styled";

import BrandLogo from './BrandLogo';

const logosStyles = {
  1: {
    '& .logo1': {
      borderRadius: '50%',
      height: '100%',
      width: '100%',
    },
  },
  2: {
    '& .logo1, & .logo2': {
      height: '100%',
      width: '100%',
    },
    '& .logo1': { left: '-21px' },
    '& .logo2': { left: '21px' },
  },
  3: {
    '& .logo1, & .logo2': {
      height: '24px',
      width: '24px',
    },
    '& .logo1': {
      left: '-5px',
      top: '-5px',
    },
    '& .logo2': {
      left: '-5px',
      top: '21px',
    },
    '& .logo3': {
      height: '48px',
      left: '21px',
      top: '0',
      width: '48px',
    },
  },
};

const GroupLogo = ({ group }) => {
  const relatedCompanies = group.relationships
    ? (group.relationships || { companies: { data: [] } }).companies.data
    : group.attributes.related_companies;

  const companies = relatedCompanies?take(3, relatedCompanies):[];

  const GroupLogoWrapper = styled("div")({
    backgroundColor: '#FFF',
    border: '4px solid #DFE1E6',
    borderRadius: '50%',
    boxSizing: 'border-box',
    height: '48px',
    overflow: 'hidden',
    position: 'relative',
    width: '48px',
    '& .logo1, & .logo2, & .logo3': {
      boxSizing: 'border-box',
      margin: '0',
      padding: '0',
      position: 'absolute',
    },
  }, props => ({
    ...logosStyles[props.companiesLength],
  }));

  return (
    <GroupLogoWrapper companiesLength={companies.length}>
      {companies.map((company, index) => (
        <BrandLogo
          key={`${group.id}-${company.id}-logo`}
          brandId={company.id}
          className={`logo${index + 1}`}
          css={{ borderRadius: 0 }}
        />
      ))}
    </GroupLogoWrapper>
  );
};

GroupLogo.propTypes = {
  group: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.shape({
      related_companies: PropTypes.array,
    }),
    relationships: PropTypes.shape({
      companies: PropTypes.shape({
        data: PropTypes.array,
      }),
    }),
  }).isRequired,
};

export default GroupLogo;
