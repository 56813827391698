import React from 'react';
import PropTypes from 'prop-types';

import { Flex } from '../../../../../../../mc-ui';

import BrandEmails from './BrandEmails';
import Header from './Header';

const BrandsEmails = ({ data, date }) => {
  const brands = data.relationships.companies.data;

  return (
    <Flex direction="column">
      <Header data={data} date={date} />
      {brands.map(brand => (
        <BrandEmails key={`${brand.id}-emails`} brand={brand} date={date} />
      ))}
    </Flex>
  );
};

BrandsEmails.propTypes = {
  data: PropTypes.shape({
    relationships: PropTypes.shape({
      companies: PropTypes.shape({
        data: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
          }),
        ),
      }),
    }),
  }).isRequired,
  date: PropTypes.shape({}).isRequired,
};

export default BrandsEmails;
