import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import AddToGroupButton from '../../../../AddToGroupButton';
import { Flex, Link, Text } from '../../../../mc-ui';
import BrandLogo from '../../../../ui/BrandLogo';

export default function MessageInfo({ data }) {
  const [brand] = data.relationships.companies.data;

  return (
    <Flex
      borderBottom="1px solid"
      borderColor="dark5"
      justifyContent="space-between"
      paddingBottom="24px"
    >
      <Flex alignItems="center" width="unset">
        <BrandLogo brandId={brand.id} css={{ marginRight: '16px' }} />

        <Flex direction="column" width="unset">
          <Link
            to={`/companies/${brand.id}`}
            css={{ fontSize: '14px', lineHeight: '16px', marginBottom: '4px' }}
          >
            {brand.attributes.name}
          </Link>

          <AddToGroupButton
            brands={[brand]}
            variant="secondary"
            withOverlay
            css={{
              width: '96px',
              '& > button': {
                display: 'inline-flex',
                fontSize: '12px',
                height: '24px',
                lineHeight: '24px',
                width: '96px !important',
              },
            }}
          />
        </Flex>
      </Flex>

      <Flex width="unset">
        <Text fontSize="12px" textAlign="right">
          {moment(data.attributes.sent_at).format('dddd, MMMM DD, YYYY')}
          <br />@{moment(data.attributes.sent_at).format('h:mm a')}
        </Text>
      </Flex>
    </Flex>
  );
}

MessageInfo.propTypes = {
  data: PropTypes.shape({}).isRequired,
};
