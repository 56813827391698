import * as React from 'react';
import PropTypes from 'prop-types';

function SpeakerIcon({ fillOpacity, ...props }) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M19.427 8.485V5.83a.832.832 0 00-.83-.83h-.248L3.909 9.232c-.166-.332-.498-.498-.913-.498-.498 0-.996.415-.996.996v3.984c0 .58.498.995.996.995.415 0 .747-.248.913-.58l3.9 1.162v.331c0 1.992 1.66 3.569 3.652 3.569 1.327 0 2.572-.747 3.153-1.909l3.817 1.079h.25c.414 0 .83-.332.83-.83v-2.738C20.92 14.46 22 13.133 22 11.639s-1.162-2.822-2.573-3.154zm-8.05 8.963c-.912 0-1.66-.664-1.825-1.577l3.153.996c-.332.415-.83.581-1.327.581zm8.05-4.481a1.338 1.338 0 01-.747.249h-.995v3.153l-3.569-.996-5.477-1.66-4.564-1.327v-1.328l13.61-3.983v3.07h.995c.25 0 .581.083.747.25.415.248.747.746.747 1.244s-.249 1.079-.747 1.328z"
        fill="currentColor"
        fillOpacity={fillOpacity}
      />
    </svg>
  );
}

SpeakerIcon.propTypes = {
  fillOpacity: PropTypes.number,
};

SpeakerIcon.defaultProps = {
  fillOpacity: 0.6,
};

export default SpeakerIcon;
