import * as React from 'react';

function SvgNeutralIcon(props) {
  return (
    <svg width="16px" height="16px" viewBox="0 0 16 16" fill="none" {...props}>
      <circle cx={8} cy={8} r={8} fill="#FFD336" />
      <path fill="#fff" d="M4 9V7h8v2z" />
    </svg>
  );
}

export default SvgNeutralIcon;
