import * as types from './types';

// ********** LIST OF JOURNEYS ACTIONS **********
export const getJourneysFailure = (model, query, error) => ({
  type: types.GET_JOURNEYS_FAILURE,
  model: {
    id: model.id,
    type: model.type === 'groups' ? 'groups' : 'brands',
  },
  query,
  error,
});

export const getJourneysRequest = (model, query = {}) => ({
  type: types.GET_JOURNEYS_REQUEST,
  model: {
    id: model.id,
    type: model.type === 'groups' ? 'groups' : 'brands',
  },
  query,
});

export const getJourneysSuccess = (model, query, payload) => ({
  type: types.GET_JOURNEYS_SUCCESS,
  model: {
    id: model.id,
    type: model.type === 'groups' ? 'groups' : 'brands',
  },
  query,
  payload,
});

export const toggleJourneysLoading = (model, query) => ({
  type: types.TOGGLE_JOURNEYS_LOADING,
  model: {
    id: model.id,
    type: model.type === 'groups' ? 'groups' : 'brands',
  },
  query,
});

// ********** INDIVIDUAL JOURNEY ACTIONS **********
export const getJourneyFailure = (model, id, filter, error) => ({
  type: types.GET_JOURNEYS_FAILURE,
  model: {
    id: model.id,
    type: model.type === 'groups' ? 'groups' : 'brands',
  },
  filter,
  id,
  error,
});

export const getJourneyRequest = (model, id, query) => ({
  type: types.GET_JOURNEY_REQUEST,
  model: {
    id: model.id,
    type: model.type === 'groups' ? 'groups' : 'brands',
  },
  query,
  id,
});

export const getJourneySuccess = (model, id, query, payload) => ({
  type: types.GET_JOURNEY_SUCCESS,
  model: {
    id: model.id,
    type: model.type === 'groups' ? 'groups' : 'brands',
  },
  query,
  id,
  payload,
});

export const toggleJourneyLoading = (model, id, query) => ({
  type: types.TOGGLE_JOURNEY_LOADING,
  model: {
    id: model.id,
    type: model.type === 'groups' ? 'groups' : 'brands',
  },
  query,
  id,
});
