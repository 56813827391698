import * as React from 'react';
import PropTypes from 'prop-types';

function QuestionCircleIcon({ fillOpacity, ...props }) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12 3a9 9 0 109 9 9.01 9.01 0 00-9-9zm0 14.25A1.125 1.125 0 1112 15a1.125 1.125 0 010 2.25zm1.2-4.56a.75.75 0 00-.45.688.75.75 0 11-1.5 0 2.25 2.25 0 011.35-2.063 1.499 1.499 0 10-2.1-1.374.75.75 0 11-1.5 0 3 3 0 114.2 2.75v-.001z"
        fill="currentColor"
        fillOpacity={fillOpacity}
      />
    </svg>
  );
}

QuestionCircleIcon.propTypes = {
  fillOpacity: PropTypes.number,
};

QuestionCircleIcon.defaultProps = {
  fillOpacity: 1,
};

export default QuestionCircleIcon;
