import React from 'react';
import PropTypes from 'prop-types';

import { TYPES } from '../../modules/shareButton/constants';

import BrandLogo from '../ui/BrandLogo';
import ContentLoader from '../ui/ContentLoader';
import ShareButton from '../ui/ShareButton';
import { Box, Flex, Heading, Link, Text } from '../mc-ui';

import AddToGroupButton from '../AddToGroupButton';

export default function CompanyInformation({ company, loading }) {
  return (
    <Flex alignItems="center" height="144px">
      {loading ? (
        <Box height="88px" marginRight="32px" width="88px">
          <ContentLoader height="88px" width="88px" />
        </Box>
      ) : (
        <BrandLogo
          brandId={company.id}
          css={{ height: '88px', marginRight: '32px', width: '88px' }}
        />
      )}

      <Box flex="1" width="unset">
        <Text
          color="dark1"
          fontSize="14px"
          fontWeight="500"
          letterSpacing="0.02em"
          lineHeight="1.25em"
        >
          <Link to="/brands">Brands</Link> /
        </Text>

        {loading ? (
          <Box height="40px" width="200px">
            <ContentLoader height="40px" width="200px" />
          </Box>
        ) : (
          <Heading as="h2" color="dark1" fontSize="32px" fontWeight="300" lineHeight="1.25em">
            {company.attributes.name}
          </Heading>
        )}
      </Box>

      {!loading && (
        <Flex alignItems="center" width="unset" wrap="nowrap">
          <AddToGroupButton brands={[company]} />
          <ShareButton type={TYPES.brandOverview} model={company} css={{ marginLeft: '8px' }} />
        </Flex>
      )}
    </Flex>
  );
}

CompanyInformation.defaultProps = {
  company: null,
};

CompanyInformation.propTypes = {
  loading: PropTypes.bool.isRequired,
  company: PropTypes.shape({}),
};
