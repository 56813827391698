const INITIAL_STATE = { loading: true, data: [], selected: 0 };

const countSelected = data => data.filter(e => e.selected).length;

const toggleEmailSelection = (state, { guid }) => {
  const data = state.data.map((email) => {
    let { selected } = email;

    if (guid === email.attributes.guid) {
      selected = !email.selected;
    }

    return {
      ...email,
      selected,
    };
  });

  return {
    ...state,
    data,
    selected: countSelected(data),
  };
};

const toggleAllEmailsSelection = (state, selected) => {
  const data = state.data.map(email => ({
    ...email,
    selected,
  }));

  return {
    ...state,
    data,
    selected: countSelected(data),
  };
};

export default (state = INITIAL_STATE, action = {}) => {
  let data = []
  switch (action.type) {
    case 'REQUEST_SEARCH':
    case 'REQUEST_DASHBOARD':
    case 'REQUEST_FEED':
    case 'REQUEST_JOURNEYS_WITH_DETAILS':
      return INITIAL_STATE;
    case 'RECEIVE_SEARCH':
    case 'RECEIVE_JOURNEY':
    case 'RECEIVE_DASHBOARD':
    case 'RECEIVE_FEED':
      return { data: action.data.data.emails, loading: false };
    case 'RECEIVE_RELATED_EMAILS':
      return { data: action.data, loading: false };
    case 'RECEIVE_JOURNEYS_WITH_DETAILS':
      data = action.data.data.reduce((m, d) => m.concat(d.included), []).filter(i => i.type == 'emails')
      return { data, loading: false }
    case 'mc/journeys/GET_JOURNEY_SUCCESS':
      data = action.payload.data.relationships.emails.data
      return { data, loading: false, }
    case 'RECEIVE_PAGINATION':
      return { data: state.data.concat(action.searches.data.emails), loading: false };
    case 'SELECT_EMAIL':
      return toggleEmailSelection(state, action);
    case 'DESELECT_EMAIL':
      return toggleEmailSelection(state, action);
    case 'SELECT_ALL_EMAILS':
      return toggleAllEmailsSelection(state, true);
    case 'DESELECT_ALL_EMAILS':
      return toggleAllEmailsSelection(state, false);
    default:
      return state;
  }
};
